import { IDropdownOption, Stack } from '@fluentui/react';
import { ElxDropdown } from '@elixir/components';
import { Project } from 'features/workspaces/models/project';
import React, { useCallback } from 'react';
import { LensLabel } from 'utils/lensLabel';
import {
  stackItemFormStyles,
  textInputStyle,
  wideSpacingStackTokens,
} from '../../workspaceStyles';
import {
  ComponentType,
  ScmWorkspaceSettingsObj,
  Tooltips,
} from '../workspaceSettingsUtil';

interface ScmWorkspaceSettingsProps {
  data: ScmWorkspaceSettingsObj;
  project: Project;
  updateOrchestratorProject: (project: Project) => void;
  onChange: (event: any) => void;
  showError: any; //error object from react hook form
  closeQuickSwitchPanel: () => void;
  closeWizard: () => void;
}

const ScmWorkspaceSettings = (
  props: ScmWorkspaceSettingsProps
): JSX.Element => {
  const scmWorkspaceSettingsFormData = [
    {
      componentType: ComponentType.ElxDropdown,
      label: 'Enable Disable SCM',
      onRenderLabel: () => (
        <Stack.Item>
          <LensLabel
            labelText="Enable Disable SCM"
            hintText={Tooltips.secureChangeManagement}
            required={false}
          ></LensLabel>
          <p>
            SCM (Secure Change Management) requires a Secure Change Id. Once
            enabled, all the contents <br />
            will be locked, editing will be unavailable without a Secure Change
            Id
          </p>
        </Stack.Item>
      ),
      selectedKey: props.data.secureChangeManagement || 'Disabled',
      styles: textInputStyle,
      options: [
        { key: 'Disabled', text: 'Disabled' },
        { key: 'Enabled', text: 'Enabled' },
      ],
      onChange: (_: any, option: IDropdownOption<any>) => {
        const val = option?.text as string;
        props.onChange({
          target: {
            name: 'secureChangeManagement',
            value: {
              ...props.data,
              secureChangeManagement: val,
            },
          },
        });
        props.updateOrchestratorProject({
          ...props.project,
          secureChangeManagement: val,
        });
      },
    },
  ];

  const compToRender = useCallback((componentData) => {
    switch (componentData.componentType) {
      case 'ElxDropdown':
        return (
          <ElxDropdown
            label={componentData.label}
            labelActions={componentData.labelActions || []}
            onRenderLabel={componentData.onRenderLabel}
            defaultValue={componentData.defaultValue}
            options={componentData.options}
            onChange={componentData.onChange}
            selectedKey={componentData.selectedKey}
          ></ElxDropdown>
        );
      default:
        return <p>Unsupported component - error</p>;
    }
  }, []);

  return (
    <Stack>
      <Stack.Item>
        <Stack>
          <Stack.Item grow tokens={wideSpacingStackTokens}>
            <Stack>
              <Stack.Item key={'header'} {...stackItemFormStyles}>
                <LensLabel
                  labelText={'SCM Configuration'}
                  hintText={'Scm Configuration'}
                />
                <p>
                  Secure Change Management (SCM) is used for SOX compliance and
                  audit purposes. When SCM is enabled, your workspace and all
                  assets (i.e. jobs, configurations, etc.) will be protected by
                  a Secure Change id and all changes logged."
                </p>
                <br />
              </Stack.Item>
              {scmWorkspaceSettingsFormData.map((componentData, idx) => {
                return (
                  <Stack.Item key={idx} {...stackItemFormStyles}>
                    {compToRender(componentData)}
                  </Stack.Item>
                );
              })}
            </Stack>
          </Stack.Item>
        </Stack>
      </Stack.Item>
    </Stack>
  );
};

export default ScmWorkspaceSettings;
