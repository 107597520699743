import { WorkspaceParameter } from '../components/editWorkspace/orchestrationSettings/workspaceSettingsUtil';

/**
 * Represents a Geneva Orchestrator
 */
export interface Project {
  projectName: string;
  projectDisplayName: string;
  icMTeamPublicId?: string;
  authorizedAADClientIds: AuthorizedAADClientIds;
  dataConnections: ProjectDataConnection[];
  serviceGuid?: string;
  jobExpiration?: string;
  parameters?: WorkspaceParameter[];
  jobPriority?: number;
  allocationType?: string;
  tokensPerJob?: number;
  percentAllocation?: number;
  maxJobInstances?: number;
  maxQueuedJobInstances?: number;
  autoMitigation?: string;
  dataQualityWorkspaceId?: string;
  secureChangeManagement?: string;
}

export interface AuthorizedAADClientIds {
  [key: string]: string[];
}

export enum ConnectionDataSourceType {
  None = 'None',
  Sql = 'Sql',
  Kusto = 'Kusto',
  ApplicationInsights = 'ApplicationInsights',
  LogAnalytics = 'LogAnalytics',
  Cosmos = 'Cosmos',
  StorageAccount = 'StorageAccount',
  EventHub = 'EventHub',
  AzureDataLakeStorageGen2 = 'AzureDataLakeStorageGen2',
  Subscription = 'Subscription',
  AzureDataLakeAnalytics = 'AzureDataLakeAnalytics',
  Mdm = 'Mdm',
  AzureDataFactory = 'AzureDataFactory',
  SynapsePipeline = 'SynapsePipeline',
  SynapseScope = 'SynapseScope',
  Shared = 'Shared',
}

export const connectionTypesWithValidation = [
  ConnectionDataSourceType.Kusto,
  ConnectionDataSourceType.Cosmos,
  ConnectionDataSourceType.Sql,
  ConnectionDataSourceType.StorageAccount,
  ConnectionDataSourceType.AzureDataLakeStorageGen2,
];

/*
 * The project data connection (as in the orchestrator project) interface resembles the Orchestrator contracts:
 * https://msazure.visualstudio.com/One/_git/EngSys-CAS-DataStudioOrchestrator?path=%2FContracts%2FConnections&version=GBdevelop&_a=contents.
 */
interface AadConnection {
  authType: AadConnectionAuthType;
  principalId: string;
  tenant: string;
  connectionSecretUri?: string;
  aadClientSecretUri?: string;
  userId?: string;
  passwordSecretUri?: string;
  certificateSecretUri?: string;
}

export interface ProjectDataConnection extends AadConnection {
  connectionName: string;
  connectionDisplayName: string;
  type: ConnectionDataSourceType;
  cluster?: string;
  database?: string;
  applicationId?: string;
  workspace?: string;
  server?: string;
  region?: string;
  vcName?: string;
  account?: string;
  subscriptionId?: string;
  resourceGroup?: string;
  factory?: string;
  adlaAccount?: string;
  adlsAccount?: string;
  skipCasSdkDeployment?: boolean;
  storageAccount?: string;
  endpointSuffix?: string;
  useIdentityBasedAccess?: boolean;
  eventHubNamespace?: string;
  eventHubName?: string;
  namespace?: string;
  isImported?: boolean;
  isSynapse?: boolean;
  useAadAuthentication?: boolean;
  sourceDataConnection?: any;
  // [extendedProp: string]: any;
}

export enum AadConnectionAuthType {
  None = 'None',
  AADApp = 'AADApp',
  User = 'User',
  ConnectionString = 'ConnectionString',
  AadAppWithSegmentedMsiFic = 'AadAppWithSegmentedMsiFic',
  UamiWithSegmentedMsiFic = 'UamiWithSegmentedMsiFic',
}

export const AadConnectionAuthTypeDisplayNames = {
  [AadConnectionAuthType.AADApp]: 'AAD Application Authentication',
  [AadConnectionAuthType.User]: 'User Authentication',
  [AadConnectionAuthType.None]: 'None',
  [AadConnectionAuthType.ConnectionString]: 'Connection String',
  [AadConnectionAuthType.AadAppWithSegmentedMsiFic]:
    'AAD App With Segmented MSI+FIC',
  [AadConnectionAuthType.UamiWithSegmentedMsiFic]:
    'Managed Identity With Segmented MSI+FIC',
};

interface DataSourceAuthentication {
  authenticationMode: AadConnectionAuthType;
  applicationId?: string;
  tenant?: string; //optional - mdm doesnt need it
  connectionSecretUri?: string;
  aadClientSecretUri?: string;
  userId?: string;
  passwordSecretUri?: string;
  certificateSecretUri?: string;
  // special fields for azure storage type:
  azureStorageTenant?: string;
}

interface CosmosTokenPolicy {
  useTokenOptimizer?: boolean;
  minTokenAllocation?: string;
  maxTokenAllocation?: string;
  minJobHistoryCount?: string;
}
export interface DataSource extends DataSourceAuthentication {
  type: ConnectionDataSourceType;
  id: string;
  name: string;
  cluster?: string;
  database?: string;
  appId?: string;
  workspace?: string;
  workspaceId?: string;
  server?: string;
  region?: string;
  vcName?: string;
  account?: string;
  subscriptionId?: string;
  resourceGroup?: string;
  factory?: string;
  adlaAccount?: string;
  adlsAccount?: string;
  skipCasSdkDeployment?: boolean;
  storageAccount?: string; // azureStorage?: string;
  endpointSuffix?: string;
  useIdentityBasedAccess?: boolean;
  eventHubNamespace?: string;
  eventHubName?: string;
  namespace?: string;
  isImported?: boolean;
  isSynapse?: boolean;
  useAadAuthentication?: boolean;
  policy?: { tokenPolicy: CosmosTokenPolicy };
  targetProjectNames?: string[];
  allowedPrincipals?: { id: string; type: string }[];
  sourceProjectName?: string;
  sourceConnectionName?: string;
  authType?: string;
  principalId?: string;
}

export interface DataSourceWithValidation extends DataSource {
  connectionStatus: string;
}

export interface KustoDataSource extends DataSource {
  type: ConnectionDataSourceType.Kusto;
  tenant: string;
  cluster: string;
  database: string;
}

export interface AppInsightsDataSource extends DataSource {
  type: ConnectionDataSourceType.ApplicationInsights;
  tenant: string;
  appId: string;
}

export interface LogAnalyticsDataSource extends DataSource {
  type: ConnectionDataSourceType.LogAnalytics;
  tenant: string;
  workspace: string; //aka workspace in the docs
}

export interface SqlDataSource extends DataSource {
  type: ConnectionDataSourceType.Sql;
  tenant: string;
  server?: string;
  database?: string;
  region?: string;
}

export interface CosmosDataSource extends DataSource {
  type: ConnectionDataSourceType.Cosmos;
  tenant: string;
  cluster: string;
  vcName: string; //aka VirtualCluster
}

export interface ADLSGen2DataSource extends DataSource {
  type: ConnectionDataSourceType.AzureDataLakeStorageGen2;
  tenant: string;
  account: string;
}

export interface AdfDataSource extends DataSource {
  type: ConnectionDataSourceType.AzureDataFactory;
  tenant: string;
  subscriptionId: string;
  resourceGroup: string;
  factory: string;
}

export interface AdlaDataSource extends DataSource {
  type: ConnectionDataSourceType.AzureDataLakeAnalytics;
  tenant: string;
  adlaAccount: string;
  adlsAccount?: string;
  subscriptionId?: string;
  resourceGroup?: string;
  skipCasSdkDeployment?: boolean;
}

export interface StorageAccountDataSource extends DataSource {
  type: ConnectionDataSourceType.StorageAccount;
  tenant: string;
  storageAccount?: string;
  applicationId: string;
  endpointSuffix?: string;
  useIdentityBasedAccess?: boolean;
  connectionSecretUri: string;
}

export interface SubscriptionDataSource extends DataSource {
  type: ConnectionDataSourceType.Subscription;
  tenant: string;
  subscriptionId: string;
  resourceGroup?: string;
  useIdentityBasedAccess?: boolean;
}

export interface EventHubDataSource extends DataSource {
  type: ConnectionDataSourceType.EventHub;
  tenant: string;
  eventHubNamespace?: string;
  eventHubName?: string;
}

export interface MdmDataSource extends DataSource {
  type: ConnectionDataSourceType.Mdm;
  account: string;
  namespace: string;
}

export interface SynapsePipelineDataSource extends DataSource {
  type: ConnectionDataSourceType.SynapsePipeline;
  tenant: string;
  subscriptionId: string;
  resourceGroup: string;
  workspace: string;
}

export interface SynapseScopeDataSource extends DataSource {
  type: ConnectionDataSourceType.SynapseScope;
  tenant: string;
  subscriptionId: string;
  resourceGroup: string;
  workspace: string;
  skipCasSdkDeployment?: boolean;
}

export interface SharedDataSource extends DataSource {
  type: ConnectionDataSourceType.Shared;
  sourceProjectName?: string;
  sourceConnectionName?: string;
}

export type ResultDetail = {
  isAuthorized: boolean;
  authType: AadConnectionAuthType;
  permission: 'Write' | 'Read' | 'Execute' | 'Access';
  message: string;
};

export type ValidateResult = {
  connectionName: string;
  results: [ResultDetail];
};

export type AadTenant = {
  adfUrl: string;
  adlUrl: string;
  adlaDomain: string;
  adlsDomain: string;
  aiUrl: string;
  alternateDomains?: string[];
  armUrl: string;
  authority: string;
  cloud: string;
  directoryReaderClientId: string;
  domain: string;
  graphUrl: string;
  id: string;
  isInPublicCloud: boolean;
  keyVaultDomain: string;
  laUrl: string;
  msGraphUrl: string;
  name: string;
  primaryDomain: string;
  rdfeUrl: string;
  serviceBusDomain: string;
  sqlUrl: string;
  storageDomain: string;
  synapseDomain: string;
  synapseUrl: string;
  url: string;
  warmPathCloud: string;
};
