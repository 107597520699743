import * as react from '@fluentui/react';
import {
  DisplayTypes,
  ElxDropdown,
  ElxTableContainer,
  IElxColumn,
  IPickerItem,
  ITableAction,
} from '@elixir/components';
import {
  IDropdownOption,
  ISelectableOption,
  Stack,
  StackItem,
} from '@fluentui/react';
import { ThemeProvider } from '@fluentui/react';
import { AppDispatch } from 'app/lensShellUtility';
import { useLensShellTheme } from 'features/shell/lensShellStyles';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { LensLabel } from 'utils/lensLabel';
import jobApi from '../api/jobApi';
import {
  cancelJobInstance,
  rerunJobInstance,
  terminateJobInstance,
} from '../jobSlice';
import { JobInstance } from '../models/job';
import { getJobInstanceStatus } from '../utils/jobCommon';
import {
  getRelativeExecutionTime,
  getRelativeQueueTime,
  getTimeLabel,
} from '../utils/jobUtils';
import JobInstanceLog from './jobInstanceLog';
import { colLarge, colMedium, colSmall } from 'utils/sharedStyles';

enum BackfillStatus {
  SUCCESS = 'Succeeded',
  BACKFILLTAGSUCCESS = 'BackfillTagSuccess',
  NOBACKFILLTAG = 'NoBackfillTag',
  FAILED = 'Failed',
  NOBACKFILLINSTANCES = 'NoBackfillInstances',
  LOADING = 'Loading',
}

interface BackfillInstancesProps {
  onCancel: () => void;
  workspaceId: string;
  jobId: string;
}

const backfillTag = 'lens_backfill';

const BackfillInstances = (props: BackfillInstancesProps) => {
  const [selectedItems, setSelectedItems] = useState<JobInstance[]>([]);
  const [jobInstanceList, setJobInstanceList] = useState<JobInstance[]>([]);
  const [jobTags, setJobTags] = useState<string[]>([]);
  const [backfillStatus, setBackfillStatus] = useState<BackfillStatus>(
    BackfillStatus.SUCCESS
  );
  const [tagQry, setTagQry] = useState<string>('');
  const [showInstanceLog, setShowInstanceLog] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  const divElxTableContaierRef = useRef(null);
  const moreThanOneSelected = selectedItems.length > 1;
  const noneSelected = selectedItems.length === 0;

  const selection = useMemo(
    () =>
      new react.Selection({
        selectionMode: react.SelectionMode.multiple,
        getKey: (item: any) => item.id,
        onSelectionChanged: (): void =>
          setSelectedItems(selection.getSelection()),
      }),
    []
  );

  useEffect(() => {
    if (props.jobId !== '') {
      setJobInstanceList([]);
      jobApi
        .getJobInstanceTags(props.workspaceId, props.jobId)
        .then((tags: string[]) => {
          setJobTags(
            tags.filter((tag) => tag.trim().startsWith(backfillTag + '_'))
          );
          setTagQry(tags.length > 1 ? tags[tags.length - 1] : '');
          setBackfillStatus(BackfillStatus.BACKFILLTAGSUCCESS);
        })
        .catch((err) => {
          setBackfillStatus(BackfillStatus.FAILED);
        });
    }
  }, [props.workspaceId, props.jobId]);

  const getBackfillInstances = (
    workspaceId: string,
    jobId: string,
    tag: string
  ) => {
    setJobInstanceList([]);
    if (tag !== null && tag !== '') {
      setBackfillStatus(BackfillStatus.LOADING);
      jobApi
        .getJobInstances(workspaceId, jobId, tag)
        .then((jobInstances: JobInstance[]) => {
          setJobInstanceList(jobInstances);
          setBackfillStatus(BackfillStatus.SUCCESS);
        })
        .catch((err) => {
          setBackfillStatus(BackfillStatus.FAILED);
        });
    }
  };

  useEffect(() => {
    setJobInstanceList([]);
    if (tagQry !== null && tagQry !== '') {
      setBackfillStatus(BackfillStatus.LOADING);
      jobApi
        .getJobInstances(props.workspaceId, props.jobId, tagQry)
        .then((jobInstances: JobInstance[]) => {
          setJobInstanceList(jobInstances);
          setBackfillStatus(BackfillStatus.SUCCESS);
        })
        .catch((err) => {
          setBackfillStatus(BackfillStatus.FAILED);
        });
    }
  }, [props.workspaceId, props.jobId, tagQry]);

  // #region columns

  const columns: IElxColumn[] = [
    {
      ...colLarge,
      key: 'Start time',
      name: 'Start time',
      // fieldName: 'startTime',
      onRender: (jobInstance: JobInstance) => {
        return <span>{getTimeLabel(new Date(jobInstance.startTime))}</span>;
      },
    },
    {
      ...colLarge,
      key: 'End time',
      name: 'End time',
      // fieldName: 'startTime',
      onRender: (jobInstance: JobInstance) => {
        return <span>{getTimeLabel(new Date(jobInstance.endTime))}</span>;
      },
    },
    {
      ...colSmall,
      key: 'Queue time',
      name: 'Queue time',
      // fieldName: 'elapsedTime',
      onRender: (jobInstance: JobInstance) => {
        return <span>{getRelativeQueueTime(jobInstance)}</span>;
      },
    },
    {
      ...colLarge,
      key: 'Execution time',
      name: 'Execution time',
      // fieldName: 'elapsedTime',
      onRender: (jobInstance: JobInstance) => {
        return <span>{getRelativeExecutionTime(jobInstance)}</span>;
      },
    },
    {
      ...colLarge,
      key: 'createdBy',
      name: 'Created By',
      fieldName: 'createdBy',
    },
    {
      ...colMedium,
      key: 'status',
      name: 'Status',
      onRender: getJobInstanceStatus,
    },
  ];
  // #endregion columns
  const actions: ITableAction[] = [
    {
      key: 'refresh',
      text: 'Refresh',
      defaultDisplay: DisplayTypes.Show,
      iconProps: { iconName: 'Refresh' },
      disableBulkAction: false,
      onBulkAction: () => {
        getBackfillInstances(props.workspaceId, props.jobId, tagQry);
      },
    },
    {
      key: 'view logs',
      text: 'View Logs',
      defaultDisplay: DisplayTypes.Show,
      iconProps: { iconName: 'BulletedList' },
      disabled: noneSelected,
      disableBulkAction: false,
      onBulkAction: () => {
        setShowInstanceLog(true);
      },
    },
    {
      key: 'rerun instance',
      text: 'Rerun Instance',
      defaultDisplay: DisplayTypes.Show,
      iconProps: { iconName: 'PlaybackRate1x' },
      disabled: noneSelected || moreThanOneSelected,
      disableBulkAction: false,
      onBulkAction: (items: JobInstance[]) => {
        dispatch(
          rerunJobInstance({
            workspaceId: props.workspaceId,
            jobId: props.jobId,
            instanceId: items[0].id,
          })
        );
      },
    },
    {
      key: 'cancel instance',
      text: 'Cancel Instance',
      defaultDisplay: DisplayTypes.Show,
      iconProps: { iconName: 'Cancel' },
      disabled: noneSelected || moreThanOneSelected,
      disableBulkAction: false,
      onBulkAction: (items: JobInstance[]) => {
        dispatch(
          cancelJobInstance({
            workspaceId: props.workspaceId,
            jobId: props.jobId,
            instanceId: items[0].id,
          })
        );
      },
    },
    {
      key: 'terminate instance',
      text: 'Terminate Instance',
      defaultDisplay: DisplayTypes.Show,
      disabled: noneSelected || moreThanOneSelected,
      iconProps: { iconName: 'CircleStop' },
      disableBulkAction: false,
      onBulkAction: (items: JobInstance[]) => {
        dispatch(
          terminateJobInstance({
            workspaceId: props.workspaceId,
            jobId: props.jobId,
            instanceId: items[0].id,
          })
        );
      },
    },
    {
      key: 'columns',
      text: 'Columns',
      defaultDisplay: DisplayTypes.Show,
      iconProps: { iconName: 'Settings' },
      disableBulkAction: false,
      onBulkAction: () => {
        let elm = divElxTableContaierRef.current! as HTMLElement;
        let e = elm.querySelector("[data-icon-name='Settings']") as HTMLElement;
        e.click();
      },
    },
  ];

  const customColumns: IPickerItem[] = columns.map((o) => ({
    key: o.key,
    text: o.name,
    selected: true,
  }));

  const [customSelectedKeys, setCustomSelectedKeys] = useState<string[]>(
    customColumns.map((o) => o.key)
  );

  function renderHighlightedOption(
    props?: ISelectableOption,
    defaultRender?: (props?: ISelectableOption) => JSX.Element | null
  ): JSX.Element | null {
    if (props?.data) {
      return props.data;
    }
    if (defaultRender) {
      return defaultRender(props);
    }
    return null;
  }
  const theme = useLensShellTheme();

  const jobOptions: IDropdownOption[] = jobTags.map((d) => ({
    key: d,
    data: (
      <Stack>
        <StackItem>
          <div>
            <span>Range: {d.split('_')[2].split(/:\d{2}\.\d{3}Z/)[0]} - </span>
            <span>
              {d.split('_')[3].split(/:\d{2}\.\d{3}Z/)[0]} | Submitted On:
            </span>
            <span>{d.split('_')[4]}</span>
          </div>
        </StackItem>
      </Stack>
    ),
    text: `Range: ${d.split('_')[2].split(/:\d{2}\.\d{3}Z/)[0]} - ${
      d.split('_')[3].split(/:\d{2}\.\d{3}Z/)[0]
    } | Submitted  On: ${d.split('_')[4]}`,
  }));

  return (
    <ThemeProvider theme={theme}>
      <Stack>
        <Stack.Item
          styles={{ root: { width: 'fit-content', paddingLeft: '16px' } }}
        >
          <ElxDropdown
            label="Select Backfill Run"
            onRenderLabel={() => (
              <>
                <LensLabel
                  labelText="Select Backfill Run"
                  hintText="The instance list related to a particular backfill run"
                  required={true}
                ></LensLabel>
              </>
            )}
            options={jobOptions}
            placeholder={
              jobTags.length === 0 ? 'No Backfill Runs for this job' : ''
            }
            selectedKey={tagQry}
            onChange={(_, option?: IDropdownOption) => {
              if (option) {
                setTagQry(option.key as string);
              }
            }}
            onRenderOption={renderHighlightedOption}
            styles={{
              root: {
                padding: '8px',
                '.label': { paddingLeft: '8px !important' },
              },
            }}
          ></ElxDropdown>
        </Stack.Item>
        <div ref={divElxTableContaierRef}>
          <ElxTableContainer
            containerProps={{
              compact: true,
              styles: {
                headerContainer: {
                  height: '0px !important',
                },
                headerContent: { marginTop: '0px !important' },
                headerComponent: { paddingTop: '0px !important' },
                body: {
                  '.elx-table': {
                    marginLeft: '0px !important',
                    marginRight: '0px !important',
                  },
                  minHeight: '200px',
                  maxHeight: '1000px',
                },
              },
              isLoading: backfillStatus === BackfillStatus.LOADING,
            }}
            tableProps={{
              columns: columns,
              actions: actions,
              selectionMode: react.SelectionMode.single,
              items: jobInstanceList,
              selectedItems: selectedItems,
              getKey: (item: JobInstance) => item.id,
              setKey: 'id',
              onSelectionChanged: (val: react.IObjectWithKey[]) => {
                setSelectedItems(val as JobInstance[]);
              },
            }}
            customColumnProps={{
              items: customColumns,
              selectedItemKeys: customSelectedKeys,
              onSave: setCustomSelectedKeys,
            }}
          ></ElxTableContainer>
        </div>
      </Stack>
      {showInstanceLog && (
        <JobInstanceLog
          onCancel={() => setShowInstanceLog(false)}
          jobInstances={selectedItems}
          onManageJobs={props.onCancel}
        ></JobInstanceLog>
      )}
    </ThemeProvider>
  );
};

export default BackfillInstances;
