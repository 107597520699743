import {
  PayloadAction,
  SerializedError,
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';
import { RootState } from 'app/lensShellUtility';
import notifier from 'utils/notifier';
import grafanaMigrationApi from './api/grafanaMigrationApi';
import {
  GrafanaFolder,
  GrafanaKustoDatasource,
  GrafanaMigrationRequest,
  GrafanaMigrationResult,
  GrafanaMigrationStatus,
} from './models/grafanaMigrationData';
import { createConversionOperation } from '@azure-observability-ecg-grafana/data-migration';
import { createWebTelemetryClient } from '@azure-observability-ecg-grafana/data-migration/dist/telemetry/web';
import {
  batchItems,
  getMissingDatasourcesFromDashboard,
} from './grafanaDatasourceUtils';
import { LensNode } from './models/lensDashboard';
import logger from 'features/appInsights/lensLogger';
import { LensTelemetryConstants } from 'features/appInsights/appInsightsLibs';

export const loadFoldersForGrafanaInstance = createAsyncThunk(
  'grafanaMigration/loadFoldersForInstance',
  async ({ instanceUrl }: { instanceUrl: string }) => {
    return await grafanaMigrationApi.getFoldersForInstance(instanceUrl);
  }
);

export const migrateDashboard = createAsyncThunk(
  'grafanaMigration/migrateDashboard',
  async ({
    lensDashboard,
    instanceUrl,
    folderUid,
    message,
  }: {
    lensDashboard: LensNode[];
    instanceUrl: string;
    folderUid: string;
    message: string;
  }) => {
    try {
      const datasourcesResponse =
        await grafanaMigrationApi.getDatasourcesForInstance(instanceUrl);

      const datasources = Object.values(datasourcesResponse.datasources);
      const missingDatasources = getMissingDatasourcesFromDashboard(
        lensDashboard,
        datasources
      );

      const createdDatasources: GrafanaKustoDatasource[] = [];
      if (missingDatasources.length > 0) {
        const batchedDatasources = batchItems(missingDatasources, 10);
        for (const batch of batchedDatasources) {
          const response = await Promise.all(
            batch.map((ds) =>
              grafanaMigrationApi.createKustoDatasource(ds, instanceUrl)
            )
          );
          createdDatasources.push(...response.map((r) => r.datasource));
        }
      }

      const operation = createConversionOperation({
        createTelemetryClient: createWebTelemetryClient,
        datasources: [...datasources, ...createdDatasources],
      });
      const dashboardConversionResult = await operation.fromLensDashboard(
        lensDashboard as any
      );

      const request: GrafanaMigrationRequest = {
        dashboard: dashboardConversionResult.data,
        folderUid: folderUid === 'General' ? null : folderUid,
        message,
        overwrite: true,
      };

      const migrationResponse = await grafanaMigrationApi.migrateDashboard(
        request,
        instanceUrl
      );
      const result: GrafanaMigrationResult = {
        id: migrationResponse.id,
        status: migrationResponse.status,
        uid: migrationResponse.uid,
        url: migrationResponse.url,
        warnings: dashboardConversionResult.warnings,
      };
      return result;
    } catch (error: any) {
      if (error.isAxiosError && error.response?.data) {
        const data = error.response.data;
        if (data?.error?.message) {
          throw new Error(`${data.error.code}: ${data.error.message}`);
        }
      }
      throw error;
    }
  }
);

export interface GrafanaMigrationSettings {
  workspaceId: string;
  grafanaInstance: string;
  folder: string;
}

const folders: GrafanaFolder[] = [];
const dashboardMigrationResult: GrafanaMigrationResult = {
  id: '',
  status: '',
  uid: '',
  url: '',
  warnings: [],
};
const previousMigrationSettings: GrafanaMigrationSettings = {
  workspaceId: '',
  grafanaInstance: '',
  folder: '',
};

let initialMigrationData = {
  folders: folders,
  dashboardMigrationResult,
  dashboardMigrationStatus: GrafanaMigrationStatus.None,
  dashboardMigrationError: '',
  folderStatus: GrafanaMigrationStatus.None,
  previousMigrationSettings,
};

function extractErrorMessage(error: SerializedError): string {
  return `${error.name || 'unknown'}: ${error.message || 'no message'}`;
}

/**
 * Redux slice representing grafana migration.
 */
const grafanaMigrationSlice = createSlice({
  name: 'grafanaMigration',
  initialState: initialMigrationData,
  reducers: {
    resetDashboardMigrationStatus: (state) => {
      state.dashboardMigrationStatus = GrafanaMigrationStatus.None;
    },
    resetFolders: (state) => {
      state.folderStatus = GrafanaMigrationStatus.None;
      state.folders = [];
    },
    saveMigrationSettings: (
      state,
      action: PayloadAction<GrafanaMigrationSettings>
    ) => {
      state.previousMigrationSettings = action.payload;
    },
    clearMigrationError: (state) => {
      state.dashboardMigrationError = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadFoldersForGrafanaInstance.pending, (state, action) => {
        state.folderStatus = GrafanaMigrationStatus.Loading;
      })
      .addCase(loadFoldersForGrafanaInstance.fulfilled, (state, action) => {
        state.folderStatus = GrafanaMigrationStatus.Loaded;

        const folders = action.payload;
        state.folders = folders;
      })
      .addCase(loadFoldersForGrafanaInstance.rejected, (state, action) => {
        state.folderStatus = GrafanaMigrationStatus.Error;
        state.dashboardMigrationError = extractErrorMessage(action.error);
        notifier.error(action.error);
      })
      .addCase(migrateDashboard.pending, (state, action) => {
        state.dashboardMigrationStatus = GrafanaMigrationStatus.Loading;
      })
      .addCase(migrateDashboard.fulfilled, (state, action) => {
        state.dashboardMigrationStatus = GrafanaMigrationStatus.Loaded;
        state.dashboardMigrationResult = action.payload;
        logger.event(
          LensTelemetryConstants.EventNames.DashboardActions.MigrateToGrafana,
          {
            lensDashboard: action.meta.arg.lensDashboard?.[0]?.Title,
            lensDashboardId: action.meta.arg.lensDashboard?.[0]?.Id,
            grafanaInstance: action.meta.arg.instanceUrl,
            grafanaDashboard: action.payload?.uid,
            grafanaDashboardUrl: action.payload?.url,
          }
        );
      })
      .addCase(migrateDashboard.rejected, (state, action) => {
        const errorMessage = extractErrorMessage(action.error);
        state.dashboardMigrationStatus = GrafanaMigrationStatus.Error;
        state.dashboardMigrationError = errorMessage;
        logger.event(
          LensTelemetryConstants.EventNames.DashboardActions
            .MigrateToGrafanaError,
          {
            lensDashboard: action.meta.arg.lensDashboard?.[0]?.Title,
            lensDashboardId: action.meta.arg.lensDashboard?.[0]?.Id,
            grafanaInstance: action.meta.arg.instanceUrl,
            errorMessage,
          }
        );
        notifier.error(action.error);
      });
  },
});

export const selectFolderStatus = (state: RootState) =>
  state.grafanaMigration.folderStatus;
export const selectDashboardMigrationStatus = (state: RootState) =>
  state.grafanaMigration.dashboardMigrationStatus;
export const selectFolders = (state: RootState) =>
  state.grafanaMigration.folders;
export const selectDashboardMigrationResult = (state: RootState) =>
  state.grafanaMigration.dashboardMigrationResult;
export const selectPreviousMigrationSettings = (state: RootState) =>
  state.grafanaMigration.previousMigrationSettings;
export const selectDashboardMigrationError = (state: RootState) =>
  state.grafanaMigration.dashboardMigrationError;

export const {
  resetDashboardMigrationStatus,
  resetFolders,
  saveMigrationSettings,
  clearMigrationError,
} = grafanaMigrationSlice.actions;

export default grafanaMigrationSlice.reducer;
