import { StorageAccountSelector } from 'features/workspaces/components/editDataConnection/dataSourceComponents/storageAccountSelector';
import { SelectorProps } from 'features/workspaces/components/editDataConnection/editDataConnectionHelper';
import {
  ConnectionDataSourceType,
  StorageAccountDataSource,
} from 'features/workspaces/models/project';
import storageAccountClient from '../storageAccountClient';
import { registerDataSourcePlugin } from '../registry';
import { DataSourcePlugin } from './dataSourcePlugin';

//this data source is formally known as Azure Storage
class StorageAccountPlugin extends DataSourcePlugin {
  public dataSourceFormComponent(selectorProps: SelectorProps): JSX.Element {
    return (
      <StorageAccountSelector
        workspace={selectorProps.workspace}
        state={selectorProps.state}
        setState={selectorProps.setState}
        customNameFilled={selectorProps.customNameFilled}
      />
    );
  }

  public generatedName(state: StorageAccountDataSource) {
    return state && state.storageAccount
      ? 'AzureStorage.Table.' + state.storageAccount
      : '';
  }
}

registerDataSourcePlugin(
  ConnectionDataSourceType.StorageAccount,
  new StorageAccountPlugin(
    ConnectionDataSourceType.StorageAccount,
    storageAccountClient
  )
);
