import { mergeStyles } from '@fluentui/react';

export const groupBorder = mergeStyles({ border: '1px solid #E0E0E0' });

export const headerExpand = mergeStyles({
  background: 'rgba(0,120,212,0.15)',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const alignEnds = mergeStyles({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const choiceGroupStyles = {
  flexContainer: {
    display: 'flex',
    '.ms-ChoiceField': { padding: '0px 16px 0px 16px' },
  },
};

export const activityInfoTitle = mergeStyles({ fontWeight: 200 });

export const activityInfoDetails = mergeStyles({ fontWeight: 600 });
