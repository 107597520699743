import {
  ElxActionButton,
  ElxIconButton,
  ElxTableContainer,
  FilterDisplayMode,
  FilterOption,
  FilterOptionMode,
  FilterOptionPillMode,
  IElxColumn,
  IElxContainerProps,
  SelectionMode,
} from '@elixir/components';
import {
  CheckboxVisibility,
  getTheme,
  ILabelStyles,
  IObjectWithKey,
  ITheme,
  mergeStyleSets,
} from '@fluentui/react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../app/lensShellUtility';
import UserSavedObjectData, {
  UserSavedObjectDataType,
} from 'features/userInfo/models/userSavedObjectData';
import { favorite } from './homePage';
import {
  deleteUserFavorites,
  loadUserFavorites,
  selectUserFavoritesStatus,
} from '../../userInfo/userSavedObjectDataSlice';
import { tableStyles } from 'utils/sharedStyles';
import {
  selectWorkspaceListEntities,
  useWorkspaceList,
} from '../../workspaces/workspaceListSlice';

const theme: ITheme = getTheme();
const { semanticColors, fonts } = theme;
export const classNames = mergeStyleSets({
  moreIcon: {
    alignSelf: 'right',
    marginRight: 10,
    fontSize: fonts.large.fontSize,
    flexShrink: 0,
  },
  tableStyles: {
    borderBottom: `1px solid ${semanticColors.bodyDivider}`,
  },
  favorite: {
    marginRight: 10,
    color: 'rgb(255, 185, 0)',
    fontSize: fonts.large.fontSize,
    flexShrink: 0,
  },
  reset: {
    fontSize: '20',
  },
});
export const labelStyles4: Partial<ILabelStyles> = {
  root: { marginTop: 0, fontSize: '13px' },
};

export const containerStyles = {
  loading: {
    zIndex: '+1', //overlapped by default when ElxTableContainer is compact
    alignItems: 'end',
  },
  messageBar: {
    display: 'none',
  },
  headerComponent: {
    paddingTop: '0px !important',
  },
  headingContainer: {
    display: 'none',
  },
  actionsContainer: {
    display: 'none',
  },
};

export const colSmall = {
  minWidth: 50,
  isResizable: true,
  isMultiline: true,
  className: classNames.tableStyles,
};
export const colMedium = {
  minWidth: 100,
  isResizable: true,
  isMultiline: true,
  className: classNames.tableStyles,
};
export const colLarge = {
  minWidth: 130,
  isResizable: true,
  isMultiline: true,
  className: classNames.tableStyles,
};
export const colXLarge = {
  minWidth: 200,
  isResizable: true,
  isMultiline: true,
  className: classNames.tableStyles,
};

export const StringOperators = ['==', '!=', 'Contains'];

export interface TableProps {
  tableMap: UserSavedObjectData[];
  onSelectionChange: (selectedItem: favorite) => void;
}
export const Favorites = (props: TableProps): JSX.Element => {
  useWorkspaceList();
  const dispatch = useDispatch<AppDispatch>();
  const userFavoriteStatus = useSelector(selectUserFavoritesStatus);
  const workspaces = useSelector(selectWorkspaceListEntities);
  const containerProps = {
    compact: true,
    isLoading: userFavoriteStatus !== 'Loaded',
    styles: containerStyles,
    subActions: [<ElxActionButton text="Reset" />],
  } as IElxContainerProps;

  const columns: IElxColumn[] = [
    {
      ...colLarge,
      key: 'name',
      name: 'Name',
      fieldName: 'name',
    },
    {
      ...colSmall,
      key: 'isfavorite',
      name: '',
      onRender: (item: any) => {
        return (
          <ElxIconButton
            text=""
            styles={labelStyles4}
            iconProps={{
              iconName: 'FavoriteStarFill',
              className: classNames.favorite,
            }}
            onClick={() => {
              unFavorite(item);
            }}
          />
        );
      },
    },
    {
      ...colXLarge,
      key: 'description',
      name: 'Activity/Object',
      fieldName: 'description',
    },
    {
      ...colLarge,
      key: 'lensObjectType',
      name: 'Type',
      fieldName: 'lensObjectType',
    },
    {
      ...colXLarge,
      key: 'status',
      name: 'Status',
      fieldName: 'status',
    },
    {
      ...colMedium,
      key: 'date',
      name: 'Date',
      onRender: (item: any) => {
        if (item.date) {
          return item.date.toDateString();
        }
      },
    },
  ];

  const unFavorite = async (item: favorite) => {
    const favoriteItem: UserSavedObjectData[] = [
      {
        workspaceId: item.workspaceId,
        type: UserSavedObjectDataType.Favorite,
        lensObjectId: item.workspaceId,
        lensObjectType: 'workspace',
      },
    ];
    await dispatch(
      deleteUserFavorites({ userSavedObjectDataList: favoriteItem })
    );
    dispatch(loadUserFavorites({ workspaces: workspaces }));
  };
  const namePillFilter: FilterOption = {
    field: 'name',
    label: 'Name',
    operators: StringOperators,
    mode: FilterOptionMode.Text,
    pillMode: FilterOptionPillMode.Static,
  };
  const activityPillFilter: FilterOption = {
    field: 'description',
    label: 'Activity/Object',
    operators: StringOperators,
    mode: FilterOptionMode.Text,
    pillMode: FilterOptionPillMode.Static,
  };
  const typePillFilter: FilterOption = {
    field: 'lensObjectType',
    label: 'Type',
    multiselect: true,
    pillMode: FilterOptionPillMode.Dynamic,
    values: props.tableMap
      .map((item) => item.lensObjectType)
      .filter((value, index, self) => self.indexOf(value) === index),
  };
  const statusPillFilter: FilterOption = {
    field: 'status',
    label: 'Status',
    operators: StringOperators,
    mode: FilterOptionMode.Text,
    pillMode: FilterOptionPillMode.Static,
  };

  const searchProps = {
    pillFilters: [
      namePillFilter,
      activityPillFilter,
      typePillFilter,
      statusPillFilter,
    ],
    filterDisplayMode: FilterDisplayMode.Pill,
  };

  return (
    <>
      <ElxTableContainer
        containerProps={containerProps}
        tableProps={{
          columns,
          selectionMode: SelectionMode.single,
          items: props.tableMap,
          checkboxVisibility: CheckboxVisibility.hidden,
          styles: tableStyles,
          onSelectionChanged: (val: IObjectWithKey[]) => {
            if (val.length > 0) {
              props.onSelectionChange(val[0] as favorite);
            }
          },
          getKey: (item: favorite) => item.id,
        }}
        searchBoxProps={searchProps}
      />
    </>
  );
};
