import React from 'react';
import { ElxPanel, PanelSize } from '@elixir/components';
import { ThemeProvider } from '@fluentui/react';
import { Parameters } from '../models/job';
import { useLensShellTheme } from 'features/shell/lensShellStyles';
import { JobParameterItems } from './jobParameterItems';

interface JobParameterProps {
  onCancel: () => void;
  onSuccess: () => void;
  parameters: Parameters[];
  onAdd: () => void;
  onNameChange: (name: string, idx: number) => void;
  onValueChange: (value: string, idx: number) => void;
  onRemove: (idx: number) => void;
}

export function JobParameter(props: JobParameterProps) {
  const theme = useLensShellTheme();

  return (
    <ThemeProvider theme={theme}>
      <ElxPanel
        headerText={'Run Jobs with Parameters'}
        isOpen={true}
        actions={[
          {
            key: 'run once',
            text: 'Run Once',
            primary: true,
            onClick: () => {
              props.onSuccess();
            },
            iconProps: {
              iconName: 'play',
            },
          },
          {
            key: 'close',
            text: 'Close',
            onClick: () => {
              props.onCancel();
            },
          },
        ]}
        size={PanelSize.medium}
        onDismiss={props.onCancel}
      >
        <JobParameterItems
          parameters={props.parameters}
          onNameChange={props.onNameChange}
          onValueChange={props.onValueChange}
          onRemove={props.onRemove}
          onAdd={props.onAdd}
        ></JobParameterItems>
      </ElxPanel>
    </ThemeProvider>
  );
}
