import { ElxTable, IElxTableProps } from '@elixir/components';
import { CheckboxVisibility, Stack } from '@fluentui/react';
import {
  CatalogEntity,
  CatalogEntityHistoryAdapterFormat,
  HistoryEntry,
} from 'features/catalog/models/catalogEntity';
import { entityDetailsStackStyles } from 'features/catalog/utils/catalogEntityDetailsStyles';
import {
  colExtraLarge,
  colLarge,
  colMedium,
  colUnlimitedLarge,
  tableStyles,
} from 'utils/sharedStyles';

interface HistoryDetailsPageProps {
  entity: CatalogEntity;
  history: CatalogEntityHistoryAdapterFormat | undefined;
}

export const EntityDetailsHistory = (
  props: HistoryDetailsPageProps
): JSX.Element => {
  const { entity, history } = props;

  let historyEntries: HistoryEntry[] = history?.historyEntries || [];

  if (history?.historyEntries) {
    historyEntries = [...history?.historyEntries];
  }

  const creationEntry: HistoryEntry = {
    changeLocation: '',
    name: '',
    operation: 'Entity Creation',
    propertyType: '',
    updateTime: entity.createdDate || '',
    userIdentifier: null,
    userObjectId: entity.createdBy || '',
    applicationAppId: '',
  };
  historyEntries.push(creationEntry);

  const historyTableProps: IElxTableProps = {
    columns: [
      {
        ...colLarge,
        key: 'date',
        name: 'Date',
        onRender: (item: HistoryEntry) => {
          if (item.updateTime) {
            var date = new Date(item.updateTime);
            return date.toUTCString();
          } else {
            return '';
          }
        },
        disableSort: true,
      },
      {
        ...colExtraLarge,
        key: 'aliasOrApplicationID',
        name: 'Alias/Application ID',
        onRender: (item: HistoryEntry) => {
          if (item.userIdentifier) {
            return item.userIdentifier;
          } else if (
            item.userObjectId !== '00000000-0000-0000-0000-000000000000'
          ) {
            return item.userObjectId;
          } else if (item.applicationAppId) {
            return item.applicationAppId;
          } else {
            return 'Not found';
          }
        },
        fieldName: 'userIdentifier',
        disableSort: true,
      },
      {
        ...colLarge,
        key: 'name',
        name: 'Change Event Name',
        fieldName: 'name',
        disableSort: true,
      },
      {
        ...colMedium,
        key: 'operation',
        name: 'Operation',
        fieldName: 'operation',
        disableSort: true,
      },
      {
        ...colMedium,
        key: 'changeLocation',
        name: 'Change Location',
        fieldName: 'changeLocation',
        disableSort: true,
      },
      {
        ...colUnlimitedLarge,
        key: 'oldValue',
        name: 'Old Value',
        fieldName: 'oldValue',
        disableSort: true,
      },
      {
        ...colUnlimitedLarge,
        key: 'newValue',
        name: 'New Value',
        fieldName: 'newValue',
        disableSort: true,
      },
    ],
    items: historyEntries,
    checkboxVisibility: CheckboxVisibility.hidden,
    styles: tableStyles,
  };

  return (
    <Stack {...entityDetailsStackStyles}>
      <Stack.Item>
        {historyEntries && historyEntries.length > 0 ? (
          <ElxTable {...historyTableProps} />
        ) : (
          <>This entity has no history records.</>
        )}
      </Stack.Item>
    </Stack>
  );
};
