import { ElxActionButton } from '@elixir/components';
import { newGuid } from '@microsoft/applicationinsights-core-js';
import { Activity } from 'features/orchestrator/models/job';
import { LensLabel } from 'utils/lensLabel';
import { JobActivityDataValidationValidation } from './JobActivityDataValidationValidation';
import { JobActivityValidationValidationTrigger } from './JobActivityValidationValidationTrigger';

export interface JobActivityDataValidationValidationsProps {
  activity: Activity;
  onChange: (activity: Activity) => void;
}

export const JobActivityDataValidationValidations = (
  props: JobActivityDataValidationValidationsProps
) => {
  const { activity, onChange } = props;
  activity.validations = activity.validations || [
    {
      id: newGuid(),
      triggers: [
        {
          alwaysTrigger: false,
          condition: {
            aggregation: 'Percentage',
            operator: 'GreaterThan',
            value: 0,
          },
          actionIds: [],
        },
      ],
      type: 'NullCount',
      dimension: {
        groupBy: [],
      },
      filter: null,
      applyToTheseColumns: [''],
    },
  ];
  

  console.log('JobActivityDataValidationValidations');

  return (
    <>
      {activity.validations!.map((validation, idx) => {
        return (
          <>
            <JobActivityDataValidationValidation
              validation={validation}
              activity={activity}
              onChange={(activity: Activity): void => {
                onChange(activity);
              }}
            ></JobActivityDataValidationValidation>

            {validation.triggers.map((trigger: any, idx: number) => {
              return (
                <>
                  {idx === 0 && (
                    <LensLabel
                      labelText={'Triggers'}
                      hintText={
                        'The validation triggers that describe how to evaluate the validation scenario. Each trigger is evaluated sequentially. If a trigger is activated, its actions are performed. Validation triggers do not stop job execution. After the actions are performed, the job instance continues to execute successfully. Click on the icon for more information.'
                      }
                    ></LensLabel>
                  )}
                  <JobActivityValidationValidationTrigger
                    trigger={trigger}
                    validation={validation}
                    activity={activity}
                    onChange={(activity: Activity): void => {
                      onChange(activity);
                    }}
                    onRemove={() => {
                      validation.triggers.splice(idx, 1);
                      onChange(activity);
                    }}
                  ></JobActivityValidationValidationTrigger>
                </>
              );
            })}

            <ElxActionButton
              text="Add Trigger"
              iconProps={{ iconName: 'Add' }}
              onClick={() => {
                validation.triggers.push({
                  id: newGuid(),
                  triggers: [
                    {
                      alwaysTrigger: false,
                      condition: {
                        aggregation: 'Percentage',
                        operator: 'GreaterThan',
                        value: 0,
                      },
                      actionIds: [],
                    },
                  ],
                  type: 'NullCount',
                  dimension: {
                    groupBy: [],
                  },
                  filter: null,
                  applyToTheseColumns: [''],
                });
                onChange(activity);
              }}
            />
          </>
        );
      })}
    </>
  );
};
