import { IObjectWithKey } from '@fluentui/react';

export enum JobExecutionStatus {
  CREATED = 'created',
  MANUAL = 'manual',
  QUEUED = 'queued',
  RUNNING = 'running',
  RETRY = 'retry',
}

export enum JobInstanceStatus {
  SUCCESS = 'Succeeded',
  FAILED = 'Failed',
  CANCELLED = 'Canceled',
  TERMINATED = 'Terminated',
  NOTRUNNING = 'NotRunning',
  RUNNING = 'Running',
  QUEUED = 'Queued',
}

export enum ActivityTypes {
  DataCommand = 'DataCommand',
  DataMovement = 'DataMovement',
  DataValidation = 'DataValidation',
  DataEvent = 'DataEvent',
  T4Transform = 'T4Transform',
}
export const retryPolicyType = ['None', 'Linear', 'Exponential'];
export const restartPolicy = [
  'Restart all activities',
  'Restart only failed activities',
];
export const severityLevels = [0, 1, 2, 3, 4];

export interface RetryPolicy {
  restartPolicy?: number;
  count?: number | null;
  delay?: string | null; // "01:00:00:00",
  type: string;
}

export interface DeltaSettings {
  lookback: number;
  backFillMode: string;
  maxDelta: number;
  respectBoundaries: string;
}

export interface Dependency {
  endpoint: any;
  partitionSize?: string;
}

export interface DependencySettings {
  dependencies: Dependency[];
  triggerPolicy: string;
  reTriggerPolicy: string;
  submissionDelay: string;
  timeout?: string | null;
  windowSize: string | null;
  startTime?: string;
  windowType?: number;
  windowsPerScan?: number | null;
  onlyFinishedWindows?: boolean;
  oldestFirst?: boolean;
}
export interface JobScheduleSettings {
  scheduleType: string;
  startTime?: string;
  dependencySettings?: DependencySettings; // need to furthur specify the fields
  frequencySettings?: any; // need to furthur specify the fields
  deltaSettings?: DeltaSettings;
}

export interface FailureWebhook {
  url: string;
  aadResource: string;
  aadTenant: string;
}
export interface Parameters {
  name: string;
  value: string;
}

export interface ParametersWithType {
  name: string;
  value: string;
  type: string;
}

export const parameterType = [
  '',
  'AadAccessToken',
  'Boolean',
  'Integer',
  'KeyVaultSecret',
  'Query',
  'SangamMacro',
  'String',
  'StringNoQuote',
];

export interface schema {
  name: string;
  type: string;
}
export interface activityInput {
  type: string;
  connectionName: string;
  query?: string;
  rangeStep?: string;
  pattern?: string;
  folder?: string;
  catalogEntity?: string;
  fileSystem?: string;
  path?: string;
  metric?: string;
  overrideStreamValues?: boolean;
  storageContainer?: string;
  blobPrefix?: string;
  table?: string;
  tableType?: string;
  schema?: schema[]; // TODO - Nambi - check orchestrator uses both - for JobSchemaEntity - it expects [] which for sql it is just string..
}

export interface activityOutput {
  type: string;
  connectionName: string;
  aadClientId?: string;
  cluster?: string;
  vcName?: string;
  folder?: string | null;
  tableType?: string;
  table?: string;
  pipeline?: string;
  catalogEntity?: string;
  fileSystem?: string;
  path?: string;
  pattern?: string;
  metric?: string;
  overrideStreamValues?: boolean;
  storageContainer?: string;
  blobPrefix?: string;
  schema?: schema[] | string; // TODO - Nambi - check orchestrator uses both - for JobSchemaEntity - it expects [] which for sql it is just string..
  rangeStep?: string;
  query?: string;
  fileFormat?: string;
  namespace?: string;
  account?: string;
}

export interface processor {
  type: string;
  connectionName: string;
  scopePool?: string;
}

export interface Action {
  type: string;
  displayName: string;
  output: activityOutput;
  id: string;
}

export interface Activity {
  validations?: any[];
  failIfValidationTriggers?: boolean;
  subscription?: string;
  validatedEntitySignalSuffix?: string;
  azureAnalyticsModulePath?: string;
  retryPolicy?: RetryPolicy;
  activityDisplayName: string;
  activityType: string;
  parameters?: ParametersWithType[];
  id: string;
  transformEngine?: string;
  input?: activityInput;
  output?: activityOutput;
  validationQuery?: string;
  partitionColumn?: string | null;
  script?: string;
  priority?: number;
  tokenAllocation?: number;
  vcPercentAllocation?: number;
  runtimeVersion?: string;
  language?: string;
  dependsOn?: { activityId: string }[];
  t4Script?: { sourcepath: string };
  t4Paths?: string[];
  t4Refs?: string[];
  eventContents?: string;
  processor?: processor;
  primaryKey?: string;
  timeColumn?: string;
  partitionCount?: number;
  maxPartitionParallelism?: number;
  maxReprocessingAge?: string;
  partitionFilters?: string[];
  actions?: Action[];
}

export interface JobContext {
  uiCron?: {
    freq: string;
    rate: number;
    value: string;
    isEntered: boolean;
    hourMins: number;
    minuteSecs: number;
    time: string;
    weekDays: [boolean, boolean, boolean, boolean, boolean, boolean, boolean];
    monthDay: number;
  };
  failureEmailAddresses?: string;
  uiPeriodBetweenJobs?: string;
  dependsOn?: any;
  nodesPosition?: any;
}
export interface JobConfig {
  jobName: string;
  jobDisplayName: string;
  jobDescription: string;
  tags: string;
  maxJobInstances: number | null;
  retryPolicy: RetryPolicy;
  isDebug: boolean;
  appendUtcIndicator: boolean;
  allocationType: string;
  jobScheduleSettings: JobScheduleSettings;
  parameters: ParametersWithType[];
  defaultICMAlerts: boolean;
  activities?: Activity[];
  project: string;
  context?: JobContext;
  failureWebhook: FailureWebhook;
  icmTeamPublicId?: string;
  alertSeverity?: number;
  maxFailedJobDuration?: string;
  maxExecutionTime?: string;
  maxQueuedJobInstances?: number;
  maxFailedJobInstances?: number;
  metricAccount?: string;
  folder?: string;
}
export interface JobInstance extends IObjectWithKey {
  id: string;
  jobId: string;
  workspace: string;
  createdBy: string;
  status: string;
  jobStatus: string;
  errorMsg?: string; // made it optional
  createdDate: string;
  startTime: string;
  endTime: string;
  elapsedTime: string;
  seed: string;
  logLink: string;
  elapsedMilliseconds: number;
  parameters: { [propName: string]: string };
  runTimeParameters?: { [propName: string]: string } | null;
  segments: {
    segmentId: string;
    startTime: string;
    endTime?: string | undefined;
    parameters: {
      z: string;
    };
    segmentName: string;
  }[]; //fix
  groupId: string;
  tags: string;
}

export interface Job extends IObjectWithKey {
  id: string;
  name: string;
  workspace: string;
  description: string;
  tags: string;
  config?: JobConfig;
  createdBy: string;
  createdDate: string;
  nextExecutionDate: string | null;
  modifiedBy: string;
  modifiedDate: string;
  version: string;
  isEnabled: boolean;
  isQuarantined: boolean;
  quarantineReason: string;
  instances?: JobInstance[];
  shareType: string;
  partitionKey: string;
  rowKey: string;
  timestamp: string;
  eTag: string;
  url?: string;
}

export interface LogEntry {
  timestamp: string;
  severityLevel: string;
  message: string;
  errorType: string;
  details: string;
  customDimensions: string;
  activityName: string;
  activityId: string;
}

export interface Log {
  project: string;
  jobName: string;
  triggerType: string;
  createdDate: string;
  entries: LogEntry[];
}

export interface InstanceLog extends Log {
  instanceId: string;
}
export interface JobsExim {
  jobs: Job[];
}

export interface JobScheduleEntity {
  endpoint: {
    type: string;
    jobName?: string;
    projectName?: string;
    activityId?: string;
    connectionName?: string;
    tableType?: string;
    table?: string;
    catalogEntity?: string;
    fileSystem?: string;
    path?: string;
    pattern?: string;
    folder?: string;
  };
  partitionSize?: string | null;
}

export interface TimeSpan {
  days: null | number;
  hours: null | number;
  minutes: null | number;
  seconds: null | number;
}
