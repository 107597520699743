import { Stack } from '@fluentui/react';
import { itemAlignmentsStackTokens1 } from './homePageStyles';
import { TrainingAndGuideCard } from './trainingAndGuideCard';

export interface TrainingAndGuidesProps {
  key: string;
  mainLabel: string;
  secondaryLabel: string;
  iconName: string;
  iconText: string;
  link: string;
}
export interface TrainingAndGuidesListProps {
  items: TrainingAndGuidesProps[];
}

export const TrainingAndGuidesList = (
  props: TrainingAndGuidesListProps
): JSX.Element => {
  const trainingAndGuidesData = props.items;
  const trainingAndGuidesList = [];
  for (let i = 0; i <= trainingAndGuidesData.length - 2; i += 2) {
    const item1 = (
      <TrainingAndGuideCard
        key={trainingAndGuidesData[i].key}
        mainLabel={trainingAndGuidesData[i].mainLabel}
        secondaryLabel={trainingAndGuidesData[i].secondaryLabel}
        iconName={trainingAndGuidesData[i].iconName}
        link={trainingAndGuidesData[i].link}
        iconText=""
      />
    );
    const item2 = (
      <TrainingAndGuideCard
        key={trainingAndGuidesData[i + 1].key}
        mainLabel={trainingAndGuidesData[i + 1].mainLabel}
        secondaryLabel={trainingAndGuidesData[i + 1].secondaryLabel}
        iconName={trainingAndGuidesData[i + 1].iconName}
        link={trainingAndGuidesData[i + 1].link}
        iconText=""
      />
    );
    const card = (
      <Stack key={i} horizontal tokens={itemAlignmentsStackTokens1}>
        {item1}
        {item2}
      </Stack>
    );

    trainingAndGuidesList.push(card);
  }

  return <>{trainingAndGuidesList}</>;
};
