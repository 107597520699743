import React, { useState } from 'react';
import { AADApplicationHelpLabel } from 'utils/helpIconText';
import { LensLabel } from 'utils/lensLabel';
import { IDropdownOption } from '@fluentui/react';
import { ElxActionButton, ElxDropdown } from '@elixir/components';

/**
 * This component is used for selecting applications associated with the workspace.
 * The options contains the list of applications id and selectedAppId contains the one selected by the user.
 *
 * Example:
 * const options = [{
 *     key: appId,
 *     text: appId,
 * }];
 *
 * <ChooseAapId
 *     options={options}
 *     onSelect={onSelectHandler} // call back when a new appId is selected by user.
 *     selectedAppId={appId} // default selected appId.
 * />
 *
 * @param props
 * @param defaultRender
 * @returns
 */

type ChooseAapIdProps = {
  selectedAppId: string;
  options: IDropdownOption[];
  onSelect: (option: string) => void;
  showAadAppForm: () => void;
  showAddAadAppForm: boolean;
};

const ChooseAapId: React.FC<ChooseAapIdProps> = (props) => {
  const [appId, setAppId] = useState(props.selectedAppId as string);

  return (
    <ElxDropdown
      label="AAD Application"
      onRenderLabel={() => (
        <>
          <LensLabel
            labelText="AAD Application"
            hintText={AADApplicationHelpLabel}
            required={true}
          ></LensLabel>
        </>
      )}
      options={props.options}
      selectedKey={appId}
      placeholder="Select an application Id"
      required
      onChange={(_, option) => {
        const key = option?.key as string;
        setAppId(key);
        props.onSelect(key);
      }}
      labelActions={[
        <ElxActionButton
          iconProps={{ iconName: 'Copy' }}
          text="Copy to clipboard"
          disabled={!appId}
          onClick={() => {
            navigator.clipboard.writeText(appId);
          }}
        />,
        <ElxActionButton
          text="Add AAD Application"
          iconProps={{ iconName: 'Add' }}
          disabled={props.showAddAadAppForm}
          onClick={() => {
            props.showAadAppForm();
          }}
        />,
      ]}
    />
  );
};

export default ChooseAapId;
