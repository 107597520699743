import { DataSourceClient } from 'features/dataSources/client/dataSourceClient';
import constants, { $laConstants } from 'utils/constants';

class SharedClient extends DataSourceClient {
  public readonly name = $laConstants.DataSourceTypes.SHARED;
  public readonly title = 'Shared Client';
  public readonly icon = 'fa fa-share';
  public readonly description = 'Shared data source.';

  public readonly orchestration = {};
  public get displayName() {
    return constants.DataSourceClientDisplayNames.SHARED;
  }

  public get dataSourceType() {
    return constants.DataSourceTypes.SHARED;
  }
}

export const sharedClient = new SharedClient();
export default SharedClient;
