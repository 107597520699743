import React from 'react';
import { ISearchBoxStyles, SearchBox } from '@fluentui/react';
import { toggleAside, Aside } from '../../utils/asideUtils';

const searchStyles: ISearchBoxStyles = {
  root: {
    display: 'inline-flex',
    verticalAlign: 'middle',
    marginRight: '16px',
  },
};

export const Discover = (): JSX.Element => {
  return (
    <span
      onMouseDownCapture={(e) => {
        e.preventDefault();
        toggleAside(Aside.DISCOVERY2);
      }}
    >
      <SearchBox
        className="discover-search"
        styles={searchStyles}
        ariaLabel="Search Lens Dashboards, Visualizations, Queries, Jobs or any Data Source"
        title="Search Lens Dashboards, Visualizations, Queries, Jobs or any Data Source"
        placeholder="Search"
      />
    </span>
  );
};

export default Discover;
