import { ElxTextField } from '@elixir/components';
import { LensLabel } from 'utils/lensLabel';
import { SelectorProps } from '../editDataConnectionHelper';

export const ADLSGen2Selector = (props: SelectorProps): JSX.Element => {
  const { state, setState } = props;

  return (
    <ElxTextField
      onRenderLabel={() => (
        <LensLabel
          labelText="Azure Data Lake Storage Gen2 Account Name"
          hintText={'The Azure Data Lake Storage Gen2 Account Name.'}
          required={true}
        ></LensLabel>
      )}
      value={state.account || ''}
      placeholder="MyADLSGen2Account"
      onChange={(e, newValue) => {
        setState({
          ...state,
          account: newValue || '',
        });
      }}
    />
  );
};
