interface NotifierErrorConfig {
  noConsole: boolean;
  noNotification: boolean;
  noTelemetry: boolean;
  noToaster: boolean;
}

class Notifier {
  private leApp: any; // AngularJS dependency - minimize use

  public error(error: string | object | Error, config?: NotifierErrorConfig) {
    this.leApp?.error(
      error,
      null,
      config?.noToaster,
      config?.noNotification,
      config?.noConsole,
      config?.noTelemetry
    );
  }

  public warning(message: string) {
    this.leApp?.warning(message);
  }

  public info(message: string) {
    this.leApp?.info(message);
  }

  public inject(leApp: any) {
    this.leApp = leApp;
  }
}

export const notifier = new Notifier();

export default notifier;
