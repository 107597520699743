import { RestApi } from 'api/restApi';
import constants from 'utils/constants';
import { AAD_CLIENT_ID } from 'utils/sharedLibs';

/**
 * Represents a base class for Lens API (LEApi) clients.
 */
export abstract class LeApi extends RestApi {
  constructor() {
    super(constants.Urls.LEApiBaseUrl, AAD_CLIENT_ID);
  }
}

export default LeApi;
