import { Label, Stack } from '@fluentui/react';
import { Editor } from 'components/monacoEditor';
import {
  entityDetailsLabelStyles,
  entityDetailsStackStyles,
} from 'features/catalog/utils/catalogEntityDetailsStyles';
import { DetailsPageProps } from 'features/catalog/utils/catalogEntityDetailsUtils';

export const EntityDetailsJSON = (props: DetailsPageProps): JSX.Element => {
  const { entity } = props;

  const JSONresponse = entity.originalAPIResponse || 'Unknown';

  return (
    <Stack
      grow
      verticalFill
      {...entityDetailsStackStyles}
      styles={{
        root: {
          margin: '1rem',
          height: '100%',
        },
      }}
    >
      <Stack.Item grow styles={{ root: { height: '100%' } }}>
        <Label {...entityDetailsLabelStyles}>Entity JSON</Label>

        <Editor
          language="json"
          readOnly={true}
          value={JSON.stringify(JSONresponse, null, 4)}
          height={'700px'}
          width={'100%'}
        />
      </Stack.Item>
    </Stack>
  );
};
