import { Route, Switch, Redirect } from 'react-router-dom';
import { HomePage } from './components/homePage';

export const HomeRoute = (): JSX.Element => {
  return (
    <>
      <Switch>
        <Route exact path="/home">
          <Redirect to="/new/home" />
        </Route>

        <Route path="/new/home" component={HomePage} />
      </Switch>
    </>
  );
};

export default HomeRoute;
