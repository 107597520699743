import { useEffect, useState } from 'react';
import { CatalogEntityDetails } from './catalogEntityDetails/catalogEntityDetails';
import CatalogEntityList from './catalogEntityList';
import { useLocation } from 'react-router-dom';

export const DataCatalog = (props: any): JSX.Element => {
  const location = useLocation();

  const [isViewingList, setIsViewingList] = useState(
    location.pathname.includes('/datacatalog/list')
  );

  useEffect(() => {
    if (location.pathname.includes('/datacatalog/list')) {
      setIsViewingList(true);
    } else {
      setIsViewingList(false);
    }
  }, [location]);

  const catalogEntityListProps = {
    isVisible: isViewingList,
  };

  return (
    <>
      <CatalogEntityList {...catalogEntityListProps} />

      {!isViewingList && <CatalogEntityDetails {...props} />}
    </>
  );
};
