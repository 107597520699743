import { Checkbox, IStackStyles, Stack } from '@fluentui/react';
import { AppDispatch } from 'app/lensShellUtility';
import { Card } from 'components/cards/card';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UserPreferences as UserPreferencesModel } from '../models/userPreferences';
import {
  saveUserPreferences,
  selectUserPreferences,
  useUserPreferences,
} from '../userPreferencesSlice';

export const UserPreferences = (): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();
  let userPreferences = useSelector(selectUserPreferences);

  const saveCurrentUserPreferences = (
    newUserPreferences: UserPreferencesModel
  ) => {
    dispatch(saveUserPreferences({ userPreferences: newUserPreferences }));
  };

  // reload user preferences to make sure they are updated
  useUserPreferences();

  const stackStyles: Partial<IStackStyles> = {
    root: {
      marginTop: '15px',
      marginLeft: '15px',
    },
  };

  const cardStyles: Partial<IStackStyles> = {
    root: {
      marginTop: '15px',
      marginLeft: '15px',
      width: '50%',
    },
  };

  return (
    <Card
      title="User Preferences"
      tokens={{ childrenGap: 16 }}
      styles={cardStyles}
    >
      <Stack
        horizontal={false}
        tokens={{ childrenGap: 5 }}
        styles={stackStyles}
      >
        <Checkbox
          label="Disable saved dashboard filters"
          checked={userPreferences.disableSavedDashboardFilters}
          onChange={(event, newValue) => {
            let newUserPreferences: UserPreferencesModel = {
              ...userPreferences,
              disableSavedDashboardFilters: newValue ?? false,
            };
            saveCurrentUserPreferences(newUserPreferences);
          }}
        />
      </Stack>
    </Card>
  );
};
