import {
  IDropdownOption,
  IGroup,
  IGroupDividerProps,
  IObjectWithKey,
  SelectionMode,
  Stack,
} from '@fluentui/react';
import {
  DisplayTypes,
  ElxCard,
  ElxIconButton,
  ElxTableContainer,
  FilterDisplayMode,
  FilterOption,
  FilterOptionMode,
  FilterOptionPillMode,
  IElxColumn,
  IElxContainerProps,
  IPickerItem,
  ITableAction,
} from '@elixir/components';
import { AppDispatch } from 'app/lensShellUtility';
import ErrorMessageBar from 'components/errorMessageBar/errorMessageBar';
import {
  AadConnectionAuthType,
  ConnectionDataSourceType,
  ProjectDataConnection,
} from 'features/workspaces/models/project';
import { WorkspaceBeforeFormat } from 'features/workspaces/models/workspace';
import {
  selectOrchestratorProject,
  selectWorkspace,
  selectWorkspaceId,
} from 'features/workspaces/workspaceSlice';
import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteJob,
  disableJob,
  enableJob,
  createJob,
  getJobs,
  JobListStatus,
  scheduleJob,
  selectAllJobs,
  selectJobListStatus,
} from '../jobSlice';
import {
  Activity,
  Job,
  JobInstanceStatus,
  JobsExim,
  Parameters,
} from '../models/job';
import utils from 'utils/utils';
import { saveAs } from 'file-saver';
import fileOpen from 'components/fileSystem/fileOpen';
import {
  selectAllWorkspaces,
  selectWorkspaceListEntities,
} from 'features/workspaces/workspaceListSlice';
import { JobParameter } from './jobParameter';
import { JobParameterWithBackFill } from './jobParameterWithBackFill';
import { ImportJobs } from './importJobs';
import JobInstanceList from './jobInstanceList';
import { useParams } from 'react-router-dom';
import notifier from 'utils/notifier';
import { Selection } from '@fluentui/react';
import {
  deleteUserFavorites,
  loadUserFavorites,
  saveUserFavorites,
  selectUserFavorites,
  useUserFavorites,
} from '../../userInfo/userSavedObjectDataSlice';
import {
  createUserSavedObjectDataJobs,
  isFavoriteJob,
} from '../../userInfo/utils/userSavedObjectUtils';
import { summaryCard } from '../../../components/cards/summaryCard';
import { colLarge, colMedium, colSmall, tableStyles } from 'utils/sharedStyles';
import { getDataSource } from 'features/orchestrator/utils/jobUtils';
import { GetScmId } from 'components/getScmId';
import CodeView from './codeView';
import { CopyJobToWs } from './copyJobToWs';
import JobEdit from './jobEdit';
import JobAuthor from './jobAuthor';
import MoveJobToFolder from './moveJobToFolder';

// #endregion
// #region styles

// #endregion

const StringOperators = ['==', '!=', 'Contains', '!Contains', 'Startswith'];

export enum DialogType {
  None = 'None',
  ImportFile = 'ImportFile',
  ExportFile = 'ExportFile',
  ImportJobs = 'ImportJobs',
  CopyToWorkspace = 'CopyToWorkspace',
  RunJobsWithParameters = 'RunJobsWithParameters',
  RunBackfillJobsWithParameters = 'RunBackfillJobsWithParameters',
  JobInstanceList = 'JobInstanceList',
  CodeView = 'CodeView',
  EditJob = 'EditJob',
  AddJob = 'AddJob',
  MoveJobToFolder = 'MoveJobToFolder',
}

export interface JobEx extends Job {
  folder?:string;
}

let currId = '';
let workspaceOptions: IDropdownOption[] = [];
let ignoreParams = false;

const defaultParams: Parameters[] = [
  { name: 'startDateTime', value: '' },
  { name: 'endDateTime', value: '' },
];

const renderRunHistory = (item: any, index: any, column: any) => {
  const job: JobEx = item as JobEx;
  const instances = job?.instances
    ?.slice(0)
    .sort((a, b) => (a.startTime > b.startTime ? 1 : 0))
    .slice(0, 3);

  return (
    <div style={{ display: 'inline-flex', width: '100%' }}>
      {instances &&
        instances.map((instance) => {
          switch (instance.status) {
            case JobInstanceStatus.SUCCESS:
              return (
                <ElxIconButton
                  //style={float:'right'}
                  styles={{
                    icon: {
                      color: 'green',
                      fontSize: '10px',
                      margin: '0px 2px',
                    },
                    root: {
                      width: '20%',
                      paddingLeft: '0px',
                      paddingRight: '0px',
                    },
                  }}
                  text={instance.status}
                  alt={instance.status}
                  key={instance.id}
                  iconProps={{ iconName: 'SkypeCircleCheck' }}
                ></ElxIconButton>
              );
            case JobInstanceStatus.FAILED:
              return (
                <ElxIconButton
                  styles={{
                    icon: {
                      color: 'red',
                      fontSize: '10px',
                      margin: '0px 2px',
                    },
                    root: {
                      width: '20%',
                      paddingLeft: '0px',
                      paddingRight: '0px',
                    },
                  }}
                  text={instance.status}
                  alt={instance.status}
                  key={instance.id}
                  iconProps={{ iconName: 'StatusErrorFull' }}
                ></ElxIconButton>
              );
            default:
              return (
                <ElxIconButton
                  styles={{
                    icon: {
                      color: 'grey',
                      fontSize: '10px',
                      margin: '0px 2px',
                    },
                    root: {
                      width: '20%',
                      paddingLeft: '0px',
                      paddingRight: '0px',
                    },
                  }}
                  text={instance.status}
                  alt={instance.status}
                  key={instance.id}
                  iconProps={{ iconName: 'CircleShapeSolid' }}
                ></ElxIconButton>
              );
          }
        })}
      {instances && instances.length === 0 && (
        <ElxIconButton
          styles={{
            icon: {
              color: 'grey',
              fontSize: '10px',
              margin: '0px 2px',
            },
            root: {
              width: '20%',
              paddingLeft: '0px',
              paddingRight: '0px',
            },
          }}
          text={'Job not run'}
          alt={'Job not run'}
          key={`jobnotrun${job.id}`}
          iconProps={{ iconName: 'StatusCircleRing' }}
        ></ElxIconButton>
      )}
    </div>
  );
};

const mergeAuthenticationLabels = (
  prev: string,
  curr: string | null
): string => {
  return !curr ? prev : !prev || prev === curr ? curr : 'Mixed';
};

const describeDataSourceAuthentication = (
  dataSource: ProjectDataConnection | null
) => {
  if (!dataSource) return null;

  if (
    dataSource.type === ConnectionDataSourceType.Cosmos &&
    !dataSource.useAadAuthentication
  ) {
    return dataSource.certificateSecretUri
      ? 'Custom Certificate'
      : 'Lens Certificate';
  }

  if (
    dataSource.type === ConnectionDataSourceType.Sql ||
    dataSource.type === ConnectionDataSourceType.EventHub
  ) {
    if (dataSource.passwordSecretUri) {
      return 'SQL Authentication';
    }
    if (dataSource.connectionSecretUri) {
      return 'Connection String';
    }
    // fall-through (AADApp or User)
  }

  if (dataSource.authType === AadConnectionAuthType.AADApp || 
    dataSource.authType === AadConnectionAuthType.AadAppWithSegmentedMsiFic || 
    dataSource.authType === AadConnectionAuthType.UamiWithSegmentedMsiFic
  ) {
    return (
      'AAD Application or Managed Identity' +
      (dataSource.principalId ? ' (' + dataSource.principalId + ')' : '')
    );
  }

  if (dataSource.authType === AadConnectionAuthType.User) {
    return (
      'User' +
      (dataSource.principalId ? ' (' + dataSource.principalId + ')' : '')
    );
  }

  return null;
};

const sortJobListbasedOnFolder = (jobList: JobEx[]) => {
  let jobListSorted: JobEx[] = [];
  let jobListNotSorted: JobEx[] = [];
  jobList.forEach((job) => {
    if (job.config!.folder) {
      jobListSorted.push(job);
    } else {
      jobListNotSorted.push(job);
    }
  });
  jobListSorted.sort((a, b) => {
    if (a.config!.folder! > b.config!.folder!) {
      return -1;
    }
    if (a.config!.folder! < b.config!.folder!) {
      return 1;
    }
    return a.name.localeCompare(b.name);
  });
  jobListNotSorted.sort((a, b) => {
    return a.name.localeCompare(b.name);
  });
  return jobListSorted.concat(jobListNotSorted);
};

const folderStr = (job:Job):string => {
  let str = '';
  if (job.config?.folder) str += `${job.config.folder} `;
  return str;
}


const JobList: React.FC = () => {
  useUserFavorites();
  const workspaceId = useSelector(selectWorkspaceId);
  const workspaces = useSelector(selectWorkspaceListEntities);
  const workspaceList = useSelector(selectAllWorkspaces);
  const workspace = useSelector(selectWorkspace);
  const project = useSelector(selectOrchestratorProject);
  const dispatch = useDispatch<AppDispatch>();
  const [selectedItems, setSelectedItems] = useState<IObjectWithKey[]>(
    [] as IObjectWithKey[]
  );
  const [dialogType, setDialogType] = useState<DialogType>(DialogType.None);
  const [importJobsValue, setImportJobsValue] = useState<string>('');
  const [parameters, setParameters] = useState<Parameters[]>(defaultParams);
  const [startDateTime, setStartDateTime] = useState<string>(
    new Date().toISOString()
  );
  const [endDateTime, setEndDateTime] = useState<string>(
    new Date().toISOString()
  );
  const [index, setIndex] = useState(0);
  const [uniqNum, setUniqNum] = useState(0);
  const params: { jobId?: string; instanceId?: string } = useParams();

  const userFavorites = useSelector(selectUserFavorites);

  const moreThanOneSelected = selectedItems.length > 1;
  const noneSelected = selectedItems.length === 0;
  const [collasedMap, setCollasedMap] = useState<{ [key: string]: boolean }>({});

  useEffect(() => {
    if (workspaceId === '' || currId === workspaceId) return;
    dispatch(getJobs({ workspaceId: workspaceId }));
    currId = workspaceId;
  }, [workspaceId, dispatch]);

  const jList = useSelector(selectAllJobs);
  const [jobList, setJobList] = useState<JobEx[]>([]);
  const jobListStatus = useSelector(selectJobListStatus);
  const [topFltr, setTopFltr] = useState('Total');
  const [jobListFiltered, setJobListFiltered] = useState<JobEx[]>([]);
  const [searchFiltered, setSearchFiltered] = useState<JobEx[]|null>(null);
  const [taskDlg, setTaskDlg] = useState<{
    callBack: ((taskId: string) => void) | null;
  }>({ callBack: null });
  const [prevScmId, setPrevScmId] = useState<string | null>(null);

  useEffect(() => {
    setJobList(jList.map((job:Job)=>{return {...job,folder:folderStr(job),}}));
  }, [jList]);

  const refresh = () => {
    dispatch(loadUserFavorites({ workspaces: workspaces }));
    dispatch(getJobs({ workspaceId: workspaceId })).then(() => {
      setSelectedItems([]);
      setUniqNum((s) => s + 1);
    });
  };
  const divElxTableContaierRef = useRef(null);

  if (!ignoreParams && jobList.length > 0 && params.jobId) {
    let j: JobEx[] = jobList.filter((o) => o.id === params.jobId);
    if (j.length !== 1) {
      notifier.error(
        `Job Id ${params.jobId} not found in the workspace ${workspace.name}`
      );
    } else {
      setSelectedItems(j);
      setIndex(0);
      setDialogType(DialogType.JobInstanceList);
      ignoreParams = true;
    }
  }
  // #region ActionHandlers

  const runJob = (
    item: JobEx,
    settings: {} | null = null,
    tags: string | null = null,
    scmId: string | null = null
  ) => {
    if (project.secureChangeManagement === 'Enabled' && scmId === null) {
      setTaskDlg({
        callBack: (id: string) => {
          setPrevScmId(id);
          setTaskDlg({ callBack: null });
          dispatch(
            scheduleJob({
              workspaceId: workspaceId,
              jobId: item.id,
              settings: settings,
              params: { tags: tags, scmId: id },
            })
          );
        },
      });
    } else {
      dispatch(
        scheduleJob({
          workspaceId: workspaceId,
          jobId: item.id,
          settings: settings,
          params: { tags: tags, scmId: scmId },
        })
      );
    }
  };
  const setJobEnable = async (
    item: JobEx,
    enable: boolean,
    scmId: string | null = null
  ) => {
    if (project.secureChangeManagement === 'Enabled' && scmId === null) {
      setTaskDlg({
        callBack: (id: string) => {
          setPrevScmId(id);
          setTaskDlg({ callBack: null });
          if (enable) {
            return dispatch(
              enableJob({
                workspaceId: workspaceId,
                jobId: item.id,
                params: { scmId: id },
              })
            );
          } else {
            return dispatch(
              disableJob({
                workspaceId: workspaceId,
                jobId: item.id,
                params: { scmId: id },
              })
            );
          }
        },
      });
    } else {
      if (enable) {
        return dispatch(
          enableJob({
            workspaceId: workspaceId,
            jobId: item.id,
            params: { scmId: scmId },
          })
        );
      } else {
        return dispatch(
          disableJob({
            workspaceId: workspaceId,
            jobId: item.id,
            params: { scmId: scmId },
          })
        );
      }
    }
  };

  const setJobsEnable = (
    items: IObjectWithKey[],
    enable: boolean,
    scmId: string | null = null
  ) => {
    let p: Promise<any>[] = [];
    if (project.secureChangeManagement === 'Enabled' && scmId === null) {
      setTaskDlg({
        callBack: (id: string) => {
          setPrevScmId(id);
          setTaskDlg({ callBack: null });
          (items as JobEx[]).forEach((item) => {
            // don't call setJobEnable if the current value is same as requested.
            if (item.isEnabled !== enable) {
              p.push(setJobEnable(item, enable, id));
            }
          });

          // After all actions are complete, initiate refresh of joblist.
          Promise.all(p).then(() => {
            refresh();
          });
        },
      });
    } else {
      (items as JobEx[]).forEach((item) => {
        // don't call setJobEnable if the current value is same as requested.
        if (item.isEnabled !== enable) {
          p.push(setJobEnable(item, enable, scmId));
        }
      });

      // After all actions are complete, initiate refresh of joblist.
      Promise.all(p).then(() => {
        refresh();
      });
    }
  };

  const enableJobs = (items: IObjectWithKey[]) => {
    if (project.secureChangeManagement === 'Enabled') {
      setTaskDlg({
        callBack: (id: string) => {
          setPrevScmId(id);
          setTaskDlg({ callBack: null });
          setJobsEnable(items, true, id);
        },
      });
    } else {
      setJobsEnable(items, true);
    }
  };
  const disableJobs = (items: IObjectWithKey[]) => {
    if (project.secureChangeManagement === 'Enabled') {
      setTaskDlg({
        callBack: (id: string) => {
          setPrevScmId(id);
          setTaskDlg({ callBack: null });
          setJobsEnable(items, false, id);
        },
      });
    } else {
      setJobsEnable(items, false);
    }
  };

  const importJobs = () => {
    const onChangeHandler = (fileList: FileList) => {
      try {
        const reader = new FileReader();
        reader.onload = async (e) => {
          const importJobsJson = e.target?.result as string;

          if (importJobsJson !== null && importJobsJson !== undefined) {
            setImportJobsValue(importJobsJson);
            setDialogType(DialogType.ImportJobs);
            return;
          }
          setDialogType(DialogType.None);
        };
        if (fileList && fileList.length > 0) {
          reader.readAsText(fileList[0]);
        }
      } catch (err) {
        setDialogType(DialogType.None);
      }
    };
    fileOpen(onChangeHandler);
  };

  const exportJobs = (items: IObjectWithKey[]) => {
    // TODO - Nambi
    // Show UI to so that user can modify json file.
    // Export
    let jobList: JobEx[] = (items as JobEx[]).map((item) => {
      let job: JobEx = JSON.parse(JSON.stringify(item));
      delete job.instances;
      return job;
    });
    let jobExim: JobsExim = { jobs: jobList };
    let content: string = JSON.stringify(jobExim, null, '    ');
    let blob = new Blob([content], { type: 'application/json' });
    let filename =
      items.length > 1
        ? `exportJobs.json`
        : `exportJob_${jobExim.jobs[0].name}.json`;

    saveAs(blob, filename, { autoBom: true });
  };
  const runJobs = (items: IObjectWithKey[]) => {
    if (project.secureChangeManagement === 'Enabled') {
      setTaskDlg({
        callBack: (id: string) => {
          setPrevScmId(id);
          setTaskDlg({ callBack: null });
          (items as JobEx[]).forEach((item) => {
            if (item.isEnabled) {
              runJob(item, null, null, id);
            }
          });
        },
      });
    } else {
      (items as JobEx[]).forEach((item) => {
        if (item.isEnabled) {
          runJob(item);
        }
      });
    }
  };
  const showParametersDlg = (items: JobEx[]) => {
    let localParameters = [...parameters];
    // Nambi - TODO - should it be >== ? take a look at job_action_button.js func:runWithParametersDialog
    if (items.length === 1) {
      items.forEach((job: JobEx) => {
        job.config?.activities?.forEach((activity: Activity) => {
          activity.parameters?.forEach((parameter) => {
            if (
              parameter.name !== 'Lens_Explorer_JobInstanceMap' &&
              !localParameters.find((o) => o.name === parameter.name)
            ) {
              localParameters.push({ ...parameter, ...{ value: '' } });
            }
          });
        });
        job.config?.parameters?.forEach((parameter) => {
          if (!localParameters.find((o) => o.name === parameter.name)) {
            localParameters.push({ ...parameter, ...{ value: '' } });
          }
        });
        project?.parameters?.forEach((parameter) => {
          if (!localParameters.find((o) => o.name === parameter.name)) {
            localParameters.push({ ...parameter, ...{ value: '' } });
          }
        });
      });
    }
    setParameters(localParameters);
    setDialogType(DialogType.RunJobsWithParameters);
  };
  const runJobsWithParameters = (items: JobEx[]) => {
    let settings: any = {};

    if (project.secureChangeManagement === 'Enabled') {
      setTaskDlg({
        callBack: (id: string) => {
          setPrevScmId(id);
          setTaskDlg({ callBack: null });
          parameters.forEach((o) => (settings[o.name] = o.value));
          (items as JobEx[]).forEach((item) => {
            if (item.isEnabled) {
              runJob(item, settings, null, id);
            }
          });
        },
      });
    } else {
      parameters.forEach((o) => (settings[o.name] = o.value));
      (items as JobEx[]).forEach((item) => {
        if (item.isEnabled) {
          runJob(item, settings);
        }
      });
    }
  };
  const showParametersBackfillDlg = (items: JobEx[]) => {
    let localParameters: Parameters[] = [];
    // Nambi - TODO - should it be items.length >== 1? take a look at job_action_button.js func:runWithParametersDialog
    if (items.length === 1) {
      items.forEach((job: JobEx) => {
        job.config?.activities?.forEach((activity: Activity) => {
          activity.parameters?.forEach((parameter) => {
            if (
              parameter.name !== 'Lens_Explorer_JobInstanceMap' &&
              !localParameters.find((o) => o.name === parameter.name)
            ) {
              localParameters.push({ ...parameter, ...{ value: '' } });
            }
          });
        });
        job.config?.parameters?.forEach((parameter) => {
          if (!localParameters.find((o) => o.name === parameter.name)) {
            localParameters.push({ ...parameter, ...{ value: '' } });
          }
        });
        project?.parameters?.forEach((parameter) => {
          if (!localParameters.find((o) => o.name === parameter.name)) {
            localParameters.push({ ...parameter, ...{ value: '' } });
          }
        });
      });
    }
    setParameters(localParameters);
    setDialogType(DialogType.RunBackfillJobsWithParameters);
  };
  const runJobsBackFill = (items: JobEx[]) => {
    // TODO - Nambi - Need to move run_job_with_parameters.js to react. Need to call checkMaxReprocessingAge()..
    const tags = `lens_backfill,lens_backfill_${startDateTime}_${endDateTime}_${new Date().toISOString()}`;
    let settings: any = {};
    if (project.secureChangeManagement === 'Enabled') {
      setTaskDlg({
        callBack: (id: string) => {
          setPrevScmId(id);
          setTaskDlg({ callBack: null });
          parameters.forEach((o) => (settings[o.name] = o.value));
          settings['startDateTime'] = startDateTime;
          settings['endDateTime'] = endDateTime;
          (items as JobEx[]).forEach((item) => {
            if (item.isEnabled) {
              runJob(item, settings, tags, id);
            }
          });
        },
      });
    } else {
      parameters.forEach((o) => (settings[o.name] = o.value));
      settings['startDateTime'] = startDateTime;
      settings['endDateTime'] = endDateTime;
      (items as JobEx[]).forEach((item) => {
        if (item.isEnabled) {
          runJob(item, settings, tags);
        }
      });
    }
  };
  const deleteJobs = (items: IObjectWithKey[]) => {
    // TODO - Nambi
    // Show UI to confirm.
    if (project.secureChangeManagement === 'Enabled') {
      setTaskDlg({
        callBack: (id: string) => {
          setPrevScmId(id);
          setTaskDlg({ callBack: null });
          (items as JobEx[]).forEach((item) => {
            dispatch(
              deleteJob({ workspaceId: workspaceId, jobId: item.id, scmId: id })
            ).then(refresh);
          });
        },
      });
    } else {
      (items as JobEx[]).forEach((item) => {
        dispatch(
          deleteJob({ workspaceId: workspaceId, jobId: item.id, scmId: null })
        ).then(refresh);
      });
    }
  };
  const favoriteJobs = async (items: JobEx[]) => {
    let userSavedObjectDataJobs = createUserSavedObjectDataJobs(items);

    await dispatch(
      saveUserFavorites({ userSavedObjectDataList: userSavedObjectDataJobs })
    );
    dispatch(loadUserFavorites({ workspaces: workspaces }));
  };
  const unFavoriteJobs = async (items: JobEx[]) => {
    let userSavedObjectDataJobs = createUserSavedObjectDataJobs(items);

    await dispatch(
      deleteUserFavorites({ userSavedObjectDataList: userSavedObjectDataJobs })
    );
    dispatch(loadUserFavorites({ workspaces: workspaces }));
  };
  const cloneJobs = (items: IObjectWithKey[]) => {
    if (project.secureChangeManagement === 'Enabled') {
      setTaskDlg({
        callBack: (id: string) => {
          setPrevScmId(id);
          setTaskDlg({ callBack: null });
          (items as JobEx[]).forEach((item) => {
            // Do deep copy of the job.
            let job: JobEx = JSON.parse(JSON.stringify(item));
            job.id = utils.newGuid();
            job.name += ' clone';
            dispatch(
              createJob({ workspaceId: workspaceId, job, scmId: id })
            ).then(refresh);
          });
        },
      });
    } else {
      (items as JobEx[]).forEach((item) => {
        // Do deep copy of the job.
        let job: JobEx = JSON.parse(JSON.stringify(item));
        job.id = utils.newGuid();
        job.name += ' clone';
        dispatch(createJob({ workspaceId: workspaceId, job })).then(refresh);
      });
    }
  };
  const selectWsId = () => {
    workspaceOptions = workspaceList
      .filter((ws: WorkspaceBeforeFormat) => !!ws.dsoProject)
      .map((ws: WorkspaceBeforeFormat) => {
        return { key: ws.id, text: ws.name };
      });
    setDialogType(DialogType.CopyToWorkspace);
  };
  const copyJobsToWs = (jobs: JobEx[], targetWsId: string) => {
    if (targetWsId !== '') {
      if (project.secureChangeManagement === 'Enabled') {
        setTaskDlg({
          callBack: (id: string) => {
            setPrevScmId(id);
            setTaskDlg({ callBack: null });
            (jobs as JobEx[]).forEach((item) => {
              dispatch(
                createJob({
                  workspaceId: targetWsId,
                  job: item,
                  scmId: id,
                })
              )
                .then(() => {
                  notifier.info(
                    `Job ${item.name} copied successfully to the workspace ${targetWsId}.`
                  );
                })
                .catch((error: any) => {
                  notifier.info(
                    `Job ${item.name} copy failed to the workspace ${targetWsId} error : ${error.message}.`
                  );
                });
            });
          },
        });
      } else {
        (jobs as JobEx[]).forEach((item) => {
          dispatch(createJob({ workspaceId: targetWsId, job: item }))
            .then(() => {
              notifier.info(
                `Job ${item.name} copied successfully to workspace ${targetWsId}.`
              );
            })
            .catch((error: any) => {
              notifier.info(
                `Job ${item.name} copy failed to workspace ${targetWsId} error : ${error.message}.`
              );
            });
        });
      }
    }
    setDialogType(DialogType.None);
  };

  const getAuthentication = (item: any) => {
    const job: JobEx = item as JobEx;
    let result = '';

    result =
      job?.config?.activities?.reduce((result: string, activity: Activity) => {
        let input = getDataSource(activity?.input?.connectionName, workspace);
        let output = getDataSource(activity?.output?.connectionName, workspace);
        let inputDescription = describeDataSourceAuthentication(input);
        let outputDescription = describeDataSourceAuthentication(output);
        result = mergeAuthenticationLabels(result, inputDescription);
        result = mergeAuthenticationLabels(result, outputDescription);
        return result;
      }, '') || '';
    return <span>{result}</span>;
  };
  // #endregion ActionHandlers

  const columns: IElxColumn[] = [
    {
      ...colLarge,
      minWidth: 200,
      maxWidth: 360,
      key: 'name',
      name: 'Name',
      fieldName: 'name',
      onRender: (item: JobEx) => {
        return (
          <a
            href={'https://dummy'}
            onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
              e.preventDefault();
              if (selectedItems.indexOf(item) >= 0) {
                setIndex(selectedItems.indexOf(item));
                setDialogType(DialogType.JobInstanceList);
              }
            }}
            onAuxClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
              e.preventDefault();
              if (e.button === 1) {
                if (selectedItems.indexOf(item) >= 0) {
                  setIndex(selectedItems.indexOf(item));
                  setDialogType(DialogType.JobInstanceList);
                }
              }
            }}
          >
            {item.name}
          </a>
        );
      },
    },
    {
      ...colMedium,
      key: 'lastRun',
      name: 'Last Run',
      onRender: (item: JobEx) => {
        const instances = item?.instances || [];

        return (
          <span>{instances.length > 0 ? instances[0].createdDate : ''}</span>
        );
      },
    },
    {
      ...colSmall,
      key: 'runHistory',
      name: 'Run History',
      onRender: renderRunHistory,
    },
    {
      ...colMedium,
      key: 'state',
      name: 'State',
      fieldName: 'isEnabled',
      onRender: (item) => {
        return (
          <span>
            {item.isEnabled
              ? 'Enabled'
              : item.isQuarantined
              ? 'Quarantined'
              : 'Disabled'}
          </span>
        );
      },
    },
    {
      ...colMedium,
      key: 'Quarantine Reason',
      name: 'Quarantine Reason',
      onRender: (item: JobEx) => {
        return <span>{item.quarantineReason || ''}</span>;
      },
    },
    {
      ...colMedium,
      key: 'nextRun',
      name: 'Next Run',
      onRender: (item: JobEx) => {
        return (
          <span>
            {(item.isEnabled && item.nextExecutionDate) || 'None Scheduled'}
          </span>
        );
      },
    },
    {
      ...colLarge,
      minWidth: 300,
      maxWidth: 360,
      key: 'authentication',
      name: 'Authentication',
      onRender: getAuthentication,
    },
    {
      ...colLarge,
      key: 'createdBy',
      name: 'Created By',
      fieldName: 'createdBy',
    },
    {
      ...colLarge,
      minWidth: 150,
      maxWidth: 540,
      key: 'description',
      name: 'Description',
      fieldName: 'description',
    },
    {
      ...colLarge,
      key: 'createdDate',
      name: 'Created Date',
      fieldName: 'createdDate',
    },
    {
      ...colLarge,
      key: 'modifiedBy',
      name: 'Modified By',
      fieldName: 'modifiedBy',
    },
    {
      ...colLarge,
      key: 'modifiedDate',
      name: 'Modified Date',
      fieldName: 'modifiedDate',
    },
    {
      ...colLarge,
      key: 'tags',
      name: 'Tags',
      fieldName: 'tags',
    },
    {
      ...colLarge,
      key: 'alertEmails',
      name: 'Alert Emails',
      onRender: (item: JobEx) => {
        return (
          <span>{item.config?.context?.failureEmailAddresses || 'None'}</span>
        );
      },
    },
    {
      ...colLarge,
      key: 'icmTeam',
      name: 'ICM Team',
      onRender: (item: JobEx) => {
        return <span>{item.config?.icmTeamPublicId || 'None'}</span>;
      },
    },
    {
      ...colLarge,
      key: 'alertSeverity',
      name: 'Alert Severity',
      onRender: (item: JobEx) => {
        return <span>{item.config?.alertSeverity || 'None'}</span>;
      },
    },
    {
      ...colSmall,
      key: 'favorite',
      name: 'Favorite',
      onRender: (item: JobEx) => {
        let isFavorite = isFavoriteJob(item, userFavorites);
        return (
          <ElxIconButton
            text={''}
            styles={{
              root: { marginTop: 0, fontSize: '13px', color: '#ffb900' },
            }}
            iconProps={{
              iconName: isFavorite ? 'FavoriteStarFill' : 'FavoriteStar',
            }}
            onClick={() => {
              isFavorite ? unFavoriteJobs([item]) : favoriteJobs([item]);
            }}
          ></ElxIconButton>
        );
      },
    },
    {
      ...colSmall,
      key:'folder',
      name:'Folder',
      fieldName:'folder',
    }
  ];

  const actions: ITableAction[] = [
    {
      key: 'add',
      text: 'Add',
      defaultDisplay: DisplayTypes.Show,
      iconProps: { iconName: 'Add' },
      disableBulkAction: false,
      onBulkAction: () => {
        setDialogType(DialogType.AddJob);
      },
    },
    {
      key: 'edit',
      text: 'Edit',
      defaultDisplay: DisplayTypes.Show,
      iconProps: { iconName: 'Edit' },
      disabled: noneSelected || moreThanOneSelected,
      disableBulkAction: true,
      onAction: (item) => {
        setDialogType(DialogType.EditJob);
      },
    },
    {
      key: 'codeview',
      text: 'Code View',
      defaultDisplay: DisplayTypes.Show,
      iconProps: { iconName: 'Script' },
      disabled: noneSelected || moreThanOneSelected,
      disableBulkAction: true,
      onAction: (item) => {
        setDialogType(DialogType.CodeView);
      },
    },
    {
      key: 'import',
      text: 'Import',
      defaultDisplay: DisplayTypes.Show,
      iconProps: {
        iconName: 'Import',
      },
      disableBulkAction: false,
      onBulkAction: importJobs,
    },
    {
      key: 'refresh',
      text: 'Refresh',
      defaultDisplay: DisplayTypes.Show,
      iconProps: { iconName: 'Refresh' },
      disableBulkAction: false,
      onBulkAction: refresh,
    },
    {
      key: 'run once',
      text: 'Run once',
      defaultDisplay: DisplayTypes.Show,
      iconProps: { iconName: 'Play' },
      disabled: noneSelected,
      disableBulkAction: false,
      onBulkAction: runJobs,
      onAction: (item) => {
        runJobs([item]);
      },
    },
    {
      key: 'run with parameters',
      text: 'Run with Parameters',
      defaultDisplay: DisplayTypes.Show,
      iconProps: { iconName: 'PlayResume' },
      disabled: noneSelected,
      disableBulkAction: false,
      onBulkAction: showParametersDlg,
      onAction: (item) => {
        showParametersDlg([item]);
      },
    },
    {
      key: 'run backfill',
      text: 'Run Backfill',
      defaultDisplay: DisplayTypes.Show,
      iconProps: { iconName: 'TimeEntry' },
      disabled: noneSelected,
      disableBulkAction: false,
      onBulkAction: showParametersBackfillDlg,
      onAction: (item) => {
        showParametersBackfillDlg([item]);
      },
    },
    {
      key: 'enable',
      text: 'Enable',
      defaultDisplay: DisplayTypes.Show,
      iconProps: {
        iconName: 'ToggleRight',
      },
      disabled: noneSelected,
      onAction: (item) => {
        enableJobs([item]);
      },
      disableBulkAction: false,
      onBulkAction: enableJobs,
    },
    {
      key: 'disable',
      text: 'Disable',
      defaultDisplay: DisplayTypes.Show,
      iconProps: {
        iconName: 'ToggleLeft',
      },
      disabled: noneSelected,
      disableBulkAction: false,
      onBulkAction: disableJobs,
      onAction: (item) => {
        disableJobs([item]);
      },
    },
    {
      key: 'delete',
      text: 'Delete',
      defaultDisplay: DisplayTypes.Show,
      iconProps: {
        iconName: 'Delete',
      },
      disabled: noneSelected,
      disableBulkAction: false,
      onBulkAction: deleteJobs,
      onAction: (item) => {
        deleteJobs([item]);
      },
    },
    {
      key: 'favorite',
      text: 'Favorite',
      defaultDisplay: DisplayTypes.Show,
      iconProps: {
        iconName: 'FavoriteStarFill',
      },
      disableBulkAction: false,
      disabled: noneSelected,
      onBulkAction: favoriteJobs,
      onAction: (item) => {
        favoriteJobs([item]);
      },
    },
    {
      key: 'unfavorite',
      text: 'Unfavorite',
      defaultDisplay: DisplayTypes.Show,
      iconProps: {
        iconName: 'FavoriteStar',
      },
      disableBulkAction: false,
      disabled: noneSelected,
      onBulkAction: unFavoriteJobs,
      onAction: (item) => {
        unFavoriteJobs([item]);
      },
    },
    {
      key: 'copy',
      text: 'Clone',
      defaultDisplay: DisplayTypes.Show,
      iconProps: {
        iconName: 'Copy',
      },
      disableBulkAction: false,
      disabled: noneSelected,
      onBulkAction: cloneJobs,
      onAction: (item) => {
        cloneJobs([item]);
      },
    },
    {
      key: 'copy to workspace',
      text: 'Copy to Workspace',
      defaultDisplay: DisplayTypes.Show,
      iconProps: {
        iconName: 'Copy', // TODO - Nambi - need change
      },
      disableBulkAction: false,
      disabled: noneSelected,
      onBulkAction: selectWsId,
      onAction: (item) => {
        selectWsId();
      },
    },
    {
      key: 'export',
      text: 'Export',
      defaultDisplay: DisplayTypes.Show,
      iconProps: {
        iconName: 'Export',
      },
      disableBulkAction: false,
      disabled: noneSelected,
      onBulkAction: exportJobs,
      onAction: (item) => {
        exportJobs([item]);
      },
    },
    {
      key: 'move job to folder',
      text: 'Move Job to Folder',
      defaultDisplay: DisplayTypes.Show,
      iconProps: {
        iconName: 'groupedList',
      },
      disableBulkAction: false,
      disabled: noneSelected,
      onBulkAction: () => {
        setDialogType(DialogType.MoveJobToFolder);
      },
      onAction: (item) => {
        setDialogType(DialogType.MoveJobToFolder);
      },
    },
    {
      key: 'columns',
      text: 'Columns',
      defaultDisplay: DisplayTypes.Show,
      iconProps: { iconName: 'Settings' },
      disableBulkAction: false,
      onBulkAction: () => {
        let elm = divElxTableContaierRef.current! as HTMLElement;
        let e = elm.querySelector("[data-icon-name='Settings']") as HTMLElement;
        e.click();
      },
      onAction: () => {
        let elm = divElxTableContaierRef.current! as HTMLElement;
        let e = elm.querySelector("[data-icon-name='Settings']") as HTMLElement;
        e.click();
      },
    },
  ];

  const containerProps = {
    isLoading: false,
  } as IElxContainerProps;

  const createdByPillFilter: FilterOption = {
    field: 'createdBy',
    label: 'Created By',
    multiselect: true,
    pillMode: FilterOptionPillMode.Dynamic,
    values: jobList
      .map((job) => job.createdBy)
      .filter((value, index, self) => self.indexOf(value) === index),
  };

  const namePillFilter: FilterOption = {
    field: 'name',
    label: 'Name',
    multiselect: true,
    pillMode: FilterOptionPillMode.Dynamic,
    values: jobList
      .map((job) => job.name)
      .filter((value, index, self) => self.indexOf(value) === index),
  };

  const descriptionPillFilter: FilterOption = {
    field: 'description',
    label: 'Description',
    operators: StringOperators,
    mode: FilterOptionMode.Text,
    pillMode: FilterOptionPillMode.Dynamic,
  };

  const tagsPillFilter: FilterOption = {
    field: 'tags',
    label: 'Tags',
    operators: StringOperators,
    mode: FilterOptionMode.Text,
    pillMode: FilterOptionPillMode.Dynamic,
  };

  const folderPillFilter: FilterOption = {
    field: 'folder',
    label: 'Folder',
    operators: StringOperators,
    mode: FilterOptionMode.Text,
    pillMode: FilterOptionPillMode.Dynamic,
  };

  const searchProps = {
    pillFilters: [
      namePillFilter /**
      permissionsPillFilter
    */,
      descriptionPillFilter,
      createdByPillFilter,
      tagsPillFilter,
      folderPillFilter,
    ],
    filterDisplayMode: FilterDisplayMode.Pill,
  };

  const importJobCancelHandler = () => {
    setDialogType(DialogType.None);
  };
  const importJobsHandler = () => {
    let jobsImportStr = importJobsValue;
    try {
      if (jobsImportStr !== null && jobsImportStr !== undefined) {
        const jobs: JobEx[] = JSON.parse(jobsImportStr).jobs;
        if (project.secureChangeManagement === 'Enabled') {
          setTaskDlg({
            callBack: (id: string) => {
              setPrevScmId(id);
              setTaskDlg({ callBack: null });
              jobs?.forEach((job) =>
                dispatch(
                  createJob({ workspaceId: workspaceId, job, scmId: id })
                ).then(refresh)
              );
            },
          });
        } else {
          jobs?.forEach((job) =>
            dispatch(createJob({ workspaceId: workspaceId, job })).then(refresh)
          );
        }
      }
      setDialogType(DialogType.None);
    } catch (err) {
      setDialogType(DialogType.None);
    }
    setDialogType(DialogType.None);
  };

  const runJobsWithParametersHandler = () => {
    runJobsWithParameters(selectedItems as JobEx[]);
    setDialogType(DialogType.None);
  };
  const cancelParameterDlgHandler = () => {
    setParameters(defaultParams);
    setDialogType(DialogType.None);
  };
  const runJobsBackFillHandler = () => {
    runJobsBackFill(selectedItems as JobEx[]);
    setDialogType(DialogType.None);
  };
  const onRemoveHandler = (idx: number) => {
    setParameters(parameters.filter((_, index) => index !== idx));
  };
  const onAddHandler = () => {
    setParameters([...parameters, { name: '', value: '' }]);
  };
  const onNameChange = (name: string, idx: number) => {
    parameters[idx].name = name;
    setParameters([...parameters]);
  };
  const onValueChange = (newval: string, idx: number) => {
    parameters[idx].value = newval;
    setParameters([...parameters]);
  };
  const cancelCopyJobToWs = () => {
    setDialogType(DialogType.None);
  };

  const cancelJobInstanceDlgHandler = () => {
    setDialogType(DialogType.None);
  };
  const customColumns: IPickerItem[] = columns.map((o) => ({
    key: o.key,
    text: o?.name.length === 0 ? o.key : o.name,
    selected: true,
  }));

  const [customSelectedKeys, setCustomSelectedKeys] = useState<string[]>(
    customColumns.map((o) => o.key)
  );

  const selection = new Selection({
    selectionMode: SelectionMode.multiple,
    onSelectionChanged: () => {
      setSelectedItems(selection.getSelection() as JobEx[]);
    },
  });

  const [summary, setSummary] = useState({
    Total: 0,
    Successful: 0,
    Failed: 0,
    IcmTickets: 0,
    Enabled: 0,
    Disabled: 0,
    LongRunning: 0,
    LowSuccessRate: 0,
    Exceptions: 0,
  });

  const gpJob = searchFiltered ? searchFiltered : jobListFiltered;
  const groups: IGroup[] = Object.values(
    gpJob.reduce(
      (
        acc: {
          [key: string]: {
            count: number;
            key: string;
            name: string;
          };
        },
        obj
      ) => {
        const key: string = obj.config!.folder || '';

        if (!acc[key]) {
          acc[key] = {
            key,
            name: key,
            count: 1,
          };
        } else {
          acc[key].count++;
        }
        return acc;
      },
      {}
    )
  ).map((value) => ({
    key: value.key === '' ? 'No Folder' : value.key,
    name: value.name === '' ? 'No Folder : All Jobs' : `Folder : ${value.name}`,
    startIndex: gpJob.findIndex(
      (j) => (j.config?.folder || '') === value.key
    ),
    count: value.count,
  })).map(gp=>({...gp, isCollapsed: collasedMap[gp.key] || false}));

  const gpSet = new Set<string>();
  jobList.forEach((job) => gpSet.add(job.config?.folder || ''));
  const groupList = Array.from(gpSet);
  const gpSetgpJob = new Set(gpJob.map(job=>job.id));

  useEffect(() => {
    const initSummary = {
      Total: 0,
      Successful: 0,
      Failed: 0,
      Enabled: 0,
      Disabled: 0,
      IcmTickets: 0,
      LongRunning: 0,
      LowSuccessRate: 0,
      Exceptions: 0,
    };
    initSummary.Total = jobList.length;
    jobList.forEach((job) => {
      if (job.isEnabled) {
        initSummary.Enabled = initSummary.Enabled + 1;
      } else {
        initSummary.Disabled = initSummary.Disabled + 1;
      }
      if (job.instances?.length) {
        switch (job.instances[0].status) {
          case JobInstanceStatus.SUCCESS:
            initSummary.Successful = initSummary.Successful + 1;
            break;
          case JobInstanceStatus.FAILED:
            initSummary.Failed = initSummary.Failed + 1;
            break;
          default:
            break;
        }
      }
    });
    setSummary(initSummary);
  }, [jobList]);

  const summaryCards: summaryCard[] = [
    { id: 'Total', cardTitle: 'Total', body: <>{summary.Total} Jobs</> },
    {
      id: 'Successful',
      cardTitle: 'Successful',
      body: <>{summary.Successful} Jobs</>,
    },
    { id: 'Failed', cardTitle: 'Failed', body: <>{summary.Failed} Jobs</> },
    { id: 'Enabled', cardTitle: 'Enabled', body: <>{summary.Enabled} Jobs</> },
    {
      id: 'Disabled',
      cardTitle: 'Disabled',
      body: <>{summary.Disabled} Jobs</>,
    },
  ];

  const cardHandler = (cardid: string) => {
    console.log('In cardHandler: ' + cardid);
    // WA for Elixir not unchecking selection after new set of items is passed.
    let elm = divElxTableContaierRef.current! as HTMLElement;
    let elmArr = elm.querySelectorAll('.ms-DetailsRow-check');
    elmArr.forEach((e) => {
      if (e.getAttribute('aria-checked') === 'true') {
        (e as HTMLElement).click();
      }
    });
    setSelectedItems([] as JobEx[]);
    setTopFltr(cardid);
  };

  useEffect(() => {
    switch (topFltr) {
      case 'Total':
        setJobListFiltered(sortJobListbasedOnFolder(jobList));
        break;
      case 'Successful':
        setJobListFiltered(
          sortJobListbasedOnFolder(
            jobList.filter((job: JobEx) => {
              if (job.instances?.length) {
                return job.instances[0].status === JobInstanceStatus.SUCCESS;
              }
              return false;
            })
          )
        );
        break;
      case 'Failed':
        setJobListFiltered(
          sortJobListbasedOnFolder(
            jobList.filter((job: JobEx) => {
              if (job.instances?.length) {
                return job.instances[0].status === JobInstanceStatus.FAILED;
              }
              return false;
            })
          )
        );
        break;
      case 'Enabled':
        setJobListFiltered(
          sortJobListbasedOnFolder(
            jobList.filter((job: JobEx) => {
              return job.isEnabled === true;
            })
          )
        );
        break;
      case 'Disabled':
        setJobListFiltered(
          sortJobListbasedOnFolder(
            jobList.filter((job: JobEx) => {
              return job.isEnabled === false;
            })
          )
        );
        break;
      default:
        break;
    }
  }, [jobList, topFltr]);

  useEffect(() => {
    // This is a workaround - the <main> element overlays the actual job edit page,
    // making the edit job not clickable, rendering it useless.
    // This should not be the case. May be an issue with the elixir.
    // The main element is part of the ShellMain elixir component.
    // Note - that the add new job, does not have this problem - the the <main> element is not present
    // Not sure why it happens only with the edit job, as both operations are very similar.
    // The work around provided ensures that the <main> element has width of 0px when edit author
    // page is rendered, and set it back to auto when the jobList component is rendered.
    let element = document.querySelector('main');
    if (element) {
      element.style.width = 'auto';
    }
  });

  return (
    <>
      {jobListStatus === JobListStatus.Error && (
        <ErrorMessageBar
          message={'ERROR GETTING JOBS'}
          onDismiss={() => {}}
        ></ErrorMessageBar>
      )}

      <div ref={divElxTableContaierRef}>
        <Stack>
          <Stack.Item styles={{ root: { background: 'white' } }}>
            <Stack
              styles={{
                root: {
                  padding: '20px',
                  fontWeight: 600,
                  fontSize: '1.375rem',
                },
              }}
            >
              Jobs
            </Stack>
          </Stack.Item>
          <Stack.Item grow verticalFill>
            <ElxTableContainer
              containerProps={{
                ...containerProps,
                isLoading: jobListStatus === JobListStatus.Loading,
                styles: {
                  subActions: { height: '0px !important' },

                  headerComponent: { paddingTop: '10px !important' },
                  subHeader: { height: '100%' },
                  header: { height: '110px' },
                  headingContainer: { width: '100%' },
                },
                onRenderHeader: () => {
                  return (
                    <Stack horizontal grow>
                      <Stack.Item>
                        <h3>Jobs - Workspace: {workspace.name} </h3>
                      </Stack.Item>
                      <Stack.Item>
                        {project.secureChangeManagement === 'Enabled' && (
                          <h3>
                            <ElxIconButton
                              iconProps={{ iconName: 'ShieldSolid' }}
                              text={''}
                            />
                          </h3>
                        )}
                      </Stack.Item>
                    </Stack>
                  );
                },
                onRenderSubHeader: () => {
                  return (
                    <Stack horizontal>
                      {summaryCards.map((o) => (
                        <Stack.Item
                          id={o.id}
                          key={o.id}
                          onClick={() => {
                            cardHandler(o.id);
                          }}
                        >
                          <ElxCard
                            id={o.id}
                            key={o.id + o.cardTitle}
                            cardTitle={o.cardTitle}
                            body={o.body}
                            width={100}
                            styles={{
                              root: {
                                marginRight: 16,
                                borderStyle: o.id === topFltr ? 'solid' : '',
                                borderColor: 'lightblue',
                                cursor: 'pointer',
                              },
                              titleContainer: { alignContent: 'center' },
                            }}
                          ></ElxCard>
                        </Stack.Item>
                      ))}
                    </Stack>
                  );
                },
              }}
              tableProps={{
                columns,
                actions,
                selectedItems: selectedItems,
                items: jobListFiltered,
                styles: tableStyles,
                getKey: (item: JobEx) => item.id,
                setKey: topFltr + uniqNum,
                onSelectionChanged: (val: IObjectWithKey[]) => {
                  setSelectedItems(val as JobEx[]);
                },
                groups: groups,
                groupProps: {
                  onRenderHeader: (props?: IGroupDividerProps, defaultRender?:(props?: IGroupDividerProps | undefined)=>JSX.Element | null) => {
                    if (props?.group?.isCollapsed !== (collasedMap[props?.group?.key || ''] || false)) {
                      collasedMap[props?.group?.key || ''] = props?.group?.isCollapsed || false;
                      setCollasedMap({ ...collasedMap });
                    }
                    return defaultRender ? defaultRender(props) : null;
                  },
                },
                onFilterItems: (items:JobEx[]) => {
                  let diff = false;
                  if (gpSetgpJob.size === items.length) {
                    for(let i = 0; i < items.length; i++) {
                        if(!gpSetgpJob.has(items[i].id)) {
                          diff = true;
                          break;
                        }
                    }
                  } else {
                    diff = true;
                  }

                  if (diff) {
                    setSearchFiltered(items);
                  }
                },
                
              }}
              searchBoxProps={searchProps}
              customColumnProps={{
                items: customColumns,
                selectedItemKeys: customSelectedKeys,
                onSave: setCustomSelectedKeys,
              }}            
            ></ElxTableContainer>
          </Stack.Item>
        </Stack>
      </div>

      {dialogType === DialogType.ImportJobs && (
        <ImportJobs
          importJobsValue={importJobsValue}
          setImportJobsValue={setImportJobsValue}
          importJobCancelHandler={importJobCancelHandler}
          importJobsHandler={importJobsHandler}
        ></ImportJobs>
      )}
      {dialogType === DialogType.CopyToWorkspace && (
        <CopyJobToWs
          onCancel={cancelCopyJobToWs}
          onSuccess={copyJobsToWs}
          workspaceList={workspaceOptions}
          jobs={(selectedItems as JobEx[]).map((item) => {
            let job = JSON.parse(JSON.stringify(item));
            delete job.instances;
            return job;
          })} // selectedWs={targetWsId}
          sourceWs={workspace} // headerText={'Copy Jobs to Workspace'}
        ></CopyJobToWs>
      )}
      {dialogType === DialogType.RunJobsWithParameters && (
        <JobParameter
          parameters={parameters}
          onSuccess={runJobsWithParametersHandler}
          onCancel={cancelParameterDlgHandler}
          onAdd={onAddHandler}
          onNameChange={onNameChange}
          onValueChange={onValueChange}
          onRemove={onRemoveHandler}
        ></JobParameter>
      )}
      {dialogType === DialogType.RunBackfillJobsWithParameters && (
        <JobParameterWithBackFill
          parameters={parameters}
          startDateTime={startDateTime}
          onStartDateTimeChange={setStartDateTime}
          endDateTime={endDateTime}
          onEndDateTimeChange={setEndDateTime}
          onCancel={cancelParameterDlgHandler}
          onSuccess={runJobsBackFillHandler}
          onRemove={onRemoveHandler}
          onAdd={onAddHandler}
          onNameChange={onNameChange}
          onValueChange={onValueChange}
        ></JobParameterWithBackFill>
      )}
      {dialogType === DialogType.JobInstanceList && (
        <JobInstanceList
          onCancel={cancelJobInstanceDlgHandler}
          jobs={selectedItems as JobEx[]}
          index={index}
        ></JobInstanceList>
      )}
      {dialogType === DialogType.CodeView && (
        <>
          <CodeView
            job={selectedItems[0] as JobEx}
            workspaceId={workspaceId}
            onCancel={() => {
              setDialogType(DialogType.None);
            }}
            onSuccess={() => {
              refresh();
              setDialogType(DialogType.None);
            }}
          ></CodeView>
        </>
      )}
      {dialogType === DialogType.EditJob && (
        <>
          <JobEdit
            job={selectedItems[0] as JobEx}
            onCancel={() => {
              setDialogType(DialogType.None);
            }}
            onSuccess={() => {
              setDialogType(DialogType.None);
              refresh();
            }}
          ></JobEdit>
        </>
      )}
      {dialogType === DialogType.AddJob && (
        <>
          <JobAuthor
            onCancel={() => {
              setDialogType(DialogType.None);
            }}
            onSuccess={() => {
              setDialogType(DialogType.None);
              refresh();
            }}
          ></JobAuthor>
        </>
      )}
      {dialogType === DialogType.MoveJobToFolder && (
        <>
          <MoveJobToFolder
            jobs={selectedItems as JobEx[]}
            groupList={groupList}
            onCancel={function (): void {
              setDialogType(DialogType.None);
            }}
            onSuccess={function (): void {
              setDialogType(DialogType.None);
              refresh();
            }}
            workspaceId={workspaceId}
          ></MoveJobToFolder>
        </>
      )}
      {taskDlg.callBack && (
        <GetScmId
          actionOnSuccess={taskDlg.callBack}
          onCancel={() => {
            setTaskDlg({ callBack: null });
          }}
          scmId={prevScmId}
        ></GetScmId>
      )}
    </>
  );
};

export default JobList;
