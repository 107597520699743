import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import { RootState, AppDispatch } from 'app/lensShellUtility';
import { notifier } from 'utils/notifier';
import { WorkspaceBeforeFormat, WorkspaceListStatus } from './models/workspace';
import { workspacesApi } from './api/workspacesApi';
import { PagedResults } from 'api/restApi';

export const useAppDispatch = () => useDispatch<AppDispatch>();

/**
 * Custom hook to use the workspace list.
 */
export const useWorkspaceList = () => {
  const dispatch = useDispatch<AppDispatch>();
  const workspaceListStatus = useSelector(selectWorkspaceListStatus);
  useEffect(() => {
    if (workspaceListStatus === WorkspaceListStatus.None) {
      dispatch(loadWorkspaces(undefined));
    }
  }, [dispatch, workspaceListStatus]);
};

export const loadWorkspaces = createAsyncThunk(
  'workspace/loadWorkspaces',
  async (
    continuationToken: string | undefined,
    thunkApi
  ): Promise<PagedResults<WorkspaceBeforeFormat>> => {
    if (!continuationToken) {
      thunkApi.dispatch(resetList());
    }
    const pagedResults = await workspacesApi.getPagedWorkspaceList(
      continuationToken
    );
    if (pagedResults.continuationToken) {
      thunkApi.dispatch(loadWorkspaces(pagedResults.continuationToken));
    }
    return pagedResults;
  }
);

const workspaceListAdapter = createEntityAdapter<WorkspaceBeforeFormat>();

const initialState = {
  workspaceList: workspaceListAdapter.getInitialState({
    status: WorkspaceListStatus.None,
  }),
};

/**
 * Redux slice representing the list of workspaces.
 */
const workspaceListSlice = createSlice({
  name: 'workspaceList',
  initialState,
  reducers: {
    resetList: (state) => {
      workspaceListAdapter.removeAll(state.workspaceList);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadWorkspaces.pending, (state, action) => {
        state.workspaceList.status = WorkspaceListStatus.Loading;
      })
      .addCase(loadWorkspaces.fulfilled, (state, action) => {
        state.workspaceList.status = action.payload.continuationToken
          ? WorkspaceListStatus.Fetching
          : WorkspaceListStatus.Loaded;
        workspaceListAdapter.upsertMany(
          state.workspaceList,
          action.payload.entities
        );
      })
      .addCase(loadWorkspaces.rejected, (state, action) => {
        state.workspaceList.status = WorkspaceListStatus.Error;
        notifier.error(action.error);
      });
  },
});

const { resetList } = workspaceListSlice.actions;
export const {
  selectAll: selectAllWorkspaces,
  selectEntities: selectWorkspaceListEntities,
  selectById: selectWorkspaceById,
  selectIds: selectWorkspaceIds,
} = workspaceListAdapter.getSelectors(
  (state: RootState) => state.workspaceList.workspaceList
);
export const selectWorkspaceListStatus = (state: RootState) =>
  state.workspaceList.workspaceList.status;

export const workspaceListSliceActions = workspaceListSlice.actions;
export default workspaceListSlice.reducer;
