import LeApi from 'api/leApi';
import { WorkspaceApplication } from '../models/workspace';

class WorkspaceApplicationsApi extends LeApi {
  baseUrl = '/v1/workspaces/';
  public addApplication = async (
    workspaceId: string,
    application: WorkspaceApplication
  ): Promise<any> => {
    return await this.postEntity(
      this.baseUrl + workspaceId + '/applications',
      application
    );
  };

  public deleteApplication = async (
    workspaceId: string,
    application: WorkspaceApplication
  ): Promise<any> => {
    return await this.postEntity(
      this.baseUrl + workspaceId + '/applications/delete',
      application
    );
  };
}
export const workspaceApplicationsApi = new WorkspaceApplicationsApi();

export default workspaceApplicationsApi;
