import { DataSourceClient } from 'features/dataSources/client/dataSourceClient';
import _ from 'lodash';
import { $laConstants, orchestratorConstants } from 'utils/constants';

class StorageAccountClient extends DataSourceClient {
  public readonly name = $laConstants.DataSourceTypes.STORAGEACCOUNT;
  public readonly title = 'Azure Storage';
  public readonly icon = 'fa fa-cat';
  public readonly description = 'Azure Storage  data source.';
  public readonly orchestration = {
    dataMovement: {
      endpointTypes: [
        orchestratorConstants.EndpointTypes.AzureTable,
        orchestratorConstants.EndpointTypes.AzureBlob,
      ],
      inputPartitioning: false,
      targetEndpointTypes: [
        orchestratorConstants.EndpointTypes.CosmosStream,
        orchestratorConstants.EndpointTypes.KustoTable,
        orchestratorConstants.EndpointTypes.AzureDataLakeStorageGen2,
      ],
      targetEndpointTypeFinalizer: function (
        inputEndpointType: any,
        currentTargetEndPointTypes: any
      ) {
        if (
          inputEndpointType === orchestratorConstants.EndpointTypes.AzureBlob
        ) {
          return _.remove(currentTargetEndPointTypes, function (d) {
            return !(
              d === orchestratorConstants.EndpointTypes.CosmosStream ||
              d === orchestratorConstants.EndpointTypes.AzureDataLakeStorageGen2
            );
          });
        }
        return currentTargetEndPointTypes;
      },
    },
    endpoint: {
      directive: 'azure-table-job-endpoint',
  },
    dataTransformation: false,
  };
  public get displayName() {
    return $laConstants.DataSourceClientDisplayNames.STORAGEACCOUNT;
  }

  public get dataSourceType() {
    return $laConstants.DataSourceTypes.STORAGEACCOUNT;
  }
}

export const storageAccountClient = new StorageAccountClient();
export default storageAccountClient;
