import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  ElxDropdown,
  ElxLink,
  ElxTextField,
  InputMessageTypes,
} from '@elixir/components';
import { useLensShellTheme } from 'features/shell/lensShellStyles';
import {
  IBasePickerSuggestionsProps,
  IPersonaProps,
  Label,
  NormalPeoplePicker,
  Spinner,
  SpinnerSize,
  ThemeProvider,
  Toggle,
} from '@fluentui/react';
import {
  AadConnectionAuthType,
  AadConnectionAuthTypeDisplayNames,
  ConnectionDataSourceType,
  DataSource,
  connectionTypesWithValidation,
} from 'features/workspaces/models/project';
import {
  Breadcrumb,
  IBreadcrumbItem,
  IDropdownOption,
  MessageBar,
  MessageBarType,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
} from '@fluentui/react';
import {
  Workspace,
  WorkspaceBeforeFormat,
  WorkspaceSaveStatus,
  WorkspaceStatus,
} from 'features/workspaces/models/workspace';
import {
  createDataConnection,
  loadProject,
  saveDataConnection,
  selectAllDataConnections,
  selectEditDataConnectionsStatus,
  selectWorkspaceDataConnectionSaveStatus,
} from 'features/workspaces/workspaceSlice';
import { AppDispatch } from 'app/lensShellUtility';
import { LensLabel } from 'utils/lensLabel';
import {
  AuthenticationModeHelpLabel,
  ConnectionNameHelpLabel,
  DataSourceTypeHelpLabel,
} from 'utils/helpIconText';
import ChooseAapId from 'components/chooseAppId';
import { ValidateButton } from './validateButton';
import {
  breadCrumbStyles,
  panelStyles,
  stackItemFormStyles,
} from '../editWorkspace/workspaceStyles';
import { AadApplicationForm } from '../editWorkspace/workspaceHelperComponents';
import {
  getAllDataSourcePlugins,
  getDataSourcePlugin,
} from 'features/dataSources/registry';
import { v4 as uuidv4 } from 'uuid';
import { getEmail } from 'utils/authUtils';
import {
  alignEnds,
  groupBorder,
} from 'features/orchestrator/components/JobActivities/JobAuthorStyles';
import { getUserGroupApp } from 'utils/graphClient';
import { validateInput } from '../editWorkspace/general/peoplePicker/peoplePickerLogic';
import { PeoplePickerItemSuggestion } from '@fluentui/react/lib/Pickers';
import { selectAllWorkspaces } from 'features/workspaces/workspaceListSlice';
import _ from 'lodash';

const suggestionProps: IBasePickerSuggestionsProps = {
  suggestionsHeaderText: 'Suggested People',
  mostRecentlyUsedHeaderText: 'Suggested Contacts',
  noResultsFoundText: 'No results found',
  loadingText: 'Loading',
  showRemoveButtons: true,
  suggestionsAvailableAlertText: 'People Picker Suggestions available',
  suggestionsContainerAriaLabel: 'Suggested contacts',
};

const dataConnectionsHelpLink =
  'https://eng.ms/docs/products/genevaanalytics/lensexplorer/lensv3/jobauthoringdataconnections';
export interface EditDataConnectionProps {
  workspace: Workspace;
  connectionId?: string;
  newConnectionName: (newDataConnection: string) => void;
  connectionNameList: string[];
  updateSavedConnectionId: (connectionId: string) => void;
  allowedDataSourceTypes?: ConnectionDataSourceType[];
  dataSourceTypeHintText?: string;
  allowedAuthTypes?: AadConnectionAuthType[];
  authTypeHintText?: string;
  panelState?: {
    panelStack: string[];
    setPanelStack: React.Dispatch<React.SetStateAction<string[]>>;
  };
  show: boolean;
  dismissPanel: () => void;
  openParentPanel: () => void;
  closeParentPanel: () => void;
  closeQuickSwitchPanel: () => void;
  refreshConnectionsTable: () => void;
  showShareOnly: boolean;
}

const allAuthModeOptions: IDropdownOption[] = Object.keys(AadConnectionAuthType)
  .filter(
    (key) =>
      key !== AadConnectionAuthType.None &&
      key !== AadConnectionAuthType.ConnectionString
  )
  .map((key) => {
    return {
      key,
      text: AadConnectionAuthTypeDisplayNames[key as AadConnectionAuthType],
    };
  });

export enum ValidateButtonStatus {
  NONE = 'NONE',
  VALID = 'VALID',
  INVALID = 'INVALID',
}
export interface MessageAndType {
  resultMsg: string | JSX.Element;
  type: MessageBarType;
}

const checkConnectionData = (dc: DataSource): boolean => {
  let res = true;
  for (const [, v] of Object.entries(dc)) {
    if (v === undefined || v.length === 0) {
      res = false;
    }
  }
  return res;
};

export const EditDataConnectionPanel = (
  props: EditDataConnectionProps
): JSX.Element => {
  const {
    show,
    workspace,
    connectionId,
    newConnectionName,
    connectionNameList,
    allowedDataSourceTypes,
    dataSourceTypeHintText,
    allowedAuthTypes,
    authTypeHintText,
    showShareOnly,
  } = props;
  const theme = useLensShellTheme();

  const allDataSourcePlugins = useMemo(() => {
    return getAllDataSourcePlugins();
  }, []);

  const allDataSourceTypeOptions: IDropdownOption[] = useMemo(() => {
    return allDataSourcePlugins
      .filter((dsp) => dsp.connectionType !== ConnectionDataSourceType.Shared)
      .map((dsp) => {
        return {
          key: dsp.dataSourceClient.dataSourceType,
          text: dsp.dataSourceClient.displayName,
        };
      });
  }, [allDataSourcePlugins]);

  const [showAddAadApp, setShowAddAadApp] = useState(false);

  const dispatch = useDispatch<AppDispatch>();
  const [isSaving, setIsSaving] = useState(false);
  const editConnectionsStatus = useSelector(selectEditDataConnectionsStatus);

  const workspaceList: WorkspaceBeforeFormat[] =
    useSelector(selectAllWorkspaces);
  const [proj2WsMap, setProj2WsMap] = useState({});
  useEffect(() => {
    setProj2WsMap(
      workspaceList
        .filter((w) => w.dsoProject && w.dsoProject !== '')
        .reduce((p, c) => {
          try {
            Object.defineProperty(p, c.dsoProject, {
              value: { id: c.id, name: c.name, projName: c.dsoProject },
            });
          } catch (err) {
            // ignore
          }
          return p;
        }, {})
    );
  }, [workspaceList]);

  // SWITCH THE BELOW TO USE THE DATA CONNECTION ENTITY ADAPTER
  const editConnections = useSelector(selectAllDataConnections);

  const connection =
    (editConnectionsStatus === WorkspaceStatus.Loaded &&
      editConnections?.find((connection) => connection.id === connectionId)) ||
    undefined;

  const connectionData = connectionId ? connection : undefined;

  const defaultDataSourceType =
    allowedDataSourceTypes && allowedDataSourceTypes.length > 0
      ? allowedDataSourceTypes[0]
      : allDataSourcePlugins[0].dataSourceClient.dataSourceType;

  const defaultAuthType =
    allowedAuthTypes && allowedAuthTypes.length > 0
      ? allowedAuthTypes[0]
      : AadConnectionAuthType.AADApp;

  const defaultState: DataSource = {
    id: uuidv4(),
    name: '',
    type: ConnectionDataSourceType.Kusto,
    authenticationMode: defaultAuthType,
    applicationId: '',
    tenant: '',
  };

  const dataConnectionInitialState: DataSource = useMemo(() => {
    return connectionData ? connectionData : defaultState;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectionData]);

  const [state, setState] = useState<DataSource>(dataConnectionInitialState);
  const [customNameFilled, setCustomNameFilled] = useState<boolean>(false);
  const [enableSharing, setEnableSharing] = useState<boolean>(
    Boolean(state.targetProjectNames)
  );
  const [prevSharingInfo, setPrevSharingInfo] = useState<{
    targetProjectNames: string[];
    allowedPrincipals: any[];
  }>({
    targetProjectNames: state.targetProjectNames || [],
    allowedPrincipals: state.allowedPrincipals || [],
  });
  const [originalSharingStatus] = useState<boolean>(enableSharing);

  const currentDataSourcePlugin = useMemo(() => {
    return getDataSourcePlugin(state.type);
  }, [state.type]);

  let currentName = '';
  if (!connectionId && !customNameFilled) {
    // auto generate name if you are creating a new connection
    currentName = currentDataSourcePlugin.generatedName(state);
  } else {
    // if (connectionId) {
    currentName = state.name;
  }
  let nameConflict = false;
  if (!connectionId) {
    // if there isnt a connection being edited
    nameConflict = connectionNameList.includes(currentName);
  } else {
    nameConflict = connectionNameList.includes(currentName) && customNameFilled;
  }
  useEffect(() => {
    if (!nameConflict) {
      setState({
        ...state,
        name: currentName,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentName, nameConflict, state.type]);

  //Need this to update on mount to show the proper data connection value
  useEffect(() => {
    setState(dataConnectionInitialState);
  }, [dataConnectionInitialState]);

  const savingStatus = useSelector(selectWorkspaceDataConnectionSaveStatus);

  const defaultMessage: MessageAndType = useMemo(() => {
    return !connectionTypesWithValidation.includes(state.type)
      ? {
          resultMsg:
            'This data connection cannot be tested/validated through Lens. We can not guarantee that the connection is valid. To validate, please create a job after you add this data connection.',
          type: MessageBarType.warning,
        }
      : {
          resultMsg: '',
          type: MessageBarType.info,
        };
  }, [state.type]);

  const [errorMessage, setErrorMessage] =
    useState<MessageAndType>(defaultMessage);

  const [dataConnectionStatus, setDataConnectionStatus] =
    useState<ValidateButtonStatus>(ValidateButtonStatus.NONE);

  const dataSourceTypeOptions = allDataSourceTypeOptions.filter(
    (o) =>
      !allowedDataSourceTypes ||
      allowedDataSourceTypes.includes(o.key as ConnectionDataSourceType)
  );

  const authModeOptions = allAuthModeOptions
    .filter(
      (o) =>
        !allowedAuthTypes ||
        allowedAuthTypes.includes(o.key as AadConnectionAuthType)
    )
    .filter((authModeOption) =>
      state.type === ConnectionDataSourceType.Cosmos
        ? authModeOption.key !== AadConnectionAuthType.User
        : true
    );

  let checkConnection = checkConnectionData(state);
  const aadAppOptions: IDropdownOption[] =
    workspace.applications?.map((app) => {
      return {
        key: app.id,
        text: app.id,
      };
    }) || [];

  const buttonStyles = {
    root: { marginRight: 8 },
    content: { background: 'white', padding: '0px' },
    main: { background: 'white' },
  };

  const onRenderFooterContent = () => (
    <Stack horizontal tokens={{ childrenGap: 5 }}>
      <ValidateButton
        dataConnectionStatus={dataConnectionStatus}
        setDataConnectionStatus={(status) => setDataConnectionStatus(status)}
        setErrorMessage={(message) => setErrorMessage(message)}
        checkConnection={checkConnection}
        workspace={workspace}
        defaultMessage={defaultMessage}
        dc={state}
        key={'validate'}
      />
      <PrimaryButton
        onClick={() => {
          setIsSaving(true);
          onSave();
        }}
        disabled={
          state.type === ConnectionDataSourceType.Shared ||
          savingStatus === WorkspaceSaveStatus.Saving ||
          !checkConnection ||
          nameConflict ||
          _.isEqual(state, dataConnectionInitialState)
        }
        onRenderChildren={() => {
          return <>{isSaving && <Spinner size={SpinnerSize.small} />}</>;
        }}
        styles={buttonStyles}
      >
        {connectionId
          ? isSaving
            ? 'Saving connection'
            : 'Save connection'
          : isSaving
          ? 'Adding connection'
          : 'Add connection'}
      </PrimaryButton>
    </Stack>
  );

  function onDismiss() {
    setCustomNameFilled(false);
    setErrorMessage(defaultMessage);
    setState({
      id: uuidv4(),
      name: '',
      type: ConnectionDataSourceType.Kusto,
      authenticationMode: defaultAuthType,
      applicationId: '',
      tenant: '',
    });

    props.closeQuickSwitchPanel();
  }

  async function onSave() {
    setErrorMessage(defaultMessage);

    if (!workspace) {
      return; // won't get here because save button will be disabled.
    }

    try {
      const workspaceId = workspace.id;

      const response = await dispatch(
        connectionId
          ? saveDataConnection({
              workspaceId: workspaceId,
              connectionRequest: state,
            })
          : createDataConnection({
              workspaceId: workspaceId,
              connectionRequest: state,
            })
      ).then(unwrapResult);
      const connection_Id = response.connectionName;

      dispatch(
        //dispatching the reload workspace for the current workspace
        //if we are editing the data connections of the current workspace
        loadProject({
          workspaceId: workspace.id,
          isEditWorkspace: true,
        })
      ).then(() => {
        newConnectionName(connection_Id);
        if (props.updateSavedConnectionId) {
          // tells the data connections table what dataconnection is being edited or added
          props.updateSavedConnectionId(connection_Id);
        }
      });

      setCustomNameFilled(false);
      //close the edit dataconnection panel
      setIsSaving(false);
      props.refreshConnectionsTable();
      props.dismissPanel();
    } catch (error) {
      setIsSaving(false);
      setErrorMessage({
        ...defaultMessage,
        ...{
          resultMsg:
            (error as Error)?.message || (error as string) || 'unknown error',
          type: MessageBarType.error,
        },
      });
    }
  }

  const onDismissError = () => setErrorMessage(defaultMessage);

  const onSelectHandler = (option: string) => {
    const tenant =
      workspace.applications?.find((app) => app.id === option)?.tenant || '';
    setState({
      ...state,
      applicationId: option,

      tenant: tenant,
    });
  };

  useEffect(() => {
    setErrorMessage(defaultMessage);
    setDataConnectionStatus(ValidateButtonStatus.NONE);
  }, [defaultMessage, state, workspace]);

  const items: IBreadcrumbItem[] = [
    {
      text: 'Workspace management',
      key: 'workspaceManagement',
      onClick: () => {
        props.dismissPanel();
        props.closeParentPanel();
      },
    },
    {
      text: workspace.name + ' - Data Connections',
      key: 'DataConnections',
      onClick: () => {
        props.dismissPanel();
      },
    },
    {
      text: showShareOnly
        ? 'Edit sharing config'
        : connectionId
        ? 'Edit data connection'
        : 'Add data connection',
      key: 'connectionAction',
      onClick: () => {},
    },
  ];

  return (
    // ElxPanels are 'outside' of LensShell, so they must have their own ThemeProvider wrapper
    <ThemeProvider theme={theme}>
      <Panel
        type={PanelType.large}
        isOpen={show}
        onDismiss={onDismiss}
        styles={panelStyles}
        isFooterAtBottom={true}
        onRenderHeader={() => {
          return (
            <Breadcrumb
              items={items}
              maxDisplayedItems={4}
              ariaLabel="Workspace data connection breadcrumb with items rendered as buttons"
              overflowAriaLabel="More links"
              styles={breadCrumbStyles}
            />
          );
        }}
        onRenderFooterContent={onRenderFooterContent}
      >
        <Stack tokens={{ padding: '10px 20px 0px', childrenGap: 16 }}>
          {!showShareOnly && (
            <>
              <Stack.Item grow styles={{ root: { paddingLeft: '20px' } }}>
                <span>
                  {'Need additional help? '}
                  <ElxLink
                    style={{ verticalAlign: 'baseline' }}
                    href={dataConnectionsHelpLink}
                    target="_blank"
                  >
                    Learn more about data connections
                  </ElxLink>
                </span>
              </Stack.Item>

              <Stack.Item {...stackItemFormStyles}>
                {errorMessage.resultMsg && (
                  <MessageBar
                    messageBarType={
                      errorMessage.type !== undefined
                        ? errorMessage.type
                        : MessageBarType.error
                    }
                    isMultiline={true}
                    dismissButtonAriaLabel="Close"
                    onDismiss={onDismissError}
                  >
                    {errorMessage.resultMsg}
                  </MessageBar>
                )}
              </Stack.Item>

              <Stack.Item {...stackItemFormStyles}>
                <ElxTextField
                  label="name"
                  onRenderLabel={() => (
                    <>
                      <LensLabel
                        labelText="Name"
                        hintText={ConnectionNameHelpLabel}
                        required={true}
                      ></LensLabel>
                    </>
                  )}
                  value={currentName}
                  placeholder="Enter the data connection name"
                  message={
                    (nameConflict && {
                      type: InputMessageTypes.Error,
                      content: `A data connection named "${currentName}" already exists`,
                    }) ||
                    undefined
                  }
                  required
                  onChange={(_, newValue) => {
                    setState({ ...state, name: newValue || '' });
                    setCustomNameFilled(true);
                  }}
                  disabled={state.type === ConnectionDataSourceType.Shared}
                />
              </Stack.Item>

              <Stack.Item {...stackItemFormStyles}>
                {dataSourceTypeOptions.length === 1 ? (
                  <ElxTextField
                    onRenderLabel={() => (
                      <LensLabel
                        labelText="Data Source type"
                        hintText={
                          dataSourceTypeHintText ||
                          `Only ${dataSourceTypeOptions[0].text} is supported in this context.`
                        }
                      ></LensLabel>
                    )}
                    value={dataSourceTypeOptions[0].text}
                  />
                ) : (
                  <ElxDropdown
                    onRenderLabel={() => (
                      <LensLabel
                        labelText="Data Source type"
                        hintText={
                          dataSourceTypeHintText || DataSourceTypeHelpLabel
                        }
                        required
                      ></LensLabel>
                    )}
                    options={
                      state.type !== ConnectionDataSourceType.Shared
                        ? dataSourceTypeOptions
                        : [
                            {
                              key: ConnectionDataSourceType.Shared,
                              text: ConnectionDataSourceType.Shared,
                            },
                          ]
                    }
                    selectedKey={state.type}
                    onChange={(_, option) => {
                      if (
                        option?.key.toString() ===
                        ConnectionDataSourceType.Shared
                      ) {
                        state.authenticationMode = AadConnectionAuthType.None;
                      }
                      setState({
                        //clear everything except for the most basic fields that are needed
                        //for a data connection
                        id: state.id,
                        name: state.name,
                        authenticationMode: state.authenticationMode,
                        ...(state.applicationId && {
                          applicationId:
                            option?.key.toString() ===
                            ConnectionDataSourceType.Mdm
                              ? getEmail()
                              : state.applicationId,
                        }),
                        type: (option?.key.toString() ||
                          defaultDataSourceType) as ConnectionDataSourceType,
                        ...(state.tenant && { tenant: state.tenant }),
                      });
                    }}
                    disabled={state.type === ConnectionDataSourceType.Shared}
                  />
                )}
              </Stack.Item>

              {state.type !== ConnectionDataSourceType.Shared &&
                state.type !== ConnectionDataSourceType.Mdm && (
                  <Stack.Item {...stackItemFormStyles}>
                    {authModeOptions.length === 1 ? (
                      <ElxTextField
                        onRenderLabel={() => (
                          <LensLabel
                            labelText="Authentication Mode"
                            hintText={
                              authTypeHintText ||
                              `Only ${authModeOptions[0].text} is supported in this context.`
                            }
                          ></LensLabel>
                        )}
                        value={authModeOptions[0].text}
                      />
                    ) : (
                      <ElxDropdown
                        onRenderLabel={() => (
                          <LensLabel
                            labelText="Authentication Mode"
                            hintText={
                              authTypeHintText || AuthenticationModeHelpLabel
                            }
                            required
                          ></LensLabel>
                        )}
                        options={authModeOptions}
                        selectedKey={state.authenticationMode}
                        onChange={(_, option) => {
                          if (option?.key === AadConnectionAuthType.User) {
                            const stateData = { ...state };
                            delete stateData.applicationId;
                            delete stateData.tenant;
                            setState({
                              ...stateData,
                              authenticationMode:
                                (option?.key as AadConnectionAuthType) ||
                                defaultAuthType,
                            });
                          } else {
                            setState({
                              ...state,
                              applicationId: '',
                              tenant: '',
                              authenticationMode:
                                (option?.key as AadConnectionAuthType) ||
                                defaultAuthType,
                            });
                          }
                        }}
                      />
                    )}
                  </Stack.Item>
                )}
              {state.type !== ConnectionDataSourceType.Shared &&
                (state.authenticationMode === AadConnectionAuthType.AADApp ||
                  state.authenticationMode === AadConnectionAuthType.AadAppWithSegmentedMsiFic ||
                  state.authenticationMode === AadConnectionAuthType.UamiWithSegmentedMsiFic) &&
                state.type !== ConnectionDataSourceType.Mdm && (
                  <>
                    <Stack.Item {...stackItemFormStyles}>
                      <ChooseAapId
                        options={aadAppOptions}
                        onSelect={onSelectHandler}
                        selectedAppId={state.applicationId || ''}
                        showAddAadAppForm={showAddAadApp}
                        showAadAppForm={() => {
                          setShowAddAadApp(true);
                        }}
                      ></ChooseAapId>
                    </Stack.Item>
                    {showAddAadApp && (
                      <Stack.Item {...stackItemFormStyles}>
                        <AadApplicationForm
                          showAddAadApp={showAddAadApp}
                          hideAadApplicationForm={() => setShowAddAadApp(false)}
                          workspaceId={workspace.id}
                          setErrorMessage={(messageInfo: MessageAndType) =>
                            setErrorMessage({
                              resultMsg: messageInfo.resultMsg,
                              type: messageInfo.type,
                            })
                          }
                          card={true}
                        />
                      </Stack.Item>
                    )}
                  </>
                )}
              <Stack.Item {...stackItemFormStyles}>
                {currentDataSourcePlugin.dataSourceFormComponent({
                  workspace: workspace,
                  state: state,
                  setState: setState,
                  customNameFilled: customNameFilled,
                  proj2WsMap: proj2WsMap,
                })}
              </Stack.Item>
            </>
          )}
          {state.type !== ConnectionDataSourceType.Shared && (
            <Stack.Item {...stackItemFormStyles}>
              <Stack tokens={{ childrenGap: 8 }}>
                <Stack.Item>
                  <LensLabel
                    labelText="Sharing and Permission Settings"
                    hintText={
                      'Allow this data connection to be used in another workspace'
                    }
                  ></LensLabel>
                </Stack.Item>
                <Stack.Item>
                  <Stack
                    tokens={{ childrenGap: 8, padding: 16 }}
                    className={groupBorder}
                    styles={{
                      root: {
                        borderRadius: 4,
                        boxShadow:
                          '0px 1.6px 3.6px rgba(0, 0, 0, 0.132), 0px 0.3px 0.9px rgba(0, 0, 0, 0.108)',
                      },
                    }}
                  >
                    <Stack.Item>
                      <Label
                        aria-label={'Share Data Connection'}
                        styles={{
                          root: {
                            fontSize: '15px',
                            fontWeight: '600',
                            paddingBottom: 4,
                          },
                        }}
                      >
                        {'Share Data Connection'}
                      </Label>
                      <p>
                        Sharing data connections across other Workspaces in Lens
                        will give permissions to selected principals to use in
                        specified workspaces.
                        <a href="https://eng.ms/docs/products/genevaanalytics/lensexplorer/howtoguides/xfloworchestratorconvergence/credentialsharing">
                          Learn More
                        </a>
                      </p>
                    </Stack.Item>
                    <Stack.Item
                      className={groupBorder}
                      styles={{
                        root: {
                          background: '#FAF9F8',
                          borderRadius: 4,
                          padding: 8,
                        },
                      }}
                    >
                      <Stack horizontal className={alignEnds}>
                        <Stack.Item>
                          <LensLabel
                            labelText={'Enable Sharing'}
                            hintText={
                              'When checked this data connection can be used in an another workspace by some allowed principal (user,group,app).'
                            }
                          ></LensLabel>
                        </Stack.Item>
                        <Stack.Item>
                          <Toggle
                            label=""
                            checked={enableSharing || false}
                            onChange={(
                              e: React.MouseEvent<HTMLElement>,
                              checked?: boolean
                            ) => {
                              if (checked) {
                                setState({
                                  ...state,
                                  allowedPrincipals:
                                    prevSharingInfo.allowedPrincipals,
                                  targetProjectNames:
                                    prevSharingInfo.targetProjectNames,
                                });
                              } else {
                                setPrevSharingInfo({
                                  ...prevSharingInfo,
                                  allowedPrincipals:
                                    state.allowedPrincipals || [],
                                  targetProjectNames:
                                    state.targetProjectNames || [],
                                });
                                const stateData = { ...state };
                                delete stateData.allowedPrincipals;
                                delete stateData.targetProjectNames;
                                setState({
                                  ...stateData,
                                });
                              }
                              setEnableSharing(checked || false);
                            }}
                            onText="On"
                            offText="Off"
                          />
                        </Stack.Item>
                      </Stack>
                      <p>
                        You can always Turn Off sharing or Manage Access
                        Permissions.
                      </p>
                    </Stack.Item>
                    <Stack.Item
                      styles={{
                        root: {
                          height: 38,
                          borderBottom: '1px solid lightgray',
                          borderTop: '1px solid lightgray',
                          alignItems: 'center',
                          display: 'flex',
                        },
                      }}
                    >
                      <span style={{ paddingLeft: 20 }}>
                        <span style={{ fontWeight: 'bold' }}>Status:</span> This
                        data connection is{' '}
                        <span style={{ fontWeight: 'bold' }}>
                          {originalSharingStatus ? '' : 'Not'} Shared
                        </span>
                      </span>
                    </Stack.Item>
                    {enableSharing && (
                      <Stack.Item>
                        <Stack tokens={{ childrenGap: 16, padding: 16 }}>
                          <Stack.Item>
                            <Label
                              aria-label={'Sharing Configuration'}
                              styles={{
                                root: {
                                  fontSize: '14px',
                                  fontWeight: '600',
                                  paddingBottom: 4,
                                },
                              }}
                            >
                              {'Sharing Configuration:'}
                            </Label>
                          </Stack.Item>
                          <Stack.Item>
                            <LensLabel
                              labelText={'Choose Target Workspace(s)'}
                              hintText={
                                'Add/remove workspaces that could be used to access this data connection!'
                              }
                            ></LensLabel>
                            <NormalPeoplePicker
                              onResolveSuggestions={function (
                                filter: string,
                                selectedItems?: IPersonaProps[] | undefined
                              ):
                                | IPersonaProps[]
                                | PromiseLike<IPersonaProps[]> {
                                // throw new Error('Function not implemented.');
                                return workspaceList
                                  .filter(
                                    (w) =>
                                      w.name.startsWith(filter) &&
                                      w.dsoProject &&
                                      w.dsoProject !== ''
                                  )
                                  .map((w) => ({
                                    text: w.name,
                                    secondaryText: w.id,
                                    tertiaryText: w.dsoProject,
                                  }));
                              }}
                              className={'ms-PeoplePicker'}
                              aria-label={'Selected contacts'}
                              defaultSelectedItems={state.targetProjectNames?.map(
                                (p: string) => {
                                  let o;
                                  try {
                                    o = Object.getOwnPropertyDescriptor(
                                      proj2WsMap,
                                      p
                                    )?.value;
                                  } catch (err) {
                                    // error - this lens project may not be having a matching workspace
                                    return {
                                      text: p,
                                      secondaryText: p,
                                      tertiaryText: p,
                                    };
                                  }
                                  return {
                                    text: o?.name || '',
                                    secondaryText: o?.id || '',
                                    tertiaryText: o?.projName || '',
                                  };
                                }
                              )}
                              pickerSuggestionsProps={{
                                ...suggestionProps,
                                showRemoveButtons: false,
                              }}
                              onRemoveSuggestion={(
                                item: IPersonaProps
                              ): void => {
                                let targetProjectNames =
                                  state.targetProjectNames?.filter(
                                    (p) => p !== item.tertiaryText
                                  ) || [];
                                setState({ ...state, targetProjectNames });
                              }}
                              onRenderSuggestionsItem={(
                                props: IPersonaProps,
                                itemProps: IBasePickerSuggestionsProps
                              ): JSX.Element => {
                                return (
                                  <PeoplePickerItemSuggestion
                                    personaProps={{
                                      ...props,
                                      styles: {
                                        root: {
                                          height: 'auto',
                                        },
                                        secondaryText: {
                                          height: 'auto',
                                          whiteSpace: 'normal',
                                        },
                                        primaryText: {
                                          height: 'auto',
                                          whiteSpace: 'normal',
                                        },
                                      },
                                    }}
                                    suggestionsProps={itemProps}
                                  />
                                );
                              }}
                              onValidateInput={validateInput}
                              onChange={(
                                items: IPersonaProps[] | undefined
                              ): void => {
                                let targetProjectNames = items?.map(
                                  (p) => p?.tertiaryText || ''
                                );
                                setState({ ...state, targetProjectNames });
                              }}
                              resolveDelay={300}
                            ></NormalPeoplePicker>
                          </Stack.Item>
                          <Stack.Item>
                            <LensLabel
                              labelText={'Add Allowed Principals'}
                              hintText={
                                'Add users, groups or app that could use this data connection!'
                              }
                            ></LensLabel>
                            <NormalPeoplePicker
                              onResolveSuggestions={(
                                filter: string,
                                selectedItems?: IPersonaProps[] | undefined,
                                limitResults?: number
                              ):
                                | IPersonaProps[]
                                | PromiseLike<IPersonaProps[]> => {
                                if (filter && filter.length >= 3) {
                                  return getUserGroupApp(filter).then(
                                    (data: {
                                      User: any;
                                      Group: any;
                                      App: any;
                                    }) => {
                                      // flattenedData - array of objects with text, secondaryText and tertiaryText
                                      const flattenedData = Object.entries(
                                        data
                                      ).flatMap((data: [string, any]) =>
                                        // data[0] - string - User, Group or App
                                        // data[1] - object contains a property value that's array of objects with displayName, id, appId, userPrincipalName etc.
                                        data[1].value.map((entity: any) => ({
                                          text: entity.displayName,
                                          secondaryText:
                                            data[0] +
                                            ': ' +
                                            (data[0] === 'App'
                                              ? entity.appId
                                              : entity.id),
                                          tertiaryText:
                                            data[0] === 'User'
                                              ? entity.userPrincipalName
                                              : data[0] === 'App'
                                              ? entity.appId
                                              : entity.id,
                                        }))
                                      );
                                      let filteredPersonas: IPersonaProps[] =
                                        flattenedData.filter(
                                          (obj) =>
                                            selectedItems?.findIndex(
                                              (s) =>
                                                s.text === obj.text &&
                                                s.secondaryText ===
                                                  obj.secondaryText &&
                                                s.tertiaryText ===
                                                  obj.tertiaryText
                                            ) === -1
                                        );

                                      filteredPersonas = limitResults
                                        ? filteredPersonas.slice(
                                            0,
                                            limitResults
                                          )
                                        : filteredPersonas;
                                      return filteredPersonas;
                                    }
                                  );
                                } else {
                                  return [];
                                }
                              }}
                              className={'ms-PeoplePicker'}
                              aria-label={'Selected contacts'}
                              defaultSelectedItems={
                                state.allowedPrincipals?.map((p) => ({
                                  text: p.id.split('@')[0],
                                  secondaryText: p.type + ': ' + p.id,
                                  tertiaryText: p.id,
                                })) || []
                              }
                              pickerSuggestionsProps={{
                                ...suggestionProps,
                                showRemoveButtons: false,
                              }}
                              onRemoveSuggestion={(
                                item: IPersonaProps
                              ): void => {
                                let allowedPrincipals =
                                  state.allowedPrincipals?.filter(
                                    (p) => p.id !== item.id
                                  ) || [];
                                setState({ ...state, allowedPrincipals });
                              }}
                              onRenderSuggestionsItem={(
                                props: IPersonaProps,
                                itemProps: IBasePickerSuggestionsProps
                              ): JSX.Element => {
                                return (
                                  <PeoplePickerItemSuggestion
                                    personaProps={{
                                      ...props,
                                      styles: {
                                        root: {
                                          height: 'auto',
                                        },
                                        secondaryText: {
                                          height: 'auto',
                                          whiteSpace: 'normal',
                                        },
                                        primaryText: {
                                          height: 'auto',
                                          whiteSpace: 'normal',
                                        },
                                      },
                                    }}
                                    suggestionsProps={itemProps}
                                  />
                                );
                              }}
                              onValidateInput={validateInput}
                              onChange={(
                                items: IPersonaProps[] | undefined
                              ): void => {
                                let allowedPrincipals =
                                  items?.map((p) => ({
                                    id: p.tertiaryText || '',
                                    type: p.secondaryText?.split(':')[0] || '',
                                  })) || [];
                                setState({ ...state, allowedPrincipals });
                              }}
                              resolveDelay={300}
                            ></NormalPeoplePicker>
                          </Stack.Item>
                          <Stack.Item
                            styles={{
                              root: {
                                minHeight: 38,
                                height: 'fit-content',
                                borderBottom: '1px solid lightgray',
                                borderTop: '1px solid lightgray',
                                alignItems: 'center',
                                display: 'flex',
                              },
                            }}
                          >
                            {!((state.allowedPrincipals?.length || 0) > 0) && (
                              <span style={{ paddingLeft: 20 }}>
                                <span style={{ fontWeight: 'bold' }}>
                                  Sharing With:
                                </span>
                                Information will be displayed once selection was
                                made
                              </span>
                            )}

                            {(state.allowedPrincipals?.length || 0) > 0 && (
                              <span style={{ paddingLeft: 20 }}>
                                <span style={{ fontWeight: 'bold' }}>
                                  Sharing With:
                                </span>
                                `Principals listed in Allowed Principals can use
                                this data connection in the listed target
                                workspaces ex. {state.allowedPrincipals![0].id}{' '}
                                can use data connection from:
                                {Object.getOwnPropertyDescriptor(
                                  proj2WsMap,
                                  state.targetProjectNames![0]
                                )?.value?.name || ''}
                                `
                              </span>
                            )}
                          </Stack.Item>
                          <Stack.Item>
                            <p>
                              Once you click
                              <span
                                style={{
                                  fontWeight: 'bold',
                                  paddingLeft: 4,
                                  paddingRight: 4,
                                }}
                              >
                                Save Changes
                              </span>
                              , the data connection will be saved, shared and
                              available for configured users/group/app to use in
                              the allowed workspaces!
                            </p>
                          </Stack.Item>
                        </Stack>
                      </Stack.Item>
                    )}
                  </Stack>
                </Stack.Item>
              </Stack>
            </Stack.Item>
          )}
          {showShareOnly && state.type === ConnectionDataSourceType.Shared && (
            <p>Cannot Share this Connection!</p>
          )}
        </Stack>
      </Panel>
    </ThemeProvider>
  );
};

export default EditDataConnectionPanel;
