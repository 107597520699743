import { DataSourceClient } from 'features/dataSources/client/dataSourceClient';
import constants, {
  $laConstants,
  orchestratorConstants,
} from 'utils/constants';

class AdlsGen2Client extends DataSourceClient {
  public readonly name = $laConstants.DataSourceTypes.ADLSGEN2;
  public readonly title = 'Azure Data Lake Storage Gen2';
  public readonly icon = 'fa fa-cat';
  public readonly description = 'Adls gen2 data source.';

  public readonly monaco = {
    language: orchestratorConstants.LanguageId.Scopescript,
  };
  public readonly orchestration = {
    dataMovement: {
      endpointTypes: [
        orchestratorConstants.EndpointTypes.AzureDataLakeStorageGen2,
      ],
      inputPartitioning: false,
      targetEndpointTypes: [
        orchestratorConstants.EndpointTypes.KustoTable,
        orchestratorConstants.EndpointTypes.SqlTable,
        orchestratorConstants.EndpointTypes.AzureBlob,
        orchestratorConstants.EndpointTypes.MdmMetric,
      ],
    },
    dataValidation: {
      inputEndpointTypes: [
        orchestratorConstants.EndpointTypes.AzureDataLakeStorageGen2,
      ],
    },
    endpoint: {
      directive: 'adls-gen2-stream-job-endpoint',
  },
  };

  public get displayName() {
    return constants.DataSourceClientDisplayNames.ADLSGEN2;
  }

  public get dataSourceType() {
    return constants.DataSourceTypes.ADLSGEN2;
  }
}

export const adlsGen2Client = new AdlsGen2Client();
export default adlsGen2Client;
