import LeApi from 'api/leApi';
import {
  Modes,
  SavedObjectBlob,
  SavedObjectBlobData,
  Workspace,
  WorkspaceObject,
  WorkspaceObjectBase,
  instanceOfSavedObjectBlob,
} from '../models/workspace';
import constants from 'utils/constants';
import utils from 'utils/utils';
import utilities from 'utils/commonUtilities';
const baseUrl = '/v1/workspaces/';

const getOptions = (options?: any) => {
  var defaultOptions = {
    timeout: constants.DefaultRequestWaitTime,
    foreground: true,
  };
  return { defaultOptions, ...options };
};

const getApiUrl = (shareType: string, alias: string) => {
  var workspaceId = shareType === Modes.Private ? 'private-' + alias : alias;
  return (
    constants.Urls.LEApiBaseUrl +
    '/' +
    constants.LEApiDefaultVersion +
    '/workspaces/' +
    workspaceId
  );
};

const getWorkspaceUrl = (workspace: Workspace) => {
  // workspace = workspaceUtil.getValidWorkspace(workspace);
  return getApiUrl(workspace.shareType, workspace.alias);
};

const getSavedObjectTypeUrl = (
  workspace: Workspace,
  metadata: WorkspaceObject | SavedObjectBlob
) => {
  var workspaceUrl = getWorkspaceUrl(workspace);

  // backcompat - saved objects used to be PascalCase

  var type =
    metadata &&
    (instanceOfSavedObjectBlob(metadata) ? metadata.Type : metadata.type);
  var typePath =
    type === constants.SavedObjectType.Dashboard
      ? 'dashboards'
      : type === constants.SavedObjectType.Visualization
      ? 'visualizations'
      : type === constants.SavedObjectType.Query
      ? 'queries'
      : null;
  if (!typePath) return 'Unsupported saved object type "' + type + '"';

  return workspaceUrl + '/' + typePath;
};

const getSavedObjectUrl = (
  workspace: Workspace,
  metadata: WorkspaceObject | SavedObjectBlob
) => {
  var typeUrl = getSavedObjectTypeUrl(workspace, metadata);

  // backcompat - saved objects used to be PascalCase
  var id =
    metadata &&
    (instanceOfSavedObjectBlob(metadata) ? metadata.Id : metadata.id);
  return typeUrl + '/' + id;
};

interface WorkspaceObjectsPayload {
  objectList: WorkspaceObjectBase[];
  idUpdateList: any[];
}

/**
 * Api for objects stored by the Lens Workspace
 */
class SavedObjectsApi extends LeApi {
  // Saved Objects
  public getWorkspaceSavedObjects = async (
    workspaceId: string
  ): Promise<WorkspaceObject[]> => {
    return await this.getEntity(baseUrl + workspaceId + '/savedobjects');
  };

  public deleteSavedObjects = async (
    workspaceId: string,
    objects: WorkspaceObject[]
  ): Promise<SavedObjectBlobData[]> => {
    return await this.deleteEntity(baseUrl + workspaceId + '/savedObjects', {
      data: objects,
    });
  };

  //Recycled Objects
  public getWorkspaceRecycledObjects = async (
    workspaceId: string
  ): Promise<WorkspaceObject[]> => {
    return await this.getEntity(baseUrl + workspaceId + '/recycledObjects');
  };

  /**
   * restores multiple recycled objects from a workspace
   * @param workspaceId workspace id
   * @param recycledObjects the objects to restore
   * @returns the restored saved objects
   */
  public restoreRecycledObjects = async (
    workspaceId: string,
    recycledObjects: WorkspaceObject[]
  ): Promise<SavedObjectBlobData[]> => {
    return await this.postEntity(
      baseUrl + workspaceId + '/restoreRecycledObjects',
      recycledObjects
    );
  };

  /**
   * Gets a saved object.
   * @param {Workspace} workspace - The workspace.
   * @param {WorkspaceObject} object - The saved object metadata (id and type).
   * @returns {Promise} - A promise that returns the object.
   */
  public getSavedObject = async (
    workspace: Workspace,
    object: WorkspaceObject | SavedObjectBlob
  ): Promise<SavedObjectBlob> => {
    const url = getSavedObjectUrl(workspace, object) + '/blob';
    const options = getOptions();
    return await this.getEntity<SavedObjectBlobData>(url, options).then(
      (resp: SavedObjectBlobData) => {
        // backcompat - saved objects used to be PascalCase
        return resp && utils.pascalCase(resp.blob);
      }
    );
  };

  /**
   * Creates a saved object.
   * @param {Workspace} workspace - The workspace.
   * @param {SavedObjectBlob} savedObject - The saved object.
   * @param {Object} options - The optional HTTP request options.
   * @returns {Promise} - A promise that returns the object.
   */
  public create = async (
    workspace: Workspace,
    savedObject: SavedObjectBlobData,
    options?: Object
  ) => {
    const url = getSavedObjectTypeUrl(workspace, savedObject.blob);

    // backcompat - saved objects used to be PascalCase
    // savedObject = utils.camelCase(savedObject);

    options = getOptions(options);
    return await this.postEntity<any, SavedObjectBlobData>(
      url,
      utils.camelCase(savedObject), // backcompat - saved objects used to be PascalCase
      options
    ).then(function (resp) {
      // backcompat - saved objects used to be PascalCase
      return resp && utils.pascalCase(resp.blob);
    });
  };

  public copyToWorkspace = async (
    sourceWsId: string,
    destWsId: string,
    objects: WorkspaceObjectBase[],
    isSourcePrivate: boolean,
    isDestPrivate: boolean,
    overwriteExisting: boolean
  ) => {
    return await this.putEntity<WorkspaceObjectsPayload>(
      baseUrl + 'copy/from/' + sourceWsId + '/to/' + destWsId,
      { objectList: objects, idUpdateList: [] },
      {
        params: {
          isSourcePrivate: isSourcePrivate,
          isDestPrivate: isDestPrivate,
          overwriteExisting: overwriteExisting,
        },
        // foreground: true,
      }
    );
  };

  /**
   * @name getSavedObjectVersion
   * @description
   * Gets a saved object version blob.
   * @param {Workspace} workspace - The workspace.
   * @param {object} metadata - The saved object version metadata (id, type, and versionId).
   * @returns {Promise} - A promise returning the blob.
   */
  public getSavedObjectVersion = async (
    workspace: Workspace,
    metadata: WorkspaceObject
  ) => {
    var url =
      getSavedObjectUrl(workspace, metadata) +
      '/versions/' +
      metadata.versionId;
    var options = getOptions();
    return this.getEntity<SavedObjectBlobData>(url, options).then((resp) => {
      // backcompat - saved objects used to be PascalCase
      return resp && utilities.pascalCase(resp.blob);
    });
  };
}

export const savedObjectsApi = new SavedObjectsApi();

export default savedObjectsApi;
