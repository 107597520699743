import { Label, Stack } from '@fluentui/react';
import {
  DeleteIconProps,
  ElxActionButton,
  ElxDropdown,
  ElxTextField,
} from '@elixir/components';
import React, { useState } from 'react';
import { WorkspaceHr } from '../../workspaceHelperComponents';
import { wideSpacingStackTokens } from '../../workspaceStyles';
import { dataSourceParamMapping } from 'features/workspaces/models/workspace';
import { orchestratorConstants } from 'utils/constants';

interface DataSourceMappingParamsProps {
  parameters: dataSourceParamMapping[];
  updatedDataSourceParamMapping: (params: dataSourceParamMapping[]) => void;
}

const INPUT_WIDTH = 275;

const Parameter = (props: {
  data: dataSourceParamMapping;
  onChange: (newParam: dataSourceParamMapping) => void;
  index: number;
}) => {
  console.log('parameters.....');
  return (
    <>
      <Stack horizontal tokens={wideSpacingStackTokens}>
        <Stack.Item grow>
          <ElxDropdown
            dropdownWidth={250}
            selectedKey={props.data.dataSourceType}
            onChange={(event, option) => {
              const newParam = {
                ...props.data,
                dataSourceType:
                  option?.text || orchestratorConstants.DataSourceTypes.KUSTO,
              };
              props.onChange(newParam);
            }}
            styles={{ dropdown: { width: INPUT_WIDTH } }}
            options={[
              orchestratorConstants.DataSourceTypes.KUSTO,
              orchestratorConstants.DataSourceTypes.APPLICATIONINSIGHTS,
              orchestratorConstants.DataSourceTypes.LOGANALYTICS,
            ].map((d) => ({ key: d, text: d }))}
          />
        </Stack.Item>
        <Stack.Item grow>
          <ElxTextField
            value={props.data.lowSideValue}
            styles={{ fieldGroup: { width: INPUT_WIDTH, height: 32 } }}
            onChange={(e, value) => {
              const newParam = { ...props.data, lowSideValue: value || '' };
              props.onChange(newParam);
            }}
            placeholder={
              props.data.dataSourceType ===
              orchestratorConstants.DataSourceTypes.KUSTO
                ? 'cluster;database'
                : props.data.dataSourceType ===
                  orchestratorConstants.DataSourceTypes.APPLICATIONINSIGHTS
                ? 'subscriptionId;resourceGroupName;subscriptionName'
                : 'subscriptionId;resourceGroupName;workspaceName'
            }
          />
        </Stack.Item>
        <Stack.Item grow>
          <ElxTextField
            value={props.data.highSideValue}
            styles={{ fieldGroup: { width: INPUT_WIDTH, height: 32 } }}
            onChange={(e, value) => {
              const newParam = { ...props.data, highSideValue: value || '' };
              props.onChange(newParam);
            }}
            placeholder={
              props.data.dataSourceType ===
              orchestratorConstants.DataSourceTypes.KUSTO
                ? 'cluster;database'
                : props.data.dataSourceType ===
                  orchestratorConstants.DataSourceTypes.APPLICATIONINSIGHTS
                ? 'subscriptionId;resourceGroupName;subscriptionName'
                : 'subscriptionId;resourceGroupName;workspaceName'
            }
          />
        </Stack.Item>
      </Stack>
    </>
  );
};

const DataSourceMappingParams = (
  props: DataSourceMappingParamsProps
): JSX.Element => {
  const [parameters, setParameters] = useState(props.parameters);

  console.log('DataSourceMappingParams....');
  return (
    <Stack tokens={{ ...wideSpacingStackTokens, padding: 16 }}>
      {/* Parameter list labels */}
      <Stack.Item grow>
        <Stack horizontal>
          <Stack.Item grow align="start" className="parameter-type-label">
            <Label>{'Type'}</Label>
          </Stack.Item>

          <Stack.Item grow align="start" className="parameter-name-label">
            <Label>{'Low Side'}</Label>
          </Stack.Item>

          <Stack.Item grow align="start" className="parameter-value-label">
            <Label>{'High Side'}</Label>
          </Stack.Item>

          <Stack.Item grow align="start">
            <Label>{''}</Label>
          </Stack.Item>
        </Stack>
        <WorkspaceHr />
      </Stack.Item>

      {/* The parameters list */}
      <Stack.Item grow>
        <Stack tokens={wideSpacingStackTokens}>
          {parameters.map((param, index) => (
            <Stack.Item key={index}>
              <Stack horizontal>
                <Parameter
                  key={index}
                  index={index}
                  data={{
                    lowSideValue: param?.lowSideValue || '',
                    highSideValue: param?.highSideValue || '',
                    dataSourceType: param?.dataSourceType || orchestratorConstants.DataSourceTypes.KUSTO,
                  }}
                  onChange={(newParamVals) => {
                    const newParams = [...parameters];
                    newParams[index] = newParamVals;
                    props.updatedDataSourceParamMapping(newParams);
                    setParameters(newParams);
                  }}
                />
                <ElxActionButton
                  iconProps={{
                    ...DeleteIconProps,
                    'aria-label': 'Delete Parameter',
                  }}
                  onClick={(e) => {
                    const tempParams = [...parameters];
                    tempParams.splice(index, 1);
                    props.updatedDataSourceParamMapping(tempParams);
                    setParameters(tempParams);
                  }}
                  text={''}
                  tooltipProps={{
                    content: 'Delete parameter',
                  }}
                />
              </Stack>
            </Stack.Item>
          ))}
        </Stack>
      </Stack.Item>

      {/* The add Data Source Mapping button */}
      <Stack.Item align="start">
        <ElxActionButton
          iconProps={{
            iconName: 'add',
            title: 'Add Data Source Mapping',
            'aria-label': 'Add Data Source Mapping',
          }}
          text="Add Data Source Mapping"
          onClick={() => {
            const newParams = [
              ...parameters,
              {
                dataSourceType: '',
                lowSideValue: '',
                highSideValue: '',
              },
            ];
            props.updatedDataSourceParamMapping(newParams);
            setParameters(newParams);
          }}
          tooltipProps={{
            content: 'Add Data Source Mapping',
          }}
        />
      </Stack.Item>
    </Stack>
  );
};

export default DataSourceMappingParams;
