import { _ } from 'utils/sharedLibs';
import { $laConstants } from 'utils/constants'
import kustoQueryParser from '../parser/kustoQueryParser';

/**
 * @name KustoRenderService
 * @description 
 * A service to process the render operator in Kusto query
 */
class KustoRenderService {
    /**
     * @name queryContainsRender
     * @description 
     * Test whether the given query text contains render
     * @param {string} queryText - query text to check
     * @returns {boolean} true if query text contains render, fals otherwise
     */
    queryContainsRender(queryText) {
        var result = false;
        var queryToCheck = kustoQueryParser.removeQueryComments(queryText);
        if (queryToCheck) {
            result = queryToCheck.search($laConstants.KustoLanguageRegex.RenderStatement) !== -1;
        }

        return result;
    };

    /**
     * @name getRenderInfo
     * @description 
     * Extract render info from query text
     * @param {string} queryText - query text to process
     * @returns {Object} an object containing render related info about this query text
     */
    getRenderInfo(queryText) {
        var result = {
            containsProperRender: false,
            renderType: null, // the type specified in the "render" query
            visualType: null, // the actual visual type name used in Lens visuals
            reason: null,
            queryWithoutRender: queryText
        };
        var queryComponents = [];
        var renderComponents = [];
        var lastComponent;
        var renderType = null;
        var queryToCheck = kustoQueryParser.removeQueryComments(queryText);

        if (queryToCheck) {
            if (this.queryContainsRender(queryToCheck)) {
                queryComponents = queryToCheck.split($laConstants.KustoSeparator.trim());
                lastComponent = queryComponents[queryComponents.length - 1].trim();

                if (lastComponent.search($laConstants.KustoLanguageRegex.RenderStatement) !== -1) {
                    renderComponents = $laConstants.KustoLanguageRegex.RenderStatement.exec(lastComponent);

                    renderType = renderComponents[$laConstants.RenderComponentIndex.VisualType];

                    if (!_.isNil(renderType)) {
                        if (_.has($laConstants.RenderKeywordsToLensVisualTypesMapping, renderType)) {
                            result.containsProperRender = true;
                            result.renderType = renderType;
                            result.visualType = $laConstants.RenderKeywordsToLensVisualTypesMapping[renderType];
                            result.kind = renderComponents[$laConstants.RenderComponentIndex.Kind] || null;
                        }
                        else {
                            result.reason = '"' + renderType + '"' + $laConstants.KustoRenderMessages.NotSupportedVisualType;
                        }
                    }
                    else {
                        result.reason = $laConstants.KustoRenderMessages.EmptyRender;
                    }

                    result.queryWithoutRender = this.removeRenderFromQuery(result.queryWithoutRender);
                }
                else {
                    result.reason = $laConstants.KustoRenderMessages.RenderNotLastOperator;
                }
            }
        }

        return result;
    };

    /**
     * @name removeRenderFromQuery
     * @description 
     * Remove render from query
     * @param {string} queryText - query text to process
     * @returns {string} a processed query text without render at the end of it
     */
    removeRenderFromQuery(queryText) {
        var result = kustoQueryParser.removeQueryComments(queryText);
        var queryComponents = [];
        var lastComponent;
        var lastPipeIndex = -1;

        if (!_.isNil(result)) {
            queryComponents = result.split($laConstants.KustoSeparator.trim());
            lastComponent = queryComponents[queryComponents.length - 1].trim();

            if (lastComponent.indexOf($laConstants.KustoRenderKeyword) === 0) {
                lastPipeIndex = _.lastIndexOf(result, $laConstants.KustoSeparator.trim());

                result = result.substring(0, lastPipeIndex);
            }
        }

        return result;
    };

    /**
     * @name checkAndProcessRenderInfo
     * @description 
     * Handle 2 cases
     * 1. If visual typs is "timechart", "anomaly chart" or "stackedareachart", check aggs to make sure they have the correct aggs
     * 2. If render has "kind" attribute in it, process that info and add agg to the vis if applicable
     * @param {Object} renderInfo - renderInfo object
     * @param {Array} dataTypes - List of fields
     * @param {vis} vis - visual object
     */
    checkAndProcessRenderInfo(renderInfo, dataTypes, vis) {
        var visState = null;
        var i = 0;
        var timefield = null;
        var newAgg = {};

        if (renderInfo && dataTypes && _.isArray(dataTypes) && vis) {
            if (vis.type.name === $laConstants.RenderKeywordsToLensVisualTypesMapping.areachart ||
                vis.type.name === $laConstants.RenderKeywordsToLensVisualTypesMapping.columnchart ||
                vis.type.name === $laConstants.RenderKeywordsToLensVisualTypesMapping.barchart) {

                vis.addGroupAggregation(dataTypes);

                if (renderInfo.renderType === $laConstants.SupportedRenderTypes.stackedareachart) {
                    vis.params.mode = $laConstants.VisualKindToAreaChartMode.stacked;
                }
                else {
                    // Change stack mode
                    if (vis.type.name === $laConstants.RenderKeywordsToLensVisualTypesMapping.areachart) {
                        vis.params.mode = renderInfo.kind ? ($laConstants.VisualKindToAreaChartMode[renderInfo.kind] || $laConstants.VisualKindToAreaChartMode['default'])
                            : $laConstants.VisualKindToAreaChartMode['default'];
                    }
                    else {
                        vis.params.mode = renderInfo.kind ? ($laConstants.VisualKindToBarChartMode[renderInfo.kind] || $laConstants.VisualKindToBarChartMode['default'])
                            : $laConstants.VisualKindToBarChartMode['default'];
                    }
                }
            }
            else if (renderInfo.renderType === $laConstants.SupportedRenderTypes.timechart || renderInfo.renderType === $laConstants.SupportedRenderTypes.anomalychart) {
                visState = vis.getState();

                timefield = _.find(dataTypes, function (dataType) {
                    return dataType.type === $laConstants.fieldType.dateTime;
                });

                if (timefield) {
                    for (i = 0; i < visState.aggs.length; i++) {
                        if (visState.aggs[i].schema === 'segment' && visState.aggs[i].type !== 'date_histogram') {
                            newAgg = {
                                schema: 'segment',
                                type: 'date_histogram',
                                params: {
                                    field: {
                                        name: timefield.name,
                                        type: timefield.type
                                    },
                                    interval: 'auto'
                                }
                            };

                            visState.aggs[i] = newAgg;
                        }
                    }
                }

                if (renderInfo.renderType === $laConstants.SupportedRenderTypes.anomalychart) {
                    vis.params.enableAnalysis = true;
                }

                vis.setState(visState);
            }
        }
    };
}

export const kustoRenderService = new KustoRenderService();

export default kustoRenderService;