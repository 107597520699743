import { ElxIconButton } from '@elixir/components';
import {
  DocumentCard,
  DocumentCardTitle,
  Icon,
  Label,
  Link,
  Stack,
} from '@fluentui/react';
import { iconProps } from 'app/elxAdapter';
import {
  cardStyles,
  iconStyles,
  labelStyles3,
  linkStyles,
  logoStyles,
  secondaryTitleStyles,
} from './homePageStyles';

export interface TopCardProps {
  key: string;
  mainLabel: string;
  secondaryLabel: string;
  iconName: string;
  iconText: string;
  link: string;
}
export const TopCard = (props: TopCardProps): JSX.Element => {
  const { mainLabel, secondaryLabel, iconName, iconText, link } = props;
  return (
    <>
      <DocumentCard aria-label={secondaryLabel} styles={cardStyles}>
        <Stack horizontal>
          <ElxIconButton
            text=""
            styles={logoStyles}
            iconProps={iconProps(iconName)}
          />
          <Label styles={labelStyles3}>{mainLabel}</Label>
        </Stack>
        <DocumentCardTitle
          styles={secondaryTitleStyles}
          title={secondaryLabel}
          shouldTruncate
          showAsSecondaryTitle
        />
        <Link styles={linkStyles} href={link} target="_blank">
          {iconText} <Icon styles={iconStyles} iconName="ChevronRight" />
        </Link>
      </DocumentCard>
    </>
  );
};
