import { _ } from 'utils/sharedLibs';
import utils from 'utils/utils';
import { $laConstants } from 'utils/constants';

/**
 * Kusto query utilities.
 */

const negateMap: { [op: string]: string } = {
  '==': '<>',
  '<>': '==',
  '>': '<=',
  '>=': '<',
  '<': '>=',
  '<=': '>',
  'isempty()': 'isnotempty()',
  'isnotempty()': 'isempty()',
  'isnull()': 'isnotnull()',
  'isnotnull()': 'isnull()',
  contains: '!contains',
  '!contains': 'contains',
  startswith: '!startswith',
  '!startswith': 'startswith',
  endswith: '!endswith',
  '!endswith': 'endswith',
};

function convertValueForFilter(fieldType: string, value: any) {
  var convertedValue = value;

  if (fieldType === 'String' || fieldType === 'Guid' || fieldType === 'Other') {
    // Other is for '*' field
    convertedValue = '"' + value + '"';
  } else if (fieldType === 'DateTime') {
    convertedValue = "todatetime('" + value + "')";
  }

  return convertedValue;
}

/**
 * @name KustoQueryHelper
 * @description
 * Service that helps write Kusto Queries and perform various transformations on queries.
 */
export class KustoQueryHelper {
  public KustoTableFqnTemplate =
    "cluster('<%=cluster%>').database('<%=database%>').[@'<%=table%>']";

  /**
   * @name setFilterQueryInFilter
   * @description
   * Modifies the query in the filter.
   * @param {Object} filter - Filter to generate the query from.
   */
  public setFilterQueryInFilter(filter: any) {
    if (filter && filter.type) {
      if (filter.type === 'phrase') {
        var operator;
        var fieldType = filter.fieldType;
        var fieldName = filter.key;
        var convertedValue = '';
        var multivalueJunction = ' or ';
        var quoteToUse = '"';

        if (!filter.parent) {
          fieldName = this.escapeName(fieldName);
        } else {
          if (filter.key.indexOf('"') > -1) {
            quoteToUse = "'";
          }

          fieldName =
            this.escapeName(filter.parent) +
            '.[' +
            quoteToUse +
            filter.key +
            quoteToUse +
            ']';
        }

        if (
          $laConstants.CustomFilter.UnaryOperators.indexOf(filter.operator) > -1
        ) {
          filter.operator = filter.meta.negate
            ? negateMap[filter.operator]
            : filter.operator;
          if (filter.operator === $laConstants.CustomFilter.Operators.IsEmpty) {
            filter.query = ' isempty(' + fieldName + ') ';
          } else if (
            filter.operator === $laConstants.CustomFilter.Operators.IsNotEmpty
          ) {
            filter.query = ' isnotempty(' + fieldName + ') ';
          } else if (
            filter.operator === $laConstants.CustomFilter.Operators.IsNull
          ) {
            filter.query = ' isnull(' + fieldName + ') ';
          } else if (
            filter.operator === $laConstants.CustomFilter.Operators.IsNotNull
          ) {
            filter.query = ' isnotnull(' + fieldName + ') ';
          }
          return;
        }

        if (
          filter.value ||
          (typeof (filter.value === 'string') && filter.value === '')
        ) {
          if (_.isUndefined(filter.operator)) {
            operator = $laConstants.FilterOperator.Default;
          } else {
            operator = ' ' + filter.operator + ' ';
          }

          if (filter.meta.negate) {
            if (
              !filter.operator ||
              filter.operator === $laConstants.FilterOperator.Default
            ) {
              operator = $laConstants.FilterOperator.NegateDefault;
            } else {
              operator = ' ' + negateMap[filter.operator] + ' ';
            }
          }

          // Some visualizations create filters with array of values
          if (Array.isArray(filter.value) && filter.value.length > 1) {
            convertedValue =
              fieldName +
              operator +
              convertValueForFilter(fieldType, filter.value[0]);

            if (filter.meta.negate) {
              multivalueJunction = ' and ';
            }

            for (var i = 1; i < filter.value.length; i++) {
              convertedValue +=
                multivalueJunction +
                fieldName +
                operator +
                convertValueForFilter(fieldType, filter.value[i]);
            }

            filter.query = convertedValue;
          } else {
            convertedValue = convertValueForFilter(fieldType, filter.value);

            filter.query = fieldName + operator + convertedValue;
          }
        } else {
          // no value, need to filter on exsistence
          operator = filter.meta.negate ? negateMap[filter.operator] : operator;
          filter.query = operator + '(' + fieldName + ')';
        }
      } else if (filter.type === 'query') {
        if (!_.isUndefined(filter.meta.negate) && filter.meta.negate) {
          if (filter.unmodifiedQuery) {
            filter.query = 'not(' + filter.unmodifiedQuery + ')';
          }
        } else {
          filter.query = filter.unmodifiedQuery;
        }
      }
    }
  }

  /**
   * @name commentText
   * @description
   * Receives unformatted text and adds comment forward slashes to the beginning of each line.
   * @param {String} text - String to escape as comments.
   * @returns {String} Commented text for a Kusto Query.
   */
  public commentText(text: string): string {
    var commentedText = text.replace(/^(.*)/gm, '//$1');
    return commentedText;
  }

  /**
   * @name this.queryLimitStatementAsPrefix
   * @description
   * Adds a query limit statement as a prefix to the query.  Uses "query_take_max_records"
   * @param {Number} configuredLimit The number of rows for the query limit
   * @returns {string} kusto query string with limit applied
   */
  public queryLimitStatementAsPrefix(configuredLimit: number): string {
    return (
      'set query_take_max_records=' +
      $laConstants.QueryLimit(configuredLimit) +
      ';\n'
    );
  }

  /**
   * @name this.queryLimitStatementAsSuffix
   * @description
   * Adds a query limit statement as a suffix to the query.  Uses "limit" statement
   * @param {Number} configuredLimit The number of rows for the query limit
   * @returns {string} kusto query string with limit applied
   */
  public queryLimitStatementAsSuffix(configuredLimit: number): string {
    return (
      $laConstants.KustoSeparator +
      'limit ' +
      $laConstants.QueryLimit(configuredLimit)
    );
  }

  /**
   * @name this.escapeName
   * @description
   * Test whether we need to escape the name in Kusto query
   * @param {string} name - Escapes the name of a string into a valid Kusto string
   * @returns {string} - Valid escaped Kusto string
   */
  public escapeName(name: string): string {
    // TODO: replace all utility.escapeName() calls with this function and move the code over.  Kusto specific code should not be in utilities.
    return utils.escapeName(name);
  }

  /**
   * @name this.unescapeName
   * @description
   * Remove the escaping of a Kusto name.  Will return falsy if there is no valid Kusto name (escaped or not) present
   * @param {string} escapedName - Kusto name, which may optionally be escaped
   * @returns {string} - Valid Kusto name
   */
  public unescapeName(escapedName: string): string | undefined {
    escapedName = escapedName.trim();
    var match =
      escapedName.match(/^([A-Za-z0-9_$ .-]+)$/) ||
      escapedName.match(/^\[\s*@?'([A-Za-z0-9_$ .-]+)'\s*\]$/) ||
      escapedName.match(/^\[\s*@?"([A-Za-z0-9_$ .-]+)"\s*\]$/);
    return (match && match[1].trim()) || undefined;
  }
}

export const kustoQueryHelper = new KustoQueryHelper();

export default kustoQueryHelper;
