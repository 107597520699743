import {
  DisplayTypes,
  ElxTableContainer,
  FilterDisplayMode,
  FilterOption,
  FilterOptionMode,
  FilterOptionPillMode,
  IElxColumn,
  IElxContainerProps,
  ITableAction,
} from '@elixir/components';
import { Link, SelectionMode, Stack } from '@fluentui/react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../app/lensShellUtility';
import { editWorkspace } from '../../workspaces/workspaceSlice';
import {
  loadLinkedGrafanaInstances,
  unlinkGrafanaInstances,
  useLinkedGrafanaInstances,
} from '../grafanaOnboardingSlice';
import {
  GrafanaOnboardingData,
  GrafanaOnboardingDataStatus,
} from '../models/grafanaOnboardingData';
import {
  containerStyles,
  filterItems,
  StringOperators,
  tableStyles,
} from './grafanaInstancesTable';

interface LinkedInstanceListTableProps {
  showAddGrafanaPanel: (show: boolean) => void;
}

export const LinkedInstanceListTable = (
  props: LinkedInstanceListTableProps
): JSX.Element => {
  const { showAddGrafanaPanel } = props;
  const workspace = useSelector(editWorkspace);
  const { lensGrafanaInstanceList, linkedGrafanaInstancesStatus } =
    useLinkedGrafanaInstances(workspace.id);
  const dispatch = useDispatch<AppDispatch>();

  const containerProps = {
    compact: true,
    isLoading:
      linkedGrafanaInstancesStatus === GrafanaOnboardingDataStatus.Loading,
    styles: containerStyles,
  } as IElxContainerProps;

  const namePillFilter: FilterOption = {
    field: 'name',
    label: 'Name',
    operators: StringOperators,
    mode: FilterOptionMode.Text,
    pillMode: FilterOptionPillMode.Static,
  };
  const subscriptionNamePillFilter: FilterOption = {
    field: 'subscriptionName',
    label: 'Subscription Name',
    multiselect: true,
    pillMode: FilterOptionPillMode.Dynamic,
    values: filterItems(
      lensGrafanaInstanceList.map((item) => item.subscriptionName)
    ),
  };
  const subscriptionIdPillFilter: FilterOption = {
    field: 'subscriptionId',
    label: 'Subscription Id',
    multiselect: true,
    pillMode: FilterOptionPillMode.Dynamic,
    values: filterItems(
      lensGrafanaInstanceList.map((item) => item.subscriptionId)
    ),
  };
  const resourceGroupPillFilter: FilterOption = {
    field: 'resourceGroup',
    label: 'Resource Group',
    multiselect: true,
    pillMode: FilterOptionPillMode.Dynamic,
    values: filterItems(
      lensGrafanaInstanceList.map((item) => item.resourceGroupName)
    ),
  };
  const endpointPillFilter: FilterOption = {
    field: 'endpoint',
    label: 'Endpoint',
    multiselect: true,
    pillMode: FilterOptionPillMode.Dynamic,
    values: filterItems(lensGrafanaInstanceList.map((item) => item.endpoint)),
  };
  const locationPillFilter: FilterOption = {
    field: 'location',
    label: 'Location',
    multiselect: true,
    pillMode: FilterOptionPillMode.Dynamic,
    values: filterItems(lensGrafanaInstanceList.map((item) => item.location)),
  };

  const columns: IElxColumn[] = [
    {
      key: 'name',
      name: 'Name',
      minWidth: 140,
      maxWidth: 140,
      fieldName: 'name',
    },
    {
      key: 'endpoint',
      name: 'Endpoint',
      minWidth: 280,
      isMultiline: true,
      fieldName: 'endpoint',
      onRender: (item) => {
        return (
          <Link href={item.endpoint} target="_blank">
            {item.endpoint}
          </Link>
        );
      },
    },
    {
      key: 'subscriptionName',
      name: 'Subscription Name',
      minWidth: 150,
      maxWidth: 150,
      isMultiline: true,
      fieldName: 'subscriptionName',
    },
    {
      key: 'resourceGroupName',
      name: 'Resource Group',
      minWidth: 150,
      maxWidth: 150,
      fieldName: 'resourceGroupName',
    },
    {
      key: 'location',
      name: 'Location',
      minWidth: 130,
      maxWidth: 130,
      fieldName: 'location',
    },
  ];

  const onClickRemove = (items: GrafanaOnboardingData[]) => {
    if (items.length > 0) {
      const unlinkArray = items.map((item) => ({
        id: item.id,
        workspaceId: workspace.id,
        name: item.name,
        subscriptionId: item.subscriptionId,
        subscriptionName: item.subscriptionName,
        resourceGroupName: item.resourceGroupName,
        location: item.location,
        endpoint: item.endpoint,
      }));

      dispatch(
        unlinkGrafanaInstances({ grafanaOnboardingDataList: unlinkArray })
      ).then(() => {
        dispatch(loadLinkedGrafanaInstances({ workspaceId: workspace.id }));
      });
    }
  };

  const searchProps = {
    pillFilters: [
      namePillFilter,
      endpointPillFilter,
      subscriptionNamePillFilter,
      subscriptionIdPillFilter,
      resourceGroupPillFilter,
      locationPillFilter,
    ],
    filterDisplayMode: FilterDisplayMode.Pill,
  };

  const actions: ITableAction[] = [
    {
      key: 'Create New Instance',
      text: 'Create New Instance',
      defaultDisplay: DisplayTypes.Show,
      iconProps: { iconName: 'Add' },
      onAction: () => {
        showAddGrafanaPanel(true);
      },
      onBulkAction: () => {
        showAddGrafanaPanel(true);
      },
    },
    {
      key: 'Unlink',
      text: 'Unlink',
      onBulkAction: (items: GrafanaOnboardingData[]) => {
        onClickRemove(items);
      },
      iconProps: { iconName: 'Cancel' },
    },
  ];

  return (
    <Stack.Item>
      <ElxTableContainer
        containerProps={containerProps}
        tableProps={{
          selectionMode: SelectionMode.multiple,
          columns: columns,
          items: lensGrafanaInstanceList,
          styles: tableStyles,
          actions: actions,
        }}
        searchBoxProps={searchProps}
        onRenderEmpty={() =>
          linkedGrafanaInstancesStatus === GrafanaOnboardingDataStatus.Loaded &&
          lensGrafanaInstanceList.length === 0 ? (
            <Stack styles={{ root: { padding: '5px 60px' } }}>
              <h4>
                {`There are currently no Azure Managed Grafana instances linked to workspace "${workspace.name}"`}
              </h4>
              <p>
                You can find existing Azure Managed Grafana instances from your
                subscriptions under the "Available Instances" tab above, or you
                can create a new instance by clicking the "Create New Instance"
                button.
              </p>
            </Stack>
          ) : (
            <></>
          )
        }
      ></ElxTableContainer>
    </Stack.Item>
  );
};
