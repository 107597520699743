import { LensTelemetryConstants } from 'features/appInsights/appInsightsLibs';
import logger from 'features/appInsights/lensLogger';
import savedObjectsApi from 'features/workspaces/api/savedObjectsApi';
import {
  SavedObjectBlob,
  Workspace,
  WorkspaceObject,
} from 'features/workspaces/models/workspace';
import rison from 'rison';
import { getAlias } from 'utils/authUtils';
import notifier from 'utils/notifier';

/**
 * Exports saved objects.
 * @param workspace workspace
 * @param metadata - The saved object metadata or metadatas (id and type).
 * @returns  A promise that returns the contents to export.
 */
export const exportObjects = (
  workspace: Workspace,
  metadata: WorkspaceObject[] | SavedObjectBlob[]
) => {
  metadata = Array.isArray(metadata) ? metadata : [metadata];
  const requests = metadata.map((d) =>
    savedObjectsApi.getSavedObject(workspace, d)
  );
  logger.event(LensTelemetryConstants.EventNames.SavedObjectActions.Export, {
    workspaceId: workspace.id,
  });
  return Promise.all(requests).then((savedObjects) => {
    const contents = savedObjects.map((savedObject) => {
      return {
        Id: savedObject.Id,
        Title: savedObject.Title,
        Type: savedObject.Type,
        Body: JSON.parse(savedObject.Body),
      };
    });

    return JSON.stringify(contents, null, 2);
  });
};

/**
 * Imports saved objects.
 * @param {Object} workspace - The workspace.
 * @param {String} contents - The exported saved objects to import.
 * @returns {Promise} - A promise that returns the objects.
 */
export const importObjects = (workspace: Workspace, contents: string) => {
  let items;
  try {
    items = JSON.parse(contents);
  } catch (err) {
    notifier.error(err + '');
  }
  items = Array.isArray(items) ? items : [items];

  const requests = items.map((item) => {
    const savedObject = {
      blob: {
        Id: item.Id || item.Title,
        Type: item.Type,
        Title: item.Title,
        // Serialize the body. When the file is downloaded from our backup store, the body is already a string.
        // Body: item.Body instanceof String Object.isString(item.Body)
        Body:
          item.Body instanceof String ? item.Body : JSON.stringify(item.Body),
      },
      blobProperties: {},
    };
    return savedObjectsApi.create(workspace, savedObject);
  });
  logger.event(LensTelemetryConstants.EventNames.SavedObjectActions.Import, {
    workspaceId: workspace.id,
  });
  return Promise.all(requests);
};

/**
 * Generates a rison url to navigate to a parameterized dashboard
 * @param {string} item - A string filter applied against the Dashboard field
 * @returns {string}- rison string specifying the a dashboard filter
 */
export const getEncodedParams = (item: string, workspace: Workspace) => {
  // var shareType = workspaceManager.getMode();
  var workspaceName =
    workspace.shareType === 'shared' || workspace.shareType === 'published'
      ? workspace.alias
      : 'private-' + getAlias();
  var params = {
    filters: [
      {
        k: 'Workspace',
        v: workspaceName,
        t: 's',
        n: false,
      },
      {
        k: 'Dashboard',
        v: item,
        t: 's',
        n: false,
      },
    ],
  };
  var encodedParams = rison.encode(params);
  return encodedParams;
};
