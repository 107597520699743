import { Label, Pivot, PivotItem, Stack } from '@fluentui/react';
import { Card } from '../../../components/cards/card';
import { AppDispatch } from 'app/lensShellUtility';
import { topCardData } from './topCardData';
import { TrainingAndGuidesList } from './trainingAndGuidesList';
import { trainingAndGuidesData } from './trainingAndGuidesData';
import { TopCardList } from './topCardList';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectWorkspaceListEntities,
  selectWorkspaceListStatus,
  useWorkspaceList,
} from 'features/workspaces/workspaceListSlice';
import { useEffect } from 'react';
import { Favorites } from './favorites';
import { Recents } from './recents';
import { switchToWorkspace } from 'features/workspaces/utils/workspaceUtils';
import {
  getSharedConnections,
  loadProject,
  loadWorkspace,
} from 'features/workspaces/workspaceSlice';
import notifier from 'utils/notifier';
import {
  cardStyles2,
  conversationTileClass,
  dividerClass,
  featuresInsightsClass,
  itemAlignmentsStackTokens,
  labelStyles,
  labelStyles1,
  labelStyles2,
  moreStyles,
  stackClass,
} from './homePageStyles';
import {
  selectUserFavorites,
  selectUserRecents,
  selectUserFavoritesStatus,
  selectUserRecentsStatus,
  loadUserFavorites,
  loadUserRecents,
} from '../../userInfo/userSavedObjectDataSlice';
import { sampleData } from './sampleDashboardAndVisualizationData';

export interface favorite {
  id: string;
  name: string;
  isFavorite: boolean;
  workspaceId: string;
  description: string;
  type: string;
  status: string;
  date?: Date;
}

export const HomePage = (): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();

  const userRecents = useSelector(selectUserRecents);
  const userRecentsStatus = useSelector(selectUserRecentsStatus);
  const userFavorites = useSelector(selectUserFavorites);
  const userFavoritesStatus = useSelector(selectUserFavoritesStatus);
  useWorkspaceList();
  const workspaces = useSelector(selectWorkspaceListEntities);
  const workspacesStatus = useSelector(selectWorkspaceListStatus);

  function goToWorkspace(selectedItem: favorite) {
    const workspaceId = selectedItem.workspaceId;
    switchToWorkspace(workspaceId);
    dispatch(loadWorkspace(workspaceId))
      .then(() => {
        dispatch(
          loadProject({ workspaceId: workspaceId, isEditWorkspace: false })
        );
      })
      .then(() => {
        dispatch(getSharedConnections(workspaceId));
      });

    notifier.info(`Workspace switched to ${selectedItem.name}.`);
  }
  function onSelectionChange(selectedItem: favorite) {
    if (selectedItem.type === 'workspace') {
      goToWorkspace(selectedItem);
    } else if (selectedItem.type === 'job') {
      window.location.href =
        window.location.origin +
        `#/job/${selectedItem.id}?_g=(ws:'${selectedItem.workspaceId}')`;
      notifier.info(`Job switched to ${selectedItem.name}.`);
    }
  }

  useEffect(() => {
    if (
      workspacesStatus === 'Loaded' &&
      userRecentsStatus === 'None' &&
      userFavoritesStatus === 'None'
    ) {
      dispatch(loadUserRecents({ workspaces: workspaces }));
      dispatch(loadUserFavorites({ workspaces: workspaces }));
    }
  }, [
    userRecents,
    userFavorites,
    workspaces,
    workspacesStatus,
    userFavoritesStatus,
    userRecentsStatus,
    dispatch,
  ]);

  return (
    <>
      <Stack className={stackClass}>
        <Stack.Item>
          <Label styles={labelStyles}>Welcome to Lens Explorer!</Label>
        </Stack.Item>
        <Stack.Item className={conversationTileClass}>
          <Stack
            horizontal
            tokens={{
              childrenGap: '10',
            }}
          >
            <TopCardList items={topCardData} />
          </Stack>
        </Stack.Item>
        <Stack.Item>
          <Label styles={labelStyles2}>Featured Insights</Label>
        </Stack.Item>
        <Stack.Item className={featuresInsightsClass}>
          <Stack horizontal tokens={{ childrenGap: '20' }}>
            <Card styles={cardStyles2}>
              <Stack
                horizontal
                tokens={itemAlignmentsStackTokens}
                className={dividerClass}
              >
                <Stack.Item styles={moreStyles}>
                  <Label styles={labelStyles1}>Training & guides</Label>
                </Stack.Item>
              </Stack>
              <TrainingAndGuidesList items={trainingAndGuidesData} />
            </Card>

            <Card styles={cardStyles2}>
              <Stack
                horizontal
                tokens={itemAlignmentsStackTokens}
                className={dividerClass}
              >
                <Stack.Item styles={moreStyles}>
                  <Label styles={labelStyles1}>
                    Sample Dashboards and Visualizations
                  </Label>
                </Stack.Item>
              </Stack>
              <TrainingAndGuidesList items={sampleData} />
            </Card>
          </Stack>
        </Stack.Item>
        <Stack.Item className={conversationTileClass} grow>
          <Pivot aria-label="Tabs">
            <PivotItem headerText="Favorites" data-is-scrollable>
              <Favorites
                tableMap={userFavorites}
                onSelectionChange={onSelectionChange}
              />
            </PivotItem>
            <PivotItem headerText="Recents" data-is-scrollable>
              <Recents
                tableMap={userRecents}
                onSelectionChange={onSelectionChange}
              />
            </PivotItem>
          </Pivot>
        </Stack.Item>
      </Stack>
    </>
  );
};
