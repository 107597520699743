import {
  Breadcrumb,
  Checkbox,
  IBreadcrumbItem,
  MessageBar,
  MessageBarType,
  Panel,
  PanelType,
  Separator,
  Stack,
} from '@fluentui/react';
import { useCallback, useState } from 'react';
import { MessageAndType } from '../../workspaces/components/editDataConnection/editDataConnectionPanel';
import { panelStyles } from '../../workspaces/components/editWorkspace/workspaceStyles';
import { SubscriptionPicker, SubscriptionInfo } from './subscriptionPicker';
import { ResourceGroupPicker } from './resourceGroupPicker';
import { RegionPicker } from './regionPicker';
import {
  ElxPrimaryButton,
  ElxSecondaryButton,
  ElxTextField,
} from '@elixir/components';
import { LensLabel } from 'utils/lensLabel';
import { useLensShellTheme } from 'features/shell/lensShellStyles';
import {
  resetUsersGrafanaInstances,
  useUsersGrafanaInstances,
} from '../grafanaOnboardingSlice';
import { GrafanaOnboardingDataStatus } from '../models/grafanaOnboardingData';
import SpinnerOverlay from 'components/spinnerOverlay/spinnerOverlay';
import { useDispatch, useSelector } from 'react-redux';
import { editWorkspace } from 'features/workspaces/workspaceSlice';
import { AppDispatch } from 'app/lensShellUtility';
import logger from 'features/appInsights/lensLogger';
import { LensTelemetryConstants } from 'features/appInsights/appInsightsLibs';
import armClient from 'utils/armClient';
import notifier from 'utils/notifier';

const stackStyles = {
  root: {
    width: '50%',
    padding: '30px',
  },
};

const buttonStyles = {
  root: {
    width: '50px',
  },
};

const IndentShape = ({ color }: { color: string }) => (
  <Stack.Item
    styles={{
      root: {
        width: '75px',
        height: '35px',
        margin: '10px 20px',
        borderLeft: `1px solid ${color}`,
        borderBottom: `1px solid ${color}`,
      },
    }}
  >
    &nbsp;
  </Stack.Item>
);

interface CreateGrafanaInstancePanelProps {
  show: boolean;
  parentBreadcrumb: IBreadcrumbItem[];
  dismissPanel: () => void;
  onNewInstanceCreated: (
    id: string,
    subscriptionInfo: SubscriptionInfo,
    name: string,
    resourceGroupName: string,
    location: string,
    endpoint: string,
    linkAfterCreate: boolean
  ) => void;
}

const CreateGrafanaInstancePanel = (props: CreateGrafanaInstancePanelProps) => {
  const { show, parentBreadcrumb, dismissPanel, onNewInstanceCreated } = props;
  const theme = useLensShellTheme();
  const workspace = useSelector(editWorkspace);
  const { usersGrafanaInstancesList, usersGrafanaInstancesStatus } =
    useUsersGrafanaInstances();
  const dispatch = useDispatch<AppDispatch>();
  const [subscriptionInfo, setSubscriptionInfo] = useState<SubscriptionInfo>({
    subscriptionId: '',
    subscriptionName: '',
  });
  const [resourceGroup, setResourceGroup] = useState<string>('');
  const [region, setRegion] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [linkAfterCreate, setLinkAfterCreate] = useState<boolean>(true);

  const existingGrafanaInstance = usersGrafanaInstancesList.find(
    (grafanaInstance) =>
      grafanaInstance.name === name &&
      grafanaInstance.resourceGroupName.toUpperCase() ===
        resourceGroup.toUpperCase() && // Grafana stores resource group names in all lowercase
      grafanaInstance.subscriptionId === subscriptionInfo.subscriptionId
  );
  const existsError = existingGrafanaInstance
    ? `A Grafana instance with name '${name}' already exists in this subscription and resource group.`
    : '';

  const [errorMessage, setErrorMessage] = useState<MessageAndType>({
    resultMsg: '',
    type: MessageBarType.info,
  });

  const onDismissError = () =>
    setErrorMessage({
      resultMsg: '',
      type: MessageBarType.info,
    });

  const dismiss = useCallback(() => {
    setName('');
    // keep subscription, resourceGroup, region and linkAfterCreate unchanged for next invocation
    setIsCreating(false);
    onDismissError();
    dispatch(resetUsersGrafanaInstances());
    dismissPanel();
  }, [dismissPanel, dispatch]);

  const onClick = useCallback(() => {
    if (subscriptionInfo.subscriptionId && resourceGroup && region && name) {
      setIsCreating(true);
      armClient
        .createGrafanaInstance(
          subscriptionInfo.subscriptionId,
          resourceGroup,
          name,
          region
        )
        .then((result) => {
          logger.event(
            LensTelemetryConstants.EventNames.WorkspaceActions
              .CreateGrafanaInstance,
            {
              id: result.id,
              name: result.name,
              subscription: result.subscriptionId,
              resourceGroup: result.resourceGroup,
              location: result.location,
              endpoint: result.properties.endpoint,
              provisioningState: result.properties.provisioningState,
              editWorkspaceId: workspace.id,
            }
          );
          if (
            result.properties.provisioningState === 'Succeeded' ||
            result.properties.provisioningState === 'Accepted'
          ) {
            onNewInstanceCreated(
              result.id,
              subscriptionInfo,
              name,
              resourceGroup,
              region,
              result.properties.endpoint,
              linkAfterCreate
            );
            armClient
              .assignGrafanaAdminRole(
                subscriptionInfo.subscriptionId,
                result.id
              )
              .catch((error) => {
                if (error.isAxiosError) {
                  notifier.error(
                    `Error: could not assign Grafana admin permissions to you on your new Grafana instance. ${error.response.data.error?.code}, ${error.response.data.error?.message}`
                  );
                } else {
                  notifier.error(error);
                }
              });
            dismiss();
          } else {
            setErrorMessage({
              resultMsg: (
                <>
                  <p>
                    Grafana instance creation did not immediately succeed, the
                    current provisioning state is:{' '}
                    {result.properties.provisioningState}.
                  </p>
                  <p>
                    You may want to check the status of the instance in the
                    Azure portal or try again.
                  </p>
                </>
              ),
              type: MessageBarType.error,
            });
          }
        })
        .catch((error) => {
          let errorCode = 'None';
          let errorMessage = error.message || 'Unknown error';
          if (error.isAxiosError) {
            errorCode = error.response.data.error?.code;
            errorMessage = error.response.data.error?.message;
            if (error.response.status === 403) {
              setErrorMessage({
                resultMsg: (
                  <>
                    <p>
                      You do not have permission to create a Grafana instance in
                      this subscription.
                    </p>
                    <p>
                      Error: {error.response.data.error?.code}.{' '}
                      {error.response.data.error?.message}`
                    </p>
                  </>
                ),
                type: MessageBarType.error,
              });
            } else {
              setErrorMessage({
                resultMsg:
                  `Error when creating Grafana instance: ${error.response.data.error?.code}.` +
                  ` ${error.response.data.error?.message}`,
                type: MessageBarType.error,
              });
            }
          } else {
            setErrorMessage({
              resultMsg: `Error when creating Grafana instance: ${error.message} `,
              type: MessageBarType.error,
            });
          }
          logger.event(
            LensTelemetryConstants.EventNames.WorkspaceActions
              .CreateGrafanaInstanceError,
            {
              subscriptionId: subscriptionInfo.subscriptionId,
              resourceGroup,
              name,
              region,
              editWorkspaceId: workspace.id,
              errorCode,
              errorMessage,
            }
          );
        })
        .finally(() => setIsCreating(false));
    }
  }, [
    dismiss,
    linkAfterCreate,
    name,
    onNewInstanceCreated,
    region,
    resourceGroup,
    subscriptionInfo,
    workspace.id,
  ]);

  const createButtonDisabled =
    !subscriptionInfo.subscriptionId ||
    !resourceGroup ||
    !region ||
    !name ||
    usersGrafanaInstancesStatus === GrafanaOnboardingDataStatus.Loading ||
    !!existsError;

  const onRenderFooterContent = useCallback(
    () => (
      <Stack horizontal tokens={{ childrenGap: 5 }}>
        <ElxPrimaryButton
          styles={buttonStyles}
          text="Create"
          onClick={onClick}
          disabled={createButtonDisabled}
        />
        <ElxSecondaryButton
          styles={buttonStyles}
          text="Close"
          onClick={() => dismiss()}
        />
      </Stack>
    ),
    [onClick, createButtonDisabled, dismiss]
  );

  const breadcrumbItems: IBreadcrumbItem[] = [
    parentBreadcrumb[0],
    {
      ...parentBreadcrumb[1],
      onClick: () => dismiss(),
    },
    {
      text: 'Create Grafana Instance',
      key: 'addGrafanaInstance',
      onClick: () => {},
    },
  ];

  return (
    <Panel
      type={PanelType.large}
      isOpen={show}
      onDismiss={dismiss}
      styles={panelStyles}
      onRenderHeader={() => {
        return (
          <Breadcrumb
            items={breadcrumbItems}
            maxDisplayedItems={3}
            ariaLabel="Create Grafana Instance"
            overflowAriaLabel="More links"
            styles={{ root: { fontSize: '14px' } }}
          />
        );
      }}
      isFooterAtBottom={true}
      onRenderFooterContent={onRenderFooterContent}
      isBlocking={isCreating}
    >
      <>
        {errorMessage.resultMsg && (
          <MessageBar
            messageBarType={errorMessage.type}
            isMultiline={true}
            dismissButtonAriaLabel="Close"
            onDismiss={onDismissError}
          >
            {errorMessage.resultMsg}
          </MessageBar>
        )}
        <>
          <Separator></Separator>
          <Stack styles={stackStyles} tokens={{ childrenGap: '15px' }}>
            <Stack.Item>
              <h3 style={{ marginTop: '9px' }}>
                Create a new Azure Managed Grafana instance
              </h3>
            </Stack.Item>
            <Stack.Item>
              <SubscriptionPicker
                selectedSubscription={subscriptionInfo.subscriptionId}
                setSubscriptionInfo={setSubscriptionInfo}
              />
            </Stack.Item>
            <Stack.Item>
              <Stack horizontal>
                <IndentShape color={theme.palette.neutralPrimary} />
                <Stack.Item grow>
                  <ResourceGroupPicker
                    subscriptionInfo={subscriptionInfo}
                    selectedResourceGroup={resourceGroup}
                    selectedRegion={region}
                    setResourceGroup={setResourceGroup}
                    setRegion={setRegion}
                  />
                </Stack.Item>
              </Stack>
            </Stack.Item>
            <Stack.Item>
              <RegionPicker
                subscriptionInfo={subscriptionInfo}
                selectedRegion={region}
                setRegion={setRegion}
              />
            </Stack.Item>
            <Stack.Item>
              <ElxTextField
                onRenderLabel={() => (
                  <LensLabel
                    labelText="Azure Managed Grafana Instance Name"
                    hintText={undefined}
                    required={true}
                  ></LensLabel>
                )}
                placeholder="Enter a name for your Azure Managed Grafana instance"
                value={name}
                inputActions={[]}
                onChange={(e, value) => {
                  setName(value || '');
                }}
                styles={{ errorMessage: { display: 'auto' } }}
                errorMessage={existsError}
                spellCheck={false}
              />
            </Stack.Item>
            <Stack.Item styles={{ root: { paddingTop: 10 } }}>
              <Checkbox
                label={`After creation, link the Grafana instance to workspace '${workspace.name}'`}
                checked={linkAfterCreate}
                onChange={(_event, checked) => setLinkAfterCreate(!!checked)}
              />
            </Stack.Item>
          </Stack>
        </>
        <SpinnerOverlay
          show={isCreating}
          label={'Creating Azure Managed Grafana instance...'}
          opacity={0.8}
        />
      </>
    </Panel>
  );
};

export default CreateGrafanaInstancePanel;
