import { HexToRGBA } from '@elixir/styling';
import { Spinner, SpinnerSize } from '@fluentui/react';
import { useLensShellTheme } from 'features/shell/lensShellStyles';

interface SpinnerOverlayProps {
  show: boolean;
  label?: string;
  opacity?: number;
  size?: SpinnerSize;
}

// Show a spinner that overlays the current pane. Works in callouts, panels, etc.
// CSS was stolen from ElxPanel's spinner overlay
export const SpinnerOverlay = (props: SpinnerOverlayProps) => {
  const { show, label, opacity, size } = props;
  const theme = useLensShellTheme();
  return show ? (
    <div
      role="status"
      style={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        background: HexToRGBA(theme.palette.white, opacity ?? 0.4),
      }}
    >
      <Spinner
        size={size ?? SpinnerSize.large}
        label={label}
        labelPosition="right"
      />
    </div>
  ) : (
    <></>
  );
};

export default SpinnerOverlay;
