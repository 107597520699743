import { IDropdownOption, Stack, TextField } from '@fluentui/react';
import {
  ElxDropdown,
  ElxTimeSelector,
  TimeSelectorOutput,
} from '@elixir/components';
import { Project } from 'features/workspaces/models/project';
import React, { useState } from 'react';
import { LensLabel } from 'utils/lensLabel';
import {
  stackItemFormStyles,
  textInputStyle,
  wideSpacingStackTokens,
} from '../../workspaceStyles';
import {
  ComponentType,
  JobSettingsObj,
  Tooltips,
} from '../workspaceSettingsUtil';

interface JobSettingsProps {
  data: JobSettingsObj;
  project: Project;
  updateOrchestratorProject: (project: Project) => void;
  onChange: (event: any) => void;
  showError: any; //error object from react hook form
}

const JobSettings = (props: JobSettingsProps): JSX.Element => {
  const [autoMitigation, setAutoMitigation] = useState(
    props.data.autoMitigation
  );
  const jobSettingsFormData = [
    {
      componentType: ComponentType.ElxTextField,
      onRenderLabel: () => (
        <LensLabel
          labelText="Max Job Instances (1 - unlimited, Empty input will be passed as a null value) "
          hintText={Tooltips.maxJobInstances}
          required={false}
        ></LensLabel>
      ),
      defaultValue: props.data.maxJobInstances
        ? props.data.maxJobInstances + ''
        : undefined,
      type: 'number',
      min: 1,
      onChangeElxTextField: (event: any, value: string | undefined) => {
        props.onChange({
          target: {
            name: 'updatedMaxJobInstances',
            value: {
              ...props.data,
              maxJobInstances: value ? parseInt(value) : undefined,
            },
          },
        });
        props.updateOrchestratorProject({
          ...props.project,
          maxJobInstances: value ? parseInt(value) : undefined,
        });
      },
      styles: textInputStyle,
      onGetErrorMessage: (value: string) => {
        const inputValue = parseInt(value);
        if (inputValue < 0 || inputValue === 0) {
          return 'Max Job Instances needs to be 1 or greater.';
        }
      },
    },
    {
      componentType: ComponentType.ElxDropdown,
      onRenderLabel: () => (
        <LensLabel
          labelText="Auto Mitigation (Empty dropdown will be passed as a null value) "
          hintText={Tooltips.autoMitigation}
          required={false}
        ></LensLabel>
      ),
      defaultValue: autoMitigation || '',
      selectedKey: autoMitigation || '',
      onChangeElxDropdown: (
        event: any,
        option: IDropdownOption | undefined
      ) => {
        setAutoMitigation(option?.key + '');
        props.onChange({
          target: {
            name: 'updatedAutoMitigations',
            value: {
              ...props.data,
              autoMitigation: option?.key + '',
            },
          },
        });
        props.updateOrchestratorProject({
          ...props.project,
          autoMitigation: option?.key + '',
        });
      },
      options: [
        { key: 'On', text: 'on' },
        { key: 'Off', text: 'off' },
        { key: '', text: '' },
      ],
      styles: {},
    },
    {
      componentType: ComponentType.ElxTextField,
      onRenderLabel: () => (
        <LensLabel
          labelText="Max Queued Job Instances (0 - 1000, Empty input will be passed as a null value)"
          hintText={Tooltips.maxQueuedJobInstances}
          required={false}
        ></LensLabel>
      ),
      defaultValue: props.data.maxQueuedJobInstances
        ? props.data.maxQueuedJobInstances + ''
        : undefined,
      type: 'number',
      min: 0,
      max: 1000,
      onChangeElxTextField: (event: any, value: string | undefined) => {
        props.onChange({
          target: {
            name: 'updatedMaxQueuedJobInstances',
            value: {
              ...props.data,
              maxQueuedJobInstances: value ? parseInt(value) : undefined,
            },
          },
        });
        props.updateOrchestratorProject({
          ...props.project,
          maxQueuedJobInstances: value ? parseInt(value) : undefined,
        });
      },
      styles: textInputStyle,
      onGetErrorMessage: (value: string) => {
        const inputValue = parseInt(value);
        if (inputValue < 0) {
          return 'Max Queued Job Instances needs to be 0 or greater.';
        } else if (inputValue > 1000) {
          return 'Max Queued Job Instances needs to be less than or equal to 1000.';
        }
      },
    },
    {
      componentType: ComponentType.ElxTextField,
      onRenderLabel: () => (
        <LensLabel
          labelText="Job Priority (0 - unlimited, Empty input will be passed as a null value)"
          hintText={Tooltips.jobPriority}
          required={false}
        ></LensLabel>
      ),
      defaultValue: props.data.jobPriority
        ? props.data.jobPriority + ''
        : undefined,
      type: 'number',
      min: 0,
      onChangeElxTextField: (event: any, value: string | undefined) => {
        props.onChange({
          target: {
            name: 'updatedJobPriority',
            value: {
              ...props.data,
              jobPriority: value ? parseInt(value) : undefined,
            },
          },
        });
        props.updateOrchestratorProject({
          ...props.project,
          jobPriority: value ? parseInt(value) : undefined,
        });
      },
      styles: textInputStyle,
      onGetErrorMessage: (value: string) => {
        const inputValue = parseInt(value);
        if (inputValue < 0) {
          return 'Job Priority needs to be 0 or greater.';
        }
      },
    },
  ];

  return (
    <Stack>
      <Stack.Item>
        <Stack>
          <Stack.Item grow tokens={wideSpacingStackTokens}>
            <Stack>
              <Stack.Item key={0} {...stackItemFormStyles}>
                <LensLabel
                  labelText={'Job Expiration'}
                  hintText={Tooltips.JobExpiration}
                />
                <ElxTimeSelector
                  hours={parseInt(
                    props.data.jobExpiration?.split('.')[1]?.split(':')[0] ||
                      '0'
                  )}
                  minutes={parseInt(
                    props.data.jobExpiration?.split('.')[1].split(':')[1] || '0'
                  )}
                  seconds={parseInt(
                    props.data.jobExpiration?.split('.')[1].split(':')[2] || '0'
                  )}
                  showTimezoneSelector={false}
                  onChange={(time: TimeSelectorOutput) => {
                    props.onChange({
                      target: {
                        name: 'updatedJobPriority',
                        value: {
                          ...props.data,
                          jobExpiration:
                            '00.' +
                            time.hours +
                            ':' +
                            time.minutes +
                            ':' +
                            time.seconds,
                        },
                      },
                    });
                    props.updateOrchestratorProject({
                      ...props.project,
                      jobExpiration:
                        '00.' +
                        time.hours +
                        ':' +
                        time.minutes +
                        ':' +
                        time.seconds,
                    });
                  }}
                />
              </Stack.Item>
              <Stack.Item key={1} {...stackItemFormStyles}>
                <TextField
                  onRenderLabel={jobSettingsFormData[0].onRenderLabel}
                  defaultValue={jobSettingsFormData[0].defaultValue}
                  type={jobSettingsFormData[0].type}
                  min={jobSettingsFormData[0].min}
                  max={jobSettingsFormData[0].max}
                  styles={jobSettingsFormData[0].styles}
                  onChange={jobSettingsFormData[0].onChangeElxTextField}
                  onGetErrorMessage={(value: string) => {
                    const inputValue = parseInt(value);
                    if (inputValue < 0 || inputValue === 0) {
                      return 'Max Job Instances needs to be 1 or greater.';
                    }
                  }}
                />
              </Stack.Item>
              <Stack.Item key={2} {...stackItemFormStyles}>
                <ElxDropdown
                  onRenderLabel={jobSettingsFormData[1].onRenderLabel}
                  defaultValue={jobSettingsFormData[1].defaultValue}
                  selectedKey={jobSettingsFormData[1].selectedKey}
                  onChange={jobSettingsFormData[1].onChangeElxDropdown}
                  options={jobSettingsFormData[1].options || []}
                />
              </Stack.Item>
              <Stack.Item key={3} {...stackItemFormStyles}>
                <TextField
                  onRenderLabel={jobSettingsFormData[2].onRenderLabel}
                  defaultValue={jobSettingsFormData[2].defaultValue}
                  type={jobSettingsFormData[2].type}
                  min={jobSettingsFormData[2].min}
                  max={jobSettingsFormData[2].max}
                  styles={jobSettingsFormData[2].styles}
                  onChange={jobSettingsFormData[2].onChangeElxTextField}
                  onGetErrorMessage={jobSettingsFormData[2].onGetErrorMessage}
                />
              </Stack.Item>
              <Stack.Item key={4} {...stackItemFormStyles}>
                <TextField
                  onRenderLabel={jobSettingsFormData[3].onRenderLabel}
                  defaultValue={jobSettingsFormData[3].defaultValue}
                  type={jobSettingsFormData[3].type}
                  min={jobSettingsFormData[3].min}
                  max={jobSettingsFormData[3].max}
                  styles={jobSettingsFormData[3].styles}
                  onChange={jobSettingsFormData[3].onChangeElxTextField}
                  onGetErrorMessage={jobSettingsFormData[3].onGetErrorMessage}
                />
              </Stack.Item>
            </Stack>
          </Stack.Item>
        </Stack>
      </Stack.Item>
    </Stack>
  );
};

export default JobSettings;
