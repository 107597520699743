import { Stack } from '@fluentui/react';
import RedisCacheSettings from './redisCache/redisCacheSettings';
import SupportNotificationSettings from './supportNotification/supportNotificationSettings';
import { useLensShellTheme } from 'features/shell/lensShellStyles';
import { useWorkspaceStyles } from 'features/workspaces/components/editWorkspace/workspaceStyles';

const AdminSettings = (): JSX.Element => {
  const theme = useLensShellTheme();
  const styles = useWorkspaceStyles();
  return (
    <Stack
      className={styles.rootStackStyle}
      styles={{
        root: {
          background: theme.palette.white,
          childrenGap: '30px',
          padding: '16px',
        },
      }}
    >
      <Stack.Item>
        <RedisCacheSettings />
      </Stack.Item>

      <Stack.Item>
        <SupportNotificationSettings />
      </Stack.Item>
    </Stack>
  );
};

export default AdminSettings;
