import React from 'react';
import { getStyles } from './styles';
import { Stack } from '@fluentui/react';
import {
  allSupportNotifications,
  allActivityLogs,
} from '../notificationsSlice';
import { useSelector } from 'react-redux';

const NotificationNav = (props: any): JSX.Element => {
  const supportNotifications = useSelector(allSupportNotifications);
  const activeSupportNotification = supportNotifications.filter(
    (item) => item.Show === true
  );
  const activityLogNotification = useSelector(allActivityLogs);
  const style = getStyles();
  return (
    <Stack className={style.stack}>
      <Stack
        className={style.innerStack}
        horizontal
        onClick={() => props.setSelectedTab('notification')}
      >
        <Stack.Item
          className={
            props.notificationSelected
              ? style.stackItemSelected
              : style.stackItemHidden
          }
        >
          |
        </Stack.Item>
        <Stack.Item grow={13}>Notifications</Stack.Item>
        <Stack.Item>{activeSupportNotification.length}</Stack.Item>
      </Stack>
      <Stack
        className={style.innerStack}
        horizontal
        onClick={() => props.setSelectedTab('activityLog')}
      >
        <Stack.Item
          className={
            props.activityLogSelected
              ? style.stackItemSelected
              : style.stackItemHidden
          }
        >
          |
        </Stack.Item>
        <Stack.Item grow={13}>Activity Log</Stack.Item>
        <Stack.Item>{activityLogNotification.length}</Stack.Item>
      </Stack>
    </Stack>
  );
};

export default NotificationNav;
