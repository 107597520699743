import { Stack } from '@fluentui/react';
import { ElxDropdown, ElxTextField } from '@elixir/components';
import React from 'react';
import { wideSpacingStackTokens } from '../../workspaceStyles';
import { ParameterType, WorkspaceParameter } from '../workspaceSettingsUtil';

interface WorkspaceParameterProps {
  data: WorkspaceParameter;
  index: number;
  onChange: (data: WorkspaceParameter) => void;
}

const INPUT_WIDTH = 275;
const Parameter = (props: WorkspaceParameterProps): JSX.Element => {
  return (
    <Stack horizontal tokens={wideSpacingStackTokens}>
      <Stack.Item grow>
        <ElxTextField
          value={props.data.name}
          styles={{ fieldGroup: { width: INPUT_WIDTH, height: 32 } }}
          onChange={(e, value) => {
            const newParam = { ...props.data, name: value || '' };
            props.onChange(newParam);
          }}
        />
      </Stack.Item>
      <Stack.Item grow>
        <ElxTextField
          value={props.data.value}
          styles={{ fieldGroup: { width: INPUT_WIDTH, height: 32 } }}
          onChange={(e, value) => {
            const newParam = { ...props.data, value: value || '' };
            props.onChange(newParam);
          }}
        />
      </Stack.Item>
      <Stack.Item grow>
        <ElxDropdown
          dropdownWidth={250}
          selectedKey={props.data.type}
          onChange={(event, option) => {
            const newParam = {
              ...props.data,
              type: option?.key as ParameterType,
            };
            props.onChange(newParam);
          }}
          styles={{ dropdown: { width: INPUT_WIDTH } }}
          options={[
            {
              key: ParameterType.None,
              text: '',
            },
            {
              key: ParameterType.AadAccessToken,
              text: 'AAD Access Token',
            },
            {
              key: ParameterType.Boolean,
              text: 'Boolean',
            },
            {
              key: ParameterType.Integer,
              text: 'Integer',
            },
            {
              key: ParameterType.Query,
              text: 'Query',
            },
            {
              key: ParameterType.SangamMacro,
              text: 'SangamMacro',
            },
            {
              key: ParameterType.String,
              text: 'String',
            },
            {
              key: ParameterType.StringNoQuote,
              text: 'String Without Quotes',
            },
          ]}
        />
      </Stack.Item>
    </Stack>
  );
};

export default Parameter;
