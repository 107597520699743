import { ISpinButtonStyles } from '@fluentui/react';

export const FlexContainerStyle: React.CSSProperties = {
  display: 'flex',
};

export const RightSpacingStyle: React.CSSProperties = {
  marginRight: '.42rem',
};

export const SpinButtonStyle: Partial<ISpinButtonStyles> = {
  root: {
    minWidth: '3.4rem',
    width: '3.4rem',
  },
  spinButtonWrapper: {
    minWidth: '3.4rem',
    width: '3.4rem',
  },
  input: {
    minWidth: '2rem',
  },
};

export const TimeZoneStyle: React.CSSProperties = {
  paddingTop: '.11rem',
  minWidth: '6.7rem',
  width: '6.7rem',
};
