import { ValueRangeInputButton } from './valueRangeInputButton/valueRangeInputButton';
import { FlexContainerStyle } from './valueRangeInputButton/valueRangeInputButton.styles';

export interface IDayHourMinSecPickerProps {
  setValue: string; // days.hours:minutes:seconds
  onChange: (value: string) => void;
  maxDays: number;
  showDays: boolean;
  showHours: boolean;
  showMinutes: boolean;
  showSeconds: boolean;
}

const DayHourMinSecPicker = (props: IDayHourMinSecPickerProps) => {
  const {
    showDays,
    showHours,
    showMinutes,
    showSeconds,
    onChange,
    setValue,
    maxDays,
  } = props;

  let tok = setValue.split('.');
  let timeTok: string[];

  if (tok.length > 1) {
    timeTok = tok[1].split(':');
  } else {
    timeTok = tok[0].split(':');
    tok[0] = '0';
  }

  const daysSelector = showDays && (
    <ValueRangeInputButton
      name={'Days'}
      maxValue={maxDays || 0}
      // minValue={props.minDays || 0}
      numericValue={+tok[0]}
      disabled={false}
      setNumericValue={(value: number): void => {
        tok[0] = value.toString();
        onChange([tok[0], timeTok.join(':')].join('.'));
      }}
    ></ValueRangeInputButton>
  );

  const hoursSelector = showHours && (
    <ValueRangeInputButton
      name={'Hours'}
      maxValue={23}
      numericValue={+timeTok[0]}
      disabled={false}
      setNumericValue={(value: number): void => {
        timeTok[0] = value.toString();
        onChange([tok[0], timeTok.join(':')].join('.'));
      }}
    ></ValueRangeInputButton>
  );

  const minutesSelector = showMinutes && (
    <ValueRangeInputButton
      name={'Minutes'}
      maxValue={59}
      numericValue={+timeTok[1]}
      disabled={false}
      setNumericValue={(value: number): void => {
        timeTok[1] = value.toString();
        onChange([tok[0], timeTok.join(':')].join('.'));
      }}
    ></ValueRangeInputButton>
  );

  const secondsSelector = showSeconds && (
    <ValueRangeInputButton
      name={'Seconds'}
      maxValue={59}
      numericValue={+timeTok[2]}
      disabled={false}
      setNumericValue={function (value: number): void {
        timeTok[2] = value.toString();
        onChange([tok[0], timeTok.join(':')].join('.'));
      }}
    ></ValueRangeInputButton>
  );
  return (
    <div style={FlexContainerStyle}>
      {daysSelector}
      {hoursSelector}
      {minutesSelector}
      {secondsSelector}
      {/* <ElxPrimaryButton
        label="Clear"
        onClick={() => {
          onChange('0.00:00:00');
        }}
      ></ElxPrimaryButton> */}
    </div>
  );
};

export default DayHourMinSecPicker;
