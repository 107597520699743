import {
  IObjectWithKey,
  MessageBar,
  MessageBarButton,
  MessageBarType,
  SelectionMode,
  Stack,
} from '@fluentui/react';
import {
  DeleteIconProps,
  DisplayTypes,
  ElxTableContainer,
  FilterDisplayMode,
  FilterOption,
  FilterOptionPillMode,
  IElxColumn,
  IElxContainerProps,
  ITableAction,
} from '@elixir/components';
import {
  Workspace,
  WorkspaceApplication,
  WorkspaceStatus,
} from 'features/workspaces/models/workspace';
import {
  loadProject,
  selectEditWorkspaceApplications,
  selectEditWorkspaceApplicationsStatus,
} from 'features/workspaces/workspaceSlice';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { classNames } from '../../workspacesStyles';
import { useWorkspaceStyles, workspacePadding } from '../workspaceStyles';
import _ from 'lodash';
import workspaceApplicationsApi from 'features/workspaces/api/workspaceApplicationsApi';
import { AppDispatch } from 'app/lensShellUtility';
import AddAadAppsPanel from './addAadAppPanel';
import { tableStyles } from 'utils/sharedStyles';
import workspacesApi from 'features/workspaces/api/workspacesApi';

interface AadAppTableProps {
  workspaceId: string;
  workspace?: Workspace;
  dismissPanel: () => void;
  openPanel: () => void;
  closeQuickSwitchPanel: () => void;
}

const AadAppsTable = (props: AadAppTableProps): JSX.Element => {
  const styles = useWorkspaceStyles();
  const dispatch = useDispatch<AppDispatch>();

  const appsStatus = useSelector(selectEditWorkspaceApplicationsStatus);
  const aadApplications = useSelector(selectEditWorkspaceApplications) || [];
  const [selected, setSelected] = useState<IObjectWithKey[]>([]);
  const [canDelete, setCanDelete] = useState(false);
  const [disableDeleteBtn, setDisableDeleteBtn] = useState(true);
  const [message, setMessage] = useState<string>('');
  const [showAddAadApp, setShowAddAadApp] = useState(false);

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const containerProps = {
    isLoading: appsStatus !== WorkspaceStatus.Loaded,
    compact: true,
    styles: {
      headerContainer: 'hidden',
      body: { minHeight: '750px' },
      actionsContainer: 'hidden',
    },
  } as IElxContainerProps;

  const tenantPillFilter: FilterOption = {
    field: 'tenant',
    label: 'tenant',
    multiselect: true,
    pillMode: FilterOptionPillMode.Dynamic,
    values: (aadApplications || [])
      .map((item) => item.tenant)
      .filter((value, index, self) => self.indexOf(value) === index),
  };

  const idPillFilter: FilterOption = {
    field: 'id',
    label: 'id',
    multiselect: true,
    pillMode: FilterOptionPillMode.Dynamic,
    values: (aadApplications || [])
      .map((item) => item.id)
      .filter((value, index, self) => self.indexOf(value) === index),
  };
  const searchProps = {
    pillFilters: [tenantPillFilter, idPillFilter],
    filterDisplayMode: FilterDisplayMode.Pill,
  };

  const columns: IElxColumn[] = [
    {
      minWidth: 400,
      isResizable: true,
      isMultiline: true,
      className: classNames.tableStyles,
      key: 'tenant',
      name: 'Tenant',
      fieldName: 'tenant',
    },
    {
      minWidth: 400,
      isResizable: true,
      isMultiline: false,
      className: classNames.tableStyles,
      key: 'id',
      name: 'Application ID',
      fieldName: 'id',
    },
  ];

  const actions: ITableAction[] = [
    {
      key: 'addApplication',
      text: 'Add Application or Managed Identity',
      defaultDisplay: DisplayTypes.Show,
      iconProps: {
        iconName: 'Add',
      },
      onAction: () => {
        setShowAddAadApp(true);
      },
      onBulkAction: () => {
        setShowAddAadApp(true);
      },
    },
    {
      key: 'deleteApplication',
      text: 'Delete Application',
      disabled: disableDeleteBtn,
      iconProps: DeleteIconProps,
      onAction: () => {
        removeApplication(selected[0] as WorkspaceApplication);
      },
      onBulkAction: () => {
        removeApplication(selected[0] as WorkspaceApplication);
      },
    },
  ];

  function removeApplication(application: WorkspaceApplication) {
    workspacesApi
      .getDataConnections(props.workspaceId)
      .then((dataSources: any) => {
        let selection = selected[0] as WorkspaceApplication;
        let dataSource = _.find(dataSources, {
          ApplicationId: application.id,
          Tenant: application.tenant,
        });
        if (dataSource) {
          setMessage(
            'Application "' +
              application.id +
              '" is used by data connection "' +
              dataSource.Name +
              '" and cannot be removed from workspace "' +
              props.workspace?.name +
              '". Please change the authentication of that data connection before removing this application.'
          );
          setShowDeleteConfirm(true);
          return;
        }

        dataSource = _.find(dataSources, {
          CertificateApplicationId: application.id,
          CertificateTenant: application.tenant,
        });
        if (dataSource) {
          setMessage(
            'Application "' +
              application.id +
              '" is used by data connection "' +
              dataSource.Name +
              '" and cannot be removed from workspace "' +
              props.workspace?.name +
              '". Please change the authentication of that data connection before removing this application.'
          );
          setShowDeleteConfirm(true);
          return;
        }
        setCanDelete(true);
        setMessage(
          `Are you sure you want to remove application "${selection.id}" in tenant "${selection.tenant}" from workspace "${props.workspace?.name}"? The data connections and jobs will not be able to use this application for authentication.`
        );
        setShowDeleteConfirm(true);
      });
  }

  return (
    <Stack className={styles.rootStackStyle}>
      <Stack.Item styles={workspacePadding}>
        {showDeleteConfirm && (
          <MessageBar
            messageBarType={MessageBarType.warning}
            isMultiline={true}
            dismissButtonAriaLabel="Close"
            actions={
              <div>
                <MessageBarButton
                  text="Delete"
                  disabled={!canDelete}
                  onClick={() => {
                    setShowDeleteConfirm(false);
                    workspaceApplicationsApi
                      .deleteApplication(
                        props.workspaceId,
                        selected[0] as WorkspaceApplication
                      )
                      .then((res) => {
                        setSelected([]);
                        dispatch(
                          loadProject({
                            workspaceId: props.workspaceId,
                            isEditWorkspace: true,
                          })
                        );
                      });
                  }}
                />
                <MessageBarButton
                  text="Cancel"
                  onClick={() => {
                    setShowDeleteConfirm(false);
                  }}
                />
              </div>
            }
          >
            {message}
          </MessageBar>
        )}
        <ElxTableContainer
          containerProps={containerProps}
          tableProps={{
            columns: columns,
            selectionMode: SelectionMode.single,
            items: aadApplications,
            styles: tableStyles,
            actions: actions,
            getKey: (item: WorkspaceApplication) => item.id,
            setKey: 'id',
            selectedItems: selected, //need to control the selected items here with another state
            onSelectionChanged: (selected: IObjectWithKey[]) => {
              const selectedItem = selected[0] as WorkspaceApplication;
              setSelected(selected);
              setDisableDeleteBtn(selectedItem.id === aadApplications[0].id);
            },
          }}
          searchBoxProps={searchProps}
        />

        <AddAadAppsPanel
          workspaceId={props.workspaceId}
          show={showAddAadApp}
          dismissPanel={() => setShowAddAadApp(false)}
          openPanel={() => setShowAddAadApp(true)}
          openParentPanel={props.openPanel}
          closeParentPanel={props.dismissPanel}
          closeQuickSwitchPanel={props.closeQuickSwitchPanel}
        />
      </Stack.Item>
    </Stack>
  );
};

export default AadAppsTable;
