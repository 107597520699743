import {
  ElxPrimaryButton,
  ElxSecondaryButton,
  ElxTextField,
} from '@elixir/components';
import { Callout, Stack, Target } from '@fluentui/react';
import { useState } from 'react';
import { LensLabel } from 'utils/lensLabel';
import notifier from 'utils/notifier';
import SpinnerOverlay from 'components/spinnerOverlay/spinnerOverlay';
import grafanaMigrationApi from '../api/grafanaMigrationApi';
import { useSelector } from 'react-redux';
import { selectFolders } from '../grafanaMigrationSlice';

export interface CreateGrafanaFolderCalloutProps {
  show: boolean;
  instanceUrl: string;
  onGrafanaFolderCreated: (grafanaFolderUid: string) => void;
  dismissCallout: () => void;
  target?: Target;
}

// show a callout which prompts the user for a Grafana folder name and then creates the Grafana folder using graphClient.createGrafanaFolder
const CreateGrafanaFolderCallout = (props: CreateGrafanaFolderCalloutProps) => {
  const { show, instanceUrl, onGrafanaFolderCreated, dismissCallout, target } =
    props;
  const [grafanaFolderName, setGrafanaFolderName] = useState<string>('');
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const folders = useSelector(selectFolders);

  const existingGrafanaFolders = folders.map((f) => f.title);

  const dismiss = () => {
    setGrafanaFolderName('');
    setIsCreating(false);
    dismissCallout();
  };

  const onCreate = () => {
    if (!instanceUrl) return;
    setIsCreating(true);
    grafanaMigrationApi
      .createFolder(instanceUrl, grafanaFolderName)
      .then((result) => {
        onGrafanaFolderCreated(result.uid);
        notifier.info(`Successfully created Grafana folder: ${result.title}`);
      })
      .catch((error) => {
        if (error.isAxiosError) {
          if (error.response.status === 403) {
            notifier.error(
              'You do not have permission to create the Grafana folder.' +
                ` Error: ${error.response.data.error?.code}. ${error.response.data.error?.message}`
            );
          } else {
            notifier.error(
              `Failed to create Grafana folder: ${error.response.data.error?.code}. ${error.response.data.error?.message}`
            );
          }
        } else {
          notifier.error(`Failed to create Grafana folder: ${error.message}`);
        }
      })
      .finally(() => {
        dismiss();
      });
  };

  const errorMessage = existingGrafanaFolders.includes(grafanaFolderName)
    ? 'Folder already exists'
    : '';

  return (
    <Callout
      target={target}
      onDismiss={dismiss}
      hidden={!show}
      styles={{ calloutMain: { width: 450 } }}
    >
      <Stack
        tokens={{ padding: 20, childrenGap: 15 }}
        styles={{ root: { minHeight: 250 } }}
      >
        <h4>Create a new Grafana folder</h4>
        <ElxTextField
          onRenderLabel={() => (
            <LensLabel
              labelText="Grafana Folder Name"
              hintText={'Enter a name for your new Grafana folder'}
              required={true}
            ></LensLabel>
          )}
          placeholder="Enter a name for your new Grafana folder"
          value={grafanaFolderName}
          onChange={(_event, value) => {
            setGrafanaFolderName(value || '');
          }}
          styles={{ errorMessage: { display: 'auto' } }} // error message needs this or it won't display
          errorMessage={errorMessage}
          spellCheck={false}
        />
        <Stack
          horizontal
          styles={{ root: { width: '100%', paddingTop: 20 } }}
          tokens={{ childrenGap: 10 }}
        >
          <Stack.Item grow={1} />
          <ElxPrimaryButton
            onClick={onCreate}
            disabled={
              isCreating ||
              !grafanaFolderName ||
              existingGrafanaFolders.includes(grafanaFolderName)
            }
          >
            Create
          </ElxPrimaryButton>
          <ElxSecondaryButton onClick={dismiss}>Cancel</ElxSecondaryButton>
        </Stack>
      </Stack>
      <SpinnerOverlay
        show={isCreating}
        label={'Creating Grafana folder...'}
        opacity={0.8}
      />
    </Callout>
  );
};

export default CreateGrafanaFolderCallout;
