import { ElxTextField } from '@elixir/components';
import { ChoiceGroup, IChoiceGroupOption, Stack } from '@fluentui/react';
import { ConnectionDataSourceType } from 'features/workspaces/models/project';
import { useState } from 'react';
import { LensLabel } from 'utils/lensLabel';
import { SelectorProps } from '../editDataConnectionHelper';

const SqlSelector = (props: SelectorProps) => {
  const { state, setState } = props;

  function calculateInitChoice() {
    if (state.connectionSecretUri && !state.userId) {
      return 'Connection String';
    } else if (state.passwordSecretUri && state.userId) {
      return 'Username/Password';
    } else {
      return 'AAD';
    }
  }

  const [selectedKey, setSelectedKey] = useState<string | undefined>(
    calculateInitChoice()
  );
  const options: IChoiceGroupOption[] = [
    { key: 'AAD', text: 'AAD' },
    { key: 'Username/Password', text: 'Username/Password' },
    { key: 'Connection String', text: 'Connection String' },
  ];

  return (
    <Stack tokens={{ childrenGap: 16 }}>
      <Stack.Item>
        <LensLabel
          labelText="Authentication Method"
          hintText={' Authentication method for sql data connection'}
          required={true}
        ></LensLabel>
        <ChoiceGroup
          selectedKey={selectedKey}
          options={options}
          onChange={(ev, option: IChoiceGroupOption | undefined) => {
            setSelectedKey(option?.key);
            //clear the sql specific form state on authentication method change
            setState({
              name: state.name,
              authenticationMode: state.authenticationMode,
              ...(state.applicationId && {
                applicationId: state.applicationId,
              }),
              ...(state.tenant && { tenant: state.tenant }),
              type: ConnectionDataSourceType.Sql,
              ...(state.isImported && {
                isImported: state.isImported,
              }),
              id: state.id,
            });
          }}
        />
      </Stack.Item>
      {selectedKey === 'AAD' && (
        <>
          <Stack.Item>
            <ElxTextField
              onRenderLabel={() => (
                <LensLabel
                  labelText="server"
                  hintText={
                    "The SQL Azure server , for example 'database.windows.net'."
                  }
                  required={true}
                ></LensLabel>
              )}
              value={state.server || ''}
              placeholder="dso.database.windows.net"
              onChange={(e, newValue) => {
                setState({
                  ...state,
                  database: state.database,
                  server: newValue || '',
                });
              }}
            />
          </Stack.Item>
          <Stack.Item>
            <ElxTextField
              onRenderLabel={() => (
                <LensLabel
                  labelText="database"
                  hintText={
                    'The SQL Azure or SQL server (Corpnet) database name.'
                  }
                  required={true}
                ></LensLabel>
              )}
              value={state.database || ''}
              placeholder="database name"
              onChange={(e, newValue) => {
                setState({
                  ...state,
                  database: newValue || '',
                  server: state.server,
                });
              }}
            />
          </Stack.Item>
        </>
      )}
      {selectedKey === 'Username/Password' && (
        <>
          <Stack.Item>
            <ElxTextField
              onRenderLabel={() => (
                <LensLabel
                  labelText="server"
                  hintText={
                    "The SQL Azure server , for example 'database.windows.net'."
                  }
                  required={true}
                ></LensLabel>
              )}
              value={state.server || ''}
              placeholder="dso.database.windows.net"
              onChange={(e, newValue) => {
                setState({
                  ...state,
                  server: newValue || '',
                  userId: state.userId,
                  database: state.database,
                  passwordSecretUri: state.passwordSecretUri,
                });
              }}
            />
          </Stack.Item>
          <Stack.Item>
            <ElxTextField
              onRenderLabel={() => (
                <LensLabel
                  labelText="database"
                  hintText={
                    'The SQL Azure or SQL server (Corpnet) database name.'
                  }
                  required={true}
                ></LensLabel>
              )}
              value={state.database || ''}
              placeholder="database name"
              onChange={(e, newValue) => {
                setState({
                  ...state,
                  userId: state.userId,
                  database: newValue || '',
                  server: state.server,
                  passwordSecretUri: state.passwordSecretUri,
                });
              }}
            />
          </Stack.Item>
          <Stack.Item>
            <ElxTextField
              onRenderLabel={() => (
                <LensLabel
                  labelText="User id"
                  hintText={'SQL server User id.'}
                  required={true}
                ></LensLabel>
              )}
              value={state.userId || ''}
              placeholder="username"
              onChange={(e, newValue) => {
                setState({
                  ...state,
                  userId: newValue || '',
                  database: state.database,
                  server: state.server,
                  passwordSecretUri: state.passwordSecretUri,
                });
              }}
            />
          </Stack.Item>
          <Stack.Item>
            <ElxTextField
              onRenderLabel={() => (
                <LensLabel
                  labelText="Password Secret Key Vault URI"
                  hintText={
                    'https://<mykeyvault>.vault.azure.net/secrets/<user-password>'
                  }
                  required={true}
                ></LensLabel>
              )}
              value={state.passwordSecretUri || ''}
              placeholder="https://<mykeyvault>.vault.azure.net/secrets/<connection-string>"
              onChange={(e, newValue) => {
                setState({
                  ...state,
                  userId: state.userId,
                  database: state.database,
                  server: state.server,
                  passwordSecretUri: newValue || '',
                });
              }}
            />
          </Stack.Item>
        </>
      )}
      {selectedKey === 'Connection String' && (
        <Stack.Item>
          <ElxTextField
            onRenderLabel={() => (
              <LensLabel
                labelText="Password Secret Key Vault URI"
                hintText={
                  'https://<mykeyvault>.vault.azure.net/secrets/<user-password>'
                }
                required={true}
              ></LensLabel>
            )}
            value={state.connectionSecretUri || ''}
            placeholder="https://<mykeyvault>.vault.azure.net/secrets/<connection-string>"
            onChange={(e, newValue) => {
              setState({
                ...state,
                connectionSecretUri: newValue || '',
              });
            }}
          />
        </Stack.Item>
      )}
    </Stack>
  );
};

export default SqlSelector;
