export enum ServiceListStatus {
  None = 'None',
  Loading = 'Loading',
  Loaded = 'Loaded',
  Error = 'Error',
}

export interface ServiceTreeApiResponse {
  value: ServiceTreeApiService[];
}

export interface ServiceTreeApiService {
  Name: string;
  ServiceGroupId: string;
  Id: string;
}

export interface Service {
  name: string;
  serviceGroupId: string;
  id: string;
}
