import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from 'app/lensShellUtility';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import notifier from 'utils/notifier';
import userPreferencesApi from './api/userPreferencesApi';
import UserPreferences, {
  UserPreferencesStatus,
} from './models/userPreferences';
import { setUserPreferences } from './utils/userPreferencesUtils';

/**
 * Custom hook to use the current userprofile.
 */
export const useUserPreferences = () => {
  const dispatch = useDispatch<AppDispatch>();
  const userPreferencesStatus = useSelector(selectUserPreferencesStatus);

  useEffect(() => {
    if (userPreferencesStatus === UserPreferencesStatus.None) {
      dispatch(loadUserPreferences());
    }
  }, [dispatch, userPreferencesStatus]);
};

export const loadUserPreferences = createAsyncThunk(
  'userinfo/loadUserPreferences',
  async () => {
    let userPreferences = await userPreferencesApi.getUserPreferences();
    return userPreferences;
  }
);

export const saveUserPreferences = createAsyncThunk(
  'userinfo/saveUserPreferences',
  async ({ userPreferences }: { userPreferences: UserPreferences }) => {
    return await userPreferencesApi.saveUserPreferences(userPreferences);
  }
);

export const saveSupportNotificationStatus = createAsyncThunk(
  'userinfo/saveUserPreferences',
  async ({
    userPreferences,
    supportNotificationStatus,
  }: {
    userPreferences: UserPreferences;
    supportNotificationStatus: string;
  }) => {
    return await userPreferencesApi.saveSupportNotificationStatus(
      userPreferences,
      supportNotificationStatus
    );
  }
);

let initialLoadedUserPreferences: UserPreferences = {
  upn: '',
  name: '',
  supportNotificationStatus: '',
  themeColorPreference: '',
  disableAutoRunningQuery: false,
  trimSearchColumns: '',
  turnOffVisualAnalyzerTeaser: false,
  timeZone: '',
  disableSavedDashboardFilters: false,
  aadTenantId: '',
  oid: '',
};

let initialUserPreferences = {
  currentUserPreferences: initialLoadedUserPreferences,
  userPreferencesStatus: UserPreferencesStatus.None,
};

/**
 * Redux slice representing the current userPreferences.
 */
const userPreferencesSlice = createSlice({
  name: 'userPreferences',
  initialState: initialUserPreferences,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadUserPreferences.pending, (state, action) => {
        state.userPreferencesStatus = UserPreferencesStatus.Loading;
      })
      .addCase(loadUserPreferences.fulfilled, (state, action) => {
        state.userPreferencesStatus = UserPreferencesStatus.Loaded;

        const userPreferences = action.payload;

        setUserPreferences(userPreferences);
        state.currentUserPreferences = userPreferences;
      })
      .addCase(loadUserPreferences.rejected, (state, action) => {
        state.userPreferencesStatus = UserPreferencesStatus.Error;
        notifier.error(action.error);
      })
      .addCase(saveUserPreferences.pending, (state, action) => {
        state.userPreferencesStatus = UserPreferencesStatus.Loading;
      })
      .addCase(saveUserPreferences.fulfilled, (state, action) => {
        state.userPreferencesStatus = UserPreferencesStatus.Loaded;

        const userPreferences = action.payload;

        setUserPreferences(userPreferences);
        state.currentUserPreferences = userPreferences;
      })
      .addCase(saveUserPreferences.rejected, (state, action) => {
        notifier.error(action.error);
      });
  },
});

export const selectUserPreferencesStatus = (state: RootState) =>
  state.userPreferences.userPreferencesStatus;
export const selectUserPreferences = (state: RootState) =>
  state.userPreferences.currentUserPreferences;
export const selectUserPreferencesNotifications = (state: RootState) =>
  state.userPreferences.currentUserPreferences.supportNotificationStatus;
export default userPreferencesSlice.reducer;
