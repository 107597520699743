import { LensTelemetryConstants } from 'features/appInsights/appInsightsLibs';
import logger from 'features/appInsights/lensLogger';
import constants from 'utils/constants';
import { getUserInfo } from 'utils/graphClient';

/**
 * @name isGuestUser
 * @description
 * Check if the sign-in user is a guest user
 * @return {boolean} - True is the user is a guest user, false otherwise
 */
export const isGuestUser = () => {
  return getUserInfo()
    .then(function (resp) {
      let isGuestUser =
        (resp?.userType || '').toLowerCase() === constants.AADGuestUserType;
      logger.event(LensTelemetryConstants.EventNames.CheckUserType, {
        isGuestUser: isGuestUser,
      });
      return isGuestUser;
    })
    .catch(function () {
      return false;
    });
};
