import {
  ElxIconButton,
  ElxTable,
  IElxColumn,
  IElxTableProps,
  ITableAction,
} from '@elixir/components';
import {
  CheckboxVisibility,
  Label,
  MessageBar,
  MessageBarType,
  Stack,
  TextField,
} from '@fluentui/react';
import { AppDispatch } from 'app/lensShellUtility';
import { CatalogEntityApiColumns } from 'features/catalog/api/catalogApiModels';
import {
  loadCatalogEntity,
  loadCatalogEntityHistory,
  updateCatalogEntity,
} from 'features/catalog/catalogSlice';
import { CatalogEntityColumn } from 'features/catalog/models/catalogEntity';
import {
  entityDetailsStackItemTableStyles,
  entityDetailsStackStyles,
  entityDetailsLabelStyles,
} from 'features/catalog/utils/catalogEntityDetailsStyles';
import { DetailsPageProps } from 'features/catalog/utils/catalogEntityDetailsUtils';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  colLarge,
  colMedium,
  colUnlimitedLarge,
  tableStyles,
} from 'utils/sharedStyles';

interface CatalogEntityColumnTableItem extends CatalogEntityColumn {
  index: number;
}

export const EntityDetailsSchema = (props: DetailsPageProps): JSX.Element => {
  const { entity } = props;
  const dispatch = useDispatch<AppDispatch>();

  const [indexEditing, setIndexEditing] = useState(-1);
  const [editedColumnName, setEditedColumnName] = useState('');
  const [editedColumnType, setEditedColumnType] = useState('');
  const [editedColumnDescription, setEditedColumnDescription] = useState('');

  const entityColumnTableItems: CatalogEntityColumnTableItem[] =
    entity.columns?.map((value, index) => {
      return { ...value, index: index };
    }) || [];

  const columns: IElxColumn[] = [
    {
      ...colLarge,
      key: 'name',
      name: 'Name',
      fieldName: 'name',
      disableSort: true,
    },
    {
      ...colMedium,
      key: 'type',
      name: 'Type',
      fieldName: 'type',
      disableSort: true,
    },
    {
      ...colLarge,
      key: 'effectiveDate',
      name: 'Effective Date',
      onRender: (item: CatalogEntityColumnTableItem) => {
        if (item.effectiveDate !== '') {
          var date = new Date(item.effectiveDate);
          return date.toUTCString();
        } else {
          return '';
        }
      },
      disableSort: true,
    },
    {
      ...colUnlimitedLarge,
      key: 'description',
      name: 'Description',
      fieldName: 'description',
      disableSort: true,
      onRender(item, index) {
        if (index === indexEditing) {
          return (
            <Stack horizontal verticalAlign="center">
              <TextField
                styles={{ root: { width: '75%' } }}
                resizable
                value={editedColumnDescription}
                onChange={(event, newValue) => {
                  setEditedColumnDescription(newValue || '');
                }}
              />
              <ElxIconButton
                text={'Save'}
                iconProps={{ iconName: 'CheckMark' }}
                onClick={() => {
                  item.description = editedColumnDescription;
                  // send off API call
                  if (entity.originalAPIResponse) {
                    let columns: CatalogEntityApiColumns =
                      entity.originalAPIResponse.Columns || {};
                    let newColumns: CatalogEntityApiColumns =
                      JSON.parse(
                        JSON.stringify(entity.originalAPIResponse.Columns)
                      ) || {};
                    let col: keyof CatalogEntityApiColumns;

                    for (col in columns) {
                      if (
                        col === editedColumnName &&
                        columns[col].Type === editedColumnType
                      ) {
                        newColumns[col] = {
                          ...columns[col],
                          Description: editedColumnDescription,
                        };
                      }
                    }

                    dispatch(
                      updateCatalogEntity({
                        ...entity.originalAPIResponse,
                        Columns: newColumns,
                      })
                    ).then(() => {
                      dispatch(loadCatalogEntity(entity.manifestKey));
                      dispatch(loadCatalogEntityHistory(entity.manifestKey));
                    });

                    setIndexEditing(-1);
                    setEditedColumnName('');
                    setEditedColumnType('');
                  }
                }}
              />
              <ElxIconButton
                text={'Cancel'}
                iconProps={{ iconName: 'Cancel' }}
                onClick={() => {
                  setIndexEditing(-1);
                  setEditedColumnName('');
                  setEditedColumnType('');
                  setEditedColumnDescription('');
                }}
              />
            </Stack>
          );
        } else {
          return item.description;
        }
      },
    },
  ];

  const schemaActions: ITableAction[] = [
    {
      key: 'editDescription',
      text: 'Edit Description',
      disableBulkAction: true,
      onAction: (item: CatalogEntityColumnTableItem) => {
        setIndexEditing(item.index);
        setEditedColumnName(item.name);
        setEditedColumnType(item.type);
        setEditedColumnDescription(item.description);
      },
    },
  ];

  const tableProps: IElxTableProps = {
    columns: columns,
    items: entityColumnTableItems,
    checkboxVisibility: CheckboxVisibility.hidden,
    styles: tableStyles,
    actions: schemaActions,
  };

  const gdprStatus = entity.GDPRScope || 'of unknown status';

  return (
    <Stack {...entityDetailsStackStyles}>
      <Stack.Item>
        <Label {...entityDetailsLabelStyles}> Privacy Information</Label>
        <MessageBar
          messageBarType={MessageBarType.info}
          styles={{ root: { width: '85%' } }}
        >
          This entity is <b>{gdprStatus}</b> for GDPR.
        </MessageBar>
      </Stack.Item>
      <Stack.Item {...entityDetailsStackItemTableStyles}>
        <Label {...entityDetailsLabelStyles}> Schema</Label>
        <ElxTable {...tableProps} />
      </Stack.Item>
    </Stack>
  );
};
