import { RestApi } from 'api/restApi';
import { AAD_CLIENT_ID } from 'utils/sharedLibs';

/**
 * Represents a base class for LEWeb API clients.
 */
export abstract class LeWebApi extends RestApi {
  constructor() {
    super('/', AAD_CLIENT_ID);
  }
}

export default LeWebApi;
