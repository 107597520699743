import {
  Breadcrumb,
  IBreadcrumbItem,
  Panel,
  PanelType,
  Spinner,
  SpinnerSize,
  Stack,
  MessageBar,
  MessageBarType,
} from '@fluentui/react';
import {
  ElxActionButton,
  ElxPrimaryButton,
  ElxTextField,
} from '@elixir/components';
import { Project } from 'features/workspaces/models/project';
import { useCallback, useMemo, useRef, useState } from 'react';
import { LensLabel } from 'utils/lensLabel';
import { FormErrorMessageBar } from '../../workspaceHelperComponents';
import {
  breadCrumbStyles,
  descriptionTextStyle,
  panelContentStackStyle2,
  panelStyles,
  stackItemFormStyles,
  textInputStyle,
  titleTextStyle,
  wideSpacingStackTokens,
} from '../../workspaceStyles';
import {
  ComponentType,
  OrchestrationWorkspaceSettingsObj,
  Tooltips,
} from '../workspaceSettingsUtil';
import { Editor } from 'components/monacoEditor';
import {
  editWorkspace,
  loadEditWorkspace,
  loadProject,
  selectOrchestratorEditProject,
} from 'features/workspaces/workspaceSlice';
import { useDispatch, useSelector } from 'react-redux';
import logger from 'features/appInsights/lensLogger';
import { LensTelemetryConstants } from 'features/appInsights/appInsightsLibs';
import { AppDispatch } from 'app/lensShellUtility';
import workspacesApi from 'features/workspaces/api/workspacesApi';
import serviceTreeApi from 'features/serviceTree/api/serviceTreeApi';
import { debounce } from 'utils/debounceUtils';

interface OrchestrationWorkspaceSettingsProps {
  data: OrchestrationWorkspaceSettingsObj;
  project: Project;
  updateOrchestratorProject: (project: Project) => void;
  onChange: (event: any) => void;
  showError: any; //error object from react hook form
  closeQuickSwitchPanel: () => void;
  closeWizard: () => void;
}
const OrchestrationWorkspaceSettings = (
  props: OrchestrationWorkspaceSettingsProps
): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();

  const workspace = useSelector(editWorkspace);
  const orchProject = useSelector(selectOrchestratorEditProject);
  const [showProjectCode, setShowProjectCode] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [enablingOrch, setEnablingOrch] = useState(false);
  const serviceTreeId = useRef('');
  const [serviceName, setServiceName] = useState('');
  const [isServiceValid, setIsServiceValid] = useState(false);

  const serviceGuidRequired = useMemo(() => {
    return (
      workspace.dsoProject === undefined ||
      workspace.dsoProject === '' ||
      orchProject.serviceGuid === undefined ||
      (workspace.dsoProject !== undefined &&
        orchProject.serviceGuid === undefined)
    );
  }, [orchProject.serviceGuid, workspace.dsoProject]);

  const [showGeneralPage, setShowGeneralPage] = useState(!serviceGuidRequired);

  const workspaceSettingsFormData = [
    {
      componentType: ComponentType.ElxTextField,
      label: 'Orchestrator Project Name ',
      onRenderLabel: () => (
        <LensLabel
          labelText="Orchestrator Project Name"
          hintText={Tooltips.orchestratorProjectName}
          required={false}
        ></LensLabel>
      ),
      defaultValue: props.data.projectName,
      disabled: true,
      labelActions: [
        <>
          <ElxActionButton
            iconProps={{
              iconName: 'code',
              title: 'View Code',
              'aria-label': 'View Code',
            }}
            text="View Code"
            onClick={() => {
              //launch monaco editor
              setShowProjectCode(true);
            }}
          />
        </>,
      ],
      styles: textInputStyle,
    },
    {
      componentType: ComponentType.ElxTextField,
      label: 'Orchestrator Project Display Name ',
      onRenderLabel: () => (
        <LensLabel
          labelText="Orchestrator Project Display Name"
          hintText={Tooltips.orchestratorProjectDisplayName}
          required={false}
        ></LensLabel>
      ),
      defaultValue: props.data.projectDisplayName,
      disabled: true,
      onChange: (event: any, value: string | undefined) => {
        props.onChange({
          target: {
            name: 'updatedProjectDisplayName',
            value: {
              ...props.data,
              projectDisplayName: value,
            },
          },
        });
        props.updateOrchestratorProject({
          ...props.project,
          projectDisplayName: value || '',
        });
      },
      styles: textInputStyle,
    },
    {
      componentType: ComponentType.ElxTextField,
      label: 'Service Tree Service ',
      onRenderLabel: () => (
        <LensLabel
          labelText="Service Tree Service"
          hintText={Tooltips.serviceTreeService}
          required={false}
        ></LensLabel>
      ),
      defaultValue: props.data.serviceGuid || '',
      labelActions: [
        <ElxActionButton
          iconProps={{ iconName: 'Link12' }}
          text="Find Service Tree Service ID."
          onClick={() => {
            window.open('https://aka.ms/servicetree', '_blank');
          }}
        />,
      ],
      onChange: (event: any, value: string | undefined) => {
        props.onChange({
          target: {
            name: 'updatedServiceGuid',
            value: {
              ...props.data,
              serviceGuid: value,
            },
          },
        });
        props.updateOrchestratorProject({
          ...props.project,
          serviceGuid: value,
        });
      },
      styles: textInputStyle,
    },
    {
      componentType: ComponentType.ElxTextField,
      label: 'IcM Team Public Id ',
      onRenderLabel: () => (
        <LensLabel
          labelText="IcM Team Public Id"
          hintText={Tooltips.icMTeamPublicId}
          required={false}
        ></LensLabel>
      ),
      defaultValue: props.data.icMTeamPublicId || '',
      styles: textInputStyle,
      labelActions: [
        <ElxActionButton
          iconProps={{
            iconName: 'Link12',
            title: 'Look Up IcM Team Public Id',
            'aria-label': 'Look Up IcM Team Public Id',
          }}
          text="Look Up IcM Team Public Id"
          onClick={() => {
            window.open(
              'https://portal.microsofticm.com/imp/TenantAndTeamIdLookup.aspx',
              '_blank'
            );
          }}
        />,
      ],
      onChange: (event: any, value: string | undefined) => {
        props.onChange({
          target: {
            name: 'updatedIcmTeamPublicId',
            value: {
              ...props.data,
              icMTeamPublicId: value,
            },
          },
        });
        props.updateOrchestratorProject({
          ...props.project,
          icMTeamPublicId: value,
        });
      },
    },
  ];

  const compToRender = useCallback(
    (componentData) => {
      switch (componentData.componentType) {
        case 'ElxTextField':
          return (
            <ElxTextField
              label={componentData.label}
              disabled={componentData.disabled || serviceGuidRequired || false}
              labelActions={componentData.labelActions || []}
              onRenderLabel={componentData.onRenderLabel}
              defaultValue={componentData.defaultValue}
              onChange={componentData.onChange}
            />
          );
        default:
          return <p>Unsupported component - error</p>;
      }
    },
    [serviceGuidRequired]
  );

  const numberOfColumns = 1;
  const inputsPerColumn = Math.ceil(
    workspaceSettingsFormData.length / numberOfColumns
  );
  let inputComponents: JSX.Element[] = [];
  let index = 0;
  for (
    let columnIndex = 0;
    columnIndex < numberOfColumns && index < workspaceSettingsFormData.length;
    columnIndex++
  ) {
    let children: JSX.Element[] = [];

    for (index; index < inputsPerColumn * (columnIndex + 1); index++) {
      const componentData = workspaceSettingsFormData[index];

      children.push(
        <Stack.Item key={index} {...stackItemFormStyles}>
          {compToRender(componentData)}
        </Stack.Item>
      );
    }

    inputComponents[columnIndex] = (
      <Stack.Item grow key={columnIndex}>
        {children}
      </Stack.Item>
    );
  }

  const items: IBreadcrumbItem[] = [
    {
      text: 'Workspace management',
      key: 'workspaceManagement',
      onClick: () => {
        props.closeWizard();
      },
    },
    {
      text: 'Orchestration Settings',
      key: 'orchestrationSettings',
      onClick: () => {
        setShowProjectCode(false);
      },
    },
    {
      text:
        props.data.projectDisplayName + ' - Geneva Orchestrator Project Code',
      key: 'showProjectCode',
      onClick: () => {},
    },
  ];

  const getServiceById = (serviceGuid: string) => {
    return serviceTreeApi
      .getServiceById(serviceGuid)
      .then((services) => {
        setIsServiceValid(true);
        setServiceName(services[0].Name);
      })
      .catch(() => {
        setServiceName('Invalid Service Tree service');
        setIsServiceValid(false);
      });
  };

  const debouncedGetService = debounce(
    (serviceGuid: string) => getServiceById(serviceGuid),
    500
  );

  return (
    <Stack.Item>
      <Stack>
        <Stack.Item>
          <FormErrorMessageBar
            showError={props.showError}
            errorMsg="OrchestrationWorkspaceSettings error"
          />
        </Stack.Item>

        <Stack.Item>
          <Panel
            type={PanelType.large}
            isOpen={showProjectCode}
            onDismiss={props.closeQuickSwitchPanel}
            styles={panelStyles}
            onRenderHeader={() => {
              return (
                <Breadcrumb
                  items={items}
                  maxDisplayedItems={2}
                  ariaLabel="Workspace data connection breadcrumb with items rendered as buttons"
                  overflowAriaLabel="More links"
                  styles={breadCrumbStyles}
                />
              );
            }}
            isFooterAtBottom={true}
          >
            <Stack verticalFill>
              <Editor
                language="json"
                value={JSON.stringify(props.project, null, 4)}
                readOnly={true}
              ></Editor>
            </Stack>
          </Panel>
        </Stack.Item>

        {serviceGuidRequired && !enablingOrch && (
          <Stack {...panelContentStackStyle2}>
            {errorMsg && (
              <MessageBar
                messageBarType={MessageBarType.error}
                isMultiline={true}
              >
                {errorMsg}
              </MessageBar>
            )}

            <Stack.Item {...stackItemFormStyles}>
              <div style={titleTextStyle}>Enable workspace orchestration:</div>
              <div style={descriptionTextStyle}>
                Service Tree Service ID is now required for workspaces with
                Orchestration enabled. To enable/re-enable workspace
                orchestration, enter your Service Tree Service ID below.
              </div>
            </Stack.Item>
            <Stack.Item {...stackItemFormStyles}>
              <ElxTextField
                placeholder="Service Tree Service ID"
                onChange={(ev, value) => {
                  serviceTreeId.current = value + '';
                  if (value + '' !== '') {
                    setServiceName('Retrieving service name...');
                  } else {
                    setIsServiceValid(false);
                    setServiceName('');
                  }
                  debouncedGetService(serviceTreeId.current);
                }}
                label="Service Tree Service ID"
                labelActions={[
                  <ElxActionButton
                    iconProps={{ iconName: 'Link' }}
                    text="Find Service Tree Service ID."
                    onClick={() => {
                      window.open('https://aka.ms/servicetree', '_blank');
                    }}
                  />,
                ]}
              />
              <p style={descriptionTextStyle}>{serviceName}</p>
            </Stack.Item>
            <Stack.Item align="end">
              <ElxPrimaryButton
                text="Enable"
                disabled={!isServiceValid}
                onClick={async () => {
                  logger.event(
                    LensTelemetryConstants.EventNames.WorkspaceActions
                      .EnableOrchestration,
                    { workspaceId: workspace.id }
                  );
                  setEnablingOrch(true);
                  if (workspace.dsoProject && workspace.dsoProject !== '') {
                    await workspacesApi
                      .updateWorkspaceConfiguration(workspace.id, {
                        ...orchProject,
                        serviceGuid: serviceTreeId.current || '',
                      })
                      .then(
                        () => {
                          dispatch(loadEditWorkspace(workspace.id)).then(() => {
                            dispatch(
                              loadProject({
                                workspaceId: workspace.id,
                                isEditWorkspace: true,
                              })
                            ).then(() => {
                              setEnablingOrch(false);
                              setShowGeneralPage(true);
                            });
                          });
                        },
                        (error) => {
                          setServiceName('');
                          setErrorMsg(error.response.data.error.message);
                          setEnablingOrch(false);
                        }
                      );
                  } else {
                    await workspacesApi
                      .postEnableOrchestration(
                        workspace.id,
                        serviceTreeId.current || ''
                      )
                      .then(
                        () => {
                          dispatch(loadEditWorkspace(workspace.id)).then(() => {
                            dispatch(
                              loadProject({
                                workspaceId: workspace.id,
                                isEditWorkspace: true,
                              })
                            ).then(() => {
                              setEnablingOrch(false);
                              setShowGeneralPage(true);
                            });
                          });
                        },
                        (error) => {
                          setErrorMsg(error.response.data.error.message);
                          setEnablingOrch(false);
                        }
                      );
                  }
                }}
              />
            </Stack.Item>
          </Stack>
        )}

        {enablingOrch ? (
          <Spinner
            label="Enabling orchestration..."
            size={SpinnerSize.medium}
          />
        ) : null}

        {showGeneralPage && (
          <Stack.Item grow>
            <Stack horizontal tokens={wideSpacingStackTokens}>
              {inputComponents}
            </Stack>
          </Stack.Item>
        )}
      </Stack>
    </Stack.Item>
  );
};

export default OrchestrationWorkspaceSettings;
