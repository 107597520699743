import { _ } from 'utils/sharedLibs';
import { utilities } from 'utils/commonUtilities';
import { $laConstants } from 'utils/constants';
import kustoQueryHelper from './kustoQueryHelper';
import timefilter from 'utils/timefilter';
import filterPlacementService from 'utils/filterPlacementService';
import parameterService from 'utils/parameterService';
import kustoRenderService from './kustoRenderService';

//#region Ported over from search.js
var separator = $laConstants.KustoSeparator;
var filter = "";

/**
 * Process aggregations if there are any, or use count operator
 * @returns {string} constructed query
 */
function constructAggregationsOrUseCountOperator(fetchParams, queryString) {
    var timefilterQuery = null;
    var timeBounds = null;
    var hasTimeValue = timefilter.hasTimeValue();
    var tempTableName = null;

    if (hasTimeValue) {
        timeBounds = timefilter.getBounds();
    }

    // If there is a timefield selected and the timefilter has value set, then construct a timefilter query
    if (fetchParams.body.timefield && fetchParams.body.timefield.length > 0 && hasTimeValue) {
        timefilterQuery = filterPlacementService.getTimefilterQuery(fetchParams.body.timefield, timeBounds);
    }

    if (!fetchParams.body.query) {
        throw new Error("query cannot be empty.");
    }
    else { // Has query but no aggregations
        if (_.isEmpty(fetchParams.body.aggs)) {
            if (fetchParams.body.query) {
                if (fetchParams.body.query.query_string) {
                    if (fetchParams.body.query.query_string.query) {
                        if (fetchParams.body.query.query_string.query.trim().length !== 0) {
                            queryString = fetchParams.body.query.query_string.query;
                            queryString = filterPlacementService.processTimeFilter(queryString, timeBounds, timefilterQuery, fetchParams.body.ignoreTime);
                            queryString = queryString + separator + "count";
                        }
                    }
                }
            }
        }
        else {
            var useTopNWithJoin = false;

            if (fetchParams.body.query.query_string && fetchParams.body.query.query_string.query) {
                queryString = fetchParams.body.query.query_string.query;
            }

            if (fetchParams.body.query.filtered) {
                queryString = fetchParams.body.query.filtered.query.query_string.query;
                if (queryString) {
                    queryString = filterPlacementService.processTimeFilter(queryString, timeBounds, timefilterQuery, fetchParams.body.ignoreTime);

                    if (fetchParams.body.query.filtered.filter) {
                        queryString = filterPlacementService.processGenericFilterPlacement(queryString, fetchParams.body.query.filtered.filter);
                    }

                    useTopNWithJoin = fetchParams.body.aggs.indexOf($laConstants.LensTempTablePrefix) !== -1 && queryString.indexOf($laConstants.KustoManagementCommandPrefix) !== 0;
                }
            }
            else {
                if (fetchParams.body.query.query_string) {
                    queryString = fetchParams.body.query.query_string.query;
                }
                if (queryString) {
                    queryString = filterPlacementService.processTimeFilter(queryString, timeBounds, timefilterQuery, fetchParams.body.ignoreTime);
                }

                useTopNWithJoin = fetchParams.body.aggs.indexOf($laConstants.LensTempTablePrefix) !== -1;
            }

            // Append aggregations to query
            var querySuffixAggs = ""; // this is the kql string that will be appended to the query
            if (useTopNWithJoin) {
                // Use top-nested N query
                tempTableName = fetchParams.body.aggs.substring(0, fetchParams.body.aggs.indexOf(separator)).trim();
                queryString = processMultiStatements(queryString, tempTableName);
                querySuffixAggs = fetchParams.body.aggs;
            }
            else {
                if (fetchParams.body.removeUserQuery) {
                    queryString = this.processUserQuery(queryString);
                    querySuffixAggs = ";" + fetchParams.body.aggs;
                } else {
                    querySuffixAggs = separator + fetchParams.body.aggs;
                }
            }

            // provide aggs as placement if query wants it, otherwise append to the end
            if (queryString.match($laConstants.AggsPlacement.GenericPattern)) {
                queryString = queryString.replace($laConstants.AggsPlacement.GenericPattern, fetchParams.body.aggs);
            } else {
                queryString = queryString + querySuffixAggs;
            }
        }
    }

    // remove any lingering AggsPlacement, since they're only replaced if aggs exist.
    queryString = queryString.replace($laConstants.AggsPlacement.GenericPattern, '');

    queryString = filterPlacementService.removeUnnecessaryCharacters(queryString);

    // Replace possible ARGS
    queryString = parameterService.renderTemplate(queryString);

    // Set query limit
    if (queryString.indexOf($laConstants.KustoManagementCommandPrefix) !== 0) {
        if (!userQueryContainsTruncation(queryString)) {
            queryString = kustoQueryHelper.queryLimitStatementAsPrefix(fetchParams.body.maxrecords) + queryString;
        }
    }
    else { 
        // Exclude .create commands from getting the limit. '.create function' fails if you add a limit in the end.
        if (queryString.indexOf($laConstants.KustoManagementCreateCommand) === -1) {
            queryString += kustoQueryHelper.queryLimitStatementAsSuffix(fetchParams.body.maxrecords);
        }
    }

    return queryString;
}

// Process multi-statement in a user query. Get the last statement and wrap it inside a "let" statement. 
// This function assumes that the statements are in good order (only last statement does not start with let/set/restrict, all other statements start with let/set/restrict)
// This assumption is guaranteed by the _shouldUseTopNQuery function in plugins\visualize\saved_visualizations\_saved_vis.js file.
function processMultiStatements(queryString, tempTableName) {
    if (!queryString || !tempTableName) {
        return "";
    }

    // Remove ending ";" if there are any
    if (queryString.lastIndexOf($laConstants.KustoStatementDelimeter) === queryString.length - 1) {
        queryString = queryString.substring(0, queryString.length - 1);
    }

    if (queryString.indexOf($laConstants.KustoStatementDelimeter) > 0) {
        var statements = queryString.split(";");
        var lastStatement = statements[statements.length - 1].trim();
        var newStatement = "let " + tempTableName + "=" + lastStatement + ";";
        statements[statements.length - 1] = newStatement;
        queryString = statements.join($laConstants.KustoStatementDelimeter);
    }
    else {
        queryString = "let " + tempTableName + " = " + queryString + $laConstants.KustoStatementDelimeter;
    }

    return queryString;
}

/**  TODO :add description
 * Convert aggregations to Kusto query expressions
 * @param {} backendType
 * @param {} timefilter 
 * @param {} fetchParams 
 * @returns {string} Kusto query
 */
function constructKustoQuery(backendType, timefilter, fetchParams) {
    var queryString = "";
    var queryStringPromise;
    // TODO: Search ID in TelemetryProperties
    // var activeContext = $laTabsModels.Context;

    // For explore pane, append a limit of $laConstants.KustoDefaultLimit
    // TODO: Move the query mode logic to a saparate file
    if (fetchParams.requestApp === 'query') {
        queryStringPromise = Promise.resolve(this.getQueryFromParams(fetchParams));
    }
    else {
        queryStringPromise = Promise.resolve(constructAggregationsOrUseCountOperator(fetchParams, queryString));
    }

    return queryStringPromise.then(function (queryString) {
        // Construct Kusto query object
        var kustoQuery = {
            Type: backendType,
            Cluster: fetchParams.body.cluster,
            Database: fetchParams.body.database,
            Query: queryString,
            TelemetryProperties: { /* SearchId: activeContext.currentSearchGuid */ }
        };

        if (_.isNumber(fetchParams.seriesId)) {                       // Only in case of multiseries.
            kustoQuery.SeriesId = fetchParams.seriesId;
        }

        return kustoQuery;
    });
}


/**
 * @description Test if the query string contains Kusto truncation statements (set notruncation, or set truncationmaxsize, or set truncationmaxrecords, or set query_take_max_records)
 * @param {string} queryString - User query string
 * @returns {boolean} - True if the given query string contains Kusto truncation statements
 */
function userQueryContainsTruncation(queryString) {
    var result = false;
    var i = 0;
    var prefix = null;

    for (i = 0; i < $laConstants.KustoTruncationPrefixes.length; i++) {
        prefix = $laConstants.KustoTruncationPrefixes[i];

        if (prefix.test(queryString)) {
            result = true;
            break;
        }
    }

    return result;
}
//#endregion Ported over from search.js

// Ensure that filter.query is set
function setFilterAsQuery(filters) {
    var filterGroup = [];
    var filterQueryPart = '';
    _.each(filters, function (filter) {
        if (filter) {
            kustoQueryHelper.setFilterQueryInFilter(filter);
            filterGroup.push("where " + filter.query);
        }
    });

    if (filterGroup.length > 0) {
        filterQueryPart = filterGroup.join(' | ');
    }

    return filterQueryPart;
}

/**
 * @name KustoClientUtils
 * @description
 * Represents Kusto client utilities.
 * **Note** - these utilities should only be used within the Kusto clients.
 */
class KustoClientUtils {
    /**
     * @name transformArmQueryError
     * @description
     * Transforms a Kusto query error from ApplicationInsights or OMS invoked via ARM
     * Error explanations come from: 
     *     https://dev.applicationinsights.io/documentation/Using-the-API/Errors
     *     https://dev.loganalytics.io/documentation/Using-the-API/Errors
     * @param {Object} err The Kusto query error.
     */
    transformArmQueryError (err) {
        if (!err) {
            return;
        }

        if(!_.isObject(err)){
            return;
        }

        // Authentication error while trying to acquire an access token
        if (err.status === 401 && err.statusText === $laConstants.SessionIsExpiredMessage) {
            return;
        }

        // No Authentication Provided
        if (err.status === 401 && err.data && err.data.error.code === "AuthenticationFailed") {
            err.statusText = $laConstants.AccessDenied;
            err.statusDetails = err.status + ': ' + err.data.error.code;
            return;
        }

        if (err.status === 403) {
            // Access denied error
            if (err.status === 403) {
                err.statusText = $laConstants.AccessDenied;
                err.statusDetails = JSON.stringify({
                    "Status": err.status,
                    "Error": err.data.error
                });
                return;
            }
        }

        // Path not found
        if (err.status === 404 && err.data && err.data.error.code === "PathNotFoundError") {
            err.statusDetails = JSON.stringify({
                "Status": err.status,
                "Error": err.data.error
            });
            return;
        }

        // Request throttling error
        if (err.status === 429) {
            err.statusText = $laConstants.ThrottlingErrorMessage;
            err.statusDetails = err.data.message;
            return;
        }

        // Connection error
        if (err.status === -1 || err.status === 502) {
            err.statusText = err.statusText || $laConstants.ConnectionErrorMessage;
            err.statusDetails = $laConstants.QueryErrorMessage + '\n\n' + $laConstants.ConnectionErrorMessageDetails;
            return;
        }

        // Kusto error
        if (err.status === 400 && err.data) {

            // Kusto error with message
            if (err.data.error && err.data.error.message) {
                var statusDetails = err.data.error.message;

                // Get the inner error if available
                if (err.data.error.innererror && err.data.error.innererror.message) {
                    statusDetails = err.data.error.innererror.message;
                }

                // Syntax or semantic error
                if (0 === statusDetails.toLowerCase().indexOf($laConstants.ErrorMessagePrefix.SyntaxError.toLowerCase()) ||
                    0 === statusDetails.toLowerCase().indexOf($laConstants.ErrorMessagePrefix.SemanticError.toLowerCase())) {
                    err.statusText = $laConstants.SyntaxErrorMessage;
                    err.statusDetails = "Please Check for extra spaces, punctuation, or spelling errors in the query: \n\n" +
                        JSON.parse(err.config.data).query;
                    err.level = utilities.queryErrorLevel.Warning;
                    return;
                }

                // Other Kusto error message
                err.statusText = err.data.error.message;
                err.statusDetails = statusDetails;
                return;
            }

            // Other Kusto error
            err.statusText = err.statusText || $laConstants.QueryErrorMessage;
            err.statusDetails = _.isArray(err.data) ? _.uniq(err.data).join('\n') : err.data;
            return;
        }

        // Connection error
        if (err.status === -1 || err.status === 502) {
            err.statusText = err.statusText || $laConstants.ConnectionErrorMessage;
            err.statusDetails = $laConstants.QueryErrorMessage + '\n\n' + $laConstants.ConnectionErrorMessageDetails;
            return;
        }
        
        err.statusText = err.statusText || $laConstants.QueryErrorMessage;
        err.statusDetails = err.statusDetails || '';

    };

    /**
     * @name transformSchemaResponseToSchemaTree
     * @description
     * Transforms a Kusto schema response into a schema tree.
     * @param {Object} response The Kusto schema response.
     * @returns {Promise} The schema tree - unsorted.
     */
    transformSchemaResponseToSchemaTree (response) {
        var rows = _.get(response, 'Response.Tables[0].Rows', []);
        if (_.isEmpty(rows)) return []; // return empty if none

        // Convert the schema to a tree of tables and fields.  The schema tree will be cached, so minimal properties should be set here.
        // monaco-kusto: Some properties are used for Kusto IntelliSense.
        var tree = {};

        _.each(rows, function (row) {
            var tableName = row[0];
            var columnName = row[1];
            var columnType = row[2];
            var folder = row[3];
            if (!tableName) {
                return;
            }

            // Get the table, and add it if it does not exist.
            var table = tree[tableName];// Kusto object names are case-sensitive

            if (!table) {
                table = {
                    name: tableName,
                    type: $laConstants.SchemaTypes.Table,
                    children: []
                };
                tree[tableName] = table;
            }

            // Add the table folder.
            if (tableName && !columnName && !columnType && folder) {
                table.folder = folder;
            }

            // Add the field.
            if (columnName && columnType) {
                var field = {
                    name: columnName,
                    type: columnType.replace(/^System\./i, ''), // remove "System." prefix
                    Type: columnType // Kusto type, for example System.String
                };
                table.children.push(field);
            }
        });

        // Convert the tree to a schema tree of folders and nodes.  If the tree has any folders, the folders are
        // rooted at a Tables node, which is a sibling of tables without folders. This is compat with Kusto.Explorer.
        var root = { children: [] };
        var tablesFolder = { name: 'Tables', type: $laConstants.SchemaTypes.Folder, children: [] };
        this.convertFlatTreeToSchemaTree(_.toArray(tree), root, tablesFolder);
        if (tablesFolder.children.length > 0) {
            root.children = _.concat([tablesFolder], root.children);
        }
        return root.children;
    };

    /**
     * @name convertFlatTreeToSchemaTree
     * @description
     * Transforms a flat tree of nodes into a schema tree of folders and nodes.
     * @param {Array} tree The flat tree of nodes.
     * @param {Object} root The root node of the schema tree.
     * @param {Object} folderRoot The root node of the folders. Some schema trees put folders under a different node than the tree root.
     *                            If null, the tree root is the folder root.
     */
    convertFlatTreeToSchemaTree (tree, root, folderRoot = undefined) {
        folderRoot = folderRoot || root;

        _.each(tree, function (node) {
            // Find the parent of the node by following the folder path, for example 'First Level\\Second Level'.
            var parent = root;
            if (node.folder) {
                var folderParent = folderRoot;
                var folderNames = _.split(node.folder, '\\');
                _.each(folderNames, function (folderName) {
                    var folder = _.find(folderParent.children, { name: folderName, type: $laConstants.SchemaTypes.Folder }); // Kusto object names are case-sensitive

                    if (!folder) {
                        folder = { name: folderName, type: $laConstants.SchemaTypes.Folder, children: [] };
                        folderParent.children.push(folder);
                    }

                    parent = folderParent = folder;
                });
            }

            parent.children.push(node);
        });
    };

    /**
     * @name processUserQuery
     * @description
     * Returns processed userQuery which handles the case when user's Kusto query has let statements.
     * @returns {string} - Returns string.
     */
    processUserQuery (queryString) {
        queryString = "let queryToAnalyze=(){" + queryString + "}";
        return queryString;
    };

    /**
     * @name constructQuery
     * @description Translate a base query plus aggregations and filters to a query expressions
     * @param {string} backendType Back end type
     * @param {Object} timefilter Time filter
     * @param {Object} fetchParams Fetch parameters
     * @returns {string} Query
     */
    constructQuery (backendType, timefilter, fetchParams) {
        return constructKustoQuery(backendType, timefilter, fetchParams);
    };

    /**
     * @name getQueryFromParams
     * @description Translate paramters to kusto query string
     * @param {Object} fetchParams Fetch parameters
     * @returns {string} Query String
     */
    getQueryFromParams (fetchParams) {
        var queryString = "";
        var timefilterQuery = "";
        var timeBounds = null;

        filter = ""; // Delete filter from previous invocation

        var hasTimeValue = timefilter.hasTimeValue();

        if (hasTimeValue) {
            timeBounds = timefilter.getBounds();
        }

        // Construct a timefilter query if timefield is set and timefilter has timevalue set
        if (fetchParams.body.timefield && fetchParams.body.timefield.length > 0 && hasTimeValue) {
            timefilterQuery = filterPlacementService.getTimefilterQuery(fetchParams.body.timefield, timeBounds);
        }

        if (fetchParams.body.query) {
            if (fetchParams.body.query.query_string) {
                if (fetchParams.body.query.query_string.query && fetchParams.body.query.query_string.query.length !== 0) {
                    queryString = fetchParams.body.query.query_string.query;
                    // Handle .alter query
                    if (queryString.trim().indexOf($laConstants.KustoManagementAlterCommand) === 0) {
                        return queryString.trim();
                    }
                    queryString = filterPlacementService.processTimeFilter(queryString, timeBounds, timefilterQuery, fetchParams.body.ignoreTime);
                }
            }
            else {
                if (fetchParams.body.query.filtered) {
                    filter = fetchParams.body.query.filtered.filter || filter;

                    if (fetchParams.body.query.filtered.query && fetchParams.body.query.filtered.query.query_string && fetchParams.body.query.filtered.query.query_string.query) {
                        queryString = fetchParams.body.query.filtered.query.query_string.query;
                        queryString = filterPlacementService.processTimeFilter(queryString, timeBounds, timefilterQuery, fetchParams.body.ignoreTime);

                        // In query filter placement if place holder detected
                        if (filter.length > 0) {
                            queryString = filterPlacementService.processGenericFilterPlacement(queryString, filter);
                        }
                    }
                }
            }
        }

        queryString = filterPlacementService.removeUnnecessaryCharacters(queryString);

        // Replace possible ARGS
        queryString = parameterService.renderTemplate(queryString);

        if (!fetchParams.body.maxrecords) {
            fetchParams.body.maxrecords = $laConstants.QueryTableQueryLimit;
        }

        // Set query limit
        if (queryString.indexOf($laConstants.KustoManagementCommandPrefix) !== 0) {
            if (!userQueryContainsTruncation(queryString)) {
                queryString = kustoQueryHelper.queryLimitStatementAsPrefix(fetchParams.body.maxrecords) + queryString;
            }
        }
        else {
            // Exclude .create commands from getting the limit. '.create function' fails if you add a limit in the end.
            if (queryString.indexOf($laConstants.KustoManagementCreateCommand) === -1) {
                queryString += kustoQueryHelper.queryLimitStatementAsSuffix(fetchParams.body.maxrecords);
            }
        }

        return queryString;
    };

    //#region ported over from editor.js
    createQuery (backendType, timefilter, savedVis, searchSource, isQuickEditMode, querySelectionText) {
        var cluster = savedVis.savedSearch ? savedVis.savedSearch.searchSource.get($laConstants.SearchSource.Cluster) : searchSource.get($laConstants.SearchSource.Cluster);
        var database = savedVis.savedSearch ? savedVis.savedSearch.searchSource.get($laConstants.SearchSource.Database) : searchSource.get($laConstants.SearchSource.Database);
        var query = null;
        var filters = [];
        var timeBounds = null;
        var hasTimeValue = timefilter.hasTimeValue();

        if (hasTimeValue) {
            timeBounds = timefilter.getBounds();
        }

        // In some cases (e.g. from _saved_vis.js), even in a non-savedSearch case, savedVis.searchSource isn't set, so set it from the passed in value
        if (!savedVis.savedSearch && !savedVis.searchSource && searchSource)
        {
            savedVis.searchSource = searchSource;
        }
        // For saved visual, get the query directly from the search source
        if (!savedVis.isNewObject) {
            if (savedVis.savedSearch) {
                query = querySelectionText || savedVis.savedSearch.searchSource.get('query') || '';
                filters = _.cloneDeep(savedVis.savedSearch.searchSource._flattenFilter() || []);
            }
            else {
                query = querySelectionText || savedVis.searchSource.get('query') || '';
                filters = _.cloneDeep(savedVis.searchSource._flattenFilter() || []);
            }
        }
        else {
            if (!isQuickEditMode) {
                if (savedVis.savedSearch) {
                    query = querySelectionText || savedVis.savedSearch.searchSource.get('query') || '';
                    filters = _.cloneDeep(savedVis.savedSearch.searchSource._flattenFilter() || []);
                }
                else {
                    // TODO: pull current query from $laTablsModels.Discover
                    query = querySelectionText /* || $laTabsModels.Discover && $laTabsModels.Discover.content */ || savedVis.searchSource.get('query') || '';
                    filters = _.cloneDeep(savedVis.searchSource._flattenFilter() || []);
                }
            }
            else {
                query = querySelectionText || savedVis.searchSource.get('query');
                filters = _.cloneDeep(savedVis.searchSource._flattenFilter() || []);
            }
        }

        // Ensure that filter.query is set
        _.each(filters, function(filter) {
            if (filter) {
                kustoQueryHelper.setFilterQueryInFilter(filter);
            }
        });

        if (query) {
            query = filterPlacementService.processIndividualFilterPlacement(query, filters);
            query = filterPlacementService.processTimeFilter(query, timeBounds, null, false);
            query = filterPlacementService.removeUnnecessaryCharacters(query);
        }

        // Remove render from query
        query = kustoRenderService.removeRenderFromQuery(query);

        var queryInfo = {
            Type: backendType,
            Cluster: cluster,
            Database: database,
            Query: query
        };

        return queryInfo;
    };
    //#endregion ported over from editor.js

    //#region ported over from _create_query.js, used by _saved_vis.js

    createQueryForSavedVis (backendType, timefilter, savedVis, searchSource, isDashboard, querySelectionText) {
        var cluster = savedVis.savedSearch ? savedVis.savedSearch.searchSource.get($laConstants.SearchSource.Cluster) : searchSource.get($laConstants.SearchSource.Cluster);
        var database = savedVis.savedSearch ? savedVis.savedSearch.searchSource.get($laConstants.SearchSource.Database) : searchSource.get($laConstants.SearchSource.Database);
        var query = null;
        var filters = [];
        var timefilterQuery = null;
        var timeBounds = null;
        var hasTimeValue = timefilter.hasTimeValue();
        var timefield = searchSource.get('timefield');

        if (hasTimeValue) {
            timeBounds = timefilter.getBounds();
        }

        if (timefield && timefield.length > 0 && hasTimeValue) {
            timefilterQuery = filterPlacementService.getTimefilterQuery(timefield, timeBounds);
        }

        if (savedVis.savedSearch) {
            query = savedVis.savedSearch.searchSource.get('query') || null;
            filters = _.cloneDeep(savedVis.savedSearch.searchSource.getOwn('filter') || []);
        }
        else {
            if (isDashboard) {
                query = searchSource.get('query');
                // TODO: Dashboard Filters, ack!
                // filters = _.cloneDeep(filterGroup.getFiltersByGroup(savedVis.vis) || []);
                filters = [];
            }
            else {
                query = querySelectionText;
                filters = _.cloneDeep(savedVis.searchSource.getOwn('filter') || []);
            }
        }

        if (query) {
            query = filterPlacementService.processTimeFilter(query, timeBounds, timefilterQuery, false);
            query = filterPlacementService.processIndividualFilterPlacement(query, filters);

            if (filters) {
                var filterQuery = setFilterAsQuery(filters);
                query = filterPlacementService.processGenericFilterPlacement(query, filterQuery);
                filters = [];
            }
            query = filterPlacementService.removeUnnecessaryCharacters(query);
        }

        if (setFilterAsQuery(filters)) {
            query = query + ' | ' + setFilterAsQuery(filters);
        }

        var queryInfo = {
            Type: backendType,
            Cluster: cluster,
            Database: database,
            Query: query
        };

        return queryInfo;
    };
    //#endregion ported over from _create_query.js, used by _saved_vis.js

    /**
     * @name getFieldValues
     * @description
     * Returns array of field values.
     * @param {Object} queryObject The Kusto query object.
     * @param {Object} getDataFunction function to get result.
     */
    getFieldValues (queryObject, getDataFunction) {
        var fieldValues = [];
        return getDataFunction(queryObject)
        .then(function (response) {
            _.each(response.Response.Tables[0].Rows, function (resp) {
                fieldValues.push(resp[0]);
            });
            return fieldValues;
        }).catch(function (err) {
            return Promise.reject('getFieldValues() function failed. ' + err);
        });
    };

    //#region ported over from _agg_fields.js
    getFieldsFromQuery (backendType, cluster, database, query, getDataFunction) {
        
        var queryObject = {
            Type: backendType,
            Cluster: cluster,
            Database: database,
            Query: this.removeLastSemiColon(query) + " | getschema | project ColumnName, DataType = replace('^System.', '', DataType)"
        };

        return getDataFunction(queryObject)
            .then(function (resp) {
                var data = resp.Response;
                var fieldArray = [];
                var id = 0;

                fieldArray = data.Tables[0].Rows.map(function (row) {
                    var fieldObject = {};
                    fieldObject.id = id;
                    fieldObject.name = row[0];
                    fieldObject.type = row[1];
                    fieldObject.count = data.Tables[0].Rows.length;
                    fieldObject.analyzed = false;

                    fieldObject.indexed = true;

                    fieldObject.scripted = false;

                    id++;

                    return fieldObject;
                });

                return Promise.resolve(fieldArray);
            });
    };
    //#endregion ported over from _agg_fields.js

    //#region ported over from topNestedQuery.js
    checkTopNestedRequired (aggregationMap, bucketItems) {
        var bucketType;

        if (bucketItems.length > 0) {
            bucketType = bucketItems[0].type.name;
        }

        return bucketType === 'terms';
    };

    /**
     * Gets the results from top nested query (main query) .
     */
    getTopNestedResults (savedVis, aggregationMap, queryInfo, getDataFunction) {
        var bucketItems = savedVis.vis.aggs.filter(function (agg) { return agg.schema.group === 'buckets'; });
        var metricColumnNames = [];
        var metricLimitByItem = savedVis.vis.aggs.getResponseAggById(bucketItems[0].params.orderBy);            // Getting Limit by metric to pass metricColumns to generateTopNestedQuery.
        metricColumnNames.push(savedVis.vis.aggs.getColumnName(metricLimitByItem));

        // Generate top-nested query for the main query of multiseries visualization. As generateTopNestedQuery uses the given bucket to get nested query, no need to pass aggregationMap with matched pairId.
        var nestedQuery = savedVis.vis.aggs.generateTopNestedQuery(bucketItems, metricColumnNames);
        nestedQuery = nestedQuery + ' | sort by ' + metricColumnNames[0] + ' | project ' + bucketItems[0].params.field.name;
        queryInfo.Query = queryInfo.Query + ' | ' + nestedQuery;

        var queryObject = {
            Type: queryInfo.Type,
            Cluster: queryInfo.Cluster,
            Database: queryInfo.Database,
            Query: queryInfo.Query
        };

        return getDataFunction(queryObject)
            .then(function (response) {
                var topNestedResult = [];
                _.each(response.Response.Tables[0].Rows, function (resp) {
                    topNestedResult.push("\"" + resp[0] + "\"");
                });

                if (topNestedResult.length === 0)
                {
                    return nestedQuery;
                }
                return topNestedResult;
            }).catch(function (err) {
                return Promise.reject(err);
            });
    };
    //#endregion ported over from topNestedQuery.js

    /**
     * @name this.removeLastSemiColon
     * @description
     * Removes any trailing semicolon in the query
     * @param {string} query The Kusto query string
     * @returns {string} formatted kusto query string
     */
    removeLastSemiColon (query) {
        if (!_.isNil(query)) {
            query = query.trim();
            if (_.endsWith(query, $laConstants.KustoStatementDelimeter)) {
                query = query.substring(0, query.length - 1);
            }
        }
        return query;
    };
};

export const kustoClientUtils = new KustoClientUtils();

export default kustoClientUtils;
