import {
  IStackTokens,
  IButtonStyles,
  HighContrastSelector,
  mergeStyleSets,
  memoizeFunction,
  ITheme,
} from '@fluentui/react';
import { useLensShellTheme } from 'features/shell/lensShellStyles';

export const customSplitButtonStyles = (theme: ITheme): IButtonStyles => {
  return {
    splitButtonMenuButton: {
      backgroundColor: theme.palette.white,
      width: 28,
      border: 'none',
    },
    splitButtonMenuIcon: { fontSize: '7px' },
    splitButtonDivider: {
      backgroundColor: '#c8c8c8',
      width: 1,
      right: 26,
      position: 'absolute',
      top: 4,
      bottom: 4,
    },
    splitButtonContainer: {
      selectors: {
        [HighContrastSelector]: { border: 'none' },
      },
    },
  };
};

// Tokens definition
export const innerStackTokens: IStackTokens = {
  childrenGap: 5,
  padding: 10,
};

export const getStyles = memoizeFunction(() => {
  const { palette } = useLensShellTheme();
  return mergeStyleSets({
    readMore: { color: palette.themeDarkAlt },
    card: {
      alignItems: 'left',
      border: '1px solid #EAEAEA',
      boxSizing: 'border-box',
      boxShadow: ' 0px 4px 8px rgba(0, 0, 0, 0.1)',
      borderRadius: 3,
      color: '#444444',
      display: 'flex',
      flexFlow: 'wrap',
      padding: 15,
      width: 404,
      minHeight: 146,
      marginBottom: 20,
      FontSizes: 13,
    },
    dateTime: {
      fontSize: 11,
      color: '#333333',
      display: 'flex',
    },
    header: { fontSize: 20, fontWeight: 600 },
    noNotification: {
      width: '100%',
      fontSize: 14,
      fontWeight: 400,
    },
    stack: {
      width: '100%',
    },
  });
});
