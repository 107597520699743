import { combineReducers } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import { connectRouter } from 'connected-react-router';
import { framework } from '@elixir/fx/lib/store/Reducers';

import userprofileReducer from 'features/userprofile/userprofileSlice';
import userPreferencesReducer from 'features/userInfo/userPreferencesSlice';
import userSavedObjectDataReducer from 'features/userInfo/userSavedObjectDataSlice';
import workspaceReducer from 'features/workspaces/workspaceSlice';
import catalogReducer from 'features/catalog/catalogSlice';
import serviceTreeReducer from 'features/serviceTree/servicesSlice';
import workspaceListReducer from 'features/workspaces/workspaceListSlice';
import notificationReducer from 'features/notifications/notificationsSlice';
import jobListReducer from 'features/orchestrator/jobSlice';
import datasetReducer from 'features/orchestrator/datasetSlice';
import grafanaOnboardingDataReducer from 'features/grafana/grafanaOnboardingSlice';
import grafanaMigrationReducer from 'features/convertToGrafana/grafanaMigrationSlice';

export const history = createBrowserHistory();

const reducer = combineReducers({
  router: connectRouter(history), // This reducer is needed for Elixir breadcrumbs
  userprofile: userprofileReducer,
  userPreferences: userPreferencesReducer,
  userSavedObjectData: userSavedObjectDataReducer,
  workspace: workspaceReducer,
  workspaceList: workspaceListReducer,
  catalog: catalogReducer,
  services: serviceTreeReducer,
  notifications: notificationReducer,
  job: jobListReducer,
  grafanaOnboarding: grafanaOnboardingDataReducer,
  grafanaMigration: grafanaMigrationReducer,
  dataset: datasetReducer,
  framework, // This reducer is needed for Elixir UI state managmenet.
});

export default reducer;

