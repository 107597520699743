import { IDropdownOption, Stack } from '@fluentui/react';
import React, { useState } from 'react';
import { LensLabel } from 'utils/lensLabel';
import { WorkspaceItem } from 'features/workspaces/utils/workspaceQuickSwitchUtils';
import { WorkspacePicker } from 'features/workspaces/components/workspacePicker';
import { scrollablePivotStyles } from 'features/workspaces/components/workspacesStyles';

interface selectWsProps {
  onChange: (ws: WorkspaceItem) => void;
  workspaceList: IDropdownOption[];
}

export function SelectWs(props: selectWsProps): JSX.Element {
  const [selectedWorkspaceName, setSelectedWorkspaceName] =
    useState<string>('');
  return (
    <Stack
      tokens={{
        childrenGap: 8,
        padding: 16,
        maxHeight: 800,
      }}
      styles={scrollablePivotStyles}
    >
      <Stack.Item>
        <LensLabel
          labelText={'Select Workspace'}
          hintText={'Select a workspace to copy the job to'}
          required={true}
        ></LensLabel>
        <p>
          {selectedWorkspaceName === ''
            ? `No workspace selected`
            : `Selected Workspace is : ${selectedWorkspaceName}`}
        </p>
      </Stack.Item>
      <WorkspacePicker
        onWorkspaceSelect={(wsItem: WorkspaceItem) => {
          setSelectedWorkspaceName(wsItem.name);
          props.onChange(wsItem);
        }}
      />
    </Stack>
  );
}
