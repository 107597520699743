import { DataSourceClient } from 'features/dataSources/client/dataSourceClient';
import { $laConstants, orchestratorConstants } from 'utils/constants';

class CosmosClient extends DataSourceClient {
  public readonly name = $laConstants.DataSourceTypes.COSMOS;
  public readonly title = 'Cosmos';
  public readonly icon = 'fa fa-cat';
  public readonly description = 'Cosmos data source.';

  public readonly monaco = {
    language: orchestratorConstants.LanguageId.Scopescript,
  };
  public readonly orchestration = {
    dataCommand: {
      endpointType: orchestratorConstants.EndpointTypes.CosmosVc,
      scriptLabel: 'Cosmos Scope',
    },
    dataMovement: {
      endpointTypes: [orchestratorConstants.EndpointTypes.CosmosStream],
      inputPartitioning: true,
      outputFolder: true,
      targetEndpointTypes: [
        orchestratorConstants.EndpointTypes.KustoTable,
        orchestratorConstants.EndpointTypes.SqlTable,
        orchestratorConstants.EndpointTypes.AzureBlob,
        orchestratorConstants.EndpointTypes.MdmMetric,
      ],
    },
    dataValidation: {
      inputEndpointTypes: [orchestratorConstants.EndpointTypes.CosmosStream],
    },
    t4Transform: {
      endpointType: orchestratorConstants.EndpointTypes.CosmosVc,
    },
    endpoint: {
      directive: 'cosmos-stream-job-endpoint',
  },
  };
  public get displayName() {
    return $laConstants.DataSourceClientDisplayNames.COSMOS;
  }

  public get dataSourceType() {
    return $laConstants.DataSourceTypes.COSMOS;
  }
}

export const cosmosClient = new CosmosClient();
export default cosmosClient;
