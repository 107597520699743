//export type UserprofileThemeColor = 'dark' | 'light'

export interface UserprofileFeatureSetting {
   Type: string
   IsEnabled: boolean
   IsOptIn: boolean
}

export interface UserprofileDataSource {
  Id?: number;
  Type: string;
  Cluster: string;
  Alias: string;
}

//export interface UserprofileFavoritedAsset {
//    Id: string
//    Title: string
//    Workspace: string
//    ShareType: string
//    Image: string
//    Params: object
//    Type: string
//    SubType: string
//    LoadFrom: string
//    AccessTime: string
//    IsSample: boolean
//}

//export interface UserprofileTable {
//    Entity: UserprofileTableEntity
//    Type: string
//    Props: string
//    AccessTime: string
//    FullyQualifiedName: string
//}

//export interface UserprofileTableEntity {
//    FullyQualifiedName: string
//}

//export interface UserprofileKustoTableEntity extends UserprofileTableEntity {
//    Type: string
//    Cluster: string
//    Database: string
//    Table: string
//}

//export interface UserprofileAppInsightsTableEntity extends UserprofileTableEntity {
//    AzureSubscriptionId: string
//    AzureResourceGroupName: string
//    AppInsightsSubscriptionId: string
//    Table: string
//    Type: string
//}

//export interface UserprofileLogAnalyticsTableEntity extends UserprofileTableEntity {
//    AzureSubscriptionId: string
//    AzureResourceGroupName: string
//    WorkspaceId: string
//    Table: string
//    Type: string
//}

export interface UserprofileJob {
  Id: string;
  Name: string;
  WorkspaceAlias: string;
  ShareType: string;
  AccessTime: string;
}

//export interface UserprofileSupportNotificationStatus {
//    NotificationId: string
//    Seen: boolean
//    Dismissed: boolean
//}

//export enum UserprofileTimeZones = {
//    UTC = 'UTC',
//    Local = 'Local',
//}

export enum UserprofileStatus {
  None = 'None',
  Loading = 'Loading',
  Loaded = 'Loaded',
  Error = 'Error',
}

export interface Userprofile {
  UPN: string;
  Name: string;
  //themeColorPreference: UserprofileThemeColor
  //disableAutoRunningQuery: boolean
  //trimSearchColumns: string
  FeatureSettings: UserprofileFeatureSetting[];
  DataSources: UserprofileDataSource[];
  // UserSelections: string
  // FavoritedWorkspaces: string; // Deprecated - refer to UserSavedObjectData
  // RecentWorkspaces: string; // Deprecated - refer to UserSavedObjectData
  // FavoriteDatasets: string; // Deprecated - refer to UserSavedObjectData
  //RecentVisSearches: string
  //FavoriteVisSearches: string
  //FavoritedDashboards: UserprofileFavoritedAsset[]
  //RecentDashboards: UserprofileFavoritedAsset[]
  //RecentTables: UserprofileTable[]
  //FavoriteTables: UserprofileTable[]
  //RecentJobs: UserprofileJob[]
  // FavoriteJobs: UserprofileJob[]; // Deprecated - refer to UserSavedObjectData
  //turnOffVisualAnalyzerTeaser: boolean
  //TimeZone: string
  SupportNotificationStatus: string;
  [propName: string]: any;
}

export default Userprofile;
