import { ElxPicker, IPickerItem } from '@elixir/components';
import { useCallback, useEffect, useState } from 'react';
import armClient from 'utils/armClient';
import { LensLabel } from 'utils/lensLabel';
import { SubscriptionInfo } from './subscriptionPicker';

interface RegionPickerProps {
  subscriptionInfo: SubscriptionInfo;
  selectedRegion: string;
  setRegion: (region: string) => void;
  hintText?: string;
}

export const RegionPicker = (props: RegionPickerProps): JSX.Element => {
  const { subscriptionInfo, selectedRegion, setRegion, hintText } = props;

  const [regionList, setRegionList] = useState<IPickerItem[]>([]);

  const fetchRegions = useCallback(() => {
    setRegionList([]);
    if (!subscriptionInfo.subscriptionId) return;
    Promise.all([
      armClient.fetchLocations(subscriptionInfo.subscriptionId),
      armClient.fetchGrafanaLocations(subscriptionInfo.subscriptionId),
    ]).then(([locations, grafanaLocationNames]) => {
      const grafanaRegions = grafanaLocationNames
        .map((grafanaLocationName) => {
          const location = locations.find(
            (l) => l.displayName === grafanaLocationName
          );
          return location
            ? { key: location.name, text: location.regionalDisplayName }
            : undefined;
        })
        .filter((r) => r !== undefined) as IPickerItem[];

      grafanaRegions.sort((a, b) => a.text.localeCompare(b.text));
      setRegionList(grafanaRegions);
    });
  }, [subscriptionInfo, setRegionList]);

  useEffect(() => {
    fetchRegions();
  }, [fetchRegions, subscriptionInfo]);

  return (
    <ElxPicker
      onRenderLabel={() => (
        <LensLabel
          labelText="Location"
          hintText={hintText || 'Choose the Azure region that is right for you'}
          required={true}
        ></LensLabel>
      )}
      items={regionList}
      selectedKey={selectedRegion}
      placeHolder="Select Azure region"
      calloutWidth={600}
      onChange={(item: IPickerItem) => {
        setRegion(item.key);
      }}
      noResultMessage={
        subscriptionInfo.subscriptionId
          ? 'No regions found for your subscription'
          : 'Please select a subscription first'
      }
    />
  );
};
