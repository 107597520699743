import React from 'react';
import HighChartsReact from 'highcharts-react-official';
import Highcharts, {
  AxisLabelsFormatterContextObject,
} from 'highcharts/highmaps';
import { DatasetMetrics } from 'features/orchestrator/models/dataset';
import { formatBytes } from './metricsDataset';

export enum MetricVisYAxis {
  Size = 'Size',
  Latency = 'Latency',
}

interface MetricVisProps {
  // Define your props here
  data: DatasetMetrics[];
  metricVisYAxis: MetricVisYAxis;
}

const MetricVis: React.FC<MetricVisProps> = (props) => {
  // Your component logic here

  const yLabel = 'Size';
  const chartTitle = 'Stream Details';
  const { data, metricVisYAxis } = props;

  const options = {
    chart: {
      type: 'spline',
    },
    credits: {
      enabled: false,
    },
    title: {
      text: chartTitle,
    },
    xAxis: {
      type: 'datetime',
      dateTimeLabelFormats: {
        // don't display the year
        month: '%e. %b',
        year: '%b',
      },
      title: {
        text: 'Stream Date',
      },
    },
    yAxis: {
      title: { text: yLabel },
      lineWidth: 1,
      lineColor: 'lightgrey',
      tickAmount: 5,
      numericSymbols: ['k', 'M', 'G', 'T', 'P', 'E'],
      labels: {
        formatter: function (
          this: AxisLabelsFormatterContextObject,
          ctx: AxisLabelsFormatterContextObject
        ) {
          return formatBytes(Number(this.value));
        },
      },
    },
    legend: {
      enabled: false,
    },
    series: [
      {
        name: yLabel,
        data: data.map((item) => [
          new Date(item.timestamp).getTime(),
          metricVisYAxis === MetricVisYAxis.Size ? item.size : item.slaDiff,
        ]),
      },
    ],
  };

  return (
    <>
      <HighChartsReact
        highcharts={Highcharts}
        options={options}
      ></HighChartsReact>
    </>
  );
};

export default MetricVis;
