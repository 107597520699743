import {
  deleteUserFavorites,
  loadUserRecents,
  saveUserFavorites,
  saveUserRecents,
} from '../../userInfo/userSavedObjectDataSlice';
import { AppDispatch } from 'app/lensShellUtility';
import UserSavedObjectData, {
  LensObjectType,
} from '../../userInfo/models/userSavedObjectData';
import { WorkspaceBeforeFormat } from '../models/workspace';
import { createUserSavedObjectData } from '../../userInfo/utils/userSavedObjectUtils';
import { Dictionary } from '@reduxjs/toolkit';

export interface WorkspaceItem {
  workspaceId: string;
  name: string;
  isFavorite: boolean;
  isRecent?: boolean;
  isCurrent?: boolean;
  createdBy: string;
  accessType: string;
  accessIcon: string;
  accessFilter: string;
  secureChangeManagement?: string;
  key?: string; // added this so that WorkspaceItem can be used as type for Selection object
}

/**
 * Check if object is an instance of WorkspaceItem
 * @param object check that needs to be checked
 * @returns boolean
 */
export const instanceOfWorkspaceItem = (
  object: any
): object is WorkspaceItem => {
  return !('shareType' in object); //WorkspaceItem should not have stareType
};

export const workspaceAccessFilter = (accessFilter: string) => {
  switch (accessFilter) {
    case 'owned': {
      return 'Owned by me';
    }
    case 'admin': {
      return 'Admin Access';
    }
    case 'orchestrate': {
      return 'Orchestrate Access';
    }
    case 'write': {
      return 'Write Access';
    }
    case 'read': {
      return 'Read Access';
    }
    case 'none': {
      return 'Unauthorized';
    }
    default: {
      return 'none';
    }
  }
};

export const workspaceAccessIcon = (accessFilter: string) => {
  switch (accessFilter) {
    case 'owned': {
      return 'Permissions';
    }
    case 'admin': {
      return 'Settings';
    }
    case 'orchestrate': {
      return 'BranchFork2';
    }
    case 'write': {
      return 'Edit';
    }
    case 'read': {
      return 'ReadingMode';
    }
    case 'none': {
      return 'ProtectRestrict';
    }
    default: {
      return 'none';
    }
  }
};

export const toggleFavorite = async (
  dispatch: AppDispatch,
  refresh: Function,
  workspaceItem: WorkspaceItem
) => {
  if (workspaceItem.name === 'Private Workspace') return;

  let workspaceUserSavedObjectData = createUserSavedObjectData(
    workspaceItem.workspaceId,
    workspaceItem.workspaceId,
    LensObjectType.Workspace
  );
  let userFavList: UserSavedObjectData[] = [workspaceUserSavedObjectData];

  if (workspaceItem.isFavorite === false) {
    // favoriting
    await dispatch(saveUserFavorites({ userSavedObjectDataList: userFavList }));
  } else {
    // unfavoriting
    await dispatch(
      deleteUserFavorites({ userSavedObjectDataList: userFavList })
    );
  }
  refresh();
};

export const updateRecents = async (
  dispatch: AppDispatch,
  workspaceId: string,
  workspaces: Dictionary<WorkspaceBeforeFormat>
) => {
  if (workspaceId.includes('private')) return;

  let workspaceUserSavedObjectData = createUserSavedObjectData(
    workspaceId,
    workspaceId,
    LensObjectType.Workspace
  );
  let userRecList: UserSavedObjectData[] = [workspaceUserSavedObjectData];

  await dispatch(saveUserRecents({ userSavedObjectDataList: userRecList }));

  dispatch(loadUserRecents({ workspaces: workspaces }));
};

export const getAllWorkspaces = (
  currentWorkspaceId: string,
  favoriteWorkspaces: UserSavedObjectData[],
  workspaceList: WorkspaceBeforeFormat[],
  privateWorkspace: WorkspaceItem
): WorkspaceItem[] => {
  let allWorkspaceList: WorkspaceItem[] = [];

  workspaceList.forEach((workspace: WorkspaceBeforeFormat) => {
    let isFavorite =
      (favoriteWorkspaces &&
        favoriteWorkspaces.find(
          (fav) =>
            fav.lensObjectType === LensObjectType.Workspace &&
            fav.workspaceId === workspace.id
        ) !== undefined) ||
      false;
    let isCurrent = workspace.id === currentWorkspaceId;

    let item: WorkspaceItem = {
      workspaceId: workspace.id,
      name: workspace.name,
      isFavorite: isFavorite,
      isCurrent: isCurrent,
      createdBy: workspace.createdBy,
      accessType: workspaceAccessFilter(workspace.accessFilter),
      accessIcon: workspaceAccessIcon(workspace.accessFilter),
      accessFilter: workspace.accessFilter,
      key: 'all-' + workspace.id,
    };

    if (isCurrent) {
      // Add current workspace to the top of the list (shown directly under private workspace)
      allWorkspaceList.unshift(item);
    } else {
      allWorkspaceList.push(item);
    }
  });

  // Always show Private Workspace at beginning of the list
  allWorkspaceList.unshift(privateWorkspace);

  return allWorkspaceList;
};

export const getFavoriteAndRecentWorkspaces = (
  currentWorkspaceId: string,
  favoriteWorkspaces: UserSavedObjectData[],
  recentWorkspaces: UserSavedObjectData[],
  workspaceEntities: Dictionary<WorkspaceBeforeFormat>,
  privateWorkspace: WorkspaceItem
): WorkspaceItem[] => {
  let favoritesAndRecentsList: WorkspaceItem[] = [];

  let recents = recentWorkspaces.flatMap((rec) => {
    let recWorkspaceId = rec.workspaceId;
    if (recWorkspaceId === undefined) return [];

    let workspace = workspaceEntities[recWorkspaceId];
    if (workspace === undefined) return [];

    let item: WorkspaceItem = {
      workspaceId: recWorkspaceId,
      name: workspace.name,
      isFavorite:
        favoriteWorkspaces.find((fav) => fav.workspaceId === recWorkspaceId) !==
        undefined,
      isCurrent: recWorkspaceId === currentWorkspaceId,
      createdBy: workspace.createdBy,
      accessType: workspaceAccessFilter(workspace.accessFilter),
      accessIcon: workspaceAccessIcon(workspace.accessFilter),
      accessFilter: workspace.accessFilter,
      key: 'favRec-' + workspace.id,
    };

    return item;
  });

  let favs = favoriteWorkspaces.flatMap((fav) => {
    let favWorkspaceId = fav.workspaceId;
    if (favWorkspaceId === undefined) return [];

    let workspace = workspaceEntities[favWorkspaceId];
    if (workspace === undefined) return [];

    if (workspace === undefined) return [];
    let item: WorkspaceItem = {
      workspaceId: favWorkspaceId,
      name: workspace.name,
      isFavorite: true,
      isCurrent: favWorkspaceId === currentWorkspaceId,
      createdBy: workspace.createdBy,
      accessType: workspaceAccessFilter(workspace.accessFilter),
      accessIcon: workspaceAccessIcon(workspace.accessFilter),
      accessFilter: workspace.accessFilter,
    };

    return item;
  });

  // remove recents from favs list and sort alphabetically
  favs = favs
    .filter((fav) => {
      let inRecents =
        recents.find((rec) => rec.workspaceId === fav.workspaceId) !==
        undefined;
      return !inRecents;
    })
    .sort((item1, item2) => (item1.name > item2.name ? 1 : -1));

  // recents shown before favorites
  favoritesAndRecentsList = recents.concat(favs);

  // Always show Private Workspace at beginning of both lists
  favoritesAndRecentsList.unshift(privateWorkspace);

  return favoritesAndRecentsList;
};
