import { ADLSGen2Selector } from 'features/workspaces/components/editDataConnection/dataSourceComponents/adlsGen2Selector';
import { SelectorProps } from 'features/workspaces/components/editDataConnection/editDataConnectionHelper';
import {
  ADLSGen2DataSource,
  ConnectionDataSourceType,
} from 'features/workspaces/models/project';
import adlsGen2Client from '../adlsGen2/adlsGen2Client';
import { registerDataSourcePlugin } from '../registry';
import { DataSourcePlugin } from './dataSourcePlugin';

class AdlsGen2Plugin extends DataSourcePlugin {
  public dataSourceFormComponent(selectorProps: SelectorProps): JSX.Element {
    return (
      <ADLSGen2Selector
        workspace={selectorProps.workspace}
        state={selectorProps.state}
        setState={selectorProps.setState}
        customNameFilled={selectorProps.customNameFilled}
      />
    );
  }

  public generatedName(state: ADLSGen2DataSource) {
    return state && state.account ? 'Adls.gen2.' + state.account : '';
  }
}

registerDataSourcePlugin(
  ConnectionDataSourceType.AzureDataLakeStorageGen2,
  new AdlsGen2Plugin(
    ConnectionDataSourceType.AzureDataLakeStorageGen2,
    adlsGen2Client
  )
);
