import { ElxSecondaryButton } from '@elixir/components';
import { Panel, PanelType, Stack, ThemeProvider } from '@fluentui/react';
import { Editor } from 'components/editor';
import { useLensShellTheme } from 'features/shell/lensShellStyles';
import { IRange } from 'monaco-editor';
import { useCallback, useEffect, useState } from 'react';

interface EditorFullProps {
  onChange: (value: string) => void;
  value: string;
  header?: string;
  onDismiss: () => void;
  language: string;
  range?: IRange;
  onViewActvityMode?: () => void;
}

const EditorFull = (props: EditorFullProps) => {
  const { onChange, value, header, onDismiss, language, range, onViewActvityMode } = props;
  const theme = useLensShellTheme();
  // height of the monaco editor - approx height of window - panel header height - panel footer height - buffer;
  const [height, setHeight] = useState(window.innerHeight); // window.innerHeight - 100 - 80 - 60;
  const editorHeight = height - 160 < 0 ? 1200 : height - 160;
  const heightHandler = useCallback(() => {
    setHeight(window.innerHeight);
  }, [setHeight]);
  useEffect(() => {
    window.addEventListener('resize', heightHandler);
    return () => {
      window.removeEventListener('resize', heightHandler);
    };
  });

  const onRenderFooterContent = useCallback(
    () => (
      <>
      {onViewActvityMode && (<ElxSecondaryButton
          text={'View Activity Mode'}
          onClick={() => {
            onViewActvityMode();
          }}
          styles={{ root: { marginLeft: '6px', marginRight: '6px' } }}
        ></ElxSecondaryButton>)}
        <ElxSecondaryButton
          text={'Close'}
          onClick={() => {
            onDismiss();
          }}
          styles={{ root: { marginLeft: '6px', marginRight: '6px' } }}
        ></ElxSecondaryButton>
      </>
    ),
    [onDismiss, onViewActvityMode]
  );

  return (
    <ThemeProvider theme={theme}>
      <Panel
        headerText={header || ''}
        isOpen={true}
        onDismiss={() => {
          onDismiss();
        }}
        isFooterAtBottom={true}
        closeButtonAriaLabel={'Close'}
        type={PanelType.custom}
        customWidth="1200px"
        onRenderFooterContent={onRenderFooterContent}
        hasCloseButton={true}
        isLightDismiss={true}
        styles={{
          footerInner: { float: 'right' },
          footer: {
            borderTop: '1px solid lightgrey',
            backgroundColor: theme.palette.white,
          },
          content: { background: 'white', padding: '0px' },
          main: { background: 'white !important' },
        }}
      >
        <Stack tokens={{ childrenGap: 16, padding: 16 }}>
          <Stack.Item verticalFill>
            <Editor
              language={language || 'text'}
              onChange={(value: string) => {
                onChange(value);
              }}
              value={value}
              height={editorHeight}
              range={range}
            ></Editor>
          </Stack.Item>
        </Stack>
      </Panel>
    </ThemeProvider>
  );
};

export default EditorFull;
