export enum GrafanaMigrationStatus {
  None = 'None',
  Loading = 'Loading',
  Loaded = 'Loaded',
  Error = 'Error',
}

export interface GrafanaFolder {
  id: string;
  uid: string;
  title: string;
}

export interface GrafanaFolderRequest {
  title: string;
}

export interface GrafanaMigrationRequest {
  dashboard: {};
  folderUid: string | null;
  message: string;
  overwrite: boolean;
}

export interface GrafanaMigrationResponse {
  id: string;
  slug: string;
  status: string;
  uid: string;
  url: string;
  version: string;
}

export interface GrafanaMigrationResult {
  id: string;
  uid: string;
  status: string;
  url: string;
  warnings: string[];
}

export interface GrafanaDatasourceResponse {
  datasources: GrafanaDatasource[];
}

export interface GrafanaDatasource {
  id: number;
  uid: string;
  name: string;
  type: string;
  jsonData: {};
}

export interface GrafanaKustoDatasource extends GrafanaDatasource {
  id: number;
  uid: string;
  type: 'grafana-azure-data-explorer-datasource';
  name: string;
  meta: {};
  url: string; // '/api/datasources/proxy/uid/DUbHYyf4z';
  isDefault: boolean;
  access: string; // 'proxy';
  preload: boolean; // false;
  module: string; // 'plugins/grafana-azure-data-explorer-datasource/module';
  jsonData: {
    azureCredentials: {
      authType: string; // 'currentuser';
    };
    clusterUrl: string; // 'https://genevareference.westcentralus.kusto.windows.net';
    dataConsistency: string; // 'strongconsistency';
    defaultDatabase: string; // 'Shared';
    defaultEditorMode: string; // 'visual';
    oauthPassThru: true;
  };
  readOnly: boolean; // false;
}

export interface GrafanaCreateKustoDatasourceRequest {
  name: string;
  type: 'grafana-azure-data-explorer-datasource';
  access: 'proxy';
  jsonData: {
    azureCredentials: {
      authType: 'currentuser';
    };
    clusterUrl: string; // 'https://genevareference.westcentralus.kusto.windows.net';
    defaultDatabase: string; // 'Shared';
    oauthPassThru: true;
  };
}

export interface GrafanaCreateKustoDatasourceResponse {
  id: number;
  name: string;
  message: string;
  datasource: GrafanaKustoDatasource;
}
