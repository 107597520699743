import { _ } from 'utils/sharedLibs';
import { RestApi } from 'api/restApi';
import { $laConstants } from './constants';
import { resourceIds } from './sharedLibs';

interface MetaDataCache {
  [key: string]: any;
}
/**
 * @name DatasourceMetadata
 * @description This service is responsible of fetching metadat information App Insights and Log Analytics.
 */
class DatasourceMetadata extends RestApi {
  constructor() {
    // super(appInsightsUrl, resourceIds.azureResourceManagerResourceId);
    super(
      window.startUpConfig.serviceEndpoints.azureResourceManagerUrl,
      resourceIds.azureResourceManagerResourceId
    );
  }

  private metaDataCache: MetaDataCache = {
    [$laConstants.DataSourceTypes.APPLICATIONINSIGHTS]: {},
    [$laConstants.DataSourceTypes.LOGANALYTICS]: {},
  };

  public getMetaData(type: string, id: string) {
    return new Promise<any>((resolve, reject) => {
      const query = {
        Cluster: id,
      };
      const queryString =
        'api-version=' +
        (type === $laConstants.DataSourceTypes.APPLICATIONINSIGHTS
          ? '2015-05-01'
          : '2015-11-01-preview');
      const url = _.template('https://<%=resourceUrl%>?<%=queryString%>')({
        resourceUrl: query.Cluster,
        queryString: queryString,
      });

      this.getEntity(url)
        .then((resp: any) => {
          this.metaDataCache[type][id] = resp.properties;
          resolve(this.metaDataCache[type][id]);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  // get application insights application id
  public getAppId(type: string, id: string) {
    return new Promise<any>((resolve, reject) => {
      var obj = this.metaDataCache[type];
      if (!_.isUndefined(obj[id])) {
        if (type === $laConstants.DataSourceTypes.APPLICATIONINSIGHTS) {
          resolve(obj[id].AppId);
        } else {
          resolve(obj[id].customerId);
        }
      } else {
        this.getMetaData(type, id).then(
          function () {
            if (type === $laConstants.DataSourceTypes.APPLICATIONINSIGHTS) {
              resolve(obj[id].AppId);
            } else {
              resolve(obj[id].customerId);
            }
          },
          (err: any) => {
            reject(err);
          }
        );
      }
    });
  }
}

export default DatasourceMetadata;
