import { _ } from 'utils/sharedLibs';
import { DateTime, Duration, DurationUnit } from 'luxon';

const unitsMap: { [unit: string]: DurationUnit } = {
  y: 'year',
  M: 'month',
  w: 'week',
  d: 'day',
  h: 'hour',
  m: 'minute',
  s: 'second',
};

class DateMath {
  public readonly units = ['y', 'M', 'w', 'd', 'h', 'm', 's'];
  public readonly unitsAsc = _.sortBy(this.units, function (unit) {
    return Duration.fromObject({ [unitsMap[unit]]: 1 }).valueOf();
  });
  public readonly unitsDesc = this.unitsAsc.reverse();

  /* This is a simplified version of elasticsearch's date parser */
  public parse(text?: Date | DateTime | string, roundUp = false) {
    if (!text) return undefined;
    if (DateTime.isDateTime(text)) return text;
    if (_.isDate(text)) return DateTime.fromJSDate(text);

    var time;
    var mathString = '';
    var index;
    var parseString;

    if (text.substring(0, 3) === 'now') {
      time = DateTime.utc();
      mathString = text.substring('now'.length);
    } else {
      index = text.indexOf('||');
      if (index === -1) {
        parseString = text;
        mathString = ''; // nothing else
      } else {
        parseString = text.substring(0, index);
        mathString = text.substring(index + 2);
      }
      // We're going to just require ISO8601 timestamps, k?
      time = DateTime.fromISO(parseString);
    }

    if (!mathString.length) {
      return time;
    }

    return this.parseDateMath(mathString, time, roundUp);
  }

  private parseDateMath(mathString: string, time: DateTime, roundUp = false) {
    var dateTime = time;

    for (var i = 0; i < mathString.length; ) {
      var c = mathString.charAt(i++);
      var type;
      var num;
      var unit;

      if (c === '/') {
        type = 0;
      } else if (c === '+') {
        type = 1;
      } else if (c === '-') {
        type = 2;
      } else {
        return undefined;
      }

      if (isNaN(parseInt(mathString.charAt(i)))) {
        num = 1;
      } else if (mathString.length === 2) {
        num = parseInt(mathString.charAt(i));
      } else {
        var numFrom = i;
        while (!isNaN(parseInt(mathString.charAt(i)))) {
          i++;
          if (i > 10) return undefined;
        }
        num = parseInt(mathString.substring(numFrom, i), 10);
      }

      if (type === 0) {
        // rounding is only allowed on whole, single, units (eg M or 1M, not 0.5M or 2M)
        if (num !== 1) {
          return undefined;
        }
      }
      unit = mathString.charAt(i++);

      if (!_.includes(this.units, unit)) {
        return undefined;
      } else {
        let dUnit = unitsMap[unit];
        if (type === 0) {
          roundUp ? dateTime.endOf(dUnit) : dateTime.startOf(dUnit);
        } else if (type === 1) {
          dateTime.plus({ [dUnit]: num });
        } else if (type === 2) {
          dateTime.minus({ [dUnit]: num });
        }
      }
    }
    return dateTime;
  }
}

export const datemath = new DateMath();

export default datemath;
