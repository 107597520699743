import { Breadcrumb, IBreadcrumbItem, Panel, PanelType } from '@fluentui/react';
import { useSelector } from 'react-redux';
import { breadCrumbStyles, panelStyles } from '../workspaceStyles';
import AadAppsTable from './aadAppsTable';
import { editWorkspace } from 'features/workspaces/workspaceSlice';

interface AadAppsPanelProps {
  workspaceId: string;
  show: boolean;
  dismissPanel: () => void;
  openPanel: () => void;
  closeQuickSwitchPanel: () => void;
}

const AadAppsPanel = (props: AadAppsPanelProps) => {
  let workspace = useSelector(editWorkspace);

  const items: IBreadcrumbItem[] = [
    {
      text: 'Workspace management',
      key: 'workspaceManagement',
      onClick: () => {
        props.dismissPanel();
      },
    },
    {
      text: workspace?.name + ' - AAD Applications or Managed Identities',
      key: 'aadApplications',
      onClick: () => {},
    },
  ];
  return (
    <Panel
      type={PanelType.large}
      isOpen={props.show}
      onDismiss={props.closeQuickSwitchPanel}
      styles={panelStyles}
      isFooterAtBottom={true}
      onRenderHeader={() => {
        return (
          <Breadcrumb
            items={items}
            maxDisplayedItems={2}
            ariaLabel="Workspace data connection breadcrumb with items rendered as buttons"
            overflowAriaLabel="More links"
            styles={breadCrumbStyles}
          />
        );
      }}
    >
      <AadAppsTable
        workspaceId={props.workspaceId}
        workspace={workspace}
        dismissPanel={props.dismissPanel}
        openPanel={props.openPanel}
        closeQuickSwitchPanel={props.closeQuickSwitchPanel}
      />
    </Panel>
  );
};

export default AadAppsPanel;
