import CosmosSelector from 'features/workspaces/components/editDataConnection/dataSourceComponents/cosmosSelector';
import { SelectorProps } from 'features/workspaces/components/editDataConnection/editDataConnectionHelper';
import {
  ConnectionDataSourceType,
  CosmosDataSource,
} from 'features/workspaces/models/project';
import _ from 'lodash';
import cosmosClient from '../cosmos/client/cosmosClient';
import { registerDataSourcePlugin } from '../registry';
import { DataSourcePlugin } from './dataSourcePlugin';

class CosmosPlugin extends DataSourcePlugin {
  public dataSourceFormComponent(selectorProps: SelectorProps): JSX.Element {
    return (
      <CosmosSelector
        workspace={selectorProps.workspace}
        state={selectorProps.state}
        setState={selectorProps.setState}
        customNameFilled={selectorProps.customNameFilled}
      />
    );
  }

  public generatedName(state: CosmosDataSource) {
    let name = '';
    if (state && state.cluster && state.vcName) {
      let host = _.first(_.split(state.cluster, '.'));
      name = host + '.' + state.vcName;
    }
    return name;
  }
}

registerDataSourcePlugin(
  ConnectionDataSourceType.Cosmos,
  new CosmosPlugin(ConnectionDataSourceType.Cosmos, cosmosClient)
);
