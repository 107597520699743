import { IDropdownOption, Stack, TextField } from '@fluentui/react';
import { ElxDropdown } from '@elixir/components';
import { Project } from 'features/workspaces/models/project';
import React, { useState } from 'react';
import { LensLabel } from 'utils/lensLabel';
import {
  wideSpacingStackTokens,
  textInputStyle,
  stackItemFormStyles,
} from '../../workspaceStyles';
import {
  AllocationSettingsObj,
  ComponentType,
  Tooltips,
} from '../workspaceSettingsUtil';

interface AllocationSettingsProps {
  data: AllocationSettingsObj;
  project: Project;
  updateOrchestratorProject: (project: Project) => void;
  onChange: (event: any) => void;
  showError: any; //error object from react hook form
}

const AllocationSettings = (props: AllocationSettingsProps) => {
  const [allocationType, setAllocationType] = useState(
    props.data.allocationType
  );

  const allocationSettingsFormData = [
    {
      componentType: ComponentType.ElxDropdown,
      onRenderLabel: () => (
        <LensLabel
          labelText="Allocation Type (Empty dropdown will be passed as a null value) "
          hintText={Tooltips.allocationType}
          required={false}
        ></LensLabel>
      ),
      defaultValue: props.data.allocationType
        ? props.data.allocationType
        : undefined,
      onChangeElxDropdown: (
        event: any,
        option: IDropdownOption | undefined
      ) => {
        setAllocationType(option?.key + '');
        props.onChange({
          target: {
            name: 'updatedAllocationType',
            value: {
              ...props.data,
              allocationType: option?.key + '',
            },
          },
        });
        props.updateOrchestratorProject({
          ...props.project,
          allocationType: option?.key + '',
        });
      },
      selectedKey: allocationType || '',
      options: [
        { key: 'Token', text: 'Token' },
        {
          key: 'DefaultVcPercentage',
          text: 'DefaultVcPercentage',
        },
        {
          key: 'CustomPercentage',
          text: 'CustomPercentage',
        },
        { key: '', text: '' },
      ],
    },
    {
      show: allocationType === 'Token',
      componentType: ComponentType.ElxTextField,
      onRenderLabel: () => (
        <LensLabel
          labelText="Tokens Per Job (-1 - unlimited, Empty input will be passed as a null value) "
          hintText={Tooltips.tokensPerJob}
          required={false}
        ></LensLabel>
      ),
      defaultValue: props.data.tokensPerJob
        ? props.data.tokensPerJob + ''
        : undefined,
      type: 'number',
      min: -1,
      onChangeElxTextField: (event: any, value: string | undefined) => {
        props.onChange({
          target: {
            name: 'updatedTokensPerJob',
            value: {
              ...props.data,
              tokensPerJob: value ? parseInt(value) : undefined,
            },
          },
        });
        props.updateOrchestratorProject({
          ...props.project,
          tokensPerJob: value ? parseInt(value) : undefined,
        });
      },
      styles: textInputStyle,
      onGetErrorMessage: (value: string) => {
        const inputValue = parseInt(value);
        if (inputValue < -1) {
          return 'Tokens Per Job needs to be -1 or greater.';
        } else if (inputValue > 100) {
          return 'Tokens Per Job needs to be less than or equal to 100.';
        }
      },
    },
    {
      show:
        allocationType === 'DefaultVcPercentage' ||
        allocationType === 'CustomPercentage',
      componentType: ComponentType.ElxTextField,
      onRenderLabel: () => (
        <LensLabel
          labelText="Percent Allocation (-1 - 100, Empty input will be passed as a null value) "
          hintText={Tooltips.percentAllocation}
          required={false}
        ></LensLabel>
      ),
      defaultValue: props.data.percentAllocation
        ? props.data.percentAllocation + ''
        : undefined,
      type: 'number',
      min: -1,
      max: 100,
      onChangeElxTextField: (event: any, value: string | undefined) => {
        props.onChange({
          target: {
            name: 'updatedPercentAllocation',
            value: {
              ...props.data,
              percentAllocation: value ? parseInt(value) : undefined,
            },
          },
        });
        props.updateOrchestratorProject({
          ...props.project,
          percentAllocation: value ? parseInt(value) : undefined,
        });
      },
      styles: textInputStyle,
      onGetErrorMessage: (value: string) => {
        const inputValue = parseInt(value);
        if (inputValue < -1) {
          return 'Percent Allocation needs to be -1 or greater.';
        } else if (inputValue > 100) {
          return 'Percent Allocation needs to be less than or equal to 100.';
        }
      },
    },
  ];
  return (
    <Stack>
      <Stack.Item>
        <Stack.Item grow>
          <Stack tokens={wideSpacingStackTokens}>
            {allocationSettingsFormData.map((componentData, index) => {
              return componentData.componentType ===
                ComponentType.ElxDropdown ? (
                <Stack.Item key={index} {...stackItemFormStyles}>
                  <ElxDropdown
                    onRenderLabel={componentData.onRenderLabel}
                    defaultValue={componentData.defaultValue}
                    selectedKey={componentData.selectedKey}
                    onChange={componentData.onChangeElxDropdown}
                    options={componentData.options || []}
                  />
                </Stack.Item>
              ) : (
                componentData.show && (
                  <Stack.Item key={index} {...stackItemFormStyles}>
                    <TextField
                      onRenderLabel={componentData.onRenderLabel}
                      defaultValue={componentData.defaultValue}
                      type={componentData.type}
                      min={componentData.min}
                      max={componentData.max}
                      styles={componentData.styles}
                      onChange={componentData.onChangeElxTextField}
                      onGetErrorMessage={componentData.onGetErrorMessage}
                    />
                  </Stack.Item>
                )
              );
            })}
          </Stack>
        </Stack.Item>
      </Stack.Item>
    </Stack>
  );
};

export default AllocationSettings;
