import { DataSourceClient } from 'features/dataSources/client/dataSourceClient';
import { $laConstants, orchestratorConstants } from 'utils/constants';

class SynapsePipelineClient extends DataSourceClient {
  public readonly name = $laConstants.DataSourceTypes.SYNAPSEPIPELINE;
  public readonly title = 'Synapse Pipeline';
  public readonly icon = 'fa fa-cat';
  public readonly description = 'Synapse Pipeline data source.';

  public readonly monaco = {
    language: orchestratorConstants.LanguageId.Sql,
  };
  public readonly orchestration = {
    dataCommand: {
      endpointType: orchestratorConstants.EndpointTypes.SynapsePipeline,
      scriptLabel: 'Synapse Pipeline',
    },
  };
  public get displayName() {
    return $laConstants.DataSourceClientDisplayNames.SYNAPSEPIPELINE;
  }

  public get dataSourceType() {
    return $laConstants.DataSourceTypes.SYNAPSEPIPELINE;
  }
}

export const synapsePipelineClient = new SynapsePipelineClient();
export default synapsePipelineClient;
