import {
  ElxChoiceGroup,
  ElxIconButton,
  ElxTextField,
} from '@elixir/components';
import { IChoiceGroupOption, Stack } from '@fluentui/react';
import { Editor } from 'components/editor';
import { getDataSourcePlugin } from 'features/dataSources/registry';
import {
  Activity
} from 'features/orchestrator/models/job';
import _ from 'lodash';
import { useCallback, useState } from 'react';
import { orchestratorConstants } from 'utils/constants';
import { LensLabel } from 'utils/lensLabel';
import EditorFull from '../editorFull';
import { alignEnds, choiceGroupStyles } from './JobAuthorStyles';

export interface JobEndpointKustoTableProps {
  isSource: boolean;
  activity: Activity;
  onChange: (activity: Activity, endpoint:any) => void;
  endpointTypes: string[];
  endpoint:any;
}

export const JobEndpointKustoTable = (props: JobEndpointKustoTableProps) => {
  const { isSource, onChange, activity, endpointTypes, endpoint } = props;

  // const [selEndpointType, setSelEndpointType] = useState(
  //   (endpointTypes.includes(endpoint.type) && endpoint.type) || endpointTypes[0]
  // );
  const [bigSrn, setBigSrn] = useState(false);

  endpoint.type = (endpointTypes.includes(endpoint.type) && endpoint.type) || endpointTypes[0];

  const getEntityTypes = useCallback(() => {
    return _(endpointTypes)
      .map((endpointType) => {
        return _.get(orchestratorConstants.EndpointTypeOptions, endpointType);
      })
      .compact()
      .uniq()
      .value();
  }, [endpointTypes]);

  const entityTypes = getEntityTypes();
  const showEntityTypes = entityTypes?.length > 1;
  const showTable =
    _.get(orchestratorConstants.EndpointTypeOptions, endpoint.type)?.entityType === orchestratorConstants.EndpointEntityTypes.Table ||
    _.get(orchestratorConstants.EndpointTypeOptions, endpoint.type)?.entityType === orchestratorConstants.EndpointEntityTypes.Column;
  const dataSourcePlugin = getDataSourcePlugin(endpoint.type);

  if (!isSource)
    console.log(
      `check to see if the showtable is true ${showTable.toString()}`
    );

    console.log(
      `SelEndpoint ${endpoint.type}`
    );

  return (
    <>
      {showEntityTypes && isSource && (
        <Stack.Item>
          <Stack horizontal horizontalAlign="center">
            <ElxChoiceGroup
              onRenderLabel={() => (
                <LensLabel
                  labelText={'Entity'}
                  hintText={undefined}
                ></LensLabel>
              )}
              required={true}
              onChange={(e, option?: IChoiceGroupOption) => {
                // option?.key && setSelEndpointType(option?.key);
                endpoint.type = option?.key || endpointTypes[0];
                onChange(activity, endpoint);
              }}
              options={entityTypes.map((d) => ({
                key: d.type,
                text: d.entityType,
              }))}
              selectedKey={endpoint.type}
              styles={choiceGroupStyles}
            />
          </Stack>
        </Stack.Item>
      )}
      {/* TODO - Nambi - we need to verify table pattern - see kusto_table_job_endpoint.html */}
      {/* TODO - Nambi - We need to provide the list of available tables - query the database and get the table list. set kusto_table_job_endpoint.js */}
      {showTable && (
        <>
          <Stack.Item>
            <ElxTextField
              id={'Table'}
              onRenderLabel={() => {
                return (
                  <LensLabel
                    labelText={'Table'}
                    hintText={`Table name`}
                    required={true}
                  ></LensLabel>
                );
              }}
              value={endpoint.table}
              onChange={(e, val) => {
                endpoint.table = val!;
                onChange(activity, endpoint);
              }}
            ></ElxTextField>
          </Stack.Item>
          {endpoint.type === 'SqlTable' && (
            <Stack.Item>
              <ElxTextField
                id={'Schema'}
                onRenderLabel={() => {
                  return (
                    <LensLabel
                      labelText={'Schema'}
                      hintText={`The SQL schema. The default value is 'dbo'.`}
                      required={true}
                    ></LensLabel>
                  );
                }}
                value={endpoint.schema as string}
                onChange={(e, val) => {
                  endpoint.schema = val!;
                  onChange(activity, endpoint);
                }}
              ></ElxTextField>
            </Stack.Item>
          )}
        </>
      )}
      {_.get(orchestratorConstants.EndpointTypeOptions, endpoint.type)?.entityType === 'Query' && (
        <>
          <Stack.Item>
            <Stack
              className={alignEnds}
              horizontal
              grow
              styles={{
                root: {
                  marginTop: 16,
                },
              }}
            >
              <Stack.Item>
                {' '}
                <LensLabel
                  labelText="Script"
                  hintText={'Script to execute'}
                  required={true}
                ></LensLabel>
              </Stack.Item>
              <Stack.Item>
                {' '}
                <ElxIconButton
                  text={''}
                  styles={{
                    root: {
                      float: 'right',
                    },
                  }}
                  iconProps={{
                    iconName: 'FullScreen',
                  }}
                  onClick={() => {
                    setBigSrn(true);
                  }}
                ></ElxIconButton>
              </Stack.Item>
            </Stack>
          </Stack.Item>
          <Stack.Item>
            <Editor
              language={
                dataSourcePlugin?.dataSourceClient?.monaco?.language || 'json'
              }
              onChange={(value: string) => {
                activity.script = value;
                onChange(activity, endpoint);
              }}
              value={activity.script!}
              height={200}
              width={'100%'}
              focusOnMount={false}
            ></Editor>
          </Stack.Item>
        </>
      )}
      {bigSrn && (
        <EditorFull
          header={'Query'}
          onChange={(value: string) => {
            endpoint.query = value;
            onChange(activity, endpoint);
          }}
          value={activity.script!}
          onDismiss={() => {
            setBigSrn(false);
          }}
          language={
            dataSourcePlugin?.dataSourceClient?.monaco?.language || 'json'
          }
        ></EditorFull>
      )}
    </>
  );
};
