import {
  Breadcrumb,
  DefaultButton,
  IBreadcrumbItem,
  Panel,
  PanelType,
  Stack,
} from '@fluentui/react';
import { useSelector } from 'react-redux';
import {
  breadCrumbStyles,
  panelStyles,
  stackItemFormStyles,
  panelContentStackStyle,
} from '../workspaceStyles';
import { editWorkspace } from 'features/workspaces/workspaceSlice';
import { useCallback, useEffect, useState } from 'react';
import { isGuestUser } from 'features/userprofile/userProfileUtils';
import { getAccessMessage } from './accessInfoUtil';
import {
  Workspace,
  WorkspaceAccessFilter,
} from 'features/workspaces/models/workspace';
import {
  isAdministratable,
  isOrchestratable,
  isReadable,
  isWriteable,
} from 'features/workspaces/utils/workspaceUtils';

interface AccessInfoPanelProps {
  workspaceId: string;
  show: boolean;
  dismissPanel: () => void;
  openPanel: () => void;
  closeQuickSwitchPanel: () => void;
}

const AccessInfoPanel = (props: AccessInfoPanelProps) => {
  const [accessMessages, setAccessMessages] = useState<JSX.Element[]>();
  let workspace = useSelector(editWorkspace);
  const writeable = isWriteable(workspace);
  const readable = isReadable(workspace);
  const orchestratable = isOrchestratable(workspace);
  const isAdmin = isAdministratable(workspace);

  useEffect(() => {
    const handleAccessMessages = async (
      workspace: Workspace,
      isReadable: boolean,
      isWriteable: boolean,
      isOrchestratable: boolean,
      isAdmin: boolean
    ) => {
      const isGuest = await isGuestUser();
      setAccessMessages(
        getAccessMessage(
          workspace,
          [
            { accessType: WorkspaceAccessFilter.Read, permission: isReadable },
            {
              accessType: WorkspaceAccessFilter.Write,
              permission: isWriteable,
            },
            {
              accessType: WorkspaceAccessFilter.Orchestrate,
              permission: isOrchestratable,
            },
            {
              accessType: WorkspaceAccessFilter.Admin,
              permission: isAdmin,
            },
          ],
          isGuest
        )
      );
    };
    if (!writeable || !readable || !orchestratable) {
      handleAccessMessages(
        workspace,
        readable,
        writeable,
        orchestratable,
        isAdmin
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const items: IBreadcrumbItem[] = [
    {
      text: 'Workspace management',
      key: 'workspaceManagement',
      onClick: () => {
        props.dismissPanel();
      },
    },
    {
      text: workspace?.name + ' - Access Details',
      key: 'workspaceAccess',
    },
  ];

  const onRenderFooterContent = useCallback(
    () => (
      <DefaultButton onClick={() => props.dismissPanel()}>close</DefaultButton>
    ),
    [props]
  );
  return (
    <Panel
      type={PanelType.large}
      isOpen={props.show}
      onDismiss={props.closeQuickSwitchPanel}
      styles={panelStyles}
      onRenderHeader={() => {
        return (
          <Breadcrumb
            items={items}
            maxDisplayedItems={2}
            ariaLabel="Workspace migration breadcrumb with items rendered as buttons"
            overflowAriaLabel="More links"
            styles={breadCrumbStyles}
          />
        );
      }}
      onRenderFooterContent={onRenderFooterContent}
      isFooterAtBottom={true}
    >
      <Stack {...panelContentStackStyle}>
        {!writeable || !readable || !orchestratable ? (
          <Stack {...stackItemFormStyles}>{accessMessages}</Stack>
        ) : (
          <Stack.Item
            {...stackItemFormStyles}
          >{`No Access Messages Are Available.`}</Stack.Item>
        )}
      </Stack>
    </Panel>
  );
};

export default AccessInfoPanel;
