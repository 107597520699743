import { Timefilter } from 'utils/timefilter';
import {
  DataSourceClientResponse,
  DataSourceQueryObject,
  FetchParams,
} from '../models/dataSourceClient';
import { AxiosRequestConfig } from 'axios';
import { SchemaTree } from '../models/schemaTree';
import { Database, DataSource } from '../models/dataSource';
import { SchemaNodeTypes } from './dataSourceClientUtils';
import _ from 'lodash';

export function flattenSchemaTree(schema: any, type: SchemaNodeTypes) {
  var result: any[] = [];
  if (!_.isArray(schema)) {
    return result;
  }

  _.each(schema, function (node) {
    if (!node) {
      return;
    } else if (node.type === type) {
      result.push(node);
    } else if (node.type === SchemaNodeTypes.FOLDER) {
      var nodes = flattenSchemaTree(node.children, type);
      result = _.concat(result, nodes);
    }
  });

  return result;
}

/**
 * @name DataSourceClient
 * @description
 * Represents the abstract base class for data source clients.  Defines the contract that data
 * sources must provide to Lens, such as retrieving schemas and executing queries.  Data
 * source clients follow a strategy pattern, so that data source behavior is encapsulated
 * within each client and can be interchanged with other data sources.  Data source clients
 * follow an adapter pattern, so that Lens can retrieve results from different data sources.
 */
export abstract class DataSourceClient {
  public readonly name: string = '';
  public readonly title: string = '';
  public readonly icon: string = '';
  public readonly description: string = '';
  public monaco: any = null;
  public orchestration: any = null;
  public get displayName() {
    return 'Unknown Data Source';
  }

  public get dataSourceType() {
    return 'Unknown';
  }
  /**
   * @name validateDataSource
   * @description
   * Validates the data source.
   * @param {Object} dataSource The data source.
   * @returns {Promise} A promise returning the data source.
   */
  public validateDataSourcevalidateDataSource(
    dataSource: object
  ): Promise<any> {
    throw new Error('DataSourceClient.validateDataSource: not implemented');
  }

  /**
   * @name getDatabases
   * @description
   * Gets a cluster's databases.
   * @param {String} cluster The cluster.
   * @returns {Promise} The databases.
   */
  public getDatabases(cluster: string): Promise<Database[]> {
    // For default data sources, clusters have no databases, for example AppInsights/OMS.
    return Promise.resolve([]);
  }

  /**
   * @name getSchema
   * @description
   * Gets a database schema.
   * @param {String} cluster The cluster.
   * @param {String} database The database.
   * @returns {Promise} The schema tree.
   */
  public getSchema(
    cluster: string,
    database: string
  ): Promise<SchemaTree | null> {
    return Promise.resolve([]);
  }

  /**
   * @name getSchemaForTable
   * @description
   * Gets a Table schema.
   * @param {String} cluster The cluster.
   * @param {String} database The database.
   * @param {String} tableName The table.
   * @returns {Promise} The schema tree.
   */
  public getSchemaForTable(
    cluster: string,
    database: string,
    tableName: string
  ): Promise<SchemaTree | null> {
    return Promise.resolve([]);
  }
  /**
   * @name getTables
   * @description
   * Gets database tables.
   * @param {String} cluster The cluster.
   * @param {String} database The database.
   * @returns {Promise} Tables.
   */
  public getTables(cluster: string, database: string): Promise<any | null> {
    var schema = this.getSchema(cluster, database);
    var tables = flattenSchemaTree(schema, SchemaNodeTypes.TABLE);
    return Promise.resolve(tables);
  }

  /**
   * @name getQueryData
   * @description
   * Executes a query.
   * @param {Object} query The query object.
   * @param {Object} requestConfig The config for the http request, this is honored if passed in.
   * @returns {Promise} The query response.
   */
  public getQueryData(
    query: DataSourceQueryObject,
    requestConfig?: AxiosRequestConfig
  ): Promise<DataSourceClientResponse> {
    throw new Error('DataSourceClient.getQueryData: not implemented');
  }

  /**
   * @name getDataSourceAliasFromCluster
   * @description Compute the alias for the DataSource from the given cluster name.  Extracted from url or path in cluster name.
   * @param {String} cluster The cluster.
   * @returns {string} The alias for the cluster
   */
  public getDataSourceAliasFromCluster(cluster: string): string | undefined {
    throw new Error(
      'DataSourceClient.getDataSourceAliasFromCluster: not implemented'
    );
  }

  /**
   * @name constructStartingQuery
   * @description Construct the initial starting query that the user sees in their editor
   * @param {String} cluster The cluster - optional.
   * @param {String} database The database - optional.
   * @param {String} table The table.
   * @returns {string} Query The starting query the user should see in their editor
   */
  public constructStartingQuery(
    cluster: string,
    database: string,
    table: string
  ): string {
    throw new Error('DataSourceClient.constructStartingQuery: not implemented');
  }

  /**
   * @name constructQuery
   * @description Translate a base query plus aggregations and filters to a query expressions
   * @param {Object} timefilter the timefilter for the query
   * @param {Object} fetchParams Fetch parameters
   * @returns {string} Query The query that represents the passed in timefilter and fetchParams
   */
  public constructQuery(
    timefilter: Timefilter,
    fetchParams: FetchParams
  ): string {
    throw new Error('DataSourceClient.constructQuery: not implemented');
  }

  public createQuery(
    timefilter: Timefilter,
    savedVis: any,
    searchSource: any,
    isQuickEditMode: boolean,
    querySelectionText: string
  ) {
    throw new Error('DataSourceClient.createQuery: not implemented');
  }

  public getFieldsFromQuery(cluster: string, database: string, query: string) {
    throw new Error('DataSourceClient.getFieldsFromQuery: not implemented');
  }

  public getEndpointUrl(cluster: string, database: string, query: string) {
    throw new Error('DataSourceClient.getEndpointUrl: not implemented');
  }

  public describe(cluster: string, database: string) {
    throw new Error('DataSourceClient.describe: not implemented');
  }

  public appendOutputCommand(script: string, appendInfo: object) {
    throw new Error('DataSourceClient.appendOutputCommand: not implemented');
  }

  public getWorkspaceDataSourceContainer(
    dataSource: DataSource
  ): string | null {
    return null;
  }

  public getWorkspaceDataSourceTitle(dataSource: DataSource): string | null {
    return null;
  }
}
