import { ElxChoiceGroup, ElxTextField } from '@elixir/components';
import { IChoiceGroupOption, Stack } from '@fluentui/react';
import { Activity, activityInput, activityOutput } from 'features/orchestrator/models/job';
import { useState } from 'react';
import { orchestratorConstants } from 'utils/constants';
import { LensLabel } from 'utils/lensLabel';
import { choiceGroupStyles } from './JobAuthorStyles';
import { JobSchemaEntity } from './JobSchemaEntity';

export interface JobEndpointAzureTableProps {
  isSource: boolean;
  activity: Activity;
  endpointTypes: string[];
  endpoint:any;
  onChange: (activity: Activity, endpoint:any) => void;
}

export const JobEndpointAzureTable = (props: JobEndpointAzureTableProps) => {
  const { isSource, activity, endpointTypes, onChange, endpoint } = props;
  const [entityType, setEntityType] = useState(
    isSource
      ? activity.input?.type || orchestratorConstants.EndpointEntityTypes.Blob
      : activity.output?.type || orchestratorConstants.EndpointEntityTypes.Blob
  );
  const endpnt = endpoint || (isSource ? activity.input! : activity.output!);
  endpnt.type = entityType;
  const [table, setTable] = useState(endpnt?.table);
  endpnt.table = table;

  const showEntityTypes = endpointTypes.length > 1;

  const blobFrag = (
    <>
      <Stack.Item>
        <ElxTextField
          id={'Storage Container'}
          onRenderLabel={() => {
            return (
              <LensLabel
                labelText={'Storage Container'}
                hintText={'Azure Storage Container Name'}
                required={true}
              ></LensLabel>
            );
          }}
          value={endpnt.storageContainer}
          onChange={(e, val) => {
            endpnt.storageContainer = val;
            onChange(activity,endpnt);
          }}
        ></ElxTextField>
      </Stack.Item>
      <Stack.Item>
        <ElxTextField
          id={'Blob Prefix'}
          onRenderLabel={() => {
            return (
              <LensLabel
                labelText={'Blob Prefix'}
                hintText={'Azure Storage Container Blob Prefix'}
              ></LensLabel>
            );
          }}
          value={endpnt.blobPrefix}
          onChange={(e, val) => {
            endpnt.blobPrefix = val;
            onChange(activity,endpnt);
          }}
        ></ElxTextField>
      </Stack.Item>
    </>
  );

  // TODO - Nambi - There is validation that needs to done on input - look at azure_table_job_endpoint.html
  return (
    <>
      {isSource && (
        <>
          {showEntityTypes && (
            <>
              <ElxChoiceGroup
                onRenderLabel={() => (
                  <LensLabel
                    labelText={'Entity'}
                    hintText={'Entity Type'}
                  ></LensLabel>
                )}
                required={true}
                onChange={(e, option?: IChoiceGroupOption) => {
                  endpnt.type = option?.key || entityType;
                  setEntityType(option?.key || entityType);
                  onChange(activity,endpnt);
                }}
                options={endpointTypes.map((d) => ({
                  key: d,
                  text: d,
                }))}
                selectedKey={entityType}
                styles={choiceGroupStyles} 
              />
            </>
          )}
          {entityType === orchestratorConstants.EndpointEntityTypes.Table && (
            <>
              <Stack.Item>
                <ElxTextField
                  id={'Table'}
                  onRenderLabel={() => {
                    return (
                      <LensLabel
                        labelText={'Table'}
                        hintText={'The Azure Table Name.'}
                        required={true}
                      ></LensLabel>
                    );
                  }}
                  value={endpnt.table}
                  onChange={(e, val) => {
                    endpnt.table = val;
                    setTable(val)
                    onChange(activity,endpnt);
                  }}
                ></ElxTextField>
                <JobSchemaEntity endpoint={endpnt} onChange={ (endpoint: activityInput | activityOutput): void => {
                  onChange(activity,endpoint);
                } }></JobSchemaEntity>
              </Stack.Item>
            </>
          )}
          {entityType === orchestratorConstants.EndpointEntityTypes.Blob && {
            blobFrag,
          }}
        </>
      )}
      {!isSource && { blobFrag }}
    </>
  );
};
