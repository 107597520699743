import {
  Breadcrumb,
  IBreadcrumbItem,
  MessageBar,
  MessageBarType,
  Panel,
  PanelType,
} from '@fluentui/react';
import { useSelector } from 'react-redux';
import { breadCrumbStyles, panelStyles } from '../workspaceStyles';
import { RootState } from 'app/lensShellUtility';
import { selectWorkspaceById } from 'features/workspaces/workspaceListSlice';
import { MessageAndType } from '../../editDataConnection/editDataConnectionPanel';
import { useState } from 'react';
import { AadApplicationForm } from '../workspaceHelperComponents';

interface AadAppsPanelProps {
  workspaceId: string;
  show: boolean;
  dismissPanel: () => void;
  openPanel: () => void;
  openParentPanel: () => void;
  closeParentPanel: () => void;
  closeQuickSwitchPanel: () => void;
}

const AddAadAppsPanel = (props: AadAppsPanelProps) => {
  const workspace = useSelector((state: RootState) =>
    selectWorkspaceById(state, props.workspaceId)
  );
  const [errorMessage, setErrorMessage] = useState<MessageAndType>({
    resultMsg: '',
    type: MessageBarType.info,
  });
  const items: IBreadcrumbItem[] = [
    {
      text: 'Workspace management',
      key: 'workspaceManagement',
      onClick: () => {
        props.closeParentPanel();
      },
    },
    {
      text: workspace?.name + ' - AAD Applications or Managed Identities',
      key: 'aadApplications',
      onClick: () => {
        props.dismissPanel();
      },
    },
    {
      text: ' Add AAD Applications or Managed Identities',
      key: 'addAadApplications',
      onClick: () => {},
    },
  ];
  const onDismissError = () =>
    setErrorMessage({
      resultMsg: '',
      type: MessageBarType.info,
    });

  return (
    <Panel
      type={PanelType.large}
      isOpen={props.show}
      onDismiss={props.closeQuickSwitchPanel}
      styles={panelStyles}
      isFooterAtBottom={true}
      onRenderHeader={() => {
        return (
          <Breadcrumb
            items={items}
            maxDisplayedItems={2}
            ariaLabel="Workspace data connection breadcrumb with items rendered as buttons"
            overflowAriaLabel="More links"
            styles={breadCrumbStyles}
          />
        );
      }}
    >
      <>
        {errorMessage.resultMsg && (
          <MessageBar
            messageBarType={errorMessage.type}
            isMultiline={true}
            dismissButtonAriaLabel="Close"
            onDismiss={onDismissError}
          >
            {errorMessage.resultMsg}
          </MessageBar>
        )}
        <AadApplicationForm
          showAddAadApp={true}
          hideAadApplicationForm={() => {}}
          workspaceId={props.workspaceId}
          setErrorMessage={(messageInfo: MessageAndType) =>
            setErrorMessage({
              resultMsg: messageInfo.resultMsg,
              type: messageInfo.type,
            })
          }
        />
      </>
    </Panel>
  );
};

export default AddAadAppsPanel;
