import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { telemetryDimensions } from 'features/telemetry/telemetryDimensions';
import { appInsightsConnectionString, _ } from 'utils/sharedLibs';
import { LensTelemetryConstants } from 'features/appInsights/appInsightsLibs';

export const EventNames = LensTelemetryConstants.EventNames;
export const CompletionStatus = LensTelemetryConstants.CompletionStatus;

const appInsights = new ApplicationInsights({
  config: {
    connectionString: appInsightsConnectionString,
  },
});

const initializeTelemetry = (envelope: any) => {
  try {
    var telemetryItem = envelope && envelope.baseData;
    if (!telemetryItem) return;
    if (!telemetryDimensions) return;

    // Set custom dimensions - telemetry properties overwrite standard dimensions.
    var properties = telemetryDimensions;
    telemetryItem.properties = { ...properties, ...telemetryItem.properties };
    telemetryItem.measurements = telemetryItem.measurements || {};

    initializeDependencyTelemetry(envelope);
    telemetryItem.properties = _.omitBy(
      telemetryItem.properties,
      _.isUndefined
    );
    telemetryItem.measurements = _.omitBy(
      telemetryItem.measurements,
      _.isUndefined
    );
  } catch (ex) {
    console.error(ex);
  }
};

const initializeDependencyTelemetry = (envelope: any) => {
  try {
    var telemetryItem = envelope && envelope.baseData;

    var api = telemetryItem.name;
    api = _.replace(
      api,
      /\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/gi,
      '/{id}'
    ); // uuids
    api = _.replace(api, /\/[0-9a-f]{32}/gi, '/{id}'); // uuids without dashes
    api = _.replace(api, /\/\d+/gi, '/{id}'); // numbers - after uuids
    api = _.replace(api, /\/workspaces\/([^/]+)/gi, '/workspaces/{id}'); // workspace ids (private and legacy ids are not uuids)
    telemetryItem.properties.API = api;
  } catch (ex) {
    console.error(ex);
  }
};

appInsights.loadAppInsights();
appInsights.addTelemetryInitializer(initializeTelemetry);

export { appInsights };
