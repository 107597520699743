import { Job } from '../../orchestrator/models/job';
import UserSavedObjectData, {
  LensObjectType,
} from '../models/userSavedObjectData';

export const createUserSavedObjectData = (
  workspaceId: string | undefined,
  lensObjectId: string,
  lensObjectType: string
): UserSavedObjectData => {
  let userSavedObjectData: UserSavedObjectData = {
    workspaceId,
    lensObjectId,
    lensObjectType,
  };

  return userSavedObjectData;
};

export const createUserSavedObjectDataJobs = (
  jobList: Job[]
): UserSavedObjectData[] => {
  return jobList.map((job: Job) => {
    return createUserSavedObjectData(job.workspace, job.id, LensObjectType.Job);
  });
};

export const isFavoriteJob = (
  job: Job,
  favoritesList: UserSavedObjectData[]
) => {
  return (
    favoritesList.find(
      (fav: UserSavedObjectData) =>
        fav.lensObjectType === LensObjectType.Job &&
        fav.workspaceId === job.workspace &&
        fav.lensObjectId === job.id
    ) !== undefined
  );
};
