import { ElxActionButton } from '@elixir/components';
import { constants } from 'utils/constants';
import { IProcessedStyleSet, Stack } from '@fluentui/react';
import {
  AadConnectionAuthType,
  ConnectionDataSourceType,
  DataSource,
  DataSourceWithValidation,
} from 'features/workspaces/models/project';
import { Job } from 'features/orchestrator/models/job';
import { smallStackTokens } from '../workspaceStyles';

export const renderStatusHelper = (
  status: string,
  styles: IProcessedStyleSet<{
    red: string;
    green: string;
    orange: string;
    yellow: string;
  }>
): JSX.Element => {
  if (status === 'Not Connected') {
    return (
      <ElxActionButton
        iconProps={{
          iconName: 'ErrorBadge',
          style: { color: styles.red },
          title: 'Connection not connected',
          'aria-label': 'Connection not connected',
        }}
        text="Not Connected"
        onClick={() => {}}
      />
    );
  } else if (status === 'Validation Failed') {
    return (
      <ElxActionButton
        iconProps={{
          iconName: 'Error',
          style: { color: styles.orange },
          title: 'Connection Validation Failed',
          'aria-label': 'Connection Validation Failed',
        }}
        text="Validation Failed"
        onClick={() => {}}
      />
    );
  } else if (status === 'Loading') {
    return (
      <ElxActionButton
        iconProps={{
          iconName: 'ProgressRingDots',
          title: 'Connection Validation Loading',
          'aria-label': 'Connection Validation Loading',
        }}
        text="Loading ..."
        onClick={() => {}}
      />
    );
  } else if (status === 'Validation Unavailable') {
    return (
      <ElxActionButton
        iconProps={{
          iconName: 'Warning',
          style: { color: styles.yellow },
          title: 'Connection Validation Unavailable',
          'aria-label': 'Connection validation is not available',
        }}
        text="Validation Unavailable"
        onClick={() => {}}
      />
    );
  } else {
    return (
      <ElxActionButton
        iconProps={{
          iconName: 'completed',
          style: { color: styles.green },
          title: status,
          'aria-label': status,
        }}
        text={status}
        onClick={() => {}}
      />
    );
  }
};

export const renderDataSourceHelper = (
  item: DataSourceWithValidation
): string | undefined => {
  let dataSource = '';
  switch (item.type) {
    case ConnectionDataSourceType.Sql:
      dataSource = item.database || '';
      break;
    case ConnectionDataSourceType.Kusto:
      dataSource = item.database || '';
      break;
    case ConnectionDataSourceType.ApplicationInsights:
      dataSource = item.applicationId || '';
      break;
    case ConnectionDataSourceType.LogAnalytics:
      dataSource = item.workspaceId || '';
      break;
    case ConnectionDataSourceType.Cosmos:
      dataSource = item.vcName || '';
      break;
    case ConnectionDataSourceType.StorageAccount:
      dataSource = item.storageAccount || '';
      break;
    case ConnectionDataSourceType.EventHub:
      dataSource = item.eventHubName || '';
      break;
    case ConnectionDataSourceType.AzureDataLakeStorageGen2:
      dataSource = item.account || '';
      break;
    case ConnectionDataSourceType.Subscription:
      dataSource = item.database || '';
      break;
    case ConnectionDataSourceType.AzureDataLakeAnalytics:
      dataSource = item.resourceGroup || '';
      break;
    case ConnectionDataSourceType.Mdm:
      dataSource = item.namespace || '';
      break;
    case ConnectionDataSourceType.AzureDataFactory:
      dataSource = item.database || '';
      break;
    case ConnectionDataSourceType.SynapsePipeline:
      dataSource = item.resourceGroup || '';
      break;
    case ConnectionDataSourceType.SynapseScope:
      dataSource = item.resourceGroup || '';
      break;
  }
  return dataSource;
};

export const renderAuth = (item: DataSource) => {
  if (!item) return null;

  if (item.type === constants.DataSourceTypes.COSMOS) {
    return item.certificateSecretUri
      ? 'Custom Certificate'
      : 'Lens Certificate';
  }

  if (
    item.type === constants.DataSourceTypes.SQL ||
    item.type === constants.DataSourceTypes.EVENTHUB
  ) {
    if (item.passwordSecretUri) {
      return 'SQL Authentication';
    }
    if (item.connectionSecretUri) {
      return 'Connection String';
    }
    // fall-through (AADApp or User)
  }

  // if the datasource is directly for orchestrator.
  if (
    item.authType === AadConnectionAuthType.AADApp ||
    item.authType === AadConnectionAuthType.AadAppWithSegmentedMsiFic ||
    item.authType === AadConnectionAuthType.UamiWithSegmentedMsiFic
  ) {
    return (
      'AAD Application or Managed Identity' +
      (item.principalId ? ' (' + item.principalId + ')' : '')
    );
  }

  if (item.authType === AadConnectionAuthType.User) {
    return 'User' + (item.principalId ? ' (' + item.principalId + ')' : '');
  }

  if (
    item.authenticationMode === AadConnectionAuthType.AADApp ||
    item.authenticationMode === AadConnectionAuthType.AadAppWithSegmentedMsiFic ||
    item.authenticationMode === AadConnectionAuthType.UamiWithSegmentedMsiFic    
  ) {
    return (
      'AAD Application or Managed Identity' +
      (item.applicationId ? ' (' + item.applicationId + ')' : '')
    );
  }

  if (item.authenticationMode === AadConnectionAuthType.User) {
    return 'User' + (item.userId ? ' (' + item.userId + ')' : '');
  }

  return null;
};

interface DeleteDialogContentProps {
  canDelete: boolean;
  dataconnectionName: string;
  jobDependencies: Job[];
}
export const DeleteDialogContent = (props: DeleteDialogContentProps) => {
  let jobLinks: JSX.Element[] = [];
  const workspaceSharedMode = 'shared';

  const getJobUrl = (job: Job) => {
    var workspaceTerm =
      job && job.shareType === workspaceSharedMode && job.workspace
        ? "ws:'" + job.workspace + "'"
        : '';
    var jobId = job && job.id;
    return '/#/job/' + jobId + '?_g=(' + workspaceTerm + ')';
  };

  if (props.jobDependencies.length > 0) {
    for (let i = 0; i < props.jobDependencies.length; i++) {
      const jobUrl = getJobUrl(props.jobDependencies[i]);
      jobLinks.push(
        <Stack.Item key={'Job ' + i}>
          <li>
            <a href={jobUrl} target="_blank" rel="noopener, noreferrer">
              {props.jobDependencies[i].name}
            </a>
          </li>
        </Stack.Item>
      );
    }
  }
  return props.canDelete ? (
    <span>
      {`Data connection "` + props.dataconnectionName + `" is not in use and`}
      <b> can be deleted.</b>
    </span>
  ) : (
    <Stack
      tokens={{
        childrenGap: 5,
      }}
    >
      <Stack.Item>
        {`Data connection "` + props.dataconnectionName + `"`}
        <b> can not be deleted.</b>
        {` It is used by the following `}
        <b>{props.jobDependencies.length} </b>
        {` jobs :`}
      </Stack.Item>

      <Stack.Item>
        <Stack tokens={smallStackTokens}>
          <ol type="1">{jobLinks}</ol>
        </Stack>
      </Stack.Item>
      <Stack.Item>
        {
          'Please ensure that no jobs are using this data connection before deleting it.'
        }
      </Stack.Item>
    </Stack>
  );
};
