import {
  Breadcrumb,
  DefaultButton,
  FontSizes,
  IBreadcrumbItem,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
} from '@fluentui/react';
import { useDispatch, useSelector } from 'react-redux';
import {
  breadCrumbStyles,
  panelStyles,
  stackItemFormStyles,
  panelContentStackStyle,
} from '../workspaceStyles';
import {
  editWorkspace,
  migrateWorkspace,
} from 'features/workspaces/workspaceSlice';
import { useCallback, useState } from 'react';
import { AppDispatch } from 'app/lensShellUtility';
import { ElxDropdown, ElxTextField } from '@elixir/components';
import logger from 'features/appInsights/lensLogger';
import { LensTelemetryConstants } from 'features/appInsights/appInsightsLibs';

interface MigrationPanelProps {
  workspaceId: string;
  show: boolean;
  dismissPanel: () => void;
  openPanel: () => void;
  closeQuickSwitchPanel: () => void;
}
const tenants = {
  'Microsoft tenant': '72f988bf-86f1-41af-91ab-2d7cd011db47',
  'AME tenant': '33e01921-4d64-4f8c-a055-5bdaffd5e33d',
  'PME tenant': '975f013f-7f24-47e8-a7d3-abc4752bf346',
  'GME tenant': '124edf19-b350-4797-aefc-3206115ffdb3',
  'Torus tenant': 'cdc5aeea-15c5-4db6-b079-fcadd2505dc2',
  'LinkedIn Prod': '658728e7-1632-412a-9815-fe53f53ec58b',
  'LinkedIn On MS': '588e8fae-da1b-4c8b-810f-3229806c04fe',
  'Obsidian tenant': '6e0faf7f-ffe0-4345-bf85-9f1011650754',
};
const buttonStyles = { root: { marginRight: 8 } };
const MigrationPanel = (props: MigrationPanelProps) => {
  const dispatch = useDispatch<AppDispatch>();
  let workspace = useSelector(editWorkspace);
  const [targetAADTenantId, setTargetAADTenantId] = useState('');
  const [newAdminGroupId, setNewAdminGroupId] = useState('');

  const items: IBreadcrumbItem[] = [
    {
      text: 'Workspace management',
      key: 'workspaceManagement',
      onClick: () => {
        props.dismissPanel();
      },
    },
    {
      text: workspace?.name + ' - Migration',
      key: 'workspaceMigration',
      onClick: () => {},
    },
  ];

  const onRenderFooterContent = useCallback(
    () => (
      <div>
        <PrimaryButton
          onClick={() => {
            logger.event(
              LensTelemetryConstants.EventNames.WorkspaceActions.Migrate,
              { workspaceId: props.workspaceId }
            );
            dispatch(
              migrateWorkspace({
                workspaceId: props.workspaceId,
                targetAADTenantId: targetAADTenantId,
                newAdminGroupId: newAdminGroupId,
              })
            );
          }}
          styles={buttonStyles}
        >
          Save
        </PrimaryButton>
        <DefaultButton onClick={() => props.dismissPanel()}>
          Cancel
        </DefaultButton>
      </div>
    ),
    [dispatch, newAdminGroupId, props, targetAADTenantId]
  );
  return (
    <Panel
      type={PanelType.large}
      isOpen={props.show}
      onDismiss={props.closeQuickSwitchPanel}
      styles={panelStyles}
      isFooterAtBottom={true}
      onRenderHeader={() => {
        return (
          <Breadcrumb
            items={items}
            maxDisplayedItems={2}
            ariaLabel="Workspace migration breadcrumb with items rendered as buttons"
            overflowAriaLabel="More links"
            styles={breadCrumbStyles}
          />
        );
      }}
      onRenderFooterContent={onRenderFooterContent}
    >
      <Stack {...panelContentStackStyle}>
        <Stack.Item {...stackItemFormStyles}>
          <div style={{ fontSize: FontSizes.size14 }}>
            You can migrate workspaces between below AAD tenants:
          </div>
          <ol type="1">
            {Object.entries(tenants).map((tenantItem) => {
              return (
                <li key={tenantItem[0]}>
                  {tenantItem[0]} : {tenantItem[1]}
                </li>
              );
            })}
          </ol>
        </Stack.Item>
        <Stack.Item {...stackItemFormStyles}>
          <ElxDropdown
            label={'Target AAD Tenant ID'}
            placeholder={'Target AAD Tenant ID'}
            description={
              'The AAD tenant id for the tenant to which you are moving the workspace.'
            }
            options={Object.entries(tenants).map((tenantItem) => {
              return {
                key: tenantItem[1], // the guid
                text: `${tenantItem[0]} : ${tenantItem[1]}`,
              };
            })}
            onChange={(e, option) => {
              setTargetAADTenantId(option?.key + '');
            }}
          />
        </Stack.Item>
        <Stack.Item {...stackItemFormStyles}>
          <ElxTextField
            label={'New Admin Group ID'}
            placeholder={'New Admin Group ID'}
            description={
              'This group will become the admin, writer, orchestrator and reader group on the migrated workspace.'
            }
            onChange={(e, value) => {
              setNewAdminGroupId(value || '');
            }}
          />
        </Stack.Item>
      </Stack>
    </Panel>
  );
};

export default MigrationPanel;
