import { ElxChoiceGroup, ElxTextField } from '@elixir/components';
import { IChoiceGroupOption, Stack } from '@fluentui/react';
import { Activity } from 'features/orchestrator/models/job';
import { useState } from 'react';
import { LensLabel } from 'utils/lensLabel';
import { choiceGroupStyles } from './JobAuthorStyles';

const endpointTypes = ['Geneva Data', 'Cosmos Stream'];

export interface JobEndpointCosmosStreamProps {
  isSource: boolean;
  activity: Activity;
  endpoint: any;
  onChange: (activity: Activity, endpoint: any) => void;
}
export const JobEndpointCosmosStream = (
  props: JobEndpointCosmosStreamProps
) => {
  const { isSource, activity, onChange, endpoint } = props;
  const endpnt = endpoint || (isSource ? activity?.input! : activity?.output!);

  const [isCosmos, setIsCosmos] = useState<boolean>(
    !endpnt?.catalogEntity || false
  );
  const [isModernPattern, setIsModernPattern] = useState<boolean>(
    endpnt?.pattern !== undefined && endpnt?.pattern.indexOf('%year') !== -1
  );

  // TODO - Nambi - Need to check the input values see the file cosmos_stream_job_endpoint.html
  return (
    <>
      <Stack.Item>
        <ElxChoiceGroup
          onRenderLabel={() => (
            <LensLabel
              labelText={'Entity'}
              hintText={'Entity Type'}
            ></LensLabel>
          )}
          required={true}
          onChange={(e, option?: IChoiceGroupOption) => {
            setIsCosmos(Boolean(Number(option?.key)));
          }}
          options={endpointTypes.map((d, i) => ({
            key: i.toString(),
            text: d,
          }))}
          selectedKey={Number(isCosmos).toString()}
          styles={choiceGroupStyles}
        />
      </Stack.Item>
      {!isCosmos && (
        <Stack.Item>
          <ElxTextField
            id={'Geneva Entity Namespace'}
            onRenderLabel={() => {
              return (
                <LensLabel
                  labelText={'Geneva Entity Namespace'}
                  hintText={
                    'The Geneva entity namespace. It is the DisplayName property value of the Data Studio Catalog dataset, for example Microsoft.Cloud.Tree.OrganizationMetadataV2.'
                  }
                  required={true}
                ></LensLabel>
              );
            }}
            value={endpnt.catalogEntity}
            onChange={(e, val) => {
              endpnt.catalogEntity = val;
              onChange(activity, endpnt);
            }}
          ></ElxTextField>
        </Stack.Item>
      )}
      {isCosmos && (
        <>
          <Stack.Item>
            <ElxTextField
              id={'Stream Name / Folder'}
              onRenderLabel={() => {
                return (
                  <LensLabel
                    labelText={'Stream Name / Folder'}
                    hintText={
                      'The Cosmos stream name or folder, for example /shares/AzureAnalytics.Dev/Public-Published-Microsoft.Cloud.Tree/OrganizationMetadataV2/.Azure Storage Container Name'
                    }
                    required={true}
                  ></LensLabel>
                );
              }}
              value={endpnt.folder ?? ''}
              onChange={(e, val) => {
                endpnt.folder = val;
                onChange(activity, endpnt);
              }}
            ></ElxTextField>
          </Stack.Item>
          {isSource && (
            <>
              <Stack.Item>
                <ElxTextField
                  id={'Pattern'}
                  onRenderLabel={() => {
                    return (
                      <LensLabel
                        labelText={'Pattern'}
                        hintText={
                          'The pattern, for example %Y/%m/Data_%Y_%m_%d.ss.'
                        }
                        required={true}
                      ></LensLabel>
                    );
                  }}
                  value={endpnt.pattern ?? ''}
                  onChange={(e, val) => {
                    endpnt.pattern = val;
                    setIsModernPattern(endpnt.pattern?.indexOf('%year') !== -1);
                    onChange(activity, endpnt);
                  }}
                ></ElxTextField>
              </Stack.Item>

              {isModernPattern && (
                <>
                  <Stack.Item>
                    <ElxTextField
                      id={'Range Step in Seconds'}
                      onRenderLabel={() => {
                        return (
                          <LensLabel
                            labelText={'Range Step in Seconds'}
                            hintText={
                              'The range step in seconds, for example 86400 for one day (24*60*60).'
                            }
                            required={true}
                          ></LensLabel>
                        );
                      }}
                      value={endpnt.rangeStep ?? ''}
                      onChange={(e, val) => {
                        endpnt.rangeStep = val;
                        onChange(activity, endpnt);
                      }}
                    ></ElxTextField>
                  </Stack.Item>
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};
