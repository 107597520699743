import { _ } from 'utils/sharedLibs';
import userUtils from './userUtils';

/**
 * @name ParameterService
 * @description 
 * This service processes the ARGS parameters in saved object schema and replace them with URL parameters or built-in values (user names or user emails) 
 */
class ParameterService {

    constructor () {
        // Prefix for arguments used in saved object parameterization
        this.ParameterizationArgumentPrefix = "args_";
        this.ParameterizationPattern = /\{\{(ARGS.+?)\}\}/g;
        this.cachedUrlParameters = {};
    }

    getLensArguments() {
        if (!this.LensArguments) {
            this.LensArguments = {
                LensUserName: userUtils.getName(),
                LensUserEmail: userUtils.getEmail(),
            };
    
            this.LensArguments.LensUserAlias = userUtils.getAlias();
        }
        return this.LensArguments;
    }

    //#startregion Public methods
    /**
     * @name renderTemplate
     * @description 
     * Use URL parameters to render saved object template
     * @param {string} templateString - template to render, possibly contains ARGS parameters
     * @returns {string} rendered template which is free of ARGS parameters
     */
    renderTemplate(templateString) {
        var result = '';
        var parameters = {};
        var paramKeys = [];
        var rawArgs = null;
        var expectedArgs = [];
        var missingArgs = [];
        var lensArgumentKeys = _.keys(this.getLensArguments());

        if (templateString && templateString.length > 0) {
            parameters = this.extractArgumentsFromUrl();
            parameters = _.defaults(parameters, this.cachedUrlParameters);

            // Add Lens arguments 
            _.each(lensArgumentKeys, function (key) {
                if (!parameters[key]) {
                    var args = this.getLensArguments();
                    parameters[key] = args[key];
                }
            });

            paramKeys = _.keys(parameters);

            rawArgs = templateString.match(this.ParameterizationPattern);

            _.each(rawArgs, function (arg) {
                var newArg = arg.replace("{{ARGS.", "").replace("}}", "");

                // Skip checking ARGS with default values
                if (newArg.indexOf('||') === -1) {
                    expectedArgs.push(newArg);
                }
            });

            expectedArgs = _.uniq(expectedArgs);

            if (expectedArgs && expectedArgs.length > 0) {
                missingArgs = _.difference(expectedArgs, paramKeys);
                if (missingArgs.length > 0) {
                    var error = new Error("Unreplaced argument(s) in parameterized saved object. Missing argument(s): " + missingArgs.join(",") + ". ");
                    throw error;
                }
            }

            result = _.template(templateString, { interpolate: this.ParameterizationPattern });

            return result({ ARGS: parameters });
        }
        else {
            return "";
        }
    };

    /**
     * @name extractArgumentsFromUrl
     * @description 
     * Extract parameterization arguments from URL query string. Parameterization arguments have a common prefix, defined in this.ParameterizationArgumentPrefix
     */
    extractArgumentsFromUrl() {
        var result = {};
        var urlParameters = window.location.search();

        _.each(_.keys(urlParameters), function (key) {
            var paramName = null;

            if (key.indexOf(this.ParameterizationArgumentPrefix) === 0) {
                paramName = key.substring(this.ParameterizationArgumentPrefix.length);
                result[paramName] = urlParameters[key].replace("\\", "\\\\\\\\"); // JSON escape + String Escape
            }
        });

        return result;
    };

    /**
     * @name saveUrlParameters
     * @description 
     * Extract ARGS parameters from URL and saves in the cache of this service for later use.
     */
    saveUrlParameters() {
        this.cachedUrlParameters = this.extractArgumentsFromUrl();
    };
}

export const parameterService = new ParameterService();

export default parameterService;