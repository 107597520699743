export interface TelemetryDimensions {
  Origin: string;
  BuildNum: string;
  LensSessionId: string;
  TelemetryOriginId: string;
  Tenant: string;
  Url: string;
  UserAgent: string;
  StateUrl: string;
  StateName: string;
  UserId: string;
  UserName: string;
  Workspace: string;
  WorkspaceName: string;
  WorkspaceType: string;
  VersionId: string;
}

const initialDimensions: TelemetryDimensions = {
  Origin: 'React',
  BuildNum: '',
  LensSessionId: '',
  TelemetryOriginId: 'lensExplorer',
  Tenant: 'Microsoft',
  Url: '',
  UserAgent: '',
  StateName: '',
  StateUrl: '',
  UserId: '',
  UserName: '',
  Workspace: '',
  WorkspaceName: '',
  WorkspaceType: '',
  VersionId: '',
};

export let telemetryDimensions: TelemetryDimensions = initialDimensions;
