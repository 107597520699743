let leApp: any; // AngularJS dependency - minimize use

export enum Aside {
  DISCOVERY2 = 'discovery2',
  NOTIFICATIONS = 'notifications',
}

export function toggleAside(aside: Aside) {
  leApp.angularServices.asideService.toggle(aside);
}

export function inject(_leApp: any) {
  leApp = _leApp;
}
