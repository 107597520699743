import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from 'app/lensShellUtility';
import { SupportNotificationEntity } from './azureNotifications/models/supportNotificationEntity';
import { Notification } from './activityLogs/models/notification';
import { useDispatch, useSelector } from 'react-redux';
import { onNewNotification } from 'utils/notificationUtils';
import { useEffect } from 'react';
import notifier from 'utils/notifier';
import azureSupportNotificationApi from './azureNotifications/api/azureSupportNotificationApi';

export enum NotificationStatus {
  None = 'None',
  Loading = 'Loading',
  Loaded = 'Loaded',
  Error = 'Error',
}

interface NotificationSliceState {
  notifications: {
    activityLog: Notification[];
    supportNotification: SupportNotificationEntity[];
    supportNotificationStatus: NotificationStatus;
  };
}

const initialNotificationsState: NotificationSliceState = {
  notifications: {
    activityLog: [],
    supportNotification: [],
    supportNotificationStatus: NotificationStatus.None,
  },
};

// This hook is subscribing to the newNotificationEvent in leAppService
//The publisher for this event is NotificationService.js, addNotification function
export const useNotifications = () => {
  const dispatch = useDispatch<AppDispatch>();
  const supportNotificationStatus = useSelector(
    selectSupportNotificationsStatus
  );

  if (supportNotificationStatus === NotificationStatus.None) {
    dispatch(getActiveSupportNotifications());
  }

  useEffect(() => {
    let deregisternewNotifications = onNewNotification(
      (event: any, notification: any) => {
        dispatch(notificationsSlice.actions.addActivityLog(notification));
      }
    );
    return () => {
      // cleanup
      if (deregisternewNotifications) {
        deregisternewNotifications();
        deregisternewNotifications = undefined;
      }
    };
  }, [dispatch]);
};

export const getActiveSupportNotifications = createAsyncThunk(
  'notifications/getActiveSupportNotifications',
  async () => {
    return await azureSupportNotificationApi.getSupportNotifications();
  }
);

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: initialNotificationsState,
  reducers: {
    addActivityLog: (state, action) => {
      const notification = action.payload;
      state.notifications.activityLog = [
        ...state.notifications.activityLog,
        {
          id: state.notifications.activityLog.length,
          title: notification.title,
          type: notification.type,
          time: notification.time,
          description: JSON.stringify(notification?.details || [], null, 2),
        },
      ];
    },

    removeNotification: (state, action: PayloadAction<number>) => {
      state.notifications.activityLog = state.notifications.activityLog.filter(
        (item) => item.id !== action.payload
      );
    },
    dismissActivityLog: (state, action: PayloadAction<string>) => {
      if (action.payload === 'dimissall') {
        state.notifications.activityLog = [];
        return;
      }
      state.notifications.activityLog = state.notifications.activityLog.filter(
        (item) => item.type !== action.payload
      );
    },
    addSupportNotification: (
      state,
      action: PayloadAction<SupportNotificationEntity[]>
    ) => {
      state.notifications.supportNotification = [...action.payload];
    },
    updateSupportNotificationObj: (state, action: PayloadAction<string>) => {
      const supportNotificationCopy = [
        ...state.notifications.supportNotification,
      ];
      const index = supportNotificationCopy.findIndex(
        (item) => item.Id === action.payload
      );
      if (index !== -1) {
        supportNotificationCopy[index] = {
          ...supportNotificationCopy[index],
          Show: false,
        };
        state.notifications.supportNotification = supportNotificationCopy;
      }
    },
    showHideSupportNotification: (state, action: PayloadAction<boolean>) => {
      const supportNotificationCopy =
        state.notifications.supportNotification.map((item) => ({
          ...item,
          Show: action.payload,
        }));
      state.notifications.supportNotification = supportNotificationCopy;
    },
    unsetIsNewSupportNotification: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      const supportNotificationCopy =
        state.notifications.supportNotification.map((item) => ({
          ...item,
          IsNew:
            action.payload === undefined || action.payload === item.Id
              ? false
              : item.IsNew,
        }));
      state.notifications.supportNotification = supportNotificationCopy;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getActiveSupportNotifications.fulfilled, (state, action) => {
        state.notifications.supportNotification = action.payload;
        state.notifications.supportNotificationStatus =
          NotificationStatus.Loaded;
      })
      .addCase(getActiveSupportNotifications.rejected, (state, action) => {
        state.notifications.supportNotificationStatus =
          NotificationStatus.Error;
        notifier.error(
          'Error in requesting support notifications: ' + action.error
        );
      });
  },
});

export const notificationObj = (state: RootState) =>
  state.notifications.notifications;

export const allActivityLogs = (state: RootState) =>
  state.notifications.notifications.activityLog;

export const allSupportNotifications = (state: RootState) =>
  state.notifications.notifications.supportNotification;

export const selectSupportNotificationsStatus = (state: RootState) =>
  state.notifications.notifications.supportNotificationStatus;

export const uiNotifier = notificationsSlice.actions;
export default notificationsSlice.reducer;
