import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import { AppDispatch, RootState } from 'app/lensShellUtility';
import {
  Service,
  ServiceListStatus,
  ServiceTreeApiResponse,
  ServiceTreeApiService,
} from './models/service';
import serviceTreeApi from './api/serviceTreeApi';
import notifier from 'utils/notifier';

/**
 * Custom hook to use the Service Tree services.
 */
export const useServices = () => {
  const dispatch = useDispatch<AppDispatch>();
  const serviceListStatus = useSelector(selectServiceListStatus);

  useEffect(() => {
    if (serviceListStatus === ServiceListStatus.None) {
      dispatch(loadServices());
    }
  }, [dispatch, serviceListStatus]);
};

export const loadServices = createAsyncThunk(
  'services/loadServices',
  async (): Promise<object> => {
    var response = await serviceTreeApi.getServices();

    return response;
  }
);

const servicesAdapter = createEntityAdapter<Service>({
  selectId: (entity) => entity.id,
});

const initialState = {
  servicesList: servicesAdapter.getInitialState({
    status: ServiceListStatus.None,
  }),
  totalServicesCount: 0,
};

/**
 * Redux slice representing Service Tree services that are loaded on demand.
 */
const serviceTreeSlice = createSlice({
  name: 'serviceTree',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadServices.pending, (state, action) => {
        state.servicesList.status = ServiceListStatus.Loading;
      })
      .addCase(loadServices.fulfilled, (state, action) => {
        if (action.payload) {
          const response = action.payload as ServiceTreeApiResponse;

          var services = response.value;
          var transformed = services.map((service: ServiceTreeApiService) => {
            return {
              name: service.Name,
              serviceGroupId: service.ServiceGroupId,
              id: service.Id,
            } as Service;
          });

          servicesAdapter.upsertMany(state.servicesList, transformed);
          state.totalServicesCount = transformed.length;
        }
        state.servicesList.status = ServiceListStatus.Loaded;
      })
      .addCase(loadServices.rejected, (state, action) => {
        // error
        state.servicesList.status = ServiceListStatus.Error;
        notifier.error(
          'Error loading services. Message: ' + action.error.message
        );
      });
  },
});

export const {
  selectAll: selectAllServices,
  selectEntities: selectCatalogServicesEntities,
  selectById: selectCatalogServiceById,
  selectIds: selectCatalogServiceIds,
} = servicesAdapter.getSelectors(
  (state: RootState) => state.services.servicesList
);
export const selectServiceListStatus = (state: RootState) =>
  state.services.servicesList.status;
export const selectTotalServiceCount = (state: RootState) =>
  state.services.totalServicesCount;

export const serviceTreeSliceActions = serviceTreeSlice.actions;
export default serviceTreeSlice.reducer;
