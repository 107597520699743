import { MsalResponse } from 'utils/graphClient';
import { IPersonaProps } from '@fluentui/react/lib/Persona';
import { ValidationState } from '@fluentui/react/lib/Pickers';
import { PersonaType } from 'features/workspaces/models/workspace';

export const formatSuggestions = (
  promiseAllRes: MsalResponse[]
): IPersonaProps[] => {
  const users = promiseAllRes[0].value.map((element) => {
    let secondaryTextDescription = element.userPrincipalName
      ? element.userPrincipalName || ''
      : element.id;
    return {
      text: element.displayName,
      secondaryText: PersonaType.User + ': ' + secondaryTextDescription,
      //use the user id if the user does not have microsoft alias
      tertiaryText: element.userPrincipalName?.includes('@')
        ? element.userPrincipalName?.split('@')[0] || ''
        : element.id,
    };
  });
  const groups = promiseAllRes[1].value.map((element) => {
    return {
      text: element.displayName,
      secondaryText: PersonaType.Group + ': ' + element.id,
      tertiaryText: element.id,
    };
  });
  const applications = promiseAllRes[2].value.map((element) => {
    return {
      text: element.displayName,
      secondaryText: PersonaType.Application + ': ' + element.id,
      tertiaryText: element.id,
    };
  });
  return users.concat(groups, applications);
};

export function doesTextStartWith(text: string, filterText: string): boolean {
  return text.toLowerCase().indexOf(filterText.toLowerCase()) === 0;
}

export function removeDuplicates(
  personas: IPersonaProps[],
  possibleDupes: IPersonaProps[]
) {
  return personas.filter(
    (persona) => !listContainsPersona(persona, possibleDupes)
  );
}

export function listContainsPersona(
  persona: IPersonaProps,
  personas: IPersonaProps[]
) {
  if (!personas || !personas.length || personas.length === 0) {
    return false;
  }
  return personas.filter((item) => item.text === persona.text).length > 0;
}

export function getTextFromItem(persona: IPersonaProps): string {
  return persona.text as string;
}

export function validateInput(input: string): ValidationState {
  if (input.indexOf('@') !== -1) {
    return ValidationState.valid;
  } else if (input.length > 1) {
    return ValidationState.warning;
  } else {
    return ValidationState.invalid;
  }
}
