import { TimeSpan } from 'features/orchestrator/models/job';
import { _ } from 'utils/sharedLibs';

class Utils {
  public wait = <T>(ms: number): Promise<T> => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  public newGuid = (): string => {
    // c.f. rfc4122 (UUID version 4 = xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx)
    const hexValues = [
      '0',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      'A',
      'B',
      'C',
      'D',
      'E',
      'F',
    ];

    var oct = '';
    var tmp = 0;
    for (var a = 0; a < 4; a++) {
      tmp = (4294967296 * Math.random()) | 0;
      oct +=
        hexValues[tmp & 0xf] +
        hexValues[(tmp >> 4) & 0xf] +
        hexValues[(tmp >> 8) & 0xf] +
        hexValues[(tmp >> 12) & 0xf] +
        hexValues[(tmp >> 16) & 0xf] +
        hexValues[(tmp >> 20) & 0xf] +
        hexValues[(tmp >> 24) & 0xf] +
        hexValues[(tmp >> 28) & 0xf];
    }

    // Set the two most significant bits (bits 6 and 7) of the clock_seq_hi_and_reserved to zero and one, respectively
    var clockSequenceHi = hexValues[(8 + Math.random() * 4) | 0];
    var guid =
      oct.substr(0, 8) +
      '-' +
      oct.substr(9, 4) +
      '-4' +
      oct.substr(13, 3) +
      '-' +
      clockSequenceHi +
      oct.substr(16, 3) +
      '-' +
      oct.substr(19, 12);
    return guid.toLowerCase();
  };

  //export const isGuid = (item: string) => {
  //    return !!item.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/gi);
  //}

  /**
   * @ngdoc method
   * @name toDate
   * @methodOf Private.service:utilities
   * @description
   * Converts a value to a date.
   * @param {*} value - The value to convert.
   * @returns {Date} - The date.
   */
  public toDate = function (value: any) {
    if (_.isNil(value) || _.isDate(value)) return value;

    try {
      return new Date(value);
    } catch (e) {
      return undefined;
    }
  };

  /**
   * @ngdoc method
   * @name toJson
   * @methodOf Private.service:utilities
   * @description
   * Returns a json object or undefined if the text is not a valid Json
   * @param {string} text - The JSON text to deserialize into an object
   * @returns {Object} - The parsed deserialized object
   */
  public toJson = function (text: string) {
    try {
      return JSON.parse(text);
    } catch (e) {
      return undefined;
    }
  };

  ///**
  // * Generate all combination of arguments when given arrays or strings
  // * e.g. [['Ben','Jade','Darren'],['Smith','Miller']] to [['Ben','Smith'],[..]]
  // * e.g. 'the','cat' to [['t', 'c'],['t', 'a'], ...]
  //**/
  // function _cartesianProductOf(args) {
  //     if (arguments.length>1) {
  //         args=_.toArray(arguments);
  //     }

  //     // strings to arrays of letters
  //     args =_.map(args, function(opt){
  //         return typeof opt==='string'? _.toArray(opt):opt;
  //     });

  //     return _.reduce(args, function(a, b) {
  //         return _.flatten(_.map(a, function(x) {
  //             return _.map(b, function(y) {
  //                 return _.concat(x,[y]);
  //             });
  //         }), true);
  //     }, [ [] ]);
  // }

  ///** Generate all combination of arguments from objects
  // *  {Object} opts    - An object or arrays with keys describing options  {firstName:['Ben','Jade','Darren'],lastName:['Smith','Miller']}
  //*  {Array}        - An array of objects e.g. [{firstName:'Ben',LastName:'Smith'},{..]
  //**/
  // function _cartesianProductObj(optObj){
  //     var keys = _.keys(optObj);
  //     var opts = _.values(optObj);
  //     var combs = _cartesianProductOf(opts);
  //     return _.map(combs, function(comb){
  //         return _.zipObject(keys,comb);
  //     });
  // }

  //export const getJSONPaths = (o: { [x: string]: any; }, root: string, result: string[]): string[] | undefined => {
  //    root = root || '';
  //    result = result || [];
  //    if (!o || typeof o !== 'object') {
  //        if (root && root !== '') {
  //            result.push('$' + root);
  //        }
  //        return;
  //    }
  //    var ok = Object.keys(o);
  //    _.forEach(ok, function (key) {
  //        getJSONPaths(o[key], root + '.' + key, result);
  //    });
  //    return result;
  //};

  //export const hexToRgba = (hex: string, opacity: number): string | null => {              //ToDo: See if it can be merged with spectrum.js
  //    if (hex.indexOf('rgb(') === 0) {
  //        return 'rgba(' + hex.substring(4, hex.length - 1) + ',' + opacity + ')';
  //    }
  //    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  //    return result ? 'rgba(' + parseInt(result[1], 16) + ',' + parseInt(result[2], 16) + ',' + parseInt(result[3], 16) + ',' + opacity + ')' : null;
  //};

  //export const getSessionId = (): string => {
  //    if (!window.LENS_SESSION_ID) {
  //        window.LENS_SESSION_ID = newGuid();
  //    }
  //    return window.LENS_SESSION_ID;
  //};

  //export const newExceptionId = (): string => {
  //    window.LENS_EXCEPTION_ID = newGuid();
  //    return window.LENS_EXCEPTION_ID;
  //};

  public escapeName(name: string, alwaysEscape = false): string {
    if (_.startsWith(name, '[@') || name === '*') {
      // Field already escaped. No escaping required for '*' operand
      return name;
    }

    var pattern = /[^_a-zA-Z0-9]/;
    var kustoKeywordList = [
      'and',
      'anomalychart',
      'areachart',
      'asc',
      'barchart',
      'between',
      'bool',
      'boolean',
      'by',
      'columnchart',
      'consume',
      'contains',
      'containscs',
      'count',
      'date',
      'datetime',
      'default',
      'desc',
      'distinct',
      'double',
      'dynamic',
      'endswith',
      'evaluate',
      'extend',
      'false',
      'filter',
      'find',
      'first',
      'flags',
      'float',
      'getschema',
      'has',
      'hasprefix',
      'hassuffix',
      'in',
      'int',
      'join',
      'journal',
      'kind',
      'ladderchart',
      'last',
      'like',
      'limit',
      'linechart',
      'long',
      'materialize',
      'mvexpand',
      'notcontains',
      'notlike',
      'of',
      'or',
      'order',
      'parse',
      'piechart',
      'pivotchart',
      'print',
      'project',
      'queries',
      'real',
      'regex',
      'sample',
      'scatterchart',
      'search',
      'set',
      'sort',
      'stacked',
      'stacked100',
      'stackedareachart',
      'startswith',
      'string',
      'summarize',
      'take',
      'time',
      'timechart',
      'timeline',
      'timepivot',
      'timespan',
      'to',
      'top',
      'toscalar',
      'true',
      'union',
      'unstacked',
      'viewers',
      'where',
      'withsource',
    ]; // add more keywords here

    if (
      pattern.test(name) ||
      kustoKeywordList.indexOf(name) > -1 ||
      alwaysEscape
    ) {
      if (name.indexOf('"') > -1) {
        return "[@'" + name + "']";
      } else {
        return '[@"' + name + '"]';
      }
    } else {
      return name;
    }
  }

  //export const sizeof = (object: { [x: string]: any; }): number => {
  //    let objectList: object[] = [];
  //    var recurse = function (value: string | { [x: string]: any; }) {
  //        if (typeof value === 'boolean') {
  //            return 4;
  //        }
  //        else if (typeof value === 'string') {
  //            return value.length * 2;
  //        }
  //        else if (typeof value === 'number') {
  //            return 8;
  //        }
  //        else if (typeof value === 'object' && objectList.indexOf(value) === -1) {
  //            var bytes = 0;
  //            objectList[objectList.length] = value;
  //            for (let property in value) {
  //                if (value.hasOwnProperty(property)) {
  //                    bytes += 8; // an assumed existence overhead
  //                    bytes += recurse(value[property]);
  //                }
  //            }

  //            return bytes;
  //        }

  //        return 0;
  //    };

  //    return recurse(object);
  //};

  // export const convertToUtcAndFormat = (dateVal: string | number, format: string): string => {
  //     var date = new Date(dateVal);

  //     if (date == 'Invalid Date') {
  //         dateVal = moment(dateVal);
  //     }

  //     var validDate = new Date(dateVal);
  //     var utcVal = validDate.toUTCString();
  //     var utcMoment = moment.utc(utcVal, 'ddd, DD MMM YYYY HH:mm:ss Z');

  //     // moment.utc doesn't update milliseconds -> fall-back to Date.getMilliseconds
  //     utcMoment.milliseconds = function () {
  //         return validDate.getMilliseconds() || (dateVal.getMilliseconds && dateVal.getMilliseconds()) || 0;
  //     }

  //     if (!utcMoment.isValid()) {
  //         var err = errors.InvalidDate();

  //         var message = 'Failed to convert date to UTC';
  //         if (Object.prototype.toString.call(err) !== '[object Error]') {
  //             var error = new Error(message);
  //             error.caller = 'convertToUtcAndFormat';
  //             notify.error(err);
  //         } else {
  //             err.message = message + err.message;
  //             err.caller = 'convertToUtcAndFormat';
  //             notify.error(err);
  //         }
  //     }

  //     return utcMoment.format(format);
  // };

  //export const convertToSortText = (order: number): string => {
  //    var sortText = '';
  //    var numCharacters = 26; // 'z' - 'a' + 1;

  //    var div = Math.floor(order / numCharacters);
  //    for (var i = 0; i < div; ++i) {
  //        sortText += 'z';
  //    }

  //    var reminder = order % numCharacters;
  //    if (reminder > 0) {
  //        sortText += String.fromCharCode(96 + reminder);
  //    }

  //    return sortText;
  //};

  //export const guessLineEndings = (input: string): string => {
  //    input = input.substr(0, 1024 * 1024);    // max length 1 MB
  //    var r = input.split('\r');
  //    var n = input.split('\n');
  //    var nAppearsFirst = (n.length > 1 && n[0].length < r[0].length);

  //    if (r.length === 1 || nAppearsFirst) {
  //        return '\n';
  //    }

  //    var numWithN = 0;

  //    for (var i = 0; i < r.length; i++) {
  //        if (r[i][0] === '\n')
  //            numWithN++;
  //    }

  //    return numWithN >= r.length / 2 ? '\r\n' : '\r';
  //};

  camelCase = (value: object): object => {
    if (Array.isArray(value)) {
      return value.map((v) => this.camelCase(v));
    }

    if (!_.isObject(value)) {
      return value;
    }

    // upcase the first letter of all property names
    value = _.mapKeys(value, function (value, key) {
      return key.charAt(0).toLowerCase() + key.slice(1);
    });

    value = _.mapValues(value, this.camelCase);

    return value;
  };

  public pascalCase = (value: any): any => {
    if (Array.isArray(value)) {
      return value.map((v) => this.pascalCase(v));
    }

    if (_.isObject(value)) {
      // upcase the First letter of all properties
      value = _.mapKeys(value, function (value, key) {
        return key.charAt(0).toUpperCase() + key.slice(1);
      });
      value = _.mapValues(value, this.pascalCase);
      return value;
    }

    return value;
  };

  // export const product = (opts: Array<any>): Array<any> => {
  //     if (arguments.length===1 && !_.isArray(opts))
  //         return _cartesianProductObj(opts)
  //     else if (arguments.length===1)
  //         return _cartesianProductOf(opts);
  //     else
  //         return _cartesianProductOf(arguments);
  // };

  // export const permutations = (obj: Array<any>, n: number) => {
  //     if (typeof obj=='string') {
  //         obj = _.toArray(obj)
  //     }

  //     n = n || obj.length;

  //     // make n copies of keys/indices
  //     for (var j = 0, nInds=[]; j < n; j++) {
  //         nInds.push(_.keys(obj));
  //     }
  //     // get product of the indices, then filter to remove the same key twice
  //     var arrangements = self.product(nInds).filter(function(pair: any[]){
  //         return pair[0] !== pair[1];
  //     });

  //     return _.map(arrangements, function(indices) {
  //         return _.map(indices, function(i) {
  //             return obj[i];
  //             }
  //         );
  //     })
  // };

  // export const combinations = (obj: Array<any>,n: number) => {
  //     /* filter out keys out of order, e.g. [0,1] is ok but [1,0] isn't */
  //     function isSorted(arr: any) {
  //         return _.every(arr, function (value, index, array) {
  //             return index === 0 || String(array[index - 1]) <= String(value);
  //         });
  //     }
  //     // array with n copies of the keys of obj
  //     return _(self.permutations(_.keys(obj),n))
  //         .filter(isSorted)
  //         .map(function(indices) {
  //             return _.map(indices,function(i){
  //                 return obj[i];
  //             });
  //         })
  //         .value();
  // };

  //export const calculateAspectRatio = (imgWidth: number, imgHeight: number, newSize: number, takeWidth: boolean) => {
  //    if (imgHeight > 0) {
  //        var aspectRatio = imgWidth / imgHeight;
  //        if (takeWidth) {
  //            return Math.floor(newSize / aspectRatio)
  //        } else {
  //            return Math.floor(newSize * aspectRatio);
  //        }
  //    }
  //    return 0;
  //}

  //export const getControlKeyPress = (event: KeyboardEvent) => {
  //    if (event && (event.ctrlKey || event.metaKey)) {
  //        return String.fromCharCode(event.which).toLowerCase();
  //    }
  //}

  //export const convertLocalToUtc = (date: Date, atDate: Date): Date => {
  //    atDate = atDate || new Date();
  //    var timezoneOffset = atDate.getTimezoneOffset();
  //    return new Date(date.getTime() + timezoneOffset * 60000);
  //};

  //export const convertUtcToLocal = (date: Date, atDate: Date): Date => {
  //    atDate = atDate || new Date();
  //    var timezoneOffset = - atDate.getTimezoneOffset();
  //    return new Date(date.getTime() + timezoneOffset * 60000);
  //};

  /**
   * Remove duplicates in array of objects based on a property of the object
   * from: https://stackoverflow.com/a/46219650
   * @param array array you want to remove duplicates from
   * @param propertyName object property you want to be unique
   * @returns the array with unique values of the given propertyname
   */
  public unique(array: any[], propertyName: string) {
    return array.filter(
      (e, i) =>
        array.findIndex((a) => a[propertyName] === e[propertyName]) === i
    );
  }

  /**
   * @ngdoc method
   * @name convertTimeSpanToTime
   * @methodOf Private.service:utilities
   * @description
   * Converts a time span into a JavaScript time, a number of milliseconds.
   * @param {Object} timeSpan - The time span to format.
   * @return {Number} The number of milliseconds.
   */
  public convertTimeSpanToTime(timeSpan: TimeSpan | null) {
    if (!timeSpan) return null;

    var result = 0;
    result += timeSpan.days || 0;
    result *= 24; // hours
    result += timeSpan.hours || 0;
    result *= 60; // min
    result += timeSpan.minutes || 0;
    result *= 60; // sec
    result += timeSpan.seconds || 0;
    result *= 1000; // msec
    return result;
  }
  /**
   * @ngdoc method
   * @name parseTimeSpan
   * @methodOf Private.service:utilities
   * @description
   * Parses a string into a time span. The string is a standard .NET TimeSpan format string.
   * @param {String} value - The string to parse.
   * @return {Object} The parsed time span, an object of { days: Number, hours: Number, minutes: Number, seconds: Number }.
   */
  public parseTimeSpan(value: string): TimeSpan | null {
    if (!value) return null;

    let timeSpan: TimeSpan = {
      days: null,
      hours: null,
      minutes: null,
      seconds: null,
    };

    // Parse days alone: [-]D
    if (!_.includes(value, ':')) {
      timeSpan.days = _.toInteger(value);
      timeSpan.hours = 0;
      timeSpan.minutes = 0;
      timeSpan.seconds = 0;
      return timeSpan;
    }

    // Parse a TimeSpan string value: [-]d.hh:mm:ss.ff
    var tokens = _.split(value, ':');
    var tokenCount = _.size(tokens);
    if (tokenCount > 0) {
      // Parse days and hours: [-]D.HH:mm:ss.ff
      var firstToken = _.nth(tokens, 0);
      if (!_.includes(firstToken, '.')) {
        var firstValue = _.toNumber(firstToken);
        if (firstValue >= 0 && firstValue <= 23) {
          // Parse hours 0 - 23: HH:mm:ss.ff
          timeSpan.days = 0;
          timeSpan.hours = firstValue;
        } else {
          // Parse days below 0 or above 23: [-]D:mm:ss.ff
          timeSpan.days = firstValue;
          timeSpan.hours = 0;
        }
      } else {
        // Parse days and hours: [-]D.HH:mm:ss.ff
        var dayTokens = _.split(firstToken, '.');
        if (_.size(dayTokens) !== 2) {
          throw new Error('The value must be a TimeSpan: "' + value + '"');
        }
        timeSpan.days = _.toInteger(_.nth(dayTokens, 0));
        timeSpan.hours = _.toInteger(_.nth(dayTokens, 1));
      }

      // Parse minutes: [-]d.hh:MM:ss.ff
      if (tokenCount <= 1) {
        timeSpan.minutes = 0;
      } else {
        timeSpan.minutes = _.toInteger(_.nth(tokens, 1));
      }

      // Parse seconds: [-]d.hh:mm:SS.FF
      if (tokenCount <= 2) {
        timeSpan.seconds = 0;
      } else {
        timeSpan.seconds = _.toInteger(_.nth(tokens, 2));
      }
    }

    return timeSpan;
  }
}

export const utils = new Utils();

export default utils;
