import LeApi from 'api/leApi';
import { isAdmin } from 'utils/authUtils';
import constants from 'utils/constants';
import { Job, JobInstance, JobExecutionStatus } from '../models/job';

class JobApi extends LeApi {
  public createJob = async (
    workspaceId: string,
    job: Job,
    scmId: string | null = null
  ): Promise<Job> => {
    return await this.postEntity<Job, Job>(
      `/v1/workspaces/${workspaceId}/jobs`,
      job,
      { params: { scmId: scmId } }
    );
  };

  public getJob = async (workspaceId: string, jobId: string): Promise<Job> => {
    return await this.getEntity<Job>(
      `/v1/workspaces/${workspaceId}/jobs/${jobId}`
    );
  };

  public getJobs = async (workspaceId: string): Promise<Job[]> => {
    return await this.getEntity<Job[]>(
      `/v1/workspaces/${workspaceId}/jobs/?includeInstances=true`
    ).then((jobList) => {
      if (!isAdmin()) {
        // Remove auto created lens jobs.
        const jobLst = jobList.filter((job) =>
          job.tags ? !job.tags.includes(constants.NotificationJobTag) : true
        );
        return jobLst;
      } else {
        return jobList;
      }
    });
  };

  public getJobWithVersionNumber = async (
    workspaceId: string,
    jobId: string,
    versionNumber: string
  ): Promise<Job> => {
    return await this.getEntity<Job>(
      `/v1/workspaces/${workspaceId}/jobs/${jobId}/versions/${versionNumber}`
    );
  };

  public getJobVersions = async (
    workspaceId: string,
    jobId: string
  ): Promise<Job[]> => {
    return await this.getEntity<Job[]>(
      `v1/workspaces/${workspaceId}/jobs/${jobId}/versions`
    );
  };

  public deleteJob = async (
    workspaceId: string,
    jobId: string,
    scmId: string | null = null
  ): Promise<Job> => {
    return await this.deleteEntity<Job>(
      `/v1/workspaces/${workspaceId}/jobs/${jobId}`,
      { params: { scmId: scmId } }
    );
  };

  public updateJob = async (
    workspaceId: string,
    jobId: string,
    job: Job
  ): Promise<Job> => {
    return await this.putEntity<Job>(
      `/v1/workspaces/${workspaceId}/jobs/${jobId}`,
      job
    );
  };

  public enableJob = async (
    workspaceId: string,
    jobId: string,
    params: any | null
  ): Promise<null> => {
    return await this.postEntity<null, null>(
      `/v1/workspaces/${workspaceId}/jobs/${jobId}/enable`,
      null,
      { params: params }
    );
  };

  public disableJob = async (
    workspaceId: string,
    jobId: string,
    params: any | null
  ): Promise<null> => {
    return await this.postEntity<null, null>(
      `/v1/workspaces/${workspaceId}/jobs/${jobId}/disable`,
      null,
      { params: params }
    );
  };

  public getDependencyEvents = async (
    workspaceId: string,
    jobId: string
  ): Promise<any> => {
    return await this.getEntity<any>(
      `/v1/workspaces/${workspaceId}/jobs/${jobId}/dependencies/events`
    );
  };

  // Job Instances apis

  public getJobInstances = async (
    workspaceId: string,
    jobId: string,
    tag: string | null = null
  ): Promise<JobInstance[]> => {
    return await this.getEntity<JobInstance[]>(
      `/v1/workspaces/${workspaceId}/jobs/${jobId}/instances` +
        (tag ? `?tag=${tag}` : ``),
      { headers: { 'x-ms-max-item-count': 1000 } }
    );
  };

  public getJobInstanceTags = async (
    workspaceId: string,
    jobId: string
  ): Promise<string[]> => {
    return await this.getEntity<string[]>(
      `/v1/workspaces/${workspaceId}/jobs/${jobId}/instanceTags`
    );
  };

  public getJobInstance = async (
    workspaceId: string,
    jobId: string,
    instanceId: string
  ): Promise<JobInstance> => {
    return await this.getEntity<JobInstance>(
      `/v1/workspaces/${workspaceId}/jobs/${jobId}/instances/${instanceId}`
    );
  };

  // TODO - Nambi - looks like it is returning JObject - need to build a model corresponding to this.
  public getJobInstanceLogs = async (
    workspaceId: string,
    jobId: string,
    instanceId: string
  ): Promise<any> => {
    return await this.getEntity<any>(
      `/v1/workspaces/${workspaceId}/jobs/${jobId}/instances/${instanceId}/logs`
    );
  };

  public scheduleJob = async (
    workspceId: string,
    jobId: string,
    settings: any | null,
    params: any | null
  ): Promise<JobInstance> => {
    return await this.postEntity<any, JobInstance>(
      `/v1/workspaces/${workspceId}/jobs/${jobId}/scheduleInstance`,
      settings,
      { params: params }
    );
  };

  public cancelJobInstance = async (
    workspceId: string,
    jobId: string,
    instanceId: string
  ): Promise<null> => {
    return await this.postEntity<null, null>(
      `/v1/workspaces/${workspceId}/jobs/${jobId}/instances/${instanceId}/cancel`,
      null
    );
  };

  public cancelJobInstances = async (
    workspceId: string,
    jobId: string,
    listType: JobExecutionStatus
  ): Promise<null> => {
    return await this.postEntity<null, null>(
      `/v1/workspaces/${workspceId}/jobs/${jobId}/cancelInstances/${listType}`,
      null
    );
  };

  public terminateJobInstance = async (
    workspceId: string,
    jobId: string,
    instanceId: string | null
  ): Promise<null> => {
    return await this.postEntity<null, null>(
      `/v1/workspaces/${workspceId}/jobs/${jobId}/instances/${instanceId}/terminate`,
      null
    );
  };

  public rerunJobInstance = async (
    workspceId: string,
    jobId: string,
    instanceId: string
  ): Promise<JobInstance> => {
    return await this.postEntity<null, JobInstance>(
      `/v1/workspaces/${workspceId}/jobs/${jobId}/instances/${instanceId}/rerun`,
      null
    );
  };

  public getJobDependenciesEvent = async (
    workspaceId: string,
    jobId: string
  ): Promise<any> => {
    return await this.getEntity<Job[]>(
      `v1/workspaces/${workspaceId}/jobs/${jobId}/dependencies/events`
    );
  };
}

export const jobApi = new JobApi();
export default jobApi;
