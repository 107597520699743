import {
  ElxSearchBox,
  FilterOption,
  FilterOptionPillMode,
  IElxSearchBoxProps,
  ISearchBoxFilter,
  ISearchCriteria,
} from '@elixir/components';
import { IColumn, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import { Service } from 'features/serviceTree/models/service';
import { CatalogEntityApiFormat } from '../api/catalogApiModels';
import {
  CatalogEntity,
  CatalogEntityType,
  CatalogSearchFilter,
  CatalogSearchRequest,
} from '../models/catalogEntity';

const getSearchDelimiter = (queryEngine: string | null | undefined): string => {
  switch (queryEngine) {
    case 'Kusto':
      return '|';
    default:
      return '.';
  }
};

export function getName(item: CatalogEntityApiFormat): string {
  var searchDelimiter = getSearchDelimiter(item.QueryEngine);

  var startIndex = item.Name?.lastIndexOf(searchDelimiter) || 0;
  if (startIndex !== 0) startIndex++;
  var name = item.Name?.substring(startIndex);

  return name || '';
}

export function getNamespace(item: CatalogEntityApiFormat): string {
  var searchDelimiter = getSearchDelimiter(item.QueryEngine);

  var lastIndex = item.Name?.lastIndexOf(searchDelimiter);
  var namespace = item.Name?.substring(0, lastIndex);

  return namespace || '';
}

const typePillFilter = (catalogEntityList: CatalogEntity[]): FilterOption => {
  return {
    field: 'type',
    label: 'Type',
    multiselect: true,
    pillMode: FilterOptionPillMode.Dynamic,
    values: Object.values(CatalogEntityType).sort(),
  };
};

const servicePillFilter = (servicesList: Service[]): FilterOption => {
  return {
    field: 'service',
    label: 'Service',
    multiselect: true,
    pillMode: FilterOptionPillMode.Dynamic,
    values: servicesList
      .map((service) => service.name || '')
      .filter((value, index, self) => self.indexOf(value) === index)
      .sort(),
  };
};

export function getPillFilters(
  catalogEntityList: CatalogEntity[],
  servicesList: Service[]
) {
  return [typePillFilter(catalogEntityList), servicePillFilter(servicesList)];
}

export const loadingCardBody = () => {
  return (
    <Stack horizontal verticalAlign="center" horizontalAlign="center">
      <Stack.Item
        styles={{ root: { paddingTop: '10px', paddingBottom: '10px' } }}
      >
        <Spinner size={SpinnerSize.large} />
      </Stack.Item>
    </Stack>
  );
};

export const loadedCardBody = (count: number) => {
  return (
    <>
      {Intl.NumberFormat('en-US', {
        notation: 'compact',
        maximumSignificantDigits: 3,
      }).format(count)}
    </>
  );
};

export const createCatalogRequest = (
  value: ISearchCriteria
): CatalogSearchRequest => {
  var request: CatalogSearchRequest = { Filter: [] };

  request.Query = value.keyword;

  for (var filterName in value.filters) {
    var filter = value.filters[filterName];
    var filterVal = filter.value;

    if (filterVal !== undefined) {
      var name = filterName;
      if (filterName === 'type') {
        name = 'QueryEngine';
      }

      var newFilter: CatalogSearchFilter;

      if (typeof filterVal === 'string') {
        newFilter = {
          Name: name,
          Value: filterVal?.toString(),
        };

        request.Filter?.push(newFilter);
      } else if (typeof filterVal === 'object') {
        for (var val of filterVal as string[]) {
          newFilter = {
            Name: name,
            Value: val,
          };

          request.Filter?.push(newFilter);
        }
      }
    }
  }

  if (request.Filter?.length === 0) {
    request.Filter = undefined;
  }

  return request;
};

/* Start - code from ElxTableContainer */
export const renderStackedElements = (elements: JSX.Element[]): JSX.Element => {
  return (
    <>
      {elements.map((elem: JSX.Element, index: number) => {
        const key = 'stack' + (elem.key || index.toString());
        return <Stack key={key}>{elem}</Stack>;
      })}
    </>
  );
};

export const renderSearchBox = (
  onChange: (value: ISearchCriteria) => void,
  filteredColumns: IColumn[],
  props?: IElxSearchBoxProps
): JSX.Element => {
  const filters =
    props && props.filters
      ? props.filters.filter((o: ISearchBoxFilter) => {
          return (
            o.displayInline ||
            filteredColumns.findIndex((x: IColumn) => x.key === o.key) > -1
          );
        })
      : undefined;

  const changeHandler = (val: ISearchCriteria): void => {
    onChange(val);
    if (props && props.onChange) {
      props.onChange(val);
    }
  };

  return <ElxSearchBox {...props} onChange={changeHandler} filters={filters} />;
};
/* End - code from ElxTableContainer */
