import { ElxLink } from '@elixir/components/lib/Link/ElxLink';
import {
  PersonaType,
  Workspace,
  WorkspaceAccessFilter,
  WorkspacePersona,
} from '../../../models/workspace';
import constants from 'utils/constants';
import utils from 'utils/utils';
import { titleTextStyle } from '../workspaceStyles';

const getRequestAccessGroups = (
  workspace: Workspace,
  accessFilter: WorkspaceAccessFilter | string
) => {
  let displayNames = [];

  if (accessFilter === WorkspaceAccessFilter.Read) {
    displayNames.push(
      workspace.readOnlyAliases,
      workspace.readOnlyAppIds,
      workspace.readOnlyGroupIds
    );
  } else if (accessFilter === WorkspaceAccessFilter.Write) {
    displayNames.push(
      workspace.readWriteAliases,
      workspace.readWriteAppIds,
      workspace.readWriteGroupIds
    );
  } else if (accessFilter === WorkspaceAccessFilter.Orchestrate) {
    displayNames.push(
      workspace.orchestrateAliases,
      workspace.orchestrateAppIds,
      workspace.orchestrateGroupIds
    );
  } else if (accessFilter === WorkspaceAccessFilter.Admin) {
    displayNames.push(
      workspace.adminAliases,
      workspace.adminAppIds,
      workspace.adminGroupIds
    );
  }

  return (
    <ul>
      {utils.unique(displayNames.flat(), 'id').map((d: WorkspacePersona) => {
        return d.type === PersonaType.User ||
          d.type === PersonaType.Application ? (
          <li
            key={d.id}
            data-testid={accessFilter + '-' + d.type + ':' + d.displayName}
          >
            {d.type}: {d.displayName}
          </li>
        ) : (
          <li
            key={d.id}
            data-testid={accessFilter + '-' + d.type + ':' + d.displayName}
          >
            <ElxLink
              href={
                constants.Urls.IdWebGroupSearchLinkPrefix +
                encodeURIComponent(d.displayName)
              }
              target="_blank"
            >
              {d.displayName}
            </ElxLink>
            <span>Group: {d.displayName}</span>
          </li>
        );
      })}
    </ul>
  );
};

export const getAccessMessage = (
  workspace: Workspace,
  accessPermissions: {
    accessType: WorkspaceAccessFilter;
    permission: boolean;
  }[],
  isExternalOrGuest: boolean
) => {
  let accessMessages: JSX.Element[] = [
    <div key={'accessInfoOverview'} data-testid="accessInfoOverview">
      <div style={titleTextStyle}>Overview:</div>
      <p>
        You have <b>{workspace.accessFilter}</b> access to{' '}
        <b>{workspace.name}</b>.
      </p>
      <br />
      <div style={titleTextStyle}>Permission Details:</div>
    </div>,
  ];

  accessPermissions.forEach(
    (p: { accessType: WorkspaceAccessFilter; permission: boolean }) => {
      if (!p.permission) {
        if (
          p.accessType === WorkspaceAccessFilter.Read &&
          workspace.customReadAccessMessage
        ) {
          accessMessages.push(
            <p
              key={p.accessType + ':' + p.permission}
              data-testid={'accessInfoOverview-custom-' + p.accessType}
            >
              {workspace.customReadAccessMessage}
            </p>
          );
        } else if (
          p.accessType === WorkspaceAccessFilter.Write &&
          workspace.customWriteAccessMessage
        ) {
          accessMessages.push(
            <p
              key={p.accessType + ':' + p.permission}
              data-testid={'accessInfoOverview-custom-' + p.accessType}
            >
              {workspace.customReadAccessMessage}
            </p>
          );
        } else {
          accessMessages.push(
            !isExternalOrGuest ? (
              <div
                key={p.accessType + ':' + p.permission}
                data-testid={'accessInfoOverview-' + p.accessType}
              >
                To get <b>{p.accessType}</b> access to workspace{' '}
                <b>{workspace.name}</b>, please request access to below groups.
                Owners of those groups will need to grant you access. Lens
                Explorer team is <b>not</b> responsible for approving these
                requests.
                <br />
                {getRequestAccessGroups(workspace, p.accessType)}
              </div>
            ) : (
              <div
                key={p.accessType + ':' + p.permission}
                data-testid={'accessInfoOverview-' + p.accessType}
              >
                To get <b>{p.accessType}</b> access to workspace{' '}
                <b>{workspace.name}</b>, please submit a request below. The
                links will redirect you to the groups in IDWeb. Owners of the
                security group will need to grant you access. Lens Explorer team
                is <b>not</b> responsible for approving these requests.
                <br />
                {getRequestAccessGroups(workspace, p.accessType)}
              </div>
            )
          );
        }
      }
    }
  );

  return accessMessages;
};
