import { ElxTextField } from '@elixir/components';
import { ChoiceGroup, IChoiceGroupOption, Stack } from '@fluentui/react';
import { ConnectionDataSourceType } from 'features/workspaces/models/project';
import { useState } from 'react';
import { LensLabel } from 'utils/lensLabel';
import { SelectorProps } from '../editDataConnectionHelper';

export const EventHubSelector = (props: SelectorProps): JSX.Element => {
  const { state, setState } = props;
  const [generateNameCount, setGenerateNameCount] = useState(0);

  function calculateInitChoice() {
    if (state.connectionSecretUri) {
      return 'Connection String';
    } else {
      return 'AAD';
    }
  }

  const [selectedKey, setSelectedKey] = useState<string | undefined>(
    calculateInitChoice()
  );

  const authOptions: IChoiceGroupOption[] = [
    { key: 'AAD', text: 'AAD' },
    { key: 'ConnectionString', text: 'Connection String' },
  ];

  return (
    <Stack tokens={{ childrenGap: 16 }}>
      <Stack.Item>
        <LensLabel
          labelText="Authentication Method"
          hintText={' Authentication method for sql data connection'}
          required={true}
        ></LensLabel>
        <ChoiceGroup
          selectedKey={selectedKey}
          options={authOptions}
          onChange={(ev, option: IChoiceGroupOption | undefined) => {
            setGenerateNameCount(generateNameCount + 1);
            setSelectedKey(option?.key);
            //clear the event hub specific form state on authentication method change
            setState({
              name: state.name,
              authenticationMode: state.authenticationMode,
              ...(state.applicationId && {
                applicationId: state.applicationId,
              }),
              ...(state.tenant && { tenant: state.tenant }),
              type: ConnectionDataSourceType.EventHub,
              ...(state.isImported && {
                isImported: state.isImported,
              }),
              id: state.id,
            });
          }}
        />
      </Stack.Item>
      {selectedKey === 'AAD' && (
        <>
          <ElxTextField
            onRenderLabel={() => (
              <LensLabel
                labelText="Event Hub Namespace Name"
                hintText={'Azure Event Hub Namespace Name'}
                required={true}
              ></LensLabel>
            )}
            placeholder="<Event Hub Namespace Name>"
            value={state.eventHubNamespace || ''}
            onChange={(e, newValue) => {
              setGenerateNameCount(generateNameCount + 1);
              setState({
                ...state,
                type: ConnectionDataSourceType.EventHub,
                eventHubNamespace: newValue || '',
                eventHubName: state.eventHubName || '',
              });
            }}
          />
          <ElxTextField
            onRenderLabel={() => (
              <LensLabel
                labelText="Event Hub Name"
                hintText={'Azure Event Hub Name.'}
                required={true}
              ></LensLabel>
            )}
            placeholder="<Event Hub Name>"
            value={state.eventHubName || ''}
            onChange={(e, newValue) => {
              setGenerateNameCount(generateNameCount + 1);
              setState({
                ...state,
                type: ConnectionDataSourceType.EventHub,
                eventHubNamespace: state.eventHubNamespace || '',
                eventHubName: newValue || '',
              });
            }}
          />
        </>
      )}
      {selectedKey === 'ConnectionString' && (
        <ElxTextField
          onRenderLabel={() => (
            <LensLabel
              labelText="Connection Secret Key Vault URI"
              hintText={
                'Key Vault URI where the Connection String containing access credentials for Event Hub is stored.'
              }
              required={true}
            ></LensLabel>
          )}
          placeholder="https://<mykeyvault>.vault.azure.net/secrets/<connection-string>"
          value={state.connectionSecretUri || ''}
          onChange={(e, newValue) => {
            setGenerateNameCount(generateNameCount + 1);
            setState({
              ...state,
              type: ConnectionDataSourceType.EventHub,
              connectionSecretUri: newValue || '',
            });
          }}
        />
      )}
    </Stack>
  );
};
