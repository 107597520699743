import React from 'react';
import { IPersonaProps, IPersonaStyles } from '@fluentui/react/lib/Persona';
import {
  IBasePickerSuggestionsProps,
  NormalPeoplePicker,
  PeoplePickerItemSuggestion,
} from '@fluentui/react/lib/Pickers';
import {
  PeoplePickerType,
  PersonaType,
  Workspace,
} from 'features/workspaces/models/workspace';
import {
  formatSuggestions,
  getTextFromItem,
  removeDuplicates,
  validateInput,
} from './peoplePickerLogic';
import { getPeople } from 'utils/graphClient';
import { convertToWorkspacePersona } from 'features/workspaces/utils/workspaceUtils';

const suggestionProps: IBasePickerSuggestionsProps = {
  suggestionsHeaderText: 'Suggested People',
  mostRecentlyUsedHeaderText: 'Suggested Contacts',
  noResultsFoundText: 'No results found',
  loadingText: 'Loading',
  showRemoveButtons: true,
  suggestionsAvailableAlertText: 'People Picker Suggestions available',
  suggestionsContainerAriaLabel: 'Suggested contacts',
};

interface PeoplePickerProps {
  people: string[];
  workspace: Workspace;
  updateParentState: (workspace: Workspace) => void;
  onChange: (event: any) => void;
  name: PeoplePickerType;
}

const personaStyles: Partial<IPersonaStyles> = {
  root: {
    height: 'auto',
  },
  secondaryText: {
    height: 'auto',
    whiteSpace: 'normal',
  },
  primaryText: {
    height: 'auto',
    whiteSpace: 'normal',
  },
};

const PeoplePicker = (props: PeoplePickerProps): JSX.Element => {
  let people =
    props.people.map((p) => {
      return JSON.parse(p);
    }) || [];

  const [peopleList, setPeopleList] = React.useState<IPersonaProps[]>(people);
  const picker = React.useRef(null);

  const onFilterChanged = (
    filterText: string,
    currentPersonas: IPersonaProps[] | undefined,
    limitResults?: number
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    if (filterText && filterText.length >= 3) {
      return getPeople(filterText).then((data) => {
        let filteredPersonas: IPersonaProps[] = formatSuggestions(data);

        filteredPersonas = removeDuplicates(
          filteredPersonas,
          currentPersonas || []
        );
        filteredPersonas = limitResults
          ? filteredPersonas.slice(0, limitResults)
          : filteredPersonas;
        return filteredPersonas;
      });
    } else {
      return [];
    }
  };

  const onRemoveSuggestion = (item: IPersonaProps): void => {
    const indexPeopleList: number = peopleList.indexOf(item);

    if (indexPeopleList >= 0) {
      const newPeople: IPersonaProps[] = peopleList
        .slice(0, indexPeopleList)
        .concat(peopleList.slice(indexPeopleList + 1));
      setPeopleList(newPeople);
    }
  };

  const onRenderSuggestionItem = (
    personaProps: IPersonaProps,
    suggestionsProps: IBasePickerSuggestionsProps
  ) => {
    return (
      <PeoplePickerItemSuggestion
        personaProps={{ ...personaProps, styles: personaStyles }}
        suggestionsProps={suggestionsProps}
      />
    );
  };

  function updateParentStateHelper(
    type: PeoplePickerType,
    selectedPersonsa: IPersonaProps[]
  ) {
    switch (type) {
      case PeoplePickerType.Administrators:
        props.updateParentState({
          ...props.workspace,
          adminAliases: selectedPersonsa
            .filter((p) => p.secondaryText?.split(':')[0] === PersonaType.User)
            .map((p) => convertToWorkspacePersona(p, PersonaType.User)),
          adminAppIds: selectedPersonsa
            .filter(
              (p) => p.secondaryText?.split(':')[0] === PersonaType.Application
            )
            .map((p) => convertToWorkspacePersona(p, PersonaType.Application)),
          adminGroupIds: selectedPersonsa
            .filter((p) => p.secondaryText?.split(':')[0] === PersonaType.Group)
            .map((p) => convertToWorkspacePersona(p, PersonaType.Group)),
          adminGroups: selectedPersonsa
            .filter((p) => p.secondaryText?.split(':')[0] === PersonaType.Group)
            .map((p) => p.text || ''),
          administrators: selectedPersonsa.map((p) => JSON.stringify(p)),
        });
        break;
      case PeoplePickerType.Writers:
        props.updateParentState({
          ...props.workspace,
          readWriteAliases: selectedPersonsa
            .filter((p) => p.secondaryText?.split(':')[0] === PersonaType.User)
            .map((p) => convertToWorkspacePersona(p, PersonaType.User)),
          readWriteAppIds: selectedPersonsa
            .filter(
              (p) => p.secondaryText?.split(':')[0] === PersonaType.Application
            )
            .map((p) => convertToWorkspacePersona(p, PersonaType.Application)),
          readWriteGroupIds: selectedPersonsa
            .filter((p) => p.secondaryText?.split(':')[0] === PersonaType.Group)
            .map((p) => convertToWorkspacePersona(p, PersonaType.Group)),
          readWriteGroups: selectedPersonsa
            .filter((p) => p.secondaryText?.split(':')[0] === PersonaType.Group)
            .map((p) => p.text || ''),
          writers: selectedPersonsa.map((p) => JSON.stringify(p)),
        });
        break;
      case PeoplePickerType.Readers:
        props.updateParentState({
          ...props.workspace,
          readOnlyAliases: selectedPersonsa
            .filter((p) => p.secondaryText?.split(':')[0] === PersonaType.User)
            .map((p) => convertToWorkspacePersona(p, PersonaType.User)),
          readOnlyAppIds: selectedPersonsa
            .filter(
              (p) => p.secondaryText?.split(':')[0] === PersonaType.Application
            )
            .map((p) => convertToWorkspacePersona(p, PersonaType.Application)),
          readOnlyGroupIds: selectedPersonsa
            .filter((p) => p.secondaryText?.split(':')[0] === PersonaType.Group)
            .map((p) => convertToWorkspacePersona(p, PersonaType.Group)),
          readOnlyGroups: selectedPersonsa
            .filter((p) => p.secondaryText?.split(':')[0] === PersonaType.Group)
            .map((p) => p.text || ''),
          readers: selectedPersonsa.map((p) => JSON.stringify(p)),
        });

        break;
      case PeoplePickerType.Orchestrators:
        props.updateParentState({
          ...props.workspace,
          orchestrateAliases: selectedPersonsa
            .filter((p) => p.secondaryText?.split(':')[0] === PersonaType.User)
            .map((p) => convertToWorkspacePersona(p, PersonaType.User)),
          orchestrateAppIds: selectedPersonsa
            .filter(
              (p) => p.secondaryText?.split(':')[0] === PersonaType.Application
            )
            .map((p) => convertToWorkspacePersona(p, PersonaType.Application)),
          orchestrateGroupIds: selectedPersonsa
            .filter((p) => p.secondaryText?.split(':')[0] === PersonaType.Group)
            .map((p) => convertToWorkspacePersona(p, PersonaType.Group)),
          orchestrateGroups: selectedPersonsa
            .filter((p) => p.secondaryText?.split(':')[0] === PersonaType.Group)
            .map((p) => p.text || ''),
          orchestrators: selectedPersonsa.map((p) => JSON.stringify(p)),
        });
        break;
    }
  }
  return (
    <NormalPeoplePicker
      onResolveSuggestions={onFilterChanged} //help suggest people based on input
      getTextFromItem={getTextFromItem}
      className={'ms-PeoplePicker'}
      aria-label={'Selected contacts'}
      defaultSelectedItems={peopleList}
      pickerSuggestionsProps={{ ...suggestionProps, showRemoveButtons: false }}
      onRemoveSuggestion={onRemoveSuggestion}
      onRenderSuggestionsItem={onRenderSuggestionItem}
      onValidateInput={validateInput}
      onChange={(selectedPersonsa) => {
        props.onChange({
          target: {
            name: 'updatedSelected',
            value: selectedPersonsa,
          },
        });
        updateParentStateHelper(props.name, selectedPersonsa || []);
      }}
      inputProps={{
        'aria-label': props.name + ' People Picker',
      }}
      componentRef={picker}
      resolveDelay={1000}
    />
  );
};

export default PeoplePicker;
