import {
  CatalogEntityApiFormat,
  CatalogEntityHistoryApiResponse,
} from '../api/catalogApiModels';

// Catalog Entity Structure
export interface CatalogEntity {
  manifestKey: string; // unique identifier
  name: string;
  namespace: string;
  displayName: string;
  type: string; // QueryEngine
  description: string;
  service: string;
  serviceOid: string;
  authorizedReaders?: string | null;
  authorizedWriters?: string | null;
  isPrivate?: boolean | null;
  lastUpdate?: string | null;
  updatedBy?: string | null;
  createdBy?: string | null;
  createdDate?: string | null;
  columns?: CatalogEntityColumn[];
  deprecated?: string | undefined; // datetime format
  quarantined?: string | undefined; // datetime format
  additionalProperties?: AdditionalProperties | null;
  // not in original type
  originalAPIResponse: CatalogEntityApiFormat;
  nameWithNamespace: string;
  isSearchResult?: boolean | undefined;
  GDPRScope?: string | undefined;
}

export interface CatalogEntityColumn {
  name: string;
  type: string;
  description: string;
  effectiveDate: string;
}

export interface AdditionalProperties {
  container?: string | null;
  location?: string | null;
  format?: string | null;
  owner?: string | null;
  source?: string | null;
  isActive?: boolean | null;
  manifestType?: string | null;
  deprecated?: string | null;
  partitionedBy?: string | null;
  collectionAgent?: string | null;
  refreshPeriod?: string | null;
  miscAttributes?: string | null;
}

// Search
export interface CatalogSearchFilter {
  Name: string;
  Value: string | boolean;
  Source?: string;
  Condition?: string;
}

export interface CatalogSearchRequest {
  Query?: string;
  SearchFields?: string[];
  Filter?: CatalogSearchFilter[];
  Offset?: number;
  Count?: number;
  Order?: string;
  Facets?: boolean;
  RankingMethod?: string;
  IgnoreHiddenProperties?: boolean;
}

export interface CatalogSearchResponse {
  Manifests: CatalogEntityApiFormat[];
  TotalCount: number;
}

// History
export interface CatalogEntityHistoryResponseWrapper {
  status?: CatalogEntityStatus;
  manifestKey: string;
  historyResponse: CatalogEntityHistoryApiResponse | undefined;
}

export interface CatalogEntityHistoryAdapterFormat {
  status?: CatalogEntityStatus;
  manifestKey: string;
  historyEntries: HistoryEntry[] | undefined;
}

export interface HistoryEntry {
  changeLocation: string;
  name: string;
  newValue?: string;
  oldValue?: string;
  operation: string;
  propertyType: string;
  updateTime: string;
  userIdentifier: string | null;
  userObjectId: string;
  applicationAppId: string;
}

// Catalog Enums
export enum CatalogEntityStatus {
  None = 'None',
  Loading = 'Loading', // loading page 1 (blocked)
  Fetching = 'Fetching', // fetching pages 2 - N (show spinner)
  Loaded = 'Loaded',
  Error = 'Error',
}

export enum CatalogEntityType {
  ADL = 'ADL',
  AWSS3 = 'AWSS3',
  ApacheHive = 'ApacheHive',
  Application = 'Application',
  ApplicationInsights = 'ApplicationInsights',
  Aria = 'Aria',
  AzureAnalysisServices = 'AzureAnalysisServices',
  AzureBlob = 'AzureBlob',
  AzureEventHub = 'AzureEventHub',
  AzureFile = 'AzureFile',
  AzureLogAnalytics = 'AzureLogAnalytics',
  AzureQueue = 'AzureQueue',
  AzureRedisCache = 'AzureRedisCache',
  AzureSQL = 'AzureSQL',
  AzureSQLDataWarehouse = 'AzureSQLDataWarehouse',
  AzureServiceBus = 'AzureServiceBus',
  AzureTable = 'AzureTable',
  Cassandra = 'Cassandra',
  ClearDB = 'ClearDB',
  Cosmos = 'Cosmos',
  CosmosDB = 'CosmosDB',
  ETW = 'ETW',
  ElasticSearch = 'ElasticSearch',
  ExoSubstrate = 'ExoSubstrate',
  FileShare = 'FileShare',
  HDFS = 'HDFS',
  HockeyApp = 'HockeyApp',
  Interana = 'Interana',
  Kusto = 'Kusto',
  MDS = 'MDS',
  MongoDB = 'MongoDB',
  MySQL = 'MySQL',
  Neo4j = 'Neo4j',
  OnPremisesSQL = 'OnPremisesSQL',
  PostgreSQL = 'PostgreSQL',
  SSAS = 'SSAS',
  SecretStore = 'SecretStore',
  ServiceFabric = 'ServiceFabric',
  ServiceFabricReliableCollection = 'ServiceFabricReliableCollection',
  SubstrateSDSCollection = 'SubstrateSDSCollection',
  Vertica = 'Vertica',
  Webstore = 'Webstore',
}
