import MonacoEditor, { MonacoDiffEditor } from 'react-monaco-editor';
interface EditorProps {
  value: string;
  onChange?: (value: string) => void;
  language?: string | undefined;
  readOnly?: boolean;
  width?: string | number | undefined;
  height?: string | number | undefined;
}

export const Editor = (props: EditorProps) => {
  return (
    <MonacoEditor
      width={props.width || '100%'}
      height={props.height || '100%'}
      language={props.language || 'javascript'}
      theme="vs-light"
      value={props.value}
      options={{
        readOnly: props.readOnly || false,
        automaticLayout: true,
      }}
      onChange={(newValue, e) => {
        if (props.onChange) props.onChange(newValue);
      }}
    />
  );
};

interface DiffEditorProps {
  original: string;
  modified: string;
  language?: string | undefined;
  readOnly?: boolean;
  onChange?: (value: string) => void;
  width?: string | number | undefined;
  height?: string | number | undefined;
}

export const DiffEditor = (props: DiffEditorProps) => {
  return (
    <MonacoDiffEditor
      width={props.width || '100%'}
      height={props.height || '100%'}
      language={props.language || 'javascript'}
      original={props.original}
      value={props.modified}
      options={{
        readOnly: props.readOnly || false,
        renderIndicators: true,
        diffCodeLens: true,
        automaticLayout: true,
      }}
      onChange={(newValue, e) => {
        if (props.onChange) props.onChange(newValue);
      }}
    />
  );
};
