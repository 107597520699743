import { LeWebApi } from 'api/leWebApi';
import {
  CatalogSearchFilter,
  CatalogSearchRequest,
  CatalogSearchResponse,
} from '../models/catalogEntity';
import {
  CatalogEntityApiFormat,
  CatalogEntityApiUpdateResponse,
  CatalogEntityHistoryApiResponse,
} from './catalogApiModels';

class CatalogApi extends LeWebApi {
  public search = async (
    request: CatalogSearchRequest
  ): Promise<CatalogSearchResponse> => {
    let filterList = request.Filter || [];

    const privateFilter: CatalogSearchFilter = {
      Name: 'IsPrivate',
      Value: false,
    };

    filterList.push(privateFilter);

    const searchRequest: CatalogSearchRequest = {
      ...request,
      Filter: filterList,
    };

    return await this.postEntity<CatalogSearchRequest, CatalogSearchResponse>(
      '/assetcatalog/searchdatacatalog',
      searchRequest
    );
  };

  public getCatalogEntity = async (
    manifestKey: string
  ): Promise<CatalogEntityApiFormat> => {
    return await this.getEntity<CatalogEntityApiFormat>(
      '/assetcatalog/entity/' + manifestKey
    );
  };

  public getCatalogEntityByName = async (
    name: string
  ): Promise<CatalogEntityApiFormat> => {
    return await this.getEntity<CatalogEntityApiFormat>(
      '/assetcatalog/entitybyname/' + name
    );
  };

  public updateCatalogEntity = async (
    entity: CatalogEntityApiFormat
  ): Promise<CatalogEntityApiUpdateResponse> => {
    return await this.putEntity<
      CatalogEntityApiFormat,
      CatalogEntityApiUpdateResponse
    >('/assetcatalog/entity/', entity);
  };

  public getCatalogEntityHistory = async (
    manifestKey: string
  ): Promise<CatalogEntityHistoryApiResponse> => {
    return await this.getEntity<CatalogEntityHistoryApiResponse>(
      '/assetcatalog/history/' + manifestKey
    );
  };
}

export const catalogApi = new CatalogApi();

export default catalogApi;
