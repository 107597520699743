import { LeWebApi } from 'api/leWebApi';
import {
  UserSavedObjectData,
  UserSavedObjectDataResponse,
} from '../models/userSavedObjectData';

export class UserSavedObjectDataApi extends LeWebApi {
  public getUserFavorites = async (): Promise<UserSavedObjectData[]> => {
    return await this.getEntity<UserSavedObjectData[]>(
      '/userinfo/usersavedobject/userfavorites'
    );
  };

  public saveUserFavorites = async (
    userFavorites: UserSavedObjectData[]
  ): Promise<UserSavedObjectData[]> => {
    return await this.putEntity<UserSavedObjectData[]>(
      '/userinfo/usersavedobject/userfavorites',
      userFavorites
    );
  };

  public deleteUserFavorites = async (
    userFavorites: UserSavedObjectData[]
  ): Promise<UserSavedObjectDataResponse> => {
    return await this.deleteEntity<UserSavedObjectDataResponse>(
      '/userinfo/usersavedobject/userfavorites',
      {
        data: userFavorites,
      }
    );
  };

  public getUserRecents = async (): Promise<UserSavedObjectData[]> => {
    return await await this.getEntity<UserSavedObjectData[]>(
      '/userinfo/usersavedobject/userrecents'
    );
  };

  public saveUserRecents = async (
    userRecents: UserSavedObjectData[]
  ): Promise<UserSavedObjectData[]> => {
    return await this.putEntity<UserSavedObjectData[]>(
      '/userinfo/usersavedobject/userrecents',
      userRecents
    );
  };

  public deleteUserRecents = async (
    userRecents: UserSavedObjectData[]
  ): Promise<UserSavedObjectDataResponse> => {
    return await this.deleteEntity<UserSavedObjectDataResponse>(
      '/userinfo/usersavedobject/userrecents',
      {
        data: userRecents,
      }
    );
  };
}

export const userSavedObjectDataApi = new UserSavedObjectDataApi();

export default userSavedObjectDataApi;
