import { getUser } from 'utils/sharedLibs';

let leApp: any; // AngularJS dependency - minimize use

export function inject(_leApp: any) {
  leApp = _leApp;
}

export function getCurrentUser() {
  return getUser();
}

export function getAADTenantId() {
  return getUser().tenantId;
}

export function switchAADTenant() {
  leApp.switchAADTenant();
}

export function logOut() {
  leApp.logOut();
}

export function getName(): string {
  const user = getUser();
  return user?.name;
}

export function getAlias(): string {
  const email = getEmail();
  if (email?.indexOf('@') > 0) {
    return email.substring(0, email.indexOf('@'));
  }
  return email;
}

export function getEmail(): string {
  const user = getUser();
  return user?.username;
}

export function getObjectId(): string {
  const user = getUser();
  return user?.idTokenClaims?.oid;
}

export function runAsAdmin(value: boolean) {
  localStorage.clear();
  localStorage.setItem('useReactUI', true.toString());
  if (value) {
    // Store the admin setting in local storage (across tabs).
    localStorage.setItem('lensAdmin', true.toString());
  }
  sessionStorage.clear();
  window.setTimeout(function () {
    window.location.reload();
  });
}

export function isAdmin(): boolean {
  return !!localStorage?.getItem('lensAdmin');
}
