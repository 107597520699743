import { LeWebApi } from 'api/leWebApi';
import {
  GrafanaDatasourceResponse,
  GrafanaCreateKustoDatasourceRequest,
  GrafanaMigrationRequest,
  GrafanaMigrationResponse,
  GrafanaCreateKustoDatasourceResponse,
  GrafanaFolder,
  GrafanaFolderRequest,
} from '../models/grafanaMigrationData';

export class GrafanaMigrationApi extends LeWebApi {
  public getFoldersForInstance = async (instanceUrl: string): Promise<any> => {
    return await this.getEntity<any>(
      '/grafana/folders?instanceUrl=' + instanceUrl
    );
  };

  public createFolder = async (instanceUrl: string, folderName: string) => {
    return await this.postEntity<GrafanaFolderRequest, GrafanaFolder>(
      '/grafana/folders?instanceUrl=' + instanceUrl,
      { title: folderName }
    );
  };

  public getDatasourcesForInstance = async (
    instanceUrl: string
  ): Promise<GrafanaDatasourceResponse> => {
    return await this.getEntity<GrafanaDatasourceResponse>(
      '/grafana/datasources?instanceUrl=' + instanceUrl
    );
  };

  public createKustoDatasource = async (
    datasource: GrafanaCreateKustoDatasourceRequest,
    instanceUrl: string
  ): Promise<GrafanaCreateKustoDatasourceResponse> => {
    return await this.postEntity<
      GrafanaCreateKustoDatasourceRequest,
      GrafanaCreateKustoDatasourceResponse
    >('/grafana/datasources?instanceUrl=' + instanceUrl, datasource);
  };

  public migrateDashboard = async (
    dashboard: GrafanaMigrationRequest,
    instanceUrl: string
  ): Promise<GrafanaMigrationResponse> => {
    return await this.putEntity<
      GrafanaMigrationRequest,
      GrafanaMigrationResponse
    >('grafana/migrate/dashboard?instanceUrl=' + instanceUrl, dashboard);
  };
}

export const grafanaMigrationApi = new GrafanaMigrationApi();

export default grafanaMigrationApi;
