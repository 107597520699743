import { Stack } from '@fluentui/react';
import {
  ElxActionButton,
  ElxDropdown,
  ElxIconButton,
  ElxTextField,
} from '@elixir/components';
import React from 'react';

import { ParametersWithType, parameterType } from '../models/job';

const parameterOptionsType = parameterType.map((o, i) => {
  return { key: i, text: o };
});

interface JobParameterItemsWithTypeProps {
  parameters: ParametersWithType[];
  onNameChange: (name: string, idx: number) => void;
  onValueChange: (value: string, idx: number) => void;
  onRemove: (idx: number) => void;
  onAdd: () => void;
  onTypeChange: (value: string, idx: number) => void;
  title?: string;
  noTitle?: Boolean;
}

export function JobParameterItemsWithType(
  props: JobParameterItemsWithTypeProps
) {
  return (
    <Stack
      style={{
        overflow: 'auto',
        width: '600px',
      }}
      tokens={{
        childrenGap: 4,
        padding: 16,
        maxHeight: 800,
      }}
    >
      <>
        <Stack horizontal>
          <Stack.Item className="ms-lg4" style={{ fontWeight: 'bold' }}>
            <div>Name</div>
          </Stack.Item>
          <Stack.Item className="ms-lg4" style={{ fontWeight: 'bold' }}>
            <div>Value</div>
          </Stack.Item>
          <Stack.Item className="ms-lg4" style={{ fontWeight: 'bold' }}>
            <div>Type</div>
          </Stack.Item>
        </Stack>
        {props.parameters.map((o, idx) => (
          <>
            <Stack horizontal>
              <Stack.Item className="ms-lg4">
                <ElxTextField
                  value={o.name}
                  onChange={(_, name) => props.onNameChange(name || '', idx)}
                  styles={{
                    root: {
                      marginRight: '10px',
                    },
                  }}
                ></ElxTextField>
              </Stack.Item>
              <Stack.Item className="ms-lg4">
                <ElxTextField
                  value={o.value}
                  onChange={(_, value) => props.onValueChange(value || '', idx)}
                  styles={{
                    root: {
                      marginRight: '10px',
                      height: 32,
                    },
                  }}
                ></ElxTextField>
              </Stack.Item>
              <Stack.Item className="ms-lg4">
                <Stack horizontal>
                  <ElxDropdown
                    options={parameterOptionsType}
                    selectedKey={parameterType.indexOf(o.type)}
                    onChange={(e, value) =>
                      props.onTypeChange(value?.text || '', idx)
                    }
                    styles={{ root: { marginRight: '10px' } }}
                  ></ElxDropdown>
                  <ElxIconButton
                    iconProps={{
                      iconName: 'cancel',
                    }}
                    text={''}
                    onClick={() => props.onRemove(idx)}
                  ></ElxIconButton>
                </Stack>
              </Stack.Item>
            </Stack>
          </>
        ))}
      </>

      {/* <Stack.Item>
           <Stack horizontal> */}
      <Stack.Item
        className="ms-lg12"
        align="end"
        style={{
          paddingRight: '40px',
        }}
      >
        <ElxActionButton
          iconProps={{
            iconName: 'Add',
          }}
          text="Add"
          onClick={() => {
            props.onAdd();
          }}
          style={{
            float: 'right',
          }}
        />
        <ElxActionButton />
      </Stack.Item>
      {/* </Stack>
          </Stack.Item> */}
    </Stack>
  );
}
