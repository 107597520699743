import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import {
  CatalogEntity,
  CatalogEntityHistoryAdapterFormat,
  CatalogEntityHistoryResponseWrapper,
  CatalogEntityStatus,
  CatalogSearchRequest,
  CatalogSearchResponse,
  HistoryEntry,
} from './models/catalogEntity';
import catalogApi from './api/catalogApi';
import { AppDispatch, RootState } from 'app/lensShellUtility';
import notifier from 'utils/notifier';
import {
  processHistoryRecords,
  getTransformedEntity,
} from './utils/catalogSliceUtils';
import {
  CatalogEntityApiFormat,
  CatalogEntityApiUpdateResponse,
} from './api/catalogApiModels';

export const catalogRequestCount = 250;

/**
 * Custom hook to use the Data Catalog Entities.
 */
export const useCatalogEntityHistory = (
  manifestKey: string
): CatalogEntityHistoryAdapterFormat | undefined => {
  const dispatch = useDispatch();

  const entityHistory = useSelector((state: RootState) => {
    return selectCatalogEntityHistoryByManifestKey(state, manifestKey);
  });

  useEffect(() => {
    if (
      manifestKey &&
      manifestKey !== '' &&
      (entityHistory?.status === undefined ||
        entityHistory?.status === CatalogEntityStatus.None)
    ) {
      dispatch(loadCatalogEntityHistory(manifestKey));
    }
  }, [dispatch, entityHistory, manifestKey]);

  return entityHistory;
};

/**
 * Custom hook to use the Data Catalog Entities Stats.
 */
export const useCatalogEntityStats = () => {
  const dispatch = useDispatch<AppDispatch>();
  const totalEntitiesCountStatus = useSelector(
    selectTotalCatalogEntitiesCountStatus
  );
  const kustoEntitiesCountStatus = useSelector(selectKustoEntitiesCountStatus);
  const MDSEntitiesCountStatus = useSelector(selectMDSEntitiesCountStatus);

  useEffect(() => {
    if (totalEntitiesCountStatus === CatalogEntityStatus.None) {
      dispatch(loadTotalEntityCount());
    }
    if (kustoEntitiesCountStatus === CatalogEntityStatus.None) {
      dispatch(loadKustoEntityCount());
    }
    if (MDSEntitiesCountStatus === CatalogEntityStatus.None) {
      dispatch(loadMDSEntityCount());
    }
  }, [
    dispatch,
    totalEntitiesCountStatus,
    MDSEntitiesCountStatus,
    kustoEntitiesCountStatus,
  ]);
};

export const updateCatalogEntity = createAsyncThunk(
  'catalog/updateEntity',
  async (
    entity: CatalogEntityApiFormat
  ): Promise<CatalogEntityApiUpdateResponse> => {
    var response = await catalogApi.updateCatalogEntity(entity);

    return response;
  }
);

export const loadCatalogEntities = createAsyncThunk(
  'catalog/loadEntities',
  async (
    request: CatalogSearchRequest | undefined
  ): Promise<CatalogSearchResponse> => {
    if (request === undefined) {
      request = {
        Query: '',
      };
    }
    request.Count = catalogRequestCount; // 250; default = 50; max = 1000

    var response = await catalogApi.search(request);

    return response;
  }
);

interface SingleCatalogEntityResponse {
  manifestKey: string;
  catalogEntity: CatalogEntityApiFormat | undefined;
}

export const loadCatalogEntity = createAsyncThunk<
  SingleCatalogEntityResponse,
  string
>(
  'catalog/loadEntity',
  async (manifestKey: string): Promise<SingleCatalogEntityResponse> => {
    var response = undefined;

    if (manifestKey && manifestKey !== '') {
      response = await catalogApi.getCatalogEntity(manifestKey);
    }

    var loadedEntity: SingleCatalogEntityResponse = {
      manifestKey: manifestKey,
      catalogEntity: response,
    };
    return loadedEntity;
  }
);

export const loadTotalEntityCount = createAsyncThunk(
  'catalog/loadTotalEntityCount',
  async (): Promise<CatalogSearchResponse> => {
    var request = createRequest(undefined);
    var response = await catalogApi.search(request);

    return response;
  }
);

export const loadKustoEntityCount = createAsyncThunk(
  'catalog/loadKustoEntityCount',
  async (): Promise<CatalogSearchResponse> => {
    var request = createRequest('kusto');
    var response = await catalogApi.search(request);

    return response;
  }
);

export const loadMDSEntityCount = createAsyncThunk(
  'catalog/loadMDSEntityCount',
  async (): Promise<CatalogSearchResponse> => {
    var request = createRequest('MDS');
    var response = await catalogApi.search(request);

    return response;
  }
);

export const loadCatalogEntityHistory = createAsyncThunk<
  CatalogEntityHistoryResponseWrapper,
  string
>('catalog/loadEntityHistory', async (manifestKey: string): Promise<any> => {
  if (!manifestKey) {
    notifier.error('Cannot load history for unknown entity');
  } else {
    var response = await catalogApi.getCatalogEntityHistory(manifestKey);

    const newResp: CatalogEntityHistoryResponseWrapper = {
      manifestKey,
      historyResponse: response,
    };

    return newResp;
  }
});

const createRequest = (
  queryEngineType: string | undefined
): CatalogSearchRequest => {
  var request: CatalogSearchRequest = {};

  if (queryEngineType !== undefined) {
    request.Filter = [];

    request.Filter.push({
      Name: 'QueryEngine',
      Value: queryEngineType,
    });
  }

  request.Count = 0;

  return request;
};

const catalogEntitiesAdapter = createEntityAdapter<CatalogEntity>({
  selectId: (entity) => entity.manifestKey,
});

const catalogEntitiesHistoryAdapter =
  createEntityAdapter<CatalogEntityHistoryAdapterFormat>({
    selectId: (entity) => entity.manifestKey,
  });

const initialState = {
  catalogEntityList: catalogEntitiesAdapter.getInitialState({
    status: CatalogEntityStatus.None,
  }),
  catalogEntityHistoryList: catalogEntitiesHistoryAdapter.getInitialState({
    status: CatalogEntityStatus.None,
  }),
  currentEntityListCount: 0,
  totalEntitiesCountStatus: CatalogEntityStatus.None,
  totalEntitiesCount: 0,
  kustoEntitiesCountStatus: CatalogEntityStatus.None,
  kustoEntitiesCount: 0,
  MDSEntitiesCountStatus: CatalogEntityStatus.None,
  MDSEntitiesCount: 0,
  loadCatalogEntityStatus: {
    manifestKey: '',
    status: CatalogEntityStatus.None,
  },
  updateCatalogEntityStatus: CatalogEntityStatus.None,
};

/**
 * Redux slice representing Data Catalog Entities that are loaded on demand.
 */
const catalogSlice = createSlice({
  name: 'catalog',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateCatalogEntity.pending, (state, action) => {
        state.updateCatalogEntityStatus = CatalogEntityStatus.Loading;
      })
      .addCase(updateCatalogEntity.fulfilled, (state, action) => {
        if (action.payload) {
          state.updateCatalogEntityStatus = CatalogEntityStatus.Loaded;
          notifier.info('Successfully updated entity.');
        }
      })
      .addCase(updateCatalogEntity.rejected, (state, action) => {
        // error
        state.updateCatalogEntityStatus = CatalogEntityStatus.Error;
        notifier.error(
          'Error updating catalog entity. Message: ' + action.error.message
        );
      })
      .addCase(loadCatalogEntities.pending, (state, action) => {
        state.catalogEntityList.status = CatalogEntityStatus.Loading;
      })
      .addCase(loadCatalogEntities.fulfilled, (state, action) => {
        if (
          !action.meta?.arg?.Offset ||
          action.meta?.arg?.Offset === undefined ||
          action.meta?.arg?.Offset <= 0
        ) {
          catalogEntitiesAdapter.removeAll(state.catalogEntityList);
        }
        if (action.payload) {
          const response = action.payload;

          state.currentEntityListCount = response.TotalCount;

          var entities = response.Manifests;
          var transformedList = entities.map((entity) => {
            return { ...getTransformedEntity(entity), isSearchResult: true };
          });

          catalogEntitiesAdapter.addMany(
            state.catalogEntityList,
            transformedList
          );
          state.catalogEntityList.status = CatalogEntityStatus.Loaded;
        }
      })
      .addCase(loadCatalogEntities.rejected, (state, action) => {
        // error
        state.catalogEntityList.status = CatalogEntityStatus.Error;
        notifier.error(
          'Error loading catalog entities. Message: ' + action.error.message
        );
      })
      .addCase(loadCatalogEntity.pending, (state, action) => {
        state.loadCatalogEntityStatus = {
          manifestKey: action?.meta?.arg,
          status: CatalogEntityStatus.Loading,
        };
      })
      .addCase(loadCatalogEntity.fulfilled, (state, action) => {
        if (action.payload) {
          const entity = action.payload.catalogEntity;

          if (entity) {
            var transformed: CatalogEntity = getTransformedEntity(entity);
            transformed.isSearchResult = false;

            catalogEntitiesAdapter.upsertOne(
              state.catalogEntityList,
              transformed
            );
            state.loadCatalogEntityStatus = {
              manifestKey: action.payload.manifestKey,
              status: CatalogEntityStatus.Loaded,
            };
          }
        }
      })
      .addCase(loadCatalogEntity.rejected, (state, action) => {
        // error
        state.loadCatalogEntityStatus = {
          manifestKey: action?.meta?.arg,
          status: CatalogEntityStatus.Error,
        };
        notifier.error(
          'Error loading catalog entity with manifest key: ' +
            action?.meta?.arg +
            '. Message: ' +
            action.error.message
        );
      })
      .addCase(loadTotalEntityCount.pending, (state, action) => {
        state.totalEntitiesCountStatus = CatalogEntityStatus.Loading;
      })
      .addCase(loadTotalEntityCount.fulfilled, (state, action) => {
        if (action.payload) {
          const response = action.payload;

          state.totalEntitiesCount = response.TotalCount;

          state.totalEntitiesCountStatus = CatalogEntityStatus.Loaded;
        }
      })
      .addCase(loadTotalEntityCount.rejected, (state, action) => {
        // error
        state.totalEntitiesCountStatus = CatalogEntityStatus.Error;
        notifier.error(
          'Error loading the total number of catalog entities. Message: ' +
            action.error.message
        );
      })
      .addCase(loadKustoEntityCount.pending, (state, action) => {
        state.kustoEntitiesCountStatus = CatalogEntityStatus.Loading;
      })
      .addCase(loadKustoEntityCount.fulfilled, (state, action) => {
        if (action.payload) {
          const response = action.payload;

          state.kustoEntitiesCount = response.TotalCount;
          state.kustoEntitiesCountStatus = CatalogEntityStatus.Loaded;
        }
      })
      .addCase(loadKustoEntityCount.rejected, (state, action) => {
        // error
        state.kustoEntitiesCountStatus = CatalogEntityStatus.Error;
        notifier.error(
          'Error loading the number of Kusto catalog entities. Message: ' +
            action.error.message
        );
      })
      .addCase(loadMDSEntityCount.pending, (state, action) => {
        state.MDSEntitiesCountStatus = CatalogEntityStatus.Loading;
      })
      .addCase(loadMDSEntityCount.fulfilled, (state, action) => {
        if (action.payload) {
          const response = action.payload;

          state.MDSEntitiesCount = response.TotalCount;
          state.MDSEntitiesCountStatus = CatalogEntityStatus.Loaded;
        }
      })
      .addCase(loadMDSEntityCount.rejected, (state, action) => {
        // error
        state.MDSEntitiesCountStatus = CatalogEntityStatus.Error;
        notifier.error(
          'Error loading the total number of MDS catalog entities. Message: ' +
            action.error.message
        );
      })
      .addCase(loadCatalogEntityHistory.pending, (state, action) => {
        const manifestKey = action?.meta?.arg;
        if (manifestKey) {
          catalogEntitiesHistoryAdapter.upsertOne(
            state.catalogEntityHistoryList,
            {
              manifestKey: manifestKey,
              status: CatalogEntityStatus.Loading,
              historyEntries: undefined,
            }
          );
        }
      })
      .addCase(loadCatalogEntityHistory.fulfilled, (state, action) => {
        if (action.payload) {
          let newResponse: HistoryEntry[] = processHistoryRecords(
            action.payload.historyResponse
          );
          const manifestKey = action?.meta?.arg;

          catalogEntitiesHistoryAdapter.upsertOne(
            state.catalogEntityHistoryList,
            {
              manifestKey: manifestKey,
              status: CatalogEntityStatus.Loaded,
              historyEntries: newResponse,
            }
          );
        }
      })
      .addCase(loadCatalogEntityHistory.rejected, (state, action) => {
        // error
        notifier.error(
          'Error loading the history for catalog entity. Message: ' +
            action.error.message
        );
      });
  },
});

export const {
  selectAll: selectAllCatalogEntities,
  selectEntities: selectCatalogEntityEntities,
  selectById: selectCatalogEntityByManifestKey,
  selectIds: selectCatalogEntitiesManifestKeys,
} = catalogEntitiesAdapter.getSelectors(
  (state: RootState) => state.catalog.catalogEntityList
);
export const selectCatalogListStatus = (state: RootState) =>
  state.catalog.catalogEntityList.status;
export const selectCurrentCatalogEntitiesCount = (state: RootState) =>
  state.catalog.currentEntityListCount;

export const {
  selectAll: selectAllCatalogEntityHistories,
  selectEntities: selectCatalogEntityHistories,
  selectById: selectCatalogEntityHistoryByManifestKey,
  selectIds: selectCatalogEntityHistoriesManifestKeys,
} = catalogEntitiesHistoryAdapter.getSelectors(
  (state: RootState) => state.catalog.catalogEntityHistoryList
);

export const selectLoadCatalogEntityStatus = (state: RootState) =>
  state.catalog.loadCatalogEntityStatus;

export const selectTotalCatalogEntitiesCountStatus = (state: RootState) =>
  state.catalog.totalEntitiesCountStatus;
export const selectTotalCatalogEntitiesCount = (state: RootState) =>
  state.catalog.totalEntitiesCount;

export const selectKustoEntitiesCountStatus = (state: RootState) =>
  state.catalog.kustoEntitiesCountStatus;
export const selectKustoEntitiesCount = (state: RootState) =>
  state.catalog.kustoEntitiesCount;

export const selectMDSEntitiesCountStatus = (state: RootState) =>
  state.catalog.MDSEntitiesCountStatus;
export const selectMDSEntitiesCount = (state: RootState) =>
  state.catalog.MDSEntitiesCount;

export const catalogSliceActions = catalogSlice.actions;
export default catalogSlice.reducer;
