import { Dataset } from 'features/orchestrator/models/dataset';
import AddDataset from './addDataset';

export interface DatasetEditProps {
  dataset: Dataset | undefined; // TODO - Nambi: remove undefined
  onChange: (dataset: Dataset) => Promise<void>;
  onDismiss: () => void;
}

const EditDataset: React.FC<DatasetEditProps> = (props: DatasetEditProps) => {
  return <AddDataset isEdit {...props}></AddDataset>;
};

export default EditDataset;
