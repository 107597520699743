import { TelemetryClient } from 'applicationinsights';

let leApp: any; // AngularJS dependency - minimize use

export function inject(_leApp: any) {
  leApp = _leApp;
}

declare global {
  interface Window {
    BUILD_NUM: string;
    AAD_CLIENT_ID: string;
    appInsights: TelemetryClient;
    LENS_SESSION_ID: string;
    msalInstance: any;
    startUpConfig: {
      resourceIds: {
        [key: string]: string;
      };
      serviceEndpoints: {
        [key: string]: string;
      };
      showOCVFeedback: boolean;
      useReactUIByDefault?: boolean;
    };
    userInfo: {
      email: string;
      tenantId: string;
      objectId: string;
      type: string;
    };
    confirm: (message?: string | undefined) => boolean;
    lensStateParameterCache: any;
  }
}

// Export standard JS libraries - LoDash, ADAL...
// Avoid using AngularJS dependencies - $rootScope, laTelemetry, workspaceManager...
// AngularJS dependencies may be deprecated and replaced with React in the future.
export const _ = window._; // this is auto detected as type 'LoDashStatic'
export const AAD_CLIENT_ID = window.AAD_CLIENT_ID;
export const BUILD_NUM = window.BUILD_NUM;
export const appInsights = window.appInsights;
export const lensSessionId = window.LENS_SESSION_ID;
export const resourceIds = window.startUpConfig.resourceIds;
export const serviceEndpoints = window.startUpConfig.serviceEndpoints;
export const appInsightsConnectionString =
  serviceEndpoints.appInsightsConnectionString;
export const showOCVFeedback = window.startUpConfig.showOCVFeedback;
export const useReactUIByDefault = window.startUpConfig.useReactUIByDefault;
export const confirm = window.confirm;

// Use this method to aquire AAD tokens.
export const acquireToken = (resource: string): Promise<string> => {
  let scopes =
    leApp.angularServices.msalAuthenticationService.getScopeForResource(
      resource
    );
  return leApp.angularServices.msalAuthenticationService.acquireTokenSilent(
    scopes
  );
};

export const getUser = (): any => {
  return window.msalInstance.getActiveAccount();
};

export const getObjectId = (): any => {
  return window.userInfo.objectId;
};

export const getEmail = (): any => {
  return window.userInfo.email;
};

export const getActiveTabOnExplorePage = (): any => {
  return leApp.angularServices.$laTabsManager.activeTab;
};

export const getAngularJsStateParams = (stateName: string): any => {
  if (stateName === null || stateName === undefined || stateName.length === 0) {
    throw new Error('stateName cannot be empty.');
  }

  return window.lensStateParameterCache[stateName];
};
