import { DataSourceClient } from 'features/dataSources/client/dataSourceClient';
import constants, {
  $laConstants,
  orchestratorConstants,
} from 'utils/constants';

class AdfClient extends DataSourceClient {
  public readonly name = $laConstants.DataSourceTypes.AZUREDATAFACTORY;
  public readonly title = 'Azure Data Factory';
  public readonly icon = 'fa fa-cat'; // TODO - Nambi - see if we could use this as iconName.
  public readonly description = 'Azure Data Factory data source.'; // TODO - Nambi See if this one is needed.
  public readonly orchestration = {
    dataCommand: {
      endpointType: orchestratorConstants.EndpointTypes.AzureDataFactory,
    },
  };

  public get displayName() {
    return constants.DataSourceClientDisplayNames.AZUREDATAFACTORY;
  }

  public get dataSourceType() {
    return constants.DataSourceTypes.AZUREDATAFACTORY;
  }
}

export const adfClient = new AdfClient();
export default adfClient;
