import {
  Breadcrumb,
  DefaultButton,
  IBreadcrumbItem,
  Panel,
  PanelType,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Toggle,
} from '@fluentui/react';
import {
  PeoplePickerType,
  Workspace,
  WorkspaceBeforeFormat,
  WorkspaceShareType,
} from 'features/workspaces/models/workspace';
import {
  createWorkspace,
  enableOrchestration,
  loadProject,
  saveWorkspace,
  workspaceInit,
} from 'features/workspaces/workspaceSlice';
import React, { useEffect, useMemo, useState } from 'react';

import { Controller, useForm } from 'react-hook-form';
import {
  useWorkspaceStyles,
  themedSmallStackTokens,
  workspacePadding,
  panelStyles,
  stackItemFormStyles,
  breadCrumbStyles,
  descriptionTextStyle,
} from './workspaceStyles';
import {
  formatWorkspaceForUse,
  handleBeforeUnload,
} from 'features/workspaces/utils/workspaceUtils';
import { Stack } from '@fluentui/react';
import { FormError } from './workspaceHelperComponents';
import constants from 'utils/constants';
import { LensLabel } from 'utils/lensLabel';
import WorkspaceTagPicker from './general/tagPicker';
import PeoplePicker from './general/peoplePicker/peoplePicker';
import { useDispatch, useSelector } from 'react-redux';
import { ElxActionButton, ElxTextField } from '@elixir/components';
import _ from 'lodash';
import {
  loadWorkspaces,
  selectWorkspaceListEntities,
} from 'features/workspaces/workspaceListSlice';
import { loadUserprofile } from 'features/userprofile/userprofileSlice';
import { loadUserFavorites } from 'features/userInfo/userSavedObjectDataSlice';
import { AppDispatch } from 'app/lensShellUtility';
import { Tooltips } from './orchestrationSettings/workspaceSettingsUtil';
import { debounce } from 'utils/debounceUtils';
import serviceTreeApi from 'features/serviceTree/api/serviceTreeApi';
import { unwrapResult } from '@reduxjs/toolkit';

type FormValues = {
  name: string;
  description: string;
  tags: string[];
  readersAllUsers: boolean;
  administrators: string[];
  writers: string[];
  readers: string[];
  orchestrators: string[];
  customReadAccessMessage: string;
  customWriteAccessMessage: string;
  serviceGuid: string;
};

interface WorkspacePanelProps {
  workspace: Workspace;
  create: boolean;
  show: boolean;
  dismissPanel: () => void;
  openPanel: () => void;
  closeQuickSwitchPanel: () => void;
}

const buttonStyles = {
  root: { marginRight: 8 },
  content: { background: 'white', padding: '0px' },
  main: { background: 'white !important' },
};

const getDefaultValues = (workspace: Workspace): FormValues => {
  return {
    name: workspace.name || '',
    description: workspace.description || '',
    tags: workspace.tags || [],
    readersAllUsers: workspace.isReadOpen || false,
    administrators: workspace.administrators || [],
    writers: workspace.writers || [],
    readers: workspace.readers || [],
    orchestrators: workspace.orchestrators || [],
    customReadAccessMessage: workspace.customReadAccessMessage,
    customWriteAccessMessage: workspace.customWriteAccessMessage,
    serviceGuid: '',
  };
};
export const WorkspacePanel = (props: WorkspacePanelProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const styles = useWorkspaceStyles();
  const [workspace, setWorkspace] = React.useState<Workspace>(workspaceInit);
  const workspaceListEntities = useSelector(selectWorkspaceListEntities);

  const [enableOrch, setEnableOrch] = useState(false);
  const [serviceName, setServiceName] = useState('');
  const [serviceGuid, setServiceGuid] = useState('');
  const [isServiceValid, setIsServiceValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const getServiceById = (serviceGuid: string) => {
    return serviceTreeApi
      .getServiceById(serviceGuid)
      .then((services) => {
        clearErrors('serviceGuid');
        setIsServiceValid(true);
        setServiceName(services[0].Name);
      })
      .catch(() => {
        setError('serviceGuid', {
          type: 'invalidInput',
          message:
            'Need a valid Service Tree service ID to enable Workspace Orchestration',
        });
        setServiceName('Invalid Service Tree service');
        setIsServiceValid(false);
      });
  };

  const debouncedGetService = debounce(
    (serviceGuid: string) => getServiceById(serviceGuid),
    500
  );
  const cleanUpAndRefresh = () => {
    //cleaning up the state for service tree validation
    setIsSubmitting(false);
    setEnableOrch(false);
    setServiceName('');
    setServiceGuid('');
    setIsServiceValid(false);
    props.dismissPanel();
    dispatch(loadUserprofile());
    dispatch(loadWorkspaces(undefined));
    dispatch(loadUserFavorites({ workspaces: workspaceListEntities }));
  };

  const enableOrchAndCleanUp = (ws: WorkspaceBeforeFormat | Workspace) => {
    if (enableOrch) {
      dispatch(
        enableOrchestration({
          workspaceId: ws.id,
          serviceGuid: serviceGuid,
        })
      ).then(() => {
        cleanUpAndRefresh();
      });
    } else {
      cleanUpAndRefresh();
    }
  };

  const defaultValues = useMemo(() => {
    return getDefaultValues(props.workspace ? props.workspace : workspaceInit);
  }, [props.workspace]);

  const { control, formState, clearErrors, setError, handleSubmit } =
    useForm<FormValues>({
      defaultValues: defaultValues,
      mode: 'onChange', //causes rerender onChange, possible perf issue
      reValidateMode: 'onChange',
    });

  const { isDirty, isValid, errors } = formState;

  useEffect(() => {
    if (props.create) {
      setWorkspace(workspaceInit);
      setEnableOrch(false);
    }
    if (props.workspace.id && !props.create) {
      setWorkspace(props.workspace);
      setEnableOrch(
        props.workspace.dsoProject !== '' &&
          props.workspace.dsoProject !== undefined
      );
    }
  }, [props.create, props.workspace]);

  const updateParentState = (workspace: Workspace): void => {
    setWorkspace(workspace);
  };
  //prevent reload is there are any valid changes
  useEffect(() => {
    if (isDirty && isValid) {
      window.addEventListener('beforeunload', handleBeforeUnload);
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }
  }, [isDirty, isValid]);

  const onRenderFooterContent = () => (
    <Stack horizontal tokens={{ childrenGap: 5 }}>
      <PrimaryButton
        type="submit"
        onClick={handleSubmit(() => {
          setIsSubmitting(true);
          if (props.create) {
            dispatch(
              createWorkspace({
                ...workspace,
                shareType: WorkspaceShareType.Shared,
              })
            )
              .then(unwrapResult)
              .then((ws) => {
                enableOrchAndCleanUp(ws);
              });
          } else {
            if (enableOrch && !(workspace.dsoProject !== '' && !props.create)) {
              dispatch(
                enableOrchestration({
                  workspaceId: workspace.id,
                  serviceGuid: serviceGuid,
                })
              )
                .then(unwrapResult)
                .then(async (ws) => {
                  dispatch(saveWorkspace(await formatWorkspaceForUse(ws)));
                })
                .then(() => {
                  dispatch(
                    loadProject({
                      workspaceId: workspace.id,
                      isEditWorkspace: true,
                    })
                  );
                })
                .then(() => {
                  cleanUpAndRefresh();
                });
            } else {
              dispatch(saveWorkspace(workspace)).then(() =>
                cleanUpAndRefresh()
              );
            }
          }
        })}
        disabled={!_.isEmpty(errors) || !isDirty || isSubmitting}
        styles={buttonStyles}
        onRenderChildren={() => {
          return <>{isSubmitting && <Spinner size={SpinnerSize.small} />}</>;
        }}
      >
        {props.create
          ? isSubmitting
            ? 'Creating...'
            : 'Create'
          : isSubmitting
          ? 'Saving...'
          : 'Save'}
      </PrimaryButton>
      <DefaultButton
        onClick={() => {
          clearErrors();
          props.dismissPanel();
        }}
      >
        Cancel
      </DefaultButton>
    </Stack>
  );

  const items: IBreadcrumbItem[] = [
    {
      text: 'Workspace management',
      key: 'workspaceManagement',
      onClick: () => {
        clearErrors();
        props.dismissPanel();
      },
    },
    {
      text: workspace.name
        ? `Edit workspace - ${workspace.name}`
        : 'Create workspace',
      key: 'workspaceHistory',
      onClick: () => {},
    },
  ];

  return (
    <Panel
      type={PanelType.large}
      isOpen={props.show}
      onDismiss={props.closeQuickSwitchPanel}
      onRenderFooterContent={onRenderFooterContent}
      styles={panelStyles}
      onRenderHeader={() => {
        return (
          <Breadcrumb
            items={items}
            maxDisplayedItems={2}
            ariaLabel="Workspace data connection breadcrumb with items rendered as buttons"
            overflowAriaLabel="More links"
            styles={breadCrumbStyles}
          />
        );
      }}
      isFooterAtBottom={true}
    >
      <Stack>
        <form>
          <Stack styles={workspacePadding}>
            <Stack tokens={themedSmallStackTokens}>
              <Stack.Item className={styles.createEditContainerHeaderStyle}>
                About the Workspace
              </Stack.Item>
              <Stack.Item {...stackItemFormStyles}>
                <Controller
                  control={control}
                  name="name"
                  rules={{
                    minLength: {
                      value: 3,
                      message: 'This is required with minimum length of 3.',
                    },
                  }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <ElxTextField
                      onRenderLabel={() => (
                        <LensLabel
                          labelText="Name"
                          hintText={'The workspace name.'}
                          required={true}
                        ></LensLabel>
                      )}
                      placeholder="workspace name"
                      defaultValue={workspace.name}
                      onChange={(e, value) => {
                        setWorkspace({ ...workspace, name: value || '' });
                        onChange(e);
                      }}
                    />
                  )}
                />

                <FormError
                  showError={errors.name}
                  errorMsg="This is required with minimum length of 3 and no special characters."
                />
              </Stack.Item>
              <Stack.Item {...stackItemFormStyles}>
                <Controller
                  control={control}
                  name="description"
                  defaultValue={workspace.description}
                  rules={{
                    pattern: constants.NoSpecialCharactersRegex,
                  }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <ElxTextField
                      onRenderLabel={() => (
                        <LensLabel
                          labelText="Description"
                          hintText={
                            'The workspace description. This field is metadata explaining the purpose of this workspace.'
                          }
                          required={false}
                        ></LensLabel>
                      )}
                      placeholder="workspace description"
                      multiline={true}
                      defaultValue={workspace.description}
                      required={false}
                      inputActions={[]}
                      onChange={(e, value) => {
                        setWorkspace({
                          ...workspace,
                          description: value || '',
                        });
                        onChange(e);
                      }}
                    />
                  )}
                />
                <FormError
                  showError={errors.description}
                  errorMsg="No special characters are allowed."
                />
              </Stack.Item>
              <Stack.Item {...stackItemFormStyles}>
                <span>
                  <LensLabel
                    labelText="Tags"
                    hintText={'The tags for the workspace.'}
                    required={false}
                  ></LensLabel>
                </span>
                <Controller
                  control={control}
                  name="tags"
                  defaultValue={workspace.tags}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <WorkspaceTagPicker
                      workspace={workspace}
                      updateParentState={updateParentState}
                      onChange={onChange}
                    />
                  )}
                />
              </Stack.Item>
            </Stack>

            <Stack tokens={themedSmallStackTokens}>
              <Stack.Item className={styles.createEditContainerHeaderStyle}>
                Permissions
              </Stack.Item>
              <Stack.Item {...stackItemFormStyles}>
                <LensLabel
                  labelText="Administrators"
                  hintText={
                    'Administrators. The applications, security groups, and users with admin access. Max 20. Administrators have read/write access to queries, visualizations, dashboards, and jobs. Administrators can also edit the workspace.'
                  }
                  required={true}
                ></LensLabel>

                <Controller
                  control={control}
                  name="administrators"
                  defaultValue={workspace.administrators}
                  rules={{
                    required: {
                      value: workspace.administrators.length < 1,
                      message:
                        'Min 1 security group or applications or user with admin access',
                    },
                    validate: {
                      hasMaxLength: (v) =>
                        workspace.administrators.length < 20 ||
                        v.length < 20 ||
                        'Max 20 security group or applications or user with admin access',
                      hasMinLength: (v) =>
                        workspace.administrators.length > 0 ||
                        v.length > 0 ||
                        'Min 1 security group or applications or user with admin access',
                    },
                  }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <PeoplePicker
                      people={workspace.administrators}
                      workspace={workspace}
                      updateParentState={updateParentState}
                      onChange={onChange}
                      name={PeoplePickerType.Administrators}
                    />
                  )}
                />
                <FormError
                  showError={errors.administrators}
                  errorMsg={
                    'There must be at least one security group or user with admin access'
                  }
                />
              </Stack.Item>
              <Stack.Item {...stackItemFormStyles}>
                <LensLabel
                  labelText="Writers"
                  hintText={
                    'Writers. The applications, security groups, and users with read-write access. Max 20. Writers have read/write access to queries, visualizations, and dashboards.'
                  }
                  required={true}
                ></LensLabel>
                <Controller
                  control={control}
                  name="writers"
                  defaultValue={workspace.writers}
                  rules={{
                    required: {
                      value: workspace.writers.length < 1,
                      message:
                        'Min 1 security group or applications or user with write access',
                    },
                    validate: {
                      hasMaxLength: (v) =>
                        workspace.writers.length < 20 ||
                        v.length < 20 ||
                        'Max 20 security group or applications or user with write access',
                      hasMinLength: (v) =>
                        workspace.writers.length > 0 ||
                        v.length > 0 ||
                        'Min 1 security group or applications or user with write access',
                    },
                  }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <PeoplePicker
                      people={workspace.writers}
                      workspace={workspace}
                      updateParentState={updateParentState}
                      onChange={onChange}
                      name={PeoplePickerType.Writers}
                    />
                  )}
                />
                <FormError
                  showError={errors.writers}
                  errorMsg="There must be at least one security group or user with write access"
                />
              </Stack.Item>
              <Stack.Item {...stackItemFormStyles}>
                <LensLabel
                  labelText="Readers"
                  hintText={
                    'Readers. The applications, security groups, and users with read-only access. Max 20. Readers have read only access to queries, visualizations, dashboards, and jobs.'
                  }
                  required={true}
                ></LensLabel>
                <Controller
                  control={control}
                  name="readersAllUsers"
                  defaultValue={workspace.isReadOpen}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Toggle
                      onText="Grant read only access to all users. NOTE: This option does not work for job access. You need to explicitly specify read access for read only access to jobs."
                      offText="Grant read only access to specific users"
                      defaultChecked={workspace.isReadOpen}
                      onChange={(e, value) => {
                        setWorkspace({ ...workspace, isReadOpen: !!value });
                        if (workspace.isReadOpen) {
                          clearErrors('readers');
                          if (workspace.readers.length === 0) {
                            setError('readers', {
                              type: 'required',
                              message:
                                'There must be at least one security group or user with read access, if read access is not open to all.',
                            });
                          }
                        }

                        if (
                          !workspace.isReadOpen &&
                          workspace.readers.length === 0
                        ) {
                          clearErrors('readers');
                        }
                        onChange({
                          target: {
                            name: 'toggleReadersOption',
                            value: !!value,
                          },
                        });
                      }}
                    />
                  )}
                />
                {!workspace.isReadOpen && (
                  <Controller
                    control={control}
                    name="readers"
                    defaultValue={workspace.readers}
                    rules={{
                      required: {
                        value:
                          !workspace.isReadOpen && workspace.readers.length < 1,
                        message:
                          'Min 1 security group or applications or user with read access',
                      },
                      validate: {
                        hasMaxLength: (v) =>
                          workspace.readers.length < 20 ||
                          v.length < 20 ||
                          'Max 20 security group or applications or user with read access',
                      },
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <PeoplePicker
                        people={workspace.readers}
                        workspace={workspace}
                        updateParentState={updateParentState}
                        onChange={onChange}
                        name={PeoplePickerType.Readers}
                      />
                    )}
                  />
                )}
                <FormError
                  showError={errors.readers}
                  errorMsg="There must be at least one security group or user with
                      read access, if read access is not open to all."
                />
              </Stack.Item>
              <Stack.Item {...stackItemFormStyles}>
                <LensLabel
                  labelText="Orchestrators"
                  hintText={
                    'Orchestrators. The applications, security groups, and users with orchestration access. Orchestrators can manage and author jobs using workspace data connections. Max 20.'
                  }
                  required={false}
                ></LensLabel>
                <Controller
                  control={control}
                  name="orchestrators"
                  defaultValue={workspace.orchestrators}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <PeoplePicker
                      people={workspace.orchestrators}
                      workspace={workspace}
                      updateParentState={updateParentState}
                      onChange={onChange}
                      name={PeoplePickerType.Orchestrators}
                    />
                  )}
                />
              </Stack.Item>
            </Stack>

            <Stack tokens={themedSmallStackTokens}>
              <Stack.Item className={styles.createEditContainerHeaderStyle}>
                Orchestration
              </Stack.Item>
              <Stack.Item {...stackItemFormStyles}>
                <Toggle
                  label={
                    <LensLabel
                      labelText="Enable orchestration"
                      hintText={Tooltips.enableOrchestration}
                      required={false}
                    ></LensLabel>
                  }
                  disabled={workspace.dsoProject !== '' && !props.create}
                  defaultChecked={enableOrch}
                  onText={
                    workspace.dsoProject === '' || props.create
                      ? 'Orchestration is enabled'
                      : 'Orchestration is already enabled and cannot be disabled once enabled.'
                  }
                  offText="Orchestration is disabled"
                  inlineLabel
                  onChange={() => {
                    // set the validity so that if it is not enabled, we set to true
                    setIsServiceValid(!!enableOrch);
                    //if orch is currently enabled, which means after the onChange function
                    //is complete it will ne disabled, we can clear the error
                    if (enableOrch) {
                      setServiceName('');
                      clearErrors('serviceGuid');
                    }
                    setEnableOrch(!enableOrch);
                  }}
                />
                {(workspace.dsoProject === '' || props.create) && enableOrch && (
                  <Controller
                    control={control}
                    name="serviceGuid"
                    defaultValue={serviceGuid}
                    rules={{
                      required: {
                        value: enableOrch,
                        message:
                          'Need a valid Service Tree service ID to enable Workspace Orchestration',
                      },
                      validate: {
                        isValid: (v) =>
                          isServiceValid ||
                          'Need a valid Service Tree service ID to enable Workspace Orchestration',
                      },
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <>
                        <ElxTextField
                          onRenderLabel={() => (
                            <LensLabel
                              labelText="Service Tree Service"
                              hintText={Tooltips.serviceTreeService}
                              required={true}
                            ></LensLabel>
                          )}
                          labelActions={[
                            <ElxActionButton
                              iconProps={{ iconName: 'Link12' }}
                              text="Find Service Tree Service ID."
                              onClick={() => {
                                window.open(
                                  'https://aka.ms/servicetree',
                                  '_blank'
                                );
                              }}
                            />,
                          ]}
                          placeholder={Tooltips.serviceTreeService}
                          onChange={(ev, newValue) => {
                            clearErrors('serviceGuid');
                            setServiceGuid(newValue + '');
                            onChange({
                              target: {
                                name: 'updateServiceGuid',
                                value: newValue + '',
                              },
                            });
                            if (newValue + '' !== '') {
                              setServiceName('Retrieving service name...');
                            } else {
                              setIsServiceValid(false);
                              setServiceName('');
                            }
                            debouncedGetService(newValue + '');
                            if (isServiceValid) {
                              clearErrors('serviceGuid');
                            }
                          }}
                        />
                        <p style={descriptionTextStyle}>{serviceName}</p>
                      </>
                    )}
                  />
                )}

                <FormError
                  showError={errors.serviceGuid}
                  errorMsg="There must be at one valid Service Tree Service ID"
                />
              </Stack.Item>
            </Stack>

            <Stack tokens={themedSmallStackTokens}>
              <Stack.Item className={styles.createEditContainerHeaderStyle}>
                Custom Messages
              </Stack.Item>
              <Stack.Item {...stackItemFormStyles}>
                <Controller
                  control={control}
                  name="customReadAccessMessage"
                  defaultValue={workspace.customReadAccessMessage}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <ElxTextField
                      onRenderLabel={() => (
                        <LensLabel
                          labelText="Custom read access message"
                          hintText={
                            'Provide a custom message for requesting read access to the workspace.'
                          }
                          required={false}
                        ></LensLabel>
                      )}
                      placeholder="a custom message for requesting read access to the workspace"
                      defaultValue={workspace.customReadAccessMessage}
                      inputActions={[]}
                      onChange={(e, value) => {
                        setWorkspace({
                          ...workspace,
                          customReadAccessMessage: value || '',
                        });
                        onChange(e);
                      }}
                    />
                  )}
                />
              </Stack.Item>
              <Stack.Item {...stackItemFormStyles}>
                <Controller
                  control={control}
                  name="customWriteAccessMessage"
                  defaultValue={workspace.customWriteAccessMessage}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <ElxTextField
                      onRenderLabel={() => (
                        <LensLabel
                          labelText="Custom write access message"
                          hintText={
                            'Provide a custom message for requesting write access to the workspace.'
                          }
                          required={false}
                        ></LensLabel>
                      )}
                      placeholder="a custom message for requesting write access to the workspace"
                      defaultValue={workspace.customWriteAccessMessage}
                      inputActions={[]}
                      onChange={(e, value) => {
                        setWorkspace({
                          ...workspace,
                          customWriteAccessMessage: value || '',
                        });
                        onChange(e);
                      }}
                    />
                  )}
                />
              </Stack.Item>
            </Stack>
          </Stack>
        </form>
      </Stack>
    </Panel>
  );
};
