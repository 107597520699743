import { ElxDropdown, InputMessageTypes } from '@elixir/components';
import { useSelector } from 'react-redux';
import { ProjectDataConnection } from 'features/workspaces/models/project';
import { selectWorkspace } from 'features/workspaces/workspaceSlice';
import { LensLabel } from 'utils/lensLabel';
import {
  Workspace,
  WorkspaceBeforeFormat,
} from 'features/workspaces/models/workspace';
import { orchestratorConstants } from 'utils/constants';

export interface SelectDataConnectionProps {
  onSelect: (dc: ProjectDataConnection) => void;
  filter: string[];
  selectedKey: string | null;
  required?: boolean;
  disabled?: boolean;
  dcWorkspace?: Workspace;
  label?: string;
  workspaceTarget?: WorkspaceBeforeFormat;
}

export const SelectDataConnection = (
  props: SelectDataConnectionProps
): JSX.Element => {
  const {
    onSelect,
    filter,
    selectedKey,
    required,
    disabled,
    label,
    workspaceTarget,
  } = props;

  const workspaceDefault = useSelector(selectWorkspace); // get current workspace.
  const workspace = workspaceTarget || workspaceDefault;

  const sharedConnection = workspace.sourceDataConnections || [];
  let dcList = filter.length
    ? (workspace?.dataConnections || []).filter((d) => {
        if (
          d.type === orchestratorConstants.DataSourceTypes.SHARED &&
          sharedConnection.length > 0
        ) {
          const sharedConn = sharedConnection.find(
            (s) => s.targetConnectionName === d.connectionName
          );
          if (sharedConn) {
            return filter.includes(sharedConn.sourceConnection.type);
          }
        }
        return filter.includes(d.type);
      })
    : workspace?.dataConnections || [];
  dcList = disabled
    ? dcList.filter((d) => d.connectionName === selectedKey)
    : dcList;

  return (
    <ElxDropdown
      onRenderLabel={() => (
        <>
          <LensLabel
            labelText={label || 'Data connection'}
            hintText={'Select data connection'}
            required={required}
          ></LensLabel>
        </>
      )}
      options={
        dcList?.map((d) => {
          if (d.type === orchestratorConstants.DataSourceTypes.SHARED) {
            const sharedConn = sharedConnection.find(
              (s) => s.targetConnectionName === d.connectionName
            );
            if (sharedConn) {
              return {
                key: d.connectionName,
                text: `${sharedConn.sourceConnection.connectionDisplayName} | ${d.type} (${sharedConn.sourceConnection.type})`,
              };
            }
          }
          return {
            key: d.connectionName,
            text: `${d.connectionDisplayName} | ${d.type}`,
          };
        }) || [{ key: '', text: '' }]
      }
      onChange={(_, option) => {
        let dc = dcList.find((d) => {
          return d.connectionName === option!.key;
        });
        dc && onSelect(dc);
      }}
      // selectedKey={selectedKey}
      defaultSelectedKey={selectedKey}
      message={
        dcList.length === 0
          ? {
              content:
                'No suitable data connection is available in the workspace, please create a suitable data connection',
              type: InputMessageTypes.Error,
            }
          : undefined
      }
    ></ElxDropdown>
  );
};
