export interface OrchestrationSettings {
  serviceTreeService: string;
  icMTeamPublicId: string;
  autoMitigation: string;
  jobExpiration: { days: number; hours: number; minutes: number };
  maxJobInstances: number;
  maxQueuedJobInstances: number;
  jobPriority: number;
  allocationType: string;
  tokensPerJob: number;
  percentAllocation: number;
}

export enum ParameterType {
  AadAccessToken = 'AadAccessToken',
  Boolean = 'Boolean',
  Integer = 'Integer',
  KeyVaultSecret = 'KeyVaultSecret',
  Query = 'Query',
  SangamMacro = 'SangamMacro',
  String = 'String',
  StringNoQuote = 'StringNoQuote',
  None = '',
}

export interface WorkspaceParameter {
  key?: number;
  name: string;
  value: string;
  type: ParameterType;
}

export interface OrchestrationWorkspaceSettingsObj {
  projectName: string;
  projectDisplayName: string;
  serviceGuid: string;
  icMTeamPublicId: string;
}

export interface JobSettingsObj {
  maxJobInstances?: number;
  maxQueuedJobInstances?: number;
  autoMitigation?: string;
  jobPriority?: number;
  jobExpiration?: string;
}

export interface ScmWorkspaceSettingsObj {
  secureChangeManagement: string;
}

export interface AllocationSettingsObj {
  allocationType?: string;
  tokensPerJob?: number;
  percentAllocation?: number;
}

export enum ComponentType {
  ElxTextField = 'ElxTextField',
  ElxDropdown = 'ElxDropdown',
}

export const Tooltips = {
  enableOrchestration:
    'This enables the Orchestration in this workspace, meaning the Geneva Orchestrator project will be automatically created.',
  orchestratorProjectName: 'The Geneva Orchestrator project name.',
  orchestratorProjectDisplayName:
    'The Geneva Orchestrator project display name.',
  serviceTreeService:
    'The Service ID in Service Tree. It is now required to enable Orchestration.',
  icMTeamPublicId:
    'The ICM team public id. Can be overridden at the job level.',
  autoMitigation: 'Auto Mitigation property for MDM monitors.',
  maxJobInstances:
    'The maximum number of job instances per job that can run in parallel. This will be used for non stateful jobs unless overridden by job level settings.',
  maxQueuedJobInstances:
    'The maximum number of job instances that can be queued for a specific job. This will be used for non stateful jobs unless overridden by job level settings.',
  jobPriority:
    'Project level job priority setting. The priority of this job set to this, unless overridden by other settings, like job level/activity level settings.',
  allocationType: 'Select which of allocation to use. For Cosmos/Adla jobs.',
  tokensPerJob:
    'Project level token/AU allocation setting. This will be be used for activities (Cosmos job), unless overridden by other settings, like job level/activity level settings.',
  percentAllocation:
    'Project level VC compute percentage setting. This will be be used for activities (Cosmos job), unless overridden by other settings, like job level/activity level settings..',
  secureChangeManagement: 'Enable workspace for secure change management',
  JobExpiration:
    'The time after which jobs should automatically be disabled. The time is measured from the last job modification.',
};
