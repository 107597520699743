import { SelectorProps } from 'features/workspaces/components/editDataConnection/editDataConnectionHelper';
import {
  // AadConnectionAuthType,
  ConnectionDataSourceType,
  DataSource,
  // DataConnection,
  // DataConnectionRequest,
  // ProjectDataConnection,
} from 'features/workspaces/models/project';
import { DataSourceClient } from '../client/dataSourceClient';
// import DummyClient from '../dummyClient';

/**
 * @name DataSourcePlugin
 * @description The abstract class used for data source plugin object inheritance.
 * The class will hold the data source's client, form input element, and type.
 */
export abstract class DataSourcePlugin {
  private type: ConnectionDataSourceType;
  private client: DataSourceClient;

  constructor(type: ConnectionDataSourceType, client: DataSourceClient) {
    this.type = type;
    this.client = client;
  }
  public get connectionType() {
    // return ConnectionDataSourceType.None;
    return this.type;
  }

  public get dataSourceClient(): DataSourceClient {
    // return new DummyClient();
    return this.client;
  }
  public dataSourceFormComponent(selectorProps: SelectorProps) {
    return <></>;
  }

  public generatedName(state: DataSource) {
    return '';
  }

  // public convertToProjectDataConnection(
  //   connection: DataConnectionRequest
  // ): ProjectDataConnection {
  //   return {
  //     authenticationMode: AadConnectionAuthType.None,
  //     tenant: 'tenant',
  //     applicationId: 'applicationId',
  //     userId: 'userId',
  //     id: 'id',
  //     name: 'name',
  //     type: ConnectionDataSourceType.None,
  //   };
  // }

  // public convertToDataConnectionRequest(
  //   connection: DataConnection
  // ): DataConnectionRequest {
  //   return {
  //     AuthenticationMode: AadConnectionAuthType.None,
  //     Tenant: 'tenant',
  //     ApplicationId: 'applicationId',
  //     UserId: 'userId',
  //     Id: 'id',
  //     Name: 'name',
  //     Type: ConnectionDataSourceType.None,
  //   };
  // }
}
