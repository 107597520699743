import { ElxDropdown, ElxTextField } from '@elixir/components';
import { Stack, Toggle } from '@fluentui/react';
import { Activity } from 'features/orchestrator/models/job';
import { JobAccordian } from 'features/orchestrator/utils/jobAccordian';
import _ from 'lodash';
import { orchestratorConstants } from 'utils/constants';
import { LensLabel } from 'utils/lensLabel';
import { JobActivityValidationActionsSelector } from './JobActivityValidationActionsSelector';
import { groupBorder } from './JobAuthorStyles';

const aggregationOptionsAll = Object.values(
  orchestratorConstants.ValidationAggregations
).map((val) => ({ key: val, text: val }));
const operatorOptions = Object.values(
  orchestratorConstants.ValidationOperatorOptions
).map((obj) => ({ key: obj.type, text: obj.title }));

export interface JobActivityValidationValidationTriggerProps {
  trigger: any;
  validation: any;
  activity: Activity;
  onChange: (activity: Activity) => void;
  onRemove: () => void;
}

export const JobActivityValidationValidationTrigger = (
  props: JobActivityValidationValidationTriggerProps
) => {
  const { trigger, validation, activity, onChange, onRemove } = props;
  const aggregationOptions = _(aggregationOptionsAll)
    .filter((o) =>
      _.includes(
        _.get(orchestratorConstants.ValidationTypeOptions, validation.type)
          .aggregations,
        o.key
      )
    )
    .value();
  trigger.condition = trigger.condition || {};

  const isConditionEnabled = !_.isEmpty(aggregationOptions);

  console.log(
    `${isConditionEnabled} - JobActivityValidationValidationTrigger `
  );
  return (
    <>
      <Stack.Item className={groupBorder}>
        {' '}
        <JobAccordian
          isOpen={false}
          text={'Trigger'}
          hint={'Trigger'}
          onRemove={() => {
            onRemove();
          }}
        >
          <Stack tokens={{ childrenGap: 8, padding: '0px 16px' }}>
            {isConditionEnabled && (
              <Stack.Item>
                <LensLabel
                  labelText={'Condition'}
                  hintText={
                    'The condition that defines the threshold for the trigger. If the validation scenario meets the condition, the trigger is activated, and its actions are performed.'
                  }
                  required={true}
                ></LensLabel>
                <Stack horizontal grow>
                  <Stack.Item className="ms-lg5" styles={{root:{padding:'0px 8px'}}}>
                    <ElxDropdown
                      selectedKey={
                        trigger.condition?.aggregation ||
                        aggregationOptions[0].key
                      }
                      onChange={(e, val) => {
                        trigger.condition.aggregation =
                          val?.key || aggregationOptions[0].key;
                        onChange(activity);
                      }}
                      options={aggregationOptions}
                    />
                  </Stack.Item>
                  <Stack.Item className="ms-lg2" styles={{root:{padding:'0px 8px'}}}>
                    <ElxDropdown
                      selectedKey={
                        trigger.condition.operator || operatorOptions[0].key
                      }
                      onChange={(e, val) => {
                        trigger.condition.operator =
                          val?.key || operatorOptions[0].key;
                        onChange(activity);
                      }}
                      options={operatorOptions}
                    />
                  </Stack.Item>
                  <Stack.Item className="ms-lg5" styles={{root:{padding:'0px 8px'}}}>
                    <ElxTextField
                      type="number"
                      value={trigger.condition?.value || 0}
                      onChange={(e, val) => {
                        trigger.condition.value = val;
                        onChange(activity);
                      }}
                    ></ElxTextField>
                  </Stack.Item>
                </Stack>
              </Stack.Item>
            )}

            <Stack.Item>
              <LensLabel
                labelText={'Actions'}
                hintText={
                  'The actions that are performed if the trigger is activated. Validation actions do not stop job execution. After the actions are performed, the job instance continues to execute successfully. Actions are shared within the data validation activity and available to all of its triggers. If an action is changed, all triggers that use the action are affected.'
                }
                required={true}
              ></LensLabel>

              <JobActivityValidationActionsSelector
                trigger={trigger}
                activity={activity}
                onChange={(activity: Activity): void => {
                  onChange(activity);
                }}
              ></JobActivityValidationActionsSelector>
            </Stack.Item>

            <Stack.Item className={groupBorder}>
              <JobAccordian
                isOpen={false}
                text={'Advanced Trigger Options'}
                hint={''}
              >
                <Stack tokens={{ childrenGap: 16, padding: 16 }}>
                  <Stack.Item>
                    <LensLabel
                      labelText={'Always Trigger'}
                      hintText={
                        'If selected, evaluates the condition, produces the action data, and performs the actions regardless of the result of the condition.'
                      }
                    ></LensLabel>
                    <Toggle
                      label=""
                      checked={trigger.alwaysTrigger}
                      onChange={(
                        e: React.MouseEvent<HTMLElement>,
                        checked?: boolean
                      ) => {
                        trigger.alwaysTrigger = checked;
                        onChange(activity);
                      }}
                    />
                  </Stack.Item>
                </Stack>
              </JobAccordian>
            </Stack.Item>
          </Stack>
        </JobAccordian>
      </Stack.Item>
    </>
  );
};
