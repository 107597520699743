import {
  Breadcrumb,
  DefaultButton,
  IBreadcrumbItem,
  Panel,
  PanelType,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Stack,
  Toggle,
} from '@fluentui/react';
import {
  panelStyles,
  breadCrumbStyles,
  panelContentStackStyle,
  stackItemFormStyles,
  descriptionTextBoldStyle,
} from '../workspaceStyles';
import { useSelector } from 'react-redux';
import { editWorkspace } from 'features/workspaces/workspaceSlice';
import {
  getSavedDashboard,
  getSavedVisualization,
  isWriteable,
} from 'features/workspaces/utils/workspaceUtils';
import { useState } from 'react';
import { WorkspacePicker } from '../../workspacePicker';
import { WorkspaceItem } from 'features/workspaces/utils/workspaceQuickSwitchUtils';
import savedObjectsApi from 'features/workspaces/api/savedObjectsApi';
import {
  SavedObjectType,
  WorkspaceObject,
  WorkspaceObjectBase,
} from 'features/workspaces/models/workspace';
import notifier from 'utils/notifier';
import { selectWorkspaceById } from 'features/workspaces/workspaceListSlice';
import { RootState } from 'app/lensShellUtility';
import utils from 'utils/utils';
import _ from 'lodash';
import { LensTelemetryConstants } from 'features/appInsights/appInsightsLibs';
import logger from 'features/appInsights/lensLogger';

const buttonStyles = {
  root: { marginRight: 8 },
  content: { background: 'white', padding: '0px' },
  main: { background: 'white !important' },
};
interface CopyToWsPanelProps {
  workspaceId: string;
  show: boolean;
  wsObjects: WorkspaceObject[];
  dismissPanel: () => void;
  openPanel: () => void;
  openParentPanel: () => void;
  closeParentPanel: () => void;
  closeQuickSwitchPanel: () => void;
}
const CopyToWsPanel = (props: CopyToWsPanelProps) => {
  let workspace = useSelector(editWorkspace);
  const [destWorkspaceId, setDestWorkspaceId] = useState<string>('');
  const [overrideAll, setOverrideAll] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const destWorkspace = useSelector((state: RootState) =>
    selectWorkspaceById(state, destWorkspaceId)
  );

  const isSourcePrivate = workspace.shareType === 'private';
  const isDestPrivate = destWorkspace?.shareType === 'private';

  const items: IBreadcrumbItem[] = [
    {
      text: 'Workspace management',
      key: 'workspaceManagement',
      onClick: () => {
        props.dismissPanel();
        props.closeParentPanel();
      },
    },
    {
      text: workspace?.name + ' - Saved Objects',
      key: 'workspaceSavedObjects',
      onClick: () => {
        props.openParentPanel();
        props.dismissPanel();
      },
    },
    {
      text: 'Copy Object to Workspace',
      key: 'copyWorkspaceSavedObjects',
    },
  ];

  const bulkCopy = async () => {
    let objectList: WorkspaceObjectBase[] = props.wsObjects.map((a) => ({
      type: a.type,
      title: a.title,
      id: a.id,
      body: a.body,
      workspace: props.workspaceId,
    }));
    let p: Promise<any>[] = [];

    props.wsObjects.forEach(async (obj) => {
      // Dont need a care for the query objects because there is nothing to check.
      if (
        obj.type === SavedObjectType.Visualization ||
        obj.type === SavedObjectType.Visualizations
      ) {
        // Check if any visualization has a linked search, need to copy the linked search as well
        p.push(
          getSavedVisualization(
            obj.id,
            false,
            utils.pascalCase(workspace)
          ).then((visData) => {
            console.log('visData', visData);
            if (
              visData.savedSearchId !== undefined ||
              visData.savedSearchId !== null
            ) {
              const savedSearch = {
                type: SavedObjectType.Query,
                title: visData.savedSearchId,
                id: visData.savedSearchId,
                body: '',
                workspace: props.workspaceId,
              };

              objectList.push(savedSearch);
            }
          })
        );
      } else if (obj.type === SavedObjectType.Dashboard) {
        // Check visualizations in the dashboard, then searches in those visualizations
        p.push(
          getSavedDashboard(obj.id, false, utils.pascalCase(workspace))
            .then((dashboardData) => {
              console.log(dashboardData);
              const inputPanels: any[] = JSON.parse(
                dashboardData.inputPanelsJSON
              );
              let panels: any[] = JSON.parse(dashboardData.panelsJSON).concat(
                inputPanels
              );
              if (_.isEmpty(panels)) {
                const sections = JSON.parse(dashboardData.tabsJSON);
                panels = sections
                  .map((section: { panels: any }) => section.panels)
                  .flat();
              }
              let savedVisList: any[] = [];

              panels.forEach((panel: any) => {
                var objToSave = {
                  type: panel.type,
                  title: panel.id,
                  id: panel.id,
                  body: '',
                };

                if (panel.type === 'visualization') {
                  savedVisList.push(objToSave);
                }

                objectList.push(objToSave);
              });

              return savedVisList;
            })
            .then(async (savedVisList: any[]) => {
              let p2: Promise<any>[] = [];
              if (savedVisList.length > 0) {
                savedVisList.forEach((savedVis) => {
                  p2.push(
                    getSavedVisualization(
                      savedVis.title,
                      false,
                      utils.pascalCase(workspace)
                    ).then((savedObj) => {
                      if (
                        savedObj.savedSearchId !== undefined &&
                        savedObj.savedSearchId !== null
                      ) {
                        let savedSearch = {
                          type: SavedObjectType.Query,
                          title: savedObj.savedSearchId,
                          id: savedObj.savedSearchId,
                          body: '',
                          workspace: props.workspaceId,
                        };

                        objectList.push(savedSearch);
                      }
                    })
                  );
                });
              }
              await Promise.all(p2);
            })
        );
      } else {
        notifier.error('Unexpected saved object type "' + obj.type + '"');
      }
    });

    await Promise.all(p).then(() => {
      savedObjectsApi
        .copyToWorkspace(
          props.workspaceId,
          destWorkspaceId,
          objectList,
          isSourcePrivate,
          isDestPrivate,
          overrideAll
        )
        .then(() => {
          logger.event(
            LensTelemetryConstants.EventNames.SavedObjectActions
              .CopyToWorkspace,
            {
              workspaceId: workspace.id,
            }
          );
          setIsSubmitting(false);
        });
    });
  };

  const onRenderFooterContent = () => (
    <Stack horizontal tokens={{ childrenGap: 5 }}>
      <PrimaryButton
        type="submit"
        onClick={() => {
          setIsSubmitting(true);
          bulkCopy();
        }}
        disabled={destWorkspaceId === ''}
        styles={buttonStyles}
        onRenderChildren={() => {
          return <>{isSubmitting && <Spinner size={SpinnerSize.small} />}</>;
        }}
      >
        {isSubmitting ? 'Copying to Workspace...' : 'Copy to Workspace'}
      </PrimaryButton>
      <DefaultButton
        onClick={() => {
          props.dismissPanel();
        }}
      >
        Cancel
      </DefaultButton>
    </Stack>
  );

  return (
    <Panel
      type={PanelType.large}
      isOpen={props.show}
      onDismiss={props.closeQuickSwitchPanel}
      styles={panelStyles}
      isFooterAtBottom={true}
      onRenderHeader={() => {
        return (
          <Breadcrumb
            items={items}
            maxDisplayedItems={3}
            ariaLabel="Workspace copy  saved objects to another workspace breadcrumb with items rendered as buttons"
            overflowAriaLabel="More links"
            styles={breadCrumbStyles}
          />
        );
      }}
      onRenderFooterContent={onRenderFooterContent}
    >
      <Stack {...panelContentStackStyle}>
        <Stack.Item {...stackItemFormStyles}>
          <div style={descriptionTextBoldStyle}>
            Select destination workspace (R/W):
          </div>
          <p>Only workspaces you have write access will be available.</p>
          <p>
            {destWorkspaceId === ''
              ? `No workspace selected`
              : `Selected Workspace is : ${destWorkspaceId}`}
          </p>{' '}
          <Toggle
            label={'Overwrite all existing assets'}
            inlineLabel
            onChange={() => {
              setOverrideAll(!overrideAll);
            }}
          />{' '}
          <WorkspacePicker
            onWorkspaceSelect={(wsItem: WorkspaceItem) => {
              setDestWorkspaceId(wsItem.workspaceId);
            }}
            filterFunction={isWriteable}
            height={500}
          />
        </Stack.Item>
      </Stack>
    </Panel>
  );
};

export default CopyToWsPanel;
