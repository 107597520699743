import KustoDatabaseSelector from 'features/workspaces/components/editDataConnection/dataSourceComponents/kustoDatabaseSelector';
import { SelectorProps } from 'features/workspaces/components/editDataConnection/editDataConnectionHelper';
import {
  ConnectionDataSourceType,
  KustoDataSource,
} from 'features/workspaces/models/project';
import kustoClient from '../kusto/client/kustoClient';
import { registerDataSourcePlugin } from '../registry';
import { DataSourcePlugin } from './dataSourcePlugin';

class KustoPlugin extends DataSourcePlugin {
  public dataSourceFormComponent(selectorProps: SelectorProps) {
    return (
      <KustoDatabaseSelector
        workspace={selectorProps.workspace}
        state={selectorProps.state}
        setState={selectorProps.setState}
        customNameFilled={selectorProps.customNameFilled}
      />
    );
  }

  public generatedName(state: KustoDataSource) {
    if (state && state.cluster && state.database) {
      const clusterHostname = state.cluster.replace(/(^\w+:|^)\/\//, '');
      return clusterHostname
        ? (clusterHostname.split('.')[0] + '.' + state.database)
            .split(' ')
            .join('')
        : '';
    }
    return '';
  }
}

registerDataSourcePlugin(
  ConnectionDataSourceType.Kusto,
  new KustoPlugin(ConnectionDataSourceType.Kusto, kustoClient)
);
