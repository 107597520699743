import { SynapsePipelineSelector } from 'features/workspaces/components/editDataConnection/dataSourceComponents/synapsePipelineSelector';
import { SelectorProps } from 'features/workspaces/components/editDataConnection/editDataConnectionHelper';
import {
  ConnectionDataSourceType,
  SynapsePipelineDataSource,
} from 'features/workspaces/models/project';
import { registerDataSourcePlugin } from '../registry';
import synapsePipelineClient from '../synapsePipelineClient';
import { DataSourcePlugin } from './dataSourcePlugin';

class SynapsePipelinePlugin extends DataSourcePlugin {
  public dataSourceFormComponent(selectorProps: SelectorProps): JSX.Element {
    return (
      <SynapsePipelineSelector
        workspace={selectorProps.workspace}
        state={selectorProps.state}
        setState={selectorProps.setState}
        customNameFilled={selectorProps.customNameFilled}
      />
    );
  }

  public generatedName(state: SynapsePipelineDataSource) {
    return state && state.workspace ? 'SynapsePipeline.' + state.workspace : '';
  }
}

registerDataSourcePlugin(
  ConnectionDataSourceType.SynapsePipeline,
  new SynapsePipelinePlugin(
    ConnectionDataSourceType.SynapsePipeline,
    synapsePipelineClient
  )
);
