import { Stack } from '@fluentui/react';
import {
  ElxActionButton,
  ElxIconButton,
  ElxTextField,
} from '@elixir/components';
import React from 'react';
import { paramHeader } from '../utils/jobCommon';
import { Parameters } from '../models/job';

interface JobParameterItemsProps {
  parameters: Parameters[];
  onNameChange: (name: string, idx: number) => void;
  onValueChange: (value: string, idx: number) => void;
  onRemove: (idx: number) => void;
  onAdd: () => void;
}

export function JobParameterItems(props: JobParameterItemsProps) {
  return (
    <Stack
      style={{
        overflow: 'auto',
        width: '600px',
      }}
      tokens={{
        childrenGap: 4,
        padding: 16,
        maxHeight: 800,
      }}
    >
      <>
        {paramHeader}
        {props.parameters.map((o, idx) => (
          <>
            <Stack horizontal>
              <Stack.Item className="ms-lg6">
                <ElxTextField
                  value={o.name}
                  onChange={(_, name) => props.onNameChange(name || '', idx)}
                  styles={{
                    root: {
                      marginRight: '10px',
                    },
                  }}
                ></ElxTextField>
              </Stack.Item>
              <Stack.Item className="ms-lg6">
                <Stack horizontal>
                  {' '}
                  <ElxTextField
                    value={o.value}
                    onChange={(_, value) =>
                      props.onValueChange(value || '', idx)
                    }
                    styles={{
                      root: {
                        marginRight: '10px',
                      },
                    }}
                  ></ElxTextField>
                  <ElxIconButton
                    iconProps={{
                      iconName: 'cancel',
                    }}
                    text={''}
                    onClick={() => props.onRemove(idx)}
                  ></ElxIconButton>
                </Stack>
              </Stack.Item>
            </Stack>
          </>
        ))}
      </>

      {/* <Stack.Item>
           <Stack horizontal> */}
      <Stack.Item
        className="ms-lg12"
        align="end"
        style={{
          paddingRight: '40px',
        }}
      >
        <ElxActionButton
          iconProps={{
            iconName: 'Add',
          }}
          text="Add"
          onClick={() => {
            props.onAdd();
          }}
          style={{
            float: 'right',
          }}
        />
        <ElxActionButton />
      </Stack.Item>
      {/* </Stack>
          </Stack.Item> */}
    </Stack>
  );
}
