let leApp: any; // AngularJS dependency - minimize use

/**
 * Handles Notifications events.
 * @param handler - The event handler.
 * @returns The deregistration function for the handler.
 */
export const onNewNotification = (
  handler: (event: any, workspace: any) => void
): (() => void) | undefined => {
  return leApp?.onNewNotification(handler);
};

export function inject(_leApp: any) {
  leApp = _leApp;
}
