import { IDetailsListStyles, mergeStyleSets } from '@fluentui/react';
import { getTheme, ITheme } from '@fluentui/react';

// Note: This won't work with dynamic themes
const theme: ITheme = getTheme();
const { semanticColors } = theme;

export const panelStyles = {
  overlay: {
    backgroundColor: 'rgb(0 0 0 / 40%)',
  },
};

export const tableStyles: IDetailsListStyles = {
  root: {
    '.ms-DetailsHeader-cellName': {
      fontWeight: '500 !important',
      fontSize: '12px !important',
      userSelect: ' !important',
    },
    '.ms-DetailsRow-cell': {
      userSelect: 'text !important',
    },
    overflow: 'auto',
  },
  focusZone: {},
  headerWrapper: {},
  contentWrapper: {},
};

export const classNames = mergeStyleSets({
  tableStyles: {
    borderBottom: `1px solid ${semanticColors.bodyDivider}`,
  },
});

export const colSmall = {
  minWidth: 50,
  isResizable: true,
  isMultiline: true,
  maxWidth: 80,
  className: classNames.tableStyles,
};
export const colMedium = {
  minWidth: 100,
  isResizable: true,
  isMultiline: true,
  maxWidth: 130,
  className: classNames.tableStyles,
};
export const colLarge = {
  minWidth: 130,
  isResizable: true,
  isMultiline: true,
  maxWidth: 200,
  className: classNames.tableStyles,
};
export const colExtraLarge = {
  minWidth: 175,
  isResizable: true,
  isMultiline: true,
  maxWidth: 250,
  className: classNames.tableStyles,
};
export const colXXLarge = {
  minWidth: 175,
  isResizable: true,
  isMultiline: true,
  maxWidth: 300,
  className: classNames.tableStyles,
};
export const colUnlimitedLarge = {
  minWidth: 175,
  isResizable: true,
  isMultiline: true,
  className: classNames.tableStyles,
};
