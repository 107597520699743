import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  Dictionary,
} from '@reduxjs/toolkit';
import { AppDispatch, RootState } from 'app/lensShellUtility';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import notifier from 'utils/notifier';
import jobApi from '../orchestrator/api/jobApi';
import { WorkspaceBeforeFormat } from '../workspaces/models/workspace';
import {
  selectWorkspaceListEntities,
  selectWorkspaceListStatus,
  useWorkspaceList,
} from '../workspaces/workspaceListSlice';
import userSavedObjectDataApi from './api/userSavedObjectDataApi';
import UserSavedObjectData, {
  UserSavedObjectDataStatus,
} from './models/userSavedObjectData';

/**
 * Custom hook to use the current user favorites.
 */

export const useUserFavorites = () => {
  const dispatch = useDispatch<AppDispatch>();
  useWorkspaceList();
  const userFavoritesStatus = useSelector(selectUserFavoritesStatus);
  const workspaces = useSelector(selectWorkspaceListEntities);
  const workspacesStatus = useSelector(selectWorkspaceListStatus);
  useEffect(() => {
    if (
      workspacesStatus === 'Loaded' &&
      userFavoritesStatus === UserSavedObjectDataStatus.None
    ) {
      dispatch(loadUserFavorites({ workspaces: workspaces }));
    }
  }, [dispatch, userFavoritesStatus, workspaces, workspacesStatus]);
};

/**
 * Custom hook to use the current user recents.
 */
export const useUserRecents = () => {
  const dispatch = useDispatch<AppDispatch>();
  useWorkspaceList();
  const userRecentsStatus = useSelector(selectUserRecentsStatus);
  const workspaces = useSelector(selectWorkspaceListEntities);
  const workspacesStatus = useSelector(selectWorkspaceListStatus);
  useEffect(() => {
    if (
      workspacesStatus === 'Loaded' &&
      userRecentsStatus === UserSavedObjectDataStatus.None
    ) {
      dispatch(loadUserRecents({ workspaces: workspaces }));
    }
  }, [dispatch, userRecentsStatus, workspaces, workspacesStatus]);
};

export const loadUserFavorites = createAsyncThunk(
  'userinfo/loadUserFavoritesData',
  async ({ workspaces }: { workspaces: Dictionary<WorkspaceBeforeFormat> }) => {
    let userFavoritesList = await userSavedObjectDataApi.getUserFavorites();

    const getJobById = async (index: number) => {
      const result = await jobApi
        .getJob(
          userFavoritesList[index].workspaceId || '',
          userFavoritesList[index].lensObjectId
        )
        .catch((error) => {
          notifier.error(
            `Error: getJob for workspace:${userFavoritesList[index].workspaceId}, job${userFavoritesList[index].lensObjectId} failed. Message: ${error.message}. ${error.response.data.error.message}`
          );
        });

      userFavoritesList[index].name = result?.name;
      userFavoritesList[index].description = result?.description;
      userFavoritesList[index].status =
        'Recently updated by ' + result?.modifiedBy;
      userFavoritesList[index].date = new Date(
        result?.modifiedDate ? result.modifiedDate.slice(0, 10) : ''
      );
    };
    let p: Promise<any>[] = [];
    if (userFavoritesList.length > 0) {
      userFavoritesList.forEach((key, index) => {
        if (key.workspaceId) {
          const workspace = workspaces[key.workspaceId];
          if (key.lensObjectType === 'workspace') {
            userFavoritesList[index].description = workspace?.description;
            userFavoritesList[index].status =
              'Recently updated by ' + workspace?.lastUpdatedBy;
            userFavoritesList[index].date = workspace?.lastUpdated
              ? new Date(workspace.lastUpdated.slice(0, 10))
              : undefined;
            userFavoritesList[index].name = workspace?.name;
          } else if (key.lensObjectType === 'job') {
            p.push(getJobById(index));
          }
        }
      });
    }

    await Promise.all(p);
    return userFavoritesList.filter(
      (item) =>
        !(item.lensObjectType !== 'workspace' && item.lensObjectType !== 'job')
    );
  }
);

export const saveUserFavorites = createAsyncThunk(
  'userinfo/saveUserFavorites',
  async ({
    userSavedObjectDataList,
  }: {
    userSavedObjectDataList: UserSavedObjectData[];
  }) => {
    return await userSavedObjectDataApi.saveUserFavorites(
      userSavedObjectDataList
    );
  }
);

export const deleteUserFavorites = createAsyncThunk(
  'userinfo/saveUserFavorites',
  async ({
    userSavedObjectDataList,
  }: {
    userSavedObjectDataList: UserSavedObjectData[];
  }) => {
    return await userSavedObjectDataApi.deleteUserFavorites(
      userSavedObjectDataList
    );
  }
);

export const loadUserRecents = createAsyncThunk(
  'userinfo/loadUserSavedObjectData',
  async ({ workspaces }: { workspaces: Dictionary<WorkspaceBeforeFormat> }) => {
    let userRecentsList = await userSavedObjectDataApi.getUserRecents();

    const getJobById = async (index: number) => {
      const result = await jobApi
        .getJob(
          userRecentsList[index].workspaceId || '',
          userRecentsList[index].lensObjectId
        )
        .catch((error) => {
          notifier.error(
            `Error: getJob for workspace:${userRecentsList[index].workspaceId}, job${userRecentsList[index].lensObjectId} failed. Message: ${error.message}. ${error.response.data.error.message}`
          );
        });

      userRecentsList[index].name = result?.name;
      userRecentsList[index].description = result?.description;
      userRecentsList[index].status =
        'Recently updated by ' + result?.modifiedBy;
      userRecentsList[index].date = new Date(
        result?.modifiedDate ? result.modifiedDate.slice(0, 10) : ''
      );
    };
    let p: Promise<any>[] = [];
    if (userRecentsList.length > 0) {
      userRecentsList.forEach((key, index) => {
        if (key.workspaceId) {
          const workspace = workspaces[key.workspaceId];
          if (key.lensObjectType === 'workspace') {
            userRecentsList[index].description = workspace?.description;
            userRecentsList[index].status =
              'Recently updated by ' + workspace?.lastUpdatedBy;
            userRecentsList[index].date = workspace?.lastUpdated
              ? new Date(workspace.lastUpdated.slice(0, 10))
              : undefined;
            userRecentsList[index].name = workspace?.name;
          } else if (key.lensObjectType === 'job') {
            p.push(getJobById(index));
          }
        }
      });
    }

    await Promise.all(p);
    return userRecentsList.filter(
      (item) =>
        !(item.lensObjectType !== 'workspace' && item.lensObjectType !== 'job')
    );
    //return userRecentsList;
  }
);

export const saveUserRecents = createAsyncThunk(
  'userinfo/saveUserRecents',
  async ({
    userSavedObjectDataList,
  }: {
    userSavedObjectDataList: UserSavedObjectData[];
  }) => {
    return await userSavedObjectDataApi.saveUserRecents(
      userSavedObjectDataList
    );
  }
);

// TODO: might be able to delete this no use case
export const deleteUserRecents = createAsyncThunk(
  'userinfo/saveUserRecents',
  async ({
    userSavedObjectDataList,
  }: {
    userSavedObjectDataList: UserSavedObjectData[];
  }) => {
    return await userSavedObjectDataApi.deleteUserRecents(
      userSavedObjectDataList
    );
  }
);

const FavoritesListAdapter = createEntityAdapter<UserSavedObjectData>({
  selectId: (UserSavedObjectData) =>
    UserSavedObjectData.workspaceId + '_' + UserSavedObjectData.lensObjectId,
});
const RecentsListAdapter = createEntityAdapter<UserSavedObjectData>({
  selectId: (UserSavedObjectData) =>
    UserSavedObjectData.workspaceId + '_' + UserSavedObjectData.lensObjectId,
});
let initialUserSavedObjectData = {
  userFavoritesList: FavoritesListAdapter.getInitialState({
    userFavoritesStatus: UserSavedObjectDataStatus.None,
  }),
  userRecentsList: RecentsListAdapter.getInitialState({
    userRecentsStatus: UserSavedObjectDataStatus.None,
  }),
};
/**
 * Redux slice representing the current userSavedObjectData.
 */
const userSavedObjectDataSlice = createSlice({
  name: 'userSavedObjectData',
  initialState: initialUserSavedObjectData,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadUserFavorites.pending, (state, action) => {
        state.userFavoritesList.userFavoritesStatus =
          UserSavedObjectDataStatus.Loading;
      })
      .addCase(loadUserFavorites.fulfilled, (state, action) => {
        state.userFavoritesList.userFavoritesStatus =
          UserSavedObjectDataStatus.Loaded;
        FavoritesListAdapter.setAll(state.userFavoritesList, action.payload);
      })
      .addCase(loadUserFavorites.rejected, (state, action) => {
        state.userFavoritesList.userFavoritesStatus =
          UserSavedObjectDataStatus.Error;
        notifier.error(action.error);
      })
      .addCase(saveUserFavorites.pending, (state, action) => {
        state.userFavoritesList.userFavoritesStatus =
          UserSavedObjectDataStatus.Loading;
      })
      .addCase(saveUserFavorites.fulfilled, (state, action) => {
        state.userFavoritesList.userFavoritesStatus =
          UserSavedObjectDataStatus.Loaded;
        FavoritesListAdapter.setAll(state.userFavoritesList, action.payload);
      })
      .addCase(saveUserFavorites.rejected, (state, action) => {
        notifier.error(action.error);
      })
      .addCase(loadUserRecents.pending, (state, action) => {
        state.userRecentsList.userRecentsStatus =
          UserSavedObjectDataStatus.Loading;
      })
      .addCase(loadUserRecents.fulfilled, (state, action) => {
        state.userRecentsList.userRecentsStatus =
          UserSavedObjectDataStatus.Loaded;
        RecentsListAdapter.setAll(state.userRecentsList, action.payload);
      })
      .addCase(loadUserRecents.rejected, (state, action) => {
        state.userRecentsList.userRecentsStatus =
          UserSavedObjectDataStatus.Error;
        notifier.error(action.error);
      })
      .addCase(saveUserRecents.pending, (state, action) => {
        state.userRecentsList.userRecentsStatus =
          UserSavedObjectDataStatus.Loading;
      })
      .addCase(saveUserRecents.fulfilled, (state, action) => {
        state.userRecentsList.userRecentsStatus =
          UserSavedObjectDataStatus.Loaded;
        RecentsListAdapter.setAll(state.userRecentsList, action.payload);
      })
      .addCase(saveUserRecents.rejected, (state, action) => {
        notifier.error(action.error);
      });
  },
});

export const {
  selectAll: selectUserFavorites,
  selectEntities: selectUserFavoritesListEntities,
  selectById: selectUserFavoritesById,
  selectIds: selectUserFavoritesIds,
} = FavoritesListAdapter.getSelectors(
  (state: RootState) => state.userSavedObjectData.userFavoritesList
);
export const selectUserFavoritesStatus = (state: RootState) =>
  state.userSavedObjectData.userFavoritesList.userFavoritesStatus;
export const {
  selectAll: selectUserRecents,
  selectEntities: selectUserrecentsListEntities,
  selectById: selectUserRecentsById,
  selectIds: selectUserRecentsIds,
} = RecentsListAdapter.getSelectors(
  (state: RootState) => state.userSavedObjectData.userRecentsList
);
export const selectUserRecentsStatus = (state: RootState) =>
  state.userSavedObjectData.userRecentsList.userRecentsStatus;

export default userSavedObjectDataSlice.reducer;
