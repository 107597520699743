import { DataSourceClient } from 'features/dataSources/client/dataSourceClient';
import { getDataSourceClient } from 'features/dataSources/registry';
import { Activity } from 'features/orchestrator/models/job';
import { ProjectDataConnection } from 'features/workspaces/models/project';
import { selectWorkspace } from 'features/workspaces/workspaceSlice';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { orchestratorConstants } from 'utils/constants';
import { SelectDataConnection } from '../jobSchedule/selectDataConnection';
import { JobEndpointAdlsGen2Stream } from './JobEndpointAdlsGen2Stream';
import { JobEndpointAzureTable } from './JobEndpointAzureTable';
import { JobEndpointCosmosStream } from './JobEndpointCosmosStream';
import { JobEndpointKustoTable } from './JobEndpointKustoTable';
import { JobEndpointMdm } from './JobEndpointMdm';
import { JobEndpointTableType } from './JobEndPointTableType';
import notifier from 'utils/notifier';

export interface JobEndpointProps {
  isSource: boolean;
  endpoint: any;
  activity: Activity;
  onChange: (
    activity: Activity,
    dc?: ProjectDataConnection,
    endpoint?: any
  ) => void;
  showTableType?: boolean;
  hideTableType?: boolean;
  endpointTypes: string[];
}

export const JobEndpoint = (props: JobEndpointProps) => {
  const {
    isSource,
    activity,
    onChange,
    endpointTypes,
    showTableType,
    hideTableType,
    endpoint,
  } = props;
  const workspace = useSelector(selectWorkspace); // get current workspace.

  const endpnt = endpoint || (isSource ? activity.input! : activity.output!);
  const dc = workspace?.dataConnections?.find((d) => {
    return d.connectionName === endpnt.connectionName;
  });
  let dsc: DataSourceClient | null;
  if (dc?.type) {
    if (dc.type === orchestratorConstants.DataSourceTypes.SHARED) {
      const sharedConn = workspace.sourceDataConnections?.find((s) => {
        return s.targetConnectionName === dc.connectionName;
      });
      if (sharedConn) {
        dsc = getDataSourceClient(sharedConn.sourceConnection.type);
      } else {
        notifier.error('Shared connection not found.');
        dsc = null;
      }
    } else {
      dsc = getDataSourceClient(dc!.type);
    }
  } else {
    dsc = null;
  }

  const dataSourceTypes = _(endpointTypes)
    .map((endpointType) => {
      return _.get(orchestratorConstants.EndpointTypeOptions, endpointType)
        ?.dataSourceType;
    })
    .compact()
    .uniq()
    .sortBy(
      (dataSourceType) =>
        getDataSourceClient(dataSourceType).orchestration.endpoint.directive ||
        dataSourceType
    )
    .value();

  const connectionEndpointTypes = _(endpointTypes)
    .filter((endpointType) => {
      return (
        _.get(orchestratorConstants.EndpointTypeOptions, endpointType)
          ?.dataSourceType === dsc?.dataSourceType
      );
    })
    .value();

  return (
    <>
      <SelectDataConnection
        onSelect={(dc: ProjectDataConnection): void => {
          endpnt.connectionName = dc.connectionName;
          onChange(activity, dc, endpnt);
        }}
        filter={dataSourceTypes}
        selectedKey={endpnt.connectionName}
      ></SelectDataConnection>
      {dsc?.orchestration?.endpoint?.directive ===
        'kusto-table-job-endpoint' && (
        <JobEndpointKustoTable
          isSource={isSource}
          activity={activity}
          endpoint={endpnt}
          onChange={(activity: Activity, endpoint: any): void => {
            onChange(activity, undefined, endpoint);
          }}
          endpointTypes={connectionEndpointTypes}
        ></JobEndpointKustoTable>
      )}
      {dsc?.orchestration?.endpoint?.directive ===
        'cosmos-stream-job-endpoint' && (
        <JobEndpointCosmosStream
          isSource={isSource}
          activity={activity}
          endpoint={endpnt}
          onChange={(activity: Activity, endpoint: any): void => {
            onChange(activity, undefined, endpoint);
          }}
        ></JobEndpointCosmosStream>
      )}
      {dsc?.orchestration?.endpoint?.directive ===
        'azure-table-job-endpoint' && (
        <JobEndpointAzureTable
          isSource={isSource}
          activity={activity}
          endpoint={endpnt}
          onChange={(activity: Activity, endpoint: any): void => {
            onChange(activity, undefined, endpoint);
          }}
          endpointTypes={connectionEndpointTypes}
        ></JobEndpointAzureTable>
      )}
      {dsc?.orchestration?.endpoint?.directive ===
        'adls-gen2-stream-job-endpoint' && (
        <JobEndpointAdlsGen2Stream
          isSource={isSource}
          activity={activity}
          endpoint={endpnt}
          onChange={(activity: Activity, endpoint: any): void => {
            onChange(activity, undefined, endpoint);
          }}
        ></JobEndpointAdlsGen2Stream>
      )}
      {dsc?.orchestration?.endpoint?.directive === 'mdm-job-endpoint' && (
        <JobEndpointMdm
          isSource={isSource}
          activity={activity}
          endpoint={endpnt}
          onChange={(activity: Activity, endpoint: any): void => {
            onChange(activity, undefined, endpoint);
          }}
        ></JobEndpointMdm>
      )}
      {(showTableType || (!isSource && !hideTableType)) &&
        dsc?.orchestration?.endpoint?.directive !== 'mdm-job-endpoint' && (
          <JobEndpointTableType
            endpoint={endpoint}
            activity={activity}
            onChange={(activity: Activity, endpoint: any): void => {
              onChange(activity, undefined, endpoint);
            }}
          />
        )}
    </>
  );
};
