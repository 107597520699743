import { MessageBarType, Spinner, SpinnerSize } from '@fluentui/react';
import { ElxSecondaryButton } from '@elixir/components';
import workspacesApi from 'features/workspaces/api/workspacesApi';
import {
  connectionTypesWithValidation,
  DataSource,
  ResultDetail,
  ValidateResult,
} from 'features/workspaces/models/project';
import React, { useState } from 'react';
import {
  MessageAndType,
  ValidateButtonStatus,
} from './editDataConnectionPanel';
import { invalidDataConn, validDataConn } from './editDataConnectionStyles';
import { Workspace } from 'features/workspaces/models/workspace';

interface ValidateButtonProps {
  dataConnectionStatus: ValidateButtonStatus;
  setDataConnectionStatus: (status: ValidateButtonStatus) => void;
  setErrorMessage: (status: MessageAndType) => void;
  checkConnection: boolean;
  workspace: Workspace;
  defaultMessage: MessageAndType;
  dc: DataSource;
}
export const ValidateButton = (props: ValidateButtonProps) => {
  const [validateButtonStatus, setValidateButtonStatus] = useState(true);

  const dataConnectionStatus = props.dataConnectionStatus;
  const setDataConnectionStatus = props.setDataConnectionStatus;
  const setErrorMessage = props.setErrorMessage;
  const checkConnection = props.checkConnection;
  const workspace = props.workspace;
  const defaultMessage = props.defaultMessage;
  const dc = props.dc;

  const dcStyle =
    dataConnectionStatus !== ValidateButtonStatus.NONE
      ? dataConnectionStatus === ValidateButtonStatus.INVALID
        ? invalidDataConn
        : validDataConn
      : {};

  const dcText = validateButtonStatus
    ? dataConnectionStatus !== ValidateButtonStatus.NONE
      ? dataConnectionStatus === ValidateButtonStatus.INVALID
        ? 'Connection Invalid'
        : 'Connection Valid'
      : 'Test Connection'
    : 'Testing Connection...';

  const dcIcon = {
    iconName:
      dataConnectionStatus !== ValidateButtonStatus.NONE
        ? dataConnectionStatus === ValidateButtonStatus.INVALID
          ? 'Cancel'
          : 'CheckMark'
        : '',
  };

  const getResultMsg = (results: ResultDetail[]): null | MessageAndType => {
    let res = { write: false, read: false, access: false };
    results.forEach((result) => {
      if (result.permission === 'Write') {
        res.write = result.isAuthorized;
      }
      if (result.permission === 'Read') {
        res.read = result.isAuthorized;
      }
      if (result.permission === 'Access') {
        res.access = result.isAuthorized;
      }
    });

    if (res.write) {
      setDataConnectionStatus(ValidateButtonStatus.VALID);
      return {
        resultMsg: 'Your Application has permissions to Read and Write.',
        type: MessageBarType.success,
      };
    } else if (res.access) {
      setDataConnectionStatus(ValidateButtonStatus.VALID);
      return {
        resultMsg: 'Your Application has permissions to Access.',
        type: MessageBarType.success,
      };
    } else if (res.read) {
      setDataConnectionStatus(ValidateButtonStatus.VALID);
      return {
        resultMsg: 'Your Application has permissions to Read but not to Write.',
        type: MessageBarType.warning,
      };
    } else {
      setDataConnectionStatus(ValidateButtonStatus.INVALID);
      return {
        resultMsg:
          'Your Application does not have permissions to Read or Write or Access.',
        type: MessageBarType.error,
      };
    }
  };

  const validateDataConnectionHandler = async () => {
    if (!workspace) {
      return;
    }
    // Check if already validating
    if (validateButtonStatus === false) {
      return;
    }
    setDataConnectionStatus(ValidateButtonStatus.NONE);
    setErrorMessage(defaultMessage);
    setValidateButtonStatus(false);
    await workspacesApi
      .postDataConnectionValidate(workspace.id, {
        ...dc,
        ...{ cluster: dc.cluster?.trim()?.replace(/^https:\/\//i, '') },
      })
      .then((result: ValidateResult) => {
        setErrorMessage(getResultMsg(result.results) ?? defaultMessage);
        setValidateButtonStatus(true);
      })
      .catch((err) => {
        setErrorMessage({
          resultMsg: err.response.data.error.message,
          type: MessageBarType.error,
        });
        setValidateButtonStatus(true);
      });
  };
  return (
    <ElxSecondaryButton
      key="Test connection"
      text={dcText}
      iconProps={dcIcon}
      disabled={
        !checkConnection ||
        !validateButtonStatus ||
        !connectionTypesWithValidation.includes(dc.type)
      }
      onRenderChildren={() => {
        return (
          <>{!validateButtonStatus && <Spinner size={SpinnerSize.small} />}</>
        );
      }}
      onClick={validateDataConnectionHandler}
      style={dcStyle}
    />
  );
};
