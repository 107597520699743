import { ElxCard } from '@elixir/components';
import { Stack } from '@fluentui/react';

export interface summaryCard {
  autoCardWidth?: boolean | undefined;
  id: string;
  cardTitle: string;
  body: JSX.Element;
}

export const summaryCards = (summaryCards: summaryCard[]): JSX.Element => {
  return (
    <Stack horizontal tokens={{ childrenGap: 16 }}>
      {summaryCards.map((o) => (
        <Stack.Item id={o.id} key={o.id}>
          <ElxCard
            id={o.id}
            key={o.id}
            cardTitle={o.cardTitle}
            body={o.body}
            styles={{
              root: {
                marginRight: 16,
                borderColor: 'lightblue',
                cursor: 'pointer',
                width: o.autoCardWidth ? undefined : 100,
                pointerEvents: 'none',
              },
              titleContainer: { alignContent: 'center' },
            }}
          />
        </Stack.Item>
      ))}
    </Stack>
  );
};
