import { ElxTextField } from '@elixir/components';
import { Stack } from '@fluentui/react';
import { LensLabel } from 'utils/lensLabel';
import { SelectorProps } from '../editDataConnectionHelper';

export const MdmSelector = (props: SelectorProps): JSX.Element => {
  const { state, setState } = props;

  return (
    <Stack tokens={{ childrenGap: 16 }}>
      <ElxTextField
        onRenderLabel={() => (
          <LensLabel
            labelText="Geneva Metrics (MDM) Account"
            hintText={'The Geneva Metrics (MDM) account (optional).'}
            required={false}
          ></LensLabel>
        )}
        placeholder="MyMdmAccount"
        value={state.account || ''}
        onChange={(e, newValue) => {
          let stateData = {
            ...state,
            ...(state.namespace && { namespace: state.namespace }),
          };

          if (newValue === '') {
            delete stateData.account;
            setState(stateData);
          } else {
            setState({ ...stateData, account: newValue });
          }
        }}
      />
      <ElxTextField
        onRenderLabel={() => (
          <LensLabel
            labelText="Geneva Metrics (MDM) Namespace"
            hintText={'The Geneva Metrics (MDM) namespace (optional).'}
            required={false}
          ></LensLabel>
        )}
        placeholder="MyMdmNamespace"
        value={state.namespace || ''}
        onChange={(e, newValue) => {
          let stateData = {
            ...state,
            ...(state.account && { account: state.account }),
          };

          if (newValue === '') {
            delete stateData.namespace;
            setState(stateData);
          } else {
            setState({ ...stateData, namespace: newValue });
          }
        }}
      />
    </Stack>
  );
};
