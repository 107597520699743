import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  Userprofile,
  UserprofileStatus,
} from 'features/userprofile/models/userprofile';
import { notifier } from 'utils/notifier';
import { AppDispatch, RootState } from 'app/lensShellUtility';
import { OrchestratorApi } from 'api/orchestratorApi';
import { runAsAdmin } from 'utils/authUtils';
import logger from 'features/appInsights/lensLogger';
import { LensTelemetryConstants } from 'features/appInsights/appInsightsLibs';
import userprofileApi from './api/userprofileApi';
import {
  getCurrentWorkspaceId,
  getPreloadedData,
  onWorkspaceSave,
} from 'features/workspaces/utils/workspaceUtils';
import {
  getSharedConnections,
  loadEditWorkspace,
  loadProject,
  loadWorkspace,
  selectWorkspaceStatus,
  setCurrentWorkspace,
} from 'features/workspaces/workspaceSlice';
import { useReactUIByDefault } from 'utils/sharedLibs';
import {
  selectUserRecents,
  selectUserRecentsStatus,
  useUserRecents,
} from '../userInfo/userSavedObjectDataSlice';
import UserSavedObjectData, {
  LensObjectType,
  UserSavedObjectDataStatus,
} from '../userInfo/models/userSavedObjectData';

const orchestratorApi = new OrchestratorApi();
const putUserprofile = userprofileApi.saveUserprofile;
const putUserSelections = userprofileApi.saveUserSelections;
const putSupportNotificationStatus =
  userprofileApi.saveSupportNotificationStatus;

/**
 * Custom hook to load the current workspace. Uses preloaded data from the Angular side
 * and if there is no preloaded data, load the userProfile
 * Should only be called once in the application
 */
export const useUserProfileAndWorkspaceInit = () => {
  const dispatch = useDispatch<AppDispatch>();
  useUserRecents();
  const userRecentsStatus = useSelector(selectUserRecentsStatus);
  const userRecents = useSelector(selectUserRecents);
  const userprofileStatus = useSelector(selectUserprofileStatus);
  const userProfile = useSelector(selectUserprofile);

  const workspaceStatus = useSelector(selectWorkspaceStatus);

  const [loadedUserProfile, setLoadedUserProfile] = useState(false);

  useEffect(() => {
    function getUserprofile() {
      dispatch(loadUserprofile());
      dispatch(loadCanAdmin());
    }

    if (userprofileStatus === UserprofileStatus.None) {
      getUserprofile();
    }

    if (userprofileStatus === UserprofileStatus.Loaded && !loadedUserProfile) {
      getPreloadedData()
        .then((preloadedData) => {
          if (preloadedData?.workspace?.promise) {
            preloadedData.workspace.promise.then((workspace) => {
              if (workspace) {
                //check if there is workpsace in preloaded data and use that
                dispatch(setCurrentWorkspace(workspace));
                dispatch(
                  loadProject({
                    workspaceId: workspace.id,
                    isEditWorkspace: false,
                  })
                ).then(() => {
                  dispatch(getSharedConnections(workspace.id));
                });
              } else if (localStorage.workspace) {
                //check if there is workpsace in localStorage and use that
                dispatch(setCurrentWorkspace(localStorage.workspace));
                dispatch(
                  loadProject({
                    workspaceId: localStorage.workspace.id,
                    isEditWorkspace: false,
                  })
                ).then(() => {
                  dispatch(getSharedConnections(localStorage.workspace.id));
                });
              } else {
                // if there is no useful information in preloaded data, and local stoarage, then use information in the user recents
                let recentWorkspaces: UserSavedObjectData[] = [];
                if (userRecentsStatus === UserSavedObjectDataStatus.Loaded) {
                  recentWorkspaces = userRecents.filter(
                    (rec) => rec.lensObjectType === LensObjectType.Workspace
                  );
                }

                if (recentWorkspaces.length > 0) {
                  //get the most recent workspace on Lens load
                  dispatch(loadWorkspace(recentWorkspaces[0].workspaceId)).then(
                    () => {
                      dispatch(
                        loadProject({
                          workspaceId: recentWorkspaces[0].workspaceId,
                          isEditWorkspace: false,
                        })
                      ).then(() => {
                        if (recentWorkspaces[0].workspaceId) {
                          dispatch(
                            getSharedConnections(
                              recentWorkspaces[0].workspaceId
                            )
                          );
                        }
                      });
                    }
                  );
                } else if (
                  // if there are no recent workspaces in the user profile
                  preloadedData?.workspace?.alias &&
                  preloadedData?.workspace?.type
                ) {
                  if (preloadedData?.workspace?.type === 'private') {
                    dispatch(
                      loadWorkspace(
                        'private-' + preloadedData?.workspace?.alias
                      )
                    ).then(() => {
                      dispatch(
                        loadProject({
                          workspaceId:
                            'private-' + preloadedData?.workspace?.alias,
                          isEditWorkspace: false,
                        })
                      ).then(() => {
                        dispatch(
                          getSharedConnections(
                            'private-' + preloadedData?.workspace?.alias
                          )
                        );
                      });
                    });
                  } else if (preloadedData?.workspace?.type === 'shared') {
                    dispatch(
                      loadWorkspace(preloadedData?.workspace?.alias)
                    ).then(() => {
                      dispatch(
                        loadProject({
                          workspaceId: preloadedData?.workspace?.alias,
                          isEditWorkspace: false,
                        })
                      ).then(() => {
                        dispatch(
                          getSharedConnections(preloadedData?.workspace?.alias)
                        );
                      });
                    });
                  }
                }
              }
            });
          } else {
            //If there is no information, then load the workspace noted as the current workspace
            dispatch(loadWorkspace()).then(() => {
              dispatch(loadProject()).then(() => {
                dispatch(getSharedConnections(getCurrentWorkspaceId()!));
              });
            });
          }
        })
        .catch(() => {
          console.error('Unable to load the preloaded data from Angular');
        });
      setLoadedUserProfile(true);
    }

    //load workspace on AngularJS workspace orchestration save event
    let workspaceSaveHandler = onWorkspaceSave((event: any, workspace: any) => {
      if (workspace) {
        //This takes care of the save button from angular since the callback passes a workspace
        dispatch(loadEditWorkspace(workspace.workspace.Id));
      } else {
        //This handles the save button from the angular orchestration settings. No parameters passed in the call back
        dispatch(loadWorkspace()).then(() => {
          dispatch(loadProject()).then(() => {
            dispatch(getSharedConnections(getCurrentWorkspaceId()!));
          });
        });
      }
    });

    return () => {
      // cleanup
      if (workspaceSaveHandler) {
        workspaceSaveHandler();
        workspaceSaveHandler = undefined;
      }
    };
  }, [
    dispatch,
    loadedUserProfile,
    userProfile,
    userprofileStatus,
    userProfile.currentUserprofile,
    workspaceStatus,
    userRecentsStatus,
    userRecents,
  ]);
};

export const useUserProfileCanAdmin = () => {
  const userprofileCanAdminStatus = useSelector(
    selectUserprofileCanAdminStatus
  );
  let userProfileCanAdmin = useSelector(selectUserprofileCanAdmin);

  userProfileCanAdmin =
    userprofileCanAdminStatus === UserprofileStatus.Loaded &&
    userProfileCanAdmin;

  return { userProfileCanAdmin, userprofileCanAdminStatus };
};

export const loadUserprofile = createAsyncThunk(
  'userprofile/loadUserprofile',
  async () => {
    return await userprofileApi.getUserprofile();
  }
);

export const saveUserprofile = createAsyncThunk(
  'userprofile/saveUserprofile',
  async ({ userprofile }: { userprofile: Userprofile }) => {
    // TODO: sync the current userprofile with AngularJS
    return await putUserprofile(userprofile);
  }
);

export const saveUserSelections = createAsyncThunk(
  'userprofile/saveUserprofile',
  async ({
    userprofile,
    userSelections,
  }: {
    userprofile: Userprofile;
    userSelections: string;
  }) => {
    return await putUserSelections(userprofile, userSelections);
  }
);

export const saveSupportNotificationStatus = createAsyncThunk(
  'userprofile/saveUserprofile',
  async ({
    userprofile,
    supportNotificationStatus,
  }: {
    userprofile: Userprofile;
    supportNotificationStatus: string;
  }) => {
    return await putSupportNotificationStatus(
      userprofile,
      supportNotificationStatus
    );
  }
);

// Begin Admin/Debug Utils
export const loadCanAdmin = createAsyncThunk(
  'userprofile/getCanAdmin',
  async () => {
    return await userprofileApi.getCanAdmin();
  }
);

export function runAsAdministrator(): undefined {
  const cnfm = !window.confirm(
    'Do you want to run as administrator? This will grant you Lens Explorer administrative privileges and may grant temporary Geneva Orchestrator elevated privileges.'
  );
  if (cnfm) {
    return;
  }

  runAsAdmin(true);

  orchestratorApi.elevateOrchestrator().catch((err) => {
    alert('Could not elevate with Geneva Orchestrator: ' + err);
  });
}

export function runAsUser() {
  runAsAdmin(false);
}

export function switchToAngularUi(calledFrom: string) {
  const cnfm = !window.confirm(
    'Do you want to switch to the current Lens UI? You will lose any unsaved changes on this page.'
  );
  if (cnfm) {
    return;
  }

  var dimensions = {
    calledFrom: calledFrom,
  };

  let useReactUI = localStorage.getItem('useReactUI');
  if (useReactUI === null || useReactUI === undefined) {
    useReactUI = useReactUIByDefault ? 'true' : 'false';
  }

  if (useReactUI === 'true') {
    logger.event(
      LensTelemetryConstants.EventNames.UiSwitch.ReactToAngular,
      dimensions
    );
    localStorage.setItem('useReactUI', 'false');
  } else {
    logger.event(
      LensTelemetryConstants.EventNames.UiSwitch.AngularToReact,
      dimensions
    );
    localStorage.setItem('useReactUI', 'true');
  }

  window.setTimeout(function () {
    window.location.assign(window.origin);
  });
}
// End Admin Utils

let initialLoadedUserprofile: Userprofile = {
  UPN: '',
  Name: '',
  DataSources: [],
  UserSelections: '',
  SupportNotificationStatus: '',
  FeatureSettings: [],
};

let initialUserprofile = {
  currentUserprofile: initialLoadedUserprofile,
  userprofileStatus: UserprofileStatus.None,
  canAdminStatus: UserprofileStatus.None,
  canAdmin: false,
};

/**
 * Redux slice representing the current userprofile.
 */
const userprofileSlice = createSlice({
  name: 'userprofile',
  initialState: initialUserprofile,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadUserprofile.pending, (state, action) => {
        state.userprofileStatus = UserprofileStatus.Loading;
      })
      .addCase(loadUserprofile.fulfilled, (state, action) => {
        state.userprofileStatus = UserprofileStatus.Loaded;

        const userprofile = action.payload;

        state.currentUserprofile = userprofile;
        //// Convert the model to UI properties.
        //trimSearchColumns = _.parseInt(trimSearchColumns)
        //if (_.isNaN(trimSearchColumns)) {
        //    trimSearchColumns = constants.DefaultDisplayColumnCount
        //}
        //userSelections = utilities.toJson(UserSelections) || {}
        //favoritedWorkspaces = FavoritedWorkspaces && FavoritedWorkspaces !== "{}" ? JSON.parse(FavoritedWorkspaces) : []
        //recentWorkspaces = RecentWorkspaces && RecentWorkspaces !== "{}" ? JSON.parse(RecentWorkspaces) : []
        //recentVisSearches = RecentVisSearches && RecentVisSearches !== "{}" ?
        //    // exclude invalid records from recentVis array
        //    _.reject(JSON.parse(RecentVisSearches), function (recentAsset) { return typeof recentAsset === 'string' || !recentAsset }) : []
        //favoriteVisSearches = FavoriteVisSearches && FavoriteVisSearches !== "{}" ? JSON.parse(FavoriteVisSearches) : []
        //favoritedDashboards = FavoritedDashboards || []
        //recentDashboards = RecentDashboards || []
        //recentTables = RecentTables || []
        //favoriteTables = FavoriteTables || []
        //favoriteJobs = FavoriteJobs || []
        //recentJobs = RecentJobs || []
        //turnOffVisualAnalyzerTeaser = !!turnOffVisualAnalyzerTeaser
        //timeZone = TimeZone || UserprofileTimeZones.UTC
        //supportNotificationStatus = SupportNotificationStatus && SupportNotificationStatus !== "{}" ? JSON.parse(SupportNotificationStatus) : []
      })
      .addCase(loadUserprofile.rejected, (state, action) => {
        state.userprofileStatus = UserprofileStatus.Error;
        notifier.error(action.error);
      })
      .addCase(saveUserprofile.pending, (state, action) => {
        state.userprofileStatus = UserprofileStatus.Loading;

        //if (_.isNil(upn) || upn.length === 0) {
        //    throw new Error("upn is null or empty.")
        //}

        //var theiaHomePageEnabled = _.some(userprofile.features, { Type: 'Theia', IsEnabled: true }) && _.some(userprofile.features, { Type: 'TheiaHomePage', IsEnabled: true })
        //localStorage.set(Constants.TheiaHomePageEnabledKey, theiaHomePageEnabled)

        //var userSelections = {
        //    // workspace: workspaceManager.getMode() === workspaceManager.Modes.Shared ? workspaceManager.getCurrentWorkspaceAlias() : null,
        //}

        // Clean up existing userprofiles that have image data in the recent and favorites array
        //_.each(userprofile.favoriteVisSearches, function (search) { delete search.Image })
        //_.each(userprofile.recentVisSearches, function (search) { delete search.Image })
        //_.each(userprofile.favoritedDashboards, function (dashboard) { delete dashboard.Image })
        //_.each(userprofile.recentDashboards, function (dashboard) { delete dashboard.Image })

        //Convert UI properties to the model.
        //userprofile.UserSelections = JSON.stringify(userSelections)
        //userprofile.FavoritedWorkspaces = JSON.stringify(favoritedWorkspaces)
        //userprofile.RecentWorkspaces = JSON.stringify(recentWorkspaces)
        //userprofile.FavoriteVisSearches = JSON.stringify(favoriteVisSearches)
        //userprofile.RecentVisSearches = JSON.stringify(recentVisSearches)
        //userprofile.FavoritedDashboards = favoritedDashboards
        //userprofile.RecentDashboards = recentDashboards
        //userprofile.RecentTables = recentTables
        //userprofile.FavoriteTables = favoriteTables
        //userprofile.FavoriteJobs = favoriteJobs
        //userprofile.RecentJobs = recentJobs
        //userprofile.themeColorPreference = themeColorPreference
        //userprofile.trimSearchColumns = JSON.stringify(trimSearchColumns)
        //userprofile.disableAutoRunningQuery = disableAutoRunningQuery
        //userprofile.turnOffVisualAnalyzerTeaser = !!turnOffVisualAnalyzerTeaser
        //userprofile.FeatureSettings = features
        //userprofile.TimeZone = timeZone
        //userprofile.SupportNotificationStatus = JSON.stringify(state.supportNotificationStatus)

        //lscache.remove(cacheKeys.retrievedUserprofile)
      })
      .addCase(saveUserprofile.fulfilled, (state, action) => {
        state.userprofileStatus = UserprofileStatus.Loaded;

        const userprofile = action.payload;
        state.currentUserprofile = userprofile;
        //lscache.set(cachekeys.retrieveduserprofile, userprofile)
      })
      .addCase(saveUserprofile.rejected, (state, action) => {
        notifier.error(action.error);
      })
      .addCase(loadCanAdmin.pending, (state, action) => {
        state.canAdminStatus = UserprofileStatus.Loading;
      })
      .addCase(loadCanAdmin.fulfilled, (state, action) => {
        state.canAdminStatus = UserprofileStatus.Loaded;
        state.canAdmin = action.payload;
      })
      .addCase(loadCanAdmin.rejected, (state, action) => {
        notifier.error(action.error);
      });
  },
});

//export const {
//} = userprofileSlice.actions

export const selectUserprofileStatus = (state: RootState) =>
  state.userprofile.userprofileStatus;
export const selectUserprofile = (state: RootState) =>
  state.userprofile.currentUserprofile;
export const selectUserprofileCanAdminStatus = (state: RootState) =>
  state.userprofile.canAdminStatus;
export const selectUserprofileCanAdmin = (state: RootState) =>
  state.userprofile.canAdmin;
export const selectUserprofileNotifications = (state: RootState) =>
  state.userprofile.currentUserprofile.SupportNotificationStatus;
export default userprofileSlice.reducer;
