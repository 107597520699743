import { useState } from 'react';
import {
  ElxDateTimePicker,
  ElxPrimaryButton,
  InputMessageTypes,
} from '@elixir/components';
import { ElxMarkdownEditor } from '@elixir/fx/lib/components/MarkdownEditor';
import { Card } from 'components/cards/card';
import { IStackStyles, Stack } from '@fluentui/react';
import { v4 as uuidv4 } from 'uuid';
import { NeutralColors } from '@elixir/styling';
import azureSupportNotificationApi from 'features/notifications/azureNotifications/api/azureSupportNotificationApi';
import notifier from 'utils/notifier';

const cardStyles: Partial<IStackStyles> = {
  root: {
    marginTop: '15px',
    marginLeft: '15px',
    width: '80%',
  },
};

const SupportNotificationSettings = (): JSX.Element => {
  const [content, setContent] = useState<string>(
    '# TITLE \n notification general description \n\n## Steps current users should take: \n1. step 1 \n2. step 2 \n3. more steps...  \n\n```error message here```'
  );
  const [expirationTime, setExpirationTime] = useState<string | undefined>();
  const [notifBtnText, setNotifBtnText] = useState<string>('Add Notification');

  return (
    <Card
      title="Notification Settings"
      tokens={{ childrenGap: 16 }}
      styles={cardStyles}
    >
      <Stack.Item data-testid="monaco-markdown">
        <ElxMarkdownEditor
          rows={15}
          required={true}
          label="Notification content (in markdown format)"
          value={content}
          onChange={(_: any, newValue?: string) => setContent(newValue || '')}
          theme={{
            name: 'elx-markdown-new',
            theme: {
              base: 'vs',
              inherit: true,
              rules: [{ token: '', foreground: '#000000' }],
              colors: {
                'editorLineNumber.foreground': '#605E5C',
                'editorGutter.background': NeutralColors.Grays.Gray20,
                'editor.foreground': NeutralColors.Grays.Gray20,
              },
            },
          }}
        />
      </Stack.Item>
      <ElxDateTimePicker
        timeZones={[{ utcOffsetMinutes: 0, symbol: 'UTC' }]}
        label="Expiration Time"
        required={true}
        onChange={(newValue?: string) => {
          setExpirationTime(newValue);
        }}
        value={expirationTime}
        message={
          expirationTime === '' || expirationTime === undefined
            ? {
                type: InputMessageTypes.Error,
                content: 'An expiration time is required.',
              }
            : undefined
        }
      />
      <Stack.Item align="end">
        <ElxPrimaryButton
          text={notifBtnText}
          disabled={expirationTime === '' || expirationTime === undefined}
          styles={{ root: { width: '200px' } }}
          onClick={() => {
            setNotifBtnText('Adding Notification ...');
            azureSupportNotificationApi
              .addNewSupportNotification({
                Id: uuidv4(),
                ContentType: 'Markdown',
                Message: content,
                Tags: 'Global',
                Expiry: expirationTime
                  ? new Date(expirationTime).toISOString()
                  : new Date().toISOString(),
              })
              .then(() => {
                setNotifBtnText('Add Notification');
                notifier.info('Successfully added new notification.');
              });
          }}
        />
      </Stack.Item>
    </Card>
  );
};

export default SupportNotificationSettings;
