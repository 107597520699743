import { IRange } from 'monaco-editor';
import React from 'react';
import MonacoEditor from './monakoEditor';

interface EditorProps {
  height?: string | number | undefined;
  width?: string | number | undefined;
  onChange: (value: string) => void;
  value: string;
  language?: string | undefined;
  readOnly?: boolean;
  focusOnMount?: boolean;
  range?: IRange;
}

export function Editor(props: EditorProps) {
  return (
    <div style={{ border: '1px solid gray' }}>
      <MonacoEditor
        height={props.height}
        width={props.width}
        language={props.language}
        value={props.value}
        onChange={(newValue) => {
          props.onChange(newValue);
        }}
        editorDidMount={(editor, monaco) => {
          if (props.focusOnMount) {
            editor.focus();
          }
          if (props.range) {
            editor.setSelection(props.range);
            editor.revealRange(props.range);
          }
        }}
        editorWillUnmount={(editor) => {
          editor.dispose();
        }}
        options={{
          wordWrap: 'on',
          readOnly: props.readOnly || false,
          automaticLayout: true,
        }}
      ></MonacoEditor>
    </div>
  );
}
