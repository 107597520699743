import { ElxDropdown } from '@elixir/components';
import { IDropdownOption, Spinner } from '@fluentui/react';
import { LensLabel } from 'utils/lensLabel';
import { useLinkedGrafanaInstances } from '../../grafana/grafanaOnboardingSlice';
import { useEffect, useState } from 'react';
import { GrafanaOnboardingDataStatus } from '../../grafana/models/grafanaOnboardingData';
import { selectWorkspaceId } from 'features/workspaces/workspaceSlice';
import { useSelector } from 'react-redux';

export interface GrafanaInstanceSelectorProps {
  selectedGrafanaInstance: string;
  setSelectedGrafanaInstance: (instanceUrl: string) => void;
}
export const GrafanaInstanceSelector = ({
  selectedGrafanaInstance,
  setSelectedGrafanaInstance,
}: GrafanaInstanceSelectorProps): JSX.Element => {
  const workspaceId = useSelector(selectWorkspaceId);
  const { lensGrafanaInstanceList, linkedGrafanaInstancesStatus } =
    useLinkedGrafanaInstances(workspaceId);
  const [grafanaInstanceList, setGrafanaInstanceList] = useState<
    IDropdownOption[]
  >([]);

  useEffect(() => {
    if (linkedGrafanaInstancesStatus === GrafanaOnboardingDataStatus.Loaded) {
      const instanceList = lensGrafanaInstanceList.map((a) => ({
        key: a.endpoint,
        text: a.name,
      }));
      setGrafanaInstanceList(instanceList);
      if (!instanceList.some((i) => i.key === selectedGrafanaInstance)) {
        setSelectedGrafanaInstance('');
      }
    }
  }, [
    lensGrafanaInstanceList,
    linkedGrafanaInstancesStatus,
    selectedGrafanaInstance,
    setSelectedGrafanaInstance,
  ]);

  const label = (
    <LensLabel
      labelText="Select Grafana Instance"
      hintText={
        'Select a linked Grafana Instance. If you do not have any linked Grafana instances, you can go back to workspaces and link existing Grafana instances or create a new Instance and then link it.'
      }
      required
    ></LensLabel>
  );

  if (linkedGrafanaInstancesStatus === GrafanaOnboardingDataStatus.Loading) {
    return (
      <>
        {label}
        <Spinner label="Loading Grafana instances..." />
      </>
    );
  }

  return (
    <ElxDropdown
      onRenderLabel={() => label}
      options={grafanaInstanceList}
      selectedKey={selectedGrafanaInstance}
      placeholder="Select Grafana Instance"
      errorMessage={
        linkedGrafanaInstancesStatus === GrafanaOnboardingDataStatus.Loaded &&
        lensGrafanaInstanceList.length === 0
          ? 'You do not have any linked Grafana instances. Grafana instances can be linked or created from the workspaces page.'
          : undefined
      }
      inputActions={[]}
      onChange={(e, value) => {
        if (value) {
          setSelectedGrafanaInstance(value.key.toString());
        }
      }}
    />
  );
};
