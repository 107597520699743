import SqlSelector from 'features/workspaces/components/editDataConnection/dataSourceComponents/sqlSelector';
import { SelectorProps } from 'features/workspaces/components/editDataConnection/editDataConnectionHelper';
import {
  ConnectionDataSourceType,
  SqlDataSource,
} from 'features/workspaces/models/project';
import _ from 'lodash';
import { registerDataSourcePlugin } from '../registry';
import sqlClient from '../sqlDataSource/client/sqlClient';
import { DataSourcePlugin } from './dataSourcePlugin';

class SqlPlugin extends DataSourcePlugin {
  public dataSourceFormComponent(selectorProps: SelectorProps): JSX.Element {
    return (
      <SqlSelector
        workspace={selectorProps.workspace}
        state={selectorProps.state}
        setState={selectorProps.setState}
        customNameFilled={selectorProps.customNameFilled}
      />
    );
  }

  public generatedName(state: SqlDataSource): string {
    if (state) {
      if (state.server && state.database) {
        let host = _.first(_.split(state.server, '.'));
        return host + '.' + state.database;
      }
      if (state.connectionSecretUri) {
        let tokens = state.connectionSecretUri.split('/');
        let postfix;
        if (tokens.length > 2) {
          postfix = tokens[tokens.length - 2];
        } else if (tokens.length > 1) {
          postfix = tokens[tokens.length - 1];
        } else {
          postfix = tokens[0];
        }
        return 'Sql.Datasource.ConnectionStr.' + postfix;
      }
    }
    return '';
  }
}

registerDataSourcePlugin(
  ConnectionDataSourceType.Sql,
  new SqlPlugin(ConnectionDataSourceType.Sql, sqlClient)
);
