import { ElxTextField } from '@elixir/components';
import { Stack, Toggle } from '@fluentui/react';
import { Activity } from 'features/orchestrator/models/job';
import { orchestratorConstants } from 'utils/constants';
import { LensLabel } from 'utils/lensLabel';

export interface JobEndpointMdmProps {
  isSource: boolean;
  activity: Activity;
  endpoint:any;
  onChange: (activity: Activity, endpoint:any) => void;
}

export const JobEndpointMdm = (props: JobEndpointMdmProps) => {
  const { isSource, activity, onChange, endpoint } = props;
  const endpnt = endpoint || (isSource ? activity.input! : activity.output!);

  return (
    <>
      <Stack.Item>
        <ElxTextField
          id={'Metric'}
          onRenderLabel={() => {
            return (
              <LensLabel
                labelText={'Metric'}
                hintText={'The Geneva Metrics (MDM) Metric.'}
                required={activity.activityType !== 'DataMovement'}
              ></LensLabel>
            );
          }}
          value={endpnt.metric}
          onChange={(e, val) => {
            endpnt.metric = val;
            onChange(activity, endpnt);
          }}
        ></ElxTextField>
      </Stack.Item>

      {activity.activityType ===
        orchestratorConstants.ActivityTypes.DataMovement && (
        <Stack.Item>
          <Toggle
          label="Override Stream Values"
          checked={endpnt.overrideStreamValues}
          onText="Override stream values"
          offText="Not overriding stream values"
          onChange={(e: React.MouseEvent<HTMLElement>, checked?: boolean) => {
            endpnt.overrideStreamValues = checked;
            onChange(activity,endpnt);
          }}
        />
        </Stack.Item>
      )}
    </>
  );
};
