import { ElxActionButton } from '@elixir/components';
import { LoadingPage } from '@elixir/fx/lib/shell/LoadingPage';
import {
  CommandBar,
  ICommandBarItemProps,
  Label,
  MessageBar,
  MessageBarType,
  Pivot,
  PivotItem,
  Stack,
} from '@fluentui/react';
import { RootState } from 'app/lensShellUtility';
import {
  usePivotBarStyles,
  useTableCommandBarStyles,
} from 'features/catalog/utils/catalogEntityDetailsStyles';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Location } from 'history';
import { useLocation, useHistory } from 'react-router-dom';
import notifier from 'utils/notifier';
import {
  selectCatalogEntityByManifestKey,
  selectCatalogListStatus,
  useCatalogEntityHistory,
  loadCatalogEntity,
  selectLoadCatalogEntityStatus,
} from '../../catalogSlice';
import {
  CatalogEntityStatus,
  CatalogEntityType,
} from '../../models/catalogEntity';
import {
  EntityDetailsPivotKey,
  entityDetailsPivotTabs,
  getCurrPivotKey,
  getCurrPivotKeyString,
  getLinkToKustoQuery,
  getNewPivotUrl,
} from '../../utils/catalogEntityDetailsUtils';
import { EntityDetailsGeneral } from './entityDetailsGeneral';
import { EntityDetailsHistory } from './entityDetailsHistory';
import { EntityDetailsJSON } from './entityDetailsJSON';
import { EntityDetailsProperties } from './entityDetailsProperties';
import { EntityDetailsSchema } from './entityDetailsSchema';
import { catalogRouteProps } from 'features/catalog/catalogRoute';
import { CopyButtonWithNotifier } from 'components/copyButtons/copyButtons';

/**
 * Data Catalog entity details page
 * @returns JSX element of Data Catalog entity details page
 */
export const CatalogEntityDetails = (props: catalogRouteProps): JSX.Element => {
  const manifestKey = props.manifestKey || '';

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation<Location>();

  const catalogEntityListStatus = useSelector(selectCatalogListStatus);
  const loadCatalogEntityStatus = useSelector(selectLoadCatalogEntityStatus);

  // Get entity info for subpages
  const entity = useSelector((state: RootState) => {
    return selectCatalogEntityByManifestKey(state, manifestKey);
  });
  if (
    !entity &&
    manifestKey &&
    (catalogEntityListStatus === CatalogEntityStatus.Loaded ||
      catalogEntityListStatus === CatalogEntityStatus.Error) &&
    (loadCatalogEntityStatus.manifestKey !== manifestKey ||
      (loadCatalogEntityStatus.status !== CatalogEntityStatus.Loaded &&
        loadCatalogEntityStatus.status !== CatalogEntityStatus.Loading))
  ) {
    dispatch(loadCatalogEntity(manifestKey));
  }

  const entityHistory = useCatalogEntityHistory(manifestKey);
  // Get pivot key from URL and fix if necessary
  const currPivotKey = getCurrPivotKey();

  // Change url to default state (general) if subpage title malformed
  useEffect(() => {
    if (location.pathname.includes('details')) {
      const currPivotKeyStr = getCurrPivotKeyString();
      if (!entityDetailsPivotTabs.includes(currPivotKeyStr)) {
        window.history.replaceState(
          null,
          '',
          getNewPivotUrl(currPivotKey.toString())
        );
      }
    }
  }, [currPivotKey, location]);

  const [pivotTabKey, setPivotTabKey] = useState(currPivotKey);

  // Set up styling
  const commandBarStyles = useTableCommandBarStyles();
  const pivotBarStyles = usePivotBarStyles();

  const [hideNameCopyButton, setHideNameCopyButton] = useState(true);
  const [hideNameWithNamespaceCopyButton, setHideNameWithNamespaceCopyButton] =
    useState(true);

  var CommandBarActionItems: ICommandBarItemProps[] = [];

  if (entity) {
    CommandBarActionItems = [
      {
        key: 'query',
        text: 'Query',
        iconProps: { iconName: 'OfflineStorage' },
        disabled: entity.type !== CatalogEntityType.Kusto,
        onClick: () => {
          const url = getLinkToKustoQuery(entity);
          if (url === undefined) {
            notifier.error('Cannot open query');
          } else {
            window.open(url, '_blank');
          }
        },
      },
      {
        key: 'managePrivacy',
        text: 'Manage Privacy (S360)',
        iconProps: { iconName: 'OpenInNewWindow' },
        onClick: () => {
          const url =
            'https://vnext.s360.msftcloudes.com/blades/compliance/' +
            entity.manifestKey;
          window.open(url, '_blank');
        },
      },
      {
        key: 'delete',
        text: 'Delete Entity (S360)',
        iconProps: { iconName: 'OpenInNewWindow' },
        onClick: () => {
          const url =
            'https://vnext.s360.msftcloudes.com/blades/compliance/' +
            entity.manifestKey;
          window.open(url, '_blank');
        },
      },
      {
        key: 'share',
        text: 'Share',
        iconProps: { iconName: 'Share' },
        onClick: () => {
          let href = window.location.href;
          href = href.split('?')[0];

          navigator.clipboard.writeText(href);

          notifier.info(
            'Successfully copied current catalog entity link to clipboard.'
          );
        },
      },
    ];
  }

  const onLinkClick = (item?: PivotItem) => {
    if (item) {
      var newKeyString: string = item.props?.itemKey || 'General';
      var newPivotKey: EntityDetailsPivotKey =
        (newKeyString as EntityDetailsPivotKey) ||
        EntityDetailsPivotKey.General;

      setPivotTabKey(newPivotKey);
      window.history.pushState(null, '', getNewPivotUrl(newKeyString));
    }
  };

  return (
    <Stack styles={{ root: { background: '#FAF9F8', height: '100%' } }}>
      <Stack.Item styles={{ root: { background: 'white' } }}>
        <ElxActionButton
          key={3}
          text="Back to Data Catalog"
          iconProps={{ iconName: 'Back' }}
          onClick={() => {
            history.push(`/datacatalog/list`);
          }}
        />
      </Stack.Item>
      {!entity &&
        (catalogEntityListStatus === CatalogEntityStatus.Loading ||
          (manifestKey &&
            manifestKey === loadCatalogEntityStatus.manifestKey &&
            loadCatalogEntityStatus.status ===
              CatalogEntityStatus.Loading)) && (
          <Stack.Item styles={{ root: { background: 'white' } }}>
            <LoadingPage label="Loading..." />
          </Stack.Item>
        )}
      {((!entity &&
        manifestKey &&
        loadCatalogEntityStatus.manifestKey === manifestKey &&
        loadCatalogEntityStatus.status === CatalogEntityStatus.Error) ||
        (!entity &&
          loadCatalogEntityStatus.status === CatalogEntityStatus.None &&
          catalogEntityListStatus === CatalogEntityStatus.Error)) && (
        <Stack.Item styles={{ root: { background: 'white' } }}>
          <Label>
            Error Loading. Please refer to error message or reload page.
          </Label>
        </Stack.Item>
      )}
      {entity &&
        (catalogEntityListStatus === CatalogEntityStatus.Loaded ||
          (loadCatalogEntityStatus.manifestKey === manifestKey &&
            loadCatalogEntityStatus.status === CatalogEntityStatus.Loaded)) && (
          <>
            <Stack.Item styles={{ root: { background: 'white' } }}>
              {(entity.deprecated || entity.quarantined) && (
                <MessageBar
                  messageBarType={MessageBarType.warning}
                  styles={{
                    root: {
                      width: '100%',
                    },
                  }}
                >
                  This entity is <b>{entity.deprecated ? 'deprecated' : ''}</b>
                  {entity.deprecated && entity.quarantined ? ' and ' : ''}
                  <b>{entity.quarantined ? 'quarantined' : ''}</b>.
                </MessageBar>
              )}
              <Stack
                horizontal
                wrap
                verticalAlign="center"
                styles={{
                  root: {
                    fontSize: '22px',
                    padding: '20px',
                    paddingBottom: '5px',
                    paddingTop: '24px',
                  },
                }}
                tokens={{ childrenGap: '0.5rem' }}
              >
                <Stack.Item
                  styles={{
                    root: {
                      fontSize: '22px',
                    },
                  }}
                >
                  Entity Details:
                </Stack.Item>
                {entity.name && (
                  <Stack.Item
                    onMouseEnter={() => {
                      setHideNameCopyButton(false);
                    }}
                    onMouseLeave={() => {
                      setHideNameCopyButton(true);
                    }}
                  >
                    <span
                      style={{
                        overflowWrap: 'break-word',
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 600,
                          fontSize: '22px',
                          overflowWrap: 'break-word',
                          marginRight: '.5rem',
                          verticalAlign: 'middle',
                        }}
                      >
                        {entity.name}
                      </span>
                      {entity.name && (
                        <CopyButtonWithNotifier
                          text={entity.name}
                          hidden={hideNameCopyButton}
                        />
                      )}
                    </span>
                  </Stack.Item>
                )}
              </Stack>

              {entity.nameWithNamespace && (
                <Stack
                  horizontal
                  verticalAlign="center"
                  wrap
                  styles={{
                    root: {
                      fontSize: '14px',
                      padding: '20px',
                      paddingTop: '0px',
                    },
                  }}
                  onMouseEnter={() => {
                    setHideNameWithNamespaceCopyButton(false);
                  }}
                  onMouseLeave={() => {
                    setHideNameWithNamespaceCopyButton(true);
                  }}
                >
                  <span
                    style={{
                      overflowWrap: 'break-word',
                    }}
                  >
                    <span
                      style={{
                        overflowWrap: 'break-word',
                        marginRight: '.5rem',
                        verticalAlign: 'middle',
                      }}
                    >
                      {entity.nameWithNamespace}
                    </span>
                    {entity.nameWithNamespace && (
                      <CopyButtonWithNotifier
                        text={entity.nameWithNamespace}
                        hidden={hideNameWithNamespaceCopyButton}
                      />
                    )}
                  </span>
                </Stack>
              )}
            </Stack.Item>
            <Stack.Item grow verticalFill styles={{ root: { height: '100%' } }}>
              <CommandBar
                items={CommandBarActionItems}
                ariaLabel="Entity actions"
                styles={commandBarStyles}
              />
              <Pivot
                onLinkClick={onLinkClick}
                selectedKey={pivotTabKey}
                styles={pivotBarStyles}
              >
                <PivotItem
                  headerText={EntityDetailsPivotKey.General}
                  itemKey={EntityDetailsPivotKey.General}
                >
                  <EntityDetailsGeneral entity={entity} />
                </PivotItem>
                <PivotItem
                  headerText={EntityDetailsPivotKey.Schema}
                  itemKey={EntityDetailsPivotKey.Schema}
                >
                  <EntityDetailsSchema entity={entity} />
                </PivotItem>
                <PivotItem
                  headerText={EntityDetailsPivotKey.Properties}
                  itemKey={EntityDetailsPivotKey.Properties}
                >
                  <EntityDetailsProperties entity={entity} />
                </PivotItem>
                <PivotItem
                  headerText={EntityDetailsPivotKey.History}
                  itemKey={EntityDetailsPivotKey.History}
                >
                  <EntityDetailsHistory
                    entity={entity}
                    history={entityHistory}
                  />
                </PivotItem>
                <PivotItem
                  headerText={EntityDetailsPivotKey.JSON}
                  itemKey={EntityDetailsPivotKey.JSON}
                >
                  <EntityDetailsJSON entity={entity} />
                </PivotItem>
              </Pivot>
            </Stack.Item>
          </>
        )}
    </Stack>
  );
};
