import { Label, Stack } from '@fluentui/react';
import { Divider } from 'antd';
import { CatalogEntityType } from 'features/catalog/models/catalogEntity';
import {
  entityDetailsLabelStyles,
  propertiesInnerSectionStackStyles,
  propertiesSectionStackItemStyles,
  propertiesStackStyles,
} from 'features/catalog/utils/catalogEntityDetailsStyles';
import { DetailsPageProps } from 'features/catalog/utils/catalogEntityDetailsUtils';
import { CopyableLabelAndText } from 'components/copyButtons/copyButtons';

export const EntityDetailsProperties = (
  props: DetailsPageProps
): JSX.Element => {
  const { entity } = props;

  var createdDateStr: string | undefined = entity.createdDate || undefined;
  var updatedOnStr: string | undefined = entity.lastUpdate || undefined;

  if (entity.createdDate) {
    const createdDate = new Date(entity.createdDate);
    createdDateStr = createdDate.toUTCString();
  }

  if (entity.lastUpdate) {
    const updatedOn = new Date(entity.lastUpdate);
    updatedOnStr = updatedOn.toUTCString();
  }

  return (
    <Stack {...propertiesStackStyles}>
      <Stack.Item styles={propertiesSectionStackItemStyles}>
        {/* Data Store */}
        <Label {...entityDetailsLabelStyles}>Data Store</Label>

        <Stack {...propertiesInnerSectionStackStyles}>
          <Stack.Item>
            <Stack
              horizontal
              verticalAlign="center"
              tokens={{ childrenGap: '1rem' }}
            >
              <Stack.Item>
                <CopyableLabelAndText
                  label="Query Engine Type:"
                  text={entity.type}
                />
              </Stack.Item>
              <Stack.Item>
                <CopyableLabelAndText
                  label="Format:"
                  text={entity.additionalProperties?.format}
                />
              </Stack.Item>
            </Stack>
          </Stack.Item>

          <Stack.Item>
            <CopyableLabelAndText
              label="Entity Type:"
              text={entity.additionalProperties?.manifestType}
            />
          </Stack.Item>

          <Stack.Item>
            <CopyableLabelAndText
              label="Location:"
              text={entity.additionalProperties?.location}
            />
          </Stack.Item>

          {entity.type === CatalogEntityType.Cosmos && (
            <Stack.Item>
              <CopyableLabelAndText
                label="Cosmos Location Link:"
                text={
                  entity.additionalProperties?.container &&
                  entity.additionalProperties?.location
                    ? entity.additionalProperties?.container +
                      entity.additionalProperties?.location
                    : 'Not found'
                }
                link={
                  entity.additionalProperties?.container &&
                  entity.additionalProperties?.location
                    ? entity.additionalProperties?.container +
                      entity.additionalProperties?.location
                    : undefined
                }
              />
            </Stack.Item>
          )}

          {entity.additionalProperties?.partitionedBy && (
            <Stack.Item>
              <CopyableLabelAndText
                label="Partitioned By:"
                text={entity.additionalProperties?.partitionedBy}
              />
            </Stack.Item>
          )}

          {entity.additionalProperties?.collectionAgent && (
            <Stack.Item>
              <CopyableLabelAndText
                label="Collection Agent:"
                text={entity.additionalProperties?.collectionAgent}
              />
            </Stack.Item>
          )}

          {entity.additionalProperties?.refreshPeriod && (
            <Stack.Item>
              <CopyableLabelAndText
                label="Refresh Period:"
                text={entity.additionalProperties?.refreshPeriod}
              />
            </Stack.Item>
          )}

          {entity.additionalProperties?.miscAttributes && (
            <Stack.Item>
              <CopyableLabelAndText
                label="Misc Attributes:"
                text={entity.additionalProperties?.miscAttributes}
              />
            </Stack.Item>
          )}
        </Stack>
      </Stack.Item>

      <Divider />

      {/* Status */}
      <Stack.Item styles={propertiesSectionStackItemStyles}>
        <Label {...entityDetailsLabelStyles}>Status</Label>

        <Stack {...propertiesInnerSectionStackStyles}>
          <Stack.Item>
            <CopyableLabelAndText
              label="Active:"
              text={
                entity.additionalProperties?.isActive
                  ? entity.additionalProperties?.isActive.toString()
                  : 'false'
              } // TODO: Verify that if it's null that means it is not active
            />
          </Stack.Item>

          <Stack
            horizontal
            verticalAlign="center"
            tokens={{ childrenGap: '1rem' }}
          >
            <Stack.Item>
              <CopyableLabelAndText
                label="Created By:"
                text={entity.createdBy}
              />
            </Stack.Item>
            <Stack.Item>
              <CopyableLabelAndText
                label="Created Date:"
                text={createdDateStr}
              />
            </Stack.Item>
          </Stack>

          <Stack
            horizontal
            verticalAlign="center"
            tokens={{ childrenGap: '1rem' }}
          >
            <Stack.Item>
              <CopyableLabelAndText
                label="Last Updated By:"
                text={entity.updatedBy}
              />
            </Stack.Item>
            <Stack.Item>
              <CopyableLabelAndText
                label="Last Updated Date:"
                text={updatedOnStr}
              />
            </Stack.Item>
          </Stack>
        </Stack>
      </Stack.Item>
    </Stack>
  );
};
