import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import {
  InstanceLog,
  Job,
  JobExecutionStatus,
  JobInstance,
} from './models/job';
import jobApi from './api/jobApi';
import notifier from 'utils/notifier';
import { RootState } from 'app/lensShellUtility';

export enum JobListStatus {
  None = 'None',
  Loading = 'Loading',
  Loaded = 'Loaded',
  Error = 'Error',
}

export enum JobInstanceListStatus {
  None = 'None',
  Loading = 'Loading',
  Loaded = 'Loaded',
  Error = 'Error',
}

export enum JobInstanceLogStatus {
  None = 'None',
  Loading = 'Loading',
  Loaded = 'Loaded',
  Error = 'Error',
}

//#region thunks
export const rerunJobInstance = createAsyncThunk(
  'job/rerunJobInstance',
  async ({
    workspaceId,
    jobId,
    instanceId,
  }: {
    workspaceId: string;
    jobId: string;
    instanceId: string;
  }) => {
    return await jobApi.rerunJobInstance(workspaceId, jobId, instanceId);
  }
);

export const terminateJobInstance = createAsyncThunk(
  'job/terminateJobInstance',
  async ({
    workspaceId,
    jobId,
    instanceId,
  }: {
    workspaceId: string;
    jobId: string;
    instanceId: string;
  }) => {
    return await jobApi.terminateJobInstance(workspaceId, jobId, instanceId);
  }
);

export const cancelJobInstances = createAsyncThunk(
  'job/cancelJobInstances',
  async ({
    workspaceId,
    jobId,
    listType,
  }: {
    workspaceId: string;
    jobId: string;
    listType: JobExecutionStatus;
  }) => {
    return await jobApi.cancelJobInstances(workspaceId, jobId, listType);
  }
);

export const cancelJobInstance = createAsyncThunk(
  'job/cancelJobInstance',
  async ({
    workspaceId,
    jobId,
    instanceId,
  }: {
    workspaceId: string;
    jobId: string;
    instanceId: string;
  }) => {
    return await jobApi.cancelJobInstance(workspaceId, jobId, instanceId);
  }
);

export const scheduleJob = createAsyncThunk(
  'job/scheduleJob',
  async ({
    workspaceId,
    jobId,
    settings,
    params,
  }: {
    workspaceId: string;
    jobId: string;
    settings: any | null;
    params: any | null;
  }) => {
    return await jobApi.scheduleJob(workspaceId, jobId, settings, params);
  }
);

export const getJobInstanceLogs = createAsyncThunk(
  'job/getJobInstanceLogs',
  async ({
    workspaceId,
    jobId,
    instanceId,
  }: {
    workspaceId: string;
    jobId: string;
    instanceId: string;
  }) => {
    return await jobApi.getJobInstanceLogs(workspaceId, jobId, instanceId);
  }
);

export const getJobInstance = createAsyncThunk(
  'job/getJobInstance',
  async ({
    workspaceId,
    jobId,
    instanceId,
  }: {
    workspaceId: string;
    jobId: string;
    instanceId: string;
  }) => {
    return await jobApi.getJobInstance(workspaceId, jobId, instanceId);
  }
);

export const getJobInstanceTags = createAsyncThunk(
  'job/getJobInstanceTags',
  async ({ workspaceId, jobId }: { workspaceId: string; jobId: string }) => {
    return await jobApi.getJobInstanceTags(workspaceId, jobId);
  }
);

export const getJobInstances = createAsyncThunk(
  'job/getJobInstances',
  async ({ workspaceId, jobId }: { workspaceId: string; jobId: string }) => {
    return await jobApi.getJobInstances(workspaceId, jobId);
  }
);

export const getDependencyEvents = createAsyncThunk(
  'job/getDependencyEvents',
  async ({ workspaceId, jobId }: { workspaceId: string; jobId: string }) => {
    return await jobApi.getDependencyEvents(workspaceId, jobId);
  }
);

export const disableJob = createAsyncThunk(
  'job/disableJob',
  async ({
    workspaceId,
    jobId,
    params,
  }: {
    workspaceId: string;
    jobId: string;
    params: any | null;
  }) => {
    return await jobApi.disableJob(workspaceId, jobId, params);
  }
);

export const enableJob = createAsyncThunk(
  'job/enableJob',
  async ({
    workspaceId,
    jobId,
    params,
  }: {
    workspaceId: string;
    jobId: string;
    params: any | null;
  }) => {
    return await jobApi.enableJob(workspaceId, jobId, params);
  }
);

export const updateJob = createAsyncThunk(
  'job/updateJob',
  async ({
    workspaceId,
    jobId,
    job,
  }: {
    workspaceId: string;
    jobId: string;
    job: Job;
  }) => {
    return await jobApi.updateJob(workspaceId, jobId, job);
  }
);

export const getJobVersions = createAsyncThunk(
  'job/getJobVersions',
  async ({ workspaceId, jobId }: { workspaceId: string; jobId: string }) => {
    return await jobApi.getJobVersions(workspaceId, jobId);
  }
);

export const getJobWithVersionNumber = createAsyncThunk(
  'job/getJobWithVersionNumber',
  async ({
    workspaceId,
    jobId,
    versionNumber,
  }: {
    workspaceId: string;
    jobId: string;
    versionNumber: string;
  }) => {
    return await jobApi.getJobWithVersionNumber(
      workspaceId,
      jobId,
      versionNumber
    );
  }
);

export const getJobs = createAsyncThunk(
  'job/getJobs',
  async ({ workspaceId }: { workspaceId: string }) => {
    return await jobApi.getJobs(workspaceId);
  }
);

export const getJob = createAsyncThunk(
  'job/get',
  async ({ workspaceId, jobId }: { workspaceId: string; jobId: string }) => {
    return await jobApi.getJob(workspaceId, jobId);
  }
);

export const createJob = createAsyncThunk(
  'job/create',
  async ({
    workspaceId,
    job,
    scmId,
  }: {
    workspaceId: string;
    job: Job;
    scmId?: string | null;
  }) => {
    return await jobApi.createJob(workspaceId, job, scmId);
  }
);

export const deleteJob = createAsyncThunk(
  'job/delete',
  async ({
    workspaceId,
    jobId,
    scmId,
  }: {
    workspaceId: string;
    jobId: string;
    scmId: string | null;
  }) => {
    return await jobApi.deleteJob(workspaceId, jobId, scmId);
  }
);
//#endregion thunks

const jobListAdapter = createEntityAdapter<Job>({ selectId: (Job) => Job.id });
const jobInstanceListAdapter = createEntityAdapter<JobInstance>({
  selectId: (jobInstance) => jobInstance.id,
});
const jobInstanceLogListAdapter = createEntityAdapter<InstanceLog>({
  selectId: (log) => log.instanceId,
});
const initialJobState = {
  jobList: jobListAdapter.getInitialState({
    jobListStatus: JobListStatus.None,
    currentJobId: null,
  }),
  jobInstanceList: jobInstanceListAdapter.getInitialState({
    jobInstanceListStatus: JobInstanceListStatus.None,
    currentJobInstanceId: null,
  }),
  jobInstanceLogList: jobInstanceLogListAdapter.getInitialState({
    jobInstanceLogStatus: JobInstanceLogStatus.None,
  }),
};
const jobSlice = createSlice({
  name: 'job',
  initialState: initialJobState,
  reducers: {
    // TODO - Nambi needs to see what should be the action for reset.
    reset: (state) => initialJobState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteJob.pending, (state, action) => {})
      .addCase(deleteJob.rejected, (state, action) => {
        notifier.error(`Job cannot be deleted`);
      })
      .addCase(deleteJob.fulfilled, (state, action) => {
        notifier.info(`Job deletion successful`);
        jobListAdapter.removeOne(state.jobList, action.payload.id);
      })
      .addCase(createJob.pending, (state, action) => {})
      .addCase(createJob.rejected, (state, action) => {
        notifier.error(`${action.type} failed`);
      })
      .addCase(createJob.fulfilled, (state, action) => {
        jobListAdapter.upsertOne(state.jobList, action.payload);
      })
      .addCase(getJob.pending, (state, action) => {})
      .addCase(getJob.rejected, (state, action) => {
        notifier.error(`${action.type} failed`);
      })
      .addCase(getJob.fulfilled, (state, action) => {
        jobListAdapter.upsertOne(state.jobList, action.payload);
      })
      .addCase(getJobs.pending, (state, action) => {
        state.jobList.jobListStatus = JobListStatus.Loading;
      })
      .addCase(getJobs.rejected, (state, action) => {
        notifier.error(`${action.type} failed`);
        state.jobList.jobListStatus = JobListStatus.Error;
      })
      .addCase(getJobs.fulfilled, (state, action) => {
        jobListAdapter.setAll(state.jobList, action.payload);
        state.jobList.jobListStatus = JobListStatus.Loaded;
      })
      .addCase(getJobWithVersionNumber.pending, (state, action) => {})
      .addCase(getJobWithVersionNumber.rejected, (state, action) => {
        notifier.error(`${action.type} failed`);
      })
      .addCase(getJobWithVersionNumber.fulfilled, (state, action) => {
        notifier.info(`${action.type} successful`);
      })
      .addCase(getJobVersions.pending, (state, action) => {})
      .addCase(getJobVersions.rejected, (state, action) => {
        notifier.error(`${action.type} failed`);
      })
      .addCase(getJobVersions.fulfilled, (state, action) => {
        notifier.info(`${action.type} successful`);
      })
      .addCase(updateJob.pending, (state, action) => {})
      .addCase(updateJob.rejected, (state, action) => {
        notifier.error(`${action.type} failed`);
      })
      .addCase(updateJob.fulfilled, (state, action) => {
        jobListAdapter.upsertOne(state.jobList, action.payload);
      })
      .addCase(enableJob.pending, (state, action) => {})
      .addCase(enableJob.rejected, (state, action) => {
        notifier.error(`${action.type} failed`);
      })
      .addCase(enableJob.fulfilled, (state, action) => {
        notifier.info(`${action.type} successful`);
      })
      .addCase(disableJob.pending, (state, action) => {})
      .addCase(disableJob.rejected, (state, action) => {
        notifier.error(`${action.type} failed`);
      })
      .addCase(disableJob.fulfilled, (state, action) => {
        notifier.info(`${action.type} successful`);
      })
      .addCase(getDependencyEvents.pending, (state, action) => {})
      .addCase(getDependencyEvents.rejected, (state, action) => {
        notifier.error(`${action.type} failed`);
      })
      .addCase(getDependencyEvents.fulfilled, (state, action) => {
        notifier.info(`${action.type} successful`);
      })
      .addCase(getJobInstances.pending, (state, action) => {
        state.jobInstanceList.jobInstanceListStatus =
          JobInstanceListStatus.Loading;
      })
      .addCase(getJobInstances.rejected, (state, action) => {
        notifier.error(`${action.type} failed`);
        state.jobInstanceList.jobInstanceListStatus =
          JobInstanceListStatus.Error;
      })
      .addCase(getJobInstances.fulfilled, (state, action) => {
        jobInstanceListAdapter.setAll(state.jobInstanceList, action.payload);
        state.jobInstanceList.jobInstanceListStatus =
          JobInstanceListStatus.Loaded;
      })
      .addCase(getJobInstanceTags.pending, (state, action) => {})
      .addCase(getJobInstanceTags.rejected, (state, action) => {
        notifier.error(`${action.type} failed`);
      })
      .addCase(getJobInstanceTags.fulfilled, (state, action) => {
        notifier.info(`${action.type} successful`);
      })
      .addCase(getJobInstance.pending, (state, action) => {})
      .addCase(getJobInstance.rejected, (state, action) => {
        notifier.error(`${action.type} failed`);
      })
      .addCase(getJobInstance.fulfilled, (state, action) => {
        notifier.info(`${action.type} successful`);
      })
      .addCase(getJobInstanceLogs.pending, (state, action) => {
        state.jobInstanceLogList.jobInstanceLogStatus =
          JobInstanceLogStatus.Loading;
      })
      .addCase(getJobInstanceLogs.rejected, (state, action) => {
        state.jobInstanceLogList.jobInstanceLogStatus =
          JobInstanceLogStatus.Error;
        notifier.error(`${action.type} failed`);
      })
      .addCase(getJobInstanceLogs.fulfilled, (state, action) => {
        jobInstanceLogListAdapter.upsertOne(state.jobInstanceLogList, {
          ...action.payload,
          instanceId: action.meta.arg.instanceId,
        });
        state.jobInstanceLogList.jobInstanceLogStatus =
          JobInstanceLogStatus.Loaded;
      })
      .addCase(scheduleJob.pending, (state, action) => {})
      .addCase(scheduleJob.rejected, (state, action) => {
        notifier.error(`${action.type} failed`);
      })
      .addCase(scheduleJob.fulfilled, (state, action) => {
        notifier.info(`Job run schedule successful.`);
      })
      .addCase(cancelJobInstance.pending, (state, action) => {})
      .addCase(cancelJobInstance.rejected, (state, action) => {
        notifier.error(`${action.type} failed`);
      })
      .addCase(cancelJobInstance.fulfilled, (state, action) => {
        notifier.info(`${action.type} successful`);
      })
      .addCase(cancelJobInstances.pending, (state, action) => {})
      .addCase(cancelJobInstances.rejected, (state, action) => {
        notifier.error(`${action.type} failed`);
      })
      .addCase(cancelJobInstances.fulfilled, (state, action) => {
        notifier.info(`${action.type} successful`);
      })
      .addCase(terminateJobInstance.pending, (state, action) => {})
      .addCase(terminateJobInstance.rejected, (state, action) => {
        notifier.error(`${action.type} failed`);
      })
      .addCase(terminateJobInstance.fulfilled, (state, action) => {
        notifier.info(`${action.type} successful`);
      })
      .addCase(rerunJobInstance.pending, (state, action) => {})
      .addCase(rerunJobInstance.rejected, (state, action) => {
        notifier.error(`${action.type} failed`);
      })
      .addCase(rerunJobInstance.fulfilled, (state, action) => {
        notifier.info(`${action.type} successful`);
      });
  },
});

export const {
  selectAll: selectAllJobs,
  selectEntities: selectJobListEntities,
  selectById: selectJobById,
  selectIds: selectJobIds,
} = jobListAdapter.getSelectors((state: RootState) => state.job.jobList);

export const {
  selectAll: selectAllJobInstances,
  selectEntities: selectJobInstanceListEntities,
  selectById: selectJobInstanceById,
  selectIds: selectJobInstanceIds,
} = jobInstanceListAdapter.getSelectors(
  (state: RootState) => state.job.jobInstanceList
);

export const {
  selectAll: selectAllJobInstanceLog, // TODO - Nambi - this may not be needed.
  selectEntities: selectJobInstanceLogListEntities, // TODO - Nambi - this may not be needed.
  selectById: selectJobInstanceLogById,
  selectIds: selectJobInstanceLogIds, // TODO - Nambi - this may not be needed.
} = jobInstanceLogListAdapter.getSelectors(
  (state: RootState) => state.job.jobInstanceLogList
);

export const selectJobListStatus = (state: RootState) =>
  state.job.jobList.jobListStatus;

export const selectJobInstanceStatus = (state: RootState) =>
  state.job.jobInstanceList.jobInstanceListStatus;

export const selectJobInstanceLogStatus = (state: RootState) =>
  state.job.jobInstanceLogList.jobInstanceLogStatus;

export const { reset } = jobSlice.actions;

export default jobSlice.reducer;
