import {
  ElxDropdown,
  ElxPrimaryButton,
  ElxSecondaryButton,
  ElxTextField,
} from '@elixir/components';
import {
  Label,
  MessageBar,
  MessageBarType,
  Spinner,
  SpinnerSize,
  Stack,
  Toggle,
} from '@fluentui/react';
import { AppDispatch } from 'app/lensShellUtility';
import { useLensShellTheme } from 'features/shell/lensShellStyles';
import workspaceApplicationsApi from 'features/workspaces/api/workspaceApplicationsApi';
import workspacesApi from 'features/workspaces/api/workspacesApi';
import { AadTenant } from 'features/workspaces/models/project';
import { loadProject } from 'features/workspaces/workspaceSlice';
import { useEffect, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { HintText } from 'utils/hintText';
import { LensLabel } from 'utils/lensLabel';
import { MessageAndType } from '../editDataConnection/editDataConnectionPanel';
import {
  useWorkspaceStyles,
  wideSpacingStackTokens,
  workspacePadding,
} from './workspaceStyles';

export const WorkspaceHr = (): JSX.Element => {
  const theme = useLensShellTheme();
  return (
    <hr style={{ margin: '0.1em auto', color: theme.palette.neutralLight }} />
  );
};

interface FormErrorProps {
  showError: FieldError | FieldError[] | undefined;
  errorMsg: string;
}
export const FormError = (props: FormErrorProps): JSX.Element => {
  const theme = useLensShellTheme();
  const error = props.showError as FieldError;

  return (
    <>
      {error && (
        <p style={{ color: theme.palette.red }}>
          {error.message || props.errorMsg}
        </p>
      )}
    </>
  );
};

export const FormErrorMessageBar = (props: FormErrorProps): JSX.Element => {
  const error = props.showError as FieldError;

  return (
    <>
      {error && (
        <MessageBar messageBarType={1}>
          {error.message || props.errorMsg}{' '}
        </MessageBar>
      )}
    </>
  );
};

export const LoadingSpinner = (): JSX.Element => {
  return (
    <Stack //should make this a separate component?
      horizontalAlign="center"
      tokens={{ childrenGap: 24, padding: 100 }}
    >
      <Spinner size={SpinnerSize.large} />
      <Label>Loading workspace...</Label>
    </Stack>
  );
};

interface FormBoxWrapperProps {
  headerText: string;
  children: JSX.Element;
  tooltip?: string;
}
export const FormBoxWrapper = (props: FormBoxWrapperProps): JSX.Element => {
  const styles = useWorkspaceStyles();
  return (
    <Stack styles={workspacePadding} tokens={wideSpacingStackTokens}>
      <Stack.Item className={styles.orchestrationeditContainerStyle}>
        <Stack
          horizontal
          tokens={{
            childrenGap: 5,
          }}
        >
          <Stack.Item className={styles.editContainerHeaderStyle}>
            {props.headerText}
          </Stack.Item>
          <Stack.Item>
            {props.tooltip && <HintText hintText={props.tooltip} />}
          </Stack.Item>
        </Stack>

        {props.children}
      </Stack.Item>
    </Stack>
  );
};

interface AadApplicationProps {
  showAddAadApp: boolean;
  hideAadApplicationForm: () => void;
  setErrorMessage: (messageInfo: MessageAndType) => void;
  workspaceId: string;
  card?: boolean;
}
export const AadApplicationForm = (props: AadApplicationProps): JSX.Element => {
  const styles = useWorkspaceStyles();
  const dispatch = useDispatch<AppDispatch>();

  const [tenants, setTenants] = useState<AadTenant[]>([]);
  const [tenant, setTenant] = useState('');
  const [applicationId, setApplicationId] = useState('');
  const [appClientSecret, setAppClientSecret] = useState('');
  const [isManagedIdentity, setIsManagedIdentity] = useState(false);
  const [subscriptionId, setSubscriptionId] = useState('');
  const [resourceGroup, setResourceGroup] = useState('');

  useEffect(() => {
    let mounted = true;
    if (tenants.length === 0 && mounted) {
      workspacesApi.getTenants().then((tenants: AadTenant[]) => {
        setTenants(
          tenants
            .filter((t) => t.isInPublicCloud === true)
            .sort((a, b) => {
              const A = a.name.toUpperCase(); // ignore upper and lowercase
              const B = b.name.toUpperCase(); // ignore upper and lowercase
              if (A < B) {
                return -1;
              }
              if (A > B) {
                return 1;
              }

              // names must be equal
              return 0;
            })
        );
      });
    }
    return () => {
      mounted = false;
    };
  }, [props.showAddAadApp, tenants.length]);

  return (
    <Stack
      className={props.card ? styles.aadAppCardStyle : undefined}
      tokens={
        props.card
          ? wideSpacingStackTokens
          : { padding: '20px 40px 0px', childrenGap: 16, maxWidth: '600px' }
      }
    >
      <ElxDropdown
        onRenderLabel={() => (
          <LensLabel
            labelText="Tenant"
            hintText={'The Azure Active Directory tenant.'}
            required={true}
          ></LensLabel>
        )}
        options={tenants.map((tenant) => {
          return {
            key: tenant.primaryDomain,
            text: `${tenant.name} (${tenant.primaryDomain})`,
          };
        })}
        selectedKey={tenant}
        onChange={(_, option) => {
          setTenant(option?.key + '');
        }}
      />
      <ElxTextField
        onRenderLabel={() => (
          <LensLabel
            labelText={isManagedIdentity ? "Managed Identity Client ID" : "Application ID"}
            hintText={
              isManagedIdentity
                ? 'The managed identity client id. Can be found on the Azure Portal.'
                : 'The Azure Active Directory application id. Can be found on the Azure Portal.'
            }
            required={true}
          ></LensLabel>
        )}
        placeholder={
          isManagedIdentity ? 'Enter the managed identity client id' : 'Enter the application id'
        }
        value={applicationId}
        onChange={(e, value) => {
          setApplicationId(value + '');
        }}
      />
      <Stack.Item>
        <LensLabel
          labelText={'Is Managed Identity'}
          hintText={
            'When this field is checked, above id will be treated as managed identity client id.'
          }
        ></LensLabel>
        <Toggle
          label=""
          checked={isManagedIdentity}
          onChange={(e: React.MouseEvent<HTMLElement>, checked?: boolean) => {
            setIsManagedIdentity(checked || false);
          }}
          onText="Managed Identity"
          offText="AAD Application"
        />
      </Stack.Item>
      {isManagedIdentity ? (
        <>
          <ElxTextField
            onRenderLabel={() => (
              <LensLabel
                labelText="Azure Subscription"
                hintText={'Enter managed identity Azure subscription id'}
                required={true}
              ></LensLabel>
            )}
            placeholder={'Enter managed identity Azure subscription id'}
            value={subscriptionId}
            onChange={(e, value) => {
              setSubscriptionId(value ? value + '' : '');
            }}
          />
          <ElxTextField
            onRenderLabel={() => (
              <LensLabel
                labelText="Azure Resource Group"
                hintText={'Enter managed identity Azure resource group name'}
                required={true}
              ></LensLabel>
            )}
            placeholder={'Enter managed identity Azure resource group name'}
            value={resourceGroup}
            onChange={(e, value) => {
              setResourceGroup(value ? value + '' : '');
            }}
          />
        </>
      ) : (
        <ElxTextField
          onRenderLabel={() => (
            <LensLabel
              labelText="Application Client Secret"
              hintText={
                'The Azure Active Directory application client secret. The value is used to verify ownership of the application id and is not saved. For microsoft tenant only, the secret is optional and only needed if you are not an application owner. For all other tenants, the secret is required even if you are an application owner.'
              }
            ></LensLabel>
          )}
          value={appClientSecret}
          onChange={(e, value) => {
            setAppClientSecret(value + '');
          }}
        />
      )}
      <Stack horizontal tokens={{ childrenGap: 'm' }}>
        <ElxPrimaryButton
          text="Add"
          disabled={
            !(
              tenant &&
              applicationId &&
              (!isManagedIdentity || (subscriptionId && resourceGroup))
            )
          }
          onClick={() => {
            workspaceApplicationsApi
              .addApplication(props.workspaceId, {
                key: appClientSecret,
                id: applicationId,
                tenant: tenant,
                isManagedIdentity: isManagedIdentity,
                subscriptionId: isManagedIdentity ? subscriptionId : undefined,
                resourceGroup: isManagedIdentity ? resourceGroup : undefined,
              })
              .then(() => {
                dispatch(
                  loadProject({
                    workspaceId: props.workspaceId,
                    isEditWorkspace: true,
                  })
                );
                props.setErrorMessage({
                  resultMsg:
                    'Successfully added ' +
                    applicationId +
                    '. Please wait a few seconds for the changes to reflect.',
                  type: MessageBarType.success,
                });
              })
              .catch((err: any) => {
                props.setErrorMessage({
                  resultMsg: err.response.data.error.message,
                  type: MessageBarType.error,
                });
              });
          }}
        />
        <ElxSecondaryButton
          text="Cancel"
          onClick={() => {
            props.hideAadApplicationForm();
          }}
        />
      </Stack>
    </Stack>
  );
};