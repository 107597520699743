import { useEffect, useMemo, useState } from 'react';
import { Selection } from '@fluentui/react/lib/DetailsList';
import { Stack, SelectionMode, IColumn, IGroup } from '@fluentui/react';
import { classNames } from './workspacesStyles';
import {
  getFavoriteAndRecentWorkspaces,
  getAllWorkspaces,
  toggleFavorite,
  workspaceAccessFilter,
  workspaceAccessIcon,
  WorkspaceItem,
} from '../utils/workspaceQuickSwitchUtils';
import { useDispatch, useSelector } from 'react-redux';
import { ElxIconButton, FilterSelection } from '@elixir/components';
import {
  loadUserprofile,
  selectUserprofile,
  selectUserprofileStatus,
} from 'features/userprofile/userprofileSlice';
import {
  loadWorkspaces,
  selectAllWorkspaces,
  selectWorkspaceListEntities,
  selectWorkspaceListStatus,
  useWorkspaceList,
} from '../workspaceListSlice';
import { UserprofileStatus } from 'features/userprofile/models/userprofile';
import {
  WorkspaceAccessFilter,
  WorkspaceListStatus,
  WorkspaceStatus,
} from '../models/workspace';
import {
  editWorkspace,
  editWorkspaceStatus,
  loadEditWorkspace,
  loadProject,
  selectOrchestratorEditProject,
  selectOrchestratorEditProjectStatus,
  selectWorkspaceId,
} from '../workspaceSlice';
import {
  getPrivateWorkspaceId,
  isOrchestratable,
  isReadable,
  isWriteable,
} from '../utils/workspaceUtils';
import { AppDispatch } from 'app/lensShellUtility';
import WorkspacePanelManager, {
  WorkspacePanelType,
} from './editWorkspace/workspacePanelsManager';
import { DataGridBlade, IToolbarItem } from '@elixir/blades';
import logger from 'features/appInsights/lensLogger';
import { LensTelemetryConstants } from 'features/appInsights/appInsightsLibs';
import {
  loadUserFavorites,
  selectUserFavorites,
  selectUserFavoritesStatus,
  selectUserRecents,
  selectUserRecentsStatus,
  useUserFavorites,
  useUserRecents,
} from '../../userInfo/userSavedObjectDataSlice';
import UserSavedObjectData, {
  LensObjectType,
  UserSavedObjectDataStatus,
} from '../../userInfo/models/userSavedObjectData';
import { getAlias } from 'utils/authUtils';
import { exportWorkspaceParameters } from '../utils/workspaceHighsideUtils';
import { unwrapResult } from '@reduxjs/toolkit';

const filterSelections: FilterSelection[] = [];
interface Items {
  favoritesAndRecents: WorkspaceItem[];
  all: WorkspaceItem[];
}

interface SelectWorkspaceProps {
  onWorkspaceSelect: (selectedWsId: string) => void;
  onDismiss?: () => void;
  openQuickSwitchPanel: () => void;
  closeQuickSwitchPanel: () => void;
  setShowLoadingMsg: (loading: boolean) => void;
  setAccessMessages: (msg: JSX.Element | undefined) => void;
}

//WorkspaceQuickSwitch Panel
export function SelectWorkspace(props: SelectWorkspaceProps) {
  useWorkspaceList();
  useUserFavorites();
  useUserRecents();
  const currentWorkspaceId = useSelector(selectWorkspaceId);
  const workspaceListStatus = useSelector(selectWorkspaceListStatus);
  const workspaceList = useSelector(selectAllWorkspaces);
  const workspaceListEntities = useSelector(selectWorkspaceListEntities);
  const userProfileStatus = useSelector(selectUserprofileStatus);
  const userprofile = useSelector(selectUserprofile);
  const userFavoritesStatus = useSelector(selectUserFavoritesStatus);
  const userFavorites = useSelector(selectUserFavorites);
  const userRecentsStatus = useSelector(selectUserRecentsStatus);
  const userRecents = useSelector(selectUserRecents);
  const [search, setSearch] = useState({ searchString: '' });
  const [filteredItems, setFilteredItems] = useState<Items>({
    all: [],
    favoritesAndRecents: [],
  });
  const [items, setItems] = useState<Items>({
    all: [],
    favoritesAndRecents: [],
  });
  const [createWorkspace, setCreateWorkspace] = useState(false);
  const [selectedWsId, setSelectedWsId] = useState('');
  const [selected, setSelected] = useState(false);

  const [panelType, setPanelType] = useState<WorkspacePanelType>(
    WorkspacePanelType.None
  );
  const [showPanel, setShowPanel] = useState(false);

  //handling the toolbar actions visibility and loading the selected values
  const [showActions, setShowActions] = useState(false);
  const workspaceStatus = useSelector(editWorkspaceStatus);
  const workspace = useSelector(editWorkspace);

  const orchestratorEditProjectStatus = useSelector(
    selectOrchestratorEditProjectStatus
  );
  const orchProject = useSelector(selectOrchestratorEditProject);

  const privateWorkspaceId = useMemo(() => {
    return 'private-' + getAlias();
  }, []);

  const privateWorkspace = useMemo(() => {
    return {
      name: 'Private Workspace',
      isFavorite: true,
      isCurrent: currentWorkspaceId === getPrivateWorkspaceId(),
      createdBy: userprofile?.UPN,
      accessType: workspaceAccessFilter('owned'),
      accessIcon: workspaceAccessIcon('owned'),
      workspaceId: privateWorkspaceId,
      accessFilter: WorkspaceAccessFilter.Owned,
    };
  }, [currentWorkspaceId, userprofile?.UPN, privateWorkspaceId]);
  const dispatch = useDispatch<AppDispatch>();

  const refresh = () => {
    dispatch(loadUserprofile());
    dispatch(loadWorkspaces(undefined));
    dispatch(loadUserFavorites({ workspaces: workspaceListEntities }));
  };

  const updateResultsForSearch = (searchString: string, items: Items) => {
    let favoritesAndRecents: WorkspaceItem[] = [];
    let all: WorkspaceItem[] = [];
    if (searchString.length > 0) {
      const lcSearchString = searchString.toLowerCase();
      items.favoritesAndRecents.forEach((workspace: WorkspaceItem, index) => {
        if (
          workspace.name?.toLowerCase().includes(lcSearchString) ||
          workspace.createdBy?.toLowerCase().includes(lcSearchString) ||
          workspace.accessType?.toLowerCase().includes(lcSearchString) ||
          workspace.workspaceId.toLowerCase().includes(lcSearchString)
        ) {
          favoritesAndRecents.push(workspace);
        }
      });
      items.all.forEach((workspace: WorkspaceItem, index) => {
        if (
          workspace.name?.toLowerCase().includes(lcSearchString) ||
          workspace.createdBy?.toLowerCase().includes(lcSearchString) ||
          workspace.accessType?.toLowerCase().includes(lcSearchString) ||
          workspace.workspaceId.toLowerCase().includes(lcSearchString)
        ) {
          all.push(workspace);
        }
      });
    }
    return { all, favorites: favoritesAndRecents };
  };

  //load all the workspaces
  useEffect(() => {
    if (
      userProfileStatus === UserprofileStatus.Loaded && // needed for private workspace
      userFavoritesStatus === UserSavedObjectDataStatus.Loaded &&
      (workspaceListStatus === WorkspaceListStatus.Loaded ||
        workspaceListStatus === WorkspaceListStatus.Fetching)
    ) {
      const favWorkspaces = userFavorites.filter(
        (fav: UserSavedObjectData) =>
          fav.lensObjectType === LensObjectType.Workspace
      );
      const recWorkspaces = userRecents.filter(
        (rec: UserSavedObjectData) =>
          rec.lensObjectType === LensObjectType.Workspace
      );
      const all = getAllWorkspaces(
        currentWorkspaceId,
        favWorkspaces,
        workspaceList,
        privateWorkspace
      );
      const favoritesAndRecents = getFavoriteAndRecentWorkspaces(
        currentWorkspaceId,
        favWorkspaces,
        recWorkspaces,
        workspaceListEntities,
        privateWorkspace
      );
      setItems({ all, favoritesAndRecents });
    }
  }, [
    currentWorkspaceId,
    userProfileStatus,
    workspaceListStatus,
    workspaceList,
    userprofile,
    userFavoritesStatus,
    userRecentsStatus,
    userFavorites,
    userRecents,
    workspaceListEntities,
    privateWorkspace,
  ]);

  //returns the filtered workspaces though search
  useEffect(() => {
    if (search.searchString.length > 0) {
      const { all, favorites } = updateResultsForSearch(
        search.searchString,
        items
      );
      setFilteredItems({ all, favoritesAndRecents: favorites });
    } else {
      setFilteredItems(items);
    }
  }, [search, items]);

  const favAndRecentsLength = filteredItems.favoritesAndRecents.length;
  let groups: IGroup[] = [
    {
      key: 'recents&favs',
      name: 'Recents and Favorites',
      startIndex: 0,
      count: favAndRecentsLength,
      level: 0,
      isCollapsed: false,
    },
    {
      key: 'allWs',
      name: 'All Workspaces',
      startIndex: favAndRecentsLength,
      count: filteredItems.all.length,
      level: 0,
      isCollapsed: false,
    },
  ];
  const columns: IColumn[] = [
    {
      key: 'workspaceName',
      name: 'Workspace Name',
      maxWidth: 300,
      minWidth: 300,
      onRender: (workspaceItem: WorkspaceItem, index, column) => {
        return (
          <>
            {workspaceItem.isCurrent
              ? '(Currently Selected) ' + workspaceItem.name
              : workspaceItem.name}
          </>
        );
      },
    },
    {
      key: 'switch',
      name: 'Switch',
      minWidth: 50,
      maxWidth: 50,
      onRender: (workspaceItem: WorkspaceItem, index, column) => {
        return (
          <ElxIconButton
            text={'Switch to this workspace'}
            iconProps={{
              iconName: 'DrillThrough',
            }}
            disabled={workspaceItem.isCurrent}
            onClick={() => {
              props.onWorkspaceSelect(workspaceItem.workspaceId);
            }}
          />
        );
      },
    },
    {
      key: 'createdBy',
      name: 'Created By',
      minWidth: 300,
      maxWidth: 300,
      fieldName: 'createdBy',
    },
    {
      key: 'Id',
      name: 'Id',
      minWidth: 300,
      maxWidth: 300,
      fieldName: 'workspaceId',
    },
    {
      key: 'accessType',
      name: 'Access Type',
      minWidth: 100,
      maxWidth: 100,
      fieldName: 'accessType',
    },
    {
      key: 'favorite',
      name: 'Favorite',
      minWidth: 50,
      onRender: (workspaceItem, index, column) => {
        return (
          <ElxIconButton
            key={
              workspaceItem.workspaceId +
              (workspaceItem.isFavorite ? 'Unfavorite' : 'Favorite')
            }
            text={workspaceItem.isFavorite ? 'Unfavorite' : 'Favorite'}
            iconProps={{
              iconName: workspaceItem.isFavorite
                ? 'FavoriteStarFill'
                : 'FavoriteStar',
              className: classNames.favorite,
            }}
            onClick={() => {
              if (workspaceItem.name !== 'Private Workspace') {
                toggleFavorite(dispatch, refresh, workspaceItem);
              }
            }}
          />
        );
      },
    },
  ];

  //load all the information in the selected workspace
  useEffect(() => {
    if (
      selected &&
      workspace.id !== selectedWsId &&
      workspaceStatus !== WorkspaceStatus.Loading
    ) {
      props.setShowLoadingMsg(true);
      dispatch(loadEditWorkspace(selectedWsId))
        .then(unwrapResult)
        .then((resp) => {
          const writeable = isWriteable(resp.workspace);
          const readable = isReadable(resp.workspace);
          const orchestratable = isOrchestratable(resp.workspace);
          let accessRequired = [];

          if (!writeable || !readable || !orchestratable) {
            if (!readable) accessRequired.push('Read');
            if (!writeable) accessRequired.push('Write');
            if (!orchestratable) accessRequired.push('Orchestrate');

            props.setAccessMessages(
              <p style={{ margin: '0' }}>
                You DON'T have <strong>{accessRequired.join()}</strong> access.
                Please click on <strong>View Access button</strong> in the
                action bar for more information.
              </p>
            );
            dispatch(
              loadProject({
                workspaceId: selectedWsId,
                isEditWorkspace: true,
              })
            );
          }
          dispatch(
            loadProject({
              workspaceId: selectedWsId,
              isEditWorkspace: true,
            })
          );
        });
    }

    if (
      selected &&
      workspace.id === selectedWsId &&
      workspaceStatus === WorkspaceStatus.Loaded &&
      orchestratorEditProjectStatus === WorkspaceStatus.Loaded
    ) {
      setShowActions(true);
      setSelected(false);
      props.setShowLoadingMsg(false);
    }
  }, [
    dispatch,
    selected,
    selectedWsId,
    workspace.id,
    orchestratorEditProjectStatus,
    workspace.shareType,
    workspaceStatus,
    props,
    workspace,
  ]);

  const toolbarItems: IToolbarItem[] = [
    {
      id: 'newWorkspace',
      label: 'Create workspace',
      icon: 'Add',
    },
    {
      id: 'switch',
      label: 'Switch',
      icon: 'DrillThrough',
      disabled: !showActions,
    },
    {
      id: 'editSettings',
      label: 'Edit',
      icon: 'Edit',
      disabled: !showActions || selectedWsId === privateWorkspaceId,
    },
    {
      id: 'testOrchestrationGroup',
      label: 'Manage Orchestration',
      icon: 'BranchFork2',
      disabled: !showActions || selectedWsId === privateWorkspaceId,
      subMenuProps: {
        items: [
          {
            id: 'orchestration',
            label: 'Orchestration Settings',
            icon: 'Settings',
            disabled: !showActions,
          },
          {
            id: 'editDataConnections',
            label: 'Data Connections',
            icon: 'Database',
            disabled:
              workspace.dsoProject === undefined ||
              workspace.dsoProject === '' ||
              !showActions ||
              (workspace.dsoProject !== undefined &&
                orchProject.serviceGuid === undefined),
          },

          {
            id: 'aadApplications',
            label: 'AAD Applications and Managed Identities',
            icon: 'AllApps',
            disabled:
              workspace.dsoProject === undefined ||
              workspace.dsoProject === '' ||
              !showActions ||
              (workspace.dsoProject !== undefined &&
                orchProject.serviceGuid === undefined),
          },
        ],
      },
    },
    {
      id: 'delete',
      label: 'Delete',
      icon: 'Delete',
      disabled: !showActions || selectedWsId === privateWorkspaceId,
    },
    {
      id: 'migrate',
      label: 'Migrate Workspace',
      icon: 'FabricMovetoFolder',
      disabled: !showActions || selectedWsId === privateWorkspaceId,
    },
    {
      id: 'agc',
      label: 'Publish to Secure Clouds',
      icon: 'Cloud',
      disabled: !showActions || selectedWsId === privateWorkspaceId,
      subMenuProps: {
        items: [
          {
            id: 'publish',
            label: 'Publish',
            icon: 'Globe',
            disabled: !showActions || selectedWsId === privateWorkspaceId,
          },
          {
            id: 'exportParams',
            label: 'Export Parameters',
            icon: 'Export',
            disabled: !showActions || selectedWsId === privateWorkspaceId,
          },
        ],
      },
    },
    {
      id: 'manageObjects',
      label: 'Manage Objects',
      icon: 'CubeShape',
      disabled: !showActions || selectedWsId === privateWorkspaceId,
    },
    {
      id: 'viewAccess',
      label: 'View Access',
      icon: 'Permissions',
      disabled: !showActions || selectedWsId === privateWorkspaceId,
    },
    {
      id: 'history',
      label: 'View History',
      icon: 'History',
      disabled: !showActions || selectedWsId === privateWorkspaceId,
    },
    {
      id: 'azureManagedGrafana',
      label: 'Azure Managed Grafana',
      icon: 'List',
      disabled: !showActions,
    },
    {
      id: 'miscellaneous',
      label: 'Miscellaneous',
      icon: 'script',
      disabled: false,
    },
  ];

  const toolbarActionHandler = (action: string) => {
    switch (action) {
      case 'newWorkspace':
        logger.event(
          LensTelemetryConstants.EventNames.WorkspaceActions.NewWorkspace,
          { workspaceId: workspace.id }
        );
        setCreateWorkspace(true);
        setPanelType(WorkspacePanelType.WorkspaceSettings);
        setShowPanel(true);
        break;
      case 'switch':
        props.onWorkspaceSelect(selectedWsId);
        break;
      case 'editSettings':
        logger.event(LensTelemetryConstants.EventNames.WorkspaceActions.Edit, {
          workspaceId: workspace.id,
        });
        setCreateWorkspace(false);
        setPanelType(WorkspacePanelType.WorkspaceSettings);
        setShowPanel(true);
        break;
      case 'editDataConnections':
        logger.event(
          LensTelemetryConstants.EventNames.WorkspaceActions.ViewDataConnection,
          { workspaceId: workspace.id }
        );
        setPanelType(WorkspacePanelType.DataConnections);
        setShowPanel(true);
        break;
      case 'orchestration':
        logger.event(
          LensTelemetryConstants.EventNames.WorkspaceActions.ViewOrchestration,
          { workspaceId: workspace.id }
        );
        setPanelType(WorkspacePanelType.Orchestration);
        setShowPanel(true);
        break;
      case 'aadApplications':
        logger.event(
          LensTelemetryConstants.EventNames.WorkspaceActions
            .ViewAadApplications,
          { workspaceId: workspace.id }
        );
        setPanelType(WorkspacePanelType.AadAppsList);
        setShowPanel(true);
        break;
      case 'history':
        logger.event(
          LensTelemetryConstants.EventNames.WorkspaceActions.ViewHistory,
          { workspaceId: workspace.id }
        );
        setPanelType(WorkspacePanelType.History);
        setShowPanel(true);
        break;
      case 'publish':
        logger.event(
          LensTelemetryConstants.EventNames.WorkspaceActions
            .MigrateWorkspaceToAGC,
          { workspaceId: workspace.id }
        );
        setPanelType(WorkspacePanelType.Publish);
        setShowPanel(true);
        break;
      case 'exportParams':
        logger.event(
          LensTelemetryConstants.EventNames.WorkspaceActions
            .ExportWorkspaceParameters,
          { workspaceId: workspace.id }
        );
        exportWorkspaceParameters(workspace.id);
        break;
      case 'delete':
        logger.event(
          LensTelemetryConstants.EventNames.WorkspaceActions.Delete,
          { workspaceId: workspace.id }
        );
        setPanelType(WorkspacePanelType.Delete);
        setShowPanel(true);
        break;
      case 'migrate':
        setPanelType(WorkspacePanelType.Migration);
        setShowPanel(true);
        break;
      case 'azureManagedGrafana':
        setPanelType(WorkspacePanelType.Grafana);
        setShowPanel(true);
        break;
      case 'manageObjects':
        setPanelType(WorkspacePanelType.Objects);
        setShowPanel(true);
        break;
      case 'viewAccess':
        setPanelType(WorkspacePanelType.Access);
        setShowPanel(true);
        break;
      case 'miscellaneous':
        setPanelType(WorkspacePanelType.Miscellaneous);
        setShowPanel(true);
        break;
      default:
      // Do nothing. Do not throw an error, because not all actions should be processed here (some are processed but DataGridBlade)
    }
  };

  const listSelection = new Selection({
    onSelectionChanged: () => {
      props.setShowLoadingMsg(false);
      props.setAccessMessages(undefined);
      const selected = listSelection.getSelection();
      if (selected.length > 0) {
        setSelectedWsId((selected[0] as WorkspaceItem).workspaceId);
        setShowActions(false);
        setSelected(true);
      } else {
        setSelected(false);
        setShowActions(false);
      }
    },
  });

  return (
    <Stack verticalFill>
      <Stack.Item grow shrink={false}>
        <Stack
          tokens={{
            childrenGap: '5',
          }}
          verticalFill
        >
          <Stack.Item
            grow
            styles={{
              root: {
                background: 'white',
                menubar: { background: 'white' },
              },
            }}
          >
            <DataGridBlade
              gridSettings={{
                className: 'workspace-management',
                idField: 'workspaceId',
                columns: columns,
                items: filteredItems.favoritesAndRecents.concat(
                  filteredItems.all
                ),
                groups: groups,
                selectionMode: SelectionMode.single,
                loading: workspaceListStatus === WorkspaceListStatus.Fetching,
                selection: listSelection,
              }}
              bladeSettings={{
                contentAriaLabel: 'Workspace Management List',
                scrollable: true,
              }}
              toolbarSettings={{
                items: toolbarItems,
                onToolbarAction: toolbarActionHandler,
              }}
              filterSettings={{
                // searchExpression: search,
                searchPlaceholder: 'Search across all workspaces on Enter',
                filterOptions: [],
                filterSelections: filterSelections, // should have the fields/options
                // filterOptions: updatedOptions,
                // filterSelections,
                //onSearchChange: searchHandler, // todo use debounced version
                onSearch: (value) => {
                  setSearch({
                    searchString: value || '',
                  });
                },
                // onFilterSelectionChange,
              }}
            />
          </Stack.Item>
        </Stack>
        <WorkspacePanelManager
          workspaceId={selectedWsId}
          openQuickSwitchPanel={props.openQuickSwitchPanel}
          closeQuickSwitchPanel={props.closeQuickSwitchPanel}
          workspace={workspace}
          create={createWorkspace}
          panelType={panelType}
          show={showPanel}
          openSelectedPanel={() => setShowPanel(true)}
          closeSelectedPanel={() => setShowPanel(false)}
        />
      </Stack.Item>
    </Stack>
  );
}
