import { ADFSelector } from 'features/workspaces/components/editDataConnection/dataSourceComponents/adfSelector';
import { SelectorProps } from 'features/workspaces/components/editDataConnection/editDataConnectionHelper';
import {
  AdfDataSource,
  ConnectionDataSourceType,
} from 'features/workspaces/models/project';
import { adfClient } from '../azureDataFactoryClient';
import { registerDataSourcePlugin } from '../registry';
import { DataSourcePlugin } from './dataSourcePlugin';

class AdfPlugin extends DataSourcePlugin {
  public dataSourceFormComponent(selectorProps: SelectorProps): JSX.Element {
    return (
      <ADFSelector
        workspace={selectorProps.workspace}
        state={selectorProps.state}
        setState={selectorProps.setState}
        customNameFilled={selectorProps.customNameFilled}
      />
    );
  }

  public generatedName(state: AdfDataSource) {
    return state && state.factory ? 'AzureDataFactory.' + state.factory : '';
  }
}

registerDataSourcePlugin(
  ConnectionDataSourceType.AzureDataFactory,
  new AdfPlugin(ConnectionDataSourceType.AzureDataFactory, adfClient)
);
