import { Client } from '@microsoft/microsoft-graph-client';
import { AuthCodeMSALBrowserAuthenticationProvider } from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser';
import { InteractionType } from '@azure/msal-browser';
import constants from './constants';

const msalInstance = window.msalInstance;
const account = msalInstance.getActiveAccount();
const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(
  msalInstance,
  {
    account, // the AccountInfo instance to acquire the token for
    scopes: [
      'User.ReadBasic.All',
      'GroupMember.Read.All',
      'Application.Read.All',
    ],
    interactionType: InteractionType.Popup,
  }
);

// Initialize the Graph client
const graphClient = Client.initWithMiddleware({ authProvider });

const MAX_OUTPUT_PER_PERSONA_TYPE = 5;
export interface Suggestion {
  displayName: string;
  id: string;
  appId?: string; // just for applications
  userPrincipalName?: string; // just for users
}
export interface MsalResponse {
  context?: string;
  link?: string;
  value: Suggestion[];
}

export const getUserInfo = async (): Promise<any> => {
  return await graphClient.api('/me').get();
};

export async function getPeople(filter: string): Promise<MsalResponse[]> {
  const users = await graphClient
    .api('/users')
    .query(
      "$filter=(startswith(userPrincipalName,'" +
        filter +
        "') or startswith(displayName,'" +
        filter +
        "') or startswith(mail,'" +
        filter +
        "')) and accountEnabled eq true"
    )
    .top(MAX_OUTPUT_PER_PERSONA_TYPE)
    .get();

  const groups = await graphClient
    .api('/groups')
    .query(
      "$filter=(startswith(mailNickname,'" +
        filter +
        "') or startswith(displayName,'" +
        filter +
        "')) and securityEnabled eq true"
    )
    .top(MAX_OUTPUT_PER_PERSONA_TYPE)
    .get();

  const applications = await graphClient
    .api('/applications')
    .query("$filter=startswith(displayName, '" + filter + "')")
    .top(MAX_OUTPUT_PER_PERSONA_TYPE)
    .get();
  return Promise.all([users, groups, applications]);
}

export async function getUserGroupApp(filter: string) {
  const users = await graphClient
    .api('/users')
    .query(
      "$filter=(startswith(userPrincipalName,'" +
        filter +
        "') or startswith(displayName,'" +
        filter +
        "') or startswith(mail,'" +
        filter +
        "')) and accountEnabled eq true"
    )
    .top(MAX_OUTPUT_PER_PERSONA_TYPE)
    .get();

  const groups = await graphClient
    .api('/groups')
    .query(
      "$filter=(startswith(mailNickname,'" +
        filter +
        "') or startswith(displayName,'" +
        filter +
        "')) and securityEnabled eq true"
    )
    .top(MAX_OUTPUT_PER_PERSONA_TYPE)
    .get();

  const guidPattern =
    /^[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}$/;
  const guidflg = guidPattern.test(filter);
  let ftlr = "$filter=startswith(displayName, '" + filter + "')";
  if (guidflg) {
    ftlr = ftlr + " or appId eq '" + filter + "'";
  }

  const applications = await graphClient
    .api('/applications')
    .query(ftlr)
    .top(MAX_OUTPUT_PER_PERSONA_TYPE)
    .get();
  return { User: users, Group: groups, App: applications };
}

export async function getMultipleApplicationNames(
  appIds: string[]
): Promise<MsalResponse[]> {
  let promises: Promise<MsalResponse>[] = [];
  if (appIds.length > 0) {
    appIds.forEach((appId) => {
      promises.push(
        graphClient
          .api('/serviceprincipals')
          .query("$filter=appId eq '" + appId + "'")
          .get()
          .catch((error) => {
            const errorResult: MsalResponse = {
              value: [
                {
                  displayName: 'Invalid App',
                  id: 'Please removed this invalid app',
                },
              ],
            };
            return errorResult;
          })
      );
    });

    return Promise.all(promises);
  } else {
    return [
      {
        value: [],
      },
    ];
  }
}

export async function getMultipleGroupNames(
  groupIds: string[]
): Promise<MsalResponse[]> {
  let promises: Promise<MsalResponse>[] = [];
  if (groupIds.length > 0) {
    groupIds.forEach((groupId) => {
      promises.push(
        graphClient
          .api('/groups')
          .query("$filter=id eq '" + groupId + "'")
          .get()
          .catch((error) => {
            const errorResult: MsalResponse = {
              value: [
                {
                  displayName: 'Invalid Group',
                  id: 'Please removed this invalid group',
                },
              ],
            };
            return errorResult;
          })
      );
    });

    return Promise.all(promises);
  } else {
    return [
      {
        value: [],
      },
    ];
  }
}

export async function getMultipleUserAliases(
  userAliases: string[]
): Promise<MsalResponse[]> {
  if (userAliases.length > 0) {
    let guids: string[] = [];
    let aliases: string[] = [];
    let promises: Promise<MsalResponse>[] = [];

    //separate the user aliases that are stores as guids and userPrincipalNames
    userAliases.forEach((alias) => {
      if (alias.match(constants.GuidStringPattern)) {
        guids.push(alias);
      } else {
        aliases.push(alias);
      }
    });

    if (guids.length > 0) {
      guids.forEach((guid) => {
        promises.push(
          graphClient
            .api('/users')
            .query("$filter= id eq '" + guid + "'")
            .get()
            .catch((error) => {
              const errorResult: MsalResponse = {
                value: [
                  {
                    displayName: 'Invalid User',
                    id: 'Please removed this invalid user',
                  },
                ],
              };
              return errorResult;
            })
        );
      });
    }
    if (aliases.length > 0) {
      aliases.forEach((alias) => {
        promises.push(
          graphClient
            .api('/users')
            .query("$filter=startswith(userPrincipalName,'" + alias + "@')")
            .get()
            .catch((error) => {
              const errorResult: MsalResponse = {
                value: [
                  {
                    displayName: 'Invalid User',
                    id: 'Please removed this invalid user',
                  },
                ],
              };
              return errorResult;
            })
        );
      });
    }

    return Promise.all(promises);
  } else {
    return [
      {
        value: [],
      },
    ];
  }
}

export default graphClient;
