import {
  Breadcrumb,
  IBreadcrumbItem,
  Label,
  MessageBar,
  MessageBarType,
  Panel,
  PanelType,
  Spinner,
  SpinnerSize,
  Stack,
} from '@fluentui/react';
import { selectUserprofile } from 'features/userprofile/userprofileSlice';
import {
  dataConnectionParamMapping,
  dataSourceParamMapping,
} from '../models/workspace';
import { panelStyles } from './editWorkspace/workspaceStyles';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ElxPrimaryButton, ElxSecondaryButton } from '@elixir/components';
import { useLensShellTheme } from 'features/shell/lensShellStyles';
import DataConnectionMappingParams from './editWorkspace/orchestrationSettings/parameters/dataConnectionMappingParams';
import { workspacesApi } from '../api/workspacesApi';
import notifier from 'utils/notifier';
import { groupBorder } from 'features/orchestrator/components/JobActivities/JobAuthorStyles';
import { JobAccordian } from 'features/orchestrator/utils/jobAccordian';
import DataSourceMappingParams from './editWorkspace/orchestrationSettings/parameters/dataSourceMappingParams';

interface MiscellaneousPanelProps {
  workspaceId: string;
  show: boolean;
  openPanel: () => void;
  dismissPanel: () => void;
}

const MiscellaneousPanel = (props: MiscellaneousPanelProps) => {
  const { workspaceId, show, dismissPanel } = props;
  const userprofile = useSelector(selectUserprofile);
  const theme = useLensShellTheme();
  const items: IBreadcrumbItem[] = [
    {
      text: 'Workspace management',
      key: 'workspaceManagement',
      onClick: () => {
        dismissPanel();
      },
    },
    {
      text: 'Miscellaneous Config',
      key: 'MiscellaneousConfig',
    },
  ];

  const [dcParams, setDcParams] = useState<dataConnectionParamMapping[]>([]);
  const [dsParams, setDsParams] = useState<dataSourceParamMapping[]>([]);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [init, setInit] = useState<boolean>(false);

  let updatedDcParams: dataConnectionParamMapping[];
  let updatedDsParams: dataSourceParamMapping[];

  const agcCloud = (window as any).ASSET_IMPORT_ENABLED === 'true';
  const showMiscellaneous =
    (agcCloud &&
      userprofile.FeatureSettings.find((f) => f.Type === 'PublishToAgc')
        ?.IsEnabled) ||
    false;

  useEffect(() => {
    const fetchData = async () => {
      try {
        setDcParams(
          await workspacesApi.getWorkspaceDataConnectionParams(workspaceId)
        );
        setDsParams(
          await workspacesApi.getWorkspaceDataSourceParams(workspaceId)
        );
        setInit(true);
      } catch (err) {
        let errMsg = 'getWorksapcexxxxParams - failed';
        setErrorMsg(errMsg);
        notifier.error(errMsg);
        setInit(true);
        throw err;
      }
    };
    if (agcCloud) {
      fetchData();
    } else {
      setInit(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  updatedDcParams = dcParams;
  updatedDsParams = dsParams;

  return (
    <Panel
      type={PanelType.large}
      isOpen={show}
      onDismiss={dismissPanel}
      isFooterAtBottom={true}
      styles={{
        ...panelStyles,
        ...{
          footerInner: { float: 'right' },
          footer: {
            borderTop: '1px solid lightgrey',
            backgroundColor: theme.palette.neutralLighter,
          },
          content: { background: 'white', padding: '0px' },
          main: { background: 'white !important' },
        },
      }}
      onRenderHeader={() => {
        return (
          <Breadcrumb
            items={items}
            maxDisplayedItems={2}
            ariaLabel="Miscellaneous"
            styles={{ root: { fontSize: '14px' } }}
          />
        );
      }}
      onRenderFooterContent={() => (
        <>
          <ElxSecondaryButton
            text={'Close'}
            onClick={dismissPanel}
            styles={{ root: { marginLeft: '6px', marginRight: '6px' } }}
          ></ElxSecondaryButton>
          <ElxPrimaryButton
            text={'Save'}
            onClick={async () => {
              console.log('implement save handler here...');
              try {
                await workspacesApi.postWorkspaceDataConnectionParams(
                  workspaceId,
                  { params: updatedDcParams }
                );
                await workspacesApi.postWorkspaceDataSourceParams(workspaceId, {
                  params: updatedDsParams,
                });
                dismissPanel();
              } catch (err) {
                setErrorMsg('Could not save low side parameter mapping');
                notifier.error('Could not save low side parameter mapping');
              }
            }}
            disabled={!showMiscellaneous}
          ></ElxPrimaryButton>
        </>
      )}
    >
      {!showMiscellaneous && (
        <>
          <Stack tokens={{ childrenGap: 8, padding: 16 }}>
            <p>
              Environment is not in Air-Gapped cloud. Nothing to configure! or
              User has not opted into 'Publish to AGC' feature!
            </p>
          </Stack>
        </>
      )}
      {showMiscellaneous && (
        <>
          {init && (
            <Stack tokens={{ childrenGap: 16, padding: 16 }}>
              {errorMsg && (
                <MessageBar
                  messageBarType={MessageBarType.error}
                  isMultiline={true}
                >
                  {errorMsg}
                </MessageBar>
              )}
              <Stack.Item className={groupBorder}>
                <JobAccordian
                  isOpen={false}
                  text={'Data Connection Parameter Mapping'}
                  hint={
                    'Data Connection Parameter Mapping - that replaces app id and the tenant to the configured local environment'
                  }
                >
                  <DataConnectionMappingParams
                    parameters={dcParams}
                    updatedDataConnectionParamMapping={(
                      params: dataConnectionParamMapping[]
                    ): void => {
                      updatedDcParams = params;
                    }}
                  ></DataConnectionMappingParams>
                </JobAccordian>
              </Stack.Item>

              <Stack.Item className={groupBorder}>
                <JobAccordian
                  isOpen={false}
                  text={'Data Source Parameter Mapping'}
                  hint={
                    'Data Source Parameter Mapping - that replaces data source settings to the configured local environment'
                  }
                >
                  <DataSourceMappingParams
                    parameters={dsParams}
                    updatedDataSourceParamMapping={(
                      params: dataSourceParamMapping[]
                    ): void => {
                      updatedDsParams = params;
                    }}
                  ></DataSourceMappingParams>
                </JobAccordian>
              </Stack.Item>
            </Stack>
          )}
        </>
      )}
      {!init && (
        <Stack
          horizontalAlign="center"
          tokens={{ childrenGap: 24, padding: 100 }}
        >
          <Spinner size={SpinnerSize.large} />
          <Label>Loading data connection parameter mapping...</Label>
        </Stack>
      )}
    </Panel>
  );
};

export default MiscellaneousPanel;
