import { LeWebApi } from 'api/leWebApi';
import {
  GrafanaOnboardingData,
  GrafanaOnboardingDataResponse,
} from '../models/grafanaOnboardingData';

export class GrafanaOnboardingDataApi extends LeWebApi {
  public getLinkedGrafanaInstances = async (
    workspaceId: string
  ): Promise<GrafanaOnboardingData[]> => {
    return await this.getEntity<GrafanaOnboardingData[]>(
      '/grafanaOnboarding/getLinkedGrafanaInstances?workspaceId=' + workspaceId
    );
  };

  public saveGrafanaInstances = async (
    grafanaInstanceList: GrafanaOnboardingData[]
  ): Promise<GrafanaOnboardingData[]> => {
    return await this.putEntity<GrafanaOnboardingData[]>(
      '/grafanaOnboarding/saveGrafanaInstances',
      grafanaInstanceList
    );
  };

  public unlinkGrafanaInstances = async (
    grafanaInstanceList: GrafanaOnboardingData[]
  ): Promise<GrafanaOnboardingDataResponse> => {
    return await this.deleteEntity<GrafanaOnboardingDataResponse>(
      '/grafanaOnboarding/unlinkGrafanaInstances',
      {
        data: grafanaInstanceList,
      }
    );
  };

  public getUsersGrafanaInstances = async (): Promise<
    GrafanaOnboardingData[]
  > => {
    return await this.getEntity<GrafanaOnboardingData[]>(
      '/grafana/getUsersGrafanaInstances'
    );
  };
}

export const grafanaOnboardingDataApi = new GrafanaOnboardingDataApi();

export default grafanaOnboardingDataApi;
