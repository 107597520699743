import {
  ElxDropdown,
  ElxPanel,
  ElxTextField,
  PanelSize,
} from '@elixir/components';
import { Stack } from '@fluentui/react';
import {
  activityInput,
  activityOutput,
  schema,
} from 'features/orchestrator/models/job';
import { useState } from 'react';
import { orchestratorConstants } from 'utils/constants';
import { LensLabel } from 'utils/lensLabel';

export interface JobSchemaEntityProps {
  endpoint: activityInput | activityOutput;
  onChange: (endpoint: activityInput | activityOutput) => void;
}

export const JobSchemaEntity = (props: JobSchemaEntityProps)  => {
  const { endpoint, onChange } = props;
  endpoint.schema = endpoint.schema || [];

  const [tempObj, setTempObj] = useState<schema>({ name: '', type: '' });
  const [addSchema, setAddSchema] = useState(false);
  return <>{endpoint.schema instanceof Array && endpoint.schema?.map((s) => (
    <>
      <Stack.Item>Header</Stack.Item>
      <Stack.Item>
        <Stack tokens={{ childrenGap: 16, padding: 16 }}></Stack>
      </Stack.Item>
      <Stack.Item>Add Button</Stack.Item>
      {addSchema && (
        <>
          <ElxPanel
            headerText={`Add to Schema - Name,Type`}
            isOpen={true}
            size={PanelSize.medium}
            onDismiss={() => setAddSchema(false)}
            actions={[
              {
                key: `Add to Schema`,
                text: `Add to Schema`,
                primary: true,
                onClick: () => {
                  endpoint!.schema instanceof Array && endpoint!.schema!.push(tempObj);
                  onChange(endpoint);
                  setAddSchema(false);
                },
              },
              {
                key: 'close',
                text: 'Close',
                onClick: () => setAddSchema(false),
              },
            ]}
          >
            <Stack tokens={{ childrenGap: 16, padding: 16 }}>
              <Stack.Item>
                <ElxTextField
                  id={'Column Name'}
                  onRenderLabel={() => {
                    return (
                      <LensLabel
                        labelText={'Column Name'}
                        hintText={`The column name for the schema.`}
                        required={true}
                      ></LensLabel>
                    );
                  }}
                  value={tempObj.name}
                  onChange={(e, val) => {
                    setTempObj({ ...tempObj, name: val! });
                  }}
                ></ElxTextField>
              </Stack.Item>
              <Stack.Item>
                <LensLabel
                  labelText={'Column Type'}
                  hintText={`The column type for the schema.`}
                  required={true}
                ></LensLabel>
                <ElxDropdown
                  options={orchestratorConstants.EndpointSchemaTypes.map(
                    (d: string) => ({
                      key: d,
                      text: d,
                    })
                  )}
                  selectedKey={tempObj.type}
                  onChange={(e, options) => {
                    setTempObj({
                      ...tempObj,
                      type:
                        options?.key.toString() ||
                        orchestratorConstants.EndpointSchemaTypes[0],
                    });
                  }}
                ></ElxDropdown>
              </Stack.Item>
            </Stack>
          </ElxPanel>
        </>
      )}
    </>
  ))}</>;
};
