import LeApi from 'api/leApi';
import { Dataset } from '../models/dataset';

class DatasetApi extends LeApi {
  public getUserDatasets = async (
    metricsStart: string | null = null,
    metricsEnd: string | null = null
  ): Promise<Dataset[]> => {
    let params = {};
    if (metricsStart) {
      params = { ...params, metricsStart };
    }
    if (metricsEnd) {
      params = { ...params, metricsEnd };
    }
    return await this.getEntity<Dataset[]>(`/v1/my/datasets?metrics=true`, {
      params: params,
    });
  };

  public getDatasets = async (
    workspaceId: string,
    metricsStart: string | null = null,
    metricsEnd: string | null = null
  ): Promise<Dataset[]> => {
    let params = {};
    if (metricsStart) {
      params = { ...params, metricsStart };
    }
    if (metricsEnd) {
      params = { ...params, metricsEnd };
    }
    return await this.getEntity<Dataset[]>(
      `/v1/workspaces/${workspaceId}/datasets?metrics=true`,
      { params: params }
    );
  };

  public getDataset = async (
    datasetId: string,
    details: string | null = null,
    metrics: string | null = null,
    metricsStart: string | null = null,
    metricsEnd: string | null = null
  ): Promise<Dataset> => {
    let params = {};
    if (details) {
      params = { ...params, details };
    }
    if (metrics) {
      params = { ...params, metrics };
    }
    if (metricsStart) {
      params = { ...params, metricsStart };
    }
    if (metricsEnd) {
      params = { ...params, metricsEnd };
    }
    return await this.getEntity<Dataset>(`/v1/datasets/${datasetId}`, {
      params: params,
    });
  };

  public createDataset = async (dataset: Dataset): Promise<Dataset> => {
    return await this.postEntity<Dataset, Dataset>(`/v1/datasets`, dataset);
  };

  public updateDataset = async (dataset: Dataset): Promise<Dataset> => {
    return await this.putEntity<Dataset, Dataset>(
      `/v1/datasets/${dataset.id}`,
      dataset
    );
  };

  public deleteDataset = async (datasetId: string): Promise<null> => {
    return await this.deleteEntity<null>(`/v1/datasets/${datasetId}`);
  };

  public getDatasetVersions = async (datasetId: string): Promise<Dataset[]> => {
    return await this.getEntity<Dataset[]>(
      `/v1/datasets/${datasetId}/versions`
    );
  };

  public getDatasetVersion = async (
    datasetId: string,
    version: string
  ): Promise<Dataset> => {
    return await this.getEntity<Dataset>(
      `/v1/datasets/${datasetId}/versions/${version}`
    );
  };

  public changeDatasetServiceId = async (serviceId: string): Promise<null> => {
    return await this.postEntity<null, null>(
      `/v1/datasets/${serviceId}/changeServiceId`,
      null
    );
  };

  public enableDataset = async (datasetId: string): Promise<Dataset> => {
    return await this.postEntity<null, Dataset>(
      `/v1/datasets/${datasetId}/enable`,
      null
    );
  };

  public disableDataset = async (datasetId: string): Promise<Dataset> => {
    return await this.postEntity<null, Dataset>(
      `/v1/datasets/${datasetId}/disable`,
      null
    );
  };

  public restoreDataset = async (datasetId: string): Promise<Dataset> => {
    return await this.postEntity<null, Dataset>(
      `/v1/datasets/${datasetId}/restore`,
      null
    );
  };
}

export const datasetApi = new DatasetApi();
export default datasetApi;
