import { ElxActionButton, ElxIconButton } from "@elixir/components";
import { Stack } from "@fluentui/react";
import { newGuid } from "@microsoft/applicationinsights-core-js";
import { Action, Activity } from "features/orchestrator/models/job";
import { useState } from "react";
import { JobActivityDataValidationAction } from "./JobActivityDataValidationAction";


export interface JobActivityDataValidationActionsProps {
  actions:Action[];
  activity:Activity;
  onChange:(actions:Action[])=>void;
};

export const JobActivityDataValidationActions = (
  props: JobActivityDataValidationActionsProps
) => {
  const { onChange, actions, activity } = props;
  const [editAction, setEditAction] = useState(false);
  const [editIdx, setEditIdx] = useState(-1);

  const action: Action =
    editIdx > -1
      ? { ...actions[editIdx] }
      : {
          type: '',
          id: newGuid(),
          displayName: 'New Action',
          output: { type: '', connectionName: '' },
        };
  return (
    <>
      <Stack tokens={{ childrenGap: 16, padding: 16 }}>
        <Stack.Item>
          {actions.map((action, idx) => (
            <Stack horizontal grow key={idx}>
              <Stack.Item className={'ms-lg8'}>{action.displayName}</Stack.Item>
              <Stack.Item
                className={'ms-lg2'}
                align="end"
                styles={{ root: { cursor: 'pointer' } }}
                onClick={() => {
                  setEditIdx(idx);
                  setEditAction(true);
                }}
              >
                <ElxIconButton
                  iconProps={{
                    iconName: 'edit',
                  }}
                  text={'Edit Action'}
                ></ElxIconButton>
                Edit
              </Stack.Item>
              <Stack.Item
                className={'ms-lg2'}
                align="end"
                styles={{ root: { cursor: 'pointer' } }}
                onClick={() => {
                  onChange([...actions.filter((a, i) => idx !== i)]);
                }}
              >
                <ElxIconButton
                  iconProps={{
                    iconName: 'cancel',
                  }}
                  text={'Remove Action'}
                ></ElxIconButton>
                Remove
              </Stack.Item>
            </Stack>
          ))}
          {!actions?.length && (<span>No Action Configured.</span>)}
        </Stack.Item>
        <Stack.Item>
          <ElxActionButton
            iconProps={{
              iconName: 'Add',
            }}
            text="Add"
            onClick={() => {
              setEditIdx(-1);
              setEditAction(true);
            }}
          />
          <ElxActionButton />
        </Stack.Item>
      </Stack>
      {editAction && (
        <JobActivityDataValidationAction
          action={action}
          activity={activity}
          onChange={(action: Action) => {
            if (editIdx > -1) {
              actions[editIdx] = action;
            } else {
              actions.push(action);
            }
            onChange(actions);
            setEditIdx(-1);
            setEditAction(false);
          }}
          onCancel={() => {
            setEditIdx(-1);
            setEditAction(false);
          }}
        ></JobActivityDataValidationAction>
      )}
    </>
  );
};
