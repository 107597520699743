import { Stack } from '@fluentui/react';

interface VirtualizeListWrapperProps {
  height: number | string;
  children: JSX.Element;
}

/**
 * Use this wrapper to virtualize lists in panels and modals
 * @param props childrent -  the list you want to virtualize
 * height - the height of the list component
 * @returns
 */
const VirtualizeListWrapper = (props: VirtualizeListWrapperProps) => {
  // see this for the reason for implementation: https://github.com/microsoft/fluentui/issues/8548#issuecomment-479144863
  return (
    <Stack
      data-is-scrollable={true}
      styles={{
        root: {
          height: props.height, // For some reason the cal does not work but keeping it here incase it might be needed in the future 'calc(100% - ' + props.height + 'px)',
          overflowY: 'scroll',
        },
      }}
    >
      {props.children}
    </Stack>
  );
};

export default VirtualizeListWrapper;
