import { SelectionMode, Stack } from '@fluentui/react';
import {
  ElxTableContainer,
  ElxTextField,
  IElxColumn,
  SearchBoxSize,
} from '@elixir/components';
import { useEffect, useState } from 'react';
import { armClient } from 'utils/armClient';
import DatasourceMetadata from 'utils/datasourceMetadataService';
import { LensLabel } from 'utils/lensLabel';
import { classNames } from '../../workspacesStyles';
import { SelectorProps } from '../editDataConnectionHelper';
import { _ } from 'utils/sharedLibs';
import notifier from 'utils/notifier';
import { OptionSwitch } from './selectorComponentsHelper';
import { DataConnectionOptionsTableContainerProps } from '../../editWorkspace/workspaceStyles';

interface LogAnalyticsWorkspace {
  omsLogAnalyticsWorkspace: string;
  azureResourceGroup: string;
  azureSubscription: string;
  azureSubscriptionName: string;
}

interface LaSubscription {
  authorizationSource: string;
  displayName: string;
  id: string;
  state: string;
  subscriptionId: string;
  subscriptionPolicies: {
    locationPlacementId: string;
    quotaId: string;
    spendingLimit: string;
  };
}

interface LaResource {
  id: string;
  location: string;
  name: string;
  type: string;
}
const LogAnalyticsWorkspaceSelector = (props: SelectorProps) => {
  let selectedResource: LogAnalyticsWorkspace;
  let dataSource = { ...props.state };

  dataSource.cluster = (props.state.cluster || '').toLowerCase();

  function addUserEnteredWorkspaceId(workspaceId: string) {
    let enteredResource = {
      azureWorkspaceId: workspaceId,
    };
    if (!_.isNil(enteredResource.azureWorkspaceId)) {
      // For user entered Workspace id - copy workspaceid to cluster.
      props.state.workspaceId = enteredResource.azureWorkspaceId;
      props.state.cluster = props.state.workspaceId;
      return;
    }
  }

  // Sets the data source to the selected or entered resource.
  function setDataSource() {
    let resource = selectedResource;
    if (
      !resource ||
      !resource.azureSubscription ||
      !resource.azureResourceGroup ||
      !resource.omsLogAnalyticsWorkspace
    ) {
      props.setState({ ...dataSource });
      return;
    }

    // The data source id is the ARM URL (without the scheme), which is unique among all data sources and types.
    dataSource.cluster = _.template(
      window.startUpConfig.serviceEndpoints.azureResourceManagerHostname +
        'subscriptions/<%=subscription%>/resourcegroups/<%=resourceGroup%>/providers/microsoft.operationalinsights/workspaces/<%=workspace%>'
    )({
      subscription: resource.azureSubscription.trim(),
      resourceGroup: resource.azureResourceGroup.trim(),
      workspace: resource.omsLogAnalyticsWorkspace.trim(),
    }).toLowerCase();
    props.setState(dataSource);

    const datasourceMetadataService = new DatasourceMetadata();
    datasourceMetadataService
      .getAppId(dataSource.type, dataSource.cluster)
      .then(function (workspaceId) {
        props.setState({ ...dataSource, workspace: workspaceId });
      })
      .catch((error) => {
        notifier.error(error);
      });
  }

  const selectResource = (resource: LogAnalyticsWorkspace) => {
    selectedResource = resource;
    setDataSource();
  };

  const [inputOption, setInputOption] = useState(0);
  const [workspaceId, setWorkspaceId] = useState(dataSource.workspaceId);
  const [workspaceIds, setWorkspaceIds] = useState<LogAnalyticsWorkspace[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (inputOption === 1 && workspaceIds.length === 0) {
      let subscriptions: LaSubscription[] = [];

      armClient
        .getSubscriptions()
        .then((subs: LaSubscription[]) => {
          subscriptions = subs;
          if (!subscriptions) {
            return Promise.reject('Could not get your Azure subscriptions.');
          }

          let subscriptionIds = _.sortBy(
            _.map(subscriptions, 'subscriptionId')
          );
          return armClient.getResourcesBySubscriptionsAndType(
            subscriptionIds,
            armClient.resourceTypes.MicrosoftOperationalInsightsWorkspaces
          );
        })
        .then((resources: LaResource[]) => {
          let workspaceResources: LogAnalyticsWorkspace[] = _.map(
            resources,
            function (resource) {
              // id is "/subscriptions/<subscriptionId>/resourceGroups/<resourceGroup>/providers/microsoft.insights/components/<omsLogAnalyticsWorkspace>"
              let id = _.get(resource, 'id');
              let tokens = id.match(
                /^\/subscriptions\/([^/]*)\/resourceGroups\/([^/]*)\//i
              );
              let subscriptionId: string = _.nth(tokens, 1) || '';
              let subscription = _.find(subscriptions, {
                subscriptionId: subscriptionId,
              });
              return {
                azureSubscription: subscriptionId || '',
                azureSubscriptionName: _.get(subscription, 'displayName') || '',
                azureResourceGroup: _.nth(tokens, 2) || '',
                omsLogAnalyticsWorkspace: _.get(resource, 'name') || '',
              };
            }
          );
          setWorkspaceIds(
            _.sortBy(workspaceResources, ['omsLogAnalyticsWorkspace'])
          );
          setIsLoading(false);
        })
        .catch((err: string) => {
          notifier.error(
            err +
              'Could not load the OMS Log Analytics workspaces. Please try again, or enter the OMS Log Analytics workspace.'
          );
        });
    }
  }, [workspaceIds.length, inputOption]);

  const columns: IElxColumn[] = [
    {
      minWidth: 95,
      maxWidth: 95,
      isResizable: true,
      isMultiline: true,
      className: classNames.tableStyles,
      key: 'WorkspaceName',
      name: 'Name',
      fieldName: 'omsLogAnalyticsWorkspace',
    },
    {
      minWidth: 95,
      maxWidth: 95,
      isResizable: true,
      isMultiline: true,
      className: classNames.tableStyles,
      key: 'ResourceGroup',
      name: 'Resource Group',
      fieldName: 'azureResourceGroup',
    },
    {
      minWidth: 95,
      maxWidth: 95,
      isResizable: true,
      isMultiline: true,
      className: classNames.tableStyles,
      key: 'SubscriptionName',
      name: 'Subscription',
      fieldName: 'azureSubscriptionName',
    },
    {
      minWidth: 95,
      maxWidth: 95,
      isResizable: true,
      isMultiline: true,
      className: classNames.tableStyles,
      key: 'SubscriptionId',
      name: 'Subscription Id',
      fieldName: 'azureSubscription',
    },
  ];

  return (
    <Stack tokens={{ childrenGap: 16 }}>
      <LensLabel
        labelText="Log Analytics Workspace"
        hintText={'Enter or select an Log Analytics Workspace.'}
        required={true}
      ></LensLabel>

      <OptionSwitch
        numOfOptions={2}
        buttonTitles={[
          'Enter Workspace Id',
          'Select Log Analytics Workspace From List',
        ]}
        components={[
          <Stack.Item>
            <ElxTextField
              value={workspaceId}
              placeholder="Enter Log Analytics Workspace Id"
              onChange={(_, newValue) => {
                setWorkspaceId(newValue || '');
                addUserEnteredWorkspaceId(newValue || '');
                props.setState({
                  ...dataSource,
                  workspaceId: newValue || '',
                  cluster: newValue || '',
                });
              }}
            />
          </Stack.Item>,
          <Stack.Item>
            <ElxTableContainer
              containerProps={{
                ...DataConnectionOptionsTableContainerProps,
                isLoading: isLoading,
              }}
              tableProps={{
                columns: columns,
                selectionMode: SelectionMode.single,
                items: workspaceIds,
                onSelectionChanged: (selection) => {
                  const selected = selection[0] as LogAnalyticsWorkspace;
                  selectResource(selected);
                  props.setState(dataSource);
                },
                styles: {
                  root: {
                    '.ms-DetailsHeader-cellName': {
                      fontWeight: '500 !important',
                      fontSize: '12px !important',
                    },
                  },
                },
                selectedItems: [], // TODO set selected Items when editing
                setKey: 'omsLogAnalyticsWorkspace',
              }}
              searchBoxProps={{
                size: SearchBoxSize.Small,
              }}
            />
          </Stack.Item>,
        ]}
        setInputOption={setInputOption}
      />
    </Stack>
  );
};

export default LogAnalyticsWorkspaceSelector;
