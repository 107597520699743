import { TopCardProps } from './topCard';

export const topCardData: TopCardProps[] = [
  {
    key: '1',
    mainLabel: 'Watch videos',
    secondaryLabel: 'Watch past conference presentations and videos',
    iconName: 'videoIcon',
    iconText: 'Watch Now',
    link: 'https://eng.ms/docs/products/genevaanalytics/lensexplorer/webinarsandvideos/webinarsandvideos',
  },
  {
    key: '2',
    mainLabel: 'Office Hours',
    secondaryLabel: 'Attend our weekly office hours (Thursdays 2-3PM PST). ',
    iconName: 'supportIcon',
    iconText: 'Office Hours Link',
    link: 'https://aka.ms/lenssuiteofficehours',
  },
  {
    key: '3',
    mainLabel: 'Sample Dashboard',
    secondaryLabel: 'Checkout our Sample Dashboard',
    iconName: 'sampleDashboardIcon',
    iconText: 'Sample Dashboard Link',
    link:
      window.location.origin +
      '/#/dashboard/Sample%20Visualizations?newDashFromMenu=false&isSample=true&isVersion=0&isHistory=0&_g=()',
  },
];
