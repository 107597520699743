import { Label, Stack } from '@fluentui/react';
import { ElxIconButton } from '@elixir/components';
import React from 'react';
import { JobInstance, JobInstanceStatus } from '../models/job';

export const paramHeader = (
  <>
    <Label>Override Parameters</Label>
    <>
      <Stack horizontal>
        <Stack.Item className="ms-lg6" style={{ fontWeight: 'bold' }}>
          <div>Name</div>
        </Stack.Item>
        <Stack.Item className="ms-lg6" style={{ fontWeight: 'bold' }}>
          <div>Value</div>
        </Stack.Item>
      </Stack>
    </>
  </>
);

export const getJobInstanceStatus = (instance: JobInstance) => {
  switch (instance.status) {
    case JobInstanceStatus.SUCCESS:
      return (
        <>
          <ElxIconButton
            styles={{
              icon: {
                color: 'green',
                fontSize: '10px',
                margin: '0px 2px',
              },
              root: {
                width: '20%',
                paddingLeft: '0px',
                paddingRight: '0px',
              },
            }}
            text={instance.status}
            key={instance.id}
            iconProps={{ iconName: 'SkypeCircleCheck' }}
          ></ElxIconButton>
          <span>{instance.status}</span>
        </>
      );
    case JobInstanceStatus.FAILED:
      return (
        <>
          {' '}
          <ElxIconButton
            styles={{
              icon: {
                color: 'red',
                fontSize: '10px',
                margin: '0px 2px',
              },
              root: {
                width: '20%',
                paddingLeft: '0px',
                paddingRight: '0px',
              },
            }}
            text={instance.status}
            key={instance.id}
            iconProps={{ iconName: 'StatusErrorFull' }}
          ></ElxIconButton>
          <span>{instance.status}</span>
        </>
      );
    default:
      return (
        <>
          {' '}
          <ElxIconButton
            styles={{
              icon: {
                color: 'grey',
                fontSize: '10px',
                margin: '0px 2px',
              },
              root: {
                width: '20%',
                paddingLeft: '0px',
                paddingRight: '0px',
              },
            }}
            text={instance.status}
            key={instance.id}
            iconProps={{ iconName: 'CircleShapeSolid' }}
          ></ElxIconButton>
          <span>{instance.status}</span>
        </>
      );
  }
};
