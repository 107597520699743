import { Breadcrumb, IBreadcrumbItem, Panel, PanelType } from '@fluentui/react';
import { useDispatch, useSelector } from 'react-redux';
import { breadCrumbStyles, panelStyles } from '../workspaceStyles';
import { AppDispatch, RootState } from 'app/lensShellUtility';
import { selectWorkspaceById } from 'features/workspaces/workspaceListSlice';
import HistoryTable from './historyTable';
import {
  getWorkspaceHistory,
  selectWorkspaceHistory,
  selectWorkspaceHistoryStatus,
} from 'features/workspaces/workspaceSlice';
import { useEffect } from 'react';
import { WorkspaceStatus } from 'features/workspaces/models/workspace';

interface HistoryPanelProps {
  workspaceId: string;
  show: boolean;
  dismissPanel: () => void;
  openPanel: () => void;
  closeQuickSwitchPanel: () => void;
}

const HistoryPanel = (props: HistoryPanelProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const workspace = useSelector((state: RootState) =>
    selectWorkspaceById(state, props.workspaceId)
  );
  const history = useSelector(selectWorkspaceHistory);
  const historyStatus = useSelector(selectWorkspaceHistoryStatus);

  useEffect(() => {
    dispatch(getWorkspaceHistory(props.workspaceId));
    // commenting this out for now because jest is throwing an error for constants' values not being found
    // logger.event(
    //   LensTelemetryConstants.EventNames.WorkspaceActions.ViewHistory,
    //   { workspaceViewed: props.workspaceId }
    // );
  }, [dispatch, props.workspaceId]);

  const items: IBreadcrumbItem[] = [
    {
      text: 'Workspace management',
      key: 'workspaceManagement',
      onClick: () => {
        props.dismissPanel();
      },
    },
    {
      text: workspace?.name + ' - History',
      key: 'workspaceHistory',
      onClick: () => {},
    },
  ];
  return (
    <Panel
      type={PanelType.large}
      isOpen={props.show}
      onDismiss={props.closeQuickSwitchPanel}
      styles={panelStyles}
      isFooterAtBottom={true}
      onRenderHeader={() => {
        return (
          <Breadcrumb
            items={items}
            maxDisplayedItems={2}
            ariaLabel="Workspace data connection breadcrumb with items rendered as buttons"
            overflowAriaLabel="More links"
            styles={breadCrumbStyles}
          />
        );
      }}
    >
      <HistoryTable
        history={history}
        workspaceId={props.workspaceId}
        dismissHistoryPanel={props.dismissPanel}
        closeQuickSwitchPanel={props.closeQuickSwitchPanel}
        workspaceName={workspace?.name}
        isLoading={historyStatus !== WorkspaceStatus.Loaded}
      />
    </Panel>
  );
};

export default HistoryPanel;
