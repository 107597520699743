import { SelectionMode, Stack } from '@fluentui/react';
import {
  ElxTableContainer,
  ElxTextField,
  IElxColumn,
  SearchBoxSize,
} from '@elixir/components';
import { useEffect, useState } from 'react';
import { armClient } from 'utils/armClient';
import DatasourceMetadata from 'utils/datasourceMetadataService';
import { LensLabel } from 'utils/lensLabel';
import notifier from 'utils/notifier';
import { _ } from 'utils/sharedLibs';
import { DataConnectionOptionsTableContainerProps } from '../../editWorkspace/workspaceStyles';
import { classNames } from '../../workspacesStyles';
import { SelectorProps } from '../editDataConnectionHelper';
import { OptionSwitch } from './selectorComponentsHelper';

interface AppInsightsSubscription {
  applicationInsightsSubscription: string;
  azureResourceGroup: string;
  azureSubscription: string;
  azureSubscriptionName: string;
  id: string;
}

const AppInsightsAppSelector = (props: SelectorProps) => {
  let dataSource = { ...props.state };
  dataSource.cluster = (props.state.cluster || '').toLowerCase();

  //#region Public Members
  let selectedResource: AppInsightsSubscription;
  //#endregion Public Members

  // Sets the data source to the selected or entered resource.
  const setDataSource = () => {
    let resource = selectedResource;

    if (
      !resource ||
      !resource.azureSubscription ||
      !resource.azureResourceGroup ||
      !resource.applicationInsightsSubscription
    ) {
      dataSource.cluster = '';
      props.setState({ ...dataSource, cluster: '' });
      return;
    }

    // The data source id is the ARM URL (without the scheme), which is unique among all data sources and types.
    dataSource.cluster = _.template(
      window.startUpConfig.serviceEndpoints.azureResourceManagerHostname +
        'subscriptions/<%=subscription%>/resourcegroups/<%=resourceGroup%>/providers/microsoft.insights/components/<%=component%>'
    )({
      subscription: resource.azureSubscription.trim(),
      resourceGroup: resource.azureResourceGroup.trim(),
      component: resource.applicationInsightsSubscription.trim(),
    }).toLowerCase();
    props.setState(dataSource);

    const datasourceMetadataService = new DatasourceMetadata();
    datasourceMetadataService
      .getAppId(dataSource.type, dataSource.cluster)
      .then(function (appId) {
        props.setState({ ...dataSource, appId: appId });
      })
      .catch((error) => {
        notifier.error(error);
      });
  };

  const selectResource = (resource: AppInsightsSubscription) => {
    selectedResource = resource;
    setDataSource();
  };

  const addUserEnteredApplicationId = (ApplicationId: string) => {
    let enteredResource = {
      azureApplicationId: ApplicationId,
    };

    if (enteredResource.azureApplicationId !== '') {
      // For user entered application id - copy appId to cluster.
      dataSource.appId = enteredResource.azureApplicationId;
      dataSource.cluster = dataSource.appId;
    }
  };

  const [inputOption, setInputOption] = useState(0);
  const [appIds, setAppIds] = useState<AppInsightsSubscription[]>([]);

  const [isLoading, setIsLoading] = useState(true);
  const [appId, setAppId] = useState(dataSource.appId);

  const columns: IElxColumn[] = [
    {
      minWidth: 160,
      maxWidth: 160,
      isResizable: true,
      isMultiline: true,
      className: classNames.tableStyles,
      key: 'AppInsightsAccountName',
      name: 'App Insights Account Name',
      fieldName: 'applicationInsightsSubscription',
    },
    {
      minWidth: 90,
      maxWidth: 90,
      isResizable: true,
      isMultiline: true,
      className: classNames.tableStyles,
      key: 'ResourceGroup',
      name: 'Resource Group',
      fieldName: 'azureResourceGroup',
    },
    {
      minWidth: 90,
      maxWidth: 90,
      isResizable: true,
      isMultiline: true,
      className: classNames.tableStyles,
      key: 'SubscriptionName',
      name: 'Subscription',
      fieldName: 'azureSubscriptionName',
    },
    {
      minWidth: 90,
      maxWidth: 90,
      isResizable: true,
      isMultiline: true,
      className: classNames.tableStyles,
      key: 'SubscriptionId',
      name: 'Subscription Id',
      fieldName: 'azureSubscription',
    },
  ];

  useEffect(() => {
    if (inputOption === 1 && appIds.length === 0) {
      armClient
        .getApplicationInsightsSubscriptions()
        .then((subscriptions: AppInsightsSubscription[]) => {
          if (!subscriptions) {
            return Promise.reject('Could not get your Azure subscriptions.');
          }
          setAppIds(subscriptions);

          setIsLoading(false);
        });
    }
  }, [inputOption, appIds.length]);

  return (
    <Stack tokens={{ childrenGap: 16 }}>
      <LensLabel
        labelText="Application Insights Subscription"
        hintText={'Enter or select an Application Insights application.'}
        required={true}
      ></LensLabel>
      <OptionSwitch
        numOfOptions={2}
        buttonTitles={[
          'Enter Application Id',
          'Select Application Subscription From List',
        ]}
        components={[
          <Stack.Item>
            <ElxTextField
              value={appId}
              placeholder="Enter Application Insights App Id"
              onChange={(_, newValue) => {
                setAppId(newValue || '');
                addUserEnteredApplicationId(newValue || '');
                props.setState(dataSource);
              }}
            />
          </Stack.Item>,
          <Stack.Item>
            <ElxTableContainer
              containerProps={{
                ...DataConnectionOptionsTableContainerProps,
                isLoading: isLoading,
              }}
              tableProps={{
                columns: columns,
                selectionMode: SelectionMode.single,
                items: appIds,
                onSelectionChanged: (selection) => {
                  const selected = selection[0] as AppInsightsSubscription;
                  selectResource(selected);
                  props.setState(dataSource);
                },
                selectedItems: [], // TODO set selected Items when editing
                styles: {
                  root: {
                    '.ms-DetailsHeader-cellName': {
                      fontWeight: '500 !important',
                      fontSize: '12px !important',
                    },
                  },
                },
                setKey: 'id',
              }}
              searchBoxProps={{
                size: SearchBoxSize.Small,
              }}
            />
          </Stack.Item>,
        ]}
        setInputOption={setInputOption}
      />
    </Stack>
  );
};

export default AppInsightsAppSelector;
