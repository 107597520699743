import { _ } from 'utils/sharedLibs';
import { $laConstants } from 'utils/constants'
import datemath from 'utils/datemath';

function diff() {

    var valueOf = function (o) {
        if (o) return o.valueOf();
    };

    return function (rangeA, rangeB) {
        if (_.isObject(rangeA) && _.isObject(rangeB)) {
            if (
            valueOf(rangeA.to) !== valueOf(rangeB.to)
            || valueOf(rangeA.from) !== valueOf(rangeB.from)
            || valueOf(rangeA.value) !== valueOf(rangeB.value)
            ) {
            return true;
            }
        } else {
            return !_.equals(rangeA, rangeB);
        }

        return false;
    };
};

// function convertISO8601(stringTime) {
//     var obj = DateTime.fromFormat(stringTime, 'YYYY-MM-DDTHH:mm:ss.SSSZ');
//     return obj.isValid() ? obj : stringTime;
// }

var timeDefaults = {
    roundBy: 'Auto',
    timeFormat: $laConstants.TimeFormat.Local
};

var refreshIntervalDefaults = {
    display: 'Off',
    section: 0,
    value: 0
};

/**
 * @name Timefilter
 * @description
 * Time filter service to store and provide time range selections from user interactions on explore and dashboard.
 */
export class Timefilter {
    emitUpdateOnChange = true;
    defaultStartTime = "now-7d";

    constructor() {
        // var activeContext = $laTabsModels.Context;
        this.tabType = $laConstants.TimePicker.TabTypes.Filter;
        this.enabled = false;
        this.timeFilterHistory = []; // stack of time filter history 
        this.timeIndex = -1; // pointer to current time object in timeFilterHistory
        // booleans to check direction of traversal
        this.backBool = false;
        this.fwdBool = false;
        // booleans to enable/disable back/forward buttons
        this.backDisabled = true;
        this.fwdDisabled = true;
        // show warning when a time field is not selected
        this.showTimeFieldWarning = false;

        // These can be date math strings or moments.
        // this.time = _.defaults((activeContext && activeContext.time) || {}, timeDefaults);
        // this.refreshInterval = _.defaults((activeContext && activeContext.refreshInterval) || {}, refreshIntervalDefaults);
        this.time = _.defaults({}, timeDefaults);
        this.refreshInterval = _.defaults({}, refreshIntervalDefaults);
        // Time bound from data
        this.dataTimeBound = {};
        this.mytimeboundobj = {};
        // if (activeContext) {
        //     activeContext.on('fetch_with_changes', function () {
        //         this.setTimeOrDefault(activeContext.time);
        //         this.setRefreshIntervalOrDefault(activeContext.refreshInterval);
        //     });
        // }

        // $rootScope.$$timefilter = self;
        // $rootScope.$watchMulti([
        //     '$$timefilter.time.from',
        //     '$$timefilter.time.to',
        //     '$$timefilter.time.mode',
        //     '$$timefilter.time',
        //     '$$timefilter.refreshInterval',
        //     '$$timefilter.refreshInterval.value',
        //     '$$timefilter.time.timeFormat'
        // ], (function () {
        //     var oldTime;
        //     var oldRefreshInterval;

        //     return function () {
        //         if (emitUpdateOnChange && (diff(this.time, oldTime) || diff(this.refreshInterval, oldRefreshInterval))) {
        //             this.emit('update');
        //         }
        //         emitUpdateOnChange = true; // Reset

        //         if ($state.current.name.indexOf("app.dashboard") > -1 && diff(this.refreshInterval, oldRefreshInterval)) {
        //             $rootScope.$broadcast("timefilter:refreshIntervalUpdated", this.refreshInterval);
        //         }

        //         if (!_.isEmpty(this.time)) {
        //             // update time filter history for lens
        //             this.updateHistory(oldTime);
        //         }
        //         oldTime = _.clone(this.time);
        //         oldRefreshInterval = _.clone(this.refreshInterval);
        //     };
        // }()));
    }

    updateHistory (oldTime) {
        var length = this.timeFilterHistory.length;
        var timeIndex = this.timeIndex;

        // if time is changed while browsing through time filter history
        if (this.fwdBool || this.backBool) {

            // enable/disable back/forward buttons based on timeIndex and history length
            switch (length) {
                case 0:
                    this.hideForward();
                    this.hideBackWard();
                    break;

                case 1:
                    if (timeIndex === -1) {
                        this.showForward();
                        this.hideBackward();
                    }
                    if (timeIndex === 0) {
                        this.showBackward();
                        this.hideForward();
                    }
                    break;

                default:
                    if (timeIndex === -1) {
                        this.showForward();
                        this.hideBackward();
                    }
                    if (timeIndex > -1 && timeIndex < length - 1) {
                        this.showForward();
                        this.showBackward();
                    }
                    if (timeIndex === length - 1) {
                        this.hideForward();
                        this.showBackward();
                    }
                    break;
            }
        } else {
            // if time is set through: (a) dragging on time series chart (b) clicking on quick time filter values
            if (timeIndex > -1) {
                this.timeFilterHistory.length = timeIndex + 1;
            }
            var currentTime = _.clone(this.time);
            if (currentTime.from && currentTime.to && diff(currentTime, oldTime)) {
                this.timeIndex = timeIndex + 1;
                this.timeFilterHistory.push(currentTime);
                this.hideForward();
                this.showBackward();
            }
        }
        // reset traversal booleans
        this.backBool = false;
        this.fwdBool = false;
    };

    // manipulation to enable/disable back and forward buttons
    showForward () {
        this.fwdDisabled = false;
    };

    hideForward () {
        this.fwdDisabled = true;
    };

    showBackward () {
        this.backDisabled = false;
    };

    hideBackward () {
        this.backDisabled = true;
    };

    // set time to previous value when traversed back
    goBack () {
        this.backBool = true;
        this.timeIndex = this.timeIndex - 1;
        if (this.timeIndex === -1) {
            this.resetTimeValue(); // Set to initial time filter values. This is needed as a part of maintaining history
        } else {
            this.time = _.clone(this.timeFilterHistory[this.timeIndex]);
        }
    };

    // set time to next value when traversed forward
    goForward () {
        this.fwdBool = true;
        this.timeIndex = this.timeIndex + 1;
        this.time = _.clone(this.timeFilterHistory[this.timeIndex]);
    };

    // reset variables required to maintain time filter history
    removeTimeValue () {
        this.time.from = undefined;
        this.time.to = undefined;
        this.time.mode = undefined;
        this.time.timeFormat = $laConstants.TimeFormat.Local;
        this.timeFilterHistory = [];
        this.timeIndex = -1;
        this.backBool = false;
        this.fwdBool = false;
        this.backDisabled = true;
        this.fwdDisabled = true;
        // this.emit('update_section_timefilter', this.time); // clear timefilter values stored by sections
    };

    getBounds () {
        var result = {
            min: datemath.parse(this.time.from),
            max: datemath.parse(this.time.to, true) // Round up
        };

        return result;
    };

    /**
     * get the time format :Local or UTC
     * @return {string} timeFormat set by the user
     */
    getTimeFormat () {
        return this.time.timeFormat;
    };

    /**
     * set the time format :Local or UTC
     * @param {string} val to set
     */
    setTimeFormat (val) {
        this.time.timeFormat = val;
    };

    /**
     * get the time mode
     * @return {string} timefilter mode - absolute or relative
     */
    getMode () {
        return this.time.mode;
    };

    getActiveBounds () {
        return this.getBounds();
    };

    getDuration () {
        return datemath.parse(this.time.to, true).diff(datemath.parse(this.time.from));
    };

    /**
    * @name setTimeOrDefault
    * @description  Set new/default time to the filter time
    * @param {object} time - The time we want to set. The parameters that won't be set, will be set to default.
    * @param {Boolean} dontEmitUpdateOnChange - [optional] true if the 'update' event don't need to be raised after the time update.
    */
    setTimeOrDefault (time, dontEmitUpdateOnChange) {
        // Here's some Documentation for _.defaults(Target, Source, Defaults) -
        //  1. It Set the values on "Target" from "Source".
        //  2. If "Defaults" has a property that is not in "Source" - copy that property into "Target" as well
        //
        // So basically, we are cloning Source into Target, and also setting some default values from Defaults
        var newTime = _.defaults({}, time, timeDefaults);

        // The code above is converting the new time to local time - it was commented since we are working with UTC instead of local time
        //if (newTime) {
        //    if (newTime.to) newTime.to = convertISO8601(newTime.to);
        //    if (newTime.from) newTime.from = convertISO8601(newTime.from);
        //}

        this.emitUpdateOnChange = !dontEmitUpdateOnChange;
        if (newTime.from && newTime.to && diff(this.time, newTime)) {
            // check to avoid adding duplicate time filter values to history
            this.time = newTime;
        }
    };

    isDefaultTime (time){
        return !time.from && !time.to && time.roundBy === timeDefaults.roundBy;
    }

    setRefreshIntervalOrDefault (refreshInterval) {
        // See Documentation for _.defaults(Target, Source, Defaults) above.
        var newRefreshInterval = _.defaults({}, refreshInterval, refreshIntervalDefaults);
        this.refreshInterval = newRefreshInterval;
    };

    getTime () {
        return this.time;
    };

    /**
     * Test whether the timefilter has a correct format time value set
     * @returns {time} which will generate and return the query object 
     */
    hasTimeValue () {
        var time = this.time || {};
        return !!time.from && !!time.to;
    };

    // Use the time bounds (min and max) retrieved from data to set the time bounds
    setTimeBoundFromData (newBound) {
        if (newBound) {
            this.dataTimeBound = newBound;
        }
    };

    // Get data time bounds
    getBoundFromData () {
        return {
            min: datemath.parse(this.dataTimeBound.from),
            max: datemath.parse(this.dataTimeBound.to, true) // Round up
        };
    };

    resetTimeValue () {
        this.time.from = undefined;
        this.time.to = undefined;
        this.time.mode = undefined;
    };
    
    //get the tab type to open when tipe picker is opened
    getTabType () {
        return this.tabType || $laConstants.TimePicker.TabTypes.Filter;
    };

    // set the tab type to open when time picker dialog is opened
    setTabType (tabType) {
        this.tabType = tabType;
    };

}

export const timefilter = new Timefilter();

export default timefilter;