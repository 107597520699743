import { DataSourceClient } from 'features/dataSources/client/dataSourceClient';
import $laConstants, { orchestratorConstants } from 'utils/constants';

class ApplicationInsightsClient extends DataSourceClient {
  public readonly name = $laConstants.DataSourceTypes.APPLICATIONINSIGHTS;
  public readonly title = 'Application Insights';
  public readonly icon = 'fa fa-dog';
  public readonly description = 'Application Insights data source.';

  public readonly monaco = {
    language: orchestratorConstants.LanguageId.Kusto,
  };

  public readonly orchestration = {
    dataMovement: {
      endpointTypes: [
        // in order for UI
        orchestratorConstants.EndpointTypes.ApplicationInsightsTable,
        orchestratorConstants.EndpointTypes.ApplicationInsightsQuery,
      ],
      inputPartitioning: true,
      targetEndpointTypes: [
        orchestratorConstants.EndpointTypes.KustoTable,
        orchestratorConstants.EndpointTypes.CosmosStream,
      ],
    },
    endpoint: {
      directive: 'kusto-table-job-endpoint',
      tablePattern: /^[a-z\d_$.\- ]{1,1024}$/i,
      tableValidationMessage:
        'The name may consist of up to 1024 letters, digits, underscores, dollar signs, spaces, dots, and dashes.',
    },
  };
  public get displayName() {
    return $laConstants.DataSourceClientDisplayNames.APPLICATIONINSIGHTS;
  }

  public get dataSourceType() {
    return $laConstants.DataSourceTypes.APPLICATIONINSIGHTS;
  }
}

export const appInsightsClient = new ApplicationInsightsClient();
export default appInsightsClient;
