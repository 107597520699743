import {
  ElxActionButton,
  ElxPicker,
  IPickerItem,
  useId,
} from '@elixir/components';
import { Stack } from '@fluentui/react';
import { useCallback, useEffect, useState } from 'react';
import { armClient } from 'utils/armClient';
import { LensLabel } from 'utils/lensLabel';
import CreateResourceGroupCallout from './createResourceGroupCallout';
import { SubscriptionInfo } from './subscriptionPicker';

export interface ResourceGroup {
  id: string;
  location: string;
  name: string;
  type: string;
}

interface ResourceGroupPickerProps {
  subscriptionInfo: SubscriptionInfo;
  selectedResourceGroup: string;
  selectedRegion: string;
  setResourceGroup: (resourceGroup: string) => void;
  setRegion: (region: string) => void;
}

export const ResourceGroupPicker = (
  props: ResourceGroupPickerProps
): JSX.Element => {
  const {
    selectedResourceGroup,
    selectedRegion,
    subscriptionInfo,
    setResourceGroup,
    setRegion,
  } = props;
  const [showCreateResourceGroupCallout, setShowCreateResourceGroupCallout] =
    useState(false);
  const [resourceGroupList, setResourceGroupList] = useState<IPickerItem[]>([]);
  const [pendingResourceGroup, setPendingResourceGroup] = useState<string>('');
  const createNewButtonId = useId('createNew');

  const fetchResourceGroups = useCallback(() => {
    setResourceGroupList([]);
    if (!subscriptionInfo.subscriptionId) return;
    armClient
      .fetchResourceGroupList(subscriptionInfo.subscriptionId)
      .then((result: ResourceGroup[]) => {
        const resourceGroupArray = result.map((resource) => ({
          key: resource.name,
          text: resource.name,
        }));
        resourceGroupArray.sort((a, b) => a.text.localeCompare(b.text));
        setResourceGroupList(resourceGroupArray);
      });
  }, [subscriptionInfo, setResourceGroupList]);

  const onResourceGroupCreated = (resourceGroup: string) => {
    if (!subscriptionInfo.subscriptionId) return; // should not happen because create button is disabled
    fetchResourceGroups();
    setPendingResourceGroup(resourceGroup);
  };

  // fetch resource groups when subscription changes
  useEffect(() => {
    fetchResourceGroups();
  }, [fetchResourceGroups, subscriptionInfo]);

  useEffect(() => {
    if (resourceGroupList.length && pendingResourceGroup) {
      setResourceGroup(pendingResourceGroup);
      setPendingResourceGroup('');
    }
  }, [pendingResourceGroup, resourceGroupList.length, setResourceGroup]);

  return (
    <Stack>
      <ElxPicker
        onRenderLabel={() => (
          <LensLabel
            labelText="Resource Group"
            hintText={
              'The resource group in which you want to create your new Azure Managed Grafana instance'
            }
            required={true}
          ></LensLabel>
        )}
        selectedKey={selectedResourceGroup}
        items={resourceGroupList}
        placeHolder="Select a resource group"
        calloutWidth={500}
        onChange={(item: IPickerItem) => {
          setResourceGroup(item.key);
        }}
        noResultMessage={
          subscriptionInfo.subscriptionId
            ? 'No resource groups found'
            : 'Please select a subscription first'
        }
        labelActions={[
          <ElxActionButton
            styles={{
              labelDisabled: { pointerEvents: 'auto' },
              iconDisabled: { pointerEvents: 'auto' },
            }} // allow tooltip to show on disabled button
            id={createNewButtonId}
            iconProps={{ iconName: 'Add' }}
            text="Create New"
            disabled={!subscriptionInfo.subscriptionId}
            onClick={() => setShowCreateResourceGroupCallout(true)}
            title={
              !subscriptionInfo.subscriptionId
                ? 'Select a subscription first'
                : ''
            }
          />,
        ]}
      />
      {subscriptionInfo.subscriptionId && (
        <CreateResourceGroupCallout
          show={showCreateResourceGroupCallout}
          subscriptionInfo={subscriptionInfo}
          onResourceGroupCreated={onResourceGroupCreated}
          dismissCallout={() => setShowCreateResourceGroupCallout(false)}
          target={`#${createNewButtonId}`}
          existingResourceGroups={resourceGroupList.map((option) => option.key)}
          selectedRegion={selectedRegion}
          setRegion={setRegion}
        />
      )}
    </Stack>
  );
};
