interface InputFileAttributes {
  accept?: string;
  multiple?: boolean;
}

const defaultInputFileAttributes: InputFileAttributes = {
  multiple: false,
  accept: 'application/json',
};

const elementIdentifier = 'lenexploreinputfileelement321';

const fileOpen = (
  onChgHandler: { (fileList: FileList): void },
  userInputFileAttributes?: Partial<InputFileAttributes>
) => {
  const inputFileAttributes: InputFileAttributes = {
    ...defaultInputFileAttributes,
    ...userInputFileAttributes,
  };
  const onChangeHandler = (e: Event) => {
    e.preventDefault();
    const { files } = e.target as HTMLInputElement;
    if (files !== null) {
      onChgHandler(files);
    }
  };
  const elem = document.getElementById(elementIdentifier);
  elem?.parentElement?.removeChild(elem);
  const inputElem = document.createElement('input');
  inputElem.id = elementIdentifier;
  inputElem.type = 'file';
  inputFileAttributes.multiple &&
    (inputElem.multiple = inputFileAttributes.multiple);
  inputFileAttributes.accept && (inputElem.accept = inputFileAttributes.accept);
  inputElem.onchange = onChangeHandler;
  inputElem.click();
};

export default fileOpen;
