import userPreferencesApi from 'features/userInfo/api/userPreferencesApi';
import { UserPreferences as UserPreferencesModel } from 'features/userInfo/models/userPreferences';
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { UserPreferences } from './userPreferences';

/**
 * Settings landing page and container component
 * @returns JSX element of User Settings page
 */
export const Settings = (): JSX.Element => {
  var userPreferences = userPreferencesApi.getUserPreferences();

  if (userPreferences === undefined || userPreferences === null) {
    let initialUserPreferences: UserPreferencesModel = {
      upn: '',
      name: '',
      themeColorPreference: '',
      disableAutoRunningQuery: false,
      trimSearchColumns: '',
      turnOffVisualAnalyzerTeaser: false,
      timeZone: '',
      supportNotificationStatus: '',
      disableSavedDashboardFilters: false,
      aadTenantId: '',
      oid: '',
    };

    userPreferencesApi.saveUserPreferences(initialUserPreferences);
  }

  return (
    <Switch>
      <Route path="/settings/preferences" render={() => <UserPreferences />} />
      <Redirect to="/settings/preferences" />
    </Switch>
  );
};

export default Settings;
