import React, { useState } from 'react';

import { IContainerAction } from '@elixir/components';

// only panelId needs to be set ahead of time, the show functions are injected by the panel manager.
export interface BasePanelProps {
  panelId: string;
  // you can call these but do not set them, they are injected by panel manager:
  show: boolean;
  onShowPanel: (show?: boolean) => void;
  onSwitchPanel: (panelId: string) => void;
  onAddPanel: (panelId: string) => void;
  closeButton: IContainerAction;
}

// helps work around typescript complaining about undefined functions when creating a panel
export const panelDefaults = {
  show: false,
  onShowPanel: () => undefined,
  onSwitchPanel: (panelId: string) => undefined,
  onAddPanel: (panelId: string) => undefined,
  closeButton: {
    key: 'Close',
    text: 'Close',
  },
};

export interface PanelState {
  panelStack: string[];
  setPanelStack: React.Dispatch<React.SetStateAction<string[]>>;
}

export interface PanelManagerProps {
  panelState: PanelState;
  children?: React.ReactNode;
}

export function usePanelManagerState() {
  const [panelStack, setPanelStack] = useState<string[]>([]);
  return {
    panelStack,
    setPanelStack,
  };
}

/**
 * Show (or dismiss) a side-panel over top of Lens
 * @param panel which panel to show
 * @param show true to show, false to dismiss
 */
export function onShowPanel(
  panelState: PanelState,
  panel: string,
  show = true
): void {
  const { panelStack, setPanelStack } = panelState;
  if (show && panelStack?.[0] === panel) return;
  if (!show && !panelStack.includes(panel)) return;
  let newStack = panelStack.filter((p) => p !== panel);
  if (show) newStack.unshift(panel);
  setPanelStack(newStack);
}

/**
 * A panel manager that controls the visibility state of multiple side panels
 * Only one panel is visible at a time.  Individual panels can swap to another panel on demand.
 * Panels are the React children elements, and each must take BasePanelProps as props.
 * Only panelId should be set ahead of time.
 * @param props.panelState the state returned by usePanelManagerState hook
 * @returns
 */
export function PanelManager(props: PanelManagerProps): JSX.Element {
  const { panelState, children } = props;
  // The panelStack retains the visiblity state of multiple Side Panels.  panelStack[0] is the current visible panel
  const { panelStack, setPanelStack } = panelState;

  const isShown = (panel: string): boolean =>
    panelStack.length > 0 && panelStack[0] === panel;

  /**
   * Only call this from inside another side-panel.  Use showPanel() function to display a side-panel
   * @param panel panel to switch to
   */
  function switchPanel(panel: string) {
    let newStack = panelStack.slice(1);
    newStack.unshift(panel);
    setPanelStack(newStack);
  }

  return (
    <>
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          const panelId = child.props.panelId;
          if (!panelState.panelStack.includes(panelId)) return <></>;
          return React.cloneElement(
            child as React.ReactElement<BasePanelProps>,
            {
              show: isShown(panelId),
              onShowPanel: (show?: boolean) =>
                onShowPanel(panelState, panelId, show),
              onSwitchPanel: (target: string) => switchPanel(target),
              onAddPanel: (target: string) =>
                onShowPanel(panelState, target, true),
              closeButton: {
                ...panelDefaults.closeButton,
                onClick: () => onShowPanel(panelState, panelId, false),
              },
            }
          );
        }
      })}
    </>
  );
}

export default PanelManager;
