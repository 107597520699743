import {
  Breadcrumb,
  Checkbox,
  DefaultButton,
  FontSizes,
  FontWeights,
  IBreadcrumbItem,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
} from '@fluentui/react';
import { useSelector } from 'react-redux';
import {
  breadCrumbStyles,
  panelStyles,
  stackItemFormStyles,
  panelContentStackStyle,
} from '../workspaceStyles';
import { editWorkspace } from 'features/workspaces/workspaceSlice';
import { useCallback, useState } from 'react';
import notifier from 'utils/notifier';
import agcMigrationApi from 'features/workspaces/api/agcMigrationApi';
import { exportWorkspaceParameters } from 'features/workspaces/utils/workspaceHighsideUtils';

interface PublishPanelProps {
  workspaceId: string;
  show: boolean;
  dismissPanel: () => void;
  openPanel: () => void;
  closeQuickSwitchPanel: () => void;
}

const buttonStyles = { root: { marginRight: 8 } };

const PublishPanel = (props: PublishPanelProps) => {
  let workspace = useSelector(editWorkspace);
  const [exportParams, setExportParams] = useState(false);

  const items: IBreadcrumbItem[] = [
    {
      text: 'Workspace management',
      key: 'workspaceManagement',
      onClick: () => {
        props.dismissPanel();
      },
    },
    {
      text: workspace?.name + ' - Publish to High Side',
      key: 'workspacePublishing',
    },
  ];

  const onRenderFooterContent = useCallback(
    () => (
      <div>
        <PrimaryButton
          onClick={() => {
            agcMigrationApi
              .exportWorkspace(workspace.id)
              .then(() => {
                notifier.info(
                  'Workspace (' + workspace.name + ') publishing was successful'
                );
              })
              .catch((err: string | object | Error) => {
                notifier.error(err);
              });

            if (exportParams) {
              exportWorkspaceParameters(workspace.id);
            }
          }}
          styles={buttonStyles}
        >
          {exportParams ? 'Publish and Export' : 'Publish'}
        </PrimaryButton>
        <DefaultButton onClick={() => props.dismissPanel()}>
          Cancel
        </DefaultButton>
      </div>
    ),
    [exportParams, props, workspace.id, workspace.name]
  );
  return (
    <Panel
      type={PanelType.large}
      isOpen={props.show}
      onDismiss={props.closeQuickSwitchPanel}
      styles={panelStyles}
      onRenderHeader={() => {
        return (
          <Breadcrumb
            items={items}
            maxDisplayedItems={2}
            ariaLabel="Workspace migration breadcrumb with items rendered as buttons"
            overflowAriaLabel="More links"
            styles={breadCrumbStyles}
          />
        );
      }}
      onRenderFooterContent={onRenderFooterContent}
      isFooterAtBottom={true}
    >
      <Stack {...panelContentStackStyle}>
        <Stack.Item {...stackItemFormStyles}>
          <div
            style={{
              fontSize: FontSizes.size18,
              fontWeight: FontWeights.semibold,
            }}
          >
            Migrating to the government cloud/the high side:
          </div>
          {`Confirm that you do want to delete: `}
          <div
            style={{
              fontWeight: FontWeights.semibold,
            }}
          >
            {workspace.name} ({workspace.id})
          </div>
        </Stack.Item>
        <Stack.Item {...stackItemFormStyles}>
          {
            'If you want to export all Parameters attached to the selected Workspaces, check below box. Once you click Save, an automatic CSV file download will start for each Workspace.'
          }
        </Stack.Item>
        <Stack.Item {...stackItemFormStyles}>
          <Checkbox
            onRenderLabel={() => (
              <div
                style={{
                  fontWeight: FontWeights.semibold,
                }}
              >
                Export Workspace Parameters for these workspaces
              </div>
            )}
            // label="Export Workspace Parameters for these workspaces"
            checked={exportParams}
            onChange={(ev, value) => {
              setExportParams(value || false);
            }}
          />
        </Stack.Item>
      </Stack>
    </Panel>
  );
};

export default PublishPanel;
