import 'set-public-path';
import angular from 'angular';
import { UI_ROUTER_REACT_HYBRID } from '@uirouter/react-hybrid';
import { notifier } from 'utils/notifier';
import { inject as workspaceUtilsInject } from 'features/workspaces/utils/workspaceUtils';
import { inject as asideUtilsInject } from 'features/shell/utils/asideUtils';
import { inject as authUtilsInject } from './utils/authUtils';
import { inject as sharedLibsInject } from 'utils/sharedLibs';
import { inject as jobUtilsInject } from 'features/orchestrator/utils/jobUtils';
import { inject as notificationInject } from 'utils/notificationUtils';
// import { inject as catalogUtilsInject } from 'features/catalog/utils/catalogEntityDetailsUtils';
import { initializeIcons, setIconOptions } from '@fluentui/react';
import { inject as userInfoInject } from 'features/userInfo/utils/userPreferencesUtils';
import { setDashboardObject } from './features/workspaces/workspaceSlice';
import { store } from './app/lensShellUtility';
import { SidePanel, showLensShellPanel } from 'features/shell/lensShell';

export { routes } from 'app/routes';

export const sharedModules = {
  angular,
  UI_ROUTER_REACT_HYBRID /* Visualizer, */,
};

/**
 * Injects AngularJS dependencies into React. Called in leAppService.js.
 */
export const injectAngular2React = (leApp: any) => {
  // Avoid injecting AngularJS dependencies - $rootScope, laTelemetry, workspaceManager...
  // AngularJS dependencies may be deprecated and replaced with React in the future.
  // Wrap minimal AngularJS dependencies in leApp methods in leAppService.js for now.
  initializeIcons();
  setIconOptions({
    disableWarnings: true,
  });
  notifier.inject(leApp);
  workspaceUtilsInject(leApp);
  asideUtilsInject(leApp);
  authUtilsInject(leApp);
  sharedLibsInject(leApp);
  jobUtilsInject(leApp);
  notificationInject(leApp);
  userInfoInject(leApp);
  // catalogUtilsInject(leApp);
};

export const grafConvert = (jsonData: string) => {
  store.dispatch(setDashboardObject(JSON.parse(jsonData)));
  showLensShellPanel(SidePanel.CONVERTTOGRAFANA);
};
