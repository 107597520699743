import React from 'react';
import { ThemeProvider } from '@fluentui/react';
import { ElxLink, ElxPanel, PanelSize } from '@elixir/components';
import { useRouter } from '@uirouter/react';
import { useLensShellTheme } from 'features/shell/lensShellStyles';
import { BasePanelProps } from 'components/panelManager/panelManager';
import { panelStyles } from 'utils/sharedStyles';
import { BUILD_NUM } from 'utils/sharedLibs';
import { ILinkStyles, Stack } from '@fluentui/react';
import logger from 'features/appInsights/lensLogger';
import constants from 'utils/constants';
import { LensTelemetryConstants } from 'features/appInsights/appInsightsLibs';

const helpLinkStyles = {
  root: {
    fontSize: '110%',
  },
} as ILinkStyles;

export const Help = ({
  show,
  onShowPanel,
  closeButton,
}: BasePanelProps): JSX.Element => {
  const theme = useLensShellTheme();
  const router = useRouter();
  const isOnJobPages =
    (router.globals.current.name || '').indexOf('app.job') > -1;
  const icmLink = isOnJobPages
    ? 'https://aka.ms/loicm'
    : 'https://aka.ms/lenslivesite';

  return (
    // ElxPanels are 'outside' of LensShell, so they must have their own ThemeProvider wrapper
    <ThemeProvider theme={theme}>
      <ElxPanel
        headerText="Help Center"
        headerContent={
          <div>
            <p>
              {'Need additional help? '}
              <ElxLink
                style={{ fontSize: '1em' }}
                label="CreateIncident"
                href={icmLink}
                target="_blank"
              >
                {'Contact the support team via IcM'}
              </ElxLink>
            </p>
            <div>
              <p>Lens Explorer Build Number: {BUILD_NUM} </p>
            </div>
          </div>
        }
        isOpen={show}
        size={PanelSize.small}
        fillBackground={true}
        actions={[closeButton]}
        onDismiss={() => onShowPanel(false)}
        styles={panelStyles}
      >
        <Stack tokens={{ padding: 24, childrenGap: 16 }}>
          <ElxLink
            styles={helpLinkStyles}
            label="LensHelp"
            href="https://eng.ms/docs/products/genevaanalytics/lensexplorer/lensuserguide"
            target="_blank"
          >
            {'Lens Explorer documentation'}
          </ElxLink>

          <ElxLink
            styles={helpLinkStyles}
            label="LensPrivacyTerms"
            href={constants.Urls.PrivacyTermsLink}
            target="_blank"
            onClick={() => {
              logger.event(
                LensTelemetryConstants.EventNames.NavbarActions.PrivacyTerms
              );
            }}
          >
            {'Lens Explorer Privacy Terms'}
          </ElxLink>
        </Stack>
      </ElxPanel>
    </ThemeProvider>
  );
};

export default Help;
