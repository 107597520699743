import {
  ElxLink,
  ElxPanel,
  IContainerAction,
  PanelSize,
} from '@elixir/components';
import {
  MessageBar,
  MessageBarType,
  Separator,
  Stack,
  ThemeProvider,
} from '@fluentui/react';

import { useDispatch, useSelector } from 'react-redux';
import { BasePanelProps } from '../../../components/panelManager/panelManager';
import { useLensShellTheme } from '../../shell/lensShellStyles';
import { useEffect, useMemo, useState } from 'react';
import {
  selectDashboardObject,
  selectWorkspace,
  setDashboardObject,
} from '../../workspaces/workspaceSlice';
import { AppDispatch, store } from '../../../app/lensShellUtility';
import {
  clearMigrationError,
  loadFoldersForGrafanaInstance,
  migrateDashboard,
  resetDashboardMigrationStatus,
  resetFolders,
  saveMigrationSettings,
  selectDashboardMigrationError,
  selectDashboardMigrationResult,
  selectDashboardMigrationStatus,
  selectPreviousMigrationSettings,
} from '../grafanaMigrationSlice';
import { GrafanaMigrationStatus } from '../models/grafanaMigrationData';
import { GrafanaInstanceSelector } from './grafanaInstanceSelector';
import { GrafanaFolderSelector } from './grafanaFolderSelector';
import { MigrationStatus } from './migrationStatus';
import { getDashboardIsStatusEditing } from 'features/workspaces/utils/workspaceUtils';
import { withoutHttps } from '../grafanaDatasourceUtils';
import SpinnerOverlay from 'components/spinnerOverlay/spinnerOverlay';

export const buttonStyles = {
  root: {
    width: '50px',
  },
};

export const mainStackStyles = {
  root: {
    padding: '32px',
  },
};

const stackStyles = {
  root: {
    width: '75%',
  },
};

export const ConvertToGrafana = ({
  show,
  onShowPanel,
  closeButton,
}: BasePanelProps): JSX.Element => {
  const lensDashboard = useSelector(selectDashboardObject);

  const dashboardName = lensDashboard?.[0].Title || '';

  const onDismiss = () => {
    onShowPanel(false);
    store.dispatch(setDashboardObject(''));
    dispatch(resetDashboardMigrationStatus());
    dispatch(resetFolders());
    dispatch(clearMigrationError());
    setSelectedGrafanaInstance('');
    setSelectedFolder('');
  };

  const theme = useLensShellTheme();
  const dispatch = useDispatch<AppDispatch>();
  const workspace = useSelector(selectWorkspace);
  const [selectedFolder, setSelectedFolder] = useState<string>('');
  const [selectedGrafanaInstance, setSelectedGrafanaInstance] =
    useState<string>('');
  const previousMigrationSettings = useSelector(
    selectPreviousMigrationSettings
  );
  const dashboardMigrationStatus = useSelector(selectDashboardMigrationStatus);
  const dashboardMigrationResponse = useSelector(
    selectDashboardMigrationResult
  );
  const dashboardMigrationError = useSelector(selectDashboardMigrationError);

  const instanceUrl = useMemo(
    () => withoutHttps(selectedGrafanaInstance),
    [selectedGrafanaInstance]
  );

  const onDismissError = () => {
    dispatch(clearMigrationError());
  };

  const onClick = () => {
    if (selectedGrafanaInstance && selectedFolder) {
      const message = `Migrated from Lens Explorer Dashboard: '${dashboardName}' in workspace: ${workspace.name} (${workspace.id}).`;

      dispatch(
        migrateDashboard({
          lensDashboard,
          instanceUrl,
          folderUid: selectedFolder,
          message,
        })
      );
      dispatch(
        saveMigrationSettings({
          workspaceId: workspace.id,
          grafanaInstance: selectedGrafanaInstance,
          folder: selectedFolder,
        })
      );
    }
  };

  const cannotMigrateBecauseOfUnsavedChanges = getDashboardIsStatusEditing();

  const migrateButton: IContainerAction = {
    primary: true,
    key: 'migrate',
    text: 'Migrate',
    onClick: onClick,
    disabled:
      cannotMigrateBecauseOfUnsavedChanges ||
      dashboardMigrationStatus === GrafanaMigrationStatus.Loaded ||
      !selectedGrafanaInstance ||
      !selectedFolder,
  };

  closeButton = { ...closeButton, onClick: onDismiss };

  // set the previously selected folder and grafana instance when the panel is re-opened
  useEffect(() => {
    if (show && previousMigrationSettings?.workspaceId === workspace.id) {
      setSelectedGrafanaInstance(previousMigrationSettings.grafanaInstance);
      setSelectedFolder(previousMigrationSettings.folder);
    }
  }, [show, previousMigrationSettings, workspace.id]);

  // reload folders when the grafana instance is changed or the panel is re-opened
  useEffect(() => {
    if (show && instanceUrl) {
      dispatch(loadFoldersForGrafanaInstance({ instanceUrl }));
    }
  }, [dispatch, instanceUrl, show]);

  const destinationLink =
    selectedGrafanaInstance + dashboardMigrationResponse.url;

  const grafanaDocumentation =
    'https://grafana.com/docs/grafana/latest/introduction/';

  return (
    <ThemeProvider theme={theme}>
      <ElxPanel
        styles={{
          headerContent: { marginTop: -16 },
          overlay: { backgroundColor: 'rgb(0 0 0 / 40%)' },
        }}
        headerText="Migrate To Grafana (Preview)"
        headerContent={
          <div>
            <p>
              {
                'This interface allows you to move your chosen Lens Explorer Dashboard to a specific folder location in Azure Managed Grafana. If the migration process is successful, we will provide you a link to access your new dashboard.'
              }
              {' To learn more about Azure Managed Grafana follow this '}
              <ElxLink
                style={{ fontSize: '1em' }}
                label="documentation"
                href={grafanaDocumentation}
                target="_blank"
              >
                {'link'}
              </ElxLink>
              {' to the documentation.'}
            </p>
          </div>
        }
        isOpen={show}
        size={PanelSize.medium}
        actions={[closeButton, migrateButton]}
        onDismiss={onDismiss}
      >
        <Stack styles={mainStackStyles} tokens={{ childrenGap: '25px' }}>
          {dashboardMigrationError && (
            <Stack.Item>
              <MessageBar
                messageBarType={MessageBarType.error}
                isMultiline={true}
                dismissButtonAriaLabel="Close"
                onDismiss={onDismissError}
              >
                {dashboardMigrationError}
              </MessageBar>
            </Stack.Item>
          )}
          {cannotMigrateBecauseOfUnsavedChanges && (
            <Stack.Item>
              <MessageBar
                messageBarType={MessageBarType.severeWarning}
                isMultiline={true}
              >
                <h4 style={{ marginTop: 0 }}>Unsaved changes in dashboard</h4>
                <p>
                  Dashboard "{dashboardName}" is not saved. You must save your
                  changes before you can migrate your dashboard to Grafana.
                </p>
              </MessageBar>
            </Stack.Item>
          )}
          <Stack styles={stackStyles} tokens={{ childrenGap: '15px' }}>
            <Stack.Item>
              <GrafanaInstanceSelector
                selectedGrafanaInstance={selectedGrafanaInstance}
                setSelectedGrafanaInstance={setSelectedGrafanaInstance}
              ></GrafanaInstanceSelector>
            </Stack.Item>
            <Stack.Item>
              {selectedGrafanaInstance && (
                <GrafanaFolderSelector
                  instanceUrl={instanceUrl}
                  selectedFolder={selectedFolder}
                  setSelectedFolder={setSelectedFolder}
                ></GrafanaFolderSelector>
              )}
            </Stack.Item>
          </Stack>
        </Stack>
        {selectedFolder &&
          selectedGrafanaInstance &&
          dashboardMigrationStatus === GrafanaMigrationStatus.Loaded &&
          dashboardMigrationResponse.url !== '' && (
            <>
              <Separator></Separator>
              <Stack>
                <MigrationStatus
                  destinationLink={destinationLink}
                  warnings={dashboardMigrationResponse.warnings}
                ></MigrationStatus>
              </Stack>
            </>
          )}
        <SpinnerOverlay
          show={dashboardMigrationStatus === GrafanaMigrationStatus.Loading}
          label={'Migrating dashboard to Grafana...'}
          opacity={0.8}
        />
      </ElxPanel>
    </ThemeProvider>
  );
};
