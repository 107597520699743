import { ElxIconButton } from '@elixir/components';
import { Label, Link, Stack } from '@fluentui/react';
import { iconProps } from 'app/elxAdapter';
import {
  iconStyles2,
  stackWidthStyles,
  traininglabel2,
  traininglabel3,
} from './homePageStyles';
import { TrainingAndGuidesProps } from './trainingAndGuidesList';

export const TrainingAndGuideCard = (
  props: TrainingAndGuidesProps
): JSX.Element => {
  const { mainLabel, secondaryLabel, iconName, iconText, link } = props;

  const onClick = (link: string) => {
    window.open(link, '_blank');
  };
  return (
    <>
      <Stack
        horizontal
        tokens={{ maxWidth: '350px' }}
        styles={stackWidthStyles}
      >
        <Stack.Item>
          <ElxIconButton
            text={iconText}
            styles={iconStyles2}
            iconProps={iconProps(iconName)}
          />
        </Stack.Item>
        <Stack.Item>
          <Stack.Item>
            <Link styles={traininglabel3} href={link} target="_blank">
              {mainLabel}
            </Link>
          </Stack.Item>
          <Stack.Item>
            <Label styles={traininglabel2} onClick={() => onClick(link)}>
              {secondaryLabel}
            </Label>
          </Stack.Item>
        </Stack.Item>
      </Stack>
    </>
  );
};
