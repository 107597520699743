import {
  ElxContainer,
  ElxTable,
  FilterDisplayMode,
  IElxColumn,
  IElxContainerProps,
  IElxSearchBoxProps,
  IElxTableProps,
  ISearchCriteria,
} from '@elixir/components';
import { CheckboxVisibility, Shimmer, Stack } from '@fluentui/react';
import { AppDispatch } from 'app/lensShellUtility';
import { ServiceListStatus } from 'features/serviceTree/models/service';
import {
  selectAllServices,
  selectServiceListStatus,
  selectTotalServiceCount,
  useServices,
} from 'features/serviceTree/servicesSlice';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'utils/debounceUtils';
import {
  summaryCard,
  summaryCards,
} from '../../../components/cards/summaryCard';
import {
  loadCatalogEntities,
  selectAllCatalogEntities,
  selectCatalogListStatus,
  selectCurrentCatalogEntitiesCount,
  selectKustoEntitiesCount,
  selectKustoEntitiesCountStatus,
  selectMDSEntitiesCount,
  selectMDSEntitiesCountStatus,
  selectTotalCatalogEntitiesCount,
  selectTotalCatalogEntitiesCountStatus,
  useCatalogEntityStats,
} from '../catalogSlice';
import { CatalogEntityStatus } from '../models/catalogEntity';
import {
  createCatalogRequest,
  getPillFilters,
  loadedCardBody,
  loadingCardBody,
  renderSearchBox,
  renderStackedElements,
} from '../utils/catalogEntityListUtils';
import {
  colExtraLarge,
  colMedium,
  colSmall,
  colXXLarge,
  tableStyles,
} from 'utils/sharedStyles';
import { Link } from 'react-router-dom';
import './catalogEntityList.css';

/**
 * Data Catalog Entity List landing page and container component
 * @returns JSX element of Data Catalog page
 */
export const CatalogEntityList = (props: {
  isVisible: boolean;
}): JSX.Element => {
  // Use hooks to load catalog statistics and service tree services
  useCatalogEntityStats();
  useServices();
  const dispatch = useDispatch<AppDispatch>();

  // Current Catalog Entity List Info
  const currentCatalogEntityListStatus = useSelector(selectCatalogListStatus);
  const currentCatalogEntityList = useSelector(selectAllCatalogEntities);
  const currentCatalogEntityCount = useSelector(
    selectCurrentCatalogEntitiesCount
  );

  // Catalog Entity List loaded by initialization of search in useEffect
  const [searchQuery, setSearchQuery] = useState({
    keyword: '',
    filters: {},
  });
  const debouncedSetSearchQuery = debounce(setSearchQuery, 500);

  // Load catalog entity list based on search keyword
  useEffect(() => {
    if (currentCatalogEntityListStatus === CatalogEntityStatus.None) {
      callSearchAPI(searchQuery);
    } else {
      // Will call API after user is done typing and timer runs out
      const timer = setTimeout(() => {
        callSearchAPI(searchQuery);
      }, 400);

      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery.keyword]);

  // Data Catalog Statistics
  const totalCatalogEntityCountStatus = useSelector(
    selectTotalCatalogEntitiesCountStatus
  );
  const totalCatalogEntityCount = useSelector(selectTotalCatalogEntitiesCount);
  const kustoEntityCountStatus = useSelector(selectKustoEntitiesCountStatus);
  const kustoEntityCount = useSelector(selectKustoEntitiesCount);
  const MDSEntityCountStatus = useSelector(selectMDSEntitiesCountStatus);
  const MDSEntityCount = useSelector(selectMDSEntitiesCount);

  // Services
  const servicesListStatus = useSelector(selectServiceListStatus);
  const servicesList = useSelector(selectAllServices);
  const servicesCount = useSelector(selectTotalServiceCount);

  const [isLoadingOffset, setIsLoadingOffset] = useState(false);
  const loadingOffsetPlaceholderItem = {
    name: 'offsetLoadingPlaceholder',
  };

  const shimmerColumnOnRender = (item: any, text: string | JSX.Element) => {
    return item === loadingOffsetPlaceholderItem ? <Shimmer /> : text;
  };

  const columns: IElxColumn[] = [
    {
      ...colExtraLarge,
      key: 'name',
      name: 'Entity Name',
      fieldName: 'name',
      disableSort: true,
      onRender(item) {
        let name = (
          <Link to={`/datacatalog/details/${item.manifestKey}/general`}>
            {item.name}
          </Link>
        );
        return shimmerColumnOnRender(item, name);
      },
    },
    {
      ...colXXLarge,
      key: 'namespace',
      name: 'Namespace',
      fieldName: 'namespace',
      disableSort: true,
      onRender(item) {
        return shimmerColumnOnRender(item, item.namespace);
      },
    },
    {
      ...colSmall,
      key: 'type',
      name: 'Type',
      fieldName: 'type',
      disableSort: true,
      onRender(item) {
        return shimmerColumnOnRender(item, item.type);
      },
    },
    {
      ...colMedium,
      key: 'service',
      name: 'Service',
      fieldName: 'service',
      disableSort: true,
      onRender(item) {
        return shimmerColumnOnRender(item, item.service);
      },
    },
    {
      ...colXXLarge,
      key: 'description',
      name: 'Description',
      fieldName: 'description',
      disableSort: true,
      onRender(item) {
        return shimmerColumnOnRender(item, item.description);
      },
    },
  ];

  const searchProps: IElxSearchBoxProps = {
    pillFilters: getPillFilters(currentCatalogEntityList, servicesList),
    filterDisplayMode: FilterDisplayMode.Pill,
    onChange: (value: ISearchCriteria) => {
      if (value.keyword === searchQuery.keyword) {
        callSearchAPI(value);
      } else {
        debouncedSetSearchQuery(value);
      }
    },
    onSearch: (value: ISearchCriteria) => {
      var request = createCatalogRequest(value);
      setIsLoadingOffset(false);
      dispatch(loadCatalogEntities(request));
    },
    styles: { keyword: { width: '300px' }, root: { marginLeft: '0.5rem' } },
  };

  const containerProps: IElxContainerProps = {
    isLoading:
      currentCatalogEntityListStatus === CatalogEntityStatus.Loading &&
      !isLoadingOffset,
    styles: {
      headerContainer: { overflow: 'visible' },
      headerComponent: { margin: '0px' },
      subHeader: { height: '100%', overflow: 'auto' },
      body: {
        padding: '0px',
        margin: '0rem 1rem',
      },
    },
    onRenderHeader: () => {
      return <h3>{'Data Catalog'}</h3>;
    },
    onRenderSubHeader: () => {
      return summaryCards(catalogSummaryCards);
    },
  } as IElxContainerProps;

  const catalogSummaryCards: summaryCard[] = [
    {
      id: 'matching',
      cardTitle: 'Matching Entities',
      body:
        currentCatalogEntityListStatus === CatalogEntityStatus.Loading &&
        !isLoadingOffset
          ? loadingCardBody()
          : loadedCardBody(currentCatalogEntityCount),
      autoCardWidth: true,
    },
    {
      id: 'total',
      cardTitle: 'Total Entities',
      body:
        totalCatalogEntityCountStatus === CatalogEntityStatus.Loading
          ? loadingCardBody()
          : loadedCardBody(totalCatalogEntityCount),
      autoCardWidth: true,
    },
    {
      id: 'kustoEntities',
      cardTitle: 'Kusto Entities',
      body:
        kustoEntityCountStatus === CatalogEntityStatus.Loading
          ? loadingCardBody()
          : loadedCardBody(kustoEntityCount),
      autoCardWidth: true,
    },
    {
      id: 'mdsEntities',
      cardTitle: 'MDS Entities',
      body:
        MDSEntityCountStatus === CatalogEntityStatus.Loading
          ? loadingCardBody()
          : loadedCardBody(MDSEntityCount),
      autoCardWidth: true,
    },
    {
      id: 'services',
      cardTitle: 'Services',
      body:
        servicesListStatus === ServiceListStatus.Loading
          ? loadingCardBody()
          : loadedCardBody(servicesCount),
      autoCardWidth: true,
    },
  ];

  const callSearchAPI = (value: ISearchCriteria) => {
    var request = createCatalogRequest(value);
    setIsLoadingOffset(false);
    dispatch(loadCatalogEntities(request));

    // the useEffect watching searchQuery will call the API after appropriate wait time
    setSearchQuery(value);
  };

  const getItems = () => {
    var items: any[] = currentCatalogEntityList
      .filter((item) => item.isSearchResult === true)
      .map((item, index) => {
        return {
          ...item,
          key: index,
        };
      });

    // Adds 3 solid "shimmer" rows while loading
    if (
      isLoadingOffset &&
      currentCatalogEntityListStatus === CatalogEntityStatus.Loading
    ) {
      items.push(
        loadingOffsetPlaceholderItem,
        loadingOffsetPlaceholderItem,
        loadingOffsetPlaceholderItem
      );
    }

    return items;
  };

  const tableProps: IElxTableProps = {
    styles: tableStyles,
    checkboxVisibility: CheckboxVisibility.hidden,
    onRowDidMount(item, index) {
      if (
        currentCatalogEntityList.length < currentCatalogEntityCount &&
        index &&
        index > currentCatalogEntityList.length - 5 &&
        currentCatalogEntityListStatus !== CatalogEntityStatus.Loading
      ) {
        setIsLoadingOffset(true);
        var request = createCatalogRequest(searchQuery);
        dispatch(
          loadCatalogEntities({
            ...request,
            Offset: currentCatalogEntityList.length,
          })
        );
      }
    },
    columns,
    items: getItems(),
    stickyHeader: true,
  };

  const elements = [
    renderSearchBox(debouncedSetSearchQuery, columns, searchProps),
  ];
  containerProps.headerContent = renderStackedElements(elements);

  return (
    <Stack
      // Hide Data Catalog List when viewing details page to maintain loaded list, search query, and filters
      styles={{
        root: {
          visibility: props.isVisible ? 'visible' : 'hidden',
          height: props.isVisible ? 'auto' : '0',
        },
      }}
    >
      <Stack.Item styles={{ root: { background: 'white' } }}>
        <Stack
          styles={{
            root: {
              padding: '20px',
              fontWeight: 600,
              fontSize: '1.375rem',
            },
          }}
        >
          {'Data Catalog'}
        </Stack>
      </Stack.Item>
      <Stack.Item grow verticalFill>
        <ElxContainer {...containerProps}>
          <ElxTable {...tableProps} />
        </ElxContainer>
      </Stack.Item>
    </Stack>
  );
};

export default CatalogEntityList;
