import * as monaco from 'monaco-editor';
import * as React from 'react';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { MonacoEditorProps as EditorProps } from './monakoEditorTypes';

function processSize(size: number | string) {
  return !/^\d+$/.test(size as string) ? size : `${size}px`;
}

function noop() {}

interface MonacoEditorProps extends EditorProps {
  onChange: (value: string) => void;
}

function MonacoEditor(props: MonacoEditorProps) {
  const {
    width,
    height,
    value,
    defaultValue,
    language,
    theme,
    options,
    overrideServices,
    editorWillMount,
    editorDidMount,
    editorWillUnmount,
    onChange,
    className,
  } = { ...MonacoEditor.defaultProps, ...props };
  const containerElement = useRef<HTMLDivElement | null>(null);

  const editor = useRef<monaco.editor.IStandaloneCodeEditor | null>(null);

  const _subscription = useRef<monaco.IDisposable | null>(null);

  const __prevent_trigger_change_event = useRef<boolean | null>(null);

  const fixedWidth = processSize(width);

  const fixedHeight = processSize(height);

  const style = useMemo(
    () => ({
      width: fixedWidth,
      height: fixedHeight,
    }),
    [fixedWidth, fixedHeight]
  );

  const handleEditorWillMount = useCallback(() => {
    const finalOptions = editorWillMount(monaco);
    return finalOptions || {};
  }, [editorWillMount]);

  const handleEditorDidMount = useCallback(() => {
    editorDidMount(editor.current!, monaco);

    _subscription.current = editor.current!.onDidChangeModelContent((event) => {
      if (__prevent_trigger_change_event.current) {
        onChange(editor.current!.getValue());
      }
    });
  }, [editorDidMount, onChange]);

  const handleEditorWillUnmount = useCallback(() => {
    editorWillUnmount(editor.current!, monaco);
  }, [editorWillUnmount]);

  const initMonaco = useCallback(() => {
    const finalValue = value !== null ? value : defaultValue;
    if (containerElement.current) {
      // Before initializing monaco editor
      const finalOptions = { ...options, ...handleEditorWillMount() };
      editor.current = monaco.editor.create(
        containerElement.current,
        {
          value: finalValue,
          language,
          ...(className ? { extraEditorClassName: className } : {}),
          ...finalOptions,
          ...(theme ? { theme } : {}),
        },
        overrideServices
      );
      // After initializing monaco editor
      handleEditorDidMount();
    }
  }, [
    className,
    defaultValue,
    handleEditorDidMount,
    handleEditorWillMount,
    language,
    options,
    overrideServices,
    theme,
    value,
  ]);

  useEffect(() => {
    initMonaco();
  }, [initMonaco]);

  // useEffect(() => {
  //   if (editor.current) {
  //     const model = editor.current.getModel();
  //     __prevent_trigger_change_event.current = true;
  //     editor.current.pushUndoStop();
  //     // pushEditOperations says it expects a cursorComputer, but doesn't seem to need one.
  //     model!.pushEditOperations(
  //       [],
  //       [
  //         {
  //           range: model!.getFullModelRange(),
  //           text: value,
  //         },
  //       ],
  //       (i) => null
  //     );
  //     editor.current.pushUndoStop();
  //     __prevent_trigger_change_event.current = false;
  //   }
  // }, [value]);

  useEffect(() => {
    if (editor.current) {
      const model = editor.current.getModel();
      monaco.editor.setModelLanguage(model!, language);
    }
  }, [language]);

  useEffect(() => {
    if (editor.current) {
      // Don't pass in the model on update because monaco crashes if we pass the model
      // a second time. See https://github.com/microsoft/monaco-editor/issues/2027
      const { model: _model, ...optionsWithoutModel } = options;
      editor.current.updateOptions({
        ...(className ? { extraEditorClassName: className } : {}),
        ...optionsWithoutModel,
      });
    }
  }, [className, options]);

  useEffect(() => {
    if (editor.current) {
      editor.current.layout();
    }
  }, [width, height]);

  useEffect(() => {
    monaco.editor.setTheme(theme!);
  }, [theme]);

  useEffect(
    () => () => {
      if (editor.current) {
        handleEditorWillUnmount();
        editor.current.dispose();
        const model = editor.current.getModel();
        if (model) {
          model.dispose();
        }
      }
      if (_subscription.current) {
        _subscription.current.dispose();
      }
    },
    [handleEditorWillUnmount]
  );

  return (
    <div
      ref={containerElement}
      style={style}
      className="react-monaco-editor-container"
      onBlurCapture={() => {
        if (props.onChange) {
          props.onChange(editor.current?.getValue() || '');
        }
      }}
    />
  );
}

MonacoEditor.defaultProps = {
  width: '100%',
  height: '100%',
  value: null,
  defaultValue: '',
  language: 'javascript',
  theme: null,
  options: { model: null },
  overrideServices: {},
  editorWillMount: noop,
  editorDidMount: noop,
  editorWillUnmount: noop,
  onChange: noop,
  className: null,
};

export default MonacoEditor;
