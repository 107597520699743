import {
  EdgeMatrix,
  ElxActionButton,
  ElxDateTimePicker,
  ElxDateTimePickerDisplayMode,
  ElxDialog,
  ElxDropdown,
  ElxIconButton,
  ElxLink,
  ElxMasterDetailContainer,
  ElxTagPicker,
  ElxTextField,
  IElxMasterDetailTabProps,
  IElxSecondaryButtonProps,
  ITagData,
  InputMessageTypes,
  TagContainerOrientation,
} from '@elixir/components';
import { Label, Stack, Toggle } from '@fluentui/react';
import DayHourMinSecPicker from 'components/dayHourMinSecPicker';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useState, MouseEvent, useMemo } from 'react';
import { orchestratorConstants } from 'utils/constants';
import { LensLabel } from 'utils/lensLabel';
import utils from 'utils/utils';
import {
  Activity,
  ActivityTypes,
  Dependency,
  DependencySettings,
  Job,
  parameterType,
  restartPolicy,
  retryPolicyType,
  severityLevels,
} from '../models/job';
import { JobParameterItemsWithType } from './jobParameterItemsWithType';
import 'components/cron/src/styles.css';
import { JobScheduleDependencyEntity } from './jobSchedule/jobScheduleDependencyEntity';
import { JobActivity } from './JobActivities/JobActivity';

import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import jobApi from '../api/jobApi';
import notifier from 'utils/notifier';
import {
  selectWorkspace,
  selectWorkspaceId,
} from 'features/workspaces/workspaceSlice';
import { JobScheduleDelta } from './jobSchedule/jobScheduleDelta';
import { CronEx } from './CronEx';
import ReactFlow, {
  MiniMap,
  Background,
  Controls,
  Node as flNode,
  Position,
  Handle,
  Edge,
  BackgroundVariant,
  MarkerType,
} from 'reactflow';
import 'reactflow/dist/style.css';
import { getDataSource } from '../utils/jobUtils';
import { ProjectDataConnection } from 'features/workspaces/models/project';
import EditorFull from './editorFull';
import { IRange } from 'monaco-editor';
import _ from 'lodash';
import {
  activityInfoDetails,
  activityInfoTitle,
  alignEnds,
  groupBorder,
} from './JobActivities/JobAuthorStyles';
import { Card } from 'components/cards/card';
import { JobAccordian } from '../utils/jobAccordian';

export enum DialogType {
  None = 'None',
  Activity = 'Activity',
}

const defaultDependencySettings: DependencySettings = {
  dependencies: [],
  triggerPolicy: 'All',
  reTriggerPolicy: 'Never',
  submissionDelay: '',
  timeout: null,
  windowSize: null,
  startTime: '',
  windowType: 1,
  windowsPerScan: null,
  onlyFinishedWindows: true,
};

const nodeWid = 360;

const NodeRect: any = {
  DataCommand: { height: 136, width: nodeWid },
  DataMovement: { height: 160, width: nodeWid },
  DataValidation: { height: 136, width: nodeWid },
  DataEvent: { height: 136, width: nodeWid },
  T4Transform: { height: 160, width: nodeWid },
  Noop: { height: 136, width: nodeWid }, // Orchestrator has test jobs with Noop as activity type.
};

interface JobAuthorProps {
  job?: Job;
  onCancel?: () => void;
  onSuccess?: () => void;
}

const newJobTemplate = (): Job | any => ({
  id: '',
  description: '',
  tags: '',
  config: {
    maxJobInstances: 1,
    jobScheduleSettings: {
      scheduleType: 'Frequency',
      frequencySettings: {
        cronSchedule: '0 0 0 */1 * *',
        lagTime: '0.00:00:00',
      },
      startTime: DateTime.now().toISO(),
    },
    context: {
      uiCron: {
        freq: 'day',
        rate: 1,
        value: '0 0 0 */1 * *',
        isEntered: false,
        hourMins: 0,
        minuteSecs: 0,
        time: '2022-10-01T07:00:00.000Z',
        weekDays: [true, false, false, false, false, false, false],
        monthDay: 1,
      },
      uiPeriodBetweenJobs: '01.00:00:00',
    },
    defaultICMAlerts: false,
    retryPolicy: {
      type: 'None',
    },
    activities: [],
    parameters: [],
    maxFailedJobDuration: null,
    maxExecutionTime: null,
  },
});

export const jobDependencyTypeOptions = ['Job', 'Data'];

let tabs: IElxMasterDetailTabProps[];
const maxFormWidth = 600;
export const retryPolicyOptions = retryPolicyType.map((o) => ({
  key: o,
  text: o,
}));
const restartPolicyOptions = restartPolicy.map((o, i) => ({ key: i, text: o }));
const severityOptions = severityLevels.map((o) => ({
  key: o,
  text: `Severity ${o}`,
}));
const scheduleTypes = [
  'Only when user starts it explicitly (Manual)',
  'When a fixed schedule is up (Frequency)',
  'Sometime after the previous execution completes (Periods between jobs)',
  'When certain data becomes available/activity completes (Dependency)',
];
const scheduleOptions = scheduleTypes.map((o, i) => ({ key: i, text: o }));

export const parameterOptionsType = parameterType.map((o, i) => {
  return { key: i, text: o };
});

const triggerPolicyOptions = ['All', 'Any'].map((o, i) => ({
  key: o,
  text: o,
}));

const reTriggerPolicyOptions = ['All', 'Any', 'Never'].map((o, i) => ({
  key: o,
  text: o,
}));

const windowTypeOptions = Object.entries(
  orchestratorConstants.WindowTypeOptionsValues
).map(([k, v]) => ({ key: v, text: k }));

const JobAuthor = (props: JobAuthorProps) => {
  const [job, setJob] = useState<Job>(
    props.job
      ? { ...props.job }
      : { ...newJobTemplate(), ...{ id: utils.newGuid() } }
  );

  const [actIdx, setActIdx] = useState(-1);
  const [addNewActivity, setAddNewActivity] = useState(false);
  const [depIdx, setDepIdx] = useState(-1);
  const [addNewDependencyEntity, setAddNewDependencyEntity] = useState(false);
  const sideEdges = false;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [forceRender, setForceRender] = useState<boolean>(false);
  const [editActId, setEditActId] = useState<string | null>(null);
  const [disableSaveBtn, setDisableSaveBtn] = useState<boolean>(false);
  const [discardDialog, setDiscardDialog] = useState<boolean>(false);

  const workspaceId = useSelector(selectWorkspaceId);
  const workspace = useSelector(selectWorkspace);

  const history = useHistory();

  job!.config!.context = job!.config!.context || {};
  job!.config!.context!.nodesPosition =
    job!.config!.context?.nodesPosition || {};

  const verifyJob = useCallback((): boolean => {
    // Nambi - TODO - we need to have comphrensive testing to verify the job is correct.

    return !!(
      job.id &&
      job.name &&
      job.config?.activities &&
      job.config?.activities?.length > 0
    );
  }, [job]);

  const createJob = useCallback(
    async (workspaceId: string, job: Job) => {
      try {
        let p: Job;
        setDisableSaveBtn(true);
        if (props.job) {
          p = await jobApi.updateJob(workspaceId, job.id, job);
        } else {
          p = await jobApi.createJob(workspaceId, job);
        }
        setDisableSaveBtn(false);
        notifier.info(`Job successfully created/saved name=${p.name}`);
        props.onSuccess ? props.onSuccess() : history.push('/job/list');
      } catch (err: any) {
        setDisableSaveBtn(false);
        notifier.error(
          `Creating/Saving of job failed, name=${job.name} error=${err} message=${err.response?.data?.error?.message}`
        );
      }
    },
    [history, props]
  );

  const edges: EdgeMatrix = {};
  edges[''] = {};

  const NonLeafNodeSet = new Set();

  const showPartitionedProperties =
    job!.config!.jobScheduleSettings.dependencySettings?.dependencies.length ===
      0 ||
    job!.config!.jobScheduleSettings.dependencySettings?.dependencies.reduce(
      (p, c) => {
        return p || Boolean(c.partitionSize);
      },
      false
    );
  if (showPartitionedProperties) {
    if (
      job!.config!.jobScheduleSettings.dependencySettings!.windowType ===
      undefined
    ) {
      job!.config!.jobScheduleSettings.dependencySettings!.windowType =
        orchestratorConstants.WindowTypeOptionsValues.Custom;
    }

    job!.config!.jobScheduleSettings.dependencySettings!.onlyFinishedWindows =
      job!.config!.jobScheduleSettings.dependencySettings!
        .onlyFinishedWindows || false;
    job!.config!.jobScheduleSettings.dependencySettings!.oldestFirst =
      job!.config!.jobScheduleSettings.dependencySettings!.oldestFirst || false;
    if (
      job!.config!.jobScheduleSettings.dependencySettings!.windowType ===
      orchestratorConstants.WindowTypeOptionsValues.Custom
    ) {
      job!.config!.jobScheduleSettings.dependencySettings!.windowSize =
        job!.config!.jobScheduleSettings.dependencySettings!.windowSize ||
        '0.01:00:00';
    } else {
      job!.config!.jobScheduleSettings.dependencySettings!.windowSize = null;
    }
    if (
      job!.config!.jobScheduleSettings.dependencySettings!.windowType !==
      orchestratorConstants.WindowTypeOptionsValues.Snapshot
    ) {
      job!.config!.jobScheduleSettings.dependencySettings!.windowsPerScan =
        job!.config!.jobScheduleSettings.dependencySettings!.windowsPerScan ||
        4;
    } else {
      job!.config!.jobScheduleSettings.dependencySettings!.windowsPerScan =
        null;
    }
  }

  // Construct nodes
  // #region FLOW
  const flowNodes: any = [];
  const flowEdges: any = [];
  const startX = 100;
  const horizonDisplacement = 40;
  const verticalDispacement = 40;

  const getIcon = (actType: string): string => {
    let iconName = 'Script';
    switch (actType) {
      case ActivityTypes.DataCommand:
        iconName = 'Script';
        break;
      case ActivityTypes.DataMovement:
        iconName = 'DataManagementSettings';
        break;
      case ActivityTypes.DataValidation:
        iconName = 'PromotedDatabase';
        break;
      case ActivityTypes.DataEvent:
        iconName = 'SetAction';
        break;
      case ActivityTypes.T4Transform:
        iconName = 'Script';
        break;
    }
    return iconName;
  };

  const GetActInfo = (props: Activity): JSX.Element => {
    let info = <Stack.Item>{props.id}</Stack.Item>;
    let dcs: ProjectDataConnection | null = null;
    let dct: ProjectDataConnection | null = null;
    switch (props.activityType) {
      case ActivityTypes.DataCommand:
        let dc = getDataSource(props.output?.connectionName, workspace);
        info = (
          <>
            <Stack.Item className={activityInfoTitle}>
              {'Data Connection'}
            </Stack.Item>
            <Stack.Item className={activityInfoDetails}>{`${
              dc?.type || props.output?.type
            } | ${dc?.database}`}</Stack.Item>
            <Stack.Item className={activityInfoTitle}>{'Script'}</Stack.Item>
            <Stack.Item className={activityInfoDetails}>{`${
              props.script?.substring(0, 30) + '...'
            }`}</Stack.Item>
          </>
        );
        break;
      case ActivityTypes.DataMovement:
        dcs = getDataSource(props.input?.connectionName, workspace);
        dct = getDataSource(props.output?.connectionName, workspace);
        info = (
          <>
            <Stack.Item className={activityInfoTitle}>
              {'Source Data Connection'}
            </Stack.Item>
            <Stack.Item className={activityInfoDetails}>{`${
              dcs?.type || props.input?.type
            } | ${dcs?.database}`}</Stack.Item>

            <Stack.Item className={activityInfoTitle}>
              {'Targe Data Connection'}
            </Stack.Item>
            <Stack.Item className={activityInfoDetails}>{`${
              dct?.type || props.output?.type
            } | ${dct?.database}`}</Stack.Item>

            <Stack.Item className={activityInfoTitle}>
              {'Data Movement Type'}
            </Stack.Item>
            <Stack.Item
              className={activityInfoDetails}
            >{`${props.input?.tableType}`}</Stack.Item>
          </>
        );
        break;
      case ActivityTypes.DataValidation:
        dcs = getDataSource(props.input?.connectionName, workspace);
        info = (
          <>
            <Stack.Item className={activityInfoTitle}>
              {'Data Connection'}
            </Stack.Item>
            <Stack.Item className={activityInfoDetails}>{`${
              dcs?.type || props.input?.type
            } | ${dcs?.database}`}</Stack.Item>

            <Stack.Item className={activityInfoTitle}>
              {`${
                props.validationQuery ? 'Validation Query' : 'Validation modes'
              }`}
            </Stack.Item>
            <Stack.Item className={activityInfoDetails}>{`${
              props.validationQuery
                ? props.validationQuery.substring(0, 30) + '...'
                : (dcs?.type || props.input?.type) ===
                  orchestratorConstants.EndpointTypes.CosmosStream
                ? 'Cosmos Validation'
                : 'Percentage/NullCount etc'
            }`}</Stack.Item>
          </>
        );
        break;
      case ActivityTypes.DataEvent:
        dcs = getDataSource(props.input?.connectionName, workspace);
        info = (
          <>
            <Stack.Item className={activityInfoTitle}>
              {'Data Connection'}
            </Stack.Item>
            <Stack.Item className={activityInfoDetails}>{`${
              dcs?.type || props.input?.type
            }`}</Stack.Item>

            <Stack.Item className={activityInfoTitle}>{`Message`}</Stack.Item>
            <Stack.Item className={activityInfoDetails}>
              {props.eventContents?.substring(0, 30) + '...'}
            </Stack.Item>
          </>
        );
        break;
      case ActivityTypes.T4Transform:
        dcs = getDataSource(props.input?.connectionName, workspace);
        info = (
          <>
            <Stack.Item className={activityInfoTitle}>
              {'Data Connection'}
            </Stack.Item>
            <Stack.Item className={activityInfoDetails}>{`${
              dcs?.type || props.input?.type
            }`}</Stack.Item>

            <Stack.Item
              className={activityInfoTitle}
            >{`T4 Script Source Path`}</Stack.Item>
            <Stack.Item className={activityInfoDetails}>
              {props.t4Script?.sourcepath?.substring(0, 30) + '...'}
            </Stack.Item>
          </>
        );
        break;
    }
    return info;
  };

  const CustomNode = ({ data }: any) => {
    return (
      <div id={data.activity?.id}>
        <Handle
          type="target"
          position={data.tgtEdge}
          style={{ background: 'lightblue', height: 12, width: 12 }}
          onClick={(e: MouseEvent) => e.stopPropagation()}
        />
        <Stack>
          <Card styles={{ root: { width: nodeWid } }}>
            <Stack.Item>
              <Stack
                horizontal
                grow
                className={alignEnds}
                styles={{
                  root: {
                    paddingBottom: '0px',
                    borderBottom: '1px solid #E0E0E0',
                  },
                }}
              >
                <Stack.Item>
                  <Stack horizontal grow>
                    <Stack.Item>
                      <ElxIconButton
                        text=""
                        styles={{
                          root: {
                            pointerEvents: 'none',
                            padding: '5px 0px 0px 15px',
                          },
                        }}
                        iconProps={{
                          iconName: getIcon(data.activity.activityType),
                        }}
                      ></ElxIconButton>
                    </Stack.Item>
                    <Stack.Item>
                      <Label>
                        {data.activity.activityDisplayName || data.activity.id}
                      </Label>
                    </Stack.Item>
                  </Stack>
                </Stack.Item>
                <Stack.Item
                  styles={{
                    root: {
                      'ms-Button': {
                        border: 'transparent !important',
                        background: 'transparent !important',
                      },
                    },
                  }}
                >
                  <ElxIconButton
                    text="Activity Actions"
                    styles={{
                      menuIcon: {
                        // color: 'blue',
                        fontWeight: 800,
                        fontSize: 'large',
                      },
                      splitButtonMenuButton: {
                        border: 'transparent !important',
                        background: 'transparent !important',
                        padding: '5px 0px',
                      },
                    }}
                    // iconProps={{ iconName: 'MoreVertical' }}
                    menuIconProps={{ iconName: 'MoreVertical' }}
                    role="button"
                    aria-haspopup={true}
                    split={true}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    menuProps={{
                      alignTargetEdge: true,
                      items: [
                        {
                          key: 'Edit',
                          text: 'Edit',
                          iconProps: { iconName: 'edit' },
                          onClick: () => {
                            setActIdx(data.nodeRankHint);
                          },
                        },
                        {
                          key: 'Editcode',
                          text: 'Edit Code',
                          iconProps: { iconName: 'script' },
                          onClick: () => {
                            setEditActId(data.activity.id);
                          },
                        },
                        {
                          key: 'delete',
                          text: 'Delete',
                          iconProps: { iconName: 'delete' },
                          disabled: NonLeafNodeSet.has(data.activity.id),
                          onClick: () => {
                            if (!NonLeafNodeSet.has(data.activity.id)) {
                              job.config!.activities =
                                job.config!.activities!.filter(
                                  (a) => a.id !== data.activity.id
                                );
                              setForceRender((b) => !b);
                            } else {
                              console.log('Can delete only leaf nodes');
                            }
                          },
                        },
                      ],
                    }}
                  ></ElxIconButton>
                </Stack.Item>
              </Stack>
            </Stack.Item>
            <Stack.Item>
              <Stack>{GetActInfo(data.activity)}</Stack>
            </Stack.Item>
          </Card>
        </Stack>
        <Handle
          type="source"
          position={data.srcEdge}
          style={{ background: 'lightblue', height: 12, width: 12 }}
          onClick={(e: MouseEvent) => e.stopPropagation()}
        />
      </div>
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const nodeTypes = useMemo(() => ({ special: CustomNode }), []);
  let pointTaken = new Set();

  for (let index = 0; index < job.config!.activities!.length; index++) {
    const key = job.config!.activities![index].id;

    let parentActIdx: number | undefined = -1;

    job.config!.activities![index].dependsOn?.forEach((d) => {
      flowEdges.push({
        id: `${d}-${key}`,
        source: d.activityId,
        target: key,
        style: { strokeWidth: 2, stroke: 'lightblue' },
        markerEnd: { type: MarkerType.ArrowClosed, color: 'lightblue' },
        type: 'smoothstep',
      });
      if (parentActIdx === -1) {
        parentActIdx = job!.config!.activities?.findIndex(
          (a) => a.id === d.activityId
        );
        if (parentActIdx === undefined) {
          parentActIdx = -1;
        }
      }
      NonLeafNodeSet.add(d.activityId);
    });

    if (!job!.config!.context!.nodesPosition[key]) {
      job!.config!.context!.nodesPosition[key] = {
        x: startX,
        y:
          (parentActIdx !== -1
            ? job.config!.context?.nodesPosition[
                job.config!.activities![parentActIdx]!.id
              ].y +
              (job.config!.activities![parentActIdx]!.activityType in NodeRect
                ? NodeRect[job.config!.activities![parentActIdx]!.activityType]
                    .height
                : 0)
            : 0) + verticalDispacement,
      };

      while (
        pointTaken.has(
          `${job!.config!.context!.nodesPosition[key].x}-${
            job!.config!.context!.nodesPosition[key].y
          }`
        )
      ) {
        job!.config!.context!.nodesPosition[key].x =
          job!.config!.context!.nodesPosition[key].x +
          (nodeWid + horizonDisplacement);
      }
    }
    pointTaken.add(
      `${job!.config!.context!.nodesPosition[key].x}-${
        job!.config!.context!.nodesPosition[key].y
      }`
    );

    const flowNode: flNode = {
      id: key,
      type: 'special',
      data: {
        label: job.config!.activities![index].activityDisplayName,
        activity: job.config!.activities![index],
        nodeRankHint: index,
        srcEdge: sideEdges ? Position.Right : Position.Bottom,
        tgtEdge: sideEdges ? Position.Left : Position.Top,
      },
      position: {
        x: job!.config!.context!.nodesPosition[key].x,
        y: job!.config!.context!.nodesPosition[key].y,
      },
      width: 100,
      height: 200,
    };

    flowNodes.push(flowNode);
  }

  const onNodesChange = useCallback(
    (changes) => {
      if (changes.length) {
        changes.forEach((e: any) => {
          if (e.position) {
            job!.config!.context!.nodesPosition[e.id].x = e.position.x;
            job!.config!.context!.nodesPosition[e.id].y = e.position.y;
          }
        });
        setForceRender((b) => !b);
      }
    },
    [job]
  );
  const onEdgesChange = (changes: any[]) => {
    if (changes.length) {
      changes.forEach((e: any) => {
        if (e.type === 'remove') {
          const ed = flowEdges.find((a: any) => a.id === e.id);
          if (ed) {
            const act = job!.config!.activities!.find((a) => {
              return a.id === ed.target;
            });
            if (act) {
              act.dependsOn =
                act.dependsOn?.filter((d) => {
                  return d !== ed.source;
                }) || [];
            }
          }
        }
        setForceRender((b) => !b);
      });
    }
  };

  const onEdgesDelete = useCallback(
    (edges: Edge[]) => {
      if (edges.length) {
        edges.forEach((e) => {
          const act = job!.config!.activities!.find((a) => {
            return a.id === e.target;
          });
          if (act) {
            act.dependsOn =
              act.dependsOn?.filter((d) => {
                return d.activityId !== e.source;
              }) || [];
          }
        });
        setForceRender((b) => !b);
      }
    },
    [job]
  );

  const onEdgeDoubleClick = useCallback(
    (event: MouseEvent, e: Edge) => {
      if (e) {
        const act = job!.config!.activities!.find((a) => {
          return a.id === e.target;
        });
        if (act) {
          act.dependsOn =
            act.dependsOn?.filter((d) => {
              return d.activityId !== e.source;
            }) || [];
        }
      }
      event.stopPropagation();
      event.preventDefault();
    },
    [job]
  );

  const onConnect = useCallback(
    (params) => {
      const act = job!.config!.activities!.find((a) => {
        return a.id === params.target;
      });
      if (act) {
        if (!act.dependsOn?.find((d) => d.activityId === params.source)) {
          act.dependsOn?.push({ activityId: params.source });
          setForceRender((b) => !b);
        }
      }
    },
    [job]
  );

  const onNodeClick = useCallback(
    (event, node) => {
      setActIdx(node.data.nodeRankHint!);
    },
    [setActIdx]
  );
  // #endregion FLOW
  const getSchedule = useCallback((): number => {
    switch (job.config?.jobScheduleSettings.scheduleType) {
      case 'Manual':
        return 0;
        // eslint-disable-next-line no-unreachable
        break;
      case 'Frequency':
        if (
          job.config?.jobScheduleSettings?.frequencySettings
            ?.periodBetweenJobs !== undefined
        ) {
          return 2;
        } else {
          return 1;
        }
        // eslint-disable-next-line no-unreachable
        break;
      case 'Dependency':
        return 3;
        // eslint-disable-next-line no-unreachable
        break;
      default:
        break;
    }

    return 0;
  }, [job]);

  const setSchedule = useCallback(
    (scheduleKey: number): void => {
      switch (scheduleKey) {
        case 1:
          delete job.config?.jobScheduleSettings?.dependencySettings;
          job.config!.jobScheduleSettings!.frequencySettings = {
            cronSchedule: '0 0 * * *',
            lagTime: '0.00:00:00',
          };
          job.config!.jobScheduleSettings.scheduleType = 'Frequency';
          break;
        case 2:
          delete job.config?.jobScheduleSettings?.dependencySettings;
          job.config!.jobScheduleSettings!.frequencySettings = {
            lagTime: '0.00:00:00',
            periodBetweenJobs: '1.00:00:00',
          };
          job.config!.jobScheduleSettings.scheduleType = 'Frequency';
          break;
        case 3:
          delete job.config?.jobScheduleSettings?.frequencySettings;
          job.config!.jobScheduleSettings!.dependencySettings = {
            ...defaultDependencySettings,
          };
          job.config!.jobScheduleSettings.scheduleType = 'Dependency';
          break;
        case 0:
        default:
          delete job.config?.jobScheduleSettings?.frequencySettings;
          delete job.config?.jobScheduleSettings?.dependencySettings;
          job.config!.jobScheduleSettings.scheduleType = 'Manual';
          break;
      }
      setJob({ ...job });
    },
    [job]
  );

  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);

  const heightHandler = useCallback(() => {
    const elements = document.getElementsByClassName('md-tabpanel-inner'); // There is only one element with this class name in the job Author context
    setHeight(Math.max(elements[0].clientHeight - 66, 0));
    setWidth(Math.max(elements[0].clientWidth - 15, 0));
  }, [setHeight, setWidth]);

  useEffect(() => {
    window.addEventListener('resize', heightHandler);
    return () => {
      window.removeEventListener('resize', heightHandler);
    };
  });

  useEffect(() => {
    heightHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const backtoJobList: IElxSecondaryButtonProps = {
    text: 'Back to Job List',
    onClick: () => {
      if (JSON.stringify(job) !== JSON.stringify(props.job || {})) {
        setDiscardDialog(true);
      } else {
        props.onCancel ? props.onCancel() : history.push('/job/list');
      }
    },
  };

  const saveAndClose: IElxSecondaryButtonProps = {
    text: 'Save and Close',
    onClick: () => {
      createJob(workspaceId, job);
    },
    disabled: disableSaveBtn,
  };

  tabs = [
    {
      key: 'Basics',
      name: 'Basics',
      component: (
        <Stack
          styles={{ root: { maxWidth: maxFormWidth } }}
          tokens={{ childrenGap: 16, padding: 24 }}
        >
          <Stack.Item>
            <ElxTextField
              id={'Name'}
              onRenderLabel={() => (
                <LensLabel
                  labelText="Name"
                  hintText="Job Name"
                  required
                ></LensLabel>
              )}
              value={job.name}
              deferredValidationTime={500}
              onChange={(e, value) => {
                job.name = value || '';
                setJob({ ...job });
              }}
            ></ElxTextField>
            <br />
            <ElxTextField
              id={'Job Id'}
              onRenderLabel={() => (
                <LensLabel
                  labelText="Job Id"
                  hintText="Unique identifier for the Job"
                  required
                ></LensLabel>
              )}
              value={job.id}
              disabled={true}
            ></ElxTextField>
            <br />
            <ElxTextField
              id={'Description'}
              onRenderLabel={() => (
                <LensLabel
                  labelText="Description"
                  hintText="Job description - some notes regarding this job, like  what is job's intent"
                ></LensLabel>
              )}
              multiline={true}
              rows={4}
              value={job.description}
              deferredValidationTime={500}
              onChange={(e, value) => {
                job.description = value || '';
                setJob({ ...job });
              }}
            ></ElxTextField>
            <br />
            <ElxTextField
              id={'Folder'}
              onRenderLabel={() => (
                <LensLabel
                  labelText="Folder"
                  hintText="Folder name for grouping jobs - if none(empty) given, job will be not placed in any folder"
                ></LensLabel>
              )}
              value={job.config?.folder}
              deferredValidationTime={500}
              onChange={(e, value) => {
                job.config!.folder = value || '';
                if (job.config!.folder === '') {
                  delete job.config!.folder;
                }
                setJob({ ...job });
              }}
            ></ElxTextField>
            <br />
            <LensLabel
              labelText="Tags"
              hintText="List of tags associated with the job"
            ></LensLabel>
            <ElxTagPicker
              items={
                job.tags
                  ? job.tags.split(',').map((o, i): ITagData => {
                      return { key: i.toString(), text: o };
                    })
                  : ([] as ITagData[])
              }
              onAddTag={function (tag: ITagData): void {
                let tags = job.tags ? job?.tags.split(',') : [];
                tags.push(tag.text);
                job.tags = tags.join(',');
                setJob({ ...job });
              }}
              onRemoveTag={function (tag: ITagData): void {
                let tags = job.tags ? job?.tags.split(',') : [];
                if (tags.length > 0) {
                  tags = tags.filter((o) => o !== tag.text);
                  job.tags = tags.join(',');
                  setJob({ ...job });
                }
              }}
              orientation={TagContainerOrientation.Horizontal}
            ></ElxTagPicker>
          </Stack.Item>
        </Stack>
      ),
      primaryButton: {
        disabled: !(job.id && job.name),
      },
      secondaryButton: saveAndClose,
      cancelButton: backtoJobList,
      backButton: {
        disabled: false,
      },
    },
    {
      key: 'Activities',
      name: 'Activities',
      component: (
        <div>
          <Stack
            horizontal
            grow
            tokens={{ childrenGap: 16 }}
            styles={{
              root: {
                padding: 16,
                backgroundColor: 'rgb(250, 249, 248)',
              },
            }}
          >
            <Stack.Item>
              <ElxActionButton
                iconProps={{
                  iconName: 'Add',
                }}
                text="Add New Activity"
                onClick={() => {
                  setAddNewActivity(true);
                }}
              />
            </Stack.Item>
          </Stack>
          <Stack grow>
            <>
              <div style={{ height: height, width: width }}>
                <ReactFlow
                  nodeTypes={nodeTypes}
                  nodes={flowNodes}
                  edges={flowEdges}
                  onNodeClick={onNodeClick}
                  onNodesChange={onNodesChange}
                  onEdgesChange={onEdgesChange}
                  onEdgesDelete={onEdgesDelete}
                  onEdgeDoubleClick={onEdgeDoubleClick}
                  onConnect={onConnect}
                  zoomOnDoubleClick={false}
                  minZoom={0.75}
                >
                  <Controls />
                  <Background variant={BackgroundVariant.Cross} />
                  <MiniMap zoomable pannable />
                </ReactFlow>
              </div>
            </>
          </Stack>
        </div>
      ),
      primaryButton: {
        disabled: !(
          job.config?.activities && job.config?.activities.length > 0
        ),
      },
      secondaryButton: saveAndClose,
      cancelButton: backtoJobList,
      backButton: {
        disabled: false,
      },
    },
    {
      key: 'Schedule',
      name: 'Schedule',
      component: (
        <Stack tokens={{ childrenGap: 16, padding: 24 }}>
          <Stack.Item>
            <ElxDropdown
              id={'When should the job run?'}
              onRenderLabel={() => (
                <LensLabel
                  labelText="When should the job run?"
                  hintText={'When should the job run?'}
                  required={true}
                ></LensLabel>
              )}
              options={scheduleOptions}
              selectedKey={getSchedule()}
              onChange={(_, value) => {
                let k = +value!.key;
                if (k === 3) {
                  job.config!.jobScheduleSettings.dependencySettings =
                    job.config!.jobScheduleSettings.dependencySettings || {
                      ...defaultDependencySettings,
                    };
                  job.config!.jobScheduleSettings.dependencySettings!.dependencies =
                    job.config!.jobScheduleSettings.dependencySettings
                      ?.dependencies || [];
                }
                setSchedule(+value!.key);
              }}
              message={
                getSchedule() === 0
                  ? {
                      content:
                        'This job is set to triggered manually. You can the job by using the Run Once action on the job list page..',
                      type: InputMessageTypes.Info,
                    }
                  : undefined
              }
            ></ElxDropdown>
          </Stack.Item>
          <Stack.Item>
            {getSchedule() === 1 && (
              <>
                <LensLabel
                  labelText="Cron Schedule"
                  hintText={'Cron Schedule'}
                  required={true}
                ></LensLabel>
                <Stack
                  tokens={{ childrenGap: 16, padding: 16 }}
                  styles={{ root: { border: '1px solid gray' } }}
                >
                  <Stack.Item>
                    <LensLabel
                      labelText="Build cron expression"
                      hintText={'Build cron Expression'}
                    ></LensLabel>
                  </Stack.Item>
                  <Stack.Item>
                    <CronEx
                      value={
                        job.config?.jobScheduleSettings.frequencySettings
                          ?.cronSchedule || '0 * * 1 * *'
                      }
                      setValue={(
                        value: string
                        // extra: SetValueFunctionExtra
                      ): void => {
                        job.config!.jobScheduleSettings.frequencySettings!.cronSchedule =
                          value;
                        setJob({ ...job });
                      }}
                    ></CronEx>
                  </Stack.Item>
                  <Stack.Item className={groupBorder}>
                    {' '}
                    <JobAccordian
                      isOpen={false}
                      text={'Advanced Cron Option'}
                      hint={'Advanced Cron Option'}
                    >
                      <Stack tokens={{ childrenGap: 16, padding: 16 }}>
                        <ElxTextField
                          onRenderLabel={() => (
                            <LensLabel
                              labelText="Enter valid cron expression"
                              hintText={'Cron Expression'}
                            ></LensLabel>
                          )}
                          onBlur={(e) => {
                            job.config!.jobScheduleSettings.frequencySettings!.cronSchedule =
                              e.target.value;
                            setJob({ ...job });
                          }}
                          onChange={(e, value) => {
                            job.config!.jobScheduleSettings.frequencySettings!.cronSchedule =
                              value;
                            setJob({ ...job });
                          }}
                          value={
                            job.config!.jobScheduleSettings.frequencySettings!
                              .cronSchedule
                          }
                        ></ElxTextField>
                      </Stack>
                    </JobAccordian>
                  </Stack.Item>
                </Stack>
                <ElxDateTimePicker
                  onRenderLabel={() => {
                    return (
                      <LensLabel
                        labelText="Start Time"
                        hintText={'The time when the job starts executing.'}
                        required={false}
                      ></LensLabel>
                    );
                  }}
                  defaultValue={
                    job.config!.jobScheduleSettings.startTime ||
                    DateTime.now().toISO()
                  }
                  displayMode={ElxDateTimePickerDisplayMode.SideBySide}
                  onSelectDateTime={(dateTime?: string) => {
                    job.config!.jobScheduleSettings.startTime = dateTime!;
                    setJob({ ...job });
                  }}
                  timeZones={[{ utcOffsetMinutes: 0, symbol: 'UTC' }]}
                />
                <br />
                <LensLabel
                  labelText="Lag Time"
                  hintText={
                    'The time to delay the job. A job scheduled at 12:00 AM with 15 minute lag time will start at 12:15 AM with the startDateTime parameter equal to 12:00 AM.'
                  }
                  required={false}
                ></LensLabel>
                <DayHourMinSecPicker
                  setValue={
                    job.config?.jobScheduleSettings?.frequencySettings
                      ?.lagTime || '0.00:00:00'
                  }
                  onChange={(value: string): void => {
                    job.config!.jobScheduleSettings!.frequencySettings!.lagTime =
                      value;
                    setJob({ ...job });
                  }}
                  maxDays={1}
                  showDays={true}
                  showHours={true}
                  showMinutes={true}
                  showSeconds={true}
                ></DayHourMinSecPicker>
              </>
            )}
            {getSchedule() === 2 && (
              <>
                <LensLabel
                  labelText="Period Between Jobs"
                  hintText={
                    'The period to wait between each job instance. As example, a job with periodBetweenJobs = 01:00:00 (1 hour) will create a new job instance every hour.'
                  }
                  required={false}
                ></LensLabel>
                <DayHourMinSecPicker
                  setValue={
                    job.config?.jobScheduleSettings?.frequencySettings
                      ?.periodBetweenJobs || '0.00:00:00'
                  }
                  onChange={(value: string): void => {
                    job.config!.jobScheduleSettings!.frequencySettings!.periodBetweenJobs =
                      value;
                    setJob({ ...job });
                  }}
                  maxDays={1}
                  showDays={true}
                  showHours={true}
                  showMinutes={true}
                  showSeconds={true}
                ></DayHourMinSecPicker>
                <br />

                <LensLabel
                  labelText="Lag Time"
                  hintText={
                    'The time to delay the job. A job scheduled at 12:00 AM with 15 minute lag time will start at 12:15 AM with the startDateTime parameter equal to 12:00 AM.'
                  }
                  required={false}
                ></LensLabel>
                <DayHourMinSecPicker
                  setValue={
                    job.config?.jobScheduleSettings?.frequencySettings
                      ?.lagTime || '0.00:00:00'
                  }
                  onChange={(value: string): void => {
                    job.config!.jobScheduleSettings!.frequencySettings!.lagTime =
                      value;
                    setJob({ ...job });
                  }}
                  maxDays={1}
                  showDays={true}
                  showHours={true}
                  showMinutes={true}
                  showSeconds={true}
                ></DayHourMinSecPicker>
              </>
            )}
            {getSchedule() === 3 && (
              <>
                <ElxDropdown
                  onRenderLabel={() => {
                    return (
                      <LensLabel
                        labelText="Trigger policy"
                        hintText={`Tells when a new job instance is triggered based on the dependencies.
                      If 'All', a new job instance is triggered when all the dependencies are updated.
                      If 'Any', a new job instance is triggered when any of the dependencies are updated.The time to delay the job. A job scheduled at 12:00 AM with 15 minute lag time will start at 12:15 AM with the startDateTime parameter equal to 12:00 AM.`}
                        required={true}
                      ></LensLabel>
                    );
                  }}
                  options={triggerPolicyOptions}
                  selectedKey={
                    job.config?.jobScheduleSettings.dependencySettings
                      ?.triggerPolicy
                  }
                  onChange={(_, option) => {
                    job.config!.jobScheduleSettings.dependencySettings!.triggerPolicy =
                      option!.key as string;
                    job.config!.jobScheduleSettings.dependencySettings!.dependencies =
                      job.config!.jobScheduleSettings.dependencySettings
                        ?.dependencies || [];
                    setJob({ ...job });
                  }}
                ></ElxDropdown>
                <LensLabel
                  labelText="Dependencies"
                  hintText={`The list of dependencies.
                        Either partitioned dependencies or snapshot dependencies can be entered but not both.
                        Cosmos/ADLS dependencies can be produced by any producer.
                        Kusto dependencies must be produced by other Orchestrator jobs.`}
                  required={true}
                ></LensLabel>
                <Stack tokens={{ childrenGap: 16, padding: 16 }}>
                  <Stack.Item>
                    {
                      <>
                        <table>
                          {job.config!.jobScheduleSettings!.dependencySettings!
                            .dependencies.length > 0 && (
                            <thead>
                              <tr>
                                <th style={{ paddingRight: 10 }}>Dependency</th>
                                <th style={{ paddingRight: 10 }}>Partition</th>
                                <th style={{ paddingRight: 10 }}></th>
                              </tr>
                            </thead>
                          )}
                          <tbody>
                            {job.config!.jobScheduleSettings!.dependencySettings!.dependencies!.map(
                              (d: any, i) => {
                                const endpoint = d.endpoint;
                                const depData = [];
                                if (
                                  endpoint?.type ===
                                    orchestratorConstants.EndpointTypes
                                      .OrchestratorJob ||
                                  endpoint?.type ===
                                    orchestratorConstants.EndpointTypes
                                      .OrchestratorJobActivity
                                ) {
                                  depData.push(
                                    `${endpoint.jobName}-${
                                      endpoint.activityId || ''
                                    }-${'JobDependency'}`
                                  );
                                  depData.push('Snapshot');
                                }
                                if (
                                  endpoint?.type ===
                                  orchestratorConstants.EndpointTypes.KustoTable
                                ) {
                                  depData.push(
                                    `${endpoint.connectionName}-${endpoint.type}-${endpoint.table}`
                                  );
                                  depData.push(`${endpoint.tableType}`);
                                } else {
                                  depData.push(
                                    `${endpoint.connectionName}-${endpoint.type}`
                                  );
                                  depData.push(`${d.partitionSize}`);
                                }
                                return (
                                  <tr key={i}>
                                    <td style={{ paddingRight: 10 }}>
                                      {depData[0]}
                                    </td>
                                    <td style={{ paddingRight: 10 }}>
                                      {depData[1]}
                                    </td>
                                    <td style={{ paddingRight: 10 }}>
                                      <ElxIconButton
                                        iconProps={{
                                          iconName: 'edit',
                                        }}
                                        text={''}
                                        onClick={() => {
                                          setDepIdx(i);
                                        }}
                                      ></ElxIconButton>
                                      <ElxIconButton
                                        iconProps={{
                                          iconName: 'cancel',
                                        }}
                                        text={''}
                                        onClick={() => {
                                          job.config!.jobScheduleSettings.dependencySettings!.dependencies =
                                            job.config!.jobScheduleSettings.dependencySettings!.dependencies.filter(
                                              (_: any, j: number) => i !== j
                                            );
                                          setJob({ ...job });
                                        }}
                                      ></ElxIconButton>
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </table>
                      </>
                    }
                    {(depIdx >= 0 || addNewDependencyEntity) && (
                      <JobScheduleDependencyEntity
                        onDismiss={() => {
                          setDepIdx(-1);
                          setAddNewDependencyEntity(false);
                        }}
                        onChange={(entity: Object): void => {
                          if (addNewDependencyEntity) {
                            job.config!.jobScheduleSettings.dependencySettings!.dependencies.push(
                              entity as Dependency
                            );
                            setAddNewDependencyEntity(false);
                          } else {
                            job.config!.jobScheduleSettings.dependencySettings!.dependencies[
                              depIdx
                            ] = entity as Dependency;
                            setDepIdx(-1);
                          }
                          setJob({ ...job });
                        }}
                        isEdit={!addNewDependencyEntity}
                        entity={
                          (!addNewDependencyEntity &&
                            job.config!.jobScheduleSettings.dependencySettings!
                              .dependencies[depIdx]) ||
                          undefined
                        }
                        dependencies={
                          job.config!.jobScheduleSettings.dependencySettings!
                            .dependencies
                        }
                        workspace={workspace}
                        job={job}
                      ></JobScheduleDependencyEntity>
                    )}
                  </Stack.Item>
                  <ElxActionButton
                    iconProps={{
                      iconName: 'Add',
                    }}
                    text="Add"
                    onClick={() => {
                      setAddNewDependencyEntity(true);
                    }}
                    style={{
                      float: 'right',
                    }}
                  />
                </Stack>
                {showPartitionedProperties && (
                  <>
                    <ElxDropdown
                      onRenderLabel={() => {
                        return (
                          <LensLabel
                            labelText={'Window Type'}
                            hintText={
                              'Window Type - Hourly, Daily, Monthy, Snapshot or Custom. If Custom is choosen, need to provide window size.'
                            }
                            required
                          ></LensLabel>
                        );
                      }}
                      options={windowTypeOptions}
                      selectedKey={
                        job!.config?.jobScheduleSettings.dependencySettings!
                          .windowType
                      }
                      onChange={(e, option) => {
                        job!.config!.jobScheduleSettings.dependencySettings!.windowType =
                          (option?.key as number) ||
                          orchestratorConstants.WindowTypeOptionsValues.Custom;
                        setJob({ ...job });
                      }}
                    ></ElxDropdown>
                    {job!.config!.jobScheduleSettings.dependencySettings!
                      .windowType ===
                      orchestratorConstants.WindowTypeOptionsValues.Custom && (
                      <>
                        <LensLabel
                          labelText="Window Size"
                          hintText={`The size of each job execution window.
                      The most common values are 1 hour and 1 day.
                      The value must be between 1 hour and 31 days.`}
                          required
                        ></LensLabel>
                        <DayHourMinSecPicker
                          showSeconds={false}
                          setValue={
                            job!.config!.jobScheduleSettings.dependencySettings!
                              .windowSize!
                          }
                          onChange={(value: string): void => {
                            job!.config!.jobScheduleSettings.dependencySettings!.windowSize =
                              value;
                            setJob({ ...job });
                          }}
                          showDays={true}
                          showHours={true}
                          showMinutes={true}
                          maxDays={1000}
                        ></DayHourMinSecPicker>
                      </>
                    )}
                    {job!.config!.jobScheduleSettings.dependencySettings!
                      .windowType !==
                      orchestratorConstants.WindowTypeOptionsValues
                        .Snapshot && (
                      <>
                        <ElxTextField
                          onRenderLabel={() => {
                            return (
                              <LensLabel
                                labelText={'Windows Per Scan'}
                                hintText={`The number of job execution windows to scan.
                                This is a lookback period: UTC Now - (Window Size * Windows Per Scan).
                                If a dependency is updated within this lookback period, a new job instance is executed subject to the other configuration options.
                                The lookback period should be long enough to account for issues in the ingestion pipeline and congestion.
                                Consider adding extra time to the lookback period of critical jobs.`}
                                required
                              ></LensLabel>
                            );
                          }}
                          value={job!.config!.jobScheduleSettings.dependencySettings!.windowsPerScan!.toString()}
                          onChange={(e, value) => {
                            job!.config!.jobScheduleSettings.dependencySettings!.windowsPerScan =
                              Number(value) || 4;
                            setJob({ ...job });
                          }}
                        ></ElxTextField>
                      </>
                    )}
                  </>
                )}
                <ElxDropdown
                  onRenderLabel={() => {
                    return (
                      <LensLabel
                        labelText="Re-Trigger policy"
                        hintText={`Tells when another job instance is re-triggered based on the dependencies.
                        If 'All', another job instance is re-triggered when all the dependencies are updated.
                        If 'Any', another job instance is re-triggered when any of the dependencies are updated.
                        If 'Never', another job instance will not re-run after the first job instance.`}
                        required={false}
                      ></LensLabel>
                    );
                  }}
                  options={reTriggerPolicyOptions}
                  selectedKey={
                    job.config?.jobScheduleSettings.dependencySettings
                      ?.reTriggerPolicy
                  }
                  onChange={(_, option) => {
                    job.config!.jobScheduleSettings.dependencySettings!.reTriggerPolicy =
                      option!.key as string;
                    setJob({ ...job });
                  }}
                ></ElxDropdown>
                <ElxDateTimePicker
                  onRenderLabel={() => {
                    return (
                      <LensLabel
                        labelText="Start Time"
                        hintText={`The time when the job starts executing.
                        Dependency updates before the start time are ignored and not scanned.`}
                        required={false}
                      ></LensLabel>
                    );
                  }}
                  defaultValue={
                    job.config?.jobScheduleSettings.dependencySettings
                      ?.startTime || DateTime.now().toISO()
                  }
                  displayMode={ElxDateTimePickerDisplayMode.SideBySide}
                  onSelectDateTime={(dateTime?: string) => {
                    job.config!.jobScheduleSettings.dependencySettings!.startTime =
                      dateTime!;
                    setJob({ ...job });
                  }}
                  timeZones={[{ utcOffsetMinutes: 0, symbol: 'UTC' }]}
                />
                <LensLabel
                  labelText="Submission Delay"
                  hintText={`The time to delay job execution.
                    A job instance does not run until the dependencies are stable and not updated for the submission delay period.
                    The submission delay allows you to be certain the dependencies have completed updating cleanly before the job instance runs.`}
                  required={false}
                ></LensLabel>
                <DayHourMinSecPicker
                  showSeconds={false}
                  maxDays={1}
                  setValue={
                    job.config?.jobScheduleSettings.dependencySettings
                      ?.submissionDelay || '0.00:00:00'
                  }
                  onChange={(value: string): void => {
                    job.config!.jobScheduleSettings.dependencySettings!.submissionDelay =
                      value;
                    setJob({ ...job });
                  }}
                  showDays={true}
                  showHours={true}
                  showMinutes={true}
                ></DayHourMinSecPicker>
                {showPartitionedProperties && (
                  <>
                    <LensLabel
                      labelText={'Only Finished Windows'}
                      hintText={`When this flag is set, a job instance does not run until the current window closes (UTC now passes the window end).
                        Clear this flag to emulate legacy Data Studio (Sangam) feed polling behavior.`}
                    ></LensLabel>
                    <Toggle
                      label=""
                      checked={
                        job!.config!.jobScheduleSettings.dependencySettings!
                          .onlyFinishedWindows
                      }
                      onChange={(
                        e: React.MouseEvent<HTMLElement>,
                        checked?: boolean
                      ) => {
                        job!.config!.jobScheduleSettings.dependencySettings!.onlyFinishedWindows =
                          checked;
                        setJob({ ...job });
                      }}
                    />
                    <LensLabel
                      labelText={'Run Oldest First'}
                      hintText={`When this flag is set, the job instance with the oldest time window (the earliest startDateTime) in each queue will run first.
                      Otherwise, job instances run first in, first out.`}
                    ></LensLabel>
                    <Toggle
                      label=""
                      checked={
                        job!.config!.jobScheduleSettings.dependencySettings!
                          .oldestFirst
                      }
                      onChange={(
                        e: React.MouseEvent<HTMLElement>,
                        checked?: boolean
                      ) => {
                        job!.config!.jobScheduleSettings.dependencySettings!.oldestFirst =
                          checked;
                        setJob({ ...job });
                      }}
                    />
                  </>
                )}
                <LensLabel
                  labelText="Timeout"
                  hintText={`The time period after which a job instance will be created even if the dependency is not ready.
                  The value must be between 0 and 1 day.`}
                  required={false}
                ></LensLabel>
                <DayHourMinSecPicker
                  showSeconds={false}
                  maxDays={1}
                  setValue={
                    job.config?.jobScheduleSettings.dependencySettings
                      ?.timeout || '0.00:00:00'
                  }
                  onChange={(value: string): void => {
                    job.config!.jobScheduleSettings.dependencySettings!.timeout =
                      value;
                    setJob({ ...job });
                  }}
                  showDays={true}
                  showHours={true}
                  showMinutes={true}
                ></DayHourMinSecPicker>
              </>
            )}
          </Stack.Item>
          {getSchedule() !== 0 && <JobScheduleDelta job={job} />}
        </Stack>
      ),
      cancelButton: backtoJobList,
      secondaryButton: saveAndClose,
      backButton: {
        disabled: false,
      },
    },
    {
      key: 'Advanced Configuration',
      name: 'Advanced Configuration',
      component: <div> Advanced Configuration </div>,
      tabs: [
        {
          key: 'Execution Settings',
          name: 'Execution Settings',
          component: (
            <Stack tokens={{ padding: 24 }}>
              <Stack.Item>
                <ElxTextField
                  id={'Max Instances'}
                  onRenderLabel={() => (
                    <LensLabel
                      labelText="Max Instances"
                      hintText={'Maximum instances that this job may use'}
                      required={false}
                    ></LensLabel>
                  )}
                  value={job.config?.maxJobInstances?.toString()}
                  deferredValidationTime={500}
                  onChange={(e, value) => {
                    job.config!.maxJobInstances = Number(value) || 1;
                    setJob({ ...job });
                  }}
                  required
                ></ElxTextField>
                <br />
                <ElxTextField
                  id={'Max Queued Instances'}
                  label={'Max Queued Instances'}
                  onRenderLabel={() => (
                    <LensLabel
                      labelText="Max Queued Instances"
                      hintText={'Max Queued Instances'}
                      required={false}
                    ></LensLabel>
                  )}
                  value={job.config?.maxQueuedJobInstances?.toString() || '10'}
                  deferredValidationTime={500}
                  onChange={(e, value) => {
                    job.config!.maxQueuedJobInstances = Number(value) || 10;
                    setJob({ ...job });
                  }}
                ></ElxTextField>
                <br />
                <ElxTextField
                  id={'Max Failures Before Quarantine'}
                  onRenderLabel={() => (
                    <LensLabel
                      labelText="Max Failures Before Quarantine"
                      hintText={
                        'Maximum falures allowed before the job is quarantine'
                      }
                      required={false}
                    ></LensLabel>
                  )}
                  value={job.config?.maxJobInstances?.toString() || '10'}
                  deferredValidationTime={500}
                  onChange={(e, value) => {
                    job.config!.maxJobInstances = Number(value) || 10;
                    setJob({ ...job });
                  }}
                ></ElxTextField>
                <br />
                <LensLabel
                  labelText="Max Failed Job Duration"
                  hintText={'Maximum failed job duration'}
                  required={false}
                ></LensLabel>
                <DayHourMinSecPicker
                  setValue={
                    job.config?.maxFailedJobDuration?.toString() || '1.00:00:00'
                  }
                  onChange={(value: string): void => {
                    job.config!.maxFailedJobDuration = value || '1.00:00:00';
                    setJob({ ...job });
                  }}
                  maxDays={30}
                  showDays={true}
                  showHours={true}
                  showMinutes={false}
                  showSeconds={false}
                ></DayHourMinSecPicker>
                <br />
                <LensLabel
                  labelText="Max Execution Time"
                  hintText={'Maximum execution time allowed for this job'}
                  required={false}
                ></LensLabel>
                <DayHourMinSecPicker
                  setValue={job.config?.maxExecutionTime || '1.00:00:00'}
                  onChange={(value: string): void => {
                    job.config!.maxExecutionTime = value || '1.00:00:00';
                    setJob({ ...job });
                  }}
                  maxDays={30}
                  showDays={true}
                  showHours={true}
                  showMinutes={true}
                  showSeconds={false}
                ></DayHourMinSecPicker>
              </Stack.Item>
            </Stack>
          ),
          cancelButton: backtoJobList,
          secondaryButton: saveAndClose,
          backButton: {
            disabled: false,
          },
        },
        {
          key: 'Monitoring Configuration',
          name: 'Monitoring Configuration',
          component: (
            <Stack tokens={{ padding: 24 }}>
              <ElxTextField
                id={'Alert Emails'}
                onRenderLabel={() => (
                  <LensLabel
                    labelText="Alert Emails"
                    hintText={'Alert Emails'}
                    required={false}
                  ></LensLabel>
                )}
                value={job.config?.context?.failureEmailAddresses || ''}
                deferredValidationTime={500}
                onChange={(e, value) => {
                  job.config!.context!.failureEmailAddresses = value || '';
                  setJob({ ...job });
                }}
              ></ElxTextField>
              <br />
              <ElxTextField
                id={'ICM Team'}
                onRenderLabel={() => (
                  <LensLabel
                    labelText="ICM Team"
                    hintText={'ICM Team'}
                    required={false}
                  ></LensLabel>
                )}
                value={job.config?.icmTeamPublicId || ''}
                deferredValidationTime={500}
                onChange={(e, value) => {
                  job.config!.icmTeamPublicId = value || '';
                  setJob({ ...job });
                }}
              ></ElxTextField>
              <ElxLink
                style={{ verticalAlign: 'baseline' }}
                href={
                  'https://portal.microsofticm.com/imp/TenantAndTeamIdLookup.aspx'
                }
                target="_blank"
              >
                Look up your ICM team public Id
              </ElxLink>
              <br />
              <ElxDropdown
                id={'Alert Severity'}
                onRenderLabel={() => (
                  <LensLabel
                    labelText="Alert Severity"
                    hintText={'Alert Severity'}
                    required={false}
                  ></LensLabel>
                )}
                options={severityOptions}
                selectedKey={job.config?.alertSeverity || 4}
                onChange={(_, value) => {
                  job.config!.alertSeverity = Number(value!.key) || 4;
                  setJob({ ...job });
                }}
              ></ElxDropdown>
              <br />
              <ElxTextField
                id={'Custom MDM Account'}
                onRenderLabel={() => (
                  <LensLabel
                    labelText="Custom MDM Account"
                    hintText={'Custom MDM Account'}
                    required={false}
                  ></LensLabel>
                )}
                value={job.config?.metricAccount || ''}
                deferredValidationTime={500}
                onChange={(e, value) => {
                  job.config!.metricAccount = value || '';
                  setJob({ ...job });
                }}
              ></ElxTextField>
            </Stack>
          ),
          cancelButton: backtoJobList,
          secondaryButton: saveAndClose,
          backButton: {
            disabled: false,
          },
        },
        {
          key: 'Retry Configuration',
          name: 'Retry Configuration',
          component: (
            <Stack tokens={{ padding: 24 }}>
              <ElxDropdown
                id={'Policy'}
                onRenderLabel={() => (
                  <LensLabel
                    labelText="Policy"
                    hintText={
                      'Select the retry policy - None for no retry, Linear for evenly spaced retries, or Exponential for increasingly long times between retries.'
                    }
                    required={false}
                  ></LensLabel>
                )}
                options={retryPolicyOptions}
                selectedKey={
                  job.config?.retryPolicy?.type || retryPolicyType[0]
                }
                onChange={(_, value) => {
                  job.config!.retryPolicy.type =
                    value?.text || retryPolicyType[0];
                  if (job.config!.retryPolicy.type === retryPolicyType[0]) {
                    job.config!.retryPolicy.delay = null;
                    job.config!.retryPolicy.count = null;
                  }
                  setJob({ ...job });
                }}
              ></ElxDropdown>
              <br />
              {job.config?.retryPolicy?.type !== retryPolicyType[0] && (
                <>
                  <ElxTextField
                    type={'number'}
                    min={1}
                    max={10}
                    id={'Restart policy'}
                    onRenderLabel={() => (
                      <LensLabel
                        labelText="Count"
                        hintText={'retry count'}
                        required={false}
                      ></LensLabel>
                    )}
                    value={job.config?.retryPolicy?.count?.toString() || '1'}
                    onChange={(_, value) => {
                      job.config!.retryPolicy.count = Number(value);
                      setJob({ ...job });
                    }}
                  ></ElxTextField>
                  <br />

                  <LensLabel
                    labelText="Delay"
                    hintText={'Delay before re-running the job'}
                    required={false}
                  ></LensLabel>

                  <DayHourMinSecPicker
                    showSeconds={false}
                    maxDays={1}
                    setValue={job.config?.retryPolicy?.delay || '0.00:00:00'}
                    onChange={(value: string): void => {
                      job.config!.retryPolicy.delay = value;
                      setJob({ ...job });
                    }}
                    showDays={true}
                    showHours={true}
                    showMinutes={true}
                  ></DayHourMinSecPicker>
                  <br />
                  <ElxDropdown
                    id={'Restart policy'}
                    onRenderLabel={() => (
                      <LensLabel
                        labelText="Restart policy"
                        hintText={
                          'Choose restart policy - like rerun all the activities in the job or only the failed activities'
                        }
                        required={false}
                      ></LensLabel>
                    )}
                    selectedKey={job.config?.retryPolicy.restartPolicy || 0}
                    options={restartPolicyOptions}
                    onChange={(_, value) => {
                      job.config!.retryPolicy.restartPolicy =
                        Number(value?.key) || 0;
                      setJob({ ...job });
                    }}
                  ></ElxDropdown>
                </>
              )}
            </Stack>
          ),
          cancelButton: backtoJobList,
          secondaryButton: saveAndClose,
          backButton: {
            disabled: false,
          },
        },
      ],
      cancelButton: backtoJobList,
      backButton: {
        disabled: false,
      },
    },
    {
      key: 'Parameters',
      name: 'Parameters',
      component: (
        <Stack tokens={{ padding: 24 }}>
          <JobParameterItemsWithType
            parameters={job.config!.parameters}
            onNameChange={(name: string, idx: number): void => {
              job.config!.parameters[idx].name = name;
              setJob({ ...job });
            }}
            onValueChange={(value: string, idx: number): void => {
              job.config!.parameters[idx].value = value;
              setJob({ ...job });
            }}
            onRemove={(idx: number): void => {
              job.config!.parameters = job.config!.parameters.filter(
                (_, index) => index !== idx
              );
              setJob({ ...job });
            }}
            onAdd={(): void => {
              job.config!.parameters = [
                ...job.config!.parameters,
                { name: '', value: '', type: '' },
              ];
              setJob({ ...job });
            }}
            noTitle={true}
            onTypeChange={(type: string, idx: number): void => {
              job.config!.parameters[idx].type = type;
              setJob({ ...job });
            }}
          ></JobParameterItemsWithType>
        </Stack>
      ),
      primaryButton: {
        text: 'Save Job',
        disabled: !verifyJob(),
        onClick: () => {
          createJob(workspaceId, job);
        },
      },
      cancelButton: backtoJobList,
      backButton: {
        disabled: false,
      },
    },
  ];

  let range: IRange | undefined = undefined;
  if (editActId) {
    const buffer = JSON.stringify(job, null, 4);
    if (buffer) {
      const lines: string[] = buffer.split('\n');
      const index: number = _.findIndex(lines, function (l) {
        return l.indexOf('"id": "' + editActId + '"') !== -1;
      });
      const startStr: string = lines[index];
      const blanks: string[] | null = startStr?.match(/^\s*/);
      let indent = 0;
      if (blanks?.length) {
        indent = Math.max(0, blanks[0].length - 4);
      }
      const start = _.findLastIndex(
        lines,
        function (l) {
          return _.startsWith(l, ' '.repeat(indent) + '{');
        },
        index
      );
      const end = _.findIndex(
        lines,
        function (l) {
          return _.startsWith(l, ' '.repeat(indent) + '}');
        },
        index
      );
      if (end > start) {
        range = {
          startLineNumber: start + 1,
          startColumn: 1,
          endLineNumber: end + 2,
          endColumn: 1,
        };
      }
    }
  }

  return (
    <>
      <Stack styles={{ root: { zIndex: '10 !important' } }}>
        <ElxMasterDetailContainer
          styles={{
            contentContainer: { padding: '0px !important' },
          }}
          tabs={tabs}
          wizard={true}
        ></ElxMasterDetailContainer>
      </Stack>
      {(actIdx >= 0 || addNewActivity) && (
        <JobActivity
          isEdit={!addNewActivity}
          activity={
            !addNewActivity ? job.config!.activities![actIdx] : undefined
          }
          onDismiss={() => {
            if (addNewActivity) {
              setAddNewActivity(false);
            } else {
              setActIdx(-1);
            }
          }}
          onDelete={() => {
            job.config!.activities = job.config!.activities!.filter(
              (_, i) => i !== actIdx
            );
            setJob({ ...job });
            setActIdx(-1);
          }}
          onViewJson={() => {
            setEditActId(
              !addNewActivity
                ? job.config!.activities![actIdx].id || null
                : null
            );
            setActIdx(-1);
          }}
          onChange={(activity: Activity) => {
            if (addNewActivity) {
              job.config!.activities!.push(activity);
              setAddNewActivity(false);
            } else {
              job.config!.activities![actIdx] = activity;
              setActIdx(-1);
            }
            setJob({ ...job });
          }}
          dependencyOptions={[
            ...job.config!.activities!.map((activity) => ({
              key: activity.id,
              text: activity.activityDisplayName,
            })),
            {
              key: '',
              text: 'None',
            },
          ]}
        ></JobActivity>
      )}
      {editActId && (
        <>
          <EditorFull
            onChange={(value: string): void => {
              setJob(JSON.parse(value));
              setEditActId(null);
            }}
            value={JSON.stringify(job, null, 4)}
            onDismiss={(): void => {
              setEditActId(null);
            }}
            language={'json'}
            range={range}
            onViewActvityMode={() => {
              let index = job.config?.activities?.findIndex(
                (d) => d.id === editActId
              );
              if (index === undefined) {
                index = -1;
              }
              setActIdx(index);
              setEditActId(null);
            }}
          ></EditorFull>
        </>
      )}
      ,
      {discardDialog && (
        <ElxDialog
          hidden={false}
          dismissable={false}
          dialogContentProps={{
            title: 'Discard Job Changes',
            subText: 'The job has unsaved changes. Discard changes?',
          }}
          primaryButtonProps={{
            text: 'Discard',
            onClick: () => {
              setDiscardDialog(false);
              props.onCancel ? props.onCancel() : history.push('/job/list');
            },
          }}
          cancelButtonProps={{
            text: 'Cancel',
            onClick: () => {
              setDiscardDialog(false);
            },
          }}
          onDismiss={() => {
            setDiscardDialog(false);
          }}
        ></ElxDialog>
      )}
    </>
  );
};

export default JobAuthor;
