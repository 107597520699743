import { _ } from 'utils/sharedLibs';
import kustoQueryHelper from '../utils/kustoQueryHelper';
import { DataSourceClient } from '../../client/dataSourceClient';
import { ColumnType, DataType, Table } from '../../models/dataSource';

const fqnTemplate = _.template(kustoQueryHelper.KustoTableFqnTemplate);

/**
 * @name BaseKustoClient
 * @description
 * Represents the base class for a Kusto data source client.
 */
export class BaseKustoClient extends DataSourceClient {
  /**
   * @name constructStartingQuery
   * @description Construct the initial starting query that the user sees in their editor
   * @param {String} cluster The cluster - optional.
   * @param {String} database The database - optional.
   * @param {String} table The table.
   * @returns {string} Query The starting query the user should see in their editor
   */
  public constructStartingQuery(
    cluster: string,
    database: string,
    table: string
  ): string {
    if (cluster && database) {
      return fqnTemplate({
        cluster: cluster,
        database: database,
        table: table,
      });
    }
    return kustoQueryHelper.escapeName(table);
  }

  /**
   * @name serializeDynamicColumns
   * @description Serializes any dynamic columns in the passed in table, in place
   * @param {Object} table The table to work on.
   */
  public serializeDynamicColumns(table: Table) {
    for (
      var columnIndex = 0;
      columnIndex < table.Columns.length;
      columnIndex++
    ) {
      // Only serialize if the
      if (
        table.Columns[columnIndex].ColumnType === ColumnType.DYNAMIC &&
        table.Columns[columnIndex].DataType === DataType.OBJECT
      ) {
        for (var rowIndex = 0; rowIndex < table.Rows.length; rowIndex++) {
          // Only stringify objects, because fields extracted from dynamics could show up as dynamic with serialization set to object
          if (_.isObject(table.Rows[rowIndex][columnIndex])) {
            table.Rows[rowIndex][columnIndex] = JSON.stringify(
              table.Rows[rowIndex][columnIndex]
            );
          }
        }
      }
    }
  }
}

export default BaseKustoClient;
