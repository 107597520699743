import { Panel, PanelType, Stack, StackItem } from '@fluentui/react';
import {
  ElxPivot,
  ElxPrimaryButton,
  ElxSecondaryButton,
} from '@elixir/components';
import { ThemeProvider } from '@fluentui/react';
import { useLensShellTheme } from 'features/shell/lensShellStyles';
import React, { useCallback, useState } from 'react';
import { JobInstance } from '../models/job';
import InstanceDetails from './instanceDetails';
import InstanceLogs from './instanceLogs';
import { useParams } from 'react-router-dom';

interface JobInstanceLogProps {
  onCancel: () => void;
  onManageJobs: () => void;
  jobInstances: JobInstance[];
}

let ignoreParams = false;

const JobInstanceLog = (props: JobInstanceLogProps) => {
  const tabStates = ['About this Instance', 'Execution Logs'];
  const [tabSelectedKey, setTabSelectedKey] = useState<string>(tabStates[0]);
  const [index, setIndex] = useState(0);

  const params: any = useParams();
  if (!ignoreParams) {
    switch (params.tab) {
      case 'logs':
        setTabSelectedKey(tabStates[1]);
        break;
      case 'about':
      default:
        setTabSelectedKey(tabStates[0]);
        break;
    }
    ignoreParams = true;
  }

  const tabItems = tabStates.map((t) => ({ itemKey: t, headerText: t }));
  const onRenderFooterContent = useCallback(
    () => (
      <>
        <ElxSecondaryButton
          text={'Close'}
          onClick={props.onCancel}
          styles={{ root: { marginLeft: '6px', marginRight: '6px' } }}
        ></ElxSecondaryButton>
        <ElxSecondaryButton
          disabled={!(index > 0)}
          iconProps={{ iconName: 'ChevronLeft' }}
          text={'Previous Log'}
          onClick={() => setIndex(index - 1)}
          styles={{ root: { marginLeft: '6px', marginRight: '6px' } }}
        ></ElxSecondaryButton>
        <ElxSecondaryButton
          disabled={!(index < props.jobInstances.length - 1)}
          iconProps={{ iconName: 'ChevronRight' }}
          text={'Next Log'}
          onClick={() => setIndex(index + 1)}
          styles={{ root: { marginLeft: '6px', marginRight: '6px' } }}
        ></ElxSecondaryButton>
        <ElxPrimaryButton
          text={'Manage Jobs'}
          onClick={() => props.onManageJobs()}
        ></ElxPrimaryButton>
      </>
    ),
    [props, index]
  );
  const theme = useLensShellTheme();

  return (
    <ThemeProvider theme={theme}>
      {' '}
      <Panel
        onDismiss={props.onCancel}
        isOpen={true}
        isFooterAtBottom={true}
        headerText={`${props.jobInstances[index]?.id} Instances`}
        closeButtonAriaLabel={'Close'}
        type={PanelType.custom}
        customWidth="1260px"
        onRenderFooterContent={onRenderFooterContent}
        hasCloseButton={true}
        isLightDismiss={true}
        styles={{
          footerInner: { float: 'right' },
          footer: {
            borderTop: '1px solid lightgrey',
            backgroundColor: theme.palette.neutralLighter,
          },
          content: { background: 'white', padding: '0px' },
          main: { background: 'white !important' },
          scrollableContent:
            tabSelectedKey === tabStates[1]
              ? { overflowY: 'hidden !important' }
              : {},
        }}
      >
        <Stack styles={{ root: { padding: '0px' } }}>
          <StackItem>
            <ElxPivot
              selectedKey={tabSelectedKey}
              onItemSelect={setTabSelectedKey}
              items={tabItems}
              styles={{
                root: {
                  borderBottom: '1px solid lightgrey',
                  position: 'sticky',
                },
              }}
            ></ElxPivot>
          </StackItem>
        </Stack>
        {tabSelectedKey === tabStates[0] && (
          <InstanceDetails
            jobInstance={props.jobInstances[index]}
          ></InstanceDetails>
        )}
        {tabSelectedKey === tabStates[1] && (
          <InstanceLogs jobInstance={props.jobInstances[index]}></InstanceLogs>
        )}
      </Panel>
    </ThemeProvider>
  );
};

export default JobInstanceLog;
