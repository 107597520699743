import { ElxIconButton } from '@elixir/components';
import { Stack } from '@fluentui/react';
import React, { PropsWithChildren, useState } from 'react';
import { LensLabel } from 'utils/lensLabel';
import { headerExpand } from '../components/JobActivities/JobAuthorStyles';

export interface JobAccordianProps {
  isOpen: boolean;
  text: string;
  hint: string | undefined;
  onRemove?: () => void;
}

export const JobAccordian = (props: PropsWithChildren<JobAccordianProps>) => {
  const { isOpen, text, hint, onRemove, children } = props;
  const [open, setOpen] = useState(isOpen);

  return (
    <>
      <Stack
        className={headerExpand}
        horizontal
        grow
        tokens={{ childrenGap: 16 }}
        onClick={() => {
          setOpen(!open);
        }}
      >
        <Stack.Item>
          <Stack horizontal grow>
            <ElxIconButton
              text={''}
              iconProps={{
                iconName: open ? 'ChevronRightMed' : 'ChevronDownMed',
              }}
            ></ElxIconButton>

            <LensLabel labelText={text} hintText={hint}></LensLabel>
          </Stack>
        </Stack.Item>
        {onRemove && (
          <Stack.Item
            onClick={(e) => {
              onRemove();
              e.stopPropagation();
            }}
          >
            <Stack styles={{ root: { float: 'right', width: '100%' } }}>
              {' '}
              <ElxIconButton
                text={'Remove'}
                iconProps={{
                  iconName: 'cancel',
                }}
              ></ElxIconButton>
            </Stack>
          </Stack.Item>
        )}
        {/* <Stack.Item styles={{ root: { float: 'right' } }}>
          {' '}
          {onRemove && (
            <Stack>
              <Stack.Item>
                <ElxIconButton
                  text={''}
                  iconProps={{
                    iconName: 'recycle',
                  }}
                ></ElxIconButton>
              </Stack.Item>
              <Stack.Item>
                <LensLabel
                  labelText={'Remove'}
                  hintText={undefined}
                ></LensLabel>
              </Stack.Item>
            </Stack>
          )}
        </Stack.Item> */}
      </Stack>
      {open && (
        <>
          {children}
          <Stack styles={{ root: { paddingBottom: 8 } }}></Stack>
        </>
      )}
    </>
  );
};
