export enum UserPreferencesStatus {
  None = 'None',
  Loading = 'Loading',
  Loaded = 'Loaded',
  Error = 'Error',
}

export interface UserPreferences {
  aadTenantId: string;
  upn: string;
  name: string;
  oid: string;
  themeColorPreference: string; // not yet defined
  disableAutoRunningQuery: boolean;
  trimSearchColumns: string;
  turnOffVisualAnalyzerTeaser: boolean;
  timeZone: string;
  supportNotificationStatus: string;
  disableSavedDashboardFilters: boolean;
}

export default UserPreferences;
