import { DataSourceClient } from 'features/dataSources/client/dataSourceClient';
import constants, { $laConstants } from 'utils/constants';

class MdmClient extends DataSourceClient {
  public readonly name = $laConstants.DataSourceTypes.MDM;

  public readonly title = 'Geneva Metrics (MDM)';
  public readonly icon = 'fa fa-cat';
  public readonly description = 'Geneva Metrics (MDM) data source.';

  public readonly orchestration= {
    endpoint: {
        directive: 'mdm-job-endpoint',
    }
  }

  public get displayName() {
    return constants.DataSourceClientDisplayNames.MDM;
  }

  public get dataSourceType() {
    return constants.DataSourceTypes.MDM;
  }
}

export const mdmClient = new MdmClient();
export default mdmClient;
