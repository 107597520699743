import LogAnalyticsWorkspaceSelector from 'features/workspaces/components/editDataConnection/dataSourceComponents/logAnalyticsWorkspaceSelector';
import { SelectorProps } from 'features/workspaces/components/editDataConnection/editDataConnectionHelper';
import {
  ConnectionDataSourceType,
  LogAnalyticsDataSource,
} from 'features/workspaces/models/project';
import logAnalyticsClient from '../logAnalytics/client/logAnalyticsClient';
import { registerDataSourcePlugin } from '../registry';
import { DataSourcePlugin } from './dataSourcePlugin';

class LogAnalyticsPlugin extends DataSourcePlugin {
  public dataSourceFormComponent(selectorProps: SelectorProps): JSX.Element {
    return (
      <LogAnalyticsWorkspaceSelector
        workspace={selectorProps.workspace}
        state={selectorProps.state}
        setState={selectorProps.setState}
        customNameFilled={selectorProps.customNameFilled}
      />
    );
  }

  public generatedName(state: LogAnalyticsDataSource): string {
    if (state.cluster) {
      const tokens = state.cluster.split('/');
      if (tokens.length > 1) {
        return (
          tokens[tokens.indexOf('resourcegroups') + 1] +
          '.' +
          tokens[tokens.length - 1]
        );
      } else {
        if (state.workspaceId) {
          return 'LA.Datasource.WorkspaceId.' + state.workspaceId;
        }
      }
    }

    return '';
  }
}

registerDataSourcePlugin(
  ConnectionDataSourceType.LogAnalytics, // need to update to loganalytics
  new LogAnalyticsPlugin(
    ConnectionDataSourceType.LogAnalytics,
    logAnalyticsClient
  )
);
