import { LeWebApi } from 'api/leWebApi';
import { Userprofile } from 'features/userprofile/models/userprofile';

export class UserprofileApi extends LeWebApi {
  public getUserprofile = async (): Promise<Userprofile> => {
    return await this.getEntity<Userprofile>('/userprofiles');
  };

  public saveUserprofile = async (
    userprofile: Userprofile
  ): Promise<Userprofile> => {
    return await this.putEntity<Userprofile>('/userprofiles', userprofile);
  };

  public saveUserSelections = async (
    userprofile: Userprofile,
    userSelections: string
  ): Promise<Userprofile> => {
    let parent = { ...userprofile };
    parent.UserSelections = userSelections;
    return await this.putEntity<Userprofile>('/userprofiles', parent);
  };

  public getCanAdmin = async (): Promise<boolean> => {
    return await this.getEntity<boolean>('/userprofiles/canadmin').then(
      (result) => result || false
    );
  };

  public saveSupportNotificationStatus = async (
    userprofile: Userprofile,
    supportNotificationStatus: string
  ): Promise<Userprofile> => {
    let parent = { ...userprofile };
    parent.SupportNotificationStatus = supportNotificationStatus;
    return await this.putEntity<Userprofile>('/userprofiles', parent);
  };
}

export const userprofileApi = new UserprofileApi();

export default userprofileApi;
