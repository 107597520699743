import { ElxDropdown, ElxIconButton } from '@elixir/components';
import {
  DirectionalHint,
  IDropdownOption,
  ITooltipProps,
  Label,
  Spinner,
  SpinnerSize,
  Stack,
  ThemeProvider,
  TooltipHost,
} from '@fluentui/react';
import { useLensShellTheme } from 'features/shell/lensShellStyles';
import { useUserProfileCanAdmin } from 'features/userprofile/userprofileSlice';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import notifier from 'utils/notifier';
import utils from 'utils/utils';
import jobApi from '../api/jobApi';
import { alignEnds } from './JobActivities/JobAuthorStyles';
import './dependencies.css';

interface DependenciesProps {
  workspaceId: string;
  jobId: string;
}

const Dependencies = (props: DependenciesProps) => {
  const { workspaceId, jobId } = props;

  const [events, setEvents] = useState({});
  const [eventRefreshToggle, setEventRefreshToggle] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [dependencies, setDependencies] = useState<any>(undefined);
  const [partitions, setPartitions] = useState<string[]>([]);
  const [selectedPartition, setSelectedPartition] = useState('');
  const [telemetryLogUrl, setTelemetryLogUrl] = useState('');
  const [hours, setHours] = useState<{ date: string; hour: string }[]>([]);
  const [adjLeftToolTipPos, setAdjLeftToolTipPos] = useState<number>(0);

  const divDepTableContainerRef = useRef(null);
  const divTooltipRenderedRef = useRef(null);

  const { userProfileCanAdmin } = useUserProfileCanAdmin();

  const theme = useLensShellTheme();

  const convertTimeSpanToMsec = (value: string): number => {
    if (!value) return 0;

    try {
      let ts = utils.parseTimeSpan(value);
      return utils.convertTimeSpanToTime(ts) || 0;
    } catch (e) {
      return 0;
    }
  };

  const initEvents = useCallback((events) => {
    return _.filter(events, (event) => {
      if (
        !event ||
        !event.customDimensions ||
        !_.isDate(event.customDimensions.Modified)
      ) {
        return false;
      }

      event.start = moment(event.customDimensions.Modified).utc();

      let sealedMS = convertTimeSpanToMsec(event.customDimensions.SealedPeriod);
      event.end = moment(event.start).utc().add({ milliseconds: sealedMS });

      return true;
    });
  }, []);

  const getStart = useCallback((events): any => {
    return _.reduce(
      events,
      (result, event) => {
        if (!result || event.start.isBefore(result)) {
          return event.start;
        } else {
          return result;
        }
      },
      null
    );
  }, []);

  const getX = useCallback((time, start) => {
    let diff = moment.duration(time.diff(start));
    let hours = diff.asHours();

    const xHour = 100;
    let x = xHour * hours;
    return Math.max(0, Math.floor(x));
  }, []);

  const initFeedStates = useCallback(
    (partitionEvents, start) => {
      // Coalesce the events into feed states - the known availability of a feed
      // (typically Feed Service events received by Orchestrator).
      let feedStates = {};
      let partitionLeft: any = undefined;
      _.each(partitionEvents, (event) => {
        // Calculate the x-coordinates.
        let left = getX(event.start, start);
        let right = getX(event.end, start);
        let width = Math.max(0, right - left);
        partitionLeft =
          partitionLeft === undefined ? left : Math.min(partitionLeft, left);

        // All events with the same left coordinate (modified time) belong to the same feed state.
        let feedState: any = _.get(feedStates, left);
        if (!feedState) {
          feedState = {
            _id: utils.newGuid(), // for track by
            style: {
              left: left + 'px',
              width: 0,
            },
            modified: event.start.format('l LTS'), // m/d/yyyy h:m:s AM
            source: [],
          };
          _.set(feedStates, left, feedState);
        }

        // The event with the max width (sealed time) determines the feed state's width.
        if (width > feedState.style.width) {
          feedState.style.width = width;
          feedState.sealedPeriod = event.customDimensions.SealedPeriod;
          feedState.end = event.end.format('l LTS'); // m/d/yyyy h:m:s AM
        }

        feedState.source.push(event.customDimensions.Source);
        feedState.source = _.uniq(feedState.source);
      });

      // Convert the feed states object to an array.
      feedStates = _.map(feedStates, (feedState: any) => {
        feedState.style.width += 'px';
        return feedState;
      });

      return [feedStates, partitionLeft];
    },
    [getX]
  );

  const scrollTableX = useCallback((x?) => {
    if (divDepTableContainerRef.current) {
      let div = divDepTableContainerRef.current as HTMLElement;
      if (x === undefined) {
        x = div.scrollWidth;
      }

      div.scrollLeft = x;
    }
  }, []);

  const initDependencyPartition = useCallback(
    (partition, events, start) => {
      // Get the events for this dependency and partition.
      let partitionEvents = _.filter(events, (event) => {
        return (
          _.isDate(event.customDimensions.Partition) &&
          event.customDimensions.Partition.getTime() === partition.getTime()
        );
      });
      partitionEvents = _.orderBy(partitionEvents, ['timestamp'], ['asc']);

      let [feedStates, left] = initFeedStates(partitionEvents, start);

      return {
        _id: utils.newGuid(), // for track by
        label: moment(partition).utc().toISOString(),
        left,
        feedStates,
      };
    },
    [initFeedStates]
  );

  const initDependency = useCallback(
    (dependency, events, start) => {
      // Get the events for this dependency.
      let dependencyEvents = _.filter(events, (event) => {
        return event.customDimensions.DependencyId === dependency.id;
      });

      // Initialize the dependency partitions.
      dependency.partitions = _(dependencyEvents)
        .map('customDimensions.Partition')
        .filter((partition) => {
          return _.isDate(partition);
        })
        .uniqBy((partition) => {
          return partition.getTime();
        })
        .orderBy(
          [
            (partition) => {
              return partition.getTime();
            },
          ],
          ['desc']
        )
        .map((partition) => {
          return initDependencyPartition(partition, dependencyEvents, start);
        })
        .value();
    },
    [initDependencyPartition]
  );

  const convert = useCallback((result: any) => {
    _.each(result && result.events, (event) => {
      event.timestamp = utils.toDate(event.timestamp);

      event.customDimensions = utils.toJson(event.customDimensions);
      if (event.customDimensions) {
        event.customDimensions.Partition = utils.toDate(
          event.customDimensions.Partition
        );
        event.customDimensions.Modified = utils.toDate(
          event.customDimensions.Modified
        );
        event.customDimensions.FirstWindow = utils.toDate(
          event.customDimensions.FirstWindow
        );
        event.customDimensions.LastWindow = utils.toDate(
          event.customDimensions.LastWindow
        );
      }

      event.customMeasurements = utils.toJson(event.customMeasurements);
    });

    return result;
  }, []);

  const onMouseEnter = useCallback((event) => {
    let rect = event.currentTarget.getBoundingClientRect();
    setAdjLeftToolTipPos(rect.left);
  }, []);

  const onMouseLeave = useCallback((event) => {
    setAdjLeftToolTipPos(0);
  }, []);

  const feedStateTooltip = useCallback((feedState: any): JSX.Element => {
    return (
      <table>
        <tbody>
          <tr>
            <th>State</th>
            <td>Available</td>
          </tr>
          {feedState.modified && (
            <tr>
              <th>Modified</th>
              <td>{feedState.modified}</td>
            </tr>
          )}
          {feedState.end && (
            <tr>
              <th>End</th>
              <td>{feedState.end}</td>
            </tr>
          )}
          {feedState.sealedPeriod && (
            <tr>
              <th>Sealed Period</th>
              <td>{feedState.sealedPeriod}</td>
            </tr>
          )}
          {feedState.source?.length > 0 && (
            <tr ng-show="feedState.source.length">
              <th>Source</th>
              <td>{feedState.source.join()}</td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setErrorMsg(null);
        setIsLoading(true);
        let result = convert(
          await jobApi.getJobDependenciesEvent(workspaceId, jobId)
        );
        setEvents(result);
        if (result) {
          // Convert the dependencies object to an array.
          let dependencies = result.state && result.state.dependencies;
          dependencies = _.map(dependencies, (d) => d);

          // Get the time range of events.
          let events = initEvents(result.events);
          let start = moment(getStart(events)).utc().startOf('hour');
          let end = moment().utc().add({ hours: 1 });
          let hours = [];
          for (
            let m = moment(start).utc();
            m.isBefore(end);
            m.add({ hours: 1 })
          ) {
            hours.push({
              date: m.format('l'), // m/d/yyyy
              hour: m.format('LT'), // h:m AM
            });
          }
          setHours(hours);

          _.each(dependencies, (dependency) => {
            initDependency(dependency, events, start);
          });
          setDependencies(dependencies);

          let prtitions = _(dependencies)
            .flatMap('partitions')
            .map('label')
            .uniq()
            .orderBy([_.identity], ['desc'])
            .value();
          let partition = _.first(prtitions) || 'ALL';
          setSelectedPartition(partition);
          setPartitions(prtitions);

          setTelemetryLogUrl(result.state && result.state.telemetryLogUrl);
          scrollTableX();
        }

        setIsLoading(false);
      } catch (err: any) {
        const msg = 'getJobDependenciesEvent failed';
        notifier.error(msg);
        setIsLoading(false);
        setErrorMsg(err?.message || msg);
        throw err;
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventRefreshToggle]);

  useEffect(() => {
    if (adjLeftToolTipPos) {
      if (divTooltipRenderedRef.current) {
        const el = divTooltipRenderedRef.current as HTMLElement;
        let elm = el.querySelector('.dependencytriggerorfeed');
        if (elm) {
          (elm as HTMLElement).style.left = adjLeftToolTipPos + 'px';
        }
      }
    }
  }, [divTooltipRenderedRef, adjLeftToolTipPos]);

  useEffect(() => {
    const t = setInterval(() => {
      let elm = document.querySelector('.dependencytriggerorfeed');
      if (elm && adjLeftToolTipPos) {
        (elm as HTMLElement).style.left = adjLeftToolTipPos + 'px';
      }
    }, 50);
    return () => clearInterval(t);
  }, [adjLeftToolTipPos]);

  const handleRenderContent = (tooltipProps: ITooltipProps | undefined) => {
    return <div ref={divTooltipRenderedRef}>{tooltipProps?.content}</div>;
  };

  const dependencyTriggerOrFeed = {
    root: { '.dependencytriggerorfeed': { left: `${adjLeftToolTipPos}px` } },
  };

  return (
    <ThemeProvider theme={theme}>
      <Stack>
        <Stack.Item
          styles={{ root: { width: 'fit-content', paddingLeft: '16px' } }}
        ></Stack.Item>

        <Stack
          horizontal
          grow
          className={alignEnds}
          tokens={{ padding: 8 }}
          styles={{ root: { borderBottom: '1px solid lightgrey' } }}
        >
          <Stack.Item>
            <Stack horizontal grow>
              <Stack.Item styles={{ root: { paddingLeft: 8, paddingTop: 8 } }}>
                Partition
              </Stack.Item>
              <Stack.Item styles={{ root: { paddingLeft: 8 } }}>
                <ElxDropdown
                  options={[
                    { text: 'ALL', key: 'ALL' },
                    ...partitions?.map((p) => ({ text: p, key: p })),
                  ]}
                  styles={{ root: { minWidth: 200 } }}
                  selectedKey={selectedPartition}
                  onChange={(_, option?: IDropdownOption) => {
                    if (option) {
                      setSelectedPartition(option?.text || 'ALL');
                    }
                  }}
                ></ElxDropdown>
              </Stack.Item>
              <Stack.Item
                styles={{
                  root: {
                    paddingLeft: 8,
                    paddingTop: 8,
                    cursor: events ? 'pointer' : 'not-allowed',
                  },
                }}
                onClick={() => {
                  setEventRefreshToggle((b) => !b);
                }}
              >
                Refresh
              </Stack.Item>
            </Stack>
          </Stack.Item>
          <Stack.Item
            styles={{
              root: {
                paddingRight: 20,
                paddingLeft: 10,
                cursor: 'pointer',
              },
            }}
          >
            {userProfileCanAdmin && (
              <a
                href={telemetryLogUrl || '#'}
                target="_blank"
                rel="noopener, noreferrer"
              >
                <ElxIconButton
                  text={''}
                  iconProps={{ iconName: 'Link' }}
                ></ElxIconButton>
                Logs
              </a>
            )}
          </Stack.Item>
        </Stack>
        <Stack verticalFill styles={{ root: { background: 'white' } }}>
          {isLoading && (
            <Stack
              horizontalAlign="center"
              tokens={{ childrenGap: 24, padding: 100 }}
            >
              <Spinner size={SpinnerSize.large} />
              <Label>Loading dependency events...</Label>
            </Stack>
          )}
          {!isLoading && (
            <Stack.Item className="job-dependencies">
              {errorMsg && (
                <>
                  <p>{`Error loading dependencies: Error Msg = ${errorMsg}`}</p>
                </>
              )}
              {!errorMsg && (
                <>
                  {!dependencies && (
                    <>
                      <p>This job has no dependencies.</p>
                    </>
                  )}
                  {dependencies?.length === 0 && (
                    <>
                      <p>This job has no dependency events.</p>
                    </>
                  )}
                  {dependencies?.length > 0 && (
                    <>
                      <div
                        ref={divDepTableContainerRef}
                        className="dependencies-panel"
                      >
                        <table className="dependencies-table">
                          <thead>
                            <tr>
                              <th className="first-cell">Dependencies</th>
                              <th className="hours-header">
                                {hours.length === 0 && (
                                  <div className="hour">No events found.</div>
                                )}
                                {hours.map((hour) => {
                                  return (
                                    <div
                                      className="hour"
                                      key={hour.date + hour.hour}
                                    >
                                      {hour.date} <br /> {hour.hour}
                                    </div>
                                  );
                                })}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {dependencies.map((dependency: any) => {
                              return (
                                <React.Fragment key={dependency.id}>
                                  <tr className="dependency">
                                    <th>
                                      <TooltipHost
                                        content={dependency.id}
                                        key={dependency.id}
                                        directionalHint={
                                          DirectionalHint.topLeftEdge
                                        }
                                      >
                                        <span>{dependency.id}</span>
                                      </TooltipHost>
                                    </th>
                                  </tr>
                                  {dependency.partitions?.length === 0 && (
                                    <tr className="partition">
                                      <th>
                                        No partitions or dependency events found
                                        in the time interval.
                                      </th>
                                      <td></td>
                                    </tr>
                                  )}
                                  {dependency.partitions?.length > 0 && (
                                    <>
                                      {dependency.partitions.map(
                                        (partition: any) => {
                                          return (
                                            <React.Fragment key={partition._id}>
                                              {(selectedPartition === 'ALL' ||
                                                selectedPartition ===
                                                  partition.label) && (
                                                <>
                                                  <tr className="partition">
                                                    <th>
                                                      <span>
                                                        Partition
                                                        {partition.label}
                                                      </span>
                                                      <div className="dependencies-flex-spacer" />
                                                      <button
                                                        className="btn btn-icon"
                                                        onClick={() => {
                                                          scrollTableX(
                                                            partition.left - 20
                                                          );
                                                        }}
                                                        title={`Go to partition ${partition.label}`}
                                                      >
                                                        <i className="lensicon lensicon-fw lensicon-back-to-start-mirrored"></i>
                                                      </button>
                                                    </th>
                                                    <td>
                                                      {partition.feedStates.map(
                                                        (feedState: any) => {
                                                          return (
                                                            <TooltipHost
                                                              directionalHint={
                                                                DirectionalHint.topLeftEdge
                                                              }
                                                              className={
                                                                'dependencytriggerorfeed'
                                                              }
                                                              styles={
                                                                dependencyTriggerOrFeed
                                                              }
                                                              tooltipProps={{
                                                                onRenderContent:
                                                                  handleRenderContent,
                                                              }}
                                                              content={feedStateTooltip(
                                                                feedState
                                                              )}
                                                            >
                                                              <div
                                                                key={
                                                                  feedState._id
                                                                }
                                                                className="feed-state"
                                                                style={
                                                                  feedState.style
                                                                }
                                                                onMouseEnter={
                                                                  onMouseEnter
                                                                }
                                                                onMouseLeave={
                                                                  onMouseLeave
                                                                }
                                                              ></div>
                                                            </TooltipHost>
                                                          );
                                                        }
                                                      )}
                                                    </td>
                                                  </tr>
                                                </>
                                              )}
                                            </React.Fragment>
                                          );
                                        }
                                      )}
                                    </>
                                  )}
                                </React.Fragment>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}
                </>
              )}
            </Stack.Item>
          )}
        </Stack>
      </Stack>
    </ThemeProvider>
  );
};

export default Dependencies;
