import {
  ITooltipHostStyles,
  Panel,
  PanelType,
  SelectionMode,
  Stack,
  ThemeProvider,
  TooltipHost,
} from '@fluentui/react';
import {
  ElxActionButton,
  ElxPrimaryButton,
  ElxSecondaryButton,
  ElxTableContainer,
  FilterDisplayMode,
  FilterOptionMode,
  FilterOptionPillMode,
  IElxColumn,
  IPickerItem,
  useId,
} from '@elixir/components';
import { selectWorkspaceId } from 'features/workspaces/workspaceSlice';
import React, { useEffect, useRef, useState } from 'react';
import { InstanceLog, JobInstance, LogEntry } from '../models/job';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'app/lensShellUtility';
import { useLocation } from 'react-router-dom';
import {
  cancelJobInstance,
  getJobInstance,
  getJobInstanceLogs,
  JobInstanceLogStatus,
  rerunJobInstance,
  selectJobInstanceLogById,
  selectJobInstanceLogStatus,
  selectJobById,
} from '../jobSlice';
import { getTimeLabel } from '../utils/jobUtils';
import { colLarge, colMedium, colSmall, tableStyles } from 'utils/sharedStyles';
import { createJob as utilsCreateJob } from 'features/orchestrator/utils/jobUtils';
import { saveAs } from 'file-saver';
import notifier from 'utils/notifier';
import { useLensShellTheme } from 'features/shell/lensShellStyles';

interface InstanceLogsProps {
  jobInstance: JobInstance;
}
const StringOperators = ['==', '!=', 'Contains'];
function exportLogs(instanceLog: InstanceLog) {
  const formatField = (field: string) => {
    try {
      return !field ? '' : '"' + field.replace(/"/g, '""') + '"';
    } catch (err) {
      debugger;
    }
  };
  if (!instanceLog) return null;

  let lines = instanceLog.entries.map((entry) => {
    return (
      formatField(entry.timestamp) +
      ',' +
      formatField(entry.severityLevel) +
      ',' +
      formatField(entry.activityId) +
      ',' +
      formatField(entry.activityName) +
      ',' +
      formatField(entry.errorType) +
      ',' +
      formatField(entry.message) +
      ',' +
      formatField(entry.details) +
      ',' +
      formatField(entry.customDimensions)
    );
  });
  let content =
    'Timestamp,Severity,Activity ID,Activity Name,Error Type,Message,Details,Custom Dimensions\n' +
    lines.join('\n');
  let blob = new Blob([content], { type: 'data:text/csv;charset=utf-8' });
  let fileName = (instanceLog.instanceId || 'job') + '_logs.csv';
  saveAs(blob, fileName);
  notifier.info(`The job instance logs have been exported to "${fileName}".`);
}

const calloutProps = { gapSpace: 0 };
const hostStyles: Partial<ITooltipHostStyles> = {
  root: { display: 'inline-block' },
};

function InstanceLogs(props: InstanceLogsProps) {
  const workspaceId = useSelector(selectWorkspaceId);
  const dispatch = useDispatch<AppDispatch>();
  const instanceLog = useSelector((state: RootState) =>
    selectJobInstanceLogById(state, props.jobInstance.id)
  );
  const jobInstanceLogStatus = useSelector(selectJobInstanceLogStatus);
  const job = useSelector((state: RootState) =>
    selectJobById(state, props.jobInstance.jobId)
  );
  const { search } = useLocation();

  const divElxTableContaierRef = useRef(null);

  const tooltipId = useId('toolTip');
  const [message, setMessage] = useState<string | null>(null);

  const theme = useLensShellTheme();

  useEffect(() => {
    if (workspaceId === '' || instanceLog) return;
    dispatch(
      getJobInstanceLogs({
        workspaceId,
        jobId: props.jobInstance.jobId,
        instanceId: props.jobInstance.id,
      })
    );
  }, [dispatch, workspaceId, props.jobInstance, instanceLog]);

  // #region actions
  const actions = [
    {
      key: 'view job',
      text: 'View job',
      iconProps: { iconName: 'View' },
      onClick: () => {
        utilsCreateJob(job);
      },
    },
    {
      // TODO - nambi - what's the use?
      key: 'refresh',
      text: 'Refresh',
      iconProps: { iconName: 'Refresh' },
      onClick: () => {
        dispatch(
          getJobInstance({
            workspaceId,
            jobId: props.jobInstance.jobId,
            instanceId: props.jobInstance.id,
          })
        );
      },
    },
    {
      key: 'return instance',
      text: 'Rerun Instance',
      iconProps: { iconName: 'PlaybackRate1x' },
      onClick: () => {
        dispatch(
          rerunJobInstance({
            workspaceId,
            jobId: props.jobInstance.jobId,
            instanceId: props.jobInstance.id,
          })
        );
      },
    },
    {
      key: 'cancel instance',
      text: 'Cancel Instance',
      iconProps: { iconName: 'Cancel' },
      onClick: () => {
        dispatch(
          cancelJobInstance({
            workspaceId,
            jobId: props.jobInstance.jobId,
            instanceId: props.jobInstance.id,
          })
        );
      },
    },
    {
      key: 'share',
      text: 'Share Instance',
      iconProps: { iconName: 'Share' },
      onClick: () => {
        const { origin } = window.location;
        const url = `${origin}/#/job/list/${props.jobInstance.jobId}/instance/${props.jobInstance.id}/logs${search}`;
        navigator.clipboard.writeText(url);
        notifier.info(`The URL "${url}" copied to clipboard.`);
      },
    },
    {
      key: 'dowload',
      text: 'Download',
      iconProps: { iconName: 'Download' },
      disable: !instanceLog || instanceLog.entries.length === 0,
      onClick: () => {
        exportLogs(instanceLog!);
      },
    },
    {
      key: 'columns',
      text: 'Columns',
      iconProps: { iconName: 'Settings' },
      disable: !instanceLog || instanceLog.entries.length === 0,
      onClick: () => {
        let elm = divElxTableContaierRef.current! as HTMLElement;
        let e = elm.querySelector("[data-icon-name='Settings']") as HTMLElement;
        e.click();
      },
    },
  ];
  // #endregion actions
  // #region columns

  // #region searchProps
  const searchProps = {
    pillFilters: [
      {
        field: 'timestamp',
        label: 'Timestamp',
        multiselect: true,
        values: instanceLog?.entries
          .map((entry) => entry.timestamp)
          .filter((value, index, self) => self.indexOf(value) === index),
        pillMode: FilterOptionPillMode.Dynamic,
      },
      {
        field: 'message',
        label: 'Message',
        operators: StringOperators,
        pillMode: FilterOptionPillMode.Dynamic,
        mode: FilterOptionMode.Text,
      },
      {
        field: 'severityLevel',
        label: 'Severity',
        multiselect: true,
        values: instanceLog?.entries
          .map((entry) => entry.severityLevel)
          .filter((value, index, self) => self.indexOf(value) === index),
        pillMode: FilterOptionPillMode.Dynamic,
      },
    ],
    filterDisplayMode: FilterDisplayMode.Pill,
  };
  // #endregion searchProps
  const columns: IElxColumn[] = [
    {
      ...colLarge,
      key: 'timestamp',
      name: 'Timestamp',
      onRender: (logEntry: LogEntry) => {
        return <span>{getTimeLabel(new Date(logEntry.timestamp))}</span>;
      },
    },
    { ...colMedium, key: 'name', name: 'Name', fieldName: 'activityName' },
    {
      ...colSmall,
      key: 'severity',
      name: 'Severity',
      fieldName: 'severityLevel',
    },
    {
      ...colLarge,
      isMultiline: false,
      minWidth: 300,
      maxWidth: 360,
      key: 'message',
      name: 'Message',
      onRender: (item: any) => {
        return (
          <TooltipHost
            content={item.message}
            calloutProps={calloutProps}
            styles={hostStyles}
          >
            <span
              aria-describedby={tooltipId}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setMessage(item.message);
              }}
            >
              {item.message}
            </span>
          </TooltipHost>
        );
      },
    },
    {
      ...colLarge,
      isMultiline: false,
      key: 'details',
      name: 'Details',
      fieldName: 'details',
    },
  ];

  const customColumns: IPickerItem[] = columns.map((o) => ({
    key: o.key,
    text: o.name,
    selected: true,
  }));

  const [customSelectedKeys, setCustomSelectedKeys] = useState<string[]>(
    customColumns.map((o) => o.key)
  );

  // #endregion columns
  return (
    <>
      {' '}
      <Stack
        horizontal
        styles={{ root: { borderBottom: '1px solid lightgrey' } }}
      >
        {actions.map((o) => (
          <ElxActionButton
            {...o}
            styles={{ root: { margin: '0px 4px' } }}
          ></ElxActionButton>
        ))}
      </Stack>
      <Stack>
        {jobInstanceLogStatus === JobInstanceLogStatus.Error && <p>Error</p>}
        {jobInstanceLogStatus !== JobInstanceLogStatus.Error && (
          <div ref={divElxTableContaierRef}>
            <ElxTableContainer
              containerProps={{
                compact: true,
                styles: {
                  headerContainer: { height: '0px !important' },
                  loading: { paddingTop: '300px' },
                  body: {
                    '.elx-table': {
                      marginLeft: '0px !important',
                      marginRight: '0px !important',
                    },
                    minHeight: '200px',
                    maxHeight: '1000px',
                  },
                },
                isLoading:
                  jobInstanceLogStatus === JobInstanceLogStatus.Loading,
              }}
              tableProps={{
                columns,
                selectionMode: SelectionMode.none,
                items: instanceLog ? instanceLog.entries : [],
                styles: tableStyles,
              }}
              searchBoxProps={searchProps}
              onRenderEmpty={() => {
                return jobInstanceLogStatus === JobInstanceLogStatus.Loading ? (
                  <p></p>
                ) : (
                  <p>NO LOGS AVAILABLE</p>
                );
              }}
              customColumnProps={{
                items: customColumns,
                selectedItemKeys: customSelectedKeys,
                onSave: setCustomSelectedKeys,
              }}
            ></ElxTableContainer>
            {message && (
              <ThemeProvider theme={theme}>
                <Panel
                  headerText={'Message'}
                  isOpen={true}
                  onDismiss={() => {
                    setMessage(null);
                  }}
                  isFooterAtBottom={true}
                  closeButtonAriaLabel={'Close'}
                  type={PanelType.medium}
                  onRenderFooterContent={() => (
                    <>
                      <ElxSecondaryButton
                        text={'Copy message to clipboard'}
                        onClick={() => {
                          navigator.clipboard.writeText(message);
                        }}
                        styles={{
                          root: { marginLeft: '6px', marginRight: '6px' },
                        }}
                        iconProps={{ iconName: 'copy' }}
                      ></ElxSecondaryButton>
                      <ElxPrimaryButton
                        text={'Close'}
                        onClick={() => {
                          setMessage(null);
                        }}
                        styles={{
                          root: { marginLeft: '6px', marginRight: '6px' },
                        }}
                      ></ElxPrimaryButton>
                    </>
                  )}
                  hasCloseButton={true}
                  isLightDismiss={true}
                  styles={{
                    footerInner: { float: 'right' },
                    footer: {
                      borderTop: '1px solid lightgrey',
                      backgroundColor: theme.palette.neutralLighter,
                    },
                    content: { background: 'white', padding: '0px' },
                    main: { background: 'white !important' },
                  }}
                >
                  <Stack tokens={{ padding: 16 }}>
                    <p>{message}</p>
                  </Stack>
                </Panel>
              </ThemeProvider>
            )}
          </div>
        )}
      </Stack>
    </>
  );
}
export default InstanceLogs;
