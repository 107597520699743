import React from 'react';
import { Route, Switch } from 'react-router-dom';
import JobAuthor from '../components/jobAuthor';
import JobEdit from '../components/jobEdit';
import JobList from '../components/jobList';

const JobRoute: React.FC = () => {
  return (
    <>
      <Switch>
        <Route
          path={['/job/create']}
          render={() => {
            return <JobAuthor />;
          }}
        ></Route>
        <Route
          path={['/job/edit/:jobId']}
          render={() => {
            return <JobEdit />;
          }}
        ></Route>
        <Route
          path={[
            '/job/list/:jobId/instance/:instanceId/:tab',
            '/job/list/:jobId',
            '/job/list',
          ]}
          render={() => {
            return <JobList />;
          }}
        />
      </Switch>
    </>
  );
};

export default JobRoute;
