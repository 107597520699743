import { ThunkAction, Action } from '@reduxjs/toolkit';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import { Store } from 'redux';
import { cloneDeep } from 'lodash';
import { getDocument } from '@fluentui/react/lib/Utilities';
import { IBreadcrumbItem } from '@fluentui/react/lib/Breadcrumb';
import { getConfig } from '@elixir/fx/lib/ElxConfig';
import { IModule } from '@elixir/fx';
import { getBreadcrumbItems } from '@elixir/fx/lib/hooks/useBreadcrumb';
import { filterRoutesFromModules } from '@elixir/fx/lib/shell/Routing/RouteUtils';
import { history } from 'app/reducers';
import { setupReduxStore } from 'app/store';

export const store = setupReduxStore();

/**
 * Get application title.
 */
function getTitle(breadcrumbItems: IBreadcrumbItem[]): string {
  return [getConfig().applicationTitle]
    .concat(breadcrumbItems.map((item: IBreadcrumbItem) => item.text))
    .join(' | ');
}

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export function initializeLensShell(modules?: IModule[]): any {
  initializeIcons();

  if (modules) {
    const doc = getDocument();
    if (doc) {
      const { breadcrumbItems } = getBreadcrumbItems(
        history.location.pathname,
        filterRoutesFromModules(cloneDeep(modules)),
        []
      );
      doc.title = getTitle(breadcrumbItems);
    }
  }

  return {
    history,
    store: store as unknown as Store,
  };
}
