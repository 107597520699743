import {
  CatalogEntityApiColumns,
  CatalogEntityApiFormat,
  CatalogEntityHistoryApiResponse,
} from '../api/catalogApiModels';
import {
  CatalogEntity,
  CatalogEntityColumn,
  HistoryEntry,
} from '../models/catalogEntity';
import { getName, getNamespace } from './catalogEntityListUtils';

export const getCatalogEntityCols = (
  columns: CatalogEntityApiColumns
): CatalogEntityColumn[] => {
  if (columns === undefined || columns === null) {
    return [];
  }

  var columnArray: CatalogEntityColumn[] = [];
  let col: keyof CatalogEntityApiColumns;

  for (col in columns) {
    var newCol: CatalogEntityColumn = {
      name: col,
      type: columns[col].Type,
      description: columns[col].Description || '',
      effectiveDate: columns[col].EffectiveDate || '',
    };
    columnArray.push(newCol);
  }

  return columnArray;
};

export const getTransformedEntity = (
  entity: CatalogEntityApiFormat
): CatalogEntity => {
  const camelCasedEntity = catalogAPIResponseToCamel(entity);

  const transformed: CatalogEntity = {
    ...camelCasedEntity,
    name: getName(entity),
    nameWithNamespace: entity.Name,
    namespace: getNamespace(entity),
    type: entity.QueryEngine,
    service: entity.AdditionalProperties?.Service,
    serviceOid: entity.AdditionalProperties?.ServiceOid,
    columns: getCatalogEntityCols(entity.Columns || {}),
    GDPRScope:
      entity?.Extensions?.GDPR?.Properties?.[
        'GDPR Status'
      ]?.[0].toLowerCase() || undefined,
    originalAPIResponse: entity,
  };

  return transformed;
};

export const processHistoryRecords = (
  response: CatalogEntityHistoryApiResponse | undefined
): HistoryEntry[] => {
  if (response === undefined) {
    return [];
  }

  let historyEntries: HistoryEntry[] = [];

  for (var entry of response?.Entries) {
    for (var changeEntry of entry?.Entries) {
      let newHistoryEntry: HistoryEntry = {
        updateTime: entry.UpdateTime,
        userIdentifier: entry.UserIdentifier,
        userObjectId: entry.UserObjectId,
        changeLocation: changeEntry.ChangeLocation,
        newValue: changeEntry.NewValue,
        oldValue: changeEntry.OldValue,
        operation: changeEntry.Operation,
        propertyType: changeEntry.PropertyType,
        name: changeEntry.Name,
        applicationAppId: entry.ApplicationAppId,
      };

      historyEntries.push(newHistoryEntry);
    }
  }

  return historyEntries;
};

// code copied and altered from: https://stackoverflow.com/questions/12931828/convert-returned-json-object-properties-to-lower-first-camelcase
export function catalogAPIResponseToCamel(o: object) {
  var newO: any, origKey, newKey, value: any;
  if (o instanceof Array) {
    return o.map(function (value) {
      if (typeof value === 'object') {
        if (
          typeof value !== 'string' ||
          (typeof value === 'string' && value.toLowerCase() !== 'columns')
        ) {
          value = catalogAPIResponseToCamel(value);
        }
      }
      return value;
    });
  } else {
    newO = {};
    for (origKey in o) {
      if (o.hasOwnProperty(origKey)) {
        var firstFour = origKey.substring(0, 4);
        if (firstFour !== 'GDPR' && firstFour !== 'CELA') {
          // TODO: remove
          newKey = (
            origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey
          ).toString();
        } else {
          newKey = origKey.toString();
        }
        value = o[origKey as keyof object];
        if (
          value instanceof Array ||
          (value !== null && value.constructor === Object)
        ) {
          value = catalogAPIResponseToCamel(value);
        }
        newO[newKey as keyof object] = value;
      }
    }
  }
  return newO;
}
