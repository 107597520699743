import { useDispatch, useSelector } from 'react-redux';
import {
  DatasetListStatus,
  deleteDataset,
  disableDataset,
  enableDataset,
  getDatasets,
  getUserDatasets,
  selectAllDatasets,
  selectDatasetStatus,
} from 'features/orchestrator/datasetSlice';
import { useEffect, useRef, useState } from 'react';
import { IObjectWithKey, Stack } from '@fluentui/react';
import {
  DisplayTypes,
  ElxTableContainer,
  FilterDisplayMode,
  FilterOption,
  FilterOptionPillMode,
  IElxColumn,
  IElxContainerProps,
  ITableAction,
  IPickerItem,
  ElxDialog,
} from '@elixir/components';
import { Dataset } from 'features/orchestrator/models/dataset';
import { colLarge, colMedium } from 'utils/sharedStyles';
import { selectWorkspaceId } from 'features/workspaces/workspaceSlice';
import { AppDispatch } from 'app/lensShellUtility';
import AddDataset from './addDataset';
import EditDataset from './editDataset';
import MetricsDataset from './metricsDataset';
import notifier from 'utils/notifier';
import datasetApi from 'features/orchestrator/api/datasetApi';

export enum DataSetDialogType {
  None = 'None',
  AddDataSet = 'AddDataSet',
  EditDataSet = 'EditDataSet',
  MetricsDataset = 'MetricsDataset',
  DeleteConfirm = 'DeleteConfirm',
}

const Monitor = (props: any): JSX.Element => {
  const workspaceId = useSelector(selectWorkspaceId);
  const dataSetList = useSelector(selectAllDatasets);
  const datasetListStatus = useSelector(selectDatasetStatus);
  const dispatch = useDispatch<AppDispatch>();
  const divElxTableContaierRef = useRef(null);

  const [showMyDataset, setShowMyDataset] = useState<boolean>(true);
  const [selectedItems, setSelectedItems] = useState<IObjectWithKey[]>([]);
  const [uniqNum, setUniqNum] = useState(0);
  const [DataSetDialog, setDataSetDialog] = useState<DataSetDialogType>(
    DataSetDialogType.None
  );
  const [dataset2Edit, setDataset2Edit] = useState<Dataset | undefined>();

  const moreThanOneSelected = selectedItems.length > 1;
  const noneSelected = selectedItems.length === 0;

  const containerProps = {
    isLoading: false,
  } as IElxContainerProps;

  useEffect(() => {
    dispatch(getUserDatasets());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const namePillFilter: FilterOption = {
    field: 'name',
    label: 'Name',
    multiselect: true,
    pillMode: FilterOptionPillMode.Dynamic,
    values: dataSetList
      .map((dataSet) => dataSet.displayName || '')
      .filter((value, index, self) => self.indexOf(value) === index),
  };

  const createdByPillFilter: FilterOption = {
    field: 'createdBy',
    label: 'Created By',
    multiselect: true,
    pillMode: FilterOptionPillMode.Dynamic,
    values: dataSetList
      .map((dataSet) => dataSet.createdBy)
      .filter((value, index, self) => self.indexOf(value) === index),
  };

  const searchProps = {
    pillFilters: [
      namePillFilter /**
      permissionsPillFilter
    */,
      createdByPillFilter,
    ],
    filterDisplayMode: FilterDisplayMode.Pill,
  };
  const columns: IElxColumn[] = [
    {
      key: 'name',
      name: 'Name',
      fieldName: 'displayName',
      ...colLarge,
      minWidth: 200,
      maxWidth: 360,
    },
    {
      ...colMedium,
      key: 'state',
      name: 'State',
      onRender: (item: Dataset) => {
        return <span>{item.disabled ? 'Disabled' : 'Enabled'}</span>;
      },
    },
    {
      ...colLarge,
      key: 'metricsLookback',
      name: 'Metrics Lookback',
      fieldName: 'metricsLookback',
    },
    {
      ...colLarge,
      key: 'partitionSize',
      name: 'Partition Size',
      fieldName: 'partitionSize',
    },
    {
      ...colLarge,
      key: 'serviceId',
      name: 'Service Id',
      fieldName: 'serviceId',
    },
    {
      ...colLarge,
      key: 'sla',
      name: 'Sla',
      fieldName: 'sla',
    },
    {
      ...colLarge,
      key: 'id',
      name: 'Id',
      fieldName: 'id',
    },
    {
      ...colLarge,
      key: 'relativePath',
      name: 'Relative Path',
      onRender: (item: Dataset) => {
        return (
          <span>{`${item.endpoint?.folder || ''}${item.endpoint?.pattern}${
            item.endpoint?.bucketPostfixFormat || ''
          }`}</span>
        );
      },
    },
    {
      ...colLarge,
      key: 'Latest Stream',
      name: 'Latest Stream',
      onRender: (item: Dataset) => {
        return (
          <span>{`${
            !Array.isArray(item.metrics)
              ? item.metrics?.latestPartitionDate || ''
              : ''
          }`}</span>
        );
      },
    },
    {
      ...colLarge,
      key: 'Next SLA',
      name: 'Next SLA',
      onRender: (item: Dataset) => {
        return (
          <span>{`${
            !Array.isArray(item.metrics) ? item.metrics?.nextSlaDate || '' : ''
          }`}</span>
        );
      },
    },
    {
      ...colLarge,
      key: 'Next Latency',
      name: 'Next Latency',
      onRender: (item: Dataset) => {
        const nextLatencyStatus = !Array.isArray(item.metrics)
          ? item.metrics?.nextLatencyStatus || ''
          : '';
        const color = nextLatencyStatus === 'OnTime' ? 'green' : 'red';
        return <span style={{ color }}>{`${nextLatencyStatus}`}</span>;
      },
    },
    {
      ...colLarge,
      key: 'Latest Modified Date',
      name: 'Latest Modified Date',
      onRender: (item: Dataset) => {
        const latestModifiedDate = !Array.isArray(item.metrics)
          ? item.metrics?.latestModifiedDate || ''
          : '';
        return <span>{`${latestModifiedDate}`}</span>;
      },
    },
  ];

  const loadMyDataset = () => {
    dispatch(getUserDatasets()).then(() => {
      setShowMyDataset(true);
    });
  };

  const loadWorkspaceDataset = () => {
    dispatch(getDatasets({ workspaceId })).then(() => {
      setShowMyDataset(false);
    });
  };
  const refresh = () => {
    if (showMyDataset) {
      dispatch(getUserDatasets());
    } else {
      dispatch(getDatasets({ workspaceId }));
    }
    setUniqNum((state) => state + 1);
  };

  const setDtSetEnable = async (dataset: Dataset, enable: boolean) => {
    if (enable) {
      return dispatch(enableDataset({ datasetId: dataset.id }));
    } else {
      return dispatch(disableDataset({ datasetId: dataset.id }));
    }
  };

  const setDatasetsEnable = async (
    items: IObjectWithKey[],
    enable: boolean
  ) => {
    const promises = items.map((item) => {
      const dataset = item as Dataset;
      return setDtSetEnable(dataset, enable);
    });
    return await Promise.all(promises).then(() => {
      refresh();
    });
  };

  const deleteDtSet = async (dataset: Dataset) => {
    return dispatch(deleteDataset({ datasetId: dataset.id }));
  };

  const deleteDatasets = async (items: IObjectWithKey[]) => {
    const promises = items.map((item) => {
      const dataset = item as Dataset;
      return deleteDtSet(dataset);
    });
    return await Promise.all(promises).then(() => {
      refresh();
    });
  };

  const AddDtSet = () => {
    setDataSetDialog(DataSetDialogType.AddDataSet);
  };

  const editDtSet = () => {
    setDataSetDialog(DataSetDialogType.EditDataSet);
  };

  const createDtSet = async (dataset: Dataset) => {
    try {
      await datasetApi.createDataset(dataset);
      setDataSetDialog(DataSetDialogType.None);
      refresh();
    } catch (err: any) {
      notifier.error(
        `${err} : Error while creating dataset : detail message : ${err.response?.data?.error?.message}`
      );
    }
  };

  const updateDtSet = async (dataset: Dataset) => {
    try {
      await datasetApi.updateDataset(dataset);
      setDataSetDialog(DataSetDialogType.None);
      refresh();
    } catch (err: any) {
      notifier.error(
        `${err} : Error while updating dataset :  detail message : ${err.response?.data?.error?.message}`
      );
    }
  };

  const actions: ITableAction[] = [
    {
      key: 'add',
      text: 'Add Dataset',
      defaultDisplay: DisplayTypes.Show,
      iconProps: { iconName: 'Add' },
      disableBulkAction: false,
      onAction: (item) => {
        AddDtSet();
      },
      onBulkAction: () => {
        AddDtSet();
      },
    },
    {
      key: 'edit',
      text: 'Edit ',
      defaultDisplay: DisplayTypes.Show,
      iconProps: { iconName: 'Edit' },
      disabled: noneSelected || moreThanOneSelected,
      disableBulkAction: true,
      onAction: (item) => {
        let dts = JSON.parse(JSON.stringify(item));
        setDataset2Edit(dts as Dataset);
        editDtSet();
      },
      onBulkAction: (items) => {
        let dts = JSON.parse(JSON.stringify(items[0]));
        setDataset2Edit(dts as Dataset);
        editDtSet();
      },
    },
    {
      key: 'metrics',
      text: 'Metrics',
      defaultDisplay: DisplayTypes.Show,
      iconProps: { iconName: 'Chart' },
      disabled: noneSelected || moreThanOneSelected,
      disableBulkAction: false,
      onAction: (item) => {
        setDataSetDialog(DataSetDialogType.MetricsDataset);
      },
      onBulkAction: () => {
        setDataSetDialog(DataSetDialogType.MetricsDataset);
      },
    },
    {
      key: 'Workspace datasets',
      text: 'Workspace datasets',
      defaultDisplay: DisplayTypes.Show,
      iconProps: { iconName: 'CollapseMenu' },
      disabled: !showMyDataset,
      disableBulkAction: false,
      onAction: (item) => {
        loadWorkspaceDataset();
      },
      onBulkAction: () => {
        loadWorkspaceDataset();
      },
    },
    {
      key: 'My datasets',
      text: 'My datasets',
      defaultDisplay: DisplayTypes.Show,
      iconProps: { iconName: 'database' },
      disabled: showMyDataset,
      disableBulkAction: false,
      onAction: (item) => {
        loadMyDataset();
      },
      onBulkAction: () => {
        loadMyDataset();
      },
    },
    {
      key: 'Enable dataset',
      text: 'Enable dataset',
      defaultDisplay: DisplayTypes.Show,
      iconProps: { iconName: 'ToggleRight' },
      disabled: noneSelected,
      disableBulkAction: false,
      onAction: (item) => {
        setDatasetsEnable([item], true);
      },
      onBulkAction: (items: IObjectWithKey[]) => {
        setDatasetsEnable(items, true);
      },
    },
    {
      key: 'Disable dataset',
      text: 'Disable dataset',
      defaultDisplay: DisplayTypes.Show,
      iconProps: { iconName: 'ToggleLeft' },
      disabled: noneSelected,
      disableBulkAction: false,
      onAction: (item) => {
        setDatasetsEnable([item], false);
      },
      onBulkAction: (items: IObjectWithKey[]) => {
        setDatasetsEnable(items, false);
      },
    },
    {
      key: 'Delete dataset',
      text: 'Delete dataset',
      defaultDisplay: DisplayTypes.Show,
      iconProps: { iconName: 'Delete' },
      disabled: false,
      disableBulkAction: false,
      onAction: (item) => {
        setDataSetDialog(DataSetDialogType.DeleteConfirm);
      },
      onBulkAction: (items: IObjectWithKey[]) => {
        setDataSetDialog(DataSetDialogType.DeleteConfirm);
      },
    },
    {
      key: 'columns',
      text: 'Columns',
      defaultDisplay: DisplayTypes.Show,
      iconProps: { iconName: 'Settings' },
      disableBulkAction: false,
      onBulkAction: () => {
        let elm = divElxTableContaierRef.current! as HTMLElement;
        let e = elm.querySelector("[data-icon-name='Settings']") as HTMLElement;
        e.click();
      },
      onAction: () => {
        let elm = divElxTableContaierRef.current! as HTMLElement;
        let e = elm.querySelector("[data-icon-name='Settings']") as HTMLElement;
        e.click();
      },
    },
  ];
  const customColumns: IPickerItem[] = columns.map((o) => ({
    key: o.key,
    text: o?.name.length === 0 ? o.key : o.name,
    selected: true,
  }));

  const [customSelectedKeys, setCustomSelectedKeys] = useState<string[]>(
    customColumns.map((o) => o.key)
  );
  return (
    <>
      <div ref={divElxTableContaierRef}>
        <Stack>
          <Stack.Item styles={{ root: { background: 'white' } }}>
            <Stack
              styles={{
                root: {
                  padding: '20px',
                  fontWeight: 600,
                  fontSize: '1.375rem',
                },
              }}
            >
              Data Monitor
            </Stack>
          </Stack.Item>
          <Stack.Item grow verticalFill>
            <ElxTableContainer
              containerProps={{
                ...containerProps,
                isLoading: DatasetListStatus.Loading === datasetListStatus,
                styles: {
                  subActions: { height: '0px !important' },

                  headerComponent: { paddingTop: '10px !important' },
                  subHeader: { height: '100%' },
                  header: { height: '110px' },
                  headingContainer: { width: '100%' },
                },
                onRenderHeader: () => {
                  return (
                    <Stack horizontal grow>
                      <Stack.Item>
                        <h3>Dataset Monitor</h3>
                      </Stack.Item>
                    </Stack>
                  );
                },
              }}
              tableProps={{
                columns,
                actions,
                selectedItems: selectedItems,
                items: dataSetList,
                getKey: (item: Dataset) => item.id,
                setKey: uniqNum.toString(),
                onSelectionChanged: setSelectedItems,
              }}
              searchBoxProps={searchProps}
              customColumnProps={{
                items: customColumns,
                selectedItemKeys: customSelectedKeys,
                onSave: setCustomSelectedKeys,
              }}
            ></ElxTableContainer>
          </Stack.Item>
        </Stack>
        {DataSetDialog === DataSetDialogType.AddDataSet && (
          <AddDataset
            onChange={function (dataset: Dataset): Promise<void> {
              return createDtSet(dataset);
            }}
            onDismiss={function (): void {
              setDataSetDialog(DataSetDialogType.None);
            }}
          ></AddDataset>
        )}
        {DataSetDialog === DataSetDialogType.EditDataSet && (
          <EditDataset
            dataset={dataset2Edit}
            onChange={function (dataset: Dataset): Promise<void> {
              return updateDtSet(dataset);
            }}
            onDismiss={function (): void {
              setDataSetDialog(DataSetDialogType.None);
            }}
          ></EditDataset>
        )}
        {DataSetDialog === DataSetDialogType.MetricsDataset && (
          <MetricsDataset
            selDataset={selectedItems[0] as Dataset}
            onDismiss={() => {
              setDataSetDialog(DataSetDialogType.None);
            }}
          ></MetricsDataset>
        )}
        {DataSetDialog === DataSetDialogType.DeleteConfirm && (
          <ElxDialog
            hidden={false}
            dismissable={false}
            dialogContentProps={{
              title: 'Delete Dataset(s)',
              subText: 'The dataset(s) will be deleted, are you sure?',
            }}
            primaryButtonProps={{
              text: 'Delete',
              onClick: () => {
                setDataSetDialog(DataSetDialogType.None);
                deleteDatasets(selectedItems);
              },
            }}
            cancelButtonProps={{
              text: 'Cancel',
              onClick: () => {
                setDataSetDialog(DataSetDialogType.None);
              },
            }}
            onDismiss={() => {
              setDataSetDialog(DataSetDialogType.None);
            }}
          ></ElxDialog>
        )}
      </div>
    </>
  );
};

export default Monitor;
