import { AzureSubscriptionSelector } from 'features/workspaces/components/editDataConnection/dataSourceComponents/azureSubscriptionSelector';
import { SelectorProps } from 'features/workspaces/components/editDataConnection/editDataConnectionHelper';
import {
  ConnectionDataSourceType,
  SubscriptionDataSource,
} from 'features/workspaces/models/project';
import azureSubscriptionClient from '../azureSubscriptionClient';
import { registerDataSourcePlugin } from '../registry';
import { DataSourcePlugin } from './dataSourcePlugin';

class AzureSubscriptionPlugin extends DataSourcePlugin {
  public dataSourceFormComponent(selectorProps: SelectorProps): JSX.Element {
    return (
      <AzureSubscriptionSelector
        workspace={selectorProps.workspace}
        state={selectorProps.state}
        setState={selectorProps.setState}
        customNameFilled={selectorProps.customNameFilled}
      />
    );
  }

  public generatedName(state: SubscriptionDataSource) {
    return state && state.subscriptionId
      ? 'azure.subscription.' + state.subscriptionId
      : '';
  }
}

registerDataSourcePlugin(
  ConnectionDataSourceType.Subscription,
  new AzureSubscriptionPlugin(
    ConnectionDataSourceType.Subscription,
    azureSubscriptionClient
  )
);
