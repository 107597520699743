import { ElxPicker, IPickerItem } from '@elixir/components';
import { useCallback, useEffect, useState } from 'react';
import { armClient } from 'utils/armClient';
import { LensLabel } from 'utils/lensLabel';

export interface SubscriptionInfo {
  subscriptionId: string;
  subscriptionName: string;
}

interface SubscriptionPickerProps {
  selectedSubscription: string;
  setSubscriptionInfo: (subscription: SubscriptionInfo) => void;
}

export const SubscriptionPicker = (
  props: SubscriptionPickerProps
): JSX.Element => {
  const { selectedSubscription, setSubscriptionInfo } = props;
  const [subscriptionList, setSubscriptionList] = useState<
    IPickerItem<string>[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getSubscriptionList = useCallback(() => {
    setIsLoading(true);
    armClient
      .fetchSubscriptionList()
      .then((result) => {
        const subscriptionArray = result.map(
          (subscription): IPickerItem<string> => ({
            key: subscription.subscriptionId,
            text: `${subscription.displayName} (${subscription.subscriptionId})`,
            data: subscription.displayName,
          })
        );
        subscriptionArray.sort((a, b) => a.text.localeCompare(b.text));
        setSubscriptionList(subscriptionArray);
      })
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    getSubscriptionList();
  }, [getSubscriptionList]);

  return (
    <ElxPicker
      onRenderLabel={() => (
        <LensLabel
          labelText="Subscription"
          hintText={
            'The subscription in which you want to create an Azure Managed Grafana instance'
          }
          required={true}
        ></LensLabel>
      )}
      selectedKey={selectedSubscription}
      items={subscriptionList}
      placeHolder="Select a subscription"
      calloutWidth={600}
      onChange={(item) => {
        const subscriptionId = item.key.toString() || '';
        const subscriptionName = item.data || '';
        setSubscriptionInfo({ subscriptionId, subscriptionName });
      }}
      isLoading={isLoading}
    />
  );
};
