import React, { useState } from 'react';
import { useLensShellTheme } from 'features/shell/lensShellStyles';
import { ElxPanel, PanelSize } from '@elixir/components';
import { ThemeProvider } from '@fluentui/react';
import { BasePanelProps } from 'components/panelManager/panelManager';
import { Stack } from '@fluentui/react';
import NotificationNav from 'features/notifications/nav/notificationNav';
import ActivityLogs from 'features/notifications/activityLogs/activityLogs';
import SupportNotifications from 'features/notifications/azureNotifications/supportNotifications';

export const Notifications = ({
  show,
  onShowPanel,
  closeButton,
}: BasePanelProps): JSX.Element => {
  const theme = useLensShellTheme();
  const [notificationSelected, setNotificationSelected] = useState(true);
  const [activityLogSelected, setActivityLogSelected] = useState(false);

  const setSelectedTab = (tab: string) => {
    if (tab === 'notification') {
      setNotificationSelected(true);
      setActivityLogSelected(false);
    } else {
      setNotificationSelected(false);
      setActivityLogSelected(true);
    }
  };
  return (
    // ElxPanels are 'outside' of LensShell, so they must have their own ThemeProvider wrapper
    <ThemeProvider theme={theme}>
      <ElxPanel
        headerText="Notifications Center"
        isOpen={show}
        size={PanelSize.medium}
        actions={[closeButton]}
        onDismiss={() => onShowPanel(false)}
      >
        <Stack verticalFill>
          <Stack horizontal verticalFill>
            <Stack.Item grow={3}>
              <NotificationNav
                setSelectedTab={setSelectedTab}
                notificationSelected={notificationSelected}
                activityLogSelected={activityLogSelected}
              />
            </Stack.Item>
            <Stack.Item grow={15}>
              {notificationSelected && <SupportNotifications />}
              {activityLogSelected && <ActivityLogs />}
            </Stack.Item>
          </Stack>
        </Stack>
      </ElxPanel>
    </ThemeProvider>
  );
};

export default Notifications;
