import React from 'react';
import { Route, Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import { DataCatalog } from './components/dataCatalog';

export interface catalogRouteProps {
  manifestKey?: string;
  subPage?: string;
}

const CatalogRoute = (): JSX.Element => {
  return (
    <Switch>
      <Route exact path={'/datacatalog'}>
        <Redirect to="/datacatalog/list"></Redirect>
      </Route>
      <Route
        path={[
          '/datacatalog/details/:manifestKey/:subPage',
          '/datacatalog/list',
        ]}
        render={(props: RouteComponentProps<catalogRouteProps>) => {
          return <DataCatalog {...props.match.params} />;
        }}
      ></Route>
    </Switch>
  );
};

export default CatalogRoute;
