import React, { useEffect, useState } from 'react';
import { DirectionalHint, Callout, Stack } from '@fluentui/react';
import { useDispatch, useSelector } from 'react-redux';
import { allSupportNotifications, uiNotifier } from '../notificationsSlice';
import ReactMarkdown from 'react-markdown';
import CloseButton from 'components/closeButton/closeButton';
import {
  saveSupportNotificationStatus,
  selectUserprofile,
} from 'features/userprofile/userprofileSlice';
import { SupportNotificationUserProfileStatus } from './models/supportNotificationUserProfileStatus';
import { onShowPanel, PanelState } from 'components/panelManager/panelManager';
import { ElxActionButton } from '@elixir/components';
import { SidePanel } from 'features/shell/lensShell';
import { getSupportNotifications } from './api/azureSupportNotificationApi';
import { AppDispatch } from 'app/lensShellUtility';
import { DateTime } from 'luxon';

// function to smoothly resize notification popup up to a fixed max width.
function computeCalloutWidth(): number {
  const maxW = 1000;
  const maxPercent = 0.6;
  const minW = 400;
  const minPercent = 0.8;

  const windowWidth = window.innerWidth;
  if (windowWidth >= maxW / maxPercent) return maxW;
  if (windowWidth <= minW / minPercent) return windowWidth * minPercent;

  // this is a linear interpolation function between maxPercent and minPercent
  // y = y1 + (x - x1)(y2-y1)/(x2 - x1)
  return (
    maxW +
    ((windowWidth - maxW / maxPercent) * (minW - maxW)) /
      (minW / minPercent - maxW / maxPercent)
  );
}

/**
 * A notification panel for messages from Lens team and DRIs
 * @param props - needs the panelState in order to switch to notifications side panel
 */
export const SupportNotificationPopup = ({
  panelState,
}: {
  panelState: PanelState;
}): JSX.Element => {
  const userprofile = useSelector(selectUserprofile);
  const supportNotifications = useSelector(allSupportNotifications);
  const dispatch = useDispatch<AppDispatch>();

  const newNotifications = supportNotifications
    .filter((item) => item.Show === true && item.IsNew === true)
    .sort(
      (a, b) =>
        (b.Created ? DateTime.fromISO(b.Created).toMillis() : 0) -
        (a.Created ? DateTime.fromISO(a.Created).toMillis() : 0)
    );

  const notification = newNotifications?.[0];

  const [calloutWidth, setCalloutWidth] = useState<number>(
    computeCalloutWidth()
  );

  // update dynamic width as window resizes
  useEffect(() => {
    const updateCalloutWidth = () => {
      setCalloutWidth(computeCalloutWidth());
    };

    window.addEventListener('resize', updateCalloutWidth);
    return () => {
      window.removeEventListener('resize', updateCalloutWidth);
    };
  }, []);

  // refresh the support notifications from back-end every 3 minutes
  useEffect(() => {
    async function fetchSupportNotifications() {
      const azureNotifications = await getSupportNotifications();
      dispatch(uiNotifier.addSupportNotification(azureNotifications));
      dispatch(uiNotifier.updateSupportNotificationObj);
    }

    const interval = setInterval(() => {
      if (document.visibilityState === 'visible') {
        fetchSupportNotifications();
      }
    }, 180000); // refresh every 3 minutes

    return () => clearInterval(interval);
  }, [dispatch]);

  // on dismiss, mark the current notification as "seen" in user profile and not "isNew"
  // in the redux support notification state
  function onDismiss() {
    let notificationList: SupportNotificationUserProfileStatus[] = JSON.parse(
      userprofile.SupportNotificationStatus.trim()
    );
    notificationList = notificationList.map((item) => ({
      ...item,
      Seen: item.NotificationId === notification.Id || item.Seen,
    }));
    const supportNotificationStatus = JSON.stringify(notificationList);
    dispatch(uiNotifier.unsetIsNewSupportNotification(notification.Id));
    dispatch(
      saveSupportNotificationStatus({ userprofile, supportNotificationStatus })
    );
  }

  return (
    <>
      {notification && (
        <Callout
          directionalHint={DirectionalHint.bottomRightEdge}
          target={`#lens-notification-icon`}
          dismissOnTargetClick
          preventDismissOnLostFocus
          calloutWidth={calloutWidth}
          beakWidth={20}
          gapSpace={4}
        >
          <Stack tokens={{ padding: '16px 32px 16px 16px' }}>
            <ReactMarkdown>{notification.Message}</ReactMarkdown>
            {newNotifications.length > 1 && (
              <Stack.Item align="end">
                <ElxActionButton
                  onClick={() =>
                    onShowPanel(panelState, SidePanel.NOTIFICATIONS)
                  }
                >
                  Show {newNotifications.length - 1} more new notification
                  {newNotifications.length > 2 ? 's' : ''}
                </ElxActionButton>
              </Stack.Item>
            )}
          </Stack>
          <CloseButton onDismiss={onDismiss} />
        </Callout>
      )}
    </>
  );
};

export default SupportNotificationPopup;
