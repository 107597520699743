import { RootState, AppDispatch } from 'app/lensShellUtility';
import {
  ElxChoiceGroup,
  ElxDropdown,
  ElxPanel,
  ElxTextField,
  InputMessageTypes,
  PanelSize,
} from '@elixir/components';
import { IChoiceGroupOption, Stack } from '@fluentui/react';
import { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { $laConstants, orchestratorConstants } from 'utils/constants';
import { LensLabel } from 'utils/lensLabel';
import { Dependency, Job, JobScheduleEntity } from '../../models/job';
import {
  choiceGroupStyles,
  groupBorder,
} from '../JobActivities/JobAuthorStyles';
import { Workspace } from 'features/workspaces/models/workspace';
import {
  getJobs,
  selectAllJobs,
  selectJobById,
} from 'features/orchestrator/jobSlice';
import { SelectDataConnection } from './selectDataConnection';
import { ProjectDataConnection } from 'features/workspaces/models/project';
import DayHourMinSecPicker from 'components/dayHourMinSecPicker';
import notifier from 'utils/notifier';

const jobDependencyTypeOptions = ['Data', 'Job'];
const getDependencyTypeOrDefault = (entity: JobScheduleEntity) => {
  return entity?.endpoint?.type ===
    orchestratorConstants.EndpointTypes.OrchestratorJob ||
    entity?.endpoint?.type ===
      orchestratorConstants.EndpointTypes.OrchestratorJobActivity
    ? jobDependencyTypeOptions[1]
    : jobDependencyTypeOptions[0];
};

export interface JobScheduleDependencyEntityProps {
  onDismiss: () => void;
  onChange: (entity: Object) => void;
  entity?: JobScheduleEntity;
  isEdit: boolean;
  dependencies: Dependency[];
  workspace: Workspace;
  job: Job;
}

export function JobScheduleDependencyEntity(
  props: JobScheduleDependencyEntityProps
) {
  const dispatch = useDispatch<AppDispatch>();
  const { isEdit, entity, onChange, onDismiss, dependencies, workspace, job } =
    props;
  const [jobDependencyType, setJobDependencyType] = useState(
    getDependencyTypeOrDefault(entity!)
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [tempObj, setTempObj] = useState(
    isEdit ? { ...entity } : { endpoint: { type: jobDependencyType } }
  );
  const entityTypesSet: Set<string> = new Set(
    dependencies?.map((d: Dependency) => d.endpoint.type) || []
  );
  const onlyCosmos =
    entityTypesSet.has(orchestratorConstants.EndpointTypes.CosmosStream) ||
    entityTypesSet.has(
      orchestratorConstants.EndpointTypes.AzureDataLakeStorageGen2
    );

  if (onlyCosmos) {
    tempObj.partitionSize = tempObj.partitionSize || '1.00:00:00';
  } else {
    tempObj.partitionSize = null;
  }

  const jobList = useSelector(selectAllJobs);
  const dependentJob: Job | undefined = useSelector((state: RootState) =>
    selectJobById(state, tempObj.endpoint!.jobName!)
  );

  if (jobDependencyType === 'Job') {
    tempObj.endpoint!.projectName = workspace.dsoProject;
  }

  const getJobName = useCallback(
    (id: string | undefined) => {
      if (id) {
        const job = jobList.find((j) => j.id === id);
        return job?.name || id;
      } else {
        return '';
      }
    },
    [jobList]
  );
  useEffect(() => {
    dispatch(getJobs({ workspaceId: workspace.id }));
  }, [workspace.id, dispatch]);
  return (
    <ElxPanel
      headerText={`${isEdit ? 'Edit' : 'Add'} Dependency (Trigger)`}
      isOpen={true}
      size={PanelSize.large}
      onDismiss={onDismiss}
      actions={[
        {
          key: `${isEdit ? 'Save' : 'Add'} dependency`,
          text: `${isEdit ? 'Save' : 'Add'} dependency`,
          primary: true,
          onClick: () => {
            const {
              projectName,
              type,
              jobName,
              activityId,
              connectionName,
              tableType,
              table,
              catalogEntity,
              folder,
              pattern,
              fileSystem,
              path,
            } = tempObj.endpoint!;
            let newEp;
            switch (type) {
              case 'OrchestratorJobActivity':
              case 'OrchestratorJob':
                newEp = { projectName, type, jobName, activityId };
                tempObj.partitionSize = null;
                break;
              case orchestratorConstants.EndpointTypes.KustoTable:
                newEp = { connectionName, type, tableType, table };
                tempObj.partitionSize = null;
                break;
              case orchestratorConstants.EndpointTypes.CosmosStream:
                if (catalogEntity) {
                  newEp = { connectionName, type, catalogEntity };
                } else {
                  newEp = { connectionName, type, folder, pattern };
                }
                tempObj.partitionSize = tempObj.partitionSize || '1.00:00:00';
                break;
              case orchestratorConstants.EndpointTypes.AzureDataLakeStorageGen2:
                if (catalogEntity) {
                  newEp = { connectionName, type, catalogEntity };
                } else {
                  newEp = { connectionName, type, fileSystem, path, pattern };
                }
                tempObj.partitionSize = tempObj.partitionSize || '1.00:00:00';
                break;
              default:
                break;
            }

            onChange({ ...tempObj, endpoint: newEp || tempObj.endpoint });
          },
        },
        {
          key: 'close',
          text: 'Close',
          onClick: onDismiss,
        },
      ]}
    >
      <Stack tokens={{ childrenGap: 16, padding: 16 }}>
        <Stack.Item>
          <ElxChoiceGroup
            onRenderLabel={() => (
              <LensLabel
                labelText={'Select Dependency Type'}
                hintText={'Select dependency'}
              ></LensLabel>
            )}
            required={true}
            onChange={(e, option?: IChoiceGroupOption) => {
              setJobDependencyType(option?.key || jobDependencyTypeOptions[0]);
            }}
            options={jobDependencyTypeOptions.map((d) => ({
              key: d,
              text: d,
            }))}
            selectedKey={jobDependencyType}
            styles={choiceGroupStyles}
            disabled={onlyCosmos}
          />
        </Stack.Item>
        <Stack.Item>
          {jobDependencyType === 'Job' && (
            <>
              <Stack tokens={{ childrenGap: 16, padding: 8 }}>
                <Stack.Item>
                  <ElxTextField
                    id={'select workspace'}
                    onRenderLabel={() => (
                      <LensLabel
                        labelText={'Workspace'}
                        hintText={
                          'The dependency workspace. A job dependency must belong to the same workspace as the current job.'
                        }
                        required={true}
                      ></LensLabel>
                    )}
                    value={workspace.name}
                    disabled={true}
                  ></ElxTextField>
                </Stack.Item>
                <Stack.Item>
                  <ElxDropdown
                    onRenderLabel={() => {
                      return (
                        <LensLabel
                          labelText={'Job Id'}
                          hintText={
                            'Select a Job from the list of all jobs in the workspace!'
                          }
                          required={true}
                        ></LensLabel>
                      );
                    }}
                    options={jobList
                      .filter((j: Job) => job.id !== j.id)
                      .map((j) => ({ key: j.id, text: `${j.id}:${j.name}` }))}
                    message={{
                      type: InputMessageTypes.Info,
                      content: `Selected Job: ${getJobName(
                        tempObj?.endpoint?.jobName
                      )}`,
                    }}
                    selectedKey={tempObj?.endpoint?.jobName}
                    onChange={(_, option) => {
                      tempObj!.endpoint!.jobName =
                        (option?.key as string) || '';
                      tempObj!.endpoint!.activityId = '';
                      tempObj!.endpoint!.type = 'OrchestratorJob';
                      setTempObj({ ...tempObj });
                    }}
                  ></ElxDropdown>
                </Stack.Item>
                <Stack.Item>
                  <ElxDropdown
                    onRenderLabel={() => {
                      return (
                        <LensLabel
                          labelText={'Activity Id'}
                          hintText={
                            'Optionally,select an activity from the list of all activities in the selected Job!'
                          }
                          required={false}
                        ></LensLabel>
                      );
                    }}
                    options={
                      dependentJob?.config?.activities?.map((a) => ({
                        key: a.id,
                        text: `${a.id}:${a.activityDisplayName}`,
                      })) || []
                    }
                    selectedKey={tempObj?.endpoint?.activityId}
                    onChange={(_, option) => {
                      tempObj!.endpoint!.activityId =
                        (option?.key as string) || '';
                      tempObj!.endpoint!.type = 'OrchestratorJobActivity';
                      setTempObj({ ...tempObj });
                    }}
                  ></ElxDropdown>
                </Stack.Item>
              </Stack>
            </>
          )}
          {jobDependencyType === 'Data' && (
            <>
              <Stack tokens={{ childrenGap: 16, padding: 16 }}>
                <Stack.Item>
                  <SelectDataConnection
                    onSelect={(dc: ProjectDataConnection) => {
                      let type;
                      let dcType = dc.type;
                      if (
                        dcType === orchestratorConstants.DataSourceTypes.SHARED
                      ) {
                        const sharedConn =
                          workspace.sourceDataConnections?.find((s) => {
                            return s.targetConnectionName === dc.connectionName;
                          });
                        if (sharedConn) {
                          dcType = sharedConn.sourceConnection.type;
                        } else {
                          notifier.error('Shared connection not found.');
                        }
                      }
                      switch (dcType) {
                        case orchestratorConstants.DataSourceTypes.KUSTO:
                          type = orchestratorConstants.EndpointTypes.KustoTable;
                          break;
                        case orchestratorConstants.DataSourceTypes.COSMOS:
                          type =
                            orchestratorConstants.EndpointTypes.CosmosStream;
                          tempObj!.endpoint!.catalogEntity = '';
                          break;
                        case orchestratorConstants.DataSourceTypes.ADLSGEN2:
                          type =
                            orchestratorConstants.EndpointTypes
                              .AzureDataLakeStorageGen2;
                          tempObj!.endpoint!.catalogEntity = '';
                          break;
                        default:
                          break;
                      }
                      tempObj!.endpoint!.type = type || '';
                      tempObj!.endpoint!.connectionName = dc.connectionName;
                      setTempObj({ ...tempObj });
                    }}
                    filter={
                      dependencies.length === 0
                        ? [
                            $laConstants.DataSourceTypes.COSMOS,
                            $laConstants.DataSourceTypes.ADLSGEN2,
                            $laConstants.DataSourceTypes.KUSTO,
                          ]
                        : onlyCosmos
                        ? [
                            $laConstants.DataSourceTypes.COSMOS,
                            $laConstants.DataSourceTypes.ADLSGEN2,
                          ]
                        : [$laConstants.DataSourceTypes.KUSTO]
                    }
                    selectedKey={tempObj?.endpoint?.connectionName || null}
                    required={true}
                  ></SelectDataConnection>
                </Stack.Item>
                {tempObj?.endpoint?.connectionName && (
                  <>
                    {(tempObj.endpoint?.type ===
                      orchestratorConstants.EndpointTypes.CosmosStream ||
                      tempObj.endpoint?.type ===
                        orchestratorConstants.EndpointTypes
                          .AzureDataLakeStorageGen2) && (
                      <>
                        <Stack.Item className={groupBorder}>
                          <ElxChoiceGroup
                            onRenderLabel={() => (
                              <LensLabel
                                labelText={'Select Entity'}
                                hintText={'Select Entity Type'}
                                required
                              ></LensLabel>
                            )}
                            onChange={(e, option?: IChoiceGroupOption) => {
                              if (option?.key === 'Geneva') {
                                tempObj.endpoint!.catalogEntity =
                                  tempObj.endpoint?.catalogEntity || '';
                              } else {
                                delete tempObj.endpoint?.catalogEntity;
                              }
                              setTempObj({ ...tempObj });
                            }}
                            options={[
                              { key: 'Geneva', text: 'Geneva Data' },
                              {
                                key: 'Stream',
                                text: `${
                                  tempObj.endpoint!.type ===
                                  orchestratorConstants.EndpointTypes
                                    .CosmosStream
                                    ? 'Cosmos '
                                    : 'Adls '
                                } stream`,
                              },
                            ]}
                            selectedKey={
                              tempObj.endpoint?.catalogEntity === undefined
                                ? 'Stream'
                                : 'Geneva'
                            }
                            styles={choiceGroupStyles}
                          />
                          <Stack tokens={{ childrenGap: 16, padding: 16 }}>
                            {tempObj.endpoint?.catalogEntity !== undefined && (
                              <>
                                <Stack.Item>
                                  <ElxTextField
                                    onRenderLabel={() => {
                                      return (
                                        <LensLabel
                                          labelText={'Geneva Entity'}
                                          hintText={
                                            'The Geneva Data Catalog entity. It is the DisplayName of the dataset, for example Microsoft.Cloud.Tree.OrganizationMetadataV2.'
                                          }
                                        ></LensLabel>
                                      );
                                    }}
                                    value={tempObj.endpoint.catalogEntity}
                                    onChange={(e, value) => {
                                      tempObj.endpoint!.catalogEntity =
                                        value || '';
                                      setTempObj({ ...tempObj });
                                    }}
                                  ></ElxTextField>
                                </Stack.Item>
                              </>
                            )}
                            {tempObj.endpoint?.catalogEntity === undefined && (
                              <>
                                {tempObj.endpoint!.type ===
                                  orchestratorConstants.EndpointTypes
                                    .CosmosStream && (
                                  <>
                                    <Stack.Item>
                                      <ElxTextField
                                        onRenderLabel={() => {
                                          return (
                                            <LensLabel
                                              labelText={'Stream Name/Folder'}
                                              hintText={`The stream name or folder without the pattern (%y, %m, %d, %h), for example /shares/AzureAnalytics.Dev/Public-Published-Microsoft.Cloud.Tree/OrganizationMetadataV2/.`}
                                              required
                                            ></LensLabel>
                                          );
                                        }}
                                        value={tempObj.endpoint.folder}
                                        onChange={(e, value) => {
                                          tempObj.endpoint!.folder =
                                            value || '';
                                          setTempObj({ ...tempObj });
                                        }}
                                      ></ElxTextField>
                                    </Stack.Item>

                                    <Stack.Item>
                                      <ElxTextField
                                        onRenderLabel={() => {
                                          return (
                                            <LensLabel
                                              labelText={'Pattern'}
                                              hintText={
                                                'The stream pattern, for example %Y/%m/Data_%Y_%m_%d.ss.'
                                              }
                                            ></LensLabel>
                                          );
                                        }}
                                        value={tempObj.endpoint.pattern}
                                        onChange={(e, value) => {
                                          tempObj.endpoint!.pattern =
                                            value || '';
                                          setTempObj({ ...tempObj });
                                        }}
                                      ></ElxTextField>
                                    </Stack.Item>
                                  </>
                                )}
                                {tempObj.endpoint!.type ===
                                  orchestratorConstants.EndpointTypes
                                    .AzureDataLakeStorageGen2 && (
                                  <>
                                    <Stack.Item>
                                      <ElxTextField
                                        onRenderLabel={() => {
                                          return (
                                            <LensLabel
                                              labelText={
                                                'ADLS Gen2 File System'
                                              }
                                              hintText={`The file system name.
                                            This name must be between 3 and 63 characters long.
                                            It must begin with a letter or number, and it may only contain lowercase letters, numbers, and hyphens.
                                            Each hyphen must be preceded and followed by a non-hyphen character.`}
                                            ></LensLabel>
                                          );
                                        }}
                                        value={tempObj.endpoint.fileSystem}
                                        onChange={(e, value) => {
                                          tempObj.endpoint!.fileSystem =
                                            value || '';
                                          setTempObj({ ...tempObj });
                                        }}
                                      ></ElxTextField>
                                    </Stack.Item>

                                    <Stack.Item>
                                      <ElxTextField
                                        onRenderLabel={() => {
                                          return (
                                            <LensLabel
                                              labelText={'Path'}
                                              hintText={`The stream path in the form '<parentfolder>/<folder>/<childfolder>' up to 1024 characters.`}
                                              required
                                            ></LensLabel>
                                          );
                                        }}
                                        value={tempObj.endpoint.path}
                                        onChange={(e, value) => {
                                          tempObj.endpoint!.path = value || '';
                                          setTempObj({ ...tempObj });
                                        }}
                                      ></ElxTextField>
                                    </Stack.Item>

                                    <Stack.Item>
                                      <ElxTextField
                                        onRenderLabel={() => {
                                          return (
                                            <LensLabel
                                              labelText={'Pattern'}
                                              hintText={
                                                'The stream pattern, for example %Y/%m/Data_%Y_%m_%d.ss.'
                                              }
                                            ></LensLabel>
                                          );
                                        }}
                                        value={tempObj.endpoint.pattern}
                                        onChange={(e, value) => {
                                          tempObj.endpoint!.pattern =
                                            value || '';
                                          setTempObj({ ...tempObj });
                                        }}
                                      ></ElxTextField>
                                    </Stack.Item>
                                  </>
                                )}
                              </>
                            )}
                          </Stack>
                        </Stack.Item>

                        <Stack.Item>
                          <LensLabel
                            labelText="Partition"
                            hintText={`The stream time partition size. The most common values are 1 hour and 1 day.`}
                            required={true}
                          ></LensLabel>
                          <DayHourMinSecPicker
                            showSeconds={false}
                            setValue={tempObj.partitionSize || '1.00:00:00'}
                            onChange={(value: string): void => {
                              tempObj.partitionSize = value;
                              setTempObj({ ...tempObj });
                            }}
                            showDays={true}
                            showHours={true}
                            showMinutes={true}
                            maxDays={1000}
                          ></DayHourMinSecPicker>
                        </Stack.Item>
                      </>
                    )}

                    {tempObj?.endpoint?.type ===
                      orchestratorConstants.EndpointTypes.KustoTable && (
                      <>
                        <Stack.Item>
                          <ElxTextField
                            onRenderLabel={() => {
                              return (
                                <LensLabel
                                  labelText={'Table'}
                                  hintText={'The dependency table name'}
                                ></LensLabel>
                              );
                            }}
                            value={tempObj.endpoint.table}
                            onChange={(e, value) => {
                              tempObj.endpoint!.table = value || '';
                              setTempObj({ ...tempObj });
                            }}
                          ></ElxTextField>
                        </Stack.Item>
                        <Stack.Item>
                          <ElxDropdown
                            onRenderLabel={() => (
                              <LensLabel
                                labelText={'Table Type'}
                                hintText={
                                  'The dependency table type. Only Overwrite (Snapshot) is supported currently.'
                                }
                                required={true}
                              ></LensLabel>
                            )}
                            options={[
                              orchestratorConstants.TableTypes.Snapshot,
                            ].map((d: string) => ({
                              key: d,
                              text: d,
                            }))}
                            selectedKey={tempObj.endpoint.tableType}
                            onChange={(e, options) => {
                              tempObj.endpoint!.tableType =
                                options?.key! as string;
                              setTempObj({ ...tempObj });
                            }}
                            message={{
                              type: InputMessageTypes.Info,
                              content: `Note: Kusto dependency will work only if the dependencies are produed by other orchestrator jobs`,
                            }}
                          ></ElxDropdown>
                        </Stack.Item>
                      </>
                    )}
                  </>
                )}
              </Stack>
            </>
          )}
        </Stack.Item>
      </Stack>
    </ElxPanel>
  );
}
