import { Label, Stack } from '@fluentui/react';
import { DeleteIconProps, ElxActionButton } from '@elixir/components';
import { Project } from 'features/workspaces/models/project';
import React, { useState } from 'react';
import { FormError, WorkspaceHr } from '../../workspaceHelperComponents';
import { wideSpacingStackTokens } from '../../workspaceStyles';
import { ParameterType, WorkspaceParameter } from '../workspaceSettingsUtil';
import Parameter from './parameter';
interface ParametersListProps {
  parameters: WorkspaceParameter[];
  project: Project;
  updateOrchestratorProject: (project: Project) => void;
  onChange: (event: any) => void;
  showError: any;
}
const filterParams = (params: WorkspaceParameter[]) => {
  return params.filter(
    (param) =>
      param.name !== '' &&
      param.value !== '' &&
      param.type !== ParameterType.None
  );
};

const ParametersList = (props: ParametersListProps): JSX.Element => {
  const [parameters, setParameters] = useState(props.parameters);
  const _onChange = (newParams: WorkspaceParameter[]) => {
    props.updateOrchestratorProject({
      ...props.project,
      parameters: filterParams(newParams),
    });
    props.onChange({
      target: {
        name: 'updatedParameterList',
        value: filterParams(newParams),
      },
    });
  };
  return (
    <Stack>
      <Stack.Item>
        <FormError
          showError={props.showError}
          errorMsg="Workspace parameters error"
        />
      </Stack.Item>
      <Stack.Item>
        <Stack tokens={wideSpacingStackTokens}>
          {/* Parameter list labels */}
          <Stack.Item grow>
            <Stack horizontal>
              <Stack.Item
                grow={3}
                align="start"
                className="parameter-name-label"
              >
                <Label>{'Name'}</Label>
              </Stack.Item>

              <Stack.Item
                grow={3}
                align="start"
                className="parameter-value-label"
              >
                <Label>{'Value'}</Label>
              </Stack.Item>

              <Stack.Item
                grow={3}
                align="start"
                className="parameter-type-label"
              >
                <Label>{'Type'}</Label>
              </Stack.Item>

              <Stack.Item grow align="start">
                <Label>{''}</Label>
              </Stack.Item>
            </Stack>
            <WorkspaceHr />
          </Stack.Item>

          {/* The parameters list */}
          <Stack.Item grow>
            <Stack tokens={wideSpacingStackTokens}>
              {parameters.map((param, index) => (
                <Stack.Item key={index} className={'parameter-item'}>
                  <Stack horizontal tokens={wideSpacingStackTokens} key={index}>
                    <Stack.Item>
                      <Stack horizontal>
                        <Parameter
                          key={index}
                          index={index}
                          data={{
                            name: param?.name || '',
                            value: param?.value || '',
                            type: param?.type || ParameterType.None,
                          }}
                          onChange={(newParamVals) => {
                            const newParams = [...parameters];
                            newParams[index] = newParamVals;
                            setParameters(newParams);
                            _onChange(newParams);
                          }}
                        />
                        <ElxActionButton
                          iconProps={{
                            ...DeleteIconProps,
                            'aria-label': 'Delete Parameter',
                          }}
                          onClick={(e) => {
                            const tempParams = [...parameters];
                            tempParams.splice(index, 1);
                            setParameters(tempParams);
                            _onChange(tempParams);
                          }}
                          text={''}
                          tooltipProps={{
                            content: 'Delete parameter',
                          }}
                        />
                      </Stack>
                    </Stack.Item>
                  </Stack>
                </Stack.Item>
              ))}
            </Stack>
          </Stack.Item>

          {/* The add parameter button */}
          <Stack.Item align="start">
            <ElxActionButton
              iconProps={{
                iconName: 'add',
                title: 'Add parameter',
                'aria-label': 'Add parameter',
              }}
              text="Add parameter"
              onClick={() => {
                const newParams = [
                  ...parameters,
                  {
                    name: '',
                    value: '',
                    type: ParameterType.None,
                  },
                ];
                setParameters(newParams);
                _onChange(newParams);
              }}
              tooltipProps={{
                content: 'Add parameter',
              }}
            />
          </Stack.Item>
        </Stack>
      </Stack.Item>
    </Stack>
  );
};

export default ParametersList;
