import { Stack } from '@fluentui/react';
import { ElxPanel, PanelSize } from '@elixir/components';
import { ThemeProvider } from '@fluentui/react';
import HelpLink from 'components/helpLink/helpLink';
import { BasePanelProps } from 'components/panelManager/panelManager';
import { useLensShellTheme } from 'features/shell/lensShellStyles';
import React from 'react';
import { getEmail, showOCVFeedback } from 'utils/sharedLibs';

export const Feedback = ({
  show,
  onShowPanel,
  closeButton,
}: BasePanelProps): JSX.Element => {
  const onDismiss = () => {
    onShowPanel(false);
  };

  const theme = useLensShellTheme();
  let feedbackContent = (
    <div key={2} style={{ width: '100%', minHeight: 400 }}>
      <ocv-feedback-lite
        is-prod={process.env.NODE_ENV === 'production'}
        app-id="2585"
        area="Lens Suite"
        feedback-title=""
        allow-attachment="true"
        email={getEmail()}
      ></ocv-feedback-lite>
    </div>
  );
  if (!showOCVFeedback) {
    feedbackContent = (
      <div style={{ marginTop: 15 }}>
        Submitting feedback is not enabled in this Lens environment. Please send
        emails to
        <span style={{ marginLeft: 5 }}>
          <a href="mailto:lensxpsup@microsoft.com">lensxpsup@microsoft.com</a>.
        </span>
      </div>
    );
  }
  return (
    // ElxPanels are 'outside' of LensShell, so they must have their own ThemeProvider wrapper
    <ThemeProvider theme={theme}>
      <ElxPanel
        styles={{
          headerContent: { marginTop: -16 },
          overlay: { backgroundColor: 'rgb(0 0 0 / 40%)' },
        }} // force header content to overlap with the header text
        headerText="Provide Feedback"
        headerContent={feedbackContent}
        isOpen={show}
        size={PanelSize.small}
        actions={[closeButton]}
        onDismiss={onDismiss}
      >
        {
          <Stack tokens={{ padding: '10px 20px', childrenGap: 16 }}>
            <b>Helpful links:</b>
            <Stack tokens={{ childrenGap: 8 }}>
              <HelpLink
                iconName="Unknown"
                linkText="Ask us a question with Stack Overflow"
                href="https://stackoverflow.microsoft.com/questions/tagged/lensexplorer"
              />
              <HelpLink
                iconName="Bug"
                linkText="Report any issues in IcM"
                href="https://portal.microsofticm.com/imp/v3/incidents/create?tmpl=mLd617"
              />
              <HelpLink
                iconName="Lightbulb"
                linkText="Request a new feature in our backlog"
                href="https://microsoft.sharepoint.com/:w:/r/teams/LensSuite/_layouts/15/guestaccess.aspx?e=tYLx2r&CID=379E771C-DD59-4A9F-9B0F-838131DACD06&wdLOR=c63624786-9209-4B28-88E4-89F30CB9FF39&share=EVXYJLiFUOBJhgHkP_Ri9HcBGyCvrDNNmteAFo1PJtDJIw"
              />
            </Stack>
          </Stack>
        }
      </ElxPanel>
    </ThemeProvider>
  );
};

export default Feedback;
