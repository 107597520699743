export enum ColumnType {
  DYNAMIC = 'dynamic',
}
export enum DataType {
  OBJECT = 'Object',
}

export interface Column {
  ColumnName: string;
  ColumnType: ColumnType;
  DataType: DataType;
}

// export enum FieldType {
//     STRING = 'String',
//     GUID = 'Guid',
//     OTHER = 'Other',
//     DATETIME = 'DateTime',
// }

export interface Table {
  Columns: Column[];
  Rows: any[][];
}

// Just a stub for now...
export interface DataSource {
  Cluster: string;
  Database: string;
}

export interface Database {
  DatabaseName: string;
  PrettyName: string;
}
