import { SynapseScopeSelector } from 'features/workspaces/components/editDataConnection/dataSourceComponents/synapseScopeSelector';
import { SelectorProps } from 'features/workspaces/components/editDataConnection/editDataConnectionHelper';
import {
  ConnectionDataSourceType,
  SynapseScopeDataSource,
} from 'features/workspaces/models/project';
import { registerDataSourcePlugin } from '../registry';
import synapseScopeClient from '../synapseScopeClient';
import { DataSourcePlugin } from './dataSourcePlugin';

class SynapseScopePlugin extends DataSourcePlugin {
  public dataSourceFormComponent(selectorProps: SelectorProps): JSX.Element {
    return (
      <SynapseScopeSelector
        workspace={selectorProps.workspace}
        state={selectorProps.state}
        setState={selectorProps.setState}
        customNameFilled={selectorProps.customNameFilled}
      />
    );
  }

  public generatedName(state: SynapseScopeDataSource) {
    return state && state.workspace ? 'SynapseScope.' + state.workspace : '';
  }
}

registerDataSourcePlugin(
  ConnectionDataSourceType.SynapseScope,
  new SynapseScopePlugin(
    ConnectionDataSourceType.SynapseScope,
    synapseScopeClient
  )
);
