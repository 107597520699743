import { ElxDialog, ElxTextField } from '@elixir/components';
import { Stack } from '@fluentui/react';
import { useState } from 'react';

export interface GetScmIdProps {
  actionOnSuccess: (num: string) => void;
  onCancel: () => void;
  scmId?: string | null;
}
export const GetScmId = (props: GetScmIdProps) => {
  const { actionOnSuccess, onCancel, scmId } = props;
  const [taskId, setTaskId] = useState<string | null>(scmId || null);

  return (
    <ElxDialog
      hidden={false}
      dismissable={false}
      dialogContentProps={{
        title: 'Secure Change Id',
        subText:
          'Please provide a secure change id, it is needed for secure change management enabled workspace',
      }}
      primaryButtonProps={{
        text: 'Continue',
        disabled: !taskId,
        onClick: () => {
          if (taskId) {
            actionOnSuccess(taskId);
          }
        },
      }}
      cancelButtonProps={{ text: 'Cancel', onClick: onCancel }}
      onDismiss={() => {
        onCancel();
      }}
    >
      <Stack>
        <ElxTextField
          key={'securechangeid'}
          label={'Secure Change Id'}
          value={taskId || undefined}
          onChange={(e, value) => {
            setTaskId(value || null);
          }}
        />
      </Stack>
    </ElxDialog>
  );
};
