import { Stack, ThemeProvider } from '@fluentui/react';
import { ElxPanel, PanelSize } from '@elixir/components';
import React, { useState, useEffect, useCallback } from 'react';
import { useLensShellTheme } from 'features/shell/lensShellStyles';
import { Editor } from 'components/editor';

interface ImportJobsProps {
  importJobsValue: string;
  setImportJobsValue: (importjobsValue: string) => void;
  importJobCancelHandler: () => void;
  importJobsHandler: () => void;
}

export function ImportJobs(props: ImportJobsProps) {
  const theme = useLensShellTheme();
  // height of the monaco editor - approx height of window - panel header height - panel footer height - buffer;
  const [height, setHeight] = useState(window.innerHeight); // window.innerHeight - 100 - 80 - 60;
  const editorHeight = height - 220 < 0 ? 1200 : height - 240;
  const heightHandler = useCallback(() => {
    setHeight(window.innerHeight);
  }, [setHeight]);
  useEffect(() => {
    window.addEventListener('resize', heightHandler);
    return () => {
      window.removeEventListener('resize', heightHandler);
    };
  });

  return (
    <ThemeProvider theme={theme}>
      <ElxPanel
        headerText={'Import Jobs'}
        isOpen={true}
        size={PanelSize.large}
        onDismiss={() => {
          props.importJobCancelHandler();
        }}
        actions={[
          {
            key: 'import jobs',
            text: 'Import Jobs',
            primary: true,
            onClick: () => {
              props.importJobsHandler();
            },
          },
          {
            key: 'close',
            text: 'Close',
            onClick: () => {
              props.importJobCancelHandler();
            },
          },
        ]}
      >
        <Stack verticalFill tokens={{ childrenGap: 16, padding: 16 }}>
          <Editor
            language="json"
            onChange={props.setImportJobsValue}
            value={props.importJobsValue}
            height={editorHeight}
            width={'100%'}
          ></Editor>
        </Stack>
      </ElxPanel>
    </ThemeProvider>
  );
}
