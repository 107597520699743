import { ElxDropdown, ElxTextField } from '@elixir/components';
import { Stack } from '@fluentui/react';
import { Job } from 'features/orchestrator/models/job';
import { useState } from 'react';
import { orchestratorConstants } from 'utils/constants';
import { LensLabel } from 'utils/lensLabel';

const BackfillModeOptions = Object.values(
  orchestratorConstants.BackfillModeOptions
).map((v) => ({ key: v, text: v }));
const RespectBoundariesOptions = Object.values(
  orchestratorConstants.RespectBoundariesOptions
).map((v) => ({ key: v, text: v }));

interface JobScheduleDeltaProps {
  job: Job;
}
export const JobScheduleDelta = (props: JobScheduleDeltaProps) => {
  const { job } = props;
  job.config!.jobScheduleSettings!.deltaSettings = job.config
    ?.jobScheduleSettings?.deltaSettings || {
    lookback: 1,
    backFillMode: orchestratorConstants.BackfillModeOptions.None,
    maxDelta: 0,
    respectBoundaries: orchestratorConstants.RespectBoundariesOptions.None,
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setRender] = useState<Boolean>(false);

  return (
    <>
      <ElxTextField
        onRenderLabel={() => (
          <LensLabel
            labelText="Look Back"
            hintText={
              'The amount of recurrence cycles (hours, days or months) to look back for re-scheduling.'
            }
            required={false}
          ></LensLabel>
        )}
        value={job.config!.jobScheduleSettings!.deltaSettings!.lookback.toString()}
        deferredValidationTime={500}
        onChange={(e, value) => {
          job.config!.jobScheduleSettings!.deltaSettings!.lookback =
            Number(value) || 1;
          setRender((old) => !old);
        }}
      ></ElxTextField>

      <Stack.Item>
        <ElxDropdown
          onRenderLabel={() => (
            <LensLabel
              labelText="Backfill Mode"
              hintText="InstacePerGap: will create a single job instance per missing/failed periods (hour, day, week, etc)
                  GroupGaps: will create a single job instance covering contiguos missing/failed periods.
                  None: will not create any additonal job instance."
              required={true}
            ></LensLabel>
          )}
          options={BackfillModeOptions}
          selectedKey={
            job.config!.jobScheduleSettings!.deltaSettings.backFillMode
          }
          onChange={(_, value) => {
            job.config!.jobScheduleSettings!.deltaSettings!.backFillMode =
              value!.text;
            setRender((old) => !old);
          }}
        ></ElxDropdown>
      </Stack.Item>

      <ElxTextField
        onRenderLabel={() => (
          <LensLabel
            labelText="Max Delta"
            hintText={
              'Amount of gaps that will be grouped together. -1 will attempt to group the entire gap.'
            }
            required={false}
          ></LensLabel>
        )}
        value={job.config!.jobScheduleSettings!.deltaSettings!.maxDelta.toString()}
        deferredValidationTime={500}
        onChange={(e, value) => {
          job.config!.jobScheduleSettings!.deltaSettings!.maxDelta =
            Number(value) || 0;
          setRender((old) => !old);
        }}
        disabled={
          job.config!.jobScheduleSettings!.deltaSettings!.backFillMode !==
          orchestratorConstants.BackfillModeOptions.GroupGaps
        }
      ></ElxTextField>

      <Stack.Item>
        <ElxDropdown
          onRenderLabel={() => (
            <LensLabel
              labelText="Respect Boundaries"
              hintText="Job instances that go over the specified boundary will be split into two or more job instances to avoid running jobs that cover more than a single Day or Month."
              required={true}
            ></LensLabel>
          )}
          options={RespectBoundariesOptions}
          selectedKey={
            job.config!.jobScheduleSettings!.deltaSettings.respectBoundaries
          }
          onChange={(_, value) => {
            job.config!.jobScheduleSettings!.deltaSettings!.respectBoundaries =
              value!.text;
            setRender((old) => !old);
          }}
        ></ElxDropdown>
      </Stack.Item>
    </>
  );
};
