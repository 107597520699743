import LeWebApi from 'api/leWebApi';

class RedisCacheApi extends LeWebApi {
  public clearRedisCache = async (): Promise<any> => {
    return await this.postEntity<any, any>('/cache/clear', {});
  };
}

const redisCacheApi = new RedisCacheApi();

export default redisCacheApi;
