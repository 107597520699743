import {
  ElxActionButton,
  ElxDropdown,
  ElxIconButton,
  ElxTextField,
} from '@elixir/components';
import { Stack } from '@fluentui/react';
import { Editor } from 'components/editor';
import { Activity } from 'features/orchestrator/models/job';
import { JobAccordian } from 'features/orchestrator/utils/jobAccordian';
import _ from 'lodash';
import { orchestratorConstants } from 'utils/constants';
import { LensLabel } from 'utils/lensLabel';
import { groupBorder } from './JobAuthorStyles';

const validationTypeOptions = Object.values(
  orchestratorConstants.ValidationTypeOptions
).map((opt) => ({ key: opt.type, text: opt.title }));
const validationTypes = orchestratorConstants.ValidationTypes;

export interface JobActivityDataValidationValidationProps {
  validation: any;
  activity: Activity;
  onChange: (activity: Activity) => void;
}
export const JobActivityDataValidationValidation = (
  props: JobActivityDataValidationValidationProps
) => {
  const { validation, activity, onChange } = props;

  const showDimensionOptions = function () {
    var showDimension = true;
    if (!_.isNil(validation.values) || validation.type === validationTypes.NullCount)
        showDimension = false;
    return showDimension;
}
  return (
    <>
      <Stack tokens={{ childrenGap: 8 }}>
        <Stack.Item>
          <LensLabel
            labelText={'Validation Type'}
            hintText={
              'The type of validation scenario to perform. Click on the icon for more information.'
            }
            required={true}
          ></LensLabel>
          <ElxDropdown
            selectedKey={validation.type}
            onChange={(e, val) => {
              validation.type = val?.key || validationTypeOptions[0].key;
              onChange(activity);
            }}
            options={validationTypeOptions}
          />
        </Stack.Item>
        {(validation.type === validationTypes.KeyViolationCount ||
          validation.type === validationTypes.NullCount ||
          validation.type === validationTypes.RegexMismatchCount ||
          validation.type === validationTypes.UnexpectedValueCount) && (
          <>
            <LensLabel
              labelText={'Columns'}
              hintText={
                'The columns to validate. If multiple columns are entered, the validation scenario is evaluated for each column and each trigger.'
              }
              required={true}
            ></LensLabel>

            <Stack.Item>
              {validation.applyToTheseColumns.map(
                (col: string, idx: number) => {
                  return (
                    <>
                      {/* <>
                  TODO - Nambi - the should provide dropdown instead with
                  choices of column names in table - see 'job-endpoint-column'
                  directive
                </> */}
                      <Stack
                        horizontal
                        grow
                        tokens={{ childrenGap: 8, padding: 2 }}
                        styles={{
                          root: {
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          },
                        }}
                      >
                        <Stack.Item styles={{ root: { width: '100%' } }}>
                          <ElxTextField
                            value={col}
                            onChange={(e, val) => {
                              validation.applyToTheseColumns[idx] = val;
                              onChange(activity);
                            }}
                          ></ElxTextField>
                        </Stack.Item>
                        <Stack.Item>
                          <ElxIconButton
                            iconProps={{
                              iconName: 'cancel',
                            }}
                            text="Remove"
                            onClick={() => {
                              validation.applyToTheseColumns.splice(idx, 1);
                              onChange(activity);
                            }}
                          ></ElxIconButton>
                        </Stack.Item>
                      </Stack>
                    </>
                  );
                }
              )}
            </Stack.Item>
            <Stack.Item>
              <ElxActionButton
                iconProps={{
                  iconName: 'Add',
                }}
                text="Add Column"
                onClick={() => {
                  validation.applyToTheseColumns.push('');
                  onChange(activity);
                }}
              />
              <ElxActionButton />
            </Stack.Item>
          </>
        )}
        {validation.type !== validationTypes.SchemaChange && (
          <>
            <Stack.Item className={groupBorder}>
              <JobAccordian
                isOpen={false}
                text={'Filter'}
                hint={`An optional Kusto Query Language filter that filters data to validate. For example, &quot;| where Cloud == 'Public'&quot; validates rows where the Cloud column has the 'Public' value.`}
              >
                <Stack.Item styles={{root:{padding:6}}}>
                  <Editor
                    height={'120px'}
                    onChange={(value: string): void => {
                      validation.filter = value;
                      onChange(activity);
                    }}
                    value={validation.filter}
                  ></Editor>
                </Stack.Item>
              </JobAccordian>
            </Stack.Item>
          </>
        )}
        {/* {<>TODO - Nambi - in angular we have<div class="form-group"  ng-hide="showDimensionOptions()"> may be a mistake</>} */}
        {!showDimensionOptions() && (
          <>
            <Stack.Item className={groupBorder}>
              <JobAccordian isOpen={false} text={'Dimension Options'} hint={''}>
                <Stack tokens={{ childrenGap: 8, padding: '0px 16px' }}>
                  <LensLabel
                    labelText={'Group By'}
                    hintText={
                      'Names of data columns that will be used to group the validation'
                    }
                    required={true}
                  ></LensLabel>

                  <Stack.Item>
                    <Stack tokens={{ childrenGap: 4 }}>
                      {' '}
                      {validation.dimension.groupBy.map(
                        (col: string, idx: number) => {
                          return (
                            <>
                              {/* <>
                  TODO - Nambi - the should provide dropdown instead with
                  choices of column names in table - see 'job-endpoint-column'
                  directive
                </> */}
                              <Stack horizontal grow>
                                <Stack.Item className="ms-lg9">
                                  <ElxTextField
                                    value={col}
                                    onChange={(e, val) => {
                                      validation.dimension.groupBy[idx] = val;
                                      onChange(activity);
                                    }}
                                  ></ElxTextField>
                                </Stack.Item>
                                <Stack.Item className="ms-lg3">
                                  <ElxIconButton
                                    iconProps={{
                                      iconName: 'cancel',
                                    }}
                                    text="Remove"
                                    onClick={() => {
                                      validation.dimension.groupBy.splice(
                                        idx,
                                        1
                                      );
                                      onChange(activity);
                                    }}
                                  ></ElxIconButton>
                                </Stack.Item>
                              </Stack>
                            </>
                          );
                        }
                      )}
                    </Stack>
                  </Stack.Item>
                  <Stack.Item>
                    <ElxActionButton
                      iconProps={{
                        iconName: 'Add',
                      }}
                      text="Add Column"
                      onClick={() => {
                        validation.dimension.groupBy.push('');
                        onChange(activity);
                      }}
                    />
                    <ElxActionButton />
                  </Stack.Item>

                  <ElxTextField
                    onRenderLabel={() => {
                      return (
                        <>
                          <LensLabel
                            labelText={'Dimension Prefix'}
                            hintText={'Optional prefix for MDM dimensions'}
                          ></LensLabel>
                        </>
                      );
                    }}
                    value={validation.dimension.dimensionPrefix}
                    onChange={(e, value) => {
                      validation.dimension.dimensionPrefix = value || '';
                      onChange(activity);
                    }}
                  ></ElxTextField>
                </Stack>
              </JobAccordian>
            </Stack.Item>
          </>
        )}
      </Stack>
    </>
  );
};
