import { DataSourcePlugin } from './plugins/dataSourcePlugin';

const dataSourcePlugins: { [type: string]: DataSourcePlugin } = {};

export function registerDataSourcePlugin(
  type: string,
  plugin: DataSourcePlugin
) {
  dataSourcePlugins[type] = plugin;
}
/**
 * Get dataSourcePlugins by data source type
 * @param type
 * @returns data source plugin that matches the given data source type
 */
export function getDataSourcePlugin(type: string) {
  let dataSourceType = type;
  return dataSourcePlugins[dataSourceType];
}

export function getDataSourceClient(type: string) {
  return getDataSourcePlugin(type).dataSourceClient;
}

export function getAllDataSourcePlugins() {
  let allPlugins: DataSourcePlugin[] = [];
  for (const pluginKey of Object.keys(dataSourcePlugins)) {
    allPlugins.push(dataSourcePlugins[pluginKey]);
  }
  return allPlugins;
}
