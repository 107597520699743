import Cron from 'components/cron/src';
export interface CronExProps {
  value: string;
  setValue: (value: string) => void;
}

export const CronEx = (props: CronExProps) => {
  const { value, setValue } = props;

  let tok = value.split(' ');

  const first = tok.shift();
  const val = tok.join(' ');

  return (
    <>
      <Cron
        value={val}
        setValue={(value: string): void => {
          setValue(first + ' ' + value);
        }}
        seconds={first}
        changeSeconds={(seconds: string) => {
          setValue(seconds + ' ' + val);
        }}
      ></Cron>
    </>
  );
};
