import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import { Dataset } from './models/dataset';
import { datasetApi } from './api/datasetApi';
import notifier from 'utils/notifier';
import { RootState } from 'app/lensShellUtility';

export enum DatasetListStatus {
  None = 'None',
  Loading = 'Loading',
  Loaded = 'Loaded',
  Error = 'Error',
}

//#region thunks
export const getUserDatasets = createAsyncThunk(
  'dataset/getUserDatasets',
  async () => {
    return await datasetApi.getUserDatasets();
  }
);
export const getDatasets = createAsyncThunk(
  'dataset/getDatasets',
  async ({ workspaceId }: { workspaceId: string }) => {
    return await datasetApi.getDatasets(workspaceId);
  }
);
export const getDataset = createAsyncThunk(
  'dataset/getDataset',
  async ({ datasetId }: { datasetId: string }) => {
    return await datasetApi.getDataset(datasetId);
  }
);
export const createDataset = createAsyncThunk(
  'dataset/createDataset',
  async ({ dataset }: { dataset: Dataset }) => {
    return await datasetApi.createDataset(dataset);
  }
);
export const updateDataset = createAsyncThunk(
  'dataset/updateDataset',
  async ({ dataset }: { dataset: Dataset }) => {
    return await datasetApi.updateDataset(dataset);
  }
);
export const deleteDataset = createAsyncThunk(
  'dataset/deleteDataset',
  async ({ datasetId }: { datasetId: string }) => {
    return await datasetApi.deleteDataset(datasetId);
  }
);
// export const getDatasetVersions = createAsyncThunk(
//   'dataset/getDatasetVersions',
//   async ({datasetId}:{datasetId: string}) => {
//     return await datasetApi.getDatasetVersions(datasetId);
//   });
// export const getDatasetVersion = createAsyncThunk(
//   'dataset/getDatasetVersion',
//   async ({datasetId, version}:{datasetId: string, version: string}) => {
//     return await datasetApi.getDatasetVersion(datasetId, version);
//   });
// export const changeDatasetServiceId = createAsyncThunk(
//   'dataset/changeDatasetServiceId',
//   async ({serviceId}:{serviceId: string}) => {
//     return await datasetApi.changeDatasetServiceId(serviceId);
//   });
export const enableDataset = createAsyncThunk(
  'dataset/enableDataset',
  async ({ datasetId }: { datasetId: string }) => {
    return await datasetApi.enableDataset(datasetId);
  }
);
export const disableDataset = createAsyncThunk(
  'dataset/disableDataset',
  async ({ datasetId }: { datasetId: string }) => {
    return await datasetApi.disableDataset(datasetId);
  }
);
export const restoreDataset = createAsyncThunk(
  'dataset/restoreDataset',
  async ({ datasetId }: { datasetId: string }) => {
    return await datasetApi.restoreDataset(datasetId);
  }
);

//#endregion thunks

const datasetListAdapter = createEntityAdapter<Dataset>({
  selectId: (dataset) => dataset.id,
});
const initialDatasetState = {
  datasetList: datasetListAdapter.getInitialState({
    datasetListStatus: DatasetListStatus.None,
    currentDatasetId: null,
  }),
};

const datasetSlice = createSlice({
  name: 'dataset',
  initialState: initialDatasetState,
  reducers: {
    reset: (state) => initialDatasetState,
  },
  extraReducers: (builder) => {
    builder.addCase(getUserDatasets.pending, (state) => {
      state.datasetList.datasetListStatus = DatasetListStatus.Loading;
    });
    builder.addCase(getUserDatasets.fulfilled, (state, action) => {
      notifier.info('User Dataset list loaded');
      datasetListAdapter.setAll(state.datasetList, action.payload);
      state.datasetList.datasetListStatus = DatasetListStatus.Loaded;
    });
    builder.addCase(getUserDatasets.rejected, (state) => {
      notifier.error('User Dataset list failed to load');
      state.datasetList.datasetListStatus = DatasetListStatus.Error;
    });
    builder.addCase(getDatasets.pending, (state) => {
      state.datasetList.datasetListStatus = DatasetListStatus.Loading;
    });
    builder.addCase(getDatasets.fulfilled, (state, action) => {
      notifier.info('Dataset list loaded');
      datasetListAdapter.setAll(state.datasetList, action.payload);
      state.datasetList.datasetListStatus = DatasetListStatus.Loaded;
    });
    builder.addCase(getDatasets.rejected, (state) => {
      notifier.error('Dataset list failed to load');
      state.datasetList.datasetListStatus = DatasetListStatus.Error;
    });
    builder.addCase(getDataset.pending, (state) => {
      state.datasetList.datasetListStatus = DatasetListStatus.Loading;
    });
    builder.addCase(getDataset.fulfilled, (state, action) => {
      notifier.info('Dataset loaded');
      datasetListAdapter.upsertOne(state.datasetList, action.payload);
      state.datasetList.datasetListStatus = DatasetListStatus.Loaded;
    });
    builder.addCase(getDataset.rejected, (state) => {
      notifier.error('Dataset failed to load');
      state.datasetList.datasetListStatus = DatasetListStatus.Error;
    });
    builder.addCase(createDataset.pending, (state) => {
      state.datasetList.datasetListStatus = DatasetListStatus.Loading;
    });
    builder.addCase(createDataset.fulfilled, (state, action) => {
      notifier.info('Dataset created');
      datasetListAdapter.addOne(state.datasetList, action.payload);
      state.datasetList.datasetListStatus = DatasetListStatus.Loaded;
    });
    builder.addCase(createDataset.rejected, (state) => {
      notifier.error('Dataset failed to create');
      state.datasetList.datasetListStatus = DatasetListStatus.Error;
    });
    builder.addCase(updateDataset.pending, (state) => {
      state.datasetList.datasetListStatus = DatasetListStatus.Loading;
    });
    builder.addCase(updateDataset.fulfilled, (state, action) => {
      notifier.info('Dataset updated');
      datasetListAdapter.upsertOne(state.datasetList, action.payload);
      state.datasetList.datasetListStatus = DatasetListStatus.Loaded;
    });
    builder.addCase(updateDataset.rejected, (state) => {
      notifier.error('Dataset failed to update');
      state.datasetList.datasetListStatus = DatasetListStatus.Error;
    });
    builder.addCase(deleteDataset.pending, (state) => {
      state.datasetList.datasetListStatus = DatasetListStatus.Loading;
    });
    builder.addCase(deleteDataset.fulfilled, (state, action) => {
      notifier.info('Dataset deleted');
      state.datasetList.datasetListStatus = DatasetListStatus.Loaded;
    });
    builder.addCase(deleteDataset.rejected, (state) => {
      notifier.error('Dataset failed to delete');
      state.datasetList.datasetListStatus = DatasetListStatus.Error;
    });
    // builder.addCase(getDatasetVersions.pending, (state) => {
    //   state.datasetList.datasetListStatus = DatasetListStatus.Loading;
    // }
    // );
    // builder.addCase(getDatasetVersions.fulfilled, (state, action) => {
    //   datasetListAdapter.upsertMany(state, action.payload);
    //   state.datasetList.datasetListStatus = DatasetListStatus.Loaded;
    // }
    // );
    // builder.addCase(getDatasetVersions.rejected, (state) => {
    //   state.datasetList.datasetListStatus = DatasetListStatus.Error;
    // }
    // );
    // builder.addCase(getDatasetVersion.pending, (state) => {
    //   state.datasetList.datasetListStatus = DatasetListStatus.Loading;
    // }
    // );
    // builder.addCase(getDatasetVersion.fulfilled, (state, action) => {
    //   datasetListAdapter.upsertOne(state, action.payload);
    //   state.datasetList.datasetListStatus = DatasetListStatus.Loaded;
    // }
    // );
    // builder.addCase(getDatasetVersion.rejected, (state) => {
    //   state.datasetList.datasetListStatus = DatasetListStatus.Error;
    // }
    // );
    // builder.addCase(changeDatasetServiceId.pending, (state) => {
    //   state.datasetList.datasetListStatus = DatasetListStatus.Loading;
    // }
    // );
    // builder.addCase(changeDatasetServiceId.fulfilled, (state) => {
    //   state.datasetList.datasetListStatus = DatasetListStatus.Loaded;
    // }
    // );

    // builder.addCase(changeDatasetServiceId.rejected, (state) => {
    //   state.datasetList.datasetListStatus = DatasetListStatus.Error;
    // }
    // );
    builder.addCase(enableDataset.pending, (state) => {
      state.datasetList.datasetListStatus = DatasetListStatus.Loading;
    });
    builder.addCase(enableDataset.fulfilled, (state, action) => {
      notifier.info('Dataset enabled');
      datasetListAdapter.upsertOne(state.datasetList, action.payload);
      state.datasetList.datasetListStatus = DatasetListStatus.Loaded;
    });
    builder.addCase(enableDataset.rejected, (state) => {
      notifier.error('Dataset failed to enable');
      state.datasetList.datasetListStatus = DatasetListStatus.Error;
    });
    builder.addCase(disableDataset.pending, (state) => {
      state.datasetList.datasetListStatus = DatasetListStatus.Loading;
    });
    builder.addCase(disableDataset.fulfilled, (state, action) => {
      notifier.info('Dataset disabled');
      datasetListAdapter.upsertOne(state.datasetList, action.payload);
      state.datasetList.datasetListStatus = DatasetListStatus.Loaded;
    });
    builder.addCase(disableDataset.rejected, (state) => {
      notifier.error('Dataset failed to disable');
      state.datasetList.datasetListStatus = DatasetListStatus.Error;
    });
    builder.addCase(restoreDataset.pending, (state) => {
      state.datasetList.datasetListStatus = DatasetListStatus.Loading;
    });
    builder.addCase(restoreDataset.fulfilled, (state, action) => {
      datasetListAdapter.upsertOne(state.datasetList, action.payload);
      state.datasetList.datasetListStatus = DatasetListStatus.Loaded;
    });
    builder.addCase(restoreDataset.rejected, (state) => {
      state.datasetList.datasetListStatus = DatasetListStatus.Error;
    });
  },
});

export const selectDatasetStatus = (state: RootState) =>
  state.dataset.datasetList.datasetListStatus;

export const {
  selectAll: selectAllDatasets,
  selectById: selectDatasetById,
  selectIds: selectDatasetIds,
} = datasetListAdapter.getSelectors<RootState>(
  (state: RootState) => state.dataset.datasetList
);

export const { reset } = datasetSlice.actions;
export default datasetSlice.reducer;
