import {
  Breadcrumb,
  DefaultButton,
  FontSizes,
  FontWeights,
  IBreadcrumbItem,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
} from '@fluentui/react';
import { useDispatch, useSelector } from 'react-redux';
import {
  breadCrumbStyles,
  panelStyles,
  stackItemFormStyles,
  panelContentStackStyle,
} from '../workspaceStyles';
import {
  deleteWorkspace,
  editWorkspace,
} from 'features/workspaces/workspaceSlice';
import { useCallback } from 'react';
import { AppDispatch } from 'app/lensShellUtility';

import { loadUserprofile } from 'features/userprofile/userprofileSlice';
import {
  loadWorkspaces,
  selectWorkspaceListEntities,
} from 'features/workspaces/workspaceListSlice';
import { loadUserFavorites } from 'features/userInfo/userSavedObjectDataSlice';

interface DeletePanelProps {
  workspaceId: string;
  show: boolean;
  dismissPanel: () => void;
  openPanel: () => void;
  closeQuickSwitchPanel: () => void;
}

const buttonStyles = { root: { marginRight: 8 } };
const DeletePanel = (props: DeletePanelProps) => {
  const dispatch = useDispatch<AppDispatch>();
  let workspace = useSelector(editWorkspace);
  const workspaceListEntities = useSelector(selectWorkspaceListEntities);

  const items: IBreadcrumbItem[] = [
    {
      text: 'Workspace management',
      key: 'workspaceManagement',
      onClick: () => {
        props.dismissPanel();
      },
    },
    {
      text: workspace?.name + ' - Deletion',
      key: 'workspaceDeletion',
      onClick: () => {},
    },
  ];

  const onRenderFooterContent = useCallback(
    () => (
      <div>
        <PrimaryButton
          onClick={() => {
            dispatch(deleteWorkspace(workspace.id)).then(() => {
              props.dismissPanel();
              dispatch(loadUserprofile());
              dispatch(loadWorkspaces(undefined));
              dispatch(
                loadUserFavorites({ workspaces: workspaceListEntities })
              );
            });
          }}
          styles={buttonStyles}
        >
          Delete
        </PrimaryButton>
        <DefaultButton onClick={() => props.dismissPanel()}>
          Cancel
        </DefaultButton>
      </div>
    ),
    [dispatch, props, workspace.id, workspaceListEntities]
  );
  return (
    <Panel
      type={PanelType.large}
      isOpen={props.show}
      onDismiss={props.closeQuickSwitchPanel}
      styles={panelStyles}
      onRenderHeader={() => {
        return (
          <Breadcrumb
            items={items}
            maxDisplayedItems={2}
            ariaLabel="Workspace migration breadcrumb with items rendered as buttons"
            overflowAriaLabel="More links"
            styles={breadCrumbStyles}
          />
        );
      }}
      isFooterAtBottom={true}
      onRenderFooterContent={onRenderFooterContent}
    >
      <Stack {...panelContentStackStyle}>
        <Stack.Item {...stackItemFormStyles}>
          <div
            style={{
              fontSize: FontSizes.size18,
              fontWeight: FontWeights.semibold,
            }}
          >
            Deleting {workspace.name}:
          </div>
        </Stack.Item>
        <Stack.Item {...stackItemFormStyles}>
          {
            'Are you sure you want to delete this workspace? All saved objects and jobs in this workspace will be deleted.'
          }
        </Stack.Item>
      </Stack>
    </Panel>
  );
};

export default DeletePanel;
