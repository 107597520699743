import { LeApi } from 'api/leApi';
import {
  dataConnectionParamMapping,
  dataSourceParamMapping,
  MigrationRequest,
  Workspace,
  WorkspaceBeforeFormat,
} from '../models/workspace';
import { DataSource, Project, ValidateResult } from '../models/project';
import { WorkspaceDataConnectionValidation } from '../utils/workspaceUtils';
import { PagedResults } from 'api/restApi';

const baseUrl = '/v1/workspaces/';
const initialWorkspaceCount = 500;
const subsequentWorkspaceCount = 5000;

class WorkspacesApi extends LeApi {
  public createWorkspace = async (
    workspace: WorkspaceBeforeFormat
  ): Promise<WorkspaceBeforeFormat> => {
    return await this.postEntity<WorkspaceBeforeFormat, WorkspaceBeforeFormat>(
      baseUrl,
      workspace
    );
  };

  public getWorkspace = async (
    workspaceId: string
  ): Promise<WorkspaceBeforeFormat> => {
    return await this.getEntity<WorkspaceBeforeFormat>(baseUrl + workspaceId);
  };

  public getProject = async (workspaceId: string): Promise<Project> => {
    return await this.getEntity<Project>(
      baseUrl + workspaceId + '/orchestratorProject'
    );
  };

  public getPagedWorkspaceList = async (
    continuationToken?: string
  ): Promise<PagedResults<WorkspaceBeforeFormat>> => {
    // request a small batch on the initial request, and then larger batches later.
    const count =
      continuationToken === undefined
        ? initialWorkspaceCount
        : subsequentWorkspaceCount;
    return await this.getPagedEntities(baseUrl, count, continuationToken);
  };

  public postEnableOrchestration = async (
    workspaceId: string,
    serviceGuid: string
  ): Promise<WorkspaceBeforeFormat> => {
    return await this.postEntity<any, WorkspaceBeforeFormat>(
      'v1/workspaces/' + workspaceId + '/enableOrchestration/' + serviceGuid,
      ''
    );
  };

  public postConnection = async (
    workspaceId: string,
    connectionRequest: DataSource
  ): Promise<any> => {
    return await this.postEntity<DataSource, string>(
      baseUrl + workspaceId + '/dataSources',
      connectionRequest
    );
  };

  public putConnection = async (
    workspaceId: string,
    connectionRequest: DataSource
  ): Promise<any> => {
    return await this.putEntity<DataSource>(
      baseUrl + workspaceId + '/dataSources/' + connectionRequest.id,
      connectionRequest
    );
  };

  public postDataConnectionValidate = async (
    workspaceId: string,
    dataConnection: DataSource
  ): Promise<ValidateResult> => {
    return await this.postEntity<DataSource, ValidateResult>(
      '/v1/workspaces/' + workspaceId + '/dataSources/validate',
      dataConnection
    );
  };

  public deleteWorkspace = async (workspaceId: string): Promise<Workspace> => {
    return await this.deleteEntity<Workspace>(baseUrl + workspaceId);
  };

  public saveWorkspace = async (
    workspaceId: string,
    workspace: WorkspaceBeforeFormat
  ): Promise<WorkspaceBeforeFormat> => {
    return await this.putEntity<WorkspaceBeforeFormat>(
      baseUrl + workspaceId,
      workspace
    );
  };

  public migrateWorkspace = async (
    migrationRequest: MigrationRequest
  ): Promise<any> => {
    return await this.postEntity(
      baseUrl + migrationRequest.workspaceId + '/migrateWorkspaceToAADTenant',
      migrationRequest
    );
  };
  public updateWorkspaceConfiguration = async (
    workspaceId: string,
    projectToUpdate: any
  ): Promise<any> => {
    return await this.putEntity(
      baseUrl + workspaceId + '/updateWorkspaceConfiguration',
      projectToUpdate
    );
  };

  public getWorkspaceHistory = async (workspaceId: string): Promise<any> => {
    return await this.getEntity(baseUrl + workspaceId + '/history');
  };

  public getDataConnections = async (workspaceId: string): Promise<any> => {
    return await this.getEntity(baseUrl + workspaceId + '/dataSources');
  };

  public deleteDataConnection = async (
    workspaceId: string,
    connectionName: string
  ): Promise<any> => {
    return await this.deleteEntity(
      baseUrl + workspaceId + '/dataSources/' + connectionName
    );
  };

  public validateDataConnection = async (
    workspaceId: string,
    connection: DataSource
  ): Promise<WorkspaceDataConnectionValidation> => {
    return await this.postEntity(
      baseUrl + workspaceId + '/dataSources/validate',
      connection
    );
  };

  public getTenants = async (): Promise<any> => {
    return await this.getEntity(baseUrl + 'aadtenants');
  };

  /**
   * Previous named getWorkspaceAssetParameters in angular
   * @param workspaceId
   * @returns workspace orchestration parameters
   */
  public getWorkspaceExportParameters = async (
    workspaceId: string
  ): Promise<any> => {
    return await this.getEntity(baseUrl + workspaceId + '/exportParams');
  };

  public getWorkspaceDataConnectionParams = async (
    workspaceId: string
  ): Promise<dataConnectionParamMapping[]> => {
    return await this.getEntity(
      baseUrl + workspaceId + '/dataConnectionParams'
    );
  };

  public postWorkspaceDataConnectionParams = async (
    workspaceId: string,
    dataConnectionParams: { params: dataConnectionParamMapping[] }
  ): Promise<dataConnectionParamMapping[]> => {
    return await this.postEntity(
      baseUrl + workspaceId + '/dataConnectionParams',
      dataConnectionParams
    );
  };

  public getWorkspaceDataSourceParams = async (
    workspaceId: string
  ): Promise<dataSourceParamMapping[]> => {
    return await this.getEntity(baseUrl + workspaceId + '/dataSourceParams');
  };

  public postWorkspaceDataSourceParams = async (
    workspaceId: string,
    dataSourceParams: { params: dataSourceParamMapping[] }
  ): Promise<dataSourceParamMapping[]> => {
    return await this.postEntity(
      baseUrl + workspaceId + '/dataSourceParams',
      dataSourceParams
    );
  };

  public getDataSourceShared = async (workspaceId: string): Promise<any> => {
    return await this.getEntity<any>(
      `/v1/workspaces/${workspaceId}/dataSourcesShared`
    );
  };
}

export const workspacesApi = new WorkspacesApi();

export default workspacesApi;
