import { Stack, StackItem } from '@fluentui/react';
import { ElxActionButton, ElxIconButton } from '@elixir/components';
import React, { useEffect, useState } from 'react';
import { JobInstance, JobInstanceStatus } from '../models/job';
import { getTimeLabel } from '../utils/jobUtils';
import HighChartsReact from 'highcharts-react-official';
import Highcharts, {
  AxisLabelsFormatterContextObject,
} from 'highcharts/highmaps';

interface JobInstanceStats {
  success: number;
  failed: number;
  terminated: number;
}

interface JobInstanceTimeRange {
  start: string | Date | null;
  end: string | Date | null;
}

interface JobHistoryProps {
  jobInstances: JobInstance[];
  logViewHandler: (idx: number) => void;
}

const jobHistoryMax = 60;
const sliceSize = 5;
const colr = ['#DFF6DD', '#FFF4CE', '#FDE7E9'];
const yCategories = ['Success', 'Terminated', 'Failed'];
const defaultBackgroundColor = 'white';

const JobHistory = (props: JobHistoryProps) => {
  const [displayIndex, setDisplayIndex] = useState(0);
  let jobInstanceStats: JobInstanceStats = {
    success: 0,
    failed: 0,
    terminated: 0,
  };
  let failureIndex: number[] = [];
  let chartData: any[] = [];
  // chart data
  props.jobInstances.forEach((d: JobInstance, i) => {
    chartData.push({
      x: i,
      y: 0,
      value: d.id?.substring(0, 30) + '...',
      status: d.status,
      color:
        d.status === JobInstanceStatus.SUCCESS
          ? colr[0]
          : defaultBackgroundColor,
      startTime: d.startTime,
    });
    chartData.push({
      x: i,
      y: 1,
      value: d.id?.substring(0, 30) + '...',
      status: d.status,
      color:
        d.status === JobInstanceStatus.TERMINATED
          ? colr[1]
          : defaultBackgroundColor,
      startTime: d.startTime,
    });

    chartData.push({
      x: i,
      y: 2,
      value: d.id?.substring(0, 30) + '...',
      status: d.status,
      color:
        d.status === JobInstanceStatus.FAILED
          ? colr[2]
          : defaultBackgroundColor,
      startTime: d.startTime,
    });
    if (d.status === JobInstanceStatus.FAILED) {
      failureIndex.push(i);
    }
  });

  if (Math.min(props.jobInstances.length, jobHistoryMax) < jobHistoryMax) {
    for (let i = props.jobInstances.length; i < jobHistoryMax; i++) {
      chartData.push({
        x: i - jobHistoryMax,
        y: 0,
        value: null,
        color: defaultBackgroundColor,
      });
      chartData.push({
        x: i - jobHistoryMax,
        y: 1,
        value: null,
        color: defaultBackgroundColor,
      });

      chartData.push({
        x: i - jobHistoryMax,
        y: 2,
        value: null,
        color: defaultBackgroundColor,
      });
    }
  }

  let jobInstanceTimeRange: JobInstanceTimeRange = {
    start: 'Start Time',
    end: 'End Time',
  };
  // get stats.
  let idx = displayIndex;
  if (idx < props.jobInstances.length) {
    jobInstanceTimeRange.end = getTimeLabel(props.jobInstances[idx].startTime);
  }
  for (
    ;
    idx < props.jobInstances.length && idx < displayIndex + jobHistoryMax;
    idx++
  ) {
    switch (props.jobInstances[idx].status) {
      case JobInstanceStatus.SUCCESS:
        jobInstanceStats.success += 1;
        break;
      case JobInstanceStatus.FAILED:
        jobInstanceStats.failed += 1;
        break;
      case JobInstanceStatus.TERMINATED:
        jobInstanceStats.terminated += 1;
        break;
      default:
        break;
    }
  }
  if (idx < props.jobInstances.length) {
    jobInstanceTimeRange.start = getTimeLabel(
      props.jobInstances[idx].startTime
    );
  }

  let chartDataSlice = chartData.slice(
    displayIndex * 3,
    (displayIndex + jobHistoryMax) * 3
  );

  const clickHandler = function (event: any) {
    event.stopPropagation();
    if (event.point.options.x > -1) {
      props.logViewHandler(event.point.options.x);
    }
  };
  const options = {
    chart: {
      type: 'heatmap',
      height: 180,
      width: 1230,
      marginTop: 30,
    },
    credits: {
      enabled: false,
    },
    title: { text: '' },
    yAxis: { categories: yCategories, title: null, reversed: true },
    xAxis: {
      visible: true,
      reversed: true,
      minTickInterval: 10,
      labels: {
        style: {
          textAnchor: 'start',
          fontWeight: 500,
        },
        formatter: function (
          this: AxisLabelsFormatterContextObject,
          ctx: AxisLabelsFormatterContextObject
        ) {
          if (
            this.isFirst ||
            this.value < 0 ||
            props.jobInstances.length === 0 ||
            this.value >= props.jobInstances.length
          ) {
            return '';
          }
          return getTimeLabel(props.jobInstances[Number(this.value)].startTime);
        },
      },
    },
    series: [
      {
        borderWidth: 1,
        borderColor: 'lightgrey',
        data: chartDataSlice,
        name: 'Job Instance',
        events: {
          click: clickHandler,
        },
      },
      {
        data: chartDataSlice.filter((d) => d.color === colr[0]),
        name: 'Job Instance',
        marker: {
          enabled: true,
          height: 14,
          width: 14,
          symbol: 'url(./images/circle_check.png)',
        },
        events: {
          click: clickHandler,
        },
      },
      {
        name: 'Job Instance',
        data: chartDataSlice.filter((d) => d.color === colr[1]),
        marker: {
          enabled: true,
          height: 14,
          width: 14,
          symbol: 'url(./images/circle_box.png)',
        },
        events: {
          click: clickHandler,
        },
      },
      {
        name: 'Job Instance',
        data: chartDataSlice.filter((d) => d.color === colr[2]),
        marker: {
          enabled: true,
          height: 14,
          width: 14,

          symbol: 'url(./images/circle_cross.png)',
        },
        events: {
          click: clickHandler,
        },
      },
    ],
    legend: { enabled: false },
    tooltip: {
      shared: true,
      useHTML: true,
      eaderFormat: '<table><tr><th colspan="2">Job Instance</th></tr>',
      pointFormat:
        '<tr><td ">Id :</td>' +
        '<td style="text-align: right"><b>{point.value} </b></td></tr>  </br>' +
        '<tr><td ">Status :</td>' +
        '<td style="text-align: right"><b>{point.status} </b></td></tr>  </br>' +
        '<tr><td ">Start Time :</td> ' +
        '<td style="text-align: right"><b>{point.startTime} </b></td></tr> </br>',
      footerFormat: '</table>',
    },
  };

  useEffect(() => {
    setDisplayIndex(0);
  }, [props.jobInstances]);

  const nextFailureIndex = (() => {
    if (
      failureIndex.length === 0 ||
      props.jobInstances.length < jobHistoryMax
    ) {
      return -1;
    }
    let failGreater = failureIndex.filter(
      (d) => d >= displayIndex + jobHistoryMax
    );
    if (failGreater.length === 0) {
      return -1;
    }
    return failGreater[0];
  })();

  const prevFailureIndex = (() => {
    if (
      failureIndex.length === 0 ||
      props.jobInstances.length < jobHistoryMax
    ) {
      return -1;
    }
    let failLesser = failureIndex.filter((d) => d < displayIndex);
    if (failLesser.length === 0) {
      return -1;
    }
    return failLesser[failLesser.length - 1];
  })();

  return (
    <>
      <Stack
        styles={{
          root: {
            height: '15%',
            background: '#f4f3f2',
            padding: '8px',
          },
        }}
        horizontal
        grow
      >
        <StackItem
          styles={{
            root: { width: '64%', fontWeight: 700, paddingLeft: '8px' },
          }}
        >
          <span>{`Viewing Instances:  ${jobInstanceTimeRange.start} - ${jobInstanceTimeRange.end}`}</span>
        </StackItem>

        <StackItem
          styles={{
            root: {
              width: '36%',
              float: 'right',
              fontWeight: 500,
              textAlign: 'right',
            },
          }}
        >
          {' '}
          <ElxIconButton
            styles={{
              icon: { color: 'green' },
              root: { pointerEvents: 'none' },
            }}
            iconProps={{ iconName: 'SkypeCircleCheck' }}
            text={''}
          ></ElxIconButton>
          <span>{`${jobInstanceStats.success} SUCCESS`}</span>
          <ElxIconButton
            styles={{ icon: { color: 'red' }, root: { pointerEvents: 'none' } }}
            iconProps={{ iconName: 'StatusErrorFull' }}
            text={''}
          ></ElxIconButton>
          <span>{`${jobInstanceStats.failed} FAILED`}</span>
          <ElxIconButton
            styles={{
              icon: { color: 'grey' },
              root: { pointerEvents: 'none' },
            }}
            iconProps={{ iconName: 'CircleStopSolid' }}
            text={''}
          ></ElxIconButton>
          <span>{`${jobInstanceStats.terminated} Terminated`}</span>
        </StackItem>
      </Stack>
      <Stack styles={{ root: { height: '70%' } }}>
        <HighChartsReact
          highcharts={Highcharts}
          options={options}
        ></HighChartsReact>
      </Stack>
      <Stack
        styles={{
          root: {
            width: '100%',
            height: '15%',
            float: 'right',
            padding: '8px',
            borderBottom: '1px solid lightgrey',
          },
        }}
        horizontal
        grow
      >
        <StackItem
          styles={{
            root: { width: '72%' },
          }}
        >
          <span>{``}</span>
        </StackItem>
        <StackItem
          styles={{
            root: { width: '28%' },
          }}
        >
          <ElxActionButton
            onClick={() => {
              setDisplayIndex(nextFailureIndex - (jobHistoryMax - 1));
            }}
            styles={{ root: { fontWeight: 600 } }}
            disabled={nextFailureIndex === -1} // note that job instances are displayed reverse order.
          >
            <span>{`<< Previous Fail | `}</span>
          </ElxActionButton>
          <ElxActionButton
            onClick={() => {
              setDisplayIndex(
                Math.min(
                  displayIndex + sliceSize,
                  props.jobInstances.length - jobHistoryMax
                )
              );
            }}
            styles={{ root: { fontWeight: 600 } }}
            disabled={displayIndex >= props.jobInstances.length - jobHistoryMax}
          >
            <span>{`< Previous `}</span>
          </ElxActionButton>
          <ElxActionButton
            onClick={() => {
              setDisplayIndex(Math.max(displayIndex - sliceSize, 0));
            }}
            styles={{ root: { fontWeight: 600 } }}
            disabled={displayIndex === 0}
          >
            <span>{`   -    Next  >`}</span>
          </ElxActionButton>
          <ElxActionButton
            onClick={() => {
              setDisplayIndex(prevFailureIndex);
            }}
            styles={{ root: { fontWeight: 600 } }}
            disabled={prevFailureIndex === -1} // note that job instances are displayed reverse order.
          >
            <span>{` | Next  Fail >>`}</span>
          </ElxActionButton>
        </StackItem>
      </Stack>
    </>
  );
};
export default JobHistory;
