import { _ } from 'utils/sharedLibs';

export class SortCollection {
    ascendingComparator (property, parseFn, numberType) {
        return function (a, b) {
            if (!_.isUndefined(property)) {
                if (!_.isString(a[property]) || !_.isString(b[property])) {
                    throw new TypeError('stringSort expects 2 strings');
                }
            } else {
                if (!_.isString(a) || !_.isString(b)) {
                    throw new TypeError('stringSort expects 2 strings');
                }
            }

            var nameA = !_.isUndefined(property) ? parseFn(a[property]) : parseFn(a);
            var nameB = !_.isUndefined(property) ? parseFn(b[property]) : parseFn(b);

            // If the value is null, show it first
            if (numberType && isNaN(nameA)) {
                return -1;
            } else if (numberType && isNaN(nameB)) {
                return 1;
            }
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            // names must be equal
            return 0;
        }
    }

    descendingComparator (property, parseFn, numberType) {
        return function (a, b) {
            if (!_.isUndefined(property)) {
                if (!_.isString(a[property]) || !_.isString(b[property])) {
                    throw new TypeError('stringSort expects 2 strings');
                }
            } else {
                if (!_.isString(a) || !_.isString(b)) {
                    throw new TypeError('stringSort expects 2 strings');
                }
            }

            var nameA = !_.isUndefined(property) ? parseFn(a[property]) : parseFn(a);
            var nameB = !_.isUndefined(property) ? parseFn(b[property]) : parseFn(b);

            // If the value is null, show it last
            if (numberType && isNaN(nameA)) {
                return 1;
            } else if (numberType && isNaN(nameB)) {
                return -1;
            }
            if (nameA < nameB) {
                return 1;
            }
            if (nameA > nameB) {
                return -1;
            }
            // names must be equal
            return 0;
        }
    }
    stringSort (order, dataType, property) {
        var fn;
        var numberType = true;
        switch (dataType) {
            case 'String':
                numberType = false;
                fn = function (str) {
                    return str.toUpperCase();
                }
                break;
            case 'DateTime':
                numberType = false;
                fn = function (d) {
                    return new Date(d);
                }
                break;
            case 'Single':
                fn = function (f) {
                    return parseFloat(f);
                }
                break;
            case 'Double':
                fn = function (f) {
                    return parseFloat(f);
                }
                break;
            default:
                fn = function (i) {
                    return parseInt(i);
                }
                break;

        }
        switch (order) {
            default: 
            case 'termAsc':
                return this.ascendingComparator(property, fn, numberType);
            case 'termDesc':
                return this.descendingComparator(property, fn, numberType);
        }
    }

    conditonSort (conditions) {
        return function sortByObjectArray(a, b) {
            var indexOfA = _.findIndex(conditions, { 'name': a.name });
            var indexOfB = _.findIndex(conditions, { 'name': b.name });
            if (indexOfA === -1) {
                return 1;
            }
            if (indexOfB === -1) {
                return -1;
            }
            if (indexOfA < indexOfB) {
                return -1;
            }
            if (indexOfA > indexOfB) {
                return 1;
            }
            return 0;
        }
    };
};

export default SortCollection;