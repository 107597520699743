import { DataSourceClient } from 'features/dataSources/client/dataSourceClient';
import { $laConstants, orchestratorConstants } from 'utils/constants';

class SynapseScopeClient extends DataSourceClient {
  public readonly name = $laConstants.DataSourceTypes.SYNAPSESCOPE;

  public readonly title = 'Synapse Scope.';
  public readonly icon = 'fa fa-cat';
  public readonly description = 'Synapse Scope data source.';

  public readonly monaco = {
    language: orchestratorConstants.LanguageId.Sql,
  };
  public readonly orchestration = {
    dataCommand: {
      endpointType: orchestratorConstants.EndpointTypes.SynapseScope,
      scriptLabel: 'Synapse Scope',
    },
  };
  public get displayName() {
    return $laConstants.DataSourceClientDisplayNames.SYNAPSESCOPE;
  }

  public get dataSourceType() {
    return $laConstants.DataSourceTypes.SYNAPSESCOPE;
  }
}

export const synapseScopeClient = new SynapseScopeClient();
export default synapseScopeClient;
