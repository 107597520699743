import { ICommandBarStyles, useTheme } from '@fluentui/react';

export const entityDetailsStackStyles = {
  tokens: { childrenGap: 8 },
  styles: { root: { margin: '1rem' } },
};

export const entityDetailsStackItemTableStyles = {
  styles: { root: { width: '85%' } },
};

export const entityDetailsLabelStyles = {
  styles: { root: { fontSize: '14px' } },
};

export const useTableCommandBarStyles = (): Partial<ICommandBarStyles> => ({
  root: {
    background: useTheme().palette.white,
    paddingLeft: '.5rem',
    paddingBottom: '.5rem',
  },
});

export const usePivotBarStyles = (): Partial<ICommandBarStyles> => ({
  root: {
    background: '#F3F2F1',
    paddingLeft: '.5rem',
    height: '100%',
  },
});

export const propertiesStackStyles = {
  tokens: { childrenGap: '0rem' },
  styles: { root: { margin: '1rem 1rem 1rem 1rem' } },
};

export const propertiesSectionStackItemStyles = {
  root: { paddingBottom: '8px' },
};

export const propertiesInnerSectionStackStyles = {
  tokens: { childrenGap: '0.5rem' },
  styles: { root: { margin: '1rem 1rem 1rem 1rem' } },
};
