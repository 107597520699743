import { ElxTextField } from '@elixir/components';
import { Stack } from '@fluentui/react';
import { LensLabel } from 'utils/lensLabel';
import { SelectorProps } from '../editDataConnectionHelper';

export const SharedSelector = (props: SelectorProps): JSX.Element => {
  const { state } = props;

  return (
    <Stack tokens={{ childrenGap: 16 }}>
      <Stack.Item>
        <ElxTextField
          onRenderLabel={() => (
            <LensLabel
              labelText="Source Workspace"
              hintText={'Source Workspace where the connection is shared'}
              required={true}
            ></LensLabel>
          )}
          value={state.sourceProjectName || ''}
          disabled={true}
        />
      </Stack.Item>
      <Stack.Item>
        <ElxTextField
          onRenderLabel={() => (
            <LensLabel
              labelText="Data Connection"
              hintText={'Select Data Connection from the shared workspace'}
              required={true}
            ></LensLabel>
          )}
          value={state.sourceConnectionName || ''}
          disabled={true}
        />
      </Stack.Item>
    </Stack>
  );
};
