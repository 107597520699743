import { ElxTextField } from '@elixir/components';
import {
  ActionButton,
  DefaultButton,
  Label,
  PrimaryButton,
  Stack,
} from '@fluentui/react';
import { AppDispatch } from 'app/lensShellUtility';
import { entityDetailsStackStyles } from 'features/catalog/utils/catalogEntityDetailsStyles';
import { DetailsPageProps } from 'features/catalog/utils/catalogEntityDetailsUtils';
import { CopyableLabelAndText } from 'components/copyButtons/copyButtons';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  loadCatalogEntity,
  loadCatalogEntityHistory,
  updateCatalogEntity,
} from '../../catalogSlice';
import { validate as uuidValidate } from 'uuid';
import { CatalogEntityType } from 'features/catalog/models/catalogEntity';

export const EntityDetailsGeneral = (props: DetailsPageProps): JSX.Element => {
  const { entity } = props;
  const defaultDescription = entity.description || '';

  const dispatch = useDispatch<AppDispatch>();

  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const [editedDescription, setEditedDescription] =
    useState(defaultDescription);

  return (
    <Stack {...entityDetailsStackStyles}>
      <Stack.Item>
        <Stack verticalAlign="center">
          <Stack.Item>
            <Label>Description:</Label>
          </Stack.Item>
          {!isEditingDescription && entity && (
            <>
              <Stack.Item styles={{ root: { width: '65%' } }}>
                {editedDescription || 'No description added.'}
              </Stack.Item>
              <Stack.Item>
                <ActionButton
                  styles={{
                    root: {
                      color: 'rgb(0, 90, 158)',
                      margin: 0,
                      padding: 0,
                      height: 'auto',
                    },
                    label: {
                      margin: '5px 0px',
                    },
                  }}
                  text="Edit"
                  onClick={() => {
                    setIsEditingDescription(true);
                    setEditedDescription(defaultDescription);
                  }}
                />
              </Stack.Item>
            </>
          )}
          {isEditingDescription && (
            <Stack.Item>
              <ElxTextField
                multiline
                styles={{ root: { width: '65%' } }}
                autoAdjustHeight
                value={editedDescription}
                onChange={(e, newValue) => {
                  const newDescription = newValue || '';
                  setEditedDescription(newDescription);
                }}
              />
              <Stack
                horizontal
                styles={{ root: { marginTop: 8 } }}
                tokens={{ childrenGap: 8 }}
              >
                <Stack.Item>
                  <PrimaryButton
                    onClick={() => {
                      setIsEditingDescription(false);
                      setEditedDescription(editedDescription);
                      // send off API call
                      if (entity.originalAPIResponse) {
                        dispatch(
                          updateCatalogEntity({
                            ...entity.originalAPIResponse,
                            Description: editedDescription,
                          })
                        ).then(() => {
                          dispatch(loadCatalogEntity(entity.manifestKey));
                          dispatch(
                            loadCatalogEntityHistory(entity.manifestKey)
                          );
                        });
                      }
                    }}
                    disabled={defaultDescription === editedDescription}
                  >
                    Save Changes
                  </PrimaryButton>
                </Stack.Item>
                <Stack.Item>
                  <DefaultButton
                    onClick={() => {
                      setIsEditingDescription(false);
                      setEditedDescription(defaultDescription);
                    }}
                  >
                    Cancel
                  </DefaultButton>
                </Stack.Item>
              </Stack>
            </Stack.Item>
          )}
        </Stack>
      </Stack.Item>

      <Stack.Item>
        <CopyableLabelAndText
          label="Data Store:"
          text={entity.type}
          horizontal={false}
        />
      </Stack.Item>
      <Stack.Item>
        <CopyableLabelAndText
          label="Format:"
          text={entity.additionalProperties?.format}
          horizontal={false}
        />
      </Stack.Item>
      {entity.type === CatalogEntityType.Cosmos && (
        <Stack.Item>
          <CopyableLabelAndText
            label="Cosmos Location Link:"
            text={
              entity.additionalProperties?.container &&
              entity.additionalProperties?.location
                ? entity.additionalProperties?.container +
                  entity.additionalProperties?.location
                : 'Not found'
            }
            horizontal={false}
            link={
              entity.additionalProperties?.container &&
              entity.additionalProperties?.location
                ? entity.additionalProperties?.container +
                  entity.additionalProperties?.location
                : undefined
            }
          />
        </Stack.Item>
      )}
      <Stack.Item>
        <CopyableLabelAndText
          label="Owning Service Name:"
          text={entity.service}
          horizontal={false}
        />
      </Stack.Item>
      <Stack.Item>
        <CopyableLabelAndText
          label="Owning Service ID:"
          text={entity.serviceOid}
          horizontal={false}
          link={
            uuidValidate(entity.serviceOid)
              ? 'https://microsoftservicetree.com/services/' + entity.serviceOid
              : undefined
          }
        />
      </Stack.Item>

      {/* Permission Info */}
      <Stack.Item>
        <CopyableLabelAndText
          label="Owner:"
          text={entity.additionalProperties?.owner}
          horizontal={false}
        />
      </Stack.Item>
      <Stack.Item>
        <CopyableLabelAndText
          label="Authorized Writers:"
          text={entity.authorizedWriters}
          horizontal={false}
        />
      </Stack.Item>
      <Stack.Item>
        <CopyableLabelAndText
          label="Authorized Readers:"
          text={entity.authorizedReaders}
          horizontal={false}
        />
      </Stack.Item>
      <Stack.Item>
        <CopyableLabelAndText
          label="Private:"
          text={entity.isPrivate ? entity.isPrivate.toString() : 'false'}
          horizontal={false}
        />
      </Stack.Item>
    </Stack>
  );
};
