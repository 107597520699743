import { DataSourceClient } from 'features/dataSources/client/dataSourceClient';
import constants, {
  $laConstants,
  orchestratorConstants,
} from 'utils/constants';

class LogAnalyticsClient extends DataSourceClient {
  public readonly name = $laConstants.DataSourceTypes.LOGANALYTICS;
  public readonly title = 'Log Analytics';
  public readonly icon = 'fa fa-fish';
  public readonly description = 'OMS Log Analytics data source.';

  public readonly monaco = {
    language: orchestratorConstants.LanguageId.Kusto,
  };
  public readonly orchestration = {
    dataMovement: {
      endpointTypes: [
        // in order for UI
        orchestratorConstants.EndpointTypes.LogAnalyticsTable,
        orchestratorConstants.EndpointTypes.LogAnalyticsQuery,
      ],
      inputPartitioning: true,
      targetEndpointTypes: [orchestratorConstants.EndpointTypes.KustoTable],
    },
    endpoint: {
      directive: 'kusto-table-job-endpoint',
      tablePattern: /^[a-z\d_$.\- ]{1,1024}$/i,
      tableValidationMessage:
        'The name may consist of up to 1024 letters, digits, underscores, dollar signs, spaces, dots, and dashes.',
    },
  };
  public get displayName() {
    return constants.DataSourceClientDisplayNames.LOGANALYTICS;
  }

  public get dataSourceType() {
    return constants.DataSourceTypes.LOGANALYTICS;
  }
}

export const logAnalyticsClient = new LogAnalyticsClient();
export default logAnalyticsClient;
