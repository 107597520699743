import {
  ElxMasterDetailContainer,
  ElxTabControlOrientation,
  IElxMasterDetailTabProps,
} from '@elixir/components';
import {
  Breadcrumb,
  IBreadcrumbItem,
  Panel,
  PanelType,
  Stack,
} from '@fluentui/react';
import { AppDispatch } from 'app/lensShellUtility';
import { Project } from 'features/workspaces/models/project';
import {
  editWorkspace,
  selectOrchestratorEditProject,
  updateWorkspaceConfiguration,
} from 'features/workspaces/workspaceSlice';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import AllocationSettings from './orchestration/allocationSettings';
import JobSettings from './orchestration/jobSettings';
import OrchestrationWorkspaceSettings from './orchestration/orchestrationWorkspaceSettings';
import ParametersList from './parameters/parametersList';
import {
  AllocationSettingsObj,
  JobSettingsObj,
  OrchestrationWorkspaceSettingsObj,
  ScmWorkspaceSettingsObj,
  WorkspaceParameter,
} from './workspaceSettingsUtil';
import {
  breadCrumbStyles,
  panelStyles,
  themedSmallStackTokens,
  useWorkspaceStyles,
  workspacePadding,
} from '../workspaceStyles';
import ScmWorkspaceSettings from './orchestration/scmWorkspaceSettings';

type FormValues = {
  parameters: WorkspaceParameter[];
  orchestrationWorkspaceSettings: OrchestrationWorkspaceSettingsObj;
  jobSettings: JobSettingsObj;
  allocationSettings: AllocationSettingsObj;
  scmWorkspaceSettings: ScmWorkspaceSettingsObj;
};

const getDefaultValues = (project: Project): FormValues => {
  return {
    parameters: project.parameters || [],
    orchestrationWorkspaceSettings: {
      projectName: project.projectName,
      projectDisplayName: project.projectDisplayName,
      serviceGuid: project.serviceGuid || '',
      icMTeamPublicId: project.icMTeamPublicId || '',
    },
    jobSettings: {
      maxJobInstances: project.maxJobInstances,
      maxQueuedJobInstances: project.maxQueuedJobInstances,
      autoMitigation: project.autoMitigation,
      jobPriority: project.jobPriority,
      jobExpiration: project.jobExpiration,
    },
    allocationSettings: {
      allocationType: project.allocationType,
      tokensPerJob: project.tokensPerJob,
      percentAllocation: project.percentAllocation,
    },
    scmWorkspaceSettings: {
      secureChangeManagement: 'Disabled',
    },
  };
};

interface OrchestrationSettingsPanelProps {
  workspaceId: string;
  show: boolean;
  dismissPanel: () => void;
  openPanel: () => void;
  openQuickSwitchPanel: () => void;
  closeQuickSwitchPanel: () => void;
}

const OrchestrationSettingsPanel = (props: OrchestrationSettingsPanelProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const styles = useWorkspaceStyles();
  const currentProject = useSelector(selectOrchestratorEditProject);
  const [project, setProject] = useState(currentProject);
  const workspace = useSelector(editWorkspace);
  const [jobSettingsError, setJobSettingsError] = useState(false);
  const [allocationSettingsError, setAllocationSettingsError] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    setProject(currentProject);
  }, [currentProject]);

  const { control, formState, reset } = useForm<FormValues>({
    defaultValues: getDefaultValues(project),
    mode: 'onChange', //causes rerender onChange, possible perf issue
  });
  const { isDirty, isValid, errors } = formState;

  const tabs: IElxMasterDetailTabProps[] = [
    {
      key: '1',
      name: 'General',
      hasError: !!errors.orchestrationWorkspaceSettings && !isValid,
      component: (
        <Controller
          control={control}
          name="orchestrationWorkspaceSettings"
          render={({ field: { onChange } }) => (
            <OrchestrationWorkspaceSettings
              data={{
                projectName: project.projectName,
                projectDisplayName: project.projectDisplayName,
                serviceGuid: project.serviceGuid || '',
                icMTeamPublicId: project.icMTeamPublicId || '',
              }}
              project={project}
              updateOrchestratorProject={setProject}
              onChange={onChange}
              showError={errors.orchestrationWorkspaceSettings}
              closeQuickSwitchPanel={props.closeQuickSwitchPanel}
              closeWizard={props.dismissPanel}
            />
          )}
        />
      ),
      primaryButton: {
        text: 'Next',
        disabled: !!errors.orchestrationWorkspaceSettings,
        onClick: () => {},
        onClickCapture: (
          event: React.MouseEvent<HTMLButtonElement, MouseEvent>
        ) => {},
      },
      cancelButton: { text: 'Close' },
    },
    {
      key: '2',
      name: 'Job Settings',
      hasError: jobSettingsError,
      disabled: project.serviceGuid === '' || project.serviceGuid === undefined,
      component: (
        <Controller
          control={control}
          name="jobSettings"
          rules={{
            validate: {
              validateFields: (v) => {
                const isValidMj =
                  v?.maxJobInstances !== undefined && v?.maxJobInstances < 1;
                const isValidMqj1 =
                  v?.maxQueuedJobInstances !== undefined &&
                  v?.maxQueuedJobInstances < 0;
                const isValidMqj2 =
                  v?.maxQueuedJobInstances !== undefined &&
                  v?.maxQueuedJobInstances > 1000;
                const isValidJp =
                  v?.jobPriority !== undefined && v?.jobPriority < 0;
                setJobSettingsError(
                  isValidMj || isValidMqj1 || isValidMqj2 || isValidJp
                );
                return (
                  isValidMj ||
                  isValidMqj1 ||
                  isValidMqj2 ||
                  isValidJp ||
                  'Error'
                );
              },
            },
          }}
          render={({ field: { onChange } }) => (
            <JobSettings
              data={{
                maxJobInstances: project.maxJobInstances,
                maxQueuedJobInstances: project.maxQueuedJobInstances,
                autoMitigation: project.autoMitigation,
                jobPriority: project.jobPriority,
                jobExpiration: project.jobExpiration,
              }}
              project={project}
              updateOrchestratorProject={setProject}
              onChange={onChange}
              showError={errors.jobSettings}
            />
          )}
        />
      ),
      cancelButton: { text: 'Close' },
    },
    {
      key: '3',
      name: 'SCM Configuration',
      hasError: !!errors.orchestrationWorkspaceSettings && !isValid,
      component: (
        <Controller
          control={control}
          name="scmWorkspaceSettings"
          render={({ field: { onChange } }) => (
            <ScmWorkspaceSettings
              data={{
                secureChangeManagement:
                  project.secureChangeManagement || 'Disabled',
              }}
              project={project}
              updateOrchestratorProject={setProject}
              onChange={onChange}
              showError={errors.scmWorkspaceSettings}
              closeQuickSwitchPanel={props.closeQuickSwitchPanel}
              closeWizard={props.dismissPanel}
            />
          )}
        />
      ),
      primaryButton: {
        text: 'Next',
        disabled: !!errors.scmWorkspaceSettings,
        onClick: () => {},
        onClickCapture: (
          event: React.MouseEvent<HTMLButtonElement, MouseEvent>
        ) => {},
      },
      cancelButton: { text: 'Close' },
    },
    {
      key: '4',
      name: 'Cosmos, ADLS, Synapse Allocation Settings',
      hasError: allocationSettingsError,
      disabled: project.serviceGuid === '' || project.serviceGuid === undefined,
      isCompleted: false,
      component: (
        <Controller
          control={control}
          name="allocationSettings"
          rules={{
            validate: {
              validateFields: (v) => {
                const isValid =
                  (v?.tokensPerJob !== undefined && v?.tokensPerJob < -1) ||
                  (v?.percentAllocation !== undefined &&
                    v?.percentAllocation < -1) ||
                  (v?.percentAllocation !== undefined &&
                    v?.percentAllocation > 100);
                setAllocationSettingsError(isValid);
                return isValid;
              },
            },
          }}
          render={({ field: { onChange } }) => (
            <AllocationSettings
              data={{
                allocationType: project.allocationType,
                tokensPerJob: project.tokensPerJob,
                percentAllocation: project.percentAllocation,
              }}
              project={project}
              updateOrchestratorProject={setProject}
              onChange={onChange}
              showError={errors.allocationSettings}
            />
          )}
        />
      ),
      cancelButton: { text: 'Close' },
    },
    {
      key: '5',
      name: 'Global User Job Parameters',
      hasError: !!errors.parameters,
      disabled: project.serviceGuid === '' || project.serviceGuid === undefined,
      component: (
        <Controller
          control={control}
          name="parameters"
          rules={{
            validate: {
              validParameters: (v) => {
                const hasDuplicates = (arr: string[]) => {
                  return new Set(arr).size !== arr.length;
                };
                const hasNullValue = (arr: WorkspaceParameter[]) => {
                  arr.forEach((p) => {
                    if (p.value === '') {
                      return true;
                    }
                  });
                  return false;
                };
                const hasNullType = (arr: WorkspaceParameter[]) => {
                  arr.forEach((p) => {
                    if (p.type === '') {
                      return true;
                    }
                  });
                  return false;
                };
                let errorMsg = [];
                const hasNameError = hasDuplicates(v?.map((p) => p.name));
                const hasValueError = hasNullValue(v || []);
                const hasTypeError = hasNullType(v || []);
                if (hasNameError) {
                  errorMsg.push('Parameter names have duplicates.');
                }
                if (hasValueError) {
                  errorMsg.push('Parameter value must not be null.');
                }
                if (hasTypeError) {
                  errorMsg.push('Parameter type must not be null.');
                }
                const msg = errorMsg.join(' ');
                const hasError = hasNameError || hasValueError || hasTypeError;
                return !hasError || msg;
              },
            },
          }}
          render={({ field: { onChange } }) => (
            <Stack className={styles.rootStackStyle}>
              {/* Orchestrator parameters */}
              <Stack.Item>
                <Stack
                  styles={workspacePadding}
                  tokens={themedSmallStackTokens}
                >
                  <ParametersList
                    parameters={project.parameters || []}
                    project={project}
                    updateOrchestratorProject={setProject}
                    onChange={onChange}
                    showError={errors.parameters}
                  />
                </Stack>
              </Stack.Item>
            </Stack>
          )}
        />
      ),
      primaryButton: {
        text: isSaving ? 'Saving...' : 'Save',
        disabled: !!errors.parameters && (!isDirty || !isValid),
        onClick: () => {
          let proj = { ...project };
          delete proj['dataQualityWorkspaceId'];
          reset(getDefaultValues(project));
          setIsSaving(true);
          dispatch(
            updateWorkspaceConfiguration({
              workspaceId: props.workspaceId,
              project: proj,
            })
          ).then(() => setIsSaving(false));
        },
      },
    },
  ];

  const items: IBreadcrumbItem[] = [
    {
      text: 'Workspace management',
      key: 'workspaceManagement',
      onClick: () => {
        props.dismissPanel();
      },
    },
    {
      text: workspace?.name + ' - Orchestration Settings',
      key: 'orchestrationSettings',
      onClick: () => {},
    },
  ];

  return (
    <Panel
      type={PanelType.large}
      isOpen={props.show}
      onDismiss={props.closeQuickSwitchPanel}
      styles={panelStyles}
      isFooterAtBottom={true}
      onRenderHeader={() => {
        return (
          <Breadcrumb
            items={items}
            maxDisplayedItems={2}
            ariaLabel="Workspace data connection breadcrumb with items rendered as buttons"
            overflowAriaLabel="More links"
            styles={breadCrumbStyles}
          />
        );
      }}
    >
      <ElxMasterDetailContainer
        subHeaderText="keep this field for formatting purposes"
        tabs={tabs}
        tabsOrientation={ElxTabControlOrientation.Horizontal}
        showNumeric={false}
        wizard={true}
        maxDisplayActions={4}
        onDismiss={props.closeQuickSwitchPanel}
        styles={{
          verticalTabControl: {
            minWidth: '25vw !important',
            maxWidth: '30vw !important',
            overflowWrap: 'break-word',
          },
        }}
      />
    </Panel>
  );
};

export default OrchestrationSettingsPanel;
