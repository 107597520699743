import {
  DeleteIconProps,
  DisplayTypes,
  ElxActionButton,
  ElxPivot,
  ElxTableContainer,
  FilterDisplayMode,
  FilterOption,
  FilterOptionMode,
  FilterOptionPillMode,
  IElxColumn,
  IElxContainerProps,
  ITableAction,
} from '@elixir/components';
import {
  Breadcrumb,
  CheckboxVisibility,
  IBreadcrumbItem,
  MessageBar,
  MessageBarButton,
  MessageBarType,
  Panel,
  PanelType,
  SelectionMode,
  Stack,
  StackItem,
} from '@fluentui/react';
import {
  getJobs,
  JobListStatus,
  selectAllJobs,
  selectJobListStatus,
} from 'features/orchestrator/jobSlice';
import { Job } from 'features/orchestrator/models/job';
import {
  WorkspaceSaveStatus,
  WorkspaceStatus,
} from 'features/workspaces/models/workspace';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { classNames } from '../../workspacesStyles';
import {
  breadCrumbStyles,
  panelStyles,
  useDataConnectionStyles,
} from '../workspaceStyles';
import {
  DeleteDialogContent,
  renderAuth,
  renderDataSourceHelper,
  renderStatusHelper,
} from './dataConnectionsHelperLogic';
import { _ } from 'utils/sharedLibs';
import {
  clearDataConnectionValidations,
  deleteDataConnection,
  editWorkspace,
  editWorkspaceStatus,
  getDataConnections,
  selectAllDataConnections,
  selectAllValidations,
  selectDataConnectionById,
  selectEditDataConnectionsStatus,
  selectWorkspaceApplicationsStatus,
  selectWorkspaceDataConnectionSaveStatus,
  validateDataConnection,
} from 'features/workspaces/workspaceSlice';
import { AppDispatch, RootState } from 'app/lensShellUtility';
import EditDataConnectionPanel from '../../editDataConnection/editDataConnectionPanel';
import {
  AadConnectionAuthType,
  ConnectionDataSourceType,
  DataSource,
  DataSourceWithValidation,
} from 'features/workspaces/models/project';
import { tableStyles } from 'utils/sharedStyles';
import { Editor } from 'components/monacoEditor';
import workspacesApi from 'features/workspaces/api/workspacesApi';

interface DataConnectionsTablePanelProps {
  workspaceId: string;
  show: boolean;
  dismissPanel: () => void;
  openPanel: () => void;
  closeQuickSwitchPanel: () => void;
}

const DataConnectionsTablePanel = (props: DataConnectionsTablePanelProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const [dispatchedDataConnection, setDispatchedDataConnection] =
    useState(false);
  const [allLoaded, setAllLoaded] = useState(false);
  const dataConnectionsValidations = useSelector(selectAllValidations);
  const dataConnections: DataSource[] = useSelector(selectAllDataConnections);
  const dataConnectionsStatus = useSelector(selectEditDataConnectionsStatus);
  const dataConnectionSaveStatus = useSelector(
    selectWorkspaceDataConnectionSaveStatus
  );
  const editWorkspaceAppStatus = useSelector(selectWorkspaceApplicationsStatus);
  const editWorkspaceLoadingStatus = useSelector(editWorkspaceStatus);
  let workspace = useSelector(editWorkspace);

  const [validatedDataConnections, setValidatedDataConnections] = useState<
    DataSourceWithValidation[]
  >([]);

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [addConnection, setAddConnection] = useState(false);
  const [selected, setSelected] = useState<DataSourceWithValidation>({
    applicationId: '',
    authenticationMode: AadConnectionAuthType.None,
    cluster: '',
    database: '',
    id: '',
    name: '',
    isImported: false,
    tenant: '',
    type: ConnectionDataSourceType.None,
    connectionStatus: '',
  });
  const dcStyles = useDataConnectionStyles();
  const jobList = useSelector(selectAllJobs);
  const jobListStatus = useSelector(selectJobListStatus);
  const [canDelete, setCanDelete] = useState(false);
  const [dependencyCalculated, setDependencyCalculated] = useState(false);
  const [jobDependencies, setJobDependencies] = useState<Job[]>([]);
  const [showEditPanel, setShowEditPanel] = useState(false);
  const [savedDataConnectionId, setSavedDataConnectionId] =
    useState<string>('');
  const [showShareOnly, setShowShareOnly] = useState<boolean>(false);

  const [prevWorkspace, setPrevWorkspace] = useState(props.workspaceId);

  const tabStates = ['Data Connection List', 'Code View'];
  const [tabSelectedKey, setTabSelectedKey] = useState<string>(tabStates[0]);
  const [sharedConnList, setSharedConnList] = useState<any[]>([]);

  const tabItems = tabStates.map((t) => ({ itemKey: t, headerText: t }));
  // height of the monaco editor - approx height of window - panel header height - panel footer height - buffer;
  const [height, setHeight] = useState(window.innerHeight); // window.innerHeight - 100 - 80 - 60;
  const editorHeight = height - 160 < 0 ? 1200 : height - 160;
  const heightHandler = useCallback(() => {
    setHeight(window.innerHeight);
  }, [setHeight]);
  useEffect(() => {
    window.addEventListener('resize', heightHandler);
    return () => {
      window.removeEventListener('resize', heightHandler);
    };
  });

  useEffect(() => {
    setValidatedDataConnections(
      dataConnections.map((dc) => {
        return { ...dc, connectionStatus: 'Loading' };
      })
    );
  }, [dataConnections]);

  const refreshAndRevalidate = useCallback(() => {
    dispatch(clearDataConnectionValidations());
    setDispatchedDataConnection(false);
    setAllLoaded(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      props.show &&
      (prevWorkspace !== props.workspaceId || !dispatchedDataConnection)
    ) {
      dispatch(getDataConnections(props.workspaceId));
      setDispatchedDataConnection(true);
      setAllLoaded(false);
      setPrevWorkspace(props.workspaceId);
    }
  }, [
    dispatch,
    dispatchedDataConnection,
    editWorkspaceLoadingStatus,
    prevWorkspace,
    props.show,
    props.workspaceId,
  ]);

  useEffect(() => {
    const getDataSourceShared = async (workspaceId: string) => {
      const sharedConnections = await workspacesApi.getDataSourceShared(
        workspaceId
      );
      setSharedConnList(sharedConnections);
    };
    getDataSourceShared(workspace.id);
  }, [workspace.id]);

  //data connection validation
  //dispatch validation for all the data connections
  if (
    dispatchedDataConnection &&
    dataConnectionsStatus === WorkspaceStatus.Loaded &&
    editWorkspaceAppStatus === WorkspaceStatus.Loaded &&
    !allLoaded
  ) {
    setValidatedDataConnections(
      dataConnections.map((dc) => {
        return { ...dc, connectionStatus: 'Loading' };
      })
    );
    dataConnections.forEach((connection) => {
      dispatch(
        validateDataConnection({
          workspaceId: props.workspaceId,
          connection: connection,
        })
      );
    });

    setAllLoaded(true);
  }

  useEffect(() => {
    if (
      dataConnectionsValidations.length > 0 &&
      dataConnectionsValidations.length <= dataConnections.length
    ) {
      const latestValidation =
        dataConnectionsValidations[dataConnectionsValidations.length - 1];

      const dcIndex = validatedDataConnections.findIndex(
        (dc) => dc.id === latestValidation.connectionName
      );

      validatedDataConnections[dcIndex] = {
        ...dataConnections[dcIndex],
        connectionStatus: latestValidation.connectionStatus,
      };

      setValidatedDataConnections(validatedDataConnections);
    }
  }, [dataConnections, dataConnectionsValidations, validatedDataConnections]);

  const connection: DataSource | undefined = useSelector((state: RootState) =>
    selectDataConnectionById(state, selected.id)
  );

  useEffect(() => {
    if (dataConnectionSaveStatus === WorkspaceSaveStatus.Saved) {
      if (connection) {
        dispatch(
          validateDataConnection({
            workspaceId: props.workspaceId,
            connection: connection,
          })
        );
      }
    }
  }, [
    dataConnectionSaveStatus,
    connection,
    savedDataConnectionId,
    dispatch,
    props.workspaceId,
  ]);

  const updateSavedConnectionId = (connectionId: string) => {
    setSavedDataConnectionId(connectionId);
  };

  const renderStatus = (item: any, index: any, column: any) => {
    return <>{renderStatusHelper(item.connectionStatus, dcStyles)}</>;
  };
  const renderDataSource = (
    item: DataSourceWithValidation,
    index: any,
    column: any
  ) => {
    return renderDataSourceHelper(item);
  };

  // NOTE: reminder to check for dependency before deleting
  // dependency based triggers. if data is present on database, trigger a job
  // trigger job based on completion of another job
  const [dispatchedDatasetsAndJobs, setDispatchedDatasetsAndJobs] =
    useState(false);
  useEffect(() => {
    if (showDeleteDialog && !dispatchedDatasetsAndJobs) {
      if (props.workspaceId !== '') {
        dispatch(getJobs({ workspaceId: props.workspaceId }));
        setDispatchedDatasetsAndJobs(true);
      }
    }
  }, [
    dispatch,
    showDeleteDialog,
    props.workspaceId,
    dispatchedDatasetsAndJobs,
  ]);

  const containerProps = {
    isLoading: !allLoaded,
    compact: true,
    styles: {
      headerContainer: 'hidden',
      body: { minHeight: '750px' },
      actionsContainer: 'hidden',
    },
  } as IElxContainerProps;

  const StringOperators = ['==', '!=', 'Contains'];
  const namePillFilter: FilterOption = {
    field: 'name',
    label: 'Name',
    multiselect: true,
    pillMode: FilterOptionPillMode.Dynamic,
    values: dataConnections
      .map((dataConnection: DataSource) => dataConnection.name)
      .filter((value, index, self) => self.indexOf(value) === index),
  };
  const idPillFilter: FilterOption = {
    field: 'id',
    label: 'Id',
    multiselect: true,
    pillMode: FilterOptionPillMode.Dynamic,
    values: dataConnections
      .map((dataConnection: DataSource) => dataConnection.id)
      .filter((value, index, self) => self.indexOf(value) === index),
  };
  const statusPillFilter: FilterOption = {
    field: 'connectionStatus',
    label: 'Status',
    multiselect: true,
    operators: StringOperators,
    mode: FilterOptionMode.Text,
    pillMode: FilterOptionPillMode.Static,
  };

  const columns: IElxColumn[] = [
    {
      minWidth: 100,
      isResizable: true,
      isMultiline: true,
      className: classNames.tableStyles,
      key: 'name',
      name: 'Name',
      onRender: (item: DataSource) => {
        if (item.type === ConnectionDataSourceType.Shared) {
          const sharedConn = sharedConnList.find(
            (s) => s.targetConnectionName === item.id
          );
          if (sharedConn) {
            return (
              <div>{sharedConn.sourceConnection.connectionDisplayName}</div>
            );
          }
        }
        return <div>{item.name}</div>;
      },
    },
    {
      minWidth: 150,
      isResizable: true,
      isMultiline: false,
      className: classNames.tableStyles,
      key: 'status',
      name: 'Status',
      onRender: renderStatus,
    },
    {
      minWidth: 150,
      isResizable: true,
      isMultiline: false,
      className: classNames.tableStyles,
      key: 'share status',
      name: 'Share Status',
      onRender: (item: any) => {
        return (
          <>
            {item?.targetProjectNames ||
            item.type === ConnectionDataSourceType.Shared ? (
              <>
                <ElxActionButton
                  iconProps={{
                    iconName: 'Link',
                    title: 'Connection Shared',
                    'aria-label': 'Connection not connected',
                  }}
                  text="Shared"
                  onClick={() => {}}
                  styles={{ root: { cursor: 'default' } }}
                />
              </>
            ) : (
              <>
                <ElxActionButton
                  text={'Not Shared'}
                  styles={{ root: { cursor: 'default' } }}
                  onClick={() => {}}
                />
              </>
            )}
          </>
        );
      },
    },
    {
      minWidth: 0,
      maxWidth: 0,
      isResizable: true,
      isMultiline: false,
      className: classNames.hiddenColumnStyles,
      styles: { root: { display: 'none' } },
      key: 'statusHidden',
      name: '',
      fieldName: 'connectionStatus',
    },
    {
      minWidth: 100,
      isResizable: true,
      isMultiline: false,
      className: classNames.tableStyles,
      key: 'type',
      name: 'Type',
      fieldName: 'type',
    },
    {
      minWidth: 150,
      isResizable: true,
      isMultiline: false,
      className: classNames.tableStyles,
      key: 'container',
      name: 'Container',
      onRender: (item: DataSource) => {
        if (item.type === ConnectionDataSourceType.Shared) {
          const sharedConn = sharedConnList.find(
            (s) => s.targetConnectionName === item.id
          );
          if (sharedConn) {
            return <div>{sharedConn.sourceConnection.cluster}</div>;
          }
        }
        return <div>{item.cluster}</div>;
      },
    },
    {
      minWidth: 100,
      isResizable: true,
      isMultiline: false,
      className: classNames.tableStyles,
      key: 'dataSource',
      name: 'Data source',
      onRender: (item: DataSourceWithValidation, i, col) => {
        if (item.type === ConnectionDataSourceType.Shared) {
          const sharedConn = sharedConnList.find(
            (s) => s.targetConnectionName === item.id
          );
          if (sharedConn) {
            return renderDataSource(sharedConn.sourceConnection, i, col);
          }
        }
        return renderDataSource(item, i, col);
      },
    },
    {
      minWidth: 150,
      isResizable: true,
      isMultiline: false,
      className: classNames.tableStyles,
      key: 'authentication',
      name: 'Authentication',
      onRender: (item: DataSource) => {
        if (item.type === ConnectionDataSourceType.Shared) {
          const sharedConn = sharedConnList.find(
            (s) => s.targetConnectionName === item.id
          );
          if (sharedConn) {
            return renderAuth(sharedConn.sourceConnection);
          }
        }
        return renderAuth(item);
      },
    },
    {
      minWidth: 150,
      isResizable: true,
      isMultiline: false,
      className: classNames.tableStyles,
      key: 'id',
      name: 'Id',
      fieldName: 'id',
    },
  ];

  const searchProps = {
    pillFilters: [namePillFilter, idPillFilter, statusPillFilter],
    filterDisplayMode: FilterDisplayMode.Pill,
  };

  const actions: ITableAction[] = [
    {
      key: 'add',
      text: 'Add Connection ',
      iconProps: { iconName: 'Add' },
      defaultDisplay: DisplayTypes.Show,
      onBulkAction: (items: any[]) => {
        setAddConnection(true);
        setShowEditPanel(true);
      },
    },
    {
      key: 'edit',
      text: 'Edit Connection',
      iconProps: { iconName: 'Edit' },
      onAction: (item: any) => {
        setSelected(item as DataSourceWithValidation);
        setAddConnection(false);
        setShowEditPanel(true);
      },
      onBulkAction: (items: any[]) => {
        setSelected(items[0] as DataSourceWithValidation);
        setAddConnection(false);
        setShowEditPanel(true);
      },
    },
    {
      key: 'delete',
      text: 'Delete Connection',
      iconProps: { ...DeleteIconProps },
      onAction: (item: any) => {
        setShowDeleteDialog(true);
        setSelected(item as DataSourceWithValidation);
      },
      onBulkAction: (items: any[]) => {
        setShowDeleteDialog(true);
        setSelected(items[0] as DataSourceWithValidation);
      },
    },
    {
      key: 'revalidate',
      text: 'Refresh and Revalidate Connections',
      iconProps: { iconName: 'Refresh' },
      defaultDisplay: DisplayTypes.Show,
      onBulkAction: (items: any[]) => {
        refreshAndRevalidate();
      },
    },
    {
      key: 'Share',
      text: 'Share Connection',
      iconProps: { iconName: 'Share' },
      onAction: (item: any) => {
        setSelected(item as DataSourceWithValidation);
        setAddConnection(false);
        setShowEditPanel(true);
        setShowShareOnly(true);
      },
      onBulkAction: (items: any[]) => {
        setSelected(items[0] as DataSourceWithValidation);
        setAddConnection(false);
        setShowEditPanel(true);
        setShowShareOnly(true);
      },
    },
  ];

  useEffect(() => {
    if (jobListStatus === JobListStatus.Loaded && showDeleteDialog) {
      let jobs: Job[];

      jobs = _(jobList)
        .filter(function (job) {
          return !!_.find(
            job && job.config && job.config.activities,
            function (activity) {
              return (
                (activity &&
                  activity.input &&
                  activity.input.connectionName === selected.id) ||
                (activity &&
                  activity.output &&
                  activity.output.connectionName === selected.id)
              );
            }
          );
        })
        .sortBy(['Name'])
        .value();

      setJobDependencies(jobs);
      const hasDependentJobs = (): boolean => {
        return !_.isEmpty(jobs);
      };
      const canDelete = (): boolean => {
        return !hasDependentJobs();
      };
      const deleteConnection = canDelete();
      setCanDelete(deleteConnection);
      setDependencyCalculated(true);
    }
  }, [jobList, jobListStatus, selected.id, showDeleteDialog]);

  const items: IBreadcrumbItem[] = [
    {
      text: 'Workspace management',
      key: 'workspaceManagement',
      onClick: () => {
        props.dismissPanel();
      },
    },
    {
      text: workspace?.name + ' - Data Connections',
      key: 'dataConnections',
      onClick: () => {},
    },
  ];

  return (
    <Panel
      type={PanelType.large}
      isOpen={props.show}
      onDismiss={props.closeQuickSwitchPanel}
      styles={panelStyles}
      isFooterAtBottom={true}
      onRenderHeader={() => {
        return (
          <Breadcrumb
            items={items}
            maxDisplayedItems={2}
            ariaLabel="Workspace data connection breadcrumb with items rendered as buttons"
            overflowAriaLabel="More links"
            styles={breadCrumbStyles}
          />
        );
      }}
    >
      <StackItem>
        <ElxPivot
          selectedKey={tabSelectedKey}
          onItemSelect={setTabSelectedKey}
          items={tabItems}
          styles={{
            root: {
              borderBottom: '1px solid lightgrey',
              position: 'sticky',
            },
          }}
        ></ElxPivot>
      </StackItem>
      {tabSelectedKey === tabStates[0] && (
        <>
          {/* Delete Data Connection */}
          {showDeleteDialog && (
            <MessageBar
              messageBarType={MessageBarType.warning}
              isMultiline={true}
              onDismiss={() => setShowDeleteDialog(false)}
              dismissButtonAriaLabel="Close"
              actions={
                <div>
                  <MessageBarButton
                    text="Delete"
                    disabled={!canDelete}
                    onClick={() => {
                      setShowDeleteDialog(false);
                      setValidatedDataConnections(
                        validatedDataConnections.filter((d) => {
                          return d.id !== selected.id;
                        })
                      );
                      dispatch(
                        deleteDataConnection({
                          workspaceId: props.workspaceId,
                          connectionId: selected.id,
                        })
                      );
                    }}
                  />
                  <MessageBarButton
                    text="Cancel"
                    onClick={() => {
                      setShowDeleteDialog(false);
                    }}
                  />
                </div>
              }
            >
              <span>
                {dependencyCalculated ? (
                  <DeleteDialogContent
                    canDelete={
                      canDelete && jobListStatus === JobListStatus.Loaded
                    }
                    dataconnectionName={selected?.name}
                    jobDependencies={jobDependencies}
                  />
                ) : (
                  'Loading...'
                )}
              </span>
            </MessageBar>
          )}
          <ElxTableContainer
            containerProps={containerProps}
            tableProps={{
              columns: columns,
              selectionMode: SelectionMode.single,
              checkboxVisibility: CheckboxVisibility.always,
              items: validatedDataConnections,
              styles: tableStyles,
              actions: actions,
              setKey: 'id',
            }}
            searchBoxProps={searchProps}
          />

          {showEditPanel && (
            <EditDataConnectionPanel
              connectionId={addConnection ? undefined : selected.id}
              workspace={workspace}
              newConnectionName={(newDataConnection: string) => {}}
              updateSavedConnectionId={updateSavedConnectionId}
              connectionNameList={dataConnections.map(
                (dc: DataSource) => dc.name
              )}
              show={showEditPanel}
              dismissPanel={() => {
                setShowEditPanel(false);
                setShowShareOnly(false);
              }}
              openParentPanel={props.openPanel}
              closeParentPanel={props.dismissPanel}
              closeQuickSwitchPanel={props.closeQuickSwitchPanel}
              refreshConnectionsTable={refreshAndRevalidate}
              showShareOnly={showShareOnly}
            />
          )}
        </>
      )}
      {tabSelectedKey === tabStates[1] && (
        <>
          <Stack.Item>
            <Editor
              language={'json'}
              readOnly={true}
              value={JSON.stringify(validatedDataConnections, null, 4)}
              height={editorHeight}
              width={'100%'}
              onChange={function (value: string): void {}}
            ></Editor>
          </Stack.Item>
        </>
      )}
    </Panel>
  );
};

export default DataConnectionsTablePanel;
