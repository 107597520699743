import { ElxPrimaryButton } from '@elixir/components/lib/Button/Variations/PrimaryButton';
import redisCacheApi from './redisCacheApi';
import { Card } from 'components/cards/card';
import { IStackStyles, Stack } from '@fluentui/react';
import { useState } from 'react';

const cardStyles: Partial<IStackStyles> = {
  root: {
    marginTop: '15px',
    marginLeft: '15px',
    width: '50%',
  },
};

const RedisCacheSettings = (): JSX.Element => {
  const [clearBtnText, setClearBtnText] = useState<string>('Clear Cache');
  return (
    <Card title="Redis Cache" tokens={{ childrenGap: 16 }} styles={cardStyles}>
      <p>Redis is used to cache :</p>
      <ul>
        <li>User Profiles and Feature Settings</li>
        <li>Workspaces and Saved Objects</li>
        <li>Schema and Catalog Metadata</li>
      </ul>
      <p>and more.</p>
      <Stack.Item align="end">
        <ElxPrimaryButton
          text={clearBtnText}
          styles={{ root: { width: '200px' } }}
          onClick={() => {
            setClearBtnText('Clearing Cache ...');
            redisCacheApi.clearRedisCache().then(() => {
              setClearBtnText('Clear Cache');
            });
          }}
        />
      </Stack.Item>
    </Card>
  );
};

export default RedisCacheSettings;
