import saveAs from 'file-saver';
// import { FileSaverOptions } from 'file-saver';
// import _ from 'lodash';

export interface CsvOptions {
  separator: ',';
  quoteValues: boolean;
}
/**
 * Helper Class to export data in csv format.
 * Used to export workspace orchestration parameters
 */
class CsvExporter {
  private defaultCsvOptions: CsvOptions = {
    separator: ',',
    quoteValues: true,
  };

  private escape(val: string | Object, csvOptions: CsvOptions) {
    let nonAlphaNumRE = /[^a-zA-Z0-9]/;
    let allDoubleQuoteRE = /"/g;
    let value = String(val);

    if (csvOptions.quoteValues && nonAlphaNumRE.test(value)) {
      value = '"' + value.replace(allDoubleQuoteRE, '""') + '"';
    }

    return value;
  }
  private convertToCsv(columns: any[], rows: any[], csvOptions: CsvOptions) {
    // escape each cell in each row
    let csvRows = rows.map((row) => {
      let newRow: string[] = [];

      columns.forEach((column) => {
        newRow.push(row[column.id]);
      });
      return newRow.map((rowItem) => this.escape(rowItem, csvOptions));
    });
    // add the columns to the rows
    csvRows.unshift(
      columns.map((col) => {
        return this.escape(col.name, csvOptions);
      })
    );

    return csvRows
      .map(function (row) {
        return row.join(csvOptions.separator) + '\r\n';
      })
      .join('');
  }

  public exportAsCsv(
    filename: string,
    columns: Object[],
    rows: Object[],
    csvOptions: CsvOptions
  ) {
    if (!csvOptions) {
      csvOptions = this.defaultCsvOptions;
    }
    if (columns && columns.length > 0) {
      // Set default value to rows
      rows = rows || [];
      let csv = new Blob([this.convertToCsv(columns, rows, csvOptions)], {
        type: 'text/plain',
      });
      saveAs(csv, filename);
    }
  }
}

const csvExporter = new CsvExporter();
export default csvExporter;
