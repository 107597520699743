import * as pako from 'pako';

/**
 * Compress a text, encode with base64 encoding, and encode with URL encoding.
 * @param {} str - uncompressed text
 * @returns {} Compressed + base64-encoded + URL-encoded text
 */
export function compressAndEncodeBase64AndUri(str: string): string {
  var compressedBase64 = compressAndEncodeBase64(str);

  // Encode the data with URL-encoding
  return encodeURIComponent(compressedBase64);
}

/**
 * Compress a text and encode with base64 encoding.
 * @param {} str - uncompressed text
 * @returns {} Compressed + base64-encoded text
 */
function compressAndEncodeBase64(str: string): string {
  var compressed = compressString(str);

  // Convert from Base64
  return btoa(compressed);
}

/**
 * Compress a text.
 * @param {} str - uncompressed text
 * @returns {} Compressed text
 */
function compressString(str: string): string {
  // Convert to a byte array
  // var byteArray : number[] = str.split('').map(function (e) {
  //     return e.charCodeAt(0)
  // });

  // var byteArray2 = new Uint8Array(byteArray);

  // Compress the byte array
  var compressedByteArray: Uint8Array = pako.gzip(str);

  var test = Array.from(compressedByteArray);

  // Convert from compressed byte array to compressed string
  var compressed = String.fromCharCode.apply(null, test);

  return compressed;
}

// /**
//  * Decompress a text that is (1) URL Encoded, (2) Base-64 encoded, and (3) ZIP compressed.
//  * @param {} compressedBase64UriComponent
//  * @returns {} Decompressed text
//  */
// function decompressBase64UriComponent(compressedBase64UriComponent) {
//     // Decode the data from the URL
//     var compressedBase64 = decodeURIComponent(compressedBase64UriComponent);

//     return decompressBase64(compressedBase64);
// }

// /**
//  * Decompress a text that is (1) Base-64 encoded, and (2) ZIP compressed.
//  * @param {} compressedBase64
//  * @returns {} Decompressed text
//  */
// function decompressBase64(compressedBase64) {
//     // Convert from Base64
//     var compressed = atob(compressedBase64);

//     return decompressString(compressed);
// }

// /**
//  * Decompress a text that is ZIP compressed.
//  * @param {} compressed
//  * @returns {} Decompressed text
//  */
// function decompressString(compressed) {
//     // Convert to a byte array
//     var compressedByteArray = compressed.split('').map(function (e) {
//         return e.charCodeAt(0);
//     });

//     // Decompress the byte array
//     var decompressedByteArray = pako.inflate(compressedByteArray);

//     // Convert from decompressed byte array to string
//     var decompressed = String.fromCharCode.apply(null, decompressedByteArray);

//     return decompressed;
// }
