import React, { useState } from 'react';

const ReadMore = (props: any): JSX.Element => {
  const text = props.text;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <>
      {isReadMore ? text.slice(0, props.charLimit) : text}
      {text.length > props.charLimit && (
        <span onClick={toggleReadMore} className={props.readMoreClassName}>
          {isReadMore ? props.readMoreText : props.readLessText}
        </span>
      )}
    </>
  );
};

export default ReadMore;
