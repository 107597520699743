import { ProjectDataConnection } from './project';

export enum Modes {
  Private = 'private',
  Shared = 'shared',
}

export interface SavedObjectBlob {
  Id: string;
  Type: any;
  Title: string;
  Body: any;
}
/**
 * Check if object is an instance of SavedObjectBlob
 * @param object check that needs to be checked
 * @returns boolean
 */
export const instanceOfSavedObjectBlob = (
  object: any
): object is SavedObjectBlob => {
  return (
    'Id' in object && 'Type' in object && 'Title' in object && 'Body' in object
  );
};

export interface SavedObjectBlobData {
  blob: SavedObjectBlob;
  blobProperties: Object;
}

export enum WorkspaceAccessFilter {
  None = 'none',
  Read = 'read',
  Write = 'write',
  Orchestrate = 'orchestrate',
  Admin = 'admin',
  Owned = 'owned',
}

export enum WorkspaceStatus {
  None = 'None',
  Loading = 'Loading',
  Loaded = 'Loaded',
  Error = 'Error',
}

export enum WorkspaceSaveStatus {
  None = 'None',
  Saving = 'Saving',
  Saved = 'Saved',
  Error = 'Error',
}

export enum WorkspaceListStatus {
  None = 'None',
  Loading = 'Loading', // loading page 1 (blocked)
  Fetching = 'Fetching', // fetching pages 2 - N (show spinner)
  Loaded = 'Loaded',
  Error = 'Error',
}

export enum PeoplePickerType {
  Administrators = 'Administrators',
  Writers = 'Writers',
  Readers = 'Readers',
  Orchestrators = 'Orchestrators',
}

export enum PersonaType {
  User = 'User',
  Group = 'Group',
  Application = 'Application',
  None = 'None',
}

export enum WorkspaceShareType {
  Shared = 'shared',
  Private = 'private',
}
interface WorkspaceBase {
  id: string;
  name: string;
  alias: string;
  dsoProject: string;
  accessFilter: WorkspaceAccessFilter;
  createdBy: string;
  applications: WorkspaceApplication[] | undefined;
  dataConnections: ProjectDataConnection[] | undefined;
  lastUpdated: string;
  description: string;
  isReadOpen: boolean;
  shareType: string;
  lastUpdatedBy: string;
  customReadAccessMessage: string;
  customWriteAccessMessage: string;
  sourceDataConnections?: any[];
}
export interface Workspace extends WorkspaceBase {
  tags: string[];
  adminAppIds: WorkspacePersona[];
  adminGroups: string[];
  adminGroupIds: WorkspacePersona[];
  adminAliases: WorkspacePersona[];
  orchestrateAppIds: WorkspacePersona[];
  orchestrateGroups: string[];
  orchestrateGroupIds: WorkspacePersona[];
  orchestrateAliases: WorkspacePersona[];
  readOnlyAppIds: WorkspacePersona[];
  readOnlyGroups: string[];
  readOnlyGroupIds: WorkspacePersona[];
  readOnlyAliases: WorkspacePersona[];
  readWriteAppIds: WorkspacePersona[];
  readWriteGroups: string[];
  readWriteGroupIds: WorkspacePersona[];
  readWriteAliases: WorkspacePersona[];
  administrators: string[];
  writers: string[];
  readers: string[];
  orchestrators: string[];
}

export interface WorkspaceBeforeFormat extends WorkspaceBase {
  tags: string;
  adminAppIds: string;
  adminGroups: string;
  adminGroupIds: string;
  adminAliases: string;
  orchestrateAppIds: string;
  orchestrateGroups: string;
  orchestrateGroupIds: string;
  orchestrateAliases: string;
  readOnlyAppIds: string;
  readOnlyGroups: string;
  readOnlyGroupIds: string;
  readOnlyAliases: string;
  readWriteAppIds: string;
  readWriteGroups: string;
  readWriteGroupIds: string;
  readWriteAliases: string;
}

export interface WorkspaceApplication {
  tenant: string;
  id: string;
  key?: string;
  isManagedIdentity?: boolean;
  subscriptionId?: string;
  resourceGroup?: string;
}

export interface WorkspacePersona {
  displayName: string;
  id: string; //this is appId for Apps
  type: PersonaType;
}
export interface MigrationRequest {
  workspaceId: string;
  targetAADTenantId: string;
  newAdminGroupId: string;
}

export interface WorkspaceHistoryObj {
  id: string;
  lastUpdated: string;
  lastUpdatedBy: string;
  historyOperation: string;
}

export interface WorkspaceHistoryFullObj extends WorkspaceBeforeFormat {
  aadTenantId: string;
  historyBy: string;
  historyOperation: string;
  historyPartitionKey: string; //not sure if this is needed
  historyTime: string;
  timestamp: string;
}

export enum SavedObjectType {
  Query = 'search',
  Visualization = 'visualization',
  Visualizations = 'visualizations', // looks like some recent/favorite items have type 'visualizationS'
  Dashboard = 'dashboard',
}

/**
 * interface for the objects the workspace stores
 **/
export interface WorkspaceObjectBase {
  id: string;
  title: string;
  type: SavedObjectType;
  body?: string;
  workspace?: string;
}

export interface WorkspaceObject extends WorkspaceObjectBase {
  subType?: string;
  temporary: boolean;
  description?: string;
  tags?: string[];
  versionId?: string;
  workspace: string;
}

interface lowHighMapping {
  lowSideValue: string;
  highSideValue: string;
}

export interface dataSourceParamMapping extends lowHighMapping {
  dataSourceType: string;
}

export interface dataConnectionParamMapping extends lowHighMapping {
  propertyName: string;
}
