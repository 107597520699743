import {
  WorkspacePersona,
  Workspace,
  WorkspaceBeforeFormat,
  PersonaType,
  Modes,
  WorkspaceAccessFilter,
} from 'features/workspaces/models/workspace';
import {
  getMultipleApplicationNames,
  getMultipleGroupNames,
  getMultipleUserAliases,
} from 'utils/graphClient';
import { IPersonaProps } from '@fluentui/react';
import { getAlias } from 'utils/authUtils';
import {
  WorkspaceItem,
  instanceOfWorkspaceItem,
} from './workspaceQuickSwitchUtils';

let leApp: any; // AngularJS dependency - minimize use

/**
 * Gets the user profiles and workspace based on the url.
 * @returns A promise that returns an object with two promises
 */
export function getPreloadedData(): Promise<{
  userProfile: object;
  workspace: {
    type: string;
    alias: string;
    promise: Promise<WorkspaceBeforeFormat> | null;
  };
  jobs: boolean | object;
}> {
  return leApp?.getPreloadedData();
}

/**
 * Gets the current workspace. Ensures that the workspace is a real workspace object retrieved
 * from the backend (not null or a dummy object for the private workspace).
 * @returns A promise that returns the current workspace.
 */

export function getCurrentWorkspaceId(): string | undefined {
  return leApp?.getCurrentWorkspaceId();
}

/**
 * Handles workspace switch events.
 * @param handler - The event handler.
 * @returns The deregistration function for the handler.
 */
export const onWorkspaceSwitch = (
  handler: (event: any, workspace: any) => void
): (() => void) | undefined => {
  return leApp?.onWorkspaceSwitch(handler);
};

/**
 * Handles workspace save events.
 * @param handler - The event handler.
 * @returns The deregistration function for the handler.
 */
export const onWorkspaceSave = (
  handler: (event: any, workspace: any) => void
): (() => void) | undefined => {
  return leApp?.onWorkspaceSave(handler);
};

export const getPrivateWorkspaceId = () => `private-${getAlias()}`;

/**
 * Switches to a workspace of share type 'shared' or to private if now share type is provided.
 * @param {string} alias - alias of the workspace to switch to.  null will switch to private workspace
 * @param {boolean} checkOnly - a flag indicating whether workspace switch should happen, or just perform permission checks
 * @param {boolean} force - Forces the workspace to be reloaded.
 * @returns {Promise} - A promise returning the workspace.
 */
export const switchToWorkspace = (
  alias: string | null,
  checkOnly: boolean = false,
  force: boolean = false
): Promise<any> => {
  return leApp?.switchToWorkspace(alias, checkOnly, force);
};

/**
 * Gets the a visualization object from the provided objectId
 * @param objectId id of visualization object
 * @param isTempObject
 * @param workspace workspace associated with the the object
 * @returns A promise containing all visualization information
 */
export const getSavedVisualization = (
  objectId: string,
  isTempObject: boolean,
  workspace: Workspace
): Promise<any> => {
  return leApp?.getSavedVisualization(objectId, isTempObject, workspace);
};

/**
 * Gets the a dashboard object from the provided objectId
 * @param objectId id of visualization object
 * @param isTempObject
 * @param workspace workspace associated with the the object
 * @returns A promise containing all dashboard information
 */
export const getSavedDashboard = (
  objectId: string,
  isTempObject: boolean,
  workspace: Workspace
): Promise<any> => {
  return leApp?.getSavedDashboard(objectId, isTempObject, workspace);
};

/**
 * Gets the current dashboard's saved status
 * @returns A promise containing all dashboard information
 */
export const getDashboardIsStatusEditing = (): boolean => {
  return leApp?.getDashboardIsStatusEditing();
};

export function inject(_leApp: any) {
  leApp = _leApp;
}

//Format workspace data helpers START
const formatData = (field: string) => {
  return typeof field === 'string' && field !== '' ? field?.split(',') : [];
};

const convertAppIdToName = async (
  appIds: string
): Promise<WorkspacePersona[]> => {
  const appIdsList = formatData(appIds);

  return getMultipleApplicationNames(appIdsList).then((msalResponses) => {
    const combinedResponse = msalResponses.flatMap(
      (repsonse) => repsonse.value
    );
    return combinedResponse.map((app) => {
      return {
        displayName: app.displayName,
        id: app.appId || '',
        type: PersonaType.Application,
      };
    });
  });
};

const convertGroupIdToName = async (
  groupIds: string
): Promise<WorkspacePersona[]> => {
  const groupIdsList = formatData(groupIds);

  return getMultipleGroupNames(groupIdsList).then((msalResponses) => {
    const combinedResponse = msalResponses.flatMap(
      (repsonse) => repsonse.value
    );
    return combinedResponse.map((group) => {
      return {
        displayName: group.displayName,
        id: group.id,
        type: PersonaType.Group,
      };
    });
  });
};

const convertUserAliasToName = async (
  userAliases: string
): Promise<WorkspacePersona[]> => {
  const userAliasList = formatData(userAliases);

  return getMultipleUserAliases(userAliasList).then((msalResponses) => {
    const combinedResponse = msalResponses.flatMap(
      (repsonse) => repsonse.value
    );
    return combinedResponse.map((userAlias) => {
      return {
        displayName: userAlias.displayName,
        //use the user id if the user does not have microsoft alias
        id: userAlias.userPrincipalName?.includes('@')
          ? userAlias.userPrincipalName?.split('@')[0] || ''
          : userAlias.id,
        type: PersonaType.User,
      };
    });
  });
};

const workspacePersonasToString = (personas: WorkspacePersona[]): string => {
  return personas
    .map((p) => {
      return p.id;
    })
    .toString();
};
//Format workspace data helpers END

/**
 * formats the workspace object from api call to a workspace object with values more appropriate to modification
 * @param workspace workspace object from the api call
 * @returns new workspace object where the string values representing lists are turn into js arrays
 */
export const formatWorkspaceForUse = async (
  workspace: WorkspaceBeforeFormat
): Promise<Workspace> => {
  let {
    id,
    name,
    alias,
    dsoProject,
    accessFilter,
    createdBy,
    applications,
    dataConnections,
    lastUpdated,
    description,
    isReadOpen,
    shareType,
    lastUpdatedBy,
    customReadAccessMessage,
    customWriteAccessMessage,
  } = workspace;

  let workspaceReturn: Workspace = {
    id: id,
    name: name,
    alias: alias,
    dsoProject: dsoProject,
    applications: applications,
    dataConnections: dataConnections,
    createdBy: createdBy,
    accessFilter: accessFilter,
    lastUpdated: lastUpdated,
    description: description,
    lastUpdatedBy: lastUpdatedBy,
    isReadOpen: isReadOpen,
    shareType: shareType,
    customReadAccessMessage: customReadAccessMessage || '',
    customWriteAccessMessage: customWriteAccessMessage || '',
    tags: [],
    adminAppIds: [],
    adminGroups: [],
    adminGroupIds: [],
    adminAliases: [],
    orchestrateAppIds: [],
    orchestrateGroups: [],
    orchestrateGroupIds: [],
    orchestrateAliases: [],
    readOnlyAppIds: [],
    readOnlyGroups: [],
    readOnlyGroupIds: [],
    readOnlyAliases: [],
    readWriteAppIds: [],
    readWriteGroups: [],
    readWriteGroupIds: [],
    readWriteAliases: [],
    administrators: [],
    writers: [],
    readers: [],
    orchestrators: [],
  };

  if (workspace.shareType === 'shared') {
    workspaceReturn.tags =
      typeof workspace.tags === 'string' ? JSON.parse(workspace.tags) : [];
    workspaceReturn.adminAppIds = await convertAppIdToName(
      workspace.adminAppIds
    );
    workspaceReturn.adminGroupIds = await convertGroupIdToName(
      workspace.adminGroupIds
    );
    workspaceReturn.adminAliases = await convertUserAliasToName(
      workspace.adminAliases
    );
    workspaceReturn.orchestrateAppIds = await convertAppIdToName(
      workspace.orchestrateAppIds
    );
    workspaceReturn.orchestrateGroupIds = await convertGroupIdToName(
      workspace.orchestrateGroupIds
    );
    workspaceReturn.orchestrateAliases = await convertUserAliasToName(
      workspace.orchestrateAliases
    );
    workspaceReturn.readOnlyAppIds = await convertAppIdToName(
      workspace.readOnlyAppIds
    );
    workspaceReturn.readOnlyGroupIds = await convertGroupIdToName(
      workspace.readOnlyGroupIds
    );
    workspaceReturn.readOnlyAliases = await convertUserAliasToName(
      workspace.readOnlyAliases
    );
    workspaceReturn.readWriteAppIds = await convertAppIdToName(
      workspace.readWriteAppIds
    );
    workspaceReturn.readWriteGroupIds = await convertGroupIdToName(
      workspace.readWriteGroupIds
    );
    workspaceReturn.readWriteAliases = await convertUserAliasToName(
      workspace.readWriteAliases
    );
  }

  return workspaceReturn;
};

/**
 * Revert the changes that were done to values of some workspace field in the formatWorkspaceForUse function
 * @param workspace Workspace object with arrays as values for some field , easy for modification
 * @returns workspace in the format that was returned from the api
 */
export const formatWorkspaceForSave = (
  workspace: Workspace
): WorkspaceBeforeFormat => {
  let {
    id,
    name,
    alias,
    dsoProject,
    accessFilter,
    createdBy,
    applications,
    dataConnections,
    lastUpdated,
    description,
    isReadOpen,
    shareType,
    lastUpdatedBy,
    customReadAccessMessage,
    customWriteAccessMessage,
  } = workspace;

  let workspaceReturn: WorkspaceBeforeFormat = {
    id: id,
    name: name,
    alias: alias,
    dsoProject: dsoProject,
    applications: applications,
    dataConnections: dataConnections,
    createdBy: createdBy,
    accessFilter: accessFilter,
    lastUpdated: lastUpdated,
    description: description,
    lastUpdatedBy: lastUpdatedBy,
    isReadOpen: isReadOpen,
    shareType: shareType,
    customReadAccessMessage: customReadAccessMessage,
    customWriteAccessMessage: customWriteAccessMessage,
    tags: '',
    adminAppIds: '',
    adminGroups: '',
    adminGroupIds: '',
    adminAliases: '',
    orchestrateAppIds: '',
    orchestrateGroups: '',
    orchestrateGroupIds: '',
    orchestrateAliases: '',
    readOnlyAppIds: '',
    readOnlyGroups: '',
    readOnlyGroupIds: '',
    readOnlyAliases: '',
    readWriteAppIds: '',
    readWriteGroups: '',
    readWriteGroupIds: '',
    readWriteAliases: '',
  };
  workspaceReturn.tags = JSON.stringify([...new Set(workspace.tags)]);
  workspaceReturn.adminAppIds = workspacePersonasToString(
    workspace.adminAppIds || []
  );
  workspaceReturn.adminGroups = workspace.adminGroups?.toString();
  workspaceReturn.adminGroupIds = workspacePersonasToString(
    workspace.adminGroupIds || []
  );
  workspaceReturn.adminAliases = workspacePersonasToString(
    workspace.adminAliases || []
  );

  workspaceReturn.orchestrateAppIds = workspacePersonasToString(
    workspace.orchestrateAppIds || []
  );
  workspaceReturn.orchestrateGroups = workspace.orchestrateGroups?.toString();
  workspaceReturn.orchestrateGroupIds = workspacePersonasToString(
    workspace.orchestrateGroupIds || []
  );
  workspaceReturn.orchestrateAliases = workspacePersonasToString(
    workspace.orchestrateAliases || []
  );

  workspaceReturn.readOnlyAppIds = workspacePersonasToString(
    workspace.readOnlyAppIds || []
  );
  workspaceReturn.readOnlyGroups = workspace.readOnlyGroups?.toString();
  workspaceReturn.readOnlyGroupIds = workspacePersonasToString(
    workspace.readOnlyGroupIds || []
  );
  workspaceReturn.readOnlyAliases = workspacePersonasToString(
    workspace.readOnlyAliases || []
  );

  workspaceReturn.readWriteAppIds = workspacePersonasToString(
    workspace.readWriteAppIds || []
  );
  workspaceReturn.readWriteGroups = workspace.readWriteGroups?.toString();
  workspaceReturn.readWriteGroupIds = workspacePersonasToString(
    workspace.readWriteGroupIds || []
  );
  workspaceReturn.readWriteAliases = workspacePersonasToString(
    workspace.readWriteAliases || []
  );

  return workspaceReturn;
};

export const combinePersonas = (
  groups: WorkspacePersona[],
  apps: WorkspacePersona[],
  users: WorkspacePersona[]
): string[] => {
  return [
    ...stringifyPersonas(groups),
    ...stringifyPersonas(apps),
    ...stringifyPersonas(users),
  ];
};

const stringifyPersonas = (personas: WorkspacePersona[]): string[] => {
  return personas.map((p) => {
    return JSON.stringify({
      text: p.displayName,
      secondaryText: p.type,
      tertiaryText: p.id,
    });
  });
};

export const convertToWorkspacePersona = (
  personaProps: IPersonaProps,
  type: PersonaType
): WorkspacePersona => {
  return {
    displayName: personaProps.text || '',
    id: personaProps.tertiaryText || '',
    type: type,
  };
};

//might delete and use the validation that Kevin made for the validation button
export interface DataConnectionResult {
  isAuthorized: boolean;
  authType: string;
  permission: string;
  message: string;
}
export interface WorkspaceDataConnectionValidation {
  connectionName: string;
  results: DataConnectionResult[];
}

export const handleBeforeUnload = (e: BeforeUnloadEvent) => {
  e.preventDefault();
  const message = 'Are you sure you want to leave? All changes will be lost.';
  e.returnValue = message;
  return message;
};

/**
 * @name isPrivate
 * @description
 * Tells whether a workspace is private.
 * @param {Workspace  | WorkspaceItem} workspace - The workspace.
 * @returns {Boolean} - true if private; false otherwise.
 */
export const isPrivate = (workspace: Workspace | WorkspaceItem) => {
  return instanceOfWorkspaceItem(workspace)
    ? false // return false when checking for workspaceItem type since there isnt enough information
    : workspace?.shareType === Modes.Private;
};

/**
 * @name isAdministratable
 * @description
 * Tells whether a workspace is administratable.
 * @param {Workspace  | WorkspaceItem} workspace - The workspace.
 * @returns {Boolean} - true if administratable; false otherwise.
 */
export const isAdministratable = (workspace: Workspace | WorkspaceItem) => {
  return (
    isPrivate(workspace) ||
    workspace.accessFilter === WorkspaceAccessFilter.Owned ||
    workspace.accessFilter === WorkspaceAccessFilter.Admin
  );
};

/**
 * @name isOrchestratable
 * @description
 * Tells whether a workspace is orchestratable.
 * @param {Workspace  | WorkspaceItem} workspace - The workspace.
 * @returns {Boolean} - true if orchestratable; false otherwise.
 */
export const isOrchestratable = (workspace: Workspace | WorkspaceItem) => {
  return (
    isAdministratable(workspace) ||
    workspace.accessFilter === WorkspaceAccessFilter.Orchestrate
  );
};

/**
 * @name isWriteable
 * @description
 * Tells whether a workspace is writeable.
 * @param {Workspace  | WorkspaceItem} workspace - The workspace.
 * @returns {Boolean} - true if writeable; false otherwise.
 */
export const isWriteable = (workspace: Workspace | WorkspaceItem) => {
  return (
    isOrchestratable(workspace) ||
    workspace.accessFilter === WorkspaceAccessFilter.Write
  );
};

/**
 * @name isReadable
 * @description
 * Tells whether a workspace is readable.
 * @param {Workspace  | WorkspaceItem} workspace - The workspace.
 * @returns {Boolean} - true if readable; false otherwise.
 */
export const isReadable = (workspace: Workspace | WorkspaceItem) => {
  return (
    isWriteable(workspace) ||
    workspace.accessFilter === WorkspaceAccessFilter.Read
  );
};
