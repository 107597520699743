import {
  ElxTableContainer,
  IElxColumn,
  IPickerItem,
  ElxDateTimePicker,
  ElxDateTimePickerDisplayMode,
  ElxDropdown,
} from '@elixir/components';
import {
  Panel,
  PanelType,
  SelectionMode,
  Stack,
  ThemeProvider,
} from '@fluentui/react';
import ErrorMessageBar from 'components/errorMessageBar/errorMessageBar';
import { useEffect, useRef, useState } from 'react';
import { colMedium, colSmall, colUnlimitedLarge } from 'utils/sharedStyles';
import { Dataset, DatasetMetrics } from 'features/orchestrator/models/dataset';
import MetricVis, { MetricVisYAxis } from './metricVis';
import { useLensShellTheme } from 'features/shell/lensShellStyles';
import datasetApi from 'features/orchestrator/api/datasetApi';
import { LensLabel } from 'utils/lensLabel';
import { DateTime, Duration } from 'luxon';
import notifier from 'utils/notifier';

export enum MetricsDatasetStatus {
  Loading,
  Loaded,
  Error,
}

const rangeOtions = [
  { key: 'last7days', text: 'Last 7 days' },
  { key: 'last30days', text: 'Last 30 days' },
  { key: 'ytd', text: 'Year Till Date' },
  { key: 'custom', text: 'Custom range' },
];

export const formatBytes = (bytes: number): string => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB'];
  if (bytes === 0) return '0 Bytes';
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return `${(bytes / Math.pow(1024, i)).toFixed(2)} ${sizes[i]}`;
};

export interface MetricsDatasetProps {
  selDataset: Dataset;
  onDismiss: () => void;
}

const MetricsDataset = (props: MetricsDatasetProps) => {
  const { selDataset, onDismiss } = props;

  const [datasetMetrics, setDatasetMetrics] = useState<DatasetMetrics[]>([]);
  const [stTime, setStTime] = useState<string>(
    DateTime.local().minus({ days: 7 }).toISO()
  );
  const [endTime, setEndTime] = useState<string>(DateTime.local().toISO());
  const [metricsDatasetStatus, setMetricsDatasetStatus] =
    useState<MetricsDatasetStatus>(MetricsDatasetStatus.Loading);
  const [rangeOption, setRangeOption] = useState<string>('custom');

  const divElxTableContaierRef = useRef(null);

  const columns: IElxColumn[] = [
    {
      ...colUnlimitedLarge,
      styles: { root: { fontWeight: 700 } },
      key: 'Relative path',
      name: 'Relative path',
      onRender: (datasetMetrics: DatasetMetrics) => {
        return datasetMetrics.uri?.split('://')[1];
      },
    },
    {
      ...colMedium,
      styles: { root: { fontWeight: 700 } },
      key: 'Stream Date',
      name: 'Stream Date',
      onRender: (datasetMetrics: DatasetMetrics) => {
        return DateTime.fromISO(datasetMetrics.partitionDate).toFormat(
          'MM/dd/yyyy hh:mm a'
        );
      },
    },
    {
      ...colSmall,
      styles: { root: { fontWeight: 700 } },
      key: 'Size',
      name: 'Size',
      onRender: (datasetMetrics: DatasetMetrics) => {
        return formatBytes(datasetMetrics.size);
      },
    },
    {
      ...colMedium,
      styles: { root: { fontWeight: 700 } },
      key: 'SLA',
      name: 'SLA',
      onRender: (datasetMetrics: DatasetMetrics) => {
        return DateTime.fromISO(datasetMetrics.slaDate).toFormat(
          'MM/dd/yyyy hh:mm a'
        );
      },
    },
    {
      ...colMedium,
      styles: { root: { fontWeight: 700 } },
      key: 'SLA Diff',
      name: 'SLA Diff',
      onRender: (datasetMetrics: DatasetMetrics) => {
        let slaDiffArr = datasetMetrics.slaDiff.split('.');
        if (slaDiffArr.length < 3) {
          let ret = Duration.fromISO(
            `${datasetMetrics.slaDiff.replace(
              /(\d+):(\d+):(\d+)\.(\d+)/,
              'PT$1H$2M$3.$4S'
            )}`
          ).toFormat("d 'days', h 'hr', m 'min'");
          if (ret.includes('-')) {
            ret = ret.replace(/-/g, '');
            ret = `-${ret}`;
          }
          return ret;
        }

        let ti = slaDiffArr[1]
          .concat('.', slaDiffArr[2])
          .replace(/(\d+):(\d+):(\d+)\.(\d+)/, 'T$1H$2M$3.$4S');
        let days = slaDiffArr[0].replace(/(\d+$)/, 'P$1D');
        let ret = Duration.fromISO(`${days}${ti}`).toFormat(
          "d 'days', h 'hr', m 'min'"
        );
        if (ret.includes('-')) {
          ret = ret.replace(/-/g, '');
          ret = `-${ret}`;
        }
        return ret;
      },
    },
    {
      ...colMedium,
      styles: { root: { fontWeight: 700 } },
      key: 'Created',
      name: 'Created',
      onRender: (datasetMetrics: DatasetMetrics) => {
        return DateTime.fromISO(datasetMetrics.createdDate).toFormat(
          'MM/dd/yyyy hh:mm a'
        );
      },
    },
    {
      ...colMedium,
      styles: { root: { fontWeight: 700 } },
      key: 'Expires',
      name: 'Expires',
      onRender: (datasetMetrics: DatasetMetrics) => {
        return DateTime.fromISO(datasetMetrics.expirationDate).toFormat(
          'MM/dd/yyyy hh:mm a'
        );
      },
    },
  ];

  const customColumns: IPickerItem[] = columns.map((o) => ({
    key: o.key,
    text: o.name,
    selected: true,
  }));

  const [customSelectedKeys, setCustomSelectedKeys] = useState<string[]>(
    customColumns.map((o) => o.key)
  );
  const theme = useLensShellTheme();

  useEffect(() => {
    const fetchDataset = async (stTime: string | null, endTime: string) => {
      stTime = encodeURIComponent(stTime || '');
      endTime = encodeURIComponent(endTime);
      try {
        setMetricsDatasetStatus(MetricsDatasetStatus.Loading);
        const dataset = await datasetApi.getDataset(
          selDataset.id,
          null,
          'true',
          stTime,
          endTime
        );
        let dm = Array.isArray(dataset.metrics)
          ? dataset.metrics.map((m) => ({ ...m, key: m.timestamp }))
          : [];
        setDatasetMetrics(dm);
        setMetricsDatasetStatus(MetricsDatasetStatus.Loaded);
      } catch (err) {
        notifier.error('Error - Getting Metrics Data for this dataset');
        setMetricsDatasetStatus(MetricsDatasetStatus.Error);
      }
    };
    if (stTime) {
      if (stTime < endTime) {
        fetchDataset(stTime, endTime);
      } else {
        notifier.warning('Start time is greater than end time');
      }
    } else {
      fetchDataset(stTime, endTime);
    }
  }, [selDataset, stTime, endTime]);

  return (
    <ThemeProvider theme={theme}>
      <Panel
        headerText={`Metrics for Dataset: ${selDataset.displayName}`}
        isOpen={true}
        key={'Metrics Dataset Panel'}
        type={PanelType.custom}
        customWidth="1260px"
        onDismiss={onDismiss}
        hasCloseButton={true}
        styles={{
          footerInner: { float: 'right' },
          footer: {
            borderTop: '1px solid lightgrey',
            backgroundColor: theme.palette.neutralLighter,
          },
          content: { background: 'white', padding: '0px' },
          main: { background: 'white !important' },
          scrollableContent: { overflowY: 'hidden !important' },
        }}
      >
        <Stack tokens={{ childrenGap: 8, padding: 16 }}>
          <Stack.Item
            styles={{
              root: { height: '450px' },
            }}
          >
            <Stack
              horizontal
              grow
              tokens={{ childrenGap: 8, padding: 8 }}
              styles={{
                root: {
                  borderBottom: '1px solid lightgray',
                  borderTop: '1px solid lightgray',
                },
              }}
            >
              <Stack.Item>
                <LensLabel
                  labelText="Start Time"
                  hintText={'Start when the metrics is shown.'}
                  required={false}
                ></LensLabel>
              </Stack.Item>
              <Stack.Item>
                <ElxDateTimePicker
                  value={stTime}
                  displayMode={ElxDateTimePickerDisplayMode.SideBySide}
                  onSelectDateTime={(dateTime?: string) => {
                    setStTime(dateTime || '');
                  }}
                  timeZones={[{ utcOffsetMinutes: 0, symbol: 'UTC' }]}
                  disabled={rangeOption !== 'custom'}
                />
              </Stack.Item>
              <Stack.Item>
                <LensLabel
                  labelText="End Time"
                  hintText={'End when the metrics is shown.'}
                  required={false}
                ></LensLabel>
              </Stack.Item>
              <Stack.Item>
                <ElxDateTimePicker
                  value={endTime}
                  displayMode={ElxDateTimePickerDisplayMode.SideBySide}
                  onSelectDateTime={(dateTime?: string) => {
                    setEndTime(dateTime || '');
                  }}
                  timeZones={[{ utcOffsetMinutes: 0, symbol: 'UTC' }]}
                  disabled={rangeOption !== 'custom'}
                />
              </Stack.Item>
              <Stack.Item>
                <LensLabel
                  labelText="Range"
                  hintText={'Select the range to show metrics.'}
                  required={false}
                ></LensLabel>
              </Stack.Item>
              <Stack.Item>
                <ElxDropdown
                  options={rangeOtions}
                  selectedKey={rangeOption}
                  onChange={(_, option) => {
                    const key = option?.key as string;
                    setRangeOption(key);
                    if (key === 'last7days') {
                      setStTime(DateTime.local().minus({ days: 7 }).toISO());
                      setEndTime(DateTime.local().toISO());
                    }
                    if (key === 'last30days') {
                      setStTime(DateTime.local().minus({ days: 30 }).toISO());
                      setEndTime(DateTime.local().toISO());
                    }
                    if (key === 'ytd') {
                      setStTime(DateTime.local().startOf('year').toISO());
                      setEndTime(DateTime.local().toISO());
                    }
                  }}
                ></ElxDropdown>
              </Stack.Item>
            </Stack>
            <Stack>
              <Stack.Item
                styles={{ root: { borderBottom: '1px solid lightgray' } }}
              >
                <MetricVis
                  data={datasetMetrics}
                  metricVisYAxis={MetricVisYAxis.Size}
                ></MetricVis>
              </Stack.Item>
            </Stack>
          </Stack.Item>
          <Stack.Item styles={{ root: { padding: '0px, 8px' } }}>
            {metricsDatasetStatus === MetricsDatasetStatus.Error && (
              <ErrorMessageBar
                message={'Error - Getting Metrics Data for this dataset'}
                onDismiss={() => {}}
              />
            )}
            {metricsDatasetStatus !== MetricsDatasetStatus.Error && (
              <div ref={divElxTableContaierRef}>
                <ElxTableContainer
                  containerProps={{
                    compact: true,
                    styles: {
                      headerContainer: { height: '0px !important' },
                      headerContent: { marginTop: '0px !important' },
                      headerComponent: { paddingTop: '0px !important' },
                      root: {
                        '.ms-DetailsHeader-cellName': {
                          fontWeight: '600 !important',
                        },
                      },
                      loading: { paddingTop: '300px' },
                      body: {
                        '.elx-table': {
                          marginLeft: '0px !important',
                          marginRight: '0px !important',
                        },
                        minHeight: '200px',
                        maxHeight: '1000px',
                      },
                    },
                    isLoading:
                      metricsDatasetStatus === MetricsDatasetStatus.Loading,
                  }}
                  tableProps={{
                    columns,
                    // actions,
                    items: datasetMetrics,
                    // selectedItems: selectedItems,
                    getKey: (item: DatasetMetrics) => item.timestamp,
                    // setKey: 'id',
                    // onSelectionChanged: (val) => {
                    //   setSelectedItems(val as DatasetMetrics[]);
                    // },
                    selectionMode: SelectionMode.none,
                  }}
                  customColumnProps={{
                    items: customColumns,
                    selectedItemKeys: customSelectedKeys,
                    onSave: setCustomSelectedKeys,
                  }}
                ></ElxTableContainer>
              </div>
            )}
          </Stack.Item>
        </Stack>
      </Panel>
    </ThemeProvider>
  );
};

export default MetricsDataset;
