/* Help Labels for Add Dataset Panel */

export const DatasetNameHelpLabel =
  'This is an optional friendly name for your dataset. It will show up in the Dataset name column in the Datasets main page.';
export const SelectConnectionHelpLabel =
  'Select an existing AAD authenticated data connection or create a new data connection.';
export const SelectTableHelpLabel =
  'This is the table within the cluster/container that contains the data you want to scrutinize.';
export const AddQueryHelpLabel =
  'This is an advanced feature. Most users won’t need this.​ If your dataset if too large or if you want to add additional computed columns, you can use a Kusto query to use just a sample or see more columns than what’s contained in the dataset. ';

/* Help Labels for Add Data Connection Panel */

export const ConnectionNameHelpLabel =
  'This is an optional friendly name for your data connection. You can add multiple datasets from the same data connection.';
export const DataSourceTypeHelpLabel =
  'Select the data source type that you would like to connect to.';
export const AuthenticationModeHelpLabel =
  'Select the authentication mode to be used to obtain permissions to access the data.';
export const AADApplicationHelpLabel =
  'This is the AAD Application Id that will be used to read the data.';

/* Help Labels for Edit Application Panel */

export const SelectSentenceHelpLabel =
  'Try expressing your expectation in a simple English sentence. Then, select the sentences that closely resembles it. Bolded Blue items correspond to parameter you will be asked to fill in the form below. If you can’t find a sentence template that seems to express your expectation, we can add more. ';
export const FixedSentenceHelpLabel =
  'The expectation sentence cannot be changed for an existing expectation.';
export const ClassifyResultHelpLabel =
  'It’s important to tell us what the quality bar is, you can do this by classifying your results into Pass, Sev-1, Sev-2, Sev-3, or Sev-4​';
export const ObserveHelpLabel =
  'If you are unsure what a good threshold should be, you can select “Observe only” and we will just report observations. You won’t receive any ICM tickets since alerting requires thresholds.​';
export const ThresholdHelpLabel =
  'If you have thresholds in mind, select the “Thresholds” option. Specify a range of values and how you would classify the results. Please note that any uncovered ranges are considered as “Pass”​';
export const SimpleAlertHelpLabel =
  'If you would like failures to be reported or alerts, select the "Simple Alerts" option. Specify the severity that a failure should trigger. IcMs will not be sent unless IcMs are set up in Notification Settings.​';
export const CustomHelpLabel =
  'This will give you the option define your own custom failure options and alerts.';

/* Help Labels for Result Details */

export const ExpectationDetailsHelpLabel =
  'This is the sentence that describes your expectation.​';
export const EvaluationDetailsHelpLabel =
  'These are the results from the latest evaluation of your expectation. You can also find what thresholds were use to classify the results of your expectation.';
export const HistoricalDetailsHelpLabel =
  'These are the current and previous results. You can click on any of these to get more information.​';
export const RawResultsHelpLabel =
  'These are the raw JSON results from the API.​';

/* Help Labels for Notification Settings */

export const GeneralHelpLabel =
  'Let us how often and when you would like us to check your expectations.​';
export const EmailReportsHelpLabel =
  'Use this section if you want email with summaries of your expectation and results at the end of every run';
export const ICMTicketsHelpLabel =
  'Use this section if you want ICM tickets to be files on expectations that were not met. You can control the severities for which the tickets are filed. If the same expectation fails in the next run, a new ICM ticket will be filed. You can control the ICM Team in Workspaces > Settings > Orchestration​';
