import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import { AppDispatch, RootState } from 'app/lensShellUtility';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import notifier from 'utils/notifier';
import grafanaOnboardingDataApi from './api/grafanaOnboardingApi';
import {
  GrafanaOnboardingDataStatus,
  GrafanaOnboardingData,
} from './models/grafanaOnboardingData';
import armClient from 'utils/armClient';

export function getId(item: GrafanaOnboardingData) {
  return item.subscriptionId + '_' + item.resourceGroupName + '_' + item.name;
}

/**
 * Custom hook to use grafana instances linked to Lens Workspace
 */

export const useLinkedGrafanaInstances = (workspaceId: string) => {
  const dispatch = useDispatch<AppDispatch>();
  const linkedGrafanaInstancesStatus = useSelector(
    selectLinkedGrafanaInstancesStatus
  );
  const linkedGrafanaInstancesWorkspaceId = useSelector(
    selectLinkedGrafanaInstancesWorkspaceId
  );
  const lensGrafanaInstanceList = useSelector(selectLinkedGrafanaInstanceList);

  const reload =
    linkedGrafanaInstancesStatus === GrafanaOnboardingDataStatus.None ||
    (linkedGrafanaInstancesStatus === GrafanaOnboardingDataStatus.Loaded &&
      linkedGrafanaInstancesWorkspaceId !== workspaceId);

  useEffect(() => {
    if (reload) {
      dispatch(loadLinkedGrafanaInstances({ workspaceId }));
    }
  }, [dispatch, reload, workspaceId]);

  if (reload) {
    return {
      lensGrafanaInstanceList: [],
      linkedGrafanaInstancesStatus: GrafanaOnboardingDataStatus.Loading,
    };
  }

  return { lensGrafanaInstanceList, linkedGrafanaInstancesStatus };
};

export const useUsersGrafanaInstances = () => {
  const dispatch = useDispatch<AppDispatch>();
  const usersGrafanaInstancesStatus = useSelector(
    selectUsersGrafanaInstancesStatus
  );
  const usersGrafanaInstancesList = useSelector(selectUsersGrafanaInstanceList);

  const reload =
    usersGrafanaInstancesStatus === GrafanaOnboardingDataStatus.None;

  useEffect(() => {
    if (reload) {
      dispatch(loadUsersGrafanaInstances());
    }
  }, [dispatch, reload]);

  if (reload) {
    return {
      usersGrafanaInstancesList: [],
      usersGrafanaInstancesStatus: GrafanaOnboardingDataStatus.Loading,
    };
  }

  return {
    usersGrafanaInstancesList,
    usersGrafanaInstancesStatus,
  };
};

export const loadLinkedGrafanaInstances = createAsyncThunk(
  'grafanaOnboarding/loadLinkedGrafanaInstances',
  async ({ workspaceId }: { workspaceId: string }) => {
    return await grafanaOnboardingDataApi.getLinkedGrafanaInstances(
      workspaceId
    );
  }
);

export const loadUsersGrafanaInstances = createAsyncThunk(
  'grafanaOnboarding/loadUsersGrafanaInstances',
  async (): Promise<GrafanaOnboardingData[]> => {
    const subscriptions = await armClient.fetchSubscriptionList();
    const result = await armClient.fetchGrafanaInstanceListForSubscriptions(
      subscriptions.map((s) => s.subscriptionId)
    );
    return result.map((grafanaInstance) => ({
      id: grafanaInstance.id,
      name: grafanaInstance.name,
      aadTenantId: grafanaInstance.tenantId,
      subscriptionId: grafanaInstance.subscriptionId,
      subscriptionName:
        subscriptions.find(
          (s) => s.subscriptionId === grafanaInstance.subscriptionId
        )?.displayName || '',
      resourceGroupName: grafanaInstance.resourceGroup,
      location: grafanaInstance.location,
      endpoint: grafanaInstance.properties.endpoint,
    }));
  }
);

export const saveGrafanaInstances = createAsyncThunk(
  'grafanaOnboarding/saveGrafanaInstances',
  async ({
    grafanaOnboardingDataList,
  }: {
    grafanaOnboardingDataList: GrafanaOnboardingData[];
  }) => {
    return await grafanaOnboardingDataApi.saveGrafanaInstances(
      grafanaOnboardingDataList
    );
  }
);

export const unlinkGrafanaInstances = createAsyncThunk(
  'grafanaOnboarding/unlinkGrafanaInstances',
  async ({
    grafanaOnboardingDataList,
  }: {
    grafanaOnboardingDataList: GrafanaOnboardingData[];
  }) => {
    return await grafanaOnboardingDataApi.unlinkGrafanaInstances(
      grafanaOnboardingDataList
    );
  }
);

const GrafanaInstancesListAdapter = createEntityAdapter<GrafanaOnboardingData>({
  selectId: (GrafanaOnboardingData) => getId(GrafanaOnboardingData),
});
const UsersGrafanaInstancesListAdapter =
  createEntityAdapter<GrafanaOnboardingData>({
    selectId: (AMGInstance) =>
      AMGInstance.subscriptionId +
      '_' +
      AMGInstance.resourceGroupName +
      '_' +
      AMGInstance.name,
  });
let initialGrafanaOnboardingData = {
  linkedGrafanaInstanceList: GrafanaInstancesListAdapter.getInitialState({
    linkedGrafanaInstanceStatus: GrafanaOnboardingDataStatus.None,
    linkedGrafanaInstanceWorkspaceId: '',
  }),
  usersGrafanaInstanceList: UsersGrafanaInstancesListAdapter.getInitialState({
    usersGrafanaInstanceStatus: GrafanaOnboardingDataStatus.None,
  }),
};
/**
 * Redux slice representing the currently linked grafana instances.
 */
const grafanaOnboardingDataSlice = createSlice({
  name: 'grafanaOnboardingData',
  initialState: initialGrafanaOnboardingData,
  reducers: {
    resetUsersGrafanaInstances: (state) => {
      state.usersGrafanaInstanceList =
        UsersGrafanaInstancesListAdapter.getInitialState({
          usersGrafanaInstanceStatus: GrafanaOnboardingDataStatus.None,
        });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadLinkedGrafanaInstances.pending, (state, action) => {
        state.linkedGrafanaInstanceList.linkedGrafanaInstanceStatus =
          GrafanaOnboardingDataStatus.Loading;
        state.linkedGrafanaInstanceList.linkedGrafanaInstanceWorkspaceId = '';
      })
      .addCase(loadLinkedGrafanaInstances.fulfilled, (state, action) => {
        state.linkedGrafanaInstanceList.linkedGrafanaInstanceStatus =
          GrafanaOnboardingDataStatus.Loaded;
        state.linkedGrafanaInstanceList.linkedGrafanaInstanceWorkspaceId =
          action.meta.arg.workspaceId;
        GrafanaInstancesListAdapter.setAll(
          state.linkedGrafanaInstanceList,
          action.payload
        );
      })
      .addCase(loadLinkedGrafanaInstances.rejected, (state, action) => {
        state.linkedGrafanaInstanceList.linkedGrafanaInstanceStatus =
          GrafanaOnboardingDataStatus.Error;
        notifier.error(action.error);
      })
      .addCase(loadUsersGrafanaInstances.pending, (state, action) => {
        state.usersGrafanaInstanceList.usersGrafanaInstanceStatus =
          GrafanaOnboardingDataStatus.Loading;
      })
      .addCase(loadUsersGrafanaInstances.fulfilled, (state, action) => {
        state.usersGrafanaInstanceList.usersGrafanaInstanceStatus =
          GrafanaOnboardingDataStatus.Loaded;
        UsersGrafanaInstancesListAdapter.setAll(
          state.usersGrafanaInstanceList,
          action.payload
        );
      })
      .addCase(loadUsersGrafanaInstances.rejected, (state, action) => {
        state.usersGrafanaInstanceList.usersGrafanaInstanceStatus =
          GrafanaOnboardingDataStatus.Error;
        notifier.error(action.error);
      })
      .addCase(saveGrafanaInstances.pending, (state, action) => {
        state.linkedGrafanaInstanceList.linkedGrafanaInstanceStatus =
          GrafanaOnboardingDataStatus.Loading;
      })
      .addCase(saveGrafanaInstances.fulfilled, (state, action) => {
        state.linkedGrafanaInstanceList.linkedGrafanaInstanceStatus =
          GrafanaOnboardingDataStatus.Loaded;
        GrafanaInstancesListAdapter.setAll(
          state.linkedGrafanaInstanceList,
          action.payload
        );
      })
      .addCase(saveGrafanaInstances.rejected, (state, action) => {
        notifier.error(action.error);
      });
  },
});

export const {
  selectAll: selectLinkedGrafanaInstanceList,
  selectEntities: selectLinkedGrafanaInstanceListEntities,
  selectById: selectLinkedGrafanaInstancesById,
  selectIds: selectLinkedGrafanaInstanceIds,
} = GrafanaInstancesListAdapter.getSelectors(
  (state: RootState) => state.grafanaOnboarding.linkedGrafanaInstanceList
);
export const selectLinkedGrafanaInstancesStatus = (state: RootState) =>
  state.grafanaOnboarding.linkedGrafanaInstanceList.linkedGrafanaInstanceStatus;

export const selectLinkedGrafanaInstancesWorkspaceId = (state: RootState) =>
  state.grafanaOnboarding.linkedGrafanaInstanceList
    .linkedGrafanaInstanceWorkspaceId;

export const {
  selectAll: selectUsersGrafanaInstanceList,
  selectEntities: selectUsersGrafanaInstanceListEntities,
  selectById: selectUsersGrafanaInstancesById,
  selectIds: selectUsersGrafanaInstanceIds,
} = UsersGrafanaInstancesListAdapter.getSelectors(
  (state: RootState) => state.grafanaOnboarding.usersGrafanaInstanceList
);
export const selectUsersGrafanaInstancesStatus = (state: RootState) =>
  state.grafanaOnboarding.usersGrafanaInstanceList.usersGrafanaInstanceStatus;

export const { resetUsersGrafanaInstances } =
  grafanaOnboardingDataSlice.actions;

export default grafanaOnboardingDataSlice.reducer;
