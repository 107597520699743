import { ElxChoiceGroup, ElxTextField } from '@elixir/components';
import { IChoiceGroupOption, Stack } from '@fluentui/react';
import { Activity } from 'features/orchestrator/models/job';
import { ProjectDataConnection } from 'features/workspaces/models/project';
import { useState } from 'react';
import { orchestratorConstants } from 'utils/constants';
import { LensLabel } from 'utils/lensLabel';
import { SelectDataConnection } from '../jobSchedule/selectDataConnection';
import { choiceGroupStyles } from './JobAuthorStyles';

const computeDataSourceTypes = [
  orchestratorConstants.DataSourceTypes.ADLA,
  orchestratorConstants.DataSourceTypes.SYNAPSESCOPE,
];

export interface JobEndpointAdlsGen2StreamProps {
  isSource: boolean;
  endpoint:any;
  activity: Activity;
  onChange: (
    activity: Activity, endpoint:any
  ) => void;
}

export const JobEndpointAdlsGen2Stream = (
  props: JobEndpointAdlsGen2StreamProps
) => {
  const { isSource, activity, onChange, endpoint } = props;

  const [processor, setProcessor] = useState(
    activity.processor || { connectionName: '', type: '' }
  );
  const endpnt = endpoint || (isSource ? activity.input! : activity.output!);
  const [isCosmosStream, setIsCosmosSream] = useState<boolean>(
    !!endpnt?.catalogEntity || false
  );

  const isAdla =
    processor.type ===
    orchestratorConstants.EndpointTypes.AzureDataLakeAnalytics;
  const isSynapseScope =
    processor.type === orchestratorConstants.EndpointTypes.SynapseScope;
  const showRuntimeVersion = isAdla || isSynapseScope;

  return (
    <>
      <ElxChoiceGroup
        onRenderLabel={() => (
          <LensLabel labelText={'Entity'} hintText={'Entity Type'}></LensLabel>
        )}
        required={true}
        onChange={(e, option?: IChoiceGroupOption) => {
          setIsCosmosSream(Boolean(Number(option?.key)));
        }}
        options={['Geneva Data', 'ADLS Gen2 Stream'].map((d, i) => ({
          key: i.toString(),
          text: d,
        }))}
        selectedKey={Number(isCosmosStream).toString()}
        styles={choiceGroupStyles}
      />
      {!isCosmosStream && (
        <Stack.Item>
          <ElxTextField
            id={'Geneva Entity Namespace'}
            onRenderLabel={() => {
              return (
                <LensLabel
                  labelText={'Synapse SCOPE Pool'}
                  hintText={
                    'The Geneva entity namespace. It is the DisplayName property value of the Data Studio Catalog dataset, for example Microsoft.Cloud.Tree.OrganizationMetadataV2.'
                  }
                  required={true}
                ></LensLabel>
              );
            }}
            value={endpnt.catalogEntity}
            onChange={(e, val) => {
              endpnt.catalogEntity = val;
              onChange(activity,endpnt);
            }}
          ></ElxTextField>
        </Stack.Item>
      )}
      {isCosmosStream && (
        <>
          <Stack.Item>
            <ElxTextField
              id={'ADLS Gen2 File System'}
              onRenderLabel={() => {
                return (
                  <LensLabel
                    labelText={'ADLS Gen2 File System'}
                    hintText={
                      'The ADLS Gen2 file system. This is the same as the Azure Storage Blob Container. This name may only contain lowercase letters, numbers, and hyphens, and must begin with a letter or a number. Each hyphen must be preceded and followed by a non-hyphen character. The name must also be between 3 and 63 characters long.'
                    }
                    required={true}
                  ></LensLabel>
                );
              }}
              value={endpnt.fileSystem}
              onChange={(e, val) => {
                endpnt.fileSystem = val;
                onChange(activity,endpnt);
              }}
            ></ElxTextField>
          </Stack.Item>
          <Stack.Item>
            <ElxTextField
              id={'Stream Name / Folder'}
              onRenderLabel={() => {
                return (
                  <LensLabel
                    labelText={'Stream Name / Folder'}
                    hintText={`The folder or file name (optional), including full path. Should be in the form "<path>/<file_name>";.`}
                    required={true}
                  ></LensLabel>
                );
              }}
              value={endpnt.path}
              onChange={(e, val) => {
                endpnt.path = val;
                onChange(activity,endpnt);
              }}
            ></ElxTextField>
          </Stack.Item>{' '}
          <Stack.Item>
            <ElxTextField
              id={'Pattern'}
              onRenderLabel={() => {
                return (
                  <LensLabel
                    labelText={'Pattern'}
                    hintText={
                      'The stream pattern, for example %Y/%m/Data_%Y_%m_%d.ss.'
                    }
                  ></LensLabel>
                );
              }}
              value={endpnt.pattern}
              onChange={(e, val) => {
                endpnt.pattern = val;
                onChange(activity,endpnt);
              }}
            ></ElxTextField>
          </Stack.Item>
        </>
      )}
      {isSource && (
        <>
          <LensLabel
            labelText={'Compute'}
            hintText={
              'The connection which will provide CPU power for processing this activity.'
            }
          ></LensLabel>
          <Stack tokens={{ childrenGap: 5, padding: 16 }}>
            <Stack.Item>
              <SelectDataConnection
                onSelect={(dc: ProjectDataConnection) => {
                  const p = {
                    ...processor,
                    connectionName: dc.connectionName,
                    type: dc.type,
                  };
                  setProcessor(p);
                  activity.processor = p;
                  onChange(activity,endpnt);
                }}
                filter={computeDataSourceTypes}
                selectedKey={processor.connectionName}
              ></SelectDataConnection>
            </Stack.Item>
            {isSynapseScope && (
              <Stack.Item>
                <ElxTextField
                  id={'Synapse SCOPE Pool'}
                  onRenderLabel={() => {
                    return (
                      <LensLabel
                        labelText={'Synapse SCOPE Pool'}
                        hintText={'The name of the Azure Synapse SCOPE pool.'}
                        required={true}
                      ></LensLabel>
                    );
                  }}
                  value={processor.scopePool}
                  onChange={(e, val) => {
                    const p = { ...processor, scopePool: val || '' };
                    setProcessor(p);
                    onChange({ ...activity, processor: p },endpnt);
                  }}
                ></ElxTextField>
              </Stack.Item>
            )}
            {showRuntimeVersion && (
              <Stack.Item>
                <ElxTextField
                  id={'Runtime Version'}
                  onRenderLabel={() => {
                    return (
                      <LensLabel
                        labelText={'Runtime Version'}
                        hintText={
                          isSynapseScope
                            ? 'The Azure Synapse SCOPE runtime version.'
                            : 'The Azure Data Lake Analytics runtime version.'
                        }
                      ></LensLabel>
                    );
                  }}
                  value={activity.runtimeVersion}
                  onChange={(e, val) => {
                    onChange({ ...activity, runtimeVersion: val },endpnt);
                  }}
                ></ElxTextField>
              </Stack.Item>
            )}
          </Stack>
        </>
      )}
    </>
  );
};
