import { ProjectDataConnection } from 'features/workspaces/models/project';
import { Workspace } from 'features/workspaces/models/workspace';
import moment from 'moment';
import { Job, JobInstance } from '../models/job';

let leApp: any; // AngularJS dependency - minimize use

export function inject(_leApp: any) {
  leApp = _leApp;
}

export const createJob = (job: Job | undefined = undefined) => {
  leApp?.createJob(job);
};

// Returns a time in human readable format.
export const getTimeLabel = (time: string | Date | null) => {
  if (!time) return time;
  try {
    let m = moment(time).utc();
    return (
      m.format('l') + // m/d/yyyy
      ' ' +
      m.format('LTS')
    ); // h:m:s AM
  } catch (e) {
    // Ignore
  }
  return time;
};

// Returns a relative time in human readable format such as 10 minutes 4 seconds
export const getRelativeTime = (ms: number) => {
  if (ms <= 0) {
    return '0 seconds';
  }

  let SECOND_MS = 1000;
  let MINUTE_MS = 60 * SECOND_MS;
  let HOUR_MS = 60 * MINUTE_MS;
  let DAY_MS = 24 * HOUR_MS;
  let WEEK_MS = 7 * DAY_MS;
  let MONTH_MS = 30 * DAY_MS;
  let lookup = [
    'month',
    'week',
    'day',
    'hour',
    'minute',
    'second',
    'millisecond',
  ];
  let values = [];
  values.push(ms / MONTH_MS);
  ms %= MONTH_MS;
  values.push(ms / WEEK_MS);
  ms %= WEEK_MS;
  values.push(ms / DAY_MS);
  ms %= DAY_MS;
  values.push(ms / HOUR_MS);
  ms %= HOUR_MS;
  values.push(ms / MINUTE_MS);
  ms %= MINUTE_MS;
  values.push(ms / SECOND_MS);
  ms %= SECOND_MS;
  values.push(ms);
  let output = [];
  let pretty = '';
  let firstOccurance = true;
  for (let i = 0; i < values.length; i++) {
    let val = Math.floor(values[i]);
    if (val <= 0 && firstOccurance) continue;
    firstOccurance = false;
    if (output.length === 2 || (val === 0 && output.length === 1)) {
      break;
    }
    pretty = val + ' ' + lookup[i];
    if (val > 1) {
      pretty += 's';
    }
    output.push(pretty);
  }
  return output.join(' and ');
};

// Returns relative queue time for a job instance
export const getRelativeQueueTime = (jobInstance: JobInstance) => {
  try {
    return getRelativeTime(
      new Date(jobInstance.startTime).valueOf() -
        new Date(jobInstance.createdDate).valueOf()
    );
  } catch (e) {
    // Ignore
  }
  return '';
};

export const getRelativeExecutionTime = (jobInstance: JobInstance) => {
  try {
    return getRelativeTime(
      new Date(jobInstance.endTime).valueOf() -
        new Date(jobInstance.startTime).valueOf()
    );
  } catch (e) {
    // Ignore
  }
  return '';
};

export function* uniqNumber() {
  let i = 0;
  for (;;) {
    yield ++i;
  }
}

export function getDataSource(
  id: string | undefined,
  workspace: Workspace
): ProjectDataConnection | null {
  return (
    (id && workspace?.dataConnections?.find((x) => x.connectionName === id)) ||
    null
  );
}
