import {
  ISearchBoxStyles,
  IStackTokens,
  IStackStyles,
  ILabelStyles,
  getFocusStyle,
  getTheme,
  mergeStyleSets,
  mergeStyles,
  IStackItemStyles,
  ITheme,
  IPivotStyles,
} from '@fluentui/react';
import { CSSProperties } from 'react';

const theme: ITheme = getTheme();
const { palette, semanticColors, fonts } = theme;

export const AccessIcon = mergeStyles({
  padding: 0,
  color: palette.neutralTertiary,
  fontSize: '10px',
});

export const AccessIcon1 = mergeStyles({
  paddingRight: '3px',
  fontSize: '10px',
  color: '#000000',
});

export const AccessLabel = mergeStyles({
  padding: 0,
  color: palette.neutralTertiary,
  fontSize: '10px',
});

export const itemAlignmentsStackTokens: IStackTokens = {
  childrenGap: '0 5',
};

export const searchStyles: ISearchBoxStyles = {
  root: {
    display: 'inline-flex',
    verticalAlign: 'middle',
    marginLeft: '10px',
  },
};

export const labelStyles1: Partial<ILabelStyles> = {
  root: { marginTop: 10, fontSize: '13px' },
};
export const labelStyles2: Partial<ILabelStyles> = {
  root: { marginLeft: 0, fontSize: '13px' },
};
export const labelStyles3: Partial<ILabelStyles> = {
  root: {
    padding: 0,
    marginTop: 0,
    marginLeft: 10,
    marginBottom: 5,
    fontSize: '13px',
  },
};
export const labelStyles4: Partial<ILabelStyles> = {
  root: { marginTop: 0, fontSize: '13px' },
};

export const stackStyles: Partial<IStackStyles> = {
  root: {
    height: 40,
  },
};

export const tagStyles: ILabelStyles = {
  root: {
    width: '100%',
    fontFamily: 'Segoe UI',
    fontSize: '10px',
    backgroundColor: '#EDEBE9',
    border: 'none',
    borderRadius: '10px',
    textAlign: 'center',
  },
};

export const tagStyles1: ILabelStyles = {
  root: {
    fontFamily: 'Segoe UI',
    width: '90px',
    fontSize: '10px',
    backgroundColor: '#EDEBE9',
    border: 'none',
    borderRadius: '10px',
    textAlign: 'center',
  },
};

export const stackItemStyles: IStackItemStyles = {
  root: {
    width: 490,
  },
};

// some of the parent stack Items need 'overflow-y: hidden' so that flex layout
// calculation doesn't try to make the very top parent scrollable.  We want our
// scrolling on the innermost workspace list container
export const stackItemHiddenOverflow: IStackItemStyles = {
  root: {
    overflowY: 'hidden',
  },
};

// These 'scrollablePivot' styles allow us to have a scrollable item inside the
// pivotItem, and we can scroll without losing the pivot tabs off the top.
export const stackItemWithScrollablePivotStyles: IStackItemStyles =
  mergeStyleSets(stackItemHiddenOverflow, {
    root: {
      'div[role=toolbar]': { height: '100%' },
    },
  });

export const scrollablePivotStyles: Partial<IPivotStyles> = {
  root: { height: '44px', overflowY: 'visible' },
  itemContainer: { height: 'calc(100% - 44px)' },
};

// pivot item doesn't have styles property, use CSSProperties
export const scrollablePivotItemStyle: CSSProperties = {
  overflowY: 'auto',
  height: '700px',
};

// export const classNames = mergeStyleSets({
//   itemCell: [
//     getFocusStyle(theme, { inset: -1 }),
//     {
//       height: 50,
//       padding: 0,
//       marginLeft: 10,
//       boxSizing: 'border-box',
//       borderBottom: `1px solid ${semanticColors.bodyDivider}`,
//       display: 'flex',
//     },
//   ],
//   favorite: {
//     alignSelf: 'right',
//     marginRight: 10,
//     color: '#FFB900',
//     fontSize: fonts.large.fontSize,
//     flexShrink: 0,
//   },
//   favoriteStar: {
//     align: 'right',
//   },
//   tableStyles: {
//     borderBottom: `1px solid ${semanticColors.bodyDivider}`,
//   },
//   pillFilter: {
//     borderRadius: '10px',
//   },
// });
export const classNames = mergeStyleSets({
  itemCell: [
    getFocusStyle(theme, { inset: -1 }),
    {
      height: 'auto',
      paddingLeft: 60,
      // marginLeft: 10, //using 60 to account for the -50 from the group styles of the GroupList
      boxSizing: 'border-box',
      borderBottom: `1px solid ${semanticColors.bodyDivider}`,
      display: 'flex',
    },
  ],
  itemCellSelected: [
    getFocusStyle(theme, { inset: -1 }),
    {
      height: 'auto',
      paddingLeft: 60,
      // marginLeft: 10, //using 60 to account for the -50 from the group styles of the GroupList
      boxSizing: 'border-box',
      borderBottom: `1px solid ${semanticColors.bodyDivider}`,
      display: 'flex',
      background: '#EDEBE9',
    },
  ],
  favorite: {
    alignSelf: 'right',
    marginRight: 10,
    color: '#FFB900',
    fontSize: fonts.large.fontSize,
    flexShrink: 0,
  },
  favoriteStar: {
    align: 'right',
  },
  tableStyles: {
    borderBottom: `1px solid ${semanticColors.bodyDivider}`,
  },
  hiddenColumnStyles: {
    display: 'none !important',
  },
  pillFilter: {
    borderRadius: '10px',
  },
});
