import { _ } from 'utils/sharedLibs';
import { SortCollection } from 'utils/sortCollection.js';
import { $laConstants } from 'utils/constants'

const hexValues = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "A", "B", "C", "D", "E", "F"];
const sort_collection = new SortCollection();

/**
 * Generate all combination of arguments when given arrays or strings
 * e.g. [['Ben','Jade','Darren'],['Smith','Miller']] to [['Ben','Smith'],[..]]
 * e.g. 'the','cat' to [['t', 'c'],['t', 'a'], ...]
 */
function _cartesianProductOf(args) {
    if (arguments.length>1) {
        args=_.toArray(arguments);
    }

    // strings to arrays of letters
    args =_.map(args, function(opt){
        return typeof opt==='string'? _.toArray(opt):opt;
    });

    return _.reduce(args, function(a, b) {
        return _.flatten(_.map(a, function(x) {
            return _.map(b, function(y) {
                return _.concat(x,[y]);
            });
        }), true);
    }, [ [] ]);
}

/** Generate all combination of arguments from objects
 *  {Object} opts    - An object or arrays with keys describing options  {firstName:['Ben','Jade','Darren'],lastName:['Smith','Miller']}
 *  {Array}        - An array of objects e.g. [{firstName:'Ben',LastName:'Smith'},{..]
 */
function _cartesianProductObj(optObj){
    var keys = _.keys(optObj);
    var opts = _.values(optObj);
    var combs = _cartesianProductOf(opts);
    return _.map(combs, function(comb){
        return _.zipObject(keys,comb);
    });
}

// utility for loadImageForImageUrlFormattingOption, which is commented out because of jQuery dependency
// /**
//  * @name calculateAspectRatio
//  * @methodOf Utilities
//  * @description
//  * Calculate aspect ratio for an image
//  * @param {Integer} imgWidth - the width of image
//  * @param {Integer} imgHeight - the height of image
//  * @param {Integer} newSize - the new size of image which can be width or height
//  * @param {Boolean} takeWidth - it will tell weather to calculate ratio base on width or height of an image
//  */
//  function calculateAspectRatio(imgWidth, imgHeight, newSize, takeWidth) {
//     if (imgHeight > 0) {
//         var aspectRatio = imgWidth / imgHeight;
//         if (takeWidth) {
//             return Math.floor(newSize / aspectRatio)
//         } else {
//             return Math.floor(newSize * aspectRatio);
//         }
//     }
//     return 0;
// }

/**
 * @name utilities
 * @description
 * A set of miscellaneous utility functions
 * 
 * This file has become a sink for all utility functions. We should break them up.
 */
export class Utilities {

    /**
     * @name newGuid
     * @description
     * Returns a GUID such as xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx.
     * @returns {string} - New GUID.
     */
    newGuid () {
        // c.f. rfc4122 (UUID version 4 = xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx)
        var oct = "";
        var tmp = 0;
        for (var a = 0; a < 4; a++) {
            tmp = (4294967296 * Math.random()) | 0;
            oct += hexValues[tmp & 0xF]
            + hexValues[(tmp >> 4) & 0xF]
            + hexValues[(tmp >> 8) & 0xF]
            + hexValues[(tmp >> 12) & 0xF]
            + hexValues[(tmp >> 16) & 0xF]
            + hexValues[(tmp >> 20) & 0xF]
            + hexValues[(tmp >> 24) & 0xF]
            + hexValues[(tmp >> 28) & 0xF];
        }

        // "Set the two most significant bits (bits 6 and 7) of the clock_seq_hi_and_reserved to zero and one, respectively"
        var clockSequenceHi = hexValues[8 + (Math.random() * 4) | 0];
        var guid = oct.substr(0, 8) + "-" + oct.substr(9, 4) + "-4" + oct.substr(13, 3) + "-" + clockSequenceHi + oct.substr(16, 3) + "-" + oct.substr(19, 12);
        return guid.toLowerCase();
    };

    /**
     * @name SortChildren
     * @description
     * Returns sorted array of Kusto response slices.
     * @returns {array} - Returns an array of objects.
     */
    SortChildren (children, sortOrder, params) {
        if (sortOrder.val === $laConstants.TermAscending || sortOrder.val === $laConstants.TermDescending) {
            children = children.sort(sort_collection.stringSort(sortOrder.val, params.field.type, 'name'));
        } else if (sortOrder.val === $laConstants.OrderValDesc) {
            children = children.sort(function (a, b) { return (a.size < b.size) ? 1 : ((b.size < a.size) ? -1 : 0); });
        } else if (sortOrder.val === $laConstants.ConditonOrder) {
            children = children.sort(sort_collection.conditonSort(params.conditions));
        } else {
            children = children.sort(function (a, b) { return (a.size > b.size) ? 1 : ((b.size > a.size) ? -1 : 0); });
        }

        if (params.showOtherResults && params.moveOtherToEnd) {
            var otherValuesResult = children.filter(function (child) { return child.name === $laConstants.OtherValuesLabel });
            children = children.filter(function (child) { return child.name !== $laConstants.OtherValuesLabel });
            if (otherValuesResult.length > 0) {
                children.push(otherValuesResult[0]);
            }
        }

        return children;
    }

    /**
     * @name getJSONPaths
     * @description
     * Returns all the JSONPaths from the JSON.
     * @returns {string} - List of paths.
     */
    getJSONPaths (o, root, result) {
        root = root || "";
        result = result || [];
        if (!o || typeof o !== "object") {
            if (root && root !== "") {
                result.push('$' + root);
            }
            return;
        }
        var ok = Object.keys(o);
        _.forEach(ok, function (key) {
            this.getJSONPaths(o[key], root + '.' + key, result);
        });
        return result;
    };

    /**
     * @name getTooltip
     * @description
     * Returns a tooltip for aggregation type.
     * @returns {string} - Tooltip help.
     */
    getTooltip (agg, typeSchema) {
        var schemaName = typeSchema ? agg.name : agg.schema.name;
        if (schemaName === $laConstants.Vizceral.totalAggName) {
            return $laConstants.Vizceral.totalAggTooltip;
        }
        else if (schemaName === $laConstants.Vizceral.successAggName) {
            return $laConstants.Vizceral.successAggTooltip;
        }
        else if (schemaName === $laConstants.Vizceral.failureAggName) {
            return $laConstants.Vizceral.failureAggTooltip;
        }
        else if (schemaName === $laConstants.Vizceral.warningAggName) {
            return $laConstants.Vizceral.warningAggTooltip;
        }
        else if (schemaName === $laConstants.Vizceral.topLevelAggName) {
            return $laConstants.Vizceral.topLevelAggTooltip;
        }
        else if (schemaName === $laConstants.Vizceral.subLevelAggName) {
            return $laConstants.Vizceral.subLevelAggTooltip;
        }
        else if (schemaName === $laConstants.Funnel.entityAggName) {
            return $laConstants.Funnel.entityAggTooltip;
        }
        else if (schemaName === $laConstants.Funnel.stageAggName) {
            return $laConstants.Funnel.stageAggTooltip;
        }
    };

    /**
     * @name hexToRgba
     * @description
     * Returns a rgba color using hex or rgb format and opacity.
     * @param {String} hex - hex or rgb format of color.
     * @param {Number} opacity - opacity of color.
     * @returns {string} - Rgba color.
     */
    hexToRgba (hex, opacity) {              //ToDo: See if it can be merged with spectrum.js
        if (hex.indexOf('rgb(') === 0) {
            return 'rgba(' + hex.substring(4, hex.length - 1) + ',' + opacity + ')';
        }
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? 'rgba(' + parseInt(result[1], 16) + ',' + parseInt(result[2], 16) + ',' + parseInt(result[3], 16) + ',' + opacity + ')' : null;
    };

    /**
     * @name getSessionId
     * @description
     * Returns SessionId which has been set for the current user session.
     * @returns {string} - New GUID.
     */
    getSessionId () {
        if (!window.LENS_SESSION_ID) {
            window.LENS_SESSION_ID = this.newGuid();
        }
        return window.LENS_SESSION_ID;
    };

    /**
    * @name newExceptionId
    * @description
    * Returns a new ExceptionId 
    * @returns {string} - New GUID.
    */
    newExceptionId () {
        window.LENS_EXCEPTION_ID = this.newGuid();
        return window.LENS_EXCEPTION_ID;
    };

    /**
     * @name escapeName
     * @description
     * Test whether we need to escape the name in Kusto query
     * @param {string} name - Escapes the name of a string into a valid Kusto string
     * @returns {string} - Valid escaped Kusto string
     */
    escapeName (name, alwaysEscape) {
        if (_.startsWith(name, '[@') || name === "*") { // Field already escaped. No escaping required for '*' operand
            return name;
        } 

        var pattern = /[^_a-zA-Z0-9]/;
        var kustoKeywordList = ['and', 'anomalychart', 'areachart', 'asc', 'barchart', 'between', 'bool', 'boolean', 'by',
            'columnchart', 'consume', 'contains', 'containscs', 'count', 'date', 'datetime', 'default', 'desc', 'distinct',
            'double', 'dynamic', 'endswith', 'evaluate', 'extend', 'false', 'filter', 'find', 'first', 'flags', 'float',
            'getschema', 'has', 'hasprefix', 'hassuffix', 'in', 'int', 'join', 'journal', 'kind', 'ladderchart', 'last',
            'like', 'limit', 'linechart', 'long', 'materialize', 'mvexpand', 'notcontains', 'notlike', 'of', 'or', 'order',
            'parse', 'piechart', 'pivotchart', 'print', 'project', 'queries', 'real', 'regex', 'sample', 'scatterchart',
            'search', 'set', 'sort', 'stacked', 'stacked100', 'stackedareachart', 'startswith', 'string', 'summarize',
            'take', 'time', 'timechart', 'timeline', 'timepivot', 'timespan', 'to', 'top', 'toscalar', 'true', 'union', 
            'unstacked', 'viewers', 'where', 'withsource']; // add more keywords here

        var result = pattern.test(name) || kustoKeywordList.indexOf(name) > -1 || alwaysEscape;
        if (result) {
            if (name.indexOf('"') > -1) {
                result = "[@'" + name + "']";
            }
            else {
                result = "[@\"" + name + "\"]";
            }
            return result;
        }
        else {
            return name;
        }
    };

    /**
     * @name sizeof
     * @description
     * Returns the total size of the object in bytes
     * @param {Object} object - the object whose size is to be calculated
     * @returns {number} - The total amount of bytes the object is
     */
    sizeof (object) {
        var objectList = [];
        var recurse = function (value) {
            if (typeof value === 'boolean') {
                return 4;
            }
            else if (typeof value === 'string') {
                return value.length * 2;
            }
            else if (typeof value === 'number') {
                return 8;
            }
            else if (typeof value === 'object' && objectList.indexOf(value) === -1) {
                var bytes = 0;
                objectList[objectList.length] = value;
                for (let property in value) {
                    if (value.hasOwnProperty(property)) {
                        bytes += 8; // an assumed existence overhead
                        bytes += recurse(value[property]);
                    }
                }

                return bytes;
            }

            return 0;
        };

        return recurse(object);
    };

    // remove moment dependency for now..., moment.js is deprecated.
    // /**
    //  * @name convertToUtcAndFormat
    //  * @methodOf Utilities
    //  * @description
    //  * Converts the date value to utc and formats it to the desired format.
    //  * @param {string|number} dateVal - The value that represents the date to transform.
    //  * @param {string} format - The desired date format.
    //  * @returns {string} - The dateVal after conversion to UTC and applying the new format.
    //  */
    // convertToUtcAndFormat (dateVal, format) {
    //     var date = new Date(dateVal);

    //     if (date === "Invalid Date") {
    //         dateVal = moment(dateVal);
    //     }

    //     var validDate = new Date(dateVal);
    //     var utcVal = validDate.toUTCString();
    //     var utcMoment = moment.utc(utcVal, "ddd, DD MMM YYYY HH:mm:ss Z");

    //     // moment.utc doesn't update milliseconds -> fall-back to Date.getMilliseconds
    //     utcMoment.milliseconds = function () {
    //         return validDate.getMilliseconds() || (dateVal.getMilliseconds && dateVal.getMilliseconds()) || 0;
    //     }

    //     if (!utcMoment.isValid()) {
    //         var err = errors.InvalidDate();

    //         var message = "Failed to convert date to UTC";
    //         if (Object.prototype.toString.call(err) !== "[object Error]") {
    //             var error = new Error(message);
    //             error.caller = "convertToUtcAndFormat";
    //             notify.error(err);
    //         } else {
    //             err.message = message + err.message;
    //             err.caller = "convertToUtcAndFormat";
    //             notify.error(err);
    //         }
    //     }

    //     return utcMoment.format(format);
    // };

    /**
     * @name convertToSortText
     * @description
     * Converts the given number (that represnts an order) to a string, that keeps that order when sorting.
     * 1 -> "a", 26 -> "z", 27 -> "za", 28 -> "zb", 52 -> "zz", 53 ->"zza"
     * @param {Number} order - The number to be converted to a sorting-string. order should start at 1.
     * @returns {String} - a string repsenting the order.
    */
    convertToSortText (order) {
        var sortText = "";
        var numCharacters = 26; // "z" - "a" + 1;

        var div = Math.floor(order / numCharacters);
        for (var i = 0; i < div; ++i) {
            sortText += "z";
        }

        var reminder = order % numCharacters;
        if (reminder > 0) {
            sortText += String.fromCharCode(96 + reminder);
        }

        return sortText;
    };

    /**
     * @name guessLineEndings
     * @description
     * Taken from PapaParse code: https://github.com/mholt/PapaParse/blob/master/papaparse.js
     * @param {String} text - input string.
     * @returns {String} - returns the line ending.
    */
    guessLineEndings (input) {
        input = input.substr(0, 1024 * 1024);    // max length 1 MB
        var r = input.split('\r');
        var n = input.split('\n');
        var nAppearsFirst = (n.length > 1 && n[0].length < r[0].length);

        if (r.length === 1 || nAppearsFirst) {
            return '\n';
        }

        var numWithN = 0;

        for (var i = 0; i < r.length; i++) {
            if (r[i][0] === '\n')
                numWithN++;
        }

        return numWithN >= r.length / 2 ? '\r\n' : '\r';
    };

    /**
     * @name toDate
     * @description
     * Converts a value to a date.
     * @param {*} value - The value to convert.
     * @returns {Date} - The date.
     */
    toDate (value) {
        if (_.isNil(value) || _.isDate(value)) return value;

        try {
            return new Date(value);
        } catch (e) {
            return undefined;
        }
    };

    /**
     * @name toJson
     * @description
     * Returns a json object or undefined if the text is not a valid Json
     * @param {string} text - The JSON text to deserialize into an object
     * @returns {Object} - The parsed deserialized object
     */
    toJson (text) {
        try {
            return JSON.parse(text);
        } catch (e) {
            return undefined;
        }
    };

    /**
     * @name camelCase
     * @description
     * Converts object property names to camelCase by lower-casing the first letter.
     * Intended to convert PascalCase to camelCase.
     * @param {Object} value - The object to convert.
     * @returns {Object} A converted copy of the object.
     */
    camelCase (value) {
        if (_.isArray(value)) {
            return _.map(value, this.camelCase);
        }

        if (!_.isObject(value)) {
            return value;
        }

        // upcase the first letter of all property names
        value = _.mapKeys(value, function (value, key) {
            return key.charAt(0).toLowerCase() + key.slice(1);
        });

        value = _.mapValues(value, this.camelCase);

        return value;
    };

    /**
     * @name pascalCase
     * @description
     * Copies an object to a "pascalCase" version of that object 
     * where all properties start with upper-case letters
     * @param {Object} value - The object to copy into pascalCase format
     * @returns {Object} A pascalCase copy of the object
     */
    pascalCase (value) {
        if (_.isArray(value)) {
            return _.map(value, this.pascalCase);
        }

        if (_.isObject(value)) {
            // upcase the First letter of all properties
            value = _.mapKeys(value, function (value, key) {
                return key.charAt(0).toUpperCase() + key.slice(1);
            });
            value = _.mapValues(value, this.pascalCase);
            return value;
        }

        return value;
    };

    /*
    * Enum for possible query's error level
    */
    queryErrorLevel = {
        Info: "info",
        Warning: "warning",
        Error: "error"
    };

    /**
     * @name createQueryNotification
     * @description
     * Create a Query notification object, contains: header, message, log-level, help menu source (if required), brief message (used in dashboard panel) and a flag indicating whether a pop-up link should be displayed (used in dashboard panel)
     * @returns {Object} - Contains statusText, statusDetails, level and source properties
     */
    createQueryNotification (title, content, level, helpMenuSource, briefMessage, showDetailsDialog) {
        return { "statusText": title, "statusDetails": content, "level": level, "helpMenuSource": helpMenuSource, "briefMessage": briefMessage || '', "showDetailsDialog": !!showDetailsDialog };
    };

    /**
     * @name getBasicUrl
     * @description
     * Returns the basic url origin + path
     * @returns {string} - the basic url for the current resource
     */
    getBasicUrl () {
        return window.location.origin + window.location.pathname;
    };

    /**
     * @name fillArray
     * @description
     * Creates a new array that is filled with the desired value.
     * @param {number} length - new array length
     * @param {Object} valueToFill - the value that will be in each of the array's elements.
     * @returns {Array} - new array with the length of 'length' that is filled with 'valueToFill'
     */
    fillArray (length, valueToFill) {
        var arr = [];

        for (var i = 0; i < length; i++) {
            arr[i] = valueToFill;
        }

        return arr;
    };

    /**
     * @name removeExceptionFromRows
     * @description
     * Check if there is an row containing only the "Exceptions" field at the end of the array. If yes, remove that row
     */
    removeExceptionFromRows (rows) {
        var lastEntry = null;
        var hasException = false;

        if (rows.length > 0) {
            lastEntry = rows[rows.length - 1];

            if (lastEntry && lastEntry.Exceptions) {
                hasException = true;
                // Remove last element
                rows.splice(-1, 1);
            }
        }

        return hasException;
    };

    /**
     * @name browserName
     * @description
     * returns current browser's name
     * @returns {string} - browser name
     */
    browserName (){
        return window.BROWSER_NAME;
    }

    // ignore for now, depends on jQuery
    // /**
    //  * @name loadImageForImageUrlFormattingOption
    //  * @methodOf Utilities
    //  * @description
    //  * Calculate image's size while it is loading and assign it accordingly to the ratio and the size of its container
    //  * @param {Object} img - the DOM element of an image
    //  * @param {Boolean} format - the ratio is to resize image by maintaining its ratio or not
    //  * @param {Object} cellSize - the object which contains the width and height of the image's container
    //  */
    // loadImageForImageUrlFormattingOption (img, maintainRatio, cellSize) {
    //     if (maintainRatio) {
    //         $(img).one('load', function () {
    //             var imgWidth = 0;
    //             var imgHeight = 0;
    //             var tmpImg = $('#' + img.id)[0];
    //             if (tmpImg) {
    //                 var cellElement = $(tmpImg).parent();
    //                 if (img.width > cellSize.width && img.height > cellSize.height) {
    //                     if (cellSize.width < cellSize.height) {
    //                         imgHeight = calculateAspectRatio(img.width, img.height, cellSize.width, true);
    //                         tmpImg.width = cellSize.width;
    //                         tmpImg.height = imgHeight;
    //                     } else {
    //                         imgWidth = calculateAspectRatio(img.width, img.height, cellSize.height, false);
    //                         tmpImg.width = imgWidth;
    //                         tmpImg.height = cellSize.height;
    //                     }
    //                 } else if (img.width < cellSize.width && img.height > cellSize.height) {
    //                     imgWidth = calculateAspectRatio(img.width, img.height, cellSize.height, false);
    //                     tmpImg.width = imgWidth;
    //                     tmpImg.height = cellSize.height;
    //                 } else if (img.height < cellSize.height && img.width > cellSize.width) {
    //                     imgHeight = calculateAspectRatio(img.width, img.height, cellSize.width, true);
    //                     tmpImg.width = cellSize.width;
    //                     tmpImg.height = imgHeight;
    //                 }

    //                 cellElement.width(cellSize.width);
    //                 cellElement.height(cellSize.height);
    //                 cellElement.addClass('image_element')
    //             }
    //         });
    //     } else {
    //         $(img).one('load', function () {
    //             var tmpImg = $('#' + img.id)[0];
    //             if (tmpImg) {
    //                 var cellElement = $(tmpImg).parent();
    //                 tmpImg.width = cellSize.width;
    //                 tmpImg.height = cellSize.height;
    //                 cellElement.width(cellSize.width);
    //                 cellElement.height(cellSize.height);
    //                 cellElement.addClass('image_element')
    //             }
    //         });
    //     }
    // };

    /**
     * @name addObservableProperty
     * @description
     * Creates an observable property on an object that inhertis the Events service.
     * @param {Object} obj - Object to add observable property to.
     * @param {String} observablePropertyName - String indicating the property to add to the specified function.
     * @returns {String} Event name that is raised whenever the value is updated.
     */
    addObservableProperty (obj, observablePropertyName) {
        var eventName = undefined;

        if (_.isObject(obj)) {
            eventName = observablePropertyName + 'Changed';

            var realPropertyName = '__' + observablePropertyName;

            obj[realPropertyName] = undefined;

            Object.defineProperty(obj, observablePropertyName, {
                get: function () {
                    return this[realPropertyName];
                },
                set: function (value) {

                    if (value !== this[realPropertyName]) {
                        this[realPropertyName] = value;

                        // Notify on property change
                        this.emit(eventName, value);
                    }
                }
            });
        }

        return eventName;
    };

    /**
     * @name product
     * @param {Array} opts - List of elements to perform the product on. If the array has nested arrays, it will flatten them out.
     * @description
     * Generate the cartesian product of input objects, arrays, or strings
     * 
     * product('me','hi')
     * // => [["m","h"],["m","i"],["e","h"],["e","i"]]
     *
     * product([1,2,3],['a','b','c']
     * // => [[1,"a"],[1,"b"],[1,"c"],[2,"a"],[2,"b"],[2,"c"],[3,"a"],[3,"b"],[3,"c"]]
     *
     * product({who:['me','you'],say:['hi','by']})
     * // => [{"who":"me","say":"hi"},{"who":"me","say":"by"},{"who":"you","say":"hi"},{"who":"you","say":"by"}]
     *
     * // It also takes in a single array of args
     * product(['me','hi'])
     * // => [["m","h"],["m","i"],["e","h"],["e","i"]]
     * @returns {Array} Product of the elements contained in opts
     */
    product (opts){
        if (arguments.length===1 && !_.isArray(opts))
            return _cartesianProductObj(opts)
        else if (arguments.length===1)
            return _cartesianProductOf(opts);
        else
            return _cartesianProductOf(arguments);
    };
    
    /**
     * @name permutations
     * @param {Array} obj - List that contains the elements that will be perumutated.
     * @param {number} n - Number of items to select per permutation.
     * @description
     * Generate the cartesian product of input objects, arrays, or strings
     * Generate permutations, in all possible orderings, with no repeat values
     *
     *
     * permutations([1,2,3],2)
     * // => [[1,2],[1,3],[2,1],[2,3],[3,1],[3,2]
     *
     * permutations('cat',2)
     * // => [["c","a"],["c","t"],["a","c"],["a","t"],["t","c"],["t","a"]]
     */
    permutations (obj, n){
        if (typeof obj=='string') {
            obj = _.toArray(obj)
        }
        
        n = n || obj.length;

        // make n copies of keys/indices
        for (var j = 0, nInds=[]; j < n; j++) {
            nInds.push(_.keys(obj));
        }
        // get product of the indices, then filter to remove the same key twice
        var arrangements = this.product(nInds).filter(function(pair){
            return pair[0] !== pair[1]; 
        });
        
        return _.map(arrangements, function(indices) {
            return _.map(indices, function(i) {
                return obj[i]; 
                }
            );
        })
    };
    
    /**
     * @name combinations
     * @param {Array} obj - List that contains the elements that will be combined.
     * @param {number} n - Number of items to select per combination.
     * @description
     * Generate n combinations of an object with no repeat values in each combination.
     *
     *
     * combinations([0,1,2,3],2)
     * // => [[0,1],[0,2],[0,3],[1,2],[1,3],[2,3]]
     */
    combinations (obj,n){
        /* filter out keys out of order, e.g. [0,1] is ok but [1,0] isn't */
        function isSorted(arr) {
            return _.every(arr, function (value, index, array) {
                return index === 0 || String(array[index - 1]) <= String(value);
            });
        }
        // array with n copies of the keys of obj
        return _(this.permutations(_.keys(obj),n))
            .filter(isSorted)
            .map(function(indices) {
                return _.map(indices,function(i){
                    return obj[i];
                });
            })
            .value();
    };

    /**
     * @name isGuid
     * @param {string} item String that may or may not be a guid.
     * @description
     * Determines if the given item is a guid string or not.
     */
    isGuid (item){
        return !!item.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/gi);
    }

    /**
     * @name getControlKeyPress
     * @param {Event} event - The keypress event.
     * @description
     * Returns the lowercase control-key that was pressed, if any.  Based on String.fromCharCode()
     */
    getControlKeyPress (event) {
        if (event && (event.ctrlKey || event.metaKey)) {
            return String.fromCharCode(event.which).toLowerCase();
        }
    }

    /**
     * @name convertLocalToUtc
     * @description
     * Converts a local date to UTC.
     * @param {Date} date - The local date to convert.
     * @param {Date} atDate - The optional date for the timezone offset. If null, the current timezone offset is used.
     * @return {Date} The converted date in UTC.
     */
    convertLocalToUtc (date, atDate) {
        atDate = atDate || new Date();
        var timezoneOffset = atDate.getTimezoneOffset();
        return new Date(date.getTime() + timezoneOffset * 60000);
    };

    /**
     * @name convertUtcToLocal
     * @description
     * Converts a UTC date to local time.
     * @param {Date} date - The UTC date to convert.
     * @param {Date} atDate - The optional date for the timezone offset. If null, the current timezone offset is used.
     * @return {Date} The converted date in local time.
     */
    convertUtcToLocal (date, atDate) {
        atDate = atDate || new Date();
        var timezoneOffset = - atDate.getTimezoneOffset();
        return new Date(date.getTime() + timezoneOffset * 60000);
    };

    /**
     * @name parseTimeSpan
     * @description
     * Parses a string into a time span. The string is a standard .NET TimeSpan format string.
     * @param {String} value - The string to parse.
     * @return {Object} The parsed time span, an object of { days: Number, hours: Number, minutes: Number, seconds: Number }.
     */
    parseTimeSpan (value) {
        if (!value) return null;

        var timeSpan = {
            days: null,
            hours: null,
            minutes: null,
            seconds: null
        };

        // Parse days alone: [-]D
        if (!_.includes(value, ':')) {
            timeSpan.days = _.toInteger(value);
            timeSpan.hours = 0;
            timeSpan.minutes = 0;
            timeSpan.seconds = 0;
            return timeSpan;
        }

        // Parse a TimeSpan string value: [-]d.hh:mm:ss.ff
        var tokens = _.split(value, ':');
        var tokenCount = _.size(tokens);
        if (tokenCount > 0) {
            // Parse days and hours: [-]D.HH:mm:ss.ff
            var firstToken = _.nth(tokens, 0);
            if (!_.includes(firstToken, '.')) {
                var firstValue = _.toNumber(firstToken);
                if (firstValue >= 0 && firstValue <= 23) {
                    // Parse hours 0 - 23: HH:mm:ss.ff
                    timeSpan.days = 0;
                    timeSpan.hours = firstValue;
                } else {
                    // Parse days below 0 or above 23: [-]D:mm:ss.ff
                    timeSpan.days = firstValue;
                    timeSpan.hours = 0;
                }
            } else {
                // Parse days and hours: [-]D.HH:mm:ss.ff
                var dayTokens = _.split(firstToken, '.');
                if (_.size(dayTokens) !== 2) {
                    throw new Error('The value must be a TimeSpan: "' + value + '"');
                }
                timeSpan.days = _.toInteger(_.nth(dayTokens, 0));
                timeSpan.hours = _.toInteger(_.nth(dayTokens, 1));
            }

            // Parse minutes: [-]d.hh:MM:ss.ff
            if (tokenCount <= 1) {
                timeSpan.minutes = 0;
            } else {
                timeSpan.minutes = _.toInteger(_.nth(tokens, 1));
            }

            // Parse seconds: [-]d.hh:mm:SS.FF
            if (tokenCount <= 2) {
                timeSpan.seconds = 0;
            } else {
                timeSpan.seconds = _.toInteger(_.nth(tokens, 2));
            }
        }

        return timeSpan;
    };

    /**
     * @name formatTimeSpan
     * @description
     * Formats a time span into a a standard .NET TimeSpan format string.
     * @param {Object} timeSpan - The time span to format.
     * @return {String} The formatted string.
     */
    formatTimeSpan (timeSpan) {
        if (!timeSpan) return null;

        // Format a TimeSpan string: "[-]d.hh:mm:ss.ff".
        var result = '';
        if (timeSpan.days) {
            result += timeSpan.days + '.';
        }
        result += _.padStart(timeSpan.hours, 2, '0')
            + ':'
            + _.padStart(timeSpan.minutes, 2, '0')
            + ':'
            + _.padStart(timeSpan.seconds, 2, '0');
        return result;
    };

    /**
     * @name convertTimeSpanToTime
     * @description
     * Converts a time span into a JavaScript time, a number of milliseconds.
     * @param {Object} timeSpan - The time span to format.
     * @return {Number} The number of milliseconds.
     */
    convertTimeSpanToTime (timeSpan) {
        if (!timeSpan) return null;

        var result = 0;
        result += timeSpan.days || 0;
        result *= 24; // hours
        result += timeSpan.hours || 0;
        result *= 60; // min
        result += timeSpan.minutes || 0;
        result *= 60; // sec
        result += timeSpan.seconds || 0;
        result *= 1000; // msec
        return result;
    };

    sha256 (ascii) {
        function rightRotate(value, amount) {
            return (value >>> amount) | (value << (32 - amount));
        };

        var mathPow = Math.pow;
        var maxWord = mathPow(2, 32);
        var lengthProperty = 'length'
        var i, j; // Used as a counter across the whole file
        var result = ''

        var words = [];
        var asciiBitLength = ascii[lengthProperty] * 8;

        //* caching results is optional - remove/add slash from front of this line to toggle
        // Initial hash value: first 32 bits of the fractional parts of the square roots of the first 8 primes
        // (we actually calculate the first 64, but extra values are just ignored)
        //var hash = sha256.h = sha256.h || [];
        // Round constants: first 32 bits of the fractional parts of the cube roots of the first 64 primes
        //var k = sha256.k = sha256.k || [];
        //var primeCounter = k[lengthProperty];
        
        var hash = [], k = [];
        var primeCounter = 0;
        

        var isComposite = {};
        for (var candidate = 2; primeCounter < 64; candidate++) {
            if (!isComposite[candidate]) {
                for (i = 0; i < 313; i += candidate) {
                    isComposite[i] = candidate;
                }
                hash[primeCounter] = (mathPow(candidate, .5) * maxWord) | 0;
                k[primeCounter++] = (mathPow(candidate, 1 / 3) * maxWord) | 0;
            }
        }

        ascii += '\x80' // Append Ƈ' bit (plus zero padding)
        while (ascii[lengthProperty] % 64 - 56) ascii += '\x00' // More zero padding
        for (i = 0; i < ascii[lengthProperty]; i++) {
            j = ascii.charCodeAt(i);
            if (j >> 8) return; // ASCII check: only accept characters in range 0-255
            words[i >> 2] |= j << ((3 - i) % 4) * 8;
        }
        words[words[lengthProperty]] = ((asciiBitLength / maxWord) | 0);
        words[words[lengthProperty]] = (asciiBitLength)

        // process each chunk
        for (j = 0; j < words[lengthProperty];) {
            var w = words.slice(j, j += 16); // The message is expanded into 64 words as part of the iteration
            var oldHash = hash;
            // This is now the undefinedworking hash", often labelled as variables a...g
            // (we have to truncate as well, otherwise extra entries at the end accumulate
            hash = hash.slice(0, 8);

            for (i = 0; i < 64; i++) {
                // var i2 = i + j;   // never used
                // Expand the message into 64 words
                // Used below if
                var w15 = w[i - 15], w2 = w[i - 2];

                // Iterate
                var a = hash[0], e = hash[4];
                var temp1 = hash[7]
                    + (rightRotate(e, 6) ^ rightRotate(e, 11) ^ rightRotate(e, 25)) // S1
                    + ((e & hash[5]) ^ ((~e) & hash[6])) // ch
                    + k[i]
                    // Expand the message schedule if needed
                    + (w[i] = (i < 16) ? w[i] : (
                        w[i - 16]
                        + (rightRotate(w15, 7) ^ rightRotate(w15, 18) ^ (w15 >>> 3)) // s0
                        + w[i - 7]
                        + (rightRotate(w2, 17) ^ rightRotate(w2, 19) ^ (w2 >>> 10)) // s1
                    ) | 0
                    );
                // This is only used once, so *could* be moved below, but it only saves 4 bytes and makes things unreadble
                var temp2 = (rightRotate(a, 2) ^ rightRotate(a, 13) ^ rightRotate(a, 22)) // S0
                    + ((a & hash[1]) ^ (a & hash[2]) ^ (hash[1] & hash[2])); // maj

                hash = [(temp1 + temp2) | 0].concat(hash); // We don't bother trimming off the extra ones, they're harmless as long as we're truncating when we do the slice()
                hash[4] = (hash[4] + temp1) | 0;
            }

            for (i = 0; i < 8; i++) {
                hash[i] = (hash[i] + oldHash[i]) | 0;
            }
        }

        for (i = 0; i < 8; i++) {
            for (j = 3; j + 1; j--) {
                var b = (hash[i] >> (j * 8)) & 255;
                result += ((b < 16) ? 0 : '') + b.toString(16);
            }
        }
        return result;
    };
}

export const utilities = new Utilities();

export default utilities;