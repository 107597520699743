import {
  IButtonStyles,
  IDocumentCardLogoStyles,
  IDocumentCardStyles,
  IIconStyles,
  ILabelStyles,
  ILinkStyles,
  IStackTokens,
  mergeStyles,
} from '@fluentui/react';

export const labelStyles4: Partial<ILabelStyles> = {
  root: { marginTop: 0, fontSize: '13px' },
};

export const itemAlignmentsStackTokens: IStackTokens = {
  childrenGap: '0 5',
};
export const itemAlignmentsStackTokens1: IStackTokens = {
  padding: '10px',
  childrenGap: '50',
};

export const labelStyles: Partial<ILabelStyles> = {
  root: {
    padding: 0,
    marginTop: 10,
    marginLeft: 0,
    marginBottom: 5,
    fontSize: '32px',
  },
};
export const moreStyles = {
  root: {
    width: 650,
  },
};
export const stackWidthStyles = { root: { width: '350px' } };
export const labelStyles1: Partial<ILabelStyles> = {
  root: {
    fontSize: 14,
    font: 'Sergoe UI',
    weight: 600,
    padding: '10px 0px 10px 15px',
    float: 'left',
  },
};
export const labelStyles3: Partial<ILabelStyles> = {
  root: {
    fontSize: 14,
    font: 'Sergoe UI',
    weight: 600,
    padding: '15px 0px 0px 15px',
    float: 'left',
  },
};
export const traininglabel1: ILabelStyles = {
  root: {
    fontSize: 14,
    font: 'Sergoe UI',
    fontWeight: 600,
    padding: '10px 0px 0px 10px',
  },
};
export const traininglabel2: ILabelStyles = {
  root: {
    fontSize: 14,
    font: 'Sergoe UI',
    fontWeight: 400,
    padding: '0px 0px 0px 10px',
  },
};

export const traininglabel3: ILabelStyles = {
  root: {
    fontSize: 14,
    font: 'Sergoe UI',
    fontWeight: 600,
    padding: '10px 0px 0px 10px',
    color: 'black',
  },
};

export const labelStyles2: Partial<ILabelStyles> = {
  root: {
    padding: 0,
    marginTop: 10,
    marginLeft: 0,
    marginBottom: 5,
    fontSize: '20px',
    lineHeight: '26px',
    weight: 600,
  },
};

export const cardStyles2: IDocumentCardStyles = {
  root: {
    display: 'inline-block',
    height: 300,
    width: 700,
    left: 0,
    top: 20,
    padding: 0,
    borderRadius: 4,
    boxShadow: '2px 2px 4px 2px rgba(0, 0, 0, 0.14)',
  },
};

export const logoStyles1: IButtonStyles = {
  root: { marginTop: 10, fontSize: '15px', alignSelf: 'right' },
};

export const conversationTileClass = mergeStyles({
  paddingBottom: '40px',
  borderBottom: '1px solid #E0E0E0',
});
export const featuresInsightsClass = mergeStyles({
  paddingTop: '10px',
  paddingBottom: '40px',
  borderBottom: '1px solid #E0E0E0',
});
export const dividerClass = mergeStyles({
  paddingBottom: '0px',
  borderBottom: '1px solid #E0E0E0',
});
export const stackClass = mergeStyles({
  backgroundColor: '#ffffff',
  paddingLeft: '40px',
  paddingRight: '40px',
});

export const iconStyles2: IIconStyles = {
  root: {
    pointerEvents: 'none',
    fontSize: '40px',
    color: 'rgb(0,120,212)',
  },
};

export const cardStyles: IDocumentCardStyles = {
  root: {
    display: 'inline-block',
    height: 122,
    width: 284,
    left: 0,
    top: 20,
    borderRadius: 4,
    boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.14)',
  },
};
export const secondaryTitleStyles: IDocumentCardStyles = {
  root: {
    padding: '5px 0px 20px 10px',
    height: '55px',
  },
};
export const logoStyles: IDocumentCardLogoStyles = {
  root: {
    pointerEvents: 'none',
    padding: '5px 0px 0px 15px',
  },
};

export const linkStyles: ILinkStyles = {
  root: {
    padding: '0px 0px 0px 10px',
    fontSize: 13,
  },
};
export const iconStyles: IIconStyles = {
  root: {
    fontSize: '10px',
  },
};
