import { DataSourceClient } from 'features/dataSources/client/dataSourceClient';
import constants, {
  $laConstants,
  orchestratorConstants,
} from 'utils/constants';

class SqlClient extends DataSourceClient {
  public readonly name = $laConstants.DataSourceTypes.SQL;
  public readonly title = 'SQL';
  public readonly icon = 'fa fa-cat';
  public readonly description = 'SQL data source.';

  public readonly monaco = {
    language: orchestratorConstants.LanguageId.Sql,
  };
  public readonly orchestration = {
    dataCommand: {
      endpointType: orchestratorConstants.EndpointTypes.SqlDatabase,
      scriptLabel: 'SQL',
    },
    dataMovement: {
      endpointTypes: [
        // in order for UI
        orchestratorConstants.EndpointTypes.SqlTable,
        orchestratorConstants.EndpointTypes.SqlQuery,
      ],
      inputPartitioning: true,
      primaryKey: true,
      targetEndpointTypes: [
        orchestratorConstants.EndpointTypes.KustoTable,
        orchestratorConstants.EndpointTypes.CosmosStream,
        orchestratorConstants.EndpointTypes.AzureDataLakeStorageGen2,
      ],
      validationQuery: true,
    },
    endpoint: {
      directive: 'kusto-table-job-endpoint',
      tablePattern: /^.{1,128}$/i,
      tableValidationMessage: 'The name may consist of up to 128 characters.',
  },
  };
  public get displayName() {
    return constants.DataSourceClientDisplayNames.SQL;
  }

  public get dataSourceType() {
    return constants.DataSourceTypes.SQL;
  }
}

export const sqlClient = new SqlClient();
export default sqlClient;
