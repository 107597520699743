import { ElxDropdown } from '@elixir/components';
import { Stack } from '@fluentui/react';
import { Activity } from 'features/orchestrator/models/job';
import { useEffect, useState } from 'react';
import { orchestratorConstants } from 'utils/constants';
import { LensLabel } from 'utils/lensLabel';

const tableTypes = Object.values(orchestratorConstants.TableTypes);

export interface JobEndpointTableTypeProps {
  endpoint:any;
  activity: Activity;
  onChange: (activity: Activity, endpoint:any) => void;
}

export const JobEndpointTableType = (props: JobEndpointTableTypeProps) => {
  const { endpoint, activity, onChange } = props;
  const [tableTypeOptions, setTableTypeOptions] = useState(tableTypes);
  const [selectedKey, setSelectedKey] = useState(endpoint.tableType || tableTypes[0]);

  endpoint.tableType = endpoint.tableType || selectedKey;
  // if (
  //   activity.activityType === orchestratorConstants.ActivityTypes.DataMovement
  // ) {
  //   activity!.input!.tableType = activity!.output!.tableType;
  // }

  useEffect(() => {
    if (
      activity.activityType === orchestratorConstants.ActivityTypes.DataMovement
    ) {
      const inputType = activity.input?.type;
      switch (inputType) {
        case orchestratorConstants.EndpointTypes.ApplicationInsightsTable:
        case orchestratorConstants.EndpointTypes.LogAnalyticsTable:
          // AI/LA table data movement only supports Timeseries.
          setTableTypeOptions(
            tableTypes.filter(
              (d) => d === orchestratorConstants.TableTypes.Timeseries
            )
          );

          break;
        case orchestratorConstants.EndpointTypes.CosmosStream:
        case orchestratorConstants.EndpointTypes.AzureDataLakeStorageGen2:
          setTableTypeOptions(
            tableTypes.filter(
              (d) =>
                d === orchestratorConstants.TableTypes.Timeseries ||
                d === orchestratorConstants.TableTypes.Snapshot
            )
          );

          break;
        case orchestratorConstants.EndpointTypes.AzureTable:
          setTableTypeOptions(
            tableTypes.filter(
              (d) =>
                d === orchestratorConstants.TableTypes.Timeseries ||
                d === orchestratorConstants.TableTypes.Log
            )
          );

          break;
        case orchestratorConstants.EndpointTypes.KustoTable:
        case orchestratorConstants.EndpointTypes.KustoQuery:
        case orchestratorConstants.EndpointTypes.ApplicationInsightsQuery:
        case orchestratorConstants.EndpointTypes.LogAnalyticsQuery:
        default:
          // All other data movement types (Kusto, AI/LA query, and Cosmos) supports all table types.
          setTableTypeOptions(tableTypes);
          break;
      }
    }
    setTableTypeOptions(tableTypes);
  }, [activity.activityType, activity.input?.type]);

  return (
    <>
      <Stack.Item>
        {' '}
        <ElxDropdown
          onRenderLabel={() => (
            <LensLabel
              labelText={'Table Type'}
              hintText={'Table Type - log, timeseries or snapshot '}
              required={true}
            ></LensLabel>
          )}
          options={tableTypeOptions.map((d: string) => ({ key: d, text: d }))}
          selectedKey={selectedKey}
          onChange={(e, options) => {
            setSelectedKey(options?.key! as string);
            endpoint.tableType = options?.key.toString();
            // activity!.input!.tableType = options?.key.toString();
            // activity!.output!.tableType = options?.key.toString();
            onChange(activity, endpoint);
          }}
        ></ElxDropdown>
      </Stack.Item>
    </>
  );
};
