import { useDispatch, useSelector } from 'react-redux';
import {
  MessageBar,
  MessageBarType,
  Panel,
  PanelType,
  ThemeProvider,
} from '@fluentui/react';
import { useLensShellTheme } from 'features/shell/lensShellStyles';
import { BasePanelProps } from 'components/panelManager/panelManager';
import { Stack } from '@fluentui/react';
import {
  selectWorkspaceListEntities,
  useWorkspaceList,
} from 'features/workspaces/workspaceListSlice';
import { stackItemHiddenOverflow } from './workspacesStyles';
import { updateRecents } from '../utils/workspaceQuickSwitchUtils';
import { switchToWorkspace } from '../utils/workspaceUtils';
import { getSharedConnections, loadProject, loadWorkspace } from '../workspaceSlice';
import { AppDispatch } from 'app/lensShellUtility';
import { SelectWorkspace } from './selectWorkspace';
import { useState } from 'react';
import { panelStyles } from './editWorkspace/workspaceStyles';

export const WorkspaceQuickSwitch = ({
  onShowPanel,
  show,
}: BasePanelProps): JSX.Element => {
  const theme = useLensShellTheme();
  useWorkspaceList();
  const workspaces = useSelector(selectWorkspaceListEntities);
  const dispatch = useDispatch<AppDispatch>();
  const [showLoading, setShowLoading] = useState(false);
  const [accessMessages, setAccessMessages] = useState<JSX.Element>();

  const onDismiss = () => {
    onShowPanel(false);
  };

  const goToWorkspace = (workspaceId: string) => {
    updateRecents(dispatch, workspaceId, workspaces);
    const alias = workspaceId.includes('private') ? null : workspaceId;
    switchToWorkspace(alias);
    dispatch(loadWorkspace(alias)).then(() => {
      dispatch(loadProject({ workspaceId: alias, isEditWorkspace: false }));
    }).then(() => {dispatch(getSharedConnections(workspaceId));});

    onDismiss();
  };

  return (
    <ThemeProvider theme={theme}>
      <Panel
        onRenderHeader={() => {
          return (
            <Stack styles={{ root: { width: '100%' } }}>
              <Stack.Item
                styles={{
                  root: {
                    background: 'white',
                    height: '30px',
                  },
                }}
              >
                <>
                  {(showLoading || accessMessages) && (
                    <MessageBar
                      messageBarType={MessageBarType.info}
                      isMultiline={true}
                      dismissButtonAriaLabel="Close"
                    >
                      {accessMessages || 'Loading workspace information ...'}
                    </MessageBar>
                  )}
                </>
              </Stack.Item>

              <Stack.Item
                styles={{
                  root: {
                    fontSize: '18px',
                    fontWeight: '600',
                    paddingLeft: '7px',
                  },
                }}
              >
                Workspace Management
              </Stack.Item>
            </Stack>
          );
        }}
        type={PanelType.large}
        isOpen={show}
        onDismiss={() => onShowPanel(false)}
        styles={panelStyles}
        isFooterAtBottom={false}
      >
        {show && (
          <Stack
            verticalFill
            tokens={{ padding: '10px 20px 0px' }}
            styles={{ root: { height: '100%' } }}
          >
            <Stack.Item styles={{ root: { paddingLeft: '20px' } }}>
              <span>
                {
                  'To edit your workspaces, select the row in the table to edit.'
                }
              </span>
            </Stack.Item>
            <Stack.Item grow styles={stackItemHiddenOverflow}>
              <SelectWorkspace
                onWorkspaceSelect={goToWorkspace}
                onDismiss={onDismiss}
                openQuickSwitchPanel={() => {
                  onShowPanel(true);
                }}
                closeQuickSwitchPanel={() => onShowPanel(false)}
                setShowLoadingMsg={(loading: boolean) =>
                  setShowLoading(loading)
                }
                setAccessMessages={(message: JSX.Element | undefined) =>
                  setAccessMessages(message)
                }
              ></SelectWorkspace>
            </Stack.Item>
          </Stack>
        )}
      </Panel>
    </ThemeProvider>
  );
};

export default WorkspaceQuickSwitch;
