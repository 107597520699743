import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { appInsights } from './appInsights';
class LensLogger {
  public setUserId(userId: any) {
    if (appInsights) {
      appInsights.setAuthenticatedUserContext(userId);
    }
  }

  public event(name: any, properties?: any, metrics?: any) {
    const event = { name };
    if (appInsights) {
      appInsights.trackEvent(
        event,
        Object.assign(
          Object.assign({}, this.getCustomObject(properties)),
          this.getCustomObject(metrics)
        )
      );
    }
  }

  public trace(message: any, properties: any) {
    const trace = {
      message,
      severityLevel: SeverityLevel.Information,
    };
    const customProperties = this.getCustomObject(properties);
    if (appInsights) {
      appInsights.trackTrace(trace, customProperties);
    }
  }

  public trackMetric(name: any, value: any) {
    appInsights.trackMetric(name, value);
  }

  public warn(warning: any, properties: any) {
    const trackWarningException =
      typeof warning === 'string' ? new Error(warning) : warning;
    this.trackException(
      trackWarningException,
      SeverityLevel.Warning,
      properties
    );
  }

  public error(exception: any, critical = false, properties: any) {
    const errorToTrack =
      typeof exception === 'string' ? new Error(exception) : exception;
    this.trackException(
      errorToTrack,
      critical ? SeverityLevel.Critical : SeverityLevel.Error,
      properties
    );
  }

  private trackException(exception: any, severityLevel: any, context: any) {
    if (context) {
      const telemetry = {
        exception: exception,
        serverityLevel: severityLevel,
        properties: context,
      };
      if (appInsights) {
        appInsights.trackException(telemetry);
      }
    }
  }
  private getCustomObject(context: any) {
    if (context) {
      return Object.assign({}, context);
    }
  }
}

export const logger = new LensLogger();

export default logger;
