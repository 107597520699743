import { Label, Link, Stack } from '@fluentui/react';
import { mainStackStyles } from './convertToGrafana';
import { CopyButtonWithNotifier } from 'components/copyButtons/copyButtons';
import { ConversionWarnings } from './conversionWarnings';

export interface MigrationStatusProps {
  destinationLink: string;
  warnings: string[];
}
export const MigrationStatus = ({
  destinationLink,
  warnings,
}: MigrationStatusProps): JSX.Element => {
  return (
    <>
      <Stack styles={mainStackStyles}>
        <Stack horizontal tokens={{ childrenGap: '5px' }}>
          <Label> Your dashboard was migrated </Label>
          <Label styles={{ root: { color: '#107c10' } }}>successfully</Label>
        </Stack>
        <Stack>
          <br />
          <Label>
            Link to Grafana Dashboard:{' '}
            <Link href={destinationLink} target="_blank">
              {destinationLink}
            </Link>{' '}
            <CopyButtonWithNotifier text={destinationLink} />
          </Label>
        </Stack>
        <br />
        <ConversionWarnings warnings={warnings} />
      </Stack>
    </>
  );
};
