import {
  GrafanaDatasource,
  GrafanaCreateKustoDatasourceRequest,
  GrafanaKustoDatasource,
} from './models/grafanaMigrationData';
import { LensNode, SearchSource } from './models/lensDashboard';

// from Github Co-Pilot
export function batchItems<T>(collection: T[], batchSize = 10) {
  return collection.reduce(
    (acc: T[][], curr) => {
      if (acc[acc.length - 1].length < batchSize) {
        acc[acc.length - 1].push(curr);
      } else {
        acc.push([curr]);
      }
      return acc;
    },
    [[]]
  );
}

// https://stackoverflow.com/questions/3543187/prepending-http-to-a-url-that-doesnt-already-contain-http
const withHttps = (url: string) =>
  !/^https?:\/\//i.test(url) ? `https://${url}` : url;

export const withoutHttps = (url: string) => url.replace(/^https?:\/\//i, '');

export function compareCluster(clusterA: string, clusterB: string): boolean {
  try {
    const urlA = new URL(withHttps(clusterA));
    const urlB = new URL(withHttps(clusterB));
    return urlA.hostname.toUpperCase() === urlB.hostname.toUpperCase();
  } catch (error) {
    return (
      withHttps(clusterA).toUpperCase().replace(/\/$/, '') ===
      withHttps(clusterB).toUpperCase().replace(/\/$/, '')
    );
  }
}

// Create new datasource unique name from cluster name, like "lens cluster 1"
function getDatasourceNameFromCluster(
  cluster: string,
  existingNames: string[]
): string {
  let name = '';
  try {
    const url = new URL(withHttps(cluster));
    name = url.hostname.split('.')[0] + ' cluster';
  } catch (error) {
    name = withoutHttps(cluster.split('.')[0]) + ' cluster';
  }
  if (existingNames.includes(name)) {
    let i = 1;
    while (existingNames.includes(name + ' ' + i)) {
      i++;
    }
    name = name + ' ' + i;
  }
  return name;
}

// iterate through the dashboard and find all Kusto datasources that are not in the list of existing datasources
// on the Grafana instance, and create a new datasource for each one.
export function getMissingDatasourcesFromDashboard(
  lensDashboard: LensNode[],
  datasources: GrafanaDatasource[]
): GrafanaCreateKustoDatasourceRequest[] {
  const newDatasources: GrafanaCreateKustoDatasourceRequest[] = [];
  const existingNames = datasources.map((ds) => ds.name);

  const kustoDatasources = datasources.filter(
    (ds) => ds.type === 'grafana-azure-data-explorer-datasource'
  ) as GrafanaKustoDatasource[];

  // Logic to extract datasources from the dashboard goes here
  lensDashboard.forEach((node) => {
    if (node.Body.savedSearchId) return; // skip linked queries
    const searchSource: SearchSource = JSON.parse(
      node.Body.kibanaSavedObjectMeta?.searchSourceJSON || ''
    );

    if (searchSource?.datasourceType === 'Kusto') {
      // check for the Lens Sample cluster called '/kustoproxy/<guid>'
      const isKustoProxy = searchSource.cluster
        .toLowerCase()
        .startsWith('/kustoproxy/');
      // Note: 'kustoproxy' will not be converted to Grafana datasource
      if (!isKustoProxy) {
        const foundKustoDatasource = kustoDatasources.find((kds) =>
          compareCluster(kds.jsonData.clusterUrl, searchSource.cluster)
        );
        const foundNewDatasource = newDatasources.find((nds) =>
          compareCluster(nds.jsonData.clusterUrl, searchSource.cluster)
        );
        if (!foundKustoDatasource && !foundNewDatasource) {
          const newName = getDatasourceNameFromCluster(
            searchSource.cluster,
            existingNames
          );
          const newDatasource: GrafanaCreateKustoDatasourceRequest = {
            name: newName,
            type: 'grafana-azure-data-explorer-datasource',
            access: 'proxy',
            jsonData: {
              azureCredentials: {
                authType: 'currentuser',
              },
              clusterUrl: withHttps(searchSource.cluster.replace(/\/$/, '')),
              defaultDatabase: searchSource.database,
              oauthPassThru: true,
            },
          };
          existingNames.push(newName);
          newDatasources.push(newDatasource);
        }
      }
    }
  });
  return newDatasources;
}
