import React, { useMemo } from 'react';

import { classNames } from '../utils';

export interface SecondsProps {
  seconds: string;
  changeSeconds: (val: string) => void;
  className: string | undefined;
}
export default function Seconds(props: SecondsProps) {
  const { seconds, changeSeconds, className } = props;
  const internalClassName = useMemo(
    () =>
      classNames({
        'react-js-cron-field': true,
        'react-js-cron-minutes': true,
        [`${className}-field`]: !!className,
        [`${className}-minutes`]: !!className,
      }),
    [className]
  );

  return (
    <div className={internalClassName}>
      <span> : </span>
      <input
        style={{
          padding: 6,
          marginLeft: 6,
          border: '1px solid #d9d9d9',
        }}
        type="number"
        value={seconds}
        onChange={(e) => {
          let val = String(e.target.value);
          changeSeconds(val);
        }}
        min="0"
        max="59"
      />
    </div>
  );
}
