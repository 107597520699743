import { configureStore } from '@reduxjs/toolkit';
import reducer from 'app/reducers';

export const setupReduxStore = (initialState = {}, reducers = reducer) => {
  return configureStore({
    reducer: reducers,
    preloadedState: initialState,
    // This will replace the value of warnAfter in all default middleware
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: {
          warnAfter: 1000,
        },
        serializableCheck: false,
      }),
  });
};
