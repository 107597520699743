import {
  IButtonStyles,
  IconFontSizes,
  IStackStyles,
  Stack,
} from '@fluentui/react';
import { ElxIconButton } from '@elixir/components';
import { useLensShellTheme } from 'features/shell/lensShellStyles';
import React from 'react';

export interface CloseButtonProps {
  /**
   * Tooltip and aria label for the close button
   */
  text?: string;

  /**
   * action when button is pressed
   */
  onDismiss?: () => void;
}

/**
 * An 'X' close button absolutely positioned at the top right of the containing element.  You
 * should leave some extra margin or padding on the right of the container to leave some room for
 * this icon.
 */
export const CloseButton = ({
  text,
  onDismiss,
}: CloseButtonProps): JSX.Element => {
  const theme = useLensShellTheme();

  const buttonStyles: IButtonStyles = {
    root: {
      margin: '12px 12px 0 0',
      borderRadius: 0,
      fontSize: IconFontSizes.large,
      color: theme.palette.neutralPrimary,
    },
    rootHovered: {
      color: theme.palette.neutralDark,
    },
    rootPressed: {
      color: theme.palette.neutralDark,
    },
  };

  const stackStyles: IStackStyles = {
    root: {
      position: 'absolute',
      right: 0,
      top: 0,
    },
  };

  const closeButtonText = text || 'Dismiss';
  return (
    <Stack styles={stackStyles}>
      <ElxIconButton
        text={closeButtonText}
        iconProps={{ iconName: 'Cancel' }}
        ariaLabel={closeButtonText}
        onClick={onDismiss}
        styles={buttonStyles}
      />
    </Stack>
  );
};

export default CloseButton;
