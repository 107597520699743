import { RestApi } from 'api/restApi';
import constants from 'utils/constants';
import { AAD_CLIENT_ID } from 'utils/sharedLibs';

const versionedOrchestratorApiUrl =
  constants.Urls.OrchestratorApiUrl +
  '/' +
  constants.OrchestratorDefaultVersion;

/**
 * Represents a base class for Orchestrator API (OrchestratorApi) clients.
 */
export class OrchestratorApi extends RestApi {
  constructor() {
    super(versionedOrchestratorApiUrl, AAD_CLIENT_ID);
  }

  public elevateOrchestrator = async (): Promise<string | null> => {
    return await this.postEntity<null, string | null>(
      '/principals/elevation',
      null
    );
  };
}

export default OrchestratorApi;
