import { LeWebApi } from 'api/leWebApi';
import UserPreferences from '../models/userPreferences';

export class UserPreferencesApi extends LeWebApi {
  public getUserPreferences = async (): Promise<UserPreferences> => {
    return await this.getEntity<UserPreferences>('/userinfo/userpreferences');
  };

  public saveUserPreferences = async (
    userPreferences: UserPreferences
  ): Promise<UserPreferences> => {
    return await this.putEntity<UserPreferences>(
      '/userinfo/userpreferences',
      userPreferences
    );
  };

  public saveSupportNotificationStatus = async (
    userPreferences: UserPreferences,
    supportNotificationStatus: string
  ): Promise<UserPreferences> => {
    let parent = { ...userPreferences };
    parent.supportNotificationStatus = supportNotificationStatus;
    return await this.putEntity<UserPreferences>(
      '/userinfo/userpreferences',
      parent
    );
  };
}

export const userPreferencesApi = new UserPreferencesApi();

export default userPreferencesApi;
