import { Stack } from '@fluentui/react';
import { ElxPrimaryButton, ElxSecondaryButton } from '@elixir/components';
import React, { useEffect, useMemo, useState } from 'react';

interface optionSwitchProps {
  numOfOptions: number;
  buttonTitles: string[];
  components: JSX.Element[];
  setInputOption: (inputOption: number) => void;
}
export const OptionSwitch = (props: optionSwitchProps) => {
  const { numOfOptions, buttonTitles, components, setInputOption } = props;

  const [show, setShow] = useState<boolean[]>([]);
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    let showComponents: boolean[] = [];
    for (let i = 0; i < numOfOptions; i++) {
      showComponents.push(false);
    }
    setShow(showComponents);
  }, [selectedIndex, numOfOptions]);

  const selectionButtonsMemo = useMemo(() => {
    let selectionButtons: JSX.Element[] = [];
    for (let i = 0; i < numOfOptions; i++) {
      if (selectedIndex === i || show[i] === true) {
        //default or selected button
        selectionButtons.push(
          <ElxPrimaryButton
            key={'selectionButtons ' + i}
            text={buttonTitles[i]}
            onClick={() => {
              setSelectedIndex(i);
              let temp = [...show];
              temp[i] = false;
              setShow(temp);
              setInputOption(i);
            }}
          />
        );
      } else if (show[i] === false) {
        // all other buttons
        selectionButtons.push(
          <ElxSecondaryButton
            key={'selectionButtons ' + i}
            text={buttonTitles[i]}
            onClick={() => {
              setSelectedIndex(i);
              let temp = [...show];
              temp[i] = true;
              setShow(temp);
              setInputOption(i);
            }}
          />
        );
      }
    }
    return <Stack.Item>{selectionButtons}</Stack.Item>;
  }, [show, selectedIndex, buttonTitles, numOfOptions, setInputOption]);

  return (
    <>
      {selectionButtonsMemo}
      {components[selectedIndex]}
    </>
  );
};
