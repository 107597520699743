import { DataSourceClient } from 'features/dataSources/client/dataSourceClient';
import constants, {
  $laConstants,
  orchestratorConstants,
} from 'utils/constants';

class AdlaClient extends DataSourceClient {
  public readonly name = $laConstants.DataSourceTypes.ADLA;
  public readonly title = 'Azure Data Lake Analytics.';
  public readonly icon = 'fa fa-cat'; // TODO - Nambi - see if we could use this as iconName.
  public readonly description = 'Azure Data Lake Analytics Data Source.'; // TODO - Nambi See if this one is needed.
  monaco = {
    language: orchestratorConstants.LanguageId.Scopescript,
  };
  orchestration = {
    dataCommand: {
      endpointType: orchestratorConstants.EndpointTypes.AzureDataLakeAnalytics,
      scriptLabel: 'Cosmos Scope or U-SQL',
    },
  };

  public get displayName() {
    return constants.DataSourceClientDisplayNames.ADLA;
  }

  public get dataSourceType() {
    return constants.DataSourceTypes.ADLA;
  }
}

export const adlaClient = new AdlaClient();
export default adlaClient;
