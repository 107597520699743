import { onShowPanel } from 'components/panelManager/panelManager';
import { ElxIconButton } from '@elixir/components';
import {
  allActivityLogs,
  allSupportNotifications,
} from 'features/notifications/notificationsSlice';
import { SidePanel } from 'features/shell/lensShell';
import { useLensShellTheme } from 'features/shell/lensShellStyles';
import { Icon } from '@fluentui/react';
import React from 'react';
import { useSelector } from 'react-redux';

const NotificationIcon = (props: any): JSX.Element => {
  const theme = useLensShellTheme();
  const supportNotifications = useSelector(allSupportNotifications);
  const activeSupportNotification = supportNotifications.filter(
    (item) => item.Show === true
  );
  const activityLogNotification = useSelector(allActivityLogs);
  const totalNotificationCount =
    activeSupportNotification?.length + activityLogNotification?.length;

  const getNotificationCountIcon = () => {
    return (
      <div>
        {totalNotificationCount > 0 && (
          <div
            style={{
              position: 'absolute',
              background: theme.palette.blue,
              color: theme.palette.white,
              borderRadius: 10,
              width: 20,
              height: 20,
              marginLeft: 10,
            }}
          >
            {totalNotificationCount}
          </div>
        )}
        <Icon iconName="Ringer" styles={{ root: { paddingTop: '2px' } }} />
      </div>
    );
  };
  return (
    <ElxIconButton
      label="Notifications"
      text="Notifications"
      id="lens-notification-icon"
      className="elx-header-action"
      onRenderIcon={getNotificationCountIcon}
      onClick={() => onShowPanel(props.panelState, SidePanel.NOTIFICATIONS)}
    />
  );
};

export default NotificationIcon;
