import {
  ElxActionButton,
  ElxDropdown,
  ElxIconButton,
  ElxPanel,
  ElxTagPicker,
  ElxTextField,
  ElxTimeSelector,
  ITagData,
  PanelSize,
  TagContainerOrientation,
  TimeSelectorOutput,
} from '@elixir/components';
import { IDropdownOption, Stack, Toggle } from '@fluentui/react';
import DayHourMinSecPicker from 'components/dayHourMinSecPicker';
import {
  alignEnds,
  groupBorder,
} from 'features/orchestrator/components/JobActivities/JobAuthorStyles';
import {
  Dataset,
  DatasetAction,
  DatasetValidation,
  DatasetTrigger,
} from 'features/orchestrator/models/dataset';
import { severityLevels } from 'features/orchestrator/models/job';
import { JobAccordian } from 'features/orchestrator/utils/jobAccordian';
import { uniqNumber } from 'features/orchestrator/utils/jobUtils';
import workspacesApi from 'features/workspaces/api/workspacesApi';
import {
  selectOrchestratorEditProject,
  selectWorkspace,
} from 'features/workspaces/workspaceSlice';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { LensLabel } from 'utils/lensLabel';
import utils from 'utils/utils';

const partitionOptions = [
  { text: 'None', key: '' },
  { text: 'Hourly', key: '01:00:00' },
  { text: 'Daily', key: '1.00:00:00' },
];

const actionTypeOptions = [{ text: 'SendIcmAlert', key: 'SendIcmAlert' }];
const validationTypeOptions = [
  { text: 'DatasetLatency', key: 'DatasetLatency' },
];

const severityOptions = severityLevels.map((o) => ({
  key: o,
  text: `Severity ${o}`,
}));

const conditionAggregationOptions = [
  { text: 'Absolute', key: 'Absolute' },
  { text: 'Percentage', key: 'Percentage' },
];

const conditionOperatorOptions = [
  { text: '<', key: 'LessThan' },
  { text: '<=', key: 'LessThanOrEqualTo' },
  { text: '==', key: 'EqualTo' },
  { text: '>=', key: 'GreaterThanOrEqualTo' },
  { text: '>', key: 'GreaterThan' },
];
const defaultTrigger: DatasetTrigger = {
  condition: {
    aggregation: conditionAggregationOptions[0].key,
    operator: conditionOperatorOptions[0].key,
    value: 0,
  },
  actionIds: [],
};
const defaultValidation: DatasetValidation = {
  id: utils.newGuid(),
  type: validationTypeOptions[0].key,
  triggers: [],
};

const defaultAction: DatasetAction = {
  id: utils.newGuid(),
  displayName: '',
  type: actionTypeOptions[0].key,
};

const defaultDataset: Dataset = {
  id: '',
  datasetKey: '',
  serviceId: null,
  version: 0,
  sla: '',
  manifestName: '',
  disabled: false,
  deleted: false,
  displayName: `Temp Dataset Name ${uniqNumber().next().value} (please edit)`,
  tags: [],
  endpoint: {
    type: 'AzureDataLakeStorage',
    account: '',
    folder: '',
    pattern: '',
    isBucketed: false,
    bucketPostfixFormat: '',
    aadTenant: '',
    aadClientId: '',
  },
  partitionSize: '',
  metricsLookback: '',
  validations: [],
  actions: [],
  createdBy: '',
  createdDate: '',
  modifiedBy: '',
  modifiedDate: '',
  eTag: '',
};

const cansave = (dataset: Dataset): boolean => {
  if (!dataset.displayName || dataset.displayName === '') {
    return false;
  }
  if (!dataset.serviceId || dataset.serviceId === '') {
    return false;
  }
  if (!dataset.endpoint.folder || dataset.endpoint.folder === '') {
    return false;
  }
  if (!dataset.endpoint.pattern || dataset.endpoint.pattern === '') {
    return false;
  }
  if (!dataset.endpoint.account || dataset.endpoint.account === '') {
    return false;
  }
  if (!dataset.endpoint.aadTenant || dataset.endpoint.aadTenant === '') {
    return false;
  }
  if (!dataset.endpoint.aadClientId || dataset.endpoint.aadClientId === '') {
    return false;
  }

  return true;
};

export interface DatasetProps {
  isEdit?: boolean;
  dataset?: Dataset;
  onChange: (dataset: Dataset) => Promise<void>;
  onDismiss: () => void;
}

const AddDataset: React.FC<DatasetProps> = (props: DatasetProps) => {
  const { isEdit, dataset, onChange, onDismiss } = props;

  const [tempDataset, setTempDataset] = useState<Dataset>(
    isEdit ? dataset! : defaultDataset
  );
  const workspace = useSelector(selectWorkspace);
  const [, setCurrentProject] = useState(
    useSelector(selectOrchestratorEditProject)
  );
  const [saving, setSaving] = useState(false);

  const handleChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    const { name } = event.target as HTMLInputElement;
    setTempDataset((prevDataset) => ({
      ...prevDataset,
      [name]: newValue,
    }));
  };

  const handleChangeEndpoint = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    const { name } = event.target as HTMLInputElement;
    setTempDataset((prevDataset) => ({
      ...prevDataset,
      endpoint: { ...prevDataset.endpoint, [name]: newValue },
    }));
  };

  const handleChangeAction = (
    name: string,
    value: string | number,
    idx: number
  ) => {
    setTempDataset((prevDataset) => {
      let dtSet = { ...prevDataset };
      dtSet.actions[idx] = { ...dtSet.actions[idx], [name]: value };

      return { ...dtSet };
    });
  };

  useEffect(() => {
    async function getProject() {
      const proj = await workspacesApi.getProject(workspace.alias);
      setCurrentProject(proj);
      setTempDataset((prevDataset) => ({
        ...prevDataset,
        serviceId:
          prevDataset.serviceId === null || prevDataset.serviceId === undefined
            ? proj?.serviceGuid || ''
            : prevDataset.serviceId,
      }));
    }
    getProject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ElxPanel
      headerText={`${isEdit ? 'Edit' : 'Add'} Dataset`}
      isOpen={true}
      key={'Dataset Panel'}
      size={PanelSize.large}
      onDismiss={onDismiss}
      actions={[
        {
          key: 'activity',
          text: `${isEdit ? 'Save' : 'Add'} Dataset`,
          primary: true,
          onClick: () => {
            setSaving(true);
            onChange(tempDataset)
              .then(() => {
                setSaving(false);
              })
              .catch((err) => {
                setSaving(false);
              });
          },
          disabled: !cansave(tempDataset) || saving,
        },
        {
          key: 'close',
          text: 'Close',
          onClick: () => {
            onDismiss();
          },
        },
      ]}
    >
      <Stack tokens={{ childrenGap: 16, padding: 16 }}>
        <Stack.Item>
          <ElxTextField
            onRenderLabel={() => (
              <LensLabel
                labelText={'Display Name'}
                hintText={'The display name'}
                required
              ></LensLabel>
            )}
            name="displayName"
            value={tempDataset.displayName || ''}
            placeholder="Enter a name for the dataset"
            onChange={handleChange}
          />
          {isEdit && (
            <>
              <ElxTextField
                onRenderLabel={() => (
                  <LensLabel
                    labelText={'ID'}
                    hintText={'The dataset id'}
                  ></LensLabel>
                )}
                name="id"
                value={tempDataset.id}
                onChange={handleChange}
                disabled={true}
              />
              <ElxTextField
                onRenderLabel={() => (
                  <LensLabel
                    labelText={'Dataset Key'}
                    hintText={'The dataset key'}
                  ></LensLabel>
                )}
                name="datasetKey"
                value={tempDataset.datasetKey}
                onChange={handleChange}
                disabled={true}
              />
            </>
          )}
          <ElxTextField
            onRenderLabel={() => (
              <LensLabel
                labelText={'Service ID'}
                hintText={'The service tree id'}
                required
              ></LensLabel>
            )}
            name="serviceId"
            value={
              tempDataset.serviceId === null ? undefined : tempDataset.serviceId
            }
            onChange={handleChange}
          />
          {isEdit && (
            <ElxTextField
              onRenderLabel={() => (
                <LensLabel
                  labelText={'Version'}
                  hintText={'The version number'}
                ></LensLabel>
              )}
              name="version"
              type="number"
              value={tempDataset.version?.toString() || '0'}
              disabled={true}
            />
          )}
          {/* <ElxDropdown
            label="Catalog Integration"
            name="catalogIntegration"
            options={catalogIntegrationOptions}
            selectedKey={tempDataset.catalogIntegration}
            onChange={(_, option) =>
              option && handleChange(undefined, option.key)
            }
          />
          <ElxTextField
            label="Manifest Name"
            name="manifestName"
            value={tempDataset.manifestName}
            onChange={handleChange}
          />
          <ElxCheckbox
            label="Disabled"
            name="disabled"
            checked={tempDataset.disabled}
            onChange={handleCheckboxChange}
          />
          <ElxCheckbox
            label="Deleted"
            name="deleted"
            checked={tempDataset.deleted}
            onChange={handleCheckboxChange}
          />
          <ElxTextField
            label="Display Name"
            name="displayName"
            value={tempDataset.displayName}
            onChange={handleChange}
          />
          <ElxTextField
            label="Tags"
            name="tags"
            value={tempDataset.tags.join(',')}
            onChange={handleTagsChange}
          /> */}
          <ElxDropdown
            onRenderLabel={() => (
              <LensLabel
                labelText={'Partition Size'}
                hintText={'The partition size'}
                required
              ></LensLabel>
            )}
            options={partitionOptions}
            styles={{ root: { minWidth: 200 } }}
            selectedKey={tempDataset.partitionSize}
            onChange={(_, option?: IDropdownOption) => {
              if (option) {
                setTempDataset((prevDataset) => ({
                  ...prevDataset,
                  partitionSize: option.key.toString(),
                }));
              }
            }}
          />
          <LensLabel
            labelText="Metrics Lookback"
            hintText={`The lookback time period for polling. Any dataset partitions created or updated beyond the lookback period will be ignored. Can be omitted for a fixed dataset.`}
          ></LensLabel>
          <DayHourMinSecPicker
            setValue={tempDataset.metricsLookback || '0.00:00:00'}
            onChange={(value: string): void => {
              setTempDataset((prevDataset) => ({
                ...prevDataset,
                metricsLookback: value,
              }));
            }}
            showDays={true}
            showHours={true}
            showMinutes={true}
            showSeconds={true}
            maxDays={1000}
          ></DayHourMinSecPicker>
          {isEdit && (
            <>
              <ElxTextField
                onRenderLabel={() => (
                  <LensLabel
                    labelText={'Created By'}
                    hintText={'Created By'}
                  ></LensLabel>
                )}
                name="createdBy"
                value={tempDataset.createdBy}
                onChange={handleChange}
                disabled={true}
              />
              <ElxTextField
                label="Created Date"
                onRenderLabel={() => (
                  <LensLabel
                    labelText={'Created Date'}
                    hintText={'Created Date'}
                  ></LensLabel>
                )}
                name="createdDate"
                value={tempDataset.createdDate}
                onChange={handleChange}
                disabled={true}
              />
              <ElxTextField
                onRenderLabel={() => (
                  <LensLabel
                    labelText={'Modified By'}
                    hintText={'Modified By'}
                  ></LensLabel>
                )}
                name="modifiedBy"
                value={tempDataset.modifiedBy}
                onChange={handleChange}
                disabled={true}
              />
              <ElxTextField
                onRenderLabel={() => (
                  <LensLabel
                    labelText={'Modified Date'}
                    hintText={'Modified Date'}
                  ></LensLabel>
                )}
                name="modifiedDate"
                value={tempDataset.modifiedDate}
                onChange={handleChange}
                disabled={true}
              />
            </>
          )}
          {/* <ElxTextField
            label="ETag"
            name="eTag"
            value={tempDataset.eTag || ''}
            onChange={handleChange}
            disabled={true}
          /> */}
          <ElxTagPicker
            label="Tags"
            // onRenderLabel={() => (
            //   <LensLabel labelText={'Tags'} hintText={'The tags'}></LensLabel>
            // )}
            name="tags"
            items={
              tempDataset.tags?.map((o, i) => ({
                key: i.toString(),
                text: o,
              })) || ([] as ITagData[])
            }
            onAddTag={function (tag: ITagData): void {
              let tags = tempDataset.tags || [];
              tags = [...tags, tag.text];
              setTempDataset((prevDataset) => ({
                ...prevDataset,
                tags: tags.slice(),
              }));
            }}
            onRemoveTag={function (tag: ITagData): void {
              let tags = tempDataset.tags || [];
              if (tags.length > 0) {
                tags = tags.filter((o) => o !== tag.text);
                tags = [...tags];
                setTempDataset((prevDataset) => ({
                  ...prevDataset,
                  tags: tags,
                }));
              }
            }}
            orientation={TagContainerOrientation.Horizontal}
          ></ElxTagPicker>
        </Stack.Item>
        <Stack.Item className={groupBorder}>
          <JobAccordian
            isOpen={false}
            text={'Endpoint'}
            hint={`Endpoint for the dataset`}
          >
            <Stack tokens={{ childrenGap: 16, padding: 16 }}>
              <Stack.Item>
                <ElxTextField
                  onRenderLabel={() => (
                    <LensLabel
                      labelText={'Type'}
                      hintText={`Endpoint type - ADLS Gen1`}
                      required
                    ></LensLabel>
                  )}
                  name="type"
                  value={tempDataset.endpoint?.type || 'AzureDataLakeStorage'}
                  onChange={handleChangeEndpoint}
                  disabled={true}
                />
                <ElxTextField
                  onRenderLabel={() => (
                    <LensLabel
                      labelText={'Account'}
                      hintText={`The Adls gen1 account`}
                      required
                    ></LensLabel>
                  )}
                  name="account"
                  value={tempDataset.endpoint?.account || ''}
                  onChange={handleChangeEndpoint}
                />
                <ElxTextField
                  onRenderLabel={() => (
                    <LensLabel
                      labelText={'Folder'}
                      hintText={`The folder path`}
                      required
                    ></LensLabel>
                  )}
                  name="folder"
                  value={tempDataset.endpoint?.folder || ''}
                  onChange={handleChangeEndpoint}
                />
                <ElxTextField
                  onRenderLabel={() => (
                    <LensLabel
                      labelText={'Pattern'}
                      hintText={`The file pattern. If time-partitioned, must include %Y %m %d %h placeholders. If fixed, must not include placeholders.`}
                      required
                    ></LensLabel>
                  )}
                  name="pattern"
                  value={tempDataset.endpoint?.pattern || ''}
                  onChange={handleChangeEndpoint}
                />
                <LensLabel
                  labelText={'Is Bucketed'}
                  hintText={'When checked data is bucketed.'}
                ></LensLabel>
                <Toggle
                  label=""
                  checked={tempDataset.endpoint?.isBucketed || false}
                  onChange={(
                    e: React.MouseEvent<HTMLElement>,
                    checked?: boolean
                  ) => {
                    setTempDataset((prevDataset) => ({
                      ...prevDataset,
                      endpoint: {
                        ...prevDataset.endpoint,
                        isBucketed: checked || false,
                      },
                    }));
                  }}
                  onText="Bucketed"
                  offText="Not Bucketed"
                />
                {tempDataset.endpoint?.isBucketed && (
                  <ElxTextField
                    onRenderLabel={() => (
                      <LensLabel
                        labelText={'BucketPostfixFormat'}
                        hintText={`The regular expression for the file extension suffix if bucketed.`}
                      ></LensLabel>
                    )}
                    name="bucketPostfixFormat"
                    value={tempDataset.endpoint?.bucketPostfixFormat || ''}
                    onChange={handleChangeEndpoint}
                  />
                )}
                <ElxTextField
                  onRenderLabel={() => (
                    <LensLabel
                      labelText={'Aad Tenant'}
                      hintText={`The Azure tenant domain name of the AAD application used to connect to the endpoint.`}
                      required
                    ></LensLabel>
                  )}
                  name="aadTenant"
                  value={tempDataset.endpoint?.aadTenant || ''}
                  onChange={handleChangeEndpoint}
                />
                <ElxTextField
                  onRenderLabel={() => (
                    <LensLabel
                      labelText={'Aad Client Id'}
                      hintText={`The Application ID of the AAD application used to connect to the endpoint.`}
                      required
                    ></LensLabel>
                  )}
                  name="aadClientId"
                  value={tempDataset.endpoint?.aadClientId || ''}
                  onChange={handleChangeEndpoint}
                />
              </Stack.Item>
            </Stack>
          </JobAccordian>
        </Stack.Item>
        <Stack.Item className={groupBorder}>
          <JobAccordian
            isOpen={false}
            text={'Validations'}
            hint={'The list of validation on the dataset'}
          >
            <Stack tokens={{ childrenGap: 16, padding: 16 }}>
              {tempDataset.validations?.map((vldn, idx) => {
                if (vldn.type === validationTypeOptions[0].key) {
                  return (
                    <Stack
                      tokens={{ childrenGap: 8, padding: 8 }}
                      key={`validation ${idx}`}
                    >
                      <Stack.Item className={groupBorder}>
                        <Stack
                          className={alignEnds}
                          horizontal
                          grow
                          styles={{
                            root: {
                              borderBottom: '1px solid #ccc',
                              backgroundColor: 'rgb(237, 235, 233)',
                            },
                          }}
                        >
                          <Stack.Item style={{ paddingLeft: 4 }}>
                            <LensLabel
                              labelText={`Validation ${idx + 1}`}
                              hintText={`Validation index ${idx}`}
                            ></LensLabel>
                          </Stack.Item>
                          <Stack.Item>
                            <ElxIconButton
                              text={'Remove Validation'}
                              styles={{
                                root: {
                                  float: 'right',
                                },
                              }}
                              iconProps={{
                                iconName: 'cancel',
                              }}
                              onClick={() => {
                                setTempDataset((prevDataset) => ({
                                  ...prevDataset,
                                  validations: [
                                    ...prevDataset.validations.filter(
                                      (actn, i) => i !== idx
                                    ),
                                  ],
                                }));
                                console.log('delete validation');
                              }}
                            ></ElxIconButton>
                          </Stack.Item>
                        </Stack>
                        <Stack tokens={{ childrenGap: 8, padding: 8 }}>
                          {/* <Stack.Item>
                        <ElxTextField
                          name="id"
                          onRenderLabel={() => (
                            <LensLabel
                              labelText={`Id`}
                              hintText={'Validation Id'}
                            ></LensLabel>
                          )}
                          value={vldn.id}
                          onChange={(
                            event: React.FormEvent<
                              HTMLInputElement | HTMLTextAreaElement
                            >,
                            newValue?: string
                          ) => {
                            const { name } = event.target as HTMLInputElement;
                            if (newValue) {
                              handleChangeValidation(name, newValue, idx);
                            }
                          }}
                        ></ElxTextField>
                      </Stack.Item> */}
                          <Stack.Item>
                            <ElxDropdown
                              onRenderLabel={() => (
                                <LensLabel
                                  labelText={'Validation Type : DatasetLatency'}
                                  hintText={
                                    'Validation Type - Only DatasetLatency supported'
                                  }
                                ></LensLabel>
                              )}
                              options={validationTypeOptions}
                              styles={{ root: { minWidth: 200 } }}
                              selectedKey={
                                tempDataset.validations[idx].type ||
                                validationTypeOptions[0].key
                              }
                              onChange={(_, option?: IDropdownOption) => {
                                if (option) {
                                  setTempDataset((prevDataset) => ({
                                    ...prevDataset,
                                    validations: prevDataset.validations.map(
                                      (v, i) => {
                                        if (i === idx) {
                                          v.type = option.key?.toString();
                                          return v;
                                        } else {
                                          return v;
                                        }
                                      }
                                    ),
                                  }));
                                }
                              }}
                              disabled={true}
                            ></ElxDropdown>
                          </Stack.Item>
                          <Stack.Item className={groupBorder}>
                            <JobAccordian
                              isOpen={false}
                              text={`Trigers`}
                              hint={''}
                            >
                              <Stack tokens={{ childrenGap: 8, padding: 8 }}>
                                {vldn.triggers.map((t, i) => (
                                  <Stack
                                    tokens={{ childrenGap: 8, padding: 8 }}
                                    key={`trigger ${idx} ${i}`}
                                  >
                                    <Stack.Item className={groupBorder}>
                                      <Stack
                                        className={alignEnds}
                                        horizontal
                                        grow
                                        styles={{
                                          root: {
                                            borderBottom: '1px solid #ccc',
                                            backgroundColor:
                                              'rgb(237, 235, 233)',
                                          },
                                        }}
                                      >
                                        <Stack.Item style={{ paddingLeft: 4 }}>
                                          <LensLabel
                                            labelText={`Trigger ${i + 1}`}
                                            hintText={`Trigger index ${i}`}
                                          ></LensLabel>
                                        </Stack.Item>
                                        <Stack.Item>
                                          <ElxIconButton
                                            text={'Remove Trigger'}
                                            styles={{
                                              root: {
                                                float: 'right',
                                              },
                                            }}
                                            iconProps={{
                                              iconName: 'cancel',
                                            }}
                                            onClick={() => {
                                              let trigs =
                                                tempDataset.validations[
                                                  idx
                                                ].triggers.filter(
                                                  (trig, j) => j !== i
                                                );
                                              let validations = [
                                                ...tempDataset.validations,
                                              ];
                                              validations[idx].triggers = trigs;
                                              setTempDataset((prevDataset) => ({
                                                ...prevDataset,
                                                validations: validations,
                                              }));
                                              console.log('delete validation');
                                            }}
                                          ></ElxIconButton>
                                        </Stack.Item>
                                      </Stack>

                                      <Stack
                                        tokens={{
                                          childrenGap: 8,
                                          padding: 8,
                                        }}
                                      >
                                        <Stack.Item>
                                          <LensLabel
                                            labelText={'SLA'}
                                            hintText={'SLA in minutes'}
                                            required
                                          ></LensLabel>
                                          <ElxTextField
                                            type="number"
                                            value={
                                              tempDataset.validations[
                                                idx
                                              ].triggers[
                                                i
                                              ].condition?.value.toString() ||
                                              (0).toString()
                                            }
                                            onChange={(e, val) => {
                                              let trigs = [
                                                ...tempDataset.validations[idx]
                                                  .triggers,
                                              ];
                                              trigs[i].condition.value =
                                                Number(val) || 0;
                                              let vldns = [
                                                ...tempDataset.validations,
                                              ];
                                              vldns[idx].triggers = trigs;
                                              trigs[i].condition.aggregation =
                                                conditionAggregationOptions[0].key;
                                              trigs[i].condition.operator =
                                                conditionOperatorOptions[4].key;
                                              setTempDataset((prevDataset) => ({
                                                ...prevDataset,
                                                validations: [...vldns],
                                              }));
                                            }}
                                          ></ElxTextField>
                                        </Stack.Item>
                                        <Stack.Item>
                                          <LensLabel
                                            labelText={'Actions'}
                                            hintText={
                                              'The actions that are performed if the trigger is activated. Validation actions do not stop job execution. After the actions are performed, the job instance continues to execute successfully. Actions are shared within the data validation activity and available to all of its triggers. If an action is changed, all triggers that use the action are affected.'
                                            }
                                            required={true}
                                          ></LensLabel>
                                          <ElxDropdown
                                            multiSelect={true}
                                            options={
                                              tempDataset.actions?.map(
                                                (act) => ({
                                                  text:
                                                    act.displayName || act.id,
                                                  key: act.id,
                                                })
                                              ) || []
                                            }
                                            onMultiSelectChange={(
                                              selectedKeys
                                            ) => {
                                              let trigs = [
                                                ...tempDataset.validations[idx]
                                                  .triggers,
                                              ];
                                              trigs[i].actionIds =
                                                selectedKeys as string[];
                                              let validations = [
                                                ...tempDataset.validations,
                                              ];
                                              validations[idx].triggers = trigs;
                                              setTempDataset((prevDataset) => ({
                                                ...prevDataset,
                                                validations: validations,
                                              }));
                                            }}
                                            selectedKeys={
                                              tempDataset.validations[idx]
                                                .triggers[i].actionIds || []
                                            }
                                          ></ElxDropdown>
                                        </Stack.Item>
                                      </Stack>
                                    </Stack.Item>
                                  </Stack>
                                ))}
                                {!(
                                  tempDataset.validations[idx].triggers.length >
                                  0
                                ) && (
                                  <Stack.Item>No trigger available</Stack.Item>
                                )}
                                <Stack.Item>
                                  <ElxActionButton
                                    iconProps={{ iconName: 'Add' }}
                                    text="Add Trigger"
                                    onClick={() => {
                                      let trigs = [
                                        ...tempDataset.validations[idx]
                                          .triggers,
                                        { ...defaultTrigger },
                                      ];
                                      let validations = [
                                        ...tempDataset.validations,
                                      ];
                                      validations[idx].triggers = trigs;

                                      setTempDataset((prevDataset) => ({
                                        ...prevDataset,
                                        validations: validations,
                                      }));
                                      console.log('add Trigger');
                                    }}
                                  />
                                </Stack.Item>
                              </Stack>
                            </JobAccordian>
                          </Stack.Item>
                        </Stack>
                      </Stack.Item>
                    </Stack>
                  );
                } else {
                  return <></>;
                }
              })}
              {!(tempDataset.validations?.length > 0) && (
                <Stack.Item>No actions available, add action</Stack.Item>
              )}
              <Stack.Item>
                <ElxActionButton
                  iconProps={{ iconName: 'Add' }}
                  text="Add Validation"
                  onClick={() => {
                    setTempDataset((prevDataset) => ({
                      ...prevDataset,
                      validations: [
                        ...prevDataset.validations,
                        {
                          ...defaultValidation,
                          triggers: [{ ...defaultTrigger }],
                        },
                      ],
                    }));
                    console.log('add validation');
                  }}
                />
              </Stack.Item>
            </Stack>
          </JobAccordian>
        </Stack.Item>
        <Stack.Item className={groupBorder}>
          <JobAccordian isOpen={false} text={'Actions'} hint={'Action List'}>
            <Stack tokens={{ childrenGap: 16, padding: 16 }}>
              {tempDataset.actions?.map((actn, idx) => (
                <Stack
                  tokens={{ childrenGap: 8, padding: 8 }}
                  key={`action ${idx}`}
                >
                  <Stack.Item className={groupBorder}>
                    <Stack
                      className={alignEnds}
                      horizontal
                      grow
                      styles={{
                        root: {
                          borderBottom: '1px solid #ccc',
                          backgroundColor: 'rgb(237, 235, 233)',
                        },
                      }}
                    >
                      <Stack.Item style={{ paddingLeft: 4 }}>
                        <LensLabel
                          labelText={`Action ${idx + 1}`}
                          hintText={`Action index ${idx}`}
                        ></LensLabel>
                      </Stack.Item>
                      <Stack.Item>
                        <ElxIconButton
                          text={'Remove Action'}
                          styles={{
                            root: {
                              float: 'right',
                            },
                          }}
                          iconProps={{
                            iconName: 'cancel',
                          }}
                          onClick={() => {
                            setTempDataset((prevDataset) => ({
                              ...prevDataset,
                              actions: [
                                ...prevDataset.actions.filter(
                                  (actn, i) => i !== idx
                                ),
                              ],
                            }));
                            console.log('delete action');
                          }}
                        ></ElxIconButton>
                      </Stack.Item>
                    </Stack>
                    <Stack tokens={{ childrenGap: 8, padding: 8 }}>
                      {/* <Stack.Item>
                        <ElxTextField
                          name="id"
                          onRenderLabel={() => (
                            <LensLabel
                              labelText={`Id`}
                              hintText={'Action Id'}
                              required
                            ></LensLabel>
                          )}
                          value={actn.id}
                          onChange={(
                            event: React.FormEvent<
                              HTMLInputElement | HTMLTextAreaElement
                            >,
                            newValue?: string
                          ) => {
                            const { name } = event.target as HTMLInputElement;
                            if (newValue) {
                              handleChangeAction(name, newValue, idx);
                            }
                          }}
                        ></ElxTextField>
                      </Stack.Item> */}
                      <Stack.Item>
                        <ElxTextField
                          name="displayName"
                          onRenderLabel={() => (
                            <LensLabel
                              labelText={`Name`}
                              hintText={'Action Name'}
                              required
                            ></LensLabel>
                          )}
                          value={actn.displayName}
                          onChange={(
                            event: React.FormEvent<
                              HTMLInputElement | HTMLTextAreaElement
                            >,
                            newValue?: string
                          ) => {
                            const { name } = event.target as HTMLInputElement;
                            if (newValue) {
                              handleChangeAction(name, newValue, idx);
                            }
                          }}
                        ></ElxTextField>
                      </Stack.Item>
                      <Stack.Item>
                        <ElxDropdown
                          onRenderLabel={() => (
                            <LensLabel
                              labelText={'Action Type'}
                              hintText={'The Action Type'}
                              required
                            ></LensLabel>
                          )}
                          options={actionTypeOptions}
                          styles={{ root: { minWidth: 200 } }}
                          selectedKey={actn.type || actionTypeOptions[0].key}
                          onChange={(_, option?: IDropdownOption) => {
                            if (option) {
                              setTempDataset((prevDataset) => ({
                                ...prevDataset,
                                actions: prevDataset.actions.map((a, i) => {
                                  if (i === idx) {
                                    a.type = option.key?.toString();
                                    return a;
                                  } else {
                                    return a;
                                  }
                                }),
                              }));
                            }
                          }}
                        />
                      </Stack.Item>
                      <Stack.Item>
                        <ElxTextField
                          name="icmTeamPublicId"
                          onRenderLabel={() => (
                            <LensLabel
                              labelText={`Icm Team`}
                              hintText={'The IcM team public id'}
                              required
                            ></LensLabel>
                          )}
                          value={actn.icmTeamPublicId}
                          onChange={(
                            event: React.FormEvent<
                              HTMLInputElement | HTMLTextAreaElement
                            >,
                            newValue?: string
                          ) => {
                            const { name } = event.target as HTMLInputElement;
                            if (newValue) {
                              handleChangeAction(name, newValue, idx);
                            }
                          }}
                        ></ElxTextField>
                      </Stack.Item>
                      <Stack.Item>
                        <ElxDropdown
                          onRenderLabel={() => (
                            <LensLabel
                              labelText={`Alert Severity`}
                              hintText={'The alert severity'}
                              required
                            ></LensLabel>
                          )}
                          options={severityOptions}
                          styles={{ root: { minWidth: 200 } }}
                          selectedKey={
                            actn.alertSeverity ||
                            severityOptions[severityOptions.length - 2].key
                          }
                          onChange={(_, option?: IDropdownOption) => {
                            if (option) {
                              setTempDataset((prevDataset) => ({
                                ...prevDataset,
                                actions: prevDataset.actions.map((a, i) => {
                                  if (i === idx) {
                                    a.alertSeverity = Number(option.key);
                                    return a;
                                  } else {
                                    return a;
                                  }
                                }),
                              }));
                            }
                          }}
                        />
                      </Stack.Item>
                      <Stack.Item>
                        <LensLabel
                          labelText={'Auto mitigate'}
                          hintText={`Tells whether alerts should auto-mitigate`}
                        ></LensLabel>
                        <Toggle
                          label=""
                          checked={actn.autoMitigation || false}
                          onChange={(
                            e: React.MouseEvent<HTMLElement>,
                            checked?: boolean
                          ) => {
                            setTempDataset((prevDataset) => ({
                              ...prevDataset,
                              actions: prevDataset.actions.map((a, i) => {
                                if (i === idx) {
                                  a.autoMitigation = checked;
                                  return a;
                                } else {
                                  return a;
                                }
                              }),
                            }));
                          }}
                        />
                      </Stack.Item>
                      <Stack.Item>
                        <LensLabel
                          labelText={'Use Healthy Time To Mitigate Incident'}
                          hintText={``}
                        ></LensLabel>
                        <Toggle
                          label=""
                          checked={
                            actn.healthyTimeToMitigateIncident !== undefined
                              ? true
                              : false
                          }
                          onChange={(
                            e: React.MouseEvent<HTMLElement>,
                            newValue?: boolean
                          ) => {
                            setTempDataset((prevDataset) => ({
                              ...prevDataset,
                              actions: prevDataset.actions.map((a, i) => {
                                if (i === idx) {
                                  if (newValue === false) {
                                    delete a.healthyTimeToMitigateIncident;
                                  } else {
                                    a.healthyTimeToMitigateIncident =
                                      '00.00:00:00';
                                  }
                                  return a;
                                } else {
                                  return a;
                                }
                              }),
                            }));
                          }}
                        />
                      </Stack.Item>
                      <Stack.Item key={'healthyTimeToMitigateIncident'}>
                        <LensLabel
                          labelText={'Healthy Time To Mitigate Incident'}
                          hintText={'Healthy time to mitigate'}
                        />
                        <ElxTimeSelector
                          disabled={
                            actn.healthyTimeToMitigateIncident === undefined
                          }
                          hours={parseInt(
                            actn.healthyTimeToMitigateIncident
                              ?.split('.')[1]
                              ?.split(':')[0] || '0'
                          )}
                          minutes={parseInt(
                            actn.healthyTimeToMitigateIncident
                              ?.split('.')[1]
                              .split(':')[1] || '0'
                          )}
                          seconds={parseInt(
                            actn.healthyTimeToMitigateIncident
                              ?.split('.')[1]
                              .split(':')[2] || '0'
                          )}
                          showTimezoneSelector={false}
                          onChange={(time: TimeSelectorOutput) => {
                            setTempDataset((prevDataset) => ({
                              ...prevDataset,
                              actions: prevDataset.actions.map((a, i) => {
                                if (i === idx) {
                                  a.healthyTimeToMitigateIncident =
                                    '00.' +
                                    time.hours +
                                    ':' +
                                    time.minutes +
                                    ':' +
                                    time.seconds;
                                  return a;
                                } else {
                                  return a;
                                }
                              }),
                            }));
                          }}
                        />
                      </Stack.Item>
                      <Stack.Item>
                        <ElxTextField
                          name="tsgLink"
                          onRenderLabel={() => (
                            <LensLabel
                              labelText={`Tsg Link`}
                              hintText={'Tsg Link'}
                            ></LensLabel>
                          )}
                          onChange={(
                            event: React.FormEvent<
                              HTMLInputElement | HTMLTextAreaElement
                            >,
                            newValue?: string
                          ) => {
                            const { name } = event.target as HTMLInputElement;
                            if (newValue) {
                              handleChangeAction(name, newValue, idx);
                            }
                          }}
                        ></ElxTextField>
                      </Stack.Item>
                    </Stack>
                  </Stack.Item>
                </Stack>
              ))}
              {!(tempDataset.actions?.length > 0) && (
                <Stack.Item>No actions available, add action</Stack.Item>
              )}
              <Stack.Item>
                <ElxActionButton
                  iconProps={{ iconName: 'Add' }}
                  text="Add Action"
                  onClick={() => {
                    setTempDataset((prevDataset) => ({
                      ...prevDataset,
                      actions: [...prevDataset.actions, { ...defaultAction }],
                    }));
                    console.log('add action');
                  }}
                />
              </Stack.Item>
            </Stack>
          </JobAccordian>
        </Stack.Item>
      </Stack>
    </ElxPanel>
  );
};

export default AddDataset;
