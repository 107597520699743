import { DateTime } from 'luxon';

export enum UserSavedObjectDataStatus {
  None = 'None',
  Loading = 'Loading',
  Loaded = 'Loaded',
  Error = 'Error',
}

export enum UserSavedObjectDataType {
  Favorite = 'favorite',
  Recent = 'recent',
}

export enum LensObjectType {
  Dashboard = 'dashboard',
  Job = 'job',
  Table = 'table',
  VisSearch = 'visSearch',
  Workspace = 'workspace',
  Dataset = 'dataset', // Data Catalog
}

export interface UserSavedObjectData {
  aadTenantId?: string;
  upn?: string;
  oid?: string;
  workspaceId?: string; // only optional for dataset type
  lensObjectType: string;
  lensObjectId: string;
  type?: UserSavedObjectDataType;
  ttl?: number;
  lastUpdated?: DateTime;
  name?: string;
  description?: string;
  status?: string;
  date?: Date;
}

export interface userSavedObjectDataResponseItem {
  userSavedObjectData: UserSavedObjectData;
  statusCode: number;
  message: string;
}

export interface UserSavedObjectDataResponse {
  allSucceeded: boolean;
  userSavedObjectDataResponseItems: userSavedObjectDataResponseItem[];
}

export default UserSavedObjectData;
