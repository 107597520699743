import { ElxTextField } from '@elixir/components';
import { Stack } from '@fluentui/react';
import { getAllDataSourcePlugins } from 'features/dataSources/registry';
import { Activity } from 'features/orchestrator/models/job';
import { ProjectDataConnection } from 'features/workspaces/models/project';
import { useCallback } from 'react';
import { orchestratorConstants } from 'utils/constants';
import { LensLabel } from 'utils/lensLabel';
import { SelectDataConnection } from '../jobSchedule/selectDataConnection';
import { selectWorkspace } from 'features/workspaces/workspaceSlice';
import { useSelector } from 'react-redux';

const supportedDCTypesForT4Transform = getAllDataSourcePlugins()
  .map((dsp) => dsp.dataSourceClient)
  .map((ds) => ds.orchestration?.t4Transform?.endpointType && ds.name)
  .filter((d) => d);

const dcType2EndPointType4T4T = (dcType: string) => {
  let endPointType = '';

  switch (dcType) {
    case orchestratorConstants.DataSourceTypes.KUSTO:
      endPointType = orchestratorConstants.EndpointTypes.KustoDatabase;
      break;

    case orchestratorConstants.DataSourceTypes.ADLA:
      endPointType = orchestratorConstants.EndpointTypes.AzureDataLakeAnalytics;
      break;

    case orchestratorConstants.DataSourceTypes.COSMOS:
      endPointType = orchestratorConstants.EndpointTypes.CosmosVc;
      break;

    case orchestratorConstants.DataSourceTypes.SQL:
      endPointType = orchestratorConstants.EndpointTypes.SqlDatabase;
      break;

    case orchestratorConstants.DataSourceTypes.SYNAPSEPIPELINE:
      endPointType = orchestratorConstants.EndpointTypes.SynapsePipeline;
      break;

    case orchestratorConstants.DataSourceTypes.SYNAPSESCOPE:
      endPointType = orchestratorConstants.EndpointTypes.SynapseScope;
      break;

    case orchestratorConstants.DataSourceTypes.AZUREDATAFACTORY:
      endPointType = orchestratorConstants.EndpointTypes.AzureDataFactory;
      break;

    default:
      break;
  }
  return endPointType;
};

export interface JobActivityT4TransformProps {
  activity: Activity;
  onChange: (activity: Activity) => void;
}
export const JobActivityT4Transform = (props: JobActivityT4TransformProps) => {
  const { activity, onChange } = props;
  activity.input = activity.input || { connectionName: '', type: '' };
  delete activity.output;
  const workspace = useSelector(selectWorkspace); // get current workspace.

  const dcType2EndPointTypeForT4Transform = useCallback(
    (dc: ProjectDataConnection) => {
      activity.input!.connectionName = dc.connectionName;

      switch (dc.type) {
        case orchestratorConstants.DataSourceTypes.KUSTO:
          activity.input!.type =
            orchestratorConstants.EndpointTypes.KustoDatabase;
          break;

        case orchestratorConstants.DataSourceTypes.ADLA:
          activity.input!.type =
            orchestratorConstants.EndpointTypes.AzureDataLakeAnalytics;
          break;

        case orchestratorConstants.DataSourceTypes.COSMOS:
          activity.input!.type = orchestratorConstants.EndpointTypes.CosmosVc;
          break;

        case orchestratorConstants.DataSourceTypes.SQL:
          activity.input!.type =
            orchestratorConstants.EndpointTypes.SqlDatabase;
          break;

        case orchestratorConstants.DataSourceTypes.SYNAPSEPIPELINE:
          activity.input!.type =
            orchestratorConstants.EndpointTypes.SynapsePipeline;
          break;

        case orchestratorConstants.DataSourceTypes.SYNAPSESCOPE:
          activity.input!.type =
            orchestratorConstants.EndpointTypes.SynapseScope;
          break;

        case orchestratorConstants.DataSourceTypes.AZUREDATAFACTORY:
          activity.input!.type =
            orchestratorConstants.EndpointTypes.AzureDataFactory;
          break;
        case orchestratorConstants.DataSourceTypes.SHARED:
          const sharedConn = workspace.sourceDataConnections?.find(
            (s) => s.targetConnectionName === dc.connectionName
          );
          if (sharedConn) {
            activity.input!.type = dcType2EndPointType4T4T(
              sharedConn.sourceConnection.type
            );
          }
          break;

        default:
          break;
      }

      onChange(activity);
    },
    [activity, onChange, workspace]
  );
  return (
    <>
      <SelectDataConnection
        onSelect={dcType2EndPointTypeForT4Transform}
        filter={supportedDCTypesForT4Transform}
        selectedKey={activity.input?.connectionName || null}
        required={true}
      ></SelectDataConnection>
      <Stack verticalFill tokens={{ childrenGap: 16 }}>
        <Stack.Item>
          <ElxTextField
            id={'T4 Script'}
            onRenderLabel={() => (
              <LensLabel
                labelText={'T4 Script'}
                hintText={'Source template file path'}
                required
              ></LensLabel>
            )}
            value={activity.t4Script && activity.t4Script.sourcepath}
            onChange={(e, value) => {
              activity.t4Script = activity.t4Script || { sourcepath: '' };
              activity.t4Script = { sourcepath: value ? value : '' };
              onChange(activity);
            }}
            onGetErrorMessage={(value: string) => {
              return value
                ? ''
                : 'T4 Script path cannot be empty, enter source template file path';
            }}
          ></ElxTextField>
        </Stack.Item>
        <Stack.Item>
          <ElxTextField
            id={'T4 Paths'}
            onRenderLabel={() => (
              <LensLabel
                labelText={'T4 Paths'}
                hintText={
                  'Comma seperated list of folder paths containing resources for the include and assembly references in T4 template'
                }
              ></LensLabel>
            )}
            value={activity.t4Paths?.join(',')}
            onChange={(e, value) => {
              activity.t4Paths = value?.split(',') || [];
              onChange(activity);
            }}
          ></ElxTextField>
        </Stack.Item>
        <Stack.Item>
          <ElxTextField
            id={'T4 Referenes'}
            onRenderLabel={() => (
              <LensLabel
                labelText={'T4 References'}
                hintText={
                  'Comma seperated list of template file paths to generate resources needed for job submission.'
                }
              ></LensLabel>
            )}
            value={activity.t4Refs?.join(',')}
            onChange={(e, value) => {
              activity.t4Refs = value?.split(',') || [];
              onChange(activity);
            }}
          ></ElxTextField>
        </Stack.Item>
      </Stack>
    </>
  );
};
