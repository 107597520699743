import LeApi from 'api/leApi';
import {
  ServiceTreeApiResponse,
  ServiceTreeApiService,
} from '../models/service';

class ServiceTreeApi extends LeApi {
  public getServices = async (): Promise<ServiceTreeApiResponse> => {
    return await this.getEntity<ServiceTreeApiResponse>('/v1/services');
  };

  /**
   * Gets the service with the given ID..
   * @param prefix -  The service ID.
   * @returns Promise<Array> - A promise that returns the service metadata.
   */
  public getServiceById = async (
    id: string
  ): Promise<ServiceTreeApiService[]> => {
    return await this.getEntity<ServiceTreeApiService[]>(
      '/v1/services/search/' + id
    );
  };

  /**
   * Gets the validity of the service id.
   * @param serviceId -  The service ID.
   * @returns Promise<boolean> - A promise that returns the validity of the service id.
   */
  public validateServiceId = async (serviceId: string) => {
    if (!serviceId) return Promise.resolve(false);

    return this.getServiceById(serviceId)
      .then((service) => {
        return !!service;
      })
      .catch((error) => {
        return false;
      });
  };
}

export const serviceTreeApi = new ServiceTreeApi();

export default serviceTreeApi;
