import {
  DisplayTypes,
  ElxTableContainer,
  FilterDisplayMode,
  FilterOption,
  FilterOptionMode,
  FilterOptionPillMode,
  IElxColumn,
  IElxContainerProps,
  ITableAction,
} from '@elixir/components';
import { Link, SelectionMode, Stack } from '@fluentui/react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'app/lensShellUtility';
import { editWorkspace } from 'features/workspaces/workspaceSlice';
import {
  getId,
  loadLinkedGrafanaInstances,
  resetUsersGrafanaInstances,
  saveGrafanaInstances,
  useLinkedGrafanaInstances,
  useUsersGrafanaInstances,
} from '../grafanaOnboardingSlice';
import {
  GrafanaOnboardingData,
  GrafanaOnboardingDataStatus,
} from '../models/grafanaOnboardingData';
import { useMemo } from 'react';

interface GrafanaInstanceListProps {
  showAddGrafanaPanel: (show: boolean) => void;
}

export const tableStyles = {
  root: {
    '.ms-DetailsHeader-cellName': {
      fontWeight: '500 !important',
      fontSize: '12px !important',
    },
  },
  focusZone: {},
};

export const containerStyles = {
  messageBar: {
    display: 'none',
  },
  headerComponent: {
    paddingTop: '0px !important',
  },
  headingContainer: {
    display: 'none',
  },
  actionsContainer: {
    display: 'none',
  },
  loading: { position: 'relative', padding: '25px' },
};

export const StringOperators = ['==', '!=', 'Contains'];

export const filterItems = (items: string[]) => {
  return items.filter((value, index, self) => self.indexOf(value) === index);
};

export const GrafanaInstancesTable = (
  props: GrafanaInstanceListProps
): JSX.Element => {
  const { showAddGrafanaPanel } = props;
  const workspace = useSelector(editWorkspace);
  const { lensGrafanaInstanceList, linkedGrafanaInstancesStatus } =
    useLinkedGrafanaInstances(workspace.id);
  const { usersGrafanaInstancesList: items, usersGrafanaInstancesStatus } =
    useUsersGrafanaInstances();
  const dispatch = useDispatch<AppDispatch>();

  const containerProps = {
    compact: true,
    isLoading:
      usersGrafanaInstancesStatus === GrafanaOnboardingDataStatus.Loading ||
      linkedGrafanaInstancesStatus === GrafanaOnboardingDataStatus.Loading,
    loadingLabel:
      'Searching for Grafana instances in your Azure subscriptions...',
    styles: containerStyles,
  } as IElxContainerProps;

  const namePillFilter: FilterOption = {
    field: 'name',
    label: 'Name',
    operators: StringOperators,
    mode: FilterOptionMode.Text,
    pillMode: FilterOptionPillMode.Static,
  };
  const subscriptionNamePillFilter: FilterOption = {
    field: 'subscriptionName',
    label: 'Subscription Name',
    multiselect: true,
    pillMode: FilterOptionPillMode.Dynamic,
    values: filterItems(items.map((item) => item.subscriptionName)),
  };
  const subscriptionIdPillFilter: FilterOption = {
    field: 'subscriptionId',
    label: 'Subscription Id',
    multiselect: true,
    pillMode: FilterOptionPillMode.Dynamic,
    values: filterItems(items.map((item) => item.subscriptionId)),
  };
  const resourceGroupPillFilter: FilterOption = {
    field: 'resourceGroupName',
    label: 'Resource Group',
    multiselect: true,
    pillMode: FilterOptionPillMode.Dynamic,
    values: filterItems(items.map((item) => item.resourceGroupName)),
  };
  const endpointPillFilter: FilterOption = {
    field: 'endpoint',
    label: 'Endpoint',
    multiselect: true,
    pillMode: FilterOptionPillMode.Dynamic,
    values: filterItems(items.map((item) => item.endpoint)),
  };
  const locationPillFilter: FilterOption = {
    field: 'location',
    label: 'Location',
    multiselect: true,
    pillMode: FilterOptionPillMode.Dynamic,
    values: filterItems(items.map((item) => item.location)),
  };

  const columns: IElxColumn[] = [
    {
      key: 'name',
      name: 'Name',
      minWidth: 140,
      maxWidth: 140,
      fieldName: 'name',
    },
    {
      key: 'endpoint',
      name: 'Endpoint',
      minWidth: 280,
      isMultiline: true,
      fieldName: 'endpoint',
      onRender: (item) => {
        return (
          <Link href={item.endpoint} target="_blank">
            {item.endpoint}
          </Link>
        );
      },
    },
    {
      key: 'subscriptionName',
      name: 'Subscription Name',
      minWidth: 150,
      maxWidth: 150,
      isMultiline: true,
      fieldName: 'subscriptionName',
    },
    {
      key: 'resourceGroupName',
      name: 'Resource Group',
      minWidth: 150,
      maxWidth: 150,
      fieldName: 'resourceGroupName',
    },
    {
      key: 'location',
      name: 'Location',
      minWidth: 130,
      maxWidth: 130,
      fieldName: 'location',
    },
  ];

  const onClickLink = (items: GrafanaOnboardingData[]) => {
    let grafanaArray: GrafanaOnboardingData[] = [];

    items.forEach((item) => {
      const id = getId(item);
      if (!lensGrafanaInstanceList.find((instance) => instance.id === id)) {
        var instance: GrafanaOnboardingData = {
          id: id,
          workspaceId: workspace.id,
          name: item.name,
          subscriptionId: item.subscriptionId,
          resourceGroupName: item.resourceGroupName,
          location: item.location,
          endpoint: item.endpoint,
          subscriptionName: item.subscriptionName,
          aadTenantId: item.aadTenantId,
        };

        grafanaArray.push(instance);
      }
    });

    if (grafanaArray.length > 0) {
      dispatch(
        saveGrafanaInstances({ grafanaOnboardingDataList: grafanaArray })
      ).then(() => {
        dispatch(loadLinkedGrafanaInstances({ workspaceId: workspace.id }));
      });
    }
  };

  const searchProps = {
    pillFilters: [
      namePillFilter,
      endpointPillFilter,
      subscriptionNamePillFilter,
      subscriptionIdPillFilter,
      resourceGroupPillFilter,
      locationPillFilter,
    ],
    filterDisplayMode: FilterDisplayMode.Pill,
  };

  const actions: ITableAction[] = [
    {
      key: 'Create New Instance',
      text: 'Create New Instance',
      defaultDisplay: DisplayTypes.Show,
      iconProps: { iconName: 'Add' },
      onAction: () => showAddGrafanaPanel(true),
      onBulkAction: () => showAddGrafanaPanel(true),
    },
    {
      key: 'Refresh',
      text: 'Refresh',
      defaultDisplay: DisplayTypes.Show,
      onAction: () => dispatch(resetUsersGrafanaInstances()),
      onBulkAction: () => dispatch(resetUsersGrafanaInstances()),
      iconProps: { iconName: 'Refresh' },
      disabled:
        usersGrafanaInstancesStatus === GrafanaOnboardingDataStatus.Loading,
    },
    {
      key: 'Link',
      text: 'Link',
      onBulkAction: (items: GrafanaOnboardingData[]) => {
        onClickLink(items);
      },
      iconProps: { iconName: 'Link' },
      disabled:
        linkedGrafanaInstancesStatus !== GrafanaOnboardingDataStatus.Loaded,
    },
  ];

  const unlinkedGrafanaInstances = useMemo(() => {
    return items.filter(
      (item) =>
        !lensGrafanaInstanceList.find(
          (instance) => instance.id?.toUpperCase() === getId(item).toUpperCase()
        )
    );
  }, [items, lensGrafanaInstanceList]);

  return (
    <Stack.Item>
      <ElxTableContainer
        containerProps={containerProps}
        tableProps={{
          selectionMode: SelectionMode.multiple,
          columns: columns,
          items: unlinkedGrafanaInstances,
          styles: tableStyles,
          actions: actions,
        }}
        searchBoxProps={searchProps}
        onRenderEmpty={() =>
          (usersGrafanaInstancesStatus === GrafanaOnboardingDataStatus.Loaded &&
            (items.length === 0 ? (
              <Stack styles={{ root: { padding: '5px 60px' } }}>
                <h4>
                  No Azure Managed Grafana instances were found in any of your
                  subscriptions.
                </h4>
                <p>
                  You can create a new Azure Managed Grafana instance by
                  clicking the "Create New Instance" button.
                </p>
              </Stack>
            ) : unlinkedGrafanaInstances.length === 0 ? (
              <Stack styles={{ root: { padding: '5px 60px' } }}>
                <h4>
                  All of your available Azure Managed Grafana instances have
                  already been linked to this workspace.
                </h4>
                <p>
                  You can review your workspace's linked Grafana instances by
                  selecting the "Already Linked Instances" tab above.
                </p>
              </Stack>
            ) : undefined)) || <></>
        }
      ></ElxTableContainer>
    </Stack.Item>
  );
};
