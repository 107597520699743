import {
  Breadcrumb,
  FontSizes,
  FontWeights,
  IBreadcrumbItem,
  IObjectWithKey,
  Link,
  Panel,
  PanelType,
  Pivot,
  PivotItem,
  SelectionMode,
  Stack,
  Toggle,
} from '@fluentui/react';
import { useDispatch, useSelector } from 'react-redux';
import {
  breadCrumbStyles,
  panelStyles,
  panelContentStackStyle,
  descriptionTextStyle,
  descriptionTextBoldStyle,
} from '../workspaceStyles';
import {
  deleteWorkspaceSavedObject,
  editWorkspace,
  getWorkspaceRecycledObjects,
  getWorkspaceSavedObjects,
  restoreRecycledObjects,
  selectRecycledObjects,
  selectRecycledObjectsStatus,
  selectSavedObjects,
  selectSavedObjectsStatus,
} from 'features/workspaces/workspaceSlice';
import {
  DisplayTypes,
  ElxActionButton,
  ElxTableContainer,
  FilterDisplayMode,
  FilterOption,
  FilterOptionPillMode,
  IElxColumn,
  ITableAction,
} from '@elixir/components';
import { classNames } from '../../workspacesStyles';
import { useEffect, useState } from 'react';
import { AppDispatch } from 'app/lensShellUtility';
import { containerStyles } from 'features/home/components/favorites';
import {
  exportObjects,
  getEncodedParams,
  importObjects,
} from './savedObjectsUtils';
import { saveAs } from 'file-saver';
import notifier from 'utils/notifier';
import fileOpen from 'components/fileSystem/fileOpen';
import {
  SavedObjectBlob,
  SavedObjectType,
  WorkspaceObject,
  WorkspaceObjectBase,
  WorkspaceStatus,
} from 'features/workspaces/models/workspace';
import slugify from 'slugify';
import constants from 'utils/constants';
import CopyToWsPanel from './copyToWsPanel';
import utils from 'utils/utils';
import { LensLabel } from 'utils/lensLabel';

interface SavedObjectsPanelProps {
  workspaceId: string;
  show: boolean;
  dismissPanel: () => void;
  openPanel: () => void;
  closeQuickSwitchPanel: () => void;
}

export const renderObjectName = (wsObj: WorkspaceObject) => {
  const { origin } = window.location;
  switch (wsObj.type) {
    case SavedObjectType.Query:
      return (
        <Link
          href={
            origin +
            '/#/discover/query/' +
            encodeURIComponent(wsObj.id) +
            "/results/table?_g=(ws:'" +
            encodeURIComponent(wsObj.workspace) +
            "')"
          }
          target="_blank"
        >
          {wsObj.title}
        </Link>
      );
    case SavedObjectType.Visualization:
      return (
        <Link
          href={
            origin +
            '/#/visualize/edit/' +
            encodeURIComponent(wsObj.id) +
            "?_g=(ws:'" +
            encodeURIComponent(wsObj.workspace) +
            "')"
          }
          target="_blank"
        >
          {wsObj.title}
        </Link>
      );
    case SavedObjectType.Visualizations:
      return (
        <Link
          href={
            origin +
            '/#/visualize/edit/' +
            encodeURIComponent(wsObj.id) +
            "?_g=(ws:'" +
            encodeURIComponent(wsObj.workspace) +
            "')"
          }
          target="_blank"
        >
          {wsObj.title}
        </Link>
      );
    case SavedObjectType.Dashboard:
      return (
        <Link
          href={
            origin +
            '/#/dashboard/' +
            encodeURIComponent(wsObj.id) +
            "?_g=(ws:'" +
            encodeURIComponent(wsObj.workspace) +
            "')"
          }
          target="_blank"
        >
          {wsObj.title}
        </Link>
      );
    default:
      return <>{wsObj.title}</>;
  }
};

const SavedObjectsPanel = (props: SavedObjectsPanelProps) => {
  const dispatch = useDispatch<AppDispatch>();
  let workspace = useSelector(editWorkspace);
  const savedObjects = useSelector(selectSavedObjects);
  const savedObjsStatus = useSelector(selectSavedObjectsStatus);
  const recycledObjects = useSelector(selectRecycledObjects);
  const recyledObjsStatus = useSelector(selectRecycledObjectsStatus);

  const [exportFullDash, setExportFullDash] = useState(false);
  const [openCopyPanel, setOpenCopyPanel] = useState(false);

  const [selectedObjects, setSelectedObjects] = useState<WorkspaceObject[]>([]);

  useEffect(() => {
    dispatch(getWorkspaceSavedObjects(props.workspaceId));
    dispatch(getWorkspaceRecycledObjects(props.workspaceId));
  }, [dispatch, props.workspaceId]);

  const reloadSavedAndRecylcedData = () => {
    dispatch(getWorkspaceSavedObjects(props.workspaceId));
    dispatch(getWorkspaceRecycledObjects(props.workspaceId));
  };

  const typePillFilter: FilterOption = {
    field: 'type',
    label: 'Type',
    multiselect: true,
    pillMode: FilterOptionPillMode.Dynamic,
    values: savedObjects
      .map((obj) => obj.type)
      .filter((value, index, self) => self.indexOf(value) === index),
  };

  /**
   * Custom render of details for workspace objects
   * @param wsObj
   */
  const renderDetails = (wsObj: WorkspaceObject) => {
    return wsObj.type === 'dashboard' ? (
      <ElxActionButton
        iconProps={{
          iconName: 'health',
          ariaLabel: 'Button to view Dashboard Health',
        }}
        text="Health"
        onClick={() => {
          //combined together navigateToView & openHealthDashboard from objects.js in angular
          if (wsObj.id) {
            const escapedTitle = slugify(wsObj.id);
            const encodedParams = getEncodedParams(escapedTitle, workspace);
            console.log('encodedParams', encodedParams);
            const healthWsId = constants.DashboardViewConfig.DashboardWorkspace;
            const healthDashboardId =
              constants.DashboardViewConfig.HealthDashboardName;

            const { origin } = window.location;
            window.open(
              origin +
                `/#/dashboard/${healthDashboardId}?params=${encodedParams}&newDashFromMenu=false&isSample=false&isVersion=0&isHistory=0&_g=(ws:${healthWsId})`,
              '_blank'
            );
          } else {
            notifier.error('Saved dashboard does not exist');
          }
        }}
      />
    ) : (
      <>{wsObj.description}</>
    );
  };

  const exportSavedObjects = (items: WorkspaceObject[]) => {
    exportObjects(workspace, items).then(async (contents) => {
      let exportProms: Promise<string>[] = [];
      const contentsParsed: SavedObjectBlob[] = JSON.parse(contents);
      // go through all the export items to see which items are dashboards
      contentsParsed.forEach((d) => {
        //if it's a dashboard and we are exporting them in full
        if (d.Type === SavedObjectType.Dashboard && exportFullDash) {
          const panels: WorkspaceObjectBase[] = JSON.parse(d.Body.panelsJSON);
          const inputs: WorkspaceObjectBase[] = JSON.parse(
            d.Body.inputPanelsJSON
          );
          const panelsObjs = panels.map((p) => {
            return {
              Id: p.id,
              Title: p.id,
              Type: p.type,
              Body: {},
            };
          });
          const inputsObjs = inputs.map((i) => {
            return {
              Id: i.id,
              Title: i.id,
              Type: i.type,
              Body: {},
            };
          });

          exportProms.push(
            exportObjects(workspace, [...panelsObjs, ...inputsObjs])
          );
        }
      });

      await Promise.all(exportProms).then((response) => {
        const responseParsed = response
          .map((r) => {
            return JSON.parse(r);
          })
          .flat();
        const returnContent = exportFullDash
          ? JSON.stringify(
              utils.unique([...contentsParsed, ...responseParsed], 'Id'),
              null,
              2
            )
          : contents;
        const blob = new Blob([returnContent], {
          type: 'application/json',
        });
        saveAs(blob, 'export.json');
        notifier.info('Successfully exported objects.');
      });
    });
  };

  const columns: IElxColumn[] = [
    {
      key: 'name',
      name: 'Name',
      onRender: renderObjectName,
      minWidth: 120,
      isResizable: true,
      className: classNames.tableStyles,
    },
    {
      key: 'type',
      name: 'Type',
      fieldName: 'type',
      minWidth: 120,
      isResizable: true,
      className: classNames.tableStyles,
    },
    {
      key: 'id',
      name: 'Id',
      fieldName: 'id',
      minWidth: 120,
      isResizable: true,
      className: classNames.tableStyles,
    },
    {
      key: 'detailsHidden',
      name: '',
      fieldName: 'title',
      minWidth: 0,
      maxWidth: 0,
      isResizable: false,
      className: classNames.hiddenColumnStyles,
      styles: { root: { display: 'none' } },
    },
    {
      key: 'details',
      name: 'Details',
      onRender: renderDetails,
      minWidth: 120,
      isResizable: true,
      className: classNames.tableStyles,
    },
  ];
  const importObjs = () => {
    const onChangeHandler = (fileList: FileList) => {
      try {
        const reader = new FileReader();
        reader.onload = async (e) => {
          const importObjectsJson = e.target?.result as string;

          if (importObjectsJson !== null && importObjectsJson !== undefined) {
            // setObjectJson(importObjectsJson);
            importObjects(workspace, importObjectsJson || '').then(() => {
              //update the list of Saved Objects
              dispatch(getWorkspaceSavedObjects(props.workspaceId));
            });
            return;
          }
        };
        if (fileList && fileList.length > 0) {
          reader.readAsText(fileList[0]);
        }
      } catch (err) {}
    };
    fileOpen(onChangeHandler);
  };

  const savedObjActions: ITableAction[] = [
    {
      key: 'importToWs',
      text: 'Import to Workspace',
      iconProps: { iconName: 'upload' },
      defaultDisplay: DisplayTypes.Show,
      onBulkAction: (items: WorkspaceObject[]) => {
        importObjs();
      },
    },
    {
      key: 'export',
      text: 'Export ',
      iconProps: { iconName: 'export' },
      onAction: (item: WorkspaceObject) => {
        exportSavedObjects([item]);
      },
      onBulkAction: (items: WorkspaceObject[]) => {
        exportSavedObjects(items);
      },
    },
    {
      key: 'copyToWs',
      text: 'Copy to Workspace',
      iconProps: { iconName: 'copy' },
      onAction: (item: WorkspaceObject) => {
        setOpenCopyPanel(true);
      },
      onBulkAction: (items: WorkspaceObject[]) => {
        setOpenCopyPanel(true);
      },
    },
    {
      key: 'delete',
      text: 'Delete',
      iconProps: { iconName: 'delete' },
      onAction: (item: WorkspaceObject) => {
        notifier.info('Deleting: ' + item.title + '...');
        dispatch(
          deleteWorkspaceSavedObject({
            workspaceId: props.workspaceId,
            objects: [item],
          })
        ).then(() => {
          //update the list of Saved Objects
          dispatch(getWorkspaceSavedObjects(props.workspaceId));
        });
      },
      onBulkAction: (items: WorkspaceObject[]) => {
        notifier.info('Deleting: ' + items.map((i) => i.title).join() + '...');
        dispatch(
          deleteWorkspaceSavedObject({
            workspaceId: props.workspaceId,
            objects: items,
          })
        ).then(() => {
          //update the list of Saved Objects
          dispatch(getWorkspaceSavedObjects(props.workspaceId));
        });
      },
    },
  ];

  const recycledObjActions: ITableAction[] = [
    {
      key: 'restore',
      text: 'Restore',
      iconProps: { iconName: 'RecycleBin' },
      onAction: (item: WorkspaceObject) => {
        dispatch(
          restoreRecycledObjects({
            workspaceId: props.workspaceId,
            objects: [item],
          })
        ).then(() => {
          //update both lists
          reloadSavedAndRecylcedData();
        });
      },
      onBulkAction: (items: WorkspaceObject[]) => {
        dispatch(
          restoreRecycledObjects({
            workspaceId: props.workspaceId,
            objects: items,
          })
        ).then(() => {
          //update both lists
          reloadSavedAndRecylcedData();
        });
      },
    },
  ];

  const items: IBreadcrumbItem[] = [
    {
      text: 'Workspace management',
      key: 'workspaceManagement',
      onClick: () => {
        props.dismissPanel();
      },
    },
    {
      text: workspace?.name + ' - Saved Objects',
      key: 'workspaceSavedObjects',
    },
  ];

  return (
    <Panel
      type={PanelType.large}
      isOpen={props.show}
      onDismiss={props.closeQuickSwitchPanel}
      styles={panelStyles}
      onRenderHeader={() => {
        return (
          <Breadcrumb
            items={items}
            maxDisplayedItems={2}
            ariaLabel="Workspace saved objects breadcrumb with items rendered as buttons"
            overflowAriaLabel="More links"
            styles={breadCrumbStyles}
          />
        );
      }}
    >
      <Stack {...panelContentStackStyle}>
        <Stack.Item>
          <div
            style={{
              fontSize: FontSizes.size18,
              fontWeight: FontWeights.semibold,
            }}
          >
            Workspace Saved Object
          </div>
          <div style={descriptionTextStyle}>
            Saved Objects refer to queries, visualizations and dashboards that
            are saved in a given workspace.
          </div>
          <div style={descriptionTextBoldStyle}>
            Note: Recycled objects will be DELETED after 30 days.
          </div>
        </Stack.Item>
        <Pivot aria-label="Workspace Objects Pivot">
          <PivotItem
            key={'savedObjs'}
            headerText={`Saved Objects (${savedObjects.length})`}
          >
            <Stack.Item
              styles={{
                root: {
                  padding: '0px 0px 0px 10px',
                },
              }}
            >
              <Toggle
                label={
                  <LensLabel
                    labelText={'Export dashboard objects with visualizations'}
                    hintText={
                      'If this is not enabled, only the dashboard blob will be downloaded and the visualizations on the dashboards may not show up.'
                    }
                  />
                }
                onText="On"
                offText="Off"
                onChange={() => {
                  setExportFullDash(!exportFullDash);
                }}
              />
            </Stack.Item>
            <Stack.Item grow verticalFill>
              <ElxTableContainer
                containerProps={{
                  compact: true,
                  styles: containerStyles,
                  isLoading: savedObjsStatus !== WorkspaceStatus.Loaded,
                }}
                tableProps={{
                  selectionMode: SelectionMode.multiple,
                  items: savedObjects,
                  columns: columns,
                  actions: savedObjActions,
                  setKey: 'id',
                  selectedItems: selectedObjects as IObjectWithKey[],
                  onSelectionChanged: (values) => {
                    setSelectedObjects(values as WorkspaceObject[]);
                  },
                }}
                searchBoxProps={{
                  pillFilters: [typePillFilter],
                  filterDisplayMode: FilterDisplayMode.Pill,
                }}
              />
            </Stack.Item>
          </PivotItem>

          <PivotItem
            key={'recycledObjs'}
            headerText={`Recycled Objects (${recycledObjects.length})`}
          >
            <ElxTableContainer
              containerProps={{
                compact: true,
                styles: containerStyles,
                isLoading: recyledObjsStatus !== WorkspaceStatus.Loaded,
              }}
              tableProps={{
                selectionMode: SelectionMode.multiple,
                items: recycledObjects,
                columns: columns,
                actions: recycledObjActions,
                setKey: 'id',
              }}
              searchBoxProps={{
                pillFilters: [],
                filterDisplayMode: FilterDisplayMode.Pill,
              }}
            />
          </PivotItem>
        </Pivot>
      </Stack>
      {openCopyPanel && (
        <CopyToWsPanel
          workspaceId={props.workspaceId}
          show={openCopyPanel}
          dismissPanel={() => setOpenCopyPanel(false)}
          openPanel={() => setOpenCopyPanel(true)}
          closeQuickSwitchPanel={props.closeQuickSwitchPanel}
          openParentPanel={props.openPanel}
          closeParentPanel={props.dismissPanel}
          wsObjects={selectedObjects}
        />
      )}
    </Panel>
  );
};

export default SavedObjectsPanel;
