import { registerIcons } from '@fluentui/react';

// IconCloud Icons
// https://www.iconcloud.design/browse/Azure%20Icons
export const registerSidebarIcons = (): void => {
  registerIcons({
    icons: {
      DashboardIcon: (
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0)">
            <path
              d="M0.0598876 2.4901H16.9999C17.1362 2.48464 17.2691 2.53294 17.3702 2.62459C17.4712 2.71625 17.5321 2.84393 17.5399 2.9801V14.7501C17.5321 14.8863 17.4712 15.014 17.3702 15.1056C17.2691 15.1973 17.1362 15.2456 16.9999 15.2401H0.0598876C-0.077428 15.2457 -0.211538 15.1977 -0.31415 15.1063C-0.416763 15.0149 -0.479853 14.8871 -0.490112 14.7501V3.0001C-0.487535 2.93049 -0.471258 2.86206 -0.442214 2.79874C-0.413169 2.73542 -0.371926 2.67845 -0.320843 2.63108C-0.269761 2.58371 -0.209841 2.54688 -0.144512 2.52269C-0.0791825 2.4985 -0.00972506 2.48743 0.0598876 2.4901Z"
              fill="url(#paint0_linear)"
            />
            <path
              d="M6.99997 4H15.09C15.1307 3.99993 15.17 4.0154 15.1997 4.04328C15.2294 4.07115 15.2474 4.10933 15.25 4.15001V8.62001C15.2474 8.66068 15.2294 8.69886 15.1997 8.72673C15.17 8.75461 15.1307 8.77009 15.09 8.77001H6.99997C6.95921 8.77009 6.91996 8.75461 6.89022 8.72673C6.86049 8.69886 6.84251 8.66068 6.83997 8.62001V4.14C6.84486 4.10112 6.86386 4.06539 6.89335 4.03959C6.92284 4.01378 6.96078 3.9997 6.99997 4Z"
              fill="white"
            />
            <path
              d="M5.01 3.99011H1.9C1.81716 3.99011 1.75 4.05727 1.75 4.14011V13.4501C1.75 13.533 1.81716 13.6001 1.9 13.6001H5.01C5.09284 13.6001 5.16 13.533 5.16 13.4501V4.14011C5.16 4.05727 5.09284 3.99011 5.01 3.99011Z"
              fill="white"
            />
            <path
              d="M11.92 10.1201H15.06C15.0998 10.1201 15.138 10.1359 15.1661 10.164C15.1942 10.1921 15.21 10.2303 15.21 10.2701V13.4601C15.2075 13.4981 15.1906 13.5337 15.1627 13.5597C15.1348 13.5857 15.0981 13.6001 15.06 13.6001H11.92C11.8819 13.6001 11.8452 13.5857 11.8173 13.5597C11.7895 13.5337 11.7726 13.4981 11.77 13.4601V10.2701C11.77 10.2303 11.7858 10.1921 11.814 10.164C11.8421 10.1359 11.8802 10.1201 11.92 10.1201Z"
              fill="white"
            />
            <path
              d="M6.94999 10.1201H9.99999C10.0398 10.1201 10.0779 10.1359 10.1061 10.164C10.1342 10.1921 10.15 10.2303 10.15 10.2701V13.4601C10.15 13.4784 10.1464 13.4966 10.1393 13.5136C10.1323 13.5306 10.122 13.546 10.109 13.559C10.096 13.572 10.0806 13.5824 10.0636 13.5894C10.0466 13.5964 10.0284 13.6001 10.01 13.6001H6.99999C6.96021 13.6001 6.92206 13.5843 6.89392 13.5561C6.86579 13.528 6.84999 13.4898 6.84999 13.4501V10.2701C6.84632 10.2373 6.85434 10.2043 6.87263 10.1768C6.89093 10.1494 6.91832 10.1293 6.94999 10.1201Z"
              fill="white"
            />
          </g>
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="8.50989"
              y1="0.580103"
              x2="8.50989"
              y2="17.2701"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.09" stopColor="#6CC6B9" />
              <stop offset="0.46" stopColor="#6AC4B7" />
              <stop offset="0.64" stopColor="#62BDB0" />
              <stop offset="0.77" stopColor="#56B1A4" />
              <stop offset="0.88" stopColor="#439F93" />
              <stop offset="0.98" stopColor="#2C897D" />
              <stop offset="1" stopColor="#258277" />
            </linearGradient>
            <clipPath id="clip0">
              <rect width="18" height="18" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ),
      DatasetIcon: (
        <svg
          width="18"
          height="18"
          viewBox="8 8 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.4267 12.4392C12.1917 12.4392 8.83667 11.3392 8.83667 10.0009V22.9992C8.83667 24.3375 12.21 25.4192 16.39 25.4375H16.5C20.735 25.4375 24.1633 24.3375 24.1633 22.9992V10.0009C24.09 11.3392 20.6617 12.4392 16.4267 12.4392Z"
            fill="url(#paint0_linear)"
          />
          <path
            d="M24.09 10.0008C24.09 11.3391 20.6617 12.4391 16.4267 12.4391C12.1917 12.4391 8.83667 11.3391 8.83667 10.0008C8.83667 8.66244 12.265 7.56244 16.5 7.56244C20.735 7.56244 24.1633 8.64411 24.1633 10.0008"
            fill="#E8E8E8"
          />
          <path
            d="M22.3116 9.85405C22.3116 10.7157 19.6716 11.4124 16.4266 11.4124C13.1816 11.4124 10.5599 10.6607 10.5599 9.85405C10.5599 9.04738 13.1816 8.29572 16.4266 8.29572C19.6716 8.29572 22.3116 8.99238 22.3116 9.85405Z"
            fill="#50E6FF"
          />
          <path
            d="M16.4268 10.1658C14.854 10.1277 13.2843 10.3254 11.7701 10.7525C13.2829 11.1882 14.8529 11.3922 16.4268 11.3575C18.0007 11.3922 19.5707 11.1882 21.0835 10.7525C19.5693 10.3254 17.9996 10.1277 16.4268 10.1658Z"
            fill="#32BEDD"
          />
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="8.76334"
              y1="17.7192"
              x2="24.09"
              y2="17.7192"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#32BEDD" />
              <stop offset="0.06" stopColor="#37C5E3" />
              <stop offset="0.3" stopColor="#49DDF7" />
              <stop offset="0.45" stopColor="#50E6FF" />
              <stop offset="0.55" stopColor="#50E6FF" />
              <stop offset="0.7" stopColor="#49DDF7" />
              <stop offset="0.94" stopColor="#37C5E3" />
              <stop offset="1" stopColor="#32BEDD" />
            </linearGradient>
          </defs>
        </svg>
      ),
      HomeIcon: (
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.36358 0.707077C8.71506 0.316553 9.28492 0.316554 9.63637 0.707078L17.1 8.99997C17.4321 9.369 17.1969 9.99997 16.7272 9.99997C16.4361 9.99997 16.2 10.2623 16.2 10.5858V17C16.2 17.5523 15.7971 18 15.3 18H11.7C11.2029 18 10.8 17.5523 10.8 17V13C10.8 12.4477 10.3971 12 9.89998 12H8.09998C7.60292 12 7.19998 12.4477 7.19998 13V17C7.19998 17.5523 6.79703 18 6.29998 18H2.69998C2.20293 18 1.79998 17.5523 1.79998 17V10.5858C1.79998 10.2623 1.56394 9.99997 1.27277 9.99997C0.80308 9.99997 0.567857 9.369 0.899979 8.99997L8.36358 0.707077Z"
            fill="url(#paint0_linear)"
          />
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="4.84972"
              y1="-0.0596712"
              x2="15.005"
              y2="16.6514"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#00CCF9" />
              <stop offset="1" stopColor="#0095E6" />
            </linearGradient>
          </defs>
        </svg>
      ),
      JobIcon: (
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.5 6.09473H17.5V16.0737C17.5 16.2328 17.4399 16.3854 17.3331 16.4979C17.2262 16.6105 17.0812 16.6737 16.93 16.6737H1.07C0.918827 16.6737 0.773845 16.6105 0.666949 16.4979C0.560053 16.3854 0.5 16.2328 0.5 16.0737V6.09473Z"
            fill="url(#paint0_linear)"
          />
          <path
            d="M1.07 2.28418H16.93C17.0812 2.28418 17.2262 2.34739 17.3331 2.45992C17.4399 2.57244 17.5 2.72505 17.5 2.88418V6.09471H0.5V2.87365C0.502622 2.71635 0.563831 2.56643 0.670444 2.45618C0.777057 2.34592 0.920543 2.28416 1.07 2.28418Z"
            fill="#37C2B1"
          />
          <path
            d="M2.80993 7.25244H15.1799C15.2498 7.25517 15.3159 7.28631 15.3644 7.33932C15.4129 7.39232 15.44 7.46306 15.4399 7.53665V9.01034C15.44 9.08393 15.4129 9.15466 15.3644 9.20767C15.3159 9.26068 15.2498 9.29182 15.1799 9.29455H2.80993C2.74007 9.29182 2.67393 9.26068 2.62544 9.20767C2.57695 9.15466 2.54988 9.08393 2.54993 9.01034V7.53665C2.54988 7.46306 2.57695 7.39232 2.62544 7.33932C2.67393 7.28631 2.74007 7.25517 2.80993 7.25244Z"
            fill="white"
          />
          <path
            d="M2.81998 10.1893H15.19C15.2598 10.1921 15.326 10.2232 15.3745 10.2762C15.423 10.3292 15.45 10.4 15.45 10.4735V11.9577C15.45 12.0313 15.423 12.1021 15.3745 12.1551C15.326 12.2081 15.2598 12.2392 15.19 12.242H2.81998C2.75012 12.2392 2.68398 12.2081 2.63549 12.1551C2.587 12.1021 2.55993 12.0313 2.55998 11.9577V10.5262C2.5527 10.4856 2.55392 10.4438 2.56355 10.4037C2.57319 10.3637 2.591 10.3263 2.61575 10.2943C2.6405 10.2622 2.67159 10.2362 2.70686 10.2181C2.74213 10.2 2.78074 10.1901 2.81998 10.1893Z"
            fill="#37C2B1"
          />
          <path
            d="M2.82006 13.1579H15.1901C15.2599 13.1606 15.3261 13.1918 15.3745 13.2448C15.423 13.2978 15.4501 13.3685 15.4501 13.4421V14.9263C15.4501 14.9999 15.423 15.0706 15.3745 15.1237C15.3261 15.1767 15.2599 15.2078 15.1901 15.2105H2.82006C2.7502 15.2078 2.68406 15.1767 2.63557 15.1237C2.58708 15.0706 2.56001 14.9999 2.56006 14.9263V13.4421C2.56001 13.3685 2.58708 13.2978 2.63557 13.2448C2.68406 13.1918 2.7502 13.1606 2.82006 13.1579Z"
            fill="#258277"
          />
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="9"
              y1="16.6631"
              x2="9"
              y2="6.09473"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#B3B3B3" />
              <stop offset="0.26" stopColor="#C1C1C1" />
              <stop offset="1" stopColor="#E6E6E6" />
            </linearGradient>
          </defs>
        </svg>
      ),
      QueryIcon: (
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.7299 1.62006L10.7399 8.72006L6.51992 4.42006L2.16992 10.0601L3.18992 10.8601L6.63992 6.41006L11.0199 10.8801L15.8599 2.27006L14.7299 1.62006Z"
            fill="#B796F9"
          />
          <path
            d="M6.56981 7.05989C7.47556 7.05989 8.20981 6.32116 8.20981 5.40989C8.20981 4.49862 7.47556 3.75989 6.56981 3.75989C5.66406 3.75989 4.92981 4.49862 4.92981 5.40989C4.92981 6.32116 5.66406 7.05989 6.56981 7.05989Z"
            fill="url(#paint0_linear)"
          />
          <path
            d="M11.02 11.15C11.9258 11.15 12.66 10.4113 12.66 9.50004C12.66 8.58877 11.9258 7.85004 11.02 7.85004C10.1143 7.85004 9.38 8.58877 9.38 9.50004C9.38 10.4113 10.1143 11.15 11.02 11.15Z"
            fill="url(#paint1_linear)"
          />
          <path
            d="M15.1499 3.8C16.0556 3.8 16.7899 3.06127 16.7899 2.15C16.7899 1.23873 16.0556 0.5 15.1499 0.5C14.2441 0.5 13.5099 1.23873 13.5099 2.15C13.5099 3.06127 14.2441 3.8 15.1499 3.8Z"
            fill="url(#paint2_linear)"
          />
          <path
            d="M2.84996 11.8699C3.75571 11.8699 4.48996 11.1312 4.48996 10.2199C4.48996 9.30868 3.75571 8.56995 2.84996 8.56995C1.94421 8.56995 1.20996 9.30868 1.20996 10.2199C1.20996 11.1312 1.94421 11.8699 2.84996 11.8699Z"
            fill="url(#paint3_linear)"
          />
          <path
            d="M7.96992 9.91998H5.94992C5.79528 9.91998 5.66992 10.0453 5.66992 10.2V17.22C5.66992 17.3746 5.79528 17.5 5.94992 17.5H7.96992C8.12456 17.5 8.24992 17.3746 8.24992 17.22V10.2C8.24992 10.0453 8.12456 9.91998 7.96992 9.91998Z"
            fill="#0078D4"
          />
          <path
            d="M12.0802 12.54H10.0602C9.90551 12.54 9.78015 12.6654 9.78015 12.82V17.22C9.78015 17.3747 9.90551 17.5 10.0602 17.5H12.0802C12.2348 17.5 12.3602 17.3747 12.3602 17.22V12.82C12.3602 12.6654 12.2348 12.54 12.0802 12.54Z"
            fill="#005BA1"
          />
          <path
            d="M16.1899 7.37994H14.1699C14.0153 7.37994 13.8899 7.5053 13.8899 7.65994V17.2199C13.8899 17.3746 14.0153 17.4999 14.1699 17.4999H16.1899C16.3445 17.4999 16.4699 17.3746 16.4699 17.2199V7.65994C16.4699 7.5053 16.3445 7.37994 16.1899 7.37994Z"
            fill="#005BA1"
          />
          <path
            d="M3.85981 12.54H1.83981C1.68517 12.54 1.55981 12.6654 1.55981 12.82V17.22C1.55981 17.3747 1.68517 17.5 1.83981 17.5H3.85981C4.01445 17.5 4.13981 17.3747 4.13981 17.22V12.82C4.13981 12.6654 4.01445 12.54 3.85981 12.54Z"
            fill="#5EA0EF"
          />
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="6.56981"
              y1="7.05989"
              x2="6.56981"
              y2="3.75989"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#773ADC" />
              <stop offset="0.25" stopColor="#9255E6" />
              <stop offset="0.5" stopColor="#A669EE" />
              <stop offset="0.76" stopColor="#B376F2" />
              <stop offset="1" stopColor="#B77AF4" />
            </linearGradient>
            <linearGradient
              id="paint1_linear"
              x1="45.5256"
              y1="44.645"
              x2="45.5256"
              y2="33.788"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#773ADC" />
              <stop offset="0.25" stopColor="#9255E6" />
              <stop offset="0.5" stopColor="#A669EE" />
              <stop offset="0.76" stopColor="#B376F2" />
              <stop offset="1" stopColor="#B77AF4" />
            </linearGradient>
            <linearGradient
              id="paint2_linear"
              x1="63.2019"
              y1="13.007"
              x2="63.2019"
              y2="2.15"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#773ADC" />
              <stop offset="0.25" stopColor="#9255E6" />
              <stop offset="0.5" stopColor="#A669EE" />
              <stop offset="0.76" stopColor="#B376F2" />
              <stop offset="1" stopColor="#B77AF4" />
            </linearGradient>
            <linearGradient
              id="paint3_linear"
              x1="10.558"
              y1="47.7079"
              x2="10.558"
              y2="36.8509"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#773ADC" />
              <stop offset="0.25" stopColor="#9255E6" />
              <stop offset="0.5" stopColor="#A669EE" />
              <stop offset="0.76" stopColor="#B376F2" />
              <stop offset="1" stopColor="#B77AF4" />
            </linearGradient>
          </defs>
        </svg>
      ),
      SettingsIcon: (
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.9556 2.73086L11.5251 2.90186L12.051 3.186L14.3036 1.57629L16.425 3.69771L14.8179 5.94771C15.003 6.3 15.1547 6.66514 15.273 7.04571L18 7.49957V10.5004L15.2679 10.9556L15.0956 11.5264L14.814 12.051L16.4237 14.3036L14.3023 16.425L12.0523 14.8179C11.7 15.003 11.3349 15.1547 10.9543 15.273L10.4991 18H7.49957L7.04443 15.2691L6.47357 15.0969L5.949 14.8153L3.69643 16.425L1.575 14.3036L3.18214 12.0536C2.997 11.7 2.84529 11.3349 2.727 10.9543L0 10.4991V7.49957L2.73086 7.04443L2.90186 6.47486L3.18471 5.949L1.575 3.69643L3.69643 1.575L5.94643 3.18214C6.3 2.997 6.66514 2.84657 7.04571 2.727L7.49957 0H10.5004L10.9556 2.73086ZM9.00007 11.844C10.5699 11.844 11.8441 10.5711 11.8441 9.00001C11.8441 7.42886 10.5712 6.15601 9.00007 6.15601C7.42892 6.15601 6.15607 7.43015 6.15607 9.00001C6.15607 10.5699 7.42892 11.844 9.00007 11.844Z"
            fill="url(#paint0_linear)"
          />
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="3.52173"
              y1="2.67393"
              x2="15.8152"
              y2="15.8152"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#37C5E3" />
              <stop offset="0.557292" stopColor="#0078D4" />
            </linearGradient>
          </defs>
        </svg>
      ),
      WorkspaceIcon: (
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.5 5.79001H17.5V15.27C17.5 15.4212 17.4399 15.5662 17.3331 15.6731C17.2262 15.78 17.0812 15.84 16.93 15.84H1.07C0.918827 15.84 0.773845 15.78 0.666949 15.6731C0.560053 15.5662 0.5 15.4212 0.5 15.27V5.79001Z"
            fill="url(#paint0_linear)"
          />
          <path
            d="M1.07 2.17001H16.93C17.0812 2.17001 17.2262 2.23007 17.3331 2.33696C17.4399 2.44386 17.5 2.58884 17.5 2.74001V5.79001H0.5V2.73001C0.502622 2.58058 0.563831 2.43815 0.670444 2.33341C0.777057 2.22867 0.920543 2.16999 1.07 2.17001Z"
            fill="#198AB3"
          />
          <path
            d="M2.81005 6.89001H15.18C15.2499 6.8926 15.316 6.92219 15.3645 6.97255C15.413 7.0229 15.4401 7.09011 15.44 7.16001V8.56002C15.4401 8.62992 15.413 8.69713 15.3645 8.74748C15.316 8.79784 15.2499 8.82743 15.18 8.83001H2.81005C2.74019 8.82743 2.67405 8.79784 2.62556 8.74748C2.57707 8.69713 2.55 8.62992 2.55005 8.56002V7.16001C2.55 7.09011 2.57707 7.0229 2.62556 6.97255C2.67405 6.92219 2.74019 6.8926 2.81005 6.89001Z"
            fill="white"
          />
          <path
            d="M2.81998 9.67999H15.19C15.2598 9.68258 15.326 9.71217 15.3745 9.76252C15.423 9.81288 15.45 9.88008 15.45 9.94999V11.36C15.45 11.4299 15.423 11.4971 15.3745 11.5475C15.326 11.5978 15.2598 11.6274 15.19 11.63H2.81998C2.75012 11.6274 2.68398 11.5978 2.63549 11.5475C2.587 11.4971 2.55993 11.4299 2.55998 11.36V9.99999C2.5527 9.96142 2.55392 9.92173 2.56355 9.88368C2.57319 9.84563 2.591 9.81014 2.61575 9.77968C2.6405 9.74922 2.67159 9.72452 2.70686 9.7073C2.74213 9.69009 2.78074 9.68077 2.81998 9.67999Z"
            fill="#9CEBFF"
          />
          <path
            d="M2.82006 12.5H15.1901C15.2599 12.5026 15.3261 12.5322 15.3745 12.5825C15.423 12.6329 15.4501 12.7001 15.4501 12.77V14.18C15.4501 14.2499 15.423 14.3171 15.3745 14.3675C15.3261 14.4178 15.2599 14.4474 15.1901 14.45H2.82006C2.7502 14.4474 2.68406 14.4178 2.63557 14.3675C2.58708 14.3171 2.56001 14.2499 2.56006 14.18V12.77C2.56001 12.7001 2.58708 12.6329 2.63557 12.5825C2.68406 12.5322 2.7502 12.5026 2.82006 12.5Z"
            fill="#50E6FF"
          />
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="9"
              y1="15.83"
              x2="9"
              y2="5.79001"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#32BEDD" />
              <stop offset="0.18" stopColor="#32CAEA" />
              <stop offset="0.41" stopColor="#32D2F2" />
              <stop offset="0.78" stopColor="#32D4F5" />
            </linearGradient>
          </defs>
        </svg>
      ),
      DataCatalogIcon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <defs>
            <linearGradient
              id="a790cec7-07c9-4638-b5c5-ab9fbd5ca908"
              x1="6.41"
              y1="1.78"
              x2="6.41"
              y2="14.13"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" stopColor="#b3b2b3" />
              <stop offset="0.38" stopColor="#afaeaf" />
              <stop offset="0.76" stopColor="#a2a2a2" />
              <stop offset="1" stopColor="#979797" />
            </linearGradient>
            <linearGradient
              id="b9e6ae88-e13e-4cbb-9678-9c3623cbd024"
              x1="7.85"
              y1="13.09"
              x2="16.6"
              y2="13.09"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" stopColor="#005ba1" />
              <stop offset="0.07" stopColor="#0060a9" />
              <stop offset="0.36" stopColor="#0071c8" />
              <stop offset="0.52" stopColor="#0078d4" />
              <stop offset="0.64" stopColor="#0074cd" />
              <stop offset="0.82" stopColor="#006abb" />
              <stop offset="1" stopColor="#005ba1" />
            </linearGradient>
          </defs>
          <title>Icon-integration-216</title>
          <g>
            <g id="e324a405-82e2-4fb6-93ca-106df2178669">
              <g>
                <path
                  d="M1.42,1.79l1-1.13A.48.48,0,0,1,2.75.5h9.31a.66.66,0,0,1,.71.67V12.79a.48.48,0,0,1-.16.35l-1,.95Z"
                  fill="#999"
                />
                <path
                  d="M1.42,1.79l1-1.13A.48.48,0,0,1,2.75.5h9.31a.66.66,0,0,1,.71.67V12.79a.48.48,0,0,1-.16.35l-1,.95Z"
                  fill="#999"
                  opacity="0.5"
                />
                <path
                  d="M2.2,1.79l.59-.68A.44.44,0,0,1,3.11,1H11.8a.42.42,0,0,1,.42.42V12.43a.44.44,0,0,1-.13.31l-.93.85"
                  fill="#fff"
                />
                <path
                  d="M11.1,1.78H1.45a0,0,0,0,0-.05,0v12a.31.31,0,0,0,.31.31H11.1a.31.31,0,0,0,.31-.31V2.09A.31.31,0,0,0,11.1,1.78Z"
                  fill="url(#a790cec7-07c9-4638-b5c5-ab9fbd5ca908)"
                />
                <g>
                  <path
                    d="M12.22,10.08c-2.41,0-4.37-.62-4.37-1.39v7.42c0,.76,1.92,1.38,4.31,1.39h.06c2.42,0,4.38-.62,4.38-1.39V8.69C16.6,9.46,14.64,10.08,12.22,10.08Z"
                    fill="url(#b9e6ae88-e13e-4cbb-9678-9c3623cbd024)"
                  />
                  <path
                    d="M16.6,8.69c0,.77-2,1.39-4.38,1.39S7.85,9.46,7.85,8.69s2-1.4,4.37-1.4,4.38.63,4.38,1.4"
                    fill="#e8e8e8"
                  />
                  <path
                    d="M15.58,8.58c0,.49-1.51.88-3.36.88s-3.35-.39-3.35-.88,1.5-.89,3.35-.89,3.36.4,3.36.89"
                    fill="#50e6ff"
                  />
                  <path
                    d="M12.22,8.78a8.89,8.89,0,0,0-2.65.34,8.79,8.79,0,0,0,2.65.34,8.86,8.86,0,0,0,2.66-.34A9,9,0,0,0,12.22,8.78Z"
                    fill="#198ab3"
                  />
                </g>
              </g>
            </g>
          </g>
        </svg>
      ),
      VideoIcon: (
        <svg
          id="b9387544-77b0-4ec1-8981-755e4bca3fe4"
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 18 18"
        >
          <defs>
            <linearGradient
              id="b6e39f87-6a57-4936-b4f3-e833ade45473"
              x1="9"
              y1="788.2393"
              x2="9"
              y2="773.7214"
              gradientTransform="matrix(1, 0, 0, -1, 0, 792)"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.117" stopColor="#5ea0ef" />
              <stop offset="0.271" stopColor="#4595e8" />
              <stop offset="0.531" stopColor="#1f85dd" />
              <stop offset="0.741" stopColor="#087cd6" />
              <stop offset="0.873" stopColor="#0078d4" />
            </linearGradient>
            <radialGradient
              id="fd54fdfd-a202-431e-8cf4-7061f5675dbe"
              cx="9.6322"
              cy="11.9097"
              r="3.7977"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.0043" stopColor="#5ea0ef" />
              <stop offset="0.4876" stopColor="#5ea0ef" />
              <stop offset="0.781" stopColor="#76b0f5" />
              <stop offset="1" stopColor="#83b9f9" />
            </radialGradient>
            <linearGradient
              id="a0bf7507-e4c9-4c87-be90-7ff950477934"
              x1="9.0263"
              y1="787.1198"
              x2="8.9803"
              y2="782.9771"
              gradientTransform="matrix(1, 0, 0, -1, 0, 792)"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" stopColor="#0078d4" />
              <stop offset="1" stopColor="#005ba1" />
            </linearGradient>
            <linearGradient
              id="fa5e590a-3f3b-4c46-83d8-de9639f2ae4c"
              x1="45.9236"
              y1="802.9558"
              x2="45.9236"
              y2="799.9289"
              gradientTransform="matrix(-0.9732, 0.2301, 0.2301, 0.9732, -131.1437, -787.358)"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" stopColor="#005ba1" />
              <stop offset="1" stopColor="#0078d4" />
            </linearGradient>
          </defs>
          <path
            d="M17,6V17.4375A.5626.5626,0,0,1,16.4372,18H1.5628A.5626.5626,0,0,1,1,17.4375V6Z"
            fill="url(#b6e39f87-6a57-4936-b4f3-e833ade45473)"
          />
          <polygon
            points="13.856 12.89 9.606 11.64 9.5 12 9.574 11.632 5.824 10.882 5.676 11.618 8.316 12.146 4.589 13.911 4.911 14.589 9.216 12.549 9.881 16.315 10.619 16.185 9.974 12.531 13.644 13.61 13.856 12.89"
            fill="url(#fd54fdfd-a202-431e-8cf4-7061f5675dbe)"
          />
          <path
            d="M1,6H17V8.5H1Z"
            fill="url(#a0bf7507-e4c9-4c87-be90-7ff950477934)"
          />
          <path
            d="M3,6,2,8H4L5,6ZM7,6,6,8H8L9,6Zm4,0L10,8h2l1-2Zm4,0L14,8h2l1-2Z"
            fill="#5ea0ef"
          />
          <g>
            <path
              d="M1,6.0108.5543,4.1246a.5626.5626,0,0,1,.418-.6771L15.4411.026a.5625.5625,0,0,1,.6768.4181L16.5636,2.33Z"
              fill="url(#fa5e590a-3f3b-4c46-83d8-de9639f2ae4c)"
            />
            <path
              d="M15.13.6134,16.5636,2.33l-1.9463.46L13.184,1.0737Zm-3.8927.9206,1.4333,1.7168-1.9464.46L9.2913,1.9942Zm-3.8927.92L8.7782,4.1714l-1.9464.46L5.3985,2.9148ZM3.4522,3.375,4.8855,5.0919l-1.9464.46L1.5058,3.8353Z"
              fill="#5ea0ef"
            />
          </g>
          <circle cx="5.75" cy="11.25" r="0.75" fill="#50e6ff" />
          <circle cx="5.75" cy="11.25" r="0.75" fill="#9cebff" />
          <circle cx="4.75" cy="14.25" r="0.75" fill="#9cebff" />
          <circle cx="10.25" cy="16.25" r="0.75" fill="#9cebff" />
          <circle cx="13.75" cy="13.25" r="0.75" fill="#9cebff" />
          <path
            d="M12.1853,11.8252l-3.8882-2.16A.2.2,0,0,0,8,9.84v4.32a.2.2,0,0,0,.2971.1748l3.8882-2.16A.2.2,0,0,0,12.1853,11.8252Z"
            fill="#c3f1ff"
          />
        </svg>
      ),
      SupportIcon: (
        <svg
          id="b67d27d7-b238-4cb0-bc90-a803a2c4fe4a"
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 18 18"
        >
          <defs>
            <linearGradient
              id="ba9d1e8e-19b8-4782-ad3f-f688d67182ec"
              x1="8.67"
              y1="8.09"
              x2="8.67"
              y2="20.18"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.22" stopColor="#0078D4" />
              <stop offset="1" stopColor="#5EA0EF" />
            </linearGradient>
            <linearGradient
              id="ae99c3ef-995c-40af-9fc5-5a2da5013186"
              x1="8.33"
              y1="1.61"
              x2="9.21"
              y2="12.59"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.22" stopColor="#0078D4" />
              <stop offset="1" stopColor="#5EA0EF" />
            </linearGradient>
          </defs>
          <title>Icon-general-13</title>
          <path
            d="M14.65,18A1.3,1.3,0,0,0,16,16.71v-.16c-.51-4.07-2.84-7.38-7.27-7.38S1.85,12,1.4,16.56A1.29,1.29,0,0,0,2.56,18H14.65Z"
            fill="url(#ba9d1e8e-19b8-4782-ad3f-f688d67182ec)"
          />
          <path
            d="M8.68,10.13a4,4,0,0,1-2.21-.65L8.66,15.2l2.18-5.68A4.09,4.09,0,0,1,8.68,10.13Z"
            fill="#fff"
            opacity="0.8"
          />
          <circle
            cx="8.68"
            cy="6.05"
            r="4.08"
            fill="url(#ae99c3ef-995c-40af-9fc5-5a2da5013186)"
          />
          <path
            d="M14.8,7.23a6.26,6.26,0,0,0-.09-2.55A6.19,6.19,0,0,0,8.89,0,5.53,5.53,0,0,0,3.68,2.77a.59.59,0,0,0,.18.81h0a.54.54,0,0,0,.74-.16A4.53,4.53,0,0,1,8.82,1.13a5.1,5.1,0,0,1,4.76,3.68A5.34,5.34,0,0,1,13.71,7a.93.93,0,0,0-.54.59l-.27.9a.94.94,0,0,0,.62,1.17A1,1,0,0,0,14.7,9l.27-.9A.92.92,0,0,0,14.8,7.23Z"
            fill="#767676"
          />
        </svg>
      ),
      ConnectIcon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 18 18"
        >
          <g id="canvas" opacity="0" fill="none">
            <path d="M16,16H0V0H16Z" opacity="0" fill="none" />
          </g>
          <g id="level-1">
            <path
              d="M14.5,3.47V11.5c0,.829-1.791,1.5-4,1.5-1.947,0-3.568-.521-3.926-1.212A4,4,0,0,0,7,9.986V6.014H6.5V3.47c0-.828,1.791-1.5,4-1.5S14.5,2.642,14.5,3.47Z"
              fill="#0077a0"
              opacity="0.1"
            />
            <path
              d="M6,3.47V6.014H7V4.761a7.863,7.863,0,0,0,3.5.709A7.863,7.863,0,0,0,14,4.761V11.5c0,.29-1.227,1-3.5,1S7,11.79,7,11.5H6.705a7.449,7.449,0,0,1-.412.762c.682.816,2.453,1.238,4.207,1.238,2.236,0,4.5-.686,4.5-2V3.47C15,.843,6,.843,6,3.47Zm4.5,1c-2.273,0-3.5-.71-3.5-1s1.227-1,3.5-1,3.5.71,3.5,1S12.773,4.47,10.5,4.47Z"
              fill="#0077a0"
            />
            <path
              d="M3,12.487H3a2.5,2.5,0,0,1-2.5-2.5V7.513h5V9.987A2.5,2.5,0,0,1,3,12.487Z"
              fill="#006758"
              opacity="0.1"
            />
            <path
              d="M5,7.013v-2a.5.5,0,0,0-1,0v2H2v-2a.5.5,0,0,0-1,0v2H0V9.987a3,3,0,0,0,2.5,2.957v3.069h1V12.944A3,3,0,0,0,6,9.987V7.013ZM5,9.987a2,2,0,0,1-4,0V8.013H5Z"
              fill="#006758"
            />
          </g>
        </svg>
      ),
      DeployIcon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 2048 2048"
        >
          <path
            d="M960 870l301 301-90 90-147-146v677H896v-677l-147 146-90-90 301-301zM768 128h512v512H768V128zm128 384h256V256H896v256zM128 128h512v512H128V128zm1792 0v512h-512V128h512z"
            fill="#0078D4"
          />
        </svg>
      ),
      SampleDashboardIcon: (
        <svg
          width="30"
          height="30"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0)">
            <path
              d="M0.0598876 2.4901H16.9999C17.1362 2.48464 17.2691 2.53294 17.3702 2.62459C17.4712 2.71625 17.5321 2.84393 17.5399 2.9801V14.7501C17.5321 14.8863 17.4712 15.014 17.3702 15.1056C17.2691 15.1973 17.1362 15.2456 16.9999 15.2401H0.0598876C-0.077428 15.2457 -0.211538 15.1977 -0.31415 15.1063C-0.416763 15.0149 -0.479853 14.8871 -0.490112 14.7501V3.0001C-0.487535 2.93049 -0.471258 2.86206 -0.442214 2.79874C-0.413169 2.73542 -0.371926 2.67845 -0.320843 2.63108C-0.269761 2.58371 -0.209841 2.54688 -0.144512 2.52269C-0.0791825 2.4985 -0.00972506 2.48743 0.0598876 2.4901Z"
              fill="url(#paint0_linear)"
            />
            <path
              d="M6.99997 4H15.09C15.1307 3.99993 15.17 4.0154 15.1997 4.04328C15.2294 4.07115 15.2474 4.10933 15.25 4.15001V8.62001C15.2474 8.66068 15.2294 8.69886 15.1997 8.72673C15.17 8.75461 15.1307 8.77009 15.09 8.77001H6.99997C6.95921 8.77009 6.91996 8.75461 6.89022 8.72673C6.86049 8.69886 6.84251 8.66068 6.83997 8.62001V4.14C6.84486 4.10112 6.86386 4.06539 6.89335 4.03959C6.92284 4.01378 6.96078 3.9997 6.99997 4Z"
              fill="white"
            />
            <path
              d="M5.01 3.99011H1.9C1.81716 3.99011 1.75 4.05727 1.75 4.14011V13.4501C1.75 13.533 1.81716 13.6001 1.9 13.6001H5.01C5.09284 13.6001 5.16 13.533 5.16 13.4501V4.14011C5.16 4.05727 5.09284 3.99011 5.01 3.99011Z"
              fill="white"
            />
            <path
              d="M11.92 10.1201H15.06C15.0998 10.1201 15.138 10.1359 15.1661 10.164C15.1942 10.1921 15.21 10.2303 15.21 10.2701V13.4601C15.2075 13.4981 15.1906 13.5337 15.1627 13.5597C15.1348 13.5857 15.0981 13.6001 15.06 13.6001H11.92C11.8819 13.6001 11.8452 13.5857 11.8173 13.5597C11.7895 13.5337 11.7726 13.4981 11.77 13.4601V10.2701C11.77 10.2303 11.7858 10.1921 11.814 10.164C11.8421 10.1359 11.8802 10.1201 11.92 10.1201Z"
              fill="white"
            />
            <path
              d="M6.94999 10.1201H9.99999C10.0398 10.1201 10.0779 10.1359 10.1061 10.164C10.1342 10.1921 10.15 10.2303 10.15 10.2701V13.4601C10.15 13.4784 10.1464 13.4966 10.1393 13.5136C10.1323 13.5306 10.122 13.546 10.109 13.559C10.096 13.572 10.0806 13.5824 10.0636 13.5894C10.0466 13.5964 10.0284 13.6001 10.01 13.6001H6.99999C6.96021 13.6001 6.92206 13.5843 6.89392 13.5561C6.86579 13.528 6.84999 13.4898 6.84999 13.4501V10.2701C6.84632 10.2373 6.85434 10.2043 6.87263 10.1768C6.89093 10.1494 6.91832 10.1293 6.94999 10.1201Z"
              fill="white"
            />
          </g>
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="8.50989"
              y1="0.580103"
              x2="8.50989"
              y2="17.2701"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.09" stopColor="#6CC6B9" />
              <stop offset="0.46" stopColor="#6AC4B7" />
              <stop offset="0.64" stopColor="#62BDB0" />
              <stop offset="0.77" stopColor="#56B1A4" />
              <stop offset="0.88" stopColor="#439F93" />
              <stop offset="0.98" stopColor="#2C897D" />
              <stop offset="1" stopColor="#258277" />
            </linearGradient>
            <clipPath id="clip0">
              <rect width="18" height="18" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ),
    },
  });
};
