import { _ } from 'utils/sharedLibs';
import { $laConstants } from './constants';
import LeWebApi from 'api/leWebApi';

interface MetaDataCache {
  [key: string]: any;
}
/**
 * @name CosmosMetadata
 * @description This service is responsible of fetching cosmos metadata information.
 */
class CosmosMetadata extends LeWebApi {
  private metaDataCache: MetaDataCache = {
    [$laConstants.DataSourceTypes.COSMOS]: {},
  };

  //gets the cosmos virtual
  private getCosmosVirtualClusters(cluster: string) {
    const url = '/cosmos/' + cluster + '/info';
    return new Promise<any>((resolve, reject) => {
      this.getEntity(url)
        .then((resp: any) => {
          this.metaDataCache[$laConstants.DataSourceTypes.COSMOS][cluster] =
            resp;
          resolve(
            this.metaDataCache[$laConstants.DataSourceTypes.COSMOS][cluster]
          );
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  public getCosmosVCs(cluster: string, hardRefresh?: boolean) {
    return new Promise<any>((resolve, reject) => {
      const obj = this.metaDataCache[$laConstants.DataSourceTypes.COSMOS];
      if (hardRefresh || _.isUndefined(obj[cluster])) {
        this.getCosmosVirtualClusters(cluster).then(
          () => {
            resolve(obj[cluster]);
          },
          (err: any) => {
            reject(err);
          }
        );
      } else {
        resolve(obj[cluster]);
      }
    });
  }
}

export default CosmosMetadata;
