export interface GrafanaOnboardingData {
  aadTenantId?: string;
  id?: string;
  oid?: string;
  workspaceId?: string;
  name: string;
  subscriptionId: string;
  subscriptionName: string;
  resourceGroupName: string;
  location: string;
  ttl?: string;
  endpoint: string;
}

export interface GrafanaOnboardingDataResponseItem {
  grafanaOnboardingData: GrafanaOnboardingData;
  statusCode: number;
  message: string;
}

export interface GrafanaOnboardingDataResponse {
  allSucceeded: boolean;
  grafanaOnboardingDataResponseItems: GrafanaOnboardingDataResponseItem[];
}

export interface GrafanaMigrationDataResponse {
  allSucceeded: boolean;
  grafanaOnboardingDataResponseItems: GrafanaMigrationResponseItem[];
}

export interface GrafanaMigrationResponseItem {
  id: string;
  slug: string;
  status: string;
  uid: string;
  url: string;
  version: string;
}

export enum GrafanaOnboardingDataStatus {
  None = 'None',
  Loading = 'Loading',
  Loaded = 'Loaded',
  Error = 'Error',
}
