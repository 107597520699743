import * as react from '@fluentui/react';

import {
  DisplayTypes,
  ElxPivot,
  ElxSearchBox,
  ElxSecondaryButton,
  ElxTable,
  ElxTableCommandBar,
  ElxTableContainer,
  FilterDisplayMode,
  IPickerItem,
  ITableAction,
  FilterOptionPillMode,
  IElxColumn,
} from '@elixir/components';

import { Stack, StackItem } from '@fluentui/react';
import { ThemeProvider } from '@fluentui/react';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import { AppDispatch } from 'app/lensShellUtility';
import ErrorMessageBar from 'components/errorMessageBar/errorMessageBar';
import { useLensShellTheme } from 'features/shell/lensShellStyles';
import { selectWorkspaceId } from 'features/workspaces/workspaceSlice';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import notifier from 'utils/notifier';
import {
  cancelJobInstance,
  cancelJobInstances,
  getJobInstances,
  JobInstanceListStatus,
  rerunJobInstance,
  selectAllJobInstances,
  selectJobInstanceStatus,
  terminateJobInstance,
} from '../jobSlice';
import { Job, JobExecutionStatus, JobInstance } from '../models/job';
import {
  getRelativeExecutionTime,
  getRelativeQueueTime,
  getTimeLabel,
} from '../utils/jobUtils';
import BackfillInstances from './backfillInstances';
import JobHistory from './jobHistory';
import JobInstanceLog from './jobInstanceLog';
import { colLarge, colMedium, colSmall, tableStyles } from 'utils/sharedStyles';
import Dependencies from './dependencies';

interface JobInstanceListProps {
  onCancel: () => void;
  jobs: Job[];
  index: number;
}

let currJobId = '';
const StringOperators = ['==', '!=', 'Contains'];
let ignoreParams = false;

const JobInstanceList = (props: JobInstanceListProps) => {
  const [selectedItems, setSelectedItems] = useState<JobInstance[]>([]);
  const [index, setIndex] = useState<number>(props?.index || 0);

  const jobInstanceListStatus = useSelector(selectJobInstanceStatus);
  const workspaceId = useSelector(selectWorkspaceId);

  const jobInstanceList = useSelector(selectAllJobInstances);
  const dispatch = useDispatch<AppDispatch>();
  const cancelInstances = (
    workspaceId: string,
    jobId: string,
    listType: JobExecutionStatus
  ) => {
    dispatch(cancelJobInstances({ workspaceId, jobId, listType }));
  };

  const moreThanOneSelected = selectedItems.length > 1;
  const noneSelected = selectedItems.length === 0;

  const [showInstanceLog, setShowInstanceLog] = useState<boolean>(false);

  const selection = useMemo(
    () =>
      new react.Selection({
        selectionMode: react.SelectionMode.multiple,
        getKey: (item: any) => item.id,
        onSelectionChanged: (): void =>
          setSelectedItems(selection.getSelection()),
      }),
    []
  );

  const params: any = useParams();

  if (!ignoreParams && jobInstanceList.length > 0 && params.instanceId) {
    let j: JobInstance[] = jobInstanceList.filter(
      (o) => o.id === params.instanceId
    );
    if (j.length !== 1) {
      notifier.error(
        `Job Instance ${params.instanceId} not found for the job ${params.jobId}`
      );
    } else {
      setSelectedItems(j);
      setIndex(0);
      setShowInstanceLog(true);
      ignoreParams = true;
    }
  }
  const divElxTableContaierRef = useRef(null);
  const tabStates = ['Scheduled', 'BackFill', 'Dependencies'];
  const [tabSelectedKey, setTabSelectedKey] = useState<string>(tabStates[0]);

  const tabItems = tabStates.map((t) => ({ itemKey: t, headerText: t }));
  // #region columns

  const columns: IElxColumn[] = [
    {
      ...colLarge,
      key: 'Instance Id',
      name: 'Instance Id',
      fieldName: 'id',
    },

    {
      ...colMedium,
      key: 'Start time',
      name: 'Start time',
      fieldName: 'startTime',
      onRender: (jobInstance: JobInstance) => {
        return <span>{getTimeLabel(new Date(jobInstance.startTime))}</span>;
      },
    },
    {
      ...colMedium,
      key: 'End time',
      name: 'End time',
      fieldName: 'endTime',
      onRender: (jobInstance: JobInstance) => {
        return <span>{getTimeLabel(new Date(jobInstance.endTime))}</span>;
      },
    },
    {
      ...colSmall,
      key: 'Queue time',
      name: 'Queue time',
      fieldName: 'elapsedTime',
      onRender: (jobInstance: JobInstance) => {
        return <span>{getRelativeQueueTime(jobInstance)}</span>;
      },
    },
    {
      ...colLarge,
      key: 'Execution time',
      name: 'Execution time',
      fieldName: 'elapsedTime',
      onRender: (jobInstance: JobInstance) => {
        return <span>{getRelativeExecutionTime(jobInstance)}</span>;
      },
    },
    {
      ...colLarge,
      key: 'createdBy',
      name: 'Created By',
      fieldName: 'createdBy',
    },
    {
      ...colMedium,
      key: 'InstanceStartTime',
      name: 'Instance StartTime',
      onRender: (jobInstance: JobInstance) => {
        return (
          <span>
            {getTimeLabel(new Date(jobInstance.parameters.startDateTime))}
          </span>
        );
      },
    },
    {
      ...colMedium,
      key: 'InstanceEndTime',
      name: 'Instance EndTime',
      onRender: (jobInstance: JobInstance) => {
        return (
          <span>
            {getTimeLabel(new Date(jobInstance.parameters.endDateTime))}
          </span>
        );
      },
    },
  ];
  // #endregion columns
  const actions: ITableAction[] = [
    {
      key: 'refresh',
      text: 'Refresh',
      defaultDisplay: DisplayTypes.Show,
      iconProps: { iconName: 'Refresh' },
      disableBulkAction: false,
      onBulkAction: () => {
        dispatch(getJobInstances({ workspaceId, jobId: props.jobs[index].id }));
      },
    },
    {
      key: 'view logs',
      text: 'View Logs',
      defaultDisplay: DisplayTypes.Show,
      iconProps: { iconName: 'BulletedList' },
      disabled: noneSelected,
      disableBulkAction: false,
      onBulkAction: () => {
        setShowInstanceLog(true);
      },
    },
    {
      key: 'cancel all',
      text: 'Cancel All',
      defaultDisplay:
        tabSelectedKey === tabStates[0] ? DisplayTypes.Show : DisplayTypes.Hide,
      iconProps: { iconName: 'Cancel' },
      disableBulkAction: false,
      onBulkAction: () => {
        cancelInstances(
          workspaceId,
          props.jobs[index].id,
          JobExecutionStatus.QUEUED
        );
        cancelInstances(
          workspaceId,
          props.jobs[index].id,
          JobExecutionStatus.RUNNING
        );
        cancelInstances(
          workspaceId,
          props.jobs[index].id,
          JobExecutionStatus.RETRY
        );
      },
    },
    {
      key: 'cancel all queued',
      text: 'Cancel All Queued',
      defaultDisplay:
        tabSelectedKey === tabStates[0] ? DisplayTypes.Show : DisplayTypes.Hide,
      iconProps: { iconName: 'Cancel' },
      disableBulkAction: false,
      onBulkAction: () => {
        cancelInstances(
          workspaceId,
          props.jobs[index].id,
          JobExecutionStatus.QUEUED
        );
      },
    },
    {
      key: 'cancel all running',
      text: 'Cancel All Running',
      defaultDisplay:
        tabSelectedKey === tabStates[0] ? DisplayTypes.Show : DisplayTypes.Hide,
      iconProps: { iconName: 'Cancel' },
      disableBulkAction: false,
      onBulkAction: () => {
        cancelInstances(
          workspaceId,
          props.jobs[index].id,
          JobExecutionStatus.RUNNING
        );
      },
    },
    {
      key: 'cancel all retry',
      text: 'Cancel All Retry',
      defaultDisplay:
        tabSelectedKey === tabStates[0] ? DisplayTypes.Show : DisplayTypes.Hide,
      iconProps: { iconName: 'Cancel' },
      disableBulkAction: false,
      onBulkAction: () => {
        cancelInstances(
          workspaceId,
          props.jobs[index].id,
          JobExecutionStatus.RETRY
        );
      },
    },
    {
      key: 'rerun instance',
      text: 'Rerun Instance',
      defaultDisplay: DisplayTypes.Show,
      iconProps: { iconName: 'PlaybackRate1x' },
      disabled: noneSelected || moreThanOneSelected,
      disableBulkAction: false,
      onBulkAction: (items: JobInstance[]) => {
        dispatch(
          rerunJobInstance({
            workspaceId,
            jobId: props.jobs[index].id,
            instanceId: items[0].id,
          })
        );
      },
    },
    {
      key: 'cancel instance',
      text: 'Cancel Instance',
      defaultDisplay: DisplayTypes.Show,
      iconProps: { iconName: 'Cancel' },
      disabled: noneSelected || moreThanOneSelected,
      disableBulkAction: false,
      onBulkAction: (items: JobInstance[]) => {
        dispatch(
          cancelJobInstance({
            workspaceId,
            jobId: props.jobs[index].id,
            instanceId: items[0].id,
          })
        );
      },
    },
    {
      key: 'terminate instance',
      text: 'Terminate Instance',
      defaultDisplay: DisplayTypes.Show,
      disabled: noneSelected || moreThanOneSelected,
      iconProps: { iconName: 'CircleStop' },
      disableBulkAction: false,
      onBulkAction: (items: JobInstance[]) => {
        dispatch(
          terminateJobInstance({
            workspaceId,
            jobId: props.jobs[index].id,
            instanceId: items[0].id,
          })
        );
      },
    },
    {
      key: 'columns',
      text: 'Columns',
      defaultDisplay: DisplayTypes.Show,
      iconProps: { iconName: 'Settings' },
      disableBulkAction: false,
      onBulkAction: () => {
        let elm = divElxTableContaierRef.current! as HTMLElement;
        let e = elm.querySelector("[data-icon-name='Settings']") as HTMLElement;
        e.click();
      },
      onAction: () => {
        let elm = divElxTableContaierRef.current! as HTMLElement;
        let e = elm.querySelector("[data-icon-name='Settings']") as HTMLElement;
        e.click();
      },
    },
  ];

  const searchProps = {
    pillFilters: [
      {
        field: 'startTime',
        label: 'Start time',
        multiselect: true,
        // operators: StringOperators,
        values: jobInstanceList
          .map((jobInstance) => jobInstance.startTime)
          .filter((value, index, self) => self.indexOf(value) === index),
        pillMode: FilterOptionPillMode.Dynamic,
      },
      {
        field: 'createdBy',
        label: 'Created By',
        multiselect: true,
        operators: StringOperators,
        pillMode: FilterOptionPillMode.Static,
      },
      {
        field: 'status',
        label: 'Status',
        // operators: StringOperators,
        values: jobInstanceList
          .map((jobInstance) => jobInstance.status)
          .filter((value, index, self) => self.indexOf(value) === index),
        pillMode: FilterOptionPillMode.Dynamic,
      },
      {
        field: 'endTime',
        label: 'End time',
        multiselect: true,
        // operators: StringOperators,
        values: jobInstanceList
          .map((jobInstance) => jobInstance.endTime)
          .filter((value, index, self) => self.indexOf(value) === index),
        pillMode: FilterOptionPillMode.Dynamic,
      },
    ],
    filterDisplayMode: FilterDisplayMode.Pill,
  };

  const onRenderFooterContent = useCallback(
    () => (
      <>
        {' '}
        <ElxSecondaryButton
          disabled={!(index > 0)}
          iconProps={{ iconName: 'ChevronLeft' }}
          text={'Previous Job'}
          onClick={() => setIndex(index - 1)}
          styles={{ root: { marginLeft: '6px', marginRight: '6px' } }}
        ></ElxSecondaryButton>
        <ElxSecondaryButton
          disabled={!(index < props.jobs.length - 1)}
          iconProps={{ iconName: 'ChevronRight' }}
          text={'Next Job'}
          onClick={() => setIndex(index + 1)}
          styles={{ root: { marginLeft: '6px', marginRight: '6px' } }}
        ></ElxSecondaryButton>
        <ElxSecondaryButton
          text={'Close'}
          onClick={(ev) => {
            ev.preventDefault();
            props.onCancel();
          }}
        ></ElxSecondaryButton>
      </>
    ),
    [props, index]
  );

  const customColumns: IPickerItem[] = columns.map((o) => ({
    key: o.key,
    text: o.name,
    selected: true,
  }));

  const [customSelectedKeys, setCustomSelectedKeys] = useState<string[]>(
    customColumns.map((o) => o.key)
  );

  // const [selectedJob] = useState<Job>(props.job); // used when we need to select different job instances for now just leave as is.
  // const [jobInstances, setJobInstances] = useState<JobInstance[]>([]);

  useEffect(() => {
    if (currJobId === props.jobs[index].id) return;
    dispatch(getJobInstances({ workspaceId, jobId: props.jobs[index].id }));
  }, [workspaceId, props.jobs, dispatch, index]);

  const toggleTableComponent = false;
  const theme = useLensShellTheme();

  return (
    <ThemeProvider theme={theme}>
      {' '}
      <Panel
        onDismiss={(ev) => {
          ev?.preventDefault();
          props.onCancel();
        }}
        onOuterClick={(ev) => {
          ev?.preventDefault();
        }}
        isOpen={true}
        isFooterAtBottom={true}
        headerText={`${props.jobs[index].name} Instance`}
        closeButtonAriaLabel={'Close'}
        type={PanelType.custom}
        customWidth="1260px"
        onRenderFooterContent={onRenderFooterContent}
        hasCloseButton={true}
        styles={{
          footerInner: { float: 'right' },
          footer: {
            borderTop: '1px solid lightgrey',
            backgroundColor: theme.palette.neutralLighter,
          },
          content: { background: 'white', padding: '0px' },
          main: { background: 'white !important' },
          scrollableContent: { overflowY: 'hidden !important' },
        }}
      >
        <Stack styles={{ root: { padding: '0px' } }}>
          <StackItem>
            <ElxPivot
              selectedKey={tabSelectedKey}
              onItemSelect={setTabSelectedKey}
              items={tabItems}
              styles={{
                root: {
                  borderBottom: '1px solid lightgrey',
                  position: 'sticky',
                },
              }}
            ></ElxPivot>
          </StackItem>
          {tabSelectedKey === tabStates[0] && (
            <StackItem styles={{ root: { height: '260px' } }}>
              <JobHistory
                jobInstances={jobInstanceList}
                logViewHandler={(idx: number) => {
                  setSelectedItems([jobInstanceList[idx]]);
                  setShowInstanceLog(true);
                }}
              ></JobHistory>
            </StackItem>
          )}
          {!toggleTableComponent && (
            <StackItem>
              {jobInstanceListStatus === JobInstanceListStatus.Error && (
                <ErrorMessageBar
                  message={'ERROR GETTING JOBS'}
                  onDismiss={() => {}}
                ></ErrorMessageBar>
              )}
              {tabSelectedKey === tabStates[0] && (
                <div ref={divElxTableContaierRef}>
                  <ElxTableContainer
                    containerProps={{
                      compact: true,
                      styles: {
                        headerContainer: { height: '0px !important' },
                        headerContent: {
                          marginTop: '0px !important',
                          paddingTop: '32px !important',
                        },
                        headerComponent: { paddingTop: '0px !important' },
                        loading: { paddingTop: '300px' },
                        body: {
                          '.elx-table': {
                            marginLeft: '0px !important',
                            marginRight: '0px !important',
                          },
                          minHeight: '200px',
                          maxHeight: '1000px',
                        },
                      },
                      isLoading:
                        jobInstanceListStatus === JobInstanceListStatus.Loading,
                    }}
                    tableProps={{
                      columns,
                      actions,
                      selectionMode: react.SelectionMode.multiple,
                      items: (jobInstanceList as any[]) || [],
                      selectedItems: selectedItems,
                      getKey: (item: JobInstance) => item.id,
                      setKey: 'id',
                      onSelectionChanged: (val: react.IObjectWithKey[]) => {
                        setSelectedItems(val as JobInstance[]);
                      },
                      styles: tableStyles,
                    }}
                    searchBoxProps={searchProps}
                    customColumnProps={{
                      items: customColumns,
                      selectedItemKeys: customSelectedKeys,
                      onSave: setCustomSelectedKeys,
                    }}
                  ></ElxTableContainer>
                </div>
              )}
              {tabSelectedKey === tabStates[1] && (
                <BackfillInstances
                  workspaceId={workspaceId}
                  jobId={props.jobs[index].id}
                  onCancel={props.onCancel}
                ></BackfillInstances>
              )}
              {tabSelectedKey === tabStates[2] && (
                <Dependencies
                  workspaceId={workspaceId}
                  jobId={props.jobs[index].id}
                ></Dependencies>
              )}
            </StackItem>
          )}
          {toggleTableComponent && (
            <>
              <StackItem>
                {tabSelectedKey === tabStates[0] && (
                  <div
                    style={{
                      borderBottom: '1px solid lightgray',
                      position: 'sticky',
                    }}
                  >
                    <ElxTableCommandBar
                      selectedItems={selectedItems}
                      actions={actions}
                    ></ElxTableCommandBar>
                  </div>
                )}
              </StackItem>
              <StackItem>
                {tabSelectedKey === tabStates[0] && (
                  <ElxSearchBox
                    filterDisplayMode={FilterDisplayMode.Pill}
                    pillFilters={searchProps.pillFilters}
                    styles={{
                      root: {
                        paddingTop: '20px',
                        paddingLeft: '24px',
                        position: 'sticky',
                      },
                    }}
                  ></ElxSearchBox>
                )}
              </StackItem>
              <StackItem>
                {tabSelectedKey === tabStates[0] &&
                  jobInstanceListStatus === JobInstanceListStatus.Loaded && (
                    <ElxTable
                      columns={columns}
                      actions={actions}
                      items={jobInstanceList as any[]}
                      stickyHeader={true}
                      selectedItems={selectedItems as react.IObjectWithKey[]}
                      selection={selection}
                      compact={true}
                      styles={tableStyles}
                    ></ElxTable>
                  )}
                {tabSelectedKey === tabStates[0] &&
                  jobInstanceListStatus === JobInstanceListStatus.Loading && (
                    <react.Spinner></react.Spinner>
                  )}
              </StackItem>
              {tabSelectedKey === tabStates[1] && <p>backfill stuff</p>}
            </>
          )}
        </Stack>
      </Panel>
      {showInstanceLog && (
        <JobInstanceLog
          onCancel={() => setShowInstanceLog(false)}
          jobInstances={selectedItems}
          onManageJobs={props.onCancel}
        ></JobInstanceLog>
      )}
    </ThemeProvider>
  );
};

export default JobInstanceList;
