import {
  IStackTokens,
  HighContrastSelector,
  IButtonStyles,
  mergeStyleSets,
  memoizeFunction,
  ITheme,
} from '@fluentui/react';
import { useLensShellTheme } from 'features/shell/lensShellStyles';

export const customSplitButtonStyles = (theme: ITheme): IButtonStyles => {
  return {
    splitButtonMenuButton: {
      backgroundColor: theme.palette.white,
      width: 28,
      border: 'none',
    },
    splitButtonMenuIcon: { fontSize: '7px' },
    splitButtonDivider: {
      backgroundColor: '#c8c8c8',
      width: 1,
      right: 26,
      position: 'absolute',
      top: 4,
      bottom: 4,
    },
    splitButtonContainer: {
      selectors: {
        [HighContrastSelector]: { border: 'none' },
      },
    },
  };
};

export const innerStackTokens: IStackTokens = {
  childrenGap: 5,
  padding: 10,
};

export const getStyles = memoizeFunction(() => {
  const { palette } = useLensShellTheme();
  return mergeStyleSets({
    readMore: { color: palette.themePrimary, marginLeft: 5, cursor: 'pointer' },
    wordBreak: { wordBreak: 'break-word' },
    iconSpacing: { marginTop: 5 },
    dateTime: {
      fontFamily: 'Segoe UI',
      fontSize: 11,
      color: '#333333',
      display: 'flex',
    },
    description: {
      fontFamily: 'Segoe UI',
      fontSize: 12,
      color: '#666666',
      display: 'flex',
    },
    title: {
      fontFamily: 'Segoe UI',
      fontSize: 13,
      color: '#333333',
      display: 'flex',
      paddingBottom: 15,
    },
    firstRow: {
      alignItems: 'center',
    },
    errorIcon: { fontSize: 25, marginRight: 5, color: palette.red },
    warningIcon: { fontSize: 25, marginRight: 5, color: palette.orangeLight },
    infoIcon: { color: '#2F96B4', fontSize: 25, marginRight: 5 },
    card: {
      alignItems: 'left',
      border: '1px solid #EAEAEA',
      boxSizing: 'border-box',
      boxShadow: ' 0px 4px 8px rgba(0, 0, 0, 0.1)',
      borderRadius: 3,
      color: '#444444',
      display: 'flex',
      flexFlow: 'wrap',
      padding: 15,
      minwidth: 404,
      minHeight: 146,
      marginBottom: 20,
      fontFamily: 'Segoe UI',
      FontSizes: 13,
    },
    noNotification: {
      width: '100%',
      fontSize: 14,
      fontFamily: 'Segoe UI',
      fontWeight: 400,
    },
    stack: {
      width: '100%',
    },
  });
});
