import { ElxDropdown, ElxIconButton, ElxTextField } from '@elixir/components';
import { Stack } from '@fluentui/react';
import { Editor } from 'components/editor';
import { DataSourcePlugin } from 'features/dataSources/plugins/dataSourcePlugin';
import {
  getAllDataSourcePlugins,
  getDataSourcePlugin,
} from 'features/dataSources/registry';
import { Activity } from 'features/orchestrator/models/job';
import { ProjectDataConnection } from 'features/workspaces/models/project';
import _ from 'lodash';
import { useCallback, useState } from 'react';
import { orchestratorConstants } from 'utils/constants';
import { LensLabel } from 'utils/lensLabel';
import EditorFull from '../editorFull';
import { SelectDataConnection } from '../jobSchedule/selectDataConnection';
import { alignEnds } from './JobAuthorStyles';
import { selectWorkspace } from 'features/workspaces/workspaceSlice';
import { useSelector } from 'react-redux';

const connectionDataSourceTypes = _(getAllDataSourcePlugins())
  .map((dsp) => dsp.dataSourceClient)
  .map(
    (dsc) => (dsc.orchestration?.dataCommand?.endpointType && dsc.name) || []
  )
  .flatten()
  .compact()
  .uniq()
  .value();

const supportedLanguage = [
  { key: 'Scope', text: 'Scope' },
  { key: 'USql', text: 'USql' },
];

const dcType2EndPointType4DC = (dcType: string) => {
  let endPointType = '';

  switch (dcType) {
    case orchestratorConstants.DataSourceTypes.KUSTO:
      endPointType = orchestratorConstants.EndpointTypes.KustoDatabase;
      break;

    case orchestratorConstants.DataSourceTypes.ADLA:
      endPointType = orchestratorConstants.EndpointTypes.AzureDataLakeAnalytics;
      break;

    case orchestratorConstants.DataSourceTypes.COSMOS:
      endPointType = orchestratorConstants.EndpointTypes.CosmosVc;
      break;

    case orchestratorConstants.DataSourceTypes.SQL:
      endPointType = orchestratorConstants.EndpointTypes.SqlDatabase;
      break;

    case orchestratorConstants.DataSourceTypes.SYNAPSEPIPELINE:
      endPointType = orchestratorConstants.EndpointTypes.SynapsePipeline;
      break;

    case orchestratorConstants.DataSourceTypes.SYNAPSESCOPE:
      endPointType = orchestratorConstants.EndpointTypes.SynapseScope;
      break;

    case orchestratorConstants.DataSourceTypes.AZUREDATAFACTORY:
      endPointType = orchestratorConstants.EndpointTypes.AzureDataFactory;
      break;

    default:
      break;
  }
  return endPointType;
};

export interface JobActivityDataCommandProps {
  activity: Activity;
  onChange: (activity: Activity) => void;
}
export const JobActivityDataCommand = (props: JobActivityDataCommandProps) => {
  const { activity, onChange } = props;
  delete activity.input;
  activity.output = activity.output || { connectionName: '', type: '' };

  const [bigSrn, setBigSrn] = useState<boolean>(false);
  const [dataSourcePlugin, setDataSourcePlugin] =
    useState<DataSourcePlugin | null>(null);
  const workspace = useSelector(selectWorkspace);

  const dcType2EndPointTypeForDataCommand = useCallback(
    (dc: ProjectDataConnection) => {
      activity.output!.connectionName = dc.connectionName;

      switch (dc.type) {
        case orchestratorConstants.DataSourceTypes.KUSTO:
          activity.output!.type =
            orchestratorConstants.EndpointTypes.KustoDatabase;
          break;

        case orchestratorConstants.DataSourceTypes.ADLA:
          activity.output!.type =
            orchestratorConstants.EndpointTypes.AzureDataLakeAnalytics;
          break;

        case orchestratorConstants.DataSourceTypes.COSMOS:
          activity.output!.type = orchestratorConstants.EndpointTypes.CosmosVc;
          break;

        case orchestratorConstants.DataSourceTypes.SQL:
          activity.output!.type =
            orchestratorConstants.EndpointTypes.SqlDatabase;
          break;

        case orchestratorConstants.DataSourceTypes.SYNAPSEPIPELINE:
          activity.output!.type =
            orchestratorConstants.EndpointTypes.SynapsePipeline;
          break;

        case orchestratorConstants.DataSourceTypes.SYNAPSESCOPE:
          activity.output!.type =
            orchestratorConstants.EndpointTypes.SynapseScope;
          break;

        case orchestratorConstants.DataSourceTypes.AZUREDATAFACTORY:
          activity.output!.type =
            orchestratorConstants.EndpointTypes.AzureDataFactory;
          break;

        case orchestratorConstants.DataSourceTypes.SHARED:
          const sharedConnection = workspace.sourceDataConnections?.find(
            (s) => s.targetConnectionName === dc.connectionName
          );
          if (sharedConnection) {
            activity.output!.type = dcType2EndPointType4DC(
              sharedConnection.sourceConnection.type || ''
            );
          }
          break;

        default:
          break;
      }
      setDataSourcePlugin(getDataSourcePlugin(dc.type));
      onChange(activity);
    },
    [activity, onChange, workspace]
  );

  const showVcProperties =
    activity.output?.type ===
      orchestratorConstants.EndpointTypes.AzureDataLakeAnalytics ||
    activity.output?.type === orchestratorConstants.EndpointTypes.CosmosVc;
  const showSynapsePipeline =
    activity.output?.type ===
    orchestratorConstants.EndpointTypes.SynapsePipeline;
  const showLanguageType =
    activity.output?.type ===
    orchestratorConstants.EndpointTypes.AzureDataLakeAnalytics;
  const showScript =
    activity.output?.type !==
    orchestratorConstants.EndpointTypes.AzureDataFactory;
  const showPipeline =
    activity.output?.type ===
    orchestratorConstants.EndpointTypes.AzureDataFactory;
  const showSynapseScopePool =
    activity.output?.type === orchestratorConstants.EndpointTypes.SynapseScope;
  const showRuntimeVersion =
    activity.output?.type ===
      orchestratorConstants.EndpointTypes.AzureDataLakeAnalytics ||
    activity.output?.type === orchestratorConstants.EndpointTypes.SynapseScope;

  return (
    <>
      <SelectDataConnection
        onSelect={dcType2EndPointTypeForDataCommand}
        filter={connectionDataSourceTypes}
        selectedKey={activity.output?.connectionName || null}
        required={true}
      ></SelectDataConnection>
      <Stack
        verticalFill
        tokens={{
          childrenGap: 16,
        }}
      >
        {showRuntimeVersion && (
          <Stack.Item>
            <ElxTextField
              id={'Runtime version'}
              onRenderLabel={() => (
                <LensLabel
                  labelText="Runtime version"
                  hintText={
                    showSynapseScopePool
                      ? 'The Azure Synapse SCOPE runtime version.'
                      : 'The Azure Data Lake Analytics runtime version.'
                  }
                ></LensLabel>
              )}
              value={activity.runtimeVersion}
              onChange={(e, value) => {
                activity.runtimeVersion = value || '';
                onChange(activity);
              }}
            ></ElxTextField>
          </Stack.Item>
        )}
        {showLanguageType && (
          <Stack.Item>
            <ElxDropdown
              id={'Language type'}
              onRenderLabel={() => (
                <LensLabel
                  labelText="Language type"
                  hintText="Scope Script or U-SQL"
                  required
                ></LensLabel>
              )}
              options={supportedLanguage}
              selectedKey={activity.language || supportedLanguage[0].key}
              onChange={(e, value) => {
                activity.language =
                  value?.key.toString() || supportedLanguage[0].key;
                onChange(activity);
              }}
            ></ElxDropdown>
          </Stack.Item>
        )}
        <Stack.Item>
          {' '}
          <Stack
            className={alignEnds}
            horizontal
            grow
            styles={{
              root: {
                marginTop: 16,
              },
            }}
          >
            <Stack.Item>
              {' '}
              <LensLabel
                labelText="Script"
                hintText={'Script to execute'}
                required={true}
              ></LensLabel>
            </Stack.Item>
            <Stack.Item>
              {' '}
              <ElxIconButton
                text={''}
                styles={{
                  root: {
                    float: 'right',
                  },
                }}
                iconProps={{
                  iconName: 'FullScreen',
                }}
                onClick={() => {
                  setBigSrn(true);
                }}
              ></ElxIconButton>
            </Stack.Item>
          </Stack>
        </Stack.Item>{' '}
        {showScript && (
          <Stack.Item>
            <Editor
              language={
                dataSourcePlugin?.dataSourceClient?.monaco?.language || 'json'
              }
              onChange={(value: string) => {
                activity.script = value;
                onChange(activity);
              }}
              value={activity.script!}
              height={200}
              width={'100%'}
              focusOnMount={false}
            ></Editor>
          </Stack.Item>
        )}
        {bigSrn && (
          <EditorFull
            header={'Data command script'}
            onChange={(value: string) => {
              activity.script = value;
              onChange(activity);
            }}
            value={activity.script!}
            onDismiss={() => {
              setBigSrn(false);
            }}
            language={
              dataSourcePlugin?.dataSourceClient?.monaco?.language || 'json'
            }
          ></EditorFull>
        )}
        {showPipeline && (
          <Stack.Item>
            <ElxTextField
              id={'Pipeline'}
              onRenderLabel={() => (
                <LensLabel
                  labelText="Pipeline"
                  hintText={'The name of the Azure Data Factory pipeline.'}
                  required={true}
                ></LensLabel>
              )}
              value={activity.output?.pipeline || ''}
              onChange={(e, value) => {
                activity.output!.pipeline = value || '';
                onChange(activity);
              }}
            ></ElxTextField>
          </Stack.Item>
        )}
        {showSynapsePipeline && (
          <Stack.Item>
            <ElxTextField
              id={'Pipeline'}
              onRenderLabel={() => (
                <LensLabel
                  labelText="Pipeline"
                  hintText={'The name of the Azure Synapse pipeline.'}
                  required={true}
                ></LensLabel>
              )}
              value={activity.output?.pipeline || ''}
              onChange={(e, value) => {
                activity.output!.pipeline = value || '';
                onChange(activity);
              }}
            ></ElxTextField>
          </Stack.Item>
        )}
        {showVcProperties && (
          <>
            <Stack.Item>
              <ElxTextField
                id={'Priority'}
                onRenderLabel={() => (
                  <LensLabel
                    labelText="Priority"
                    hintText="The priority of this job.
                      Lower values have higher priority.
                      The default is 1100."
                  ></LensLabel>
                )}
                value={activity.priority?.toString() || undefined}
                onChange={(e, value) => {
                  activity.priority = Number(value);
                  onChange(activity);
                }}
              ></ElxTextField>
            </Stack.Item>
            <Stack.Item>
              <ElxTextField
                id={'Token'}
                onRenderLabel={() => (
                  <LensLabel
                    labelText="Token"
                    hintText="The token/AU allocation for this job.
                      Enter a positive number for the token count, or -1 for the default allocation of 10.
                      Enter either the tokens or the VC percent allocation or neither but not both."
                  ></LensLabel>
                )}
                value={activity.tokenAllocation?.toString() || undefined}
                onChange={(e, value) => {
                  activity.tokenAllocation = Number(value);
                  onChange(activity);
                }}
              ></ElxTextField>
            </Stack.Item>
            <Stack.Item>
              <ElxTextField
                id={'VC percent allocation'}
                onRenderLabel={() => (
                  <LensLabel
                    labelText="VC percent allocation"
                    hintText="The VC percent allocation for this job.
                      Enter a number from 1 to 100 inclusive, or -1 for the default allocation.
                      Enter either the tokens or the VC percent allocation or neither but not both."
                  ></LensLabel>
                )}
                value={activity.vcPercentAllocation?.toString() || undefined}
                onChange={(e, value) => {
                  activity.vcPercentAllocation = Number(value);
                  onChange(activity);
                }}
              ></ElxTextField>
            </Stack.Item>
          </>
        )}
      </Stack>
    </>
  );
};
