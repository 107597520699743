import { MdmSelector } from 'features/workspaces/components/editDataConnection/dataSourceComponents/mdmSelector';
import { SelectorProps } from 'features/workspaces/components/editDataConnection/editDataConnectionHelper';
import {
  ConnectionDataSourceType,
  MdmDataSource,
} from 'features/workspaces/models/project';
import mdmClient from '../mdmClient';
import { registerDataSourcePlugin } from '../registry';
import { DataSourcePlugin } from './dataSourcePlugin';

class MdmPlugin extends DataSourcePlugin {
  public dataSourceFormComponent(selectorProps: SelectorProps): JSX.Element {
    return (
      <MdmSelector
        workspace={selectorProps.workspace}
        state={selectorProps.state}
        setState={selectorProps.setState}
        customNameFilled={selectorProps.customNameFilled}
      />
    );
  }

  public generatedName(state: MdmDataSource) {
    return state && state.account && state.namespace
      ? state.account + '.' + state.namespace
      : '';
  }
}

registerDataSourcePlugin(
  ConnectionDataSourceType.Mdm,
  new MdmPlugin(ConnectionDataSourceType.Mdm, mdmClient)
);
