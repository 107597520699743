import {
  ElxPrimaryButton,
  ElxSecondaryButton,
  ElxTextField,
} from '@elixir/components';
import { Callout, Stack, Target } from '@fluentui/react';
import { useState } from 'react';
import { RegionPicker } from './regionPicker';
import { SubscriptionInfo } from './subscriptionPicker';
import { LensLabel } from 'utils/lensLabel';
import notifier from 'utils/notifier';
import SpinnerOverlay from 'components/spinnerOverlay/spinnerOverlay';
import armClient from 'utils/armClient';

export interface CreateResourceGroupCalloutProps {
  show: boolean;
  subscriptionInfo: SubscriptionInfo; // don't call without valid subscriptionInfo
  selectedRegion: string;
  onResourceGroupCreated: (resourceGroupName: string) => void;
  setRegion: (region: string) => void;
  dismissCallout: () => void;
  target?: Target;
  existingResourceGroups: string[];
}

// show a callout which prompts the user for a resource group name and then creates the resource group using graphClient.createResourceGroup
const CreateResourceGroupCallout = (props: CreateResourceGroupCalloutProps) => {
  const {
    show,
    subscriptionInfo,
    selectedRegion,
    setRegion,
    onResourceGroupCreated,
    dismissCallout,
    target,
    existingResourceGroups,
  } = props;
  const [resourceGroupName, setResourceGroupName] = useState<string>('');
  const [isCreating, setIsCreating] = useState<boolean>(false);

  const dismiss = () => {
    setResourceGroupName('');
    setIsCreating(false);
    dismissCallout();
  };

  const onCreate = () => {
    if (!subscriptionInfo.subscriptionId || !selectedRegion) return;
    setIsCreating(true);
    armClient
      .createResourceGroup(
        subscriptionInfo.subscriptionId,
        resourceGroupName,
        selectedRegion
      )
      .then(() => {
        onResourceGroupCreated(resourceGroupName);
        notifier.info(
          `Successfully created resource group: ${resourceGroupName}`
        );
      })
      .catch((error) => {
        if (error.isAxiosError) {
          if (error.response.status === 403) {
            notifier.error(
              'You do not have permission to create the resource group.' +
                ` Error: ${error.response.data.error?.code}. ${error.response.data.error?.message}`
            );
          } else {
            notifier.error(
              `Failed to create resource group: ${error.response.data.error?.code}. ${error.response.data.error?.message}`
            );
          }
        } else {
          notifier.error(`Failed to create resource group: ${error.message}`);
        }
      })
      .finally(() => {
        dismiss();
      });
  };

  const errorMessage = existingResourceGroups.includes(resourceGroupName)
    ? 'Resource group already exists'
    : '';

  return (
    <Callout
      target={target}
      onDismiss={dismiss}
      hidden={!show}
      styles={{ root: { width: '450px' } }}
    >
      <Stack
        tokens={{ padding: 20, childrenGap: 15 }}
        styles={{ root: { minHeight: 325 } }}
      >
        <h4>Create a new resource group</h4>
        <ElxTextField
          onRenderLabel={() => (
            <LensLabel
              labelText="Resource Group Name"
              hintText={'Enter a name for your new resource group'}
              required={true}
            ></LensLabel>
          )}
          value={resourceGroupName}
          placeholder="Enter a name for your new resource group"
          onChange={(_event, value) => {
            setResourceGroupName(value || '');
          }}
          styles={{ errorMessage: { display: 'auto' } }} // error message needs this or it won't display
          errorMessage={errorMessage}
          spellCheck={false}
        />
        <RegionPicker
          subscriptionInfo={subscriptionInfo}
          selectedRegion={selectedRegion}
          setRegion={setRegion}
          hintText="Select an Azure region for your new resource group."
        />
        <Stack
          horizontal
          styles={{ root: { width: '100%', paddingTop: 20 } }}
          tokens={{ childrenGap: 10 }}
        >
          <Stack.Item grow={1} />
          <ElxPrimaryButton
            onClick={onCreate}
            disabled={
              isCreating ||
              !resourceGroupName ||
              !selectedRegion ||
              existingResourceGroups.includes(resourceGroupName)
            }
          >
            Create
          </ElxPrimaryButton>
          <ElxSecondaryButton onClick={dismiss}>Cancel</ElxSecondaryButton>
        </Stack>
      </Stack>
      <SpinnerOverlay
        show={isCreating}
        label={'Creating resource group...'}
        opacity={0.8}
      />
    </Callout>
  );
};

export default CreateResourceGroupCallout;
