import { LeWebApi } from 'api/leWebApi';
import { SupportNotificationEntity } from '../models/supportNotificationEntity';

class AzureSupportNotificationApi extends LeWebApi {
  public getSupportNotifications = async (): Promise<
    SupportNotificationEntity[]
  > => {
    return await this.getEntity<SupportNotificationEntity[]>(
      '/supportnotifications/active?bypassCache=false'
    );
  };

  public addNewSupportNotification = async (
    notif: SupportNotificationEntity
  ): Promise<any> => {
    return await this.postEntity<SupportNotificationEntity, any>(
      '/supportNotifications',
      notif
    );
  };
}

export const azureSupportNotificationApi = new AzureSupportNotificationApi();
export const getSupportNotifications =
  azureSupportNotificationApi.getSupportNotifications;

export default azureSupportNotificationApi;
