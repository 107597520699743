import {
  Breadcrumb,
  IBreadcrumbItem,
  MessageBar,
  MessageBarType,
  Panel,
  PanelType,
} from '@fluentui/react';
import { panelStyles } from '../../workspaces/components/editWorkspace/workspaceStyles';
import { ElxContainer, ElxLink } from '@elixir/components';
import { useState } from 'react';
import { LinkedInstanceListTable } from './linkedInstanceListTable';
import { GrafanaInstancesTable } from './grafanaInstancesTable';
import {
  loadLinkedGrafanaInstances,
  saveGrafanaInstances,
} from '../grafanaOnboardingSlice';
import { GrafanaOnboardingData } from '../models/grafanaOnboardingData';
import { useDispatch, useSelector } from 'react-redux';
import { editWorkspace } from 'features/workspaces/workspaceSlice';
import CreateGrafanaInstancePanel from './createGrafanaInstancePanel';
import { SubscriptionInfo } from './subscriptionPicker';
import { AppDispatch } from 'app/lensShellUtility';
import { getAADTenantId } from 'utils/authUtils';
import { MessageAndType } from 'features/workspaces/components/editDataConnection/editDataConnectionPanel';
import { CopyButtonWithNotifier } from 'components/copyButtons/copyButtons';

interface GrafanaPanelProps {
  show: boolean;
  dismissPanel: () => void;
  openPanel: () => void;
  closeQuickSwitchPanel: () => void;
}

const pivotStyles = {
  headerComponent: { 'padding-top': '1rem !important' },
};

const GrafanaPanel = (props: GrafanaPanelProps) => {
  const { show, dismissPanel } = props;
  const workspace = useSelector(editWorkspace);
  const [selectedTab, setSelectedTab] = useState<string>('linkedInstances');
  const [showAddGrafana, setShowAddGrafana] = useState(false);
  const dispatch = useDispatch<AppDispatch>();

  const [isCreatedMessage, setIsCreatedMessage] = useState<MessageAndType>({
    resultMsg: '',
    type: MessageBarType.info,
  });

  const onDismiss = () => {
    onDismissCreatedMessage();
    dismissPanel();
  };

  const items: IBreadcrumbItem[] = [
    {
      text: 'Workspace management',
      key: 'workspaceManagement',
      onClick: onDismiss,
    },
    {
      text: 'Azure Managed Grafana',
      key: 'azureManagedGrafana',
      onClick: () => {},
    },
  ];

  const onDismissCreatedMessage = () => {
    setIsCreatedMessage({
      resultMsg: '',
      type: MessageBarType.info,
    });
  };

  function onNewInstanceCreated(
    id: string,
    subscriptionInfo: SubscriptionInfo,
    name: string,
    resourceGroupName: string,
    location: string,
    endpoint: string,
    linkAfterCreate: boolean
  ): void {
    if (linkAfterCreate) {
      setSelectedTab('linkedInstances');
      var instance: GrafanaOnboardingData = {
        id,
        name,
        workspaceId: workspace.id,
        subscriptionId: subscriptionInfo.subscriptionId,
        subscriptionName: subscriptionInfo.subscriptionName,
        resourceGroupName: resourceGroupName.toLocaleLowerCase(), // grafana resource group name is always lowercase
        location,
        endpoint,
        aadTenantId: getAADTenantId(),
      };

      dispatch(
        saveGrafanaInstances({ grafanaOnboardingDataList: [instance] })
      ).then(() => {
        dispatch(loadLinkedGrafanaInstances({ workspaceId: workspace.id }));
      });
    } else {
      setSelectedTab('availableInstances');
    }
    setIsCreatedMessage({
      resultMsg: (
        <>
          <p>
            Your new Grafana instance '{name}' has been created. However, it may
            take some time for the instance to be ready. You should wait a few
            minutes before trying to migrate a dashboard to this instance.
          </p>
          {linkAfterCreate ? (
            <p>The new instance has already been linked to your workspace.</p>
          ) : (
            <p>
              Before you can migrate Lens Explorer dashboards to this instance,
              you will need to link it to your workspace. You can do this by
              clicking the 'Link' button under 'Available Instances' when the
              instance is ready.
            </p>
          )}
          You can click here to see if your new Grafana instance is ready:{' '}
          <ElxLink href={endpoint} target="_blank">
            {endpoint}
          </ElxLink>
          <CopyButtonWithNotifier text={endpoint} />
        </>
      ),
      type: MessageBarType.success,
    });
  }

  return (
    <Panel
      type={PanelType.large}
      isOpen={show}
      onDismiss={onDismiss}
      styles={panelStyles}
      onRenderHeader={() => {
        return (
          <Breadcrumb
            items={items}
            maxDisplayedItems={2}
            ariaLabel="Azure Managed Grafana Breadcrumb"
            styles={{ root: { fontSize: '14px' } }}
          />
        );
      }}
    >
      <ElxContainer
        styles={pivotStyles}
        compact={true}
        headerText={`(Preview) Link your Azure Managed Grafana instances to workspace "${workspace.name}"`}
        headingLevel={3}
        pivotProps={{
          items: [
            {
              headerText: 'Already Linked Instances',
              itemKey: 'linkedInstances',
            },
            {
              headerText: 'Available Instances',
              itemKey: 'availableInstances',
            },
          ],
          selectedKey: selectedTab,
          onItemSelect: (selectedKey) => {
            setSelectedTab(selectedKey);
          },
        }}
      >
        {isCreatedMessage.resultMsg && (
          <MessageBar
            messageBarType={isCreatedMessage.type}
            isMultiline={true}
            dismissButtonAriaLabel="Close"
            onDismiss={onDismissCreatedMessage}
          >
            {isCreatedMessage.resultMsg}
          </MessageBar>
        )}

        {selectedTab === 'linkedInstances' && (
          <LinkedInstanceListTable showAddGrafanaPanel={setShowAddGrafana} />
        )}
        {selectedTab === 'availableInstances' && (
          <GrafanaInstancesTable showAddGrafanaPanel={setShowAddGrafana} />
        )}
      </ElxContainer>
      <CreateGrafanaInstancePanel
        show={showAddGrafana}
        parentBreadcrumb={items}
        dismissPanel={() => setShowAddGrafana(false)}
        onNewInstanceCreated={onNewInstanceCreated}
      />
    </Panel>
  );
};

export default GrafanaPanel;
