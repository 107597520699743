import { EventHubSelector } from 'features/workspaces/components/editDataConnection/dataSourceComponents/eventHubSelector';
import { SelectorProps } from 'features/workspaces/components/editDataConnection/editDataConnectionHelper';
import {
  ConnectionDataSourceType,
  EventHubDataSource,
} from 'features/workspaces/models/project';
import _ from 'lodash';
import eventHubClient from '../eventHubClient';
import { registerDataSourcePlugin } from '../registry';
import { DataSourcePlugin } from './dataSourcePlugin';

class EventHubPlugin extends DataSourcePlugin {
  public dataSourceFormComponent(selectorProps: SelectorProps): JSX.Element {
    return (
      <EventHubSelector
        workspace={selectorProps.workspace}
        state={selectorProps.state}
        setState={selectorProps.setState}
        customNameFilled={selectorProps.customNameFilled}
      />
    );
  }

  public generatedName(state: EventHubDataSource) {
    let name = '';
    if (state) {
      if (state.eventHubNamespace && state.eventHubName) {
        let host = _.first(_.split(state.eventHubNamespace, '.'));
        name = host + '.' + state.eventHubName;
      }
      if (state.connectionSecretUri) {
        var tokens = state.connectionSecretUri.split('/');
        var postfix;
        if (tokens.length > 2) {
          postfix = tokens[tokens.length - 2];
        } else if (tokens.length > 1) {
          postfix = tokens[tokens.length - 1];
        } else {
          postfix = tokens[0];
        }
        name = 'EventHub.Datasource.ConnectionStr.' + postfix;
      }
    }
    return name;
  }
}

registerDataSourcePlugin(
  ConnectionDataSourceType.EventHub,
  new EventHubPlugin(ConnectionDataSourceType.EventHub, eventHubClient)
);
