import { TopCard, TopCardProps } from './topCard';

export interface TopCardListProps {
  items: TopCardProps[];
}

export const TopCardList = (props: TopCardListProps): JSX.Element => {
  const topCardData = props.items;
  const topCardList: JSX.Element[] = [];
  topCardData.forEach((topCard: TopCardProps) => {
    const card = (
      <TopCard
        key={topCard.key}
        mainLabel={topCard.mainLabel}
        secondaryLabel={topCard.secondaryLabel}
        iconName={topCard.iconName}
        iconText={topCard.iconText}
        link={topCard.link}
      />
    );
    topCardList.push(card);
  });
  return <>{topCardList}</>;
};
