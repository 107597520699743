import {
  IconButton,
  IContextualMenuProps,
  IIconProps,
  Label,
  Stack,
} from '@fluentui/react';
import { ElxIconButton } from '@elixir/components';
import React, { useCallback, useEffect } from 'react';
import { customSplitButtonStyles, getStyles, innerStackTokens } from './styles';
import {
  selectUserprofile,
  saveSupportNotificationStatus,
} from 'features/userprofile/userprofileSlice';
import { useDispatch, useSelector } from 'react-redux';
import { allSupportNotifications, uiNotifier } from '../notificationsSlice';
import { Card } from 'components/cards/card';
import ReactMarkdown from 'react-markdown';
import { useLensShellTheme } from 'features/shell/lensShellStyles';
import { SupportNotificationUserProfileStatus } from './models/supportNotificationUserProfileStatus';
import { AppDispatch } from 'app/lensShellUtility';

const SupportNotifications = (): JSX.Element => {
  const userprofile = useSelector(selectUserprofile);
  const supportNotifications = useSelector(allSupportNotifications);
  const data = supportNotifications.filter((item) => item.Show === true);
  const dispatch = useDispatch<AppDispatch>();
  const moreIcon: IIconProps = { iconName: 'More' };
  const style = getStyles();
  const theme = useLensShellTheme();

  //This hides the Notification on Click of Cancel/ Dismiss
  //This is done by updating the supportNotificationStatus in User Profile
  const updateSupportNotificationStatus = (notificationId: string) => {
    updateUserProfileSupportNotification(notificationId);
    updateSupportNotification(notificationId);
  };

  //Logic to Update Support Notification Status in User Profile
  const updateUserProfileSupportNotification = (notificationId: string) => {
    const supportNotificationStatus =
      manipulateSupportNotificationInUserProfile(notificationId, true);
    dispatch(
      saveSupportNotificationStatus({ supportNotificationStatus, userprofile })
    );
  };

  const manipulateSupportNotificationInUserProfile = (
    notificationId: string | undefined,
    showAll: boolean
  ) => {
    let notificationList = JSON.parse(
      userprofile.SupportNotificationStatus.trim()
    );
    //Works for Single Notification
    if (notificationId) {
      const notificationIndex = notificationList.findIndex(
        (notification: any) => notification.NotificationId === notificationId
      );
      if (notificationIndex !== -1) {
        notificationList[notificationIndex].Dismissed = true;
        notificationList[notificationIndex].Seen = true;
      }
    }
    //Works for all Notification. Triggered on Dismiss All and Restore All
    else {
      notificationList = notificationList.map((item: any) => ({
        ...item,
        Dismissed: showAll,
      }));
    }

    return JSON.stringify(notificationList);
  };

  const showHideAllNotificationHandler = (show: boolean) => {
    const supportNotificationStatus =
      manipulateSupportNotificationInUserProfile(undefined, !show);
    dispatch(uiNotifier.showHideSupportNotification(show));
    dispatch(
      saveSupportNotificationStatus({ supportNotificationStatus, userprofile })
    );
  };

  const setAllSeenInUserProfile = useCallback(() => {
    let notificationList: SupportNotificationUserProfileStatus[] = JSON.parse(
      userprofile.SupportNotificationStatus.trim()
    );
    notificationList = notificationList.map((item) => ({
      ...item,
      Seen: true,
    }));
    return JSON.stringify(notificationList);
  }, [userprofile.SupportNotificationStatus]);

  const setAllSeenNotificationHandler = useCallback(() => {
    const supportNotificationStatus = setAllSeenInUserProfile();
    dispatch(uiNotifier.unsetIsNewSupportNotification(undefined));
    dispatch(
      saveSupportNotificationStatus({ userprofile, supportNotificationStatus })
    );
  }, [dispatch, setAllSeenInUserProfile, userprofile]);

  const menuProps: IContextualMenuProps = {
    items: [
      {
        key: 'dimissAll',
        id: 'dismiss',
        text: 'Dismiss All',
        canCheck: true,
        isChecked: false,
      },
      {
        key: 'restoreAll',
        id: 'restore',
        text: 'Restore All',
        canCheck: true,
        isChecked: false,
        disabled: false,
      },
    ],
    onItemClick: (props: any) => {
      let showAllNotification = true;
      if (props?.currentTarget.id === 'dismiss') {
        showAllNotification = false;
      }
      showHideAllNotificationHandler(showAllNotification);
    },
    directionalHintFixed: false,
  };

  //Logic to Update Support Notification Status in Redux
  const updateSupportNotification = (notificationId: string) => {
    dispatch(uiNotifier.updateSupportNotificationObj(notificationId));
  };

  useEffect(() => {
    const userprofileSupportNotification =
      userprofile.SupportNotificationStatus;
    if (!userprofileSupportNotification) {
      return;
    }
    let userProfileNotificationList = JSON.parse(
      userprofileSupportNotification
    );

    if (supportNotifications.length > userProfileNotificationList.length) {
      supportNotifications.forEach((item) => {
        const found = userProfileNotificationList.find(
          (userprofileItem: any) => userprofileItem.NotificationId === item.Id
        );
        if (!found) {
          userProfileNotificationList.push({
            NotificationId: item.Id,
            Dismissed: false,
            Seen: false,
          });
        }
      });
      const supportNotificationStatus = JSON.stringify(
        userProfileNotificationList
      );
      dispatch(
        saveSupportNotificationStatus({
          supportNotificationStatus,
          userprofile,
        })
      );
    }

    // when loading mark any "isNew" notifications as "seen"
    const isNew = supportNotifications.find((item) => item.IsNew);
    if (isNew) {
      setAllSeenNotificationHandler();
    }
  }, [
    dispatch,
    setAllSeenNotificationHandler,
    supportNotifications,
    userprofile,
  ]);

  return (
    <>
      {supportNotifications.length > 0 && (
        <Stack>
          <Stack.Item align="end">
            <IconButton
              // split
              iconProps={moreIcon}
              splitButtonAriaLabel="See 2 options"
              aria-roledescription="split button"
              styles={customSplitButtonStyles(theme)}
              menuProps={menuProps}
              ariaLabel="Notifications options"
              disabled={false}
              checked={true}
              onClick={() => showHideAllNotificationHandler(true)}
            />
          </Stack.Item>
        </Stack>
      )}
      <Stack className={style.stack} tokens={innerStackTokens}>
        {supportNotifications && supportNotifications.length === 0 && (
          <Stack.Item className={style.noNotification}>
            There are no notifications to view
          </Stack.Item>
        )}
        {data &&
          data.length === 0 &&
          supportNotifications &&
          supportNotifications.length > 0 && (
            <Stack.Item className={style.noNotification}>
              All notifications are dismissed
            </Stack.Item>
          )}

        {data &&
          data.map((notification) => {
            return (
              <Card
                key={notification.Id}
                className={style.card}
                tokens={{ childrenGap: 16 }}
              >
                <Stack className={style.stack}>
                  <Stack.Item align="end">
                    <ElxIconButton
                      label="Cancel"
                      text="Cancel"
                      width={8}
                      height={8}
                      iconProps={{ iconName: 'Cancel' }}
                      style={{ color: 'black' }}
                      onClick={() =>
                        updateSupportNotificationStatus(notification.Id)
                      }
                    />
                  </Stack.Item>
                  <Stack.Item className={style.dateTime}>
                    {notification.Created &&
                      new Date(notification.Created).toLocaleDateString(
                        navigator.language,
                        {
                          day: '2-digit',
                          month: '2-digit',
                          year: 'numeric',
                        }
                      )}
                  </Stack.Item>
                  <Stack.Item>
                    <Label>
                      <ReactMarkdown children={notification.Message} />
                    </Label>
                  </Stack.Item>
                </Stack>
              </Card>
            );
          })}
      </Stack>
    </>
  );
};

export default SupportNotifications;
