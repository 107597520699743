import { Workspace } from 'features/workspaces/models/workspace';
import AadAppsPanel from './aadApplications/aadAppsPanel';
import DataConnectionsTablePanel from './dataConnections/dataConnectionsPanel';
import HistoryPanel from './history/historyPanel';
import OrchestrationSettingsPanel from './orchestrationSettings/orchSettingsPanel';
import { WorkspacePanel } from './workspacePanel';
import '../../../dataSources/plugins/index';
import MigrationPanel from './migration/migrationPanel';
import DeletePanel from './delete/deletePanel';
import PublishPanel from './publishAGC/publishPanel';
import SavedObjectsPanel from './savedObjects/savedObjectsPanel';
import AccessInfoPanel from './accessInfo/accessInfoPanel';
import MiscellaneousPanel from '../MiscellaneousPanel';
import GrafanaPanel from 'features/grafana/components/grafanaPanel';

export enum WorkspacePanelType {
  DataConnections = 'DataConnections',
  History = 'History',
  Orchestration = 'Orchestration',
  AadAppsList = 'AadAppsList',
  WorkspaceSettings = 'WorkspaceSettings',
  Migration = 'Migration',
  Delete = 'Delete',
  Publish = 'Publish',
  Objects = 'Objects',
  Access = 'Access',
  Grafana = 'Grafana',
  Miscellaneous = 'Miscellaneous',
  None = 'None',
}
interface WorkspacePanelManagerProps {
  workspaceId: string;
  workspace: Workspace;
  create: boolean;
  openQuickSwitchPanel: () => void;
  closeQuickSwitchPanel: () => void;
  panelType: WorkspacePanelType;
  show: boolean;
  openSelectedPanel: () => void;
  closeSelectedPanel: () => void;
}

const WorkspacePanelManager = (
  props: WorkspacePanelManagerProps
): JSX.Element => {
  const getPanelByName = (panelType: WorkspacePanelType) => {
    switch (panelType) {
      case WorkspacePanelType.DataConnections:
        return (
          <DataConnectionsTablePanel
            workspaceId={props.workspaceId}
            show={props.show}
            dismissPanel={props.closeSelectedPanel}
            openPanel={props.openSelectedPanel}
            closeQuickSwitchPanel={props.closeQuickSwitchPanel}
          />
        );

      case WorkspacePanelType.History:
        return (
          <HistoryPanel
            workspaceId={props.workspaceId}
            show={props.show}
            dismissPanel={props.closeSelectedPanel}
            openPanel={props.openSelectedPanel}
            closeQuickSwitchPanel={props.closeQuickSwitchPanel}
          />
        );
      case WorkspacePanelType.Orchestration:
        return (
          <OrchestrationSettingsPanel
            workspaceId={props.workspaceId}
            openQuickSwitchPanel={props.openQuickSwitchPanel}
            show={props.show}
            dismissPanel={props.closeSelectedPanel}
            openPanel={props.openSelectedPanel}
            closeQuickSwitchPanel={props.closeQuickSwitchPanel}
          />
        );
      case WorkspacePanelType.WorkspaceSettings:
        return (
          <WorkspacePanel
            show={props.show}
            workspace={props.workspace}
            dismissPanel={props.closeSelectedPanel}
            openPanel={props.openSelectedPanel}
            create={props.create}
            closeQuickSwitchPanel={props.closeQuickSwitchPanel}
          />
        );
      case WorkspacePanelType.Migration:
        return (
          <MigrationPanel
            workspaceId={props.workspaceId}
            show={props.show}
            dismissPanel={props.closeSelectedPanel}
            openPanel={props.openSelectedPanel}
            closeQuickSwitchPanel={props.closeQuickSwitchPanel}
          />
        );
      case WorkspacePanelType.Delete:
        return (
          <DeletePanel
            workspaceId={props.workspaceId}
            show={props.show}
            dismissPanel={props.closeSelectedPanel}
            openPanel={props.openSelectedPanel}
            closeQuickSwitchPanel={props.closeQuickSwitchPanel}
          />
        );
      case WorkspacePanelType.Publish:
        return (
          <PublishPanel
            workspaceId={props.workspaceId}
            show={props.show}
            dismissPanel={props.closeSelectedPanel}
            openPanel={props.openSelectedPanel}
            closeQuickSwitchPanel={props.closeQuickSwitchPanel}
          />
        );
      case WorkspacePanelType.Objects:
        return (
          <SavedObjectsPanel
            workspaceId={props.workspaceId}
            show={props.show}
            dismissPanel={props.closeSelectedPanel}
            openPanel={props.openSelectedPanel}
            closeQuickSwitchPanel={props.closeQuickSwitchPanel}
          />
        );
      case WorkspacePanelType.Access:
        return (
          <AccessInfoPanel
            workspaceId={props.workspaceId}
            show={props.show}
            dismissPanel={props.closeSelectedPanel}
            openPanel={props.openSelectedPanel}
            closeQuickSwitchPanel={props.closeQuickSwitchPanel}
          />
        );
      case WorkspacePanelType.AadAppsList:
        return (
          <AadAppsPanel
            workspaceId={props.workspaceId}
            show={props.show}
            dismissPanel={props.closeSelectedPanel}
            openPanel={props.openSelectedPanel}
            closeQuickSwitchPanel={props.closeQuickSwitchPanel}
          />
        );
      case WorkspacePanelType.Grafana:
        return (
          <GrafanaPanel
            show={props.show}
            dismissPanel={props.closeSelectedPanel}
            openPanel={props.openSelectedPanel}
            closeQuickSwitchPanel={props.closeQuickSwitchPanel}
          />
        );
      case WorkspacePanelType.Miscellaneous:
        return (
          <MiscellaneousPanel
            show={props.show}
            dismissPanel={props.closeSelectedPanel}
            openPanel={props.openSelectedPanel}
            workspaceId={props.workspaceId}
          ></MiscellaneousPanel>
        );
    }
  };

  return <>{props.show && getPanelByName(props.panelType)}</>;
};

export default WorkspacePanelManager;
