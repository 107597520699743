import {
  ElxActionButton,
  ElxDropdown,
  ElxIconButton,
  ElxPanel,
  ElxSecondaryButton,
  ElxTextField,
  PanelSize,
} from '@elixir/components';
import { IDropdownOption, Stack } from '@fluentui/react';
import DayHourMinSecPicker from 'components/dayHourMinSecPicker';
import { useState } from 'react';
import { orchestratorConstants } from 'utils/constants';
import { LensLabel } from 'utils/lensLabel';
import utils from 'utils/utils';
import {
  Activity,
  ActivityTypes,
  parameterType,
  retryPolicyType,
} from '../../models/job';
import { uniqNumber } from '../../utils/jobUtils';
import { JobActivityDataCommand } from './jobActivityDataCommand';
import { retryPolicyOptions, parameterOptionsType } from '../jobAuthor';
import { JobActivityT4Transform } from './JobActivityT4Transform';
import { JobActivityDataEvent } from './JobActivityDataEvent';
import { JobActivityDataProcessing } from './JobActivityDataProcessing';
import { JobActivityDataValidation } from './JobActivityDataValidation';
import { JobAccordian } from 'features/orchestrator/utils/jobAccordian';
import { groupBorder } from './JobAuthorStyles';

const tableTypes = orchestratorConstants.TableTypes;

export const ActivityTypesDisplay = {
  DataCommand: 'Data Command',
  DataMovement: 'Data Movement',
  DataValidation: 'Data Validation',
  DataEvent: 'Signal Data Event',
  T4Transform: ' Dynamic Script Transform (T4)',
};
export const ActivityTypesDisplayKeyValue = Object.entries(
  ActivityTypesDisplay
).map(([key, text]) => ({ key: key, text: text }));

let it = uniqNumber();

const newActivityTemplate = (): Activity => ({
  retryPolicy: {
    type: 'None',
  },
  activityDisplayName: `New activity`,
  activityType: ActivityTypes.DataCommand,
  output: { connectionName: '', type: '' },
  dependsOn: [],
  parameters: [],
  id: '',
});

const getNewActivity = (activity: Activity = newActivityTemplate()) => ({
  ...activity,
  activityDisplayName: `${activity.activityDisplayName} ${it.next().value}`,
  id: utils.newGuid(),
});

export interface JobActivityProps {
  isEdit: boolean;
  activity?: Activity;
  onDismiss: () => void;
  onChange: (activity: Activity) => void;
  onDelete: () => void;
  onViewJson: () => void;
  dependencyOptions: IDropdownOption<any>[];
}

export function JobActivity(props: JobActivityProps) {
  const {
    isEdit,
    activity,
    onDismiss,
    onChange,
    onDelete,
    dependencyOptions,
    onViewJson,
  } = props;

  const [tempActivity, setTempActivity] = useState<Activity>(
    isEdit ? activity! : getNewActivity()
  );

  if (tempActivity.activityType !== ActivityTypes.DataValidation) {
    // when activity is changed to DataValidation and then back - retryPolicy is undefined.
    if (!tempActivity.retryPolicy) {
      tempActivity.retryPolicy = {
        type: 'None',
      };
    }
  }

  return (
    <ElxPanel
      headerText={`${isEdit ? 'Edit' : 'Add'} ${
        isEdit ? activity?.activityDisplayName! : ''
      } activity`}
      isOpen={true}
      key={'Activity Panel'}
      actions={[
        {
          key: 'activity',
          text: `${isEdit ? 'Save' : 'Add'} activity`,
          primary: true,
          onClick: () => {
            // Use this to clean up any property that is not relevant for activity type!
            switch (tempActivity.activityType) {
              case ActivityTypes.DataValidation:
                delete tempActivity.output;
                tempActivity.input!.tableType = tableTypes.Snapshot;
                break;
              case ActivityTypes.DataMovement:
                tempActivity.input!.tableType = tempActivity.output?.tableType;
                break;
              case ActivityTypes.DataCommand:
                break;
              case ActivityTypes.DataEvent:
                break;
              case ActivityTypes.T4Transform:
                break;
              default:
                throw new Error('Invalid activity Type');
                // eslint-disable-next-line no-unreachable
                break;
            }
            onChange(tempActivity);
          },
        },
        {
          key: 'close',
          text: 'Close',
          onClick: () => {
            onDismiss();
          },
        },
      ]}
      size={PanelSize.large}
      onDismiss={() => {
        onDismiss();
      }}
      subActions={[
        <ElxSecondaryButton
          key={'Delete activity'}
          text={'Delete activity'}
          onClick={() => {
            onDelete();
          }}
          styles={{
            root: {
              marginLeft: '6px',
              marginRight: '6px',
            },
          }}
          disabled={!isEdit}
        ></ElxSecondaryButton>,
        <ElxSecondaryButton
          key={'View JSON'}
          text={'View JSON'}
          onClick={() => {
            onViewJson();
          }}
          styles={{
            root: {
              marginLeft: '6px',
              marginRight: '6px',
            },
          }}
        ></ElxSecondaryButton>,
      ]}
    >
      <Stack
        tokens={{
          childrenGap: 16,
          padding: 16,
        }}
      >
        <Stack.Item>
          <ElxTextField
            key="Activity Name"
            onRenderLabel={() => (
              <>
                <LensLabel
                  labelText="Activity Name"
                  hintText={
                    'Name of the activity, that uniquely identifies within the job'
                  }
                  required={true}
                ></LensLabel>
              </>
            )}
            value={tempActivity.activityDisplayName}
            onChange={(_, value) => {
              setTempActivity({ ...tempActivity, activityDisplayName: value! });
            }}
          ></ElxTextField>
        </Stack.Item>
        <Stack.Item>
          <ElxDropdown
            onRenderLabel={() => (
              <>
                <LensLabel
                  labelText="Activity Type"
                  hintText={'Activity Type, eg: Data Command, Data Movement...'}
                  required={true}
                ></LensLabel>
              </>
            )}
            options={ActivityTypesDisplayKeyValue}
            selectedKey={tempActivity.activityType}
            onChange={(_, option) => {
              tempActivity.activityType = option!.key.toString();

              switch (tempActivity.activityType) {
                case orchestratorConstants.ActivityTypes.DataCommand:
                  tempActivity.output = tempActivity.output || {
                    type: '',
                    connectionName: '',
                    folder: '',
                    tableType: '',
                  };
                  break;

                case orchestratorConstants.ActivityTypes.DataMovement:
                  break;

                case orchestratorConstants.ActivityTypes.DataEvent:
                  break;

                case orchestratorConstants.ActivityTypes.DataTransform:
                  break;

                case orchestratorConstants.ActivityTypes.DataValidation:
                  break;

                default:
                  break;
              }

              setTempActivity({ ...tempActivity });
            }}
          ></ElxDropdown>
        </Stack.Item>
        <Stack.Item>
          <ElxDropdown
            onRenderLabel={() => (
              <>
                <LensLabel
                  labelText="Dependency"
                  hintText={
                    'Select the activities that this activity depends on, this activity will be excuted only after the dependent activities are successfully completed'
                  }
                  required={false}
                ></LensLabel>
              </>
            )}
            multiSelect={true}
            options={dependencyOptions}
            onMultiSelectChange={(selectedKeys) => {
              tempActivity.dependsOn = (selectedKeys as string[]).map((s) => ({
                activityId: s,
              }));
              setTempActivity({
                ...tempActivity,
              });
            }}
            selectedKeys={tempActivity.dependsOn?.map((d) => d.activityId)}
          ></ElxDropdown>
        </Stack.Item>
        <Stack.Item>
          {tempActivity.activityType === ActivityTypes.DataCommand && (
            <JobActivityDataCommand
              activity={tempActivity}
              onChange={(activity: Activity): void => {
                setTempActivity({ ...activity });
              }}
            ></JobActivityDataCommand>
          )}
          {tempActivity.activityType === ActivityTypes.DataMovement && (
            <>
              <JobActivityDataProcessing
                activity={tempActivity}
                onChange={(activity: Activity): void => {
                  setTempActivity({ ...activity });
                }}
              ></JobActivityDataProcessing>
            </>
          )}
          {tempActivity.activityType === ActivityTypes.DataEvent && (
            <>
              <JobActivityDataEvent
                activity={tempActivity}
                onChange={(activity: Activity) => {
                  setTempActivity({ ...activity });
                }}
              ></JobActivityDataEvent>
            </>
          )}
          {tempActivity.activityType === ActivityTypes.DataValidation && (
            <>
              <JobActivityDataValidation
                activity={tempActivity}
                onChange={(activity: Activity) => {
                  setTempActivity({ ...activity });
                }}
              ></JobActivityDataValidation>
            </>
          )}
          {tempActivity.activityType === ActivityTypes.T4Transform && (
            <JobActivityT4Transform
              activity={tempActivity}
              onChange={(activity: Activity) => {
                setTempActivity({ ...activity });
              }}
            ></JobActivityT4Transform>
          )}
        </Stack.Item>
        {tempActivity.activityType !== ActivityTypes.DataValidation && (
          <>
            <Stack.Item className={groupBorder}>
              <JobAccordian
                isOpen={false}
                text={'Retry Policy'}
                hint={'Activity Retry policy configurations'}
              >
                <Stack.Item tokens={{ padding: 16 }}>
                  <ElxDropdown
                    id={'Policy'}
                    onRenderLabel={() => (
                      <LensLabel
                        labelText="Policy"
                        hintText={
                          'Select the retry policy - None for no retry, Linear for evenly spaced retries, or Exponential for increasingly long times between retries.'
                        }
                        required={false}
                      ></LensLabel>
                    )}
                    options={retryPolicyOptions}
                    selectedKey={
                      tempActivity.retryPolicy?.type || retryPolicyType[0]
                    }
                    onChange={(_, value) => {
                      tempActivity.retryPolicy!.type =
                        value?.text || retryPolicyType[0];
                      if (
                        tempActivity.retryPolicy!.type !== retryPolicyType[0]
                      ) {
                        tempActivity.retryPolicy!.count =
                          tempActivity.retryPolicy?.count || 1;
                      } else {
                        tempActivity.retryPolicy!.delay = null;
                        tempActivity.retryPolicy!.count = null;
                      }
                      setTempActivity({ ...tempActivity });
                    }}
                  ></ElxDropdown>

                  {tempActivity.retryPolicy?.type !== retryPolicyType[0] && (
                    <>
                      <ElxTextField
                        type={'number'}
                        min={1}
                        max={10}
                        id={'Restart policy'}
                        onRenderLabel={() => (
                          <LensLabel
                            labelText="Count"
                            hintText={
                              'Number of times to retry the activity, on failure'
                            }
                            required={false}
                          ></LensLabel>
                        )}
                        value={
                          tempActivity.retryPolicy!.count?.toString() || '1'
                        }
                        onChange={(_, value) => {
                          tempActivity.retryPolicy!.count = Number(value);
                          setTempActivity({ ...tempActivity });
                        }}
                      ></ElxTextField>
                      <br />

                      <LensLabel
                        labelText="Delay"
                        hintText={'Delay between retries'}
                        required={false}
                      ></LensLabel>

                      <DayHourMinSecPicker
                        showSeconds={false}
                        maxDays={1}
                        setValue={
                          tempActivity.retryPolicy?.delay || '0.00:00:00'
                        }
                        onChange={(value: string): void => {
                          tempActivity.retryPolicy!.delay = value;
                          setTempActivity({ ...tempActivity });
                        }}
                        showDays={true}
                        showHours={true}
                        showMinutes={true}
                      ></DayHourMinSecPicker>
                    </>
                  )}
                </Stack.Item>
              </JobAccordian>
            </Stack.Item>

            <Stack.Item className={groupBorder}>
              <JobAccordian
                isOpen={false}
                text={'Activity Parameters'}
                hint={'Activity Parameters'}
              >
                <Stack.Item tokens={{ padding: 16 }}>
                  <LensLabel
                    labelText="Parameters"
                    hintText={
                      'Activity Parameters, this takes precedence over the parameters defined in the Job level and Workspace level'
                    }
                  ></LensLabel>
                  <Stack horizontal>
                    <Stack.Item
                      className="ms-lg4"
                      style={{
                        fontWeight: 'bold',
                      }}
                    >
                      <div>Name</div>
                    </Stack.Item>
                    <Stack.Item
                      className="ms-lg4"
                      style={{
                        fontWeight: 'bold',
                      }}
                    >
                      <div>Value</div>
                    </Stack.Item>
                    <Stack.Item
                      className="ms-lg4"
                      style={{
                        fontWeight: 'bold',
                      }}
                    >
                      <div>Type</div>
                    </Stack.Item>
                  </Stack>
                  {tempActivity.parameters?.map((o, idx) => (
                    <>
                      <Stack horizontal key={idx}>
                        <Stack.Item className="ms-lg4">
                          <ElxTextField
                            value={o.name}
                            onChange={(_, name) => {
                              tempActivity.parameters![idx]!.name = name || '';
                              setTempActivity({ ...tempActivity });
                            }}
                            styles={{
                              root: {
                                marginRight: '10px',
                              },
                            }}
                          ></ElxTextField>
                        </Stack.Item>
                        <Stack.Item className="ms-lg4">
                          <ElxTextField
                            value={o.value}
                            onChange={(_, value) => {
                              tempActivity.parameters![idx]!.value =
                                value || '';
                              setTempActivity({ ...tempActivity });
                            }}
                            styles={{
                              root: {
                                marginRight: '10px',
                              },
                            }}
                          ></ElxTextField>
                        </Stack.Item>
                        <Stack.Item className="ms-lg4">
                          <Stack horizontal>
                            <ElxDropdown
                              options={parameterOptionsType}
                              selectedKey={parameterType.indexOf(o.type)}
                              onChange={(e, value) => {
                                tempActivity.parameters![idx]!.type =
                                  value?.text || '';
                                setTempActivity({ ...tempActivity });
                              }}
                              styles={{
                                root: {
                                  marginRight: '10px',
                                },
                              }}
                            ></ElxDropdown>
                            <ElxIconButton
                              iconProps={{
                                iconName: 'cancel',
                              }}
                              text={''}
                              onClick={() => {
                                tempActivity.parameters =
                                  tempActivity.parameters?.filter(
                                    (_, index) => index !== idx
                                  );
                                setTempActivity({ ...tempActivity });
                              }}
                            ></ElxIconButton>
                          </Stack>
                        </Stack.Item>
                      </Stack>
                    </>
                  ))}
                  <Stack.Item
                    className="ms-lg12"
                    align="end"
                    style={{
                      paddingRight: '40px',
                    }}
                  >
                    <ElxActionButton
                      iconProps={{
                        iconName: 'Add',
                      }}
                      text="Add"
                      onClick={() => {
                        tempActivity.parameters = [
                          ...(tempActivity?.parameters || []),
                          {
                            name: '',
                            value: '',
                            type: '',
                          },
                        ];
                        setTempActivity({ ...tempActivity });
                      }}
                      style={{
                        float: 'right',
                      }}
                    />
                    <ElxActionButton />
                  </Stack.Item>
                </Stack.Item>
              </JobAccordian>
            </Stack.Item>
          </>
        )}
      </Stack>
    </ElxPanel>
  );
}
