import { ElxPanel, PanelSize } from '@elixir/components';
import { Stack, ThemeProvider } from '@fluentui/react';
import { Editor } from 'components/monacoEditor';
import { Job } from 'features/orchestrator/models/job';
import { useLensShellTheme } from 'features/shell/lensShellStyles';
import { useCallback, useEffect, useState } from 'react';
import jobApi from '../api/jobApi';
import notifier from 'utils/notifier';

export interface CodeViewProps {
  job: Job;
  workspaceId: string;
  onCancel: () => void;
  onSuccess: () => void;
}

const CodeView = (props: CodeViewProps) => {
  const { job, onCancel, onSuccess, workspaceId } = props;
  const theme = useLensShellTheme();
  const [jobStr, setJobStr] = useState<string>(JSON.stringify(job, null, 4));
  const [busySaveButton, setBusySaveButton] = useState(false);
  // height of the monaco editor - approx height of window - panel header height - panel footer height - buffer;
  const [height, setHeight] = useState(window.innerHeight); // window.innerHeight - 100 - 80 - 60;
  const editorHeight = height - 160 < 0 ? 1200 : height - 160;
  const heightHandler = useCallback(() => {
    setHeight(window.innerHeight);
  }, [setHeight]);
  useEffect(() => {
    window.addEventListener('resize', heightHandler);
    return () => {
      window.removeEventListener('resize', heightHandler);
    };
  });

  return (
    <ThemeProvider theme={theme}>
      <ElxPanel
        headerText={`View/Edit Job - ${job.name}`}
        isOpen={true}
        size={PanelSize.large}
        actions={[
          {
            key: 'save',
            text: busySaveButton ? 'Saving Job..' : 'Save Job',
            primary: true,
            onClick: async () => {
              let tempJob = JSON.parse(jobStr) as Job;
              try {
                setBusySaveButton(true);
                await jobApi.updateJob(workspaceId, job.id, tempJob);
                notifier.info(`Job successfully modified name=${tempJob.name}`);
                setBusySaveButton(false);
                onSuccess();
              } catch (err) {
                setBusySaveButton(false);
                notifier.error(
                  `Saving of job failed, name=${tempJob.name} error=${err}`
                );
              }
            },
            iconProps: { iconName: busySaveButton ? 'refresh' : 'save' },
            disabled: busySaveButton,
          },
          {
            key: 'close',
            text: 'Close',
            onClick: () => {
              onCancel();
            },
          },
        ]}
      >
        <Stack verticalFill>
          <Stack.Item grow>
            <Editor
              language={'json'}
              value={jobStr}
              onChange={(value: string) => {
                setJobStr(value);
              }}
              height={editorHeight}
            ></Editor>
          </Stack.Item>
        </Stack>
      </ElxPanel>
    </ThemeProvider>
  );
};

export default CodeView;
