import React from 'react';
import { useSelector } from 'react-redux';
import {
  selectWorkspace,
  selectWorkspaceStatus,
} from 'features/workspaces/workspaceSlice';
import { useRouter } from '@uirouter/react';
import { WorkspaceStatus } from 'features/workspaces/models/workspace';
import { telemetryDimensions } from 'features/telemetry/telemetryDimensions';
import { selectUserprofileStatus } from 'features/userprofile/userprofileSlice';
import { getName, getObjectId } from 'utils/authUtils';
import { BUILD_NUM, lensSessionId } from 'utils/sharedLibs';
import { UserprofileStatus } from 'features/userprofile/models/userprofile';

export const TelemetryListener = (): JSX.Element => {
  const router = useRouter();
  const userprofileStatus = useSelector(selectUserprofileStatus);
  const workspace = useSelector(selectWorkspace);
  const workspaceStatus = useSelector(selectWorkspaceStatus);
  if (
    workspaceStatus === WorkspaceStatus.Loaded &&
    userprofileStatus === UserprofileStatus.Loaded
  ) {
    telemetryDimensions.UserId = getObjectId();
    telemetryDimensions.UserName = getName();
    telemetryDimensions.Workspace = workspace.id;
    telemetryDimensions.WorkspaceName = workspace.name;
    telemetryDimensions.WorkspaceType = workspace.shareType;
    telemetryDimensions.StateUrl = router.globals.current.url || '';
    telemetryDimensions.StateName = router.globals.current.name || '';
    telemetryDimensions.VersionId = router.globals.params.versionId;
    telemetryDimensions.BuildNum = BUILD_NUM;
    telemetryDimensions.LensSessionId = lensSessionId;
  }
  return <></>;
};
