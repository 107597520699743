import { useEffect, useMemo, useState } from 'react';
import {
  SearchBox,
  Label,
  Stack,
  FontIcon,
  Spinner,
  SpinnerSize,
  GroupedList,
  SelectionMode,
  IGroup,
  IStackTokens,
} from '@fluentui/react';
import {
  labelStyles1,
  classNames,
  stackItemStyles,
  labelStyles2,
  AccessIcon,
  AccessLabel,
} from './workspacesStyles';
import {
  getAllWorkspaces,
  getFavoriteAndRecentWorkspaces,
  workspaceAccessFilter,
  workspaceAccessIcon,
  WorkspaceItem,
} from '../utils/workspaceQuickSwitchUtils';
import { useSelector } from 'react-redux';
import { ElxIconButton } from '@elixir/components';
import {
  selectUserprofile,
  selectUserprofileStatus,
} from 'features/userprofile/userprofileSlice';
import {
  selectAllWorkspaces,
  selectWorkspaceListEntities,
  selectWorkspaceListStatus,
  useWorkspaceList,
} from '../workspaceListSlice';
import { UserprofileStatus } from 'features/userprofile/models/userprofile';
import {
  WorkspaceAccessFilter,
  WorkspaceListStatus,
} from '../models/workspace';
import { selectWorkspaceId } from '../workspaceSlice';
import { getPrivateWorkspaceId } from '../utils/workspaceUtils';
import {
  selectUserFavorites,
  selectUserFavoritesStatus,
  selectUserRecents,
  selectUserRecentsStatus,
  useUserFavorites,
  useUserRecents,
} from '../../userInfo/userSavedObjectDataSlice';
import UserSavedObjectData, {
  LensObjectType,
  UserSavedObjectDataStatus,
} from '../../userInfo/models/userSavedObjectData';
import { getAlias } from 'utils/authUtils';
import VirtualizeListWrapper from 'components/virtualizeListWrapper';

interface Items {
  favoritesAndRecents: WorkspaceItem[];
  all: WorkspaceItem[];
}

interface WorkspacePickerProps {
  onWorkspaceSelect: (selectedWs: WorkspaceItem) => void;
  filterFunction?: (arg: WorkspaceItem) => boolean;
  height?: number | string;
}

const getPrivateWorkspace = (
  upn: string,
  currentWorkspaceId: string
): WorkspaceItem => {
  return {
    name: 'Private Workspace',
    isFavorite: true,
    isCurrent: currentWorkspaceId === getPrivateWorkspaceId(),
    createdBy: upn,
    accessType: workspaceAccessFilter('owned'),
    accessIcon: workspaceAccessIcon('owned'),
    workspaceId: 'private-' + getAlias(),
    accessFilter: WorkspaceAccessFilter.Owned,
  };
};

const ListSpinner = (
  <Stack tokens={{ padding: 16 }}>
    <Spinner
      size={SpinnerSize.large}
      ariaLabel={'Loading remaining workspaces'}
    />
  </Stack>
);

//WorkspaceQuickSwitch Panel
export function WorkspacePicker(props: WorkspacePickerProps) {
  useWorkspaceList();
  useUserFavorites();
  useUserRecents();
  const currentWorkspaceId = useSelector(selectWorkspaceId);
  const workspaceListStatus = useSelector(selectWorkspaceListStatus);
  const workspaceList = useSelector(selectAllWorkspaces);
  const workspaceListEntities = useSelector(selectWorkspaceListEntities);
  const userProfileStatus = useSelector(selectUserprofileStatus);
  const userprofile = useSelector(selectUserprofile);
  const userFavoritesStatus = useSelector(selectUserFavoritesStatus);
  const userFavorites = useSelector(selectUserFavorites);
  const userRecentsStatus = useSelector(selectUserRecentsStatus);
  const userRecents = useSelector(selectUserRecents);
  const [search, setSearch] = useState({ searchString: '' });
  const [filteredItems, setFilteredItems] = useState<Items>({
    all: [],
    favoritesAndRecents: [],
  });
  const [items, setItems] = useState<Items>({
    all: [],
    favoritesAndRecents: [],
  });
  const [selectedId, setSelectedId] = useState('');
  const privateWorkspace = useMemo(
    () => getPrivateWorkspace(userprofile?.UPN, currentWorkspaceId),
    [userprofile?.UPN, currentWorkspaceId]
  );

  const itemAlignmentsStackTokensNew: IStackTokens = {
    childrenGap: '0 5',
  };
  const onRenderCell = (
    nestingDepth?: number,
    workspaceItem?: WorkspaceItem
  ): JSX.Element => {
    if (workspaceItem) {
      let createdBy =
        workspaceItem.accessType + ' | Created by ' + workspaceItem.createdBy;
      let CurrentlySelected = workspaceItem.isCurrent
        ? '(Currently Workspace) '
        : '';

      return (
        <div
          className={
            selectedId === workspaceItem.workspaceId
              ? classNames.itemCellSelected
              : classNames.itemCell
          }
          data-is-focusable={true}
          onClick={() => {
            setSelectedId(workspaceItem.workspaceId);
            props.onWorkspaceSelect(workspaceItem);
          }}
        >
          <Stack horizontal tokens={itemAlignmentsStackTokensNew}>
            <Stack.Item styles={stackItemStyles}>
              <Stack>
                <Stack.Item>
                  <Label styles={labelStyles2}>
                    {CurrentlySelected}
                    {workspaceItem.name}
                  </Label>
                </Stack.Item>
                <Stack.Item>
                  <Stack horizontal tokens={itemAlignmentsStackTokensNew}>
                    <Stack.Item>
                      <FontIcon
                        iconName={workspaceItem.accessIcon}
                        className={AccessIcon}
                      />
                    </Stack.Item>
                    <Stack.Item>
                      <Label className={AccessLabel}>{createdBy}</Label>
                    </Stack.Item>
                  </Stack>
                </Stack.Item>
              </Stack>
            </Stack.Item>
            <Stack.Item grow>
              <ElxIconButton
                text={workspaceItem.isFavorite ? 'Unfavorite' : 'Favorite'}
                styles={labelStyles1}
                iconProps={{
                  iconName: workspaceItem.isFavorite
                    ? 'FavoriteStarFill'
                    : 'FavoriteStar',
                  className: classNames.favorite,
                }}
              />
            </Stack.Item>
          </Stack>
        </div>
      );
    } else return <></>;
  };

  const updateResultsForSearch = (searchString: string, items: Items) => {
    let favoritesAndRecents: WorkspaceItem[] = [];
    let all: WorkspaceItem[] = [];
    if (searchString.length > 0) {
      const lcSearchString = searchString.toLowerCase();
      items.favoritesAndRecents.forEach((workspace: WorkspaceItem, index) => {
        if (workspace.name?.toLowerCase().includes(lcSearchString)) {
          favoritesAndRecents.push(workspace);
        }
      });
      items.all.forEach((workspace: WorkspaceItem, index) => {
        if (workspace.name?.toLowerCase().includes(lcSearchString)) {
          all.push(workspace);
        }
      });
    }

    all = props.filterFunction ? all.filter(props.filterFunction) : all;
    favoritesAndRecents = props.filterFunction
      ? favoritesAndRecents.filter(props.filterFunction)
      : favoritesAndRecents;

    return { all, favoritesAndRecents };
  };

  useEffect(() => {
    if (
      userProfileStatus === UserprofileStatus.Loaded && // needed for private workspace
      userFavoritesStatus === UserSavedObjectDataStatus.Loaded &&
      (workspaceListStatus === WorkspaceListStatus.Loaded ||
        workspaceListStatus === WorkspaceListStatus.Fetching)
    ) {
      const favWorkspaces = userFavorites.filter(
        (fav: UserSavedObjectData) =>
          fav.lensObjectType === LensObjectType.Workspace
      );
      const recWorkspaces = userRecents.filter(
        (rec: UserSavedObjectData) =>
          rec.lensObjectType === LensObjectType.Workspace
      );

      let all = getAllWorkspaces(
        currentWorkspaceId,
        favWorkspaces,
        workspaceList,
        privateWorkspace
      );
      let favoritesAndRecents = getFavoriteAndRecentWorkspaces(
        currentWorkspaceId,
        favWorkspaces,
        recWorkspaces,
        workspaceListEntities,
        privateWorkspace
      );

      all = props.filterFunction ? all.filter(props.filterFunction) : all;
      favoritesAndRecents = props.filterFunction
        ? favoritesAndRecents.filter(props.filterFunction)
        : favoritesAndRecents;

      setItems({ all, favoritesAndRecents });
    }
  }, [
    currentWorkspaceId,
    userProfileStatus,
    workspaceListStatus,
    workspaceList,
    userprofile,
    userFavoritesStatus,
    userRecentsStatus,
    userFavorites,
    userRecents,
    workspaceListEntities,
    privateWorkspace,
    props.filterFunction,
  ]);

  useEffect(() => {
    if (search.searchString.length > 0) {
      const { all, favoritesAndRecents } = updateResultsForSearch(
        search.searchString,
        items
      );
      setFilteredItems({ all, favoritesAndRecents: favoritesAndRecents });
    } else {
      setFilteredItems(items);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, items]);

  const favAndRecentsLength = filteredItems.favoritesAndRecents.length;
  let groups: IGroup[] = [
    {
      key: 'recents&favs',
      name: 'Recents and Favorites',
      startIndex: 0,
      count: favAndRecentsLength,
      level: 0,
      isCollapsed: false,
    },
    {
      key: 'allOtherWs',
      name: 'All Other Workspaces',
      startIndex: favAndRecentsLength,
      count: filteredItems.all.length,
      level: 0,
      isCollapsed: false,
    },
  ];

  return (
    <Stack verticalFill data-testid="workspace-picker">
      <Stack.Item shrink={false}>
        <Stack
          tokens={{
            childrenGap: '5',
          }}
        >
          <Stack.Item styles={{ root: { padding: 2 } }}> </Stack.Item>
          <Stack.Item>
            <SearchBox
              className="discover-search"
              styles={{
                root: {
                  display: 'inline-flex',
                  verticalAlign: 'middle',
                  marginLeft: '10px',
                  width: '95%',
                },
              }}
              value={search.searchString}
              ariaLabel="Search workspaces..."
              title="Search workspaces..."
              placeholder="Search workspaces..."
              onChange={(_, value) => {
                setSearch({
                  searchString: value || '',
                });
              }}
              onClear={() => {
                setSearch({
                  searchString: '',
                });
              }}
            />
          </Stack.Item>
          <VirtualizeListWrapper height={props.height ? props.height : 700}>
            <>
              {workspaceListStatus === WorkspaceListStatus.Fetching &&
                ListSpinner}
              <GroupedList
                items={filteredItems.favoritesAndRecents.concat(
                  filteredItems.all
                )}
                groups={groups}
                onRenderCell={onRenderCell}
                compact={false}
                selectionMode={SelectionMode.single}
                styles={{
                  group: { marginLeft: '-50px' },
                }}
                onShouldVirtualize={() => true}
              />
            </>
          </VirtualizeListWrapper>
        </Stack>
      </Stack.Item>
    </Stack>
  );
}
