import { useDispatch, useSelector } from 'react-redux';
import {
  ElxIconButton,
  ElxTableContainer,
  FilterDisplayMode,
  FilterOption,
  FilterOptionMode,
  FilterOptionPillMode,
  IElxColumn,
  IElxContainerProps,
  SelectionMode,
} from '@elixir/components';
import { CheckboxVisibility, IObjectWithKey } from '@fluentui/react';
import { favorite } from './homePage';
import {
  deleteUserFavorites,
  loadUserFavorites,
  loadUserRecents,
  saveUserFavorites,
  selectUserFavoritesListEntities,
  selectUserRecentsStatus,
} from '../../userInfo/userSavedObjectDataSlice';
import {
  classNames,
  colLarge,
  colMedium,
  colSmall,
  colXLarge,
  containerStyles,
  labelStyles4,
  TableProps,
} from './favorites';
import UserSavedObjectData, {
  UserSavedObjectDataType,
} from '../../userInfo/models/userSavedObjectData';
import { tableStyles } from 'utils/sharedStyles';
import {
  selectWorkspaceListEntities,
  useWorkspaceList,
} from '../../workspaces/workspaceListSlice';
import { AppDispatch } from '../../../app/lensShellUtility';

export const Recents = (props: TableProps): JSX.Element => {
  useWorkspaceList();
  const dispatch = useDispatch<AppDispatch>();
  const userRecentStatus = useSelector(selectUserRecentsStatus);
  const workspaces = useSelector(selectWorkspaceListEntities);
  const userFavoritesEntities = useSelector(selectUserFavoritesListEntities);
  const containerProps = {
    compact: true,
    isLoading: userRecentStatus !== 'Loaded',
    styles: containerStyles,
  } as IElxContainerProps;
  const StringOperators = ['==', '!=', 'Contains'];
  const columns: IElxColumn[] = [
    {
      ...colLarge,
      key: 'name',
      name: 'Name',
      fieldName: 'name',
    },
    {
      ...colSmall,
      key: 'favorites',
      name: '',
      onRender: (item: UserSavedObjectData) => {
        const id = item.workspaceId + '_' + item.lensObjectId;
        let iconName = 'FavoriteStar';
        if (userFavoritesEntities.hasOwnProperty(id)) {
          iconName = 'FavoriteStarFill';
        }
        return (
          <ElxIconButton
            text=""
            styles={labelStyles4}
            iconProps={{
              iconName: iconName,
              className: classNames.favorite,
            }}
            onClick={() => {
              if (userFavoritesEntities.hasOwnProperty(id)) {
                unFavorite(item);
              } else {
                favorite(item);
              }
            }}
          />
        );
      },
    },
    {
      ...colXLarge,
      key: 'description',
      name: 'Activity/Object',
      fieldName: 'description',
    },
    {
      ...colLarge,
      key: 'lensObjectType',
      name: 'Type',
      fieldName: 'lensObjectType',
    },
    {
      ...colXLarge,
      key: 'status',
      name: 'Status',
      fieldName: 'status',
    },
    {
      ...colMedium,
      key: 'date',
      name: 'Date',
      onRender: (item: any) => {
        if (item.date) {
          return item.date.toDateString();
        }
      },
    },
  ];

  const namePillFilter: FilterOption = {
    field: 'name',
    label: 'Name',
    operators: StringOperators,
    mode: FilterOptionMode.Text,
    pillMode: FilterOptionPillMode.Static,
  };
  const activityPillFilter: FilterOption = {
    field: 'description',
    label: 'Activity/Object',
    operators: StringOperators,
    mode: FilterOptionMode.Text,
    pillMode: FilterOptionPillMode.Static,
  };
  const typePillFilter: FilterOption = {
    field: 'lensObjectType',
    label: 'Type',
    multiselect: true,
    pillMode: FilterOptionPillMode.Dynamic,
    values: props.tableMap
      .map((item) => item.lensObjectType)
      .filter((value, index, self) => self.indexOf(value) === index),
  };
  const statusPillFilter: FilterOption = {
    field: 'status',
    label: 'Status',
    operators: StringOperators,
    mode: FilterOptionMode.Text,
    pillMode: FilterOptionPillMode.Static,
  };

  const searchProps = {
    pillFilters: [
      namePillFilter,
      activityPillFilter,
      typePillFilter,
      statusPillFilter,
    ],
    filterDisplayMode: FilterDisplayMode.Pill,
  };

  const unFavorite = async (item: UserSavedObjectData) => {
    const favoriteItem: UserSavedObjectData[] = [
      {
        workspaceId: item.workspaceId,
        type: UserSavedObjectDataType.Favorite,
        lensObjectId: item.workspaceId || '',
        lensObjectType: 'workspace',
      },
    ];
    await dispatch(
      deleteUserFavorites({ userSavedObjectDataList: favoriteItem })
    );
    dispatch(loadUserFavorites({ workspaces: workspaces }));
    dispatch(loadUserRecents({ workspaces: workspaces }));
  };
  const favorite = async (item: UserSavedObjectData) => {
    const favoriteItem: UserSavedObjectData[] = [
      {
        workspaceId: item.workspaceId,
        type: UserSavedObjectDataType.Favorite,
        lensObjectId: item.workspaceId || '',
        lensObjectType: 'workspace',
      },
    ];
    await dispatch(
      saveUserFavorites({ userSavedObjectDataList: favoriteItem })
    );
    dispatch(loadUserFavorites({ workspaces: workspaces }));
    dispatch(loadUserRecents({ workspaces: workspaces }));
  };

  return (
    <ElxTableContainer
      containerProps={containerProps}
      tableProps={{
        columns,
        selectionMode: SelectionMode.single,
        items: props.tableMap,
        checkboxVisibility: CheckboxVisibility.hidden,
        styles: tableStyles,
        onSelectionChanged: (val: IObjectWithKey[]) => {
          if (val.length > 0) {
            props.onSelectionChange(val[0] as favorite);
          }
        },
        getKey: (item: favorite) => item.id,
      }}
      searchBoxProps={searchProps}
    />
  );
};
