import { useEffect, useRef, useState } from 'react';
import { IDropdownOption, IPickerItem, Stack } from '@fluentui/react';
import { ElxPicker, ElxTextField } from '@elixir/components';
import { getDataSourcePlugin } from 'features/dataSources/registry';
import { Database } from 'features/dataSources/models/dataSource';
import useDebounce from 'components/customHooks/useDebounce';
import { LensLabel } from 'utils/lensLabel';
import { AadConnectionAuthType } from 'features/workspaces/models/project';
import { SelectorProps } from '../editDataConnectionHelper';

export interface PickerItem extends IPickerItem {
  key: string;
  text: string;
}

export interface EditDataConnectionState {
  name?: string;
  type: string;
  cluster: string;
  database: string;
  authMode: AadConnectionAuthType;
  appId: string;
}

enum ClusterInfoStatus {
  LOADING = 'LOADING',
  READY = 'READY',
  ERROR = 'ERROR',
}

interface ClusterInfo {
  status: ClusterInfoStatus;
  databases?: Database[];
}

interface ClusterInfos {
  [clusterName: string]: ClusterInfo;
}

export const KustoDatabaseSelector = (props: SelectorProps): JSX.Element => {
  const [clusterInfos, setClusterInfos] = useState<ClusterInfos>({});

  const clusterInfosRef = useRef<ClusterInfos>();
  clusterInfosRef.current = clusterInfos;
  const [clusterName, setClusterName] = useState(props.state.cluster || '');
  const [databasesLoadingCluster, setDatabasesLoadingCluster] = useState('');
  const databasesLoadingClusterRef = useRef('');
  databasesLoadingClusterRef.current = databasesLoadingCluster;
  const debouncedCluster = useDebounce<string>(props.state.cluster || '', 500);
  const dataSourceClient = getDataSourcePlugin(
    props.state.type
  )?.dataSourceClient;

  useEffect(() => {
    if (
      dataSourceClient &&
      debouncedCluster.length > 0 &&
      !clusterInfos[debouncedCluster]
    ) {
      setClusterInfos({
        ...clusterInfos,
        [debouncedCluster]: { status: ClusterInfoStatus.LOADING },
      });
      setDatabasesLoadingCluster(debouncedCluster);
      dataSourceClient
        .getDatabases(debouncedCluster)
        .then(function (databases) {
          if (databasesLoadingClusterRef.current === debouncedCluster) {
            setClusterInfos({
              ...clusterInfosRef.current,
              [debouncedCluster]: {
                status: ClusterInfoStatus.LOADING,
                databases,
              },
            });
          }
        })
        .catch(function () {
          if (databasesLoadingClusterRef.current === debouncedCluster) {
            setClusterInfos({
              ...clusterInfosRef.current,
              [debouncedCluster]: { status: ClusterInfoStatus.ERROR },
            });
          }
        })
        .finally(() => {
          if (databasesLoadingClusterRef.current === debouncedCluster) {
            setDatabasesLoadingCluster('');
          }
        });
    }
  }, [
    dataSourceClient,
    debouncedCluster,
    clusterInfos,
    databasesLoadingCluster,
  ]);

  const databaseOptions: IDropdownOption[] = databasesLoadingCluster
    ? [
        {
          key: 0,
          text: 'Loading databases...',
          data: { spinner: 'left' },
        },
      ]
    : clusterInfos[props.state.cluster || '']?.databases
        ?.map((database) => {
          return {
            key: database.DatabaseName,
            text: database.PrettyName || database.DatabaseName,
          };
        })
        .sort((a: PickerItem, b: PickerItem) => (a.key > b.key ? 1 : -1)) || [];

  return (
    // ElxPanels are 'outside' of LensShell, so they must have their own ThemeProvider wrapper
    <Stack tokens={{ childrenGap: 16 }}>
      <Stack.Item>
        <ElxTextField
          label="Cluster"
          value={clusterName}
          placeholder={
            'cluster_name.' +
            window.startUpConfig.serviceEndpoints.kustoClusterSuffix
          }
          required
          onChange={(_, newValue) => {
            setClusterName(newValue || '');
          }}
          onBlur={() => {
            props.setState({ ...props.state, cluster: clusterName });
          }}
        />
      </Stack.Item>
      <Stack.Item>
        <ElxPicker
          label="Database"
          onRenderLabel={() => (
            <>
              <LensLabel
                labelText="Select a Database"
                hintText={'Select a database'}
                required={true}
              ></LensLabel>
            </>
          )}
          items={databaseOptions as PickerItem[]}
          selectedKey={props.state.database}
          placeHolder="Select a database"
          searchBoxProps={{
            placeholder: 'Search databases',
          }}
          onChange={(newValue: PickerItem) => {
            props.setState({
              ...props.state,
              database: newValue?.key.toString() || '',
            });
          }}
          required
        />
      </Stack.Item>
    </Stack>
  );
};

export default KustoDatabaseSelector;
