import {
  getConfig,
  IElixirRoute,
  uxSetContainerLoadingAction,
} from '@elixir/fx';
import {
  IContextualMenuProps,
  IProcessedStyleSet,
  IStackStyles,
  mergeStyleSets,
  Stack,
  ThemeProvider,
} from '@fluentui/react';
import React, { PropsWithChildren, useEffect } from 'react';
import { cloneDeep } from 'lodash';
import { ElxBranding } from '@elixir/fx/lib/shell/Header/Branding';
import { ElxHeaderActions } from '@elixir/fx/lib/shell/Header/HeaderActions';
import {
  IElxShellProps,
  IElxShellStyles,
} from '@elixir/fx/lib/shell/ElxShell.types';
import { Provider, useDispatch } from 'react-redux';
import {
  AuthModule,
  getCurrentUserAlias,
  allowCurrentUser,
} from '@elixir/auth';
import {
  PresentationModeStyles,
  useElxShellStyles,
} from '@elixir/fx/lib/shell/ElxShell.styles';
import { lensFramework, LensRoute } from 'app/elxAdapter';
import { AppDispatch } from 'app/lensShellUtility';
import { useScopedContainer } from '@elixir/fx/lib/hooks/useScopedContainer';
import { LoadingPage } from '@elixir/fx/lib/shell/LoadingPage';
import { Flighting } from '@elixir/flighting';
import {
  expandRoutes,
  filterRoutesFromModules,
  renderRoutes,
} from '@elixir/fx/lib/shell/Routing/RouteUtils';
import { usePresentationMode } from '@elixir/fx/lib/hooks/usePresentationMode';
import {
  ElxErrorBoundary,
  onDefaultRenderFallback,
} from '@elixir/components/lib/ErrorBoundary';
import { HashRouter as Router, Switch } from 'react-router-dom';
import { ElxShellBreadcrumbContextProvider } from '@elixir/fx/lib/hooks/useBreadcrumb';
import { ElxHeader } from '@elixir/fx/lib/shell/Header';
import { ElxToastNotification, INotification } from '@elixir/components';
import { ElxUnauthorizedPage } from '@elixir/fx/lib/shell/UnauthorizedPage/UnauthorizedPage';
import { UIView } from '@uirouter/react';
import { ElxResponsiveContextProvider } from '@elixir/styling';
import { LensSidebar } from '../sidebar/lensSidebar';
import { useLensShellTheme } from 'features/shell/lensShellStyles';
import { TelemetryListener } from 'features/telemetry/telemetryListener';

const LensCustomShell = (
  props: PropsWithChildren<IElxShellProps>
): JSX.Element => {
  const config = getConfig();
  const { history, store } = lensFramework;
  const shellStyles = {
    root: {
      margin: 0, // elxshell seems to start with negative margin?
      '.elx-sidebar-icon': {
        padding: 0,
      },
    },
    body: {
      position: 'relative',
    },
  } as IElxShellStyles;

  const theme = useLensShellTheme();
  const classnames = useElxShellStyles({ theme }, shellStyles);

  return (
    <ThemeProvider className={classnames.root} applyTo="element" theme={theme}>
      <Provider store={store}>
        <AuthModule authMode={config.authConfig?.authentication}>
          <ShellWithFlighting
            {...props}
            defaultPath={undefined}
            classnames={classnames}
            history={history}
          />
          <TelemetryListener />
        </AuthModule>
      </Provider>
    </ThemeProvider>
  );
};

LensCustomShell.Branding = ElxBranding;
LensCustomShell.Actions = ElxHeaderActions;

export { LensCustomShell };

type ShellProps = React.PropsWithChildren<IElxShellProps> & {
  history: History;
  classnames?: IProcessedStyleSet<IElxShellStyles>;
  flightingConfig?: Record<string, boolean>;
  userDropdownProps?: IContextualMenuProps;
};

const ShellWithFlighting = (props: ShellProps): JSX.Element => {
  const { scope, loadingProps, onInit } = props;
  const label = (loadingProps && loadingProps.label) || '';

  const dispatch = useDispatch<AppDispatch>();
  const container = useScopedContainer(scope);
  useEffect(() => {
    if (scope && onInit) {
      dispatch(uxSetContainerLoadingAction(true, label, scope));
      onInit();
    } else if (onInit) {
      onInit();
    }
  }, [dispatch, label, onInit, scope]);

  const flightingConfig = useFlighting();

  return flightingConfig === false || (container && container.isLoading) ? (
    <LoadingPage {...loadingProps} label={label} />
  ) : (
    <Shell {...props} flightingConfig={flightingConfig} />
  );
};

function useFlighting(): Record<string, boolean> | false | undefined {
  const [flightingConfig, setFlightingConfig] = React.useState<
    Record<string, boolean> | undefined | false
  >(false);
  const hasFlightingEnabled = !!getConfig().applicationFlightingConfig;

  useEffect(() => {
    if (hasFlightingEnabled) {
      new Flighting(getCurrentUserAlias())
        .loadConfig()
        .then(setFlightingConfig)
        .catch(() => {
          // Passing undefined means the flighting config was supposed to be fetched and unfortunately wasn't
          setFlightingConfig(undefined);
        });
    } else {
      // Passing an empty config means no features are configured in the flighting system.
      setFlightingConfig({});
    }
  }, [hasFlightingEnabled]);

  return flightingConfig;
}

const Shell = (props: ShellProps): JSX.Element => {
  const {
    modules,
    footerRoutes,
    flightingConfig,
    defaultSidebarCollapsed,
    enableExternalRoutes,
    headerStyles,
    // classnames,
    // history,
  } = props;
  const footer: IElixirRoute[] = [];
  if (footerRoutes) {
    footer.push(...footerRoutes);
  }
  const mainRoutes: LensRoute[] = [];
  if (modules) {
    mainRoutes.push(
      ...filterRoutesFromModules(
        cloneDeep(modules),
        flightingConfig,
        enableExternalRoutes
      )
    );
  }

  const allRoutes: IElixirRoute[] = [...mainRoutes, ...footer];
  const allExpandedRoutes: IElixirRoute[] = expandRoutes(allRoutes);
  const { header } = getCompoundComponents(props);

  const { isPresentationMode } = usePresentationMode();
  const mainStackStyles = {
    root: {
      position: 'relative',
      width: '100%',
      height: '100%',
      overflow: 'auto',
    },
    main: {
      height: '100%',
    },
  } as IStackStyles;

  return (
    <ElxErrorBoundary onRenderFallback={onDefaultRenderFallback}>
      <Router>
        <ElxResponsiveContextProvider>
          {allowCurrentUser() ? (
            <ElxShellBreadcrumbContextProvider routes={allRoutes}>
              <ElxHeader
                {...header}
                styles={mergeStyleSets(
                  headerStyles,
                  isPresentationMode && { root: PresentationModeStyles }
                )}
              />
              <div className={props.classnames?.body}>
                <LensSidebar
                  routes={mainRoutes}
                  footerRoutes={footer}
                  styles={
                    isPresentationMode
                      ? { root: PresentationModeStyles }
                      : undefined
                  }
                  isDefaultCollapsed={defaultSidebarCollapsed}
                />
                <Stack styles={mainStackStyles}>
                  <UIView className="lens-shell-view"></UIView>
                  <Switch>
                    {renderRoutes(allExpandedRoutes.filter((r) => r.component))}
                  </Switch>
                </Stack>
              </div>
              <ElxToastNotification
                notifications={[]}
                onToastDismiss={function (notifications: INotification): void {
                  throw new Error('Function not implemented.');
                }}
              />
            </ElxShellBreadcrumbContextProvider>
          ) : (
            <ElxUnauthorizedPage />
          )}
        </ElxResponsiveContextProvider>
      </Router>
    </ElxErrorBoundary>
  );
};

interface ICompoundComponents {
  header: {
    branding?: JSX.Element;
    actions?: JSX.Element;
  };
}

function getCompoundComponents(
  props: React.PropsWithChildren<IElxShellProps>
): ICompoundComponents {
  const compound: ICompoundComponents = {
    header: {
      branding: undefined,
      actions: undefined,
    },
  };

  React.Children.forEach(props.children, (o: React.ReactNode) => {
    const element = o as React.ReactElement;
    const component = element.type as React.FunctionComponent;
    const displayName = component.displayName;

    switch (displayName) {
      case ElxBranding.displayName:
        compound.header.branding = element;
        break;
      case ElxHeaderActions.displayName:
        compound.header.actions = element;
        break;
      default:
        break;
    }
  });

  return compound;
}
