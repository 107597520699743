import { TrainingAndGuidesProps } from './trainingAndGuidesList';

export const sampleData: TrainingAndGuidesProps[] = [
  {
    key: '1',
    mainLabel: 'Sample Dashboard',
    secondaryLabel: 'Checkout our Sample Dashboard',
    iconName: 'DashboardIcon',
    iconText: '',
    link:
      window.location.origin +
      '/#/dashboard/Sample%20Visualizations?newDashFromMenu=false&isSample=true&isVersion=0&isHistory=0&_g=()',
  },
  {
    key: '2',
    mainLabel: 'Groups Sample Dashboard',
    secondaryLabel: 'Checkout Groups in Dashboard',
    iconName: 'DashboardIcon',
    iconText: '',
    link:
      window.location.origin +
      '/#/dashboard/Groups%20Demo?newDashFromMenu=false&isSample=true&isVersion=0&isHistory=0&_g=()',
  },
  {
    key: '3',
    mainLabel: 'Pie Chart Visualization',
    secondaryLabel:
      'Breakdown of pageView count by City, with one pie per Country',
    iconName: 'QueryIcon',
    iconText: '',
    link:
      window.location.origin +
      '/#/visualize/edit/Demo%20Pie%20with%20Split%20Chart?isSample=true&_g=()',
  },
  {
    key: '4',
    mainLabel: 'Line Chart Visualization',
    secondaryLabel: 'Distribution of pageViews over the past 30 days',
    iconName: 'QueryIcon',
    iconText: '',
    link:
      window.location.origin +
      '/#/visualize/edit/Test%20Line%20Chart?isSample=true&_g=()',
  },
  {
    key: '5',
    mainLabel: 'Bar Chart Visualization',
    secondaryLabel: 'Trend of Top States represented by grouped bars over time',
    iconName: 'QueryIcon',
    iconText: '',
    link:
      window.location.origin +
      '/#/visualize/edit/Demo%20bar%20grouped?isSample=true&_g=()',
  },
  {
    key: '6',
    mainLabel: 'Area Chart Visualization',
    secondaryLabel: 'Stacked area chart of top client_OS over time',
    iconName: 'QueryIcon',
    iconText: '',
    link:
      window.location.origin +
      '/#/visualize/edit/Test%20Area%20Chart?isSample=true&_g=()',
  },
];
