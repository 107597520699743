import { Stack, ThemeProvider } from '@fluentui/react';
import {
  ElxDateTimePicker,
  ElxDateTimePickerDisplayMode,
  ElxPanel,
  InputMessageTypes,
  PanelSize,
} from '@elixir/components';
import React from 'react';
import { useLensShellTheme } from 'features/shell/lensShellStyles';
import { Parameters } from '../models/job';
import { JobParameterItems } from './jobParameterItems';

interface JobParameterWithBackFillProps {
  onCancel: () => void;
  onSuccess: () => void;
  parameters: Parameters[];
  onAdd: () => void;
  onNameChange: (name: string, idx: number) => void;
  onValueChange: (value: string, idx: number) => void;
  onRemove: (idx: number) => void;
  startDateTime: string;
  onStartDateTimeChange: (startDateTime: string) => void;
  endDateTime: string;
  onEndDateTimeChange: (endDateTime: string) => void;
}

export function JobParameterWithBackFill(props: JobParameterWithBackFillProps) {
  const theme = useLensShellTheme();
  const [stTime, setStTime] = React.useState<string>(props.startDateTime);
  const [endTime, setEndTime] = React.useState<string>(props.endDateTime);
  const [validStDtTm, setValidStDtTm] = React.useState<boolean>(true);
  const [validEndDtTm, setValidEndDtTm] = React.useState<boolean>(true);
  return (
    <ThemeProvider theme={theme}>
      <ElxPanel
        headerText={'Run Backfill with Parameters'}
        isOpen={true}
        actions={[
          {
            key: 'backfill jobs',
            text: 'Backfill Jobs',
            primary: true,
            onClick: () => {
              props.onStartDateTimeChange(
                new Date(stTime!.split(' ')[0]).toISOString()
              );
              props.onEndDateTimeChange(
                new Date(endTime!.split(' ')[0]).toISOString()
              );
              props.onSuccess();
            },
            iconProps: {
              iconName: 'play',
            },
            disabled: !validStDtTm || !validEndDtTm,
          },
          {
            key: 'close',
            text: 'Close',
            onClick: () => {
              props.onCancel();
            },
          },
        ]}
        size={PanelSize.medium}
        onDismiss={props.onCancel}
      >
        {
          <Stack
            style={{
              overflow: 'auto',
              width: '600px',
            }}
            tokens={{
              childrenGap: 8,
              padding: 16,
              maxHeight: 800,
            }}
          >
            <ElxDateTimePicker
              timeZones={[]}
              label={'Start Time'}
              value={stTime}
              displayMode={ElxDateTimePickerDisplayMode.SideBySide}
              onChange={(dateTime?: string) => {
                try {
                  new Date(dateTime!.split(' ')[0]).toISOString();
                  setStTime(dateTime || '');
                  setValidStDtTm(true);
                } catch (err) {
                  setStTime(dateTime || '');
                  setValidStDtTm(false);
                }
              }}
              message={
                validStDtTm
                  ? undefined
                  : {
                      type: InputMessageTypes.Error,
                      content: 'Please Enter valid Start Date Time',
                    }
              }
            />
            <ElxDateTimePicker
              timeZones={[]}
              label={'End Time'}
              value={endTime}
              displayMode={ElxDateTimePickerDisplayMode.SideBySide}
              onChange={(dateTime?: string) => {
                try {
                  new Date(dateTime!.split(' ')[0]).toISOString();
                  setEndTime(dateTime || '');
                  setValidEndDtTm(true);
                } catch (err) {
                  setEndTime(dateTime || '');
                  setValidEndDtTm(false);
                }
              }}
              message={
                validEndDtTm
                  ? undefined
                  : {
                      type: InputMessageTypes.Error,
                      content: 'Please enter valid End Date Time',
                    }
              }
            />
            <hr />
          </Stack>
        }
        <JobParameterItems
          parameters={props.parameters}
          onNameChange={props.onNameChange}
          onValueChange={props.onValueChange}
          onRemove={props.onRemove}
          onAdd={props.onAdd}
        ></JobParameterItems>
      </ElxPanel>
    </ThemeProvider>
  );
}
