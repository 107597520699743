import {
  IDropdownOption,
  Stack,
  ThemeProvider,
  Panel,
  PanelType,
  SpinnerSize,
  Spinner,
  Label,
} from '@fluentui/react';
import {
  ElxMasterDetailContainer,
  IElxMasterDetailTabProps,
} from '@elixir/components';
import React, { useEffect, useState } from 'react';
import { useLensShellTheme } from 'features/shell/lensShellStyles';
import { WorkspaceItem } from 'features/workspaces/utils/workspaceQuickSwitchUtils';
import { Activity, Dependency, Job } from '../models/job';
import { SelectWs } from './selectWs';
import { Workspace } from 'features/workspaces/models/workspace';
import { ProjectDataConnection } from 'features/workspaces/models/project';
import { SelectDataConnection } from './jobSchedule/selectDataConnection';
import { groupBorder } from './JobActivities/JobAuthorStyles';
import { JobAccordian } from '../utils/jobAccordian';
import { selectWorkspaceById } from 'features/workspaces/workspaceListSlice';
import { RootState } from 'app/lensShellUtility';
import { useSelector } from 'react-redux';
import notifier from 'utils/notifier';
import workspacesApi from 'features/workspaces/api/workspacesApi';

const propertiesToRemove: string[] = [
  'id',
  'workspace',
  'createdBy',
  'createdDate',
  'modifiedBy',
  'modifiedDate',
  'version',
  'isEnabled',
  'isQuarantined',
  'instances',
  'config.jobName',
  'config.project',
  'config.context',
];

const unsetProperty = (jobObject: any, property: string) => {
  if (!jobObject || !property || property === '') {
    return;
  }
  if (jobObject.hasOwnProperty(property)) {
    delete jobObject[property];
    return;
  }
  var firstProperty = property.split('.')[0];
  if (firstProperty && jobObject.hasOwnProperty(firstProperty)) {
    unsetProperty(
      jobObject[firstProperty],
      property.substring(property.indexOf('.') + 1)
    );
  }
  return;
};

const cleanJobConfig = (job: Job) => {
  if (!job || !job.config)
    throw new Error('The job does not have a config property.');

  propertiesToRemove.forEach((property) => {
    unsetProperty(job, property);
  });
};

interface copyJobToWsProps {
  jobs: Job[];
  onCancel: () => void;
  onSuccess: (jobs: Job[], wsId: string) => void;
  workspaceList: IDropdownOption[];
  filterFunction?: (arg: WorkspaceItem) => boolean;
  sourceWs: Workspace;
}

export function CopyJobToWs(props: copyJobToWsProps): JSX.Element {
  const { jobs, onCancel, onSuccess, workspaceList, sourceWs } = props;
  const [selectedWorkspace, setSelectedWorkspace] =
    useState<WorkspaceItem | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [sourceConnectionNames, setSourceConnectionNames] = useState<string[]>(
    []
  );
  const [connectionMappings, setConnectionMappings] = useState<
    { source: ProjectDataConnection; target: ProjectDataConnection | null }[]
  >([]);
  const targetWs = useSelector((state: RootState) =>
    selectWorkspaceById(state, selectedWorkspace?.workspaceId || '')
  );
  const [dataConnections, setDataConnections] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const theme = useLensShellTheme();

  useEffect(() => {
    const loadDs = async () => {
      if (selectedWorkspace) {
        setLoading(true);
        try {
          let orchProj = await workspacesApi.getProject(
            selectedWorkspace.workspaceId
          );
          setLoading(false);
          setDataConnections(orchProj.dataConnections);
        } catch (err: any) {
          setLoading(false);
          notifier.error(
            `Failed to load workspace data sources ${selectedWorkspace.name} error: ${err.message}`
          );
        }
      }
    };
    loadDs();
  }, [selectedWorkspace]);

  useEffect(() => {
    let connectionSet = new Set<string>();
    jobs.forEach((job) => {
      job.config!.activities!.forEach((activity: Activity) => {
        if (activity.input) {
          connectionSet.add(activity.input.connectionName);
        }
        if (activity.output) {
          connectionSet.add(activity.output.connectionName);
        }
        if (
          job.config!.jobScheduleSettings &&
          job.config!.jobScheduleSettings.scheduleType === 'Dependency'
        ) {
          job.config!.jobScheduleSettings!.dependencySettings?.dependencies.forEach(
            (dependency: Dependency) => {
              if (dependency.endpoint?.connectionName) {
                connectionSet.add(dependency.endpoint.connectionName);
              }
            }
          );
        }
      });
    });
    let connectionNames = Array.from(connectionSet);
    setSourceConnectionNames(connectionNames);
    setConnectionMappings(
      sourceWs.dataConnections
        ?.filter((dc) => connectionNames.includes(dc.connectionName))
        .map((dc) => ({ source: dc, target: null })) || []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tabs: IElxMasterDetailTabProps[] = [
    {
      key: 'Select Workspace',
      name: 'Select Workspace',
      component: (
        <SelectWs
          onChange={(ws: WorkspaceItem): void => {
            setSelectedWorkspace(ws);
          }}
          workspaceList={workspaceList}
        ></SelectWs>
      ),
      primaryButton: {
        disabled: !selectedWorkspace,
      },
      cancelButton: {
        text: 'Cancel',
        onClick: onCancel,
      },
      backButton: { disabled: true },
    },
    {
      key: 'Data Connection',
      name: 'Data Connection',
      component: (
        <Stack tokens={{ childrenGap: 16, padding: 16 }}>
          {!loading &&
            connectionMappings.map((m: any, i: number) => {
              return (
                <Stack.Item key={i} className={groupBorder}>
                  <JobAccordian
                    isOpen={true}
                    text={`Connection Mapping ${i + 1}`}
                    hint={undefined}
                  >
                    <Stack tokens={{ childrenGap: 16, padding: 16 }}>
                      <SelectDataConnection
                        onSelect={(dc: ProjectDataConnection): void => {}}
                        filter={[connectionMappings[i].source.type]}
                        selectedKey={
                          connectionMappings[i].source.connectionName
                        }
                        disabled
                        label={`Source connection in ${sourceWs?.name}`}
                      ></SelectDataConnection>
                      <SelectDataConnection
                        onSelect={(dc: ProjectDataConnection): void => {
                          let modifiedConnectionMappings = [
                            ...connectionMappings,
                          ];
                          modifiedConnectionMappings[i].target = dc;
                          setConnectionMappings(modifiedConnectionMappings);
                        }}
                        filter={[connectionMappings[i].source.type]}
                        selectedKey={
                          connectionMappings[i].target?.connectionName || ''
                        }
                        label={`Target connection for ${selectedWorkspace?.name}`}
                        workspaceTarget={
                          targetWs
                            ? {
                                ...targetWs,
                                dataConnections:
                                  dataConnections as ProjectDataConnection[],
                              }
                            : undefined
                        }
                      ></SelectDataConnection>
                    </Stack>
                  </JobAccordian>
                </Stack.Item>
              );
            })}
          {loading && (
            <>
              <Spinner size={SpinnerSize.large}></Spinner>
              <Label>Loading data connection for the selected workspace!</Label>
            </>
          )}
        </Stack>
      ),
      primaryButton: {
        disabled: !connectionMappings.reduce(
          (p, c) => c.target !== null && p,
          true
        ),
      },
      cancelButton: {
        text: 'Cancel',
        onClick: onCancel,
      },
      backButton: { disabled: false },
    },
    {
      key: 'Summary',
      name: 'Summary',
      component: (
        <Stack tokens={{ padding: 16 }}>
          <Stack.Item>
            <p>
              Please review the changes before submitting. The following
              artifacts will be copied to the target workspace.
            </p>
          </Stack.Item>
          <Stack.Item>
            <h4>Jobs</h4>
            <ol>
              {jobs.map((job) => (
                <li key={job.name}>{job.name}</li>
              ))}
            </ol>
          </Stack.Item>
          <Stack.Item>
            <h4>Data Connections</h4>
            {connectionMappings.map((m, i) => (
              <Stack tokens={{ padding: 16 }} key={`connectionmap${i}`}>
                <Stack.Item>
                  <strong>{`Mapping ${i + 1}`}</strong>
                </Stack.Item>
                <Stack horizontal grow>
                  <Stack.Item>
                    <Label>Source: </Label>
                  </Stack.Item>
                  <Stack.Item>
                    <Label>{m.source.connectionDisplayName}</Label>
                  </Stack.Item>
                </Stack>
                <Stack horizontal grow>
                  <Stack.Item>
                    <Label>Destination: </Label>
                  </Stack.Item>
                  <Stack.Item>
                    <Label>{m.target?.connectionDisplayName}</Label>
                  </Stack.Item>
                </Stack>
              </Stack>
            ))}
            {connectionMappings.length === 0 && (
              <p>No data connections to copy</p>
            )}
          </Stack.Item>
        </Stack>
      ),
      primaryButton: {
        text: 'Copy to Workspace',
        onClick: () => {
          let modifiedJob = JSON.parse(JSON.stringify(props.jobs)) as Job[]; // [...props.jobs];
          modifiedJob.forEach((job) => {
            cleanJobConfig(job);
            job.config!.activities!.forEach((activity) => {
              const inputConnectionName = activity.input?.connectionName;
              const outputConnectionName = activity.output?.connectionName;

              if (inputConnectionName) {
                const inputConnectionMapping = connectionMappings.find(
                  (m) => m.source.connectionName === inputConnectionName
                );
                if (inputConnectionMapping) {
                  activity.input!.connectionName =
                    inputConnectionMapping.target!.connectionName;
                }
              }
              if (outputConnectionName) {
                const outputConnectionMapping = connectionMappings.find(
                  (m) => m.source.connectionName === outputConnectionName
                );
                if (outputConnectionMapping) {
                  activity.output!.connectionName =
                    outputConnectionMapping.target!.connectionName;
                }
              }
            });

            if (
              job.config?.jobScheduleSettings?.scheduleType === 'Dependency'
            ) {
              job.config.jobScheduleSettings.dependencySettings!.dependencies?.forEach(
                (dependency) => {
                  const dependencyConnectionName =
                    dependency.endpoint.connectionName;
                  if (dependencyConnectionName) {
                    const dependencyConnectionMapping = connectionMappings.find(
                      (m) =>
                        m.source.connectionName === dependencyConnectionName
                    );
                    if (dependencyConnectionMapping) {
                      dependency.endpoint.connectionName =
                        dependencyConnectionMapping.target!.connectionName;
                    }
                  }
                }
              );
            }
          });
          onSuccess(modifiedJob, selectedWorkspace!.workspaceId);
        },
      },
      cancelButton: {
        text: 'Cancel',
        onClick: onCancel,
      },
      backButton: { disabled: false },
    },
  ];
  return (
    <ThemeProvider theme={theme}>
      <Panel isOpen={true} type={PanelType.large} onDismiss={onCancel}>
        <ElxMasterDetailContainer
          headerText={`Copy Job(s) to Workspace ${selectedWorkspace?.name}`}
          styles={{
            contentContainer: { padding: '0px !important' },
          }}
          tabs={tabs}
          wizard={true}
        ></ElxMasterDetailContainer>
      </Panel>
    </ThemeProvider>
  );
}
