import { encode } from 'rison';
import { CatalogEntity, CatalogEntityType } from '../models/catalogEntity';
import { compressAndEncodeBase64AndUri } from './compressionUtils';

export interface DetailsPageProps {
  entity: CatalogEntity;
}

export enum EntityDetailsPivotKey {
  General = 'General',
  Schema = 'Schema',
  Properties = 'Properties',
  History = 'History',
  JSON = 'JSON',
}

export const entityDetailsPivotTabs = [
  'general',
  'schema',
  'properties',
  'history',
  'json',
];

export const getCurrPivotKeyString = (): string => {
  const currUrl = new URL(window.location.href);
  const currHash = currUrl.hash;
  const lastSlash = currHash.lastIndexOf('/');
  const lastQuestionMark = currHash.lastIndexOf('?');
  const currPivotPage = currHash.substring(lastSlash + 1, lastQuestionMark);

  return currPivotPage;
};

export const getCurrPivotKeyFromStr = (
  newKeyString: string
): EntityDetailsPivotKey => {
  if (newKeyString.toLowerCase() === 'json') {
    newKeyString = newKeyString.toUpperCase();
  } else {
    newKeyString = newKeyString.charAt(0).toUpperCase() + newKeyString.slice(1);
  }

  return (
    EntityDetailsPivotKey[newKeyString as keyof typeof EntityDetailsPivotKey] ||
    EntityDetailsPivotKey.General
  );
};

export const getCurrPivotKey = (): EntityDetailsPivotKey => {
  const currPivotKeyString = getCurrPivotKeyString();
  const currPivotKey = getCurrPivotKeyFromStr(currPivotKeyString);

  return currPivotKey;
};

export const getNewPivotUrl = (newKeyString: string): string => {
  const currUrl = new URL(window.location.href);
  const prevHash = currUrl.hash;
  const prevPivotPage = getCurrPivotKeyString();
  const newHash = prevHash.replace(prevPivotPage, newKeyString.toLowerCase());

  currUrl.hash = newHash;

  return currUrl.toString();
};

/** Start: Code copied and edited, originally from export_query.js */
const getPortalBaseUrl = () => {
  var baseUrl = window.location.href.split('#')[0];
  baseUrl = baseUrl + '#/discover/query//results';
  return baseUrl;
};

const generateLinkToQuery = (
  queryText: string,
  datasourceEncodedText: string
): string => {
  var baseUrl = getPortalBaseUrl();
  var encodedQuery = '';
  var linkToQueryUrl = baseUrl + '?datasource=' + datasourceEncodedText;

  if (queryText) {
    encodedQuery = compressAndEncodeBase64AndUri(queryText);
    linkToQueryUrl = linkToQueryUrl + '&query=' + encodedQuery + '&runquery=1';
  }

  return linkToQueryUrl;
};

interface DataSourceInfo {
  dataSourceType: string;
  cluster: string;
  database: string;
}

const getDatasourceInfo = (
  entity: CatalogEntity
): DataSourceInfo | undefined => {
  var dataSourceInfo: DataSourceInfo | undefined = undefined;

  if (
    entity.type === CatalogEntityType.Kusto &&
    entity.additionalProperties?.source &&
    entity.additionalProperties?.container
  ) {
    dataSourceInfo = {
      dataSourceType: entity.type,
      cluster: entity.additionalProperties.source,
      database: entity.additionalProperties.container.substring(
        entity.additionalProperties.container.lastIndexOf('.') + 1
      ),
    };
  }

  return dataSourceInfo;
};

export const getLinkToKustoQuery = (
  entity: CatalogEntity | undefined
): string | undefined => {
  if (entity === undefined) return '';
  var linkToQueryUrl;
  var selectedQuery;
  selectedQuery = `// Click 'Run' button to view query results\n${entity.name}`;

  var datasourceEncodedText = '';
  var datasourceInfo = undefined;

  datasourceInfo = getDatasourceInfo(entity);
  if (datasourceInfo === undefined) {
    return undefined;
  }

  datasourceEncodedText = encode(datasourceInfo);

  linkToQueryUrl = generateLinkToQuery(selectedQuery, datasourceEncodedText);

  return linkToQueryUrl;
};
/** End: code from export_query.js */
