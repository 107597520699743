import { DataSourceClient } from 'features/dataSources/client/dataSourceClient';
import constants, { $laConstants } from 'utils/constants';

class AzureSubscriptionClient extends DataSourceClient {
  public readonly name = $laConstants.DataSourceTypes.AZURESUBSCRIPTION;
  public readonly title = 'subscription';
  public readonly icon = 'fa fa-cat';
  public readonly description = 'Azure Subscription.';

  public get displayName() {
    return constants.DataSourceClientDisplayNames.AZURESUBSCRIPTION;
  }

  public get dataSourceType() {
    return constants.DataSourceTypes.AZURESUBSCRIPTION;
  }
}

export const azureSubscriptionClient = new AzureSubscriptionClient();
export default azureSubscriptionClient;
