import {
  Breadcrumb,
  IBreadcrumbItem,
  IObjectWithKey,
  Panel,
  PanelType,
  SelectionMode,
  Stack,
} from '@fluentui/react';
import {
  ElxTableContainer,
  FilterDisplayMode,
  FilterOption,
  FilterOptionPillMode,
  IElxColumn,
  IElxContainerProps,
  ITableAction,
} from '@elixir/components';
import { Editor, DiffEditor } from 'components/monacoEditor';
import { WorkspaceHistoryFullObj } from 'features/workspaces/models/workspace';
import React, { useState } from 'react';
import { WorkspaceHistoryObj } from 'features/workspaces/models/workspace';
import { classNames } from '../../workspacesStyles';
import {
  breadCrumbStyles,
  panelStyles,
  workspacePadding,
} from '../workspaceStyles';
import {
  HistoryEntryCompareSummary,
  HistoryEntrySummary,
} from './historyTableHelper';
import logger from 'features/appInsights/lensLogger';
import { LensTelemetryConstants } from 'features/appInsights/appInsightsLibs';
import { tableStyles } from 'utils/sharedStyles';

const colMedium = {
  minWidth: 425,
  isResizable: true,
  isMultiline: false,
  className: classNames.tableStyles,
};

interface HistoryTableProps {
  history: WorkspaceHistoryObj[];
  workspaceId: string;
  workspaceName?: string;
  isLoading?: boolean;
  closeQuickSwitchPanel: () => void;
  dismissHistoryPanel: () => void;
}

const HistoryTable = (props: HistoryTableProps) => {
  const [singleSelect, setSingleSelect] = useState(false);
  const [doubleSelect, setDoubleSelect] = useState(false);
  const [selected, setSelected] = useState<IObjectWithKey[]>([]);
  const [viewCode, setViewCode] = useState(false);
  const [compare, setCompare] = useState(false);

  const containerProps = {
    isLoading: props.isLoading || false,
    compact: true,
    styles: { headerContainer: 'hidden', body: { minHeight: '900px' } },
  } as IElxContainerProps;

  const lastUpdatedPillFilter: FilterOption = {
    field: 'lastUpdated',
    label: 'Modified',
    multiselect: true,
    pillMode: FilterOptionPillMode.Dynamic,
    values: props.history
      .map((history) => history.lastUpdated)
      .filter((value, index, self) => self.indexOf(value) === index),
  };
  const lastUpdatedByPillFilter: FilterOption = {
    field: 'lastUpdatedBy',
    label: 'Modified By',
    multiselect: true,
    pillMode: FilterOptionPillMode.Dynamic,
    values: props.history
      .map((history) => history.lastUpdatedBy)
      .filter((value, index, self) => self.indexOf(value) === index),
  };
  const historyOperationPillFilter: FilterOption = {
    field: 'historyOperation',
    label: 'Operation',
    multiselect: true,
    pillMode: FilterOptionPillMode.Dynamic,
    values: props.history
      .map((history) => history.historyOperation)
      .filter((value, index, self) => self.indexOf(value) === index),
  };

  const columns: IElxColumn[] = [
    {
      ...colMedium,
      key: 'modified',
      name: 'Modified',
      fieldName: 'lastUpdated',
    },
    {
      ...colMedium,
      key: 'modifiedBy',
      name: 'Modified By',
      fieldName: 'lastUpdatedBy',
    },
    {
      ...colMedium,
      key: 'operation',
      name: 'Operation',
      fieldName: 'historyOperation',
    },
    {
      minWidth: 100,
      isResizable: true,
      isMultiline: false,
      className: classNames.tableStyles,
      key: 'empty',
      name: '',
      fieldName: '',
    },
  ];

  const searchProps = {
    pillFilters: [
      lastUpdatedPillFilter,
      lastUpdatedByPillFilter,
      historyOperationPillFilter,
    ],
    filterDisplayMode: FilterDisplayMode.Pill,
  };

  const actions: ITableAction[] = [
    {
      key: 'viewCode',
      text: 'View Code',
      disabled: !singleSelect,
      iconProps: {
        iconName: 'Code',
        title: 'View Code',
        'aria-label': 'View Code',
      },
      onAction: () => {
        setViewCode(true);
        logger.event(
          LensTelemetryConstants.EventNames.WorkspaceActions.ViewHistoryEntry,
          { workspaceViewed: props.workspaceId }
        );
      },
      onBulkAction: () => {
        setViewCode(true);
        logger.event(
          LensTelemetryConstants.EventNames.WorkspaceActions.ViewHistoryEntry,
          { workspaceViewed: props.workspaceId }
        );
      },
    },
    {
      key: 'compare',
      text: 'Compare',
      disabled: !doubleSelect,
      iconProps: {
        iconName: 'Source',
        title: 'Compare',
        'aria-label': 'Compare',
      },
      onAction: () => {
        setCompare(true);
        logger.event(
          LensTelemetryConstants.EventNames.WorkspaceActions
            .CompareHistoryEntries,
          { workspaceViewed: props.workspaceId }
        );
      },
      onBulkAction: () => {
        setCompare(true);
        logger.event(
          LensTelemetryConstants.EventNames.WorkspaceActions
            .CompareHistoryEntries,
          { workspaceViewed: props.workspaceId }
        );
      },
    },
  ];

  const items: IBreadcrumbItem[] = [
    {
      text: 'Workspace management',
      key: 'workspaceManagement',
      onClick: () => {
        props.dismissHistoryPanel();
      },
    },
    {
      text: props.workspaceName + ' - History',
      key: 'workspaceHistory',
      onClick: () => {
        setCompare(false);
        setViewCode(false);
      },
    },
    {
      text: 'Workspace Entry History',
      key: 'workspaceHistoryEditor',
      onClick: () => {},
    },
  ];

  return (
    <Stack>
      <Stack.Item>
        <Panel
          onRenderHeader={() => {
            return (
              <Breadcrumb
                items={items}
                maxDisplayedItems={4}
                ariaLabel="Workspace data connection breadcrumb with items rendered as buttons"
                overflowAriaLabel="More links"
                styles={breadCrumbStyles}
              />
            );
          }}
          isOpen={viewCode}
          type={PanelType.large}
          styles={panelStyles}
          isFooterAtBottom={true}
          onDismiss={props.closeQuickSwitchPanel}
        >
          <Stack verticalFill styles={{ root: { padding: '5px' } }}>
            <Stack.Item>
              <HistoryEntrySummary
                modified={(selected[0] as WorkspaceHistoryFullObj)?.lastUpdated}
                modifiedBy={
                  (selected[0] as WorkspaceHistoryFullObj)?.lastUpdatedBy
                }
                operation={
                  (selected[0] as WorkspaceHistoryFullObj)?.historyOperation
                }
              />
            </Stack.Item>
            <Stack.Item grow>
              <Editor
                language="json"
                value={JSON.stringify(selected[0], null, 4)}
                readOnly={true}
              ></Editor>
            </Stack.Item>
          </Stack>
        </Panel>
      </Stack.Item>

      <Stack.Item>
        <Panel
          onRenderHeader={() => {
            return (
              <Breadcrumb
                items={items}
                maxDisplayedItems={4}
                ariaLabel="Workspace data connection breadcrumb with items rendered as buttons"
                overflowAriaLabel="More links"
                styles={breadCrumbStyles}
              />
            );
          }}
          isOpen={compare}
          type={PanelType.large}
          styles={panelStyles}
          isFooterAtBottom={true}
          onDismiss={props.closeQuickSwitchPanel}
        >
          <Stack verticalFill styles={{ root: { padding: '5px' } }}>
            <Stack.Item>
              <div style={{ height: '25px' }} />
              <div style={{ backgroundColor: '#FAF9F8', height: '1px' }} />
            </Stack.Item>
            <Stack.Item>
              <HistoryEntryCompareSummary
                original={{
                  modified: (selected[0] as WorkspaceHistoryFullObj)
                    ?.lastUpdated,
                  modifiedBy: (selected[0] as WorkspaceHistoryFullObj)
                    ?.lastUpdatedBy,
                  operation: (selected[0] as WorkspaceHistoryFullObj)
                    ?.historyOperation,
                }}
                modified={{
                  modified: (selected[1] as WorkspaceHistoryFullObj)
                    ?.lastUpdated,
                  modifiedBy: (selected[1] as WorkspaceHistoryFullObj)
                    ?.lastUpdatedBy,
                  operation: (selected[1] as WorkspaceHistoryFullObj)
                    ?.historyOperation,
                }}
              />
            </Stack.Item>
            <DiffEditor
              language="json"
              original={JSON.stringify(selected[0], null, 4)}
              modified={JSON.stringify(selected[1], null, 4)}
              readOnly={true}
            ></DiffEditor>
          </Stack>
        </Panel>
      </Stack.Item>

      <Stack.Item styles={workspacePadding}>
        <ElxTableContainer
          containerProps={containerProps}
          tableProps={{
            columns: columns,
            selectionMode: SelectionMode.multiple,
            items: props.history,
            styles: tableStyles,
            actions: actions,
            getKey: (item: WorkspaceHistoryObj) => item.id,
            setKey: 'id',
            selectedItems: selected,
            onSelectionChanged: (selected: IObjectWithKey[]) => {
              setSelected(selected);
              setDoubleSelect(false);
              setSingleSelect(false);
              if (selected.length === 1) {
                setSingleSelect(true);
              } else if (selected.length === 2) {
                setDoubleSelect(true);
              }
            },
          }}
          searchBoxProps={searchProps}
        />
      </Stack.Item>
    </Stack>
  );
};

export default HistoryTable;
