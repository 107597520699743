import { RestApi } from 'api/restApi';
import { AxiosRequestConfig } from 'axios';
import { DataSourceClientResponseData } from 'features/dataSources/models/dataSourceClient';
import { resourceIds } from 'utils/sharedLibs';

const kustoResource = resourceIds.kustoResourceId;

export interface KustoRequestBody {
  db: string;
  csl: string;
}

// This is not a fixed endpoint API, consumer must construct a new "Api" for each Kusto cluster endpoint.
class KustoClientApi extends RestApi {
  constructor(kustoUrl: string) {
    super(kustoUrl, kustoResource);
  }

  public postQuery = async (
    body: KustoRequestBody,
    config: AxiosRequestConfig
  ): Promise<DataSourceClientResponseData> => {
    // DataQualty POST Rule returns the rule guid
    return await this.postEntity<
      KustoRequestBody,
      DataSourceClientResponseData
    >('', body, config);
  };
}

export default KustoClientApi;
