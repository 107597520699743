import { ElxTextField } from '@elixir/components';
import { Stack } from '@fluentui/react';
import { LensLabel } from 'utils/lensLabel';
import { SelectorProps } from '../editDataConnectionHelper';

export const ADFSelector = (props: SelectorProps): JSX.Element => {
  const { state, setState } = props;

  return (
    <Stack tokens={{ childrenGap: 16 }}>
      <ElxTextField
        onRenderLabel={() => (
          <LensLabel
            labelText="Subscription ID"
            hintText={
              'The Azure Subscription ID - the GUID that can be found in Azure Portal.'
            }
            required={true}
          ></LensLabel>
        )}
        value={state.subscriptionId || ''}
        onChange={(e, newValue) => {
          setState({
            ...state,
            subscriptionId: newValue || '',
          });
        }}
      />
      <ElxTextField
        onRenderLabel={() => (
          <LensLabel
            labelText="Resource Group"
            hintText={'The Azure Resource Group name.'}
            required={true}
          ></LensLabel>
        )}
        value={state.resourceGroup || ''}
        onChange={(e, newValue) => {
          setState({
            ...state,
            resourceGroup: newValue || '',
          });
        }}
      />
      <ElxTextField
        onRenderLabel={() => (
          <LensLabel
            labelText="Data Factory"
            hintText={'The Azure Data Factory name.'}
            required={true}
          ></LensLabel>
        )}
        value={state.factory || ''}
        onChange={(e, newValue) => {
          setState({
            ...state,
            factory: newValue || '',
          });
        }}
      />
    </Stack>
  );
};
