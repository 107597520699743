import { _ } from 'utils/sharedLibs';
import { RestApi } from 'api/restApi';
import { resourceIds } from './sharedLibs';
import { ResourceGroup } from 'features/grafana/components/resourceGroupPicker';
import { v4 as uuidv4 } from 'uuid';
import { getObjectId } from './authUtils';

const apiVersion = '2017-08-01';

interface SubscriptionPolicy {
  locationPlacementId: string;
  quotaId: string;
  spendingLimit: string;
}

interface Subscription {
  authorizationSource: string;
  displayName: string;
  id: string;
  state: string;
  subscriptionId: string;
  subscriptionPolicies: SubscriptionPolicy;
}

export interface GrafanaInstanceARM {
  id: string;
  name: string;
  type: string;
  tenantId: string;
  kind: string;
  location: string;
  resourceGroup: string;
  subscriptionId: string;
  managedBy: string;
  sku: {
    name: string;
  };
  plan: {} | null;
  tags: {};
  systemData: {};
  properties: {
    provisioningState: string;
    endpoint: string;
    grafanaIntegrations: {
      azureMonitorWorkspaceIntegrations: [];
    };
    grafanaVersion: string;
    grafanaConfigurations: {};
  };
}

export interface AzureLocation {
  displayName: string;
  id: string;
  metadata: {};
  name: string;
  regionalDisplayName: string;
  subscriptionId: string;
}

export interface ResourceType {
  resourceType: string;
  locations: string[];
  apiVersions: string[];
  capabilities: string;
}

export interface Provider {
  namespace: string;
  resourceTypes: ResourceType[];
}

export interface RoleAssignment {
  properties: {
    roleDefinitionId: string;
    principalId: string;
    principalType: string;
    scope: string;
  };
  id: string;
  type: string;
  name: string;
}

export class ArmClient extends RestApi {
  subscriptions: Subscription[] = [];
  constructor() {
    super(
      window.startUpConfig.serviceEndpoints.azureResourceManagerUrl,
      resourceIds.azureResourceManagerResourceId
    );
  }

  private callGetApi<T>(url: string) {
    return this.getEntity<T>(url).then((response) => {
      return response;
    });
  }

  private callGetListApi<T>(url: string, prevResults?: T[]): Promise<T[]> {
    const config = {};

    return this.getEntity<{ value: T[]; nextLink?: string }>(url, config).then(
      (response) => {
        const value = _.get(response, 'value');
        const results =
          prevResults && value
            ? [...prevResults, ...value]
            : value || prevResults || [];

        // Continue getting pages of results, or return at the last page.
        const nextLink = _.get(response, 'nextLink');
        if (nextLink) {
          return this.callGetListApi<T>(nextLink, results);
        } else {
          return results;
        }
      }
    );
  }

  private callPutApi<T extends any, R = T>(url: string, entity: T) {
    return this.putEntity<T, R>(url, entity).then((response) => {
      return response;
    });
  }

  private callPostApi<T extends any, R = T>(url: string, entity: T) {
    return this.postEntity<T, { data: R }>(url, entity).then((response) => {
      const data = _.get(response, 'data');
      return data;
    });
  }

  private callGetApisSequentially(
    urls: string[],
    results?: any[]
  ): Promise<any[]> {
    results = results || [];
    if (0 === _.size(urls)) {
      return Promise.resolve(results);
    }

    let url = _.first(urls);
    urls = _.tail(urls);
    return this.callGetListApi<any[]>(url || '').then((callResults: any) => {
      results = _.concat(results, callResults);
      return this.callGetApisSequentially(urls, results);
    });
  }

  public resourceTypes = {
    MicrosoftInsightsComponents: 'microsoft.insights/components', // Application Insights subscriptions
    MicrosoftOperationalInsightsWorkspaces:
      'microsoft.operationalinsights/workspaces', // Operations Management Suite (OMS) workspaces,
    MicrosoftKustoClusters: 'microsoft.kusto/clusters', // Operations Management Suite (OMS) workspaces
  };

  public fetchResourceGroupList(subscription: string) {
    const url = `${window.startUpConfig.serviceEndpoints.azureResourceManagerUrl}/subscriptions/${subscription}/resourceGroups?api-version=2021-04-01`;
    return this.callGetListApi<ResourceGroup>(url);
  }

  public fetchGrafanaInstanceList(subscription: string, resourceGroup: string) {
    const url = `${window.startUpConfig.serviceEndpoints.azureResourceManagerUrl}/subscriptions/${subscription}/resourceGroups/${resourceGroup}/providers/Microsoft.Dashboard/grafana?api-version=2022-08-01`;
    return this.callGetListApi(url);
  }

  public fetchSubscriptionList() {
    const url = `${window.startUpConfig.serviceEndpoints.azureResourceManagerUrl}/subscriptions?api-version=2022-09-01`;
    return this.callGetListApi<Subscription>(url);
  }

  public fetchGrafanaInstanceListForSubscription(subscription: string) {
    const url = `${window.startUpConfig.serviceEndpoints.azureResourceManagerUrl}/subscriptions/${subscription}/providers/Microsoft.Dashboard/grafana?api-version=2021-09-01-preview`;
    return this.callGetListApi(url);
  }

  // fetch azure locations for a subscription
  public fetchLocations(subscription: string) {
    const url = `${window.startUpConfig.serviceEndpoints.azureResourceManagerUrl}/subscriptions/${subscription}/locations?api-version=2020-01-01`;
    return this.callGetListApi<AzureLocation>(url);
  }

  // fetch azure locations supported by Grafana for a subscription
  public fetchGrafanaLocations(subscription: string) {
    const url = `${window.startUpConfig.serviceEndpoints.azureResourceManagerUrl}/subscriptions/${subscription}/providers/microsoft.dashboard?api-version=2014-04-01-preview`;
    return this.callGetApi<Provider>(url).then((response) => {
      const locations = response.resourceTypes.find(
        (r) => r.resourceType === 'grafana'
      )?.locations;
      return locations || [];
    });
  }

  public fetchGrafanaInstanceListForSubscriptions(subscriptions: string[]) {
    const entity = {
      query: "resources|where type =~ 'microsoft.dashboard/grafana'",
      subscriptions: subscriptions,
      options: {
        $top: 1000,
        $skip: 0,
        $skipToken: '',
      },
    };

    const url = `${window.startUpConfig.serviceEndpoints.azureResourceManagerUrl}/providers/Microsoft.ResourceGraph/resources?api-version=2021-03-01`;
    return this.callPostApi<typeof entity, GrafanaInstanceARM[]>(url, entity);
  }

  public createGrafanaInstance(
    subscription: string,
    resourceGroup: string,
    instanceName: string,
    location: string
  ) {
    const url = `${window.startUpConfig.serviceEndpoints.azureResourceManagerUrl}/subscriptions/${subscription}/resourceGroups/${resourceGroup}/providers/Microsoft.Dashboard/grafana/${instanceName}?api-version=2021-09-01-preview`;
    const entity = { location: location, properties: {} };
    return this.callPutApi<typeof entity, GrafanaInstanceARM>(url, entity);
  }

  public assignGrafanaAdminRole(
    subscription: string,
    grafanaInstanceResourceId: string
  ) {
    const url = `${
      window.startUpConfig.serviceEndpoints.azureResourceManagerUrl
    }${grafanaInstanceResourceId}/providers/Microsoft.Authorization/roleAssignments/${uuidv4()}?api-version=2022-04-01`;
    const principalId = getObjectId();
    const entity = {
      properties: {
        roleDefinitionId: `/subscriptions/${subscription}/providers/Microsoft.Authorization/roleDefinitions/22926164-76b3-42b3-bc55-97df8dab3e41`, // Grafana Admin
        principalId,
      },
    };
    return this.callPutApi<typeof entity, RoleAssignment>(url, entity);
  }

  /**
   * @name getSubscriptions
   * @description Gets the user's subscriptions.
   * @returns {Promise} A promise that returns the result.
   */
  public getSubscriptions() {
    const url = _.template(
      window.startUpConfig.serviceEndpoints.azureResourceManagerUrl +
        'subscriptions?api-version=<%=apiVersion%>' //'/subscriptions?api-version=<%=apiVersion%>'
    )({
      apiVersion: apiVersion,
    });
    return this.callGetListApi<Subscription>(url);
  }

  /**
   * @name getApplicationInsightsSubscriptions
   * @description Gets the user's application insights subscriptions.
   * @returns {Promise} A promise that returns the result.
   */
  public getApplicationInsightsSubscriptions() {
    return this.getSubscriptions()
      .then((subscriptions: Subscription[]) => {
        this.subscriptions = subscriptions;
        if (!subscriptions) {
          return Promise.reject('Could not get your Azure subscriptions.');
        }
        var subscriptionIds = _.sortBy(_.map(subscriptions, 'subscriptionId'));
        return this.getResourcesBySubscriptionsAndType(
          subscriptionIds,
          this.resourceTypes.MicrosoftInsightsComponents
        );
      })
      .then((resources: any[]) => {
        let appInsightsSubscriptions: any[] = _.map(resources, (resource) => {
          const id = _.get(resource, 'id');
          const tokens = id.match(
            /^\/subscriptions\/([^/]*)\/resourceGroups\/([^/]*)\//i
          );
          const subscriptionId = _.nth(tokens, 1);
          const subscription = _.find(this.subscriptions, {
            subscriptionId: subscriptionId,
          });
          return {
            id: id,
            azureSubscription: subscriptionId,
            azureSubscriptionName: _.get(subscription, 'displayName'),
            azureResourceGroup: _.nth(tokens, 2),
            applicationInsightsSubscription: _.get(resource, 'name'),
          };
        });

        return _.sortBy(
          appInsightsSubscriptions,
          'applicationInsightsSubscription'
        );
      });
  }

  /**
   * @name getResourcesBySubscriptionsAndType
   * @description Gets the user's resources.
   * @param {string} subscriptionIds The subscription ids.
   * @param {string} resourceType The resource type.
   * @returns {Promise} A promise that returns the result.
   */
  public getResourcesBySubscriptionsAndType(
    subscriptionIds: string[],
    resourceType: string
  ) {
    // Slice 20 subscriptions at a time.  The ARM /resources REST API accepts up to 40, but we can get errors:
    // UrlTooLong message: "url is too long, it has been truncated to 2048 characters."  20 should be safe.
    let urls = [];
    const maxSubscriptions = 20;
    for (
      let start = 0;
      start < subscriptionIds.length;
      start += maxSubscriptions
    ) {
      const end = Math.min(start + maxSubscriptions, subscriptionIds.length);
      const subscriptionIdSlice = _.slice(subscriptionIds, start, end);

      const subscriptionIdClauses = _.map(
        subscriptionIdSlice,
        function (subscriptionId) {
          return "subscriptionId+eq+'" + subscriptionId + "'";
        }
      );
      const subscriptionIdFilter = _.join(subscriptionIdClauses, '+or+');
      const filterParam = _.template(
        "$filter=(<%=subscriptionIdFilter%>)+and+(resourceType+eq+'<%=resourceType%>')"
      )({
        subscriptionIdFilter: subscriptionIdFilter,
        resourceType: resourceType,
      });
      const url = _.template(
        window.startUpConfig.serviceEndpoints.azureResourceManagerUrl +
          'resources?<%=filterParam%>&api-version=<%=apiVersion%>'
      )({
        filterParam: filterParam,
        apiVersion: apiVersion,
      });

      urls.push(url);
    }

    // Call the APIs sequentially.  It seems that ARM APIs timeout if too many are made in parallel.
    return this.callGetApisSequentially(urls);
  }

  public createResourceGroup(
    subscription: string,
    resourceGroup: string,
    location: string
  ) {
    const url = `${window.startUpConfig.serviceEndpoints.azureResourceManagerUrl}/subscriptions/${subscription}/resourceGroups/${resourceGroup}?api-version=2021-04-01`;
    const entity = { location: location };
    return this.callPutApi(url, entity);
  }
}

export const armClient = new ArmClient();

export default armClient;
