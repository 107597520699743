import {
  Panel,
  PanelType,
  SelectionMode,
  Stack,
  StackItem,
} from '@fluentui/react';
import {
  ElxActionButton,
  ElxSecondaryButton,
  ElxTable,
} from '@elixir/components';
import { AppDispatch, RootState } from 'app/lensShellUtility';
import { selectWorkspaceId } from 'features/workspaces/workspaceSlice';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getJobInstance,
  selectJobById,
  rerunJobInstance,
  cancelJobInstance,
} from '../jobSlice';
import { JobInstance } from '../models/job';
import {
  createJob as utilsCreateJob,
  getRelativeExecutionTime,
  getRelativeQueueTime,
} from 'features/orchestrator/utils/jobUtils';
import notifier from 'utils/notifier';
import { getJobInstanceStatus } from '../utils/jobCommon';
import { Card } from 'components/cards/card';
import { ThemeProvider } from '@fluentui/react';
import { Editor } from 'components/editor';
import { useLensShellTheme } from 'features/shell/lensShellStyles';
import { useLocation } from 'react-router-dom';
import { tableStyles, colLarge, colMedium } from 'utils/sharedStyles';

interface InstanceDetailsProps {
  jobInstance: JobInstance;
}
function InstanceDetails(props: InstanceDetailsProps) {
  const workspaceId = useSelector(selectWorkspaceId);
  const dispatch = useDispatch<AppDispatch>();
  const job = useSelector((state: RootState) =>
    selectJobById(state, props.jobInstance.jobId)
  );
  const [showStack, setShowStack] = useState(false);
  const theme = useLensShellTheme();
  const { search } = useLocation();
  const onRenderFooterContent = useCallback(
    () => (
      <>
        <ElxSecondaryButton
          text={'Close'}
          onClick={() => setShowStack(false)}
          styles={{ root: { marginLeft: '6px', marginRight: '6px' } }}
        ></ElxSecondaryButton>
      </>
    ),
    []
  );
  const actions = [
    {
      key: 'view job',
      text: 'View job',
      iconProps: { iconName: 'View' },
      onClick: () => {
        utilsCreateJob(job);
      },
    },
    {
      // TODO - nambi - what's the use?
      key: 'refresh',
      text: 'Refresh',
      iconProps: { iconName: 'Refresh' },
      onClick: () => {
        dispatch(
          getJobInstance({
            workspaceId,
            jobId: props.jobInstance.jobId,
            instanceId: props.jobInstance.id,
          })
        );
      },
    },
    {
      key: 'return instance',
      text: 'Rerun Instance',
      iconProps: { iconName: 'PlaybackRate1x' },
      onClick: () => {
        dispatch(
          rerunJobInstance({
            workspaceId,
            jobId: props.jobInstance.jobId,
            instanceId: props.jobInstance.id,
          })
        );
      },
    },
    {
      key: 'cancel instance',
      text: 'Cancel Instance',
      iconProps: { iconName: 'Cancel' },
      onClick: () => {
        dispatch(
          cancelJobInstance({
            workspaceId,
            jobId: props.jobInstance.jobId,
            instanceId: props.jobInstance.id,
          })
        );
        notifier.warning(`The URL copied to clipboard.`);
      },
    },
    {
      key: 'share',
      text: 'Share Instance',
      iconProps: { iconName: 'Share' },
      onClick: () => {
        const { origin } = window.location;
        const url = `${origin}/#/job/list/${props.jobInstance.jobId}/instance/${props.jobInstance.id}/about${search}`;
        navigator.clipboard.writeText(url);
        notifier.info(`The URL "${url}" copied to clipboard.`);
      },
    },
  ];

  const displayNameValue = (
    nameValueList: { name: string; value: JSX.Element | string }[]
  ) => {
    return (
      <Stack>
        {nameValueList.map((i) => (
          <Stack
            horizontal
            styles={{ root: { padding: '4px 0px' } }}
            key={i.name}
          >
            <StackItem
              styles={{ root: { width: '120px !important', fontWeight: 700 } }}
            >
              {i.name}
            </StackItem>
            <StackItem
              styles={{ root: { height: 'fit-content' } }}
              verticalFill
            >
              {i.value}
            </StackItem>
          </Stack>
        ))}
      </Stack>
    );
  };

  const getActivityParams = (jobInstance: JobInstance) => {
    let ret: any[] = [];
    jobInstance.segments.forEach((s: any) => {
      Object.keys(s.parameters).forEach((k) =>
        ret.push({
          segmentName: s.segmentName,
          name: k,
          value: s.parameters[k],
        })
      );
    });
    return ret;
  };

  return (
    <>
      {' '}
      <Stack
        horizontal
        styles={{ root: { borderBottom: '1px solid lightgrey' } }}
      >
        {actions.map((o) => (
          <ElxActionButton
            {...o}
            styles={{ root: { margin: '0px 4px' } }}
          ></ElxActionButton>
        ))}
      </Stack>
      <Stack tokens={{ childrenGap: 16, padding: 16 }}>
        <StackItem>
          <Card
            collapsible
            title="About this Instance"
            tokens={{ childrenGap: 4, padding: 8 }}
          >
            <Stack horizontal>
              <StackItem styles={{ root: { width: '50%' } }}>
                {displayNameValue([
                  { name: 'Job name:', value: job?.name || '' },
                  {
                    name: 'Instance Id:',
                    value: props.jobInstance.id.substring(0, 50) + '...',
                  },
                  {
                    name: 'Status:',
                    value: getJobInstanceStatus(props.jobInstance),
                  },
                  { name: 'Created by:', value: props.jobInstance.createdBy },
                ])}
              </StackItem>
              <StackItem styles={{ root: { width: '50%' } }}>
                {displayNameValue([
                  {
                    name: 'Queue time:',
                    value: getRelativeQueueTime(props.jobInstance),
                  },
                  { name: 'Start time:', value: props.jobInstance.startTime },
                  {
                    name: 'Execution time:',
                    value: getRelativeExecutionTime(props.jobInstance),
                  },
                  {
                    name: 'Stack trace:',
                    value: (
                      <ElxActionButton
                        text="View Stack trace"
                        iconProps={{ iconName: 'Stack' }}
                        onClick={() => {
                          setShowStack(true);
                        }}
                        disabled={!props.jobInstance.errorMsg}
                      ></ElxActionButton>
                    ),
                  },
                ])}
              </StackItem>
            </Stack>
          </Card>
        </StackItem>

        <StackItem>
          <Card
            collapsible
            title="Exception Message"
            copyHandler={() => {
              navigator.clipboard.writeText(props.jobInstance.errorMsg || '');
            }}
          >
            <div style={{ overflowY: 'scroll', height: '150px' }}>
              {props.jobInstance.errorMsg || ''}
            </div>
          </Card>
        </StackItem>

        <StackItem>
          <Card collapsible title="Instance Parameter">
            <ElxTable
              compact={true}
              selectionMode={SelectionMode.none}
              styles={tableStyles}
              columns={[
                {
                  ...colMedium,
                  key: 'Parameter name',
                  name: 'Parameter name',
                  fieldName: 'name',
                },
                {
                  ...colLarge,
                  key: 'Parameter value',
                  name: 'Parameter value',
                  fieldName: 'value',
                },
              ]}
              items={Object.keys(props.jobInstance.parameters).map((k) => ({
                name: k,
                value: props.jobInstance.parameters[k],
              }))}
            ></ElxTable>
          </Card>
        </StackItem>
        <StackItem>
          <Card collapsible title="Activity Parameter">
            <ElxTable
              compact={true}
              selectionMode={SelectionMode.none}
              styles={tableStyles}
              columns={[
                {
                  ...colMedium,
                  key: 'Activity name',
                  name: 'Activity name',
                  fieldName: 'segmentName',
                },
                {
                  ...colMedium,
                  key: 'Parameter name',
                  name: 'Parameter name',
                  fieldName: 'name',
                },
                {
                  ...colLarge,
                  key: 'Parameter value',
                  name: 'Parameter value',
                  fieldName: 'value',
                },
              ]}
              items={getActivityParams(props.jobInstance)}
            ></ElxTable>
          </Card>
        </StackItem>
      </Stack>
      {showStack && (
        <ThemeProvider theme={theme}>
          <Panel
            headerText={'Stack trace:'}
            isOpen={true}
            onDismiss={() => {
              setShowStack(false);
            }}
            isFooterAtBottom={true}
            closeButtonAriaLabel={'Close'}
            type={PanelType.custom}
            customWidth="1260px"
            onRenderFooterContent={onRenderFooterContent}
            hasCloseButton={true}
            isLightDismiss={true}
            styles={{
              footerInner: { float: 'right' },
              footer: {
                borderTop: '1px solid lightgrey',
                backgroundColor: theme.palette.neutralLighter,
              },
              content: { background: 'white', padding: '0px' },
              main: { background: 'white !important' },
            }}
          >
            <Editor
              language="text"
              onChange={(str: string) => {}}
              value={
                props.jobInstance.errorMsg
                  ? JSON.parse(props.jobInstance.errorMsg).stackTrace
                  : ''
              }
              height={600}
            ></Editor>
            {/* <p>{props.jobInstance.errorMsg}</p> */}
          </Panel>
        </ThemeProvider>
      )}
    </>
  );
}
export default InstanceDetails;
