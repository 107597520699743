import lensShell from 'features/shell/lensShell';

export const routes = [
  {
    name: 'leapp',
    abstract: true,
    views: {
      lensshell: {
        component: lensShell,
      },
    },
  },
  {
    name: 'app',
    abstract: true,
    parent: 'leapp',
    template:
      '<div class="application lens-classic embedded-kibana"><div class="outer-view"><ui-view class="application"></ui-view></div></div>',
  },
  {
    name: 'leapp.home',
    url: '/new/home',
  },
  {
    name: 'leapp.workspaces',
    url: '/workspaces',
  },
  {
    name: 'leapp.workspaces.manage',
    url: '/manage',
  },
  {
    name: 'leapp.workspaces.edit',
    url: '/edit/{workspaceAlias}',
  },
  { name: 'leapp.job', url: '/job' },
  {
    name: 'leapp.job.list',
    url: '/list',
  },
  { name: 'leapp.job.list.instances', url: '/{jobId}' },
  { name: 'leapp.job.create', url: '/create' },
  { name: 'leapp.job.edit', url: '/edit/{jobId}' },
  {
    name: 'leapp.job.list.instances.list',
    url: '/instance/{instanceId}/{tab}',
  },
  {
    name: 'leapp.datacatalog',
    url: '/datacatalog',
    abstract: true,
  },
  {
    name: 'leapp.datacatalog.list',
    url: '/list',
  },
  {
    name: 'leapp.monitor',
    url: '/monitor',
    abstract: true,
  },
  {
    name: 'leapp.monitor.list',
    url: '/list',
  },
  {
    name: 'leapp.datacatalog.details',
    url: '/details/{manifestKey}/{subPage}',
  },
  {
    name: 'leapp.settings',
    url: '/settings',
  },
  {
    name: 'leapp.settings.preferences',
    url: '/preferences',
  },
  {
    name: 'leapp.settings.admin',
    url: '/admin',
  },
];

export default routes;
