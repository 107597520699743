import React from 'react';

import { Position, SpinButton } from '@fluentui/react';

import * as Styles from './valueRangeInputButton.styles';

interface IValueRangeInputButtonProps {
  name: string;
  maxValue: number;
  minValue?: number;
  numericValue: number;
  disabled: boolean;
  setNumericValue: (value: number) => void;
}

/**
 * ValueRangeInputButton component
 * @param props
 */
export const ValueRangeInputButton = (
  props: IValueRangeInputButtonProps
): JSX.Element => {
  return (
    <div style={Styles.RightSpacingStyle}>
      <SpinButton
        label={props.name}
        labelPosition={Position.top}
        styles={Styles.SpinButtonStyle}
        min={props.minValue || 0}
        max={props.maxValue}
        step={1}
        value={
          props.numericValue < 10
            ? `0${props.numericValue}`
            : `${props.numericValue}`
        }
        onDecrement={(value: string) => {
          let newValue: number = +value - 1;
          if (newValue < 0) {
            newValue = props.maxValue;
          }
          props.setNumericValue(newValue);
        }}
        onIncrement={(value: string) => {
          let newValue: number = +value + 1;
          if (newValue > props.maxValue) {
            newValue = 0;
          }
          props.setNumericValue(newValue);
        }}
        onValidate={(value: string) => {
          if (isNaN(+value) || +value < 0) {
            props.setNumericValue(0);
          } else if (+value > props.maxValue) {
            props.setNumericValue(props.maxValue);
          } else {
            props.setNumericValue(+value);
          }
        }}
        disabled={props.disabled}
      />
    </div>
  );
};
