/* eslint-disable */
export var kqlParser = /*
 * Generated by PEG.js 0.10.0.
 *
 * http://pegjs.org/
 * 
 */
(function () {
    "use strict";

    function peg$subclass(child, parent) {
        function ctor() { this.constructor = child; }
        ctor.prototype = parent.prototype;
        child.prototype = new ctor();
    }

    function peg$SyntaxError(message, expected, found, location) {
        this.message = message;
        this.expected = expected;
        this.found = found;
        this.location = location;
        this.name = "SyntaxError";

        if (typeof Error.captureStackTrace === "function") {
            Error.captureStackTrace(this, peg$SyntaxError);
        }
    }

    peg$subclass(peg$SyntaxError, Error);

    peg$SyntaxError.buildMessage = function (expected, found) {
        var DESCRIBE_EXPECTATION_FNS = {
            literal: function (expectation) {
                return "\"" + literalEscape(expectation.text) + "\"";
            },

            "class": function (expectation) {
                var escapedParts = "",
                    i;

                for (i = 0; i < expectation.parts.length; i++) {
                    escapedParts += expectation.parts[i] instanceof Array
                      ? classEscape(expectation.parts[i][0]) + "-" + classEscape(expectation.parts[i][1])
                      : classEscape(expectation.parts[i]);
                }

                return "[" + (expectation.inverted ? "^" : "") + escapedParts + "]";
            },

            any: function (expectation) {
                return "any character";
            },

            end: function (expectation) {
                return "end of input";
            },

            other: function (expectation) {
                return expectation.description;
            }
        };

        function hex(ch) {
            return ch.charCodeAt(0).toString(16).toUpperCase();
        }

        function literalEscape(s) {
            return s
              .replace(/\\/g, '\\\\')
              .replace(/"/g, '\\"')
              .replace(/\0/g, '\\0')
              .replace(/\t/g, '\\t')
              .replace(/\n/g, '\\n')
              .replace(/\r/g, '\\r')
              .replace(/[\x00-\x0F]/g, function (ch) { return '\\x0' + hex(ch); })
              .replace(/[\x10-\x1F\x7F-\x9F]/g, function (ch) { return '\\x' + hex(ch); });
        }

        function classEscape(s) {
            return s
              .replace(/\\/g, '\\\\')
              .replace(/\]/g, '\\]')
              .replace(/\^/g, '\\^')
              .replace(/-/g, '\\-')
              .replace(/\0/g, '\\0')
              .replace(/\t/g, '\\t')
              .replace(/\n/g, '\\n')
              .replace(/\r/g, '\\r')
              .replace(/[\x00-\x0F]/g, function (ch) { return '\\x0' + hex(ch); })
              .replace(/[\x10-\x1F\x7F-\x9F]/g, function (ch) { return '\\x' + hex(ch); });
        }

        function describeExpectation(expectation) {
            return DESCRIBE_EXPECTATION_FNS[expectation.type](expectation);
        }

        function describeExpected(expected) {
            var descriptions = new Array(expected.length),
                i, j;

            for (i = 0; i < expected.length; i++) {
                descriptions[i] = describeExpectation(expected[i]);
            }

            descriptions.sort();

            if (descriptions.length > 0) {
                for (i = 1, j = 1; i < descriptions.length; i++) {
                    if (descriptions[i - 1] !== descriptions[i]) {
                        descriptions[j] = descriptions[i];
                        j++;
                    }
                }
                descriptions.length = j;
            }

            switch (descriptions.length) {
                case 1:
                    return descriptions[0];

                case 2:
                    return descriptions[0] + " or " + descriptions[1];

                default:
                    return descriptions.slice(0, -1).join(", ")
                      + ", or "
                      + descriptions[descriptions.length - 1];
            }
        }

        function describeFound(found) {
            return found ? "\"" + literalEscape(found) + "\"" : "end of input";
        }

        return "Expected " + describeExpected(expected) + " but " + describeFound(found) + " found.";
    };

    function peg$parse(input, options) {
        options = options !== void 0 ? options : {};

        var peg$FAILED = {},

            peg$startRuleFunctions = { start: peg$parsestart },
            peg$startRuleFunction = peg$parsestart,

            peg$c0 = function (list) { return list; },
            peg$c1 = ";",
            peg$c2 = peg$literalExpectation(";", false),
            peg$c3 = function (head, tail) {
                return { tables: foundTables, identifiers: foundIdentifiers, others: foundOthers };
            },
            peg$c4 = "=",
            peg$c5 = peg$literalExpectation("=", false),
            peg$c6 = peg$anyExpectation(),
            peg$c7 = function (id, view) {
                var charArray = makeCharArray(view);
                foundIdentifiers.push({ identifier: id.identifier, rule: "LetView" }); // view: charArray.join('')
            },
            peg$c8 = "|",
            peg$c9 = peg$literalExpectation("|", false),
            peg$c10 = function (idL, idR, exp) {
                var charArray = makeCharArray(exp);
                foundIdentifiers.push({ identifier: idL.identifier, rule: "LetExpLeft" });
                foundIdentifiers.push({ identifier: idR.identifier, rule: "LetExpRight" }); // expression: charArray.join('') 
            },
            peg$c11 = function (id) {
                foundIdentifiers.push({ identifier: id.identifier, rule: "LetPipeLeft" });
            },
            peg$c12 = "(",
            peg$c13 = peg$literalExpectation("(", false),
            peg$c14 = ")",
            peg$c15 = peg$literalExpectation(")", false),
            peg$c16 = function (id, lmbd) {
                var charArray = makeCharArray(lmbd);
                foundIdentifiers.push({ identifier: id.identifier, rule: "LetLambda" }); // lambda: charArray.join('') 
            },
            peg$c17 = function (id, exp) {
                var charArray = makeCharArray(exp);
                foundIdentifiers.push({ identifier: id.identifier, rule: "LetExpLeft" }); // expression: charArray.join('')
            },
            peg$c18 = "access",
            peg$c19 = peg$literalExpectation("access", false),
            peg$c20 = "to",
            peg$c21 = peg$literalExpectation("to", false),
            peg$c22 = function (table) { return tableStack.push(table.identifier); },
            peg$c23 = function (table, tail) { return tableStack.pop(table.identifier); },
            peg$c24 = function (table, tail) {
                foundTables.push({ table: table.identifier, rule: "PipeExpLeft" });
            },
            peg$c25 = "kind",
            peg$c26 = peg$literalExpectation("kind", false),
            peg$c27 = "Kind",
            peg$c28 = peg$literalExpectation("Kind", false),
            peg$c29 = function (on) {
                var onCharArray = makeCharArray(on);
            },
            peg$c30 = function (elem) {
                var charArray = makeCharArray(elem); // expression: charArray.join('')            foundOthers
                foundOthers.push({ element: joinCharArray(makeCharArray(elem)), rule: "Others" });
            },
            peg$c31 = "select",
            peg$c32 = peg$literalExpectation("select", false),
            peg$c33 = "where",
            peg$c34 = peg$literalExpectation("where", false),
            peg$c35 = function (predicate) {

            },
            peg$c36 = "<",
            peg$c37 = peg$literalExpectation("<", false),
            peg$c38 = ">",
            peg$c39 = peg$literalExpectation(">", false),
            peg$c40 = "withsource",
            peg$c41 = peg$literalExpectation("withsource", false),
            peg$c42 = function (id, tail) {
                var tailCharArray = makeCharArray(tail);
                foundTables.push({ table: id.identifier, rule: "JoinRightSide" });
            },
            peg$c43 = function (id) {
                foundTables.push({ table: id.identifier, rule: "JoinRightSide" });
            },
            peg$c44 = "*",
            peg$c45 = peg$literalExpectation("*", false),
            peg$c46 = function () {
                foundTables.push({ table: "*", rule: "TableList" });
            },
            peg$c47 = function (wildcard) {
                foundTables.push({ table: wildcard.identifier, rule: "TableList" });
            },
            peg$c48 = ",",
            peg$c49 = peg$literalExpectation(",", false),
            peg$c50 = function (tlb1, tbls) {
                var i;
                foundTables.push({ table: tlb1.identifier, rule: "TableList" }); // add table to global table list
                for (i = 0; i < tbls.length; i++) {
                    foundTables.push({ table: tbls[i][2].identifier, rule: "TableList" });
                };
            },
            peg$c51 = "_",
            peg$c52 = peg$literalExpectation("_", false),
            peg$c53 = function (head, tail) {
                return { identifier: (head + tail.join("")), location: location() };
            },
            peg$c54 = function (head, middle, tail) {
                return { identifier: (head.join("") + middle + tail.join("")) };
            },
            peg$c55 = function (head) {
                return { identifier: (head.identifier + "*") }
            },
            peg$c56 = function (head, tail) {
                return { literal: (head + tail.join("")) };
            },
            peg$c57 = "join",
            peg$c58 = peg$literalExpectation("join", false),
            peg$c59 = "let",
            peg$c60 = peg$literalExpectation("let", false),
            peg$c61 = "project",
            peg$c62 = peg$literalExpectation("project", false),
            peg$c63 = "restrict",
            peg$c64 = peg$literalExpectation("restrict", false),
            peg$c65 = "string",
            peg$c66 = peg$literalExpectation("string", false),
            peg$c67 = "union",
            peg$c68 = peg$literalExpectation("union", false),
            peg$c69 = "view",
            peg$c70 = peg$literalExpectation("view", false),
            peg$c71 = function (key) {
                return { keyword: key };
            },
            peg$c72 = "true",
            peg$c73 = peg$literalExpectation("true", false),
            peg$c74 = "True",
            peg$c75 = peg$literalExpectation("True", false),
            peg$c76 = "TRUE",
            peg$c77 = peg$literalExpectation("TRUE", false),
            peg$c78 = function () { return { boolean: "true" }; },
            peg$c79 = "false",
            peg$c80 = peg$literalExpectation("false", false),
            peg$c81 = "False",
            peg$c82 = peg$literalExpectation("False", false),
            peg$c83 = "FALSE",
            peg$c84 = peg$literalExpectation("FALSE", false),
            peg$c85 = function () { return { boolean: "false" }; },
            peg$c86 = "\"",
            peg$c87 = peg$literalExpectation("\"", false),
            peg$c88 = function (chars) { return { stringliteral: chars }; },
            peg$c89 = "'",
            peg$c90 = peg$literalExpectation("'", false),
            peg$c91 = "\\",
            peg$c92 = peg$literalExpectation("\\", false),
            peg$c93 = function () { return text(); },
            peg$c94 = function (sequence) { return sequence; },
            peg$c95 = "a",
            peg$c96 = peg$literalExpectation("a", false),
            peg$c97 = "b",
            peg$c98 = peg$literalExpectation("b", false),
            peg$c99 = "f",
            peg$c100 = peg$literalExpectation("f", false),
            peg$c101 = "n",
            peg$c102 = peg$literalExpectation("n", false),
            peg$c103 = "r",
            peg$c104 = peg$literalExpectation("r", false),
            peg$c105 = "t",
            peg$c106 = peg$literalExpectation("t", false),
            peg$c107 = "u",
            peg$c108 = peg$literalExpectation("u", false),
            peg$c109 = "U",
            peg$c110 = peg$literalExpectation("U", false),
            peg$c111 = "x",
            peg$c112 = peg$literalExpectation("x", false),
            peg$c113 = "v",
            peg$c114 = peg$literalExpectation("v", false),
            peg$c115 = /^[0-3]/,
            peg$c116 = peg$classExpectation([["0", "3"]], false, false),
            peg$c117 = /^[0-7]/,
            peg$c118 = peg$classExpectation([["0", "7"]], false, false),
            peg$c119 = /^[0-7']/,
            peg$c120 = peg$classExpectation([["0", "7"], "'"], false, false),
            peg$c121 = "\n",
            peg$c122 = peg$literalExpectation("\n", false),
            peg$c123 = "\r\n",
            peg$c124 = peg$literalExpectation("\r\n", false),
            peg$c125 = "\r",
            peg$c126 = peg$literalExpectation("\r", false),
            peg$c127 = function () { return ""; },
            peg$c128 = /^[0-9]/,
            peg$c129 = peg$classExpectation([["0", "9"]], false, false),
            peg$c130 = /^[a-z]/,
            peg$c131 = peg$classExpectation([["a", "z"]], false, false),
            peg$c132 = /^[A-Z]/,
            peg$c133 = peg$classExpectation([["A", "Z"]], false, false),
            peg$c134 = /^[\n\r]/,
            peg$c135 = peg$classExpectation(["\n", "\r"], false, false),
            peg$c136 = /^[1-9]/,
            peg$c137 = peg$classExpectation([["1", "9"]], false, false),
            peg$c138 = function (head, tail) { return { integer: head + tail.join("") }; },
            peg$c139 = "+",
            peg$c140 = peg$literalExpectation("+", false),
            peg$c141 = "-",
            peg$c142 = peg$literalExpectation("-", false),
            peg$c143 = ".",
            peg$c144 = peg$literalExpectation(".", false),
            peg$c145 = function (sign, whole, fraction, exp) { return { number: sign + whole.join("") + fraction.join("") + exp }; },
            peg$c146 = function (sign, whole, exp) { return { number: sign + whole.join("") + exp }; },
            peg$c147 = "e",
            peg$c148 = peg$literalExpectation("e", false),
            peg$c149 = "E",
            peg$c150 = peg$literalExpectation("E", false),
            peg$c151 = peg$otherExpectation("whitespace"),
            peg$c152 = /^[ \t\n\r]/,
            peg$c153 = peg$classExpectation([" ", "\t", "\n", "\r"], false, false),

            peg$currPos = 0,
            peg$savedPos = 0,
            peg$posDetailsCache = [{ line: 1, column: 1 }],
            peg$maxFailPos = 0,
            peg$maxFailExpected = [],
            peg$silentFails = 0,

            peg$result;

        if ("startRule" in options) {
            if (!(options.startRule in peg$startRuleFunctions)) {
                throw new Error("Can't start parsing from rule \"" + options.startRule + "\".");
            }

            peg$startRuleFunction = peg$startRuleFunctions[options.startRule];
        }

        function text() {
            return input.substring(peg$savedPos, peg$currPos);
        }

        function location() {
            return peg$computeLocation(peg$savedPos, peg$currPos);
        }

        function expected(description, location) {
            location = location !== void 0 ? location : peg$computeLocation(peg$savedPos, peg$currPos)

            throw peg$buildStructuredError(
              [peg$otherExpectation(description)],
              input.substring(peg$savedPos, peg$currPos),
              location
            );
        }

        function error(message, location) {
            location = location !== void 0 ? location : peg$computeLocation(peg$savedPos, peg$currPos)

            throw peg$buildSimpleError(message, location);
        }

        function peg$literalExpectation(text, ignoreCase) {
            return { type: "literal", text: text, ignoreCase: ignoreCase };
        }

        function peg$classExpectation(parts, inverted, ignoreCase) {
            return { type: "class", parts: parts, inverted: inverted, ignoreCase: ignoreCase };
        }

        function peg$anyExpectation() {
            return { type: "any" };
        }

        function peg$endExpectation() {
            return { type: "end" };
        }

        function peg$otherExpectation(description) {
            return { type: "other", description: description };
        }

        function peg$computePosDetails(pos) {
            var details = peg$posDetailsCache[pos], p;

            if (details) {
                return details;
            } else {
                p = pos - 1;
                while (!peg$posDetailsCache[p]) {
                    p--;
                }

                details = peg$posDetailsCache[p];
                details = {
                    line: details.line,
                    column: details.column
                };

                while (p < pos) {
                    if (input.charCodeAt(p) === 10) {
                        details.line++;
                        details.column = 1;
                    } else {
                        details.column++;
                    }

                    p++;
                }

                peg$posDetailsCache[pos] = details;
                return details;
            }
        }

        function peg$computeLocation(startPos, endPos) {
            var startPosDetails = peg$computePosDetails(startPos),
                endPosDetails = peg$computePosDetails(endPos);

            return {
                start: {
                    offset: startPos,
                    line: startPosDetails.line,
                    column: startPosDetails.column
                },
                end: {
                    offset: endPos,
                    line: endPosDetails.line,
                    column: endPosDetails.column
                }
            };
        }

        function peg$fail(expected) {
            if (peg$currPos < peg$maxFailPos) { return; }

            if (peg$currPos > peg$maxFailPos) {
                peg$maxFailPos = peg$currPos;
                peg$maxFailExpected = [];
            }

            peg$maxFailExpected.push(expected);
        }

        function peg$buildSimpleError(message, location) {
            return new peg$SyntaxError(message, null, null, location);
        }

        function peg$buildStructuredError(expected, found, location) {
            return new peg$SyntaxError(
              peg$SyntaxError.buildMessage(expected, found),
              expected,
              found,
              location
            );
        }

        function peg$parsestart() {
            var s0, s1;

            s0 = peg$currPos;
            s1 = peg$parsestatementList();
            if (s1 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c0(s1);
            }
            s0 = s1;

            return s0;
        }

        function peg$parsestatementList() {
            var s0, s1, s2, s3, s4, s5;

            s0 = peg$currPos;
            s1 = peg$parsestatement();
            if (s1 !== peg$FAILED) {
                s2 = [];
                s3 = peg$currPos;
                if (input.charCodeAt(peg$currPos) === 59) {
                    s4 = peg$c1;
                    peg$currPos++;
                } else {
                    s4 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c2); }
                }
                if (s4 !== peg$FAILED) {
                    s5 = peg$parsestatement();
                    if (s5 !== peg$FAILED) {
                        s4 = [s4, s5];
                        s3 = s4;
                    } else {
                        peg$currPos = s3;
                        s3 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s3;
                    s3 = peg$FAILED;
                }
                while (s3 !== peg$FAILED) {
                    s2.push(s3);
                    s3 = peg$currPos;
                    if (input.charCodeAt(peg$currPos) === 59) {
                        s4 = peg$c1;
                        peg$currPos++;
                    } else {
                        s4 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c2); }
                    }
                    if (s4 !== peg$FAILED) {
                        s5 = peg$parsestatement();
                        if (s5 !== peg$FAILED) {
                            s4 = [s4, s5];
                            s3 = s4;
                        } else {
                            peg$currPos = s3;
                            s3 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s3;
                        s3 = peg$FAILED;
                    }
                }
                if (s2 !== peg$FAILED) {
                    if (input.charCodeAt(peg$currPos) === 59) {
                        s3 = peg$c1;
                        peg$currPos++;
                    } else {
                        s3 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c2); }
                    }
                    if (s3 === peg$FAILED) {
                        s3 = null;
                    }
                    if (s3 !== peg$FAILED) {
                        s4 = peg$parse_();
                        if (s4 !== peg$FAILED) {
                            peg$savedPos = s0;
                            s1 = peg$c3(s1, s2);
                            s0 = s1;
                        } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }

            return s0;
        }

        function peg$parsestatement() {
            var s0;

            s0 = peg$parseletStatement();
            if (s0 === peg$FAILED) {
                s0 = peg$parserestrictStatement();
                if (s0 === peg$FAILED) {
                    s0 = peg$parsequeryStatement();
                }
            }

            return s0;
        }

        function peg$parseletStatement() {
            var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12, s13, s14, s15;

            s0 = peg$currPos;
            s1 = peg$parse_();
            if (s1 !== peg$FAILED) {
                s2 = peg$parseLetToken();
                if (s2 !== peg$FAILED) {
                    s3 = peg$parse_();
                    if (s3 !== peg$FAILED) {
                        s4 = peg$parseIdentifier();
                        if (s4 !== peg$FAILED) {
                            s5 = peg$parse_();
                            if (s5 !== peg$FAILED) {
                                if (input.charCodeAt(peg$currPos) === 61) {
                                    s6 = peg$c4;
                                    peg$currPos++;
                                } else {
                                    s6 = peg$FAILED;
                                    if (peg$silentFails === 0) { peg$fail(peg$c5); }
                                }
                                if (s6 !== peg$FAILED) {
                                    s7 = peg$parse_();
                                    if (s7 !== peg$FAILED) {
                                        s8 = peg$parseViewToken();
                                        if (s8 !== peg$FAILED) {
                                            s9 = [];
                                            s10 = peg$currPos;
                                            s11 = peg$currPos;
                                            peg$silentFails++;
                                            if (input.charCodeAt(peg$currPos) === 59) {
                                                s12 = peg$c1;
                                                peg$currPos++;
                                            } else {
                                                s12 = peg$FAILED;
                                                if (peg$silentFails === 0) { peg$fail(peg$c2); }
                                            }
                                            peg$silentFails--;
                                            if (s12 === peg$FAILED) {
                                                s11 = void 0;
                                            } else {
                                                peg$currPos = s11;
                                                s11 = peg$FAILED;
                                            }
                                            if (s11 !== peg$FAILED) {
                                                if (input.length > peg$currPos) {
                                                    s12 = input.charAt(peg$currPos);
                                                    peg$currPos++;
                                                } else {
                                                    s12 = peg$FAILED;
                                                    if (peg$silentFails === 0) { peg$fail(peg$c6); }
                                                }
                                                if (s12 !== peg$FAILED) {
                                                    s11 = [s11, s12];
                                                    s10 = s11;
                                                } else {
                                                    peg$currPos = s10;
                                                    s10 = peg$FAILED;
                                                }
                                            } else {
                                                peg$currPos = s10;
                                                s10 = peg$FAILED;
                                            }
                                            while (s10 !== peg$FAILED) {
                                                s9.push(s10);
                                                s10 = peg$currPos;
                                                s11 = peg$currPos;
                                                peg$silentFails++;
                                                if (input.charCodeAt(peg$currPos) === 59) {
                                                    s12 = peg$c1;
                                                    peg$currPos++;
                                                } else {
                                                    s12 = peg$FAILED;
                                                    if (peg$silentFails === 0) { peg$fail(peg$c2); }
                                                }
                                                peg$silentFails--;
                                                if (s12 === peg$FAILED) {
                                                    s11 = void 0;
                                                } else {
                                                    peg$currPos = s11;
                                                    s11 = peg$FAILED;
                                                }
                                                if (s11 !== peg$FAILED) {
                                                    if (input.length > peg$currPos) {
                                                        s12 = input.charAt(peg$currPos);
                                                        peg$currPos++;
                                                    } else {
                                                        s12 = peg$FAILED;
                                                        if (peg$silentFails === 0) { peg$fail(peg$c6); }
                                                    }
                                                    if (s12 !== peg$FAILED) {
                                                        s11 = [s11, s12];
                                                        s10 = s11;
                                                    } else {
                                                        peg$currPos = s10;
                                                        s10 = peg$FAILED;
                                                    }
                                                } else {
                                                    peg$currPos = s10;
                                                    s10 = peg$FAILED;
                                                }
                                            }
                                            if (s9 !== peg$FAILED) {
                                                s10 = peg$parse_();
                                                if (s10 !== peg$FAILED) {
                                                    peg$savedPos = s0;
                                                    s1 = peg$c7(s4, s9);
                                                    s0 = s1;
                                                } else {
                                                    peg$currPos = s0;
                                                    s0 = peg$FAILED;
                                                }
                                            } else {
                                                peg$currPos = s0;
                                                s0 = peg$FAILED;
                                            }
                                        } else {
                                            peg$currPos = s0;
                                            s0 = peg$FAILED;
                                        }
                                    } else {
                                        peg$currPos = s0;
                                        s0 = peg$FAILED;
                                    }
                                } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                }
                            } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                            }
                        } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }
            if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                s1 = peg$parse_();
                if (s1 !== peg$FAILED) {
                    s2 = peg$parseLetToken();
                    if (s2 !== peg$FAILED) {
                        s3 = peg$parse_();
                        if (s3 !== peg$FAILED) {
                            s4 = peg$parseIdentifier();
                            if (s4 !== peg$FAILED) {
                                s5 = peg$parse_();
                                if (s5 !== peg$FAILED) {
                                    if (input.charCodeAt(peg$currPos) === 61) {
                                        s6 = peg$c4;
                                        peg$currPos++;
                                    } else {
                                        s6 = peg$FAILED;
                                        if (peg$silentFails === 0) { peg$fail(peg$c5); }
                                    }
                                    if (s6 !== peg$FAILED) {
                                        s7 = peg$parseIdentifier();
                                        if (s7 !== peg$FAILED) {
                                            s8 = peg$parse_();
                                            if (s8 !== peg$FAILED) {
                                                s9 = peg$currPos;
                                                peg$silentFails++;
                                                if (input.charCodeAt(peg$currPos) === 124) {
                                                    s10 = peg$c8;
                                                    peg$currPos++;
                                                } else {
                                                    s10 = peg$FAILED;
                                                    if (peg$silentFails === 0) { peg$fail(peg$c9); }
                                                }
                                                peg$silentFails--;
                                                if (s10 === peg$FAILED) {
                                                    s9 = void 0;
                                                } else {
                                                    peg$currPos = s9;
                                                    s9 = peg$FAILED;
                                                }
                                                if (s9 !== peg$FAILED) {
                                                    s10 = peg$parse_();
                                                    if (s10 !== peg$FAILED) {
                                                        s11 = [];
                                                        s12 = peg$currPos;
                                                        s13 = peg$currPos;
                                                        peg$silentFails++;
                                                        if (input.charCodeAt(peg$currPos) === 59) {
                                                            s14 = peg$c1;
                                                            peg$currPos++;
                                                        } else {
                                                            s14 = peg$FAILED;
                                                            if (peg$silentFails === 0) { peg$fail(peg$c2); }
                                                        }
                                                        peg$silentFails--;
                                                        if (s14 === peg$FAILED) {
                                                            s13 = void 0;
                                                        } else {
                                                            peg$currPos = s13;
                                                            s13 = peg$FAILED;
                                                        }
                                                        if (s13 !== peg$FAILED) {
                                                            if (input.length > peg$currPos) {
                                                                s14 = input.charAt(peg$currPos);
                                                                peg$currPos++;
                                                            } else {
                                                                s14 = peg$FAILED;
                                                                if (peg$silentFails === 0) { peg$fail(peg$c6); }
                                                            }
                                                            if (s14 !== peg$FAILED) {
                                                                s13 = [s13, s14];
                                                                s12 = s13;
                                                            } else {
                                                                peg$currPos = s12;
                                                                s12 = peg$FAILED;
                                                            }
                                                        } else {
                                                            peg$currPos = s12;
                                                            s12 = peg$FAILED;
                                                        }
                                                        while (s12 !== peg$FAILED) {
                                                            s11.push(s12);
                                                            s12 = peg$currPos;
                                                            s13 = peg$currPos;
                                                            peg$silentFails++;
                                                            if (input.charCodeAt(peg$currPos) === 59) {
                                                                s14 = peg$c1;
                                                                peg$currPos++;
                                                            } else {
                                                                s14 = peg$FAILED;
                                                                if (peg$silentFails === 0) { peg$fail(peg$c2); }
                                                            }
                                                            peg$silentFails--;
                                                            if (s14 === peg$FAILED) {
                                                                s13 = void 0;
                                                            } else {
                                                                peg$currPos = s13;
                                                                s13 = peg$FAILED;
                                                            }
                                                            if (s13 !== peg$FAILED) {
                                                                if (input.length > peg$currPos) {
                                                                    s14 = input.charAt(peg$currPos);
                                                                    peg$currPos++;
                                                                } else {
                                                                    s14 = peg$FAILED;
                                                                    if (peg$silentFails === 0) { peg$fail(peg$c6); }
                                                                }
                                                                if (s14 !== peg$FAILED) {
                                                                    s13 = [s13, s14];
                                                                    s12 = s13;
                                                                } else {
                                                                    peg$currPos = s12;
                                                                    s12 = peg$FAILED;
                                                                }
                                                            } else {
                                                                peg$currPos = s12;
                                                                s12 = peg$FAILED;
                                                            }
                                                        }
                                                        if (s11 !== peg$FAILED) {
                                                            peg$savedPos = s0;
                                                            s1 = peg$c10(s4, s7, s11);
                                                            s0 = s1;
                                                        } else {
                                                            peg$currPos = s0;
                                                            s0 = peg$FAILED;
                                                        }
                                                    } else {
                                                        peg$currPos = s0;
                                                        s0 = peg$FAILED;
                                                    }
                                                } else {
                                                    peg$currPos = s0;
                                                    s0 = peg$FAILED;
                                                }
                                            } else {
                                                peg$currPos = s0;
                                                s0 = peg$FAILED;
                                            }
                                        } else {
                                            peg$currPos = s0;
                                            s0 = peg$FAILED;
                                        }
                                    } else {
                                        peg$currPos = s0;
                                        s0 = peg$FAILED;
                                    }
                                } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                }
                            } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                            }
                        } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
                if (s0 === peg$FAILED) {
                    s0 = peg$currPos;
                    s1 = peg$parse_();
                    if (s1 !== peg$FAILED) {
                        s2 = peg$parseLetToken();
                        if (s2 !== peg$FAILED) {
                            s3 = peg$parse_();
                            if (s3 !== peg$FAILED) {
                                s4 = peg$parseIdentifier();
                                if (s4 !== peg$FAILED) {
                                    s5 = peg$parse_();
                                    if (s5 !== peg$FAILED) {
                                        if (input.charCodeAt(peg$currPos) === 61) {
                                            s6 = peg$c4;
                                            peg$currPos++;
                                        } else {
                                            s6 = peg$FAILED;
                                            if (peg$silentFails === 0) { peg$fail(peg$c5); }
                                        }
                                        if (s6 !== peg$FAILED) {
                                            s7 = peg$parse_();
                                            if (s7 !== peg$FAILED) {
                                                s8 = peg$parsepipeExpression();
                                                if (s8 !== peg$FAILED) {
                                                    peg$savedPos = s0;
                                                    s1 = peg$c11(s4);
                                                    s0 = s1;
                                                } else {
                                                    peg$currPos = s0;
                                                    s0 = peg$FAILED;
                                                }
                                            } else {
                                                peg$currPos = s0;
                                                s0 = peg$FAILED;
                                            }
                                        } else {
                                            peg$currPos = s0;
                                            s0 = peg$FAILED;
                                        }
                                    } else {
                                        peg$currPos = s0;
                                        s0 = peg$FAILED;
                                    }
                                } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                }
                            } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                            }
                        } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                    if (s0 === peg$FAILED) {
                        s0 = peg$currPos;
                        s1 = peg$parse_();
                        if (s1 !== peg$FAILED) {
                            s2 = peg$parseLetToken();
                            if (s2 !== peg$FAILED) {
                                s3 = peg$parse_();
                                if (s3 !== peg$FAILED) {
                                    s4 = peg$parseIdentifier();
                                    if (s4 !== peg$FAILED) {
                                        s5 = peg$parse_();
                                        if (s5 !== peg$FAILED) {
                                            if (input.charCodeAt(peg$currPos) === 61) {
                                                s6 = peg$c4;
                                                peg$currPos++;
                                            } else {
                                                s6 = peg$FAILED;
                                                if (peg$silentFails === 0) { peg$fail(peg$c5); }
                                            }
                                            if (s6 !== peg$FAILED) {
                                                s7 = peg$parse_();
                                                if (s7 !== peg$FAILED) {
                                                    if (input.charCodeAt(peg$currPos) === 40) {
                                                        s8 = peg$c12;
                                                        peg$currPos++;
                                                    } else {
                                                        s8 = peg$FAILED;
                                                        if (peg$silentFails === 0) { peg$fail(peg$c13); }
                                                    }
                                                    if (s8 !== peg$FAILED) {
                                                        s9 = peg$parse_();
                                                        if (s9 !== peg$FAILED) {
                                                            if (input.charCodeAt(peg$currPos) === 41) {
                                                                s10 = peg$c14;
                                                                peg$currPos++;
                                                            } else {
                                                                s10 = peg$FAILED;
                                                                if (peg$silentFails === 0) { peg$fail(peg$c15); }
                                                            }
                                                            if (s10 !== peg$FAILED) {
                                                                s11 = peg$parse_();
                                                                if (s11 !== peg$FAILED) {
                                                                    s12 = [];
                                                                    s13 = peg$currPos;
                                                                    s14 = peg$currPos;
                                                                    peg$silentFails++;
                                                                    if (input.charCodeAt(peg$currPos) === 59) {
                                                                        s15 = peg$c1;
                                                                        peg$currPos++;
                                                                    } else {
                                                                        s15 = peg$FAILED;
                                                                        if (peg$silentFails === 0) { peg$fail(peg$c2); }
                                                                    }
                                                                    peg$silentFails--;
                                                                    if (s15 === peg$FAILED) {
                                                                        s14 = void 0;
                                                                    } else {
                                                                        peg$currPos = s14;
                                                                        s14 = peg$FAILED;
                                                                    }
                                                                    if (s14 !== peg$FAILED) {
                                                                        if (input.length > peg$currPos) {
                                                                            s15 = input.charAt(peg$currPos);
                                                                            peg$currPos++;
                                                                        } else {
                                                                            s15 = peg$FAILED;
                                                                            if (peg$silentFails === 0) { peg$fail(peg$c6); }
                                                                        }
                                                                        if (s15 !== peg$FAILED) {
                                                                            s14 = [s14, s15];
                                                                            s13 = s14;
                                                                        } else {
                                                                            peg$currPos = s13;
                                                                            s13 = peg$FAILED;
                                                                        }
                                                                    } else {
                                                                        peg$currPos = s13;
                                                                        s13 = peg$FAILED;
                                                                    }
                                                                    while (s13 !== peg$FAILED) {
                                                                        s12.push(s13);
                                                                        s13 = peg$currPos;
                                                                        s14 = peg$currPos;
                                                                        peg$silentFails++;
                                                                        if (input.charCodeAt(peg$currPos) === 59) {
                                                                            s15 = peg$c1;
                                                                            peg$currPos++;
                                                                        } else {
                                                                            s15 = peg$FAILED;
                                                                            if (peg$silentFails === 0) { peg$fail(peg$c2); }
                                                                        }
                                                                        peg$silentFails--;
                                                                        if (s15 === peg$FAILED) {
                                                                            s14 = void 0;
                                                                        } else {
                                                                            peg$currPos = s14;
                                                                            s14 = peg$FAILED;
                                                                        }
                                                                        if (s14 !== peg$FAILED) {
                                                                            if (input.length > peg$currPos) {
                                                                                s15 = input.charAt(peg$currPos);
                                                                                peg$currPos++;
                                                                            } else {
                                                                                s15 = peg$FAILED;
                                                                                if (peg$silentFails === 0) { peg$fail(peg$c6); }
                                                                            }
                                                                            if (s15 !== peg$FAILED) {
                                                                                s14 = [s14, s15];
                                                                                s13 = s14;
                                                                            } else {
                                                                                peg$currPos = s13;
                                                                                s13 = peg$FAILED;
                                                                            }
                                                                        } else {
                                                                            peg$currPos = s13;
                                                                            s13 = peg$FAILED;
                                                                        }
                                                                    }
                                                                    if (s12 !== peg$FAILED) {
                                                                        s13 = peg$parse_();
                                                                        if (s13 !== peg$FAILED) {
                                                                            peg$savedPos = s0;
                                                                            s1 = peg$c16(s4, s12);
                                                                            s0 = s1;
                                                                        } else {
                                                                            peg$currPos = s0;
                                                                            s0 = peg$FAILED;
                                                                        }
                                                                    } else {
                                                                        peg$currPos = s0;
                                                                        s0 = peg$FAILED;
                                                                    }
                                                                } else {
                                                                    peg$currPos = s0;
                                                                    s0 = peg$FAILED;
                                                                }
                                                            } else {
                                                                peg$currPos = s0;
                                                                s0 = peg$FAILED;
                                                            }
                                                        } else {
                                                            peg$currPos = s0;
                                                            s0 = peg$FAILED;
                                                        }
                                                    } else {
                                                        peg$currPos = s0;
                                                        s0 = peg$FAILED;
                                                    }
                                                } else {
                                                    peg$currPos = s0;
                                                    s0 = peg$FAILED;
                                                }
                                            } else {
                                                peg$currPos = s0;
                                                s0 = peg$FAILED;
                                            }
                                        } else {
                                            peg$currPos = s0;
                                            s0 = peg$FAILED;
                                        }
                                    } else {
                                        peg$currPos = s0;
                                        s0 = peg$FAILED;
                                    }
                                } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                }
                            } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                            }
                        } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                        }
                        if (s0 === peg$FAILED) {
                            s0 = peg$currPos;
                            s1 = peg$parse_();
                            if (s1 !== peg$FAILED) {
                                s2 = peg$parseLetToken();
                                if (s2 !== peg$FAILED) {
                                    s3 = peg$parse_();
                                    if (s3 !== peg$FAILED) {
                                        s4 = peg$parseIdentifier();
                                        if (s4 !== peg$FAILED) {
                                            s5 = peg$parse_();
                                            if (s5 !== peg$FAILED) {
                                                if (input.charCodeAt(peg$currPos) === 61) {
                                                    s6 = peg$c4;
                                                    peg$currPos++;
                                                } else {
                                                    s6 = peg$FAILED;
                                                    if (peg$silentFails === 0) { peg$fail(peg$c5); }
                                                }
                                                if (s6 !== peg$FAILED) {
                                                    s7 = peg$parse_();
                                                    if (s7 !== peg$FAILED) {
                                                        s8 = [];
                                                        s9 = peg$currPos;
                                                        s10 = peg$currPos;
                                                        peg$silentFails++;
                                                        if (input.charCodeAt(peg$currPos) === 59) {
                                                            s11 = peg$c1;
                                                            peg$currPos++;
                                                        } else {
                                                            s11 = peg$FAILED;
                                                            if (peg$silentFails === 0) { peg$fail(peg$c2); }
                                                        }
                                                        peg$silentFails--;
                                                        if (s11 === peg$FAILED) {
                                                            s10 = void 0;
                                                        } else {
                                                            peg$currPos = s10;
                                                            s10 = peg$FAILED;
                                                        }
                                                        if (s10 !== peg$FAILED) {
                                                            if (input.length > peg$currPos) {
                                                                s11 = input.charAt(peg$currPos);
                                                                peg$currPos++;
                                                            } else {
                                                                s11 = peg$FAILED;
                                                                if (peg$silentFails === 0) { peg$fail(peg$c6); }
                                                            }
                                                            if (s11 !== peg$FAILED) {
                                                                s10 = [s10, s11];
                                                                s9 = s10;
                                                            } else {
                                                                peg$currPos = s9;
                                                                s9 = peg$FAILED;
                                                            }
                                                        } else {
                                                            peg$currPos = s9;
                                                            s9 = peg$FAILED;
                                                        }
                                                        while (s9 !== peg$FAILED) {
                                                            s8.push(s9);
                                                            s9 = peg$currPos;
                                                            s10 = peg$currPos;
                                                            peg$silentFails++;
                                                            if (input.charCodeAt(peg$currPos) === 59) {
                                                                s11 = peg$c1;
                                                                peg$currPos++;
                                                            } else {
                                                                s11 = peg$FAILED;
                                                                if (peg$silentFails === 0) { peg$fail(peg$c2); }
                                                            }
                                                            peg$silentFails--;
                                                            if (s11 === peg$FAILED) {
                                                                s10 = void 0;
                                                            } else {
                                                                peg$currPos = s10;
                                                                s10 = peg$FAILED;
                                                            }
                                                            if (s10 !== peg$FAILED) {
                                                                if (input.length > peg$currPos) {
                                                                    s11 = input.charAt(peg$currPos);
                                                                    peg$currPos++;
                                                                } else {
                                                                    s11 = peg$FAILED;
                                                                    if (peg$silentFails === 0) { peg$fail(peg$c6); }
                                                                }
                                                                if (s11 !== peg$FAILED) {
                                                                    s10 = [s10, s11];
                                                                    s9 = s10;
                                                                } else {
                                                                    peg$currPos = s9;
                                                                    s9 = peg$FAILED;
                                                                }
                                                            } else {
                                                                peg$currPos = s9;
                                                                s9 = peg$FAILED;
                                                            }
                                                        }
                                                        if (s8 !== peg$FAILED) {
                                                            s9 = peg$parse_();
                                                            if (s9 !== peg$FAILED) {
                                                                peg$savedPos = s0;
                                                                s1 = peg$c17(s4, s8);
                                                                s0 = s1;
                                                            } else {
                                                                peg$currPos = s0;
                                                                s0 = peg$FAILED;
                                                            }
                                                        } else {
                                                            peg$currPos = s0;
                                                            s0 = peg$FAILED;
                                                        }
                                                    } else {
                                                        peg$currPos = s0;
                                                        s0 = peg$FAILED;
                                                    }
                                                } else {
                                                    peg$currPos = s0;
                                                    s0 = peg$FAILED;
                                                }
                                            } else {
                                                peg$currPos = s0;
                                                s0 = peg$FAILED;
                                            }
                                        } else {
                                            peg$currPos = s0;
                                            s0 = peg$FAILED;
                                        }
                                    } else {
                                        peg$currPos = s0;
                                        s0 = peg$FAILED;
                                    }
                                } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                }
                            } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                            }
                        }
                    }
                }
            }

            return s0;
        }

        function peg$parserestrictStatement() {
            var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12;

            s0 = peg$currPos;
            s1 = peg$parse_();
            if (s1 !== peg$FAILED) {
                s2 = peg$parseRestrictToken();
                if (s2 !== peg$FAILED) {
                    s3 = peg$parse_();
                    if (s3 !== peg$FAILED) {
                        if (input.substr(peg$currPos, 6) === peg$c18) {
                            s4 = peg$c18;
                            peg$currPos += 6;
                        } else {
                            s4 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c19); }
                        }
                        if (s4 !== peg$FAILED) {
                            s5 = peg$parse_();
                            if (s5 !== peg$FAILED) {
                                if (input.substr(peg$currPos, 2) === peg$c20) {
                                    s6 = peg$c20;
                                    peg$currPos += 2;
                                } else {
                                    s6 = peg$FAILED;
                                    if (peg$silentFails === 0) { peg$fail(peg$c21); }
                                }
                                if (s6 !== peg$FAILED) {
                                    s7 = peg$parse_();
                                    if (s7 !== peg$FAILED) {
                                        if (input.charCodeAt(peg$currPos) === 40) {
                                            s8 = peg$c12;
                                            peg$currPos++;
                                        } else {
                                            s8 = peg$FAILED;
                                            if (peg$silentFails === 0) { peg$fail(peg$c13); }
                                        }
                                        if (s8 !== peg$FAILED) {
                                            s9 = peg$parse_();
                                            if (s9 !== peg$FAILED) {
                                                s10 = peg$parsetableList();
                                                if (s10 !== peg$FAILED) {
                                                    s11 = peg$parse_();
                                                    if (s11 !== peg$FAILED) {
                                                        if (input.charCodeAt(peg$currPos) === 41) {
                                                            s12 = peg$c14;
                                                            peg$currPos++;
                                                        } else {
                                                            s12 = peg$FAILED;
                                                            if (peg$silentFails === 0) { peg$fail(peg$c15); }
                                                        }
                                                        if (s12 !== peg$FAILED) {
                                                            s1 = [s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12];
                                                            s0 = s1;
                                                        } else {
                                                            peg$currPos = s0;
                                                            s0 = peg$FAILED;
                                                        }
                                                    } else {
                                                        peg$currPos = s0;
                                                        s0 = peg$FAILED;
                                                    }
                                                } else {
                                                    peg$currPos = s0;
                                                    s0 = peg$FAILED;
                                                }
                                            } else {
                                                peg$currPos = s0;
                                                s0 = peg$FAILED;
                                            }
                                        } else {
                                            peg$currPos = s0;
                                            s0 = peg$FAILED;
                                        }
                                    } else {
                                        peg$currPos = s0;
                                        s0 = peg$FAILED;
                                    }
                                } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                }
                            } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                            }
                        } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }

            return s0;
        }

        function peg$parsequeryStatement() {
            var s0, s1, s2, s3;

            s0 = peg$currPos;
            s1 = peg$parse_();
            if (s1 !== peg$FAILED) {
                s2 = peg$parsepipeExpression();
                if (s2 !== peg$FAILED) {
                    s3 = peg$parse_();
                    if (s3 !== peg$FAILED) {
                        s1 = [s1, s2, s3];
                        s0 = s1;
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }

            return s0;
        }

        function peg$parsepipeExpression() {
            var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9;

            s0 = peg$currPos;
            s1 = peg$parse_();
            if (s1 !== peg$FAILED) {
                s2 = peg$parseUnionTables();
                if (s2 !== peg$FAILED) {
                    s3 = peg$parse_();
                    if (s3 !== peg$FAILED) {
                        s4 = [];
                        s5 = peg$currPos;
                        if (input.charCodeAt(peg$currPos) === 124) {
                            s6 = peg$c8;
                            peg$currPos++;
                        } else {
                            s6 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c9); }
                        }
                        if (s6 !== peg$FAILED) {
                            s7 = peg$parse_();
                            if (s7 !== peg$FAILED) {
                                s8 = peg$parsepipeElement();
                                if (s8 !== peg$FAILED) {
                                    s6 = [s6, s7, s8];
                                    s5 = s6;
                                } else {
                                    peg$currPos = s5;
                                    s5 = peg$FAILED;
                                }
                            } else {
                                peg$currPos = s5;
                                s5 = peg$FAILED;
                            }
                        } else {
                            peg$currPos = s5;
                            s5 = peg$FAILED;
                        }
                        while (s5 !== peg$FAILED) {
                            s4.push(s5);
                            s5 = peg$currPos;
                            if (input.charCodeAt(peg$currPos) === 124) {
                                s6 = peg$c8;
                                peg$currPos++;
                            } else {
                                s6 = peg$FAILED;
                                if (peg$silentFails === 0) { peg$fail(peg$c9); }
                            }
                            if (s6 !== peg$FAILED) {
                                s7 = peg$parse_();
                                if (s7 !== peg$FAILED) {
                                    s8 = peg$parsepipeElement();
                                    if (s8 !== peg$FAILED) {
                                        s6 = [s6, s7, s8];
                                        s5 = s6;
                                    } else {
                                        peg$currPos = s5;
                                        s5 = peg$FAILED;
                                    }
                                } else {
                                    peg$currPos = s5;
                                    s5 = peg$FAILED;
                                }
                            } else {
                                peg$currPos = s5;
                                s5 = peg$FAILED;
                            }
                        }
                        if (s4 !== peg$FAILED) {
                            s1 = [s1, s2, s3, s4];
                            s0 = s1;
                        } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }
            if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                s1 = peg$parse_();
                if (s1 !== peg$FAILED) {
                    s2 = peg$parseIdentifier();
                    if (s2 !== peg$FAILED) {
                        peg$savedPos = peg$currPos;
                        s3 = peg$c22(s2);
                        if (s3) {
                            s3 = void 0;
                        } else {
                            s3 = peg$FAILED;
                        }
                        if (s3 !== peg$FAILED) {
                            s4 = peg$parse_();
                            if (s4 !== peg$FAILED) {
                                s5 = [];
                                s6 = peg$currPos;
                                if (input.charCodeAt(peg$currPos) === 124) {
                                    s7 = peg$c8;
                                    peg$currPos++;
                                } else {
                                    s7 = peg$FAILED;
                                    if (peg$silentFails === 0) { peg$fail(peg$c9); }
                                }
                                if (s7 !== peg$FAILED) {
                                    s8 = peg$parse_();
                                    if (s8 !== peg$FAILED) {
                                        s9 = peg$parsepipeElement();
                                        if (s9 !== peg$FAILED) {
                                            s7 = [s7, s8, s9];
                                            s6 = s7;
                                        } else {
                                            peg$currPos = s6;
                                            s6 = peg$FAILED;
                                        }
                                    } else {
                                        peg$currPos = s6;
                                        s6 = peg$FAILED;
                                    }
                                } else {
                                    peg$currPos = s6;
                                    s6 = peg$FAILED;
                                }
                                while (s6 !== peg$FAILED) {
                                    s5.push(s6);
                                    s6 = peg$currPos;
                                    if (input.charCodeAt(peg$currPos) === 124) {
                                        s7 = peg$c8;
                                        peg$currPos++;
                                    } else {
                                        s7 = peg$FAILED;
                                        if (peg$silentFails === 0) { peg$fail(peg$c9); }
                                    }
                                    if (s7 !== peg$FAILED) {
                                        s8 = peg$parse_();
                                        if (s8 !== peg$FAILED) {
                                            s9 = peg$parsepipeElement();
                                            if (s9 !== peg$FAILED) {
                                                s7 = [s7, s8, s9];
                                                s6 = s7;
                                            } else {
                                                peg$currPos = s6;
                                                s6 = peg$FAILED;
                                            }
                                        } else {
                                            peg$currPos = s6;
                                            s6 = peg$FAILED;
                                        }
                                    } else {
                                        peg$currPos = s6;
                                        s6 = peg$FAILED;
                                    }
                                }
                                if (s5 !== peg$FAILED) {
                                    peg$savedPos = peg$currPos;
                                    s6 = peg$c23(s2, s5);
                                    if (s6) {
                                        s6 = void 0;
                                    } else {
                                        s6 = peg$FAILED;
                                    }
                                    if (s6 !== peg$FAILED) {
                                        peg$savedPos = s0;
                                        s1 = peg$c24(s2, s5);
                                        s0 = s1;
                                    } else {
                                        peg$currPos = s0;
                                        s0 = peg$FAILED;
                                    }
                                } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                }
                            } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                            }
                        } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            }

            return s0;
        }

        function peg$parsepipeElement() {
            var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11;

            s0 = peg$currPos;
            s1 = peg$parse_();
            if (s1 !== peg$FAILED) {
                s2 = peg$parseJoinToken();
                if (s2 !== peg$FAILED) {
                    s3 = peg$parse_();
                    if (s3 !== peg$FAILED) {
                        s4 = peg$currPos;
                        if (input.substr(peg$currPos, 4) === peg$c25) {
                            s5 = peg$c25;
                            peg$currPos += 4;
                        } else {
                            s5 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c26); }
                        }
                        if (s5 === peg$FAILED) {
                            if (input.substr(peg$currPos, 4) === peg$c27) {
                                s5 = peg$c27;
                                peg$currPos += 4;
                            } else {
                                s5 = peg$FAILED;
                                if (peg$silentFails === 0) { peg$fail(peg$c28); }
                            }
                        }
                        if (s5 !== peg$FAILED) {
                            s6 = peg$parse_();
                            if (s6 !== peg$FAILED) {
                                if (input.charCodeAt(peg$currPos) === 61) {
                                    s7 = peg$c4;
                                    peg$currPos++;
                                } else {
                                    s7 = peg$FAILED;
                                    if (peg$silentFails === 0) { peg$fail(peg$c5); }
                                }
                                if (s7 !== peg$FAILED) {
                                    s8 = peg$parse_();
                                    if (s8 !== peg$FAILED) {
                                        s9 = peg$parseAlphaLiteral();
                                        if (s9 !== peg$FAILED) {
                                            s5 = [s5, s6, s7, s8, s9];
                                            s4 = s5;
                                        } else {
                                            peg$currPos = s4;
                                            s4 = peg$FAILED;
                                        }
                                    } else {
                                        peg$currPos = s4;
                                        s4 = peg$FAILED;
                                    }
                                } else {
                                    peg$currPos = s4;
                                    s4 = peg$FAILED;
                                }
                            } else {
                                peg$currPos = s4;
                                s4 = peg$FAILED;
                            }
                        } else {
                            peg$currPos = s4;
                            s4 = peg$FAILED;
                        }
                        if (s4 === peg$FAILED) {
                            s4 = null;
                        }
                        if (s4 !== peg$FAILED) {
                            s5 = peg$parse_();
                            if (s5 !== peg$FAILED) {
                                s6 = peg$parsejoinRightSide();
                                if (s6 !== peg$FAILED) {
                                    s7 = peg$parse_();
                                    if (s7 !== peg$FAILED) {
                                        s8 = [];
                                        s9 = peg$currPos;
                                        s10 = peg$currPos;
                                        peg$silentFails++;
                                        if (input.charCodeAt(peg$currPos) === 124) {
                                            s11 = peg$c8;
                                            peg$currPos++;
                                        } else {
                                            s11 = peg$FAILED;
                                            if (peg$silentFails === 0) { peg$fail(peg$c9); }
                                        }
                                        if (s11 === peg$FAILED) {
                                            if (input.charCodeAt(peg$currPos) === 59) {
                                                s11 = peg$c1;
                                                peg$currPos++;
                                            } else {
                                                s11 = peg$FAILED;
                                                if (peg$silentFails === 0) { peg$fail(peg$c2); }
                                            }
                                        }
                                        peg$silentFails--;
                                        if (s11 === peg$FAILED) {
                                            s10 = void 0;
                                        } else {
                                            peg$currPos = s10;
                                            s10 = peg$FAILED;
                                        }
                                        if (s10 !== peg$FAILED) {
                                            if (input.length > peg$currPos) {
                                                s11 = input.charAt(peg$currPos);
                                                peg$currPos++;
                                            } else {
                                                s11 = peg$FAILED;
                                                if (peg$silentFails === 0) { peg$fail(peg$c6); }
                                            }
                                            if (s11 !== peg$FAILED) {
                                                s10 = [s10, s11];
                                                s9 = s10;
                                            } else {
                                                peg$currPos = s9;
                                                s9 = peg$FAILED;
                                            }
                                        } else {
                                            peg$currPos = s9;
                                            s9 = peg$FAILED;
                                        }
                                        while (s9 !== peg$FAILED) {
                                            s8.push(s9);
                                            s9 = peg$currPos;
                                            s10 = peg$currPos;
                                            peg$silentFails++;
                                            if (input.charCodeAt(peg$currPos) === 124) {
                                                s11 = peg$c8;
                                                peg$currPos++;
                                            } else {
                                                s11 = peg$FAILED;
                                                if (peg$silentFails === 0) { peg$fail(peg$c9); }
                                            }
                                            if (s11 === peg$FAILED) {
                                                if (input.charCodeAt(peg$currPos) === 59) {
                                                    s11 = peg$c1;
                                                    peg$currPos++;
                                                } else {
                                                    s11 = peg$FAILED;
                                                    if (peg$silentFails === 0) { peg$fail(peg$c2); }
                                                }
                                            }
                                            peg$silentFails--;
                                            if (s11 === peg$FAILED) {
                                                s10 = void 0;
                                            } else {
                                                peg$currPos = s10;
                                                s10 = peg$FAILED;
                                            }
                                            if (s10 !== peg$FAILED) {
                                                if (input.length > peg$currPos) {
                                                    s11 = input.charAt(peg$currPos);
                                                    peg$currPos++;
                                                } else {
                                                    s11 = peg$FAILED;
                                                    if (peg$silentFails === 0) { peg$fail(peg$c6); }
                                                }
                                                if (s11 !== peg$FAILED) {
                                                    s10 = [s10, s11];
                                                    s9 = s10;
                                                } else {
                                                    peg$currPos = s9;
                                                    s9 = peg$FAILED;
                                                }
                                            } else {
                                                peg$currPos = s9;
                                                s9 = peg$FAILED;
                                            }
                                        }
                                        if (s8 !== peg$FAILED) {
                                            s9 = peg$parse_();
                                            if (s9 !== peg$FAILED) {
                                                peg$savedPos = s0;
                                                s1 = peg$c29(s8);
                                                s0 = s1;
                                            } else {
                                                peg$currPos = s0;
                                                s0 = peg$FAILED;
                                            }
                                        } else {
                                            peg$currPos = s0;
                                            s0 = peg$FAILED;
                                        }
                                    } else {
                                        peg$currPos = s0;
                                        s0 = peg$FAILED;
                                    }
                                } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                }
                            } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                            }
                        } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }
            if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                s1 = peg$parse_();
                if (s1 !== peg$FAILED) {
                    s2 = peg$parseUnionTables();
                    if (s2 !== peg$FAILED) {
                        s3 = peg$parse_();
                        if (s3 !== peg$FAILED) {
                            s4 = [];
                            s5 = peg$currPos;
                            s6 = peg$currPos;
                            peg$silentFails++;
                            if (input.charCodeAt(peg$currPos) === 124) {
                                s7 = peg$c8;
                                peg$currPos++;
                            } else {
                                s7 = peg$FAILED;
                                if (peg$silentFails === 0) { peg$fail(peg$c9); }
                            }
                            if (s7 === peg$FAILED) {
                                if (input.charCodeAt(peg$currPos) === 59) {
                                    s7 = peg$c1;
                                    peg$currPos++;
                                } else {
                                    s7 = peg$FAILED;
                                    if (peg$silentFails === 0) { peg$fail(peg$c2); }
                                }
                            }
                            peg$silentFails--;
                            if (s7 === peg$FAILED) {
                                s6 = void 0;
                            } else {
                                peg$currPos = s6;
                                s6 = peg$FAILED;
                            }
                            if (s6 !== peg$FAILED) {
                                if (input.length > peg$currPos) {
                                    s7 = input.charAt(peg$currPos);
                                    peg$currPos++;
                                } else {
                                    s7 = peg$FAILED;
                                    if (peg$silentFails === 0) { peg$fail(peg$c6); }
                                }
                                if (s7 !== peg$FAILED) {
                                    s6 = [s6, s7];
                                    s5 = s6;
                                } else {
                                    peg$currPos = s5;
                                    s5 = peg$FAILED;
                                }
                            } else {
                                peg$currPos = s5;
                                s5 = peg$FAILED;
                            }
                            while (s5 !== peg$FAILED) {
                                s4.push(s5);
                                s5 = peg$currPos;
                                s6 = peg$currPos;
                                peg$silentFails++;
                                if (input.charCodeAt(peg$currPos) === 124) {
                                    s7 = peg$c8;
                                    peg$currPos++;
                                } else {
                                    s7 = peg$FAILED;
                                    if (peg$silentFails === 0) { peg$fail(peg$c9); }
                                }
                                if (s7 === peg$FAILED) {
                                    if (input.charCodeAt(peg$currPos) === 59) {
                                        s7 = peg$c1;
                                        peg$currPos++;
                                    } else {
                                        s7 = peg$FAILED;
                                        if (peg$silentFails === 0) { peg$fail(peg$c2); }
                                    }
                                }
                                peg$silentFails--;
                                if (s7 === peg$FAILED) {
                                    s6 = void 0;
                                } else {
                                    peg$currPos = s6;
                                    s6 = peg$FAILED;
                                }
                                if (s6 !== peg$FAILED) {
                                    if (input.length > peg$currPos) {
                                        s7 = input.charAt(peg$currPos);
                                        peg$currPos++;
                                    } else {
                                        s7 = peg$FAILED;
                                        if (peg$silentFails === 0) { peg$fail(peg$c6); }
                                    }
                                    if (s7 !== peg$FAILED) {
                                        s6 = [s6, s7];
                                        s5 = s6;
                                    } else {
                                        peg$currPos = s5;
                                        s5 = peg$FAILED;
                                    }
                                } else {
                                    peg$currPos = s5;
                                    s5 = peg$FAILED;
                                }
                            }
                            if (s4 !== peg$FAILED) {
                                s5 = peg$parse_();
                                if (s5 !== peg$FAILED) {
                                    s1 = [s1, s2, s3, s4, s5];
                                    s0 = s1;
                                } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                }
                            } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                            }
                        } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
                if (s0 === peg$FAILED) {
                    s0 = peg$currPos;
                    s1 = peg$parse_();
                    if (s1 !== peg$FAILED) {
                        s2 = [];
                        s3 = peg$currPos;
                        s4 = peg$currPos;
                        peg$silentFails++;
                        if (input.charCodeAt(peg$currPos) === 124) {
                            s5 = peg$c8;
                            peg$currPos++;
                        } else {
                            s5 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c9); }
                        }
                        if (s5 === peg$FAILED) {
                            if (input.charCodeAt(peg$currPos) === 59) {
                                s5 = peg$c1;
                                peg$currPos++;
                            } else {
                                s5 = peg$FAILED;
                                if (peg$silentFails === 0) { peg$fail(peg$c2); }
                            }
                        }
                        peg$silentFails--;
                        if (s5 === peg$FAILED) {
                            s4 = void 0;
                        } else {
                            peg$currPos = s4;
                            s4 = peg$FAILED;
                        }
                        if (s4 !== peg$FAILED) {
                            if (input.length > peg$currPos) {
                                s5 = input.charAt(peg$currPos);
                                peg$currPos++;
                            } else {
                                s5 = peg$FAILED;
                                if (peg$silentFails === 0) { peg$fail(peg$c6); }
                            }
                            if (s5 !== peg$FAILED) {
                                s4 = [s4, s5];
                                s3 = s4;
                            } else {
                                peg$currPos = s3;
                                s3 = peg$FAILED;
                            }
                        } else {
                            peg$currPos = s3;
                            s3 = peg$FAILED;
                        }
                        while (s3 !== peg$FAILED) {
                            s2.push(s3);
                            s3 = peg$currPos;
                            s4 = peg$currPos;
                            peg$silentFails++;
                            if (input.charCodeAt(peg$currPos) === 124) {
                                s5 = peg$c8;
                                peg$currPos++;
                            } else {
                                s5 = peg$FAILED;
                                if (peg$silentFails === 0) { peg$fail(peg$c9); }
                            }
                            if (s5 === peg$FAILED) {
                                if (input.charCodeAt(peg$currPos) === 59) {
                                    s5 = peg$c1;
                                    peg$currPos++;
                                } else {
                                    s5 = peg$FAILED;
                                    if (peg$silentFails === 0) { peg$fail(peg$c2); }
                                }
                            }
                            peg$silentFails--;
                            if (s5 === peg$FAILED) {
                                s4 = void 0;
                            } else {
                                peg$currPos = s4;
                                s4 = peg$FAILED;
                            }
                            if (s4 !== peg$FAILED) {
                                if (input.length > peg$currPos) {
                                    s5 = input.charAt(peg$currPos);
                                    peg$currPos++;
                                } else {
                                    s5 = peg$FAILED;
                                    if (peg$silentFails === 0) { peg$fail(peg$c6); }
                                }
                                if (s5 !== peg$FAILED) {
                                    s4 = [s4, s5];
                                    s3 = s4;
                                } else {
                                    peg$currPos = s3;
                                    s3 = peg$FAILED;
                                }
                            } else {
                                peg$currPos = s3;
                                s3 = peg$FAILED;
                            }
                        }
                        if (s2 !== peg$FAILED) {
                            s3 = peg$parse_();
                            if (s3 !== peg$FAILED) {
                                peg$savedPos = s0;
                                s1 = peg$c30(s2);
                                s0 = s1;
                            } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                            }
                        } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                }
            }

            return s0;
        }

        function peg$parseOperator() {
            var s0, s1, s2, s3;

            s0 = peg$currPos;
            s1 = peg$parse_();
            if (s1 !== peg$FAILED) {
                s2 = peg$parseWhereOperator();
                if (s2 !== peg$FAILED) {
                    s3 = peg$parse_();
                    if (s3 !== peg$FAILED) {
                        s1 = [s1, s2, s3];
                        s0 = s1;
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }
            if (s0 === peg$FAILED) {
                if (input.substr(peg$currPos, 6) === peg$c31) {
                    s0 = peg$c31;
                    peg$currPos += 6;
                } else {
                    s0 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c32); }
                }
            }

            return s0;
        }

        function peg$parseWhereOperator() {
            var s0, s1, s2, s3, s4;

            s0 = peg$currPos;
            s1 = peg$parse_();
            if (s1 !== peg$FAILED) {
                if (input.substr(peg$currPos, 5) === peg$c33) {
                    s2 = peg$c33;
                    peg$currPos += 5;
                } else {
                    s2 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c34); }
                }
                if (s2 !== peg$FAILED) {
                    s3 = peg$parse_();
                    if (s3 !== peg$FAILED) {
                        s4 = peg$parsePredicate();
                        if (s4 !== peg$FAILED) {
                            peg$savedPos = s0;
                            s1 = peg$c35(s4);
                            s0 = s1;
                        } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }

            return s0;
        }

        function peg$parsePredicate() {
            var s0, s1, s2, s3, s4, s5, s6, s7;

            s0 = peg$currPos;
            s1 = peg$parse_();
            if (s1 !== peg$FAILED) {
                s2 = peg$parsePredicateExp();
                if (s2 !== peg$FAILED) {
                    s3 = peg$parse_();
                    if (s3 !== peg$FAILED) {
                        s4 = peg$parseRelationOperator();
                        if (s4 !== peg$FAILED) {
                            s5 = peg$parse_();
                            if (s5 !== peg$FAILED) {
                                s6 = peg$parsePredicateExp();
                                if (s6 !== peg$FAILED) {
                                    s7 = peg$parse_();
                                    if (s7 !== peg$FAILED) {
                                        s1 = [s1, s2, s3, s4, s5, s6, s7];
                                        s0 = s1;
                                    } else {
                                        peg$currPos = s0;
                                        s0 = peg$FAILED;
                                    }
                                } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                }
                            } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                            }
                        } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }
            if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                s1 = peg$parse_();
                if (s1 !== peg$FAILED) {
                    if (input.charCodeAt(peg$currPos) === 40) {
                        s2 = peg$c12;
                        peg$currPos++;
                    } else {
                        s2 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c13); }
                    }
                    if (s2 !== peg$FAILED) {
                        s3 = peg$parse_();
                        if (s3 !== peg$FAILED) {
                            s4 = peg$parsePredicate();
                            if (s4 !== peg$FAILED) {
                                if (input.charCodeAt(peg$currPos) === 41) {
                                    s5 = peg$c14;
                                    peg$currPos++;
                                } else {
                                    s5 = peg$FAILED;
                                    if (peg$silentFails === 0) { peg$fail(peg$c15); }
                                }
                                if (s5 !== peg$FAILED) {
                                    s6 = peg$parse_();
                                    if (s6 !== peg$FAILED) {
                                        s1 = [s1, s2, s3, s4, s5, s6];
                                        s0 = s1;
                                    } else {
                                        peg$currPos = s0;
                                        s0 = peg$FAILED;
                                    }
                                } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                }
                            } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                            }
                        } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            }

            return s0;
        }

        function peg$parsePredicateExp() {
            var s0, s1, s2, s3;

            s0 = peg$currPos;
            s1 = peg$parse_();
            if (s1 !== peg$FAILED) {
                s2 = peg$parseIdentifier();
                if (s2 !== peg$FAILED) {
                    s3 = peg$parse_();
                    if (s3 !== peg$FAILED) {
                        s1 = [s1, s2, s3];
                        s0 = s1;
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }

            return s0;
        }

        function peg$parseRelationOperator() {
            var s0;

            if (input.charCodeAt(peg$currPos) === 60) {
                s0 = peg$c36;
                peg$currPos++;
            } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c37); }
            }
            if (s0 === peg$FAILED) {
                if (input.charCodeAt(peg$currPos) === 62) {
                    s0 = peg$c38;
                    peg$currPos++;
                } else {
                    s0 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c39); }
                }
            }

            return s0;
        }

        function peg$parseUnionTables() {
            var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12, s13;

            s0 = peg$currPos;
            s1 = peg$parse_();
            if (s1 !== peg$FAILED) {
                s2 = peg$parseUnionToken();
                if (s2 !== peg$FAILED) {
                    s3 = peg$parse_();
                    if (s3 !== peg$FAILED) {
                        s4 = peg$currPos;
                        if (input.substr(peg$currPos, 4) === peg$c25) {
                            s5 = peg$c25;
                            peg$currPos += 4;
                        } else {
                            s5 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c26); }
                        }
                        if (s5 !== peg$FAILED) {
                            s6 = peg$parse_();
                            if (s6 !== peg$FAILED) {
                                if (input.charCodeAt(peg$currPos) === 61) {
                                    s7 = peg$c4;
                                    peg$currPos++;
                                } else {
                                    s7 = peg$FAILED;
                                    if (peg$silentFails === 0) { peg$fail(peg$c5); }
                                }
                                if (s7 !== peg$FAILED) {
                                    s8 = peg$parse_();
                                    if (s8 !== peg$FAILED) {
                                        s9 = peg$parseAlphaLiteral();
                                        if (s9 !== peg$FAILED) {
                                            s5 = [s5, s6, s7, s8, s9];
                                            s4 = s5;
                                        } else {
                                            peg$currPos = s4;
                                            s4 = peg$FAILED;
                                        }
                                    } else {
                                        peg$currPos = s4;
                                        s4 = peg$FAILED;
                                    }
                                } else {
                                    peg$currPos = s4;
                                    s4 = peg$FAILED;
                                }
                            } else {
                                peg$currPos = s4;
                                s4 = peg$FAILED;
                            }
                        } else {
                            peg$currPos = s4;
                            s4 = peg$FAILED;
                        }
                        if (s4 === peg$FAILED) {
                            s4 = null;
                        }
                        if (s4 !== peg$FAILED) {
                            s5 = peg$parse_();
                            if (s5 !== peg$FAILED) {
                                s6 = peg$currPos;
                                if (input.substr(peg$currPos, 10) === peg$c40) {
                                    s7 = peg$c40;
                                    peg$currPos += 10;
                                } else {
                                    s7 = peg$FAILED;
                                    if (peg$silentFails === 0) { peg$fail(peg$c41); }
                                }
                                if (s7 !== peg$FAILED) {
                                    s8 = peg$parse_();
                                    if (s8 !== peg$FAILED) {
                                        if (input.charCodeAt(peg$currPos) === 61) {
                                            s9 = peg$c4;
                                            peg$currPos++;
                                        } else {
                                            s9 = peg$FAILED;
                                            if (peg$silentFails === 0) { peg$fail(peg$c5); }
                                        }
                                        if (s9 !== peg$FAILED) {
                                            s10 = peg$parse_();
                                            if (s10 !== peg$FAILED) {
                                                s11 = peg$parseIdentifier();
                                                if (s11 !== peg$FAILED) {
                                                    s7 = [s7, s8, s9, s10, s11];
                                                    s6 = s7;
                                                } else {
                                                    peg$currPos = s6;
                                                    s6 = peg$FAILED;
                                                }
                                            } else {
                                                peg$currPos = s6;
                                                s6 = peg$FAILED;
                                            }
                                        } else {
                                            peg$currPos = s6;
                                            s6 = peg$FAILED;
                                        }
                                    } else {
                                        peg$currPos = s6;
                                        s6 = peg$FAILED;
                                    }
                                } else {
                                    peg$currPos = s6;
                                    s6 = peg$FAILED;
                                }
                                if (s6 === peg$FAILED) {
                                    s6 = null;
                                }
                                if (s6 !== peg$FAILED) {
                                    s7 = peg$parse_();
                                    if (s7 !== peg$FAILED) {
                                        s8 = peg$currPos;
                                        if (input.substr(peg$currPos, 4) === peg$c25) {
                                            s9 = peg$c25;
                                            peg$currPos += 4;
                                        } else {
                                            s9 = peg$FAILED;
                                            if (peg$silentFails === 0) { peg$fail(peg$c26); }
                                        }
                                        if (s9 !== peg$FAILED) {
                                            s10 = peg$parse_();
                                            if (s10 !== peg$FAILED) {
                                                if (input.charCodeAt(peg$currPos) === 61) {
                                                    s11 = peg$c4;
                                                    peg$currPos++;
                                                } else {
                                                    s11 = peg$FAILED;
                                                    if (peg$silentFails === 0) { peg$fail(peg$c5); }
                                                }
                                                if (s11 !== peg$FAILED) {
                                                    s12 = peg$parse_();
                                                    if (s12 !== peg$FAILED) {
                                                        s13 = peg$parseAlphaLiteral();
                                                        if (s13 !== peg$FAILED) {
                                                            s9 = [s9, s10, s11, s12, s13];
                                                            s8 = s9;
                                                        } else {
                                                            peg$currPos = s8;
                                                            s8 = peg$FAILED;
                                                        }
                                                    } else {
                                                        peg$currPos = s8;
                                                        s8 = peg$FAILED;
                                                    }
                                                } else {
                                                    peg$currPos = s8;
                                                    s8 = peg$FAILED;
                                                }
                                            } else {
                                                peg$currPos = s8;
                                                s8 = peg$FAILED;
                                            }
                                        } else {
                                            peg$currPos = s8;
                                            s8 = peg$FAILED;
                                        }
                                        if (s8 === peg$FAILED) {
                                            s8 = null;
                                        }
                                        if (s8 !== peg$FAILED) {
                                            s9 = peg$parse_();
                                            if (s9 !== peg$FAILED) {
                                                s10 = peg$parsetableList();
                                                if (s10 !== peg$FAILED) {
                                                    s11 = peg$parse_();
                                                    if (s11 !== peg$FAILED) {
                                                        s1 = [s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11];
                                                        s0 = s1;
                                                    } else {
                                                        peg$currPos = s0;
                                                        s0 = peg$FAILED;
                                                    }
                                                } else {
                                                    peg$currPos = s0;
                                                    s0 = peg$FAILED;
                                                }
                                            } else {
                                                peg$currPos = s0;
                                                s0 = peg$FAILED;
                                            }
                                        } else {
                                            peg$currPos = s0;
                                            s0 = peg$FAILED;
                                        }
                                    } else {
                                        peg$currPos = s0;
                                        s0 = peg$FAILED;
                                    }
                                } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                }
                            } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                            }
                        } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }

            return s0;
        }

        function peg$parsejoinRightSide() {
            var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9;

            s0 = peg$currPos;
            s1 = peg$parse_();
            if (s1 !== peg$FAILED) {
                if (input.charCodeAt(peg$currPos) === 40) {
                    s2 = peg$c12;
                    peg$currPos++;
                } else {
                    s2 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c13); }
                }
                if (s2 !== peg$FAILED) {
                    s3 = peg$parse_();
                    if (s3 !== peg$FAILED) {
                        s4 = peg$parseIdentifier();
                        if (s4 !== peg$FAILED) {
                            s5 = peg$parse_();
                            if (s5 !== peg$FAILED) {
                                s6 = [];
                                s7 = peg$currPos;
                                s8 = peg$currPos;
                                peg$silentFails++;
                                if (input.charCodeAt(peg$currPos) === 41) {
                                    s9 = peg$c14;
                                    peg$currPos++;
                                } else {
                                    s9 = peg$FAILED;
                                    if (peg$silentFails === 0) { peg$fail(peg$c15); }
                                }
                                peg$silentFails--;
                                if (s9 === peg$FAILED) {
                                    s8 = void 0;
                                } else {
                                    peg$currPos = s8;
                                    s8 = peg$FAILED;
                                }
                                if (s8 !== peg$FAILED) {
                                    if (input.length > peg$currPos) {
                                        s9 = input.charAt(peg$currPos);
                                        peg$currPos++;
                                    } else {
                                        s9 = peg$FAILED;
                                        if (peg$silentFails === 0) { peg$fail(peg$c6); }
                                    }
                                    if (s9 !== peg$FAILED) {
                                        s8 = [s8, s9];
                                        s7 = s8;
                                    } else {
                                        peg$currPos = s7;
                                        s7 = peg$FAILED;
                                    }
                                } else {
                                    peg$currPos = s7;
                                    s7 = peg$FAILED;
                                }
                                while (s7 !== peg$FAILED) {
                                    s6.push(s7);
                                    s7 = peg$currPos;
                                    s8 = peg$currPos;
                                    peg$silentFails++;
                                    if (input.charCodeAt(peg$currPos) === 41) {
                                        s9 = peg$c14;
                                        peg$currPos++;
                                    } else {
                                        s9 = peg$FAILED;
                                        if (peg$silentFails === 0) { peg$fail(peg$c15); }
                                    }
                                    peg$silentFails--;
                                    if (s9 === peg$FAILED) {
                                        s8 = void 0;
                                    } else {
                                        peg$currPos = s8;
                                        s8 = peg$FAILED;
                                    }
                                    if (s8 !== peg$FAILED) {
                                        if (input.length > peg$currPos) {
                                            s9 = input.charAt(peg$currPos);
                                            peg$currPos++;
                                        } else {
                                            s9 = peg$FAILED;
                                            if (peg$silentFails === 0) { peg$fail(peg$c6); }
                                        }
                                        if (s9 !== peg$FAILED) {
                                            s8 = [s8, s9];
                                            s7 = s8;
                                        } else {
                                            peg$currPos = s7;
                                            s7 = peg$FAILED;
                                        }
                                    } else {
                                        peg$currPos = s7;
                                        s7 = peg$FAILED;
                                    }
                                }
                                if (s6 !== peg$FAILED) {
                                    if (input.charCodeAt(peg$currPos) === 41) {
                                        s7 = peg$c14;
                                        peg$currPos++;
                                    } else {
                                        s7 = peg$FAILED;
                                        if (peg$silentFails === 0) { peg$fail(peg$c15); }
                                    }
                                    if (s7 !== peg$FAILED) {
                                        peg$savedPos = s0;
                                        s1 = peg$c42(s4, s6);
                                        s0 = s1;
                                    } else {
                                        peg$currPos = s0;
                                        s0 = peg$FAILED;
                                    }
                                } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                }
                            } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                            }
                        } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }
            if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                s1 = peg$parse_();
                if (s1 !== peg$FAILED) {
                    s2 = peg$parseIdentifier();
                    if (s2 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s1 = peg$c43(s2);
                        s0 = s1;
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            }

            return s0;
        }

        function peg$parsetableList() {
            var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9;

            s0 = peg$currPos;
            if (input.charCodeAt(peg$currPos) === 42) {
                s1 = peg$c44;
                peg$currPos++;
            } else {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c45); }
            }
            if (s1 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c46();
            }
            s0 = s1;
            if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                s1 = peg$parse_();
                if (s1 !== peg$FAILED) {
                    s2 = peg$parseIdentifierStar();
                    if (s2 !== peg$FAILED) {
                        s3 = peg$parse_();
                        if (s3 !== peg$FAILED) {
                            peg$savedPos = s0;
                            s1 = peg$c47(s2);
                            s0 = s1;
                        } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
                if (s0 === peg$FAILED) {
                    s0 = peg$currPos;
                    s1 = peg$parse_();
                    if (s1 !== peg$FAILED) {
                        s2 = peg$parseIdentifier();
                        if (s2 !== peg$FAILED) {
                            s3 = peg$parse_();
                            if (s3 !== peg$FAILED) {
                                s4 = [];
                                s5 = peg$currPos;
                                if (input.charCodeAt(peg$currPos) === 44) {
                                    s6 = peg$c48;
                                    peg$currPos++;
                                } else {
                                    s6 = peg$FAILED;
                                    if (peg$silentFails === 0) { peg$fail(peg$c49); }
                                }
                                if (s6 !== peg$FAILED) {
                                    s7 = peg$parse_();
                                    if (s7 !== peg$FAILED) {
                                        s8 = peg$parseIdentifier();
                                        if (s8 !== peg$FAILED) {
                                            s9 = peg$parse_();
                                            if (s9 !== peg$FAILED) {
                                                s6 = [s6, s7, s8, s9];
                                                s5 = s6;
                                            } else {
                                                peg$currPos = s5;
                                                s5 = peg$FAILED;
                                            }
                                        } else {
                                            peg$currPos = s5;
                                            s5 = peg$FAILED;
                                        }
                                    } else {
                                        peg$currPos = s5;
                                        s5 = peg$FAILED;
                                    }
                                } else {
                                    peg$currPos = s5;
                                    s5 = peg$FAILED;
                                }
                                while (s5 !== peg$FAILED) {
                                    s4.push(s5);
                                    s5 = peg$currPos;
                                    if (input.charCodeAt(peg$currPos) === 44) {
                                        s6 = peg$c48;
                                        peg$currPos++;
                                    } else {
                                        s6 = peg$FAILED;
                                        if (peg$silentFails === 0) { peg$fail(peg$c49); }
                                    }
                                    if (s6 !== peg$FAILED) {
                                        s7 = peg$parse_();
                                        if (s7 !== peg$FAILED) {
                                            s8 = peg$parseIdentifier();
                                            if (s8 !== peg$FAILED) {
                                                s9 = peg$parse_();
                                                if (s9 !== peg$FAILED) {
                                                    s6 = [s6, s7, s8, s9];
                                                    s5 = s6;
                                                } else {
                                                    peg$currPos = s5;
                                                    s5 = peg$FAILED;
                                                }
                                            } else {
                                                peg$currPos = s5;
                                                s5 = peg$FAILED;
                                            }
                                        } else {
                                            peg$currPos = s5;
                                            s5 = peg$FAILED;
                                        }
                                    } else {
                                        peg$currPos = s5;
                                        s5 = peg$FAILED;
                                    }
                                }
                                if (s4 !== peg$FAILED) {
                                    s5 = peg$parse_();
                                    if (s5 !== peg$FAILED) {
                                        peg$savedPos = s0;
                                        s1 = peg$c50(s2, s4);
                                        s0 = s1;
                                    } else {
                                        peg$currPos = s0;
                                        s0 = peg$FAILED;
                                    }
                                } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                }
                            } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                            }
                        } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                }
            }

            return s0;
        }

        function peg$parseIdentifier() {
            var s0, s1, s2, s3, s4, s5, s6;

            s0 = peg$currPos;
            s1 = peg$currPos;
            peg$silentFails++;
            s2 = peg$parseKEYWORD();
            peg$silentFails--;
            if (s2 === peg$FAILED) {
                s1 = void 0;
            } else {
                peg$currPos = s1;
                s1 = peg$FAILED;
            }
            if (s1 !== peg$FAILED) {
                s2 = peg$parse_();
                if (s2 !== peg$FAILED) {
                    if (input.charCodeAt(peg$currPos) === 95) {
                        s3 = peg$c51;
                        peg$currPos++;
                    } else {
                        s3 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c52); }
                    }
                    if (s3 === peg$FAILED) {
                        s3 = peg$parseLETTER();
                    }
                    if (s3 !== peg$FAILED) {
                        s4 = [];
                        if (input.charCodeAt(peg$currPos) === 95) {
                            s5 = peg$c51;
                            peg$currPos++;
                        } else {
                            s5 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c52); }
                        }
                        if (s5 === peg$FAILED) {
                            s5 = peg$parseLETTER();
                            if (s5 === peg$FAILED) {
                                s5 = peg$parseDIGIT();
                            }
                        }
                        while (s5 !== peg$FAILED) {
                            s4.push(s5);
                            if (input.charCodeAt(peg$currPos) === 95) {
                                s5 = peg$c51;
                                peg$currPos++;
                            } else {
                                s5 = peg$FAILED;
                                if (peg$silentFails === 0) { peg$fail(peg$c52); }
                            }
                            if (s5 === peg$FAILED) {
                                s5 = peg$parseLETTER();
                                if (s5 === peg$FAILED) {
                                    s5 = peg$parseDIGIT();
                                }
                            }
                        }
                        if (s4 !== peg$FAILED) {
                            s5 = peg$parse_();
                            if (s5 !== peg$FAILED) {
                                peg$savedPos = s0;
                                s1 = peg$c53(s3, s4);
                                s0 = s1;
                            } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                            }
                        } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }
            if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                s1 = peg$currPos;
                peg$silentFails++;
                s2 = peg$parseKEYWORD();
                peg$silentFails--;
                if (s2 === peg$FAILED) {
                    s1 = void 0;
                } else {
                    peg$currPos = s1;
                    s1 = peg$FAILED;
                }
                if (s1 !== peg$FAILED) {
                    s2 = peg$parse_();
                    if (s2 !== peg$FAILED) {
                        s3 = [];
                        s4 = peg$parseDIGIT();
                        if (s4 !== peg$FAILED) {
                            while (s4 !== peg$FAILED) {
                                s3.push(s4);
                                s4 = peg$parseDIGIT();
                            }
                        } else {
                            s3 = peg$FAILED;
                        }
                        if (s3 !== peg$FAILED) {
                            s4 = peg$parseLETTER();
                            if (s4 === peg$FAILED) {
                                s4 = peg$parseDIGIT();
                                if (s4 === peg$FAILED) {
                                    if (input.charCodeAt(peg$currPos) === 95) {
                                        s4 = peg$c51;
                                        peg$currPos++;
                                    } else {
                                        s4 = peg$FAILED;
                                        if (peg$silentFails === 0) { peg$fail(peg$c52); }
                                    }
                                }
                            }
                            if (s4 !== peg$FAILED) {
                                s5 = [];
                                if (input.charCodeAt(peg$currPos) === 95) {
                                    s6 = peg$c51;
                                    peg$currPos++;
                                } else {
                                    s6 = peg$FAILED;
                                    if (peg$silentFails === 0) { peg$fail(peg$c52); }
                                }
                                if (s6 === peg$FAILED) {
                                    s6 = peg$parseLETTER();
                                    if (s6 === peg$FAILED) {
                                        s6 = peg$parseDIGIT();
                                    }
                                }
                                while (s6 !== peg$FAILED) {
                                    s5.push(s6);
                                    if (input.charCodeAt(peg$currPos) === 95) {
                                        s6 = peg$c51;
                                        peg$currPos++;
                                    } else {
                                        s6 = peg$FAILED;
                                        if (peg$silentFails === 0) { peg$fail(peg$c52); }
                                    }
                                    if (s6 === peg$FAILED) {
                                        s6 = peg$parseLETTER();
                                        if (s6 === peg$FAILED) {
                                            s6 = peg$parseDIGIT();
                                        }
                                    }
                                }
                                if (s5 !== peg$FAILED) {
                                    s6 = peg$parse_();
                                    if (s6 !== peg$FAILED) {
                                        peg$savedPos = s0;
                                        s1 = peg$c54(s3, s4, s5);
                                        s0 = s1;
                                    } else {
                                        peg$currPos = s0;
                                        s0 = peg$FAILED;
                                    }
                                } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                }
                            } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                            }
                        } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            }

            return s0;
        }

        function peg$parseIdentifierStar() {
            var s0, s1, s2, s3, s4;

            s0 = peg$currPos;
            s1 = peg$parse_();
            if (s1 !== peg$FAILED) {
                s2 = peg$parseIdentifier();
                if (s2 !== peg$FAILED) {
                    if (input.charCodeAt(peg$currPos) === 42) {
                        s3 = peg$c44;
                        peg$currPos++;
                    } else {
                        s3 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c45); }
                    }
                    if (s3 !== peg$FAILED) {
                        s4 = peg$parse_();
                        if (s4 !== peg$FAILED) {
                            peg$savedPos = s0;
                            s1 = peg$c55(s2);
                            s0 = s1;
                        } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }

            return s0;
        }

        function peg$parseAlphaLiteral() {
            var s0, s1, s2, s3, s4;

            s0 = peg$currPos;
            s1 = peg$parse_();
            if (s1 !== peg$FAILED) {
                if (input.charCodeAt(peg$currPos) === 95) {
                    s2 = peg$c51;
                    peg$currPos++;
                } else {
                    s2 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c52); }
                }
                if (s2 === peg$FAILED) {
                    s2 = peg$parseLETTER();
                }
                if (s2 !== peg$FAILED) {
                    s3 = [];
                    if (input.charCodeAt(peg$currPos) === 95) {
                        s4 = peg$c51;
                        peg$currPos++;
                    } else {
                        s4 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c52); }
                    }
                    if (s4 === peg$FAILED) {
                        s4 = peg$parseLETTER();
                    }
                    while (s4 !== peg$FAILED) {
                        s3.push(s4);
                        if (input.charCodeAt(peg$currPos) === 95) {
                            s4 = peg$c51;
                            peg$currPos++;
                        } else {
                            s4 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c52); }
                        }
                        if (s4 === peg$FAILED) {
                            s4 = peg$parseLETTER();
                        }
                    }
                    if (s3 !== peg$FAILED) {
                        s4 = peg$parse_();
                        if (s4 !== peg$FAILED) {
                            peg$savedPos = s0;
                            s1 = peg$c56(s2, s3);
                            s0 = s1;
                        } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }

            return s0;
        }

        function peg$parseJoinToken() {
            var s0;

            if (input.substr(peg$currPos, 4) === peg$c57) {
                s0 = peg$c57;
                peg$currPos += 4;
            } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c58); }
            }

            return s0;
        }

        function peg$parseLetToken() {
            var s0;

            if (input.substr(peg$currPos, 3) === peg$c59) {
                s0 = peg$c59;
                peg$currPos += 3;
            } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c60); }
            }

            return s0;
        }

        function peg$parseProjectToken() {
            var s0;

            if (input.substr(peg$currPos, 7) === peg$c61) {
                s0 = peg$c61;
                peg$currPos += 7;
            } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c62); }
            }

            return s0;
        }

        function peg$parseRestrictToken() {
            var s0;

            if (input.substr(peg$currPos, 8) === peg$c63) {
                s0 = peg$c63;
                peg$currPos += 8;
            } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c64); }
            }

            return s0;
        }

        function peg$parseStringToken() {
            var s0;

            if (input.substr(peg$currPos, 6) === peg$c65) {
                s0 = peg$c65;
                peg$currPos += 6;
            } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c66); }
            }

            return s0;
        }

        function peg$parseUnionToken() {
            var s0;

            if (input.substr(peg$currPos, 5) === peg$c67) {
                s0 = peg$c67;
                peg$currPos += 5;
            } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c68); }
            }

            return s0;
        }

        function peg$parseWhereToken() {
            var s0;

            if (input.substr(peg$currPos, 5) === peg$c33) {
                s0 = peg$c33;
                peg$currPos += 5;
            } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c34); }
            }

            return s0;
        }

        function peg$parseViewToken() {
            var s0;

            if (input.substr(peg$currPos, 4) === peg$c69) {
                s0 = peg$c69;
                peg$currPos += 4;
            } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c70); }
            }

            return s0;
        }

        function peg$parseKEYWORD() {
            var s0, s1, s2, s3;

            s0 = peg$currPos;
            s1 = peg$parse_();
            if (s1 !== peg$FAILED) {
                s2 = peg$currPos;
                s3 = peg$parseJoinToken();
                if (s3 === peg$FAILED) {
                    s3 = peg$parseLetToken();
                    if (s3 === peg$FAILED) {
                        s3 = peg$parseProjectToken();
                        if (s3 === peg$FAILED) {
                            s3 = peg$parseRestrictToken();
                            if (s3 === peg$FAILED) {
                                s3 = peg$parseStringToken();
                                if (s3 === peg$FAILED) {
                                    s3 = peg$parseUnionToken();
                                    if (s3 === peg$FAILED) {
                                        s3 = peg$parseWhereToken();
                                        if (s3 === peg$FAILED) {
                                            s3 = peg$parseViewToken();
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                if (s3 !== peg$FAILED) {
                    s2 = input.substring(s2, peg$currPos);
                } else {
                    s2 = s3;
                }
                if (s2 !== peg$FAILED) {
                    s3 = peg$parse_();
                    if (s3 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s1 = peg$c71(s2);
                        s0 = s1;
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }

            return s0;
        }

        function peg$parseBOOLEANLITERAL() {
            var s0, s1, s2, s3;

            s0 = peg$currPos;
            s1 = peg$parse_();
            if (s1 !== peg$FAILED) {
                if (input.substr(peg$currPos, 4) === peg$c72) {
                    s2 = peg$c72;
                    peg$currPos += 4;
                } else {
                    s2 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c73); }
                }
                if (s2 === peg$FAILED) {
                    if (input.substr(peg$currPos, 4) === peg$c74) {
                        s2 = peg$c74;
                        peg$currPos += 4;
                    } else {
                        s2 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c75); }
                    }
                    if (s2 === peg$FAILED) {
                        if (input.substr(peg$currPos, 4) === peg$c76) {
                            s2 = peg$c76;
                            peg$currPos += 4;
                        } else {
                            s2 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c77); }
                        }
                    }
                }
                if (s2 !== peg$FAILED) {
                    s3 = peg$parse_();
                    if (s3 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s1 = peg$c78();
                        s0 = s1;
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }
            if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                s1 = peg$parse_();
                if (s1 !== peg$FAILED) {
                    if (input.substr(peg$currPos, 5) === peg$c79) {
                        s2 = peg$c79;
                        peg$currPos += 5;
                    } else {
                        s2 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c80); }
                    }
                    if (s2 === peg$FAILED) {
                        if (input.substr(peg$currPos, 5) === peg$c81) {
                            s2 = peg$c81;
                            peg$currPos += 5;
                        } else {
                            s2 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c82); }
                        }
                        if (s2 === peg$FAILED) {
                            if (input.substr(peg$currPos, 5) === peg$c83) {
                                s2 = peg$c83;
                                peg$currPos += 5;
                            } else {
                                s2 = peg$FAILED;
                                if (peg$silentFails === 0) { peg$fail(peg$c84); }
                            }
                        }
                    }
                    if (s2 !== peg$FAILED) {
                        s3 = peg$parse_();
                        if (s3 !== peg$FAILED) {
                            peg$savedPos = s0;
                            s1 = peg$c85();
                            s0 = s1;
                        } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            }

            return s0;
        }

        function peg$parseSTRINGLITERAL() {
            var s0, s1, s2, s3, s4, s5;

            s0 = peg$currPos;
            s1 = peg$parse_();
            if (s1 !== peg$FAILED) {
                if (input.charCodeAt(peg$currPos) === 34) {
                    s2 = peg$c86;
                    peg$currPos++;
                } else {
                    s2 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c87); }
                }
                if (s2 !== peg$FAILED) {
                    s3 = peg$currPos;
                    s4 = [];
                    s5 = peg$parseDoubleStringCharacter();
                    while (s5 !== peg$FAILED) {
                        s4.push(s5);
                        s5 = peg$parseDoubleStringCharacter();
                    }
                    if (s4 !== peg$FAILED) {
                        s3 = input.substring(s3, peg$currPos);
                    } else {
                        s3 = s4;
                    }
                    if (s3 !== peg$FAILED) {
                        if (input.charCodeAt(peg$currPos) === 34) {
                            s4 = peg$c86;
                            peg$currPos++;
                        } else {
                            s4 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c87); }
                        }
                        if (s4 !== peg$FAILED) {
                            s5 = peg$parse_();
                            if (s5 !== peg$FAILED) {
                                peg$savedPos = s0;
                                s1 = peg$c88(s3);
                                s0 = s1;
                            } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                            }
                        } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }
            if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                s1 = peg$parse_();
                if (s1 !== peg$FAILED) {
                    if (input.charCodeAt(peg$currPos) === 39) {
                        s2 = peg$c89;
                        peg$currPos++;
                    } else {
                        s2 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c90); }
                    }
                    if (s2 !== peg$FAILED) {
                        s3 = peg$currPos;
                        s4 = [];
                        s5 = peg$parseSingleStringCharacter();
                        while (s5 !== peg$FAILED) {
                            s4.push(s5);
                            s5 = peg$parseSingleStringCharacter();
                        }
                        if (s4 !== peg$FAILED) {
                            s3 = input.substring(s3, peg$currPos);
                        } else {
                            s3 = s4;
                        }
                        if (s3 !== peg$FAILED) {
                            if (input.charCodeAt(peg$currPos) === 39) {
                                s4 = peg$c89;
                                peg$currPos++;
                            } else {
                                s4 = peg$FAILED;
                                if (peg$silentFails === 0) { peg$fail(peg$c90); }
                            }
                            if (s4 !== peg$FAILED) {
                                s5 = peg$parse_();
                                if (s5 !== peg$FAILED) {
                                    peg$savedPos = s0;
                                    s1 = peg$c88(s3);
                                    s0 = s1;
                                } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                }
                            } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                            }
                        } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            }

            return s0;
        }

        function peg$parseDoubleStringCharacter() {
            var s0, s1, s2;

            s0 = peg$currPos;
            s1 = peg$currPos;
            peg$silentFails++;
            if (input.charCodeAt(peg$currPos) === 34) {
                s2 = peg$c86;
                peg$currPos++;
            } else {
                s2 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c87); }
            }
            if (s2 === peg$FAILED) {
                if (input.charCodeAt(peg$currPos) === 92) {
                    s2 = peg$c91;
                    peg$currPos++;
                } else {
                    s2 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c92); }
                }
                if (s2 === peg$FAILED) {
                    s2 = peg$parseLINETERMINATOR();
                }
            }
            peg$silentFails--;
            if (s2 === peg$FAILED) {
                s1 = void 0;
            } else {
                peg$currPos = s1;
                s1 = peg$FAILED;
            }
            if (s1 !== peg$FAILED) {
                s2 = peg$parseAnyCharacter();
                if (s2 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c93();
                    s0 = s1;
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }
            if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                if (input.charCodeAt(peg$currPos) === 92) {
                    s1 = peg$c91;
                    peg$currPos++;
                } else {
                    s1 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c92); }
                }
                if (s1 !== peg$FAILED) {
                    s2 = peg$parseEscapeSequence();
                    if (s2 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s1 = peg$c94(s2);
                        s0 = s1;
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            }

            return s0;
        }

        function peg$parseSingleStringCharacter() {
            var s0, s1, s2;

            s0 = peg$currPos;
            s1 = peg$currPos;
            peg$silentFails++;
            if (input.charCodeAt(peg$currPos) === 39) {
                s2 = peg$c89;
                peg$currPos++;
            } else {
                s2 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c90); }
            }
            if (s2 === peg$FAILED) {
                if (input.charCodeAt(peg$currPos) === 92) {
                    s2 = peg$c91;
                    peg$currPos++;
                } else {
                    s2 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c92); }
                }
                if (s2 === peg$FAILED) {
                    s2 = peg$parseLINETERMINATOR();
                }
            }
            peg$silentFails--;
            if (s2 === peg$FAILED) {
                s1 = void 0;
            } else {
                peg$currPos = s1;
                s1 = peg$FAILED;
            }
            if (s1 !== peg$FAILED) {
                s2 = peg$parseAnyCharacter();
                if (s2 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c93();
                    s0 = s1;
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }
            if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                if (input.charCodeAt(peg$currPos) === 92) {
                    s1 = peg$c91;
                    peg$currPos++;
                } else {
                    s1 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c92); }
                }
                if (s1 !== peg$FAILED) {
                    s2 = peg$parseEscapeSequence();
                    if (s2 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s1 = peg$c94(s2);
                        s0 = s1;
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            }

            return s0;
        }

        function peg$parseAnyCharacter() {
            var s0;

            if (input.length > peg$currPos) {
                s0 = input.charAt(peg$currPos);
                peg$currPos++;
            } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c6); }
            }

            return s0;
        }

        function peg$parseEscapeSequence() {
            var s0, s1, s2, s3;

            if (input.charCodeAt(peg$currPos) === 39) {
                s0 = peg$c89;
                peg$currPos++;
            } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c90); }
            }
            if (s0 === peg$FAILED) {
                if (input.charCodeAt(peg$currPos) === 34) {
                    s0 = peg$c86;
                    peg$currPos++;
                } else {
                    s0 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c87); }
                }
                if (s0 === peg$FAILED) {
                    if (input.charCodeAt(peg$currPos) === 92) {
                        s0 = peg$c91;
                        peg$currPos++;
                    } else {
                        s0 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c92); }
                    }
                    if (s0 === peg$FAILED) {
                        if (input.charCodeAt(peg$currPos) === 97) {
                            s0 = peg$c95;
                            peg$currPos++;
                        } else {
                            s0 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c96); }
                        }
                        if (s0 === peg$FAILED) {
                            if (input.charCodeAt(peg$currPos) === 98) {
                                s0 = peg$c97;
                                peg$currPos++;
                            } else {
                                s0 = peg$FAILED;
                                if (peg$silentFails === 0) { peg$fail(peg$c98); }
                            }
                            if (s0 === peg$FAILED) {
                                if (input.charCodeAt(peg$currPos) === 102) {
                                    s0 = peg$c99;
                                    peg$currPos++;
                                } else {
                                    s0 = peg$FAILED;
                                    if (peg$silentFails === 0) { peg$fail(peg$c100); }
                                }
                                if (s0 === peg$FAILED) {
                                    if (input.charCodeAt(peg$currPos) === 110) {
                                        s0 = peg$c101;
                                        peg$currPos++;
                                    } else {
                                        s0 = peg$FAILED;
                                        if (peg$silentFails === 0) { peg$fail(peg$c102); }
                                    }
                                    if (s0 === peg$FAILED) {
                                        if (input.charCodeAt(peg$currPos) === 114) {
                                            s0 = peg$c103;
                                            peg$currPos++;
                                        } else {
                                            s0 = peg$FAILED;
                                            if (peg$silentFails === 0) { peg$fail(peg$c104); }
                                        }
                                        if (s0 === peg$FAILED) {
                                            if (input.charCodeAt(peg$currPos) === 116) {
                                                s0 = peg$c105;
                                                peg$currPos++;
                                            } else {
                                                s0 = peg$FAILED;
                                                if (peg$silentFails === 0) { peg$fail(peg$c106); }
                                            }
                                            if (s0 === peg$FAILED) {
                                                if (input.charCodeAt(peg$currPos) === 117) {
                                                    s0 = peg$c107;
                                                    peg$currPos++;
                                                } else {
                                                    s0 = peg$FAILED;
                                                    if (peg$silentFails === 0) { peg$fail(peg$c108); }
                                                }
                                                if (s0 === peg$FAILED) {
                                                    if (input.charCodeAt(peg$currPos) === 85) {
                                                        s0 = peg$c109;
                                                        peg$currPos++;
                                                    } else {
                                                        s0 = peg$FAILED;
                                                        if (peg$silentFails === 0) { peg$fail(peg$c110); }
                                                    }
                                                    if (s0 === peg$FAILED) {
                                                        if (input.charCodeAt(peg$currPos) === 120) {
                                                            s0 = peg$c111;
                                                            peg$currPos++;
                                                        } else {
                                                            s0 = peg$FAILED;
                                                            if (peg$silentFails === 0) { peg$fail(peg$c112); }
                                                        }
                                                        if (s0 === peg$FAILED) {
                                                            if (input.charCodeAt(peg$currPos) === 118) {
                                                                s0 = peg$c113;
                                                                peg$currPos++;
                                                            } else {
                                                                s0 = peg$FAILED;
                                                                if (peg$silentFails === 0) { peg$fail(peg$c114); }
                                                            }
                                                            if (s0 === peg$FAILED) {
                                                                s0 = peg$currPos;
                                                                if (peg$c115.test(input.charAt(peg$currPos))) {
                                                                    s1 = input.charAt(peg$currPos);
                                                                    peg$currPos++;
                                                                } else {
                                                                    s1 = peg$FAILED;
                                                                    if (peg$silentFails === 0) { peg$fail(peg$c116); }
                                                                }
                                                                if (s1 !== peg$FAILED) {
                                                                    if (peg$c117.test(input.charAt(peg$currPos))) {
                                                                        s2 = input.charAt(peg$currPos);
                                                                        peg$currPos++;
                                                                    } else {
                                                                        s2 = peg$FAILED;
                                                                        if (peg$silentFails === 0) { peg$fail(peg$c118); }
                                                                    }
                                                                    if (s2 !== peg$FAILED) {
                                                                        if (peg$c117.test(input.charAt(peg$currPos))) {
                                                                            s3 = input.charAt(peg$currPos);
                                                                            peg$currPos++;
                                                                        } else {
                                                                            s3 = peg$FAILED;
                                                                            if (peg$silentFails === 0) { peg$fail(peg$c118); }
                                                                        }
                                                                        if (s3 !== peg$FAILED) {
                                                                            s1 = [s1, s2, s3];
                                                                            s0 = s1;
                                                                        } else {
                                                                            peg$currPos = s0;
                                                                            s0 = peg$FAILED;
                                                                        }
                                                                    } else {
                                                                        peg$currPos = s0;
                                                                        s0 = peg$FAILED;
                                                                    }
                                                                } else {
                                                                    peg$currPos = s0;
                                                                    s0 = peg$FAILED;
                                                                }
                                                                if (s0 === peg$FAILED) {
                                                                    s0 = peg$currPos;
                                                                    if (peg$c117.test(input.charAt(peg$currPos))) {
                                                                        s1 = input.charAt(peg$currPos);
                                                                        peg$currPos++;
                                                                    } else {
                                                                        s1 = peg$FAILED;
                                                                        if (peg$silentFails === 0) { peg$fail(peg$c118); }
                                                                    }
                                                                    if (s1 !== peg$FAILED) {
                                                                        if (peg$c117.test(input.charAt(peg$currPos))) {
                                                                            s2 = input.charAt(peg$currPos);
                                                                            peg$currPos++;
                                                                        } else {
                                                                            s2 = peg$FAILED;
                                                                            if (peg$silentFails === 0) { peg$fail(peg$c118); }
                                                                        }
                                                                        if (s2 !== peg$FAILED) {
                                                                            s1 = [s1, s2];
                                                                            s0 = s1;
                                                                        } else {
                                                                            peg$currPos = s0;
                                                                            s0 = peg$FAILED;
                                                                        }
                                                                    } else {
                                                                        peg$currPos = s0;
                                                                        s0 = peg$FAILED;
                                                                    }
                                                                    if (s0 === peg$FAILED) {
                                                                        if (peg$c119.test(input.charAt(peg$currPos))) {
                                                                            s0 = input.charAt(peg$currPos);
                                                                            peg$currPos++;
                                                                        } else {
                                                                            s0 = peg$FAILED;
                                                                            if (peg$silentFails === 0) { peg$fail(peg$c120); }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            return s0;
        }

        function peg$parseLineContinuation() {
            var s0, s1;

            s0 = peg$currPos;
            if (input.charCodeAt(peg$currPos) === 10) {
                s1 = peg$c121;
                peg$currPos++;
            } else {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c122); }
            }
            if (s1 === peg$FAILED) {
                if (input.substr(peg$currPos, 2) === peg$c123) {
                    s1 = peg$c123;
                    peg$currPos += 2;
                } else {
                    s1 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c124); }
                }
                if (s1 === peg$FAILED) {
                    if (input.charCodeAt(peg$currPos) === 13) {
                        s1 = peg$c125;
                        peg$currPos++;
                    } else {
                        s1 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c126); }
                    }
                }
            }
            if (s1 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c127();
            }
            s0 = s1;

            return s0;
        }

        function peg$parseCharacter() {
            var s0;

            s0 = peg$parseLETTER();
            if (s0 === peg$FAILED) {
                s0 = peg$parseDIGIT();
            }

            return s0;
        }

        function peg$parseDIGIT() {
            var s0;

            if (peg$c128.test(input.charAt(peg$currPos))) {
                s0 = input.charAt(peg$currPos);
                peg$currPos++;
            } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c129); }
            }

            return s0;
        }

        function peg$parseLETTER() {
            var s0;

            if (peg$c130.test(input.charAt(peg$currPos))) {
                s0 = input.charAt(peg$currPos);
                peg$currPos++;
            } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c131); }
            }
            if (s0 === peg$FAILED) {
                if (peg$c132.test(input.charAt(peg$currPos))) {
                    s0 = input.charAt(peg$currPos);
                    peg$currPos++;
                } else {
                    s0 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c133); }
                }
            }

            return s0;
        }

        function peg$parseLINETERMINATOR() {
            var s0;

            if (peg$c134.test(input.charAt(peg$currPos))) {
                s0 = input.charAt(peg$currPos);
                peg$currPos++;
            } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c135); }
            }

            return s0;
        }

        function peg$parseINTEGER() {
            var s0, s1, s2, s3, s4;

            s0 = peg$currPos;
            s1 = peg$parse_();
            if (s1 !== peg$FAILED) {
                if (peg$c136.test(input.charAt(peg$currPos))) {
                    s2 = input.charAt(peg$currPos);
                    peg$currPos++;
                } else {
                    s2 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c137); }
                }
                if (s2 !== peg$FAILED) {
                    s3 = [];
                    s4 = peg$parseDIGIT();
                    while (s4 !== peg$FAILED) {
                        s3.push(s4);
                        s4 = peg$parseDIGIT();
                    }
                    if (s3 !== peg$FAILED) {
                        s4 = peg$parse_();
                        if (s4 !== peg$FAILED) {
                            peg$savedPos = s0;
                            s1 = peg$c138(s2, s3);
                            s0 = s1;
                        } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }

            return s0;
        }

        function peg$parseNonIntegerNumber() {
            var s0, s1, s2, s3, s4, s5, s6, s7;

            s0 = peg$currPos;
            s1 = peg$parse_();
            if (s1 !== peg$FAILED) {
                s2 = peg$currPos;
                if (input.charCodeAt(peg$currPos) === 43) {
                    s3 = peg$c139;
                    peg$currPos++;
                } else {
                    s3 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c140); }
                }
                if (s3 === peg$FAILED) {
                    if (input.charCodeAt(peg$currPos) === 45) {
                        s3 = peg$c141;
                        peg$currPos++;
                    } else {
                        s3 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c142); }
                    }
                }
                if (s3 === peg$FAILED) {
                    s3 = null;
                }
                if (s3 !== peg$FAILED) {
                    s2 = input.substring(s2, peg$currPos);
                } else {
                    s2 = s3;
                }
                if (s2 !== peg$FAILED) {
                    s3 = [];
                    s4 = peg$parseDIGIT();
                    if (s4 !== peg$FAILED) {
                        while (s4 !== peg$FAILED) {
                            s3.push(s4);
                            s4 = peg$parseDIGIT();
                        }
                    } else {
                        s3 = peg$FAILED;
                    }
                    if (s3 !== peg$FAILED) {
                        if (input.charCodeAt(peg$currPos) === 46) {
                            s4 = peg$c143;
                            peg$currPos++;
                        } else {
                            s4 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c144); }
                        }
                        if (s4 !== peg$FAILED) {
                            s5 = [];
                            s6 = peg$parseDIGIT();
                            while (s6 !== peg$FAILED) {
                                s5.push(s6);
                                s6 = peg$parseDIGIT();
                            }
                            if (s5 !== peg$FAILED) {
                                s6 = peg$currPos;
                                s7 = peg$parseExponent();
                                if (s7 === peg$FAILED) {
                                    s7 = null;
                                }
                                if (s7 !== peg$FAILED) {
                                    s6 = input.substring(s6, peg$currPos);
                                } else {
                                    s6 = s7;
                                }
                                if (s6 !== peg$FAILED) {
                                    s7 = peg$parse_();
                                    if (s7 !== peg$FAILED) {
                                        peg$savedPos = s0;
                                        s1 = peg$c145(s2, s3, s5, s6);
                                        s0 = s1;
                                    } else {
                                        peg$currPos = s0;
                                        s0 = peg$FAILED;
                                    }
                                } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                }
                            } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                            }
                        } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }
            if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                s1 = peg$parse_();
                if (s1 !== peg$FAILED) {
                    s2 = peg$currPos;
                    if (input.charCodeAt(peg$currPos) === 43) {
                        s3 = peg$c139;
                        peg$currPos++;
                    } else {
                        s3 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c140); }
                    }
                    if (s3 === peg$FAILED) {
                        if (input.charCodeAt(peg$currPos) === 45) {
                            s3 = peg$c141;
                            peg$currPos++;
                        } else {
                            s3 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c142); }
                        }
                    }
                    if (s3 === peg$FAILED) {
                        s3 = null;
                    }
                    if (s3 !== peg$FAILED) {
                        s2 = input.substring(s2, peg$currPos);
                    } else {
                        s2 = s3;
                    }
                    if (s2 !== peg$FAILED) {
                        s3 = [];
                        s4 = peg$parseDIGIT();
                        if (s4 !== peg$FAILED) {
                            while (s4 !== peg$FAILED) {
                                s3.push(s4);
                                s4 = peg$parseDIGIT();
                            }
                        } else {
                            s3 = peg$FAILED;
                        }
                        if (s3 !== peg$FAILED) {
                            s4 = peg$currPos;
                            s5 = peg$parseExponent();
                            if (s5 !== peg$FAILED) {
                                s4 = input.substring(s4, peg$currPos);
                            } else {
                                s4 = s5;
                            }
                            if (s4 !== peg$FAILED) {
                                s5 = peg$parse_();
                                if (s5 !== peg$FAILED) {
                                    peg$savedPos = s0;
                                    s1 = peg$c146(s2, s3, s4);
                                    s0 = s1;
                                } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                }
                            } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                            }
                        } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            }

            return s0;
        }

        function peg$parseExponent() {
            var s0, s1, s2, s3, s4;

            s0 = peg$currPos;
            if (input.charCodeAt(peg$currPos) === 101) {
                s1 = peg$c147;
                peg$currPos++;
            } else {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c148); }
            }
            if (s1 === peg$FAILED) {
                if (input.charCodeAt(peg$currPos) === 69) {
                    s1 = peg$c149;
                    peg$currPos++;
                } else {
                    s1 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c150); }
                }
            }
            if (s1 !== peg$FAILED) {
                if (input.charCodeAt(peg$currPos) === 43) {
                    s2 = peg$c139;
                    peg$currPos++;
                } else {
                    s2 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c140); }
                }
                if (s2 === peg$FAILED) {
                    if (input.charCodeAt(peg$currPos) === 45) {
                        s2 = peg$c141;
                        peg$currPos++;
                    } else {
                        s2 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c142); }
                    }
                }
                if (s2 === peg$FAILED) {
                    s2 = null;
                }
                if (s2 !== peg$FAILED) {
                    s3 = [];
                    s4 = peg$parseDIGIT();
                    if (s4 !== peg$FAILED) {
                        while (s4 !== peg$FAILED) {
                            s3.push(s4);
                            s4 = peg$parseDIGIT();
                        }
                    } else {
                        s3 = peg$FAILED;
                    }
                    if (s3 !== peg$FAILED) {
                        s1 = [s1, s2, s3];
                        s0 = s1;
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }

            return s0;
        }

        function peg$parseEOF() {
            var s0, s1;

            s0 = peg$currPos;
            peg$silentFails++;
            if (input.length > peg$currPos) {
                s1 = input.charAt(peg$currPos);
                peg$currPos++;
            } else {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c6); }
            }
            peg$silentFails--;
            if (s1 === peg$FAILED) {
                s0 = void 0;
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }

            return s0;
        }

        function peg$parse_() {
            var s0, s1;

            peg$silentFails++;
            s0 = [];
            if (peg$c152.test(input.charAt(peg$currPos))) {
                s1 = input.charAt(peg$currPos);
                peg$currPos++;
            } else {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c153); }
            }
            while (s1 !== peg$FAILED) {
                s0.push(s1);
                if (peg$c152.test(input.charAt(peg$currPos))) {
                    s1 = input.charAt(peg$currPos);
                    peg$currPos++;
                } else {
                    s1 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c153); }
                }
            }
            peg$silentFails--;
            if (s0 === peg$FAILED) {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c151); }
            }

            return s0;
        }


        var tableStack = [];

        var foundIdentifiers = []; 	// global list of identifies found in the query, including tables
        var foundTables = []; 		// global list of table names found in the query
        var foundOthers = [];

        function makeCharArray(parsedAny) {
            var result = [], i;
            for (i = 0; i < parsedAny.length; i++) {
                if (!(parsedAny[i][1] === "\r" || parsedAny[i][1] === "\n" || parsedAny[i][1] === "\r\n")) { result.push(parsedAny[i][1]) }
            };
            return result;
        }

        function joinCharArray(charArray) {
            var result = [], i;
            for (i = 0; i < charArray.length; i++) {
                if (!(charArray[i] === "\r" || charArray[i] === "\n" || charArray[i] === "\r\n")) { result.push(charArray[i]) }
            };
            return result.join('');
        }


        peg$result = peg$startRuleFunction();

        if (peg$result !== peg$FAILED && peg$currPos === input.length) {
            return peg$result;
        } else {
            if (peg$result !== peg$FAILED && peg$currPos < input.length) {
                peg$fail(peg$endExpectation());
            }

            throw peg$buildStructuredError(
              peg$maxFailExpected,
              peg$maxFailPos < input.length ? input.charAt(peg$maxFailPos) : null,
              peg$maxFailPos < input.length
                ? peg$computeLocation(peg$maxFailPos, peg$maxFailPos + 1)
                : peg$computeLocation(peg$maxFailPos, peg$maxFailPos)
            );
        }
    }

    return {
        SyntaxError: peg$SyntaxError,
        parse: peg$parse
    };
})();

export default kqlParser