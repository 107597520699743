import constants from 'utils/constants';
import csvExporter, { CsvOptions } from 'utils/csvExporter';
import notifier from 'utils/notifier';
import workspacesApi from '../api/workspacesApi';

function ConvertIndetifierToParameterType(identifier: string) {
  if (identifier === constants.DataSourceTypes.KUSTO) {
    return constants.DataSourceParamUiTypes.KUSTO;
  } else if (identifier === constants.DataSourceTypes.APPLICATIONINSIGHTS) {
    return constants.DataSourceParamUiTypes.APPLICATIONINSIGHTS;
  } else if (identifier === constants.DataSourceTypes.LOGANALYTICS) {
    return constants.DataSourceParamUiTypes.LOGANALYTICS;
  } else if (identifier === constants.DataConnectionParamTypes.TENANT) {
    return constants.DataConnectionParamUiTypes.TENANT;
  } else if (identifier === constants.DataConnectionParamTypes.APPLICATIONID) {
    return constants.DataConnectionParamUiTypes.APPLICATIONID;
  }

  return null;
}

/**
 * Export workspace orchestration parameters in form of csv
 * @param workspaceId Id of the workspace that containers the parameters you want to export
 */
export const exportWorkspaceParameters = (workspaceId: string) => {
  let columns = ['Type', 'LowSide Value', 'HighSide Value'];
  let columnsWithId = columns.map((column) => {
    return { id: column, name: column };
  });

  workspacesApi
    .getWorkspaceExportParameters(workspaceId)
    .then((parameters) => {
      let rows: Object[] = [];
      parameters.forEach(
        (parameter: { identifier: { toString: () => string }; value: any }) => {
          // Back-end return enum version of type(identifier) which needs to be converted to value used in CSV (same as in UI), e.g. ApplicationInsight -> Application Insight
          let type = ConvertIndetifierToParameterType(
            parameter.identifier.toString()
          );
          if (type !== null) {
            rows.push({
              Type: type,
              'LowSide Value': parameter.value,
              'HighSide Value': '',
            });
          }
        }
      );
      // Value contains non-alphabetic and non-numeric characters so we need to set quoteValues to false
      let csvOptions: CsvOptions = {
        separator: ',',
        quoteValues: false,
      };

      let fileName = 'workspace_' + workspaceId + '_parameters.csv';

      return csvExporter.exportAsCsv(fileName, columnsWithId, rows, csvOptions);
    })
    .catch((err) => {
      notifier.error(err);
    });
};
