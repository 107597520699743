/* eslint-disable no-lone-blocks */
import { ElxDropdown, ElxIconButton, ElxTextField } from '@elixir/components';
import { Stack, Toggle } from '@fluentui/react';
import { Editor } from 'components/editor';
import {
  getAllDataSourcePlugins,
  getDataSourcePlugin,
} from 'features/dataSources/registry';
import { Action, Activity } from 'features/orchestrator/models/job';
import { JobAccordian } from 'features/orchestrator/utils/jobAccordian';
import { ProjectDataConnection } from 'features/workspaces/models/project';
import _ from 'lodash';
import { useState } from 'react';
import { orchestratorConstants } from 'utils/constants';
import { LensLabel } from 'utils/lensLabel';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import validationUtility from 'utils/validationUtility';
import EditorFull from '../editorFull';
import { JobActivityDataValidationActions } from './JobActivityDataValidationActions';
import { JobActivityDataValidationValidations } from './JobActivityDataValidationValidations';
import { alignEnds, groupBorder } from './JobAuthorStyles';
import { JobEndpoint } from './JobEndpoint';

const Modes = {
  Validations: 'Validations',
  ValidationQuery: 'ValidationQuery',
  CosmosValidation: 'CosmosValidation',
};

const validationOptions = [
  { key: Modes.Validations, text: 'Validation' },
  { key: Modes.ValidationQuery, text: 'Validation Query' },
];

const inputEndpointTypes = _(getAllDataSourcePlugins())
  .map((dsp) => dsp.dataSourceClient)
  .map((dsc) => dsc.orchestration?.dataValidation?.inputEndpointTypes || [])
  .flatten()
  .compact()
  .uniq()
  .sortBy([(dsc) => dsc.name])
  .value();

export interface JobActivityDataValidationProps {
  activity: Activity;
  onChange: (activity: Activity) => void;
}

export const JobActivityDataValidation = (
  props: JobActivityDataValidationProps
) => {
  const { activity, onChange } = props;

  activity.input = activity.input || { connectionName: '', type: '' };
  activity.actions = activity.actions || [];
  activity.failIfValidationTriggers =
    activity.failIfValidationTriggers || false;
  delete activity.parameters;
  delete activity.retryPolicy;

  const inputDataSourceType = _.get(
    orchestratorConstants.EndpointTypeOptions,
    activity!.input!.type
  )?.dataSourceType;
  const inputDsc = getDataSourcePlugin(inputDataSourceType)?.dataSourceClient;

  const [mode, setMode] = useState(
    inputDataSourceType === orchestratorConstants.EndpointTypes.CosmosStream
      ? Modes.CosmosValidation
      : !_.isEmpty(activity.validationQuery)
      ? Modes.ValidationQuery
      : Modes.Validations
  );
  const [, setPrevConfig] = useState({
    validations: activity.validations,
    validationQuery: activity.validationQuery,
  });
  const [bigSrn, setBigSrn] = useState(false);

  return (
    <>
      <LensLabel
        labelText={'Input'}
        hintText={
          'The input data to validate. Click on the icon for more information.'
        }
        required={true}
      ></LensLabel>
      <Stack
        className={groupBorder}
        tokens={{
          childrenGap: 5,
          padding: 16,
        }}
      >
        <JobEndpoint
          isSource={true}
          activity={activity}
          endpoint={activity.input}
          onChange={(
            activity: Activity,
            dc: ProjectDataConnection | undefined,
            endpoint: any
          ): void => {
            if (
              activity.input!.type ===
              orchestratorConstants.EndpointTypes.CosmosStream
            ) {
              setMode(Modes.CosmosValidation);
            }
            if (endpoint) {
              activity.input = endpoint;
            }
            onChange(activity);
          }}
          endpointTypes={inputEndpointTypes}
          hideTableType={true}
        ></JobEndpoint>
      </Stack>
      <Stack tokens={{ childrenGap: 16, padding: 16 }}>
        {mode !== Modes.CosmosValidation && (
          <>
            <Stack.Item>
              <ElxDropdown
                onRenderLabel={() => {
                  return (
                    <LensLabel
                      labelText="Validation mode"
                      hintText={`Validation mode - validation or validation query`}
                      required={false}
                    ></LensLabel>
                  );
                }}
                options={validationOptions}
                selectedKey={mode}
                onChange={(_, option) => {
                  const vld = option!.key as string;
                  if (vld === Modes.Validations) {
                    setPrevConfig((cfg) => {
                      return {
                        ...cfg,
                        validationQuery: activity.validationQuery,
                      };
                    });
                    activity.validationQuery = undefined;
                  } else {
                    setPrevConfig((cfg) => {
                      return { ...cfg, validations: activity.validations };
                    });
                    activity.validations = undefined;
                  }
                  setMode(option!.key as string);
                }}
              ></ElxDropdown>
            </Stack.Item>
          </>
        )}

        <Stack.Item>
          {mode === Modes.Validations && (
            <LensLabel
              labelText={'Validation'}
              hintText={
                'The validation scenario to evaluate the quality of the input data.'
              }
            ></LensLabel>
          )}
          {mode === Modes.ValidationQuery && (
            <LensLabel
              labelText={'Validation Query'}
              hintText={'The validation query to evaluate.'}
            ></LensLabel>
          )}
          {mode === Modes.CosmosValidation && (
            <LensLabel
              labelText={'Validation Script'}
              hintText={'The validation script to evaluate.'}
            ></LensLabel>
          )}
        </Stack.Item>
        {mode === Modes.Validations && (
          <>
            <Stack.Item>
              <JobActivityDataValidationValidations
                activity={activity}
                onChange={(activity: Activity): void => {
                  onChange(activity);
                }}
              ></JobActivityDataValidationValidations>
            </Stack.Item>
          </>
        )}
        {(mode === Modes.ValidationQuery ||
          mode === Modes.CosmosValidation) && (
          <>
            <Stack.Item>
              <Stack
                className={alignEnds}
                horizontal
                grow
                styles={{
                  root: {
                    marginTop: 16,
                  },
                }}
              >
                <Stack.Item>
                  {' '}
                  <LensLabel
                    labelText={
                      mode === Modes.ValidationQuery ? 'Query' : 'Script'
                    }
                    hintText={
                      mode === Modes.ValidationQuery
                        ? 'The validation query. The query is executed on the input data connection and can query any tables in the data source. If the query produces any rows, all validation actions are performed sequentially.'
                        : 'The validation script.'
                    }
                    required={true}
                  ></LensLabel>
                </Stack.Item>
                <Stack.Item>
                  {' '}
                  <ElxIconButton
                    text={''}
                    styles={{
                      root: {
                        float: 'right',
                      },
                    }}
                    iconProps={{
                      iconName: 'FullScreen',
                    }}
                    onClick={() => {
                      setBigSrn(true);
                    }}
                  ></ElxIconButton>
                </Stack.Item>
              </Stack>
            </Stack.Item>
            {
              <Stack.Item>
                <Editor
                  language={inputDsc?.monaco?.language || 'query'}
                  onChange={(value: string) => {
                    activity.validationQuery = value;
                    onChange(activity);
                  }}
                  value={activity.validationQuery!}
                  height={200}
                  width={'100%'}
                  focusOnMount={false}
                ></Editor>
              </Stack.Item>
            }
            {bigSrn && (
              <EditorFull
                header={
                  mode === Modes.ValidationQuery
                    ? 'Validation Query'
                    : 'Validation Script'
                }
                onChange={(value: string) => {
                  activity.validationQuery = value;
                  onChange(activity);
                }}
                value={activity.validationQuery!}
                onDismiss={() => {
                  setBigSrn(false);
                }}
                language={inputDsc?.monaco?.language || 'query'}
              ></EditorFull>
            )}
            {mode === Modes.CosmosValidation && (
              <>
                <Stack.Item>
                  <ElxTextField
                    onRenderLabel={() => {
                      return (
                        <LensLabel
                          labelText={'Azure Analytics Module Path'}
                          hintText={`CAS SDK Path to support custom versions. Default value is '/shares/AzureAnalytics.Prod/Sdk/AzureAnalytics1.5.module'.`}
                          required={true}
                        ></LensLabel>
                      );
                    }}
                    value={activity.azureAnalyticsModulePath}
                    onChange={(_, value) => {
                      activity.azureAnalyticsModulePath = value || '';
                      onChange(activity);
                    }}
                  ></ElxTextField>
                </Stack.Item>

                <Stack.Item>
                  {/* TODO - Nambi - need to re-work on the below  */}
                  {/* <div type="text" name="validatedEntitySignal-{{elementSuffix}}" class="form-control" ng-if="activity.input.catalogEntity"
                                 style="overflow-x: hidden; text-overflow: ellipsis;max-width:300px;min-width: 0px; width: fit-content;" tooltip="{{activity.input.catalogEntity}}.">
                                {{activity.input.catalogEntity}}.
                            </div> */}
                  {<>{activity.input.catalogEntity}</>}
                  <ElxTextField
                    onRenderLabel={() => {
                      return (
                        <LensLabel
                          labelText={
                            'Catalog Entity Suffix for Validation Results'
                          }
                          hintText={`Suffix for the Geneva Catalog entity name for validation results. The new entity name will be in the format of <ValidatedEntity>.<Suffix>. 
                           For example, if the Catalog entity to validate is Microsoft.Cloud.TestEntity, and the suffix is SuccessMetrics. Then the generated validated entity will be named Microsoft.Cloud.TestEntity.SuccessMetrics. 
                           Results of the validation script will be sent to this entity. Use any non-empty string if you are validating Cosmos stream instead of a Catalog entity.`}
                          required={true}
                        ></LensLabel>
                      );
                    }}
                    value={activity.validatedEntitySignalSuffix}
                    onChange={(_, value) => {
                      activity.validatedEntitySignalSuffix = value || '';
                      onChange(activity);
                    }}
                  ></ElxTextField>
                </Stack.Item>

                <Stack.Item>
                  <ElxTextField
                    onRenderLabel={() => {
                      return (
                        <LensLabel
                          labelText={'MDM Account'}
                          hintText={`The MDM Account.`}
                          required={true}
                        ></LensLabel>
                      );
                    }}
                    value={activity.actions![0].output.account}
                    onChange={(_, value) => {
                      activity.actions![0].output.account = value || '';
                      onChange(activity);
                    }}
                  ></ElxTextField>
                </Stack.Item>

                <Stack.Item>
                  <ElxTextField
                    onRenderLabel={() => {
                      return (
                        <LensLabel
                          labelText={'MDM Namespace'}
                          hintText={`The corresponding MDM Namespace.`}
                          required={true}
                        ></LensLabel>
                      );
                    }}
                    value={activity.actions![0].output.namespace}
                    onChange={(_, value) => {
                      activity.actions![0].output.namespace = value || '';
                      onChange(activity);
                    }}
                  ></ElxTextField>
                </Stack.Item>

                <Stack.Item>
                  <ElxTextField
                    onRenderLabel={() => {
                      return (
                        <LensLabel
                          labelText={'MDM Metric Name'}
                          hintText={`Given MDM Metric Name. This field is static`}
                          required={true}
                        ></LensLabel>
                      );
                    }}
                    value={'GenevaValidationResult'}
                    readOnly
                  ></ElxTextField>
                </Stack.Item>

                <Stack.Item>
                  <ElxTextField
                    onRenderLabel={() => {
                      return (
                        <LensLabel
                          labelText={'MDM Dimension Value'}
                          hintText={`Static dimension value for MDM signal to help diagnostics and debugging. Can be any non-empty string.`}
                          required={true}
                        ></LensLabel>
                      );
                    }}
                    value={activity.subscription}
                    onChange={(_, value) => {
                      activity.subscription = value || '';
                      onChange(activity);
                    }}
                  ></ElxTextField>
                </Stack.Item>

                <Stack.Item>
                  <ElxTextField
                    onRenderLabel={() => {
                      return (
                        <LensLabel
                          labelText={'MDM Dimension Value'}
                          hintText={`Static dimension value for MDM signal to help diagnostics and debugging. Can be any non-empty string.`}
                          required={true}
                        ></LensLabel>
                      );
                    }}
                    value={activity.subscription}
                    onChange={(_, value) => {
                      activity.subscription = value || '';
                      onChange(activity);
                    }}
                  ></ElxTextField>
                </Stack.Item>
              </>
            )}
            {mode === Modes.ValidationQuery && (
              <>
                <Stack.Item>
                  <LensLabel
                    labelText={'Actions'}
                    hintText={`The validation actions. If the validation query produces any rows, all actions are performed sequentially.`}
                    required={true}
                  ></LensLabel>
                  <JobActivityDataValidationActions
                    actions={activity.actions!}
                    activity={activity}
                    onChange={(actions: Action[]): void => {
                      activity.actions = [...actions];
                      onChange(activity);
                    }}
                  ></JobActivityDataValidationActions>
                </Stack.Item>
              </>
            )}
          </>
        )}
        {
          <>
            <Stack.Item className={groupBorder}>
              <JobAccordian
                isOpen={false}
                text={'Advance Activity Options'}
                hint={'Advanced config.'}
              >
                <Stack tokens={{ childrenGap: 16, padding: 16 }}>
                  <Stack.Item>
                    <Toggle
                      label="Terminate Job If Validation Triggers"
                      checked={activity.failIfValidationTriggers}
                      onChange={(
                        e: React.MouseEvent<HTMLElement>,
                        checked?: boolean
                      ) => {
                        activity.failIfValidationTriggers = checked;
                        onChange(activity);
                      }}
                    />
                  </Stack.Item>
                  {mode === Modes.CosmosValidation && (
                    <>
                      <Stack.Item>
                        <ElxTextField
                          onRenderLabel={() => {
                            return (
                              <LensLabel
                                labelText={'Priority'}
                                hintText={`The priority of this job.
                          Lower values have higher priority.
                          The default is 1100.`}
                                required={true}
                              ></LensLabel>
                            );
                          }}
                          value={
                            activity.priority
                              ? String(activity.priority)
                              : String(1100)
                          }
                          onChange={(_, value) => {
                            activity.priority = Number(value) || 1100;
                            onChange(activity);
                          }}
                        ></ElxTextField>
                      </Stack.Item>

                      <Stack.Item>
                        <ElxTextField
                          onRenderLabel={() => {
                            return (
                              <LensLabel
                                labelText={'Tokens'}
                                hintText={`The token allocation for this job.
                                Enter either the tokens or the VC percent allocation or neither but not both.`}
                                required={true}
                              ></LensLabel>
                            );
                          }}
                          value={
                            activity.tokenAllocation
                              ? String(activity.tokenAllocation)
                              : String(1100)
                          }
                          onChange={(_, value) => {
                            activity.tokenAllocation = Number(value) || 1100;
                            onChange(activity);
                          }}
                        ></ElxTextField>
                      </Stack.Item>

                      <Stack.Item>
                        <ElxTextField
                          min={1}
                          max={100}
                          onRenderLabel={() => {
                            return (
                              <LensLabel
                                labelText={'VC Percent Allocation'}
                                hintText={`The VC percent allocation for this job.
                                Enter a number from 1 to 100 inclusive, or -1 for the default allocation.
                                Enter either the tokens or the VC percent allocation or neither but not both.`}
                                required={true}
                              ></LensLabel>
                            );
                          }}
                          value={
                            activity.vcPercentAllocation
                              ? String(activity.vcPercentAllocation)
                              : String(1)
                          }
                          onChange={(_, value) => {
                            activity.vcPercentAllocation = Number(value) || 1;
                            onChange(activity);
                          }}
                        ></ElxTextField>
                      </Stack.Item>
                    </>
                  )}
                </Stack>
              </JobAccordian>
            </Stack.Item>
          </>
        }
      </Stack>
    </>
  );
};
