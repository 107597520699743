import {
  ElxActionButton,
  ElxIconButton,
  ElxPanel,
  ElxTextField,
  PanelSize,
} from '@elixir/components';
import { Stack, StackItem, ThemeProvider, Toggle } from '@fluentui/react';
import { DataSourceClient } from 'features/dataSources/client/dataSourceClient';
import {
  getAllDataSourcePlugins,
  getDataSourcePlugin,
} from 'features/dataSources/registry';
import { Activity } from 'features/orchestrator/models/job';
import { useLensShellTheme } from 'features/shell/lensShellStyles';
import { ProjectDataConnection } from 'features/workspaces/models/project';
import { selectWorkspace } from 'features/workspaces/workspaceSlice';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { orchestratorConstants } from 'utils/constants';
import { LensLabel } from 'utils/lensLabel';
import { JobEndpoint } from './JobEndpoint';
import { useSelector } from 'react-redux';
import { Editor } from 'components/editor';
import EditorFull from '../editorFull';
import DayHourMinSecPicker from 'components/dayHourMinSecPicker';
import { alignEnds, groupBorder } from './JobAuthorStyles';
import { JobAccordian } from 'features/orchestrator/utils/jobAccordian';

var restrictTimeColumn = [
  orchestratorConstants.EndpointTypes.KustoQuery,
  orchestratorConstants.EndpointTypes.CosmosStream,
  orchestratorConstants.EndpointTypes.AzureDataLakeStorageGen2,
  orchestratorConstants.EndpointTypes.SqlQuery,
];

const inputEndpointTypes = _(getAllDataSourcePlugins())
  .map((dsp) => dsp.dataSourceClient)
  .map((dsc) => dsc.orchestration?.dataMovement?.endpointTypes || [])
  .flatten()
  .compact()
  .uniq()
  .value();

const outputEndpointTypes = (dataSourceType: string): string[] => {
  return (
    getDataSourcePlugin(dataSourceType).dataSourceClient.orchestration
      ?.dataMovement?.targetEndpointTypes || []
  );
};

export interface JobActivityDataProcessingProps {
  activity: Activity;
  onChange: (activity: Activity) => void;
}
export const JobActivityDataProcessing = (
  props: JobActivityDataProcessingProps
) => {
  const { activity, onChange } = props;
  activity.input = activity.input || { connectionName: '', type: '' };
  activity.output = activity.output || { connectionName: '', type: '' };
  activity.validationQuery = activity.validationQuery || '';

  const inputDataSourceType = _.get(
    orchestratorConstants.EndpointTypeOptions,
    activity!.input!.type
  )?.dataSourceType;
  const inputDsc = getDataSourcePlugin(inputDataSourceType)?.dataSourceClient;
  const outputDataSourceType = _.get(
    orchestratorConstants.EndpointTypeOptions,
    activity!.output!.type
  )?.dataSourceType;
  const outputDsc = getDataSourcePlugin(outputDataSourceType)?.dataSourceClient;

  const [subscriptionDCCreate, setSubscriptionDCCreate] = useState(false);

  const [isLambda, setIsLambda] = useState(
    activity!.input!.tableType === orchestratorConstants.TableTypes.Log &&
      activity!.output!.tableType === orchestratorConstants.TableTypes.Snapshot
  );
  const [bigSrn, setBigSrn] = useState(false);
  const [addNewPartitionFilter, setAddNewPartitionFilter] = useState(false);
  const [partitionFilter, setPartitionFilter] = useState('');

  const [config, setConfig] = useState<any>({
    isLambdaProcessingEnabled:
      inputDataSourceType === orchestratorConstants.DataSourceTypes.KUSTO &&
      outputDataSourceType === orchestratorConstants.DataSourceTypes.KUSTO,
    hideTableType: false,
    isPrimaryKeyEnabled:
      outputDsc?.orchestration.dataMovement.primaryKey || false,
    isOutputFolderEnabled:
      outputDsc?.orchestration.dataMovement.outputFolder || false,
    isMaxReprocessingAgeEnabled:
      outputDataSourceType === orchestratorConstants.DataSourceTypes.KUSTO &&
      activity.output.tableType === orchestratorConstants.TableTypes.Timeseries,
    enablePartitionCount:
      inputDsc?.orchestration?.dataMovement?.inputPartitioning || false,
    enableVcProperties:
      inputDataSourceType === orchestratorConstants.DataSourceTypes.ADLSGEN2 ||
      inputDataSourceType === orchestratorConstants.DataSourceTypes.COSMOS,
    enableSchema:
      inputDataSourceType ===
      orchestratorConstants.DataSourceTypes.AZURESTORAGE,
    enablePartitionFilters:
      activity!.input!.type === orchestratorConstants.EndpointTypes.AzureTable,
    isValidationQueryEnabled:
      outputDsc?.orchestration.dataMovement.validationQuery,
  });

  if (config.enablePartitionCount) {
    activity.partitionCount = activity.partitionCount || 1;
  }

  const workspace = useSelector(selectWorkspace); // get current workspace.

  const isInputQuery = useCallback(() => {
    var inputType = activity!.input!.type;
    return (
      inputType === orchestratorConstants.EndpointTypes.KustoQuery ||
      inputType ===
        orchestratorConstants.EndpointTypes.ApplicationInsightsQuery ||
      inputType === orchestratorConstants.EndpointTypes.LogAnalyticsQuery ||
      inputType === orchestratorConstants.EndpointTypes.SqlQuery
    );
  }, [activity]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const setInputTableType = useCallback(() => {
    // Lambda processing is data movement from a Log input table to a Snapshot output table.
    if (isLambda) {
      activity!.input!.tableType = orchestratorConstants.TableTypes.Log;
      return;
    }

    // Query processing or Cosmos stream processing has no input table.
    if (
      isInputQuery() ||
      activity!.input!.type === orchestratorConstants.EndpointTypes.CosmosStream
    ) {
      activity!.input!.tableType = undefined;
      return;
    }

    if (
      activity!.input!.type === orchestratorConstants.EndpointTypes.SqlTable
    ) {
      activity!.input!.tableType = orchestratorConstants.TableTypes.Timeseries;
      return;
    }

    if (
      activity!.input!.type === orchestratorConstants.EndpointTypes.SqlQuery
    ) {
      activity!.input!.tableType = '';
      return;
    }

    // Otherwise, the input table type matches the output table type.
    activity!.input!.tableType = activity!.output!.tableType;
  }, [activity, isLambda, isInputQuery]);

  // TODO - Nambi - we need to have this function to get the timecolumn (to show in dropdown)
  // function initInputColumns() {
  //   $scope.inputColumns = null;
  //   $scope.timeColumns = null;

  //   var input = $scope.activity && $scope.activity.input;
  //   var dataSource = getDataSource(input);
  //   getSchema(dataSource)
  //       .then(function (schema) {
  //           if (!schema) return null;

  //           var tables = getTables(schema);
  //           var tableName = input && input.table;
  //           var table = _.find(tables, { name: tableName });
  //           var fields = table && table.children;
  //           $scope.inputColumns = _.map(fields, 'name');

  //           var timeFields = _.filter(fields, { type: 'DateTime' });
  //           $scope.timeColumns = _.map(timeFields, 'name');
  //       });
  // }

  // Could be call when finalizing..
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const setOutputFileFormat = useCallback(() => {
    if (
      activity!.output!.type ===
        orchestratorConstants.EndpointTypes.CosmosStream &&
      (activity!.input!.type ===
        orchestratorConstants.EndpointTypes.AzureTable ||
        activity!.input!.type ===
          orchestratorConstants.EndpointTypes.SqlTable ||
        activity!.input!.type === orchestratorConstants.EndpointTypes.SqlQuery)
    ) {
      activity!.output!.fileFormat = 'customCsv';
    }
    // AzureTable -> Adls Gen2, Sql -> Adls Gen2 scopeCsv fileFormat is default
    else if (
      activity!.output!.type ===
        orchestratorConstants.EndpointTypes.AzureDataLakeStorageGen2 &&
      (activity!.input!.type ===
        orchestratorConstants.EndpointTypes.AzureTable ||
        activity!.input!.type ===
          orchestratorConstants.EndpointTypes.SqlTable ||
        activity!.input!.type === orchestratorConstants.EndpointTypes.SqlQuery)
    ) {
      activity!.output!.fileFormat = 'scopeCsv';
    } else {
      delete activity!.output!.fileFormat;
    }
  }, [activity]);

  useEffect(() => {
    const inDsc: DataSourceClient = getDataSourcePlugin(
      _.get(orchestratorConstants.EndpointTypeOptions, activity!.input!.type)
        ?.dataSourceType
    )?.dataSourceClient;
    const outDsc: DataSourceClient = getDataSourcePlugin(
      _.get(orchestratorConstants.EndpointTypeOptions, activity!.output!.type)
        ?.dataSourceType
    )?.dataSourceClient;
    let isLambdaProcessingEnabled =
      inDsc?.dataSourceType === orchestratorConstants.DataSourceTypes.KUSTO &&
      outDsc?.dataSourceType === orchestratorConstants.DataSourceTypes.KUSTO;
    setConfig({
      isLambdaProcessingEnabled: isLambdaProcessingEnabled,
      hideTableType:
        (activity.input?.type ===
          orchestratorConstants.EndpointTypes.CosmosStream ||
          activity.input?.type ===
            orchestratorConstants.EndpointTypes.AzureDataLakeStorageGen2) &&
        (activity.output?.type ===
          orchestratorConstants.EndpointTypes.AzureBlob ||
          activity.output?.type ===
            orchestratorConstants.EndpointTypes.MdmMetric),
      isPrimaryKey: outDsc?.orchestration.dataMovement.primaryKey,
      isOutputFolderEnabled: outDsc?.orchestration?.dataMovement?.oututFolder,
      isMaxReprocessingAgeEnabled:
        outDsc?.dataSourceType ===
          orchestratorConstants.DataSourceTypes.KUSTO &&
        activity!.output!.tableType ===
          orchestratorConstants.TableTypes.Timeseries,
      enablePartitionCount:
        inDsc?.orchestration?.dataMovement?.inputPartitioning || false,
      enableVcProperties:
        inDsc?.dataSourceType ===
          orchestratorConstants.DataSourceTypes.ADLSGEN2 ||
        inDsc?.dataSourceType === orchestratorConstants.DataSourceTypes.COSMOS,
      enableSchema:
        inDsc?.dataSourceType ===
        orchestratorConstants.DataSourceTypes.AZURESTORAGE,
      enablePartitionFilters:
        activity!.input!.type ===
        orchestratorConstants.EndpointTypes.AzureTable,
      isValidationQueryEnabled:
        outDsc?.orchestration.dataMovement.validationQuery,
    });
    if (isLambda && !isLambdaProcessingEnabled) {
      setIsLambda(false);
    }
  }, [activity, isLambda]);

  const [endpointTypesForTarget, setEndpointTypesForTarget] = useState(
    outputEndpointTypes(
      _.get(
        orchestratorConstants.EndpointTypeOptions,
        activity.input?.type || orchestratorConstants.EndpointTypes.KustoTable
      )?.dataSourceType || []
    )
  );

  const showPrimaryKey = config.isLamda || config.isPrimary;

  const showTimeColumnInput =
    activity.output.tableType === 'Timeseries' &&
    (restrictTimeColumn.indexOf(activity.input.type) === -1 ||
      activity.output.type === orchestratorConstants.EndpointTypes.SqlTable ||
      activity.output.type === orchestratorConstants.EndpointTypes.SqlQuery);

  const isTimeColumnRequired =
    activity.output.tableType === 'Timeseries' &&
    activity.output.type !== orchestratorConstants.EndpointTypes.SqlTable &&
    activity.output.type !== orchestratorConstants.EndpointTypes.SqlQuery;

  const subscriptionDCNeededButNotPresent =
    _.isNil(
      _.find(workspace?.dataConnections, {
        Type: orchestratorConstants.DataSourceTypes.AZURESUBSCRIPTION,
      })
    ) &&
    !_.isNil(
      _.find(orchestratorConstants.SubscriptionRequired.DataMovement, {
        input: activity.input.type,
        output: activity.output.type,
      })
    );

  const theme = useLensShellTheme();

  return (
    <>
      <LensLabel
        labelText={'Source'}
        hintText={'Source'}
        required={true}
      ></LensLabel>
      <Stack
        className={groupBorder}
        tokens={{
          childrenGap: 5,
          padding: 16,
        }}
      >
        <JobEndpoint
          isSource={true}
          activity={activity}
          endpoint={activity.input}
          onChange={(
            activity: Activity,
            dc: ProjectDataConnection | undefined,
            endpoint: any
          ): void => {
            if (dc) {
              setEndpointTypesForTarget(outputEndpointTypes(dc.type));
              // dcType2EndPointTypeForDataProcessingInput(dc);
            }
            if (endpoint) {
              activity.input = endpoint;
            }
            onChange(activity);
          }}
          endpointTypes={inputEndpointTypes}
          showTableType={false}
        ></JobEndpoint>
      </Stack>
      <LensLabel
        labelText={'Target'}
        hintText={'Target'}
        required={true}
      ></LensLabel>
      <Stack
        className={groupBorder}
        tokens={{
          childrenGap: 5,
          padding: 16,
        }}
      >
        <Stack.Item>
          <JobEndpoint
            isSource={false}
            activity={activity}
            endpoint={activity.output}
            onChange={(
              activity: Activity,
              dc: ProjectDataConnection | undefined,
              endpoint: any
            ): void => {
              if (endpoint) {
                endpoint.type = undefined;
                activity.output = endpoint;
              }
              onChange(activity);
            }}
            endpointTypes={endpointTypesForTarget}
            // showTableType={true}
            hideTableType={isLambda || config.hideTableType}
          ></JobEndpoint>
        </Stack.Item>
      </Stack>
      <Stack tokens={{ childrenGap: 16 }}>
        {showPrimaryKey && (
          <>
            <Stack.Item>
              <ElxTextField
                onRenderLabel={() => {
                  return (
                    <LensLabel
                      labelText={'Primary Key'}
                      hintText={
                        'The primary key column. Multiple values are comma-separated or semicolon-separated.'
                      }
                      required={false}
                    ></LensLabel>
                  );
                }}
                value={activity.primaryKey}
                onChange={(_, value) => {
                  activity.primaryKey = value || '';
                  onChange(activity);
                }}
                // {TODO - Nambi - we need to get dropdown for inputColummsn for user to choose from
                // basically we need to query the table and get the field names   }
              ></ElxTextField>
            </Stack.Item>
          </>
        )}
        {showTimeColumnInput && (
          <>
            <Stack.Item>
              <ElxTextField
                onRenderLabel={() => {
                  return (
                    <LensLabel
                      labelText={'Time Column'}
                      hintText={
                        'The time column that contains the timestamp for append (timeseries) data movement.'
                      }
                      required={isTimeColumnRequired}
                    ></LensLabel>
                  );
                }}
                value={activity.timeColumn}
                onChange={(_, value) => {
                  activity.timeColumn = value || '';
                  onChange(activity);
                }}
                // {TODO - Nambi - we need to get dropdown for timecolumn for user to choose from
                // basically we need to query the table and get the field names   }
              ></ElxTextField>{' '}
            </Stack.Item>
          </>
        )}
        {subscriptionDCNeededButNotPresent && (
          <>
            <Stack.Item>
              <ElxActionButton
                iconProps={{
                  iconName: 'Add',
                }}
                text="Add"
                onClick={() => {
                  setSubscriptionDCCreate(true);
                }}
                style={{
                  float: 'right',
                }}
              />
              <ElxActionButton />
            </Stack.Item>
          </>
        )}
        {subscriptionDCCreate && (
          <>
            <ThemeProvider theme={theme}>
              <ElxPanel
                headerText={'Create Subscription data connection'}
                isOpen={true}
                actions={[
                  {
                    key: 'Create Data Connection',
                    text: 'Create Data Connection',
                    primary: true,
                    onClick: () => {
                      // TODO - Nambi - create subscripton code here.
                      setSubscriptionDCCreate(false);
                    },
                  },
                  {
                    key: 'close',
                    text: 'Close',
                    onClick: () => {
                      setSubscriptionDCCreate(false);
                    },
                  },
                ]}
                size={PanelSize.medium}
                onDismiss={() => {
                  setSubscriptionDCCreate(false);
                }}
              >
                <div>
                  {' '}
                  TODO - Nambi - Control to create subscription data connection.
                </div>
              </ElxPanel>
            </ThemeProvider>
          </>
        )}

        {
          <>
            <Stack.Item>
              {/* <Stack
              horizontal
              grow
              styles={{
                root: {
                  marginTop: 16,
                },
              }}
            >
              {' '}
              <Stack.Item>
                <LensLabel
                  labelText={'Advanced Config'}
                  hintText={'Advanced - optional configurations'}
                ></LensLabel>
              </Stack.Item>
              <Stack.Item>
                <Toggle
                  styles={{
                    root: {
                      float: 'right',
                      paddingLeft: 8,
                      paddingTop: 4,
                    },
                  }}
                  checked={expandAdvanced}
                  onChange={(e, checked?: boolean) =>
                    setExpandAdvanced(checked as boolean)
                  }
                ></Toggle>
              </Stack.Item>
            </Stack> */}
              <JobAccordian
                isOpen={false}
                text={'Advanced Config'}
                hint={'Advanced - optional configurations'}
              >
                <Stack
                  className={groupBorder}
                  tokens={{ childrenGap: 5, padding: 16 }}
                >
                  <Stack.Item>
                    <ElxTextField
                      onRenderLabel={() => {
                        return (
                          <LensLabel
                            labelText={'Target Folder'}
                            hintText={`The folder name for the target Kusto table, for example "first level\\second level";.`}
                            required={false}
                          ></LensLabel>
                        );
                      }}
                      value={activity.output.folder || ''}
                      onChange={(_, value) => {
                        activity!.output!.folder = value || '';
                        onChange(activity);
                      }}
                      // {TODO - Nambi - we need to get dropdown for timecolumn for user to choose from
                      // basically we need to query the table and get the field names   }
                    ></ElxTextField>{' '}
                  </Stack.Item>

                  {config.isValidationQueryEnabled && (
                    <>
                      <Stack.Item>
                        {' '}
                        <Stack
                          className={alignEnds}
                          horizontal
                          grow
                          styles={{
                            root: {
                              marginTop: 16,
                            },
                          }}
                        >
                          <Stack.Item>
                            {' '}
                            <LensLabel
                              labelText="Validation Query"
                              hintText={
                                'The query to validate the data in the staging table before it is written to the target table. The query result is rows of error messages.'
                              }
                            ></LensLabel>
                          </Stack.Item>
                          <Stack.Item>
                            {' '}
                            <ElxIconButton
                              text={''}
                              styles={{
                                root: {
                                  float: 'right',
                                },
                              }}
                              iconProps={{
                                iconName: 'FullScreen',
                              }}
                              onClick={() => {
                                setBigSrn(true);
                              }}
                            ></ElxIconButton>
                          </Stack.Item>
                        </Stack>
                      </Stack.Item>
                      <Stack.Item>
                        {/* {TODO - Nambi-need to supply schematree for intellisense} */}
                        <Editor
                          language={'query'}
                          onChange={(value: string) => {
                            activity.validationQuery = value;
                            onChange(activity);
                          }}
                          value={activity.validationQuery!}
                          height={200}
                          width={'100%'}
                          focusOnMount={false}
                        ></Editor>
                      </Stack.Item>

                      {bigSrn && (
                        <EditorFull
                          header={'Validation Query'}
                          onChange={(value: string) => {
                            activity.validationQuery = value;
                            onChange(activity);
                          }}
                          value={activity.validationQuery!}
                          onDismiss={() => {
                            setBigSrn(false);
                          }}
                          language={'query'}
                        ></EditorFull>
                      )}
                    </>
                  )}

                  {config.isLambdaProcessingEnabled && (
                    <>
                      <Stack.Item>
                        <Toggle
                          label="Lambda Processing"
                          checked={isLambda}
                          onChange={(
                            e: React.MouseEvent<HTMLElement>,
                            checked?: boolean
                          ) => {
                            setIsLambda(checked || false);
                          }}
                        />
                      </Stack.Item>
                    </>
                  )}
                  {config.enablePartitionCount && (
                    <>
                      <Stack.Item>
                        <ElxTextField
                          onRenderLabel={() => {
                            return (
                              <LensLabel
                                labelText={'Partition Count'}
                                hintText={`The number of partitions. A value greater than 1 enables 'key partitioning'. min 1: max 1000`}
                              ></LensLabel>
                            );
                          }}
                          type={'number'}
                          min={1}
                          max={1000}
                          value={activity.partitionCount?.toString()}
                          onChange={(_, value) => {
                            activity.partitionCount = Number(value) || 1;
                            onChange(activity);
                          }}
                        ></ElxTextField>{' '}
                      </Stack.Item>{' '}
                      {activity.partitionCount && activity.partitionCount > 1 && (
                        <>
                          <Stack.Item>
                            <ElxTextField
                              onRenderLabel={() => {
                                return (
                                  <LensLabel
                                    labelText={'Maximum Parallel Partitions'}
                                    hintText={`The number of partitions to process in parallel. By default, all partitions determined by 'partition count' are processed in parallel. Setting this value limits the number of parallel executions. Click on the icon for more information.`}
                                  ></LensLabel>
                                );
                              }}
                              value={activity.maxPartitionParallelism?.toString()}
                              onChange={(_, value) => {
                                activity.maxPartitionParallelism =
                                  Number(value) || 1;
                                onChange(activity);
                              }}
                              // {TODO - Nambi - min=1 max =activity.partitionCount check for those.   }
                            ></ElxTextField>{' '}
                          </Stack.Item>
                          {!isInputQuery() && (
                            <>
                              <Stack.Item>
                                <ElxTextField
                                  onRenderLabel={() => {
                                    return (
                                      <LensLabel
                                        labelText={'Partition Column'}
                                        hintText={`The name of the column used for 'key partitioning'. Click on the icon for more information.`}
                                        required={Boolean(
                                          activity.partitionCount
                                        )}
                                      ></LensLabel>
                                    );
                                  }}
                                  value={activity.maxPartitionParallelism?.toString()}
                                  onChange={(_, value) => {
                                    activity.maxPartitionParallelism =
                                      Number(value) || 1;
                                    onChange(activity);
                                  }}
                                  // {TODO - Nambi - min=1 max =activity.partitionCount check for those.   }
                                ></ElxTextField>{' '}
                              </Stack.Item>
                            </>
                          )}
                        </>
                      )}
                      {config.enablePartitionFilters && (
                        <>
                          <LensLabel
                            labelText="Partition Filters"
                            hintText={`partition fileters`}
                            required={false}
                          ></LensLabel>
                          <Stack tokens={{ childrenGap: 16, padding: 16 }}>
                            <Stack.Item>
                              {
                                <>
                                  <table>
                                    {activity.partitionFilters?.map(
                                      (p, depIdx) => {
                                        return (
                                          <>
                                            <tr>
                                              <td>{p}</td>
                                              <td>
                                                <ElxIconButton
                                                  iconProps={{
                                                    iconName: 'cancel',
                                                  }}
                                                  text={''}
                                                  onClick={() => {
                                                    activity.partitionFilters =
                                                      activity.partitionFilters?.filter(
                                                        (_: any, i: number) => {
                                                          return i !== depIdx;
                                                        }
                                                      );
                                                    onChange(activity);
                                                  }}
                                                ></ElxIconButton>
                                              </td>
                                            </tr>
                                          </>
                                        );
                                      }
                                    )}
                                  </table>
                                </>
                              }
                              {addNewPartitionFilter && (
                                <>
                                  <ElxPanel
                                    headerText={`Add partition filter`}
                                    isOpen={true}
                                    size={PanelSize.medium}
                                    onDismiss={() =>
                                      setAddNewPartitionFilter(false)
                                    }
                                    actions={[
                                      {
                                        key: `Save Partition Filter`,
                                        text: `Save Partition Filter`,
                                        primary: true,
                                        onClick: () => {
                                          setAddNewPartitionFilter(false);
                                          activity.partitionFilters?.push(
                                            partitionFilter
                                          );
                                          onChange(activity);
                                        },
                                      },
                                      {
                                        key: 'close',
                                        text: 'Close',
                                        onClick: () =>
                                          setAddNewPartitionFilter(false),
                                      },
                                    ]}
                                  >
                                    <Stack>
                                      <ElxTextField
                                        onRenderLabel={() => {
                                          return (
                                            <LensLabel
                                              labelText={'Partition Filter'}
                                              hintText={`Please enter partition filter. eg. PartitionKey gt  '20190623T0000'.`}
                                              required={true}
                                            ></LensLabel>
                                          );
                                        }}
                                        value={partitionFilter}
                                        onChange={(_, val) =>
                                          setPartitionFilter(val || '')
                                        }
                                      ></ElxTextField>
                                    </Stack>
                                  </ElxPanel>
                                </>
                              )}
                            </Stack.Item>
                            <ElxActionButton
                              iconProps={{
                                iconName: 'Add',
                              }}
                              text="Add"
                              onClick={() => {
                                setAddNewPartitionFilter(true);
                              }}
                              style={{
                                float: 'right',
                              }}
                            />
                          </Stack>
                        </>
                      )}
                      {config.isMaxReprocessingAgeEnabled && (
                        <>
                          <StackItem>
                            <LensLabel
                              labelText="Maximum Reprocessing Age"
                              hintText={
                                'The maximum age to reprocess the data, for example backfill jobs. After this age, Kusto drop-by tags are removed, and reprocessing may cause data duplication. Only applies to data processing activities with a Kusto target data connection and the append (timeseries) table type. By default, the age is 30 days for daily jobs, 7 days for hourly jobs, and 1 day for faster jobs.'
                              }
                              required={false}
                            ></LensLabel>
                            <DayHourMinSecPicker
                              setValue={
                                activity.maxReprocessingAge || '0.00:00:00'
                              }
                              onChange={(value: string): void => {
                                activity.maxReprocessingAge = value;
                                onChange(activity);
                              }}
                              maxDays={1}
                              showDays={true}
                              showHours={true}
                              showMinutes={true}
                              showSeconds={true}
                            ></DayHourMinSecPicker>
                          </StackItem>
                        </>
                      )}
                      {config.enableVcProperties && (
                        <>
                          <Stack.Item>
                            <ElxTextField
                              onRenderLabel={() => {
                                return (
                                  <LensLabel
                                    labelText={'Priority'}
                                    hintText={`The priority of this job.
                            Lower values have higher priority.
                            The default is 1100.`}
                                  ></LensLabel>
                                );
                              }}
                              value={activity.priority?.toString()}
                              onChange={(_, value) => {
                                activity.priority = Number(value) || 1;
                                onChange(activity);
                              }}
                            ></ElxTextField>{' '}
                          </Stack.Item>
                          <Stack.Item>
                            <ElxTextField
                              onRenderLabel={() => {
                                return (
                                  <LensLabel
                                    labelText={'Tokens / Analytics Units (AU)'}
                                    hintText={`The maximum age to reprocess the data, for example backfill jobs. After this age, Kusto drop-by tags are removed, and reprocessing may cause data duplication. Only applies to data processing activities with a Kusto target data connection and the append (timeseries) table type. By default, the age is 30 days for daily jobs, 7 days for hourly jobs, and 1 day for faster jobs.`}
                                  ></LensLabel>
                                );
                              }}
                              value={activity.tokenAllocation?.toString()}
                              onChange={(_, value) => {
                                activity.tokenAllocation = Number(value) || 1;
                                onChange(activity);
                              }}
                            ></ElxTextField>{' '}
                          </Stack.Item>
                          <Stack.Item>
                            <ElxTextField
                              onRenderLabel={() => {
                                return (
                                  <LensLabel
                                    labelText={'VC Percent Allocation'}
                                    hintText={`The VC percent allocation for this job.
                            Enter a number from 1 to 100 inclusive, or -1 for the default allocation.
                            Enter either the tokens or the VC percent allocation or neither but not both.`}
                                  ></LensLabel>
                                );
                              }}
                              value={activity.vcPercentAllocation?.toString()}
                              onChange={(_, value) => {
                                activity.vcPercentAllocation =
                                  Number(value) || -1;
                                onChange(activity);
                              }}
                              // {TODO - Nambi - min=1 max = 100 check for those.   }
                            ></ElxTextField>{' '}
                          </Stack.Item>
                        </>
                      )}
                    </>
                  )}
                </Stack>
              </JobAccordian>
            </Stack.Item>
          </>
        }
      </Stack>
    </>
  );
};
