import { DataSourceClient } from 'features/dataSources/client/dataSourceClient';
import constants, {
  $laConstants,
  orchestratorConstants,
} from 'utils/constants';

class EventHubClient extends DataSourceClient {
  public readonly name = $laConstants.DataSourceTypes.EVENTHUB;
  public readonly title = 'Event hub';
  public readonly icon = 'fa fa-hubspot';
  public readonly description = 'Event Hub data source.';

  public readonly orchestration = {
    dataEvent: {
      endpointType: orchestratorConstants.EndpointTypes.EventHub,
    },
  };
  public get displayName() {
    return constants.DataSourceClientDisplayNames.EVENTHUB;
  }

  public get dataSourceType() {
    return constants.DataSourceTypes.EVENTHUB;
  }
}

export const eventHubClient = new EventHubClient();
export default eventHubClient;
