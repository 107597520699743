import { _ } from 'utils/sharedLibs';
// import { $laConstants } from 'utils/constants';
import { AxiosRequestConfig } from 'axios';
import { HttpHeaders } from 'api/restApi';
import {
  DataSourceClientResponse,
  DataSourceQueryObject,
} from '../models/dataSourceClient';
import { SchemaTree, TreeNode } from '../models/schemaTree';

/**
 * @name SchemaNodeTypes
 * @description
 * Represents schema node types.
 */
export enum SchemaNodeTypes {
  FIELD = 'field',
  FOLDER = 'folder',
  FUNCTION = 'function',
  TABLE = 'table',
}

/**
 * @name DataSourceClientUtils
 * @description
 * Represents data source client utilities.
 * **Note** - these utilities should only be used within the data source clients.
 */
export class DataSourceClientUtils {
  /**
   * @name sortSchemaTree
   * @description
   * Sorts a schema tree.
   * @param {Object} tree The schema tree.
   * @returns {Object} The sorted schema tree.
   */
  public sortSchemaTree(tree: SchemaTree): SchemaTree {
    if (!_.isArray(tree)) return tree;

    // Sort the tree nodes by type, then by name (case-insensitive), then by name (case-sensitive).
    tree = _.sortBy(tree, function (node) {
      var typeOrder =
        node.type === 'folder'
          ? '1'
          : node.type === 'function'
          ? '2'
          : node.type === 'table'
          ? '3'
          : node.type === 'field'
          ? '4'
          : '5';
      var name = node.name || '';
      return typeOrder + ' ' + name.toLowerCase() + ' ' + name;
    });

    // Sort each level.
    var self = this;
    _.each(tree, function (node) {
      if (!node.children) return;
      node.children = self.sortSchemaTree(node.children) as TreeNode[];
    });

    return tree;
  }

  /**
   * @name createAxiosConfig
   * @description
   * Creates an HTTP config object for a query.
   * @param {Object} query The query object
   */
  public createAxiosConfig(query: DataSourceQueryObject): AxiosRequestConfig {
    let headers: any = {
      [HttpHeaders.LensRetries]: _.isNumber(query.RetriesCount)
        ? query.RetriesCount
        : 3,
      [HttpHeaders.XMsClientRequestId]: query.requestId || undefined,
    };

    // if ($state.current.name.indexOf('dashboard') === -1) {
    //     if ($laTabsModels.Context && $laTabsModels.Context.queriesCancellerPromise) {
    //         config.timeout = $laTabsModels.Context.queriesCancellerPromise.promise;
    //     } else {
    //         config.timeout = 0;
    //     }
    // } else {
    //     config.timeout = dashboardHelper.getCancellationToken().promise;
    // }

    return {
      headers,
    };
  }

  /**
   * @name trackTelemetry
   * @description
   * Tracks query telemetry.
   * @param {Object} query The query object.
   * @param {String} url The URL.
   * @param {Number} startTime The time when the query was started.
   * @param {Number} sendTime The time when the query was sent.
   * @param {Object} response The response or error.
   * @param {Boolean} isSuccess true if the query is successful; false otherwise.
   */
  public trackTelemetry(
    query: DataSourceQueryObject,
    url: string,
    startTime: number,
    sendTime: number,
    response: DataSourceClientResponse,
    isSuccess: boolean
  ) {
    // TODO: turn telemetry on
    // var timeNow = new Date().getTime();
    // var duration = timeNow - startTime;
    // // Obfuscate potential connection strings in sql_request and cosmosdb_sql_request calls for telemetry
    // if (query.Query) {
    //     query.Query = query.Query.replace($laConstants.KustoConnectionStringPattern, $laConstants.KustoObfuscatedString);
    // }
    // var dimensions = {
    //     QueryUrl: url,
    //     MsClientRequestId: query.requestId,
    //     RowsCount: _.get(response, 'Response.Tables[0].Rows.length'),
    // };
    // dimensions = _.merge(dimensions, query.TelemetryProperties);
    // var measurements = {
    //     Duration: duration,
    //     SendTime: sendTime && timeNow - sendTime
    // };
    // laTelemetry.trackAndForkEvent(laTelemetry.EventNames.CustomerQuery, dimensions, measurements);
    // if (isSuccess) {
    //     var message = JSON.stringify(query);
    //     laTelemetry.trackTrace(message, dimensions);
    // }
  }

  /**
   * @name convertSqlDecimal
   * @description
   * Converts SqlDecimal data from String to Number in response.
   * @param {Object} response The response object.
   */
  public convertSqlDecimal(response: DataSourceClientResponse) {
    if (!_.get(response, 'Response.Tables[0].Columns')) return response;

    // Check if the column is a SqlDecimal type
    for (let i = 0; i < response.Response.Tables[0].Columns.length; i++) {
      var curCol = response.Response.Tables[0].Columns[i];
      if (curCol.DataType.includes('SqlDecimal')) {
        _.each(response.Response.Tables[0].Rows, function (row) {
          row[i] *= 1; // Convert to Number type
        });
      }
    }

    return response;
  }
}

export const dataSourceClientUtils = new DataSourceClientUtils();

export default dataSourceClientUtils;
