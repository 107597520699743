import { ElxDropdown } from '@elixir/components';
import { Stack } from '@fluentui/react';
import { Activity } from 'features/orchestrator/models/job';
import { useState } from 'react';
import { LensLabel } from 'utils/lensLabel';
import { JobActivityManageActions } from './JobActivityManageActions';
import { alignEnds } from './JobAuthorStyles';

export interface JobActivityValidationActionsSelectorProps {
  trigger: any;
  activity: Activity;
  onChange: (activity: Activity) => void;
}

export const JobActivityValidationActionsSelector = (
  props: JobActivityValidationActionsSelectorProps
) => {
  const { trigger, activity, onChange } = props;
  const actionsOptions =
    activity.actions?.map((act) => ({ key: act.id, text: act.displayName })) ||
    [];

  const [manageActions, setManageActions] = useState(false);

  return (
    <>
      <Stack.Item>
        <Stack horizontal grow className={alignEnds}>
          <Stack.Item>
            <LensLabel
              labelText="Actions"
              hintText={'Actions to perform'}
              required={false}
            ></LensLabel>
          </Stack.Item>

          <Stack.Item
            styles={{ root: { cursor: 'pointer', fontSize:14, fontWeight:600 } }}
            onClick={() => {
              setManageActions(true);
            }}
          >
            <span>Click to Manage Actions</span>
          </Stack.Item>
        </Stack>
        <ElxDropdown
          multiSelect={true}
          options={actionsOptions}
          onMultiSelectChange={(selectedKeys) => {
            trigger.actionIds = selectedKeys as string[];
            onChange(activity);
          }}
          selectedKeys={trigger?.actionIds || []}
        ></ElxDropdown>
      </Stack.Item>
      {manageActions && (
        <>
          <JobActivityManageActions
            activity={activity}
            onChange={(activity: Activity): void => {
              setManageActions(false);
              onChange(activity);
            }}
            onDismiss={() => {
              setManageActions(false);
            }}
          ></JobActivityManageActions>
        </>
      )}
    </>
  );
};
