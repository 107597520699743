import { serviceEndpoints, _ } from 'utils/sharedLibs';

declare global {
  interface Window {
    IS_EXTERNAL: string;
    LEAPI_BASE_URL: string;
    AAD_TENANT: string;
  }
}

class Constants {
  public readonly ApplicationName = 'Lens Explorer';

  public readonly KustoSeparator = ' | ';
  public readonly LEApiDefaultVersion = 'v1';
  // private readonly IsExternal = window.IS_EXTERNAL === 'true'

  //private readonly serviceEndpoints = _.get(window, 'startUpConfig.serviceEndpoints', {})
  //private readonly WikiBaseUrl = IsExternal ? 'https://docs.lensexplorer.io/' : 'https://gadocs.msftcloudes.com/'
  public readonly Urls = {
    //    LensUrl: 'https://aka.ms/lensexplorer',
    LEApiBaseUrl: _.trimEnd(window.LEAPI_BASE_URL, '/') || '',
    OrchestratorApiUrl: _.trimEnd(serviceEndpoints.orchestratorUrl, '/') || '',
    //    GraphApiV2BaseUrl: _.trimEnd(serviceEndpoints.msGraphUrl, '/') || '',
    //    CrawlerAPIBaseUrl: _.trimEnd(serviceEndpoints.theiaCrawlerConfigUrl, '/') || '',
    //    LanguageReferenceLink: IsExternal ? 'http://aka.ms/kdocs' : 'http://aka.ms/csl',
    IdWebGroupSearchLinkPrefix:
      'https://idweb.microsoft.com/IdentityManagement/aspx/common/GlobalSearchResult.aspx?searchtype=e0c132db-08d8-4258-8bce-561687a8a51e&content=',
    // 'https://idweb/identitymanagement/aspx/groups/AllGroups.aspx?searchtype=e0c132db-08d8-4258-8bce-561687a8a51e&content=',
    //    LensReleaseNotesLink: 'https://aka.ms/lensreleasenotes',
    //    DocumentationLink: IsExternal ? 'http://aka.ms/lenswiki' : 'http://aka.ms/lensv2wiki',
    //    GenevaAnalyticsLink: 'https://aka.ms/genevaanalytics',
    //    LensSupportLink: 'mailto:' + (IsExternal ? 'LensPreviewSupport@microsoft.com' : 'lensxpsup@microsoft.com'),
    //    TheiaSupportLink: 'mailto:theiasupport@microsoft.com',
    //    KustoWikiLink: IsExternal ? 'http://aka.ms/kdocs' : 'http://aka.ms/csl',
    //    SelfHelpLink: 'https://sherlockserviceprod.azurewebsites.net/?Product=Lens%20Explorer&stackOverflowTag=lensexplorer&helpAlias=LensXPSup@microsoft.com&title=Lens%20Explorer',
    //    TheiaDocsLink: 'https://aka.ms/theiadocs',
    //    DataCatalogLink: 'https://datastudio.msftcloudes.com/#/entities',
    //    SubscribeNewsLetterLink: IsExternal ? 'https://www.microsoft.com' : 'https://idwebelements/GroupManagement.aspx?Group=LensXPAnnounce&Operation=join',
    //    LensStackOverFlowLink: 'https://stackoverflow.microsoft.com/questions/tagged/lensexplorer',
    //    accessPortalString: 'https://idweb/',
    //    //
    //    // Documentation Links
    //    //
    //    DashboardCachingUrl: WikiBaseUrl + 'Lens%20Explorer/Dashboards/Dashboard%20Caching.html',
    //    TheiaJobManagement: WikiBaseUrl + 'Lens%20Explorer/Project%20Theia/Job%20Management.html',
    //    TheiaJobAuthoring: WikiBaseUrl + 'Lens%20Explorer/Project%20Theia/Job%20Authoring.html',
    //    LinkSectionInUrlWiki: WikiBaseUrl + 'Lens%20Explorer/Dashboards/Groups.html',
    //    // Lens wiki on how to use Kusto bot to get data permission info
    //    KustoBotUrl: 'http://aka.ms/gaia',
    //    KustoBotWiki: 'https://microsoft.sharepoint.com/teams/WAG/EngSys/Monitor/AmdWiki/UseKustoBot.aspx',
    //    KustoQueryOptimizationUrl:  WikiBaseUrl + 'Lens%20Explorer/Queries/Best%20Practices.html',
    //    AutofocusAnalyticsWikiURL: WikiBaseUrl + 'Lens%20Explorer/Visualizations/Autofocus%20Analytics.html',
    //    RenderModeWiki: WikiBaseUrl + 'Lens%20Explorer/Visualizations/QueryAssist%20And%20Render%20Mode.html#render-mode',
    //    ApplyAttributeWikiLink: WikiBaseUrl + 'Lens%20Explorer/Queries/Control%20Filter%20Placement.html',
    //    DashboardGroupWikiLink: WikiBaseUrl + 'Lens%20Explorer/Dashboards/Groups.html',
    //    QueryFormattingLink: WikiBaseUrl + 'Lens%20Explorer/Visualizations/QueryTable%20Formatting%20Options.html',
    //    TermsAndConditionUrl: 'https://docs.lensexplorer.io/Lens%20Explorer/TermsAndConditions.html',
    //    ExportToJarvisLink: 'https://microsoft.sharepoint.com/teams/WAG/EngSys/Monitor/AmdWiki/LensV2_ExportToJarvis.aspx',
    //    ConnectToKustoLink: WikiBaseUrl + 'Lens%20Explorer/Connect/Connect%20to%20Kusto.html',
    //    VisualizationLink: WikiBaseUrl + 'Lens%20Explorer/Visualizations/Working%20with%20Visualizations.html',
    //    CollaborateWorkspaceLink: WikiBaseUrl + 'Lens%20Explorer/Workspaces/Collaborate%20with%20workspaces.html',
    //    //Media Links
    //    LensOverview: 'https://microsoft.sharepoint.com/teams/WAG/EngSys/Monitor/Shared%20Documents/Videos/LensV2Overview.mp4',
    //    VoiceOfCustomerLink: 'https://microsoft.sharepoint.com/teams/WAG/EngSys/Shared%20Documents/Geneva%20Analytics/Argon/Marketing%20Content/KeyVaultCustomerVoice.mp4',
    //    ProductVideoLink: 'https://microsoft.sharepoint.com/:v:/r/teams/WAG/EngSys/Shared%20Documents/Geneva%20Analytics/Argon/Marketing%20Content/Lens%20Ad%20V3%20-%201080p.mp4',
    //    MarchBBLink: 'https://microsoft.sharepoint.com/teams/WAG/EngSys/Shared%20Documents/Geneva%20Analytics/Videos/Lens/Brownbags/Lens%20March%20Update%20Brown%20Bag%20-%20Tuesday,%20March%2028,%202017%2012.04.56%20PM.mp4?csf=1',
    //    OverviewBBLink: 'https://microsoft.sharepoint.com/teams/WAG/EngSys/Shared%20Documents/Geneva%20Analytics/Videos/Lens/Brownbags/Lens%20v2%20Brown%20Bag%20-%20Tuesday,%20April%2011,%202017%201.13.38%20PM.mp4?csf=1',
    //    AprilBBLink: 'https://microsoft.sharepoint.com/teams/WAG/EngSys/Shared%20Documents/Geneva%20Analytics/Videos/Lens/Brownbags/Lens%20April%20Update%20Brown%20Bag%20-%20Monday,%20May%208,%202017%2012.07.16%20PM.mp4?csf=1',
    //    JuneBBLink: 'https://microsoft.sharepoint.com/teams/WAG/EngSys/Shared%20Documents/Geneva%20Analytics/Videos/Lens/Brownbags/Lens%20June%20Update%20Brown%20Bag%20-%20Thursday,%20June%2022,%202017%2012.07.24%20PM.mp4?csf=1',
    //    JulyBBLink: 'https://microsoft.sharepoint.com/teams/WAG/EngSys/Shared%20Documents/Geneva%20Analytics/Videos/Lens/Brownbags/Lens%20v2%20July%20Update%20Brown%20Bag.mp4?csf=1',
    //    //Image Links
    //    DefaultImageLink: 'https://assets.onestore.ms/cdnfiles/external/uhf/long/9a49a7e9d8e881327e81b9eb43dabc01de70a9bb/images/microsoft-gray.png',
    //    // Privacy terms link
    PrivacyTermsLink: 'https://privacy.microsoft.com/en-US/data-privacy-notice',
  };
  public readonly KustoProxyUrl = '/kustoproxy/';
  public readonly SampleClusterProxyId = 'CA95E6E9-2536-4388-AE66-9966527F95D7';
  public readonly SampleClusterProxyUrl =
    this.KustoProxyUrl + this.SampleClusterProxyId;
  public readonly OrchestratorDefaultVersion = 'v1';

  public readonly OperationCanceledMessage = 'Operation canceled';

  public readonly FilterPanelItem = 'filter_panel_item'; // name for the filter item; used in the custom filter UI to auto-populate values for the selected field.

  public readonly DataSourceParamUiTypes = {
    KUSTO: 'Kusto',
    APPLICATIONINSIGHTS: 'Application Insights',
    LOGANALYTICS: 'Log Analytics',
  };

  public readonly DataConnectionParamTypes = {
    TENANT: 'Tenant',
    APPLICATIONID: 'ApplicationId',
  };
  public readonly DataConnectionParamUiTypes = {
    TENANT: 'Tenant',
    APPLICATIONID: 'Application Id',
  };
  // search_source properties
  public readonly SearchSource = {
    Type: 'type',
    Query: 'query',
    Filter: 'filter',
    Sort: 'sort',
    Highlight: 'highlight',
    Aggs: 'aggs',
    From: 'from',
    Size: 'size',
    MaxRecords: 'maxrecords',
    Source: 'source',
    FieldDataFields: 'fielddata_fields',
    DataSourceType: 'datasourceType',
    Cluster: 'cluster',
    Database: 'database',
    TimeField: 'timefield',
    TimeFilter: 'timefilter',
    Tab: 'tab',
    GroupByColumns: 'groupByColumns',
    AllQueries: 'allQueries',
    RemoveUserQuery: 'removeUserQuery',
    IsInclude: 'isInclude', // boolean: include or exclude filters
    ChooseAll: 'chooseAll', // boolean: include all or exclude all
    FieldList: 'fieldList', // array: array of fields to include or exclude
    IgnoreTime: 'ignoreTime', // boolean: ignore time filter
    DataSourceId: 'dataSourceId', // for datasource stored in WS use this to uniquely identify.
    VcName: 'vcName',
    Folder: 'folder',
    File: 'file',
    AdlaAccount: 'adlaAccount',
    SubscriptionId: 'subscriptionId',
    ResourceGroup: 'resourceGroup',
  };

  public readonly ConditionsAgg = {
    aggName: 'conditions',
    paramName: 'conditions',
    paramItem: 'condition',
    aggTitle: 'Conditions',
    aggNamePrefix: 'Condition',
    ColumnNamePrefix: 'Condition_Col',
  };

  // Regex for various Kusto language statements
  public readonly KustoLanguageRegex = {
    Comment:
      /((['"])(?:(?!\2|\\).|\\.)*\2)|\/\/[^\n]*|\/\*(?:[^*]|\*(?!\/))*\*\//g,
    RenderStatement:
      /render\s+(\S+)(\s+kind\s*=\s*(default|unstacked|stacked(100)?))?/,
  };

  // begin rison filter param constants
  public readonly FilterOperator = {
    Default: '==',
    NegateDefault: '<>',
  };

  public readonly AllowedFilterOperators = ['==', 'startswith', 'contains'];

  public readonly RisonDataType = {
    String: 's',
    Number: 'n',
    DateTime: 'd',
  };
  public readonly RisonNonEmptyKeyError =
    'Filter in URL parameters must have a non-empty k field for filter key.';
  public readonly RisonOperatorNotSupportedError =
    "Only '==', 'startswith' and 'contains' operators are supported.";

  public readonly RisonOperatorSupportedForStringError =
    ' is supported only for string field type.';
  // end rison filter param constants

  // begin timepicker constants
  public readonly TimeFormat = {
    Local: 'Local',
    UTC: 'UTC',
  };
  // end timepicker constants

  // The data source types.  The values are the same as LEWeb.Models.DataSourceType.
  public readonly DataSourceTypes = {
    KUSTO: 'Kusto',
    APPLICATIONINSIGHTS: 'ApplicationInsights',
    // OMSLOGANALYTICS: 'OmsLogAnalytics',
    LOGANALYTICS: 'LogAnalytics',
    COSMOS: 'Cosmos',
    SQL: 'Sql',
    // AZURESTORAGE: 'AzureStorage',
    STORAGEACCOUNT: 'StorageAccount',
    DSO: 'Dso',
    EVENTHUB: 'EventHub',
    ADLSGEN2: 'AzureDataLakeStorageGen2',
    AZURESUBSCRIPTION: 'Subscription',
    ADLA: 'AzureDataLakeAnalytics',
    MDM: 'Mdm',
    AZUREDATAFACTORY: 'AzureDataFactory',
    SYNAPSEPIPELINE: 'SynapsePipeline',
    SYNAPSESCOPE: 'SynapseScope',
    SHARED: 'Shared',
  };

  public readonly DataSourceClientDisplayNames = {
    KUSTO: 'Azure Data Explorer (Kusto)',
    APPLICATIONINSIGHTS: 'Application Insights',
    LOGANALYTICS: 'Log Analytics',
    // OMSLOGANALYTICS: 'Log Analytics',
    SQL: 'SQL',
    COSMOS: 'Cosmos',
    // AZURESTORAGE: 'Azure Storage',
    STORAGEACCOUNT: 'Azure Storage',
    DSO: 'Dso',
    EVENTHUB: 'Event Hub',
    ADLSGEN2: 'Azure Data Lake Storage Gen2',
    AZURESUBSCRIPTION: 'Azure Subscription',
    ADLA: 'Azure Data Lake Analytics',
    MDM: 'Geneva Metrics (MDM)',
    AZUREDATAFACTORY: 'Azure Data Factory',
    SYNAPSEPIPELINE: 'Synapse Pipeline',
    SYNAPSESCOPE: 'Synapse Scope',
    SHARED: 'Shared Connection',
  };

  public readonly SchemaTypes = {
    Cluster: 'cluster',
    Database: 'database',
    Folder: 'folder',
    Function: 'function',
    Table: 'table',
    Job: 'Dso',
    Source: 'source',
    Catalog: 'catalog',
    // column types
    Boolean: 'Boolean',
    Byte: 'Byte',
    DateTime: 'DateTime',
    Double: 'Double',
    Dynamic: 'Dynamic',
    Guid: 'Guid',
    Int32: 'Int32',
    UInt32: 'UInt32',
    Int64: 'Int64',
    Object: 'Object',
    SByte: 'SByte',
    SqlDecimal: 'SqlDecimal',
    DataSqlTypesSqlDecimal: 'Data.SqlTypes.SqlDecimal',
    String: 'String',
    TimeSpan: 'TimeSpan',
  };

  public readonly QueryLimit = function (configuredLimit: number) {
    if (_.isNumber(configuredLimit) && configuredLimit > 0) {
      return configuredLimit;
    } else {
      return 5000;
    }
  };

  public readonly AccessDenied = 'Access Denied';
  public readonly QueryErrorMessage = 'Error retrieving data';
  public readonly QuerySyntaxErrorMessage =
    'Error retrieving data. Please refine your query and try again.';

  public readonly SyntaxErrorMessage = 'Syntax Error';
  public readonly ThrottlingErrorMessage =
    'You have exceeded the maximum number of requests per minute. Please try again later.';
  public readonly ConnectionErrorMessage = 'Connection Error';
  public readonly ConnectionErrorMessageDetails =
    'We have experienced a connection issue while retrieving data. This is usually an indication that the network is down' +
    ' or a firewall or browser extension (such as an ad blocker) is mistakenly preventing access.';

  public readonly ErrorMessagePrefix = {
    SyntaxError: 'Syntax error',
    SemanticError: 'Semantic error',
  };

  public readonly SessionIsExpiredMessage =
    'Your session timed out. Refresh the page to log in again';

  public readonly Queries = {
    GetTimelineData: 'TimeChartQuery',
    BuildSchemaOnCluster: 'BuildSchemaOnCluster',
    BuildSchemaTemplate: 'BuildSchema_<%= type %>_Query',
    GetFacetsDistinctCountAndTopValues: 'FacetsQuery',
    GetLogicalSchema: 'GetLogicalSchema',
    GetDatabaseFunctions: 'GetDatabaseFunctions',
    SlowQueryThresholdInMs: 5000,
  };

  // App name, explore or dashboard
  public readonly AppName = {
    Explore: 'explore',
    Dashboard: 'dashboard',
    QuickEditor: 'quickeditor',
  };

  public readonly FilterPlacement = {
    GenericPattern: /apply_attribute lens:filters/g,
    TimeFilterPattern: /apply_attribute lens:time:[^|);\r\n]+/g,
    TimeValueFromPattern: /apply_attribute lens:timefrom\?<[^|<>]*>/g,
    TimeValueToPattern: /apply_attribute lens:timeto\?<[^|<>]*>/g,
    IndividualFilterPattern: /apply_attribute lens:filter:[^|);\r\n]+/g,
    IndividualValuePattern: /apply_attribute lens:value:[^|<>}]+\?<[^|<>]*>/g,
    EmptyPipePattern: /\|\s*(\|\s*)+/g,
    IsolatedPipePattern: /\|[\s\n\r]*([);])/g,
    EndOfQueryPipePattern: /\|\s*$/g,
    TimeFilterPrefix: 'apply_attribute lens:time:',
    TimeValueFromPrefix: 'apply_attribute lens:timefrom?',
    TimeValueToPrefix: 'apply_attribute lens:timeto?',
    IndividualFilterPrefix: 'apply_attribute lens:filter:',
    IndividualValuePrefix: 'apply_attribute lens:value:',
  };

  // Aggs placement constant values
  public readonly AggsPlacement = {
    GenericPattern: /apply_attribute lens:aggs/g,
  };

  // Kusto management command prefix
  public readonly KustoManagementCommandPrefix = '.';

  // Kusto management create command
  public readonly KustoManagementCreateCommand = '.create';

  // Kusto management alter command
  public readonly KustoManagementAlterCommand = '.alter';

  // Kusto truncation prefixes. If one of this is detected in user query, we don't add our own truncation statement
  public readonly KustoTruncationPrefixes = [
    /set\s+notruncation/i,
    /set\s+truncationmaxsize/i,
    /set\s+truncationmaxrecords/i,
    /set\s+query_take_max_records/i,
  ];

  public readonly LensSupportAddress = 'lensxpsup@microsoft.com';

  public readonly KustoStatementDelimeter = ';';

  // Prefix for the temporary table used in visual queries
  public readonly LensTempTablePrefix = 'lensTmp_';

  public readonly LensLimitError =
    'Query result set has exceeded the internal record count limit';

  // begin custom filter constants
  public readonly CustomFilter = {
    UnaryOperators: ['isempty()', 'isnotempty()', 'isnull()', 'isnotnull()'],
    Operators: {
      IsEmpty: 'isempty()',
      IsNotEmpty: 'isnotempty()',
      IsNull: 'isnull()',
      IsNotNull: 'isnotnull()',
    },
    GroupingErrorMessages: {
      Default: 'Grouping Error: ',
      SelectAtleastTwoClauses: 'Select atleast two clauses to group.',
      GroupAlreadyExists: 'Group already exists.',
      ClausesFromDifferentGroups: 'Clauses are from different groups.',
      SelectedItemsHasSpaceBetween: 'Selected clauses have space in between.',
    },
    AllowAutoComplete: [
      this.AppName.Explore,
      this.AppName.QuickEditor,
      this.FilterPanelItem,
      this.ConditionsAgg.paramItem,
    ],
  };
  // end custom filter constants

  // Query limit for query table
  public readonly QueryTableQueryLimit = 1000;

  public readonly QueryContainTooManyDataMessagePrefix =
    'Query result set has exceeded the internal record count limit';

  public readonly QueryContainTooManyDataWarningMessage =
    ' " is plotting more than 5000 data points. This will affect performance of the browser. Please update the query to display fewer points.';

  public readonly TimePicker = {
    TabTypes: {
      Filter: 'filter',
      RefreshInterval: 'interval',
      Settings: 'settings',
    },
  };

  public readonly AriaProxyURL = 'kusto.aria.microsoft.com';

  public readonly KustoConnectionStringPattern =
    /(cosmosdb_)?sql_request\([^)]*\)/g;
  public readonly KustoObfuscatedString = '**CredentialRemoved**';

  public readonly GuidStringPattern =
    /^([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})$/g;

  public readonly NoSpecialCharactersRegex = /^(|[a-zA-Z0-9_+\-',?!%$."\s]*)$/;

  public readonly NotificationJobTag = 'AutoGenerated_DashboardNotifications';

  public readonly SavedObjectType = {
    Query: 'search',
    Visualization: 'visualization',
    Visualizations: 'visualizations', // looks like some recent/favorite items have type 'visualizationS'
    Dashboard: 'dashboard',
  };

  public readonly DefaultRequestWaitTime = 180000; // 3 minutes

  // Dashboard Meric Settings
  public readonly DashboardViewConfig = {
    HealthDashboardName: 'Health Dashboard Template',
    DashboardWorkspace: 'ws-lensxpreporting',
  };

  public readonly AADGuestUserType = 'guest';
}

class OrchestratorConstants {
  public readonly ActivityTypes = {
    // Geneva Orchestrator activity types
    DataCommand: 'DataCommand',
    DataMovement: 'DataMovement',
    DataTransform: 'DataTransform',
    DataValidation: 'DataValidation',
    DataEvent: 'DataEvent',
  };
  public readonly DataSourceTypes = {
    KUSTO: 'Kusto',
    APPLICATIONINSIGHTS: 'ApplicationInsights',
    LOGANALYTICS: 'LogAnalytics',
    COSMOS: 'Cosmos',
    SQL: 'Sql',
    AZURESTORAGE: 'StorageAccount', //renamed from'AzureStorage',
    DSO: 'Dso',
    EVENTHUB: 'EventHub',
    ADLSGEN2: 'AzureDataLakeStorageGen2',
    AZURESUBSCRIPTION: 'Subscription',
    ADLA: 'AzureDataLakeAnalytics',
    MDM: 'Mdm',
    AZUREDATAFACTORY: 'AzureDataFactory',
    SYNAPSEPIPELINE: 'SynapsePipeline',
    SYNAPSESCOPE: 'SynapseScope',
    SHARED: 'Shared',
  };

  public readonly ActivityTypeOptions = {
    // activity type options
    DataCommand: {
      activityType: this.ActivityTypes.DataCommand,
      name: 'Execute data command',
      icon: 'lensicon-run-script',
    },
    DataMovement: {
      activityType: this.ActivityTypes.DataMovement,
      name: 'Process data',
      icon: 'lensicon-shuffle',
    },
    DataTransform: {
      activityType: this.ActivityTypes.DataTransform,
      name: 'Transform data',
      icon: 'lensicon-shuffle',
    },
    DataValidation: {
      activityType: this.ActivityTypes.DataValidation,
      name: 'Validate data',
      icon: 'lensicon-completed',
    },
    DataEvent: {
      activityType: this.ActivityTypes.DataEvent,
      name: 'Signal data event',
      icon: 'lensicon-buy',
    },
  };
  public readonly ScheduleTypes = {
    // Geneva Orchestrator schedule types
    Dependency: 'Dependency',
    Frequency: 'Frequency',
    Manual: 'Manual',
  };
  public readonly FrequencyScheduleTypes = {
    cron: 'Time Schedule',
    periodBetweenJobs: 'Period Between Jobs Schedule',
  };
  public readonly WindowTypeOptions = [
    { text: 'Snapshot', value: 0 },
    { text: 'Custom', value: 1 },
    { text: 'Hourly', value: 2 },
    { text: 'Daily', value: 3 },
    { text: 'Monthly', value: 4 },
  ];
  public readonly BackfillModeOptions = {
    None: 'None',
    InstancePerGap: 'InstancePerGap',
    GroupGaps: 'GroupGaps',
  };
  public readonly RespectBoundariesOptions = {
    None: 'None',
    Day: 'Day',
    Month: 'Month',
  };
  public readonly WindowTypeOptionsValues = {
    Snapshot: 0,
    Custom: 1,
    Hourly: 2,
    Daily: 3,
    Monthly: 4,
  };
  public readonly ReTriggerPolicyOptions = [
    { text: 'All', value: 'All' },
    { text: 'Any', value: 'Any' },
    { text: 'Never', value: 'Never' },
  ];
  public readonly TriggerPolicyOptions = [
    { text: 'All', value: 'All' },
    { text: 'Any', value: 'Any' },
  ];
  public readonly EndpointTypes = {
    // Geneva Orchestrator endpoint types
    KustoColumn: 'KustoColumn',
    KustoDatabase: 'KustoDatabase',
    KustoTable: 'KustoTable',
    KustoQuery: 'KustoQuery',
    ApplicationInsightsTable: 'ApplicationInsightsTable',
    ApplicationInsightsQuery: 'ApplicationInsightsQuery',
    LogAnalyticsTable: 'LogAnalyticsTable',
    LogAnalyticsQuery: 'LogAnalyticsQuery',
    CosmosVc: 'CosmosVc',
    CosmosStream: 'CosmosStream',
    SqlDatabase: 'SqlDatabase',
    SqlTable: 'SqlTable',
    SqlQuery: 'SqlQuery',
    AzureTable: 'AzureTable',
    AzureBlob: 'AzureBlob',
    MdmNamespace: 'MdmNamespace',
    EventHub: 'EventHub',
    AzureDataLakeStorageGen2: 'AzureDataLakeStorageGen2',
    AzureDataLakeAnalytics: 'AzureDataLakeAnalytics',
    SynapsePipeline: 'SynapsePipeline',
    SynapseScope: 'SynapseScope',
    MdmMetric: 'MdmMetric',
    AzureDataFactory: 'AzureDataFactory',
    OrchestratorProject: 'OrchestratorProject',
    OrchestratorJob: 'OrchestratorJob',
    OrchestratorJobActivity: 'OrchestratorJobActivity',
  };
  public readonly EndpointEntityTypes = {
    // endpoint entity types for UI
    Column: 'Column',
    Database: 'Database',
    Query: 'Query',
    Stream: 'Stream',
    Table: 'Table',
    VC: 'VC',
    Blob: 'Blob',
  };
  public readonly Metric = {
    DefaultMetric: 'JobValidation',
  };
  public readonly TableTypes = {
    // Geneva Orchestrator table types
    Timeseries: 'Timeseries',
    Log: 'Log',
    Snapshot: 'Snapshot',
  };
  public readonly ValidationTypes = {
    // Geneva Orchestrator data validation types
    DataVolumeChange: 'DataVolumeChange',
    KeyViolationCount: 'KeyViolationCount',
    NullCount: 'NullCount',
    RegexMismatchCount: 'RegexMismatchCount',
    RowCount: 'RowCount',
    SchemaChange: 'SchemaChange',
    UnexpectedValueCount: 'UnexpectedValueCount',
  };
  public readonly ValidationDataVolumeChangeTypes = {
    // Geneva Orchestrator data validation data volume change types
    Bytes: 'Bytes',
    Rows: 'Rows',
  };
  public readonly ValidationRegexTypes = {
    // Geneva Orchestrator data validation regex types
    Email: 'Email',
    Guid: 'Guid',
    Ip: 'Ip',
    RegularExpression: 'RegularExpression',
    URL: 'URL',
  };
  public readonly ValidationActionTypes = {
    // Geneva Orchestrator data validation action types
    // TODO: DVF: add more validation action types when supported
    //CallWebHook: 'CallWebHook',
    PublishData: 'PublishData',
    //SendEmail: 'SendEmail',
    //SendIcmAlert: 'SendIcmAlert',
  };
  public readonly ValidationAggregations = {
    // Geneva Orchestrator data validation aggregations
    Absolute: 'Absolute',
    Percentage: 'Percentage',
  };
  public readonly ValidationOperators = {
    // Geneva Orchestrator data validation operators
    LessThan: 'LessThan',
    LessThanOrEqualTo: 'LessThanOrEqualTo',
    EqualTo: 'EqualTo',
    GreaterThanOrEqualTo: 'GreaterThanOrEqualTo',
    GreaterThan: 'GreaterThan',
  };
  public readonly EndpointTypeOptions = {
    // endpoint type configuration
    KustoColumn: {
      type: this.EndpointTypes.KustoColumn,
      dataSourceType: this.DataSourceTypes.KUSTO,
      entityType: this.EndpointEntityTypes.Column,
    },
    KustoDatabase: {
      type: this.EndpointTypes.KustoDatabase,
      dataSourceType: this.DataSourceTypes.KUSTO,
      entityType: this.EndpointEntityTypes.Database,
    },
    KustoTable: {
      type: this.EndpointTypes.KustoTable,
      dataSourceType: this.DataSourceTypes.KUSTO,
      entityType: this.EndpointEntityTypes.Table,
    },
    KustoQuery: {
      type: this.EndpointTypes.KustoQuery,
      dataSourceType: this.DataSourceTypes.KUSTO,
      entityType: this.EndpointEntityTypes.Query,
    },
    ApplicationInsightsTable: {
      type: this.EndpointTypes.ApplicationInsightsTable,
      dataSourceType: this.DataSourceTypes.APPLICATIONINSIGHTS,
      entityType: this.EndpointEntityTypes.Table,
    },
    ApplicationInsightsQuery: {
      type: this.EndpointTypes.ApplicationInsightsQuery,
      dataSourceType: this.DataSourceTypes.APPLICATIONINSIGHTS,
      entityType: this.EndpointEntityTypes.Query,
    },
    LogAnalyticsTable: {
      type: this.EndpointTypes.LogAnalyticsTable,
      dataSourceType: this.DataSourceTypes.LOGANALYTICS,
      entityType: this.EndpointEntityTypes.Table,
    },
    LogAnalyticsQuery: {
      type: this.EndpointTypes.LogAnalyticsQuery,
      dataSourceType: this.DataSourceTypes.LOGANALYTICS,
      entityType: this.EndpointEntityTypes.Query,
    },
    CosmosVc: {
      type: this.EndpointTypes.CosmosVc,
      dataSourceType: this.DataSourceTypes.COSMOS,
      entityType: this.EndpointEntityTypes.VC,
    },
    CosmosStream: {
      type: this.EndpointTypes.CosmosStream,
      dataSourceType: this.DataSourceTypes.COSMOS,
      entityType: this.EndpointEntityTypes.Stream,
    },
    SqlDatabase: {
      type: this.EndpointTypes.SqlDatabase,
      dataSourceType: this.DataSourceTypes.SQL,
      entityType: this.EndpointEntityTypes.Database,
    },
    SqlTable: {
      type: this.EndpointTypes.SqlTable,
      dataSourceType: this.DataSourceTypes.SQL,
      entityType: this.EndpointEntityTypes.Table,
    },
    SqlQuery: {
      type: this.EndpointTypes.SqlQuery,
      dataSourceType: this.DataSourceTypes.SQL,
      entityType: this.EndpointEntityTypes.Query,
    },
    AzureTable: {
      type: this.EndpointTypes.AzureTable,
      dataSourceType: this.DataSourceTypes.AZURESTORAGE,
      entityType: this.EndpointEntityTypes.Table,
    },
    AzureBlob: {
      type: this.EndpointTypes.AzureBlob,
      dataSourceType: this.DataSourceTypes.AZURESTORAGE,
      entityType: this.EndpointEntityTypes.Blob,
    },
    EventHub: {
      type: this.EndpointTypes.EventHub,
      dataSourceType: this.DataSourceTypes.EVENTHUB,
    },
    AzureDataLakeStorageGen2: {
      type: this.EndpointTypes.AzureDataLakeStorageGen2,
      dataSourceType: this.DataSourceTypes.ADLSGEN2,
    },
    MdmMetric: {
      type: this.EndpointTypes.MdmMetric,
      dataSourceType: this.DataSourceTypes.MDM,
      entityType: this.EndpointEntityTypes.Table,
    },
  };
  public readonly TableTypeOptions = [
    // table type select options
    {
      type: this.TableTypes.Timeseries,
      displayName: 'Append (Timeseries)',
    },
    {
      type: this.TableTypes.Log,
      displayName: 'Append Only (Log)',
    },
    {
      type: this.TableTypes.Snapshot,
      displayName: 'Overwrite (Snapshot)',
    },
  ];
  public readonly ValidationTypeOptions = {
    // data validation type options and configuration
    // listed in order for the UI (by title)
    DataVolumeChange: {
      type: this.ValidationTypes.DataVolumeChange,
      title: 'Data Volume Change',
      aggregations: [
        this.ValidationAggregations.Absolute,
        this.ValidationAggregations.Percentage,
      ],
    },
    NullCount: {
      type: this.ValidationTypes.NullCount,
      title: 'Null Count',
      aggregations: [
        this.ValidationAggregations.Absolute,
        this.ValidationAggregations.Percentage,
      ],
    },
    RegexMismatchCount: {
      type: this.ValidationTypes.RegexMismatchCount,
      title: 'Regular Expression Mismatch Count',
      aggregations: [
        this.ValidationAggregations.Absolute,
        this.ValidationAggregations.Percentage,
      ],
    },
    RowCount: {
      type: this.ValidationTypes.RowCount,
      title: 'Row Count',
      aggregations: [this.ValidationAggregations.Absolute],
    },
    SchemaChange: {
      type: this.ValidationTypes.SchemaChange,
      title: 'Schema Change',
      aggregations: null,
    },
    UnexpectedValueCount: {
      type: this.ValidationTypes.UnexpectedValueCount,
      title: 'Unexpected Value Count',
      aggregations: [
        this.ValidationAggregations.Absolute,
        this.ValidationAggregations.Percentage,
      ],
    },
    KeyViolationCount: {
      type: this.ValidationTypes.KeyViolationCount,
      title: 'Unique Key Violation Count',
      aggregations: [this.ValidationAggregations.Absolute],
    },
  };
  public readonly ValidationDataVolumeChangeTypeOptions = {
    // data validation data volume change type options
    //Bytes: {
    //    type: this.ValidationDataVolumeChangeTypes.Bytes,
    //    title: 'Bytes',
    //    value: this.ValidationDataVolumeChangeTypes.Bytes,
    //},
    Rows: {
      type: this.ValidationDataVolumeChangeTypes.Rows,
      title: 'Rows',
      value: this.ValidationDataVolumeChangeTypes.Rows,
    },
  };
  public readonly ValidationRegexTypeOptions = {
    // data validation regex type options
    // listed in order for the UI
    Email: {
      type: this.ValidationRegexTypes.Email,
      title: 'Email Address',
      value: this.ValidationRegexTypes.Email,
    },
    Guid: {
      type: this.ValidationRegexTypes.Guid,
      title: 'GUID',
      value: this.ValidationRegexTypes.Guid,
    },
    Ip: {
      type: this.ValidationRegexTypes.Ip,
      title: 'IP Address',
      value: this.ValidationRegexTypes.Ip,
    },
    URL: {
      type: this.ValidationRegexTypes.URL,
      title: 'URL',
      value: this.ValidationRegexTypes.URL,
    },
    RegularExpression: {
      type: this.ValidationRegexTypes.RegularExpression,
      title: 'Custom Regular Expression',
      value: null, // regular expression is the default (null)
    },
  };
  public readonly ValidationActionPublishDataEndpointTypeOptions = {
    KustoTable: {
      type: this.EndpointTypes.KustoTable,
    },
    MdmMetric: {
      type: this.EndpointTypes.MdmMetric,
    },
    MdmNamespace: {
      type: this.EndpointTypes.MdmNamespace,
    },
  };
  public readonly ValidationAggregationOptions = {
    // data validation aggregation options
    Absolute: {
      type: this.ValidationAggregations.Absolute,
      title: 'Absolute',
    },
    Percentage: {
      type: this.ValidationAggregations.Percentage,
      title: 'Percentage',
    },
  };
  public readonly ValidationOperatorOptions = {
    // data validation operator options
    LessThan: {
      type: this.ValidationOperators.LessThan,
      title: '<',
    },
    LessThanOrEqualTo: {
      type: this.ValidationOperators.LessThanOrEqualTo,
      title: '<=',
    },
    EqualTo: {
      type: this.ValidationOperators.EqualTo,
      title: '=',
    },
    GreaterThanOrEqualTo: {
      type: this.ValidationOperators.GreaterThanOrEqualTo,
      title: '>=',
    },
    GreaterThan: {
      type: this.ValidationOperators.GreaterThan,
      title: '>',
    },
  };
  public readonly KustoSchemaColumnTypes = {
    // Geneva Orchestrator Kusto schema column types
    Boolean: 'boolean',
    Datetime: 'datetime',
    Decimal: 'decimal',
    Double: 'double',
    Dynamic: 'dynamic',
    Guid: 'guid',
    Int: 'int',
    Long: 'long',
    String: 'string',
    Time: 'time',
    Timespan: 'timespan',
  };
  public readonly EndpointSchemaTypes = [
    'System.Binary',
    'System.Boolean',
    'System.DateTime',
    'System.Double',
    'System.Guid',
    'System.Int32',
    'System.Int64',
    'System.String',
  ];
  public readonly SubscriptionRequired = {
    DataMovement: [
      {
        input: this.EndpointTypes.CosmosStream,
        output: this.EndpointTypes.AzureTable,
      },
      {
        input: this.EndpointTypes.SqlTable,
        output: this.EndpointTypes.AzureDataLakeStorageGen2,
      },
      {
        input: this.EndpointTypes.SqlQuery,
        output: this.EndpointTypes.AzureDataLakeStorageGen2,
      },
      {
        input: this.EndpointTypes.SqlTable,
        output: this.EndpointTypes.CosmosStream,
      },
      {
        input: this.EndpointTypes.SqlQuery,
        output: this.EndpointTypes.CosmosStream,
      },
    ],
  };

  public readonly Schedule = {
    DependencyMsg:
      'The dependencies can include tables/entities that are generated/updated by other Lens jobs, or by other non-Lens services.',
  };
  public readonly TriggerStates = {
    Waiting: 'Waiting',
    Ready: 'Ready',
    ShouldTrigger: 'ShouldTrigger',
    Triggered: 'Triggered',
  };

  public readonly NotSupportedTypesForExport = [
    this.DataSourceTypes.COSMOS,
    this.DataSourceTypes.AZURESUBSCRIPTION,
    this.EndpointTypes.CosmosVc,
    this.EndpointTypes.CosmosStream,
  ];

  public readonly LanguageId = {
    // Language identifier for Monaco editor
    Plaintext: 'plaintext',
    Javascript: 'javascript',
    Kusto: 'kusto',
    Scopescript: 'csharp',
    Json: 'json',
    Sql: 'sql',
    Markdown: 'markdown',
  };

  // This is for building a proper url for VcManagenment service.
  // eg. https://aad.cosmos09.osdinfra.net/VCManagement/ChangeSecurityGroups?cluster=cosmos09-prod-co3c&vc=adCenter.BICore.prod2&adminVCNames=adCenter.BICore.prod2
  public readonly VcMgtmCosmosClusterMap: { [cosmosCluster: string]: string } =
    {
      cosmos08: 'cosmos08-prod-co3c',
      cosmos09: 'cosmos09-prod-co3c',
      cosmos11: 'cosmos11-prod-cy2',
      cosmos12: 'cosmos12-prod-cy2',
      cosmos14: 'cosmos14-prod-cy2',
      cosmos15: 'cosmos15-prod-cy',
      cosmos17: 'cosmos17-prod-co',
    };
}

export const orchestratorConstants = new OrchestratorConstants();

export const constants = new Constants();

export const $laConstants = constants;

export default constants;
