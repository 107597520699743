import { ElxActionButton } from '@elixir/components';
import { IStackProps, Label, Link, Stack } from '@fluentui/react';
import { useState } from 'react';
import notifier from 'utils/notifier';
export interface CopyButtonProps {
  text: string;
  label?: string;
  hidden?: boolean;
}

export const CopyButtonWithNotifier = (props: CopyButtonProps): JSX.Element => {
  const { text, label, hidden = false } = props;

  return (
    <ElxActionButton
      iconProps={{ iconName: 'Copy' }}
      styles={{
        root: { visibility: hidden ? 'hidden' : 'visible' },
      }}
      onClick={() => {
        navigator.clipboard.writeText(text);

        notifier.info("Successfully copied '" + text + "' to clipboard.");
      }}
    >
      {label || <></>}
    </ElxActionButton>
  );
};

export interface LabelAndTextProps {
  label: string;
  text: string | null | undefined;
  horizontal?: boolean;
  link?: string;
}

export const CopyableLabelAndText = (props: LabelAndTextProps): JSX.Element => {
  const { label, text, horizontal, link } = props;
  const [hideCopyButton, setHideCopyButton] = useState(true);

  const stackProps: IStackProps = {
    verticalAlign: 'center',
    wrap: true,
    onMouseEnter: () => {
      setHideCopyButton(false);
    },
    onMouseLeave: () => {
      setHideCopyButton(true);
    },
  };

  // Default to horizontial
  if (horizontal === true || horizontal === undefined) {
    stackProps.horizontal = true;
    stackProps.tokens = { childrenGap: '.5rem' };
  }

  return (
    <Stack {...stackProps}>
      <Stack.Item>
        <Label>{label}</Label>
      </Stack.Item>
      <Stack.Item>
        <span style={{ overflowWrap: 'break-word' }}>
          <span
            style={{
              overflowWrap: 'break-word',
              marginRight: '.5rem',
              verticalAlign: 'middle',
            }}
          >
            {getDisplayText(text, link)}
          </span>
          {text && (
            <CopyButtonWithNotifier hidden={hideCopyButton} text={text} />
          )}
        </span>
      </Stack.Item>
    </Stack>
  );
};

const getDisplayText = (
  text: string | null | undefined,
  link: string | undefined
): JSX.Element => {
  if (text) {
    if (link) {
      return (
        <Link href={link} target="_blank">
          {text}
        </Link>
      );
    } else {
      return <>{text}</>;
    }
  } else {
    return <>Not Found</>;
  }
};
