import { ADLASelector } from 'features/workspaces/components/editDataConnection/dataSourceComponents/adlaSelector';
import { SelectorProps } from 'features/workspaces/components/editDataConnection/editDataConnectionHelper';
import {
  AdlaDataSource,
  ConnectionDataSourceType,
} from 'features/workspaces/models/project';
import adlaClient from '../azureDateLakeAnalyticsClient';
import { registerDataSourcePlugin } from '../registry';
import { DataSourcePlugin } from './dataSourcePlugin';

class AdlaPlugin extends DataSourcePlugin {
  public dataSourceFormComponent(selectorProps: SelectorProps): JSX.Element {
    return (
      <ADLASelector
        workspace={selectorProps.workspace}
        state={selectorProps.state}
        setState={selectorProps.setState}
        customNameFilled={selectorProps.customNameFilled}
      />
    );
  }

  public generatedName(state: AdlaDataSource) {
    return state && state.adlaAccount ? 'Adla.' + state.adlaAccount : '';
  }
}

registerDataSourcePlugin(
  ConnectionDataSourceType.AzureDataLakeAnalytics,
  new AdlaPlugin(ConnectionDataSourceType.AzureDataLakeAnalytics, adlaClient)
);
