import LeApi from 'api/leApi';
import constants from 'utils/constants';
var baseUrl = constants.Urls.LEApiBaseUrl + '/' + constants.LEApiDefaultVersion;
function getWorkspaceUrl(workspaceId: string) {
  return baseUrl + '/workspaces/' + workspaceId + '/export';
}
class AGCMigrationApi extends LeApi {
  /**
   * @name exportWorkspace
   * @description
   * Export workspace to AGC
   * @param {Workspace} workspaceId- The workspace id
   * @returns {Promise} - A promise returning the response.
   */
  public exportWorkspace = async (workspaceId: string): Promise<any> => {
    return await this.postEntity(getWorkspaceUrl(workspaceId), null);
  };
}
export const agcMigrationApi = new AGCMigrationApi();

export default agcMigrationApi;
