import React from 'react';

import { getUserMenuItems } from './userMenu';
import { getCurrentUser, getEmail, getName } from 'utils/authUtils';
import { ElixirPersonaTypes, ElxPersona } from '@elixir/fx';
import { ElxIconButton } from '@elixir/components';
import { useUserProfileCanAdmin } from 'features/userprofile/userprofileSlice';

export const UserIcon = (): JSX.Element => {
  const user = getCurrentUser();
  if (!user) {
    return <></>;
  }

  return (
    <ElxPersona
      type={ElixirPersonaTypes.Image}
      displayName={getName()}
      userPrincipalName={getEmail()}
    />
  );
};

export const UserIconWithDropdown = (): JSX.Element => {
  const { userProfileCanAdmin } = useUserProfileCanAdmin();
  let menuItems = getUserMenuItems(userProfileCanAdmin);

  return (
    <ElxIconButton
      label="LensUserMenu"
      className="elx-header-action"
      ariaLabel={`User ${getName()}`}
      text={getName()}
      onRenderIcon={() => UserIcon()}
      onRenderMenuIcon={() => <></>}
      menuProps={menuItems}
    />
  );
};
