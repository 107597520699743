import React from 'react';
import { Route, Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import Monitor from './components/monitor';

export interface monitorRouteProps {}

const MonitorRoute = (): JSX.Element => {
  console.log('MonitorRoute');
  return (
    <Switch>
      <Route exact path={'/monitor'}>
        <Redirect to="/monitor/list"></Redirect>
      </Route>
      <Route
        path={['/monitor/list']}
        render={(props: RouteComponentProps<monitorRouteProps>) => {
          return <Monitor {...props.match.params} />;
        }}
      ></Route>
    </Switch>
  );
};

export default MonitorRoute;
