import {
  ElxActionButton,
  ElxDropdown,
  ElxHierarchicalDropdown,
  ElxTextField,
} from '@elixir/components';
import {
  ChoiceGroup,
  IChoiceGroupOption,
  IDropdownOption,
  Link,
  Stack,
  TextField,
  Toggle,
} from '@fluentui/react';
import { useEffect, useState } from 'react';
import CosmosMetadata from 'utils/cosmosMetadataService';
import { LensLabel } from 'utils/lensLabel';
import notifier from 'utils/notifier';
import { SelectorProps } from '../editDataConnectionHelper';
import {
  descriptionTextBoldStyle,
  descriptionTextStyle,
} from '../../editWorkspace/workspaceStyles';
import { orchestratorConstants } from 'utils/constants';
import { ConnectionDataSourceType } from 'features/workspaces/models/project';

const CosmosSelector = (props: SelectorProps) => {
  const { state, setState } = props;
  const [certifcateOption, setCertifcateOption] = useState<string | undefined>(
    state.certificateSecretUri ? 'CustomCertificate' : 'LensCertificate'
  );
  const [cosmosVcsOptions, setCosmosVcsOptions] = useState<
    IDropdownOption<any>[]
  >([]);
  const [cluster, setCluster] = useState(state.cluster || '');
  const [showComp, setShowComp] = useState(cluster === '');
  const [showPolicySettings, setShowPolicySettings] = useState(false);
  const [useTokenOptimizer, setUseTokenOptimizer] = useState(
    state.policy?.tokenPolicy.useTokenOptimizer || false
  );
  const [minTokenAllocation, setMinTokenAllocation] = useState(
    state.policy?.tokenPolicy.minTokenAllocation || '2'
  );
  const [maxTokenAllocation, setMaxTokenAllocation] = useState(
    state.policy?.tokenPolicy.maxTokenAllocation || ''
  );
  const [minJobHistoryCount, setMinJobHistoryCount] = useState(
    state.policy?.tokenPolicy.minJobHistoryCount || '3'
  );
  const [virtualCluster, setVirtualCluster] = useState(state.vcName || '');

  const options: IChoiceGroupOption[] = [
    { key: 'CustomCertificate', text: 'Custom Certificate' },
    { key: 'LensCertificate', text: 'Lens Certificate' },
  ];

  const availableCosmosClusters = [
    { key: 'cosmos08', text: 'cosmos08' },
    { key: 'cosmos09', text: 'cosmos09' },
    { key: 'cosmos11', text: 'cosmos11' },
    { key: 'cosmos12', text: 'cosmos12' },
    { key: 'cosmos14', text: 'cosmos14' },
    { key: 'cosmos15', text: 'cosmos15' },
    { key: 'cosmos17', text: 'cosmos17' },
  ];
  useEffect(() => {
    if (cluster) {
      const datasourceMetadataService = new CosmosMetadata();
      datasourceMetadataService
        .getCosmosVCs(cluster)
        .then((vcs) => {
          setCosmosVcsOptions(
            vcs.map((vc: string) => {
              return { key: vc, text: vc };
            })
          );
          setShowComp(true);
        })
        .catch((err) => {
          notifier.error(err);
        });
    }
  }, [cluster, state.applicationId]);

  return showComp ? (
    <Stack tokens={{ childrenGap: 16 }}>
      <Stack.Item>
        <ElxDropdown
          onRenderLabel={() => (
            <LensLabel
              labelText="Cosmos Cluster"
              hintText={'The Cosmos cluster. Click the icon for more info.'}
              required={true}
            ></LensLabel>
          )}
          options={availableCosmosClusters}
          selectedKey={cluster}
          onChange={(ev, option) => {
            setCluster(option?.key + '');
            setState({
              ...state,
              cluster: option?.key + '',
            });
          }}
        />
      </Stack.Item>
      <Stack.Item>
        <ElxHierarchicalDropdown
          onRenderLabel={() => (
            <LensLabel
              labelText="Virtual Cluster"
              hintText={
                'The Virtual Cluster. You must have VC admin permissions, and the Cosmos access certificate below must have VC access permissions. Click the icon for more info.'
              }
              required={true}
            ></LensLabel>
          )}
          options={cosmosVcsOptions}
          enableSearchBox={true}
          placeholder="Select the Virtual Cluster name"
          selectedKey={virtualCluster}
          onChange={(ev, option) => {
            setVirtualCluster(option?.key + '');
            setState({
              ...state,
              vcName: option?.key + '',
            });
          }}
        />
      </Stack.Item>
      <Stack.Item>
        <LensLabel
          labelText={'Use AAD Authentication'}
          hintText={
            'When checked uses AAD authentication to access cosmos resource, rather than using certificate based authentication.'
          }
        ></LensLabel>
        <Toggle
          label=""
          checked={state.useAadAuthentication || false}
          onChange={(e: React.MouseEvent<HTMLElement>, checked?: boolean) => {
            setState({ ...state, useAadAuthentication: checked || false });
          }}
          onText="AAD Based Authentication"
          offText="Certificate Based Authentication"
        />
      </Stack.Item>

      {!state.useAadAuthentication && (
        <>
          <Stack.Item>
            <LensLabel
              labelText="Cosmos Access Certificate"
              hintText={`Cosmos Access Certificate`}
              required={true}
            ></LensLabel>
            <ChoiceGroup
              selectedKey={certifcateOption}
              options={options}
              onChange={(ev, option: IChoiceGroupOption | undefined) => {
                setCertifcateOption(option?.key);
                //clear the cosmos specific form state on authentication method change
                setState({
                  name: state.name,
                  authenticationMode: state.authenticationMode,
                  ...(state.applicationId && {
                    applicationId: state.applicationId,
                  }),
                  ...(state.tenant && { tenant: state.tenant }),
                  type: ConnectionDataSourceType.Cosmos,
                  ...(state.isImported && {
                    isImported: state.isImported,
                  }),
                  id: state.id,
                  cluster: state.cluster,
                  vcName: state.vcName,
                });
              }}
            />
          </Stack.Item>
          <Stack.Item>
            {certifcateOption === 'LensCertificate' && (
              <>
                <p>
                  Geneva Orchestrator will use certificate{' '}
                  <strong>
                    'Certificate://MSIT CA
                    Z2/jobrunner.analytics.cloudes.phx.gbl'
                  </strong>
                  <ElxActionButton
                    iconProps={{ iconName: 'Copy' }}
                    onClick={() => {
                      navigator.clipboard.writeText(
                        'Certificate://MSIT CA Z2/jobrunner.analytics.cloudes.phx.gbl'
                      );
                    }}
                  />
                  to access the Cosmos Virtual Cluster. Please ensure that this
                  certificate has VC access permissions.
                </p>
                <p>
                  {state.cluster && state.vcName && (
                    <>
                      To manage the virtual cluster access control, please click{' '}
                      <Link
                        href={`https://aad.${
                          state.cluster
                        }.osdinfra.net/VCManagement/ChangeSecurityGroups?cluster=${
                          orchestratorConstants.VcMgtmCosmosClusterMap[
                            state.cluster + ''
                          ]
                        }&vc=${state.vcName}&adminVCNames=${state.vcName}`}
                        target="_blank"
                      >
                        here to modify access controls
                      </Link>
                      .
                    </>
                  )}
                </p>
              </>
            )}
            {certifcateOption === 'CustomCertificate' && (
              <>
                <ElxTextField
                  onRenderLabel={() => (
                    <LensLabel
                      labelText="Custom Certificate"
                      hintText={`The URI of the certificate in Key Vault.
          The Key Vault Authentication Mode below must have read access to the Key Vault certificate,
          and the certificate must have VC access permissions.
          Click the icon for more info.`}
                      required={true}
                    ></LensLabel>
                  )}
                  placeholder="https://<key_vault>.vault.azure.net/secrets/<secret_name>"
                  onChange={(e, value) => {
                    setState({ ...state, certificateSecretUri: value || '' });
                  }}
                  value={state.certificateSecretUri}
                />
                <p>
                  Geneva Orchestrator will use the custom certificate to access
                  the Cosmos Virtual Cluster. Please ensure that the Key Vault
                  authentication credential below has read access to the Key
                  Vault certificate, and please ensure that this certificate has
                  VC access permissions.
                </p>
                <p>
                  {state.cluster && state.vcName && (
                    <>
                      To manage the virtual cluster access control, please click{' '}
                      <Link
                        href={`https://aad.${
                          state.cluster
                        }.osdinfra.net/VCManagement/ChangeSecurityGroups?cluster=${
                          orchestratorConstants.VcMgtmCosmosClusterMap[
                            state.cluster + ''
                          ]
                        }&vc=${state.vcName}&adminVCNames=${state.vcName}`}
                        target="_blank"
                      >
                        here to modify access controls
                      </Link>
                      .
                    </>
                  )}
                </p>
              </>
            )}
          </Stack.Item>
        </>
      )}

      <Stack.Item align="end">
        <ElxActionButton
          iconProps={{
            iconName: 'Settings',
          }}
          text={'See Advanced Policy Settings'}
          onClick={() => {
            setShowPolicySettings(!showPolicySettings);
          }}
        />
        <ElxActionButton
          iconProps={{
            iconName: 'ChevronDown',
          }}
          onClick={() => {
            setShowPolicySettings(!showPolicySettings);
          }}
        />
      </Stack.Item>

      {showPolicySettings && (
        <Stack.Item>
          <div style={descriptionTextBoldStyle}> Cosmos Token Policy</div>
          <div style={descriptionTextStyle}>
            This is the policy settings for Cosmos Connection for token
            right-sizing which allows users to set the number of tokens for
            Cosmos jobs. If Use Token Optimizer is disabled, then the settings
            will not be applied to Cosmos jobs. To have it apply, please enable
            Use Token Optimizer.
          </div>

          <Toggle
            label={
              <LensLabel
                labelText="Use Token Optimizer"
                hintText={`Flag to enable or disable token right-sizing`}
              ></LensLabel>
            }
            inlineLabel
            onText="Enable"
            offText="Disable"
            checked={useTokenOptimizer}
            onChange={(
              ev: React.MouseEvent<HTMLElement>,
              checked?: boolean
            ) => {
              setUseTokenOptimizer(checked || false);
              setState({
                ...state,
                policy: {
                  tokenPolicy: {
                    useTokenOptimizer: checked || false,
                    minTokenAllocation: minTokenAllocation,
                    maxTokenAllocation: maxTokenAllocation,
                    minJobHistoryCount: minJobHistoryCount,
                  },
                },
              });
            }}
          />

          <LensLabel
            labelText="Min Token Allocation (default value: 2)"
            hintText={`Right-sizing lower bound. Default to 2`}
          ></LensLabel>
          <TextField
            value={minTokenAllocation}
            type="number"
            min={0}
            step={1}
            onChange={(
              event: React.SyntheticEvent<HTMLElement, Event>,
              newValue?: string | undefined
            ) => {
              setMinTokenAllocation(newValue + '');
              setState({
                ...state,
                policy: {
                  tokenPolicy: {
                    useTokenOptimizer: useTokenOptimizer,
                    minTokenAllocation: newValue + '',
                    maxTokenAllocation: maxTokenAllocation,
                    minJobHistoryCount: minJobHistoryCount,
                  },
                },
              });
            }}
          />
        </Stack.Item>
      )}

      {showPolicySettings && (
        <Stack.Item>
          <LensLabel
            labelText="Max Token Allocation "
            hintText={`Right-sizing upper bound: must be greater than the lower bound. Token allocated to a job can't go above the VC capacity.`}
          ></LensLabel>
          <TextField
            defaultValue={maxTokenAllocation}
            type="number"
            min={0}
            step={1}
            onChange={(
              event: React.SyntheticEvent<HTMLElement, Event>,
              newValue?: string | undefined
            ) => {
              setMaxTokenAllocation(newValue + '');
              setState({
                ...state,
                policy: {
                  tokenPolicy: {
                    useTokenOptimizer: useTokenOptimizer,
                    minTokenAllocation: minTokenAllocation,
                    maxTokenAllocation: newValue + '',
                    minJobHistoryCount: minJobHistoryCount,
                  },
                },
              });
            }}
            onGetErrorMessage={(value: string) => {
              const inputValue = parseInt(value);
              const minToken = parseInt(minTokenAllocation);
              if (inputValue <= minToken) {
                return 'Max Token Allocation needs to be greater than Min Token Allocation.';
              }
            }}
          />
        </Stack.Item>
      )}

      {showPolicySettings && (
        <Stack.Item>
          <LensLabel
            labelText="Min Job History Count (default value: 3)"
            hintText={`Minimum number of historical job instances required. Default to 3 if not provided. It must be at least 1.`}
          ></LensLabel>
          <TextField
            defaultValue={minJobHistoryCount}
            type="number"
            min={1}
            step={1}
            onChange={(
              event: React.SyntheticEvent<HTMLElement, Event>,
              newValue?: string | undefined
            ) => {
              setMinJobHistoryCount(newValue + '');
              setState({
                ...state,
                policy: {
                  tokenPolicy: {
                    useTokenOptimizer: useTokenOptimizer,
                    minTokenAllocation: minTokenAllocation,
                    maxTokenAllocation: maxTokenAllocation,
                    minJobHistoryCount: newValue + '',
                  },
                },
              });
            }}
            onGetErrorMessage={(value: string) => {
              const inputValue = parseInt(value);
              if (inputValue < 1) {
                return 'Min Job History Count needs to be greater than or equal to 1.';
              }
            }}
          />
        </Stack.Item>
      )}
    </Stack>
  ) : (
    <></>
  );
};

export default CosmosSelector;
