import {
  FontSizes,
  FontWeights,
  IStackProps,
  IStackStyles,
  IStackTokens,
  memoizeFunction,
  mergeStyleSets,
} from '@fluentui/react';
import { IElxContainerProps } from '@elixir/components';
import { useLensShellTheme } from 'features/shell/lensShellStyles';

export const themedSmallStackTokens: IStackTokens = {
  childrenGap: '3',
  padding: 'm',
};

export const smallStackTokens: IStackTokens = {
  childrenGap: 1,
};

export const wideSpacingStackTokens: IStackTokens = {
  childrenGap: 10,
};
export const actionsGapStackTokens: IStackTokens = {
  childrenGap: 1,
};

export const workspacePadding: IStackStyles = {
  root: { paddingLeft: '16px' },
};

export const useWorkspaceStyles = memoizeFunction(() => {
  const theme = useLensShellTheme();
  const editContainerStyle = {
    background: theme.palette.white,
    border: '1px solid ' + theme.palette.neutralLight,

    boxShadow:
      '0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13)',
    borderRadius: '4px',
    padding: '16px',
  };
  const headerStyle = { fontSize: '16px', color: theme.palette.neutralPrimary };

  return mergeStyleSets({
    rootStackStyle: {
      background: theme.palette.neutralLighterAlt,
      childrenGap: 1,
    },
    editContainerStyle: { ...editContainerStyle, width: '703px' },
    aadAppCardStyle: { ...editContainerStyle, width: '585px' },
    aadAppStyle: { ...editContainerStyle, boxShadow: '', width: '600px' },
    editContainerHeaderStyle: { ...headerStyle, fontWeight: '700' },
    innerHeaderStyle: headerStyle,
    commandBarStackStyle: {
      width: '100%',
      paddingLeft: '10px',
      background: theme.palette.white,
    },
    historyCommandBarStackStyle: {
      width: '100%',
      paddingLeft: '35px',
      background: theme.palette.white,
    },
    pivotContainerStyle: {
      background: theme.palette.white,
    },
    workspaceNameStyle: {
      color: theme.palette.neutralPrimary,
      paddingLeft: '10px',
    },
    labelStyle: { marginTop: 10 },
    modalSpanStyle: { 'word-wrap': 'break-word' },
    inputStyle: { width: '300px' },
    orchestrationeditContainerStyle: {
      ...editContainerStyle,
      width: '1000px',
    },
    wideEditContainerStyles: { childrenGap: 10 },
    createWSRootStackStyle: {
      background: theme.palette.white,
      childrenGap: 1,
    },
    createEditContainerHeaderStyle: { ...headerStyle, fontWeight: '600' },
  });
});

export const useDataConnectionStyles = memoizeFunction(() => {
  const { palette } = useLensShellTheme();
  return mergeStyleSets({
    red: palette.red,
    green: palette.green,
    orange: palette.orangeLighter,
    yellow: palette.yellowDark,
    breadCrumbStyle: {
      fontSize: '12px',
      paddingLeft: '20px',
      // paddingRight: '55%',
    },
  });
});
export const useHistoryStyles = memoizeFunction(() => {
  return mergeStyleSets({
    summaryStyle: {
      width: '500px',
      height: '100px',
      background: '#FAF9F8',
      border: '1px solid #E1DFDD',
      boxSizing: 'border-box',
      borderRadius: '4px',
    },
    compareSummaryStyle: {
      width: '650px',
      height: '100px',
      background: '#FAF9F8',
      border: '1px solid #E1DFDD',
      boxSizing: 'border-box',
      borderRadius: '4px',
    },
    summaryPadding: { padding: '5px' },
    summaryTitlesStyle: { fontWeight: '500' },
  });
});

export const DataConnectionOptionsTableContainerProps = {
  compact: true,
  styles: {
    headerContainer: 'hidden',
    headerComponent: {
      '@media (min-width: 1024px)': {
        paddingTop: '0px',
      },
    },
    loading: {
      padding: '50%',
    },
    body: {
      minHeight: '120px',
      maxHeight: '550px',
      maxWidth: '600px',
    },
  },
} as IElxContainerProps;

export const panelStyles = {
  root: { overflowY: 'hidden !important' },
  scrollableContent: { overflowY: 'auto', height: '100%' },
  content: {
    background: 'white !important',
    padding: '0px',
    height: '100%',
    overflowY: 'auto',
  },
  main: {
    background: 'white !important',
    overflowY: 'hidden',
  },
  header: {
    background: 'white !important',
    justifyContent: 'space-between',
  },
  commands: { background: 'white !important' },
  navigation: {
    background: 'white !important',
    fontSize: '14px',
    justifyContent: 'space-between',
    paddingLeft: '2rem',
    paddingRight: '1rem',
  },
};

export const stackItemFormStyles: Partial<IStackProps> = {
  tokens: { padding: '0px 0px 0px 14px' },
  styles: { root: { width: 600 } },
};
export const textInputStyle = { fieldGroup: { height: 32 } };

export const breadCrumbStyles = { root: { fontSize: '14px' } };
export const panelContentStackStyle: Partial<IStackProps> = {
  styles: {
    root: { paddingLeft: '32px', paddingTop: '32px' },
  },
  tokens: { childrenGap: 'm' },
};
export const panelContentStackStyle2: Partial<IStackProps> = {
  styles: { root: { padding: 'm', width: 600 } },
  tokens: { childrenGap: 'm' },
};

export const titleTextStyle = {
  fontSize: FontSizes.size18,
  fontWeight: FontWeights.semibold,
};

export const descriptionTextStyle = { fontSize: FontSizes.size14 };
export const descriptionTextBoldStyle = {
  fontSize: FontSizes.size14,
  fontWeight: FontWeights.bold,
};
