export const LensTelemetryConstants = {
  CompletionStatus: {
    Success: 'Successfully',
    Canceled: 'Canceled',
    Failed: 'Failed',
    Aborted: 'Aborted',
  },

  EventNames: {
    LargestContentfulPaint: 'LargestContentfulPaint',
    ResourceTiming: 'ResourceTiming',
    FirstInputDelay: 'FirstInputDelay',
    StateChange: 'StateChange',
    DashboardLoadingCompleted: 'DashboardLoadingCompleted',
    DashboardLoadingCancelled: 'DashboardLoadingCancelled',
    VisualizationLoaded: 'VisualizationLoaded',
    SearchStarted: 'SearchStarted',
    SearchCompleted: 'SearchCompleted',
    QueryStarted: 'QueryStarted',
    QueryCompleted: 'QueryCompleted',
    QueryE2ECompleted: 'QueryE2ECompleted',
    TabTransitionCompleted: 'TabTransitionCompleted',
    GridLoadingCompleted: 'GridLoadingCompleted',
    TimeChartLoadingStarted: 'TimeChartLoadingStarted',
    TimeChartLoadingFirstResultsArrived: 'TimeChartLoadingFirstResultsArrived', // When the first results arrived
    TimeChartLoadingInProcess: 'TimeChartLoadingInProcess', // When results arrived
    TimeChartQueryFailed: 'TimeChartQueryFailed', // Failure with facets loading, facets loading process is continuing
    TimeChartLoadingCompleted: 'TimeChartLoadingCompleted',
    FacetsLoadingStarted: 'FacetsLoadingStarted',
    FacetsLoadingCompleted: 'FacetsLoadingCompleted',
    FacetsBatchQueryFailed: 'FacetsBatchQueryFailed',
    StaticFacetsLoadingCompleted: 'StaticFacetsLoadingCompleted',
    DynamicFacetsSchemaLoadingCompleted: 'DynamicFacetsSchemaLoadingCompleted',
    DynamicFacetsLoadingCompleted: 'DynamicFacetsLoadingCompleted',
    SchemaLoadingCompleted: 'SchemaLoadingCompleted',
    RenderVisualizationCompleted: 'RenderVisualizationCompleted',
    Error: 'Error',
    GetApplicationDetails: 'GetApplicationDetails',
    GetResourceToken: 'GetResourceToken',
    ConfigurationReceived: 'ConfigurationReceived',
    ConfigurationError: 'ConfigurationError',
    CustomerQuery: 'CustomerQuery',
    IntellisenseProvider: 'IntellisenseProvider',
    ConfigurationStoreRequest: 'ConfigurationStoreRequest',
    ApplicationIsLoaded: 'ApplicationIsLoaded',
    PerformanceEntry: 'PerformanceEntry',
    LensLaunch: 'LensLaunch',
    ClientSideLogin: 'ClientSideLogin',
    FatalError: 'FatalError',
    UnhandledException: 'UnhandledException',
    Feedback: 'Feedback',
    FeedbackPaneOpen: 'FeedbackPaneOpen',
    FeedbackPaneClose: 'FeedbackPaneClose',
    InvalidFeedback: 'InvalidFeedback',
    SchemaNotSortedException: 'SchemaNotSortedException',
    ExportToExcel: 'ExportToExcel',
    ExportToPowerBI: 'ExportToPowerBI',
    TutorialWasOpened: 'TutorialWasOpened',
    CommandClicked: 'CommandClicked',
    OnBeforeUnload: 'OnBeforeUnload',
    QueryPartialResults: 'QueryPartialResults', // Telemetry that indicates that the results arrived from array are partial
    ShareLinkToQuery: 'ShareLinkToQuery',
    JumpStartView: 'JumpStartView',
    UserProfileLoadingFailure: 'UserProfileLoadingFailure',
    GridAction: 'GridAction',
    VideoAction: 'VideoAction',
    ErrorPage: 'ErrorPage',
    HttpRequestSent: 'HttpRequestSent',
    Import: 'Import',
    PrettifyQuery: 'PrettifyQuery',
    MultiSeriesProcessLegend: 'MultiSeriesProcessLegend',
    CreateYAxisLabel: 'CreateYAxisLabel',
    SearchObjectCompleted: 'SearchObjectCompleted',
    SearchObjectStarted: 'SearchObjectStarted',
    SearchObjectFailed: 'SearchObjectFailed',
    SearchObjectSelected: 'SearchObjectSelected',
    AutoDash: 'AutoDash',
    VisualizationClickedOnVisStrip: 'VisualizationClickedOnVisStrip',
    SampleDashboardUsed: 'SampleDashboardUsed',
    HistoryButtonClicked: 'HistoryButtonClicked',
    ExportData: 'ExportData',
    RenderModeUsed: 'RenderModeUsed',
    TimeFilterTypeUsed: 'TimeFilterTypeUsed',
    OmniSearches: 'OmniSearches',
    LeftNavSearches: 'LeftNavSearches',
    DashboardShared: 'DashboardShared',
    JumpStartSlideClicked: 'JumpStartSlideClicked',
    PinToDashboardClicked: 'PinToDashboardClicked',
    DeepDashboardCopy: 'DeepDashboardCopy',
    ImportDashboard: 'ImportDashboard',
    VisRenderPerformance: 'VisRenderPerformance',
    CheckUserType: 'CheckUserType',
    ClusterLeftNav: {
      Initialized: 'ClusterLeftNavInitialized',
      Scrolled: 'ClusterLeftNavScrolled',
      CollapsedAll: 'ClusterLeftNavCollapsedAll',
      DataSourceRemoved: 'ClusterLeftNavClusterRemoved',
      TextFiltered: 'ClusterLeftNavTextFiltered',
      ClusterDownloaded: 'ClusterLeftNavClusterDownloaded',
      DatabaseDownloaded: 'ClusterLeftNavDatabaseDownloaded',
      DatabaseExpanded: 'ClusterLeftNavDatabaseExpanded',
      ClusterExpanded: 'ClusterLeftNavClusterExpanded',
    },
    Cache: {
      Initialized: 'CacheInitialized',
      AutoRefreshed: 'CacheAutoRefreshed',
    },
    Relationship: {
      Success: {
        DashSaved: 'DashSavedRelationship',
        SearchSaved: 'SearchSavedRelationship',
        VisualizationSaved: 'VisualizationSavedRelationship',
        Delete: 'DeletedRelationship',
      },
      Failure: {
        DashSaved: 'DashSavedRelationshipError',
        SearchSaved: 'SearchSavedRelationshipError',
        VisualizationSaved: 'VisualizationSavedRelationshipError',
        Delete: 'DeletedRelationshipError',
      },
    },
    ReferenceGraph: {
      Clicked: 'ReferenceGraphButtonClicked',
      Launched: 'ReferenceGraphLaunched',
      QueryGenerated: 'ReferenceGraphQueryGenerated',
    },
    Aggregations: {
      Used: 'AggregationsUsed',
    },
    FacetDropdown: {
      LoadingCompleted: 'FacetDropdownLoadingCompleted',
      LoadingCancelled: 'FacetDropdownLoadingCancelled',
      LoadingError: 'FacetDropdownLoadingError',
    },
    ExploreActions: {
      New: 'ExploreAction.New',
      Open: 'ExploreAction.Open',
      Save: 'ExploreAction.Save',
      SaveQueryAs: 'ExploreAction.SaveQueryAs',
      SaveVisualizationAs: 'ExploreAction.SaveVisualizationAs',
      CopyVisualization: 'ExploreAction.CopyVisualization',
      CopyToClipboard: 'ExploreAction.CopyToClipboard',
      ShareQuery: 'ExploreAction.ShareQuery',
      ShareVisualization: 'ExploreAction.ShareVisualization',
      SendEmail: 'ExploreAction.SendEmail',
      LinkToASavedQuery: 'ExploreAction.LinkToASavedQuery',
      GenerateTable: 'ExploreAction.GenerateTable',
      ImportJson: 'ExploreAction.ImportJson',
      ExportToPowerBI: 'ExploreAction.Export.PowerBI',
      ExportCSVAllColumns: 'ExploreAction.Export.CSVAllColumns',
      ExportCSVDisplayedColumns: 'ExploreAction.Export.CSVDisplayedColumns',
      ShareALinkToQuery: 'ExploreAction.Export.ShareALinkToQuery',
      OpenQueryInLensExplorer: 'ExploreAction.Export.OpenQueryInLensExplorer',
      OpenQueryInKustoExplorer: 'ExploreAction.Export.OpenQueryInKustoExplorer',
      ExportToJarvisDashboard: 'ExploreAction.Export.ExportToJarvisDashboard',
      ExportQueryJson: 'ExploreAction.Export.ExportQueryJson',
      ExportVisualizationJson: 'ExploreAction.Export.ExportVisualizationJson',
      ExportToKustoCLI: 'ExploreAction.Export.KustoCLI',
    },
    MetadataActions: {
      // metadata cards
      SetColumnDescription: 'MetadataActions.SetColumnDescription',
      SetColumnTags: 'MetadataActions.SetColumnTags',
      SetTableDescription: 'MetadataActions.SetTableDescription',
      SetTableService: 'MetadataActions.SetTableService',
      SetTableTags: 'MetadataActions.SetTableTags',
      ShowColumnMetadata: 'MetadataActions.ShowColumnMetadata',
      ShowTableMetadata: 'MetadataActions.ShowTableMetadata',
    },
    DashboardActions: {
      New: 'DashboardAction.New',
      Open: 'DashboardAction.Open',
      StandaloneSave: 'DashboardAction.StandaloneSave',
      AddFilter: 'DashboardAction.AddFilter',
      Save: 'DashboardAction.Save',
      SaveAs: 'DashboardAction.SaveAs',
      AddPanel: 'DashboardAction.AddPanel',
      AddInputButtonClicked: 'DashboardAction.AddInputButtonClicked',
      ConfigureDashboard: 'DashboardAction.ConfigureDashboard',
      Share: 'DashboardAction.Share',
      SendEmail: 'DashboardAction.SendEmail',
      CopyToWorkspace: 'DashboardAction.CopyToWorkspace',
      AddToFavorites: 'DashboardAction.AddToFavorites',
      FullScreen: 'DashboardAction.FullScreen',
      ImportJson: 'DashboardAction.ImportJson',
      ExportJson: 'DashboardAction.ExportJson',
      ClonePanel: 'DashboardAction.ClonePanel',
      KeyboardShortcutSave: 'DashboardAction.KeyboardShortcutSave',
      ScheduleNotification: 'DashboardAction.ScheduleNotification',
      MigrateToGrafana: 'DashboardAction.MigrateToGrafana',
      MigrateToGrafanaError: 'DashboardAction.MigrateToGrafanaError',
    },
    SavedObjectActions: {
      Import: 'SavedObjectActions.Import',
      Export: 'SavedObjectActions.Export',
      CopyToWorkspace: 'SavedObjectActions.CopyToWorkspace',
    },
    DashboardPanels: {
      // All common panel actions.
      Add: 'DashboardPanel.Add',
      Remove: 'DashboardPanel.Remove',
      Inputs: {
        // Input panel specific actions
        RequiredConditionModified:
          'DashboardPanel.Inputs.RequiredConditionModified',
        MakeItInputPanel: 'DashboardPanel.Inputs.MakeItInputPanel',
      },
    },
    HomepageActions: {
      AddConnection: 'HomepageAction.AddConnection',
      ExploreData: 'HomepageAction.ExploreData',
      OpenDashboard: 'HomepageAction.OpenDashboard',
      ManageWorkspace: 'HomepageAction.ManageWorkspace',
      SuggestIdea: 'HomepageAction.SuggestIdea',
      ReportBug: 'HomepageAction.ReportBug',
      ReleaseNotes: 'HomepageAction.ReleaseNotes',
      LensWiki: 'HomepageAction.LensWiki',
      StackOverflow: 'HomepageAction.StackOverflow',
      KustoDocumentation: 'HomepageAction.KustoDocumentation',
      GenevaAnalyticsWiki: 'HomepageAction.GenevaAnalyticsWiki',
      SubscribeToNewsletter: 'HomepageAction.SubscribeToNewletter',
      SelfHelp: 'HomepageAction.SelfHelp',
    },
    Discovery: {
      Search: 'Discovery.Search',
      NextPage: 'Discovery.Page',
      ItemClick: 'Discovery.ItemClick',
    },
    MyTeamPage: {
      Load: 'MyTeamPage.Load',
      WorkspaceCollaborators: {
        All: 'MyTeamAction.WorkspaceCollaborators.All',
        Dashboards: 'MyTeamAction.WorkspaceCollaborators.Dashboards',
        Visualizations: 'MyTeamAction.WorkspaceCollaborators.Visualizations',
        Data: 'MyTeamAction.WorkspaceCollaborators.Data',
        Queries: 'MyTeamAction.WorkspaceCollaborators.Queries',
        Jobs: 'MyTeamAction.WorkspaceCollaborators.Jobs',
        Workspaces: 'MyTeamAction.WorkspaceCollaborators.Workspaces',
        SeeMore: 'MyTeamAction.WorkspaceCollaborators.SeeMore',
        Toggle: 'MyTeamAction.WorkspaceCollaborators.Toggle',
      },
      ServiceTeam: {
        All: 'MyTeamAction.ServiceTeam.All',
        Data: 'MyTeamAction.ServiceTeam.Data',
        Jobs: 'MyTeamAction.ServiceTeam.Jobs',
        SeeMore: 'MyTeamAction.ServiceTeam.SeeMore',
        Toggle: 'MyTeamAction.ServiceTeam.Toggle',
      },
      Assets: {
        Click: 'MyTeamAction.Asset.Click',
        Infocard: 'MyTeamAction.Asset.InfocardClick',
        ExploreItem: 'MyTeamAction.Asset.ExploreItem',
        ToggleFavorite: 'MyTeamAction.Asset.ToggleFavorite',
      },
      MyItems: {
        Toggle: 'MyTeamAction.MyItems.Toggle',
        SwitchToRecent: 'MyTeamAction.MyItems.SwitchToRecent',
        SwitchToFavorites: 'MyTeamAction.MyItems.SwitchToFavorites',
      },
      SidebarToggle: 'MyTeamAction.ToggleSidebar',
    },
    NavbarActions: {
      ToggleNotificationList: 'NavbarAction.ToggleNotificationList',
      ReportBug: 'NavbarAction.Feedback.ReportBug',
      StackOverflow: 'NavbarAction.Feedback.StackOverflow',
      SuggestIdea: 'NavbarAction.Feedback.SuggestIdea',
      LensWiki: 'NavbarAction.Help.LensWiki',
      KustoDocumentation: 'NavbarAction.Help.KustoDocumentation',
      SelfHelp: 'NavbarAction.Help.SelfHelp',
      PrivacyTerms: 'NavbarAction.Help.PrivacyTerms',
    },
    WorkspaceActions: {
      NewWorkspace: 'WorkspaceAction.NewWorkspace',
      DisplayAllWorkspaces: 'WorkspaceAction.Display.All',
      DisplayFavoriteWorkspaces: 'WorkspaceAction.Display.Favorite',
      DisplayOwnedWorkspaces: 'WorkspaceAction.Display.Owned',
      DisplayReadWorkspaces: 'WorkspaceAction.Display.Read',
      DisplayWriteWorkspaces: 'WorkspaceAction.Display.Write',
      DisplayOrchestrateWorkspaces: 'WorkspaceAction.Display.Orchestrate',
      DisplayAdminWorkspaces: 'WorkspaceAction.Display.Admin',
      DisplayNoAccessWorkspaces: 'WorkspaceAction.Display.None',
      RefreshWorkspaceList: 'WorkspaceAction.RefreshList',
      Edit: 'WorkspaceAction.Edit',
      ViewHistory: 'WorkspaceAction.ViewHistory',
      ViewHistoryEntry: 'WorkspaceAction.ViewHistoryEntry',
      CompareHistoryEntries: 'WorkspaceAction.CompareHistoryEntries',
      Switch: 'WorkspaceAction.Switch',
      RequestReadAccess: 'WorkspaceAction.RequestReadAccess',
      RequestWriteAccess: 'WorkspaceAction.RequestWriteAccess',
      AddWorkspaceDataSource: 'WorkspaceAction.AddWorkspaceDataSource',
      EditWorkspaceDataSource: 'WorkspaceAction.EditWorkspaceDataSource',
      RemoveWorkspaceDataSource: 'WorkspaceAction.RemoveWorkspaceDataSource',
      Save: 'WorkspaceAction.Save',
      Delete: 'WorkspaceAction.Delete',
      Migrate: 'WorkspaceAction.Migrate',
      AddApplication: 'WorkspaceAction.AddApplication',
      RemoveApplication: 'WorkspaceAction.RemoveApplication',
      SaveDataSource: 'WorkspaceAction.SaveDataSource',
      UpdateDataSource: 'WorkspaceAction.UpdateDataSource',
      RemoveDataSource: 'WorkspaceAction.RemoveDataSource',
      MigrateOrchestratorProject: 'WorkspaceAction.MigrateOrchestratorProject',
      ViewDataConnection: 'WorkspaceAction.ViewDataConnection',
      ViewAadApplications: 'WorkspaceAction.ViewAadApplications',
      ViewOrchestration: 'WorkspaceAction.ViewOrchestration',
      MigrateWorkspaceToAGC: 'WorkspaceAction.MigrateWorkspaceToAGC',
      ExportWorkspaceParameters: 'WorkspaceAction.ExportWorkspaceParameters',
      EnableOrchestration: 'WorkspaceAction.EnableOrchestration',
      CreateGrafanaInstance: 'WorkspaceAction.CreateGrafanaInstance',
      CreateGrafanaInstanceError: 'WorkspaceAction.CreateGrafanaInstanceError',
    },
    VersionActions: {
      Job: {
        Restore: 'VersionActions.Job.Restore',
        GetVersion: 'VersionActions.Job.GetVersion',
        VersionMetadata: 'VersionActions.Job.VersionMetadata',
      },
    },
    JobActions: {
      DisplayJobsPerWorkspace: 'JobActions.DisplayJobsPerWorkspace',
      DisplayJobInstancesPerJob: 'JobActions.DisplayJobInstancesPerJob',
      JobManagementActionButtons: {
        Edit: 'JobActions.JobManagementActionButtons.Edit',
        Enable: 'JobActions.JobManagementActionButtons.Enable',
        Disable: 'JobActions.JobManagementActionButtons.Disable',
        Delete: 'JobActions.JobManagementActionButtons.Delete',
        Create: 'JobActions.JobManagementActionButtons.Create',
        Refresh: 'JobActions.JobManagementActionButtons.Refresh',
        ScheduleJobInstances:
          'JobActions.JobManagementActionButtons.ScheduleJobInstances',
        RunWithParameters:
          'JobActions.JobManagementActionButtons.RunWithParameters',
        RunBackfill: 'JobActions.JobManagementActionButtons.RunBackfill',
        Export: 'JobActions.JobManagementActionButtons.Export',
        Import: 'JobActions.JobManagementActionButtons.Import',
        Favorite: 'JobActions.JobManagementActionButtons.Favorite',
        Unfavorite: 'JobActions.JobManagementActionButtons.Unfavorite',
        Clone: 'JobActions.JobManagementActionButtons.Clone',
        CopyToWorkspace:
          'JobActions.JobManagementActionButtons.CopyToWorkspace',
      },
      JobInstanceActionButtons: {
        CancelQueued: 'JobActions.JobInstanceActionButtons.CancelQueued',
        CancelRunning: 'JobActions.JobInstanceActionButtons.CancelRunning',
        CancelRetry: 'JobActions.JobInstanceActionButtons.CancelRetry',
        RerunJobInstance:
          'JobActions.JobInstanceActionButtons.RerunJobInstance',
        CancelJobInstance:
          'JobActions.JobInstanceActionButtons.CancelJobInstance',
        TerminateJobInstance:
          'JobActions.JobInstanceActionButtons.TerminateJobInstance',
        ViewExecutionLogs:
          'JobActions.JobInstanceActionButtons.ViewExecutionLogs',
        ViewParameters: 'JobActions.JobInstanceActionButtons.ViewParameters',
      },
      JobAuthoring: {
        Create: 'JobActions.JobAuthoring.Create',
        Update: 'JobActions.JobAuthoring.Update',
        Restore: 'JobActions.JobAuthoring.Restore',
        RunOnce: 'JobActions.JobAuthoring.RunOnce',
        Enable: 'JobActions.JobAuthoring.Enable',
        Disable: 'JobActions.JobAuthoring.Disable',
        Delete: 'JobActions.JobAuthoring.Delete',
        ChangeUserAuthConnectionsIsVisible:
          'JobActions.JobAuthoring.ChangeUserAuthConnectionsIsVisible',
        ChangeUserAuthConnectionsUsingUserAuth:
          'JobActions.JobAuthoring.ChangeUserAuthConnectionsUsingUserAuth',
        ChangeUserAuthConnectionsUsingAppAuth:
          'JobActions.JobAuthoring.ChangeUserAuthConnectionsUsingAppAuth',
      },
      CosmosJob: {
        Create: 'JobActions.CosmosJob.Create',
        Update: 'JobActions.CosmosJob.Update',
        Query: 'JobActions.CosmosJob.Query',
      },
      Create: 'JobAction.Create',
      Update: 'JobAction.Update',
      Enable: 'JobAction.Enable',
      Disable: 'JobAction.Disable',
      Delete: 'JobAction.Delete',
      ScheduleJobInstances: 'JobActions.ScheduleJobInstances',
      ScheduleJobInstance: 'JobActions.ScheduleJobInstance',
      CancelJobInstance: 'JobActions.CancelJobInstance',
      CancelJobInstancesInList: 'JobActions.CancelJobInstancesInList',
      TerminateJobInstance: 'JobActions.TerminateJobInstance',
      RerunJobInstance: 'JobActions.RerunJobInstance',
      FolderWorkflow: 'JobActions.FolderWorkflow',
    },
    FeedbackEvents: {
      Send: 'Feedback.Send',
      Failed: 'Feedback.Failed',
    },
    Features: {
      Enabled: 'Feature.Enabled',
      Disabled: 'Feature.Disabled',
      State: 'Feature.State',
    },
    TimeFilter: {
      TimeEditFrom: {
        FilterCapsule: 'TimeFilter.TimeEditFrom.FilterCapsule',
        DashboardIcon: 'TimeFilter.TimeEditFrom.DashboardIcon',
      },
    },
    CustomFilter: {
      EditedFrom: {
        FilterBarPlusButton: 'CustomFilter.EditedFrom.FilterBarPlusButton',
        DashboardIcon: 'CustomFilter.EditedFrom.DashboardIcon',
      },
    },
    ColorSettings: {
      IndividualColorMode: {
        OptionSelected: 'ColorSettings.IndividualColorMode.OptionSelected',
      },
      RangeColorMode: {
        OptionSelected: 'ColorSettings.RangeColorMode.OptionSelected',
        MetricChanged: 'ColorSettings.RangeColorMode.MetricChanged',
      },
      EditColors: 'ColorSettings.EditColors',
      ChangesApplied: 'ColorSettings.ChangesApplied',
      ChangesCancelled: 'ColorSettings.ChangesCancelled',
    },
    SearchBar: {
      SearchProcessingTime: 'SearchBar.SearchProcessingTime',
      FilterAdded: 'SearchBar.FilterAdded',
      FilterRemoved: 'SearchBar.FilterRemoved',
    },
    JoinSuggestions: {
      AppendJoin: 'JoinSuggestions.AppendJoin',
      FacetSuggestionClicked: 'JoinSuggestions.FacetSuggestionClicked',
      GetManifestProfileResults: 'JoinSuggestions.GetManifestProfileResults',
      GetInteractiveProfileResults:
        'JoinSuggestions.GetInteractiveProfileResults',
    },
    Preloader: {
      Userprofile: 'Preloader.Userprofile',
      Workspace: 'Preloader.Workspace',
      Visualization: 'Preloader.Visualization',
      Dashboard: 'Preloader.Dashboard',
      Jobs: 'Preloader.Jobs',
      Job: 'Preloader.Job',
    },
    DataPrep: {
      Open: 'DataPrep.Open',
      AddToQuery: 'DataPrep.AddToQuery',
      CloseArrow: 'DataPrep.CloseArrow',
      CancelButton: 'DataPrep.CancelButton',
      CreateNewCol: 'DataPrep.CreateNewCol',
    },
    UserSettings: {
      Posted: 'UserSettings.Posted',
      NotFound: 'UserSettings.NotFound',
      Filters: {
        Loaded: 'UserSettings.Filters.Loaded',
        NotLoaded: 'UserSettings.Filters.NotLoaded',
      },
      TimeFilter: {
        Loaded: 'UserSettings.Filters.Loaded',
        NotLoaded: 'UserSettings.Filters.NotLoaded',
      },
    },
    SupportNotifications: {
      ShowPopover: 'ShowPopover',
      ClickPopoverSummary: 'ClickPopoverSummary',
      ClickPopoverClose: 'ClickPopoverClose',
      ClickNotificationsAsideCloseButton: 'ClickNotificationsAsideCloseButton',
      ClickSupportNotificationDismiss: 'ClickSupportNotificationDismiss',
      ClickSupportNotificationsDismissAll:
        'ClickSupportNotificationsDismissAll',
      ClickSupportNotificationsRestoreAll:
        'ClickSupportNotificationsRestoreAll',
      ClickActivityLogViewMore: 'ClickActivityLogViewMore',
      ClickActivityLogDismiss: 'ClickActivityLogDismiss',
      ClickActivityLogDismissAll: 'ClickActivityLogDismissAll',
      ClickActivityLogDismissSpecial: 'ClickActivityLogDismissSpecial',
    },
    UiSwitch: {
      ReactToAngular: 'UiSwitch.ReactToAngular',
      AngularToReact: 'UiSwitch.AngularToReact',
    },
  },
};
