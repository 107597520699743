import { ElxTextField } from '@elixir/components';
import { Stack } from '@fluentui/react';
import { LensLabel } from 'utils/lensLabel';
import { SelectorProps } from '../editDataConnectionHelper';

export const StorageAccountSelector = (props: SelectorProps): JSX.Element => {
  const { state, setState } = props;

  return (
    <Stack tokens={{ childrenGap: 16 }}>
      <ElxTextField
        onRenderLabel={() => (
          <LensLabel
            labelText="Azure Storage Account Name"
            hintText={'The Azure Storage Account Name.'}
            required={true}
          ></LensLabel>
        )}
        placeholder="MyStorageAccount"
        value={state.storageAccount || ''}
        onChange={(e, newValue) => {
          setState({
            ...state,
            storageAccount: newValue || '',
            connectionSecretUri: state.connectionSecretUri,
          });
        }}
      />
      <ElxTextField
        onRenderLabel={() => (
          <LensLabel
            labelText="Connection Secret Uri"
            hintText={
              'The Key Vault URI from where to get the key to access the Azure Storage Account.'
            }
            required={true}
          ></LensLabel>
        )}
        placeholder="https://<key_vault>.vault.azure.net/secrets/<secret_name>"
        value={state.connectionSecretUri || ''}
        onChange={(e, newValue) => {
          setState({
            ...state,
            storageAccount: state.storageAccount,
            connectionSecretUri: newValue || '',
          });
        }}
      />
      <ElxTextField
        onRenderLabel={() => (
          <LensLabel
            labelText="End Point Suffix"
            hintText={
              'If the key is the SAS key, then end point suffix deduced from the application tenant, if you need to override it, enter here.'
            }
            required={false}
          ></LensLabel>
        )}
        placeholder="core.windows.net"
        value={state.endpointSuffix || ''}
        onChange={(e, newValue) => {
          setState({
            ...state,
            storageAccount: state.storageAccount,
            connectionSecretUri: state.connectionSecretUri,
            endpointSuffix: newValue || '',
          });
        }}
      />
    </Stack>
  );
};
