export const invalidDataConn = {
  background: '#fde7e9',
  border: '1px solid #ff0000',
  color: 'black',
  fontWeight: 'bold',
};

export const validDataConn = {
  background: '#E9F6E8',
  border: '1px solid #107C10',
  color: 'black',
  fontWeight: 'bold',
};
