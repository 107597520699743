import React, { ComponentType, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { IRenderFunction } from '@fluentui/react/lib/Utilities';
import { History } from 'history';

import { iconProps, LensRoute } from 'app/elxAdapter';
import { matchWithParams } from '@elixir/fx';
import { LensSidebarProps } from './lensSidebar.types';
import {
  elixirSelectors,
  uxSetSidebarDefaultCollapsedAction,
} from '@elixir/fx';

import {
  ElxSidebar,
  IElxSidebarItemProps,
  SidebarDisplayTypes,
} from '@elixir/components';
import { ResponsiveMode, useResponsiveContext } from '@elixir/styling';

import { useRouter } from '@uirouter/react';
import { UIRouter } from '@uirouter/core';
import {
  PanelManager,
  usePanelManagerState,
  onShowPanel,
  PanelState,
  panelDefaults,
} from 'components/panelManager/panelManager';
import {
  selectWorkspace,
  selectWorkspaceStatus,
} from 'features/workspaces/workspaceSlice';
import {
  Workspace,
  WorkspaceStatus,
} from 'features/workspaces/models/workspace';
import { mergeStyleSets } from '@fluentui/react';
import { useNotifications } from 'features/notifications/notificationsSlice';
import {
  getActiveTabOnExplorePage,
  getAngularJsStateParams,
} from 'utils/sharedLibs';
import { useUserPreferences } from 'features/userInfo/userPreferencesSlice';
import { useUserProfileAndWorkspaceInit } from 'features/userprofile/userprofileSlice';
import { AppDispatch } from 'app/lensShellUtility';
import WorkspaceQuickSwitchNew from 'features/workspaces/components/workspaceQuickSwitch';

export enum SidePanel {
  WORKSPACESELECTOR = 'WorkspaceSelector',
}

const getFooterRoutes = (): LensRoute[] => {
  const lensFooterRoute: LensRoute[] = [
    {
      index: 'Workspace Selector',
      name: 'Workspace Selector',
      inSidebar: true,
      iconProps: iconProps('WorkspaceIcon'),
    },
  ];

  return lensFooterRoute;
};

export const LensSidebar = (props: LensSidebarProps): JSX.Element => {
  useUserProfileAndWorkspaceInit();
  useNotifications();
  useUserPreferences();

  const currentWorkspace = useSelector(selectWorkspace);
  const workspaceStatus = useSelector(selectWorkspaceStatus);
  const footerRoutes = useMemo(() => getFooterRoutes(), []);
  const { routes, className, isDefaultCollapsed, styles } = props;
  const panelState = usePanelManagerState();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const { responsiveMode } = useResponsiveContext();
  const allRoutes: LensRoute[] = useMemo(
    () => [...routes, ...footerRoutes],
    [footerRoutes, routes]
  );

  const sidebarItems = useMemo(() => {
    return getSidebarItems(
      routes,
      panelState,
      currentWorkspace,
      workspaceStatus
    );
  }, [currentWorkspace, panelState, routes, workspaceStatus]);

  const collapsedState = useSelector(elixirSelectors.isSidebarDefaultCollapsed);
  const [isCollapsed, setIsCollapsed] = useState(
    () => isDefaultCollapsed || collapsedState
  );
  const [currentPath, setCurrentPath] = useState(() =>
    getCurrentPath(location.pathname, allRoutes)
  );
  const router = useRouter();
  useEffect(() => {
    setCurrentPath(getCurrentPath(location.pathname, allRoutes));
  }, [allRoutes, location.pathname]);

  useEffect(() => {
    if (collapsedState !== undefined) {
      setIsCollapsed(collapsedState);
    }
  }, [collapsedState]);

  let displayTypes = SidebarDisplayTypes.Regular;
  switch (responsiveMode) {
    case ResponsiveMode.Large:
    case ResponsiveMode.Medium:
      displayTypes =
        SidebarDisplayTypes.SingleExpanded | SidebarDisplayTypes.Overlay;
      break;
    case ResponsiveMode.Small:
      displayTypes = SidebarDisplayTypes.Thin;
      break;
    default:
      if (isCollapsed) {
        displayTypes = SidebarDisplayTypes.Collapsed;
      }
  }

  return (
    <>
      <ElxSidebar<LensRoute>
        className={className}
        items={sidebarItems}
        // footerItems={sidebarFooterItems}
        selectedKey={currentPath}
        displayTypes={displayTypes}
        onItemSelect={(
          event:
            | React.MouseEvent<HTMLElement, MouseEvent>
            | React.KeyboardEvent<HTMLElement>,
          item: IElxSidebarItemProps<LensRoute>
        ) => onNavigation(router, history, item.data)}
        onMainNavToggle={(isCollapsed) => {
          if (responsiveMode > ResponsiveMode.Large) {
            dispatch(uxSetSidebarDefaultCollapsedAction(isCollapsed));
          }
        }}
        styles={styles}
        useNewSidebar={true}
      />
      <PanelManager panelState={panelState}>
        <WorkspaceQuickSwitchNew
          panelId={SidePanel.WORKSPACESELECTOR}
          {...panelDefaults}
        />
      </PanelManager>
    </>
  );
};
function getCurrentPath(path: string, allRoutes: LensRoute[]) {
  const currentRoute = findCurrentRoute(path, allRoutes);
  return currentRoute && currentRoute.index;
}

let stateParameterCache = new Map();

function onNavigation(
  router: UIRouter,
  history: History,
  route?: LensRoute
): boolean {
  if (route && route.isRouteable !== false && (route.path || route.state)) {
    // route.state contains the target state name
    // router.globals.current.name is the current state name
    // router.globals.params contains parameters for current state
    if (route.state) {
      if (route.state.indexOf('app.discover') > -1) {
        if (router.globals.current.name?.indexOf('app.discover') === -1) {
          stateParameterCache.set(
            router.globals.current.name,
            JSON.parse(JSON.stringify(router.globals.params))
          );
          let activeTabOnExplore = getActiveTabOnExplorePage();
          if (activeTabOnExplore) {
            router.stateService.go(
              activeTabOnExplore.lastState,
              activeTabOnExplore.lastStateParams
            );
          } else {
            router.stateService.go(
              route.state,
              {},
              { inherit: false, notify: true, reload: true }
            );
          }
        }
      } else if (router.globals.current.name !== route.state) {
        stateParameterCache.set(
          router.globals.current.name,
          JSON.parse(JSON.stringify(router.globals.params))
        );

        let newStateParams = stateParameterCache.get(route.state);
        let angularStateParams = getAngularJsStateParams(route.state);

        // Apply state parameters from AngularJS side.
        if (angularStateParams !== null && angularStateParams !== undefined) {
          newStateParams = angularStateParams;
        }
        router.stateService.go(route.state, newStateParams);
      }
    } else if (route.path) {
      history.push(route.path);
    }
    return true;
  }

  if (route && route.isExternal && route.path) {
    window.open(route.path, '_blank');
  }

  return false;
}
//content tag
const footerStyles = mergeStyleSets({
  footer: {
    '.ms-Button-label': {
      fontSize: '12px',
      fontFamily: 'Segoe UI',
      lineHeight: '16px',
      color: '#323130',
      fontWeight: '400',
      selectors: {
        '::before': {
          content: "'Workspace Selected:\\a'",
          whiteSpace: 'pre',
          fontFamily: 'Segoe UI',
          fontSize: '10px',
          color: '#605E5C',
          lineHeight: '12px',
        },
        '::after': {
          color: '#323130',
          marginRight: '10px',
          float: 'right',
          borderStyle: 'solid',
          borderWidth: '0.12em 0.12em 0 0',
          content: "' '",
          display: 'inline-block',
          height: '0.60em',
          width: '0.60em',
          transform: 'rotate(45deg)',
          position: 'relative',
        },
      },
    },
  },
});

function getSidebarItems(
  routes: LensRoute[],
  panelState: PanelState,
  currentWorkspace: Workspace,
  workspaceStatus: WorkspaceStatus
): IElxSidebarItemProps<LensRoute>[] {
  return routes
    .filter((route: LensRoute) => route.inSidebar)
    .map((route: LensRoute) => {
      let workspaceName = ' ';
      switch (workspaceStatus) {
        case WorkspaceStatus.Loaded:
          workspaceName =
            currentWorkspace.shareType === 'private'
              ? 'Private Workspace'
              : currentWorkspace.name;
          break;
        case WorkspaceStatus.Error:
          workspaceName = 'Error';
          break;
        case WorkspaceStatus.Loading:
          workspaceName = 'Loading...';
          break;
        case WorkspaceStatus.None:
        default:
          workspaceName = ' ';
      }

      switch (route.index) {
        case 'WorkspacesSelectNew':
          return {
            key: route.index,
            text: route.name,
            iconProps: route.iconProps,
            data: route,
            items:
              route.subRoutes &&
              getSidebarItems(
                route.subRoutes,
                panelState,
                currentWorkspace,
                workspaceStatus
              ),
            onRenderNavPane: onRenderCustomPane(route),
            onClick: () => {
              onShowPanel(panelState, SidePanel.WORKSPACESELECTOR, true);
            },
            className: footerStyles.footer,
          };
        case 'WorkspacesName':
          return {
            key: route.index,
            text: 'Current Workspace:\r\n ' + workspaceName,
            data: route,
            iconProps: route.iconProps,
            items:
              route.subRoutes &&
              getSidebarItems(
                route.subRoutes,
                panelState,
                currentWorkspace,
                workspaceStatus
              ),
            onRenderNavPane: onRenderCustomPane(route),
            onClick: () => {
              onShowPanel(panelState, SidePanel.WORKSPACESELECTOR, true);
            },
            className: footerStyles.footer,
          };
      }
      return {
        key: route.index,
        text: route.name,
        iconProps: route.iconProps,
        data: route,
        items:
          route.subRoutes &&
          getSidebarItems(
            route.subRoutes,
            panelState,
            currentWorkspace,
            workspaceStatus
          ),
        onRenderNavPane: onRenderCustomPane(route),
        className: footerStyles.footer,
      };
    });
}

function onRenderCustomPane(
  route: LensRoute
): IRenderFunction<IElxSidebarItemProps> | undefined {
  if (route.customNavComponent) {
    return (props?: IElxSidebarItemProps) => {
      const CustomComponent = route.customNavComponent as ComponentType;
      return <CustomComponent />;
    };
  }
}

const findCurrentRoute = (
  path: string,
  routes: LensRoute[]
): LensRoute | undefined => {
  for (const route of routes) {
    if (route.isRouteable !== false && route.path) {
      let currPath = path;
      if (path.length && path.substring(0, 2) === '/#') {
        currPath = path.substring(2);
      }

      const matchValue = matchWithParams(currPath, route.path as string);

      if (matchValue) {
        return route;
      }

      for (const otherPath of route.otherPaths || []) {
        const matchValue = matchWithParams(currPath, otherPath);

        if (matchValue) {
          return route;
        }
      }
    }

    if (route.subRoutes) {
      let found;
      if ((found = findCurrentRoute(path, route.subRoutes))) {
        return found;
      }
    }
  }
};
