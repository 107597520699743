import { Stack } from '@fluentui/react';
import React from 'react';
import {
  smallStackTokens,
  themedSmallStackTokens,
  useHistoryStyles,
} from '../workspaceStyles';

interface HistoryEntrySummaryProps {
  modified: string;
  modifiedBy: string;
  operation: string;
}
const HistorySummary = (props: HistoryEntrySummaryProps): JSX.Element => {
  const style = useHistoryStyles();
  return (
    <>
      <Stack.Item>
        <Stack tokens={smallStackTokens}>
          <Stack.Item className={style.summaryTitlesStyle}>
            Modified:
          </Stack.Item>
          <Stack.Item className={style.summaryTitlesStyle}>
            Modified by:
          </Stack.Item>
          <Stack.Item className={style.summaryTitlesStyle}>
            Operation:
          </Stack.Item>
        </Stack>
      </Stack.Item>
      <Stack.Item>
        <Stack tokens={smallStackTokens}>
          <Stack.Item>{props.modified}</Stack.Item>
          <Stack.Item>{props.modifiedBy}</Stack.Item>
          <Stack.Item>{props.operation}</Stack.Item>
        </Stack>
      </Stack.Item>
    </>
  );
};

export const HistoryEntrySummary = (props: HistoryEntrySummaryProps) => {
  const style = useHistoryStyles();
  return (
    <Stack className={style.summaryPadding}>
      <Stack.Item align="center">
        <Stack
          horizontal
          className={style.summaryStyle}
          tokens={themedSmallStackTokens}
        >
          <HistorySummary {...props} />
        </Stack>
      </Stack.Item>
    </Stack>
  );
};

interface HistoryEntryCompareSummaryProps {
  original: HistoryEntrySummaryProps;
  modified: HistoryEntrySummaryProps;
}
export const HistoryEntryCompareSummary = (
  props: HistoryEntryCompareSummaryProps
) => {
  const style = useHistoryStyles();
  return (
    <Stack className={style.summaryPadding}>
      <Stack.Item align="center">
        <Stack
          horizontal
          className={style.compareSummaryStyle}
          tokens={themedSmallStackTokens}
          horizontalAlign="space-between"
        >
          <HistorySummary {...props.original} />
          <HistorySummary {...props.modified} />
        </Stack>
      </Stack.Item>
    </Stack>
  );
};
