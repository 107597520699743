import UserPreferences from '../models/userPreferences';

let leApp: any; // AngularJS dependency - minimize use

export function inject(_leApp: any) {
  leApp = _leApp;
}

/**
 * Sets the user preferences.
 * @returns The resolved user preferences object.
 */

export function setUserPreferences(
  userPreferences: UserPreferences | undefined
): void {
  return leApp?.setUserPreferences(userPreferences);
}
