import './lensShell.scss';
import '../../app/register-web-components';
import '../dataSources/plugins/index';
import { defineCustomElements } from '@cseofeedback/feedback-lite/loader';
import { Image } from '@fluentui/react';
import gaLogo from './images/GA_LensLogo.png';
import preview from './images/preview.png';
import { ElxIconButton } from '@elixir/components';
import Discover from './components/discover/discover';
import { useReactUIByDefault } from 'utils/sharedLibs';
import PanelManager, {
  PanelState,
  onShowPanel,
  panelDefaults,
  usePanelManagerState,
} from 'components/panelManager/panelManager';
import { ElxShell } from '@elixir/fx';
import { modules } from '../../app/elxAdapter';
import NotificationIcon from './components/notificationIcon/notificationIcon';
import SupportNotificationPopup from 'features/notifications/azureNotifications/supportNotificationPopup';
import { UserIconWithDropdown } from './components/user/user';
import Help from './components/help/help';
import Notifications from './components/notifications/notifications';
import Feedback from './components/feedback/feedback';
import { LensCustomShell } from './components/lensCustomShell/lensCustomShell';
import { ConvertToGrafana } from '../convertToGrafana/components/convertToGrafana';
export enum SidePanel {
  FEEDBACK = 'Feedback',
  NOTIFICATIONS = 'Notifications',
  // DISCOVER = 'Discover',
  HELP = 'Help',
  CONVERTTOGRAFANA = 'ConvertToGrafana',
}

let panelState: PanelState | undefined;

export function showLensShellPanel(panelId: string) {
  if (panelState) {
    onShowPanel(panelState, panelId);
  }
}

export function LensShell() {
  defineCustomElements();
  panelState = usePanelManagerState();

  return (
    <LensCustomShell modules={modules} useNewSidebar={true}>
      <ElxShell.Branding
        onRenderLogo={(): JSX.Element => (
          <div className="branding-container">
            <Image
              src={gaLogo}
              alt="Lens Explorer"
              height={22}
              style={{ padding: '0px 10px' }}
            />
            {/* Turn off preview banner when react mode has been set as the default */}
            {!useReactUIByDefault && (
              <Image
                src={preview}
                alt="Preview"
                style={{
                  position: 'fixed',
                  left: '0px',
                  top: '0px',
                  padding: '0',
                  zIndex: 10,
                  height: '49px',
                }}
              />
            )}
          </div>
        )}
        applicationName={'Lens Explorer'}
      />
      <ElxShell.Actions>
        <Discover />
        <ElxIconButton
          label="Feedback"
          text="Feedback"
          iconProps={{ iconName: 'Feedback' }}
          className="elx-header-action"
          onClick={() =>
            panelState && onShowPanel(panelState, SidePanel.FEEDBACK)
          }
        />
        <ElxIconButton
          label="Help"
          text="Help"
          iconProps={{ iconName: 'Unknown' }}
          className="elx-header-action"
          onClick={() => panelState && onShowPanel(panelState, SidePanel.HELP)}
        />
        <NotificationIcon panelState={panelState} />
        <SupportNotificationPopup panelState={panelState} />
        <UserIconWithDropdown />
        <PanelManager panelState={panelState}>
          <Help panelId={SidePanel.HELP} {...panelDefaults} />
          <Notifications panelId={SidePanel.NOTIFICATIONS} {...panelDefaults} />
          <Feedback panelId={SidePanel.FEEDBACK} {...panelDefaults} />
          <ConvertToGrafana
            panelId={SidePanel.CONVERTTOGRAFANA}
            {...panelDefaults}
          ></ConvertToGrafana>
        </PanelManager>
      </ElxShell.Actions>
    </LensCustomShell>
  );
}

export default LensShell;
