import { ElxDropdown, ElxPanel, ElxTextField, PanelSize } from "@elixir/components";
import { Stack } from "@fluentui/react";
import { Action, Activity } from "features/orchestrator/models/job";
import { ProjectDataConnection } from "features/workspaces/models/project";
import _ from "lodash";
import { useState } from "react";
import { orchestratorConstants } from "utils/constants";
import { LensLabel } from "utils/lensLabel";
import { JobEndpoint } from "./JobEndpoint";

const validationOptions = Object.values(orchestratorConstants.ValidationActionTypes).map(d=>({text:d, key:d}));
const actionEndpointTypes = _.map(orchestratorConstants.ValidationActionPublishDataEndpointTypeOptions, 'type');

export interface JobActivityDataValidationActionProps {
  action:Action;
  activity:Activity;
  onChange:(action:Action)=>void;
  onCancel:()=>void;
}

export const JobActivityDataValidationAction = (
  props: JobActivityDataValidationActionProps
) => {
  const { action, onChange, onCancel, activity } = props;
  const [tempAction, setTempAction] = useState(action);
  
  action.type = action?.type ||  validationOptions[0].key;

  return (
    <>
      <ElxPanel
        headerText={'Add / Edit Action'}
        isOpen={true}
        size={PanelSize.large}
        onDismiss={() => {
          onCancel();
        }}
        actions={[
          {
            key: 'action',
            text: 'Create/Update Action',
            primary: true,
            onClick: () => {
              onChange(tempAction);
            },
          },
          {
            key: 'close',
            text: 'Close',
            onClick: () => {
              onCancel();
            },
          },
        ]}
      >
        <Stack tokens={{ childrenGap: 16, padding: 16 }}>
          <Stack.Item>
            <ElxTextField
              id={'Name'}
              onRenderLabel={() => (
                <LensLabel
                  labelText="Name"
                  hintText="Action Display Name"
                  required
                ></LensLabel>
              )}
              value={tempAction.displayName}
              deferredValidationTime={500}
              onChange={(e, value) => {
                tempAction.displayName = value || '';
                setTempAction({ ...tempAction });
              }}
            ></ElxTextField>
          </Stack.Item>
          <Stack.Item>
            <ElxDropdown
              onRenderLabel={() => (
                <>
                  <LensLabel
                    labelText="Type"
                    hintText={'Type of action to perform if validation fails'}
                    required={true}
                  ></LensLabel>
                </>
              )}
              options={validationOptions}
              selectedKey={tempAction.type || validationOptions[0].key}
              required
              onChange={(_, option) => {
                setTempAction({
                  ...tempAction,
                  type: option?.key.toString() || validationOptions[0].key,
                });
              }}
            ></ElxDropdown>
          </Stack.Item>
          <Stack.Item>
            <JobEndpoint
              isSource={false}
              endpoint={tempAction.output}
              activity={activity}
              onChange={function (
                activity: Activity,
                dc?: ProjectDataConnection | undefined,
                endpoint?: any
              ): void {
                setTempAction({...tempAction, output:endpoint!});
              }}
              endpointTypes={actionEndpointTypes}
            ></JobEndpoint>
          </Stack.Item>
        </Stack>
      </ElxPanel>
    </>
  );
};