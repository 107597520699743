import { IElixirRoute, IModule, setConfig } from '@elixir/fx';
import reducer from 'app/reducers';
import { initializeLensShell } from 'app/lensShellUtility';
import Settings from 'features/userInfo/components/settings';
import { IIconProps, mergeStyleSets } from '@fluentui/react';
import { registerSidebarIcons } from './sidebarIcons';
import { HomeRoute } from '../features/home/homeRoute';
import JobRoute from 'features/orchestrator/utils/JobRoute';
import AdminSettings from 'features/settings/adminSettings';
import { isAdmin } from 'utils/authUtils';
import CatalogRoute from 'features/catalog/catalogRoute';
import MonitorRoute from 'features/monitor/monitorRoute';

export function iconProps(src: string): IIconProps {
  registerSidebarIcons();

  const stylesIcon = mergeStyleSets({
    icon: {
      fontWeight: '400',
      lineHeight: 0,
    },
  });

  return {
    iconName: src,
    className: stylesIcon.icon,
  };
}

export interface LensRoute extends IElixirRoute {
  path?: string;
  otherPaths?: string[];
  state?: string;
  subRoutes?: LensRoute[];
}
export interface LensModule extends IModule {
  route?: LensRoute;
  underDevelopment?: boolean;
}

const allModules: LensModule[] = [
  {
    name: 'Home',
    env: 'production',
    route: {
      index: 'Home',
      name: 'Home',
      iconProps: iconProps('HomeIcon'),
      exact: false,
      path: '/home/',
      state: 'app.home.start',
      isRouteable: true,
      inSidebar: true,
    },
    reducer,
  },
  {
    name: 'Home',
    env: 'production',
    underDevelopment: true,
    route: {
      index: 'Home',
      name: 'Home',
      iconProps: iconProps('HomeIcon'),
      exact: false,
      path: '/new/home',
      state: 'leapp.home',
      isRouteable: true,
      inSidebar: false,
      component: HomeRoute,
    },
    reducer,
  },
  {
    name: 'Queries',
    env: 'production',
    route: {
      index: 'Queries',
      name: 'Queries',
      iconProps: iconProps('QueryIcon'),
      exact: false,
      path: '/discover',
      otherPaths: ['/discover', '/visualize'],
      state: 'app.discover.query.results',
      isRouteable: true,
      inSidebar: true,
      subRoutes: [
        // These are fake subRoutes, to make realistic Elixir breadcrumbs for AngularJS routes
        {
          inSidebar: false,
          exact: true,
          index: 'Create Fake Breadcrumb',
          name: 'Create',
          isRouteable: true,
          path: '/discover/query//results',
        },
        {
          inSidebar: false,
          exact: true,
          index: 'Results Fake Breadcrumb',
          name: 'Results',
          isRouteable: true,
          path: '/discover/query//results/table',
        },
        {
          inSidebar: false,
          exact: true,
          index: 'Edit Fake Breadcrumb',
          name: 'Edit',
          isRouteable: true,
          path: '/discover/query/:tableId/results/table',
        },
      ],
    },
    reducer,
  },
  {
    name: 'Dashboards',
    env: 'production',
    route: {
      index: 'Dashboards',
      name: 'Dashboards',
      iconProps: iconProps('DashboardIcon'),
      exact: false,
      path: '/dashboard',
      state: 'app.dashboard',
      isRouteable: true,
      inSidebar: true,
      subRoutes: [
        // This is a fake subRoute for Elixir breadcrumbs
        {
          inSidebar: false,
          exact: true,
          index: 'Dashboard Edit Fake Breadcrumb',
          name: 'Edit',
          isRouteable: true,
          path: '/dashboard/:dashboardId',
        },
      ],
    },
    reducer,
  },
  {
    name: 'Jobs',
    env: 'production',
    route: {
      index: 'Jobs',
      name: 'Jobs',
      iconProps: iconProps('JobIcon'),
      exact: false,
      path: '/jobs/list',
      otherPaths: ['/job'],
      state: 'app.jobs.list',
      isRouteable: true,
      inSidebar: true,
    },
    reducer,
  },
  {
    name: 'Data Catalog',
    env: 'development',
    underDevelopment: true,
    route: {
      index: 'DataCatalog',
      name: 'Data Catalog',
      iconProps: iconProps('DataCatalogIcon'),
      exact: false,
      path: '/datacatalog',
      isRouteable: true,
      inSidebar: true,
      component: CatalogRoute,
      subRoutes: [
        {
          inSidebar: false,
          exact: true,
          index: 'List',
          name: 'List',
          path: '/datacatalog/list',
        },
        {
          inSidebar: false,
          exact: false,
          index: 'Details',
          name: 'Details',
          path: '/datacatalog/details/:manifestKey',
        },
      ],
    },
    reducer,
  },
  {
    name: 'Data Monitoring',
    env: 'production',
    route: {
      index: 'DataMonitoring',
      name: 'Data Monitoring',
      iconProps: iconProps('DataManagementSettings'),
      exact: false,
      path: '/monitor',
      isRouteable: true,
      inSidebar: true,
      component: MonitorRoute,
      subRoutes: [
        {
          inSidebar: false,
          exact: true,
          index: 'List',
          name: 'List',
          path: '/monitor/list',
        },
      ],
    },
    reducer,
  },
  {
    name: 'Settings',
    env: 'production',
    route: {
      index: 'Settings',
      name: 'Settings',
      iconProps: iconProps('SettingsIcon'),
      exact: true,
      path: '/settings',
      isRouteable: false,
      inSidebar: true,
      subRoutes: [
        {
          inSidebar: true,
          isRouteable: true,
          exact: true,
          index: 'UserPreferences',
          name: 'User Preferences',
          path: '/settings/preferences',
          component: Settings,
          iconProps: { iconName: 'PlayerSettings' },
        },
        {
          inSidebar: true,
          exact: true,
          index: 'PilotFeatures',
          name: 'Pilot Features',
          isRouteable: true,
          path: '/manage/settings/features',
          state: 'app.manage.settings.features',
          iconProps: { iconName: 'Ferry' },
        },
        {
          inSidebar: isAdmin(),
          exact: true,
          index: 'AdminSettings',
          name: 'Admin Settings',
          isRouteable: true,
          path: '/settings/admin',
          component: AdminSettings,
          iconProps: { iconName: 'Admin' },
        },
      ],
    },
    reducer,
  },
  {
    name: 'WorkspacesSelectNew',
    env: 'development',
    underDevelopment: true,
    route: {
      index: 'WorkspacesSelectNew',
      name: 'Workspaces',
      iconProps: iconProps('WorkspaceIcon'),
      exact: false,
      path: '/workspacesPanelPath',
      // state: 'leapp.workspaces.manage',
      isRouteable: false,
      inSidebar: true,
      // component: ManageWorkspaces,
    },
    reducer,
  },
  {
    name: 'WorkspaceNameDisplayItem',
    env: 'development',
    underDevelopment: true,
    route: {
      index: 'WorkspacesName',
      name: 'WorkspacesName',
      iconProps: { iconName: 'Tiles' },
      exact: false,
      path: '/workspacesName', // /manage',
      isRouteable: false,
      inSidebar: true,
    },
    reducer,
  },

  // TODO: these "Fake Breadcrumbs" are totally bogus, needed for AngularJS breadcrumb backward compatibility
  // delete them for real React settings pages
  {
    name: 'Queries Fake Breadcrumb',
    env: 'production',
    route: {
      inSidebar: false,
      exact: false,
      index: 'Queries Fake Breadcrumb',
      name: 'Queries',
      isRouteable: true,
      path: '/visualize',
      subRoutes: [
        {
          inSidebar: false,
          exact: true,
          index: 'Visualize Fake Breadcrumb',
          name: 'Visualize',
          isRouteable: true,
          path: '/visualize',
          subRoutes: [
            {
              inSidebar: false,
              exact: true,
              index: 'Create Fake Breadcrumb',
              name: 'Create',
              isRouteable: true,
              path: '/visualize/create',
            },
            {
              inSidebar: false,
              exact: true,
              index: 'Edit Fake Breadcrumb',
              name: 'Edit',
              isRouteable: true,
              path: '/visualize/edit',
            },
          ],
        },
      ],
    },
    reducer,
  },
  {
    name: 'ViewSettings Fake Breadcrumb',
    env: 'production',
    route: {
      inSidebar: false,
      exact: true,
      index: 'ViewSettings Fake Breadcrumb',
      name: 'View Settings',
      isRouteable: true,
      path: '/manage/settings/view',
    },
    reducer,
  },
  {
    name: 'UserSettings Fake Breadcrumb',
    env: 'production',
    route: {
      inSidebar: false,
      exact: true,
      index: 'UserSettings Fake Breadcrumb',
      name: 'User Profile',
      isRouteable: true,
      path: '/manage/settings/user',
    },
    reducer,
  },
  {
    name: 'PilotFeatures Fake Breadcrumb',
    env: 'production',
    route: {
      inSidebar: false,
      exact: true,
      index: 'PilotFeatures Fake Breadcrumb',
      name: 'Pilot Features',
      isRouteable: true,
      path: '/manage/settings/features',
    },
    reducer,
  },
  {
    name: 'Jobs - under development',
    env: 'development',
    underDevelopment: true,
    route: {
      index: 'Job',
      name: 'Job',
      iconProps: iconProps('JobIcon'),
      exact: false,
      path: '/job',
      state: 'leapp.job',
      isRouteable: true,
      inSidebar: false,
      component: JobRoute,
    },
    reducer,
  },
];

export const modules: LensModule[] = allModules;

setConfig({
  // authentication: 'none',  IMPORTANT CHANGE
  applicationTitle: 'Lens Explorer',
  authConfig: {
    authentication: 'none',
  },
});

export const lensFramework = initializeLensShell(modules);
