import AppInsightsAppSelector from 'features/workspaces/components/editDataConnection/dataSourceComponents/appInsightsAppSelector';
import { SelectorProps } from 'features/workspaces/components/editDataConnection/editDataConnectionHelper';
import {
  AppInsightsDataSource,
  ConnectionDataSourceType,
} from 'features/workspaces/models/project';
import appInsightsClient from '../applicationInsights/client/applicationInsightsClient';
import { registerDataSourcePlugin } from '../registry';
import { DataSourcePlugin } from './dataSourcePlugin';

class AppInsightsPlugin extends DataSourcePlugin {
  public dataSourceFormComponent(selectorProps: SelectorProps): JSX.Element {
    return (
      <AppInsightsAppSelector
        workspace={selectorProps.workspace}
        state={selectorProps.state}
        setState={selectorProps.setState}
        customNameFilled={selectorProps.customNameFilled}
      />
    );
  }

  public generatedName(state: AppInsightsDataSource): string {
    if (state && state.cluster) {
      const aITokens = state.cluster.split('/');
      if (aITokens.length > 1) {
        return (
          aITokens[aITokens.indexOf('resourcegroups') + 1] +
          '.' +
          aITokens[aITokens.length - 1]
        );
      }
    }
    if (state.appId) {
      return 'AI.Datasource.AppId.' + state.appId;
    }
    return '';
  }
}

registerDataSourcePlugin(
  ConnectionDataSourceType.ApplicationInsights,
  new AppInsightsPlugin(
    ConnectionDataSourceType.ApplicationInsights,
    appInsightsClient
  )
);
