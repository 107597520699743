import { ElxCombobox, ElxPanel, PanelSize } from '@elixir/components';
import { IComboBox, Stack, ThemeProvider } from '@fluentui/react';
import { Job } from 'features/orchestrator/models/job';
import { useLensShellTheme } from 'features/shell/lensShellStyles';
import { useState } from 'react';
import jobApi from '../api/jobApi';
import notifier from 'utils/notifier';
import { LensLabel } from 'utils/lensLabel';
import logger from 'features/appInsights/lensLogger';
import { LensTelemetryConstants } from 'features/appInsights/appInsightsLibs';

export interface MoveJobToFolderProps {
  jobs: Job[];
  workspaceId: string;
  groupList: string[];
  onCancel: () => void;
  onSuccess: () => void;
}

const MoveJobToFolder = (props: MoveJobToFolderProps) => {
  const { jobs, onCancel, onSuccess, groupList, workspaceId } = props;
  const theme = useLensShellTheme();
  const [busySaveButton, setBusySaveButton] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState<string>('');

  return (
    <ThemeProvider theme={theme}>
      <ElxPanel
        headerText={`Move Jobs to Folder`}
        isOpen={true}
        size={PanelSize.large}
        onDismiss={onCancel}
        actions={[
          {
            key: 'save',
            text: 'Move Jobs to Folder',
            primary: true,
            onClick: async () => {
              logger.event(
                LensTelemetryConstants.EventNames.JobActions.FolderWorkflow
              );
              const promises = jobs.map(async (job) => {
                let tempJob = JSON.parse(JSON.stringify(job)) as Job;
                if (selectedFolder === '') {
                  delete tempJob.config!.folder;
                } else {
                  tempJob.config!.folder = selectedFolder;
                }
                return jobApi.updateJob(workspaceId, job.id, tempJob);
              });
              try {
                setBusySaveButton(true);
                await Promise.all(promises);
                notifier.info(
                  `All Jobs successfully modified name - moved to the folder "${selectedFolder}"`
                );
                setBusySaveButton(false);
                onSuccess();
              } catch (err) {
                setBusySaveButton(false);
                notifier.error(`Saving of job failed,  error=${err}`);
              }
            },
            iconProps: { iconName: busySaveButton ? 'refresh' : 'save' },
            disabled: busySaveButton,
          },
          {
            key: 'close',
            text: 'Close',
            onClick: () => {
              onCancel();
            },
          },
        ]}
      >
        <Stack verticalFill tokens={{ childrenGap: 16, padding: 16 }}>
          <Stack.Item>
            <ElxCombobox
              onRenderLabel={() => (
                <LensLabel
                  labelText={'Folder name'}
                  hintText={
                    'Name of the folder where the jobs will be moved to, if empty string is provided, the jobs will not be in any folder'
                  }
                ></LensLabel>
              )}
              text={selectedFolder}
              options={groupList.map((m) => ({
                key: m,
                text: m === '' ? 'No Folder' : m,
              }))}
              onChange={(e: React.FormEvent<IComboBox>, option) => {
                if (option) {
                  setSelectedFolder(option.key.toString() || '');
                } else {
                  setSelectedFolder((e.target as any).value || '');
                }
              }}
              allowFreeform={true}
            ></ElxCombobox>
          </Stack.Item>
          <Stack.Item grow>
            <p>
              The following Jobs will be moved to the folder:<span> </span>
              <strong>{selectedFolder || 'No Folder'}</strong>
            </p>
            <Stack tokens={{ childrenGap: 16, padding: 16 }}>
              <ul>
                {jobs.map((job) => (
                  <li key={job.id}>{job.config?.jobDisplayName}</li>
                ))}
              </ul>
            </Stack>
          </Stack.Item>
        </Stack>
      </ElxPanel>
    </ThemeProvider>
  );
};

export default MoveJobToFolder;
