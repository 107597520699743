import { ElxTextField } from '@elixir/components';
import { Stack, Toggle } from '@fluentui/react';
import { useState } from 'react';
import { LensLabel } from 'utils/lensLabel';
import { SelectorProps } from '../editDataConnectionHelper';

export const SynapseScopeSelector = (props: SelectorProps): JSX.Element => {
  const { state, setState } = props;
  const [skipCasSdkDeployment, setSkipCasSdkDeployment] = useState(
    state.skipCasSdkDeployment === undefined
      ? false
      : state.skipCasSdkDeployment
  );

  return (
    <Stack tokens={{ childrenGap: 16 }}>
      <ElxTextField
        onRenderLabel={() => (
          <LensLabel
            labelText="Subscription ID"
            hintText={
              'The Azure Subscription ID - the GUID that can be found in Azure Portal.'
            }
            required={true}
          ></LensLabel>
        )}
        value={state.subscriptionId || ''}
        onChange={(e, newValue) => {
          setState({
            ...state,
            subscriptionId: newValue || '',
          });
        }}
      />
      <ElxTextField
        onRenderLabel={() => (
          <LensLabel
            labelText="Resource Group"
            hintText={'The Azure Resource Group name.'}
            required={true}
          ></LensLabel>
        )}
        value={state.resourceGroup || ''}
        onChange={(e, newValue) => {
          setState({
            ...state,
            resourceGroup: newValue || '',
          });
        }}
      />
      <ElxTextField
        onRenderLabel={() => (
          <LensLabel
            labelText="Synapse Workspace"
            hintText={'The Synapse Workspace.'}
            required={true}
          ></LensLabel>
        )}
        value={state.workspace || ''}
        onChange={(e, newValue) => {
          setState({
            ...state,
            workspace: newValue || '',
          });
        }}
      />
      <Toggle
        label={
          <LensLabel
            labelText="Publish AzureAnalytics CAS SDK"
            hintText={
              'if checked - CAS SDK will be deployed to the selected ADLA/ADLS account'
            }
            required={true}
          ></LensLabel>
        }
        onText={'true'}
        offText={'false'}
        role={'checkbox'}
        checked={!skipCasSdkDeployment}
        onChange={(e, newValue) => {
          setSkipCasSdkDeployment(newValue === undefined ? false : !newValue);
          setState({
            ...state,
            skipCasSdkDeployment: !newValue,
          });
        }}
      />
    </Stack>
  );
};
