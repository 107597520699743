import { _ } from 'utils/sharedLibs';
import { $laConstants } from 'utils/constants'
import utility from 'utils/commonUtilities';
import notify from 'utils/notifier';
import timefilter from './timefilter';
import kustoQueryParser from '../features/dataSources/kusto/parser/kustoQueryParser';
import { DateTime } from 'luxon';

var filtersQueryParamToken = 'apply_attribute lens:filters';
var filtersTokenRegex = new RegExp(filtersQueryParamToken, 'g');

function getTimeText(timeObject) {
    var format = timefilter.getTimeFormat();
    var mode = timefilter.getMode();

    if (mode === 'absolute') {
        // converts Local to UTC and keeps UTC
        if (format === $laConstants.TimeFormat.Local) {
            return "\"" + timeObject.utc().toISOString() + "\"";
        }
        else {
            return "\"" + DateTime.fromJSDate(new Date(timeObject)).toFormat('YYYY-MM-DD HH:mm:ss.SSS') + "\"";
        }
    } else {
        return "\"" + timeObject.utc().toISOString() + "\"";
    }
}

// Used by filter value placement, get rid of the "{{" and "}}" wrappers
function getDefaultValue(defaultValuePart) {
    var result = defaultValuePart;
    var emptyValuePattern = /<>/g;
    if (result) {
        if (!emptyValuePattern.test(result)) {
            result = result.substring(1); // Get rid of "<"
            result = result.substring(0, result.length - 1); // Get rid of ">"
        }
        else {
            result = '';
        }
    }
    else {
        result = '';
    }
    return result;
}


/**
 * @name FilterPlacementService
 * @description Handles custom filter placements in Kusto queries
 */
class FilterPlacementService {

    /**
     * @name processIndividualFilterPlacement
     * @description 
     * Search for individual filter markers in query string and replace them with actual filter query
     * @param {string} query - a query string with custom filter placement markers
     * @param {Array<Object>} filters - an array of filters used for the placement
     * @returns {string} a query string with individual filter placement markers replaced with actual filters
     */
    processIndividualFilterPlacement(query, filters) {
        if (!query) {
            return query;
        }

        filters = filters || [];
        var matchedList = [];
        query = kustoQueryParser.removeQueryComments(query);
        query = this._processFilterPlacement(query, filters, matchedList);
        query = this._processValuePlacement(query, filters, matchedList);

        matchedList = _.uniq(matchedList);

        // Removed filters from matched list
        _.each(matchedList, function (key) {
            var matchedFilterIndex = _.findIndex(filters, function (filter) {
                return filter.key === key;
            });

            if (matchedFilterIndex > -1) {
                filters.splice(matchedFilterIndex, 1);
            }
        });

        return query;
    };

    /**
     * @name removeUnnecessaryCharacters
     * @description
     * Removes unreplaced filter markers from user query so that the resulting query is a valid Kusto query
     * @param {string} queryString - query string for filter marker removal
     * @returns {string} a query string free of custom filter markers
     */
    removeUnnecessaryCharacters(queryString) {
        if (!queryString) {
            return queryString;
        }

        queryString = kustoQueryParser.removeQueryComments(queryString);
        queryString = queryString.replace(filtersTokenRegex, "");
        queryString = queryString.replace($laConstants.FilterPlacement.TimeFilterPattern, "");
        queryString = queryString.replace($laConstants.FilterPlacement.IndividualFilterPattern, "");
        queryString = queryString.replace($laConstants.FilterPlacement.EmptyPipePattern, "| "); // handle multiple consecutive pipe characters
        queryString = queryString.replace($laConstants.FilterPlacement.IsolatedPipePattern, "$1"); // remove dangling pipe character
        queryString = queryString.replace($laConstants.FilterPlacement.EndOfQueryPipePattern, ""); // remove end of query pipe character
        
        return queryString;
    };

    processTimeFilter(query, timeBounds, timefilterQuery, isIgnoreTime) {
        var matches = [];
        var hasTimeFilterMarker = false;
        var hasTimeValueMarker = false;

        if (!query) {
            return query;
        }

        if (isIgnoreTime) {
            query = query.replace($laConstants.FilterPlacement.TimeValueFromPattern, '');
            query = query.replace($laConstants.FilterPlacement.TimeValueToPattern, '');
            query = query.replace($laConstants.FilterPlacement.TimeFilterPattern, '');
            return query;
        }
    
        query = kustoQueryParser.removeQueryComments(query);

        hasTimeValueMarker = $laConstants.FilterPlacement.TimeValueFromPattern.test(query) || $laConstants.FilterPlacement.TimeValueToPattern.test(query);

        if (hasTimeValueMarker) {
            query = this._processTimeValuePlacement(query, timeBounds);
        }

        if (!timeBounds) {
            return query;
        }

        hasTimeFilterMarker = $laConstants.FilterPlacement.TimeFilterPattern.test(query);

        if (hasTimeFilterMarker) {
            matches = query.match($laConstants.FilterPlacement.TimeFilterPattern) || [];

            _.each(matches, function (match) {
                var timefield = match.trim().replace($laConstants.FilterPlacement.TimeFilterPrefix, '');
                var whereClause = this.getTimefilterQuery(timefield, timeBounds);
                query = query.replace(match, whereClause);
            });
        }
        else {
            if (!hasTimeValueMarker && timefilterQuery) {
                query = query + $laConstants.KustoSeparator + timefilterQuery;
            }
        }

        return query;
    };

    getTimefilterQuery(timefield, timebounds) {
        var result = "";
        var minUtcText = null;
        var maxUtcText = null;
        var escapedTimeField = utility.escapeName(timefield);

        if (!timebounds) {
            throw new Error("timebounds cannot be null or undefined.");
        }

        if (!escapedTimeField) {
            throw new Error("timefield cannot be null or undefined.");
        }

        minUtcText = getTimeText(timebounds.min);
        maxUtcText = getTimeText(timebounds.max);

        result = "where " + escapedTimeField + " >= todatetime(" + minUtcText + ") and " + escapedTimeField + " <= todatetime(" + maxUtcText + ")";

        return result;
    };

    // Process generic filter placement markers "apply_attribute lens:filters"
    processGenericFilterPlacement(queryString, filters) {
        if (!queryString) {
            return queryString;
        }

        queryString = kustoQueryParser.removeQueryComments(queryString);

        if (queryString.match($laConstants.FilterPlacement.GenericPattern)) {
            queryString = queryString.replace($laConstants.FilterPlacement.GenericPattern, filters);
        }
        else {
            queryString = queryString + $laConstants.KustoSeparator + filters;
        }

        return queryString;
    };

    // Process individual filter placement (apply_attribute lens:filter:FieldName)
    _processFilterPlacement(query, filters, matchedList) {
        var hasFilterMarker = $laConstants.FilterPlacement.IndividualFilterPattern.test(query);
        var matches = [];

        if (hasFilterMarker) {
                // Find all matching filter markers
            matches = query.match(_.clone($laConstants.FilterPlacement.IndividualFilterPattern)) || [];

            _.each(matches, function (match) {
                if (match) {
                    var fieldName = match.trim().replace($laConstants.FilterPlacement.IndividualFilterPrefix, '').trim();

                    // Find matched filter from the filter array
                    var matchedFilterIndex = _.findIndex(filters, function (filter) {
                        return filter.key === fieldName;
                    });
                    var matchedFilter = null;
                    var filterQuery = null;

                    if (matchedFilterIndex > -1) {
                        matchedFilter = filters[matchedFilterIndex];
                        matchedList.push(matchedFilter.key);

                        filterQuery = 'where ' + matchedFilter.query;

                        query = query.replace(match, filterQuery);
                    }
                }
                else {
                    var error = new Error("Empty match in query " + query);
                    error.caller = "_processFilterPlacement";
                    notify.error(error);
                }
            });

            // Get rid of any unreplaced markers
            query = query.replace($laConstants.FilterPlacement.IndividualFilterPattern, '');
        }

        return query;
    };

    // Process individual filter value placement (apply_attribute lens:filter:FieldName?<default value>)
    _processValuePlacement(query, filters, matchedList) {
        var hasValueMarker = $laConstants.FilterPlacement.IndividualValuePattern.test(query);
        var matches = [];

        if (hasValueMarker) {
            // Find all matching filter markers
            matches = query.match($laConstants.FilterPlacement.IndividualValuePattern) || [];

            _.each(matches, function (match) {
                if (match) {
                    var fieldNameAndDefault = match.trim().replace($laConstants.FilterPlacement.IndividualValuePrefix, '');
                    var fieldName = fieldNameAndDefault.substring(0, fieldNameAndDefault.indexOf('?'));
                    var defaultValue = getDefaultValue(fieldNameAndDefault.substring(fieldNameAndDefault.indexOf('?') + 1));                       
                    
                    // Find matched filter from the filter array
                    var matchedFilterIndex = _.findIndex(filters, function (filter) {
                        return filter.key === fieldName;
                    });
                    var matchedFilter = null;
                    var filterQuery = null;

                    if (matchedFilterIndex > -1) {
                        matchedFilter = filters[matchedFilterIndex];
                        matchedList.push(matchedFilter.key);

                        filterQuery = matchedFilter.value;

                        query = query.replace(match, filterQuery);
                    }
                    else {
                        query = query.replace(match, defaultValue);
                    }
                }
                else {
                    var error = new Error("Empty match in query " + query);
                    error.caller = "_processValuePlacement";
                    notify.error(error);
                }
            });
        }

        // Get rid of any unreplaced markers
        query = query.replace($laConstants.FilterPlacement.IndividualValuePattern, '');

        return query;
    };

    // Process time value placement (apply_attribute lens:timefrom?<default value>, or apply_attribute lens:timeto?<default value>)
    _processTimeValuePlacement(query, timeBounds) {
        var timeFromMatches = query.match($laConstants.FilterPlacement.TimeValueFromPattern) || [];
        var timeToMatches = query.match($laConstants.FilterPlacement.TimeValueToPattern) || [];
        var timeFromValue = timeBounds ? getTimeText(timeBounds.min) : null;
        var timeToValue = timeBounds ? getTimeText(timeBounds.max) : null;

        _.each(timeFromMatches, function (match) {
            if (match) {
                var defaultValue = match.trim().replace($laConstants.FilterPlacement.TimeValueFromPrefix, '').replace('<', '').replace('>', '');
                var timeValue = 'todatetime(' + (timeFromValue || defaultValue) + ')';
                query = query.replace(match, timeValue);
            }
            else {
                var error = new Error("Empty match in query " + query);
                error.caller = "_processTimeValuePlacement";
                notify.error(error);
            }
        });

        _.each(timeToMatches, function (match) {
            if (match) {
                var defaultValue = match.trim().replace($laConstants.FilterPlacement.TimeValueToPrefix, '').replace('<', '').replace('>', '');
                var timeValue = 'todatetime(' + (timeToValue || defaultValue) + ')';
                query = query.replace(match, timeValue);
            }
            else {
                var error = new Error("Empty match in query " + query);
                error.caller = "_processTimeValuePlacement";
                notify.error(error);
            }
        });

        return query;
    };
}

export const filterPlacementService = new FilterPlacementService();

export default filterPlacementService;
