import { Workspace } from 'features/workspaces/models/workspace';
import {
  ElxTagPicker,
  ITagData,
  TagContainerOrientation,
} from '@elixir/components';
import React from 'react';

interface TagState {
  items: ITagData[];
}

interface EditWorkspaceTagProps {
  workspace: Workspace;
  updateParentState: (workspace: Workspace) => void;
  onChange: (value: any) => void;
}

const WorkspaceTagPicker = (props: EditWorkspaceTagProps): JSX.Element => {
  const tagsObjs: ITagData[] =
    props.workspace.tags.map((tag: string, index: number) => {
      return { key: index.toString(), text: tag };
    }) || ([] as ITagData[]);

  const [state, setState] = React.useState<TagState>({ items: tagsObjs });

  const addTag = (
    tag: ITagData,
    state: TagState,
    setState: React.Dispatch<React.SetStateAction<TagState>>
  ): void => {
    const newItems = [...state.items, tag];
    setState({
      ...state,
      items: newItems,
    });

    const newTags = newItems.map((tagObj: ITagData) => tagObj.text);
    props.updateParentState({ ...props.workspace, tags: newTags });

    // The onchange is need to relay back to the form controller what has been changed.
    // Need to format the object being passed into this since there is no
    // built in function for onchange to pass back an even object
    props.onChange({
      target: {
        name: 'addTag',
        value: newTags,
      },
    });
  };

  const removeTag = (
    tag: ITagData,
    state: TagState,
    setState: React.Dispatch<React.SetStateAction<TagState>>
  ): void => {
    const newItems = [...state.items.filter((item: ITagData) => item !== tag)];
    setState({
      ...state,
      items: newItems,
    });

    const newTags = newItems.map((tagObj: ITagData) => tagObj.text);

    props.updateParentState({ ...props.workspace, tags: newTags });
    props.onChange({
      target: {
        name: 'removeTag',
        value: newTags,
      },
    });
  };

  return (
    <ElxTagPicker
      placeholder="Enter a tag (displayed below the input bar)"
      disabled={false}
      readOnly={false}
      items={state.items}
      orientation={TagContainerOrientation.Horizontal}
      onAddTag={(tag: ITagData) => {
        addTag(tag, state, setState);
      }}
      onRemoveTag={(tag: ITagData) => {
        removeTag(tag, state, setState);
      }}
    />
  );
};

export default WorkspaceTagPicker;
