import { ElxIconButton } from '@elixir/components';
import { Stack } from '@fluentui/react';
import { Editor } from 'components/editor';
import { DataSourcePlugin } from 'features/dataSources/plugins/dataSourcePlugin';
import {
  getAllDataSourcePlugins,
  getDataSourcePlugin,
} from 'features/dataSources/registry';
import { Activity } from 'features/orchestrator/models/job';
import { ProjectDataConnection } from 'features/workspaces/models/project';
import { useCallback, useState } from 'react';
import { orchestratorConstants } from 'utils/constants';
import { LensLabel } from 'utils/lensLabel';
import EditorFull from '../editorFull';
import { SelectDataConnection } from '../jobSchedule/selectDataConnection';
import { alignEnds } from './JobAuthorStyles';
import { selectWorkspace } from 'features/workspaces/workspaceSlice';
import { useSelector } from 'react-redux';

const supportedDCTypesForDataEvent = getAllDataSourcePlugins()
  .map((dsp) => dsp.dataSourceClient)
  .map((ds) => ds.orchestration?.dataEvent?.endpointType && ds.name)
  .filter((d) => d);

const dcType2EndPointType4DE = (dcType: string) => {
  let endPointType = '';

  switch (dcType) {
    case orchestratorConstants.DataSourceTypes.KUSTO:
      endPointType = orchestratorConstants.EndpointTypes.KustoDatabase;
      break;

    case orchestratorConstants.DataSourceTypes.ADLA:
      endPointType = orchestratorConstants.EndpointTypes.AzureDataLakeAnalytics;
      break;

    case orchestratorConstants.DataSourceTypes.COSMOS:
      endPointType = orchestratorConstants.EndpointTypes.CosmosVc;
      break;

    case orchestratorConstants.DataSourceTypes.SQL:
      endPointType = orchestratorConstants.EndpointTypes.SqlDatabase;
      break;

    case orchestratorConstants.DataSourceTypes.SYNAPSEPIPELINE:
      endPointType = orchestratorConstants.EndpointTypes.SynapsePipeline;
      break;

    case orchestratorConstants.DataSourceTypes.SYNAPSESCOPE:
      endPointType = orchestratorConstants.EndpointTypes.SynapseScope;
      break;

    case orchestratorConstants.DataSourceTypes.AZUREDATAFACTORY:
      endPointType = orchestratorConstants.EndpointTypes.AzureDataFactory;
      break;

    default:
      break;
  }
  return endPointType;
};
export interface JobActivityDataEventProps {
  activity: Activity;
  onChange: (activity: Activity) => void;
}
export const JobActivityDataEvent = (props: JobActivityDataEventProps) => {
  const { activity, onChange } = props;
  delete activity.input;
  activity.output = activity.output || { connectionName: '', type: '' };
  const workspace = useSelector(selectWorkspace); // get current workspace.

  const [bigSrn, setBigSrn] = useState<boolean>(false);
  const [dataSourcePlugin, setDataSourcePlugin] =
    useState<DataSourcePlugin | null>(null);

  const dcType2EndPointTypeForDataEvent = useCallback(
    (dc: ProjectDataConnection) => {
      activity.output!.connectionName = dc.connectionName;

      switch (dc.type) {
        case orchestratorConstants.DataSourceTypes.KUSTO:
          activity.output!.type =
            orchestratorConstants.EndpointTypes.KustoDatabase;
          break;

        case orchestratorConstants.DataSourceTypes.ADLA:
          activity.output!.type =
            orchestratorConstants.EndpointTypes.AzureDataLakeAnalytics;
          break;

        case orchestratorConstants.DataSourceTypes.COSMOS:
          activity.output!.type = orchestratorConstants.EndpointTypes.CosmosVc;
          break;

        case orchestratorConstants.DataSourceTypes.SQL:
          activity.output!.type =
            orchestratorConstants.EndpointTypes.SqlDatabase;
          break;

        case orchestratorConstants.DataSourceTypes.SYNAPSEPIPELINE:
          activity.output!.type =
            orchestratorConstants.EndpointTypes.SynapsePipeline;
          break;

        case orchestratorConstants.DataSourceTypes.SYNAPSESCOPE:
          activity.output!.type =
            orchestratorConstants.EndpointTypes.SynapseScope;
          break;

        case orchestratorConstants.DataSourceTypes.AZUREDATAFACTORY:
          activity.output!.type =
            orchestratorConstants.EndpointTypes.AzureDataFactory;
          break;

        case orchestratorConstants.DataSourceTypes.SHARED:
          const sharedConn = workspace.sourceDataConnections?.find(
            (s) => s.targetConnectionName === dc.connectionName
          );
          if (sharedConn) {
            activity.output!.type = dcType2EndPointType4DE(
              sharedConn.sourceConnection.type
            );
          }
          break;
        default:
          break;
      }
      setDataSourcePlugin(getDataSourcePlugin(dc.type));
      onChange(activity);
    },
    [activity, onChange, workspace]
  );

  return (
    <>
      <SelectDataConnection
        onSelect={dcType2EndPointTypeForDataEvent}
        filter={supportedDCTypesForDataEvent}
        selectedKey={activity.output?.connectionName || null}
        required={true}
      ></SelectDataConnection>
      <Stack
        verticalFill
        tokens={{
          childrenGap: 16,
        }}
      >
        <Stack.Item>
          {' '}
          <Stack
            className={alignEnds}
            horizontal
            grow
            styles={{
              root: {
                marginTop: 16,
              },
            }}
          >
            <Stack.Item>
              {' '}
              <LensLabel
                labelText="Message"
                hintText={'Event message - contents of the event'}
                required={true}
              ></LensLabel>
            </Stack.Item>
            <Stack.Item>
              {' '}
              <ElxIconButton
                text={''}
                styles={{
                  root: {
                    float: 'right',
                  },
                }}
                iconProps={{
                  iconName: 'FullScreen',
                }}
                onClick={() => {
                  setBigSrn(true);
                }}
              ></ElxIconButton>
            </Stack.Item>
          </Stack>
        </Stack.Item>{' '}
        <Stack.Item>
          <Editor
            language={
              dataSourcePlugin?.dataSourceClient?.monaco?.language || 'text'
            }
            onChange={(value: string) => {
              activity.eventContents = value;
              onChange(activity);
            }}
            value={activity.eventContents!}
            height={200}
            width={'100%'}
            focusOnMount={false}
          ></Editor>
        </Stack.Item>
        {bigSrn && (
          <EditorFull
            header={'Data command script'}
            onChange={(value: string) => {
              activity.eventContents = value;
              onChange(activity);
            }}
            value={activity.eventContents!}
            onDismiss={() => {
              setBigSrn(false);
            }}
            language={
              dataSourcePlugin?.dataSourceClient?.monaco?.language || 'text'
            }
          ></EditorFull>
        )}
      </Stack>
    </>
  );
};
