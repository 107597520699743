import { ElxActionButton, ElxDropdown, useId } from '@elixir/components';
import { IDropdownOption, Spinner } from '@fluentui/react';
import { LensLabel } from 'utils/lensLabel';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  loadFoldersForGrafanaInstance,
  selectFolderStatus,
  selectFolders,
} from '../grafanaMigrationSlice';
import { GrafanaMigrationStatus } from '../models/grafanaMigrationData';
import { useDispatch } from 'react-redux';
import CreateGrafanaFolderCallout from './createGrafanaFolderCallout';

export interface GrafanaFolderSelectorProps {
  instanceUrl: string;
  selectedFolder: string;
  setSelectedFolder: (selectedFolder: string) => void;
}
export const GrafanaFolderSelector = ({
  instanceUrl,
  selectedFolder,
  setSelectedFolder,
}: GrafanaFolderSelectorProps): JSX.Element => {
  const [folderList, setFolderList] = useState<IDropdownOption[]>([]);
  const dispatch = useDispatch();

  const folderStatus = useSelector(selectFolderStatus);
  const folders = useSelector(selectFolders);
  const [showCreateGrafanaFolderCallout, setShowCreateGrafanaFolderCallout] =
    useState(false);
  const [pendingGrafanaFolderUid, setPendingGrafanaFolderUid] =
    useState<string>('');
  const createNewFolderButtonId = useId('createNewFolder');

  useEffect(() => {
    if (folders && folderStatus === GrafanaMigrationStatus.Loaded) {
      const tempFolderList = folders.map((a) => ({
        key: a.uid,
        text: a.title,
      }));
      tempFolderList.unshift({
        key: 'General',
        text: 'General (root folder)',
      });
      setFolderList(tempFolderList);
      if (!tempFolderList.some((f) => f.key === selectedFolder)) {
        setSelectedFolder('');
      }
    }
  }, [folderStatus, folders, selectedFolder, setSelectedFolder]);

  useEffect(() => {
    if (folderList.length && pendingGrafanaFolderUid) {
      setSelectedFolder(pendingGrafanaFolderUid);
      setPendingGrafanaFolderUid('');
    }
  }, [folderList.length, pendingGrafanaFolderUid, setSelectedFolder]);

  useEffect(() => {
    if (folderStatus === GrafanaMigrationStatus.Error) {
      setFolderList([]);
    }
  }, [folderStatus]);

  const onGrafanaFolderCreated = (grafanaFolderUid: string) => {
    setFolderList([]);
    dispatch(loadFoldersForGrafanaInstance({ instanceUrl }));
    setPendingGrafanaFolderUid(grafanaFolderUid);
  };

  const label = (
    <LensLabel
      labelText="Destination Folder"
      hintText={'The destination folder in which to save the dashboard.'}
      required
    ></LensLabel>
  );

  if (folderStatus === GrafanaMigrationStatus.Loading) {
    return (
      <>
        {label}
        <Spinner label="Loading folders..." />
      </>
    );
  }

  return (
    <>
      <ElxDropdown
        onRenderLabel={() => label}
        selectedKey={selectedFolder}
        options={folderList}
        placeholder="Select folder"
        inputActions={[]}
        onChange={(e, value) => {
          if (value) {
            setSelectedFolder(value.key.toString());
          }
        }}
        labelActions={[
          <ElxActionButton
            styles={{
              labelDisabled: { pointerEvents: 'auto' },
              iconDisabled: { pointerEvents: 'auto' },
            }} // allow tooltip to show on disabled button
            id={createNewFolderButtonId}
            iconProps={{ iconName: 'Add' }}
            text="Create New"
            disabled={!instanceUrl}
            onClick={() => setShowCreateGrafanaFolderCallout(true)}
            title={!instanceUrl ? 'Select a Grafana instance first' : ''}
          />,
        ]}
      />
      <CreateGrafanaFolderCallout
        show={showCreateGrafanaFolderCallout}
        instanceUrl={instanceUrl}
        onGrafanaFolderCreated={onGrafanaFolderCreated}
        dismissCallout={() => setShowCreateGrafanaFolderCallout(false)}
        target={`#${createNewFolderButtonId}`}
      />
    </>
  );
};
