import { Label, Stack } from '@fluentui/react';
import {
  DeleteIconProps,
  ElxActionButton,
  ElxDropdown,
  ElxTextField,
} from '@elixir/components';
import React, { useState } from 'react';
import { WorkspaceHr } from '../../workspaceHelperComponents';
import { wideSpacingStackTokens } from '../../workspaceStyles';
import { dataConnectionParamMapping } from 'features/workspaces/models/workspace';

interface DataConnectionMappingParamsProps {
  parameters: dataConnectionParamMapping[];
  updatedDataConnectionParamMapping: (
    params: dataConnectionParamMapping[]
  ) => void;
}

const INPUT_WIDTH = 275;

const Parameter = (props: {
  data: dataConnectionParamMapping;
  onChange: (newParam: dataConnectionParamMapping) => void;
  index: number;
}) => {
  console.log('parameters.....');
  return (
    <>
      <Stack horizontal tokens={wideSpacingStackTokens}>
        <Stack.Item grow>
          <ElxDropdown
            dropdownWidth={250}
            selectedKey={props.data.propertyName}
            onChange={(event, option) => {
              const newParam = {
                ...props.data,
                propertyName: option?.text || 'ApplicationId',
              };
              props.onChange(newParam);
            }}
            styles={{ dropdown: { width: INPUT_WIDTH } }}
            options={[
              {
                key: 'ApplicationId',
                text: 'ApplicationId',
              },
              {
                key: 'Tenant',
                text: 'Tenant',
              },
            ]}
          />
        </Stack.Item>
        <Stack.Item grow>
          <ElxTextField
            value={props.data.lowSideValue}
            styles={{ fieldGroup: { width: INPUT_WIDTH, height: 32 } }}
            onChange={(e, value) => {
              const newParam = { ...props.data, lowSideValue: value || '' };
              props.onChange(newParam);
            }}
            placeholder={
              props.data.propertyName === 'ApplicationId'
                ? 'low side application id'
                : 'low side tenant'
            }
          />
        </Stack.Item>
        <Stack.Item grow>
          <ElxTextField
            value={props.data.highSideValue}
            styles={{ fieldGroup: { width: INPUT_WIDTH, height: 32 } }}
            onChange={(e, value) => {
              const newParam = { ...props.data, highSideValue: value || '' };
              props.onChange(newParam);
            }}
            placeholder={
              props.data.propertyName === 'ApplicationId'
                ? 'high side application id'
                : 'high side tanant'
            }
          />
        </Stack.Item>
      </Stack>
    </>
  );
};

const DataConnectionMappingParams = (
  props: DataConnectionMappingParamsProps
): JSX.Element => {
  const [parameters, setParameters] = useState(props.parameters);

  console.log('DataConnectionMappingParams....');
  return (
          <Stack tokens={{...wideSpacingStackTokens,padding:16}}>
              {/* Parameter list labels */}
              <Stack.Item grow>
                <Stack horizontal>
                  <Stack.Item
                    grow
                    align="start"
                    className="parameter-type-label"
                  >
                    <Label>{'Type'}</Label>
                  </Stack.Item>

                  <Stack.Item
                    grow
                    align="start"
                    className="parameter-name-label"
                  >
                    <Label>{'Low Side'}</Label>
                  </Stack.Item>

                  <Stack.Item
                    grow
                    align="start"
                    className="parameter-value-label"
                  >
                    <Label>{'High Side'}</Label>
                  </Stack.Item>

                  <Stack.Item grow align="start">
                    <Label>{''}</Label>
                  </Stack.Item>
                </Stack>
                <WorkspaceHr />
              </Stack.Item>

              {/* The parameters list */}
              <Stack.Item grow>
                <Stack tokens={wideSpacingStackTokens}>
                  {parameters.map((param, index) => (
                        <Stack.Item key={index}>
                          <Stack horizontal>
                            <Parameter
                              key={index}
                              index={index}
                              data={{
                                lowSideValue: param?.lowSideValue || '',
                                highSideValue: param?.highSideValue || '',
                                propertyName:
                                  param?.propertyName || 'ApplicationId',
                              }}
                              onChange={(newParamVals) => {
                                const newParams = [...parameters];
                                newParams[index] = newParamVals;
                                props.updatedDataConnectionParamMapping(
                                  newParams
                                );
                                setParameters(newParams);
                              }}
                            />
                            <ElxActionButton
                              iconProps={{
                                ...DeleteIconProps,
                                'aria-label': 'Delete Parameter',
                              }}
                              onClick={(e) => {
                                const tempParams = [...parameters];
                                tempParams.splice(index, 1);
                                props.updatedDataConnectionParamMapping(
                                  tempParams
                                );
                                setParameters(tempParams);
                              }}
                              text={''}
                              tooltipProps={{
                                content: 'Delete parameter',
                              }}
                            />
                          </Stack>
                        </Stack.Item>
                  ))}
                </Stack>
              </Stack.Item>

              {/* The add Data Connection Mapping button */}
              <Stack.Item align="start">
                <ElxActionButton
                  iconProps={{
                    iconName: 'add',
                    title: 'Add Data Connection Mapping',
                    'aria-label': 'Add Data Connection Mapping',
                  }}
                  text="Add Data Connection Mapping"
                  onClick={() => {
                    const newParams = [
                      ...parameters,
                      {
                        propertyName: '',
                        lowSideValue: '',
                        highSideValue: '',
                      },
                    ];
                    props.updatedDataConnectionParamMapping(newParams);
                    setParameters(newParams);
                  }}
                  tooltipProps={{
                    content: 'Add Data Connection Mapping',
                  }}
                />
              </Stack.Item>
            </Stack>        
  );
};

export default DataConnectionMappingParams;
