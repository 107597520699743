import { $laConstants } from 'utils/constants';
import kqlParser from './kqlParser';

/**
 * @name KustoQueryParser
 * @description
 * A service parse and manipulate Kusto queries.
 */
export class KustoQueryParser {
    /**
     * @name parseQuery
     * @description
     * Parse a Kusto query
     * @param {string} queryText - query to parse
     * @returns {object} - Syntax tree for the query
     */
    parseQuery (queryText) {
        queryText = this.removeQueryComments(queryText);
        return kqlParser.parse(queryText);
    };

    /**
     * @name removeRenderStatement
     * @description
     * Remove render statements from Kusto query
     * @param {string} queryText - Kusto query string
     * @returns {string} - a new query string free of render statements
     */
    removeRenderStatement (queryText) {
        return queryText.replace($laConstants.KustoLanguageRegex.RenderStatement, "");
    };

    /**
     * @name removeQueryComments
     * @description
     * Remove comments from Kusto query
     * @param {string} queryText - Kusto query string
     * @returns {string} - a new query string free of comments
     */
    removeQueryComments (queryText) {
        // Remove comments
        var commentRemoverRegExp = $laConstants.KustoLanguageRegex.Comment;
        var queryWithoutComments = queryText.replace(commentRemoverRegExp, function (firstGroupMatch, secondGroupMatch) {
            return secondGroupMatch || "";
        });

        // Remove empty lines before returning
        return queryWithoutComments.replace(/^\s*[\r\n]/gm, '');
    };
}

export const kustoQueryParser =  new KustoQueryParser();

export default kustoQueryParser;
