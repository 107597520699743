import { SharedSelector } from 'features/workspaces/components/editDataConnection/dataSourceComponents/sharedSelector';
import { SelectorProps } from 'features/workspaces/components/editDataConnection/editDataConnectionHelper';
import {
  ConnectionDataSourceType,
  SharedDataSource,
} from 'features/workspaces/models/project';
import { registerDataSourcePlugin } from '../registry';
import { sharedClient } from '../sharedClient';
import { DataSourcePlugin } from './dataSourcePlugin';

class SharedPlugin extends DataSourcePlugin {
  public dataSourceFormComponent(selectorProps: SelectorProps): JSX.Element {
    return (
      <SharedSelector
        workspace={selectorProps.workspace}
        state={selectorProps.state}
        setState={selectorProps.setState}
        customNameFilled={selectorProps.customNameFilled}
        proj2WsMap={selectorProps.proj2WsMap}
      />
    );
  }

  public generatedName(state: SharedDataSource) {
    let name = 'Shared Connection';
    if (state && state.sourceConnectionName) {
      name = `Share Connection from ${state.sourceProjectName}.${state.sourceConnectionName}`;
    }
    return name;
  }
}

registerDataSourcePlugin(
  ConnectionDataSourceType.Shared,
  new SharedPlugin(ConnectionDataSourceType.Shared, sharedClient)
);
