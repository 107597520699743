import { ElxPanel, PanelSize } from "@elixir/components";
import { Action, Activity } from "features/orchestrator/models/job";
import { useState } from "react";
import { JobActivityDataValidationActions } from "./JobActivityDataValidationActions";

export interface JobActivityManageActionsProps  {
  activity:Activity;
  onChange:(activity:Activity) => void;
  onDismiss:()=>void;
};

export const JobActivityManageActions = (
  props: JobActivityManageActionsProps
) => {
  const { activity, onChange, onDismiss } = props;

  const [tempActions, setTempActions] = useState(
    activity.actions || ([] as Action[])
  );

  return (
    <>
      <ElxPanel
        headerText={`Manage Actions`}
        isOpen={true}
        size={PanelSize.large}
        onDismiss={onDismiss}
        actions={[
          {
            key: `Save Actions`,
            text: `Save Action`,
            primary: true,
            onClick: () => {
              activity.actions = tempActions;
              onChange(activity);
            },
          },
          {
            key: 'close',
            text: 'Close',
            onClick: onDismiss,
          },
        ]}
      >
        <JobActivityDataValidationActions
          actions={tempActions}
          activity={activity}
          onChange={(actions: Action[]): void => {
            setTempActions(actions);
          }}
        ></JobActivityDataValidationActions>
      </ElxPanel>
    </>
  );
};