import { RootState } from 'app/lensShellUtility';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectJobById } from '../jobSlice';
import { Activity, Job, retryPolicyType } from '../models/job';
import JobAuthor from './jobAuthor';

export interface JobEditProps {
  job?: Job;
  onCancel?: () => void;
  onSuccess?: () => void;
}

const JobEdit = (props: JobEditProps) => {
  const params: { jobId: string } = useParams();
  const job = useSelector((state: RootState) =>
    selectJobById(state, params.jobId)
  );
  let tempJob = JSON.parse(JSON.stringify(props.job ? props.job : job));
  tempJob!.config.activities!.forEach((act: Activity, idx: number) => {
    if (!act.dependsOn) {
      act.dependsOn = [];
      if (idx !== 0) {
        act.dependsOn.push({
          activityId: tempJob!.config!.activities[idx - 1].id,
        });
      }
    }
    if (!act.retryPolicy) {
      act.retryPolicy = { type: retryPolicyType[0] };
    }
  });

  // Old Jobs do not have retry policy.
  if (!tempJob.config.retryPolicy) {
    tempJob.config.retryPolicy = {};
  }
  if (!tempJob.config!.parameters) {
    tempJob.config.parameters = [];
  }
  return (
    <JobAuthor
      job={tempJob}
      onCancel={props.onCancel}
      onSuccess={props.onSuccess}
    ></JobAuthor>
  );
};

export default JobEdit;
