import { useDispatch } from 'react-redux';
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  EntityState,
} from '@reduxjs/toolkit';
import { RootState, AppDispatch } from 'app/lensShellUtility';
import { notifier } from 'utils/notifier';
import {
  MigrationRequest,
  Workspace,
  WorkspaceAccessFilter,
  WorkspaceApplication,
  WorkspaceBeforeFormat,
  WorkspaceHistoryFullObj,
  WorkspaceObject,
  WorkspaceSaveStatus,
  WorkspaceStatus,
} from './models/workspace';
import {
  formatWorkspaceForSave,
  formatWorkspaceForUse,
  getCurrentWorkspaceId,
  combinePersonas,
} from './utils/workspaceUtils';
import { workspacesApi } from './api/workspacesApi';
import {
  DataSource,
  Project,
  connectionTypesWithValidation,
} from './models/project';
import logger from 'features/appInsights/lensLogger';
import { LensTelemetryConstants } from 'features/appInsights/appInsightsLibs';
import savedObjectsApi from './api/savedObjectsApi';
import { WritableDraft } from 'immer/dist/internal';
import { LensNode } from 'features/convertToGrafana/models/lensDashboard';

export const useAppDispatch = () => useDispatch<AppDispatch>();

// keep track of the current requested workspace, because they can arrive out of order
let loadId = 0;
let editLoadId = 0;

interface WorkspaceLoadState {
  loadId: number;
  workspace: Workspace;
}

export const loadWorkspace = createAsyncThunk(
  'workspace/loadWorkspace',
  async (
    workspaceId: string | null | undefined
  ): Promise<WorkspaceLoadState> => {
    var currentWorkspaceId = getCurrentWorkspaceId();
    loadId = loadId + 1;

    return {
      loadId,
      workspace: workspaceId
        ? await formatWorkspaceForUse(
            await workspacesApi.getWorkspace(workspaceId)
          )
        : currentWorkspaceId
        ? await formatWorkspaceForUse(
            await workspacesApi.getWorkspace(currentWorkspaceId)
          )
        : workspaceInit,
    };
  }
);

export const loadEditWorkspace = createAsyncThunk(
  'workspace/loadEditWorkspace',
  async (workspaceId: string): Promise<WorkspaceLoadState> => {
    editLoadId = editLoadId + 1;
    return {
      loadId: editLoadId,
      workspace:
        (await formatWorkspaceForUse(
          await workspacesApi.getWorkspace(workspaceId)
        )) || workspaceInit,
    };
  }
);

export const createWorkspace = createAsyncThunk(
  'workspace/createWorkspace',
  async (workspace: Workspace): Promise<WorkspaceBeforeFormat> => {
    logger.event(
      LensTelemetryConstants.EventNames.WorkspaceActions.NewWorkspace
    );
    return await workspacesApi.createWorkspace(
      formatWorkspaceForSave(workspace)
    );
  }
);

export const deleteWorkspace = createAsyncThunk(
  'workspace/deleteWorkspace',
  async (workspaceId: string) => {
    return await workspacesApi
      .deleteWorkspace(workspaceId)
      .then((workspace: Workspace) => {
        notifier.info('Successfully deleted ' + workspace.name + '!');
      })
      .catch((err) => {
        notifier.error(err.message);
      });
  }
);
export const saveWorkspace = createAsyncThunk(
  'workspace/saveWorkspace',
  async (workspace: Workspace) => {
    // convert back to original format (put in utils)
    logger.event(LensTelemetryConstants.EventNames.WorkspaceActions.Save, {
      workspaceSaved: workspace.id,
    });
    return await formatWorkspaceForUse(
      await workspacesApi.saveWorkspace(
        workspace.id,
        formatWorkspaceForSave(workspace)
      )
    );
  }
);

export const migrateWorkspace = createAsyncThunk(
  'workspace/migrateWorkspace',
  async (migrationRequest: MigrationRequest) => {
    return await workspacesApi
      .migrateWorkspace(migrationRequest)
      .then(() => {
        notifier.info(
          'Successfully migrated ' +
            migrationRequest.workspaceId +
            'to ' +
            migrationRequest.newAdminGroupId +
            ', ' +
            migrationRequest.targetAADTenantId
        );
      })
      .catch((err) => {
        notifier.error(err.message);
      });
  }
);

export const getWorkspaceHistory = createAsyncThunk(
  'workspace/getWorkspaceHistory',
  async (workspaceId: string) => {
    return await workspacesApi.getWorkspaceHistory(workspaceId).catch((err) => {
      notifier.error(err.message);
    });
  }
);

export const loadProject = createAsyncThunk(
  'workspace/loadProject',
  async (
    projectInfo:
      | {
          workspaceId?: string | null;
          isEditWorkspace: boolean;
        }
      | undefined
  ) => {
    // make this an object (with the workspaceId and isEditWorkspace)
    var currentWorkspaceId = getCurrentWorkspaceId();
    return currentWorkspaceId
      ? projectInfo
        ? await workspacesApi.getProject(
            projectInfo.workspaceId || currentWorkspaceId
          )
        : await workspacesApi.getProject(currentWorkspaceId)
      : undefined;
  }
);

export const getSharedConnections = createAsyncThunk(
  'workspace/getSharedConnections',
  async (workspaceId: string) => {
    return await workspacesApi.getDataSourceShared(workspaceId);
  }
);

/**
 * returns the objects in the order of type: dashboards, queries, visulizations
 */
export const getWorkspaceSavedObjects = createAsyncThunk(
  'workspace/getWorkspaceSavedObjects',
  async (workspaceId: string) => {
    return await savedObjectsApi.getWorkspaceSavedObjects(workspaceId);
  }
);

/**
 * returns the objects in the order of type: dashboards, queries, visulizations
 */
export const getWorkspaceRecycledObjects = createAsyncThunk(
  'workspace/getWorkspaceRecycledObjects',
  async (workspaceId: string) => {
    return await savedObjectsApi.getWorkspaceRecycledObjects(workspaceId);
  }
);

export const updateWorkspaceConfiguration = createAsyncThunk(
  'workspace/updateWorkspaceConfiguration',
  async ({
    workspaceId,
    project,
  }: {
    workspaceId: string;
    project: Project;
  }) => {
    return await workspacesApi.updateWorkspaceConfiguration(
      workspaceId,
      project
    );
  }
);

export const enableOrchestration = createAsyncThunk(
  'workspace/enableOrchestration',
  async ({
    workspaceId,
    serviceGuid,
  }: {
    workspaceId: string;
    serviceGuid: string;
  }): Promise<WorkspaceBeforeFormat> => {
    return await workspacesApi.postEnableOrchestration(
      workspaceId,
      serviceGuid
    );
  }
);

export const createDataConnection = createAsyncThunk(
  'workspace/createDataConnection',
  async ({
    workspaceId,
    connectionRequest,
  }: {
    workspaceId: string;
    connectionRequest: DataSource;
  }) => {
    return await workspacesApi.postConnection(workspaceId, connectionRequest);
  }
);

export const saveDataConnection = createAsyncThunk(
  'workspace/saveDataConnection',
  async ({
    workspaceId,
    connectionRequest,
  }: {
    workspaceId: string;
    connectionRequest: DataSource;
  }) => {
    return await workspacesApi.putConnection(workspaceId, connectionRequest);
  }
);

export const getDataConnections = createAsyncThunk(
  'workspace/getDataConnections',
  async (workspaceId: string) => {
    return await workspacesApi.getDataConnections(workspaceId);
  }
);

export const deleteDataConnection = createAsyncThunk(
  'workspace/deleteDataConnection',
  async ({
    workspaceId,
    connectionId,
  }: {
    workspaceId: string;
    connectionId: string;
  }) => {
    return await workspacesApi.deleteDataConnection(workspaceId, connectionId);
  }
);

export const validateDataConnection = createAsyncThunk(
  'workspace/validateDataConnection',
  async ({
    workspaceId,
    connection,
  }: {
    workspaceId: string;
    connection: DataSource;
  }) => {
    return await workspacesApi.validateDataConnection(workspaceId, connection);
  }
);

export const deleteWorkspaceSavedObject = createAsyncThunk(
  'workspace/deleteWorkspaceSavedObject',
  async ({
    workspaceId,
    objects,
  }: {
    workspaceId: string;
    objects: WorkspaceObject[];
  }) => {
    return await savedObjectsApi.deleteSavedObjects(workspaceId, objects);
  }
);

/**
 * restores multiple recycled objects
 */
export const restoreRecycledObjects = createAsyncThunk(
  'workspace/restoreRecycledObjects',
  async ({
    workspaceId,
    objects,
  }: {
    workspaceId: string;
    objects: WorkspaceObject[];
  }) => {
    return await savedObjectsApi.restoreRecycledObjects(workspaceId, objects);
  }
);

interface WorkspaceDataConnectionValidation {
  connectionName: string;
  connectionStatus: string;
}

interface DataConnectionValidationsStatus {
  [connectionId: string]: WorkspaceStatus;
}
const dataConnectionsAdapter = createEntityAdapter<DataSource>({
  selectId: (wdc) => wdc.id,
});
const dataConnectionValidationsAdapter =
  createEntityAdapter<WorkspaceDataConnectionValidation>({
    selectId: (dc) => dc.connectionName,
  });
interface WorkspaceState {
  current: Workspace;
  editWorkspace: Workspace;
  orchestratorProject: Project;
  workspaceHistory: WorkspaceHistoryFullObj[];
  dataConnections: EntityState<DataSource>;
  status: WorkspaceStatus;
  editWorkspaceStatus: WorkspaceStatus;
  orchestratorProjectStatus: WorkspaceStatus;
  editDataConnectionStatus: WorkspaceStatus;
  applicationsStatus: WorkspaceStatus;
  editWorkspaceApplicationsStatus: WorkspaceStatus;
  dataConnectionsStatus: WorkspaceStatus;
  dataConnectionSaveStatus: WorkspaceSaveStatus;
  orchestratorEditProjectStatus: WorkspaceStatus;
  orchestratorEditProject: Project;
  workspaceHistoryStatus: WorkspaceStatus;
  dataConnectionValidations: EntityState<WorkspaceDataConnectionValidation> & {
    validationsStatus: DataConnectionValidationsStatus;
  };
  dashboardObject: LensNode[];
  enableOrchestrationStatus: WorkspaceStatus;
  savedObjects: WorkspaceObject[];
  recycledObjects: WorkspaceObject[];
  savedObjectsStatus: WorkspaceStatus;
  recycledObjectsStatus: WorkspaceStatus;
}

export const workspaceInit: Workspace = {
  //  this may need to be updated and cleaned
  id: '',
  name: '',
  alias: '',
  dsoProject: '',
  applications: undefined,
  dataConnections: undefined,
  createdBy: '',
  accessFilter: WorkspaceAccessFilter.None,
  lastUpdated: '',
  description: '',
  tags: [],
  adminAppIds: [],
  adminGroups: [],
  adminGroupIds: [],
  adminAliases: [],
  lastUpdatedBy: '',
  orchestrateAppIds: [],
  orchestrateGroups: [],
  orchestrateGroupIds: [],
  orchestrateAliases: [],
  readOnlyAppIds: [],
  readOnlyGroups: [],
  readOnlyGroupIds: [],
  readOnlyAliases: [],
  readWriteAppIds: [],
  readWriteGroups: [],
  readWriteGroupIds: [],
  readWriteAliases: [],
  isReadOpen: false,
  shareType: '',
  customReadAccessMessage: '',
  customWriteAccessMessage: '',
  administrators: [],
  writers: [],
  readers: [],
  orchestrators: [],
};

const orchestratorProjectInit: Project = {
  projectName: '',
  projectDisplayName: '',
  authorizedAADClientIds: {},
  dataConnections: [],
};

const initialWorkspaceState: WorkspaceState = {
  current: { ...workspaceInit },
  editWorkspace: { ...workspaceInit },
  orchestratorProject: orchestratorProjectInit,
  workspaceHistory: [],
  dataConnections: dataConnectionsAdapter.getInitialState([]),
  editDataConnectionStatus: WorkspaceStatus.None,
  status: WorkspaceStatus.None,
  editWorkspaceStatus: WorkspaceStatus.None,
  editWorkspaceApplicationsStatus: WorkspaceStatus.None,
  orchestratorProjectStatus: WorkspaceStatus.None,
  applicationsStatus: WorkspaceStatus.None,
  dataConnectionsStatus: WorkspaceStatus.None,
  dataConnectionSaveStatus: WorkspaceSaveStatus.None,
  orchestratorEditProjectStatus: WorkspaceStatus.None,
  orchestratorEditProject: orchestratorProjectInit,
  workspaceHistoryStatus: WorkspaceStatus.None,
  dataConnectionValidations: dataConnectionValidationsAdapter.getInitialState({
    validationsStatus: {} as DataConnectionValidationsStatus,
  }),
  dashboardObject: [],
  enableOrchestrationStatus: WorkspaceStatus.None,
  savedObjects: [],
  recycledObjects: [],
  savedObjectsStatus: WorkspaceStatus.None,
  recycledObjectsStatus: WorkspaceStatus.None,
};

const updateWorkspaceData = (
  currWorkspaceState: WritableDraft<Workspace>,
  workspace: Workspace | undefined
): Workspace => {
  return {
    ...currWorkspaceState,
    ...workspace,
    administrators: combinePersonas(
      workspace?.adminGroupIds || [],
      workspace?.adminAppIds || [],
      workspace?.adminAliases || []
    ),
    writers: combinePersonas(
      workspace?.readWriteGroupIds || [],
      workspace?.readWriteAppIds || [],
      workspace?.readWriteAliases || []
    ),
    readers: combinePersonas(
      workspace?.readOnlyGroupIds || [],
      workspace?.readOnlyAppIds || [],
      workspace?.readOnlyAliases || []
    ),
    orchestrators: combinePersonas(
      workspace?.orchestrateGroupIds || [],
      workspace?.orchestrateAppIds || [],
      workspace?.orchestrateAliases || []
    ),
  };
};
/**
 * Redux slice representing the current workspace.
 */
const workspaceSlice = createSlice({
  name: 'workspace',
  initialState: initialWorkspaceState,
  reducers: {
    reset: (state) => initialWorkspaceState,
    clearDataConnectionValidations: (state) => {
      dataConnectionValidationsAdapter.removeAll(
        state.dataConnectionValidations
      );
    },
    setCurrentWorkspace: (state, action) => {
      state.status = WorkspaceStatus.Loaded;
      state.current = action.payload;
    },
    setDashboardObject: (state, action) => {
      state.dashboardObject = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadWorkspace.pending, (state, action) => {
        state.status = WorkspaceStatus.Loading;
      })
      .addCase(loadWorkspace.fulfilled, (state, action) => {
        if (action.payload?.loadId === loadId) {
          state.status = WorkspaceStatus.Loaded;
          state.current = updateWorkspaceData(
            state.current,
            action.payload.workspace
          );
        }
      })
      .addCase(loadWorkspace.rejected, (state, action) => {
        state.status = WorkspaceStatus.Error;
        notifier.error(action.error);
      })
      .addCase(loadEditWorkspace.pending, (state, action) => {
        state.editWorkspaceStatus = WorkspaceStatus.Loading;
      })
      .addCase(loadEditWorkspace.fulfilled, (state, action) => {
        if (action.payload?.loadId === editLoadId) {
          state.editWorkspaceStatus = WorkspaceStatus.Loaded;
          state.editWorkspace = updateWorkspaceData(
            state.editWorkspace,
            action.payload.workspace
          );
          dataConnectionsAdapter.removeAll(state.dataConnections);
          dataConnectionValidationsAdapter.removeAll(
            state.dataConnectionValidations
          );
          // state.editWorkspace.dataConnections = [];
          state.editWorkspaceApplicationsStatus = WorkspaceStatus.None;
        }
      })
      .addCase(loadEditWorkspace.rejected, (state, action) => {
        state.editWorkspaceStatus = WorkspaceStatus.Error;
        notifier.error(action.error);
      })
      .addCase(createWorkspace.fulfilled, (state, action) => {
        notifier.info(
          'Success. Workspace: ' + action.payload.name + ' is created.'
        );
      })
      .addCase(createWorkspace.rejected, (state, action) => {
        notifier.error('Error. ' + action.error);
      })
      .addCase(saveWorkspace.fulfilled, (state, action) => {
        notifier.info(
          'Success. Workspace: ' + action.payload.name + ' is saved.'
        );
        state.editWorkspace = updateWorkspaceData(
          state.editWorkspace,
          action.payload
        );
        state.editWorkspaceStatus = WorkspaceStatus.Loaded;
      })
      .addCase(saveWorkspace.rejected, (state, action) => {
        notifier.error('Error. ' + action.error);
      })
      .addCase(loadProject.pending, (state, action) => {
        if (!action.meta.arg?.isEditWorkspace) {
          state.applicationsStatus = WorkspaceStatus.Loading;
          state.dataConnectionsStatus = WorkspaceStatus.Loading;
          state.orchestratorProjectStatus = WorkspaceStatus.Loading;
        }
        if (action.meta.arg?.isEditWorkspace) {
          state.editWorkspaceApplicationsStatus = WorkspaceStatus.Loading;
          state.orchestratorEditProjectStatus = WorkspaceStatus.Loading;
        }
      })
      .addCase(loadProject.fulfilled, (state, action) => {
        state.applicationsStatus = WorkspaceStatus.Loaded;
        state.dataConnectionsStatus = WorkspaceStatus.Loaded;
        state.orchestratorProjectStatus = WorkspaceStatus.Loaded;

        const clientIds = action.payload?.authorizedAADClientIds ?? {};
        let applications: WorkspaceApplication[] = [];
        for (const tenant in clientIds) {
          clientIds[tenant].forEach((id) => {
            applications.push({ tenant, id });
          });
        }
        if (!action.meta.arg?.isEditWorkspace) {
          state.applicationsStatus = WorkspaceStatus.Loaded;
          // Note: perfecly valid that a workspace has no Project
          state.current.applications = applications;
          state.orchestratorProject =
            action.payload || initialWorkspaceState.orchestratorProject;

          state.current.dataConnections = action.payload?.dataConnections ?? [];
        }

        if (action.meta.arg?.isEditWorkspace) {
          state.editWorkspaceApplicationsStatus = WorkspaceStatus.Loaded;
          state.orchestratorEditProjectStatus = WorkspaceStatus.Loaded;
          // Note: perfecly valid that a workspace has no Project
          state.editWorkspace.applications = applications;
          state.orchestratorEditProject =
            action.payload || initialWorkspaceState.orchestratorProject;
        }
      })
      .addCase(loadProject.rejected, (state, action) => {
        if (!action.meta.arg?.isEditWorkspace) {
          state.applicationsStatus = WorkspaceStatus.Error;
          state.dataConnectionsStatus = WorkspaceStatus.Error;
          state.orchestratorProjectStatus = WorkspaceStatus.Error;
        }
        if (action.meta.arg?.isEditWorkspace) {
          state.editWorkspaceApplicationsStatus = WorkspaceStatus.Error;
          state.orchestratorEditProjectStatus = WorkspaceStatus.Error;
        }
        notifier.error(action.error);
      })
      .addCase(getSharedConnections.pending, (state, action) => {})
      .addCase(getSharedConnections.fulfilled, (state, action) => {
        state.current.sourceDataConnections = action.payload || [];
      })
      .addCase(getSharedConnections.rejected, (state, action) => {
        notifier.error(action.error);
      })
      .addCase(createDataConnection.pending, (state, action) => {
        state.dataConnectionSaveStatus = WorkspaceSaveStatus.Saving;
      })
      .addCase(createDataConnection.fulfilled, (state, action) => {
        state.dataConnectionSaveStatus = WorkspaceSaveStatus.Saved;
        dataConnectionsAdapter.upsertOne(state.dataConnections, action.payload);
      })
      .addCase(createDataConnection.rejected, (state, action) => {
        state.dataConnectionSaveStatus = WorkspaceSaveStatus.Error;
        notifier.error(action.error);
      })
      .addCase(saveDataConnection.pending, (state, action) => {
        state.dataConnectionSaveStatus = WorkspaceSaveStatus.Saving;
      })
      .addCase(saveDataConnection.fulfilled, (state, action) => {
        state.dataConnectionSaveStatus = WorkspaceSaveStatus.Saved;
        dataConnectionsAdapter.upsertOne(state.dataConnections, action.payload);
      })
      .addCase(saveDataConnection.rejected, (state, action) => {
        state.dataConnectionSaveStatus = WorkspaceSaveStatus.Error;
        notifier.error(action.error);
      })
      .addCase(deleteDataConnection.pending, (state, action) => {})
      .addCase(deleteDataConnection.fulfilled, (state, action) => {
        dataConnectionsAdapter.removeOne(
          state.dataConnections,
          action.payload.id
        );
        dataConnectionValidationsAdapter.removeOne(
          state.dataConnectionValidations,
          action.payload.id
        );
      })
      .addCase(deleteDataConnection.rejected, (state, action) => {
        notifier.error(action.error);
      })
      .addCase(getWorkspaceHistory.pending, (state, action) => {
        state.workspaceHistoryStatus = WorkspaceStatus.Loading;
      })
      .addCase(getWorkspaceHistory.fulfilled, (state, action) => {
        state.workspaceHistoryStatus = WorkspaceStatus.Loaded;
        if (action.payload) {
          state.workspaceHistory = action.payload;
        }
      })
      .addCase(getWorkspaceHistory.rejected, (state, action) => {
        state.workspaceHistoryStatus = WorkspaceStatus.Error;
        notifier.error(action.error);
      })
      .addCase(getDataConnections.pending, (state, action) => {
        state.editDataConnectionStatus = WorkspaceStatus.Loading;
      })
      .addCase(getDataConnections.fulfilled, (state, action) => {
        state.editDataConnectionStatus = WorkspaceStatus.Loaded;
        if (action.payload) {
          dataConnectionsAdapter.setAll(state.dataConnections, action.payload);
          dataConnectionValidationsAdapter.removeAll(
            state.dataConnectionValidations
          );
        }
      })

      .addCase(getDataConnections.rejected, (state, action) => {
        state.editDataConnectionStatus = WorkspaceStatus.Error;
        notifier.error('Cannot load data connections. ' + action.error);
      })
      .addCase(validateDataConnection.pending, (state, action) => {
        state.dataConnectionValidations.validationsStatus[
          action.meta.arg.connection.id
        ] = WorkspaceStatus.Loading;
      })
      .addCase(validateDataConnection.fulfilled, (state, action) => {
        if (action.payload) {
          let status: string[] = [];
          let connectionStatus = '';
          if (
            !connectionTypesWithValidation.includes(
              action.meta.arg.connection.type
            )
          ) {
            connectionStatus = 'Validation Unavailable';
          } else {
            action.payload.results.forEach((res) => {
              if (res.isAuthorized) {
                if (
                  res.permission === 'Read' ||
                  res.permission === 'Write' ||
                  res.permission === 'Execute' ||
                  res.permission === 'Access'
                ) {
                  status.push(res.permission);
                }
              }
            });

            connectionStatus =
              status.length === 0 ? 'Not Connected' : status.join(', ');
          }

          const validation: WorkspaceDataConnectionValidation = {
            connectionName: action.payload.connectionName,
            connectionStatus: connectionStatus,
          };

          state.dataConnectionValidations.validationsStatus[
            action.payload.connectionName
          ] = WorkspaceStatus.Loaded;

          dataConnectionValidationsAdapter.upsertOne(
            state.dataConnectionValidations,
            validation
          );
        }
      })
      .addCase(validateDataConnection.rejected, (state, action) => {
        state.dataConnectionValidations.validationsStatus[
          action.meta.arg.connection.id
        ] = WorkspaceStatus.Error;

        const validation: WorkspaceDataConnectionValidation = {
          connectionName: action.meta.arg.connection.id,
          connectionStatus: 'Validation Failed',
        };

        dataConnectionValidationsAdapter.upsertOne(
          state.dataConnectionValidations,
          validation
        );
        notifier.error('Data connection validation error. ' + action.error);
      })
      .addCase(enableOrchestration.pending, (state, action) => {
        state.enableOrchestrationStatus = WorkspaceStatus.Loading;
      })
      .addCase(enableOrchestration.fulfilled, (state, action) => {
        state.enableOrchestrationStatus = WorkspaceStatus.Loaded;
      })
      .addCase(enableOrchestration.rejected, (state, action) => {
        state.enableOrchestrationStatus = WorkspaceStatus.Error;
        notifier.error(action.error);
      })
      .addCase(getWorkspaceSavedObjects.pending, (state, action) => {
        state.savedObjectsStatus = WorkspaceStatus.Loading;
      })
      .addCase(getWorkspaceSavedObjects.fulfilled, (state, action) => {
        state.savedObjectsStatus = WorkspaceStatus.Loaded;
        const dashboards = action.payload.filter((o) => o.type === 'dashboard');
        const queries = action.payload.filter((o) => o.type === 'search');
        const vis = action.payload.filter((o) => o.type === 'visualization');
        state.savedObjects = dashboards.concat(queries, vis);
      })
      .addCase(getWorkspaceSavedObjects.rejected, (state, action) => {
        state.savedObjectsStatus = WorkspaceStatus.Error;
        notifier.error(action.error);
      })
      .addCase(getWorkspaceRecycledObjects.pending, (state, action) => {
        state.recycledObjectsStatus = WorkspaceStatus.Loading;
      })
      .addCase(getWorkspaceRecycledObjects.fulfilled, (state, action) => {
        state.recycledObjectsStatus = WorkspaceStatus.Loaded;
        const dashboards = action.payload.filter((o) => o.type === 'dashboard');
        const queries = action.payload.filter((o) => o.type === 'search');
        const vis = action.payload.filter((o) => o.type === 'visualization');
        state.recycledObjects = dashboards.concat(queries, vis);
      })
      .addCase(getWorkspaceRecycledObjects.rejected, (state, action) => {
        state.recycledObjectsStatus = WorkspaceStatus.Error;
        notifier.error(action.error);
      })
      .addCase(deleteWorkspaceSavedObject.fulfilled, (state, action) => {
        logger.event(
          LensTelemetryConstants.EventNames.Relationship.Success.Delete,
          {
            deletedObjects: action.meta.arg.objects,
            worksapceId: action.meta.arg.workspaceId,
          }
        );
        notifier.info(
          'Deleted workspace object(s): ' +
            action.meta.arg.objects.map(
              (obj) => obj.type + '-' + obj.title + '-' + obj.id
            )
        );
      })
      .addCase(deleteWorkspaceSavedObject.rejected, (state, action) => {
        logger.event(
          LensTelemetryConstants.EventNames.Relationship.Failure.Delete,
          {
            deletedObjects: action.meta.arg.objects,
            worksapceId: action.meta.arg.workspaceId,
          }
        );
        notifier.error(action.error);
      });
  },
});

export const {
  clearDataConnectionValidations,
  reset,
  setCurrentWorkspace,
  setDashboardObject,
} = workspaceSlice.actions;
// selectors
export const selectWorkspace = (state: RootState) => state.workspace.current;
export const selectWorkspaceId = (state: RootState) =>
  state.workspace.current.id;
export const selectWorkspaceName = (state: RootState) =>
  state.workspace.current.name;
export const selectWorkspaceStatus = (state: RootState) =>
  state.workspace.status;
export const selectWorkspaceApplications = (state: RootState) =>
  state.workspace.current.applications;
export const selectWorkspaceApplicationsStatus = (state: RootState) =>
  state.workspace.applicationsStatus;
export const selectWorkspaceDataConnections = (state: RootState) =>
  state.workspace.current.dataConnections;
export const selectWorkspaceDataConnectionsStatus = (state: RootState) =>
  state.workspace.dataConnectionsStatus;
export const selectWorkspaceDataConnectionSaveStatus = (state: RootState) =>
  state.workspace.dataConnectionSaveStatus;
export const selectOrchestratorProject = (state: RootState) =>
  state.workspace.orchestratorProject;
export const selectOrchestratorProjectStatus = (state: RootState) =>
  state.workspace.orchestratorProjectStatus;
export const selectOrchestratorEditProject = (state: RootState) =>
  state.workspace.orchestratorEditProject;
export const selectOrchestratorEditProjectStatus = (state: RootState) =>
  state.workspace.orchestratorEditProjectStatus;
export const editWorkspace = (state: RootState) =>
  state.workspace.editWorkspace;
export const editWorkspaceStatus = (state: RootState) =>
  state.workspace.editWorkspaceStatus;
export const selectWorkspaceHistory = (state: RootState) =>
  state.workspace.workspaceHistory;
export const selectWorkspaceHistoryStatus = (state: RootState) =>
  state.workspace.workspaceHistoryStatus;
export const selectEditDataConnectionsStatus = (state: RootState) =>
  state.workspace.editDataConnectionStatus;
export const selectEditWorkspaceApplications = (state: RootState) =>
  state.workspace.editWorkspace.applications;
export const selectEditWorkspaceApplicationsStatus = (state: RootState) =>
  state.workspace.editWorkspaceApplicationsStatus;
export const selectEnableOrchestrationStatus = (state: RootState) =>
  state.workspace.enableOrchestrationStatus;
export const selectDashboardObject = (state: RootState) =>
  state.workspace.dashboardObject;
export const selectSavedObjects = (state: RootState) =>
  state.workspace.savedObjects;
export const selectSavedObjectsStatus = (state: RootState) =>
  state.workspace.savedObjectsStatus;
export const selectRecycledObjects = (state: RootState) =>
  state.workspace.recycledObjects;
export const selectRecycledObjectsStatus = (state: RootState) =>
  state.workspace.recycledObjectsStatus;

export const {
  selectAll: selectAllDataConnections,
  selectEntities: selectDataConnectionEntities,
  selectById: selectDataConnectionById,
  selectIds: selectDataConnectionIds,
} = dataConnectionsAdapter.getSelectors(
  (state: RootState) => state.workspace.dataConnections
);

export const selectDataConnectionValidationsStatus = (state: RootState) =>
  state.workspace.dataConnectionValidations.validationsStatus;

export const {
  selectAll: selectAllValidations,
  selectEntities: selectValidationsEntities,
  selectById: selectValidationById,
  selectIds: selectValidationIds,
} = dataConnectionValidationsAdapter.getSelectors(
  (state: RootState) => state.workspace.dataConnectionValidations
);

export default workspaceSlice.reducer;
