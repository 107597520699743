import { TrainingAndGuidesProps } from './trainingAndGuidesList';

export const trainingAndGuidesData: TrainingAndGuidesProps[] = [
  {
    key: '1',
    mainLabel: 'Learn to create a workspace',
    secondaryLabel: 'Collaborate with your team using workspaces',
    iconName: 'WorkspaceIcon',
    iconText: '',
    link: 'https://eng.ms/docs/products/genevaanalytics/lensexplorer/workspaces/collaboratewithworkspaces',
  },
  {
    key: '2',
    mainLabel: 'Learn Job authoring',
    secondaryLabel: 'Perform data transformation or data movement with jobs',
    iconName: 'JobIcon',
    iconText: '',
    link: 'https://eng.ms/docs/products/genevaanalytics/lensexplorer/lensv3/jobauthoring',
  },
  {
    key: '3',
    mainLabel: 'Learn how to connect to datasources',
    secondaryLabel: 'Workspace data connections',
    iconName: 'ConnectIcon',
    iconText: '',
    link: 'https://eng.ms/docs/products/genevaanalytics/lensexplorer/workspaces/dataconnections',
  },
  {
    key: '4',
    mainLabel: 'Learn to create a dashboard',
    secondaryLabel: 'Learn how to build interactive dashboards',
    iconName: 'DashboardIcon',
    iconText: '',
    link: 'https://eng.ms/docs/products/genevaanalytics/lensexplorer/dashboards/workingwithdashboards',
  },
  {
    key: '5',
    mainLabel: 'Automate your Lens development',
    secondaryLabel: 'Learn how to deploy Lens assets using Ev2',
    iconName: 'DeployIcon',
    iconText: '',
    link: 'https://eng.ms/docs/products/genevaanalytics/lensexplorer/ev2extensions/ev2extensions',
  },
  {
    key: '6',
    mainLabel: 'Learn Query generation',
    secondaryLabel: 'Learn how Lens helps users build visualizations',
    iconName: 'QueryIcon',
    iconText: '',
    link: 'https://eng.ms/docs/products/genevaanalytics/lensexplorer/visualizations/queryassistandrendermode',
  },
];
