import { Label, MessageBar, Stack } from '@fluentui/react';
import { CopyButtonWithNotifier } from 'components/copyButtons/copyButtons';

export interface ConversionWarningsProps {
  warnings: string[];
}

export const ConversionWarnings = ({
  warnings,
}: ConversionWarningsProps): JSX.Element => {
  if (warnings.length === 0) {
    return <></>;
  }

  return (
    <Stack>
      <Stack horizontal>
        <Stack.Item align="start" grow>
          <Label>Conversion Warnings: </Label>
        </Stack.Item>
        <Stack.Item align="end">
          <CopyButtonWithNotifier text={warnings.join('\n')} label="Copy all" />
        </Stack.Item>
      </Stack>
      <Stack tokens={{ childrenGap: 4 }}>
        {warnings.map((warning, index) => (
          <MessageBar
            styles={{
              text: { display: 'grid' },
              iconContainer: { display: 'none' },
            }}
            key={index}
            isMultiline={false}
            truncated={true}
            expandButtonProps={{ ariaLabel: 'show more' }}
          >
            {warning}
          </MessageBar>
        ))}
      </Stack>
    </Stack>
  );
};
